// @ts-nocheck: converted from JS

import DebounceInput from '@/components/UI/DebounceInput';

const SelectBuilderOption = ({ onChange, index, value }) => {
  const handleValueChange = (e) => {
    onChange(e.target.value, index);
  };
  return (
    <div className="flex items-center w-100" data-test-component="SelectBuilderOption" data-test-element="container">
      <DebounceInput
        handleChange={handleValueChange}
        inputProps={{
          value,
          style: { marginBottom: 0 },
        }}
      />
    </div>
  );
};

export default SelectBuilderOption;
