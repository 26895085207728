import Icon from '@/components/UI/Icon';
import Link from '@/components/UI/Link';
import { FC } from 'react';
import styles from './FormulaError.module.scss';

interface FormulaErrorProps {
  messages: Array<string>;
}

export const FormulaError: FC<FormulaErrorProps> = ({ messages }) => (
  <div className={`f6 bg-near-white near-black ${styles['formula_error']}`}>
    <div className={`${styles['formula_error_info_box']} br3 br--top--left`}>
      <div className="flex flex-column">
        {messages.map((message, index) => (
          <div key={`msg-${index}`} className="flex">
            <Icon icon="critical_alert" className="dark-red" width="24" height="24" />
            <span className="fw5 black">{message}</span>
          </div>
        ))}
        <div className="flex-grow-1 flex-column flex lh-copy ml2">
          <div>
            Click the buttons above or the column names to the left rather than typing your formula to ensure it’s
            valid.
          </div>
          <div>
            {'You can also '}
            <Link
              to="https://help.benchling.com/hc/en-us/articles/22351086335373"
              openTab
              style={{ color: 'dodgerblue' }}
            >
              check the help article.
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
