import { CheckListStatus } from '@/components/UI/CheckList/CheckList.utils';
import type { ReactNode } from 'react';

interface CheckListItemProps {
  status: string;
  children: ReactNode;
}

const CheckListItem = ({ status, children }: CheckListItemProps) => {
  const circle = (
    <div className="mr2" style={{ height: 20, width: 20 }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="white" stroke="#637381" strokeWidth="2" />
      </svg>
    </div>
  );
  const fail = (
    <div className="mr2" style={{ height: 20, width: 20 }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="white" stroke="#CF5261" strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6876 6.31242C14.1041 6.72898 14.1041 7.40435 13.6876 7.82091L7.82091 13.6876C7.40435 14.1041 6.72898 14.1041 6.31242 13.6876C5.89586 13.271 5.89586 12.5956 6.31242 12.1791L12.1791 6.31242C12.5956 5.89586 13.271 5.89586 13.6876 6.31242Z"
          fill="#CF5261"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.31242 6.31242C6.72898 5.89586 7.40435 5.89586 7.82091 6.31242L13.6876 12.1791C14.1041 12.5956 14.1041 13.271 13.6876 13.6876C13.271 14.1041 12.5956 14.1041 12.1791 13.6876L6.31242 7.82091C5.89586 7.40435 5.89586 6.72898 6.31242 6.31242Z"
          fill="#CF5261"
        />
      </svg>
    </div>
  );
  const inactive = (
    <div className="mr2" style={{ height: 20, width: 20 }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="#97a6b6" stroke="#637381" strokeWidth="2" />
      </svg>
    </div>
  );
  const inactiveSuccess = (
    <div className="mr2" style={{ height: 20, width: 20 }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="#97a6b6" stroke="#637381" strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.697 6.31242C15.101 6.72898 15.101 7.40435 14.697 7.82091L9.0076 13.6876C8.60363 14.1041 7.94867 14.1041 7.5447 13.6876L5.30299 11.3761C4.89901 10.9596 4.899 10.2842 5.30297 9.86764C5.70693 9.45107 6.36191 9.45106 6.76589 9.86761L8.27613 11.4249L13.2341 6.31242C13.6381 5.89586 14.293 5.89586 14.697 6.31242Z"
          fill="white"
        />
      </svg>
    </div>
  );
  const success = (
    <div className="mr2" style={{ height: 20, width: 20 }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#00A35E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.697 6.31242C15.101 6.72898 15.101 7.40435 14.697 7.82091L9.0076 13.6876C8.60363 14.1041 7.94867 14.1041 7.5447 13.6876L5.30299 11.3761C4.89901 10.9596 4.899 10.2842 5.30297 9.86764C5.70693 9.45107 6.36191 9.45106 6.76589 9.86761L8.27613 11.4249L13.2341 6.31242C13.6381 5.89586 14.293 5.89586 14.697 6.31242Z"
          fill="white"
        />
      </svg>
    </div>
  );

  const componentStatusMap = {
    [CheckListStatus.idle]: circle,
    [CheckListStatus.inactive]: inactive,
    [CheckListStatus.fail]: fail,
    [CheckListStatus.inactiveSuccess]: inactiveSuccess,
    [CheckListStatus.success]: success,
  };

  return (
    <div className={'flex flex-row'}>
      {componentStatusMap[status || CheckListStatus.idle]}
      <div>{children}</div>
    </div>
  );
};

export default CheckListItem;
