import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import {
  AnalysisActions,
  type AnalysisOptions,
  type AnalysisRow,
} from '@/components/Studies/Groups/Analysis/Analysis.util';
import { Cell } from '@/components/Studies/Groups/Analysis/Cell';
import Table from '@/components/UI/Table';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { _isNil, _notNil } from '@/littledash';
import type { StudyApiId } from '@/model/Study.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { Dispatch, type FC, useEffect, useMemo } from 'react';

interface AnalysisTableProps {
  studyApiId: StudyApiId;
  options: AnalysisOptions;
  dispatch: Dispatch<AnalysisActions>;
}

export const AnalysisTable: FC<AnalysisTableProps> = ({ studyApiId, options, dispatch }) => {
  const { loading, response, invoke } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/analysis/generate',
    invokeOnInit: false,
  });
  useEffect(() => {
    if (_notNil(studyApiId) && _notNil(options)) {
      invoke({
        path: { studyApiId },
        body: options,
      })
        .then((data) => {
          dispatch({
            type: 'update-analysis-export-id',
            data: data.body?.export_snapshot_api_id,
          });
        })
        .catch(() => undefined);
    }
  }, [studyApiId, options]);

  const columns = useMemo<Columns<AnalysisRow>>(() => {
    if (response?.type === 'success') {
      const tableType = response.body.type;
      return response.body.columns.map((c) => ({
        id: c.title,
        accessor: c.accessor,
        Header: c.title,
        Cell: ({ row: { original } }) => (
          <Cell
            tableType={tableType}
            studyApiId={studyApiId}
            columnType={c.type}
            accessor={c.accessor as keyof AnalysisRow}
            data={original}
          />
        ),
      }));
    }
    return [];
  }, [studyApiId, response]);

  if (loading || _isNil(response)) {
    return <Loading txt="Loading analysis" />;
  }

  if (response.type === 'error') {
    return (
      <div className="flex justify-center" data-test-component="AnalysisTable" data-test-element="container">
        <ApiErrorBanner
          title="There was a problem fetching analysis data"
          errorType={ApiErrorType.FETCH}
          error={response.error}
        />
      </div>
    );
  }
  return <Table columns={columns} data={response.body?.rows ?? []} />;
};
