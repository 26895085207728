// @ts-nocheck: converted from JS

import MenuContainer from './MenuContainer';
import SearchSelect from '@/components/UI/SearchSelect';
import Button from '@/components/UI/Button';
import { useTableContext } from '@/components/UI/Table/TableContext';
import { _isNotEmpty } from '@/littledash';
import MetricChangeFrom from '@/components/UI/MetricChangeFrom';
import { saveTableSettings } from '@/components/UI/Table/Reusable/APITable/APITable.utils';

const UseTableShowMenu = ({
  closeMenu,
  settings,
  showMetricChangeFrom = false,
  tableName,
  hasSelectorColumn = false,
}) => {
  const toggleColumn = (value) => {
    const columns = settings?.tables[tableName]?.columns;

    if (_isNotEmpty(columns)) {
      const currentVal = columns[value];
      columns[value] = !currentVal;
      const updatedSettings = {
        ...settings,
        tables: {
          ...settings.tables,
          [tableName]: { ...settings.tables[tableName], columns },
        },
      };
      saveTableSettings(updatedSettings);
    }
    return toggleHideColumn(value);
  };

  const toggleAll = (add) => {
    const columns = settings?.tables[tableName]?.columns;
    if (_isNotEmpty(columns)) {
      Object.keys(columns).forEach((k) => {
        if (k !== 'actions') {
          columns[k] = add;
        }
      });
      const updatedSettings = {
        ...settings,
        tables: {
          ...settings.tables,
          [tableName]: { ...settings.tables[tableName], columns },
        },
      };
      saveTableSettings(updatedSettings);
    }

    toggleHideAllColumns(!add);
    if (hasSelectorColumn) {
      toggleHideColumn('selection', false);
    }
  };
  const { allColumns, visibleColumns, toggleHideColumn, toggleHideAllColumns } = useTableContext();
  const byUnselectableColumns = ({ id }) => id !== 'selection' && id !== 'actions';
  return (
    <MenuContainer width={300}>
      {showMetricChangeFrom && <MetricChangeFrom settings={settings} />}
      <SearchSelect
        sections={[
          {
            title: 'All fields',
            items: allColumns.filter(byUnselectableColumns).map(({ id, Header }) => ({
              id,
              title: Header,
            })),
          },
        ]}
        overflowHeight={300}
        selected={visibleColumns.filter(byUnselectableColumns).map((col) => col.id)}
        onSelect={({ value }) => toggleColumn(value)}
        onClearSelection={() => toggleAll(false)}
        onSelectAll={() => toggleAll(true)}
      />
      <div className="bt b--moon-gray pa3">
        <Button onClick={closeMenu} plain className="f6 w-100" testId="show-menu-close-button">
          Close
        </Button>
      </div>
    </MenuContainer>
  );
};

export default UseTableShowMenu;
