import { formatNumber } from '@/helpers';
import { _isNil } from '@/littledash';
import type { FC } from 'react';

export const NumberCell: FC<{ value?: number; percentage?: boolean }> = ({ value, percentage = false }) => {
  if (_isNil(value) || !Number.isFinite(value)) {
    return '-';
  }
  const numberToFormat = value * (percentage ? 100 : 1);
  if (!Number.isFinite(numberToFormat)) {
    return '-';
  }
  const formattedNumber = formatNumber(value * (percentage ? 100 : 1));
  if (Number.isNaN(formattedNumber)) {
    return '-';
  }
  return (
    <>
      <span>{formattedNumber}</span>
      {percentage && <span>%</span>}
    </>
  );
};
