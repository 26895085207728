// @ts-nocheck: converted from JS

import TagsInput from 'react-tagsinput';
import Icon from '@/components/UI/Icon';
import './TagSearch.scss';
import { ADDITIONAL_ENTRY_KEYS } from '../TagInput/TagInput';

const SEARCH_TAG_LIMIT = 5;

const TagSearch = ({ handleSearchInput, searchQuery, searchPlaceholderText, style, className }) => (
  <div style={style} className={`${className} relative`}>
    <span className="absolute z-5" style={{ top: '13px', left: '10px' }}>
      <Icon icon="search" width="24" height="24" />
    </span>
    <TagsInput
      className="ui__tag-search-bar w-100 relative ba b--gray bg-white"
      value={searchQuery}
      onlyUnique={true}
      onChange={handleSearchInput}
      inputProps={{ placeholder: searchQuery.length < 5 && searchPlaceholderText }}
      addKeys={ADDITIONAL_ENTRY_KEYS}
      maxTags={SEARCH_TAG_LIMIT}
    />
  </div>
);

export default TagSearch;
