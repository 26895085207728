// @ts-nocheck: converted from JS

export const Status = {
  awaiting_approval: 'awaiting_approval',
  in_progress: 'in_progress',
  rejected: 'rejected',
  fail: 'fail',
  idle: 'idle',
  approved: 'approved',
  success: 'success',
  not_submitted: 'not_submitted',
  pending: 'pending',
  inactive: 'inactive',
  inactiveSuccess: 'inactiveSuccess',
};

export const statusToTextMap = {
  [Status.awaiting_approval]: 'Awaiting review',
  [Status.in_progress]: 'In progress',
  [Status.idle]: 'In progress',
  [Status.rejected]: 'Rejected',
  [Status.fail]: 'Rejected',
  [Status.approved]: 'Completed',
  [Status.success]: 'Completed',
  [Status.not_submitted]: 'Not submitted',
  [Status.pending]: 'Pending',
};

export const ReviewActions = {
  approve: 'approve',
  reject: 'reject',
};

export const reviewActionToTextMap = {
  [ReviewActions.approve]: 'complete',
  [ReviewActions.reject]: 'reject',
};
