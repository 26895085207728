import Select from '@/components/UI/Select/Select';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { StudyCode } from '@/model/StudyCode.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import type { SingleValue } from 'react-select';

export interface StudyCodeOptionType {
  label: string;
  value: StudyCode;
}

interface StudyCodeLookupProps {
  updateStudyCode: (code: StudyCode) => void;
  settings?: boolean;
}

export const StudyCodeLookup: React.FC<StudyCodeLookupProps> = ({ updateStudyCode, settings }) => {
  const [studyCodeOptions, setStudyCodeOptions] = useState<Array<StudyCodeOptionType>>([]);
  const { control } = useFormContext();
  const codeWatch = useWatch<StudyCode>({
    control,
    name: 'study_code',
  });

  const { response } = useApiHook({
    endpoint: 'GET /api/v1/study-codes',
  });

  useEffect(() => {
    if (response?.type === 'success') {
      if (response.body.length > 1) {
        setStudyCodeOptions(
          response.body.map((code) => ({
            label: code.title,
            value: code,
          }))
        );
      } else if (response.body.length === 1) {
        updateStudyCode(response.body[0]);
        control.setValue('study_code', response.body[0]);
      }
    }
  }, [response]);

  return !settings && _isNotEmpty(studyCodeOptions) ? (
    <Controller
      name="study_code"
      control={control}
      defaultValue={codeWatch}
      rules={{ required: studyCodeOptions.length > 0 ? 'Study code is required' : false }}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div>
          <>
            <div>
              <label>Select a Study Code</label>
            </div>
            <Select
              onChange={(value: SingleValue<StudyCodeOptionType>) => {
                onChange(value?.value);
                if (_notNil(value)) {
                  updateStudyCode(value.value);
                }
              }}
              options={studyCodeOptions}
              value={value ? { value, label: value.title } : null}
              isMulti={false}
            />
            <ErrorMessage name="study_code" render={({ message }) => <p className="f6 red db pt2">{message}</p>} />
          </>
          {_notNil(codeWatch) ? (
            <div className="bg-near-white w-50 pa2 mt2">
              {'Template: '}
              {codeWatch?.composites
                .sort((comp_a: any, comp_b: any) => comp_a.order - comp_b.order)
                .reduce((acc: any, code: any) => acc + code.value, '')}
            </div>
          ) : null}
        </div>
      )}
    />
  ) : null;
};
