import { _notNil } from '@/littledash';
import ReactPortal from '@/support/HOCs/ReactPortal';
import type { FC } from 'react';
import { forwardRef, memo, useMemo, useRef, useState } from 'react';
import { EMPTY_RECT, getMenuPosition } from '../Dropdown/Dropdown.utils';
import type { TooltipBubbleProps, TooltipElementProps, TooltipProps } from './Tooltip.model';
import styles from './Tooltip.module.scss';

const Tooltip: FC<TooltipProps> = ({ children, render }) => {
  const elementWrapperRef = useRef<HTMLDivElement>(null);
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const [bubbleRef, setBubbleRef] = useState<HTMLDivElement | null>(null);

  const bubblePosition = useMemo(
    () =>
      getMenuPosition({
        toggleElementDOMRect: elementWrapperRef?.current?.getBoundingClientRect() ?? EMPTY_RECT,
        window,
        dropdownElementDOMRect: bubbleRef?.getBoundingClientRect() ?? EMPTY_RECT,
      }),
    [elementWrapperRef, bubbleRef, window]
  );

  return (
    <>
      {_notNil(render) ? (
        <span
          data-testid="tooltip-wrapper"
          onMouseEnter={() => setTooltipActive(true)}
          onMouseLeave={() => setTooltipActive(false)}
        >
          <TooltipElement ref={elementWrapperRef}>{children}</TooltipElement>
          {tooltipActive && (
            <ReactPortal wrapperId="tooltip-portal-wrapper">
              <TooltipBubble ref={setBubbleRef} style={bubblePosition?.styles}>
                <div className="relative">
                  <div data-arrow-align={`${bubblePosition.arrowOrientation.y}-${bubblePosition.arrowOrientation.x}`} />
                  <div>{render}</div>
                </div>
              </TooltipBubble>
            </ReactPortal>
          )}
        </span>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const TooltipBubble = forwardRef<HTMLDivElement, TooltipBubbleProps>(({ style, className = '', children }, ref) => (
  <div
    ref={ref}
    style={{ ...style }}
    data-testid="tooltip-bubble"
    className={`${styles['tooltip-bubble']} ${className}`}
  >
    {children}
  </div>
));

const TooltipElement = memo(
  forwardRef<HTMLSpanElement, TooltipElementProps>(({ children }, ref) => (
    <span ref={ref} data-testid="tooltip-element-wrapper">
      {children}
    </span>
  ))
);

export default Tooltip;
