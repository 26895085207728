// @ts-nocheck: converted from JS

import type { Properties } from 'csstype';
import React, { createContext, FC, HTMLAttributes, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import useConstant from 'use-constant';
import './Tabs.scss';

const TabsState = createContext();
const Elements = createContext();

interface TabsProps {
  state?: number;
  outerState?: Array<unknown>;
  children: ReactNode;
}

export const Tabs: FC<TabsProps> = ({ state: initialState = 0, outerState, children }) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState(initialState);
  }, [initialState]);
  const elements = { tabs: 0, panels: 0 };
  const value = outerState ?? [state, setState];

  return (
    <Elements.Provider value={elements}>
      <TabsState.Provider value={value}>{children}</TabsState.Provider>
    </Elements.Provider>
  );
};

const useTabState = () => {
  const [activeIndex, setActive] = useContext(TabsState);
  const elements = useContext(Elements);

  const tabIndex = useConstant(() => {
    const currentIndex = elements.tabs;
    elements.tabs += 1;

    return currentIndex;
  });

  const onClick = () => setActive(tabIndex);

  const state = useMemo(
    () => ({
      isActive: activeIndex === tabIndex,
      onClick,
    }),
    [activeIndex, onClick, tabIndex]
  );

  return state;
};

const usePanelState = () => {
  const [activeIndex] = useContext(TabsState);
  const elements = useContext(Elements);

  const panelIndex = useConstant(() => {
    const currentIndex = elements.panels;
    elements.panels += 1;

    return currentIndex;
  });

  return panelIndex === activeIndex;
};

interface TabProps {
  children: ReactNode;
  className?: string;
  counter?: number;
  plain?: boolean;
  disableNavigation?: boolean;
  activeClass?: string;
  testId?: string;
}

export const Tab: React.FC<TabProps> = ({
  children,
  className,
  counter,
  plain,
  disableNavigation,
  activeClass,
  testId,
}) => {
  const { onClick, isActive } = useTabState();

  return (
    <StatelessTab
      className={className}
      counter={counter}
      plain={plain}
      activeClass={activeClass}
      isActive={isActive}
      testId={testId}
      {...(disableNavigation ? {} : { onClick })}
    >
      {children}
    </StatelessTab>
  );
};

const baseClasses = 'lh-title tab-list-item dib mr4 pb3 relative';

interface StatelessTabProps extends TabProps {
  isActive: boolean;
  onClick?: () => any;
}

export const StatelessTab: FC<StatelessTabProps> = ({
  children,
  className,
  counter,
  plain,
  activeClass,
  isActive,
  onClick,
  testId,
}) => {
  const activeClassNames = activeClass || 'blue tab-list-active';

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  let classes = plain ? className : `${baseClasses} ${className}`;

  if (isActive) {
    classes = `${classes} ${activeClassNames}`;
  }

  return (
    <div
      className={classes}
      onClick={handleClick}
      data-test-component="StatelessTab"
      data-test-element="container"
      data-testid={testId}
    >
      {children}
      {!!counter && (
        <div className={'ui__counter f6 near-black bg-silver basier-med br2 tc dib v-mid ml2'}>
          <span data-test-element="counter">{counter}</span>
        </div>
      )}
    </div>
  );
};

interface PanelProps {
  children: ReactNode;
  className?: string;
  style?: Properties;
  unmountOnInactive?: boolean;
}

export const Panel: FC<PanelProps> = ({ children, className, style, unmountOnInactive = true }) => {
  const isActive = usePanelState();
  const renderProps: HTMLAttributes<HTMLDivElement> = {
    className: 'tab-content',
  };

  if (className) {
    renderProps.className = renderProps.className + ` ${className}`;
  }

  if (style) {
    renderProps.style = style;
  }

  if (!isActive && !unmountOnInactive) {
    const hiddenStyle = { display: 'none' };
    renderProps.style = renderProps.style ? { ...renderProps.style, ...hiddenStyle } : hiddenStyle;
  }

  return unmountOnInactive && !isActive ? null : <div {...renderProps}>{children}</div>;
};
