// @ts-nocheck: converted from JS

import SubHeader from '@/components/UI/SubHeader';
import { defaultPromiseErrorHandler } from '@/helpers';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../Loading';
import CreateTemplateForm from './CreateTemplateForm';

const CreateTemplate = () => {
  const [group, setGroup] = React.useState(false);
  const [templateLoading, setTemplateLoading] = React.useState(false);

  const params = useParams();
  const history = useHistory();

  const callBackFn = () => history.replace(webRoute('tasks.templates'));

  useEffect(() => {
    if (params.template) {
      setTemplateLoading(true);
      Http.get(
        apiRoute('tasks.templateGroups.show-item', {
          id: params.template,
        })
      )
        .then(({ data: { data } }) => {
          setGroup(data);
        })
        .catch(defaultPromiseErrorHandler)
        .finally(() => {
          setTemplateLoading(false);
        });
    }
  }, [params.template]);

  if (templateLoading) {
    return <Loading />;
  }

  return (
    <div>
      <SubHeader linkToText="Templates" link={webRoute('tasks.templates')} />
      <div className="pa3 pa4-l">
        <CreateTemplateForm callBackFn={callBackFn} group={group} />
      </div>
    </div>
  );
};

export default CreateTemplate;
