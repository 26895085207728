import { uuid } from '@/littledash';
import type { DataTableSavingEventDetail } from '../DataTable.model';
import { DataTableEvent } from '../DataTable.model';

export class DataTableStateService {
  #previousSaving = false;
  #activeOperations = new Set<string>();

  constructor(private readonly eventEmitter: EventTarget) {}

  #addOperation(id: string) {
    this.#activeOperations.add(id);
    this.#scheduleCheck();
  }

  #removeOperation(id: string) {
    this.#activeOperations.delete(id);
    this.#scheduleCheck();
  }

  #scheduleCheck() {
    window.requestAnimationFrame(() => {
      const saving = this.#activeOperations.size > 0;
      if (this.#previousSaving !== saving) {
        this.#previousSaving = saving;
        this.eventEmitter.dispatchEvent(
          new CustomEvent<DataTableSavingEventDetail>(DataTableEvent.TableSaving, {
            detail: { saving },
          })
        );
      }
    });
  }

  wrapOperation<T>(operation: Promise<T>): Promise<T> {
    const operationId = uuid();
    this.#addOperation(operationId);
    return operation.finally(() => this.#removeOperation(operationId));
  }

  get working(): boolean {
    return this.#activeOperations.size > 0;
  }
}
