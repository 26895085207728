// @ts-nocheck: converted from JS

import { Controller, useFormContext } from 'react-hook-form@latest';
import Switch from '@/components/UI/FormElements/Switch';
import { ErrorMessage } from '@hookform/error-message';
import type { FormValues } from '../../SingleSignOn/SingleSignOn';
import { _isEmpty } from '@/littledash.ts';
import { useMemo } from 'react';

const Okta = (): JSX.Element => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useFormContext<FormValues>();

  const initialClientId = useMemo(() => getValues('okta.client_id') || '', []);

  return (
    <div className="ph3">
      <div className="mb3">
        <label>Enable Okta SSO</label>
        <Controller
          name="okta.enabled"
          defaultValue={false}
          control={control}
          render={({ field: { value, onChange } }) => <Switch value={value} onChange={onChange} />}
        />
      </div>
      <div className="mb3">
        <label htmlFor="okta.domain">Domain</label>
        <input
          {...register('okta.domain', { required: 'This field is required' })}
          type="text"
          style={{ marginBottom: 0 }}
        />
        <ErrorMessage
          errors={errors}
          name="okta.domain"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
      <div className="mb3">
        <label htmlFor="okta.client_id">Client ID</label>
        <input
          {...register('okta.client_id', { required: 'This field is required' })}
          type="text"
          style={{ marginBottom: 0 }}
        />
        <ErrorMessage
          errors={errors}
          name="okta.client_id"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
      <div className="mb3">
        <label htmlFor="okta.client_secret">Client secret</label>
        <input
          type="password"
          {...register('okta.client_secret', { required: 'This field is required' })}
          placeholder={_isEmpty(initialClientId) ? '' : '✳︎'.repeat(40)}
          style={{ marginBottom: 0 }}
        />
        <ErrorMessage
          errors={errors}
          name="okta.client_secret"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
    </div>
  );
};

export default Okta;
