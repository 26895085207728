// @ts-nocheck: converted from JS

import { successToast } from '@/helpers';
import { Plotly } from '@/support/plotly';
import { DateUtils } from '@/utils/Date.utils';

const DownloadGraph = ({ studyName, graphType }) => {
  const GraphTypes = {
    GROUPS_LINE: 'Study groups',
    SPIDER: 'Spider plot',
    SURVIVAL: 'Survival curve',
    DAILY: 'Daily plot',
  };

  const openFlash = (txt) => {
    successToast(txt);
  };

  const downloadGraphFile = (e, studyName, graphType, openFlash) => {
    e.preventDefault();
    const ele = document.getElementsByClassName('js-plotly-plot');
    const config = {
      filename: `Benchling In Vivo ${graphType} - ${studyName} - ${DateUtils.renderDate(DateUtils.dateNow())}`,
      scale: 2,
      width: 1280,
      height: 720,
    };

    return Plotly.downloadImage(ele[0], config)
      .then((file) => openFlash(`Saved ${file}`))
      .catch((error) => openFlash(error));
  };

  return (
    <a
      className="link f6 db"
      style={{ whiteSpace: 'nowrap' }}
      onClick={(e) => downloadGraphFile(e, studyName, GraphTypes[graphType], openFlash)}
    >
      Download as image
    </a>
  );
};

export default DownloadGraph;
