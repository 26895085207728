import { isActive, mapFormDataToAssignees } from '@/components/Glossary/Sections/Requests/Create/Assignees.utils';
import SearchSelect from '@/components/UI/SearchSelect';
import UserCard from '@/components/UserCard/UserCard';
import { missingNameUseEmail } from '@/helpers';
import { _isEmpty, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { Request } from '@/model/Request.model';
import type { Team } from '@/model/Team.model';
import type { MainUser } from '@/model/User.model';
import { ErrorMessage } from '@hookform/error-message';
import React, { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface AssigneesProps {
  ActionButtons: JSX.Element;
  onSubmit: (assignees: unknown) => void;
  request: Request & { users: Array<ID> };
}

const Assignees = ({ ActionButtons, onSubmit, request }: AssigneesProps) => {
  const { team } = useSelector(({ team: { team } }: { team: { team: Team } }) => ({
    team,
  }));

  const formMethods = useForm({
    defaultValues: {
      assignees: request?.users || [],
      inactiveAssignees: request?.users || [],
    },
  });
  const { handleSubmit, errors, control } = formMethods;

  const assignees = useWatch<Array<ID>>({
    control,
    name: 'assignees',
  });

  const users =
    team?.users
      ?.filter((user) => isActive(user) || (request.users?.includes(user.id) && assignees?.includes(user.id)))
      .map(missingNameUseEmail) ?? [];

  const usersLookup = users?.reduce<Record<ID, MainUser>>((acc, user) => {
    acc[Number(user.id)] = user;
    return acc;
  }, {});

  const sections = useMemo(
    () => [
      {
        items: users,
      },
    ],
    [team, users]
  );

  const handleOrderingOnSubmit = (formData: { assignees: Array<ID> }) => {
    onSubmit(mapFormDataToAssignees(formData));
  };

  const canSubmit =
    _notNil(assignees) && assignees.length > 0 && assignees.every((userId: any) => isActive(usersLookup[userId]));

  return (
    <FormProvider {...formMethods}>
      <div data-test-component="Assignees" data-test-element="container">
        <div className="flex flex-wrap">
          <div className="ui-card w-third" data-test-element="search-select-container">
            <Controller
              name="assignees"
              control={control}
              rules={{
                required: true,
                validate: (data: any) => {
                  if (!Array.isArray(data) || !data.length) {
                    return 'You must select at least one assignee';
                  }
                },
              }}
              render={({ value, onChange }: ControllerRenderProps) => (
                <SearchSelect
                  className={`${errors.assignees ? 'search_select__error' : ''}`}
                  sections={sections}
                  selected={value}
                  setSelected={onChange}
                  sort
                  sortAccessor={'name'}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="assignees"
              render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
            />
          </div>
          {!_isEmpty(assignees) && (
            <div className="ui-card w-third ph3 mh3 pv2" style={{ minWidth: 350 }}>
              <AssigneeList assignees={users.filter((u) => assignees?.includes(u.id as number))} />
            </div>
          )}
        </div>

        {React.cloneElement(ActionButtons, {
          onOk: handleSubmit(handleOrderingOnSubmit),
          tooltip: canSubmit ? '' : 'There are inactive users selected',
          canOk: canSubmit,
        })}
      </div>
    </FormProvider>
  );
};

interface AssigneeListProps {
  assignees: Array<MainUser>;
}
const AssigneeList = ({ assignees = [] }: AssigneeListProps) => (
  <div>
    {assignees.map((user, index) => (
      <div key={user.id} className={`b--moon-gray ${index !== 0 ? 'bt' : ''}`}>
        <UserCard name={user.name} email={user.email} key={index} inactive={!isActive(user)} />
      </div>
    ))}
  </div>
);

export default Assignees;
