import { SetStateAction } from 'react';
import type { Dispatch } from 'redux';
import { RequiredMetadataFieldWithValue } from '@/components/Metadata/Fields';
import { StudyFormSection } from '@/model/StudyForm.model';
import { _notNil } from '@/littledash';

export const addMetadataFields = (
  newFields: Array<RequiredMetadataFieldWithValue>,
  metadataSections: Array<StudyFormSection>,
  setMetadataSections: (value: SetStateAction<Array<StudyFormSection>>) => void,
  setNewMetadataFields: (value: SetStateAction<Array<RequiredMetadataFieldWithValue>>) => void,
  dispatch: Dispatch
) => {
  const allMetaFields = metadataSections.reduce<Array<RequiredMetadataFieldWithValue>>((acc, section) => {
    section.metadataFields?.forEach((field) => {
      if (_notNil(field?.value)) {
        acc.push(field);
      }
    });
    return acc;
  }, []);
  const unsectionedMetadata = metadataSections.find((section) => section.name === 'Metadata');
  if (_notNil(unsectionedMetadata)) {
    const metaFieldsToKeep = (
      metadataSections.find((section) => section.name === 'Metadata')?.metadataFields ?? []
    ).filter((field) => _notNil(field.value));
    const fieldsToAdd = newFields.filter(
      (newField) => !allMetaFields?.find((meta) => meta?.glossary_id === newField.glossary_id)
    );
    unsectionedMetadata.metadataFields = [...metaFieldsToKeep, ...fieldsToAdd];
    setMetadataSections(metadataSections);
    setNewMetadataFields(newFields);
  }
  dispatch({
    type: 'CLOSE_MODAL',
  });
};
