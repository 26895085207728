import type { DataTableCreateV1 } from '@/model/DataTable.model';
import type { DataTableFormValues } from './Show';

export const handlePayload = (formData: DataTableFormValues): DataTableCreateV1 => {
  const {
    name,
    type,
    timeType,
    animals,
    timeStart,
    timeStartUnit,
    timeEvery,
    timeEveryUnit,
    timeDuration,
    timeDurationUnit,
    manualTimepoints,
    unit,
  } = formData;
  let payload: DataTableCreateV1 = {
    name,
    type,
    animals,
    unit,
    columns: null,
  };
  if (type === 'time') {
    if (timeType === 'automatic') {
      payload = {
        ...payload,
        columns: null,
        unit: `PT${timeStart}${timeStartUnit}/PT${timeEvery}${timeEveryUnit}/PT${timeDuration}${timeDurationUnit}`,
      };
    } else {
      payload = {
        ...payload,
        unit: null,
        columns: manualTimepoints.map(({ timepoint, unit }) => `PT${timepoint}${unit}`),
      };
    }
  } else if (type === 'custom') {
    payload = {
      ...payload,
      unit: null,
    };
  }
  return payload;
};
