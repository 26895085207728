import type {
  DataTableService,
  DataTableWorkflow,
  DataTableWorkflowActivatedEvent,
} from '@/components/UI/DataTable/DataTable.model';
import { DataTableEvent } from '@/components/UI/DataTable/DataTable.model';
import Icon from '@/components/UI/Icon';
import { classNames } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalAction } from '@/utils/modal';
import styles from './DataTableWorkflowMenuItem.module.scss';

interface DataTableWorkflowMenuItemProps {
  datatableService: DataTableService;
  disabled?: boolean;
}

export const DataTableWorkflowMenuItem: FC<DataTableWorkflowMenuItemProps> = ({
  datatableService,
  disabled = false,
}) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const [workflowActive, setWorkflowActive] = useState(datatableService?.workflowService.workflowActive ?? false);

  useEffect(() => {
    const workflowActivatedListener = ({ detail: { active } }: DataTableWorkflowActivatedEvent) => {
      setWorkflowActive(active);
    };

    datatableService?.subscribe(DataTableEvent.WorkflowActivated, workflowActivatedListener);
    return () => {
      datatableService?.unsubscribe(DataTableEvent.WorkflowActivated, workflowActivatedListener);
    };
  }, [datatableService, setWorkflowActive]);
  const handleClick = () => {
    if (!disabled) {
      openModal('DTL_WORKFLOW_SETUP', {
        columns: datatableService.columns,
        workflow: datatableService.workflowService.workflow,
        flags: datatableService.featureFlags,
        onWorkflowSave: (workflow: DataTableWorkflow) => {
          if (_isNotEmpty(workflow)) {
            const isNextAnimal = workflow.at(0)?.source === 'next-animal';
            datatableService.workflowService.loadWorkflow(workflow);
            datatableService.workflowService.activateWorkflow(true);
            datatableService.workflowService.workflowActionEnter(workflow.at(isNextAnimal ? 1 : 0)?.source);
          } else {
            datatableService.workflowService.activateWorkflow(false);
            datatableService.workflowService.clearWorkflow();
          }
          closeModal();
        },
        onWorkflowClear: () => {
          datatableService.workflowService.clearWorkflow();
          closeModal();
        },
      });
    }
  };

  return (
    <div className="pa2 pl0" onClick={handleClick}>
      <span className={classNames(`flex ${styles.iconContainer}`, { [styles.active]: workflowActive })}>
        <Icon icon="datatable_workflow" height="20" width="20" viewBox="0 0 20 20" />
      </span>
    </div>
  );
};
