import Button from '@/components/UI/Button';
import Icon from '@/components/UI/Icon';
import type { Icon as IconType } from '@/constants/Icons';
import { hasOwnProperty } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import type { ExistingMeasurement } from '@/model/Measurement.model';
import { idFields, ImportRowMatch, MatchData, ValidationState } from '../../Importer.utils';

const icons: Record<string, string> = {
  success: 'green',
  warning: 'yellow',
  critical: 'red',
};

interface MatchConfirmProps {
  col: string;
  match: ImportRowMatch;
  matchData: Record<string, MatchData>;
  fileData: Array<Record<string, string | number>>;
  toggleIgnore: (col: string) => void;
  confirmed: boolean;
  toggleConfirm: (col: string) => void;
  existingMeasurements: Array<ExistingMeasurement>;
  validationState: ValidationState;
}

const MatchConfirm: React.FC<MatchConfirmProps> = ({
  col,
  match,
  matchData,
  fileData,
  toggleIgnore,
  confirmed,
  toggleConfirm,
  existingMeasurements,
  validationState,
}) => {
  const emptyRows = fileData.filter((f) => !hasOwnProperty(f, col));
  const matchDataRow = matchData[col];
  const existingMeasurementsForMatch = existingMeasurements.filter((f) => {
    const variableNames = f.variables.map((v) => v.name);
    return variableNames.includes(matchDataRow.matched);
  });
  const { idFieldConfirmed, cageConfirmed, animalsHaveUniqueId } = validationState;
  const matchedName = matchData[col]?.matched ?? match?.name ?? '';
  const isIdField = idFields.some(({ key }) => key === matchedName && matchedName !== 'collection_name');
  const confirmDisabled = isIdField ? idFieldConfirmed || !matchDataRow.matched : !matchDataRow.matched;

  return (
    <div className="ba b--dashed pa3">
      {!confirmed ? (
        <>
          <ul className="list pb3">
            {isIdField && idFieldConfirmed ? (
              <ListItem icon="warning">
                <p className="near-black lh-copy v-mid f6">
                  An identifier has already been matched, please ignore this column
                </p>
              </ListItem>
            ) : (
              <>
                <ListItem icon={_isNotEmpty(match) ? 'success' : 'warning'}>
                  <div className="near-black lh-copy v-mid f6">
                    {_isNotEmpty(match) ? (
                      <>
                        Automatically matched to the{' '}
                        <code className="pv1 ph2 lh-solid normal br1 bg-light-silver blue">{match.displayName}</code>{' '}
                        field
                      </>
                    ) : (
                      'Unable to automatically match to a field'
                    )}
                  </div>
                </ListItem>
                <ListItem icon={_isEmpty(emptyRows) ? 'success' : 'warning'}>
                  <div className="near-black lh-copy v-mid f6">
                    {Math.floor(((fileData.length - emptyRows.length) * 100) / fileData.length)}% of your rows have a
                    value for this column
                  </div>
                </ListItem>
                {_isNotEmpty(existingMeasurementsForMatch) && (
                  <ListItem icon={'warning'}>
                    <div className="near-black lh-copy v-mid f6">
                      We&apos;ve detected existing data recorded on this day. Imported data will overwrite the existing
                      record. This action can not be undone.
                    </div>
                  </ListItem>
                )}
              </>
            )}
          </ul>
          <div>
            <Button disabled={confirmDisabled} onClick={() => toggleConfirm(col)} className="mr3 f6">
              Confirm
            </Button>
            <Button plain onClick={() => toggleIgnore(col)} className="f6">
              Ignore column
            </Button>
          </div>
        </>
      ) : (
        <div className="flex flex-wrap w-100 justify-between items-center">
          <ul className="list w-80">
            <ListItem icon="success">
              <p className="near-black lh-copy v-mid green basier-med f6">Confirmed mapping</p>
            </ListItem>
            {isIdField && !animalsHaveUniqueId && !cageConfirmed && (
              <ListItem icon="warning">
                <p className="near-black lh-copy v-mid f6">
                  This ID is not unique for all animals, please confirm a cage field to proceed.
                </p>
              </ListItem>
            )}
          </ul>
          <div>
            <Button plain onClick={() => toggleConfirm(col)}>
              Edit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

interface ListItemProps {
  icon: IconType;
  children: React.ReactNode;
}

const ListItem: React.FC<ListItemProps> = ({ icon, children }) => (
  <li className="mb2">
    <Icon icon={icon} viewBox={'0 0 22 22'} width="20" height="20" className={`mr3 v-mid ${icons[icon]}`} />
    <div className="dib v-mid lh-copy w-80">{children}</div>
  </li>
);

export default MatchConfirm;
