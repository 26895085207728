// @ts-nocheck: converted from JS

import { useState } from 'react';

export const useSteps = (initialSteps, initialActiveStep = 1) => {
  const [steps, setSteps] = useState(initialSteps);
  // activeStep is NOT zero indexed, starts at 1 to align with other components.
  const [activeStep, setActiveStep] = useState(initialActiveStep);

  const determinePrevStep = () => (activeStep > 1 ? activeStep - 1 : activeStep);
  const determineNextStep = () => (activeStep < steps.length ? activeStep + 1 : activeStep);

  const nextStep = () => {
    const newSteps = steps.map((step, i) =>
      i === activeStep - 1
        ? {
            ...step,
            complete: true,
          }
        : step
    );
    setSteps(newSteps);
    setActiveStep(determineNextStep());
  };

  const backStep = () => {
    setActiveStep(determinePrevStep());
  };

  const reset = () => {
    setSteps([...initialSteps]);
    setActiveStep(initialActiveStep);
  };

  return {
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    nextStep,
    backStep,
    reset,
  };
};
