import { errorToast, warningToast } from '@/helpers.tsx';
import { _noop, _notNil, getJwtExpiry } from '@/littledash.ts';
import { PlatformLoginRequest, PlatformLoginResponse, TeamSummary, UserSummary } from '@/model/Auth.model.ts';
import type { JwtPayload } from '@/model/Common.model.ts';
import InVivoError from '@/model/InVivoError.ts';
import { ApiService } from '@/support/ApiService.ts';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController.ts';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import Http from '@/support/http.ts';
import { web as webRoute } from '@/support/route.ts';
import { RouteService } from '@/support/RouteService.ts';
import { ApplicationEvents } from '@/utils/ApplicationEvents.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { TeamChangeDetector } from '@/utils/TeamChangeDetector.ts';
import { Duration, intervalToDuration, isBefore } from 'date-fns';
import { Reducer, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { parse as parseDuration } from 'tinyduration';

export interface LoginState {
  sso: SsoConfig;
  redeem_loading: boolean;
  platformToken?: {
    token: string;
    expiry: Date;
  };
  platformTokenExpired: boolean;
  user?: UserSummary;
  teams: Array<TeamSummary>;
  token_valid_duration?: Duration;
}

export type SsoConfig = { enabled: boolean; type?: string };
export type LoginSubmitResult =
  | { type: 'success' }
  | { type: 'unauthorised' }
  | { type: 'cooldown'; cooldownDuration: Duration }
  | { type: 'unknown-error' };
export type LoginSubmit = (login: PlatformLoginRequest) => Promise<LoginSubmitResult>;
export type LoginActions =
  | { type: 'platform_login'; data: PlatformLoginResponse }
  | { type: 'sso_config'; data: SsoConfig }
  | { type: 'redeem_loading'; data: boolean }
  | { type: 'token_valid_duration'; data?: Duration }
  | { type: 'logout' };
export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const loginReducer: Reducer<LoginState, LoginActions> = (prevState, action) => {
  switch (action.type) {
    case 'platform_login': {
      const tokenExpiry = getJwtExpiry(action.data.token);
      const platformTokenExpired = isBefore(new Date(), tokenExpiry as Date);
      return {
        ...prevState,
        user: action.data.user,
        teams: action.data.teams,
        platformToken: {
          token: action.data.token,
          expiry: tokenExpiry as Date,
        },
        platformTokenExpired,
      };
    }
    case 'sso_config': {
      return { ...prevState, sso: action.data };
    }
    case 'redeem_loading': {
      return { ...prevState, redeem_loading: action.data };
    }
    case 'token_valid_duration': {
      return { ...prevState, token_valid_duration: action.data };
    }
    case 'logout': {
      return { sso: prevState.sso, teams: [], platformTokenExpired: false, redeem_loading: false };
    }
    default: {
      return prevState;
    }
  }
};

export const useLoginApi = () => {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string }; claims: JwtPayload }>();
  const [state, dispatch] = useReducer(loginReducer, null, () => ({
    teams: [],
    redeem_loading: false,
    platformTokenExpired: false,
    sso: { enabled: false },
  }));

  useEffect(() => {
    if (_notNil(state?.platformToken?.expiry)) {
      dispatch({
        type: 'token_valid_duration',
        data: intervalToDuration({
          start: new Date(),
          end: state?.platformToken?.expiry as Date,
        }),
      });
      const intervalId = setInterval(() => {
        const duration = intervalToDuration({
          start: new Date(),
          end: state?.platformToken?.expiry as Date,
        });
        if (Object.values(duration).every((v) => v <= 0)) {
          dispatch({ type: 'logout' });
          warningToast('Logged out due to inactivity');
        } else {
          dispatch({
            type: 'token_valid_duration',
            data: duration,
          });
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, state?.platformToken?.expiry]);

  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();
  const loadConfig = useCallback(async (): Promise<void> => {
    const ssoConfig = await Http.get(RouteService.legacyApi({ apiRoute: 'config' }).url.href, {
      signal: newAbortController().signal,
      fetchOptions: { addAuth: false },
    })
      .then((response) => {
        const ssoType = response?.data?.ssoType ?? false;
        return typeof ssoType === 'string' ? { enabled: true, type: ssoType } : { enabled: false };
      })
      .catch((cause) => {
        ExceptionHandler.captureException(new InVivoError('Failed to load SSO config', { slug: 'sso-config', cause }));
        return { enabled: false };
      });
    if (isMounted()) {
      dispatch({ type: 'sso_config', data: ssoConfig });
    }
  }, [newAbortController, isMounted]);

  const platformLogin = useCallback<LoginSubmit>(
    async (login) => {
      return ApiService.call({
        endpoint: 'POST /api/v1/auth/login',
        body: login,
        options: { onError: { throw: false, capture: false, toast: false } },
      })
        .then((response): LoginSubmitResult => {
          if (response.type === 'error') {
            switch (response.error.response?.status) {
              case 401: {
                ExceptionHandler.captureException(
                  new InVivoError('Platform credentials incorrect', {
                    slug: 'platform-credentials-incorrect',
                    level: 'log',
                    context: { code: '401' },
                  })
                );
                return { type: 'unauthorised' };
              }
              case 429: {
                // @ts-expect-error: error response typed incorrectly
                const cooldown = response.error.response?.data?.cooldown ?? 'PT1M';
                ExceptionHandler.captureException(
                  new InVivoError('Too many platorm login attempts', {
                    slug: 'too-many-platform-login-attempts',
                    level: 'log',
                    context: { code: '429', cooldown },
                  })
                );
                return { type: 'cooldown', cooldownDuration: parseDuration(cooldown) };
              }
              default:
                ExceptionHandler.captureException(
                  new InVivoError('Platform login api failure', {
                    slug: 'platform-login-api-failure',
                    context: { code: `${response.error.response?.status}` },
                  })
                );
                return { type: 'unknown-error' };
            }
          }
          if (isMounted()) {
            dispatch({ type: 'platform_login', data: response.body });
            reduxDispatch({
              type: 'USER_SET_CURRENT_USER',
              currentUser: response.body?.user,
            });
          }
          return { type: 'success' } as LoginSubmitResult;
        })
        .catch((cause): LoginSubmitResult => {
          ExceptionHandler.captureException(
            new InVivoError('Platform login api failure', { slug: 'platform-login-api-failure', cause })
          );
          return { type: 'unknown-error' };
        });
    },
    [isMounted, dispatch, reduxDispatch]
  );

  const teamLogin = useCallback(
    async (team: TeamSummary, singleTeam: boolean) => {
      return ApiService.call({
        endpoint: 'POST /api/v1/auth/team/{teamApiId}/login',
        headers: { Authorization: `Bearer ${state.platformToken?.token ?? ''}` },
        path: { teamApiId: team.api_id },
        options: { onError: { throw: true, capture: false, toast: false, slug: 'team-login' } },
      })
        .then((response) => {
          if (isMounted()) {
            if (response.type === 'success') {
              const fromPathName = location.state?.from?.pathname
                ? encodeURI(location.state?.from?.pathname)
                : undefined;
              window.localStorage.setItem('team_id', team.api_id);
              reduxDispatch({
                type: 'LOGIN',
                token: response.body.token,
              });
              TeamChangeDetector.broadcastTeamChange();
              ApplicationEvents.authenticated();
              if (singleTeam && fromPathName && fromPathName !== '/') {
                history.push(fromPathName);
              } else {
                history.push(webRoute('dashboard'));
              }
            } else {
              errorToast('Team login failed ... please try again later');
            }
          }
        })
        .catch((cause) => {
          const err = new InVivoError('Team auth failure', { cause, slug: 'team-login-failure' });
          if (cause instanceof InVivoError && cause.context?.ApiStatusCode === '401') {
            logout();
          }
          if (isMounted()) {
            errorToast('Team login failed ... please try again');
          }
          ExceptionHandler.captureException(err);
          return Promise.reject(err);
        });
    },
    [history, reduxDispatch, isMounted, state.platformToken]
  );

  const redeemShortLivedToken = useCallback(
    async (token: string) => {
      dispatch({ type: 'redeem_loading', data: true });
      return ApiService.call({
        endpoint: 'POST /api/v1/short-lived/auth/redeem',
        headers: { Authorization: `Bearer ${token}` },
        options: { onError: { throw: true, capture: false, toast: false, slug: 'slt-redeem' } },
      })
        .then((response) => {
          if (isMounted()) {
            if (response.type === 'success') {
              window.localStorage.setItem('team_id', response.body.team_token);
              reduxDispatch({
                type: 'LOGIN',
                token: response.body.bearer_token,
              });
              TeamChangeDetector.broadcastTeamChange();
              ApplicationEvents.authenticated();
              history.push(webRoute('dashboard'));
            } else {
              dispatch({ type: 'redeem_loading', data: false });
              errorToast('Authentication failed ... please try again');
            }
          }
        })
        .catch((cause) => {
          if (isMounted()) {
            dispatch({ type: 'redeem_loading', data: false });
            errorToast('Authentication failed ... please try again');
          }
          ExceptionHandler.captureException(
            new InVivoError('Short lived token redeem failure', {
              slug: 'slt-failure',
              cause,
            })
          );
        });
    },
    [reduxDispatch, history, isMounted]
  );

  const logout = useCallback(() => {
    dispatch({ type: 'logout' });
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(location?.search ?? '');
    if (params.has('identifier')) {
      redeemShortLivedToken(params.get('identifier') as string).catch(_noop);
    }
  }, [location.search, redeemShortLivedToken]);

  return {
    state,
    dispatch,
    platformLogin,
    teamLogin,
    loadConfig,
    logout,
  };
};
