import { formatNumber, isNumericString } from '@/helpers';
import type { Nullable } from '@/model/Common.model';
import { DateUtils } from '@/utils/Date.utils';
import type { FC } from 'react';

export const DataTableCellValueRenderer: FC<{ value: Nullable<string> }> = ({ value }) => {
  if (isNumericString(value)) {
    return <>{formatNumber(value)}</>;
  } else if (DateUtils.isValidDateTime(value)) {
    return <>{DateUtils.renderDateTime(value, { defaultResponse: '' })}</>;
  }
  return <>{value ?? ''}</>;
};
