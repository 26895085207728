// @ts-nocheck: converted from JS

export const observationFiltersToTitle = {
  value: 'Score',
  animal: 'Animal',
  study_group: 'Study group',
  cage: 'Cage',
  glossary_id: 'Observation',
  observed_at: 'Date',
};
