import { uuid } from '@/littledash';

export class TeamChangeDetector {
  static #sessionStorageKey = 'tab-id';
  static #instance?: BroadcastChannel;

  static get available() {
    return window.BroadcastChannel instanceof Function;
  }

  static getTabId() {
    let tabId = sessionStorage.getItem(TeamChangeDetector.#sessionStorageKey);
    if (tabId === null) {
      tabId = uuid();
      sessionStorage.setItem(TeamChangeDetector.#sessionStorageKey, tabId);
    }
    return tabId;
  }

  static instance() {
    if (TeamChangeDetector.#instance == null) {
      TeamChangeDetector.#instance = new BroadcastChannel('team-change-channel');
    }
    return TeamChangeDetector.#instance;
  }

  static broadcastTeamChange() {
    if (TeamChangeDetector.available) {
      TeamChangeDetector.instance().postMessage({ tabId: TeamChangeDetector.getTabId() });
    }
  }

  /**
   * @param callback {()=>void}
   */
  static onTeamChange(callback?: () => void): void {
    if (TeamChangeDetector.available) {
      TeamChangeDetector.instance().onmessage = (event) => {
        const tabId = event?.data?.tabId ?? null;
        if (tabId !== TeamChangeDetector.getTabId()) {
          callback?.();
        }
      };
    }
  }
}
