// @ts-nocheck: converted from JS

export const Type = {
  internal: 'internal',
  external: 'external',
  all: 'all',
};

export const matchType = {
  any: 'OR',
  all: 'AND',
};

export const typeToTextMap = {
  [Type.internal]: 'Internal',
  [Type.external]: 'External',
  [Type.all]: 'All',
};

export const studyTypes = {
  internal: 'Internal',
  external: 'External',
};

export const standardSections = {
  'cages-and-animals': 'Cages and animals',
  groups: 'Groups',
  presets: 'Presets',
  alerts: 'Alerts',
  tasks: 'Tasks',
  files: 'Files',
};
