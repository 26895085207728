// @ts-nocheck: converted from JS

export const buildCageCards = (cages) => {
  return cages
    .filter(({ isVisible }) => isVisible)
    .map((cage) => ({
      id: cage.id,
      title: cage.name,
      items: cage.subjects.data,
    }));
};
