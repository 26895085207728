// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { useFetchEntity } from '@/support/Hooks/fetch';
import React from 'react';

/**
 * @param animalId {string||number}
 * @param sampleId {string||number}
 * @returns {JSX.Element||null}
 */

const WithSample = ({ animalId, sampleId, children }) => {
  const {
    entity: sample,
    entityLoading: fetchingSample,
    entityError: sampleRequestError,
  } = useFetchEntity({
    entityType: 'animalSample',
    params: {
      animalId,
      sampleId,
    },
  });

  if (fetchingSample) {
    return (
      <div className="pa3">
        <Loading textClassName="f5" />
      </div>
    );
  }

  if (sampleRequestError) {
    return <ApiErrorBanner error={sampleRequestError} />;
  }

  return <>{React.cloneElement(children, { sample })}</>;
};

export default WithSample;
