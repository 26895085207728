// @ts-nocheck: converted from JS

import CageCard from '@/components/Cages/CageCard';
import CardColumn from '@/components/CardColumn';
import Footer from '@/components/CardColumn/Footer';
import CageCardTemplateWrapper from '@/components/Modals/PrintCageCards/CageCardTemplateWrapper';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import { errorToast } from '@/helpers';
import { _get, _isNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Cage } from '@/model/Cage.model';
import type { ID } from '@/model/Common.model';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RIEInput } from 'riek';
import * as Auth from '@/support/auth';
import { useRequest } from '@/support/Hooks/request';

interface Card {
  animalId: ID;
  id: ID;
  index: string;
  type: 'card';
}

interface CageCardGroup {
  id: ID;
  title: string;
  items: Array<Animal>;
}

interface CageColumnProps {
  groups: Array<CageCardGroup>;
  group: CageCardGroup;
  cage: Cage;
  study: Study;
  groupId: ID;
  title: string;
  updateCageName: (cage: Cage, value: string) => void;
  validateCageName: (name: string) => boolean;
  groupIndex: number;
  toggleAddAnimalsModal: (cage: Cage) => void;
  toggleDeleteCageModal: (collection: Cage) => void;
  onCardMove: (dragIndex: any, hoverIndex: any) => void;
  onCardDropped: (dragIndex: any, groupIndex: any) => void;
  animalIdProperty: ID;
  inStudyView: boolean;
}

const CageColumn: React.FC<CageColumnProps> = ({
  groups,
  group,
  cage,
  study,
  groupId,
  title,
  updateCageName,
  validateCageName,
  groupIndex,
  toggleAddAnimalsModal,
  toggleDeleteCageModal,
  onCardMove,
  onCardDropped,
  animalIdProperty,
  inStudyView,
}) => {
  const MAX_ANIMALS_PER_CAGE = 10;
  const [printCage, setPrintCage] = useState(false);
  let userCanEdit = true;
  if (study) {
    userCanEdit = Auth.isWriteUserForStudy(study);
  }
  const atunesIntegrationEnabled = useSelector(
    (state: State) => state?.team?.features?.atunes_colony_management_integration ?? false
  );

  const { sendRequest: updateAnimals, requestError } = useRequest({
    route: 'animals.update.bulk',
    method: 'patch',
  });

  const buildActionMenuForCage = (cage: Cage) => {
    const deleteDisabled = Boolean(_get(cage, 'subjects.data').length);
    return [
      {
        name: 'Print label',
        action: () => setPrintCage(true),
      },
      {
        name: 'Rename',
        disabled: !userCanEdit,
        action: () => {
          const element = document?.getElementById(`editable_${cage.id}`)?.childNodes?.[0] as HTMLElement;
          element?.click();
        },
      },
      {
        name: 'Delete',
        disabled: !userCanEdit || deleteDisabled,
        action: () => {
          toggleDeleteCageModal(cage);
        },
        className: 'red',
        tooltip: deleteDisabled ? "Cages can't be deleted if animals exist within them" : '',
      },
    ];
  };

  const handleCardDrop = async (item: Card) => {
    try {
      const data = {
        animals: [
          {
            id: item.id,
            collection_id: groups[groupIndex].id,
          },
        ],
      };
      await updateAnimals(data);
      onCardDropped(item.index, groupIndex);
    } catch (error) {
      errorToast('Unable to move animal. If this keeps occuring, please contact support');
    }
  };

  const hasSpace = MAX_ANIMALS_PER_CAGE > group.items.length;
  const reqErrorClass = requestError ? 'ba b--red' : '';

  return (
    <>
      <CardColumn
        title={
          <div className="near-black normal f6 lh-solid dib v-mid" id={`editable_${groupId}`}>
            <RIEInput
              value={title}
              change={(value: Record<string, string>) => updateCageName(cage, value.text)}
              propName="text"
              validate={validateCageName}
              classInvalid="ui__error"
              className="ui__editable"
              isDisabled={!userCanEdit}
            />
          </div>
        }
        footer={
          userCanEdit && !atunesIntegrationEnabled ? (
            <Footer text="Add animal" onClick={() => toggleAddAnimalsModal(cage)} />
          ) : null
        }
        hasSpace={hasSpace}
        onDrop={handleCardDrop}
        actionMenu={
          <SelectDropDown title="Show action menu" iconOnly className="hover-near-black" alignMenu="right">
            <ActionList actions={cage && buildActionMenuForCage(cage)} />
          </SelectDropDown>
        }
        placeholderCard={
          <CageCard
            subject={
              {
                name: 'placeholder-subject',
                dob: '2000-01-01',
                sex: 'm',
              } as Animal
            }
            studyGroup={
              {
                name: 'placeholder-group',
              } as TreatmentGroup
            }
            inStudyView={inStudyView}
          />
        }
        className={reqErrorClass}
      >
        {group?.items.map((subject, itemIndex) => {
          if (_isNil(subject)) {
            return null;
          }
          const studyGroup =
            study &&
            study.study_groups.find((g) => parseInt(String(g.id)) === parseInt(String(subject.study_group_id)));
          const name = _get(subject, animalIdProperty, subject?.name);
          const index = '' + groupIndex + '-' + itemIndex;
          return (
            <CageCard
              subject={{ ...subject, name }}
              studyGroup={studyGroup}
              key={subject.id}
              id={subject.id}
              index={index}
              inStudyView={inStudyView}
              moveCallback={onCardMove}
              canDrag={userCanEdit}
              dropCallback={async (item) => {
                await handleCardDrop(item);
              }}
            />
          );
        })}
      </CardColumn>
      {printCage && <CageCardTemplateWrapper cageId={cage.id} onComplete={() => setPrintCage(false)} />}
    </>
  );
};

export default CageColumn;
