// @ts-nocheck: converted from JS
import ApiErrorBanner from '@/components/ApiErrorBanner';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { successToast } from '@/helpers';
import { _notNil } from '@/littledash';
import { useRequest } from '@/support/Hooks/request';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

const SetTrackingDate = ({ subject: animals, handleCallback, closeModal }) => {
  const defaultDate = useMemo(() => {
    if (animals?.length === 1 && _notNil(animals?.[0]?.tracking_started_at)) {
      return animals[0].tracking_started_at;
    }
    return DateUtils.dateNow();
  }, [animals]);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      trackingDate: defaultDate,
    },
  });
  const {
    sendRequest: updateTrackingDates,
    requestSending: updatingTrackingDates,
    requestError,
  } = useRequest({
    route: 'animals.tracking.date.bulk.update',
    method: 'patch',
  });

  const onSubmit = async ({ trackingDate }) => {
    const animalIds = animals.map(({ id }) => id);
    await updateTrackingDates({
      animal_ids: animalIds,
      tracking_started_at: trackingDate,
    });
    successToast(`Set ${animals.length} tracking date${animals.length !== 1 ? 's' : ''} `);
    handleCallback();
  };

  return (
    <ModalContainer size="narrow" className="bg-white">
      <div className="flex flex-column bb b--moon-gray">
        <ModalHeader title="Set a tracking date" className="pl3 pt3 pb2 w-100" />
        <div className="f6 lh-copy pl3 pb2">
          <p>Choose a date when you'd like to begin graphing measurements.</p>
          <a
            href="https://help.benchling.com/hc/en-us/articles/20217990242061"
            target="_blank"
            rel="noopener noreferrer"
            className="link blue"
          >
            Read more
          </a>
        </div>
      </div>
      {requestError && <ApiErrorBanner error={requestError} />}
      <div className="pl3" style={{ width: 290, height: 100 }}>
        <Controller
          name="trackingDate"
          control={control}
          defaultValue={defaultDate}
          rules={{
            required: 'This field is required',
          }}
          render={({ onChange, value }: ControllerRenderProps) => (
            <div className="pt3">
              <label>Tracking date</label>
              <DatePickerNative type="date" value={value} onChange={onChange} />
            </div>
          )}
        />
      </div>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Save"
        cancelBtnText="Cancel"
        submitButtonProps={{
          disabled: updatingTrackingDates,
          loading: updatingTrackingDates,
        }}
        cancelButtonProps={{ disabled: updatingTrackingDates }}
      />
    </ModalContainer>
  );
};

export default SetTrackingDate;
