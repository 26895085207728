// @ts-nocheck: converted from JS

import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import UserAvatar from '@/components/UI/UserAvatar';
import { formatNumber, renderMetadataValue } from '@/helpers';
import { _notNil } from '@/littledash';
import { details } from '@/referenceData/study/samples';
import { ModalContainer, ModalHeader } from '@/utils/modal';

const ViewSample = ({ sample, closeModal }) => {
  const {
    created_by: createdBy,
    details: sampleDetails,
    sample_id: sampleId,
    type,
    comments,
    metadata = [],
    date,
  } = sample;
  return (
    <ModalContainer size="narrow">
      <ModalHeader title={`${sampleId} • ${type}`} closeModal={closeModal} className="pa3 bb b--moon-gray w-100" />
      <div className={`flex flex-column ph2 pt3 pb3 ${comments ? 'items-top' : 'items-center'}`}>
        <div className="ph2 w-100">
          {_notNil(createdBy) && <UserAvatar user={createdBy} tooltip={createdBy?.name ?? createdBy?.email} />}
          <span className="f6 ml2 lh-title dib">
            <DateRenderer value={date} defaultResponse="" />
          </span>
        </div>
        <div className="ph2 w-100">
          {comments && (
            <div className="pa2 br3 br--top--left bg-near-white mt2 mb3 mw6">
              <p className="f6 ma0 line-copy near-black">{comments}</p>
            </div>
          )}
          <div className="mv2 w-100 bt bl br b--moon-gray">
            {(sampleDetails ?? []).map(({ key, unit, value }) => (
              <Field key={key} label={details[key]} className="pv2">
                {formatNumber(value)} {unit}
              </Field>
            ))}
            {metadata.map((meta) => (
              <Field key={meta.id} label={meta.title} className="pv2">
                {renderMetadataValue(meta)}
              </Field>
            ))}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

const Field = ({ children, label, className }) => (
  <div className={`w-100 flex items-center f6 bb b--moon-gray ${className}`}>
    <div className="w-third lh-title pl2">{label}</div>
    <div className="w-two-thirds near-black lh-title pr2">{children ?? '—'}</div>
  </div>
);

export default ViewSample;
