// @ts-nocheck: converted from JS
import AnimalsTable from '@/components/Animals/AnimalsTable/AnimalsTable';
import Header from '@/components/UI/Header';
import type { ID } from '@/model/Common.model';
import { FetchProvider, useFetchEntity } from '@/support/Hooks/fetch';
import { useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface SelectedRowProp {
  original: { api_id: ID };
}

export const AnimalSelection = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const { setValue } = useFormContext();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,study_groups',
  });

  useEffect(() => {
    setValue('animals', []);
  }, []);

  const handleSelection = (selectedFlatRows: Array<ID>) =>
    setValue('animals', [...(selectedFlatRows ?? []).map((row: SelectedRowProp) => row.original.api_id)]);

  const animalsLoaded = () => setValue('animalsLoaded', true);

  return (
    <>
      <div className="ph4 pv3">
        <Header mainHeaderText="Choose animals" />
      </div>
      {!studyLoading && (
        <div className="ph4" data-test-component="AnimalSelection" data-test-element="">
          <FetchProvider entity={study}>
            <AnimalsTable enableBulkActions={false} onSelectRow={handleSelection} onFetchTableData={animalsLoaded} />
          </FetchProvider>
        </div>
      )}
    </>
  );
};
