// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import Configuration from '@/components/Studies/StudyGroups/Configuration';
import { Treatments } from '@/components/Studies/StudyGroups/Treatments/Treatments';
import Header from '@/components/UI/Header';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _notNil } from '@/littledash';
import { MetadataField } from '@/model/Metadata.model';
import { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Groups from './Groups';

export const StudyGroups = () => {
  const { id: studyId } = useParams<{ id: string }>();

  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'study_groups,users',
  });

  const { collection: treatmentMetas, collectionLoading: treatmentMetasLoading } = useFetchCollection<MetadataField>({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: 'treatment_meta',
    },
  });

  const isComplete = _notNil(study?.archived_at);
  const canEdit =
    !isComplete && (Auth.isWriteUserForStudy(study, false, false) || Auth.isAuthor(study) || Auth.isOwner(study));

  const tabs = [
    {
      id: 'groups',
      name: 'Groups',
      element: <Groups studyApiId={study?.api_id} canEdit={canEdit} />,
    },
    {
      id: 'treatments',
      name: 'Treatments',
      element: <Treatments study={study} metadata={treatmentMetas} canEdit={canEdit} />,
    },
    canEdit && {
      id: 'configuration',
      name: 'Configuration',
      element: <Configuration canEdit={canEdit} />,
    },
  ];

  const getTabIndex = (search: string) => {
    const tabId = new URLSearchParams(search).get('tab');
    if (_notNil(tabId)) {
      const tabIndex = tabs.findIndex((tab) => tab.id === tabId);
      return tabIndex >= 0 && tabIndex <= tabs.length ? tabIndex : 0;
    }
    return 0;
  };

  const { search, pathname } = useLocation();
  const history = useHistory();
  const [tabState, setTabState] = useState(getTabIndex(search));

  useEffect(() => {
    const newTabState = getTabIndex(search);
    setTabState((prev) => (newTabState !== prev ? newTabState : prev));
  }, [search]);
  useEffect(() => {
    history.push({ pathname, search: `?tab=${tabs[tabState].id}` });
  }, [tabState]);

  if (studyLoading || treatmentMetasLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {Auth.isStudyUser(study) || Auth.isOwner(study) || Auth.isAuthor(study) || Auth.isCurrentTeamOwner() ? (
        <div className="pv3 ph4">
          <Header mainHeaderText="Treatment groups" />
          <Tabs outerState={[tabState, setTabState]}>
            <div className="mt3 pt3 bb b--moon-gray">
              {tabs.map(({ id, name }) => (
                <Tab className="f4" key={`${id}_tab`} testId={`${name}_tab`}>
                  {name}
                </Tab>
              ))}
            </div>
            {tabs.map(({ id, element }) => (
              <Panel key={`${id}_panel`}>{element}</Panel>
            ))}
          </Tabs>
        </div>
      ) : (
        <div className="w-100 h-100 flex items-center justify-center">
          <NoData
            title="You aren't authorized"
            text="Only team members with Read access have access to use Treatment groups. The study author can update your permissions to give you access."
            link={`/studies/${studyId}/animals/`}
            btnTxt="Return to animals"
          />
        </div>
      )}
    </>
  );
};
