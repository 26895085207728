import Avatar from '@/components/UI/Avatar';
import type { BaseUser } from '@/model/User.model';
import { CSSProperties } from 'react';
import type { AvatarProps } from '../Avatar/Avatar';

interface UserAvatarProps extends AvatarProps {
  user?: Pick<BaseUser, 'name'>;
  tooltip?: string;
  dataTestId?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  tooltip = '',
  bgColorClass = 'bg-dark-gray',
  includeName = false,
  dataTestId,
  className = '',
  ...props
}) => {
  let initials = '-';
  if (user?.name) {
    const matches = user.name.match(/\b\w/g);
    initials = `${matches?.shift() ?? ''}${matches?.pop() ?? ''}`.toUpperCase();
  }

  return (
    <span data-testid={dataTestId}>
      {' '}
      <Avatar tooltip={tooltip} bgColorClass={bgColorClass} className={className} {...props}>
        {initials}
      </Avatar>
    </span>
  );
};

interface TruncatedAvatarProps {
  remainder: number;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
  dataTestId?: string;
}

export const TruncatedAvatar = ({ remainder, onClick, style, className = '', dataTestId }: TruncatedAvatarProps) => (
  <span data-testid={dataTestId}>
    <div className={`tc w2 h2 dib br-100 bg-dark-gray ${className}`} onClick={onClick} style={style}>
      <div className="flex justify-center items-center w-100 h-100 normal f6 lh-title white">
        <span>+</span> {remainder}
      </div>
    </div>
  </span>
);

export default UserAvatar;
