// @ts-nocheck: converted from JS

import APITable from '@/components/UI/Table/Reusable/APITable';
import { defaultPromiseErrorHandler } from '@/helpers';
import { _notNil } from '@/littledash';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController';
import { api as apiRoutes } from '@/support/route';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateColumns, searchByText } from './Approvals.utils';

const ApprovedTable = () => {
  const { newAbortController } = useAbortController();
  const { team } = useSelector(({ team: { team } }) => ({
    team,
  }));
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (_notNil(team)) {
      generateColumns(team, newAbortController().signal)
        .then((resp) => setColumns(resp))
        .catch(defaultPromiseErrorHandler);
    }
  }, [team]);

  return (
    <div className="pa4">
      {columns.length > 0 && (
        <APITable
          defaultSortBy={{ id: 'time', desc: true }}
          columns={columns}
          apiInfo={{ type: 'legacyInternalApi', route: apiRoutes('approvals.requests.show.approved') }}
          includeParam="study"
          searchPlaceholderText={searchByText}
        />
      )}
    </div>
  );
};

export default ApprovedTable;
