// @ts-nocheck: converted from JS

import { Plotly } from '@/support/plotly';
import { useEffect, useRef } from 'react';

const BoxAndWhiskersGraph = ({ data, title }) => {
  const graphId = useRef(null);
  useEffect(() => {
    const config = {
      responsive: true,
      displayModeBar: false,
    };
    const layout = {
      showlegend: true,
      title: title,
      margin: { b: 0, t: 50 },
      font: {
        family: 'Basier Circle Reg, Arial',
        size: 12,
        color: '#6b6b76',
      },
      hoverlabel: {
        font: {
          family: 'Basier Circle Reg, Arial',
          size: 12,
        },
      },
      xaxis: {
        showgrid: false,
        zeroline: false,
        showticklabels: false,
        showexponent: 'all',
        exponentformat: 'e',
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        showticklabels: false,
        showexponent: 'all',
        exponentformat: 'e',
      },
      legend: {
        traceorder: 'reversed',
        orientation: 'h',
        font: { size: 14, family: 'Basier Circle Reg, Arial', color: '#333' },
      },
    };
    Plotly.newPlot(graphId.current, { data, config, layout });
  }, [data]);

  return <div ref={graphId} style={{ height: '100%' }} />;
};

export default BoxAndWhiskersGraph;
