// @ts-nocheck: converted from JS

import { createContext, useContext } from 'react';

const TableContext = createContext();

const TableProvider = ({ children, useTableProps }) => (
  <TableContext.Provider value={useTableProps}>{children}</TableContext.Provider>
);

const useTableContext = () => useContext(TableContext);

export { TableProvider, useTableContext };
