import type { DataTableColumn } from '@/components/UI/DataTable/DataTable.model';
import { _isEmpty, _notNil } from '@/littledash';
import type { Variable } from '@/utils/FormulaDsl';
import { FormulaDsl, FormulaDslLint } from '@/utils/FormulaDsl';
import { autocompletion, closeBrackets, closeBracketsKeymap, completionKeymap } from '@codemirror/autocomplete';
import { defaultKeymap, history, historyKeymap } from '@codemirror/commands';
import {
  bracketMatching,
  foldGutter,
  foldKeymap,
  HighlightStyle,
  indentOnInput,
  syntaxHighlighting,
} from '@codemirror/language';
import { forEachDiagnostic, linter, lintKeymap, setDiagnosticsEffect } from '@codemirror/lint';
import { highlightSelectionMatches } from '@codemirror/search';
import './FormulaInput.scss';
import { Compartment, EditorState } from '@codemirror/state';
import type { ViewUpdate } from '@codemirror/view';
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLine,
  highlightSpecialChars,
  keymap,
  placeholder,
  rectangularSelection,
} from '@codemirror/view';
import { tags } from '@lezer/highlight';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import styles from './EditDataTableFormulaColumn.module.scss';

interface FormulaInputProps {
  variables: Record<Variable, DataTableColumn>;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  isValid: (valid: boolean, message?: string) => void;
  isValidating: (validating: boolean) => void;
  setCaretPosition?: (position: number) => void;
}

const baseSetup = () => [
  highlightSpecialChars(),
  history(),
  foldGutter(),
  drawSelection(),
  dropCursor(),
  indentOnInput(),
  bracketMatching(),
  closeBrackets(),
  autocompletion({ aboveCursor: true }),
  rectangularSelection(),
  crosshairCursor(),
  highlightActiveLine(),
  highlightSelectionMatches(),
  placeholder('Formula'),
  syntaxHighlighting(
    HighlightStyle.define([
      { tag: tags.operator, color: '#97A6B6' },
      { tag: tags.number, color: '#FF6300' },
      { tag: tags.variableName, color: '#5E2CA5' },
    ])
  ),
  keymap.of([
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...historyKeymap,
    ...foldKeymap,
    ...completionKeymap,
    ...lintKeymap,
  ]),
];

export const FormulaInput: FC<FormulaInputProps> = ({
  variables,
  value,
  onChange,
  onBlur,
  isValid,
  isValidating,
  setCaretPosition,
}) => {
  const formulaEditorRef = useRef<HTMLInputElement>(null);
  const [formulaInputState, setFormulaInputState] = useState<{
    view: EditorView;
    state: EditorState;
    language: Compartment;
    linter: Compartment;
  } | null>(null);

  useEffect(() => {
    if (_notNil(formulaEditorRef.current)) {
      const updateListener = (update: ViewUpdate) => {
        const newCaretPosition = update.state.selection.main.head;
        setCaretPosition?.(newCaretPosition);
        const cmScroller = formulaEditorRef.current?.querySelector('.cm-scroller');
        if (_notNil(cmScroller)) {
          cmScroller.scrollTo({ left: cmScroller.scrollWidth });
        }
        if (update.docChanged) {
          isValidating(true);
          const docUpdate = update.state.doc.toString();
          onChange(docUpdate);
        }

        update.transactions.forEach(({ effects }) => {
          effects.forEach((effect) => {
            if (effect.is(setDiagnosticsEffect)) {
              const messages: Array<string> = [];
              forEachDiagnostic(update.state, (d) => {
                messages.push(d.message);
              });
              const valid = _isEmpty(messages);

              if (valid) {
                isValid(true);
              } else {
                isValid(false, messages.shift() ?? 'Formula invalid');
              }
              isValidating(false);
            }
          });
        });
      };
      const language = new Compartment();
      const lint = new Compartment();
      const state = EditorState.create({
        doc: value ?? '',
        extensions: [
          EditorState.transactionFilter.of((tr) => (tr.newDoc.lines > 1 ? [] : tr)),
          EditorView.updateListener.of(updateListener),
          EditorView.domEventHandlers({ blur: () => onBlur?.() }),
          baseSetup(),
          language.of(
            FormulaDsl({
              type: 'datatable',
              variables: Object.entries(variables).reduce(
                (acc, [variable, column]) => ({
                  ...acc,
                  [variable]: column.name,
                }),
                {}
              ),
            })
          ),
          lint.of(linter(FormulaDslLint(new Set<Variable>(Object.keys(variables ?? {}) as Array<Variable>)))),
        ],
      });
      const view = new EditorView({
        parent: formulaEditorRef.current,
        state,
      });
      setFormulaInputState({ view, state, language, linter: lint });
      view.focus();

      return () => {
        setFormulaInputState(null);
        view.destroy();
      };
    }
  }, [formulaEditorRef]);

  useEffect(() => {
    if (_notNil(formulaInputState) && _notNil(variables)) {
      formulaInputState.view.dispatch({
        effects: [
          formulaInputState.language.reconfigure(
            FormulaDsl({
              type: 'datatable',
              variables: Object.entries(variables).reduce(
                (acc, [variable, column]) => ({
                  ...acc,
                  [variable]: column.name,
                }),
                {}
              ),
            })
          ),
          formulaInputState.linter.reconfigure(
            linter(FormulaDslLint(new Set<Variable>(Object.keys(variables ?? {}) as Array<Variable>)))
          ),
        ],
      });
    }
  }, [variables, formulaInputState]);

  useEffect(() => {
    if (_notNil(formulaInputState) && _notNil(value)) {
      formulaInputState.view.focus();
      const formula = formulaInputState.view.state.doc.toString();
      formulaInputState.view.focus();
      const caretPosition = formulaInputState.view.state.selection.main.head + (value.length - formula.length);

      formulaInputState.view.dispatch({
        changes: { from: 0, to: formula.length, insert: value },
        selection: { anchor: caretPosition, head: caretPosition },
      });
    }
  }, [value, formulaInputState]);

  const addOperator = (operator: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (_notNil(value)) {
      const position = formulaInputState?.view.state.selection.main.head;
      onChange(`${value.slice(0, position)}${operator}${value.slice(position)}`);
    } else {
      onChange(operator);
    }
  };

  return (
    <>
      <div className="flex flex-row">
        {['+', '-', '/', '*', '(', ')'].map((operator) => (
          <div className={styles['data-table-formula-item-pill']} onClick={addOperator(operator)} key={operator}>
            {operator}
          </div>
        ))}
      </div>
      <div className="data-table-formula-column-formula-field" ref={formulaEditorRef} />
    </>
  );
};
