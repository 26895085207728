import React, { CSSProperties, useEffect, useRef } from 'react';
import styles from './AlertProgressBar.module.scss';

const progressBarColor = (color: string) => {
  switch (color) {
    case 'gold':
      return styles.gold;
    case 'dark-green':
      return styles.darkGreen;
    default:
      return '';
  }
};

interface AlertProgressBarProps {
  type?: string;
  duration?: CSSProperties['animationDuration'];
  color?: string;
  paused: boolean;
  onComplete?: () => void;
}

export const AlertProgressBar: React.FC<AlertProgressBarProps> = ({
  type = '',
  duration = '2s',
  color = 'gold',
  paused,
  onComplete,
}) => {
  const progressBarRef = useRef<HTMLSpanElement | null>(null);
  useEffect(() => {
    (progressBarRef.current?.getAnimations() ?? []).forEach((animation) => {
      animation.cancel();
      animation.play();
      if (paused) {
        animation.pause();
      }
    });
  }, [progressBarRef, type]);
  useEffect(() => {
    (progressBarRef.current?.getAnimations() ?? []).forEach((animation) => {
      animation.play();
      if (paused) {
        animation.pause();
      }
    });
  }, [paused]);
  return (
    <div className={styles.progressBarContainer}>
      <span>
        <span
          ref={progressBarRef}
          className={`${styles.progressBar} ${progressBarColor(color)}`}
          style={{ animationDuration: duration }}
          onAnimationEnd={() => onComplete?.()}
        ></span>
      </span>
    </div>
  );
};
