import NoDataCard from '@/components/NoDataCard';
import { preventNumberScroll } from '@/helpers';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { ErrorSetting } from '@/model/Study.model';
import { Plotly } from '@/support/plotly';
import { web as webRoute } from '@/support/route';
import type { State } from 'model/State.model';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { GraphsState } from '../Graph.model';
import { getAxisLabel, graphConfig, graphOptions } from './Graph.utils';
import GraphTitle, { GraphSettings } from './GraphTitle';

interface DailyPlotProps {
  id: ID;
  studyName: string;
  errorDeviation: ErrorSetting;
  state: GraphsState;
}

// The daily plot shows the average for each group depending on the day selected. The day relates the the Study Group number on the X axis
const DailyPlot: React.FC<DailyPlotProps> = ({ id, studyName, errorDeviation, state }) => {
  const { changeOption, activeMetric, metrics, groups } = state;
  const features = useSelector((state: State) => state.team.features);
  const [day, setDay] = useState<string>('0');
  const [settings, setSettings] = useState<Required<GraphSettings>>({
    yaxis: 'linear',
    error: errorDeviation,
    average: 'mean',
  });

  const updateGraphData = (graphData: Plotly.Data) => {
    const graphSettings = graphConfig({
      // @ts-expect-error: type mismatch
      yTitle: getAxisLabel(activeMetric, changeOption, settings.average),
      yType: settings.yaxis,
    });
    Plotly.newPlot('dailyPlot', { ...graphSettings, data: [graphData] });
  };

  const dataFormat: Plotly.Data = {
    x: [],
    y: [],
    marker: {
      color: [],
    },
    error_y: {
      type: 'data',
      array: [],
      visible: true,
    },
    type: 'bar',
  };
  const formatGraphData = () => {
    const graphData = metrics.reduce((groupsData, group) => {
      const dailyAverage = group.averages?.find((average) => average.study_day === Number(day));
      groupsData.x.push(group.name);
      groupsData.marker.color.push(group.color);
      groupsData.y.push(dailyAverage?.[settings.average ?? 'mean']);
      if (_notNil(settings.error) && !['dtdc', 'tgi'].includes(settings.average ?? '')) {
        groupsData.error_y.array.push(dailyAverage?.[settings.average === 'mean' ? settings.error : 'mad']);
      }
      return groupsData;
    }, dataFormat);
    updateGraphData(graphData);
  };

  useEffect(() => {
    if (groups.length) {
      formatGraphData();
    }
  }, [settings, metrics, day]);

  if (!groups.length) {
    return (
      <NoDataCard
        className="ma3"
        title="You haven't added any measurements yet"
        text="Create measurements to monitor how your study progresses. You can export all group data to excel as a report and download graphs instantly."
        link={webRoute('studies.settings', { id })}
        btnTxt="Study settings"
      />
    );
  }

  return (
    <div className="ph4 pv2" data-test-component="DailyPlot" data-test-element="container">
      <div className="flex  flex-column">
        <GraphTitle
          studyName={studyName}
          graphType="DAILY"
          graphOptions={graphOptions({ withErrors: true, average: settings.average, features })}
          settings={settings}
          setSettings={(value) => setSettings(value as Required<GraphSettings>)}
        />
        <div className="flex items-baseline" data-testid="graphs__study-day">
          <label htmlFor="dayInput" className="mr3">
            Study day
          </label>
          <input
            type="number"
            onWheel={preventNumberScroll}
            name="dayInput"
            value={day}
            style={{ marginBottom: 0, width: '75px', height: '30px' }}
            onChange={(e) => setDay(e.target.value)}
            min={0}
          />
        </div>
      </div>
      <div className="ui__graph">
        <div id="dailyPlot" className="w-100 h-100" style={{ height: '548px' }} data-testid="graphs__bar-chart" />
      </div>
    </div>
  );
};

export default DailyPlot;
