// @ts-nocheck: converted from JS

import React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { DateRenderer } from '../../DateRenderers/DateRenderers';

interface TextProps {
  name: string;
  type?: string;
}

const Text: React.FC<TextProps> = ({ name, type }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ value }: ControllerRenderProps) => {
        if (type === 'date') {
          return <DateRenderer value={value} defaultResponse="-" />;
        }
        return <span>{value ?? '-'}</span>;
      }}
    />
  );
};

export default Text;
