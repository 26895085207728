// @ts-nocheck: converted from JS

import Condition from '@/components/Glossary/Sections/Approvals/TemplateForm/Summary/Condition';

const Conditions = ({ conditions, metadata }) => {
  return (
    <div>
      {conditions.map((condition) => (
        <Condition key={condition.glossary_id} condition={condition} metadata={metadata} />
      ))}
    </div>
  );
};

export default Conditions;
