// @ts-nocheck: converted from JS

import Table from '@/components/UI/Table';
import Pagination from '@/components/UI/Table/Pagination';
import { expo } from '@/helpers';
import { _isEmpty, _notNil } from '@/littledash';
import { useState } from 'react';
import './ReviewTable.scss';

const constructReviewTableColumns = (data, metrics) => {
  const columns = [];
  const allIdColHeads = {
    collection_name: 'Cage',
    name: 'ID',
    'alt_ids.tail': 'Tail',
    'alt_ids.tag': 'Tag',
    'alt_ids.ear': 'Ear',
    'alt_ids.donor': 'Donor',
  };
  const dataIdColHeads = Object.keys(data[0]).filter((f) => Object.keys(allIdColHeads).includes(f));

  if (!_isEmpty(dataIdColHeads)) {
    dataIdColHeads.map((h) => {
      columns.push({
        id: allIdColHeads[h],
        Header: allIdColHeads[h],
        accessor: h,
        style: { padding: '0px' },
        Cell: ({ row }) => (
          <Cell accessor={h} {...row}>
            {row.original[h]}
          </Cell>
        ),
      });
    });
  }

  if (!_isEmpty(metrics)) {
    const allVariables = Object.keys(metrics);
    const dataVariables = Object.keys(data[0]['metrics']).filter((f) => allVariables.includes(f));

    dataVariables.map((h) => {
      columns.push({
        Header: metrics[h]['name'],
        accessor: `metrics.${h}`,
        style: { padding: '0px' },
        Cell: ({ row }) => {
          const value = row.original['metrics'][h];
          return (
            <Cell accessor={h} {...row}>
              {isNaN(value) ? value : Number(value) > 10000 ? expo(Number(value)) : value}
            </Cell>
          );
        },
      });
    });
  }

  return columns;
};

const ReviewTable = ({ study, data }) => {
  const [settings, setSettings] = useState({
    rows: 25,
    page: 0,
  });

  // Navigate to the last page which has results as the current page is no longer valid.
  if (!_isEmpty(data) && settings.rows * settings.page >= data.length) {
    let page = data.length / settings.rows;
    page = page < 1 ? 0 : Math.floor(page) - 1;
    setSettings({ ...settings, page });
  }

  const metrics = study.settings.calculations.reduce((acc, v) => {
    v.measurements.forEach((m) => {
      acc[m.id] = { ...m };
    });
    return acc;
  }, {});

  const columns = [
    {
      id: 'number',
      Header: 'No.',
      width: 90,
      className: 'pa0',
      Cell: ({ row }) => {
        return (
          <Cell accessor="row" {...row}>
            {row.original.row}
          </Cell>
        );
      },
    },
    ...constructReviewTableColumns(data, metrics),
  ];

  const TablePagination = (props) => (
    <div className="pv3 ph4 bt b--moon-gray">
      <Pagination {...props} settings={settings} updateSettings={setSettings} enableRowSelect={false} />
    </div>
  );

  return (
    <div className="ui__table__review bt b--moon-gray">
      <Table
        columns={columns}
        data={data}
        resizable={false}
        minRows={0}
        maxRows={25}
        showPagination={true}
        sortable={false}
        defaultPageSize={settings.rows}
        page={settings.page}
        pageSize={settings.rows}
        PaginationComponent={TablePagination}
      />
    </div>
  );
};

const Cell = ({ accessor, original, children, className }) => {
  let tooltip = {};
  let statusClasses = false;

  if (_notNil(original?.warnings?.[accessor])) {
    tooltip = { 'data-tooltip-id': 'global-tooltip-id', 'data-tooltip-content': original.warnings[accessor] };
    statusClasses = 'w-100 ui__cell__warning';
  }
  if (_notNil(original?.errors?.[accessor])) {
    tooltip = { 'data-tooltip-id': 'global-tooltip-id', 'data-tooltip-content': original.errors[accessor] };
    statusClasses = 'w-100 ui__cell__error';
  }

  return (
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      {...tooltip}
      className={`pv3 ph4 h-100 ${statusClasses} ${className}`}
    >
      <span className={(!_isEmpty(original.errors) || !children) && 'ui__disabled'}>{children || 'Empty cell'}</span>
    </div>
  );
};

export default ReviewTable;
