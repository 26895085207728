import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import type { StudyCode } from '@/model/StudyCode.model';
import { web as webRoute } from '@/support/route';

export const generateStudyCodeColumns = (): Array<Column<StudyCode>> => [
  {
    id: 'id',
    accessor: 'id',
    width: 50,
    isVisible: false,
    sortBy: 'id',
  },
  {
    id: 'title',
    Header: 'Title',
    Cell: ({ row: { original } }) => (
      <>
        <div className="flex items-center pointer">
          <Link
            className="link w-100"
            to={webRoute('team.studies.study_codes.edit', { studyCodeApiId: original.api_id })}
          >
            {original.title}
          </Link>
        </div>
      </>
    ),
    isVisible: true,
    sortBy: 'title',
    accessor: 'title',
  },
  {
    id: 'code',
    accessor: 'code',
    isVisible: true,
    Header: 'Code',
    sortDisabled: true,
    Cell: ({
      row: {
        original: { composites },
      },
    }) =>
      composites
        ?.reduce<Array<string | number>>((acc, comp) => {
          acc[comp.order - 1] = comp.value;
          return acc;
        }, Array(composites.length).fill(''))
        .join(''),
  },
  {
    id: 'created_at',
    Header: 'Created',
    isVisible: true,
    accessor: 'created_at',
    Cell: ({
      row: {
        original: { created_at },
      },
    }) => <DateTimeRenderer value={created_at} />,
  },
  {
    id: 'updated_at',
    Header: 'Updated',
    isVisible: true,
    accessor: 'updated_at',
    Cell: ({
      row: {
        original: { updated_at },
      },
    }) => <DateTimeRenderer value={updated_at} />,
  },
];
