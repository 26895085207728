// @ts-nocheck: converted from JS

import NoDataCard from '@/components/NoDataCard';
export const WorkflowNoDataCard = (
  <NoDataCard
    title="Animal data will appear here"
    text="Measurements, samples, observations and other data for this animal will appear here once they are added."
    dark
    className="ma4"
  />
);
