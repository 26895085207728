// @ts-nocheck: converted from JS

import { cleanseSpacesFromString, hasOwnProperty } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import validate from 'validate.js';
import { getAllColNames, idFields } from '../../Importer.utils';

export const returnMetricsForValidation = (metrics, matchData) => {
  const includedMatches = Object.values(matchData)
    .filter((f) => f.included)
    .map((f) => f.matched);

  return Object.keys(metrics).reduce((acc, v) => {
    if (includedMatches.includes(v)) {
      acc[v] = metrics[v];
    }

    return acc;
  }, {});
};

export const validationSchema = (calculations) => {
  return calculations.reduce((acc, v) => {
    v.measurements.forEach((m) => {
      acc[m.id] = {
        numericality: {
          strict: true,
          message: 'This value must be a number.',
        },
      };
    });
    return acc;
  }, {});
};

export const validateFields = (fields, validationSchema) => {
  const fieldsPresent = Object.keys(fields);
  const schemaFieldsPresent = Object.keys(validationSchema).reduce((acc, key) => {
    if (fieldsPresent.includes(key)) {
      acc[key] = validationSchema[key];
    }
    return acc;
  }, {});
  const results = validate(fields, schemaFieldsPresent, {
    fullMessages: false,
  });
  if (!results) {
    return true;
  }

  return results;
};

export const checkCollectionExists = (collections, collectionName) => {
  return collections.filter((c) => c.name === collectionName);
};

export const getAnimalById = (
  animals: Array<Animal>,
  chosenIdField: string,
  searchValue: string
): Animal | undefined => {
  if (_isNotEmpty(animals) && _notNil(chosenIdField)) {
    const animal = animals.find(({ alt_ids, name }) => {
      const searchValueClean = cleanseSpacesFromString(searchValue);
      if (chosenIdField.includes('alt_ids.')) {
        const method = chosenIdField.replace(/^(alt_ids.)/, '');
        const altIds = alt_ids ?? [];
        return hasOwnProperty(altIds, method) && altIds[method] === searchValueClean;
      } else {
        return name === searchValueClean;
      }
    });
    return animal;
  }
};

export const constructMatchData = (fileData, matchData) => {
  const ids = idFields.map(({ key }) => key);
  const allCols = getAllColNames(fileData);

  if (_isNotEmpty(fileData) && _isNotEmpty(matchData)) {
    return fileData.reduce((acc, v, i) => {
      acc[i] = { row: i + 1, metrics: {} };
      allCols.forEach((k) => {
        if (matchData[k]['included']) {
          const match = matchData[k]['matched'];
          const value = hasOwnProperty(v, k) ? String(v[k]) : null;
          if (ids.includes(match)) {
            acc[i][match] = value;
          } else {
            acc[i]['metrics'][match] = value;
          }
        }
      });

      return acc;
    }, []);
  }

  return [];
};

/**
 @TODO `validateAndReturnRowWithId` Should be broken
 Down into smaller more understandable steps
 **
 */

export const validateAndReturnRowWithId = (
  row,
  collections,
  subjects,
  validationSchema,
  existingMeasurements,
  matchedSubjects = [],
  callback = () => {},
  cageConfirmed,
  chosenIdField
) => {
  const tempRow = { ...row };
  const errors = {};
  const warnings = {};
  const tempMatchedSubjects = [...matchedSubjects];
  const idValue = tempRow[chosenIdField];

  let animalsToFilter;

  if (cageConfirmed) {
    const cageName = cleanseSpacesFromString(row.collection_name);
    const collectionCheck = checkCollectionExists(collections, cageName);
    if (_isEmpty(collectionCheck)) {
      errors['collection_name'] = "Cage ID doesn't exist in this study.";
    } else if (collectionCheck.length > 1) {
      errors['collection_name'] = `${collectionCheck.length} cages exist with the same identifier. Must be unique.`;
    } else {
      animalsToFilter = subjects.filter(({ cage_id }) => collectionCheck[0].id === cage_id);
    }
  } else {
    animalsToFilter = subjects;
  }

  const animal = getAnimalById(animalsToFilter, chosenIdField, idValue);
  if (_isNil(animal)) {
    errors[chosenIdField] = "ID doesn't exist.";
  } else {
    if (_notNil(animal.terminated_at)) {
      errors[chosenIdField] = 'Animal is deceased.';
    }

    tempRow.id = animal.id;
    if (tempMatchedSubjects.includes(tempRow.id)) {
      errors[chosenIdField] = 'This animal has already been mapped in a previous row.';
    } else {
      tempMatchedSubjects.push(tempRow.id);
      callback(tempMatchedSubjects);
    }
  }

  const validateMetrics = validateFields(tempRow.metrics, validationSchema);

  if (validateMetrics) {
    Object.keys(validateMetrics).map((k) => {
      errors[k] = validateMetrics[k];
    });
  }

  Object.entries(tempRow.metrics).map(([m, v]) => {
    if (m === 'weight' && v <= 0) {
      errors[m] = 'Measurement values must be greater than zero.';
    }
  });

  if (!_isEmpty(errors)) {
    tempRow.errors = { ...errors };
  }

  Object.keys(tempRow.metrics).map((m) => {
    if (tempRow.metrics[m] === null && !hasOwnProperty(errors, m)) {
      warnings[m] = 'Cell is empty, no value to import.';
    } else {
      if (hasOwnProperty(tempRow, 'id')) {
        const existingMeasurementsForMatch = existingMeasurements.filter((f) => {
          const variables = f.variables.map((v) => v.name);
          return variables.includes(m) && Number(f.subject_id) === Number(tempRow.id);
        });

        if (!_isEmpty(existingMeasurementsForMatch)) {
          warnings[m] = 'Existing record will be overwritten by this value.';
        }
      }
    }
  });

  if (!_isEmpty(warnings)) {
    tempRow.warnings = { ...warnings };
  }
  return tempRow;
};

export const countErrorsAndWarnings = (reviewData) =>
  reviewData.reduce(
    (counts, row) => {
      if (_notNil(row.errors)) {
        counts.errors += 1;
      }
      if (_notNil(row.warnings)) {
        counts.warnings += Object.keys(row.warnings).length;
      }
      return counts;
    },
    {
      errors: 0,
      warnings: 0,
    }
  );
