import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import UserAvatar from '@/components/UI/UserAvatar';
import { returnUserFromStudy } from '@/helpers';
import { _notNil } from '@/littledash';
import type { DeceasedAnimal } from '@/model/Animal.model';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import type React from 'react';
import { useDispatch } from 'react-redux';

interface DeceasedProps {
  study: Study;
  subject: DeceasedAnimal;
  handleCallback: () => void;
}

interface ModalProps {
  study?: Study;
  editing?: {
    terminated_at: string | Date;
    disease_survivor: boolean;
    termination_value?: string;
    glossary_id: number;
  };
  subjects?: Array<DeceasedAnimal>;
  closeModal: () => void;
  handleCallback: () => void;
}

const Deceased: React.FC<DeceasedProps> = ({ study, subject, handleCallback }) => {
  const { terminated_at, terminated_at_data, disease_survivor } = subject;
  let user;
  if (terminated_at_data?.id) {
    user = returnUserFromStudy(study, terminated_at_data.id);
  }
  const dispatch = useDispatch();

  const openModal = (modal: string, props: ModalProps) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const terminationDataProvided = _notNil(terminated_at_data);
  const terminatedBy = user?.name ?? user?.email ?? terminated_at_data?.email;

  return (
    <>
      <p className="normal lh-title f6 mb3 lh-copy">
        <span className="basier-med near-black">Marked as deceased</span> on{' '}
        {_notNil(terminated_at) && <DateRenderer value={terminated_at} />}{' '}
        {_notNil(terminationDataProvided) && _notNil(terminatedBy) && <>by {terminatedBy}</>}
      </p>
      {terminationDataProvided && (
        <div className="flex mt2">
          {user && <UserAvatar user={user} className="dib mr3" tooltip={user.name ?? user.email} />}
          <div className="dib f6 pa3 br3 br--top--left bg-white">
            <h3 className="basier-med near-black f6 lh-title">{terminated_at_data?.termination_title}</h3>
            {terminated_at_data.termination_value && (
              <p className="normal lh-copy f6">{terminated_at_data.termination_value}</p>
            )}
            {Auth.isWriteUserForStudy(study) && (
              <SelectDropDown title="Actions" className="f6 basier-reg pl0 stateless blue mt2" alignMenu="left">
                <ActionList
                  actions={[
                    {
                      action: () =>
                        openModal('EUTHANISE_SUBJECT', {
                          editing: {
                            terminated_at: terminated_at,
                            termination_value: terminated_at_data.termination_value,
                            glossary_id: Number(terminated_at_data.glossary_id),
                            disease_survivor: disease_survivor ?? false,
                          },
                          subjects: [subject],
                          closeModal,
                          handleCallback,
                        }),
                      name: 'Edit',
                    },
                    {
                      action: () =>
                        openModal('UNDO_EUTHANISE_SUBJECT', {
                          study,
                          subjects: [subject],
                          handleCallback,
                          closeModal,
                        }),
                      name: 'Undo',
                    },
                  ]}
                />
              </SelectDropDown>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Deceased;
