// @ts-nocheck: converted from JS

import { calculatePercentageChange, formatNumber, roundToTwo } from '@/helpers';
import { _notNil } from '@/littledash';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

const NumericDelta = ({ value, valueChange, className = '', percentageOnly = false, hidePercentageChange = false }) => {
  let status = {
    color: 'near-black',
    icon: false,
  };
  if (valueChange > 0) {
    status = {
      color: 'green',
      icon: <RiArrowUpSFill />,
    };
  }
  if (valueChange < 0) {
    status = {
      color: 'red',
      icon: <RiArrowDownSFill />,
    };
  }
  const difference = value - valueChange;
  const percentChange = roundToTwo(calculatePercentageChange(Math.abs(valueChange), Math.abs(difference)));

  return (
    <div className={`lh-title flex items-center ${status.color} ${className}`}>
      {!hidePercentageChange && status.icon ? status.icon : <>&nbsp;</>}
      {!percentageOnly && <>{formatNumber(valueChange)}&nbsp;</>}
      {_notNil(valueChange) && !hidePercentageChange && `(${percentChange}%)`}
    </div>
  );
};
export default NumericDelta;
