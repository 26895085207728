import type { Endpoints } from '@/generated/internal-api/Endpoints';
import InVivoError from '@/model/InVivoError.ts';
import { ApiErrorResponse, ApiResponse, ApiService, ApiServiceProps } from '@/support/ApiService';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { useEffect, useState } from 'react';
import { notAborted, useAbortController } from '../fetch/useAbortController';
import useMountedState from '../fetch/useMountedState';

export interface UseApiHookProps<Endpoint extends keyof Endpoints> extends ApiServiceProps<Endpoint> {
  invokeOnInit?: boolean;
}

export interface UseApiHookOutput<Endpoint extends keyof Endpoints> {
  loading: boolean;
  response?: ApiResponse<Endpoint>;
  invoke: (props?: Omit<UseApiHookProps<Endpoint>, 'endpoint' | 'invokeOnInit'>) => Promise<ApiResponse<Endpoint>>;
  abort: (reason?: string) => void;
}

export const useApiHook = <Endpoint extends keyof Endpoints>(
  props: UseApiHookProps<Endpoint>
): UseApiHookOutput<Endpoint> => {
  const [apiLoading, setApiLoading] = useState(props.invokeOnInit ?? true);
  const [apiResponse, setApiResponse] = useState<ApiResponse<Endpoint>>();
  const isMounted = useMountedState();
  const { abort } = useAbortController();

  useEffect(() => {
    if (props.invokeOnInit ?? true) {
      invoke(props).catch((error) =>
        ExceptionHandler.captureException(
          new InVivoError(`Api Error (Api Hook): ${props?.path}`, {
            cause: error,
            slug: 'use-api-hook',
          })
        )
      );
    }
  }, []);

  const invoke: UseApiHookOutput<Endpoint>['invoke'] = async ({ path, query, body } = props) => {
    const { endpoint } = props;
    setApiLoading(true);
    try {
      const result = await ApiService.call({ endpoint, path, query, body, options: props.options });
      if (isMounted()) {
        setApiLoading(false);
        setApiResponse(result);
      }
      return result;
    } catch (error) {
      const errorResponse = error as ApiErrorResponse<Endpoint>;
      if (isMounted()) {
        if (notAborted(errorResponse.error)) {
          setApiResponse(errorResponse);
        }
        setApiLoading(false);
      }
      return Promise.reject(errorResponse);
    }
  };

  return {
    loading: apiLoading,
    response: apiResponse,
    invoke,
    abort,
  };
};
