// @ts-nocheck: converted from JS

import { FieldTypes, groupTitleValue } from '@/components/FormBuilder/Item.utils';

export const isSelectField = (fieldType, fieldOptions = []) =>
  (fieldType?.toLowerCase() === FieldTypes.select && fieldOptions.length > 3) ||
  fieldType?.toLowerCase() === FieldTypes.multi_select;
export const isRadioField = (fieldType, fieldOptions = []) =>
  (fieldType?.toLowerCase() === FieldTypes.select && fieldOptions.length <= 3) ||
  fieldType?.toLowerCase() === FieldTypes.radio;

const isGroupTitle = (formItem) => formItem.type === groupTitleValue;

/**
 * Convert the FormBuilder submit data into the format of an approval object
 * @param formData
 */
export const mapFormBuilderDataToApprovalData = (formData) => {
  const buildNewGroup = (label) => {
    const group = {
      fields: [],
    };
    if (label) {
      group.label = label;
    }
    return group;
  };
  const buildApprovalField = (formItem, isRadio, isSelect) => {
    const approvalField = {
      type: isRadio ? FieldTypes.radio : formItem.type,
      label: formItem.label,
    };
    if (isSelect || isRadio) {
      approvalField.options = formItem.options;
    }
    if (formItem.hint) {
      approvalField.hint = formItem.hint;
    }
    if (formItem.required !== undefined && formItem.required !== null) {
      approvalField.required = formItem.required;
    }
    return approvalField;
  };
  const groupFormItemsReducer = (groups, formItem) => {
    if (isGroupTitle(formItem)) {
      groups.push(buildNewGroup(formItem.label));
      return groups;
    }
    const isSelect = isSelectField(formItem.type, formItem.options);
    const isRadio = isRadioField(formItem.type, formItem.options);

    const approvalField = buildApprovalField(formItem, isRadio, isSelect);
    const group = groups[groups.length - 1];
    if (!group) {
      groups.push(buildNewGroup());
    }

    groups[groups.length - 1].fields.push(approvalField);
    return groups;
  };
  const hasFields = (group) => Boolean(group.fields?.length);
  const groups = formData.reduce(groupFormItemsReducer, []).filter(hasFields);
  return {
    groups,
  };
};

/**
 * Convert an approval object into the structure to populate a FormBuilder value
 * @param approvalForm
 */
export const mapApprovalDataToFormBuilderData = (approvalForm) => {
  const buildGroup = (label) => ({
    label: label || '',
    type: groupTitleValue,
  });
  const buildFormItem = (approvalField) => {
    const isSelect = isSelectField(approvalField.type, approvalField.options);
    const isRadio = isRadioField(approvalField.type, approvalField.options);
    const mappedFormItem = {
      hint: approvalField?.hint ?? '',
      type: isRadio ? FieldTypes.radio : approvalField.type,
      label: approvalField.label,
    };
    if (approvalField.required !== undefined && approvalField.required !== null) {
      mappedFormItem.required = approvalField.required;
    }
    if (isSelect || isRadio) {
      mappedFormItem.options = approvalField.options;
    }
    return mappedFormItem;
  };
  const groupsToFormItemsReducer = (formItems, group) => {
    if (group.label) {
      formItems.push(buildGroup(group.label));
    }
    const fields = group.fields.map(buildFormItem);
    formItems.push(...fields);
    return formItems;
  };

  return approvalForm.groups.reduce(groupsToFormItemsReducer, []);
};
