// @ts-nocheck: converted from JS

export const FieldTypes = {
  text: 'text',
  select: 'select',
  multi_select: 'multi_select',
  date: 'date',
  radio: 'radio',
  textarea: 'textarea',
  lookup_select: 'lookup_select',
  lookup_multi_select: 'lookup_multi_select',
  numeric: 'numeric',
};

export const showSelectField = (fieldType) =>
  fieldType?.toLowerCase() === FieldTypes.select ||
  fieldType?.toLowerCase() === FieldTypes.multi_select ||
  fieldType?.toLowerCase() === FieldTypes.radio;

export const fieldTypeOptions = [
  {
    value: FieldTypes.text,
    label: 'Text',
  },
  {
    value: FieldTypes.textarea,
    label: 'Long text',
  },
  {
    value: FieldTypes.select,
    label: 'Select',
  },
  {
    value: FieldTypes.multi_select,
    label: 'Multiselect',
  },
  {
    value: FieldTypes.date,
    label: 'Date',
  },
];

export const groupTitleValue = 'group_title';

export const groupFieldTypeOptions = [
  {
    value: groupTitleValue,
    label: 'Group title',
  },
  ...fieldTypeOptions,
];
