// @ts-nocheck: converted from JS

import { useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { _isEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { DragHintBanner } from '@/components/UI/Banner/Reusable';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import List from '@/components/UI/List';
import { asSortableListItem } from '@/components/UI/List/List';
import SearchSelect from '@/components/UI/SearchSelect';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { errorToast, successToast } from '@/helpers';

const StudyGroups = ({ groupMetadata, savedGroupData }) => {
  const defaultSection = {
    metadata: groupMetadata || [],
  };

  const getSelectedIds = () => {
    const savedIds = savedGroupData?.map((s) => s.schema.id);
    return defaultSection.metadata.filter((s) => savedIds.includes(s.id)).map((s) => s.id);
  };

  const metaIsRequired = (id) => {
    if (id) {
      return savedGroupData.find((meta) => meta.id === id)?.required;
    }
    return false;
  };

  const [selected, setSelected] = useState(getSelectedIds());
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [_, setSwapped] = useState();

  const formMethods = useForm({
    defaultValues: {
      metadata: savedGroupData
        .filter((data) => selected.includes(data.id))
        .map((data) => ({
          value: { ...data.schema, required: metaIsRequired(data.id) },
        })),
    },
  });

  const { control, handleSubmit } = formMethods;

  const { fields, swap, append, remove } = useFieldArray({
    control,
    name: 'metadata',
  });

  // React hook form field swap can only be called once per render (see https://react-hook-form.com/api/usefieldarray/#rules)
  // https://overwatchresearch.atlassian.net/browse/OR-2278 - Duplicates created in DnD component without this
  const handleSwap = (from, to) => {
    setSwapped(() => {
      swap(from, to);
      return { from, to };
    });
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    const metadata = data.metadata?.map((meta) => meta.value) || [];
    try {
      await Http.put(apiRoute('team.studies.groups'), {
        metadata,
      });
      successToast('Group metadata saved.');
    } catch (error) {
      setApiError(error);
      errorToast('There was a problem with your submission.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleSelect = ({ type, value }) => {
    if (type === 'ADDED') {
      const metadata = groupMetadata.find((meta) => Number(meta.id) === Number(value));
      if (metadata) {
        append({
          value: {
            ...metadata,
          },
        });
      }
    } else {
      const index = fields.findIndex((field) => Number(field.value.id) === Number(value));
      remove(index);
    }
  };

  const handleSelectAll = () => {
    remove();
    append(groupMetadata.map((meta) => ({ value: { ...meta, required: false } })));
  };

  const handleClearSelection = () => {
    remove();
  };

  return (
    <FormProvider {...formMethods}>
      {apiError && <ApiErrorBanner error={apiError} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="mb3">
            <Header mainHeaderText="Groups" />
            <h3 className="f5 normal lh-title pb2 dark-gray mb3">
              Define what group metadata fields show during study creation and what is required by default.{' '}
            </h3>
          </div>
          <div className="w-50">
            {fields && fields.length > 1 && (
              <div className="pb3">
                <DragHintBanner />
              </div>
            )}
            <div className="flex w-100">
              <div className="w-50">
                <SearchSelect
                  className="ui-card"
                  sections={[
                    {
                      items: groupMetadata,
                    },
                  ]}
                  selected={selected}
                  setSelected={setSelected}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onClearSelection={handleClearSelection}
                />
              </div>
              <div className="w-50 pl3">
                <div className="ui-card pa3 overflow-auto" style={{ height: 409 }}>
                  {!_isEmpty(fields) ? (
                    <FormProvider {...formMethods}>
                      <List
                        canAmend={false}
                        data={fields}
                        renderItem={asSortableListItem({
                          canSort: fields.length > 1,
                          sortableType: 'study-section-metadata-item',
                          moveCallback: handleSwap,
                        })(MetadataFormItem)}
                        itemProps={{
                          className: 'bb b--moon-gray pb3',
                        }}
                      />
                    </FormProvider>
                  ) : (
                    <div className="bg-light-silver pa3 br2 w-100 h-100 items-center flex">
                      <h3 className="lh-title f5 w-100 tc">Select metadata for this section</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="pv3">
              <Button submit disabled={submitting}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const MetadataFormItem = ({ value, index }) => {
  const { control } = useFormContext();

  return (
    <Controller
      key={index}
      defaultValue={value}
      name={`metadata[${index}].value`}
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div>
          <h3 className="f6 normal lh-title pb2">{value.title}</h3>
          <Checkbox
            className="pointer"
            type="checkbox"
            label="Required"
            name={value.id}
            checked={value.required}
            onChange={() =>
              onChange({
                ...value,
                required: !value.required,
              })
            }
          />
        </div>
      )}
    />
  );
};

export default StudyGroups;
