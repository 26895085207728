/** Single responsibility validators, usually for forms */

/** Number validators */
export const isInteger = (value: number): boolean => Number.isInteger(value);
export const isPositiveInteger = (value: number): boolean => Number.isInteger(value) && value > 0;
export const isNegativeInteger = (value: number): boolean => Number.isInteger(value) && value < 0;

/** Date validators */
export const isDateGreaterThan = (compareDateTime: string, referenceDateTime: string): boolean =>
  new Date(compareDateTime).getTime() > new Date(referenceDateTime).getTime();
