import type { Endpoints, HttpMethod } from '@/generated/internal-api/Endpoints';
import type { EndpointParameters as LegacyEndpointParameters } from '@/generated/internal-api/LegacyApiRoutes.ts';
import { Endpoints as LegacyEndpoints } from '@/generated/internal-api/LegacyApiRoutes.ts';
import {
  EndpointParameters as WebRouteParameters,
  Endpoints as WebRoutes,
} from '@/generated/internal-api/LegacyWebRoutes.ts';
import { endpointPattern, hydrateUrlTemplate, updateQueryParams } from './RouteService.util';

type GenericQueryParameters = Record<string, string | number | boolean | Array<string | number | boolean>>;

interface ApiRouteProps<Endpoint extends keyof Endpoints> {
  endpoint: Endpoint;
  path: Endpoints[Endpoint]['path'];
  query?: Endpoints[Endpoint]['query'] extends undefined
    ? GenericQueryParameters
    : GenericQueryParameters | Endpoints[Endpoint]['query'];
}

interface LegacyRouteProps<Endpoint extends keyof LegacyEndpointParameters> {
  apiRoute: Endpoint;
  path?: LegacyEndpointParameters[Endpoint];
  query?: GenericQueryParameters;
}

interface WebRouteProps<Route extends keyof WebRouteParameters> {
  route: Route;
  path?: WebRouteParameters[Route];
  query?: GenericQueryParameters;
}
interface ApiRoute {
  method: HttpMethod;
  url: URL;
}

export class RouteService {
  static api<Endpoint extends keyof Endpoints>({ endpoint, path, query }: ApiRouteProps<Endpoint>): ApiRoute {
    const { method, path: templateUrl } = (endpointPattern.exec(endpoint)?.groups ?? {}) as {
      method: HttpMethod;
      path: string;
    };
    const url = new URL(
      hydrateUrlTemplate(templateUrl, path ?? {}),
      RouteService.#absoluteURL(AppConfig.internalApiUrl)
    );
    updateQueryParams(url.searchParams, query);
    return { method, url };
  }

  static legacyApi<Endpoint extends keyof LegacyEndpointParameters>({
    apiRoute,
    path,
    query,
  }: LegacyRouteProps<Endpoint>): Omit<ApiRoute, 'method'> {
    const url = new URL(
      hydrateUrlTemplate(LegacyEndpoints[apiRoute], path ?? {}),
      RouteService.#absoluteURL(AppConfig.legacyInternalApiUrl)
    );
    updateQueryParams(url.searchParams, query);
    return { url };
  }

  static web<Route extends keyof WebRouteParameters>({
    route,
    path,
    query,
  }: WebRouteProps<Route>): { route: string; url: URL } {
    const url = new URL(hydrateUrlTemplate(WebRoutes[route], path ?? {}).replace(/^\//, ''), window.location.origin);
    updateQueryParams(url.searchParams, query);
    return {
      url,
      route: `${url.pathname}${url.search}`,
    };
  }

  static #absoluteURL(value: string): string {
    return value.endsWith('/') ? value : `${value}/`;
  }
}
