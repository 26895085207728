// @ts-nocheck: converted from JS

import Deceased from '@/components/Subjects/Show/Notifications/Types/Deceased';
import Banner from '@/components/UI/Banner';
import { _notNil } from '@/littledash';
import { modalAction } from '@/utils/modal';
import { useDispatch } from 'react-redux';

const AnimalEvents = ({ animal, study, fetchAnimal }) => {
  const dispatch = useDispatch();
  const { closeModal } = modalAction(dispatch);
  const handleCallback = async () => {
    await fetchAnimal();
    closeModal();
  };

  return (
    <>
      {_notNil(animal?.terminated_at) && (
        <Banner info className="mw6 mt3" dismiss={false}>
          <Deceased study={study} subject={animal} handleCallback={handleCallback} />
        </Banner>
      )}
    </>
  );
};

export default AnimalEvents;
