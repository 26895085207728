import { ErrorMessage } from '@hookform/error-message';
import { featuresSelector } from '@/support/Selectors';
import { createSelector } from '@reduxjs/toolkit';
import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';

interface ReasonForChangeProps {
  isDelete?: boolean;
}

const featureSelectorTwo = createSelector([featuresSelector], (features) => ({
  reasonForChange: features?.reason_for_change ?? false,
}));

const ReasonForChange: FC<ReasonForChangeProps> = ({ isDelete }) => {
  const { reasonForChange } = useSelector(featureSelectorTwo);

  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      {reasonForChange && (
        <div className="pt2" data-testid="reason-for-change-container">
          <label htmlFor="reason_for_change" data-testid="reason-for-label">
            Reason for {isDelete ? 'deletion' : 'change'} <span className="blue">*</span>
          </label>
          <Controller
            name="reason_for_change"
            control={control}
            rules={{
              required: `You must provide a reason for the ${isDelete ? 'deletion' : 'change'}`,
              minLength: {
                value: 3,
                message: 'Please enter at least 3 characters',
              },
              maxLength: {
                value: 255,
                message: 'Please enter less than 256 characters',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <textarea
                defaultValue={value}
                onChange={onChange}
                id="reason_for_change"
                style={{ resize: 'none' }}
                className={`${errors.reason_for_change ? 'input__error' : ''} mb0`}
                data-testid="reason-for-change-field"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`reason_for_change`}
            render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
          />
        </div>
      )}
    </>
  );
};

export default ReasonForChange;
