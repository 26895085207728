import type { FC } from 'react';
import { DateUtils, RenderDateOptions, RenderDateTimeOptions } from '@/utils/Date.utils';
import type { ISODate, ISODateTime, Nullable } from '@/model/Common.model';

export const DateRenderer: FC<{ value: Nullable<ISODate> } & RenderDateOptions> = ({ value, ...options }) => (
  <>{DateUtils.renderDate(value, options)}</>
);
export const DateTimeRenderer: FC<{ value: Nullable<ISODateTime> } & RenderDateTimeOptions> = ({
  value,
  ...options
}) => <>{DateUtils.renderDateTime(value, options)}</>;
