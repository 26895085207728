import { StepFormPayloadProps, StepRenderProps } from '@/components/Modals/AddTask/AddTask.model.ts';
import styles from '@/components/Modals/AddTask/AddTask.module.scss';
import { SelectionReorder } from '@/components/Modals/SetupWorkflow/SelectionReorder.tsx';
import Button from '@/components/UI/Button';
import Switch from '@/components/UI/FormElements/Switch';
import Select from '@/components/UI/Select';
import { components } from '@/generated/internal-api/openapi-types';
import { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { MeasurementOption } from '@/components/Modals/SetupWorkflow/Forms/Measurements.tsx';

type WeightPercentageChange = components['schemas']['WeightPercentageChange.schema'];
type WorkflowMeasurementAssignIdentifier = components['schemas']['WorkflowMeasurementAssignIdentifier.schema'];

const percentageChangeFromOptions: Array<{ value: WeightPercentageChange; label: string }> = [
  { value: 'first_measurement', label: 'First measurement' },
  { value: 'latest_measurement', label: 'Latest measurement' },
  { value: 'tracking_date', label: 'Tracking date' },
];
export const assignIdentifierOptions: Array<{ value: WorkflowMeasurementAssignIdentifier; label: string }> = [
  { value: 'tag', label: 'Tag' },
  { value: 'tail', label: 'Tail' },
  { value: 'ear', label: 'Ear' },
  { value: 'donor', label: 'Donor' },
];

const MeasurementForm: FC<StepRenderProps> = ({
  onSubmit,
  onCancel,
  execution,
  submitButtonText,
  cancelButtonText,
}) => {
  const formMethods = useFormContext<StepFormPayloadProps>();
  const selectedMeasurements = (execution?.measurements ?? []).reduce((acc: Array<MeasurementOption>, measurement) => {
    if ((formMethods.watch('execution.measurements') ?? []).includes(measurement.id)) {
      acc.push({ value: measurement.id, label: measurement.title });
    }
    return acc;
  }, []);
  return (
    <form
      onSubmit={formMethods.handleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="MeasurementForm"
    >
      <div className="flex flex-column">
        <div className="bt" data-test-element="measurement-selection-container">
          <Controller
            control={formMethods.control}
            name="execution.measurements"
            rules={{
              validate: (value) => ((value?.length ?? 0) > 0 ? true : 'At least one measurement must be selected'),
            }}
            render={({ field: { value, onChange } }) => (
              <SelectionReorder
                options={execution?.measurements ?? []}
                selectionTitle="measurement"
                selection={value}
                selectionChange={onChange}
              />
            )}
          />
        </div>
        <div className="pa3 bt b--moon-gray flex justify-between items-center">
          <label className="mb0 f6">Change default cursor position</label>
          <Controller
            name="execution.cursor_position"
            control={formMethods.control}
            render={({ field: { value, onChange } }) => (
              <Select
                options={selectedMeasurements}
                defaultValue={selectedMeasurements.find((option) => option.value === value)}
                placeholder="None"
                onChange={(option) => onChange(option?.value ?? null)}
                disabled={selectedMeasurements.length === 0}
                isClearable
                isSearchable
                isMulti={false}
              />
            )}
          />
        </div>
        <div className="pa3 flex bt justify-between items-center">
          <label className="mb0 f6">Weight percentage change from</label>
          <Controller
            name="execution.weight_percentage_change"
            defaultValue={percentageChangeFromOptions[0].value}
            control={formMethods.control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={percentageChangeFromOptions}
                defaultValue={percentageChangeFromOptions.find((option) => option.value === value)}
                onChange={(option) => onChange(option?.value)}
                isSearchable
                isMulti={false}
              />
            )}
          />
        </div>
        <div className="pa3 bt flex justify-between items-center">
          <label className="mb0 f6">Assign identifiers</label>
          <Controller
            control={formMethods.control}
            name="execution.assign_identifier"
            render={({ field: { value, onChange } }) => (
              <Select
                options={assignIdentifierOptions}
                defaultValue={assignIdentifierOptions.find((option) => option.value === value)}
                placeholder="None"
                onChange={(option) => onChange(option?.value ?? null)}
                isClearable
                isSearchable
                isMulti={false}
              />
            )}
          />
        </div>
      </div>
      <div className="pa3 bt">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

const SampleForm: FC<StepRenderProps> = ({ onSubmit, onCancel, submitButtonText, cancelButtonText, execution }) => {
  const formMethods = useFormContext<StepFormPayloadProps>();

  return (
    <form
      onSubmit={formMethods.handleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="SampleForm"
    >
      <div className="flex flex-column">
        <div className="bt" data-test-element="sample-selection-container">
          <Controller
            control={formMethods.control}
            name="execution.samples"
            rules={{
              validate: (value) => ((value?.length ?? 0) > 0 ? true : 'At least one sample must be selected'),
            }}
            render={({ field: { value, onChange } }) => (
              <SelectionReorder
                options={execution?.samples ?? []}
                selectionTitle="sample"
                selection={value}
                selectionChange={onChange}
              />
            )}
          />
        </div>
        <div className="pa3 flex bt justify-between items-center">
          <label className="mb0 f6">Auto generate Sample ID</label>
          <Controller
            control={formMethods.control}
            name="execution.auto_generate_sample_id"
            render={({ field: { value, onChange, onBlur } }) => (
              <Switch value={value ?? false} onChange={onChange} onBlur={onBlur} />
            )}
          />
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit" disabled={!formMethods.formState.isValid}>
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};
const ObservationForm: FC<StepRenderProps> = ({
  onSubmit,
  onCancel,
  execution,
  submitButtonText,
  cancelButtonText,
}) => {
  const formMethods = useFormContext<StepFormPayloadProps>();
  return (
    <form
      onSubmit={formMethods.handleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="ObservationForm"
    >
      <div className="flex flex-column">
        <div className="bt" data-test-element="observation-selection-container">
          <Controller
            control={formMethods.control}
            name="execution.observations"
            rules={{
              validate: (value) => ((value?.length ?? 0) > 0 ? true : 'At least one observation must be selected'),
            }}
            render={({ field: { value, onChange } }) => (
              <SelectionReorder
                options={execution?.observations ?? []}
                selectionTitle="observation"
                selection={value}
                selectionChange={onChange}
              />
            )}
          />
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};
const DosingForm: FC<StepRenderProps> = ({ onSubmit, onCancel, execution, submitButtonText, cancelButtonText }) => {
  const formMethods = useFormContext<StepFormPayloadProps>();
  return (
    <form
      onSubmit={formMethods.handleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="DosingForm"
    >
      <div className="flex flex-column">
        <div className="ba" data-test-element="treatment-selection-container">
          <Controller
            control={formMethods.control}
            name="execution.treatments"
            rules={{
              validate: (value) => ((value?.length ?? 0) > 0 ? true : 'At least one treatment must be selected'),
            }}
            render={({ field: { value, onChange } }) => (
              <SelectionReorder
                options={execution?.treatments ?? []}
                selectionTitle="treatment"
                selection={value}
                selectionChange={onChange}
              />
            )}
          />
        </div>
        <div className="pa3 flex justify-between items-center">
          <label className="mb0 f6">Skip dosing for animals that do not require any treatments</label>
          <Controller
            control={formMethods.control}
            name="execution.skip_dosing_for_unrequired_animals"
            render={({ field: { value, onChange, onBlur } }) => (
              <Switch value={value ?? false} onChange={onChange} onBlur={onBlur} />
            )}
          />
        </div>
        <div className="pa3 flex bt justify-between items-center">
          <label className="mb0 f6">Use new body weight</label>
          <Controller
            control={formMethods.control}
            name="execution.use_new_body_weight"
            render={({ field: { value, onChange, onBlur } }) => (
              <Switch value={value ?? false} onChange={onChange} onBlur={onBlur} />
            )}
          />
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

export const AddTaskExecutionForm: FC<StepRenderProps> = (props) => {
  const formMethods = useFormContext<StepFormPayloadProps>();
  const taskType = useWatch({ control: formMethods.control, name: `overview.type` });
  switch (taskType) {
    case 'measurement': {
      return <MeasurementForm {...props} />;
    }
    case 'sample': {
      return <SampleForm {...props} />;
    }
    case 'observation': {
      return <ObservationForm {...props} />;
    }
    case 'dosing': {
      return <DosingForm {...props} />;
    }
    default: {
      return null;
    }
  }
};
