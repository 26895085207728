// @ts-nocheck: converted from JS

import { _isEmpty } from '@/littledash';

const groupUpdated = (previousGroup, currentGroup) =>
  previousGroup.updated_at !== currentGroup.updated_at ||
  previousGroup.name !== currentGroup.name ||
  previousGroup.color !== currentGroup.color ||
  previousGroup.max_subjects !== currentGroup.max_subjects ||
  previousGroup.no !== currentGroup.no;

/**
 *
 * @param initialGroups {Array<StudyGroup>}
 * @param editedGroups {Array<StudyGroup>}
 * @returns {Array<{type: 'create' | 'update' | 'delete' , id?: number, payload?: StudyGroup}>}
 */
export const generateGroupBulkUpdateOperations = (initialGroups = [], editedGroups = []) => {
  const initialGroupMap = new Map(initialGroups.map((group) => [group.id, group]));

  const { editedGroupIds, operations } = editedGroups.reduce(
    (acc, group) => {
      const { id, ...payload } = group;
      if (_isEmpty(id)) {
        return { ...acc, operations: [...acc.operations, { type: 'create', payload }] };
      }
      acc.editedGroupIds.add(id);
      if (groupUpdated(initialGroupMap.get(id), group)) {
        return { ...acc, operations: [...acc.operations, { type: 'update', id, payload }] };
      }
      return acc;
    },
    { editedGroupIds: new Set(), operations: [] }
  );
  return initialGroups.reduce(
    (acc, group) => (editedGroupIds.has(group.id) ? acc : [{ type: 'delete', id: group.id }, ...acc]),
    operations
  );
};
