// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Switch from '@/components/UI/FormElements/Switch';
import { hasOwnProperty } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { ExistingMeasurement } from '@/model/Measurement.model';
import type { Study } from '@/model/Study.model';
import React, { useEffect, useState } from 'react';
import type { ImportCageMatch, MatchData, ValidationState } from '../../Importer.utils';
import {
  constructMatchData,
  countErrorsAndWarnings,
  validateAndReturnRowWithId,
  validationSchema,
} from './Review.utils';
import ReviewTable from './ReviewTable';

export interface ReviewData {
  id: ID;
  metrics: Record<string, string>;
  name: string;
  row: number;
  warnings: Record<string, string>;
}

interface ReviewProps {
  study: Study;
  collections: Array<ImportCageMatch>;
  fileData: Array<Record<string, string | number>>;
  matchData: Record<string, MatchData>;
  reviewData: Array<ReviewData>;
  setReviewData: React.Dispatch<React.SetStateAction<ReviewData>>;
  nextStep: (nextStep: string) => void;
  backStep: (backStep: string) => void;
  existingMeasurements: Array<ExistingMeasurement>;
  validationState: ValidationState;
  subjects: Array<Animal>;
}

const Review: React.FC<ReviewProps> = ({
  study,
  collections,
  fileData,
  matchData,
  reviewData,
  setReviewData,
  nextStep,
  backStep,
  existingMeasurements,
  validationState,
  subjects,
}) => {
  const [onlyShowProblems, setOnlyShowProblems] = useState(false);
  const { cageConfirmed, chosenIdField } = validationState;

  useEffect(() => {
    if (_isNotEmpty(subjects)) {
      const schema = validationSchema(study.settings.calculations);
      const matchedRows = constructMatchData(fileData, matchData);
      let matchedSubjects = [];
      const reviewedRows = matchedRows.map((row) =>
        validateAndReturnRowWithId(
          row,
          collections,
          subjects,
          schema,
          existingMeasurements,
          matchedSubjects,
          (ids) => (matchedSubjects = ids),
          cageConfirmed,
          chosenIdField
        )
      );
      setReviewData(reviewedRows);
    }
  }, [subjects]);

  const filterRows = (data) => {
    if (onlyShowProblems) {
      return data.filter((d) => d.errors || d.warnings);
    } else {
      return data;
    }
  };

  return (
    <div className="ui-card">
      <ReviewHeader onlyShowProblems={onlyShowProblems} setOnlyShowProblems={setOnlyShowProblems} />
      {!onlyShowProblems && <ReviewNotifications reviewData={reviewData} />}
      {_isNotEmpty(filterRows(reviewData)) ? (
        <ReviewTable study={study} data={filterRows(reviewData)} />
      ) : (
        <div className="pv4 tc near-black lh-copy f5 bt b--moon-gray">No issues to report.</div>
      )}
      <div className="pv3 ph4 bt b--moon-gray">
        <ReviewActions nextStep={nextStep} backStep={backStep} reviewData={reviewData} />
      </div>
    </div>
  );
};

interface ReviewActionsProps {
  nextStep: (nextStep: string) => void;
  backStep: (backStep: string) => void;
  reviewData: Array<ReviewData>;
}

const ReviewActions: React.FC<ReviewActionsProps> = ({ nextStep, backStep, reviewData }) => {
  const validRows = reviewData.filter((r) => !hasOwnProperty(r, 'errors'));

  return (
    <div className="flex flex-wrap justify-between">
      <Button className="mr2" plain onClick={() => backStep('match')}>
        Back
      </Button>
      <span
        data-tooltip-id="global-tooltip-id"
        data-tooltip-content={validRows.length === 0 ? 'Errors are present in all rows, none can be imported.' : ''}
      >
        <Button disabled={validRows.length === 0} onClick={() => nextStep('complete')}>
          Continue
        </Button>
      </span>
    </div>
  );
};

interface ReviewHeaderProps {
  onlyShowProblems: boolean;
  setOnlyShowProblems: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReviewHeader: React.FC<ReviewHeaderProps> = ({ onlyShowProblems, setOnlyShowProblems }) => {
  return (
    <div className="pa4 flex items-top flex-wrap justify-between">
      <h3 className="lh-title basier-reg normal f5 f4-l">Review import</h3>
      <div className="flex items-top">
        <Switch onChange={setOnlyShowProblems} value={onlyShowProblems} />
        <p className="f6 near-black ml2 lh-copy">Only show rows with warnings or errors.</p>
      </div>
    </div>
  );
};

interface ReviewNotificationsProps {
  reviewData: Array<ReviewData>;
}

const ReviewNotifications: React.FC<ReviewNotificationsProps> = ({ reviewData }) => {
  const result = countErrorsAndWarnings(reviewData);

  return (
    <div className="flex flex-wrap mh4">
      {result.errors > 0 && (
        <Banner critical className="mw6 mr4 mb4">
          <h2 className="normal lh-title f5 pb1">
            {result.errors} row{result.errors > 1 && 's'} with errors detected in imported data.
          </h2>
          <p className="f6 lh-copy">
            Rows with errors will not be imported. Please review and determine if you would like to proceed.
          </p>
        </Banner>
      )}

      {result.warnings > 0 && (
        <Banner warning className="mw6 mb4">
          <h2 className="normal lh-title f5 pb1">
            {result.warnings} warning{result.warnings > 1 && 's'} detected in imported data.
          </h2>
          <p className="f6 lh-copy">
            Rows will still be imported but will overwrite the warning cell. Please review and determine if you would
            like to proceed.
          </p>
        </Banner>
      )}
    </div>
  );
};

export default Review;
