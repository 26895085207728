// @ts-nocheck: converted from JS

import { ErrorMessage } from '@hookform/error-message';
import logo from '@/assets/images/logo.svg';
import { Fragment, useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import Link from '@/components/UI/Link';
import './CreateAccount.scss';

const CreateAccount = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const { timezones, currentUser } = useSelector(({ user: { currentUser, timezones } }) => ({
    timezones,
    currentUser,
  }));

  const getTimezones = async (signal: AbortSignal) => {
    setLoading(true);
    try {
      const { data } = await Http.get<Array<string>>(apiRoute('config.timezones'), { signal });
      dispatch({
        type: 'USER_SET_TIMEZONES',
        timezones: data,
      });
    } catch (error) {
      // intentionally empty
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getTimezones(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Fragment>
      <header className="no-auth-header center w-100 mv4 mt6-l">
        <img className="dib" src={logo} style={{ width: 150, height: 60 }} alt="Benchling In Vivo" />
      </header>
      <div className="center createAccount">
        <div className="ui-card pa3 mb3">
          <h3>Create Your Account</h3>
          {loading ? <div></div> : <CreateAccountForm currentUser={currentUser} timezones={timezones} />}
        </div>
        <Link
          className="link blue"
          data-testid="logout-link"
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
            dispatch({ type: 'TEAM_SET_TEAM', team: null });
            dispatch({ type: 'USER_SET_CURRENT_USER', currentUser: null });
          }}
        >
          &larr; Log out
        </Link>
      </div>
    </Fragment>
  );
};

const CreateAccountForm = ({ currentUser, timezones }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { closeModal } = modalAction(useDispatch());

  const history = useHistory();
  const { handleSubmit, register, watch, errors } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: currentUser.email,
      timezone: currentUser.timezone === 'UTC' ? DateUtils.browserTimezone() : currentUser.timezone,
    },
  });
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await Http.put(apiRoute('user.update'), data);
      closeModal(); // Close timezone checker modal already opened when TZ invalid
      dispatch({
        type: 'USER_SET_CURRENT_USER',
        currentUser: { ...currentUser, name: data.name, timezone: data.timezone },
        teams: data.teams,
      });
      history.push(webRoute('dashboard'));
    } catch (error) {
      // intentionally empty
    } finally {
      setLoading(false);
    }
  };

  const MINIMUM_ZXCVBN_SCORE = 2;

  const checkPasswordStrength = async (password) => {
    const { data } = await Http.post(apiRoute('password_resets.evaluate'), {
      password,
    });
    if (data.score < MINIMUM_ZXCVBN_SCORE) {
      return `Password too weak: ${data.feedback.suggestions.join(' ')}`;
    } else {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test-component="CreateAccountForm" data-test-element="container">
      <div>
        <label>Email</label>
        <input type="text" name="email" ref={register} disabled />
      </div>
      <div className={errors.name && 'hasError'}>
        <label>Full Name</label>
        <input
          type="text"
          name="name"
          data-test-component="CreateAccountForm"
          data-test-element="name-input"
          disabled={loading}
          ref={register({
            required: "Name can't be blank",
          })}
          className={`${errors.name ? 'input__error' : ''}`}
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
      <div className={errors.timezone ? 'hasError' : ''}>
        <label>Timezone</label>
        <select
          style={{ marginBottom: 0 }}
          name="timezone"
          data-test-component="CreateAccountForm"
          data-test-element="timezone-select"
          ref={register({
            required: 'Required',
            validate: {
              validTimezone: (value) => (timezones.includes(value) ? true : `Unsupported timezone [${value}]`),
            },
          })}
        >
          {timezones.map((timezone) => (
            <option key={timezone} value={timezone}>
              {timezone}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="timezone"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>

      <div className={errors.password ? 'hasError mt3' : 'mt3'}>
        <label>Password</label>
        <input
          name="password"
          type="password"
          data-test-component="CreateAccountForm"
          data-test-element="password-input"
          disabled={loading}
          className={`${errors.password && 'input__error'}`}
          ref={register({
            required: 'Enter a new password',
            validate: {
              isStrongPassword: checkPasswordStrength,
            },
          })}
        />
        <ErrorMessage
          style={{ marginBottom: 19 }}
          errors={errors}
          name="password"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
      <div className={errors.password_confirmation ? 'hasError' : ''}>
        <label>Confirm Password</label>
        <input
          name="password_confirmation"
          type="password"
          data-test-component="CreateAccountForm"
          data-test-element="password-confirm-input"
          className={`${errors.password_confirmation && 'input__error'}`}
          ref={register({
            required: 'Enter your password again',
            validate: (value) => value === watch('password') || 'Must be the same as your new password.',
          })}
        />
        <ErrorMessage
          errors={errors}
          name="password_confirmation"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>

      <button type="submit" disabled={loading} data-test-component="CreateAccountForm" data-test-element="save-button">
        Save
      </button>
    </form>
  );
};

export default CreateAccount;
