import Button from '@/components/UI/Button/Button';
import { AlertUpdateEventHandler, CriticalAlert, WarningAlert } from '@/utils/alerts/Alert';
import type { AlertNotificationData } from '@/utils/alerts/useAlert';
import { AlertService, useAlert } from '@/utils/alerts/useAlert';
import React, { useMemo, useState } from 'react';
import styles from './AlertContainer.module.scss';

const WarningAlerts: React.FC<{ alerts: Array<AlertNotificationData> }> = ({ alerts = [] }) => (
  <div className={styles.warningAlertsContainer} data-test-component="WarningAlerts" data-test-element="container">
    {alerts.map(({ animalName, cageName, alert, studyId, calculation }) => (
      <WarningAlert
        key={alert.id}
        alert={alert}
        animalName={animalName}
        cageName={cageName}
        studyId={studyId}
        calculation={calculation}
      />
    ))}
  </div>
);

export const CriticalAlerts: React.FC<{ alerts: Array<AlertNotificationData> }> = ({ alerts = [] }) => {
  const [hidden, setHidden] = useState(false);
  const groupedAlerts = useMemo(
    () =>
      alerts.reduce<Record<string, Array<AlertNotificationData>>>((acc, alertData) => {
        const groupTitle = `${alertData.cageName} · ${alertData.animalName}`;
        return { ...acc, [groupTitle]: [...(acc[groupTitle] ?? []), alertData] };
      }, {}),
    [alerts]
  );

  const handleUpdate: AlertUpdateEventHandler = async (action) => {
    switch (action.type) {
      case 'deferred':
        AlertService.alertDeferred({ alerts: [action.data], dismiss: false });
        break;
      case 'resolved': {
        AlertService.alertResolved({ alerts: [action.data], dismiss: false });
        break;
      }
    }
  };
  const handleClose = async () => {
    setHidden(true);
    AlertService.dismissAlert(...alerts.map(({ alert }) => alert));
  };

  return (
    <div className={`${styles.criticalAlertsContainer} ${hidden ? styles.isHidden : ''} `}>
      <div className={`${styles.criticalAlertsContent} br2 bg-white`}>
        <div className={`${styles.criticalAlertsItems}`}>
          {Object.entries(groupedAlerts).map(([groupTitle, groupAlerts]) => (
            <div className="pa3">
              <div className="fw5 pr2 pb1 pt1 mb2">{groupTitle}</div>
              {groupAlerts.map(({ animalName, cageName, alert, studyId, calculation }) => (
                <CriticalAlert
                  key={alert.id}
                  alert={alert}
                  animalName={animalName}
                  cageName={cageName}
                  studyId={studyId}
                  calculation={calculation}
                  onUpdate={handleUpdate}
                />
              ))}
            </div>
          ))}
        </div>
        <div className="mt3 mb3 ml3">
          <Button onClick={handleClose}>
            <span>Close</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export const AlertContainer = () => {
  const { alerts, counts } = useAlert();
  if (counts.critical.total > 0) {
    return <CriticalAlerts alerts={alerts} />;
  }
  if (counts.warn.total > 0) {
    return <WarningAlerts alerts={alerts} />;
  }
  return null;
};
