// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { ErrorMessage } from '@hookform/error-message';
import { useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import useCredentials from '../../Team/ApiKeys/useCredentials';

const AddApiKey = ({ closeModal, handleCallback, scopes }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const { addApiKey } = useCredentials();

  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: {
      scopes: [],
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    return addApiKey(data)
      .then((res) => {
        closeModal();
        const { client_id, client_secret } = res.body;
        handleCallback(client_id, client_secret);
      })
      .catch((error) => {
        setLoading(false);
        setApiError(error);
      });
  };

  const sections = useMemo(() => [{ items: scopes }], [scopes]);

  return (
    <div className="center mw6 ui-card">
      <div className="pa3 bg-white">
        <h2 className="normal lh-title f4">Create an API key</h2>
        {apiError && <ApiErrorBanner error={apiError} />}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pa3">
          <div className="w-100 db">
            <label htmlFor="name">Name of API key</label>
            <div className="mb3">
              <input
                type="text"
                name="name"
                ref={register({
                  required: 'Required',
                })}
                className={`${errors.name ? 'input__error' : ''}`}
                style={{ marginBottom: 0 }}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
              />
            </div>

            <label htmlFor="scopes">Scopes</label>
            <div className="ui-card">
              <Controller
                name="scopes"
                control={control}
                rules={{
                  required: true,
                  validate: (data) => {
                    if (!Array.isArray(data) || !data.length) {
                      return 'You must select at least one scope';
                    }
                  },
                }}
                render={(props: ControllerRenderProps) => (
                  <SearchSelect
                    className={`${errors.scopes ? 'search_select__error' : ''}`}
                    sections={sections}
                    selected={props.value}
                    setSelected={props.onChange}
                    sort
                    sortAccessor={'name'}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="scopes"
                render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
              />
            </div>
          </div>
        </div>
        <div className="pa3 mt3 bt b--moon-gray bg-white">
          <Button submit className="mr2" disabled={loading}>
            {loading ? 'Creating...' : 'Create API key'}
          </Button>
          <Button plain onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddApiKey;
