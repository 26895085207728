// @ts-nocheck: converted from JS

import { Status } from '@/referenceData/study/status';

export const approvalStatusToChecklistStatusMap = {
  [Status.approved]: 'success',
  [Status.success]: 'success',
  [Status.rejected]: 'fail',
  [Status.fail]: 'fail',
  [Status.inactive]: 'inactive',
  [Status.inactiveSuccess]: 'inactiveSuccess',
};
