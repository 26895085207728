import type {
  DataTableCellIndexCoordinate,
  DataTableRect as IDataTableRect,
  DataTableSelection as IDataTableSelection,
} from '../DataTable.model';

interface DataTableRectProps {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
export interface DataTableRectLoadProps extends DataTableRectProps {
  force: boolean;
}
export class DataTableRect implements IDataTableRect {
  readonly #top: number;
  readonly #bottom: number;
  readonly #left: number;
  readonly #right: number;

  constructor({ top, bottom, left, right }: DataTableRectProps) {
    this.#top = Object.freeze(top);
    this.#bottom = Object.freeze(bottom);
    this.#left = Object.freeze(left);
    this.#right = Object.freeze(right);
  }

  get top(): number {
    return this.#top;
  }

  get bottom(): number {
    return this.#bottom;
  }

  get left(): number {
    return this.#left;
  }

  get right(): number {
    return this.#right;
  }

  get width(): number {
    return this.#right - this.#left + 1;
  }

  get height(): number {
    return this.#bottom - this.#top + 1;
  }

  get area(): number {
    return this.width * this.height;
  }

  contains({ top, bottom, left, right }: IDataTableRect): boolean {
    return this.#top <= top && this.#bottom >= bottom && this.#left <= left && this.#right >= right;
  }
}

export class DataTableSelection implements IDataTableSelection {
  readonly #rect: Readonly<IDataTableRect>;
  readonly #from: Readonly<DataTableCellIndexCoordinate>;
  readonly #to: Readonly<DataTableCellIndexCoordinate>;

  constructor({ from, to }: { from: DataTableCellIndexCoordinate; to: DataTableCellIndexCoordinate }) {
    this.#from = Object.freeze(from);
    this.#to = Object.freeze(to);
    this.#rect = Object.freeze(
      new DataTableRect({
        top: from.row <= to.row ? from.row : to.row,
        bottom: from.row >= to.row ? from.row : to.row,
        left: from.column <= to.column ? from.column : to.column,
        right: from.column >= to.column ? from.column : to.column,
      })
    );
  }

  get from(): Readonly<DataTableCellIndexCoordinate> {
    return this.#from;
  }

  get to(): Readonly<DataTableCellIndexCoordinate> {
    return this.#to;
  }

  get topLeft(): Readonly<DataTableCellIndexCoordinate> {
    return { row: this.#rect.top, column: this.#rect.left };
  }

  get bottomRight(): Readonly<DataTableCellIndexCoordinate> {
    return { row: this.#rect.bottom, column: this.#rect.right };
  }

  get rect(): Readonly<IDataTableRect> {
    return this.#rect;
  }

  public contains(cell: DataTableCellIndexCoordinate): boolean {
    return this.containsColumn(cell.column) && this.containsRow(cell.row);
  }

  public containsColumn(column: number): boolean {
    const { left, right } = this.#rect;
    return column >= left && column <= right;
  }

  public containsRow(row: number): boolean {
    const { top, bottom } = this.#rect;
    return row >= top && row <= bottom;
  }
}

export class DataTableRectForceLoad extends DataTableRect {
  readonly #force: boolean;
  constructor({ top, bottom, left, right, force }: DataTableRectLoadProps) {
    super({ top, bottom, left, right });
    this.#force = force;
  }

  get force(): Readonly<boolean> {
    return this.#force;
  }
}
