// @ts-nocheck: converted from JS

import Barcode from 'react-barcode';

const Header = ({ cageName, studyName, barcodeValue }) => {
  return (
    <div className="w-100 flex flex-row justify-between items-start" style={{ fontSize: 12 }}>
      <div className="flex flex-column pa2 bg-black white w-100 self-start mr2">
        <span className="mb1">{cageName}</span>
        <span>{studyName}</span>
      </div>
      {barcodeValue && <Barcode value={barcodeValue} format="CODE128" height={45} margin={0} marginTop={-12} />}
    </div>
  );
};

export default Header;
