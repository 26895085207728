// @ts-nocheck: converted from JS

export const getInitialCages = () => {
  const selectedCages = sessionStorage.getItem('selectedColonyCages');
  return JSON.parse(selectedCages ?? '[]');
};

export const studyIdDerivedFromCages = (cages) => {
  return cages?.[0]?.['study_link']?.data?.id;
};
