import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import { ErrorMessage } from '@hookform/error-message';
import type { ReactNode } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';
import { DatePicker } from './Fieldtypes/DatePicker';
import { NumberField } from './Fieldtypes/Number';
import { Select } from './Fieldtypes/Select';
import { SelectLookup } from './Fieldtypes/SelectLookup';
import { Text } from './Fieldtypes/Text';

interface ErrorHandlingProps {
  children: ReactNode;
  slug: string;
}

export type RequiredMetadataFieldWithValue = MetadataFieldWithValue & { required: boolean };

const ErrorHandling = ({ children, slug }: ErrorHandlingProps) => {
  const { errors } = useFormContext();

  return (
    <div>
      {children}
      <ErrorMessage
        errors={errors}
        name={`metadata[${slug}].value`}
        render={({ message }) => <p className="f6 red db pt2">{message}</p>}
      />
    </div>
  );
};

const FieldMap = (field: RequiredMetadataFieldWithValue) => {
  const testId = `metadata-field__${field.slug}`;
  const fieldName = `metadata[${field.slug}].value`;
  return {
    text: <Text field={field} testId={testId} />,
    date: <DatePicker field={field} testId={testId} />,
    select: <Select field={field} testId={testId} />,
    multi_select: <Select field={field} isMulti testId={testId} />,
    lookup_select: <SelectLookup field={field} testId={testId} name={fieldName} />,
    lookup_multi_select: <SelectLookup field={field} isMulti testId={testId} name={fieldName} />,
    numeric: <NumberField field={field} testId={testId} />,
  };
};

interface RenderedFieldProps {
  metadata: RequiredMetadataFieldWithValue;
  required: boolean;
}

export const RenderedField = ({ metadata, required }: RenderedFieldProps) => {
  const { register } = useFormContext();

  return (
    <div className="mb3 w-90">
      <label htmlFor={`metadata[${metadata.slug}].value`} data-testid={`metadata-field__${metadata.slug}--label`}>
        {metadata.title}
        {required && ' (required)'}
      </label>
      <input
        type="hidden"
        ref={register({ required: 'Existing fields cannot be empty' })}
        name={`metadata[${metadata.slug}].glossary_id`}
        defaultValue={metadata.glossary_id}
      />
      {metadata && (
        <ErrorHandling slug={metadata.slug}>
          {
            // Setting required to true for all fields as they cannot be empty on settings edit
            FieldMap({ ...metadata, required: true, id: metadata.glossary_id, value: metadata.value })[
              metadata.field_type
            ]
          }
        </ErrorHandling>
      )}
    </div>
  );
};
