import ReasonForChange from '@/components/Shared/ReasonForChange';
import { featuresSelector } from '@/support/Selectors';
import { ErrorMessage } from '@hookform/error-message';
import { createSelector } from '@reduxjs/toolkit';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

interface ConfirmDeleteProps {
  onClick: (reason?: string) => void;
  title: string;
  text: string;
  btnTxt: string;
  textOverride?: string;
  showReasonForChange?: boolean;
}

interface FormDataProps {
  confirm?: string;
  reason_for_change?: string;
}

const featureSelector = createSelector([featuresSelector], (features) => ({
  reasonForChange: features?.reason_for_change ?? false,
}));

const ConfirmDelete: FC<ConfirmDeleteProps> = ({ onClick, title, text, btnTxt, textOverride, showReasonForChange }) => {
  const { reasonForChange } = useSelector(featureSelector);
  const dispatch = useDispatch();

  const formMethods = useForm({
    defaultValues: {
      confirm: '',
      reason_for_change: '',
    },
  });

  const { handleSubmit, errors, register } = formMethods;

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const onSubmit = ({ reason_for_change }: FormDataProps) => {
    onClick(reason_for_change);
    closeModal();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="ui__narrow-modal" data-testid="confirm-delete__modal">
        <div className="pb3 tc">
          <h3 className="normal f4 pb3" data-testid="confirm-delete__modal-header">
            {title}
          </h3>
          <p className="f6 pb2" data-testid="confirm-delete__modal-subheader">
            {textOverride || text}
          </p>
        </div>
        <div className="pb3">
          {reasonForChange && showReasonForChange ? (
            <ReasonForChange data-testid="confirm-delete__modal-reason-for-change" isDelete />
          ) : (
            <>
              <label htmlFor="confirm" className="lh-copy" data-testid="confirm-delete__modal-label">
                Type DELETE into the field to confirm
              </label>
              <input
                type="text"
                id="confirm"
                name="confirm"
                ref={register({ validate: (value: string) => value === 'DELETE' })}
                data-testid="confirm-delete__modal-input"
              />
              <ErrorMessage
                errors={errors}
                name="confirm"
                render={() => (
                  <p className="f6 red db lh-copy" data-testid="confirm-delete__modal-validation">
                    Text entered is incorrect, enter DELETE to continue.
                  </p>
                )}
              />
            </>
          )}
        </div>
        <div>
          <button type="submit" className="button critical w-100" data-testid="confirm-delete__modal-confirm">
            {btnTxt}
          </button>
          <button className="button plain db w-100 mt2" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ConfirmDelete;
