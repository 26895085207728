// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import ActionButtons from '@/components/Glossary/Sections/Approvals/ActionButtons';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import RequestComments from '@/components/Requests/RequestComments';
import Attachments from '@/components/Studies/Attachments';
import ReviewTable from '@/components/Studies/Create/Steps/Groups/ReviewTable';
import Samples from '@/components/Studies/Requests/Edit/Samples';
import RequestDetails from '@/components/Studies/Requests/RequestDetails';
import Project from '@/components/Studies/Show/Project';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { successToast } from '@/helpers';
import { _isEmpty, _isNil, _notNil } from '@/littledash';
import type { DisplayedGroupMetadata } from '@/model/Metadata.model';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import { useRequest } from '@/support/Hooks/request';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createPayload } from '../Request.utils';

const Edit = () => {
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [commentsError, setCommentsError] = useState(false);
  const [request, setRequest] = useState(undefined);
  const [requestLoading, setRequestLoading] = useState(true);
  const [requestError, setRequestError] = useState(false);

  const params = useParams();
  const { id, requestId, tab } = params;
  const { newAbortController: requestAbortController } = useAbortController();
  const { newAbortController: commentAbortController } = useAbortController();

  const tabState = useMemo(() => {
    if (_notNil(tab)) {
      const tabs = [
        'detail',
        ...(_isNil(request?.project) ? [] : ['project']),
        'groups',
        'samples',
        'attachments',
        'comments',
      ];
      const selectedTab = tabs.indexOf(tab);
      if (selectedTab >= 0) {
        return selectedTab;
      }
    }
    return 0;
  }, [tab, request?.project]);

  const {
    entity: study,
    entityLoading: studyLoading,
    entityError: studyError,
  } = useFetchEntity({
    entityType: 'study',
    params: { id },
  });

  const {
    collection: studyGroups,
    collectionLoading: studyGroupsLoading,
    collectionError: studyGroupsError,
  } = useFetchCollection({
    collectionType: 'studyGroups',
    params: { id },
    includes: 'metadata,treatments[metadata]',
    queryParams: { perPage: -1 },
  });
  const {
    collection: groupMetadata,
    collectionLoading: groupMetadataLoading,
    collectionError: groupMetadataError,
  } = useFetchCollection<DisplayedGroupMetadata>({
    collectionType: 'teamStudiesGroups',
  });

  const fetchRequest = async () => {
    setRequestLoading(true);
    try {
      const { data } = await Http.get(
        apiRoute('studies.requests.get', {
          studyId: id,
          requestId,
          query: ['study_groups[metadata],study_groups[treatments],study,project'],
        }),
        { signal: requestAbortController().signal }
      );
      setRequest(data.data);
      setRequestLoading(false);
    } catch (error) {
      if (notAborted(error)) {
        setRequestError(true);
        setRequestLoading(false);
      }
    }
  };

  const fetchComments = async (id) => {
    setCommentsLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(
        RouteService.legacyApi({
          apiRoute: 'requests.comments',
          path: { requestId },
          query: { perPage: -1 },
        }).url.href,
        {
          signal: commentAbortController().signal,
        }
      );
      setComments(data ?? []);
      setCommentsLoading(false);
    } catch (error) {
      if (notAborted(error)) {
        setCommentsError(error);
        setCommentsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRequest();
    fetchComments();
  }, []);

  const {
    sendRequest: updateRequest,
    requestSending: updatingRequest,
    requestError: updateRequestError,
  } = useRequest({
    route: 'studies.requests.update',
    method: 'put',
    params: {
      requestId: request?.id,
    },
  });

  const submitRequest = async (request) => {
    await updateRequest(createPayload(request));
    if (!updateRequestError) {
      successToast('Successfully updated request');
    }
  };

  const isLoading = studyLoading || requestLoading || commentsLoading || studyGroupsLoading || groupMetadataLoading;
  const fetchErrors = requestError || studyError || commentsError || studyGroupsError || groupMetadataError;
  const hasErrors = fetchErrors || updateRequestError;

  if (isLoading && !hasErrors) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <SubHeader linkToText={study?.name} link={webRoute('studies.show', { id })} />
      <div className="ph4 pv3">
        {!hasErrors && !_isEmpty(request) ? (
          <div>
            <h1 className="f3 normal pb4">Edit {request.request_template.name}</h1>
            <Tabs state={tabState}>
              <div className="mb4 bb b--moon-gray flex">
                <Tab>Request details</Tab>
                {_notNil(request.project) && <Tab>Project</Tab>}
                <Tab counter={`${studyGroups.length}`}>Groups</Tab>
                <Tab>Samples</Tab>
                <Tab>Attachments</Tab>
                <Tab counter={comments?.length}>Comments</Tab>
              </div>
              <div className="pb4">
                <Panel>
                  <RequestDetails
                    request={request}
                    setRequest={setRequest}
                    requestTemplate={request.request_template}
                    onSubmit={submitRequest}
                    ActionButtons={<ActionButtons canOk={!updatingRequest} okText={'Save'} hideCancel />}
                  />
                </Panel>
                {_notNil(request.project) && (
                  <Panel>
                    <Project projectId={request.project.id} />
                  </Panel>
                )}
                <Panel>
                  {_isEmpty(studyGroups) ? (
                    <NoDataCard title="No groups have been defined for this study" />
                  ) : (
                    <div className="ui-card">
                      <h2 className="lh-title normal f4 pa3 bb b--moon-gray">Groups</h2>
                      <ReviewTable groups={studyGroups} groupMetadata={groupMetadata} />
                    </div>
                  )}
                </Panel>
                <Panel>
                  <Samples />
                </Panel>
                <Panel>
                  <Attachments studyId={request.study.id} isReview />
                </Panel>
                <Panel>
                  <RequestComments comments={comments} setComments={setComments} requestId={requestId} />
                </Panel>
              </div>
            </Tabs>
          </div>
        ) : (
          <ApiErrorBanner
            error={hasErrors}
            errorType={fetchErrors ? ApiErrorType.FETCH : ApiErrorType.SUBMIT}
            className="mb4"
          />
        )}
      </div>
    </>
  );
};

export default Edit;
