import type { CSSProperties, ReactNode } from 'react';

interface SummaryItemProps {
  children: ReactNode;
  label: string;
  rowStyle?: CSSProperties;
  labelClasses?: string;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ label, rowStyle, labelClasses, children }) => (
  <div className="lh-copy summary-item">
    <div className="flex ph4 pv3 break-word" style={rowStyle}>
      <div className={`f6 w-33 ${labelClasses}`}>{label}</div>
      <div className="pl4 near-black f6 w-two-thirds">{children}</div>
    </div>
  </div>
);

export default SummaryItem;
