// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import ReviewTable from '@/components/Studies/Create/Steps/Groups/ReviewTable';
import { _isEmpty } from '@/littledash';
import { DisplayedGroupMetadata } from '@/model/Metadata.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
const Groups = ({ studyId }) => {
  const {
    collection: studyGroups,
    collectionLoading: studyGroupsLoading,
    collectionError: studyGroupsError,
  } = useFetchCollection({
    collectionType: 'studyGroups',
    params: { id: studyId },
    includes: 'metadata,treatments[metadata]',
    queryParams: { perPage: -1 },
  });
  const { collection: groupMetadata, collectionLoading: groupMetadataLoading } =
    useFetchCollection<DisplayedGroupMetadata>({
      collectionType: 'teamStudiesGroups',
    });

  if (studyGroupsLoading || groupMetadataLoading) {
    return <Loading />;
  }

  return (
    <>
      {studyGroupsError && (
        <ApiErrorBanner
          className="mb4 mw6"
          title="There was an internal error fetching study groups"
          text={
            'An error has occurred with the request, please try again later. If this keeps occurring please contact support.'
          }
          error={studyGroupsError}
        />
      )}
      {_isEmpty(studyGroups) ? (
        <NoDataCard title="No groups have been defined for this study" />
      ) : (
        <div className="ui-card">
          <h2 className="lh-title normal f4 pa3 bb b--moon-gray">Groups</h2>
          <ReviewTable groups={studyGroups} groupMetadata={groupMetadata} />
        </div>
      )}
    </>
  );
};

export default Groups;
