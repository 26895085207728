import { MeasurementConfigForm } from '@/components/Glossary/Sections/Presets/Builder/MeasurementConfigForm.tsx';
import { OutputForm } from '@/components/Glossary/Sections/Presets/Builder/OutputForm.tsx';
import {
  CreateOrUpdatePresetContext,
  useMeasurementFormResolverHook,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import Button from '@/components/UI/Button';
import { PresetCreateOrUpdateV1, PresetMeasurementCreateV1 } from '@/model/Preset.model.ts';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import { FC, useContext } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form@latest';
import { RiCloseFill } from 'react-icons/ri';
import styles from './PresetBuilder.module.scss';
import { PresetHeader } from './PresetHeader';

interface MeasurementFormProps {
  index: number;
  handleClose: () => void;
}

export const MeasurementForm: FC<MeasurementFormProps> = ({ index, handleClose }) => {
  const { state, updatePresetState } = useContext(CreateOrUpdatePresetContext);
  const resolver = useMeasurementFormResolverHook({
    preset: state.preset as PresetCreateOrUpdateV1,
    measurementIndex: index,
  });
  const formMethods = useForm<PresetMeasurementCreateV1>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver,
    defaultValues: {
      ...(state?.preset?.measurements?.[index] ?? {}),
    },
  });

  const {
    fields: measurementInputFields,
    append: appendMeasurementInputField,
    remove: removeMeasurementInputField,
  } = useFieldArray({
    control: formMethods.control,
    name: 'inputs',
  });
  const measurementName = useWatch({ control: formMethods.control, name: `name` });

  const handleSaveClick = () => {
    const measurements: Array<PresetMeasurementCreateV1> = [...(state.preset?.measurements ?? [])];
    measurements.splice(index, 1, formMethods.getValues());
    return updatePresetState({ ...(state.preset as PresetCreateOrUpdateV1), measurements }).finally(() =>
      handleClose()
    );
  };
  const handleCancelClick = () => {
    handleClose();
  };
  return (
    <FormProvider {...formMethods}>
      <PresetHeader measurementName={measurementName ?? ''} />
      <div className="ui-card pa4" data-test-component="MeasurementForm" data-test-element="inputs-container">
        <div className="flex flex-row justify-start">
          <div className="pr2">
            <label>Measurement name</label>
            <Controller
              control={formMethods.control}
              name="name"
              render={({ field, fieldState }) => (
                <span className={styles.measurementName}>
                  <input
                    type="text"
                    {...field}
                    maxLength={32}
                    className={cn({ ui__error: fieldState.invalid })}
                    autoComplete="off"
                    data-tooltip-id="global-tooltip-id"
                    data-tooltip-content={fieldState.error?.message}
                  />
                  <span className={styles.characterCount}>{field.value.length}/32</span>
                </span>
              )}
            />
          </div>
          <div className="w-10">
            <label>Unit</label>
            <Controller
              control={formMethods.control}
              name="unit"
              render={({ field, fieldState }) => (
                <input
                  type="text"
                  {...field}
                  value={field.value ?? ''}
                  maxLength={16}
                  className={cn(styles.unitInput, { ui__error: fieldState.invalid })}
                  data-tooltip-id="global-tooltip-id"
                  data-tooltip-content={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.measurementInputsContainer}>
          {measurementInputFields.map((measurementInputField, measurementInputFieldIndex) => (
            <div
              key={measurementInputField.id}
              className={`mb3 flex flex-row justify-start ${styles.inputContainer}`}
              data-test-element="input-container"
              data-test-key={measurementInputField.slug}
            >
              <div className="pr2">
                <label>Input name</label>
                <Controller
                  control={formMethods.control}
                  name={`inputs.${measurementInputFieldIndex}.name`}
                  render={({ field, fieldState }) => (
                    <span className={styles.measurementInputName}>
                      <input
                        type="text"
                        {...field}
                        maxLength={32}
                        className={cn({ ui__error: fieldState.invalid })}
                        data-tooltip-id="global-tooltip-id"
                        data-tooltip-content={fieldState.error?.message}
                      />
                      <span className={styles.characterCount}>{field.value?.length}/32</span>
                    </span>
                  )}
                />
              </div>
              <div className="pr2">
                <label>Unit</label>
                <div className="flex justify-between items-center">
                  <Controller
                    control={formMethods.control}
                    name={`inputs.${measurementInputFieldIndex}.unit`}
                    render={({ field, fieldState }) => (
                      <input
                        type="text"
                        {...field}
                        value={field.value ?? ''}
                        maxLength={16}
                        style={{ marginBottom: 0 }}
                        className={cn(styles.unitInput, { ui__error: fieldState.invalid })}
                        data-tooltip-id="global-tooltip-id"
                        data-tooltip-content={fieldState.error?.message}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={formMethods.formState.errors}
                    name={`inputs.${measurementInputFieldIndex}.unit`}
                    render={({ message }) => <p className="f6 red db">{message}</p>}
                  />
                  <div
                    className="mid-gray hover-dark-gray f3 ml3 mt1"
                    data-test-element="remove-input"
                    onClick={() => removeMeasurementInputField(measurementInputFieldIndex)}
                  >
                    <RiCloseFill />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button plain outline onClick={() => appendMeasurementInputField({ slug: '', name: '', unit: '' })}>
          Add Input
        </Button>
      </div>
      <div className="ui-card mt4" data-test-component="MeasurementForm" data-test-element="formula-container">
        <OutputForm index={index} />
      </div>
      <div className="ui-card pa4 mt4" data-test-component="MeasurementForm" data-test-element="config-container">
        <MeasurementConfigForm />
      </div>

      <div
        className="flex flex-row pt4 justify-between"
        data-test-component="MeasurementForm"
        data-test-element="actions-container"
      >
        <div className="pb5">
          <Button
            className="mr3"
            tooltip={!formMethods.formState.isValid ? 'Resolve errors to save' : undefined}
            disabled={!formMethods.formState.isValid || !formMethods.formState.isDirty}
            onClick={handleSaveClick}
          >
            Save measurement
          </Button>
          <Button plain onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};
