// @ts-nocheck: converted from JS

import Link from '@/components/UI/Link';
import { isValidLinkAttribute, formatNumberComingIn, renderMetadataValue } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import { validMultiSelectValue } from '../Create/Show.utils';

const generateMetadataColumns = (linkMetadata, columns) =>
  linkMetadata?.map(({ title, id, field_type }) => ({
    id: `glossary_id_${id}`,
    accessor: `glossary_id_${id}`,
    Header: title,
    headerClassName: 'no-sort',
    isVisible: columns?.[`glossary_id_${id}`] ?? false,
    Cell: ({
      row: {
        original: { metadata },
      },
    }) => {
      const metadataItem = metadata.length && metadata.find((metaItem) => metaItem.glossary_id === id);
      if (metadataItem?.value) {
        return <>{renderMetadataValue({ value: metadataItem.value, field_type: field_type })}</>;
      }
      return false;
    },
  }));

export const generateColumns = (metadata, columns) => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      sortBy: 'name',
      isVisible: columns?.['name'] ?? true,
      Cell: ({
        row: {
          original: { url, name },
        },
      }) =>
        isValidLinkAttribute(url) ? (
          <Link
            className="link blue"
            to={url.includes('https://') || url.includes('http://') ? url : `//${url}`}
            openTab
            href
          >
            {name}
          </Link>
        ) : (
          <>{name}</>
        ),
    },
    {
      Header: 'Description',
      accessor: 'alt',
      id: 'alt',
      sortBy: 'alt',
      isVisible: columns?.['alt'] ?? true,
    },
    ...generateMetadataColumns(metadata, columns),
  ];
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.data,
      };
    case 'SET_UPDATING':
      return {
        ...state,
        updating: action.data,
      };
    case 'SET_ERROR':
      return {
        ...state,
        apiErrors: action.data,
      };
    case 'SET_FILES':
      return {
        ...state,
        files: action.data.data,
        total_pages: action.data.pagination.total_pages,
      };
    case 'SET_LINKS':
      return {
        ...state,
        links: action.data.data,
        total_pages: action.data.pagination.total_pages,
      };
    case 'UPDATE_LINK':
      return {
        ...state,
        links: state.links.map((link) => {
          if (link.id === action.data.id) {
            return action.data.updatedLink;
          }
          return link;
        }),
      };
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.data,
        current_page: 1,
      };
    case 'SET_PAGING':
      return {
        ...state,
        current_page: state.per_page === action.data.per_page ? action.data.current_page : 1,
        per_page: action.data.per_page,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: action.data['sort'],
        order: action.data.order,
      };
    case 'SET_COLUMNS':
      return {
        ...state,
        columns: action.data,
      };
    case 'SET_METADATA':
      return {
        ...state,
        metadata: action.data,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selectedRows: action.data,
      };
    default: {
      return { ...state };
    }
  }
};

export const formatMetadataForSave = (link, metadata) =>
  Object.entries(link?.metadata ?? {}).reduce((acc, [key, value]) => {
    const glossaryMeta = metadata.find(({ title }) => title === key);
    if (_notNil(glossaryMeta) && _isNotEmpty(value)) {
      if (
        (glossaryMeta.field_type === 'multi_select' || glossaryMeta.field_type === 'lookup_multi_select') &&
        !validMultiSelectValue(value)
      ) {
        return acc;
      }
      if (glossaryMeta.field_type === 'numeric') {
        value = formatNumberComingIn(value).toString();
      }
      acc.push({ glossary_id: glossaryMeta.id, value });
    }
    return acc;
  }, []);
