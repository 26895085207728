// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';

const Header = ({ steps, activeStep }) => {
  const baseClasses = 'flex-l items-center-l justify-between-l lh-title';
  const activeClasses = 'basier-med near-black';
  const inActiveClasses = 'basier-reg';
  const completeClasses = ' basier-med green';
  const keys = Object.keys(steps);

  return (
    <div className="flex-l items-center-l justify-between-l pa4">
      <div>
        <h2 className="lh-title basier-reg normal f4 f3-l pb3 pb0-l">Import</h2>
      </div>
      <div className="flex-l items-center-l justify-between-l tr-l">
        {keys.map((s, i) => {
          const step = steps[s];
          let setClasses = inActiveClasses;
          if (s === activeStep) {
            setClasses = activeClasses;
          }
          if (step.complete) {
            setClasses = completeClasses;
          }

          return (
            <a key={`${i}_${new Date()}`} className={`${baseClasses} ${setClasses}`}>
              <div className="pl5 relative">
                {i !== 0 && (
                  <Icon icon="chev_right" viewBox="0 0 20 32" width="12" height="12" className="percentCenter" />
                )}
              </div>
              <div>
                {step.complete && (
                  <Icon icon="success" viewBox="0 0 22 22" width="22" height="22" className="mr2 v-top" />
                )}
                <span>{step.displayName}</span>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
