// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import Select from '@/components/UI/Select';
import { preventNumberScroll } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import React, { useMemo } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import './InputWithUnits.scss';

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon icon="down_triangle" width="8" height="8" className="right-1 blue" style={{ pointerEvents: 'none' }} />
  </components.DropdownIndicator>
);

interface InputWithUnitsProps {
  value?: number;
  label?: string;
  onChange: (value: { value: number; unit: string }) => void;
  onBlur?: () => void;
  disabled?: boolean;
  hasError?: boolean;
  classes?: string;
  unit?: string;
  unitOptions?: Array<{ value: string; label: string }>;
  isUnitOptionDisabled?: (option: { value: string; label: string }) => boolean;
  name: string;
  valueMax?: number;
  valueMin?: number;
  valueStep?: number;
}

const InputWithUnits: React.FC<InputWithUnitsProps> = ({
  value,
  label,
  onChange,
  onBlur,
  disabled = false,
  hasError = false,
  classes,
  unit,
  unitOptions,
  isUnitOptionDisabled,
  name,
  valueMax,
  valueMin,
  valueStep,
}) => {
  const optionMap = useMemo(() => new Map((unitOptions ?? []).map((option) => [option.value, option])), [unitOptions]);
  return (
    <div className={`flex relative ${classes}`} data-test-component="InputWithUnits" data-test-element="container">
      <input
        name={name}
        type="number"
        max={valueMax}
        min={valueMin}
        step={valueStep}
        data-test-component="InputWithUnits"
        data-test-element="number-input"
        onWheel={preventNumberScroll}
        value={value}
        onChange={(event) =>
          onChange({
            value: _isNotEmpty(event?.target?.value) ? Number(event?.target?.value) : undefined,
            unit: unit ?? unitOptions?.[0]?.value,
          })
        }
        onBlur={onBlur}
        disabled={disabled}
        style={{ paddingRight: '3.5rem' }}
        className={hasError ? 'input__error' : ''}
        data-testid={name}
      />
      {_isNotEmpty(unitOptions) ? (
        <div
          className="pointer unitSelection"
          data-test-component="InputWithUnits"
          data-test-element="unit-select-container"
        >
          <Select
            name={name}
            value={optionMap.get(unit) ?? optionMap.get(unitOptions[0].value)}
            options={unitOptions}
            isOptionDisabled={isUnitOptionDisabled}
            isMulti={false}
            customStyles={{
              indicatorsContainer: () => ({
                border: 'none',
                padding: 0,
              }),
              singleValue: (base) => ({
                ...base,
                color: '#0647a6',
                fontSize: '80%',
                textAlign: 'end',
              }),
            }}
            onChange={(option) => onChange({ value, unit: option?.value })}
            onBlur={onBlur}
            disabled={disabled}
            className="w-100 mb3"
            label={`${label}_unit`}
            components={{ DropdownIndicator }}
          />
        </div>
      ) : (
        <span className="ib w2 absolute right-1 tr" style={{ top: '0.5rem' }}>
          <small>{unit}</small>
        </span>
      )}
    </div>
  );
};

export default InputWithUnits;
