import { samplesConfigObj } from '@/components/Glossary/Sections/SampleTypes/SamplesConfig';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative';
import InputWithUnits from '@/components/UI/FormElements/InputWithUnits';
import { metadataFieldBuilder } from '@/components/UI/FormFields/FormField.utils';
import FormRender from '@/components/UI/FormRender';
import Select from '@/components/UI/Select';
import type { SelectOption } from '@/components/UI/Select/Select';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { MetadataItem } from '@/model/Metadata.model';
import type { BulkSamplesFormData, SampleType } from '@/model/Sample.model';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useController,
  useForm,
  useFormContext,
  //@ts-expect-error - old hook form - replace with `react-hook-form@latest
} from 'react-hook-form';

const getDetailsDefaults = () =>
  Object.entries(samplesConfigObj).reduce<Record<string, { unit: string; value: null | string }>>(
    (acc, [key, value]) => {
      acc[key] = { unit: value.units[0], value: null };
      return acc;
    },
    {}
  );

const samplesDefaultObj = getDetailsDefaults();

interface DetailFieldProps {
  detail: string;
}

const DetailField: React.FC<DetailFieldProps> = ({ detail }) => {
  const {
    errors,
    formState: { isSubmitting },
    control,
  } = useFormContext();

  const units = samplesConfigObj?.[detail]?.units ?? [];

  return (
    <div className="w-50 pa2" data-testid="bulk-sample-detail-input">
      <label>
        <label>{samplesConfigObj?.[detail]?.title}</label>
      </label>
      <Controller
        name={`details.${detail}`}
        control={control}
        render={({ name, value, onChange, onBlur }: ControllerRenderProps) => (
          <InputWithUnits
            name={name}
            value={value.value}
            unit={units.length > 1 ? value.unit : units[0]}
            onChange={onChange}
            onBlur={onBlur}
            classes="w-100"
            disabled={isSubmitting}
            hasError={_notNil(errors?.[`details.${detail}`])}
            {...(units.length > 1
              ? { unitOptions: units.map((unit) => ({ label: unit, value: unit })) }
              : { unit: units[0] })}
          />
        )}
      />
    </div>
  );
};

interface BulkCreateSamplesFormProps {
  sampleMetadata?: Array<MetadataItem>;
  sampleTypeOptions?: Array<SampleType>;
  handleCallback: (formData: BulkSamplesFormData, metadata?: Array<MetadataItem>) => void;
  closeModal: () => void;
}

const BulkCreateSamplesForm: React.FC<BulkCreateSamplesFormProps> = ({
  sampleMetadata,
  sampleTypeOptions,
  handleCallback,
  closeModal,
}) => {
  const [sampleDetails, setSampleDetails] = useState<Array<string>>([]);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      sample_type: '',
      details: samplesDefaultObj,
      date: DateUtils.dateTimeNow(),
      meta: {},
      comments: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
    register,
    errors,
    getValues,
    reset,
    watch,
  } = formMethods;

  const {
    field: { onBlur: sampleTypeOnBlur, onChange: sampleTypeOnChange, value: sampleTypeValue },
  } = useController({
    control,
    name: 'sample_type',
    rules: { required: true },
  });

  const handleFormSubmit = (formData: BulkSamplesFormData) => {
    closeModal();
    handleCallback(formData, sampleMetadata);
  };

  const handleSampleTypeChange = (
    newValue: Readonly<SelectOption<SampleType> | SelectOption<SampleType>[]> | null | undefined
  ) => {
    if (_isNotEmpty(newValue) && !Array.isArray(newValue)) {
      sampleTypeOnChange((newValue as SelectOption<SampleType>).value);
      setSampleDetails((newValue as SelectOption<SampleType>).value.options.details);
      const newDefaultValues = {
        ...getValues(['sample_type', 'comments', 'meta', 'date']),
        ...{ details: samplesDefaultObj },
      };
      reset(newDefaultValues);
    }
  };

  const sampleTypeWatch = watch('sample_type.id');

  return (
    <FormProvider {...formMethods}>
      <form
        data-testid="bulk-samples-form"
        className="pv3 overflow-auto h-100"
        style={{ maxHeight: 600 }}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="ph3 pt3 w-100 flex g3">
          <div className="w-50" data-testid="bulk-samples-date-controller">
            <Controller
              name="date"
              control={control}
              render={({ onChange, value }: ControllerRenderProps) => (
                <>
                  <label htmlFor="date">Date taken</label>
                  <DateTimePickerNative value={value} onChange={onChange} />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="date"
              render={({ message }) => (
                <p className="f6 red db pb3" style={{ marginTop: '-9px' }}>
                  {message}
                </p>
              )}
            />
          </div>

          <div className="w-50" data-testid="bulk-samples-type-select">
            <label>Sample type (Required)</label>
            <Select
              defaultValue={sampleTypeValue}
              options={sampleTypeOptions?.map((sampleType) => ({
                label: sampleType.title,
                value: sampleType,
              }))}
              onChange={handleSampleTypeChange}
              onBlur={sampleTypeOnBlur}
              className="w-100"
              isMulti={false}
            />
          </div>
        </div>
        {_isNotEmpty(sampleDetails) && (
          <div className="w-100 pa2 flex justify-between flex-wrap">
            {sampleDetails.map((detail) => (
              <DetailField key={`${detail}-${sampleTypeWatch}`} detail={detail} />
            ))}
          </div>
        )}
        <div className="mb3 ph3 w-100">
          <label htmlFor="comments">Comments</label>
          <textarea
            data-testid="bulk-sample-comment"
            name="comments"
            style={{ marginBottom: 0 }}
            ref={register({
              maxLength: {
                value: 255,
                message: 'Must be a maximum of 255 characters',
              },
            })}
          />
        </div>
        <div className="w-100 ph3">
          <FormRender fields={sampleMetadata?.map((meta) => metadataFieldBuilder(meta))} />
        </div>
      </form>
      <ModalActions
        submitBtnText="Next"
        submitButtonProps={{
          className: 'ml3',
          disabled: !isValid || isSubmitting,
          tooltip: !isValid ? 'Missing required fields' : undefined,
          loading: isSubmitting,
          loadingText: 'Generating samples',
        }}
        onSubmit={handleSubmit(handleFormSubmit)}
        className="pa3 flex self-end bt b--moon-gray w-100"
      />
    </FormProvider>
  );
};

export default BulkCreateSamplesForm;
