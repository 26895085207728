import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import type { Column, FilterOption } from '@/components/UI/Table/TableComponent.model';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { errorToast, fetchMetadataColumns } from '@/helpers';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import type { MetadataField } from '@/model/Metadata.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { Study, StudyApiId } from '@/model/Study.model';
import type { StudyForm } from '@/model/StudyForm.model';
import { ApiService } from '@/support/ApiService';
import { web as webRoutes } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import type { History } from '@/utils/history.ts';
import _uniqBy from 'lodash/uniqBy';
import { RiMoreFill } from 'react-icons/ri';

interface Types {
  calculations: Array<PresetCalculation>;
  title: string;
  id: ID;
}

export const generateStudiesMetadataColumns = async (studyMetadataFilters: Array<MetadataField> = []) => {
  return studyMetadataFilters.reduce<Array<MetadataField>>((activeMeta, metadataItem) => {
    if (metadataItem?.in_use && metadataItem.active) {
      activeMeta.push({ ...metadataItem, header: metadataItem.title });
    }
    return activeMeta;
  }, []);
};

export const metadataTypeIgnoredFilters = ['multi_select', 'lookup_select', 'lookup_multi_select'];

export const studiesfilterOptions = (types: Array<Types>, metadata: Array<MetadataField>): Array<FilterOption> => {
  const uniqueTypes = _uniqBy(types, 'title').map((t) => ({
    id: t.id,
    name: t.title,
  }));

  const filters: Array<FilterOption> = [
    {
      value: 'type',
      name: 'Type',
      operations: [
        {
          name: filterType.eq,
          value: filterType.eq,
          options: uniqueTypes,
        },
        {
          name: filterType.ne,
          value: filterType.ne,
          options: uniqueTypes,
        },
      ],
    },
    {
      value: 'user',
      name: 'User',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [],
        },
      ],
      fetchFromApi: {
        placeholder: 'Search by user name',
        url: 'users.list',
      },
    },
  ];

  metadata?.forEach(({ field_type, id, title }) => {
    if (!metadataTypeIgnoredFilters.includes(field_type)) {
      filters.push({
        value: id,
        name: title,
        operations: [
          {
            name: filterType.contains,
            value: field_type === 'date' ? filterType.eq_date : filterType.contains,
            options: [],
          },
          {
            name: filterType.not_contains,
            value: field_type === 'date' ? filterType.not_eq_date : filterType.not_contains,
            options: [],
          },
        ],
      });
    }
  });

  return filters;
};

interface ActionColumnProps {
  history: History;
  studyForms?: Array<StudyForm>;
}

const cloneStudyCall = async (history: History, studyId: StudyApiId) => {
  try {
    const response = await ApiService.call({
      endpoint: 'POST /api/v1/studies/{studyId}/clone',
      path: { studyId },
    });

    if (response.type === 'success') {
      const { draftId, templateId: formId } = response.body;
      history.push(webRoutes('studies.new.draft', { formId, draftId }));
    }
  } catch (error) {
    errorToast('Could not clone study');
    ExceptionHandler.captureException(
      new InVivoError('Could not clone study', {
        cause: error,
        slug: 'dashboard-clone-study',
      })
    );
  }
};

export const buildActionColumn = ({ history, studyForms }: ActionColumnProps) => {
  return [
    {
      id: 'actions',
      lockVisibility: true,
      sortDisabled: true,
      accessor: 'actions',
      className: 'ui__table--overflow-cell',
      isVisible: true,
      Cell: ({ row: { original: study } }: { row: { original: Study } }) => {
        return studyForms?.some((form) => form.id === study.study_creation_template_id) ? (
          <span className="cellActions" style={{ textAlign: 'right' }}>
            <Dropdown
              renderMenu={() => (
                <>
                  <DropdownMenuItem onClick={() => cloneStudyCall(history, study.api_id)}>Clone Study</DropdownMenuItem>
                </>
              )}
            >
              <RiMoreFill size={22} />
            </Dropdown>
          </span>
        ) : null;
      },
    },
  ];
};

export const generateColumns = (
  columns: Array<Column<Study>>,
  metadata: Array<{ id: ID; header: string; title?: string; name?: string }>,
  history: History,
  studyForms?: Array<StudyForm>
): Array<Column<Study>> => [
  ...columns,
  ...fetchMetadataColumns({ metadata }),
  ...buildActionColumn({ history, studyForms }),
];

export const updateColumns = (studies: Array<Study>, columns: Array<Column<Study>>) => {
  const containsStudyID = studies.some((study) => _notNil(study?.code));
  if (containsStudyID) {
    const updatedColumns = columns.map((column) => {
      if (column.id === 'code') {
        return { ...column, isVisible: true };
      }
      return column;
    });
    return updatedColumns;
  }
  return columns;
};
