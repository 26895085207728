// @ts-nocheck: converted from JS

import { resetSessionCorrelationId, uuid } from '@/littledash.ts';

const defaultSettings = {
  sidebar: {
    open: false,
    collapsed: false,
  },
  calendar_sidebar: {
    collapsed: false,
  },
  tables: {
    animals: {
      columns: {
        donor: false,
        tail: false,
        ear: false,
        tag: false,
        cage: true,
        age: true,
        dob: false,
        sex: false,
        study_group: true,
        tracking_started_at: true,
        number: true,
        id: true,
        tumour_volume: true,
        weight: true,
        catalog: false,
      },
      filters: [],
    },
    samples: {
      columns: {
        sample_id: true,
        subject: true,
        donor: false,
        tail: false,
        ear: false,
        tag: false,
        collection: true,
        date: true,
        time: true,
        type: true,
        study: true,
        study_group: true,
        max_subjects: true,
        user: true,
      },
    },
    studySamples: {
      columns: {
        sample_id: true,
        subject: true,
        donor: false,
        tail: false,
        ear: false,
        tag: false,
        collection: true,
        date: true,
        time: true,
        type: true,
        study_group: true,
        study: false,
        max_subjects: true,
        user: true,
        comments: false,
        weight: false,
        volume: false,
        length: false,
        depth: false,
        width: false,
      },
      filters: [],
    },
    workflow: {
      filters: [],
    },
    colonyCages: {
      columns: {
        id: false,
        catalog: true,
        name: true,
        population: true,
        study: true,
        date: true,
        actions: true,
      },
      filters: [],
    },
    cagesAddToStudy: {
      columns: {
        id: false,
        catalog: true,
        name: true,
        population: true,
        study: true,
        date: true,
        actions: true,
      },
      filters: [],
    },
    colonyAnimals: {
      columns: {
        id: false,
        catalog: true,
        name: true,
        cage: true,
        collection: true,
        deceased: false,
        deceased_reason: false,
        deceased_comment: false,
        tailId: false,
        donorId: false,
        earId: false,
        tagId: false,
        sex: true,
        dob: true,
        time: true,
        species: true,
        strain: true,
        data: true,
        study: true,
      },
      filters: [],
    },
    randomization: {
      columns: {
        created_at: true,
        cage: true,
        total: false,
      },
      filters: [],
    },
    treatments: {
      columns: {
        treatment_name: true,
        dose_concentration: true,
        dose_volume: true,
        stock_concentration: true,
        volume: true,
        groups: true,
      },
      filters: [],
    },
    studycodes: {
      columns: {
        id: false,
        title: true,
        code: true,
        created_at: true,
        updated_at: true,
      },
      filters: [],
    },
    files: {
      columns: {
        name: true,
        created: true,
        created_by: true,
      },
      filters: [],
    },
    links: {
      columns: {
        name: true,
        alt: true,
      },
      filters: [],
    },
  },
  columns: {},
  filters: [],
  flashes: [],
  changePercentage: 'value_change_from_first',
  workflowFilters: [],
  sampleColumns: [],
  studyState: {
    reviewState: 'pending',
    pageStates: {
      overview: false,
      animals: true,
      cages: true,
      graphs: true,
      workflow: true,
      treatmentGroups: true,
      attachments: false,
      samples: true,
      observations: true,
      import: true,
      export: true,
      settings: false,
      dataTables: true,
    },
  },
};

const loadSettingsFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('settings');
    if (serializedState === null) {
      return defaultSettings;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return defaultSettings;
  }
};
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('settings', serializedState);
  } catch {
    // ignore write errors
  }
};
const INITIAL_STATE = {
  rightbar: null,
  loggedIn: null,
  modal: { open: false, type: undefined, props: undefined },
  flashes: [],
  settings: loadSettingsFromLocalStorage(),
};

const applySetRightbar = (state, action) => ({
  ...state,
  rightbar: action.rightbar,
});

const updateCurrentStudyStatus = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    studyState: action.studyState,
  },
});

const saveSetting = (state, action) => {
  const updatedSettings = { ...state.settings, ...action.setting };
  saveToLocalStorage(updatedSettings);
  return {
    ...state,
    settings: updatedSettings,
  };
};
const resetSettings = (state) => {
  saveToLocalStorage(defaultSettings);
  return {
    ...state,
    settings: defaultSettings,
  };
};

const openFlash = (state, action) => {
  const { text } = action;
  const id = uuid();
  const updatedFlashes = state.flashes;
  updatedFlashes.push({ id, text, open: true });
  return { ...state, flashes: updatedFlashes };
};

const closeFlash = (state, action) => {
  const updatedFlashes = state.flashes.filter(({ id }) => id !== action.id);
  return { ...state, flashes: updatedFlashes };
};

const login = (state, action) => {
  window.localStorage.setItem('token', action.token);
  resetSessionCorrelationId();
  return {
    ...state,
    loggedIn: true,
    token: action.token,
  };
};

const logout = (state, action) => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('team_id');
  resetSessionCorrelationId();

  return {
    ...state,
    loggedIn: false,
    token: undefined,
    ...closeModal,
  };
};

const closeModal = { modal: { open: false, type: undefined, props: undefined } };

function uiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN': {
      return login(state, action);
    }
    case 'LOGOUT': {
      return logout(state, action);
    }
    case 'UI_SET_RIGHTBAR': {
      return applySetRightbar(state, action);
    }
    case 'SAVE_SETTING': {
      return saveSetting(state, action);
    }
    case 'RESET_SETTINGS': {
      return resetSettings(state);
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        modal: { open: true, type: action.modal, props: action.props },
      };
    }

    case 'CLOSE_MODAL': {
      return {
        ...state,
        ...closeModal,
      };
    }
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        settings: {
          ...state.settings,
          sidebar: { ...state.settings.sidebar, open: true },
        },
      };
    }

    case 'SET_CURRENT_STUDY_STATUS': {
      return updateCurrentStudyStatus(state, action);
    }

    case 'SET_STUDY_DETAILS': {
      return {
        ...state,
        currentStudyDetails: action.currentStudyDetails,
      };
    }

    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        settings: {
          ...state.settings,
          sidebar: { ...state.settings.sidebar, open: false },
        },
      };
    }
    case 'TOGGLE_SIDEBAR': {
      return {
        ...state,
        settings: {
          ...state.settings,
          sidebar: {
            ...state.settings.sidebar,
            open: !state.settings.sidebar.open,
          },
        },
      };
    }
    case 'TOGGLE_OUTER_SIDEBAR': {
      return {
        ...state,
        settings: {
          ...state.settings,
          sidebar: {
            ...state.settings.sidebar,
            collapsed: !state.settings.sidebar.collapsed,
          },
        },
      };
    }
    case 'TOGGLE_CALENDAR_FILTERS': {
      return {
        ...state,
        settings: {
          ...state.settings,
          calendar_sidebar: {
            ...state.settings.calendar_sidebar,
            collapsed: !state.settings.calendar_sidebar.collapsed,
          },
        },
      };
    }
    case 'DISPLAY_OUTER_SIDEBAR': {
      const collapsed = !(action?.data?.display ?? false);
      return { ...state, settings: { ...state.settings, sidebar: { ...state.settings.sidebar, collapsed } } };
    }
    case 'OPEN_FLASH': {
      return openFlash(state, action);
    }

    case 'CLOSE_FLASH': {
      return closeFlash(state, action);
    }
    case 'CLEAR_FLASHES': {
      return {
        ...state,
        flashes: [],
      };
    }

    default:
      return state;
  }
}

export default uiReducer;
