import { _notNil } from '@/littledash';
import type { CSSProperties } from 'react';
import { RiCloseFill } from 'react-icons/ri';

interface ChipProps<T> {
  chipId?: T;
  title?: string;
  value?: string | number;
  style?: CSSProperties;
  className?: string;
  onRemove?: (chipId?: T) => void;
  labelTextClass?: string;
  valueTextClass?: string;
  colour?: ChipColour;
}

type ChipColour = 'blue' | 'gray';

const colourSchemes: Record<ChipColour, Record<'background' | 'border' | 'colour', string>> = {
  blue: {
    background: 'bg-lightest-blue',
    border: 'b--lightest-blue',
    colour: 'blue',
  },
  gray: {
    background: 'bg-moon-gray',
    border: 'b--moon-gray',
    colour: 'near-black',
  },
};

const Chip = <T = object,>({
  chipId,
  title,
  value,
  style,
  className,
  colour = 'blue',
  onRemove,
  labelTextClass = '',
  valueTextClass = '',
}: ChipProps<T>): JSX.Element => {
  const currentColour = colourSchemes[colour];

  return (
    <div
      className={`f6 lh-title dib ${currentColour.colour} br2 ba ${currentColour.border} ${currentColour.background} ${className}`}
      style={style}
    >
      <div className="flex">
        {title && <div className={`pa2 br ${currentColour.border} ${labelTextClass}`}>{title}</div>}
        {_notNil(value) ? <div className={`pa2 ${valueTextClass}`}>{value}</div> : null}
        {_notNil(onRemove) ? (
          <div className={`pv1 pr2 ${currentColour.border} ${currentColour.colour}`}>
            <RiCloseFill size="24" onClick={() => onRemove(chipId)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Chip;
