import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import Importer from '@/components/UI/Importer';
import { _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Cage } from '@/model/Cage.model';
import type { Study } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import * as Auth from '@/support/auth';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Show = () => {
  const [subjects, setSubjects] = useState<Animal[]>([]);
  const [subjectsLoading, setSubjectsLoading] = useState<boolean>(true);

  const { id: studyId } = useParams<{ id: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users',
  });

  const { collection: collections, collectionLoading: collectionsLoading } = useFetchCollection<Cage>({
    collectionType: 'studyCages',
    params: { studyId },
    queryParams: { perPage: -1 },
  });

  const fetchSubjects = async () => {
    if (_notNil(study) && _notNil(study?.api_id)) {
      const response = await ApiService.call({
        endpoint: 'GET /api/v1/studies/{studyId}/animals',
        path: { studyId: study.api_id },
        query: {
          perPage: -1,
          sort: 'cage',
          order: 'asc',
        },
      });
      if (response.type === 'success') {
        // @ts-expect-error: OAI animal does not match manually created animal
        setSubjects(response.body.data);
      }
      setSubjectsLoading(false);
    }
  };

  useEffect(() => {
    if (study) {
      fetchSubjects();
    }
  }, [study]);

  if (studyLoading || collectionsLoading || subjectsLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  const hasAccess = study && Auth.isWriteUserForStudy(study);

  return (
    <>
      {hasAccess ? (
        // @ts-expect-error: mapped from ts-ignore
        <Importer study={study} subjects={subjects} collections={collections} />
      ) : (
        <div className="w-100 h-100 flex items-center justify-center">
          <NoData
            title="You aren't authorized"
            text="Only team members with Write access have access to use Import. The study author can update your permissions to give you access."
            link={`/studies/${studyId}/animals/`}
            btnTxt="Return to animals"
          />
        </div>
      )}
    </>
  );
};

export default Show;
