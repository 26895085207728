import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import FormComponent from '@/components/Subjects/Show/Measurements/Form/FormComponent';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Icon from '@/components/UI/Icon';
import { isDead } from '@/constants/utils';
import type { Animal } from '@/model/Animal.model';
import type { ISODateTime } from '@/model/Common.model';
import type { Measurement } from '@/model/Measurement.model';
import { Preset } from '@/model/Preset.model.ts';
import type { StudyApiId } from '@/model/Study.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { DateUtils } from '@/utils/Date.utils';
import { FC, useEffect, useState } from 'react';

interface AddMeasurementProps {
  studyApiId: StudyApiId;
  subject: Animal;
  preset: Preset;
  onMeasurementSubmit: (response: any, editing: boolean) => void;
  closeModal: () => void;
  settings: {
    measuring: Array<string>;
    measured_at: ISODateTime;
  };
  editing?: boolean;
}

const AddMeasurement: FC<AddMeasurementProps> = ({
  studyApiId,
  subject,
  preset,
  onMeasurementSubmit,
  closeModal,
  settings,
  editing = false,
}) => {
  const [date, setDate] = useState<string>(
    DateUtils.isValidDate(settings?.measured_at) ? settings.measured_at : DateUtils.dateNow()
  );

  const {
    collection: measurement,
    collectionLoading: measurementLoading,
    collectionError: measurementError,
    collectionUpdating: measurementUpdating,
    fetchCollection: fetchMeasurement,
  } = useFetchCollection<Measurement>({
    collectionType: 'measurements',
    params: { id: subject.id },
    queryParams: { measured_at: date },
  });

  const formDisabled = measurementLoading || Boolean(measurementError) || measurementUpdating;
  useEffect(() => {
    if (!measurementLoading || measurementUpdating) {
      fetchMeasurement();
    }
  }, [date]);
  return (
    <div className="center mv3 bg-white br2 shadow-4" style={{ maxWidth: '480px' }} data-testid="add-measurement">
      <div className="pa3">
        {measurementLoading ? (
          <Loading txt="Loading measurement details" />
        ) : (
          <>
            <div className="flex justify-between items-center mb4">
              <h3 className="normal f5 f4-l">{editing ? 'Edit' : 'Add a'} measurement</h3>
              <a onClick={closeModal} className="w2 h2 br-100 bg-silver pointer flex hover-near-black">
                <Icon icon="close" width="12" height="12" className="self-center center" />
              </a>
            </div>
            {measurementError ? (
              <ApiErrorBanner
                className="mb4 mw6"
                title="There was an error fetching measurement data"
                text="An error has occurred fetching previous measurement data, please try again. If this keeps occurring please contact support."
                error={measurementError}
              />
            ) : (
              <>
                <div className="pb3">
                  <label>Date taken</label>
                  <DatePickerNative value={date} style={{ marginBottom: 0 }} onChange={setDate} disabled={editing} />
                </div>
                <FormComponent
                  subject={subject}
                  studyId={studyApiId}
                  cageName={isDead(subject)}
                  preset={preset}
                  onMeasurementSubmit={onMeasurementSubmit}
                  editing={editing}
                  disabled={formDisabled}
                  todaysMeasurement={measurement?.[0]}
                  settings={{ ...settings, measured_at: date }}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AddMeasurement;
