// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useState } from 'react';

const ManageAccount = ({ users, handleCallback, closeModal, activate = false }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setAPIerror] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const promises = users.map((user, index) => {
      return Http.put(apiRoute('users.update', user.id), {
        status: !activate ? 'inactive' : 'active',
      });
    });

    return Promise.all(promises)
      .then(() => {
        closeModal();
        handleCallback(
          `Successfully ${activate ? 'activated' : 'suspended'} ${users.length} user${users.length > 1 ? 's' : ''}`
        );
      })
      .catch((error) => {
        setLoading(false);
        setAPIerror(error);
      });
  };

  const text = {
    title: activate ? 'Activate' : 'Suspend',
    desc: activate
      ? 'Active users are able to access Benchling In Vivo again.'
      : 'Data captured will remain intact and will not be deleted.',
    btnLoading: activate ? 'Activating' : 'Suspending',
  };

  return (
    <form onSubmit={handleSubmit} className="ui__narrow-modal">
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">
          {text.title} user account{users.length > 1 ? 's' : ''}
        </h3>
        <p className="f6 pb2">{text.desc}</p>
        {apiError && (
          <Banner critical dismiss={false} className="mw6 mt3">
            <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
            <p className="f6 pb3 lh-copy">
              An error has occurred when submitting the form, please try again later. If this keeps occurring please
              contact support.
            </p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
      </div>
      <div>
        <Button disabled={loading} submit className="w-100 mb2">
          {!loading ? text.title : `${text.btnLoading}...`}
        </Button>
        <Button plain onClick={closeModal} className="w-100">
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ManageAccount;
