import Icon from '@/components/UI/Icon';
import Link from '@/components/UI/Link';
import type { Column, Columns } from '@/components/UI/Table/TableComponent.model';
import { classNames } from '@/helpers';
import { _isEmptyString } from '@/littledash';
import type { AltId } from '@/model/Animal.model';
import type { ID, PickRequired } from '@/model/Common.model';
import type { TaskAnimalV1, TaskV1 } from '@/model/Task.model';
import type { GroupApiId } from '@/model/TreatmentGroup.model';
import { web } from '@/support/route';
import { Dispatch, type FC, Fragment, SetStateAction, useMemo, useState } from 'react';

type AnimalRow = TaskAnimalV1 & { type: 'animal'; study_id: number };
type GroupRow = NonNullable<TaskAnimalV1['group']> & { type: 'group'; subRows: Array<AnimalRow>; expanded: boolean };
type UnGroupedRow = { type: 'ungrouped'; subRows: Array<AnimalRow>; expanded: boolean };
type TableRow = GroupRow | UnGroupedRow | AnimalRow;

const nameColumn: Column<TableRow> = {
  id: 'name',
  Header: 'Group',
  accessor: 'name',
  Cell: ({ row: { original } }) => {
    switch (original.type) {
      case 'group': {
        return (
          <div>
            <span
              className="mr1"
              style={{ display: 'inline-block', backgroundColor: original.color, width: 11, height: 11 }}
            />
            {original.name}
          </div>
        );
      }
      case 'ungrouped': {
        return <p>Unassigned</p>;
      }
      case 'animal': {
        return (
          <Link className="link" to={web('animals.show', { id: original.study_id, subject_id: original.id })}>
            {original.name}
          </Link>
        );
      }
    }
    return null;
  },
};
const cageColumn: Column<TableRow> = {
  id: 'cage',
  Header: 'Cage',
  accessor: 'cage',
  Cell: ({ row: { original } }) => {
    if (original.type === 'animal') {
      return <div>{original.cage?.name ?? '-'}</div>;
    }
    return null;
  },
};
const tailCoumn: Column<TableRow> = {
  id: 'tail',
  Header: 'Tail',
  Cell: ({ row: { original } }) => {
    if (original.type === 'animal') {
      return <div>{original.alt_ids?.tail ?? '-'}</div>;
    }
    return null;
  },
};
const earCoumn: Column<TableRow> = {
  id: 'ear',
  Header: 'Ear',
  Cell: ({ row: { original } }) => {
    if (original.type === 'animal') {
      return <div>{original.alt_ids?.ear ?? '-'}</div>;
    }
    return null;
  },
};
const tagCoumn: Column<TableRow> = {
  id: 'tag',
  Header: 'Tag',
  Cell: ({ row: { original } }) => {
    if (original.type === 'animal') {
      return <div>{original.alt_ids?.tag ?? '-'}</div>;
    }
    return null;
  },
};
const donorCoumn: Column<TableRow> = {
  id: 'donor',
  Header: 'Donor',
  Cell: ({ row: { original } }) => {
    if (original.type === 'animal') {
      return <div>{original.alt_ids?.donor ?? '-'}</div>;
    }
    return null;
  },
};

const ROW_CLASSES = 'f6 b--silver near-black fw5';

const TargetTableRow: FC<{
  row: TableRow;
  expanded: Array<ID>;
  setExpanded: Dispatch<SetStateAction<Array<ID>>>;
}> = ({ row, expanded, setExpanded }) => {
  const toggleExpandRow = (
    id: ID | UnGroupedRow['type'],
    expanded: Array<ID | UnGroupedRow['type']>,
    setExpanded: Dispatch<SetStateAction<Array<ID | UnGroupedRow['type']>>>
  ) => {
    const expandedRows = [...expanded];
    if (expandedRows.includes(id)) {
      const i = expandedRows.indexOf(id);
      expandedRows.splice(i, 1);
    } else {
      expandedRows.push(id);
    }
    setExpanded(expandedRows);
  };
  const rowExpanded = expanded.includes(row.type === 'group' ? row.id : 'ungrouped');
  return row.type === 'group' ? (
    <Fragment key={row.id}>
      <tr className={classNames(`h2 b--moon-gray`, { bb: !rowExpanded })}>
        <td className={`ph2 ${ROW_CLASSES}`}>
          <Icon
            icon={rowExpanded ? 'chev_up' : 'chev_down'}
            className="dib mid-gray pointer"
            width="14"
            height="14"
            onClick={() => toggleExpandRow(row.id ?? 'ungrouped', expanded, setExpanded)}
          />
        </td>
        <td className={ROW_CLASSES}>
          <p className="truncate">
            <span className="mr1 dib br1" style={{ backgroundColor: row.color, width: 11, height: 11 }}></span>
            {row.name}
          </p>
        </td>
        <td className={ROW_CLASSES} colSpan={5}></td>
      </tr>
      {rowExpanded &&
        row.subRows?.map((subRow, i) => {
          const lastSubRow = i === row.subRows.length - 1;
          return (
            <tr className={classNames(`h2 ${ROW_CLASSES}`, { bb: lastSubRow })}>
              <td></td>
              <td>
                <Link
                  to={web('animals.show', {
                    id: subRow.study_id,
                    subject_id: subRow.id,
                  })}
                  className="link blue"
                  openTab
                >
                  {subRow.name}
                </Link>
              </td>
              <td className="fw4 pl3">{subRow.cage?.name}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.tail}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.ear}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.tag}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.donor}</td>
            </tr>
          );
        })}
    </Fragment>
  ) : row.type === 'ungrouped' ? (
    <Fragment key={row.type}>
      <tr className={classNames(`h2 b--moon-gray`, { bb: !rowExpanded })}>
        <td className={`pl2 ${ROW_CLASSES}`}>
          <Icon
            icon={rowExpanded ? 'chev_up' : 'chev_down'}
            className="dib mid-gray pointer"
            width="14"
            height="14"
            onClick={() => toggleExpandRow(row.type, expanded, setExpanded)}
          />
        </td>
        <td className={ROW_CLASSES}>Unassigned</td>
        <td className={ROW_CLASSES} colSpan={5}></td>
      </tr>
      {rowExpanded &&
        row.subRows?.map((subRow, i) => {
          return (
            <tr className={`h2 ${ROW_CLASSES}`}>
              <td></td>
              <td>
                <Link
                  to={web('animals.show', {
                    id: subRow.study_id,
                    subject_id: subRow.id,
                  })}
                  className="link blue"
                  openTab
                >
                  {subRow.name}
                </Link>
              </td>
              <td className="fw4 pl3">{subRow.cage?.name}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.tail}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.ear}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.tag}</td>
              <td className="fw4 pl3">{subRow.alt_ids?.donor}</td>
            </tr>
          );
        })}
    </Fragment>
  ) : (
    <></>
  );
};

export const TargetPanel: FC<{ task: PickRequired<TaskV1, 'animals'> }> = ({ task }) => {
  const [expanded, setExpanded] = useState<Array<ID | UnGroupedRow['type']>>([]);
  const columns = useMemo<Columns<TableRow>>(() => {
    const displayedAltIds = task.animals.reduce<Record<AltId, boolean>>(
      (acc, animal) => {
        return Object.entries(animal.alt_ids ?? {}).reduce((innerAcc, [key, value]) => {
          if (!innerAcc[key as AltId] && !_isEmptyString(value)) {
            innerAcc[key as AltId] = true;
          }
          return innerAcc;
        }, acc);
      },
      { tail: false, ear: false, tag: false, donor: false }
    );
    const altIdColumns = Object.entries(displayedAltIds).reduce<Columns<TableRow>>((acc, [key, value]) => {
      if (value) {
        switch (key as AltId) {
          case 'donor':
            acc.push(donorCoumn);
            break;
          case 'ear':
            acc.push(earCoumn);
            break;
          case 'tag':
            acc.push(tagCoumn);
            break;
          case 'tail':
            acc.push(tailCoumn);
            break;
        }
      }
      return acc;
    }, []);
    return [nameColumn, cageColumn, ...altIdColumns];
  }, [task]);
  const data = useMemo(() => {
    const tableData = task.animals.reduce(
      (acc, animal) => {
        const groupId = animal.group?.api_id ?? 'ungrouped';
        const animalRow: AnimalRow = { ...animal, type: 'animal', study_id: task.study.id };
        if (acc.has(groupId)) {
          const row = acc.get(groupId) as GroupRow | UnGroupedRow;
          row.subRows.push(animalRow);
          acc.set(groupId, row);
        } else {
          acc.set(groupId, {
            ...animal.group,
            type: 'group',
            expanded: expanded.includes(groupId),
            subRows: [animalRow],
          } as GroupRow);
        }
        return acc;
      },
      new Map<GroupApiId | UnGroupedRow['type'], GroupRow | UnGroupedRow>([
        [
          'ungrouped',
          {
            type: 'ungrouped',
            expanded: expanded.includes('ungrouped'),
            subRows: [],
          },
        ],
      ])
    );
    if (tableData.get('ungrouped')?.subRows?.length === 0) {
      tableData.delete('ungrouped');
    }

    const sortedData = Array.from(tableData.values()).sort((a, b) => {
      if (a.type === 'ungrouped') return 1;
      if (b.type === 'ungrouped') return -1;
      return (a.name ?? '').localeCompare(b.name ?? '');
    });

    sortedData.forEach((group) => {
      group.subRows.sort((a, b) => a.id - b.id);
    });

    return sortedData;
  }, [task, expanded]);

  const toggleAllRows = (allIds: Array<ID>, setExpanded: Dispatch<SetStateAction<Array<ID>>>, allExpanded: boolean) => {
    if (allExpanded) {
      setExpanded([]);
    } else {
      setExpanded(allIds);
    }
  };

  const rowIds = data.reduce((acc, row) => {
    if (row.type === 'group') {
      acc.push(row.id);
    } else {
      acc.push(row.type);
    }
    return acc;
  }, [] as Array<ID>);

  const allExpanded = rowIds.length === expanded.length;

  return (
    <div className="pa4">
      <div className="bl br bb b--moon-gray bg-light-gray">
        <div className="ui__overflow__scroll_y ui_table ui__repeater">
          <table
            className="w-100 tl bt b--moon-gray"
            style={{ borderSpacing: 0, fontSize: 11, whiteSpace: 'nowrap', borderCollapse: 'collapse' }}
          >
            <thead className="bg-near-white bb b--moon-gray">
              <tr style={{ height: '30px' }}>
                <th className={`ph2 ${ROW_CLASSES}`}>
                  <Icon
                    icon={allExpanded ? 'chev_up' : 'chev_down'}
                    className="dib mid-gray pointer"
                    width="14"
                    height="14"
                    onClick={() => toggleAllRows(rowIds, setExpanded, allExpanded)}
                  />
                </th>
                {columns.map((col, index) => (
                  <th key={index} className={`${ROW_CLASSES} pl3`}>
                    {col.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="black bg-near-white">
              {data.map((dataRow) => {
                return (
                  <TargetTableRow
                    row={dataRow}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    key={dataRow.type === 'group' ? dataRow.api_id : 'ungrouped'}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
