// @ts-nocheck: converted from JS
import type { FC } from 'react';
import { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form@latest';
import { ErrorMessage } from '@hookform/error-message';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from '@/helpers';
import Button from '@/components/UI/Button';
import Banner from '@/components/UI/Banner';
import Link from '@/components/UI/Link';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import ApiErrorBanner from '@/components/ApiErrorBanner/ApiErrorBanner';
import { validatePasswordStrength } from './PasswordReset.utils';

interface FormValues {
  password: string;
  password_confirmation: string;
}
interface PasswordResetFormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  checkPasswordStrength: (password: string) => Promise<string | boolean>;
  isLoading: boolean;
}

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const formMethods = useForm<FormValues>();
  const { setError } = formMethods;
  const params = parseQueryString(location.search);
  const isMounted = useMountedState();

  const checkPasswordStrength = async (password) => {
    setLoading(true);
    try {
      const { data } = await Http.post(apiRoute('password_resets.evaluate'), {
        password,
      });
      setLoading(false);
      return validatePasswordStrength(data.feedback.suggestions, data.score);
    } catch (error) {
      setLoading(false);
      setApiError(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await Http.post(apiRoute('password_resets.update'), {
        ...data,
        email: params?.email,
        token: params?.token,
      });
      if (isMounted()) {
        setSuccess(true);
      }
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setApiError(error);
      }
      const {
        response: {
          data: { errors },
        },
      } = error;
      if (errors) {
        Object.keys(errors).map((e) =>
          setError(e, {
            type: 'manual',
            message: errors[e].map((m) => m).join(''),
          })
        );
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="fixed w-100 h-100 z-999 top-0 left-0 bg-near-white flex items-center">
        <div className="mw6 center w-100">
          {success ? (
            <Banner success className="mw6" dismiss={false}>
              <h3 className="normal lh-title f5 pb1">Successfully updated</h3>
              <p className="f6 lh-copy pb3">
                Your password has been updated, you can now log in with your new password.
              </p>
              <Button outline success url={'/login'}>
                Login with new password
              </Button>
            </Banner>
          ) : (
            <div>
              <h1 className="f4 lh-title near-black pb3 tc">Set new password</h1>
              {apiError && (
                <ApiErrorBanner
                  title="There was an error submitting your request"
                  text="Please try again later. If this keeps occurring please contact support."
                  error={apiError}
                  className="mb4"
                />
              )}
              <FormProvider {...formMethods}>
                <PasswordResetForm
                  checkPasswordStrength={checkPasswordStrength}
                  onSubmit={onSubmit}
                  isLoading={loading}
                />
                <div>
                  <Link to={webRoute('login')} className="link db">
                    &larr; Back to Log in
                  </Link>
                </div>
              </FormProvider>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const PasswordResetForm: FC<PasswordResetFormProps> = ({ onSubmit, checkPasswordStrength, isLoading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pa4 ui-card" data-testid="password-reset-form">
      <div className="mb3">
        <label htmlFor="password">New Password</label>
        <input
          {...register('password', {
            required: 'Enter your new password',
            validate: checkPasswordStrength,
          })}
          type="password"
          style={{ marginBottom: 0 }}
          className={`${errors.password ? 'input__error' : ''}`}
          data-testid="password-field"
        />
        <ErrorMessage
          errors={errors}
          name="password"
          render={({ message }) => (
            <p className="f6 red db pt2" data-testid="password-field-error-message">
              {message}
            </p>
          )}
        />
      </div>
      <div className="mb3">
        <label htmlFor="password_confirmation">Confirm new password</label>
        <input
          {...register('password_confirmation', {
            required: 'Enter your new password again',
            validate: (value) => value === watch('password') || 'Must be the same as your new password.',
          })}
          type="password"
          style={{ marginBottom: 0 }}
          className={`${errors.password_confirmation ? 'input__error' : ''}`}
          data-testid="password-confirmation-field"
        />
        <ErrorMessage
          errors={errors}
          name="password_confirmation"
          render={({ message }) => (
            <p className="f6 red db pt2" data-testid="password-confirmation-field-error-message">
              {message}
            </p>
          )}
        />
      </div>
      <div className="pb3 mb3">
        <Button submit disabled={isLoading} testId="password-reset-submit-button">
          Save
        </Button>
      </div>
    </form>
  );
};

export default PasswordReset;
