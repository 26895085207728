import { FormField } from '@/components/UI/FormFields/Latest';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils';
import React from 'react';
import { KeyboardEventHandler } from 'react';
import { useFormContext } from 'react-hook-form@latest';

interface TextProps {
  value?: string;
  name: string;
  label: string;
  disabled?: boolean;
  hint?: string;
  required?: boolean;
  pattern?: { value: RegExp; message: string };
  placeholder?: string;
  maxLength?: number;
  autoFocus?: boolean;
  testId?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const Text: React.FC<TextProps> = ({
  value,
  name,
  label,
  disabled = false,
  hint,
  required,
  pattern,
  placeholder,
  maxLength,
  autoFocus,
  onKeyDown,
  testId = 'text-field',
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <FormField label={label} name={name} hint={hint} required={required} testPrefix={testId}>
      <input
        type="text"
        data-test-component="Text"
        data-test-element="input"
        style={{ marginBottom: 0 }}
        className={errors?.[name] ? 'input__error' : ''}
        {...register(name, getRegisterConfig({ required, pattern, maxLength: maxLength ?? 250 }))}
        defaultValue={value}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        data-testid={testId}
        onKeyDown={onKeyDown}
      />
    </FormField>
  );
};

export default Text;
