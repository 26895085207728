import type {
  AnovaStats,
  OneWayAnovaResults,
} from '@/components/Studies/Randomization/Steps/Randomize/Statistics.model';
import Table from '@/components/UI/Table';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import type React from 'react';
import { useMemo } from 'react';

interface OneWayAnovaTableProps {
  oneWayAnova: OneWayAnovaResults;
  closeModal: () => void;
}

type TabulatedAnovaStats = AnovaStats & { pvalue?: number; source: string };

const OneWayAnovaTable: React.FC<OneWayAnovaTableProps> = ({ oneWayAnova, closeModal }) => {
  const tableOrder: Record<string, number> = { treatment: 0, error: 1, total: 2 };
  const tabulatedAnovaData = Object.entries(oneWayAnova.anovaTable).reduce<Array<TabulatedAnovaStats>>(
    // Massaging custom presentation order
    (acc, [key, value]) => {
      acc[tableOrder[key]] = {
        ...value,
        source: key,
        ...(key === 'treatment' ? { pvalue: oneWayAnova.pvalue } : {}),
      };

      return acc;
    },
    []
  );

  const columns = useMemo<Array<Column<TabulatedAnovaStats>>>(() => {
    return [
      {
        id: 'source',
        accessor: 'source',
        Header: 'Source',
        style: { backgroundColor: '#f6f8fa', fontWeight: 500 },
        sortDisabled: true,
      },
      {
        id: 'df',
        accessor: 'degreesOfFreedom',
        Header: 'Degrees of Freedom',
        sortDisabled: true,
      },
      {
        id: 'ss',
        Cell: ({ row }) => row.original.sumOfSquares.toFixed(4),
        Header: 'Sum of Squares',
        sortDisabled: true,
      },
      {
        id: 'ms',
        Cell: ({ row }) => row.original.meanSquare?.toFixed(4),
        Header: 'Mean Square',
        sortDisabled: true,
      },
      {
        id: 'fDist',
        Cell: ({ row }) => row.original.fdist?.toFixed(4),
        Header: 'F Stat',
        sortDisabled: true,
      },
      {
        id: 'pvalue',
        Cell: ({ row }) => row.original.pvalue?.toFixed(4),
        Header: 'P Value',
        sortDisabled: true,
      },
    ];
  }, [oneWayAnova]);

  return (
    <ModalContainer className="pa3">
      <ModalHeader title={`Anova Table`} closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      <Table columns={columns} data={tabulatedAnovaData} />
    </ModalContainer>
  );
};

export default OneWayAnovaTable;
