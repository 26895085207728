import { _chunk, safelyDecodeURIComponent } from '@/littledash';
import type { MetadataCageCardItem } from '@/model/Metadata.model';
import { useMemo } from 'react';
import '../CageCardTemplate.scss';

interface CageCardMetadataProps {
  metadata: Array<MetadataCageCardItem>;
}

const Metadata = ({ metadata = [] }: CageCardMetadataProps) => {
  const MAX_NUMBER_METADATA = 8;
  const METADATA_PER_GROUP = 2;
  const metadataColumns: Array<Array<MetadataCageCardItem>> = useMemo(
    () => _chunk(metadata.slice(0, MAX_NUMBER_METADATA), METADATA_PER_GROUP),
    [metadata, MAX_NUMBER_METADATA]
  );
  return (
    <table className="w-100 tl black" style={{ borderSpacing: 0, fontSize: 11 }}>
      {metadataColumns.map((metadataItemRow, i) => (
        <tr className={`card-row max-${metadataColumns.length}`} key={i}>
          {metadataItemRow.map(({ id, title, value }) => (
            <td key={id} className={'w-50 border'}>
              <div className="flex flex-row justify-between">
                <div>{title}</div>
                <div>{Array.isArray(value) ? value.map((val) => safelyDecodeURIComponent(val)).join(', ') : value}</div>
              </div>
            </td>
          ))}
          {metadataItemRow?.length === 1 && <td className={'w-50 border'}></td>}
        </tr>
      ))}
    </table>
  );
};

export default Metadata;
