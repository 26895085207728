// @ts-nocheck: converted from JS

import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import Table from '@/components/UI/Table';
import Pagination from '@/components/UI/Table/Pagination';
import { filterDataBySearch } from '@/components/UI/Table/Table.utils';
import { useState } from 'react';

const columns = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row: { original } }) => {
      return (
        <Link className="blue link normal lh-copy underline-hover" to={`/tasks/templates/${original.id}`}>
          {original.name}
        </Link>
      );
    },
  },
  {
    id: 'createdAt',
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({
      row: {
        original: { created_at },
      },
    }) => <DateTimeRenderer value={created_at} defaultResponse="-" />,
  },
  {
    id: 'updateAt',
    Header: 'Updated',
    accessor: 'updated_at',
    Cell: ({
      row: {
        original: { updated_at },
      },
    }) => <DateTimeRenderer value={updated_at} defaultResponse="-" />,
  },
];

const TemplatesTable = ({ templates }) => {
  const [settings, setSettings] = useState({
    rows: 10,
    page: 0,
  });
  const [searchQuery, setQuery] = useState('');

  const TablePagination = (props) => <Pagination {...props} settings={settings} updateSettings={setSettings} />;

  let data = templates.map((t) => {
    return {
      id: t.id,
      name: t.name,
      created_at: t.created_at,
      updated_at: t.updated_at,
    };
  });

  if (searchQuery !== '') {
    data = filterDataBySearch(data, searchQuery);
  }

  return (
    <>
      <div className="pa3">
        <SearchFilterBar
          searchPlaceholderText="Search by template name"
          handleSearchInput={setQuery}
          searchQuery={searchQuery}
        />
      </div>
      <div className="ui__table">
        <Table
          data={data}
          columns={columns}
          defaultPageSize={settings.rows}
          page={settings.page}
          pageSize={settings.rows}
          resizable={false}
          minRows={0}
          PaginationComponent={TablePagination}
        />
      </div>
    </>
  );
};

export default TemplatesTable;
