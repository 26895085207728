// @ts-nocheck: converted from JS

import { capitalise, missingNameUseEmail, successToast } from '@/helpers';
import { reviewActionToTextMap } from '@/referenceData/study/status';
import * as Auth from '@/support/auth';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const ConfirmStudyApproval = ({ approvalID, status, callback, approvers }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const currentUserId = useSelector((state) => state.user.currentUser.id);

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const continueApproval = ({ originalApprover }) => {
    setLoading(true);
    Http.post(apiRoute('approvals.requests.update', approvalID), {
      approver_id: originalApprover ? Number(originalApprover) : currentUserId,
      response: status,
    })
      .then(() => {
        callback();
        successToast('Study approval updated successfully');
      })
      .catch((error) => {
        callback(error);
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  return (
    <div className="ui__narrow-modal" data-test-component="ConfirmStudyApproval">
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">{`${capitalise(reviewActionToTextMap[status])} Review`}</h3>
        <p className="f6 pb2">
          Are you sure you would like to {reviewActionToTextMap[status]} this review? This action cannot be undone.
        </p>
      </div>
      <form onSubmit={handleSubmit(continueApproval)}>
        {Auth.isCurrentTeamOwner() && (
          <div className={`pb2 ${errors.original_approver ? 'hasError' : ''}`}>
            <label>{capitalise(reviewActionToTextMap[status])} on behalf of</label>
            <select
              style={{ marginBottom: 0 }}
              name="originalApprover"
              ref={register({
                required: 'Required',
              })}
            >
              {approvers.map((approver) => {
                if (!approver.response) {
                  approver = missingNameUseEmail(approver);
                  return (
                    <option key={approver.id} value={approver.id}>
                      {approver.name}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        )}
        <div>
          <button type="submit" disabled={loading} data-test-element="submit-button" className="button w-100 mb2">
            {capitalise(reviewActionToTextMap[status])} review
          </button>
          <a className="button plain w-100 db" disabled={loading} onClick={closeModal}>
            Cancel
          </a>
        </div>
      </form>
    </div>
  );
};

export default ConfirmStudyApproval;
