import type { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';

interface LinkBaseProps extends Partial<Pick<HTMLAnchorElement, 'target' | 'rel'>> {
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  style?: CSSProperties;
  role?: string;
}

interface LinkProps extends LinkBaseProps {
  to?: string;
  openModal?: boolean;
  openTab?: boolean;
  href?: string;
  children?: ReactNode;
}

const Link: FC<LinkProps> = ({ to, children, openModal, openTab, href, ...baseProps }) => {
  let additionalProps: LinkBaseProps = baseProps;

  if (openTab) {
    additionalProps = { ...additionalProps, rel: 'noopener noreferrer', target: '_blank' };
  }

  // TODO: openModal is simply a styling property, onClick seems to handle the actually functionality
  // Modal should really be broken out to another component, as this Link comp essentially has two
  // completely separate functionalities, hence the plethora of optional params
  if (openModal) {
    return <a {...additionalProps}>{children}</a>;
  }

  if ((to && (to.includes('mailto') || to.includes('http'))) || href) {
    return (
      <a href={to} {...additionalProps}>
        {children}
      </a>
    );
  }

  return (
    // If to is null it never actually reaches this point as the modal would hit first, but this isn't
    // an ideal behaviour. Would be solved when ripping out modal to another component
    <ReactLink to={to ?? ''} {...additionalProps}>
      {children}
    </ReactLink>
  );
};

export default Link;
