// @ts-nocheck: converted from JS

export const isTextField = (id, metadata) => {
  const metadataItem = metadata.find((metadataItem) => metadataItem.id === id);
  return metadataItem?.field_type.toLowerCase() === 'text';
};

export const isSelectField = (id, metadata) => {
  const metadataItem = metadata.find((metadataItem) => metadataItem.id === id);
  return metadataItem?.field_type.toLowerCase() === 'select';
};

export const isMultiSelectField = (id, metadata) => {
  const metadataItem = metadata.find((metadataItem) => metadataItem.id === id);
  return metadataItem?.field_type.toLowerCase() === 'multi_select';
};

export const isDateField = (id, metadata) => {
  const metadataItem = metadata.find((metadataItem) => metadataItem.id === id);
  return metadataItem?.field_type.toLowerCase() === 'date';
};

export const isLookupSelectField = (id, metadata) => {
  const metadataItem = metadata.find((metadataItem) => metadataItem.id === id);
  return (
    metadataItem?.field_type.toLowerCase() === 'lookup_select' ||
    metadataItem?.field_type.toLowerCase() === 'lookup_multi_select'
  );
};
