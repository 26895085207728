import type { DataTableRow, DataTableService, DataTableWorkflowEvent } from '@/components/UI/DataTable/DataTable.model';
import { DataTableEvent } from '@/components/UI/DataTable/DataTable.model';
import { DataTableRowSearch } from '@/components/UI/DataTable/toolbar/Menus/Search/DataTableRowSearch';
import { Dropdown } from '@/components/UI/Dropdown';
import type { ToggleDropdownMenu } from '@/components/UI/Dropdown/Dropdown.model';
import { _isNil, _notNil } from '@/littledash';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';

interface DataTableSearchMenuItemProps {
  dataTableService: DataTableService;
  disabled?: boolean;
}

export const DataTableRowSearchMenuItem: FC<DataTableSearchMenuItemProps> = ({
  dataTableService,
  disabled = false,
}) => {
  const [forceOpen, setForceOpen] = useState<true | undefined>();

  useEffect(() => {
    const eventListener = (event: KeyboardEvent) => {
      if (event.code === 'KeyF' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        event.stopImmediatePropagation();
        setForceOpen(true);
      }
    };
    document.body.addEventListener('keydown', eventListener);
    return () => {
      document.body.removeEventListener('keydown', eventListener);
    };
  });

  useEffect(() => {
    const workflowActionEnterListener = (event: DataTableWorkflowEvent) => {
      if (event.detail.source === 'search') {
        setForceOpen(true);
      }
    };
    dataTableService.subscribe(DataTableEvent.WorkflowActionEnter, workflowActionEnterListener);
    return () => {
      dataTableService.unsubscribe(DataTableEvent.WorkflowActionEnter, workflowActionEnterListener);
    };
  }, [dataTableService, setForceOpen]);
  const handleDropdownOpen = (open: boolean) => {
    if (forceOpen !== undefined && forceOpen !== open) {
      setForceOpen(undefined);
    }
  };
  const handleRowSelect = (row: DataTableRow, toggleDropdownMenu: ToggleDropdownMenu) => {
    toggleDropdownMenu(false);
    const targetRowIndex = dataTableService.toIndexRow(row.id);
    if (_notNil(targetRowIndex)) {
      if (!dataTableService.workflowService.workflowActionExit('search', { targetRowIndex })) {
        const currentColumn = dataTableService?.selection?.bottomRight.column;
        if (_isNil(currentColumn)) {
          dataTableService.selectRow(targetRowIndex);
        } else {
          dataTableService.selectCell(
            { row: targetRowIndex, column: currentColumn },
            { source: 'user', scrollTo: true }
          );
        }
      }
    }
  };

  return (
    <Dropdown
      disabled={disabled}
      renderMenu={({ toggleDropdownMenu }) => (
        <DataTableRowSearch
          rows={dataTableService?.rows ?? []}
          onSelect={(row) => handleRowSelect(row, toggleDropdownMenu)}
        />
      )}
      open={forceOpen}
      onOpen={handleDropdownOpen}
    >
      <span className="pa2">
        <RiSearchLine size={22} />
      </span>
    </Dropdown>
  );
};
