import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { ID } from '@/model/Common.model';
import type { Dispatch } from 'react';

export type AnimalAlertAction =
  | { type: 'SET_ALERTS'; data: Array<AnimalAlertV1> }
  | { type: 'SET_CURRENT_ANIMAL_ID'; data: ID };

export type AlertDispatch = Dispatch<AnimalAlertAction>;

interface AnimalAlertState {
  alerts: Array<AnimalAlertV1>;
  currentAnimalId: ID;
}

export const initialState: AnimalAlertState = {
  alerts: [],
  currentAnimalId: 0,
};

export const reducer = (state: Readonly<AnimalAlertState>, action: AnimalAlertAction) => {
  switch (action.type) {
    case 'SET_ALERTS':
      return {
        ...state,
        alerts: action.data,
      };
    case 'SET_CURRENT_ANIMAL_ID':
      return {
        ...state,
        currentAnimalId: action.data,
      };
    default: {
      return state;
    }
  }
};
