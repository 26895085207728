import type React from 'react';
import type { CSSProperties } from 'react';
import { isString } from '@/helpers';

export type GridData = Array<Array<string | React.ReactNode>>;

interface InlineTableProps {
  data: GridData;
  className?: string;
  style?: CSSProperties;
}

const InlineTable: React.FC<InlineTableProps> = ({ data = [], className = '', style }) => (
  <div className={`dt dt--fixed w-100 f6 near-black bb br b--moon-gray ${className}`} style={style}>
    {data.map((row, rowIndex) => (
      <div className="dt-row" key={`row-${rowIndex}`}>
        {row.map((cell, cellIndex) => (
          <div
            className={`dtc bl bt b--moon-gray ${isString(cell) ? 'pa2' : ''}`}
            key={`row-${rowIndex}-cell-${cellIndex}`}
          >
            {cell}
          </div>
        ))}
      </div>
    ))}
  </div>
);

export default InlineTable;
