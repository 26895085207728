// @ts-nocheck: converted from JS

import { hasOwnProperty, isEquivalent } from '@/helpers';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';

const tasksToBeDeletedPromises = (existingTasks, stateTasks) => {
  const tasksToBeDeleted = [existingTasks, stateTasks].reduce((a, b) =>
    a.filter((c) => !b.map((t) => t.id).includes(c.id))
  );

  if (tasksToBeDeleted?.length) {
    return tasksToBeDeleted.map((t) =>
      Http.delete(apiRoute('tasks.template.destroy', { id: t.id }), {
        ...t,
        conditional_settings: t.conditional_settings || [],
      })
    );
  }
};

const tasksToBeEditedPromises = (existingTasks, stateTasks) => {
  const tasksToBeEdited = [existingTasks, stateTasks].reduce((a, b) =>
    b.filter((c) => a.map((t) => t.id).includes(c.id))
  );

  if (tasksToBeEdited?.length) {
    return tasksToBeEdited.map((t) => {
      const originalTask = existingTasks.find((et) => Number(t.id) === Number(et.id));
      if (!isEquivalent(t, originalTask)) {
        return Http.put(apiRoute('tasks.template.update', { id: t.id }), {
          ...t,
          conditional_settings: t.conditional_settings || [],
        });
      }
    });
  }
};

const tasksToBeAddedPromises = (stateTasks, templateGroupId) => {
  const tasksToBeAdded = stateTasks.filter((t) => !hasOwnProperty(t, 'id'));

  if (tasksToBeAdded?.length) {
    return tasksToBeAdded.map((t) =>
      Http.post(apiRoute('tasks.template.create', { taskTemplateGroup: templateGroupId }), {
        ...t,
        conditional_settings: t.conditional_settings || [],
      })
    );
  }
};

export const updateExistingTasksAPI = (existingTasks, stateTasks, templateGroupId) => {
  if (existingTasks?.length && stateTasks?.length && templateGroupId) {
    return Promise.all([
      tasksToBeDeletedPromises(existingTasks, stateTasks),
      tasksToBeEditedPromises(existingTasks, stateTasks),
      tasksToBeAddedPromises(stateTasks, templateGroupId),
    ]);
  }

  return [];
};
