// @ts-nocheck: converted from JS

import { _size } from '@/littledash';
import React from 'react';

export default class Pagination extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }

    this.changePage(nextProps.page + 1);
  }

  render() {
    const { settings, updateSettings, pages, data, pageSize } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <div className="flex-l flex-wrap justify-between items-center">
        <div className="w-100 tc pb2 pb0-l w-third-l tl-l">
          <PageNavigation
            changePage={this.changePage}
            activePage={activePage}
            pages={pages}
            visiblePages={visiblePages}
            updateSettings={updateSettings}
            settings={settings}
          />
        </div>

        {this.props.enableRowSelect && (
          <div className="w-100 tc pb2 pb0-l w-third-l">
            <RowSelect updateSettings={updateSettings} settings={settings} />
          </div>
        )}

        <div className="w-100 tc w-third-l tr-l">
          <p className="f6 ma0">
            Rows {activePage === 1 ? 1 : activePage * pageSize - pageSize + 1} - {activePage * pageSize} of{' '}
            {_size(data)}
          </p>
        </div>
      </div>
    );
  }

  /** Methods */

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages),
    });

    this.props.onPageChange(page - 1);
    const settings = this.props.settings;
    settings.page = Number(page - 1);
    this.props.updateSettings({ ...settings });
  }
}

Pagination.defaultProps = {
  enableRowSelect: true,
};
const PageNavigation = ({ changePage, visiblePages, activePage, pages }) => {
  const baseClasses = 'dib pointer hover-near-black';

  return (
    <div className="f6">
      <a className={`${baseClasses} pr2`} onClick={() => changePage(1)} disabled={activePage === 1}>
        «
      </a>
      <a
        className={`${baseClasses} ph2`}
        onClick={(event) => {
          if (activePage === 1) {
            return;
          }
          changePage(activePage - 1);
        }}
        disabled={activePage === 1}
      >
        ←
      </a>
      {visiblePages.map((page, index, array) => (
        <a
          page={page}
          key={page}
          className={`
            ph2
            ${baseClasses}
            ${activePage === page ? 'basier-med near-black' : 'Table__pageButton'}
          `}
          onClick={changePage.bind(null, page)}
        >
          {array[index - 1] + 2 < page ? `...${page}` : page}
        </a>
      ))}
      <a
        className={`${baseClasses} ph2`}
        onClick={() => {
          if (activePage === pages) {
            return;
          }
          changePage(activePage + 1);
        }}
        disabled={activePage === pages}
      >
        →
      </a>
      <a
        page={pages}
        className={`${baseClasses} pl2`}
        onClick={() => changePage(pages)}
        disabled={activePage === pages}
      >
        »
      </a>
    </div>
  );
};

const RowSelect = ({ settings, updateSettings }) => {
  const handleChange = (event, settings, updateSettings) => {
    event.preventDefault();
    settings.rows = Number(event.target.value);
    settings.page = 0;
    updateSettings({ ...settings });
  };
  const rowOptions = [5, 10, 25, 50, 100, 200];

  return (
    <div className="f6 tc" data-testid="table__per-page">
      View
      <select
        className="f6 mh2 w3 mv0"
        onChange={(event) => handleChange(event, settings, updateSettings)}
        data-testid="table__per-page-options"
      >
        {rowOptions.map((row) => (
          <option key={row} value={row} selected={row === settings.rows}>
            {row}
          </option>
        ))}
      </select>
      per page
    </div>
  );
};
