import type {
  DataTableColumn,
  DataTableColumnAddRequest,
  DataTableColumnId,
} from '@/components/UI/DataTable/DataTable.model';
import {
  columnNameToFormulaVariable,
  formulaVariablesToColumnIds,
  generateColumnFormulaName,
} from '@/components/UI/DataTable/DataTable.util';
import { uniqueNameValidatorFactory } from '@/components/UI/DataTable/toolbar/Menus/AddColumn/AddColumn.util';
import Icon from '@/components/UI/Icon';
import Link from '@/components/UI/Link';
import { _isNil, _isNotEmpty } from '@/littledash';
import type { Variable } from '@/utils/FormulaDsl';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import { FC, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, FieldError, useForm } from 'react-hook-form';
import { ColumnList } from './ColumnList';
import styles from './EditDataTableFormulaColumn.module.scss';
import { FormulaInput } from './FormulaInput';

interface EditDataTableFormulaColumnForm {
  name: string;
  unit: string;
  formula: string;
}

interface EditDataTableFormulaColumnProps {
  formData: EditDataTableFormulaColumnForm;
  columnId?: DataTableColumnId;
  columns: Array<DataTableColumn>;
  onSubmit: (data: DataTableColumnAddRequest) => Promise<void>;
  closeModal: () => void;
}

export const EditDataTableFormulaColumn: FC<EditDataTableFormulaColumnProps> = ({
  formData,
  columnId,
  columns,
  onSubmit,
  closeModal,
}) => {
  const variables = useMemo<Record<Variable, DataTableColumn>>(
    () =>
      columns.reduce<Record<Variable, DataTableColumn>>((acc, column) => {
        if (column.type === 'number' || column.type === 'measurement') {
          acc[columnNameToFormulaVariable(generateColumnFormulaName(column), '$') as Variable] = column;
        }
        return acc;
      }, {}),
    [columns]
  );
  const columnNames = Object.keys(variables);
  const uniqueColumnName = useMemo(
    () => uniqueNameValidatorFactory(new Set(columns.filter((c) => c.id !== columnId).map(({ name }) => name.trim()))),
    [columnId, columns]
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    errors,
    formState: { isSubmitting, isDirty },
  } = useForm<EditDataTableFormulaColumnForm>({
    mode: 'onChange',
    defaultValues: { ...formData },
  });
  const [isValidating, setIsValidating] = useState(false);
  const [caretPosition, setCaretPosition] = useState(0);

  const handleColumnSelect = (column: DataTableColumn) => {
    const currentValue = getValues('formula') ?? '';
    const formulaToAppend = columnNameToFormulaVariable(generateColumnFormulaName(column), '$');
    const newValue = `${currentValue.substring(0, caretPosition)}${formulaToAppend}${currentValue.substring(
      caretPosition
    )}`;
    setValue('formula', newValue, { shouldDirty: true });
  };

  const mapFormData = async (data: EditDataTableFormulaColumnForm) => {
    const formula = await formulaVariablesToColumnIds(data.formula, columns);
    const unit = data?.unit?.trim() ?? '';
    await onSubmit({
      type: 'formula',
      name: data.name,
      unit: unit.length === 0 ? null : unit,
      formula,
    });
    closeModal();
  };

  return (
    <ModalContainer size="medium">
      <ModalHeader
        title={`${_isNil(columnId) ? 'Add' : 'Update'} formula`}
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
        subText={
          <>
            Click the column names and operators to build your formula.{' '}
            <Link
              to="https://help.benchling.com/hc/en-us/articles/22351086335373"
              openTab
              style={{ color: 'dodgerblue' }}
            >
              Read more
            </Link>
          </>
        }
      />

      <form>
        <div className={styles['data-table-formula-column-container']}>
          <div className={styles['data-table-formula-column-list']}>
            <ColumnList columns={Object.values(variables)} columnSelected={handleColumnSelect} />
          </div>
          <div className="pa3 w-100 bl b--moon-gray h-100">
            <div className="flex flex-row">
              <div style={{ flexGrow: 4 }}>
                <label htmlFor="name">Column name</label>
                <input
                  type="text"
                  name="name"
                  ref={register({ required: 'This field is required', maxLength: 255, validate: { uniqueColumnName } })}
                  placeholder="Name"
                  className={errors?.name ? 'input__error' : ''}
                  style={{ marginBottom: 0 }}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => <small className="red db pt2">{message}</small>}
                />
              </div>
              <div className="w4 ml3">
                <label htmlFor="unit">Unit</label>
                <input type="text" name="unit" ref={register()} placeholder="Unit" style={{ marginBottom: 0 }} />
                <ErrorMessage
                  errors={errors}
                  name="unit"
                  render={({ message }) => <small className="red db pt2">{message}</small>}
                />
              </div>
            </div>
            <div className="mt3">
              <label htmlFor="">Formula</label>

              <Controller
                name="formula"
                control={control}
                rules={{
                  required: 'This field is required',
                  validate: (val: string) =>
                    !columnNames.some((colName) => val.includes(colName))
                      ? 'At least one column must be selected'
                      : true,
                }}
                render={({ value, onChange }: ControllerRenderProps) => (
                  <FormulaInput
                    variables={variables}
                    value={value}
                    onChange={onChange}
                    isValidating={setIsValidating}
                    isValid={(valid, message) => {
                      if (valid) {
                        setIsValidating(false);
                      } else {
                        setError('formula', { type: 'validate', message });
                      }
                    }}
                    setCaretPosition={setCaretPosition}
                  />
                )}
              />
              {_isNotEmpty(errors.formula) && <FormulaError error={errors.formula} />}
            </div>
          </div>
        </div>
        <ModalActions
          className="pa3 bt b--moon-gray"
          onSubmit={handleSubmit(mapFormData)}
          onCancel={closeModal}
          submitBtnText={_isNil(columnId) ? 'Add' : 'Update'}
          submitButtonProps={{
            disabled: _isNotEmpty(errors) || isValidating || !isDirty || isSubmitting,
            submit: true,
          }}
        />
      </form>
    </ModalContainer>
  );
};

interface FormulaErrorProps {
  error: FieldError;
}
const FormulaError: FC<FormulaErrorProps> = ({ error }) => (
  <div className={`f6 bg-near-white near-black ${styles['data-table-formula-error']}`}>
    <div className={`${styles['data-table-formula-error-info-box']} br3 br--top--left`}>
      <div className="flex flex-column">
        <div className="flex">
          <Icon icon="critical_alert" className="dark-red" width="24" height="24" />
          <span className="fw5 black">
            Formula error: <b>{error.message}</b>
          </span>
        </div>
        <div className="flex-grow-1 flex-column flex lh-copy ml2">
          <div>
            Click the buttons above or the column names to the left rather than typing your formula to ensure it’s
            valid.
          </div>
          <div>
            {'You can also '}
            <Link
              to="https://help.benchling.com/hc/en-us/articles/22351086335373"
              openTab
              style={{ color: 'dodgerblue' }}
            >
              check the help article.
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
