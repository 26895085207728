// @ts-nocheck: converted from JS

import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import FormRender from '@/components/UI/FormRender';
import Summary, { SummaryItem } from '@/components/UI/Summary';
import React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

const RequestSummary = ({ ActionButtons, onSubmit, request, requestTemplate }) => {
  const formMethods = useForm({
    defaultValues: request.form_data,
  });

  const groups = requestTemplate.form.groups.map((group) => ({
    ...group,
    fields: Array.isArray(group.fields) ? group.fields : [],
  }));

  return (
    <div data-test-component="RequestSummary" data-test-element="container">
      <FormProvider {...formMethods}>
        <FormRender groups={groups} submitted />
      </FormProvider>
      <div className="mw7 w-100">
        <Summary>
          <SummaryItem label="Due date">
            <DateRenderer value={request.due_date} defaultResponse="-" />
          </SummaryItem>
        </Summary>
        <Summary title="Samples">
          <SummaryItem label="Total samples">{request.samples.length || 0}</SummaryItem>
        </Summary>
      </div>

      {React.cloneElement(ActionButtons, { onOk: () => onSubmit() })}
    </div>
  );
};

export default RequestSummary;
