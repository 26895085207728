import ApiErrorBanner from '@/components/ApiErrorBanner';
import { samplesConfigObj } from '@/components/Glossary/Sections/SampleTypes/SamplesConfig';
import ReasonForChange from '@/components/Shared/ReasonForChange';
import Button from '@/components/UI/Button';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative';
import type { MetadataFieldBuilderReturn } from '@/components/UI/FormFields/FormField.utils';
import { mapMetadataSubmissions, metadataFieldBuilder } from '@/components/UI/FormFields/FormField.utils';
import FormRender from '@/components/UI/FormRender';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import type { Sample, SampleMeasurement, SampleType } from '@/model/Sample.model';
import { useRequest } from '@/support/Hooks/request';
import { ErrorMessage } from '@hookform/error-message';
import type { AxiosResponse } from 'axios';
import type React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DetailField } from './DetailField';

interface InitialSample {
  sample_id?: string;
  date?: string;
  metadata: unknown;
  details: Array<SampleMeasurement>;
  comments?: string;
  reason_for_change?: string;
}

const initialiseSample = (sampleType: SampleType, sample?: Sample): InitialSample => {
  const typeDetails = (sampleType?.options?.details ?? []).map<SampleMeasurement>((detailKey) => ({
    key: detailKey,
    unit: samplesConfigObj?.[detailKey]?.units?.[0] ?? '',
    value: '',
  }));

  if (_notNil(sample)) {
    const sampleDetailsMap = new Map<SampleMeasurement['key'], SampleMeasurement>(
      (sample?.details ?? []).map((detail) => [detail.key, detail])
    );

    return {
      sample_id: sample?.sample_id,
      details: typeDetails.map((detail) =>
        sampleDetailsMap.has(detail.key) ? (sampleDetailsMap.get(detail.key) as SampleMeasurement) : detail
      ),
      metadata: sample?.metadata,
      comments: sample?.comments,
      date: sample?.date,
    };
  }

  return {
    metadata: [],
    details: typeDetails,
    reason_for_change: '',
  };
};

const sampleMetadataFields = (
  sampleMetas: Array<MetadataFieldWithValue>,
  sample: InitialSample
): Array<MetadataFieldBuilderReturn> =>
  sampleMetas.map((meta: MetadataFieldWithValue) => metadataFieldBuilder(meta, sample));

interface SampleFormProps {
  animalId: ID;
  collectedAt: string;
  allowNaming: boolean;
  sample?: Sample;
  sampleType: SampleType;
  sampleMetadataGlossary: Array<MetadataFieldWithValue>;
  handleAfterSave: (response: AxiosResponse) => void;
  saveButtonText: string;
  includeDatetimeField: boolean;
  formContainerRef: React.RefObject<HTMLFormElement>;
}

export const SampleForm: React.FC<SampleFormProps> = ({
  animalId,
  collectedAt,
  allowNaming = false,
  sample,
  sampleType,
  sampleMetadataGlossary,
  handleAfterSave,
  saveButtonText = 'Save',
  includeDatetimeField = false,
  formContainerRef,
}) => {
  const initialSample = initialiseSample(sampleType, sample);
  const formMethods = useForm<InitialSample>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    defaultValues: initialSample,
  });
  const { register, control, errors, handleSubmit } = formMethods;
  const { fields: details } = useFieldArray<SampleMeasurement>({
    control,
    name: 'details',
  });
  const metaFieldValues = sampleMetadataFields(sampleMetadataGlossary, initialSample);

  const {
    sendRequest: checkIfNameIsUnique,
    requestSending: checkingIfNameIsUnique,
    requestError: nameValidationRequestError,
  } = useRequest<{ value: unknown }, { is_unique: boolean }>({
    route: 'samples.validate.isNameUnique',
    method: 'post',
  });

  const {
    sendRequest: saveSample,
    requestSending: savingSample,
    requestError: saveSampleError,
  } = useRequest({
    route: _notNil(sample) ? 'animal.sample.update' : 'animal.sample.create',
    params: {
      id: sample?.id || animalId,
    },
    method: sample ? 'put' : 'post',
  });

  const validateNameIsUnique = async (value: string): Promise<true | string> => {
    if (sample?.sample_id !== value) {
      const response = await checkIfNameIsUnique({ value });
      return (response?.data?.is_unique ?? true) ? true : 'This name is already in use, please try another';
    } else {
      return true; // the sample name has already been reserved
    }
  };

  interface FormData {
    sample_id?: string;
    date?: string;
    meta: {
      glossary_id: number;
      title: string;
      value: string;
    };
    comments?: string;
    details?: Array<SampleMeasurement>;
    reason_for_change?: string;
  }

  interface FormattedPayload extends Pick<FormData, 'sample_id' | 'date' | 'comments' | 'details'> {
    glossary_id: ID;
    metadata: unknown;
    reason_for_change?: string;
  }

  const handlePayload = (data: FormData): FormattedPayload => {
    let formattedData: FormattedPayload = {
      glossary_id: sampleType?.id,
      details: data.details?.filter((detail) => !isNaN(Number(detail.value))) ?? [],
      metadata: mapMetadataSubmissions(data.meta, sampleMetadataGlossary),
      date: data?.date ?? collectedAt,
      comments: data.comments,
      reason_for_change: data.reason_for_change,
    };

    if (allowNaming && _notNil(data?.sample_id)) {
      formattedData = {
        ...formattedData,
        sample_id: data.sample_id,
      };
    }

    return formattedData;
  };

  const onSubmit = async (data: FormData): Promise<void> => {
    const payload = handlePayload(data);
    const response = await saveSample(payload);
    handleAfterSave(response);
  };

  const disableSavePress = checkingIfNameIsUnique || savingSample;

  return (
    <FormProvider {...formMethods}>
      <form
        ref={formContainerRef}
        onSubmit={handleSubmit(onSubmit)}
        className="bl b--moon-gray min-h-100"
        data-test-component="SampleForm"
        data-test-element="form-container"
      >
        <>
          {_notNil(sampleType?.title) && <h3 className="lh-title f5 fw5 ph3 pt3">{sampleType.title}</h3>}
          {nameValidationRequestError ||
            (saveSampleError && (
              <ApiErrorBanner className="ma3" error={nameValidationRequestError || saveSampleError} />
            ))}
          {includeDatetimeField && (
            <div className="pa3">
              <label htmlFor="date">Date collected</label>
              <Controller
                name="date"
                ref={register({ required: 'This field is required' })}
                control={control}
                render={({ value, onChange }: ControllerRenderProps) => (
                  <DateTimePickerNative value={value} onChange={onChange} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="date"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
          )}
          {allowNaming && (
            <div className="pa3">
              <label htmlFor="sample_id">Unique sample name</label>
              <input
                autoFocus
                type="text"
                name="sample_id"
                ref={register({
                  required: 'This field is required',
                  minLength: {
                    value: 3,
                    message: 'Must be a minimum of 3 characters',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Must be a maximum of 255 characters',
                  },
                  validate: {
                    validateNameIsUnique,
                  },
                })}
                style={{ marginBottom: 0 }}
              />
              <ErrorMessage
                errors={errors}
                name="sample_id"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
          )}
          {_isNotEmpty(details) && (
            <div
              className="w-100 pa2 flex justify-between flex-wrap"
              style={{ maxWidth: '32rem' }}
              data-test-element="details-container"
            >
              {details.map((detailProps: any, fieldIndex: any) => (
                <DetailField
                  key={detailProps.key}
                  detailKey={detailProps.key as string}
                  fieldIndex={fieldIndex}
                  value={detailProps.value}
                  unit={detailProps.unit}
                />
              ))}
            </div>
          )}
          <div className="pa3" data-test-element="comment-container">
            <label htmlFor="comments">Comment</label>
            <textarea
              name="comments"
              data-test-element="comment-textarea"
              style={{ marginBottom: 0 }}
              ref={register({
                maxLength: {
                  value: 255,
                  message: 'Must be a maximum of 255 characters',
                },
              })}
            />
          </div>
          {_isNotEmpty(metaFieldValues) && (
            <div className="ph3">
              <FormRender fields={metaFieldValues} />
            </div>
          )}
          {_notNil(sample) && (
            <div className="ph3 mb3">
              <ReasonForChange />
            </div>
          )}
          <div className="pa3 mb3" data-test-element="action-container">
            <Button submit disabled={disableSavePress}>
              {saveButtonText}
            </Button>
          </div>
        </>
      </form>
    </FormProvider>
  );
};

export default SampleForm;
