// @ts-nocheck: converted from JS

import Cages from '@/components/Cages/Show/Cages';
import Loading from '@/components/Loading';
import SubHeader from '@/components/UI/SubHeader';
import { _notNil } from '@/littledash';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoute } from '@/support/route';
import { getInitialCages, studyIdDerivedFromCages } from './Manage.utils';

const Manage = () => {
  const initialCages = getInitialCages();
  const studyId = studyIdDerivedFromCages(initialCages);
  const Render = _notNil(studyId) ? withStudy(Cages, studyId) : Cages;

  return (
    <>
      <SubHeader linkToText="Colony" link={webRoute('colony')} />
      <Render cages={initialCages} />
    </>
  );
};

const withStudy = (Component, studyId) => (props) => {
  const { entity: study, entityLoading: studyLoading } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,study_groups,counts',
  });

  if (studyLoading) {
    return <Loading />;
  }
  return <Component study={study} {...props} />;
};

export default Manage;
