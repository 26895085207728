// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import React from 'react';

const StepLinkList = ({ steps, onClick = () => {}, activeStep, style, className = '' }) => {
  const baseClasses = 'flex flex-row lh-title items-center';
  const activeClasses = 'basier-med near-black';
  const inActiveClasses = 'basier-reg';
  const completeClasses = ' basier-med green';

  return (
    <div
      style={style}
      className={`${className} flex flex-row items-center justify-end`}
      data-test-component="StepLinkList"
      data-test-element="container"
    >
      {steps.map((step, i) => {
        const isActive = i + 1 === activeStep;
        const classes = step.complete ? completeClasses : isActive ? activeClasses : inActiveClasses;

        return (
          <React.Fragment key={i}>
            <a onClick={(e) => onClick && onClick(e)}>
              <div className={`${baseClasses} ${classes}`}>
                {step.complete && (
                  <Icon icon="success" viewBox="0 0 22 22" width="22" height="22" className="mr2 v-top" />
                )}
                <span style={{ whiteSpace: 'nowrap' }}>{step.title}</span>
              </div>
            </a>
            {i + 1 !== steps.length && (
              <div className="ph3">
                <Icon icon="chev_right" viewBox="0 0 20 32" width="12" height="12" />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StepLinkList;
