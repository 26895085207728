import DatePickerNative from '@/components/UI/DatePickerNative';
import { DateUtils } from '@/utils/Date.utils';
import type React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import type { RequiredMetadataFieldWithValue } from '../Fields';

export interface DatePickerFieldProps {
  field: RequiredMetadataFieldWithValue;
  testId?: string;
}

export const DatePicker: React.FC<DatePickerFieldProps> = ({ field, testId = 'metadata-date-picker' }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={`metadata[${field.slug}].value`}
      control={control}
      defaultValue={field?.value ?? DateUtils.dateNow()}
      rules={{ required: field.required && 'This field is required' }}
      render={({ value, onChange, onBlur }: ControllerRenderProps) => (
        <DatePickerNative
          value={value}
          style={{ marginBottom: 0 }}
          onChange={onChange}
          onBlur={onBlur}
          testId={testId}
        />
      )}
    />
  );
};
