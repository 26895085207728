// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import { NoDataMessage } from '@/components/NoDataCard';
import GroupLabel from '@/components/UI/GroupLabel';
import Header from '@/components/UI/Header';
import Banner from '@/components/UI/Banner';
import Spinner from '@/components/UI/Spinner';
import SubHeader from '@/components/UI/SubHeader';
import { preventNumberScroll, successToast } from '@/helpers';
import { _isEmpty, uuid } from '@/littledash';
import { api as apiRoute, web as webRoute } from '@/support/route';
import Http from '@/support/http';
import { ErrorMessage } from '@hookform/error-message';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form@latest';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RIEInput } from 'riek';
import {
  CAGE_NAMING_METHODS,
  CAGE_NUMBER,
  chunkGroups,
  determineNextCageGroupName,
  generateRepeatingIds,
  generateSequentialIds,
  getHighestCageGroupName,
  GROUP_NUMBER,
  groupAndSortAnimals,
  ID_METHODS,
  REPEATING,
  SEQUENTIAL,
} from './Recaging.utils';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { modalAction } from '@/utils/modal.tsx';
import { RouteService } from '@/support/RouteService';
import { StudyMetrics } from '@/model/Study.model.ts';
import { State } from '@/model/State.model';
import Button from '@/components/UI/Button';
import TagInput from '@/components/FormBuilder/TagBuilder/TagBuilderInput.tsx';
import { UnassignedGroupLabel } from '@/components/UI/GroupLabel/GroupLabel.tsx';

const TH_CLASSES = 'f7 f6-l ph2 pv2 ph3-l pv3-l b--silver near-black basier-med normal';
const TD_CLASSES = 'f7 f6-l ph2 pv1 ph3-l pv2-l bt';

const ProposedCage = ({ cage, treatmentGroup, animals, index: cageIndex, onChangeAnimalId, onChangeCageName }) => {
  return (
    <div className="ui-card mv4">
      <div className="flex flex-row pa3 justify-between items-center bb b--moon-gray">
        <div
          className="basier-med near-black normal f5 lh-solid dib v-mid"
          data-tooltip-id="global-tooltip-id"
          data-tooltip-content="Click to rename"
        >
          <RIEInput
            value={cage}
            change={(value) => {
              onChangeCageName(value.text.trim().length > 0 ? value.text : cage, cageIndex);
            }}
            propName="text"
            classInvalid="ui__error"
            className="f4 ui__editable"
          >
            EDIT
          </RIEInput>
        </div>
        <div>
          {treatmentGroup.id === -1 ? (
            <UnassignedGroupLabel group={treatmentGroup} />
          ) : (
            <GroupLabel group={treatmentGroup} groupContextClassName={'pl1'} />
          )}
        </div>
      </div>

      <table className="w-100 tl bt b--moon-gray" style={{ borderSpacing: 0 }}>
        <thead className="bg-near-white">
          <tr>
            <th className={TH_CLASSES}>Old Animal ID</th>
            <th className={TH_CLASSES}>Old Cage ID</th>
            <th className={TH_CLASSES}>New Animal ID</th>
          </tr>
        </thead>
        <tbody>
          {animals.map(({ id, name, cage: { name: cageName }, newAnimalId }, animalIndex) => (
            <tr key={id}>
              <td className={TD_CLASSES}>{name}</td>
              <td className={TD_CLASSES}>{cageName}</td>
              <td className={TD_CLASSES}>
                <input
                  value={newAnimalId}
                  onChange={(event) => {
                    onChangeAnimalId(event.target.value, cageIndex, animalIndex);
                  }}
                  propName="text"
                  classInvalid="ui__error"
                  className="f4 ui__editable"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Recaging = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [studyCagesLoading, setStudyCagesLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [proposedCages, setProposedCages] = useState([]);
  const [idGenerationMethod, setIdGenerationMethod] = useState(REPEATING);
  const [cageNamingMethod, setCageNamingMethod] = useState(CAGE_NUMBER);
  const [nextCage, setNextCage] = useState<number>();
  const history = useHistory();
  const [deleteCages, setDeleteCages] = useState(false);
  const [studyCages, setStudyCages] = useState(false);
  const [existingGroupCageNames, setExistingGroupCageNames] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const handleTagChange = useCallback((newTags: string[]) => {
    setTags(newTags);
  }, []);

  const formMethods = useForm({
    defaultValues: { per_cage: 5, sequencePrefix: '', sequenceStarting: '' },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = formMethods;

  const PrefixAndSequence = () => {
    return (
      <div className="flex">
        <input
          type="text"
          {...register('sequencePrefix')}
          placeholder="ABC"
          className="w-25 mr3"
          style={{ minWidth: '13.5rem' }}
        />
        <input
          type="number"
          onWheel={preventNumberScroll}
          {...register('sequenceStarting', {
            min: { value: 0, message: 'Number must be greater than or equal to 0' },
          })}
          placeholder="123"
          className="w-25 mr3"
          style={{ minWidth: '13.5rem' }}
        />
        <ErrorMessage
          errors={errors}
          name="sequenceStarting"
          render={({ message }) => <small className="mr3 red db pt1">{message}</small>}
        />
      </div>
    );
  };

  const selectedSubjects = useSelector((state: State) => state.subjects.selectedSubjects);

  useEffect(() => {
    const { url } = RouteService.legacyApi({
      apiRoute: 'studies.metrics',
      path: { id: studyId },
      query: { include: 'counts' },
    });

    Http.get<{ data: StudyMetrics }>(url.href)
      .then(({ data }) => {
        setNextCage(data.data.counts.cages_with_trashed + 1);
      })
      .catch((error) => {
        setApiError(error);
      })
      .finally(() => {
        setLoading(false);
      });

    const studyCagesUrl = RouteService.legacyApi({
      apiRoute: 'studies.collections',
      query: { sort: 'id', order: 'asc', filterType: 'and', 'filters[]=': `study|eq|${studyId}`, perPage: '-1' },
    });

    Http.get<{ data }>(studyCagesUrl.url.href)
      .then(({ data }) => {
        setStudyCages(data.data);
      })
      .catch((error) => {
        setApiError(error);
      })
      .finally(() => {
        setStudyCagesLoading(false);
      });
  }, [studyId]);

  const onRecageSubmit = (deleteCages: boolean): void => {
    if (deleteCages) {
      openModal('CONFIRM_DELETE_EMPTY_COLLECTIONS', {
        onClick: submitRecaging,
        closeModal,
      });
    } else {
      submitRecaging();
    }
  };

  const onChangeCageName = (newCageName: string, cageIndex: number) => {
    const update = [...proposedCages];
    update[cageIndex].cage = newCageName;
    setProposedCages(update);
  };

  const onChangeAnimalId = (newId: string, cageIndex: number, animalIndex: number) => {
    const update = [...proposedCages];
    update[cageIndex].animals[animalIndex].newAnimalId = newId;
    setProposedCages(update);
  };

  const submitRecaging = () => {
    setLoading(true);
    setExistingGroupCageNames(false);
    const data = {
      study_id: studyId,
      cages: proposedCages.map((cage) => ({
        name: cage.cage,
        animals: cage.animals.map(({ id, newAnimalId, name }) => ({
          id,
          name: newAnimalId || name,
        })),
      })),
      delete_empty_cages: deleteCages,
    };
    Http.post(apiRoute('recaging'), data)
      .then(() => {
        successToast('Successfully recaged');
        history.push(webRoute('studies.animals', { id: studyId }));
      })
      .catch((error) => {
        setApiError(error);
        setLoading(false);
      });
  };

  const recage = () => {
    const { per_cage } = getValues();
    const cages = [];
    const groupedAndSortedCages = groupAndSortAnimals(selectedSubjects);
    setExistingGroupCageNames(false);

    if (cageNamingMethod === CAGE_NUMBER) {
      let cageNumber = nextCage;
      groupedAndSortedCages.forEach(({ group, animals }) => {
        chunkGroups(animals, per_cage).forEach((chunk) => {
          cages.push({
            id: uuid(),
            cage: `Cage ${cageNumber}`,
            treatmentGroup: group,
            animals: chunk,
          });
          cageNumber++;
        });
      });
    }

    if (cageNamingMethod === GROUP_NUMBER) {
      groupedAndSortedCages.forEach(({ group, animals }) => {
        let highestGroupCageName = getHighestCageGroupName(
          group.no,
          studyCages.map((cage) => cage.name)
        );
        if (highestGroupCageName && !existingGroupCageNames) {
          setExistingGroupCageNames(true);
        }

        chunkGroups(animals, per_cage).forEach((chunk) => {
          highestGroupCageName = determineNextCageGroupName(highestGroupCageName);
          cages.push({
            id: uuid(),
            cage: group.no != null ? `${group.no}${highestGroupCageName}` : `Unassigned ${highestGroupCageName}`,
            treatmentGroup: group,
            animals: chunk,
          });
        });
      });
    }

    setProposedCages(cages);
  };

  const changeIdGenerationMethod = (event) => {
    setIdGenerationMethod(event.target.value);
  };
  const changeCageNamingMethod = (event) => {
    setCageNamingMethod(event.target.value);
  };

  const generateIds = () => {
    const { sequencePrefix, sequenceStarting } = getValues();
    let update = [];

    if (idGenerationMethod === REPEATING) {
      update = generateRepeatingIds(proposedCages, tags);
    }
    if (idGenerationMethod === SEQUENTIAL) {
      update = generateSequentialIds(proposedCages, sequencePrefix, sequenceStarting);
    }
    setProposedCages(update);
  };

  return (
    <div className="h-100">
      <SubHeader linkToText="Back to study" link={webRoute('studies.show', { id: studyId })} />

      <div className="ph4 pb3">
        <Header mainHeaderText="Recaging" />
        <p className="lh-copy">
          Enter the number of animals to be placed in each new cage and visualize your results below.
          <a
            href="https://help.benchling.com/hc/en-us/articles/22269908325005"
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml2 link blue"
          >
            Read more.
          </a>
        </p>
        {apiError && <ApiErrorBanner errorType={ApiErrorType.FETCH} error={apiError} />}
        <div className="flex flex-row mt4 mb4">
          <div className="ui-card w-40 mr4 pa3">
            <form onSubmit={handleSubmit(recage)}>
              <h3 className="lh-solid f4 pb3">Generate cages</h3>
              <div>
                <div className="flex flex-row">
                  <div className="mr3">
                    <label htmlFor="per_cage">Name cages using</label>
                    <select
                      name="cage_name_generation"
                      disabled={loading && studyCagesLoading}
                      onChange={changeCageNamingMethod}
                      value={cageNamingMethod}
                      data-testid="cage_name_generation"
                    >
                      {CAGE_NAMING_METHODS.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mr3">
                    <label htmlFor="per_cage">Animals per cage</label>
                    <div className="flex flex-row">
                      <input
                        type="text"
                        style={{ marginBottom: 0, marginRight: '10px', width: '50px' }}
                        className={errors.per_cage ? 'input__error mr1' : ''}
                        {...register('per_cage', {
                          required: true,
                          validate: (value) => {
                            if (isNaN(value)) {
                              return false;
                            }
                            if (value <= 0) {
                              return 'Minimum animals per cage is 1';
                            }
                            if (value > 10) {
                              return 'Maximum animals per cage is 10';
                            }
                          },
                        })}
                      />
                      <button
                        className="w-100 mb3 w-auto-ns mb0-ns plain"
                        type="submit"
                        data-testid="generate-cages"
                        disabled={!_isEmpty(errors) || _isEmpty(selectedSubjects) || loading || studyCagesLoading}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="ui-card w-60 pl3">
            <div className="mv3">
              <h3 className="lh-solid f4 pb3">New Animal IDs</h3>
              <div className="flex flex-row justify-between">
                <div>
                  <label htmlFor="per_cage">Auto generate IDs</label>

                  <div className="flex flex-row items-center">
                    <div className="mr3">
                      <select
                        name="id_generation"
                        disabled={loading || studyCagesLoading || _isEmpty(proposedCages)}
                        onChange={changeIdGenerationMethod}
                        value={idGenerationMethod}
                        data-testid="id_generation"
                      >
                        {ID_METHODS.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex justify-center">
                      {idGenerationMethod === REPEATING && (
                        <div className="mr3">
                          <TagInput
                            propTags={tags}
                            onTagChange={handleTagChange}
                            maxTags={10}
                            allowDuplicates={false}
                          />
                        </div>
                      )}
                      {idGenerationMethod === SEQUENTIAL && <PrefixAndSequence />}
                      <Button
                        className="w-100 mb3 w-auto-ns mb0-ns plain"
                        onClick={generateIds}
                        disabled={
                          loading ||
                          studyCagesLoading ||
                          _isEmpty(proposedCages) ||
                          _isEmpty(selectedSubjects) ||
                          (idGenerationMethod === REPEATING && _isEmpty(tags))
                        }
                      >
                        Bulk assign
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="per_cage"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
          </div>
        </div>
        {existingGroupCageNames && (
          <Banner info dismiss={false} className="mt3 w-40" testId="randomize-date-banner">
            <h3 className="f6 lh-copy fw5 pb3">
              Cages for these groups already exist. New cages will be assigned the next available alphanumeric label.
            </h3>
          </Banner>
        )}

        {(loading || studyCagesLoading) && (
          <div className="pa6 tc bg-white bb b--moon-gray">
            <div className="pa3 dib tc">
              <Spinner size="large" color="blue" className="dib" />
              <h3 className="lh-title f4 basier-reg normal pv3">Loading...</h3>
            </div>
          </div>
        )}

        {!loading && !studyCagesLoading && (
          <Fragment>
            {_isEmpty(proposedCages) ? (
              <div className="ui-card">
                <NoDataMessage
                  title="Recaging results will appear here"
                  text="Enter in the required number of animals per cage above and click generate to continue."
                />
              </div>
            ) : (
              proposedCages.map(({ id, cage, treatmentGroup, animals }, index) => {
                return (
                  <ProposedCage
                    key={id}
                    index={index}
                    cage={cage}
                    treatmentGroup={treatmentGroup}
                    animals={animals}
                    onChangeAnimalId={onChangeAnimalId}
                    onChangeCageName={onChangeCageName}
                  />
                );
              })
            )}
          </Fragment>
        )}

        <div className="flex items-center w-100 mv3 w-auto-ns mb0-ns">
          <button
            disabled={loading || studyCagesLoading || _isEmpty(proposedCages)}
            onClick={() => onRecageSubmit(deleteCages)}
          >
            Submit
          </button>

          <Checkbox
            className={'dib pl3'}
            name="delete-cages"
            label="Delete empty cages in study"
            checked={deleteCages}
            onChange={({ target: { checked } }) => setDeleteCages(checked)}
          />
        </div>
      </div>
      <div className="ph3 ph4-l mb4"></div>
    </div>
  );
};

export default Recaging;
