import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import { generateFilterOptions, validateManageSamplesSelection } from '@/components/Samples/Samples.utils';
import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import { updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { errorToast, successToast } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import type { Sample } from '@/model/Sample.model';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import * as Auth from '@/support/auth';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { buildColumns } from './Samples.utils';

const SamplesTable: React.FC = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const [columns, setColumns] = useState<Array<Column<Sample>>>([]);
  const [study, setStudy] = useState<Study>();
  const [studyLoading, setStudyLoading] = useState<boolean>();
  // Required as this component is utilised both in Study context and without
  const isStudy = _isNotEmpty(studyId);
  const history = useHistory();
  const dispatch = useDispatch();

  const { users, settings } = useSelector((state: State) => ({
    users: state.team?.team?.users ?? [],
    settings: state.ui?.settings,
  }));

  const {
    collection: groupMetadata,
    collectionLoading: groupMetadataLoading,
    collectionError: groupMetadataError,
  } = useFetchCollection<MetadataFieldWithValue>({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: 'group_meta',
    },
  });

  const {
    collection: sampleMetadata,
    collectionLoading: sampleMetadataLoading,
    collectionError: sampleMetadataError,
  } = useFetchCollection<MetadataFieldWithValue>({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: 'sample_meta',
    },
  });

  // This was originally a hook, however this needs to be called conditionally so had to be reworked to a function
  // When this is ported to the Java, it can utilise the ApiService instead
  useEffect(() => {
    const abort = new AbortController();
    if (_notNil(studyId)) {
      fetchStudy(abort.signal);
    }
    return () => {
      abort.abort();
    };
  }, [studyId]);

  const fetchStudy = async (signal: AbortSignal) => {
    setStudyLoading(true);
    const url = apiRoute('studies.show.p', { id: studyId }, { include: 'users,metadata' });
    const response: any = await Http.get<{ data: Study }>(url, { signal });
    setStudy(response?.data?.data ?? response?.data ?? response);
    setStudyLoading(false);
  };

  const error = groupMetadataError || sampleMetadataError;

  useEffect(() => {
    const columnSettings = settings?.tables?.[isStudy ? 'studySamples' : 'samples']?.columns || {};
    if (!groupMetadataLoading && !sampleMetadataLoading) {
      const updatedColumns = buildColumns(groupMetadata, sampleMetadata, columnSettings, isStudy);
      setColumns(updatedColumns);
    }
  }, [groupMetadataLoading, sampleMetadataLoading]);

  const updatedSettings = useMemo(() => {
    if (_isNotEmpty(columns)) {
      return updateSettings('samples', columns, settings);
    }
    return settings;
  }, [columns]);

  const filterOptions = generateFilterOptions(users, studyId);

  const hasAccess = !studyLoading && _notNil(study) && Auth.isWriteUserForStudy(study);

  const handleDelete = async (samples: Array<Sample>, resetTableState: () => void, reasonForChange?: string) => {
    try {
      if (_notNil(study)) {
        const listOfSampleApiIds = samples.map((sample) => sample.api_id);
        await ApiService.call({
          endpoint: 'DELETE /api/v1/studies/{studyId}/samples/bulk',
          path: { studyId: study.api_id },
          body: {
            sample_api_ids: listOfSampleApiIds,
            ...(_notNil(reasonForChange) ? { reason_for_change: reasonForChange } : {}),
          },
        });
        resetTableState();
        successToast('Successfully deleted!');
      }
    } catch (error) {
      errorToast('There was a problem deleting samples, please try again later.');
    }
  };

  const bulkActions = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { resetTableState },
    },
  }: UseTableProps<Sample>) => {
    const selectedSamples = selectedFlatRows.map((sample) => sample.original);
    const { openModal, closeModal } = modalAction(dispatch);

    // For now, bulk actions are only available in the context of a Study
    return isStudy && hasAccess
      ? [
          {
            name: 'Edit Sample details',
            key: 'edit_details',
            ...validateManageSamplesSelection(hasAccess, selectedSamples),
            action: () => {
              // Sets the selected samples to session, in order to prevent losing context on refresh
              dispatch({ type: 'SET_SELECTED_SAMPLES', selectedSamples });
              sessionStorage.setItem(`selectedStudy${studyId}Samples`, JSON.stringify(selectedSamples));
              sessionStorage.setItem(`selectedStudyApiId${studyId}`, _notNil(study) ? study.api_id : '');
              history.push(webRoute('studies.samples.edit', { id: studyId }));
            },
          },
          {
            name: 'Delete',
            action: () =>
              openModal('CONFIRM_DELETE_SAMPLES', {
                samples: selectedSamples,
                closeModal,
                onClick: (reasonForDelete?: string) => handleDelete(selectedSamples, resetTableState, reasonForDelete),
              }),
            className: 'red',
          },
        ]
      : [];
  };

  return (
    <div className="h-100">
      {!isStudy && <SubHeader linkToText="Dashboard" link={webRoute('dashboard')} />}
      <div className="ph4 pb3">
        <Header mainHeaderText="Samples" />
      </div>
      {error && (
        <ApiErrorBanner
          className="ma3"
          title="There was a problem fetching metadata"
          errorType={ApiErrorType.FETCH}
          error={error}
        />
      )}
      <div className="ph3 ph4-l mb4">
        <APITable
          columns={columns}
          apiInfo={{
            type: 'legacyInternalApi',
            route: isStudy ? apiRoute('studies.samples.index', { studyId }) : apiRoute('samples.index'),
          }}
          bulkActions={bulkActions}
          includeParam="study_group[metadata],collection,user,study_link"
          filterOptions={filterOptions}
          searchPlaceholderText={'Search by Sample ID'}
          exportRoutes={
            isStudy
              ? [
                  {
                    url: 'samples.export.study',
                    params: {
                      studyId,
                    },
                  },
                ]
              : [
                  {
                    url: 'samples.export',
                  },
                ]
          }
          settings={updatedSettings}
          reduxTableName="samples"
          defaultSortBy={{ id: 'date', desc: true }}
          pageSizes={[50, 100, 200, 500]}
        />
      </div>
    </div>
  );
};

export default SamplesTable;
