// @ts-nocheck: converted from JS

export const setGlobalData = (data, dispatch) => {
  if (data.currentUser) {
    dispatch({
      type: 'USER_SET_CURRENT_USER',
      currentUser: data.currentUser,
    });
  }

  if (data.team) {
    dispatch({ type: 'TEAM_SET_TEAM', team: data.team });
  }

  if (data.features) {
    dispatch({
      type: 'TEAM_SET_FEATURES',
      features: data.features,
    });
  }

  if (data.timezones) {
    dispatch({
      type: 'USER_SET_TIMEZONES',
      timezones: data.timezones?.filter((timezone) => timezone !== 'UTC'),
    });
  }

  dispatch({
    type: 'PENDING_TASKS_FOR_TODAY',
    pendingTasksForToday: data.pendingTasksForToday,
  });
};
