// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import TableShowMenu from '@/components/UI/SelectDropDown/Menus/TableShowMenu';
import Table from '@/components/UI/Table';
import { filterDataBySearch } from '@/components/UI/Table/Table.utils';
import './Files.scss';
import { errorToast } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { useDebounce } from '@/support/Hooks';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import 'react-table/react-table.css';
import { initialColumns } from './Files.utils';

const Files = ({ readOnly = false, context, loadFiles, deleteFile, uploadFile, downloadFile }) => {
  const [files, setFiles] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [metadata, setMetadata] = useState();
  const [loading, setLoading] = useState('Loading files...');
  const [, setError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const settings = useSelector((state) => state.ui.settings);

  useEffect(() => {
    if (_notNil(metadata)) {
      const newCols = initialColumns(downloadFile, context, setLoading, metadata, settings.tables.files?.columns);
      setColumns(newCols);
    }
  }, [metadata, context]);

  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const dispatch = useDispatch();

  const handleSetFiles = (files) => {
    if (context?.dispatch) {
      context.dispatch({
        type: 'updateStudy',
        data: { files },
      });
    }
    setFiles(files);
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const addEditFileHandleSave = (name, attachmentMetadata, file, isEdit = false) => {
    uploadFile({
      context,
      file,
      setFiles: handleSetFiles,
      setInitialFiles,
      setLoading,
      setError,
      formDispatch: dispatch,
      name,
      attachmentMetadata,
      metadata,
      isEdit,
    });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!_isEmpty(rejectedFiles)) {
        errorToast('Please upload one file at a time');
        return;
      }

      dispatch({
        type: 'OPEN_MODAL',
        modal: 'ADD_EDIT_FILE',
        props: {
          handleSave: ({ filename, metadata }) => addEditFileHandleSave(filename, metadata, acceptedFiles[0]),
          closeModal,
          metadata,
          filename: acceptedFiles[0].name,
          files,
        },
      });
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  const getAttachmentMetadata = async () => {
    const {
      data: { data },
    } = await Http.get(apiRoute('meta-glossary.show'), {
      params: {
        filter_type: 'attachment_meta',
      },
    });
    setMetadata(data);
  };

  useEffect(() => {
    try {
      getAttachmentMetadata()
        .then(() => {
          loadFiles({
            context,
            setFiles: handleSetFiles,
            setInitialFiles,
            setError,
            setLoading,
          });
        })
        .catch((err) => setApiError(err));
    } catch (e) {
      setApiError(e);
    }
  }, []);

  const onDeleteFile = useCallback(
    (name) => {
      deleteFile({
        context,
        name,
        setFiles: handleSetFiles,
        setInitialFiles,
        setLoading,
        setError,
      });
    },
    [context]
  );

  const onDownloadFile = useCallback(
    (name) => {
      downloadFile({ context, name, setLoading });
    },
    [context]
  );

  const onEditFile = () => {
    const file = getSelectedFile();
    if (file) {
      const metadataFieldSlugs = metadata.map((m) => m.slug);
      if (metadataFieldSlugs.length) {
        metadataFieldSlugs.forEach((slug) => {
          const objectVal = file.metadata[slug];
          if (objectVal) {
            const metaObj = metadata.find((m) => m.slug === slug);
            metaObj.value = objectVal;
          }
        });
      }
      dispatch({
        type: 'OPEN_MODAL',
        modal: 'ADD_EDIT_FILE',
        props: {
          handleSave: ({ filename, metadata }) => addEditFileHandleSave(filename, metadata, file, true),
          closeModal,
          metadata,
          filename: file.name,
          isEdit: true,
          files,
        },
      });
    }
  };

  useEffect(() => {
    let data = [...initialFiles];

    if (debouncedSearchQuery) {
      data = filterDataBySearch(initialFiles, debouncedSearchQuery, 'name');
    }

    setFiles(data);
  }, [debouncedSearchQuery]);

  const getSelectedFile = () => {
    const getFile = (index) => files[index];
    return Object.keys(selectedRows).map(getFile)?.[0];
  };

  const buildActionMenu = ({ readOnly, downloadFile, deleteFile, editFile }) => {
    const file = getSelectedFile();
    const actions = [];
    if (downloadFile) {
      actions.push({
        name: 'Download',
        key: 'download',
        action: () => {
          downloadFile(file.name);
        },
      });
    }

    if (!readOnly) {
      actions.push([
        {
          name: 'Delete',
          key: 'delete',
          action: () =>
            openModal('CONFIRM_DELETE_FILE', {
              onClick: () => {
                deleteFile(file.name);
                return setSelectedRows({});
              },
              closeModal,
            }),
          className: 'red',
        },
        {
          name: 'Edit File',
          key: 'edit_file',
          action: editFile,
        },
      ]);
    }

    return actions;
  };

  return (
    <div className="relative">
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title="There was an error fetching metadata"
          text="An error has occurred when fetching metadata, please try again later. If this keeps occurring please contact support."
          error={apiError}
        />
      )}
      <Fragment>
        <div className="pa3 bb b-moon-gray">
          <div className="flex">
            <div className="flex mr2">
              {_isNotEmpty(selectedRows) && (
                <div
                  className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                  style={{
                    whiteSpace: 'nowrap',
                    lineHeight: '2.4rem',
                  }}
                >
                  {Object.keys(selectedRows).length} selected
                </div>
              )}
              <SelectDropDown
                title="Actions"
                className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
                alignMenu="right"
                disabled={_isEmpty(selectedRows) || Object.keys(selectedRows).length > 1}
                tooltip={Object.keys(selectedRows).length > 1 ? 'Select one file at a time' : undefined}
              >
                <ActionList
                  actions={buildActionMenu({
                    readOnly,
                    downloadFile: onDownloadFile,
                    deleteFile: onDeleteFile,
                    editFile: onEditFile,
                  })}
                />
              </SelectDropDown>
            </div>
            <div className="flex flex-grow-1">
              <div className="w-47 flex-grow-1">
                <SearchFilterBar
                  searchPlaceholderText="Search by filename"
                  handleSearchInput={setSearchQuery}
                  searchQuery={searchQuery}
                  ShowMenu={() => (
                    <TableShowMenu
                      columns={columns}
                      handleChange={(data) => {
                        const newColumns = data.reduce((acc, column) => {
                          return { ...acc, [column.id]: column.isVisible };
                        }, {});
                        const newSettings = {
                          ...settings,
                          tables: {
                            ...settings.tables,
                            files: {
                              ...settings.tables.files,
                              columns: newColumns,
                            },
                          },
                        };
                        dispatch({
                          type: 'SAVE_SETTING',
                          setting: newSettings,
                        });

                        setColumns(data);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {!readOnly && <Button onClick={open}>Upload File</Button>}
          </div>
        </div>

        <div className="relative min-h5" {...(readOnly ? {} : getRootProps())}>
          {loading || _isNil(metadata) ? (
            <div className="absolute flex flex-column w-100 h-100 top-0 ba justify-center items-center bg-white">
              <Loading txt={loading} />
            </div>
          ) : (
            <>
              <Table
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                data={files}
                columns={columns}
                sortBy={[{ id: 'number', desc: false }]}
                noDataComponent={
                  <div
                    className={`flex flex-column min-h5 white justify-center items-center tc pa3 ma3 ${
                      isDragActive ? 'bg-moon-gray' : 'bg-light-gray'
                    }`}
                  >
                    <h2 className="pb3">
                      {searchQuery ? 'No files found matching your search' : 'No files have been uploaded'}
                    </h2>
                    {!readOnly && <h2 className="mid-gray">Drag and drop files here to upload them</h2>}
                  </div>
                }
              />
              {!readOnly && <input {...getInputProps()} />}
              {isDragActive && !_isEmpty(files) && (
                <div className="absolute flex flex-column min-h5 w-100 h-100 top-0 ba justify-center items-center bg-white">
                  <h2>Drag and drop files here to upload them</h2>
                </div>
              )}
            </>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default Files;
