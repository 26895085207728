import Button from '@/components/UI/Button';
import type { State } from '@/model/State.model';
import { MainUser } from '@/model/User.model';
import { api as apiRoute } from '@/support/route';
import { ErrorMessage } from '@hookform/error-message';
import { type FC, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';
import RemoveButton from '@/components/UI/RemoveButton';
import { validateValueNotPresentInList } from './InviteUsers.utils';
import { useRequest } from '@/support/Hooks/request';
import { errorToast } from '@/helpers';

interface InviteUsersProps {
  closeModal: () => void;
  handleCallback: (txt: string) => Promise<void>;
}

export interface FormValues {
  users: Array<{ email: string }>;
}

interface Payload {
  emails: string[];
}
interface InviteUsersFormProps {
  isDisabled: boolean;
  reservedEmails: string[];
  onSubmit: (values: FormValues) => Promise<void>;
  onClose: () => void;
}

const InviteUsers: FC<InviteUsersProps> = ({ closeModal, handleCallback }) => {
  const { sendRequest: inviteUsers, requestSending } = useRequest<Payload, Record<string, boolean>>({
    route: apiRoute('users.create'),
    method: 'post',
  });
  const formMethods = useForm({
    defaultValues: {
      users: [{ email: '' }],
    },
  });
  const users = useSelector((state: State) => state?.team?.team?.users);
  const reservedEmails: string[] = useMemo(() => {
    const emails: string[] = [];
    (users ?? []).forEach((user: MainUser) => {
      if (user?.email) {
        emails.push(user.email);
      }
    });
    return emails;
  }, [users]);

  const onSubmit = async (formValues: FormValues): Promise<void> => {
    try {
      await inviteUsers({
        emails: (formValues?.users ?? []).map((user) => user?.email),
      });
      handleCallback(`Successfully invited ${formValues.users.length} user${formValues.users.length > 1 ? 's' : ''}`);
    } catch (error) {
      errorToast('There was a problem communicating with the server');
    }
  };

  return (
    <div className="ui-card mw6 center" data-test-component="InviteUsers" data-test-element="container">
      <div className="pa3 bg-white">
        <h2 className="normal lh-title f4 mb2">Invite users</h2>
        <p className="lh-copy f6">
          Users will receive an email with instructions to set up their account. You can see the progress of this in the
          Users table under the Status column.
        </p>
      </div>
      <FormProvider {...formMethods}>
        <InviteUsersForm
          onSubmit={onSubmit}
          onClose={closeModal}
          isDisabled={requestSending}
          reservedEmails={reservedEmails}
        />
      </FormProvider>
    </div>
  );
};

export const InviteUsersForm: FC<InviteUsersFormProps> = ({ onSubmit, onClose, reservedEmails, isDisabled }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="invite-users-form">
      <div className="pa3">
        {fields.map((field, index) => (
          <div key={field.id} className="mb2">
            <div className="flex items-center justify-between">
              <input
                {...register(`users.${index}.email` as const, {
                  required: "Email can't be blank",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter a valid email',
                  },
                  validate: {
                    notPresentInReservedEmails: (value: string) =>
                      validateValueNotPresentInList(value, reservedEmails, 'Email already exists in team'),
                    notPresentInFieldList: (value: string) => {
                      const emailComparatorList = (watch('users') ?? []).map((field) => field.email.toLowerCase());
                      emailComparatorList.splice(index, 1);
                      return validateValueNotPresentInList(value, emailComparatorList, 'Email must be unique');
                    },
                  },
                })}
                autoFocus={index + 1 === fields.length}
                type="email"
                defaultValue={field.email}
                placeholder="john@appleseed.com"
                data-test-component="InviteUsers"
                data-test-element="email-input"
                data-test-key={`${index}`}
                data-testid="email-input"
                style={{ marginBottom: 0, width: 430 }}
                className={`${errors?.users?.[index]?.email ? 'input__error' : ''}`}
              />
              {fields.length > 1 && <RemoveButton onClick={() => remove(index)} testId="remove-user-btn" />}
            </div>
            <ErrorMessage
              errors={errors}
              name={`users[${index}].email`}
              render={({ message }) => (
                <p className="f6 red db lh-copy pt1" data-testid="email-input-error-message">
                  {message}
                </p>
              )}
            />
          </div>
        ))}
        <Button
          paleBlue
          icon="add_new"
          onClick={() => {
            append({ email: '' });
          }}
          testId="add-new-user-btn"
        >
          Add user
        </Button>
      </div>
      <div className="pa3 mt3 bt b--moon-gray bg-white">
        <Button submit className="mr2" disabled={isDisabled} testId="button__send_invites">
          Save
        </Button>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default InviteUsers;
