// @ts-nocheck: converted from JS

import { Plotly } from '@/support/plotly';
import { useEffect } from 'react';

const Graph = ({ measurements, activeMeasurement, unit }) => {
  const graphSettings = {
    config: {
      responsive: true,
      displayModeBar: false,
    },
    layout: {
      font: {
        family: 'Basier Circle Reg, Arial',
        size: 12,
        color: '#6b6b76',
      },
      hoverlabel: {
        font: {
          family: 'Basier Circle Reg, Arial',
          size: 12,
        },
      },
      margin: {
        l: 24,
        r: 24,
        b: 24,
        t: 0,
      },
      padding: {
        l: 24,
      },
      yaxis: {
        automargin: true,
        ticks: 'inside',
        type: 'linear',
        rangemode: 'tozero',
        showline: true,
        zeroLine: true,
        nticks: 4,
        ticklen: 4,
        linecolor: '#DFE3E8',
        linewidth: 3,
        gridcolor: '#DFE3E8',
        gridwidth: 1,
        tickcolor: '#DFE3E8',
        showexponent: 'all',
        exponentformat: 'e',
        title: {
          text: unit,
          standoff: 12,
          font: {
            family: 'Basier Circle Reg, Arial',
            size: 12,
          },
        },
      },
      xaxis: {
        type: 'date',
        tickformat: '%d %b',
        automargin: true,
        showgrid: false,
        showline: true,
        linecolor: '#DFE3E8',
        linewidth: 3,
        gridcolor: '#DFE3E8',
        gridwidth: 1,
        tickcolor: '#DFE3E8',
      },
    },
    data: [
      {
        line: {
          color: '#0647A6',
          width: 3,
          shape: 'linear',
          simplify: false,
        },
        mode: 'lines+markers',
        type: 'scatter',
      },
    ],
  };

  const getDataPoints = (measurements, activeMeasurement) => {
    return measurements.reduce(
      (acc, m) => {
        if (Object.keys(m.variables ?? {}).includes(activeMeasurement)) {
          acc.y.push(Number(m.variables[activeMeasurement]));
          acc.x.push(m.measured_at);
        }

        return acc;
      },
      { x: [], y: [] }
    );
  };

  useEffect(() => {
    const dataPoints = getDataPoints(measurements, activeMeasurement);
    const settings = { ...graphSettings };
    settings.data[0].x = dataPoints.x;
    settings.data[0].y = dataPoints.y;

    Plotly.newPlot('measurementLineGraph', {
      data: settings.data,
      config: settings.config,
      layout: settings.layout,
    });
  }, [measurements, activeMeasurement]);

  return (
    <div className="ui__graph">
      <div id="measurementLineGraph" className="w-100 h-100" style={{ height: '200px' }} />
    </div>
  );
};

export default Graph;
