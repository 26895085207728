// @ts-nocheck: converted from JS

import ApprovalRequestDetails from '@/components/Approvals/ApprovalRequestDetails';
import { ApprovalSection } from '@/components/Studies/Show';
import FormRender from '@/components/UI/FormRender';
import { _notNil } from '@/littledash';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

const RequestFormSummary = ({ request }) => {
  const formMethods = useForm({
    defaultValues: request.form_data,
  });

  const { study } = request;

  return (
    <>
      {_notNil(study) && <ApprovalRequestDetails study={study} />}
      <FormProvider {...formMethods}>
        <FormRender groups={request.request_template.form.groups} groupContainer={ApprovalSection} submitted />
      </FormProvider>
    </>
  );
};

export default RequestFormSummary;
