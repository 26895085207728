// @ts-nocheck: converted from JS

import { ApprovalsTable } from '@/components/Glossary/Sections/Approvals';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import Header from '@/components/UI/Header';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { sortDescByProperty } from '@/utils/array';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Show = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const history = useHistory();

  const handleCreateNewClick = () => {
    history.push(webRoute('team.studies.approvals.create'));
  };

  const fetchApprovals = async () => {
    setIsLoading(true);
    try {
      const { data } = await Http.get(
        apiRoute('approvals.templates.list', {
          query: '?include=operations',
        })
      );
      if (data?.data) {
        setApprovals(data.data.sort(sortDescByProperty('id')));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApprovals();
  }, []);

  if (isLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }
  return (
    <div className="w-100 h-100">
      <Header mainHeaderText="Reviews" />
      {approvals.length > 0 ? (
        <ApprovalsTable approvals={approvals} refetch={fetchApprovals} onCreateNewClick={handleCreateNewClick} />
      ) : (
        <div className="mt3">
          <NoDataCard
            title="Create a new review"
            text="Reviews let you request information before a study can be started and trigger based on custom conditional requirements."
            link={webRoute('team.studies.approvals.create')}
            btnTxt="Create a new review"
          />
        </div>
      )}
    </div>
  );
};

export default Show;
