import Banner from '@/components/UI/Banner';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { MetadataItem } from '@/model/Metadata.model';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { containsNoSpaces, containsValidCharacters, withinCharacterLimits } from '@/validators';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { getMetadataFields } from '../AddEditLink/AddEditLink.utils';

interface AddFileMetaProps {
  handleSave: (data: { filename: string | undefined; metadata?: Array<MetadataItem> }) => void;
  closeModal: () => void;
  metadata: Array<MetadataItem>;
  filename: string;
  isEdit: boolean;
  files: Array<{
    name: string;
  }>;
}

const AddFileMeta: React.FC<AddFileMetaProps> = ({ handleSave, closeModal, metadata, filename, isEdit, files }) => {
  const [fileExists, setFileExists] = useState<boolean>(_notNil(files.find(({ name }) => name === filename)));
  const formMethods = useForm({
    defaultValues: {
      filename: filename?.replace(/ /g, '_'),
      metadata: metadata || [],
    },
  });

  const { handleSubmit, register, errors, control } = formMethods;

  const fileName = useWatch({
    control,
    name: 'filename',
  });

  useEffect(() => {
    setFileExists(_notNil(files.find(({ name }) => name === fileName)));
  }, [fileName, files]);

  return (
    <ModalContainer size="narrow" className="bg-white f6 near-black ">
      <ModalHeader
        title={isEdit ? 'Edit file' : 'Add a file'}
        closeModal={closeModal}
        closeDisabled={false}
        className="pa3 bb b--moon-gray w-100"
      />
      <FormProvider {...formMethods}>
        <form className="pt3">
          {!isEdit && fileExists && (
            <Banner info className="mh3 mb3" dismiss={false} center>
              <h3 className="f6 normal lh-title">
                A file with this name already exists - continuing will overwrite it.
              </h3>
            </Banner>
          )}
          <div className="pb3 ph3" style={{ whiteSpace: 'break-spaces' }}>
            <label htmlFor="filename">File Name</label>
            <input
              type="text"
              name="filename"
              ref={register({
                required: 'Required',
                validate: {
                  containsNoSpaces,
                  withinCharacterLimits: (value: string) => withinCharacterLimits(value, 3, 500),
                  containsValidCharacters: (value: string) =>
                    containsValidCharacters(
                      value,
                      "File name can only contain letters, numbers or some special characters: _ - . ! * ' ( )"
                    ),
                },
              })}
              style={{ marginBottom: 0 }}
              className={`${errors.filename ? 'input__error' : ''}${isEdit ? 'disabled-link' : ''}`}
            />
            <ErrorMessage
              errors={errors}
              name="filename"
              render={({ message }) => <p className="f6 red db pt2">{message}</p>}
            />
          </div>
          {_isNotEmpty(metadata) && <div className="pb3 ph3">{getMetadataFields(metadata, {})}</div>}
        </form>
      </FormProvider>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onCancel={closeModal}
        cancelBtnText="Close"
        submitBtnText={fileExists ? 'Overwrite' : 'Save'}
        submitButtonProps={{
          className: 'ml3',
          disabled: _isNotEmpty(errors),
        }}
        onSubmit={handleSubmit((data: { filename: string | undefined; metadata?: Array<MetadataItem> }) =>
          handleSave(data)
        )}
      />
    </ModalContainer>
  );
};

export default AddFileMeta;
