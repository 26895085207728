// This file was generated by lezer-generator. You probably shouldn't edit it.
import { LRParser } from '@lezer/lr';
export const datatableFormulaParser = LRParser.deserialize({
  version: 14,
  states:
    "!|OVQPOOQbQPOOOVQPO'#C`OOQO'#Ck'#CkOOQO'#Cb'#CbOOQO'#Cc'#CcOOQO'#Cd'#CdOOQO'#Ce'#CeOOQO'#Ca'#CaOVQPO,58zObQPO,58zOpQPO1G.fOVQPO1G.fO!UQPO7+$QOOQO<<Gl<<Gl",
  stateData: '!g~O^OS~OQRORROYQO~O`SOaTObUOcVO~O`SOaTObUOcVO[SiZSi~OZ^O`SOaTObUOcVO~O',
  goto: '!R`PPPPagooooPPPPPuXROQX[UXPZ]R[YXWPYZ]QPOQYQQZXR][',
  nodeNames:
    '⚠ Formula Number Variable NumericExpression NumericOperator MultiplicationOperator DivisionOperator AdditionOperator SubtractionOperator OpeningBracket ClosingBracket',
  maxTerm: 19,
  skippedNodes: [0],
  repeatNodeCount: 0,
  tokenData:
    "({~RdX^!apq!atu#Uxy#vyz#{z{$Q{|$V}!O$[!O!P$a!P!Q%m!Q!R%r!R![&d#y#z!a$f$g!a#BY#BZ!a$IS$I_!a$I|$JO!a$JT$JU!a$KV$KW!a&FU&FV!a~!fY^~X^!apq!a#y#z!a$f$g!a#BY#BZ!a$IS$I_!a$I|$JO!a$JT$JU!a$KV$KW!a&FU&FV!a~#XS!Q![#e!c!}#e#R#S#e#T#o#e~#jSR~!Q![#e!c!}#e#R#S#e#T#o#e~#{OY~~$QOZ~~$VO`~~$[Ob~~$aOc~~$dP!Q![$g~$lSQ~!Q![$g!g!h$x#R#S$g#X#Y$x~${S{|%X}!O%X!Q![%b#R#S%b~%[Q!Q![%b#R#S%b~%gQQ~!Q![%b#R#S%b~%rOa~~%wXQ~!O!P$g!Q![&d!g!h$x#R#S&d#U#V'Q#X#Y$x#b#c&{#c#d'o#l#m(W~&iUQ~!O!P$g!Q![&d!g!h$x#R#S&d#X#Y$x#b#c&{~'QOQ~~'TR!Q!R'^!R!S'^#R#S'^~'cSQ~!Q!R'^!R!S'^#R#S'^#b#c&{~'rQ!Q!Y'x#R#S'x~'}RQ~!Q!Y'x#R#S'x#b#c&{~(ZS!Q![(g!c!i(g#R#S(g#T#Z(g~(lTQ~!Q![(g!c!i(g#R#S(g#T#Z(g#b#c&{",
  tokenizers: [0],
  topRules: { Formula: [0, 1] },
  tokenPrec: 0,
  termNames: {
    '0': '⚠',
    '1': '@top',
    '2': 'Number',
    '3': 'Variable',
    '4': 'NumericExpression',
    '5': 'NumericOperator',
    '6': 'MultiplicationOperator',
    '7': 'DivisionOperator',
    '8': 'AdditionOperator',
    '9': 'SubtractionOperator',
    '10': 'OpeningBracket',
    '11': 'ClosingBracket',
    '12': '␄',
    '13': '%mainskip',
    '14': 'space',
    '15': 'expression',
    '16': '"*"',
    '17': '"/"',
    '18': '"+"',
    '19': '"-"',
  },
});
