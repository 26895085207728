// @ts-nocheck: converted from JS

import { Icons } from '@/constants/Icons';
import { _notNil } from '@/littledash';
import iro from '@jaames/iro';
import './ColorEditor.scss';
import Handsontable from 'handsontable';

const validHexColorPattern = /^#([0-9A-F]{3}){1,2}$/i;

const colorRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.call(null, hotInstance, td, row, column, prop, value, cellProperties);
  Handsontable.dom.empty(td);
  if (!td.classList.contains('htInvalid') && _notNil(value)) {
    const colorChip = document.createElement('span');
    colorChip.classList.add('color-render-chip');
    colorChip.style.backgroundColor = value;
    td.appendChild(colorChip);
  }
};

export const colorValidator = (value, callback) => callback(validHexColorPattern.test(value));

export class ColorEditor extends Handsontable.editors.TextEditor {
  static type = 'color';

  static register(type = ColorEditor.type) {
    Handsontable.cellTypes.registerCellType(type, {
      editor: ColorEditor,
      renderer: colorRenderer,
      validator: colorValidator,
    });
  }

  static swatch = [
    '#333333',
    '#0066CC',
    '#B90745',
    '#F39521',
    '#743789',
    '#4EACF6',
    '#E56C92',
    '#78AD2B',
    '#F6CB45',
    '#00968C',
    '#8E1807',
    '#00007D',
    '#9B6D31',
    '#FF475A',
    '#0BCAB5',
    '#D4E27E',
    '#3E4EE0',
    '#EAC76C',
    '#FFCB90',
    '#90DED6',
    '#E5BFEE',
    '#879CA0',
    '#5F44AD',
    '#C9E4EB',
  ];

  static randomColor() {
    return `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')}`;
  }

  static randomSwatchColor() {
    return ColorEditor.swatch[Math.floor(Math.random() * ColorEditor.swatch.length)];
  }

  static colorForIndex(index) {
    if (_notNil(ColorEditor.swatch?.[index])) {
      return ColorEditor.swatch[index];
    }
    return ColorEditor.randomColor();
  }

  init() {
    super.init();
    this.instance.addHook('afterDestroy', () => this.destroyElements());
  }

  createElements() {
    super.createElements();

    this.colorPickerContainer = this.hot.rootDocument.createElement('div');
    this.colorPickerContainer.classList.add('color-picker-container');
    this.colorPickerContainer.style.display = 'none';
    this.colorPickerContainer.setAttribute('data-test-component', 'ColorEditor');
    this.colorPickerContainer.setAttribute('data-test-element', 'container');

    this.colorPickerModal = this.hot.rootDocument.createElement('div');
    this.colorPickerModal.classList.add('color-picker-modal');
    this.colorPickerModal.style.top = 0;
    this.colorPickerModal.style.left = 0;
    this.colorPickerContainer.appendChild(this.colorPickerModal);

    this.colorPickerHeader = this.hot.rootDocument.createElement('div');
    this.colorPickerHeader.classList.add('color-picker-header');
    this.colorPickerModal.appendChild(this.colorPickerHeader);

    this.colorPickerBody = this.hot.rootDocument.createElement('div');
    this.colorPickerBody.classList.add('color-picker-body');
    this.colorPickerModal.appendChild(this.colorPickerBody);

    this.colorPickerFooter = this.hot.rootDocument.createElement('div');
    this.colorPickerFooter.classList.add('color-picker-footer');
    this.colorPickerModal.appendChild(this.colorPickerFooter);

    this.iconSvg = this.hot.rootDocument.createElementNS('http://www.w3.org/2000/svg', 'svg');

    this.iconSvg.setAttribute('viewBox', '0 0 32 32');
    this.iconSvg.setAttribute('height', '24');
    this.iconSvg.setAttribute('width', '24');
    this.iconSvg.classList.add('icon', 'pencil-icon');

    this.iconPath = this.hot.rootDocument.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.iconPath.setAttribute('d', Icons['pencil']);

    this.iconSvg.appendChild(this.iconPath);

    this.colorPickerHeader.appendChild(this.iconSvg);

    this.colorHexInput = this.hot.rootDocument.createElement('input');
    this.colorHexInput.classList.add('color-picker-hex-input');
    this.colorHexInput.maxLength = 7;
    this.colorHexInput.setAttribute('data-test-element', 'hex-input');
    this.colorPickerHeader.appendChild(this.colorHexInput);

    this.colorChip = this.hot.rootDocument.createElement('span');
    this.colorChip.classList.add('color-picker-chip');
    this.colorPickerHeader.appendChild(this.colorChip);

    this.saveButton = this.hot.rootDocument.createElement('button');
    this.saveButton.classList.add('color-picker-save');
    this.saveButton.innerText = 'Save';
    this.saveButton.setAttribute('data-test-element', 'save-button');
    this.colorPickerFooter.appendChild(this.saveButton);

    this.cancelButton = this.hot.rootDocument.createElement('button');
    this.cancelButton.classList.add('color-picker-cancel', 'plain', 'ml2');
    this.cancelButton.innerText = 'Cancel';
    this.colorPickerFooter.appendChild(this.cancelButton);

    this.hot.rootDocument.body.appendChild(this.colorPickerContainer);

    this.colorPickerModal.addEventListener('mousedown', (e) => e.stopPropagation());
    this.colorPickerContainer.addEventListener('mousedown', () => this.finishEditing(false));

    this.colorPicker = new iro.ColorPicker(this.colorPickerBody, {
      color: this.originalValue ?? '#ffffff',
      layoutDirection: 'horizontal',
      width: 200,
      layout: [{ component: iro.ui.Box }, { component: iro.ui.Slider, options: { sliderType: 'hue' } }],
    });
    this.colorPicker.on('color:change', (color) => this.setValue(color.hexString));
    this.colorHexInput.addEventListener('input', (event) => {
      const value = event?.target?.value ?? '';
      if (validHexColorPattern.test(value)) {
        this.setValue(event.target.value);
      } else if (!value.startsWith('#')) {
        this.setValue(`#${value}`);
      }
    });
    this.TEXTAREA.addEventListener('input', (event) => {
      const value = event?.target?.value ?? '';
      if (validHexColorPattern.test(value)) {
        this.setValue(event.target.value);
      } else if (!value.startsWith('#')) {
        this.setValue(`#${value}`);
      }
    });
    this.saveButton.addEventListener('click', () => this.finishEditing(false));
    this.cancelButton.addEventListener('click', () => this.finishEditing(true));
    this.colorChip.addEventListener('click', () => this.setValue(ColorEditor.randomSwatchColor()));
  }

  setValue(newValue) {
    const color = newValue ?? ColorEditor.randomSwatchColor();
    super.setValue(color);
    if (this.colorPicker.color.hexString !== color) {
      this.colorPicker.color.set(color);
    }
    if (this.colorHexInput.value !== color) {
      this.colorHexInput.value = color;
    }
    if (this.TEXTAREA.value !== color) {
      this.TEXTAREA.value = color;
      this.TEXTAREA.style.color = color;
      this.TEXTAREA.style.backgroundColor = color;
    }
    this.colorChip.style.backgroundColor = color;
  }

  destroyElements() {
    Handsontable.dom.empty(this.colorPickerContainer);
    this.colorPickerContainer.remove();
  }

  finishEditing(restoreOriginalValue = false, ctrlDown = false) {
    if (restoreOriginalValue && _notNil(this.originalValue)) {
      this.setValue(this.originalValue);
    }
    super.finishEditing(restoreOriginalValue, ctrlDown);
  }

  open(event = null) {
    super.open();
    this.showColorPicker(event);
  }

  close() {
    super.close();
    this.hideColorPicker();
  }

  focus() {
    super.focus();
    this.colorHexInput.focus();
  }

  showColorPicker(event) {
    const { top = 0, left = 0 } = this.TD?.getBoundingClientRect() ?? {};
    this.colorPickerModal.style.top = `${this.hot.rootWindow.scrollY + top}px`;
    this.colorPickerModal.style.left = `${this.hot.rootWindow.scrollX + left}px`;
    this.setValue(this.originalValue);
    this.colorPickerContainer.style.display = 'block';
  }

  hideColorPicker() {
    this.colorPickerContainer.style.display = 'none';
  }
}
