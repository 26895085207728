import type { DataTableColumn } from '@/components/UI/DataTable/DataTable.model';
import { _notNil } from '@/littledash';
import Fuse from 'fuse.js';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';
import styles from './EditDataTableFormulaColumn.module.scss';

interface ColumnListProps {
  columns: Array<DataTableColumn>;
  columnSelected: (column: DataTableColumn) => void;
}

export const ColumnList: FC<ColumnListProps> = ({ columns, columnSelected }) => {
  const [search, setSearch] = useState<string>('');
  const fuse = useMemo(() => {
    return new Fuse(columns, {
      includeScore: false,
      ignoreLocation: true,
      keys: [
        { name: 'name', weight: 0.55 },
        { name: 'reference_date', weight: 0.2 },
        { name: 'type', weight: 0.15 },
        { name: 'unit', weight: 0.05 },
      ],
    });
  }, [columns]);
  const filteredColumns = useMemo<Array<DataTableColumn>>(() => {
    if (_notNil(fuse) && (search?.trim()?.length ?? 0) > 0) {
      return fuse.search(search).map(({ item }) => item);
    } else {
      return columns;
    }
  }, [fuse, search]);

  const handleColumnSelect = (column: DataTableColumn) => {
    setSearch('');
    columnSelected(column);
  };

  return (
    <div>
      <div className={styles['data-table-formula-search']}>
        <span className={styles['data-table-formula-search-icon']}>
          <RiSearch2Line size="18" />
        </span>
        <input
          className={styles['data-table-formula-search-input']}
          type="text"
          disabled={columns.length === 0}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search columns"
        />
      </div>
      <div className={styles['data-table-formula-search-list']}>
        {filteredColumns.map((column) => (
          <div
            className={styles['data-table-formula-search-item']}
            key={column.id}
            onClick={() => handleColumnSelect(column)}
          >
            <div className={styles['data-table-formula-item-pill']}>
              <div>{column.name}</div>
              {_notNil(column.reference_date) ? (
                <div className={styles['data-table-formula-item-pill-subtext']}>{`${column.reference_date}`}</div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
