import { _notNil } from '@/littledash';
import type { CSSProperties, ReactNode } from 'react';

export interface AvatarProps {
  className?: string;
  style?: CSSProperties;
  tooltip?: string;
  includeName?: boolean;
  name?: string;
  children?: ReactNode;
  bgColorClass?: string;
  customBorderRadiusClass?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  className,
  style = {},
  tooltip,
  includeName,
  name,
  children,
  bgColorClass,
  customBorderRadiusClass,
}) => {
  return (
    <div className={`dib ${className}`} data-testid="avatar-container">
      <div
        data-tooltip-id="global-tooltip-id"
        data-tooltip-content={tooltip}
        className={`${customBorderRadiusClass ?? 'br-100'} tc w2 h2 dib ${bgColorClass}`}
        style={style}
      >
        <div className="flex justify-center items-center w-100 h-100">
          <h4 className={`normal ${_notNil(style?.height) ? 'f8' : 'f6'} lh-title white`} data-testid="avatar-initials">
            {children || '-'}
          </h4>
        </div>
      </div>
      {includeName && (
        <span className="ml2 dib" data-testid="avatar-name">
          {name}
        </span>
      )}
    </div>
  );
};

export default Avatar;
