import UserAvatar from '@/components/UI/UserAvatar';
import { userRoles } from '@/referenceData/study/user';
import { useDispatch } from 'react-redux';
import { _isNotEmpty } from '@/littledash';
import type { StudyUser } from '@/model/User.model';
import './UserAvatarPlus.scss';

interface UserAvatarPlusProps {
  users?: Array<StudyUser>;
  author?: StudyUser;
  owner?: StudyUser;
}

const UserAvatarPlus: React.FC<UserAvatarPlusProps> = (props) => {
  const dispatch = useDispatch();
  const { users = [] } = props;

  const showHiddenUsers = () => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'SHOW_HIDDEN_USERS',
      props,
    });
  };

  return (
    <>
      {_isNotEmpty(users) && (
        <>
          <UserAvatar
            user={users[0]}
            className="dib ml1"
            tooltip={`${users[0].name} (${userRoles[users[0].access]})`}
          />
          {users.length > 1 && (
            <div className="dib ml1 pointer" onClick={showHiddenUsers} title="Click to view users and roles">
              <div className="br-100 tc w2 h2 dib avatar-user">
                <div className="flex justify-center items-center w-100 h-100">
                  <h4 className="normal f6 lh-title white">+{users.length - 1}</h4>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserAvatarPlus;
