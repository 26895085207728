// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Radio from '@/components/UI/FormElements/Radio';
import Icon from '@/components/UI/Icon';
import Lookup from '@/components/UI/Lookup';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { validateFilter } from '@/helpers';
import { _isEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { FC, useState } from 'react';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import MenuContainer from './MenuContainer';

const EMPTY_OPTION = {
  name: '',
  value: '',
};

const operationHasOptions = (options) => options && !_isEmpty(options);

interface TableFilterMenuProps {
  closeMenu?: () => void;
  filters?: Record<string, any>;
  filterMatch?: boolean;
  filterOptions: Array<{ name: string; value: string }>;
  onUpdateFilters: (filters: TableFilterMenuProps['filters'], filterMatch: boolean) => void;
}

const TableFilterMenu: FC<TableFilterMenuProps> = ({
  closeMenu,
  filters,
  filterMatch,
  filterOptions,
  onUpdateFilters,
}) => {
  const initialiseFilter = () => {
    const firstCategory = filterOptions[0];
    const firstOperation = firstCategory?.operations?.[0];
    const hasOptions = operationHasOptions(firstOperation?.options);
    if (firstCategory) {
      const filter = {
        category: {
          name: firstCategory.name,
          value: firstCategory.value || firstCategory.name,
          index: 0,
        },
        operation: {
          name: firstOperation?.name,
          index: 0,
        },
      };
      if (hasOptions) {
        const firstOption = firstOperation?.options?.[0];
        filter.option = {
          name: firstOption?.name,
          value: firstOption?.value ?? firstOption?.name,
        };
      } else {
        filter.option = EMPTY_OPTION;
      }
      return filter;
    }
    return {};
  };
  const [localFilters, setLocalFilters] = useState(_isEmpty(filters) ? [initialiseFilter()] : filters);
  const [localFilterMatch, setLocalFilterMatch] = useState(filterMatch);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const addFilterRow = (e) => {
    setLocalFilters([...localFilters, initialiseFilter()]);
  };
  const removeFilterRow = (index) => {
    const updatedFilters = [...localFilters];
    updatedFilters.splice(index, 1);
    setLocalFilters(updatedFilters);
  };

  const onChangeFilterBetweenOptions = (selectedDays, index) => {
    const updatedFilters = [...localFilters];
    const from = DateUtils.isValidDate(selectedDays?.from) ? selectedDays.from : DateUtils.dateNow();
    const to = DateUtils.isValidDate(selectedDays?.to) ? selectedDays.to : DateUtils.dateNow();

    updatedFilters[index] = {
      ...updatedFilters[index],
      option: Date.parse(from) <= Date.parse(to) ? { from, to } : { from: to, to: from },
    };
    setLocalFilters(updatedFilters);
  };

  const onChangeFilterOptionText = (event, index, value) => {
    const updatedFilters = [...localFilters];
    updatedFilters[index] = {
      ...updatedFilters[index],
      option: {
        name: value?.label || event.target.name,
        value: value?.value || event.target.value,
      },
    };
    setLocalFilters(updatedFilters);
  };
  const onChangeFilterOptionDate = (date, index) => {
    const updatedFilters = [...localFilters];
    const to = DateUtils.isValidDate(date) ? date : DateUtils.dateNow();

    updatedFilters[index] = {
      ...updatedFilters[index],
      option: {
        value: to,
      },
    };
    setLocalFilters(updatedFilters);
  };

  /* **
    This is triggered when the category is changed.
    When this happens, the operation and option are regenerated too.
  ***/
  const onChangeFilterCategory = (event, rowIndex) => {
    const currentCategoryIndex = event.target.value;
    const updatedCategory = {
      name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
      value: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].id,
      index: currentCategoryIndex,
    };

    const updatedOperation = filterOptions[currentCategoryIndex]?.operations[0];
    const hasOptions = operationHasOptions(filterOptions[currentCategoryIndex]?.operations?.[0]?.options);

    let updatedOption;
    if (updatedOperation.name === filterType.between) {
      updatedOption = hasOptions
        ? filterOptions[currentCategoryIndex]?.operations?.[0].options?.[0]
        : { from: '', to: '' };
    } else {
      const option = hasOptions ? filterOptions[currentCategoryIndex]?.operations?.[0].options?.[0] : '';
      updatedOption = hasOptions
        ? {
            name: option.name,
            value: option.value || option.name,
          }
        : EMPTY_OPTION;
    }
    const updatedFilters = [...localFilters];
    updatedFilters[rowIndex] = {
      category: updatedCategory,
      operation: {
        name: updatedOperation.name,
        index: 0,
      },
      option: updatedOption,
    };
    setLocalFilters(updatedFilters);
  };
  /** *
   This is triggered when the operation is changed.
   When this happens, the options are regenerated too.
   ***/
  const onChangeFilterOperation = (event, index) => {
    const currentCategoryIndex = localFilters[index].category.index;
    const currentOperationIndex = event.target.value;

    const updatedOperation = {
      name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
      index: event.target.value,
    };

    const hasOptions = operationHasOptions(
      filterOptions[currentCategoryIndex].operations[currentOperationIndex].options
    );

    const updatedOption = hasOptions
      ? filterOptions[currentCategoryIndex].operations[currentOperationIndex].options[0]
      : EMPTY_OPTION;

    const updatedFilters = [...localFilters];
    updatedFilters[index] = {
      category: updatedFilters[index].category,
      operation: updatedOperation,
      option: {
        name: updatedOption.name,
        value: updatedOption.value ? updatedOption.value : updatedOption.name,
      },
    };
    setLocalFilters(updatedFilters);
  };
  const onChangeFilterMatchAny = () => {
    setLocalFilterMatch(false);
  };
  const onChangeFilterMatchAll = () => {
    setLocalFilterMatch(true);
  };
  const onChangeFilterOptionSelect = (event, index) => {
    const updatedFilters = [...localFilters];
    updatedFilters[index] = {
      ...updatedFilters[index],
      option: {
        name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
        value: event.target.value,
      },
    };
    setLocalFilters(updatedFilters);
  };

  const saveFilters = (e) => {
    e.preventDefault();
    onUpdateFilters(localFilters, localFilterMatch);
    closeMenu();
  };

  const clearFilters = (e) => {
    setLocalFilters([initialiseFilter()]);
    closeMenu();
  };

  const loadOptions = async ({ inputValue: query, page }, fetchFromApi) => {
    try {
      setLoading(true);
      let hasMore = false;

      let queryParams = {
        page,
        ...fetchFromApi.queryParams,
      };

      if (query) {
        queryParams = { ...queryParams, query };
      }

      try {
        const {
          data: { data, meta },
        } = await Http.get(
          apiRoute(
            fetchFromApi.url,
            {
              ...fetchFromApi?.params,
            },
            queryParams
          )
        );

        if (_notNil(meta)) {
          hasMore = _notNil(meta.pagination)
            ? meta.pagination.current_page < meta.pagination.total_pages
            : meta.current_page < meta.total_pages;
        }

        return {
          options: data.map((d) => ({
            label: safelyDecodeURIComponent(d.name || d.title),
            value: d.id,
          })),
          hasMore,
        };
      } catch (error) {
        setApiError(error);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err);
    }
  };

  const renderInput = (operation, filter, index, fetchFromApi) => {
    switch (operation.value) {
      case filterType.eq:
      case filterType.ne:
      case filterType.in:
      case filterType.not_in:
        if (fetchFromApi) {
          return (
            <div className="w5 pr4">
              <Lookup
                disabled={loading}
                isMulti={false}
                placeholder={fetchFromApi.placeholder}
                loadOptions={(inputValue) => loadOptions(inputValue, fetchFromApi)}
                value={{
                  label: filter.option.name,
                  value: filter.option.value,
                }}
                handleSelect={(data) => onChangeFilterOptionText({}, index, data)}
              />
              {apiError && <p className="f7 red">There was a problem fetching filter options</p>}
            </div>
          );
        } else {
          return (
            <select
              onChange={(event) => onChangeFilterOptionSelect(event, index)}
              name={filter.option.name}
              value={filter.option.value}
              className="f6"
              data-testid="option-input"
            >
              {operation.options.map((option) => (
                <option key={option.value} value={option.value} data-testid="option">
                  {option.name}
                </option>
              ))}
            </select>
          );
        }
      case filterType.contains:
      case filterType.not_contains:
        return (
          <input
            type="text"
            data-testid="option-input"
            className="f6"
            onChange={(event) => onChangeFilterOptionText(event, index)}
            name={filter.option.name}
            value={filter.option.value}
            placeholder={'Enter value'}
            autoFocus
          />
        );
      case filterType.gt:
      case filterType.lt:
        return (
          <input
            type="number"
            data-testid="option-input"
            className="f6"
            onChange={(event) => onChangeFilterOptionText(event, index)}
            onWheel={(event) => event.target.blur()}
            name={filter.option.name}
            value={filter.option.value}
            placeholder={'Enter value'}
            autoFocus
          />
        );
      case filterType.between:
        return (
          <DateRangePicker
            range={filter.option}
            onChange={(selectedDays) => onChangeFilterBetweenOptions(selectedDays, index)}
          />
        );
      case filterType.not_eq_date:
      case filterType.eq_date:
        return (
          <DatePickerNative
            data-testid="option-input"
            value={filter.option.value}
            onChange={(event) => {
              onChangeFilterOptionDate(event, index);
            }}
            style={{ marginBottom: 0 }}
          />
        );
    }
  };
  const preventDefaultForm = (event) => {
    event.preventDefault();
  };
  const renderFilterRow = (filterOptions, filter, rowIndex) => {
    if (!validateFilter(filter)) {
      return;
    }
    const currentCategory = filter.category.index;
    const currentOperation = filter.operation.index;

    const isBetween = filter.operation.name === filterType.between;
    return (
      <div className="flex" key={`${filter.category.name}-${rowIndex}`} data-testid={`row-${rowIndex}`}>
        <select
          className="f6 mr2 w-30"
          onChange={(event) => onChangeFilterCategory(event, rowIndex)}
          value={currentCategory}
          data-testid="category-select"
        >
          {filterOptions.map((category, categoryIndex) => (
            <option
              key={category.name}
              value={categoryIndex}
              id={category.value || category.name}
              name={category.name}
              data-testid="category"
            >
              {category.name}
            </option>
          ))}
        </select>

        {!isBetween && (
          <select
            className="f6 mr2 w-20"
            onChange={(event) => onChangeFilterOperation(event, rowIndex)}
            name={filter.operation.name}
            value={currentOperation}
            data-testid="operation-select"
          >
            {filterOptions[currentCategory].operations.map((operation, operationIndex) => (
              <option key={operation.name} value={operationIndex} name={operation.name} data-testid="operation">
                {operation.name}
              </option>
            ))}
          </select>
        )}

        <div className={`f6 mr2 ${isBetween ? 'w-60' : 'w-40'}`}>
          {renderInput(
            filterOptions[currentCategory].operations[currentOperation],
            filter,
            rowIndex,
            filterOptions[currentCategory]?.fetchFromApi
          )}
        </div>
        <Button
          data-testid={`remove-row-button-${rowIndex}`}
          className="hover-near-black repeater-field__remove-btn soft"
          onClick={() => removeFilterRow(rowIndex)}
        >
          <Icon icon="close" width="10" height="10" className="dark-gray" />
        </Button>
      </div>
    );
  };

  if (_isEmpty(filters) && _isEmpty(filterOptions)) {
    return null;
  }

  return (
    <MenuContainer width={620}>
      <form onSubmit={preventDefaultForm}>
        <div className="pa3">
          <div className="mb3">
            <div className="f6 near-black lh-title pb2">Results must match</div>
            <div className="flex flex-wrap">
              <Radio
                checked={localFilterMatch}
                onChange={onChangeFilterMatchAll}
                id="all"
                label="All conditions"
                data-testid="match-all-button"
                className="mr3"
              />
              <Radio
                checked={!localFilterMatch}
                onChange={onChangeFilterMatchAny}
                id="any"
                label="Any condition"
                data-testid="match-any-button"
                className="mr3"
              />
            </div>
          </div>
          {localFilters.map((filter, index) => renderFilterRow(filterOptions, filter, index))}
          <Button onClick={addFilterRow} soft blue className="mr3 f6" data-testid="add-row-button">
            Add condition
          </Button>
        </div>
        <div className="pa3 bt b--moon-gray">
          <Button type="submit" className="mr2 f6" onClick={saveFilters} data-testid="save-button">
            Filter
          </Button>
          <Button type="submit" className="plain f6" onClick={clearFilters} data-testid="cancel-button">
            Cancel
          </Button>
        </div>
      </form>
    </MenuContainer>
  );
};

export default TableFilterMenu;
