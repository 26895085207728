// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Icon from '@/components/UI/Icon';
import { _isNotEmpty, _noop, _notNil } from '@/littledash';
import { useDevices } from '@/utils/devices/useDevices';
import { FC, useEffect, useRef, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';

interface KeywordSearchProps {
  handleSearchInput?: any;
  searchQuery?: any;
  setSearchQuery?: any;
  searchBy?: any;
  setSearchBy?: any;
  searchByOptions?: any;
  searchPlaceholderText?: any;
  style?: any;
  className?: any;
  autoFocus?: any;
  disabled?: any;
  searchBarRef?: any;
  autoComplete?: boolean;
}

const KeywordSearch: FC<KeywordSearchProps> = ({
  handleSearchInput = _noop,
  searchQuery,
  setSearchQuery = false,
  searchBy,
  setSearchBy,
  searchByOptions,
  searchPlaceholderText,
  style,
  className,
  autoFocus,
  disabled,
  searchBarRef,
  autoComplete = true,
}) => {
  const [placeholder, setPlaceHolder] = useState(searchPlaceholderText ?? '');
  const _searchBarRef = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { dirtyFields },
    reset,
  } = useForm({ defaultValues: { query: searchQuery, by: searchBy } });

  const onNewReading = (response) => {
    if (_searchBarRef.current) {
      _searchBarRef.current.value = response;
    }
    handleSearchInput(response);
    nextReading(true);
  };

  const { nextReading } = useDevices({
    targetId: 'focus-search',
    onNewReading,
  });

  const { by, query } = watch(['by', 'query']);

  useEffect(() => {
    const searchByOptionDisplay = searchByOptions?.find(({ value }) => value === by)?.display ?? null;
    setPlaceHolder(`${searchPlaceholderText}${_notNil(searchByOptionDisplay) ? ` by ${searchByOptionDisplay}` : ''}`);

    if (_notNil(searchByOptionDisplay) && _notNil(searchPlaceholderText)) {
      setPlaceHolder(`${searchPlaceholderText} by ${searchByOptionDisplay}`);
    } else {
      setPlaceHolder(searchPlaceholderText);
    }
  }, [by, searchPlaceholderText]);

  useEffect(() => {
    if (setSearchQuery) {
      setSearchQuery(query);
    }
  }, [query]);

  useEffect(() => {
    if (_isNotEmpty(by) && _notNil(setSearchBy)) {
      setSearchBy(by);
      if ((query?.trim()?.length ?? 0) > 0) {
        handleSearchInput(query);
      }
    }
  }, [by]);

  const resetButtonClickHandler = () => {
    reset({ query: '', by: searchBy });
    handleSearchInput('');
  };

  const selectSearchInputText = () => {
    _searchBarRef?.current?.focus();
    _searchBarRef?.current?.select();
  };

  const preventEnterKeySubmission = (event: KeyboardEvent<HTMLFormElement>) => {
    const target = event.target as HTMLFormElement;
    if (event.key === 'Enter' && target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = () => {
    const value = query?.trim() ?? '';
    if (value.length > 0) {
      handleSearchInput(value);
    } else {
      resetButtonClickHandler();
    }
    selectSearchInputText();
  };
  const multiRef = (element) => {
    _searchBarRef.current = element;
    register(element);
    if (searchBarRef != null) {
      searchBarRef.current = element;
    }
  };

  return (
    <form
      className="relative flex flex-grow-1"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={preventEnterKeySubmission}
      data-test-component="KeywordSearch"
      data-test-element="form"
    >
      <div className="relative w-100">
        <span className="absolute z-5" style={{ top: '13px', left: '10px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <span className="mw6 relative">
          <input
            ref={multiRef}
            disabled={disabled}
            type="text"
            data-test-element="query-input"
            name="query"
            className={`${className} ui__keyword-search-bar relative ba b--gray bg-white`}
            style={{ ...style }}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete={autoComplete ? 'on' : 'off'}
          />
          {(searchQuery?.length > 0 || dirtyFields?.query) && (
            <Button
              onClick={resetButtonClickHandler}
              stateless
              style={{
                padding: '0',
                top: '0.2em',
                right: '1em',
              }}
              className="blue f6 absolute right-0"
              testId="keyword-search-reset-button"
            >
              Reset
            </Button>
          )}
        </span>
        {_isNotEmpty(searchByOptions) && (
          <select className="ml2 w4 dib" style={{ marginBottom: 0 }} name="by" ref={register}>
            {searchByOptions.map(({ value, display }) => (
              <option key={value} value={value} data-test-element="option" data-test-key={value}>
                {display}
              </option>
            ))}
          </select>
        )}
      </div>
    </form>
  );
};

export default KeywordSearch;
