// @ts-nocheck: converted from JS

import { FilterTypes as filterType } from '@/constants/FilterTypes';

export const assembleCageFilterOptions = (studyGroups) => {
  const variableFilters = [];

  if (studyGroups.length) {
    const groups = studyGroups.map((group) => ({
      name: group.name,
      value: parseInt(group.id),
    }));
    variableFilters.push({
      value: 'study_group_id',
      name: 'Group',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: groups,
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: groups,
        },
      ],
    });
  }

  return [
    {
      value: 'sex',
      name: 'Sex',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [
            { value: 'm', name: 'Male' },
            { value: 'f', name: 'Female' },
          ],
        },
      ],
    },
    ...variableFilters,
  ];
};
