// @ts-nocheck: converted from JS

import { defaultPromiseErrorHandler, errorToast, formatNumberComingIn, successToast } from '@/helpers';
import InVivoError from '@/model/InVivoError.ts';
import type { MetadataField } from '@/model/Metadata.model';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import axios from 'axios';
import { validMultiSelectValue } from '../Create/Show.utils';

const putFile = (url: string, file: File): Promise<void> =>
  fetch(url, { method: 'PUT', body: file }).then((result) => {
    if (result.status === 200) {
      return Promise.resolve();
    }
    const error = new Error('File upload error');
    Object.assign(error, { fileName: file?.name, fileType: file?.type });
    return Promise.reject(error);
  });
export const loadFiles = ({ context, setFiles, setInitialFiles, setError, setLoading }) => {
  const id = context.study?.id;
  setLoading('Loading files...');

  Http.get(apiRoute('studies.files', { id }))
    .then(({ data }) => {
      setFiles(data);
      setInitialFiles(data);
    })
    .catch(({ response }) => {
      setError(response);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const mapAttachmentMetadataForSave = (
  attachmentMetadata: Record<string, string | Array<string> | number>,
  metadata: Array<MetadataField> = []
): Record<string, string | Array<string>> =>
  Object.entries(attachmentMetadata ?? {}).reduce((acc, [key, value]) => {
    const metaType = metadata?.find((m) => m.title === key)?.field_type ?? '';
    if (metaType === 'numeric') {
      value = value === '' ? '' : formatNumberComingIn(value).toString();
    }
    if ((metaType === 'multi_select' || metaType === 'lookup_multi_select') && !validMultiSelectValue(value)) {
      value = '';
    }
    acc[key] = value;
    return acc;
  }, {});

export const uploadFile = ({
  context,
  file,
  setFiles,
  setInitialFiles,
  setLoading,
  formDispatch,
  name,
  attachmentMetadata,
  metadata,
  isEdit,
}) => {
  const mappedMetadata = mapAttachmentMetadataForSave(attachmentMetadata, metadata);
  const id = context.study?.id;

  const closeModal = () => {
    formDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const updateFileState = (data, name, isEdit) => {
    setFiles(data);
    setInitialFiles(data);
    successToast(`${isEdit ? 'Updated' : 'Uploaded'} file ${name}`);
  };

  const setFileError = (error, name) => {
    errorToast(`Could not upload ${name}.\nPlease try again later ... if this keeps occurring please contact support.`);
    formDispatch({ type: 'SET_ERROR', data: error });
  };

  const updateFile = () => {
    return Http.put(apiRoute('studies.files', { id }), { name, metadata: mappedMetadata })
      .then(() => {
        closeModal();
        return Http.get(apiRoute('studies.files', { id }));
      })
      .then(({ data }) => {
        updateFileState(data, name, isEdit);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        setFileError(error, name);
        ExceptionHandler.captureException(
          new InVivoError('Could not update file', {
            cause: error,
            slug: 'file-update',
          }),
          {
            fileName: file?.name,
            fileType: file?.type,
          }
        );
      });
  };

  const createFile = () => {
    return Http.post(apiRoute('studies.files', { id }), { name, metadata: mappedMetadata })
      .then(({ data }) => {
        closeModal();
        return putFile(data.url, file);
      })
      .then(() => Http.get(apiRoute('studies.files', { id })))
      .then(({ data }) => {
        updateFileState(data, name, isEdit);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        setFileError(error, name);
        ExceptionHandler.captureException(
          new InVivoError('Could not create file', {
            cause: error,
            slug: 'file-create',
          }),
          {
            fileName: file?.name,
            fileType: file?.type,
          }
        );
      });
  };

  setLoading(`Uploading ${name}`);
  if (isEdit) {
    updateFile();
  } else {
    createFile();
  }
};

export const deleteFile = ({ context, name, setFiles, setInitialFiles, setLoading, setError }) => {
  const id = context.study?.id;
  setLoading(`Removing ${name}...`);
  Http.delete(apiRoute('studies.files.name', { id, name }))
    .then(() => Http.get(apiRoute('studies.files', { id })))
    .then(({ data }) => {
      setFiles(data);
      setInitialFiles(data);
      successToast(`Removed ${name}`);
    })
    .catch((error) => {
      setError(error);
      ExceptionHandler.captureException(new InVivoError('Cannot delete file', { cause: error, slug: 'file-delete' }));
    })
    .finally(() => {
      setLoading(false);
    });
};

const saveFile = (url, name) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(defaultPromiseErrorHandler);
};

export const downloadFile = ({ context, name, setLoading }) => {
  const id = context.study?.id;
  setLoading(`Downloading ${name}...`);
  return Http.get(apiRoute('studies.files.name', { id, name }))
    .then(({ data }) => saveFile(data.url, name))
    .finally(() => setLoading(false));
};
