import Icon from '@/components/UI/Icon';
import Indicator from '@/components/UI/Indicator';
import { _get, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { AltId, Animal, AnimalIdentifier } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import React, { CSSProperties, MouseEventHandler, RefCallback, useCallback, useState } from 'react';
import { FixedSizeList } from 'react-window';
import type { ShowState, WorkflowDispatch } from './Workflow.model';
import Tooltip from '@/components/UI/Tooltip';
import { modalAction } from '@/utils/modal';
import { useDispatch } from 'react-redux';
import { animalIdsRecord } from '@/constants/utils';
import { EditSubjectIdProps } from '@/components/Modals/EditSubjectId/EditSubjectId';

interface AnimalSelectPanelProps {
  state: ShowState;
  dispatch: WorkflowDispatch;
  disabled: boolean;
}

const AnimalSelectPanel: React.FC<AnimalSelectPanelProps> = ({ state, dispatch, disabled }) => {
  const {
    selectedCage,
    checklist,
    selectedAnimal,
    subjects,
    collections,
    idToSearch,
    idToDisplay,
    originalSubjects,
    alerts,
  } = state;
  const { openModal, closeModal } = modalAction(useDispatch());

  const deceased = subjects.filter((subject) => _notNil(subject.terminated_at) && _isNil(subject.deceasedCageId));
  const [height, setHeight] = useState<number | null>(null);
  const stageRef = useCallback<RefCallback<HTMLDivElement>>((instance) => {
    if (_notNil(instance)) {
      setHeight(instance.getBoundingClientRect().height);
    }
  }, []);
  const inActiveMenuItemClasses = 'br2 pointer f6 hover-blue hover-bg-moon-gray db w-100 near-black pv2 ph3';
  const activeMenuItemClasses = 'br2 pointer f6 bg-near-white blue db w-100 near-black pv2 ph3';

  const filterSubjects = (subjectArray: Array<Animal>) =>
    subjectArray.filter((subject) => subject.cage_id === selectedCage || subject.deceasedCageId === selectedCage);

  const selectedSubjects = filterSubjects(subjects);
  const originalSelectedSubjects = selectedCage === 'deceased' ? deceased : filterSubjects(originalSubjects);

  const animalHasUnresolvedAlerts = (animalId: Animal['id']): boolean =>
    Object.values(alerts.animalAlerts?.[animalId] ?? {}).some((resolved) => !resolved);

  const nameToShow = (subject: Animal, idToDisplay: AnimalIdentifier) =>
    _get(subject, animalIdsRecord[idToDisplay].animalIdKey, subject.name) ?? subject.name;

  const searchPlaceholderText = animalIdsRecord[state.idToSearch]?.title ?? 'Name';

  return (
    <div className="flex flex-wrap justify-between w-40 h-100">
      <div ref={stageRef} className="ui__workflow__panel br b--moon-gray w-40 relative flex flex-wrap">
        <div className="w-100 self-start" data-testid="workflow-cage-list-container">
          <div className="pv3 ph4 bb b--moon-gray">
            <span className="basier-med lh-title f6 near-black">Cages</span>
          </div>
          {_notNil(height) && (
            // @ts-expect-error FixedSizeList
            <FixedSizeList
              height={height - 102}
              itemCount={collections.length}
              itemSize={33}
              className="workflow-cage-list"
            >
              {({ index, style }) => {
                const collection = collections[index];

                const { subjectsInCollection, hasAlert } = subjects.reduce<{
                  subjectsInCollection: Array<Animal>;
                  hasAlert: boolean;
                }>(
                  (acc, subject) => {
                    const inCage = subject.cage_id === collection?.id;
                    const subjectsInCollection = inCage
                      ? [...acc.subjectsInCollection, { ...subject }]
                      : [...acc.subjectsInCollection];

                    const hasAlert = acc.hasAlert || (inCage && animalHasUnresolvedAlerts(subject.id));

                    return { ...acc, subjectsInCollection, hasAlert };
                  },
                  { subjectsInCollection: [], hasAlert: false }
                );

                return (
                  <ListItem
                    title={collection.name}
                    available={!disabled && subjectsInCollection.length > 0}
                    selected={collection.id === selectedCage}
                    onClick={() =>
                      dispatch({
                        type: 'selectCage',
                        data: {
                          selectedCage: collection.id,
                        },
                      })
                    }
                    asideComponent={() => <span className="dark-gray">{subjectsInCollection.length}</span>}
                    style={style}
                    hasAlert={hasAlert}
                  />
                );
              }}
            </FixedSizeList>
          )}
        </div>
        <div className={`self-end w-100 bt b--moon-gray ${deceased.length < 1 && 'ui__disabled'} pv2 ph3`}>
          <a
            className={`flex flex-wrap justify-between items-center db lh-title f6 ${
              selectedCage === -1 ? activeMenuItemClasses : inActiveMenuItemClasses
            }`}
            onClick={() =>
              dispatch({
                type: 'selectCage',
                data: {
                  selectedCage: 'deceased',
                },
              })
            }
          >
            Deceased
            <Icon
              className="dark-gray"
              icon="arrow_right"
              viewBox="0 0 5 7"
              width="7"
              height="8"
              style={{ pointerEvents: 'none' }}
            />
          </a>
        </div>
      </div>
      <div className="ui__workflow__panel w-60 h-100 br b--moon-gray" data-testid="workflow-animal-list-container">
        <div className="pv3 ph4 bb b--moon-gray">
          <span className="basier-med lh-title f6 near-black">Animals</span>
        </div>
        {height && selectedCage && (
          // @ts-expect-error FixedSizeList
          <FixedSizeList
            className="workflow-animal-list"
            height={height - 102}
            itemCount={originalSelectedSubjects.length}
            itemSize={33}
          >
            {({ index, style }) => {
              const subject = originalSelectedSubjects[index];
              const available =
                originalSelectedSubjects.length > 0 &&
                !!selectedSubjects.find(({ catalog }) => catalog === subject.catalog);
              const hasAlert = animalHasUnresolvedAlerts(subject.id);
              const idValue = _get(subject, animalIdsRecord[idToSearch]?.animalIdKey ?? '');
              const hideEditId = idToSearch === 'name' && idToDisplay === 'name';
              const showEditSubjectId = idToSearch !== 'name';
              const aside = (id: ID) => (
                <>
                  {!hideEditId && (
                    <Tooltip render={showEditSubjectId ? `Edit ${searchPlaceholderText} ID` : null}>
                      <span
                        className="dark-gray small trunc"
                        style={{ marginRight: _isNotEmpty(checklist[id]) ? '20px' : '' }}
                        onClick={(event) => {
                          if (showEditSubjectId) {
                            event.stopPropagation();
                            openModal('EDIT_SUBJECT_ID', {
                              closeModal,
                              id: {
                                name: searchPlaceholderText,
                                altId: idToSearch as AltId,
                                value: idValue,
                              },
                              subject,
                              onComplete: (value) => {
                                const updatedSubject = {
                                  ...subject,
                                  alt_ids: { ...subject.alt_ids, [idToSearch]: value },
                                };
                                dispatch({
                                  type: 'updateSubject',
                                  data: updatedSubject,
                                });
                              },
                            } as EditSubjectIdProps);
                          }
                        }}
                      >
                        {idValue}
                      </span>
                    </Tooltip>
                  )}
                  {_isNotEmpty(checklist[id]) && <Checked />}
                </>
              );

              return (
                <>
                  <ListItem
                    title={nameToShow(subject, idToDisplay)}
                    available={available}
                    selected={subject.id === selectedAnimal}
                    onClick={() => {
                      dispatch({
                        type: 'update',
                        data: {
                          selectedAnimal: subject.id,
                        },
                      });
                    }}
                    style={style}
                    hasAlert={hasAlert}
                    asideComponent={() => aside(subject.id)}
                  />
                </>
              );
            }}
          </FixedSizeList>
        )}
      </div>
    </div>
  );
};

interface ListItemProps {
  title?: string;
  available?: unknown;
  selected?: unknown;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  asideComponent?: () => React.ReactNode;
  style?: CSSProperties;
  hasAlert?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  title,
  available,
  selected,
  onClick,
  asideComponent,
  style,
  hasAlert,
}) => {
  const inActiveMenuItemClasses = 'hover-blue hover-bg-moon-gray near-black';
  const activeMenuItemClasses = 'bg-near-white blue';

  return (
    <div className="mt1" style={style}>
      <a
        onClick={onClick}
        className={`flex justify-between relative lh-title pv2 pr3 pl4 mv2 mh3 db f6 pointer br2 ${
          !available && 'ui__disabled'
        } ${selected ? activeMenuItemClasses : inActiveMenuItemClasses}`}
      >
        <div className="trunc">
          {hasAlert && (
            <Indicator smallIndicator indicatorClassName="absolute" indicatorStyle={{ left: 16, top: 13 }} />
          )}
          <span>{title}</span>
        </div>
        {asideComponent && asideComponent()}
      </a>
    </div>
  );
};

const Checked: React.FC = () => (
  <span className="ui__wf__tick absolute ba br-100 ui__subject-selector__indicator ui__subject-selector__indicator__complete b--light-green bg-light-green white">
    <Icon icon="tick" width="12" height="12" viewBox="0 0 20 20" className="white percentCenter" />
  </span>
);

export default AnimalSelectPanel;
