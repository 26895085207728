// @ts-nocheck: converted from JS

const INITIAL_STATE = {
  currentCollection: null,
  currentStudy: null,
};

const applySetCurrentCollection = (state, action) => ({
  ...state,
  currentCollection: action.collection,
});

const applySetForCurrentStudy = (state, action) => ({
  ...state,
  currentStudy: action.collections,
});

const applyResetForCurrentStudy = (state, action) => ({
  ...state,
  currentStudy: null,
});

function collectionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'COLLECTIONS_SET_FOR_CURRENT_STUDY': {
      return applySetForCurrentStudy(state, action);
    }
    case 'COLLECTIONS_SET_CURRENT_COLLECTION': {
      return applySetCurrentCollection(state, action);
    }
    case 'COLLECTIONS_RESET_FOR_CURRENT_STUDY': {
      return applyResetForCurrentStudy(state, action);
    }
    default:
      return state;
  }
}

export default collectionsReducer;
