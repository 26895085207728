import type {
  DataTableCellAlertCache,
  DataTableCellIdCoordinate,
  DataTableCellReference,
} from '@/components/UI/DataTable/DataTable.model';
import { toCellRef } from '@/components/UI/DataTable/DataTable.util';
import { _notNil } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { ID } from '@/model/Common.model';

export class DataTableCellAlertService {
  readonly #cellAlerts = new Map<DataTableCellReference, DataTableCellAlertCache>();
  readonly #alertCells = new Map<ID, DataTableCellReference>();

  ingest(coordinate: DataTableCellIdCoordinate, alerts: Array<AnimalAlertV1>): DataTableCellAlertCache {
    const ref = toCellRef(coordinate);
    const cellAlertCache: DataTableCellAlertCache = this.#cellAlerts.get(ref) ?? {
      ...coordinate,
      alerts: new Map<ID, AnimalAlertV1>(),
    };
    alerts.forEach((alert) => {
      if (DataTableCellAlertService.#isResolved(alert)) {
        cellAlertCache.alerts.delete(alert.id);
        this.#alertCells.delete(alert.id);
      } else {
        cellAlertCache.alerts.set(alert.id, alert);
        this.#alertCells.set(alert.id, ref);
      }
    });
    if (cellAlertCache.alerts.size > 0) {
      this.#cellAlerts.set(ref, cellAlertCache);
    } else {
      this.#cellAlerts.delete(ref);
    }
    return { ...coordinate, alerts: cellAlertCache.alerts };
  }

  resolve(alerts: Array<AnimalAlertV1>): Record<DataTableCellReference, DataTableCellAlertCache> {
    return alerts.reduce((acc, alert) => {
      if (this.#alertCells.has(alert.id)) {
        const ref = this.#alertCells.get(alert.id) as DataTableCellReference;
        this.#alertCells.delete(alert.id);
        if (this.#cellAlerts.has(ref)) {
          const cellAlertCache = this.#cellAlerts.get(ref) as DataTableCellAlertCache;
          cellAlertCache.alerts.delete(alert.id);
          if (cellAlertCache.alerts.size > 0) {
            this.#cellAlerts.set(ref, cellAlertCache);
          } else {
            this.#cellAlerts.delete(ref);
          }
          return { ...acc, [ref]: cellAlertCache };
        }
      }
      return acc;
    }, {});
  }

  get(coordinate: DataTableCellIdCoordinate): DataTableCellAlertCache | undefined {
    return this.#cellAlerts.get(toCellRef(coordinate));
  }

  delete(coordinate: DataTableCellIdCoordinate) {
    const ref = toCellRef(coordinate);
    const cellAlertCache = this.#cellAlerts.get(ref);
    cellAlertCache?.alerts?.forEach((alert) => {
      this.#alertCells.delete(alert.id);
    });
    this.#cellAlerts.delete(ref);
  }

  static #isResolved(alert: AnimalAlertV1) {
    return _notNil(alert.resolved_at);
  }
}
