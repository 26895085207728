import type { CSSProperties } from 'react';

interface DropdownPosition {
  arrowOrientation: {
    x: 'left' | 'right' | 'center';
    y: 'top' | 'bottom';
  };
  styles: CSSProperties;
}

export const EMPTY_RECT: DOMRectReadOnly = {
  height: 0,
  width: 0,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  x: 0,
  y: 0,
  toJSON: () => {},
};

export const getMenuPosition = ({
  toggleElementDOMRect = EMPTY_RECT,
  dropdownElementDOMRect = EMPTY_RECT,
  window,
}: {
  toggleElementDOMRect: DOMRectReadOnly;
  window: Window;
  dropdownElementDOMRect: DOMRectReadOnly;
}): DropdownPosition => {
  const result: DropdownPosition = {
    styles: {
      position: 'absolute',
      zIndex: '9999',
    },
    arrowOrientation: { x: 'center', y: 'bottom' },
  };

  const {
    left: toggleLeft,
    right: toggleRight,
    top: toggleTop,
    bottom: toggleBottom,
    width: toggleWidth,
  } = toggleElementDOMRect;

  const { height: dropdownHeight, width: dropdownWidth } = dropdownElementDOMRect ?? EMPTY_RECT;

  const windowXBleed = toggleWidth - dropdownWidth / 2;
  const spaceAbove = toggleTop > dropdownHeight;
  const spaceBelow = window.innerHeight - toggleBottom > dropdownHeight;
  const spaceLeft = toggleLeft > windowXBleed;
  const spaceRight = window.innerWidth - toggleRight > dropdownWidth;
  const arrowOffset = 12;

  if (spaceAbove && !spaceBelow) {
    result.styles.top = toggleTop - dropdownHeight - arrowOffset;
  } else {
    result.styles.top = toggleBottom + arrowOffset;
    result.arrowOrientation.y = 'top';
  }

  if (spaceLeft && !spaceRight) {
    result.styles.left = toggleRight - dropdownWidth;
    result.arrowOrientation.x = 'right';
  } else if (spaceRight && !spaceLeft) {
    result.arrowOrientation.x = 'left';
    result.styles.left = toggleLeft;
  } else {
    result.styles.left = toggleLeft + toggleWidth / 2 - dropdownWidth / 2;
  }

  return result;
};
