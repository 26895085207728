// @ts-nocheck: converted from JS

import OptionsContainer from '@/components/FormBuilder/SelectBuilder/OptionsContainer';
import { hasOptionValues } from '@/components/FormBuilder/SelectBuilder/SelectBuilder.utils';
import SelectBuilderLabelInput from '@/components/FormBuilder/SelectBuilder/SelectBuilderLabelInput';
import SelectBuilderOption from '@/components/FormBuilder/SelectBuilder/SelectBuilderOption';
import List, { asListItem } from '@/components/UI/List';
import { ErrorMessage } from '@hookform/error-message';
import './SelectBuilder.scss';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, useFormContext } from 'react-hook-form';

const SelectBuilder = ({ names, defaultValue }) => {
  const { control, getValues, errors } = useFormContext();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleIsExpanded = () => setIsExpanded((prevState) => !prevState);

  const handleSelectOptionChange = (value, index, onChange) => {
    const formValue = getValues(names.options);
    const newFieldOptions = [...formValue];
    newFieldOptions[index].label = value;
    newFieldOptions[index].value = value;
    onChange(newFieldOptions);
  };

  const onAddOption = (onChange) => {
    const formValue = getValues(names.options);
    onChange([
      ...formValue,
      {
        label: '',
        value: '',
      },
    ]);
  };

  const onRemoveOption = (indexToRemove, onChange) => {
    const formValue = getValues(names.options);
    const newFieldOptions = [...formValue];
    newFieldOptions.splice(indexToRemove, 1);
    onChange(newFieldOptions);
  };

  return (
    <div className="flex flex-column" data-test-component="SelectBuilder" data-test-element="container">
      <Controller
        defaultValue={defaultValue.label || ''}
        name={names.label}
        className="w5 ui__select f6"
        classNamePrefix="ui__select"
        rules={{
          required: 'A label is required',
        }}
        control={control}
        render={({ onChange, value }) => (
          <SelectBuilderLabelInput
            onChange={(e) => onChange(e.target.value)}
            value={value}
            isExpanded={isExpanded}
            toggleIsExpanded={toggleIsExpanded}
          />
        )}
      />

      <Controller
        defaultValue={
          defaultValue.options || [
            {
              label: '',
              value: '',
            },
          ]
        }
        name={names.options}
        className="w5 ui__select f6"
        classNamePrefix="ui__select"
        rules={{
          required: 'Options are required',
          validate: {
            hasOptionValues,
          },
        }}
        control={control}
        render={({ onChange, value }) => (
          <div className="ml3 mb2" data-test-element="select-builder-option-list-container">
            <List
              canAmend
              canAdd
              canRemove={value?.length > 1}
              addButtonProps={{
                style: { marginLeft: '2rem' },
              }}
              data={value}
              itemsContainerProps={{
                className: 'ui__repeater__children',
              }}
              itemProps={{
                className: 'ui__repeater__child',
              }}
              ItemsContainerType={OptionsContainer}
              containerProps={{
                className: isExpanded ? '' : 'dn',
              }}
              renderItem={asListItem(SelectBuilderOption, {
                onChange: (value, index) => handleSelectOptionChange(value, index, onChange),
              })}
              onAddClick={() => onAddOption(onChange)}
              onRemoveClick={(index) => onRemoveOption(index, onChange)}
              addButtonText={'Add option'}
              isRemoveButtonFluid={false}
            />
          </div>
        )}
      />
      {Object.values(names).map((name) => (
        <ErrorMessage
          key={name}
          errors={errors}
          name={name}
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      ))}
    </div>
  );
};

export default SelectBuilder;
