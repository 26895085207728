import { Icon as IconType, Icons } from '@/constants/Icons';
import type { CSSProperties, FC, SVGAttributes } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface IconPropsBase {
  icon: IconType;
  style?: CSSProperties;
  circleBorder?: boolean;
  tooltip?: string;
  className?: string;
  testId?: string;
}

export type IconProps = IconPropsBase &
  Pick<SVGAttributes<HTMLOrSVGElement>, 'width' | 'height' | 'viewBox' | 'onClick'>;

const Icon: FC<IconProps> = ({
  icon,
  style,
  circleBorder,
  tooltip,
  className,
  testId,
  width,
  height,
  viewBox,
  onClick,
}) => {
  let styles = style;
  if (circleBorder) {
    styles = {
      ...styles,
      padding: '2px',
      border: '2px #999 solid',
      borderRadius: '11px',
    };
  }

  return (
    <>
      {tooltip && <ReactTooltip float={false} className="f6 lh-copy pa2 mw5 tc" />}
      <svg
        style={styles}
        className={`icon svg-tooltip-icon ${className}`}
        width={width}
        height={height}
        viewBox={viewBox || '0 0 32 32'}
        data-tooltip-id="global-tooltip-id"
        data-tooltip-content={tooltip}
        data-testid={testId}
        onClick={onClick}
      >
        <path d={Icons?.[icon]} />
      </svg>
    </>
  );
};

export default Icon;
