import { EndpointParameters } from '@/generated/internal-api/LegacyApiRoutes.ts';
import InVivoError from '@/model/InVivoError.ts';
import {
  LegacyApiErrorResponse,
  LegacyApiResponse,
  LegacyApiService,
  LegacyApiServiceProps,
} from '@/support/LegacyApiService.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { useEffect, useState } from 'react';
import { notAborted, useAbortController } from '../fetch/useAbortController';
import useMountedState from '../fetch/useMountedState';

export interface UseLegacyApiHookProps<ApiRoute extends keyof EndpointParameters>
  extends LegacyApiServiceProps<ApiRoute> {
  options?: LegacyApiServiceProps<ApiRoute>['options'] & { invokeOnInit?: boolean };
}

export interface UseLegacyApiHookOutput<ApiRoute extends keyof EndpointParameters> {
  loading: boolean;
  response?: LegacyApiResponse;
  invoke: (props?: Omit<UseLegacyApiHookProps<ApiRoute>, 'apiRoute' | 'method'>) => Promise<LegacyApiResponse>;
  abort: (reason?: string) => void;
}

export const useLegacyApiHook = <ApiRoute extends keyof EndpointParameters>(
  props: UseLegacyApiHookProps<ApiRoute>
): UseLegacyApiHookOutput<ApiRoute> => {
  const [apiLoading, setApiLoading] = useState(props?.options?.invokeOnInit ?? true);
  const [apiResponse, setApiResponse] = useState<LegacyApiResponse<any>>();
  const isMounted = useMountedState();
  const { abort } = useAbortController();

  useEffect(() => {
    if (props?.options?.invokeOnInit ?? true) {
      invoke(props).catch((error) =>
        ExceptionHandler.captureException(
          new InVivoError(`Api Error (Api Hook): ${props?.path}`, {
            cause: error,
            slug: 'use-api-hook',
          })
        )
      );
    }
  }, []);

  const invoke: UseLegacyApiHookOutput<ApiRoute>['invoke'] = async (invokeProps) => {
    const { method, apiRoute } = props;
    const options = { ...(invokeProps?.options ?? props?.options ?? {}) };
    delete options['invokeOnInit'];
    setApiLoading(true);
    try {
      const result = await LegacyApiService.call<ApiRoute>({
        method,
        apiRoute,
        path: invokeProps?.path ?? props.path,
        query: invokeProps?.query ?? props.query,
        body: invokeProps?.body ?? props.body,
        options,
      });
      if (isMounted()) {
        setApiLoading(false);
        setApiResponse(result);
      }
      return result;
    } catch (error) {
      const errorResponse = error as LegacyApiErrorResponse;
      if (isMounted()) {
        if (notAborted(errorResponse.error)) {
          setApiResponse(errorResponse);
        }
        setApiLoading(false);
      }
      return Promise.reject(errorResponse);
    }
  };

  return {
    loading: apiLoading,
    response: apiResponse,
    invoke,
    abort,
  };
};
