// @ts-nocheck: converted from JS

import { isActive } from '@/components/Glossary/Sections/Approvals/TemplateForm/Approvers.utils';
import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { missingNameUseEmail } from '@/helpers';
import { useRequest } from '@/support/Hooks/request';
import { useMemo, useState } from 'react';
import { api as apiRoute } from '@/support/route';
import ApiErrorBanner from '@/components/ApiErrorBanner';

const AddAdditionalReviewers = ({ approval, team, approvalRequestId, fetchApproval, closeModal }) => {
  const [selected, setSelected] = useState([]);

  const {
    sendRequest: addRequiredApprover,
    requestSending: addingRequiredApprover,
    requestError: requiredApproverError,
  } = useRequest({
    route: apiRoute('approvals.approvers.add', {
      approvalRequestId,
    }),
  });

  const saveAdditionalReviewers = async () => {
    await addRequiredApprover({
      required_ids: selected,
    });
    if (!requiredApproverError) {
      closeModal();
      await fetchApproval();
    }
  };

  const usersAlreadyAnApprover = [
    ...(approval.approval.approvers.required_ids || []),
    ...(approval.approval.approvers.ids || []),
  ];

  const sections = useMemo(() => {
    const canSelectUser = (user) => isActive(user) && !usersAlreadyAnApprover.includes(user.id);
    return [
      {
        items: team?.users?.filter(canSelectUser).map(missingNameUseEmail) || [],
      },
    ];
  }, [team]);

  return (
    <div className="center mw6 ui-card">
      <div className="pa3 bb b--moon-gray">
        <h3 className="normal lh-title f4 mb0">Add other reviewers</h3>
      </div>
      {requiredApproverError && (
        <ApiErrorBanner
          className="mb4 mw6"
          title="There was an error adding reviewers"
          text="An error has occurred when adding reviewers, please try again later. If this keeps occurring please contact support."
          error={requiredApproverError}
        />
      )}
      <SearchSelect sections={sections} selected={selected} setSelected={setSelected} sort sortAccessor={'name'} />
      <div className="pa3 bt b--moon-gray">
        <Button
          disabled={addingRequiredApprover}
          onClick={saveAdditionalReviewers}
          testId="save-button"
          className="mr3"
        >
          Save
        </Button>
        <Button plain onClick={closeModal} testId="cancel-button">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddAdditionalReviewers;
