// @ts-nocheck: converted from JS

import CageCardTemplate from '@/components/Modals/PrintCageCards/4x2/CageCardTemplate';
import { trunc } from '@/helpers';
import { safelyDecodeURIComponent } from '@/littledash';
import React from 'react';

class CageCardsTemplate extends React.Component {
  renderMetadataValue = function (value) {
    if (value) {
      if (Array.isArray(value)) {
        return trunc(value.map(safelyDecodeURIComponent).join(', '), 70);
      }
      return trunc(safelyDecodeURIComponent(value), 70);
    }
    return '';
  };

  render() {
    const { selectedMetadata, cages, study, maxFields } = this.props;
    return (
      <div
        className="flex flex-wrap black"
        style={{
          marginLeft: '12px',
          marginTop: '36px',
        }}
      >
        {cages.map((cage) => (
          <div key={cage.id} style={{ marginBottom: 12, marginRight: 12 }}>
            <CageCardTemplate study={study} cage={cage} selectedMetadata={selectedMetadata} maxFields={maxFields} />
          </div>
        ))}
      </div>
    );
  }
}

export default CageCardsTemplate;
