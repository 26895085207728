import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Tooltip from '@/components/UI/Tooltip';
import { _isNil, _notNil } from '@/littledash';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import type { ChangeEventHandler, FC } from 'react';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import type {
  DataTableCellComponentProps,
  DataTableCellData,
  DataTableCellUpdateEvent,
  DataTableDirection,
} from '../DataTable.model';
import {
  DataTableCellSelectionChangeEvent,
  DataTableCellSelectionState,
  DataTableCellState,
  DataTableCellStateChangeEvent,
  DataTableCellStatusType,
  DataTableEvent,
} from '../DataTable.model';
import styles from '../DataTable.module.scss';
import { toCellRef } from '../DataTable.util';

class EventListenerAbortController {
  static #abortController?: AbortController;

  static abort(reason: string): void {
    this.#abortController?.abort(reason);
  }

  static signal(): AbortSignal {
    this.abort('new controller requested');
    const abortController = new AbortController();
    this.#abortController = abortController;
    return abortController.signal;
  }
}

export const DataTableTimestampCell: FC<DataTableCellComponentProps> = ({
  coordinate,
  dataTableService,
  positionOffset,
}) => {
  const [cellData, setCellData] = useState<DataTableCellData>();
  const idCoordinate = useMemo(() => dataTableService.fromIndexCoordinate(coordinate), [coordinate, dataTableService]);
  const datetimeInputRef = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const [cellState, setCellState] = useState<DataTableCellState>(dataTableService.cellState(idCoordinate));
  const [tooltip, setTooltip] = useState<string | undefined>();
  const [selected, setCellSelected] = useState<DataTableCellSelectionState>(DataTableCellSelectionState.notSelected);
  const { cellHeight, cellWidth } = dataTableService.dimensions;
  const [cellValueOverride, setCellValueOverride] = useState<string | null>(null);

  const persistInputValue = (moveAfterPersistDirection?: DataTableDirection) => {
    setActive(() => false);
    setCellValueOverride(() => null);
    EventListenerAbortController.abort('input value persisted');
    if (_notNil(idCoordinate) && _notNil(datetimeInputRef.current)) {
      const persistValue = DateInputUtils.localDateTimeToISODateTime(datetimeInputRef.current.value);
      if (persistValue === '' && _notNil(cellData?.value)) {
        dataTableService
          .updateCells([
            {
              column_id: idCoordinate.column,
              row_id: idCoordinate.row,
              value: null,
            },
          ])
          .then(() => {
            dataTableService.validate();
          });
      } else {
        if (persistValue !== cellData?.value) {
          dataTableService
            .updateCells([
              {
                column_id: idCoordinate.column,
                row_id: idCoordinate.row,
                value: persistValue,
              },
            ])
            .then(() => dataTableService.validate());
        }
      }
      if (
        !dataTableService.workflowService.workflowActionExit(idCoordinate.column) &&
        _notNil(moveAfterPersistDirection)
      ) {
        dataTableService.moveSelection(moveAfterPersistDirection);
      }
    }
  };

  const activatePicker = () => {
    if (!dataTableService.readonly) {
      const isoDateTime = DateUtils.isValidDateTime(cellData?.value)
        ? (cellData?.value ?? DateUtils.dateTimeNow())
        : DateUtils.dateTimeNow();
      if (_notNil(datetimeInputRef.current)) {
        setActive(true);
        datetimeInputRef.current.value = DateInputUtils.toLocalDateTime(isoDateTime);
        const signal = EventListenerAbortController.signal();
        datetimeInputRef.current.addEventListener('click', () => persistInputValue(), {
          once: true,
          capture: true,
          signal,
        });
        datetimeInputRef.current.addEventListener('blur', () => persistInputValue(), {
          once: true,
          capture: true,
          signal,
        });

        datetimeInputRef.current.addEventListener(
          'keyup',
          (event) => {
            switch (event.code) {
              case 'Enter': {
                persistInputValue(event.shiftKey ? 'UP' : 'DOWN');
                break;
              }
              case 'Escape': {
                persistInputValue();
                break;
              }
            }
          },
          {
            once: true,
            capture: true,
            signal,
          }
        );

        window.requestAnimationFrame(() => {
          datetimeInputRef?.current?.showPicker();
          datetimeInputRef?.current?.focus();
        });
      }
    }
  };

  useEffect(() => {
    if (_notNil(idCoordinate)) {
      const abortController = new AbortController();
      const ref = toCellRef(idCoordinate);
      setCellData(dataTableService.cellData(idCoordinate));

      const cellUpdateListener = (event: DataTableCellUpdateEvent) => {
        if (Object.prototype.hasOwnProperty.call(event.detail, ref)) {
          setCellData(event.detail[ref]);
          setCellState(dataTableService.cellState(idCoordinate));
        }
      };

      const cellStateChangeListener = (event: DataTableCellStateChangeEvent) => {
        if (_notNil(idCoordinate)) {
          setCellState((previousCellState) => {
            if (event.detail.invalidCells.has(ref) || cellState !== 'valid') {
              const updatedCellState = dataTableService.cellState(idCoordinate);
              if (updatedCellState === 'invalid' || updatedCellState === 'warning') {
                const cellStatus = dataTableService.cellStatus(idCoordinate);
                switch (cellStatus.type) {
                  case DataTableCellStatusType.NetworkError:
                    setTooltip('could not save');
                    break;
                  case DataTableCellStatusType.ValidationError:
                  case DataTableCellStatusType.ValidationWarning:
                    setTooltip(cellStatus.error);
                    break;
                  default:
                    setTooltip(undefined);
                }
              } else {
                setTooltip(undefined);
              }
              return updatedCellState;
            }
            return previousCellState;
          });
        }
      };

      dataTableService.subscribe(DataTableEvent.CellUpdate, cellUpdateListener, abortController.signal);
      dataTableService.subscribe(DataTableEvent.CellStateChange, cellStateChangeListener, abortController.signal);

      return () => {
        abortController.abort();
      };
    }
  }, [dataTableService, idCoordinate]);

  useEffect(() => {
    if (_notNil(idCoordinate)) {
      if (dataTableService.selection?.contains(coordinate) ?? false) {
        setCellSelected(
          dataTableService.selection?.from.column === coordinate.column &&
            dataTableService.selection?.from.row === coordinate.row
            ? DataTableCellSelectionState.originSelected
            : DataTableCellSelectionState.highlighted
        );
      } else {
        setCellSelected(DataTableCellSelectionState.notSelected);
      }

      const cellSelectionChangeListener = (event: DataTableCellSelectionChangeEvent) => {
        const { from } = event.detail.selection;
        if (event.detail.selection?.contains(coordinate)) {
          setCellSelected(
            from.column === coordinate.column && from.row === coordinate.row
              ? DataTableCellSelectionState.originSelected
              : DataTableCellSelectionState.highlighted
          );
        } else {
          setCellSelected(DataTableCellSelectionState.notSelected);
        }

        if (event.detail.activate && from.column === coordinate.column && from.row === coordinate.row) {
          if (_notNil(idCoordinate) && _isNil(event.detail.content)) {
            window.requestAnimationFrame(() => {
              dataTableService
                .updateCells([
                  {
                    column_id: idCoordinate.column,
                    row_id: idCoordinate.row,
                    value: new Date().toISOString(),
                  },
                ])
                .then(() => dataTableService.validate());
              if (!dataTableService.workflowService.workflowActionExit(idCoordinate.column)) {
                dataTableService.moveSelection(event.detail.options?.direction ?? 'DOWN');
              }
            });
          }
        }
      };

      dataTableService.subscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);

      return () => {
        dataTableService.unsubscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
      };
    }
  }, [dataTableService, idCoordinate, cellData]);

  const handleMouseDown: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: event.shiftKey });
    }
  };
  const handleMouseEnter: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: true });
    }
  };
  const handleMouseUp: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: true });
    }
  };
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = DateInputUtils.localDateTimeToISODateTime(event?.target?.value ?? '');
    setCellValueOverride(value === '' ? null : value);
  };
  const cellValue = cellValueOverride ?? cellData?.value;

  return (
    <div
      className={styles['data-table-cell-container']}
      style={{
        position: 'absolute',
        top: positionOffset.y,
        left: positionOffset.x,
        zIndex: active ? 1 : 0,
        overflow: active ? 'visible' : 'hidden',
        width: `${cellWidth}px`,
        height: `${cellHeight}px`,
      }}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseUp={handleMouseUp}
      onDoubleClick={activatePicker}
    >
      {cellState === 'loading' ? (
        <div className={`${styles['data-table-cell']} ${styles['loading']}`} />
      ) : (
        <Tooltip render={tooltip}>
          <div
            className={`${styles['data-table-cell']} ${styles[selected]} ${active ? styles['active'] : ''} ${
              styles[cellState]
            }`}
            data-state={cellState}
          >
            <span className={styles['data-table-timestamp-value']}>
              {_isNil(cellValue) ? (
                <>Add Timestamp</>
              ) : (
                <DateTimeRenderer value={cellValue} defaultResponse={cellValue} />
              )}
            </span>
            <input
              className={styles['data-table-timestamp-input']}
              style={{ visibility: active ? 'visible' : 'hidden' }}
              ref={datetimeInputRef}
              type="datetime-local"
              onChange={handleInputChange}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
