import { DateTimeEditor } from '@/components/UI/SpreadSheet/editors/DateTimeEditor';
import type { SpreadSheetColumn } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { capitalise, renderMetadataValue } from '@/helpers';
import { _isNotEmpty, _notNil, safelyParseJson } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { BulkSamplesFormData, SampleData, SampleDetails } from '@/model/Sample.model';
import { defaultMetadataToColumnSettingMapper, formatColumnSettingsDataValue } from '@/support/hot';

const getSampleMeta = (meta: Record<string, any>, metadata: Array<MetadataField>) =>
  Object.entries(meta).reduce<Record<string, string>>((acc, [metaSlug, value]) => {
    const glossaryMeta = metadata.find(({ slug }) => slug === metaSlug);
    if (_notNil(glossaryMeta)) {
      if (glossaryMeta.field_type === 'date') {
        acc[formatColumnSettingsDataValue(glossaryMeta.title)] = value;
      } else if (Array.isArray(value)) {
        acc[formatColumnSettingsDataValue(glossaryMeta.title)] = value.toString();
      } else if (Array.isArray(safelyParseJson(value))) {
        acc[formatColumnSettingsDataValue(glossaryMeta.title)] = JSON.parse(value).join(',');
      } else {
        acc[formatColumnSettingsDataValue(glossaryMeta.title)] = renderMetadataValue({
          value,
          field_type: glossaryMeta.field_type,
        });
      }
    }
    return acc;
  }, {});

const getSampleDetails = (details: SampleDetails) =>
  Object.entries(details).reduce<Record<string, string>>((acc, [detail, value]) => {
    acc[detail] = value.value;
    return acc;
  }, {});

export const generateSampleData = (
  subjects: Array<Animal>,
  metadata: Array<MetadataField>,
  sampleDetails: BulkSamplesFormData
) =>
  subjects.reduce<Array<SampleData>>((acc, { id: subject_id, cage, name }) => {
    const { sample_type, date, comments, details, meta = {} } = sampleDetails;

    const sample: SampleData = {
      cageName: cage?.name,
      subject_id,
      subjectName: name ?? '',
      sampleType: sample_type.title,
      date,
      comments,
      meta: _isNotEmpty(meta) ? getSampleMeta(meta, metadata) : {},
      details: getSampleDetails(details ?? {}),
    };

    acc.push(sample);
    return acc;
  }, []);

export const generateColumns = (sampleDetails: BulkSamplesFormData, metadata: Array<MetadataField>) => {
  const columns: Array<SpreadSheetColumn> = [
    {
      data: 'cageName',
      readOnly: true,
      type: 'text',
      value: 'Cage',
      width: 150,
    },
    {
      data: 'subjectName',
      readOnly: true,
      type: 'text',
      value: 'Animal name',
      width: 150,
    },
    {
      data: 'sampleType',
      readOnly: true,
      type: 'text',
      value: 'Sample type',
      width: 150,
    },
    {
      data: 'date',
      readOnly: false,
      type: DateTimeEditor.type,
      value: 'Date taken',
      width: 200,
    },
  ];

  const colHeaders = ['Cage', 'Animal name', 'Sample type', 'Date taken'];

  Object.entries(sampleDetails.details ?? []).forEach(([detail, value]) => {
    columns.push({
      data: `details.${detail}`,
      readOnly: false,
      type: 'numeric',
      value: `${capitalise(detail)} (${value.unit})`,
      width: 150,
    });
    colHeaders.push(`${capitalise(detail)} (${value.unit})`);
  });

  colHeaders.push('Comments');
  columns.push({
    data: 'comments',
    readOnly: false,
    type: 'text',
    value: 'Comments',
    width: 150,
  });

  if (_isNotEmpty(metadata)) {
    metadata.forEach((meta) => {
      const { columnHeader, columnSettings } = defaultMetadataToColumnSettingMapper(meta);
      colHeaders.push(columnHeader);
      columns.push(columnSettings);
    });
  }

  return { colHeaders, columns };
};
