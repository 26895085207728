import ApiErrorBanner from '@/components/ApiErrorBanner';
import Comments from '@/components/UI/Comments';
import type { RequestComment } from '@/model/Request.model';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useState } from 'react';

interface RequestCommentsProps {
  comments: Array<RequestComment>;
  setComments: (comments: Array<RequestComment>) => void;
  requestId: string;
}

const RequestComments = ({ comments, setComments, requestId }: RequestCommentsProps) => {
  const [error, setError] = useState<Error>();

  const onAddComment = async (body: string) => {
    try {
      const {
        data: { data },
      } = await Http.post<{ data: RequestComment }>(
        apiRoute('requests.comments', {
          requestId,
        }),
        {
          body,
        }
      );
      setComments([data, ...comments]);
    } catch (error) {
      setError(error as Error);
    }
  };
  return (
    <div className="mw6">
      {error ? (
        <ApiErrorBanner
          className="mb3"
          title="There was an error fetching comments"
          text="Please try again later. If this keeps occurring please contact support."
          error={error}
        />
      ) : (
        <Comments comments={comments} onAddComment={onAddComment} canAddComment />
      )}
    </div>
  );
};

export default RequestComments;
