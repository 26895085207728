import Banner from '@/components/UI/Banner/index';
import type { FC } from 'react';

interface MoveToStudyHeaderProps {
  cageCount: number;
}

const MoveToStudyHeader: FC<MoveToStudyHeaderProps> = ({ cageCount }) => {
  return (
    <div>
      <div className="pa3">
        <h3 className="basier-med normal f4 lh-title near-black">
          Move {cageCount} cage{cageCount > 1 ? 's' : ''} to study
        </h3>
      </div>
      <div className="ml3 mb2">
        <span>
          <p className="f6 lh-copy ma0">
            Studies automatically includes any additional animals in chosen cages while excluding those deceased.
          </p>
          <a
            href="https://help.benchling.com/hc/en-us/articles/19900024203661-Moving-Cages-of-Animals-between-Studies"
            target="_blank"
            rel="noopener noreferrer"
            className="dib link blue f6 lh-copy"
          >
            Read more
          </a>
        </span>
      </div>
      <Banner info className="ml3 mr3 mb2" dismiss={true} center>
        <h3 className="f6 normal lh-title">
          <p>
            <span className="mr2 fw7">All groups, doses, alerts or tracking dates will be removed.</span>
            <span>Measurements, samples and observations will remain intact.</span>
          </p>
        </h3>
      </Banner>
    </div>
  );
};

export default MoveToStudyHeader;
