// @ts-nocheck: converted from JS

import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useEffect, useState } from 'react';

const useReviewTemplates = () => {
  const [reviewTemplates, setReviewTemplates] = useState(undefined);

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await Http.get(apiRoute('approvals.templates.list.all'));
      if (data) {
        setReviewTemplates(data);
      }
    })();
  }, []);

  return { reviewTemplates };
};

export default useReviewTemplates;
