import { StatusFilter } from '@/components/Tasks/Calendar/Filters/StatusFilter.tsx';
import DayList from '@/components/Tasks/Calendar/Views/Day/DayList.tsx';
import WeekLanes from '@/components/Tasks/Calendar/Views/Week/WeekLanes.tsx';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import { ISODateTime } from '@/model/Common.model.ts';
import useColors from '@/support/Hooks/useColors/useColors';
import { FC, useReducer } from 'react';
import { type CalendarFilters, CalendarView } from './Calendar.model';
import styles from './Calendar.module.scss';
import { dayRange, initialState, reducer, weekRange } from './Calendar.utils';
import CalendarNav from './CalendarNav';
import CalendarSidebar from './CalendarSidebar';
import StudyFilter from './Filters/StudyFilter';
import UserFilter from './Filters/UserFilter';
import MonthGrid from './Views/Month/MonthGrid';

const CountChip: FC<{ count?: number }> = ({ count }) =>
  (count ?? 0) > 0 ? <span className={styles.countChip}>{count}</span> : null;

const Calendar: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState());
  const {
    filters,
    selectedDate,
    yearAndMonth: [year, month],
    span,
  } = state;
  const { itemColors: studyColors } = useColors({ items: filters.study_api_id });

  const handleFilterChange = (filters: CalendarFilters): void => {
    dispatch({ type: 'SET_FILTERS', data: filters });
  };
  const handleSelectDay = (day: ISODateTime): void => {
    dispatch({ type: 'SET_CHOSEN_DAY', data: day });
  };
  const handleSelectYearAndMonth = ([newYear, newMonth]: Array<number>): void => {
    dispatch({ type: 'SET_YEAR_AND_MONTH', data: [newYear, newMonth] });
  };
  const handleSelectSpan = (span: CalendarView): void => {
    dispatch({ type: 'SET_SPAN', data: span });
  };
  const handleGoToDay = (date: ISODateTime): void => {
    dispatch({ type: 'GO_TO_DAY', data: date });
  };

  return (
    <div className="flex h-100" data-test-component="Calendar" data-test-element="container">
      <CalendarSidebar>
        <Accordion className="ml1 pa1">
          <AccordionItem isOpen>
            <AccordionHeader testId="studies-filter-header">
              <div className="flex flex-row justify-between items-center">
                <span>Studies</span>
                <CountChip count={filters.study_api_id.length} />
              </div>
            </AccordionHeader>
            <AccordionPanel>
              <StudyFilter filters={filters} onFilterChange={handleFilterChange} studyColors={studyColors} />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader testId="users-filter-header">
              <div className="flex flex-row justify-between items-center">
                <span>Users</span>
                <CountChip count={filters.assignee.length} />
              </div>
            </AccordionHeader>
            <AccordionPanel>
              <UserFilter filters={filters} onFilterChange={handleFilterChange} />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader testId="status-filter-header">
              <div className="flex flex-row justify-between items-center">
                <span>Task status</span>
                <CountChip count={filters.status.length} />
              </div>
            </AccordionHeader>
            <AccordionPanel>
              <StatusFilter filters={filters} onFilterChange={handleFilterChange} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </CalendarSidebar>
      <div className="flex flex-column w-100 h-100 overflow-hidden">
        <CalendarNav
          selectedDate={selectedDate}
          yearAndMonth={[year, month]}
          span={span}
          onSelectDay={handleSelectDay}
          onSelectYearAndMonth={handleSelectYearAndMonth}
          onSelectSpan={handleSelectSpan}
        />
        <div className={`${styles['calendar-view-container']} bg-white h-100`}>
          {span === 'Month' ? (
            <MonthGrid
              filters={filters}
              studyColours={studyColors}
              year={year}
              month={month}
              handleGoToDay={handleGoToDay}
            />
          ) : span === 'Week' ? (
            <WeekLanes
              dateRange={weekRange(selectedDate)}
              filters={filters}
              studyColours={studyColors}
              handleGoToDay={handleGoToDay}
            />
          ) : (
            <DayList dateRange={dayRange(selectedDate)} filters={filters} studyColours={studyColors} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
