import NoDataCard from '@/components/NoDataCard';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import Table from '@/components/UI/Table';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { _isNotEmpty } from '@/littledash';
import type { Approval } from '@/model/Approval.model';
import type { Study } from '@/model/Study.model';
import { statusToTextMap } from '@/referenceData/study/status';
import * as Auth from '@/support/auth';
import { web as webRoute } from '@/support/route';

interface ApprovalsProps {
  approvals?: Array<Approval>;
  study: Study;
}

const Approvals: React.FC<ApprovalsProps> = ({ approvals, study }) => {
  const hasWriteAccess = study && Auth.isWriteUserForStudy(study);
  const hasApprovals = _isNotEmpty(approvals);

  const columns: Array<Column<Approval>> = [
    {
      id: 'approval.name',
      Header: 'Name',
      accessor: 'approval.name',
      width: 200,
      Cell: ({
        row: {
          original: {
            outstanding_dependencies,
            approval: { name },
            id: approvalRequestId,
          },
        },
      }) => {
        const hasDependency = _isNotEmpty(outstanding_dependencies);
        return (
          <span
            data-tooltip-id="global-tooltip-id"
            data-tooltip-content={`${hasDependency ? 'Unable to submit until dependency is reviewed' : ''}`}
          >
            {hasWriteAccess ? (
              <Link
                to={webRoute('studies.approvals', { id: study.id, approvalRequestId })}
                className={`link blue ${hasDependency ? 'ui__disabled' : ''}`}
              >
                {name}
              </Link>
            ) : (
              name
            )}
          </span>
        );
      },
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row: { original } }) => statusToTextMap[original.status],
    },
    {
      id: 'outstanding_dependencies',
      Header: 'Review dependency',
      accessor: 'outstanding_dependencies',
      Cell: ({
        row: {
          original: { outstanding_dependencies },
        },
      }) => (_isNotEmpty(outstanding_dependencies) ? outstanding_dependencies.map((dep) => dep.name).join(', ') : '-'),
    },
    {
      id: 'approval.submitted_at',
      Header: 'Submitted At',
      accessor: 'approval.submitted_at',
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.submitted_at} defaultResponse="-" />,
    },
  ];

  return hasApprovals ? (
    <div className="ui-card mb4 overflow-hidden" data-test-component="Approvals" data-test-element="table-container">
      <Table data={approvals} columns={columns} />
    </div>
  ) : (
    <NoDataCard title="There are no reviews required for this study to begin" />
  );
};

export default Approvals;
