// @ts-nocheck: converted from JS

import ExpandButton from '@/components/ExpandButton';
import DebounceInput from '@/components/UI/DebounceInput';

const SelectBuilderLabelInput = ({
  value,
  isExpanded,
  toggleIsExpanded,
  onChange,
  hasErrors,
  placeholder = 'Label',
}) => {
  return (
    <div
      className="flex flex-row select-builder-input"
      data-test-component="SelectBuilderLabelInput"
      data-test-element="container"
      style={{ padding: 0, marginBottom: 0, overflow: 'hidden', border: hasErrors ? '2px solid red' : '' }}
    >
      <DebounceInput
        handleChange={onChange}
        inputProps={{
          value,
          placeholder,
          style: {
            marginBottom: 0,
            border: 'none',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: 'none',
          },
        }}
      />
      <ExpandButton
        isExpanded={isExpanded}
        onClick={toggleIsExpanded}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      />
    </div>
  );
};

export default SelectBuilderLabelInput;
