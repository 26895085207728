import type { FC } from 'react';
import type { AddColumnProps } from './AddColumn.model';
import { CustomTableColumnAdd } from './CustomTableAddColumn';
import { NumericTableColumnAdd } from './NumericTableAddColumn';
import { TimeTableColumnAdd } from './TimeTableAddColumn';

export const AddColumn: FC<AddColumnProps> = (props) => {
  switch (props.tableType) {
    case 'numeric':
      return (
        <NumericTableColumnAdd
          tableUnit={props.tableUnit}
          columns={props.columns}
          handleAddColumn={props.handleAddColumn}
          toggleDropdownMenu={props.toggleDropdownMenu}
        />
      );
    case 'time':
      return (
        <TimeTableColumnAdd
          columns={props.columns}
          handleAddColumn={props.handleAddColumn}
          toggleDropdownMenu={props.toggleDropdownMenu}
        />
      );
    case 'custom':
    case 'unknown':
    default:
      return (
        <CustomTableColumnAdd
          columns={props.columns}
          handleAddColumn={props.handleAddColumn}
          toggleDropdownMenu={props.toggleDropdownMenu}
        />
      );
  }
};
