import type { DataTableRow } from '@/components/UI/DataTable/DataTable.model';
import Fuse from 'fuse.js';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { ChangeEventHandler, FC, KeyboardEventHandler } from 'react';
import { useMemo, useState } from 'react';
import styles from './DataTableRowSearch.module.scss';

export interface DataTableSearchProps {
  rows: Readonly<Array<DataTableRow>>;
  onSelect: (row: DataTableRow) => void;
}

export const DataTableRowSearch: FC<DataTableSearchProps> = ({ rows, onSelect }) => {
  const [search, setSearch] = useState('');
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);

  const fuse = useMemo(() => {
    if (_isNotEmpty(rows)) {
      return new Fuse(rows, {
        includeScore: false,
        threshold: 0.15,
        ignoreLocation: true,
        keys: [
          { name: ['animal', 'name'], weight: 0.2 },
          { name: ['animal', 'alt_ids', 'donor'], weight: 0.2 },
          { name: ['animal', 'alt_ids', 'ear'], weight: 0.2 },
          { name: ['animal', 'alt_ids', 'tag'], weight: 0.2 },
          { name: ['animal', 'alt_ids', 'tail'], weight: 0.2 },
        ],
      });
    }
  }, [rows]);
  const results = useMemo(() => {
    if (_notNil(fuse) && search?.length > 0) {
      return fuse.search(search).map(({ item }) => item);
    }
    return [];
  }, [fuse, search]);
  const updateSelection = (direction: 1 | -1) => {
    setCurrentIndex((current) => {
      if (current === undefined) {
        return direction === 1 ? 0 : results.length - 1;
      }
      const next = current + direction;
      if (next < 0) {
        return results.length - 1;
      } else if (next > results.length - 1) {
        return 0;
      }
      return next;
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'Enter': {
        event.preventDefault();
        const selected = results?.[currentIndex ?? 0];
        if (_notNil(selected)) {
          onSelect(selected);
        }
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        updateSelection(-1);
        break;
      }
      case 'ArrowDown': {
        event.preventDefault();
        updateSelection(1);
        break;
      }
    }
  };
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCurrentIndex(undefined);
    setSearch(event.target.value.trim());
  };

  return (
    <div className={`pa3 ${styles['datatable-table-row-search-container']}`}>
      <div>
        <div className="flex relative">
          <input
            type="text"
            className={styles['search-input']}
            placeholder="Enter Animal ID"
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            autoFocus={true}
            style={{ marginBottom: 0 }}
          />
        </div>
      </div>
      {_isNotEmpty(results) && (
        <div className={styles['results-container']}>
          {results.map((row, rowIndex) => (
            <div
              key={row.id}
              className={styles['result'] + (rowIndex === currentIndex ? ' ' + styles['selected'] : '')}
              onClick={() => onSelect(row)}
            >
              {_notNil(row?.animal.cage) && <div className={styles['cage-name']}>{row?.animal?.cage?.name}</div>}
              <div className={styles['animal-name']}>{row?.animal?.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
