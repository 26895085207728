// @ts-nocheck: converted from JS

import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { api as apiRoutes, web as webRoutes } from '@/support/route';
import { useHistory } from 'react-router-dom';
import { generateColumns } from './Requests.utils';

const Show = () => {
  const history = useHistory();
  const columns = generateColumns();
  const options = [{ value: 'all', name: 'All' }]; // Filter options unknown

  const filterOptions = [
    {
      value: 'name',
      name: columns.find((column) => column.accessor === 'name').Header,
      operations: [
        {
          name: filterType.eq,
          value: filterType.eq,
          options: options,
        },
        {
          name: filterType.ne,
          value: filterType.ne,
          options: options,
        },
      ],
    },
  ];

  const handleCreateNewClick = () => {
    history.push(webRoutes('team.studies.requests.create'));
  };

  return (
    <>
      <Header mainHeaderText="Requests" />
      <div className="mv3" data-test-component="Show" data-test-element="container">
        <APITable
          columns={columns}
          apiInfo={{ type: 'legacyInternalApi', route: apiRoutes('requests.templates.index') }}
          filterOptions={filterOptions}
          searchPlaceholderText="Search"
          AsideComponent={
            <Button onClick={handleCreateNewClick} testId="create-request-button">
              Create request
            </Button>
          }
          NoDataComponent={
            <NoDataCard
              title="Your requests will appear here"
              text="Requests enable users to ask for support from colleagues by submitting relevant information and samples."
              link={webRoutes('team.studies.requests.create')}
              btnTxt="Create a new request"
              dark
            />
          }
        />
      </div>
    </>
  );
};

export default Show;
