// @ts-nocheck: converted from JS

import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useEffect, useState } from 'react';

const useMetadata = () => {
  const [studyMetadataOptions, setStudyMetadataOptions] = useState(undefined);
  const [projectMetadataOptions, setProjectMetadataOptions] = useState(undefined);

  useEffect(() => {
    (async () => {
      const metedataResponse = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'study_meta',
        },
      });
      if (metedataResponse?.data?.data) {
        setStudyMetadataOptions(metedataResponse.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const metedataResponse = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'project_meta',
        },
      });
      if (metedataResponse?.data?.data) {
        setProjectMetadataOptions(metedataResponse.data.data);
      }
    })();
  }, []);

  return { studyMetadataOptions, projectMetadataOptions };
};

export default useMetadata;
