import type { Endpoints } from '@/generated/internal-api/Endpoints';
import { _isNotEmpty, _notNil } from '@/littledash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiResponse, ResponseType } from '@/support/ApiService';

/**
 * @todo - infer api types instead of using `unknown` in `pages`
 */

interface UseApiPaginationProps<Endpoint extends keyof Endpoints> {
  response?: ApiResponse<Endpoint>;
}

interface UseApiPaginationOutput {
  pages: Array<Array<unknown>>;
  nextPage: number;
  hasNextPage: boolean;
  reset: () => void;
}

function _isPageEmpty(pages: Array<Array<unknown>>, pageIndex: number): boolean {
  if (pages?.[pageIndex] && _isNotEmpty(pages[pageIndex])) {
    return false;
  }
  return true;
}

export const useApiPagination = <Endpoint extends keyof Endpoints>({
  response,
}: UseApiPaginationProps<Endpoint>): UseApiPaginationOutput => {
  const [pages, setPages] = useState<Array<Array<unknown>>>([]);
  const [nextPage, setNextPage] = useState(0);

  useEffect(() => {
    // @ts-expect-error: Expecting TS error as not all endpoints have `meta`
    const pagination = response?.body?.meta;
    // @ts-expect-error: Expecting TS error as not all endpoints have `meta`
    const newPages = response?.body?.data;
    if (_notNil(pagination) && _isPageEmpty(pages, Number(pagination?.current_page) - 1) && _notNil(newPages)) {
      setPages([...pages, newPages]);
      setNextPage(pagination.current_page >= pagination.last_page ? pagination.last_page : pagination.current_page + 1);
    }
  }, [response]);

  const hasNextPage = useMemo(() => {
    // @ts-expect-error: Expecting TS error as not all endpoints have `meta`
    const pagination = response?.body?.meta;
    if (_notNil(pagination)) {
      return pagination.last_page > pagination.current_page;
    }
    return false;
  }, [response]);

  const reset = useCallback(() => {
    setPages([]);
    setNextPage(0);
  }, [setPages, setNextPage]);

  if (response?.type !== ResponseType.Success) {
    return {
      pages: [],
      nextPage: 0,
      hasNextPage: false,
      reset,
    };
  }

  return {
    pages,
    nextPage,
    hasNextPage,
    reset,
  };
};
