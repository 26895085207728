import { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import type { MainUser } from '@/model/User.model';
import { modalAction } from '@/utils/modal';

interface AccountCheckerProps {
  currentPath: string;
}

export const AccountChecker: FC<PropsWithChildren<AccountCheckerProps>> = ({ currentPath, children }) => {
  const { currentUser, timezones } = useSelector<State, { currentUser: MainUser; timezones: Array<string> }>(
    ({ user: { currentUser, timezones } }) => ({ currentUser, timezones })
  );
  const { openModal, closeModal } = modalAction(useDispatch());

  useEffect(() => {
    if (
      _notNil(currentUser) &&
      _isNotEmpty(timezones) &&
      (currentUser.timezone === 'UTC' || (currentUser.name ?? '').length === 0) &&
      currentPath !== '/account'
    ) {
      openModal('COMPLETE_ACCOUNT', {
        closeModal,
        user: currentUser,
        timezones,
      });
    }
  }, [currentUser, timezones, currentPath]);
  return <>{children}</>;
};
