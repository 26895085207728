// @ts-nocheck: converted from JS

import validate from 'validate.js';

export const initialObservationState = (observation) => {
  const o = { ...observation };
  if (o.type === 'numeric') {
    o.value = o.options.min;
  } else if (o.type === 'severity') {
    o.value = 'Mild';
  } else {
    o.value = null;
  }

  return o;
};

export const constructValidationSchema = (observation) => {
  if (observation.type === 'numeric') {
    const min = Number(observation.options?.min ?? 0);
    const max = Number(observation.options?.max ?? 0);
    return {
      value: {
        presence: {
          allowEmpty: false,
          message: "Severity score can't be blank",
        },
        numericality: {
          greaterThanOrEqualTo: min,
          lessThanOrEqualTo: max,
          message: `Severity score must be a number and between ${min}-${max}`,
        },
      },
    };
  } else if (observation.type === 'severity') {
    return {
      value: {
        inclusion: ['Mild', 'Moderate', 'Severe'],
      },
    };
  }

  return false;
};

export const validateObservation = (observation) => {
  const validationSchema = constructValidationSchema(observation);
  const validateResult = validate(observation, validationSchema, {
    fullMessages: false,
  });

  return validateResult || false;
};
