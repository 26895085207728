// @ts-nocheck: converted from JS

import Radio from '@/components/UI/FormElements/Radio';
import { hasOwnProperty, preventNumberScroll } from '@/helpers';
import { _get, _isEmpty } from '@/littledash';
import { scaleOptions } from './Observation.utils';

const SettingsForm = ({ index, handleInput, errors, observation }) => {
  const handleNumericScale = (e) => {
    const { options } = observation;
    const payload = ['min', 'max'].reduce(
      (acc, v) => {
        if (!_isEmpty(options) && hasOwnProperty(options, v)) {
          acc[v] = options[v];
        }
        return acc;
      },
      { min: '', max: '' }
    );
    payload[e.target.name] = e.target.value;

    return handleInput(index, 'options', payload);
  };

  return (
    <form className="pa3 mb3 br2 bg-near-white" onSubmit={(e) => e.preventDefault()}>
      <div className="pb3">
        <label>Scale</label>
        <div className="flex flex-wrap">
          {scaleOptions.map((o) => (
            <Radio
              key={o.value}
              value={o.value}
              id={`${observation.id}_${o.value}`}
              name={`${observation.id}_${o.value}`}
              label={o.name}
              className="mr3"
              checked={o.value === observation.type}
              onChange={(e) => handleInput(index, 'type', e.target.value)}
            />
          ))}
        </div>
      </div>
      {observation.type === 'numeric' && (
        <div>
          <div className="flex flex-wrap pb2">
            <div className="pr2">
              <label htmlFor="min">Minimum value</label>
              <input
                name="min"
                type="number"
                onWheel={preventNumberScroll}
                style={{ marginBottom: 0, maxWidth: '125px' }}
                min="0"
                value={
                  !_isEmpty(observation.options) && hasOwnProperty(observation.options, 'min')
                    ? observation.options.min
                    : ''
                }
                className={hasOwnProperty(errors, 'options') && 'input__error'}
                onChange={(e) => handleNumericScale(e)}
              />
            </div>
            <div className="pl2">
              <label
                className={`${hasOwnProperty(errors, 'options') && !_isEmpty(_get(errors.options, 'min'))}`}
                htmlFor="name"
              >
                Maximum value
              </label>
              <input
                name="max"
                type="number"
                onWheel={preventNumberScroll}
                style={{ marginBottom: 0, maxWidth: '125px' }}
                min={
                  !_isEmpty(observation.options) && hasOwnProperty(observation.options, 'min')
                    ? observation.options.min
                    : 0
                }
                value={
                  !_isEmpty(observation.options) && hasOwnProperty(observation.options, 'max')
                    ? observation.options.max
                    : ''
                }
                className={hasOwnProperty(errors, 'options') && 'input__error'}
                onChange={(e) => handleNumericScale(e)}
              />
            </div>
          </div>
          {errors && (
            <ul className="f6 red lh-copy">
              {hasOwnProperty(errors, 'options') &&
                Object.keys(errors.options).map((k) => _get(errors.options, k).map((e, i) => <li key={i}>{e}</li>))}
            </ul>
          )}
        </div>
      )}
      <div className="mt2">
        <label htmlFor={`${index}_description`}>Grading information</label>
        <textarea
          maxLength={5000}
          cols="30"
          rows="10"
          name={`${index}_description`}
          value={observation.description}
          style={{ marginBottom: 0 }}
          className={hasOwnProperty(errors, 'description') && 'input__error'}
          onChange={(e) => handleInput(index, 'description', e.target.value)}
        />
        {errors && (
          <ul className="f6 red lh-copy">
            {hasOwnProperty(errors, 'description') && errors.description.map((e, i) => <li key={i}>{e}</li>)}
          </ul>
        )}
      </div>
    </form>
  );
};

export default SettingsForm;
