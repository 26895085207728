import Button from '@/components/UI/Button';
import type { DropdownRenderAsChildProps } from '@/components/UI/Dropdown/Dropdown.model';
import SearchSelect from '@/components/UI/SearchSelect';
import { errorToast } from '@/helpers';
import { _isFunction } from '@/littledash';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { DataTableRowHeaderStateChangeEvent, DataTableService } from '../../../DataTable.model';
import { DataTableEvent, DataTableRowHeaderId } from '../../../DataTable.model';

interface ShowRowHeadersProps extends DropdownRenderAsChildProps {
  dataTableService: DataTableService;
}

const ShowRowHeaders: FC<ShowRowHeadersProps> = ({ dataTableService, toggleDropdownMenu }) => {
  const [selected, setSelected] = useState(dataTableService.displayedRowHeaderIds());
  const headersIds = dataTableService.rowHeaders().map((h) => h.id);

  const handleRowHeaderDisplayChange = async (displayedIds: Array<DataTableRowHeaderId>) => {
    try {
      const orderedIDs = [...headersIds].filter((headerId) => displayedIds.includes(headerId));
      setSelected(orderedIDs);
      await dataTableService.updateDisplayedRowHeaders(new Set(orderedIDs));
    } catch (err) {
      errorToast('Could not persist table settings');
    }
  };

  useEffect(() => {
    const listener = (event: DataTableRowHeaderStateChangeEvent) => {
      setSelected([...event.detail.displayedRowHeaderIds]);
    };
    dataTableService.subscribe(DataTableEvent.RowHeaderStateChange, listener);
    return () => {
      dataTableService.unsubscribe(DataTableEvent.RowHeaderStateChange, listener);
    };
  }, [dataTableService]);

  return (
    <div style={{ width: 300 }}>
      <SearchSelect
        sections={[
          {
            title: 'All fields',
            items: dataTableService.rowHeaders(),
          },
        ]}
        overflowHeight={300}
        selected={selected}
        setSelected={handleRowHeaderDisplayChange}
      />
      <div className="bt b--moon-gray pa3">
        <Button onClick={() => _isFunction(toggleDropdownMenu) && toggleDropdownMenu(false)} plain className="f6 w-100">
          Close
        </Button>
      </div>
    </div>
  );
};

export default ShowRowHeaders;
