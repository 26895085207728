import { _notNil, _isNotEmpty } from '@/littledash';

/**
 * ZXCVBN is used to measure the complexity and strength of a password on a scale from 0—4:
  0: Very guessable (risks being guessed immediately).
  1: Somewhat guessable (protection from unthrottled online attacks).
  2: Safely unguessable (moderate protection from offline slow-hash scenarios).
  3: Very unguessable (strong protection from offline slow-hash scenarios).
  4: Extremely unguessable (very strong protection, often overkill).
 */
const MINIMUM_ZXCVBN_SCORE = 2;

export const validatePasswordStrength = (suggestions: Array<string>, score: number): string | boolean => {
  if (_notNil(suggestions) && _notNil(score)) {
    if (score < MINIMUM_ZXCVBN_SCORE && _isNotEmpty(suggestions)) {
      return `Password does not meet the following requirements: ${suggestions.map((s) => s).join(', ')}`;
    } else {
      return true;
    }
  }
  return 'Invalid password';
};
