import { AnalysisIssueList } from '@/components/Studies/Groups/Analysis/AnalysisIssueList';
import { _notNil } from '@/littledash';
import { AnalysisIssue } from '@/model/Analysis.model.ts';
import type { StudyApiId } from '@/model/Study.model';
import { type FC, useEffect } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ModalActions, ModalContainer, ModalHeader, useModalAction } from '@/utils/modal';

interface AnalysisIssuesModalProps {
  studyApiId: StudyApiId;
  onAnalysisIssuesUpdate: (issues: Array<AnalysisIssue>) => void;
}

export const AnalysisIssuesModal: FC<AnalysisIssuesModalProps> = ({ studyApiId, onAnalysisIssuesUpdate }) => {
  const { closeModal } = useModalAction();

  const { invoke, loading, response } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/analysis/config',
    path: { studyApiId },
  });
  useEffect(() => {
    if (response?.type === 'success' && _notNil(onAnalysisIssuesUpdate)) {
      onAnalysisIssuesUpdate(response.body.issues);
    }
  }, [onAnalysisIssuesUpdate, response]);

  const issues = response?.type === 'success' ? response.body.issues : [];
  if (response?.type === 'error' || (response?.type === 'success' && issues.length === 0)) {
    closeModal();
  }

  return (
    <ModalContainer size="small">
      <ModalHeader
        title={`Issue${issues.length > 1 ? 's' : ''} detected`}
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
      />
      <div className="pv3 ph2">
        <AnalysisIssueList studyApiId={studyApiId} issues={issues} />
      </div>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={() => invoke({ path: { studyApiId } })}
        submitBtnText="Recheck"
        submitButtonProps={{
          disabled: loading,
          loading: loading,
        }}
      />
    </ModalContainer>
  );
};
