// @ts-nocheck: converted from JS

import type { JwtPayload, Nullable } from 'model/Common.model';

export function _isEmpty<T = any>(value: T | undefined): T is undefined & boolean {
  return [Object, Array].includes((value || {}).constructor) && !Object.entries(value || {}).length;
}

export const _isNotBlank = <T extends string>(val: Nullable<T>): val is string =>
  typeof val === 'string' && val.trim().length > 0;

export const _isNotEmpty = <T = any>(value: T | undefined): value is T & boolean => !_isEmpty(value);

/**
 * Compute the minimum value of `array`. If `array` is empty or
 * not an array, return `undefined`.
 */
export function _min(array: Array<number>): number | undefined {
  return _isPopulatedArray(array) ? array.reduce((a, b) => (a < b ? a : b)) : undefined;
}

/**
 * Compute the maximum value of `array`. If `array` is empty or
 * not an array, return `undefined`.
 */
export function _max(array: Array<number>): number | undefined {
  return _isPopulatedArray(array) ? array.reduce((a, b) => (a > b ? a : b)) : undefined;
}

/**
 * Return the first value of `array`. If `array` is empty or
 * not an array, return `undefined`.
 */
export function _first(array) {
  return _isPopulatedArray(array) ? array[0] : undefined;
}

/**
 * Return the last value of `array`. If `array` is empty or
 * not an array, return `undefined`.
 */
export function _last(array) {
  return _isPopulatedArray(array) ? array[array.length - 1] : undefined;
}

/**
 * Gets the size of collection by returning its length for array-like values or strings
 */
export function _size(collection) {
  let res = false;
  if (Array.isArray(collection)) {
    res = collection.length > 0 && collection.length;
  }
  if (typeof collection === 'object') {
    res = Object.keys(collection).length > 0 && Object.keys(collection).length;
  }
  if (typeof collection === 'string') {
    res = collection.length > 0 && collection.length;
  }

  return res;
}

/**
 * chunk(['a', 'b', 'c', 'd'], 2);
 => [['a', 'b'], ['c', 'd']]
 */

export const _chunk = <T>(input: Array<T>, size: number) => {
  return input.reduce<Array<Array<T>>>((arr, item, idx) => {
    return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

/**
 * Gets the value at path of object.
 */
export const _get = (obj, path, defaultValue: string | null = null) => {
  let result;
  const hasPath = Array.isArray(path) ? path.length > 0 : path;

  if (obj != null && !hasPath) {
    result = obj;
  }

  if (obj != null && hasPath) {
    path = Array.isArray(path) ? path : String.prototype.split.call(path, /[,[\].]+?/);

    let index = 0;
    const length = path.length;

    while (obj != null && index < length) {
      obj = obj[`${path[index++]}`];
    }

    result = index && index === length ? obj : undefined;
  }

  return result === undefined ? defaultValue : result;
};

/**
 * The native sort modifies the array in place. `_.orderBy` and `_.sortBy` do not, so we use `.concat()` to
 * copy the array, then sort
 * fruits.concat().sort(_orderBy("name"));
 *
 */
export const _orderBy = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const _identity = (v) => v;

export const _some = (collection, predicate = _identity) => {
  let value = false;
  const iterableCollection = Array.isArray(collection) ? collection : Object.values(collection);

  iterableCollection.forEach((v, i) => {
    if (predicate(v, i)) {
      value = true;
    }
  });

  return value;
};

export const _flatten = (array) => {
  return _isPopulatedArray(array) ? array.reduce((a, b) => a.concat(b), []) : undefined;
};

export const _flattenDeep = (arr) => (Array.isArray(arr) ? arr.reduce((a, b) => a.concat(_flattenDeep(b)), []) : [arr]);

/**
 * @internal
 */
export function _isPopulatedArray(array) {
  return Array.isArray(array) && array.length;
}

const jsonPathPattern = /([^[.\]])+/g; // Regex explained: https://regexr.com/58j0k
export function _set(obj, path, value) {
  const pathArray = Array.isArray(path) ? path : path.match(jsonPathPattern);

  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) {
      acc[key] = {};
    }
    if (i === pathArray.length - 1) {
      acc[key] = value;
    }
    return acc[key];
  }, obj);
}

/**
 * Unset a value on a given object
 * @param obj
 * @param path string | string[]
 * @returns boolean
 */
export const _unset = (obj, path) =>
  (Array.isArray(path) ? [...path] : path.match(jsonPathPattern)).reduce((acc, key, idx, array) => {
    if (idx === array.length - 1) {
      if (Array.isArray(acc)) {
        return acc.splice(key).length > 0;
      }
      return delete acc?.[key];
    }
    const next = acc?.[key];
    if (next == null) {
      array.splice(1); // break;
      return false;
    }
    return next;
  }, obj);

/**
 * Validates whether two primatives or objects are deeply equal. Short circuits on first failure
 * Does not return inequalities
 *
 * @example
 * _isDeepEqual(['test', 'alsoTest'], ['test', 'alsoTest']) // returns true
 * _isDeepEqual({test: 'test'}, {test: 'alsoTest'}) // returns false
 *
 * @param firstValue {any}
 * @param secondValue {any}
 * @returns {boolean}
 */
export const _isDeepEqual = (firstValue: any, secondValue: any): boolean => {
  // if both values are nil, return true
  // if single value is nil, return false
  if ((firstValue === null && secondValue === null) || (firstValue === undefined && secondValue === undefined)) {
    return true;
  } else if (_isNil(firstValue) || _isNil(secondValue)) {
    return false;
  }

  if (Array.isArray(firstValue) && Array.isArray(secondValue)) {
    if (firstValue.length !== secondValue.length) {
      // compare lengths - can save a lot of time
      return false;
    }

    for (let i = 0, l = firstValue.length; i < l; i++) {
      // Recursively check the nests in the array
      if (!_isDeepEqual(firstValue[i], secondValue[i])) {
        return false;
      }
    }

    return true;
  } else if (firstValue.constructor.name === 'Object' && secondValue.constructor.name === 'Object') {
    const firstValueEntries = Object.entries(firstValue);
    if (firstValueEntries.length !== Object.entries(secondValue).length) {
      return false;
    }
    // Recursively check the nests in the object
    return firstValueEntries.every(([key, value]) => _isDeepEqual(secondValue[key], value));
  } else {
    return firstValue === secondValue;
  }
};

export const _isNotDeepEqual = (firstValue: any, secondValue: any): boolean => !_isDeepEqual(firstValue, secondValue);

export const _isNil = <T = any>(val: T | undefined | null): val is null | undefined => val == null;

export const _isEmptyString = <T = any>(val: T | undefined | null, trim = true): val is '' =>
  typeof val === 'string' && (trim ? val.trim() : val).length === 0;

export const _notNil = <T = any>(val: T | undefined | null): val is T => !_isNil(val);
export const _noop = () => undefined;
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const _isFunction = <T = any>(value: T | undefined): value is T | Function => typeof value === 'function';
export const _isNumber = <T = any>(value: T | undefined): value is number =>
  typeof value === 'number' && !isNaN(value) && isFinite(value);
/**
 * decodeURIComponent can throw URIError: URI malformed. This wrapper allows you to safely call it
 *
 * @example
 * safelyDecodeURIComponent("%E0%A4%A") // returns %E0%A4%A
 * safelyDecodeURIComponent("%E0%A4%A", "defaultValue") // returns defaultValue
 *
 * @param value {string}
 * @param defaultValue {string}
 * @returns {string}
 */
export const safelyDecodeURIComponent = (value: string, defaultValue = value): string => {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    return defaultValue;
  }
};

/**
 * Handles JSON parsing errors by returning a default value
 *
 * @example
 * safelyParseJson("['test', 'alsoTest']") // returns ['test', 'alsoTest']
 * safelyParseJson("#djdsj*n,,", "defaultValue") // returns defaultValue
 *
 * @param value {string}
 * @param defaultValue {string}
 * @returns {any}
 */
export const safelyParseJson = <T>(value: string, defaultValue = value): T | string => {
  try {
    return JSON.parse(value) ?? defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

/**
 * @returns {string}
 */
export const uuid = (): string => {
  try {
    if (window?.crypto?.randomUUID instanceof Function) {
      return window.crypto.randomUUID();
    }
    if (window?.crypto?.getRandomValues instanceof Function && window?.Uint8Array instanceof Function) {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (window.crypto.getRandomValues(new window.Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    }
  } catch (err) {
    // intentionally empty
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getJwtPayload = (token?: string): JwtPayload | undefined => {
  try {
    const payloadChunk = (token ?? localStorage.getItem('token'))?.split('.')?.at(1);
    if (_notNil(payloadChunk)) {
      return JSON.parse(atob(payloadChunk)) as JwtPayload;
    }
  } catch (e) {
    // intentionally empty
  }
};
export const getJwtExpiry = (token?: string): Date | undefined => {
  const exp = getJwtPayload(token)?.exp;
  if (Number.isFinite(exp)) {
    return new Date(exp * 1000);
  }
};
export const jwtValid = (token?: string) => {
  const exp = getJwtPayload(token)?.exp;
  if (Number.isFinite(exp)) {
    return exp * 1000 > Date.now();
  }
  return false;
};

export const resetSessionCorrelationId = () => {
  const newId = uuid();
  sessionStorage.setItem('session-correlation-id', newId);
  return newId;
};

/**
 * Returns a unique id for the current session
 * @returns {string}
 */
export const getSessionCorrelationId = () => {
  const id = sessionStorage.getItem('session-correlation-id');
  if (_notNil(id)) {
    return id;
  }
  return resetSessionCorrelationId();
};

/**
 * Returns cloned object without specified props
 * @example _omit({x: 1, y: 2, z: 3}, ["x", "z"]); // returns {y: 2}
 * @param {Object} object Given object to clone
 * @param {string[]} props Props to omit
 * @returns {Object}
 */
export const _omit = (object, props) =>
  Object.fromEntries(Object.entries(object).filter(([prop]) => !props.includes(prop)));

export const _toNumber = <T = undefined>(value: any, defaultValue?: T): number | T => {
  if (_isNil(value) || _isEmpty(value)) {
    return defaultValue;
  }
  const number = Number(value);
  if (isNaN(number)) {
    return defaultValue;
  }
  return number;
};

export const _toRecord = <T extends object>(array: T[], propToKey: keyof T): Record<T[keyof T], T> =>
  array.reduce<Record<T[keyof T], T>>((acc, value) => {
    acc[value[propToKey]] = value;
    return acc;
  }, {});
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const _throttle = <T extends Function>(func: T, duration: number): T => {
  let lastInvokeTimestamp = 0;
  return (...args: Array<any>) => {
    const currentTimestamp = Date.now();
    if (currentTimestamp - lastInvokeTimestamp >= duration) {
      const result = func(...args);
      lastInvokeTimestamp = currentTimestamp;
      return result;
    }
  };
};
