import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import { DateRenderer, DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import Table from '@/components/UI/Table';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { Request } from '@/model/Request.model';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { web as webRoute } from '@/support/route';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface RequestsProps {
  requests?: Array<Request>;
  study: Study;
}

const Requests: React.FC<RequestsProps> = ({ requests, study }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasWriteAccess = study && Auth.isWriteUserForStudy(study);
  const hasRequests = _isNotEmpty(requests);

  const columns: Array<Column<Request>> = [
    {
      id: 'request_template.name',
      Header: 'Name',
      accessor: 'request_template.name',
      width: 180,
      Cell: ({
        row: {
          original: {
            id: requestId,
            request_template: { name },
          },
        },
      }) =>
        hasWriteAccess ? (
          <Link to={webRoute('studies.requests.edit', { id: study.id, requestId })} className="link blue">
            {name}
          </Link>
        ) : (
          name
        ),
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      Cell: ({
        row: {
          original: { status },
        },
      }) => (status === 'in_progress' ? 'In Progress' : status === 'complete' ? 'Complete' : 'Pending'),
    },
    {
      id: 'due_date',
      Header: 'Due date',
      accessor: 'due_date',
      Cell: ({ row: { original } }) => <DateRenderer value={original.due_date} defaultResponse="-" />,
    },
    {
      id: 'assigned.name',
      Header: 'Requestee',
      accessor: 'assigned.name',
      Cell: ({ row: { original } }) => original.assigned_user?.name || 'Unassigned',
    },
    {
      id: 'user.name',
      Header: 'Requestor',
      accessor: 'user.name',
    },
    {
      id: 'created_at',
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
    },
  ];

  const openSelectRequestsModal = () => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'SELECT_STUDY_REQUESTS',
      props: {
        assignedRequests: requests,
        handleCallback: (requestId: ID) => {
          dispatch({ type: 'CLOSE_MODAL' });
          history.push(webRoute('studies.requests', { id: study.id, requestTemplateId: requestId }));
        },
      },
    });
  };

  return hasRequests ? (
    <div className="ui-card" data-test-component="Requests" data-test-element="container">
      <div className="flex justify-end pa3">
        <Button onClick={openSelectRequestsModal} disabled={!hasWriteAccess}>
          Add new
        </Button>
      </div>
      <div className="bt b--moon-gray">
        <Table data={requests} columns={columns} />
      </div>
    </div>
  ) : (
    <div data-test-component="Requests" data-test-element="no-data-container">
      <NoDataCard
        title="Your requests will appear here"
        text="Request templates can be added and configured to your requirements without affecting the original template."
        onLinkClick={openSelectRequestsModal}
        disabled={!hasWriteAccess}
        btnTxt="Add a request"
        openModal
      />
    </div>
  );
};

export default Requests;
