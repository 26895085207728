// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { formatMetadataForSave, generateColumns } from '@/components/Studies/Attachments/Attachment.utils';
import Button from '@/components/UI/Button';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import TableShowMenu from '@/components/UI/SelectDropDown/Menus/TableShowMenu';
import Table from '@/components/UI/Table';
import { copyText } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil, uuid } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CreateLinks = ({ state: formState, dispatch: formDispatch, selectedRows, setSelectedRows }) => {
  const {
    study: { links },
    form: { loading },
  } = formState;
  const reduxDispatch = useDispatch();
  const [metadata, setMetadata] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const settings = useSelector((state) => state.ui.settings);

  const fetchAttachmentMetadata = async () => {
    formDispatch({ type: 'SET_LOADING', data: true });
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'attachment_meta',
        },
      });
      setColumns(generateColumns(data, settings.tables.links?.columns));
      setMetadata(data);
    } catch (error) {
      setError(error);
    } finally {
      formDispatch({ type: 'SET_LOADING', data: false });
    }
  };

  useEffect(() => {
    fetchAttachmentMetadata();
  }, []);

  const closeModal = () => {
    reduxDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const addLink = () => {
    reduxDispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_EDIT_LINK',
      props: {
        metadata,
        handleSave: (newLink) => {
          if (_isNotEmpty(newLink?.metadata)) {
            newLink.metadata = formatMetadataForSave(newLink, metadata);
          }
          if (newLink.alt === '') {
            delete newLink.alt;
          }
          formDispatch({
            type: 'updateStudy',
            data: { links: [...links, { ...newLink, id: uuid() }] },
          });
          closeModal();
        },
      },
    });
  };

  const getSelectedLink = () => {
    const getLink = (index) => links[index];
    return Object.keys(selectedRows).map(getLink)?.[0];
  };

  const editLink = () => {
    const selectedLink = getSelectedLink();
    if (selectedLink) {
      reduxDispatch({
        type: 'OPEN_MODAL',
        modal: 'ADD_EDIT_LINK',
        props: {
          link: selectedLink,
          metadata,
          handleSave: (editedLink) => {
            if (_isNotEmpty(editedLink?.metadata)) {
              editedLink.metadata = formatMetadataForSave(editedLink, metadata);
            }
            if (editedLink.alt === '') {
              delete editedLink.alt;
            }
            const updatedLinks = links.map((link) =>
              link.id === selectedLink.id ? { ...editedLink, id: selectedLink.id } : link
            );
            formDispatch({
              type: 'updateStudy',
              data: { links: updatedLinks },
            });
            closeModal();
          },
        },
      });
    }
  };

  const copyLink = () => {
    const link = getSelectedLink();
    if (_notNil(link?.url)) {
      copyText(link.url, 'Link successfully copied.');
    }
  };

  const deleteLink = () => {
    const selectedLink = getSelectedLink();
    if (selectedLink) {
      const remainingLinks = links.filter((link) => link.id !== selectedLink.id);
      formDispatch({
        type: 'updateStudy',
        data: { links: remainingLinks },
      });
    }
  };

  const singleRowActions = [
    {
      name: 'Edit Link',
      key: 'edit_link',
      action: editLink,
    },

    {
      name: 'Copy Link',
      key: 'copy_link',
      action: copyLink,
    },
    {
      name: 'Delete',
      key: 'delete',
      action: deleteLink,
      className: 'red',
    },
  ];

  return error.length ? (
    <ApiErrorBanner
      className="mb3"
      title="There was an creating your link"
      text="Please try again later. If this keeps occurring please contact support."
      error={error}
    />
  ) : (
    <>
      <div className="flex justify-between pa3 bb b--moon-gray">
        <div className="flex mr2">
          {!_isEmpty(selectedRows) && (
            <div
              className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
              style={{
                whiteSpace: 'nowrap',
                lineHeight: '2.4rem',
              }}
            >
              {Object.keys(selectedRows).length} selected
            </div>
          )}
          <SelectDropDown
            title="Actions"
            className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
            alignMenu="right"
            disabled={_isEmpty(selectedRows) || Object.keys(selectedRows).length > 1}
          >
            <ActionList actions={singleRowActions} />
          </SelectDropDown>
          <SearchFilterBar
            canSearch={false}
            className="ml2 flex flex-grow-1"
            ShowMenu={() => <TableShowMenu columns={columns} handleChange={(data) => setColumns(data)} />}
          />
        </div>
        <Button onClick={addLink}>Add a link</Button>
      </div>
      {loading || _isNil(metadata) ? (
        <div className="flex justify-center items-center bg-white mb5 mt5">
          <Loading txt="Loading links..." />
        </div>
      ) : (
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          columns={columns}
          data={links}
          className="overflow-visible"
          noDataMessage="No links have been added yet"
        />
      )}
    </>
  );
};

export default CreateLinks;
