import { _notNil } from '@/littledash.ts';
import { ISODateTime } from '@/model/Common.model.ts';
import { DateUtils } from '@/utils/Date.utils.ts';
import { tz } from '@date-fns/tz';
import cn from 'classnames';
import { eachDayOfInterval, endOfDay, format, isToday, startOfDay } from 'date-fns';
import { FC, useMemo } from 'react';
import type { CalendarFilters, DateRange, StudyColours } from '../../Calendar.model';
import DayList from '../Day/DayList';

interface WeekLanesProps {
  dateRange: DateRange;
  filters: CalendarFilters;
  studyColours: StudyColours;
  handleGoToDay: (date: ISODateTime) => void;
}

export const WeekLanes: FC<WeekLanesProps> = ({ dateRange, filters, studyColours, handleGoToDay }) => {
  const weekDates: Array<{ start: ISODateTime; end: ISODateTime }> = useMemo(() => {
    const timezone = tz(DateUtils.timezone());
    return eachDayOfInterval(dateRange, { in: timezone }).map((date) => ({
      start: DateUtils.formatISO(startOfDay(date, { in: timezone }).getTime()),
      end: DateUtils.formatISO(endOfDay(date, { in: timezone }).getTime()),
    }));
  }, [dateRange]);

  return (
    <div className="flex justify-between w-100 h-100" data-test-component="WeekLanes" data-test-element="container">
      {weekDates.map((range, index) => (
        <div
          key={`${range.start}-${range.end}`}
          className={cn('flex flex-column w-100 h-100', { 'bl b--moon-gray': index > 0 })}
          data-test-component="WeekLanes"
          data-test-element="day-column"
          data-test-key={range.start.substring(0, 10)}
          data-testid="lane-container"
        >
          <LaneDate date={range.start} onClick={() => handleGoToDay(range.start)} />
          <DayList dateRange={range} filters={filters} studyColours={studyColours} narrow />
        </div>
      ))}
    </div>
  );
};

export const LaneDate: FC<{ date: ISODateTime; onClick?: () => void }> = ({ date, onClick }) => {
  const timezone = tz(DateUtils.timezone());
  const today = isToday(date, { in: timezone });
  return (
    <div
      className={cn('w-100 tc pa3 bb b--moon-gray', { 'dim pointer': _notNil(onClick) })}
      onClick={onClick}
      data-test-component="LaneDate"
      data-test-element="container"
      data-testid="lane-date-container"
    >
      <h4 className="ttu fw5 f6 lh-title pb2">{format(date, 'EEE', { in: timezone })}</h4>
      <div
        className={cn('flex items-center ma0-auto br-100 w2 h2', { 'bg-blue': today })}
        data-testid={today ? 'today-date' : 'not-today-date'}
      >
        <h3
          className={cn('f5 lh-title w-100', { white: today })}
          data-testid={today ? 'today-date-text' : 'not-today-date-text'}
        >
          {format(date, 'd', { in: timezone })}
        </h3>
      </div>
    </div>
  );
};

export default WeekLanes;
