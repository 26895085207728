import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import SpreadSheet from '@/components/UI/SpreadSheet';
import { ColorEditor } from '@/components/UI/SpreadSheet/editors/ColorEditor';
import type { CustomGridSettings } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import HotTable from '@handsontable//react';
import type { ColumnSettings } from 'handsontable/settings';
import { _isEmpty, _isNil, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type {
  MetadataField,
  MetadataFieldWithValue,
  ModalMeta,
  StudyGroupsMetadataSettings,
} from '@/model/Metadata.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { metadataMapToSortedMetadataArray, sanitizeMetadataValue, valueSet } from '@/support/hot';
import { structuredCloneUtil } from '@/utils/browser.utils';
import {
  generateColumnData,
  initialData,
  metadataToColumnSettingMapper,
  settings as baseSettings,
} from './CreateTreatmentGroups.utils';
import './CreateTreatmentGroups.scss';

export interface TreatmentGroupKeyedMetadata extends Omit<TreatmentGroup, 'metadata'> {
  metadata: Record<string, MetadataFieldWithValue>;
}

const mapIncomingGroupData = (groups: Array<ModalMeta<TreatmentGroup>>): Array<TreatmentGroupKeyedMetadata> =>
  groups.map<TreatmentGroupKeyedMetadata>((group) => {
    const groupMeta = group?.metadata ?? group?.meta?.data ?? [];
    const metadata = groupMeta.reduce<Record<ID, MetadataFieldWithValue>>((acc, meta) => {
      if (_notNil(meta)) {
        acc[meta.glossary_id ?? meta.id] = { ...meta };
      }
      return acc;
    }, {});
    return { ...group, metadata };
  });

const getMetadataToRetain = (groups: Array<TreatmentGroupKeyedMetadata>): Set<ID> =>
  groups.reduce<Set<ID>>((acc, group) => {
    Object.values(group.metadata ?? {}).forEach(({ glossary_id: glossaryId, value, field_type: fieldType }) => {
      if (valueSet(sanitizeMetadataValue(value, fieldType))) {
        acc.add(glossaryId);
      }
    });
    return acc;
  }, new Set());

interface CreateTreatmentGroupsProps {
  groups: Array<ModalMeta<TreatmentGroup>>;
  onAddGroups: (groups: Array<TreatmentGroup>) => void;
}

const CreateTreatmentGroups: React.FC<CreateTreatmentGroupsProps> = ({ groups, onAddGroups }) => {
  const [metadata, setMetadata] = useState<Array<MetadataField>>([]);
  const [columnData, updateColumnData] = useState<{ colHeaders: Array<string>; columns: Array<ColumnSettings> }>({
    colHeaders: [],
    columns: [],
  });
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const initialGroupData = structuredCloneUtil<Array<TreatmentGroupKeyedMetadata>>(initialData);

  const [groupData, setGroupData] = useState<{ groups: Array<TreatmentGroupKeyedMetadata> }>({
    groups: _isEmpty(groups) ? initialGroupData : mapIncomingGroupData(groups),
  });

  const ref = useRef<HotTable>(null);
  const dispatch = useDispatch();

  const validateRows = async () =>
    new Promise<boolean>((resolve) => {
      const hot = ref.current?.hotInstance;
      if (hot != null) {
        try {
          hot.validateRows(
            Array.from({ length: hot.countRows() - 1 }).map((_, idx) => idx),
            (valid: boolean) => {
              resolve(valid);
              hot.render();
            }
          );
        } catch (e) {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });

  useEffect(() => {
    setGroupData({
      groups: _isEmpty(groups) ? initialGroupData : mapIncomingGroupData(groups),
    });
  }, [groups]);

  const { entity: groupMetadata, entityLoading: groupMetadataLoading } = useFetchEntity<Array<MetadataField>>({
    entityType: 'glossaryMeta',
    queryParams: {
      filter_type: 'group_meta',
    },
  });

  const { entity: studyGroupsMetadataSettings, entityLoading: studyGroupsMetadataSettingsLoading } = useFetchEntity<
    Array<StudyGroupsMetadataSettings>
  >({
    entityType: 'studyGroups',
  });

  useEffect(() => {
    if (_isEmpty(metadata) && _notNil(groupMetadata) && _notNil(studyGroupsMetadataSettings) && _notNil(groups)) {
      const metadataMap = new Map(groupMetadata?.map((meta) => [meta.id, meta]) ?? []);
      groups.forEach((group) =>
        group?.metadata?.forEach((md) => {
          const { glossary_id: id, options, title, active } = md;
          if (!metadataMap.has(id)) {
            metadataMap.set(id, {
              id,
              glossary_id: id,
              field_type: md.field_type,
              options,
              read_only: md.read_only,
              title,
              created_at: md.created_at,
              updated_at: md.updated_at,
              active,
              slug: md.slug,
            });
          }
        })
      );

      const requiredMetadata = new Map(studyGroupsMetadataSettings?.map(({ id, required }) => [id, required]) ?? []);
      groups.forEach((g) => {
        (g?.metadata ?? []).forEach((m) => {
          const id = m?.glossary_id ?? m?.id;
          if (_notNil(id) && !requiredMetadata.has(id)) {
            requiredMetadata.set(id, false);
          }
        });
      });
      updateColumnData(
        generateColumnData(
          [...requiredMetadata.entries()].map(([metadataId, required]) => ({
            ...metadataMap.get(metadataId),
            required,
          }))
        )
      );
      setMetadata(groupMetadata.filter(({ id }) => !requiredMetadata.has(id)));
    }
  }, [groupMetadata, studyGroupsMetadataSettings]);

  const settings = useMemo<CustomGridSettings>(
    () => ({
      ...baseSettings,
      ...columnData,
      contextMenu: {
        items: {
          row_above: {},
          row_below: {},
          remove_row: {},
        },
      },
      manualColumnResize: true,
    }),
    [columnData]
  );

  const resetColumns = () => {
    settings.columns = columnData.columns;
    settings.colHeaders = columnData.colHeaders;
  };

  const closeModal = () => {
    resetColumns();
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const handleAfterCreateRow = () => {
    requestAnimationFrame((timestamp) => {
      const groups = [...groupData.groups];
      for (let rowIndex = 0; rowIndex < groups.length - 1; rowIndex++) {
        const group = groups[rowIndex];
        if (_isNil(group.color)) {
          group.color = ColorEditor.colorForIndex(rowIndex);
        }
        group.no = rowIndex;
      }
      setGroupData({ groups });
    });
  };

  const submitGroups = async () => {
    const valid = await validateRows();
    if (valid && _notNil(onAddGroups)) {
      const metadataGlossaryIdOrder = columnData.columns.reduce<Array<number>>(
        (acc, column) => [...acc, ...(_notNil(column.metaID) ? [column.metaID] : [])],
        []
      );
      const groups = groupData.groups.slice(0, groupData.groups.length - 1);
      const metadataIdsToRetain = getMetadataToRetain(groups);
      onAddGroups(
        groups.map((group) => ({
          ...group,
          metadata: metadataMapToSortedMetadataArray(metadataGlossaryIdOrder, metadataIdsToRetain, group.metadata),
        }))
      );

      closeModal();
    }
  };

  return (
    <div
      style={{ minHeight: '90vh' }}
      data-test-component="CreateTreatmentGroups"
      data-test-element="container"
      className="flex flex-wrap w-90 overflow-hidden center ma3 bg-light-gray br2 mt4 mb-2 relative"
    >
      <div className="flex flex-wrap self-start w-100" style={{ minHeight: 'calc( 90vh - 72px)' }}>
        <div className="ph4 pt4 pb3 relative z-5">
          <h3 className="normal f4 lh-title">Setup treatment groups</h3>
          <p className="lh-copy f5 pv2">
            Click a field to update group information or add a new meta-data column.
            <a
              href="https://help.benchling.com/hc/en-us/articles/21952642953101"
              target="_blank"
              rel="noopener noreferrer"
              className="link blue"
            >
              {' '}
              Read more
            </a>
          </p>
        </div>
        {groupMetadataLoading || studyGroupsMetadataSettingsLoading ? (
          <div className="w-100 h-100">
            <Loading />
          </div>
        ) : (
          <div
            className="ph4 pb4 w-100 ow-spreadsheet-styles"
            style={{ marginTop: '-50px' }}
            data-test-component="CreateTreatmentGroups"
            data-test-element="spreadsheet-container"
          >
            <SpreadSheet
              data={groupData.groups}
              settings={settings}
              innerRef={ref}
              className="studyGroups"
              buttonClasses={'absolute-sheet-buttons'}
              autoAddRow
              metadata={metadata}
              metadataToColumnSettingMapper={metadataToColumnSettingMapper}
              afterCreateRow={handleAfterCreateRow}
              setMetadata={setMetadata}
              setInvalid={setIsInvalid}
            />
          </div>
        )}
      </div>
      <div
        className="ph4 pv3 flex self-end bt b--moon-gray w-100"
        data-test-component="CreateTreatmentGroups"
        data-test-element="action-container"
      >
        <Button
          onClick={submitGroups}
          testId="save-action"
          disabled={groupMetadataLoading || studyGroupsMetadataSettingsLoading || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
        >
          Save
        </Button>
        <Button plain className="ml3" onClick={closeModal} testId="cancel-action">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CreateTreatmentGroups;
