// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import Link from '@/components/UI/Link';

interface SubHeaderProps {
  linkToText?: any;
  link?: any;
  asideComponent?: any;
  testId?: any;
}

const SubHeader: FC<SubHeaderProps> = ({ linkToText, link, asideComponent, testId = 'header' }) => (
  <div className="pv2 pv3-l ph3 ph4-l bb b--moon-gray flex-l justify-between-l flex-wrap-l items-center">
    <div data-testid={testId}>
      {!!link && (
        <Link to={link} className="link f5">
          <Icon
            icon="previous"
            width="13"
            height="13"
            className="previous mr2 v-mid relative"
            style={{ bottom: '1px' }}
          />
          <span className="lh-title" data-testid={`${testId}--text`}>
            {linkToText}
          </span>
        </Link>
      )}
    </div>
    {asideComponent && <div>{asideComponent()}</div>}
  </div>
);

export default SubHeader;
