// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Header from '@/components/UI/Header';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchEntity } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { columns, formatFilterOptions } from './Observations.utils';

const Show = () => {
  const { id: studyId } = useParams();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
  });

  const [filterOptions, setFilterOptions] = useState([]);
  const [errors, setErrors] = useState('');

  const getFilterOptions = () =>
    Http.get(
      apiRoutes('studies.observations.filter.get', {
        id: study.id,
      })
    )
      .then(({ data }) => setFilterOptions(formatFilterOptions(data)))
      .catch((error) => {
        setErrors(error);
      });

  useEffect(() => {
    if (study) {
      getFilterOptions();
    }
  }, [study]);

  const exportRoutes = [
    {
      label: 'All observations',
      url: 'studies.observations.export',
      params: { id: study?.id },
      noFilters: true,
    },
    {
      label: 'Current selection',
      url: 'studies.observations.export',
      params: { id: study?.id },
    },
  ];

  if (studyLoading) {
    return null;
  }

  return (
    <>
      <div className="ph4">
        <Header mainHeaderText="Observations" />
      </div>
      {errors ? (
        <ApiErrorBanner className="mb4" errorType={ApiErrorType.FETCH} error={errors} />
      ) : (
        <div className="pa4">
          <APITable
            columns={columns(study.id)}
            apiInfo={{
              type: 'legacyInternalApi',
              route: apiRoutes('studies.observations.show', {
                id: study.id,
              }),
            }}
            includeParam="study_group"
            filterOptions={filterOptions}
            exportRoutes={exportRoutes}
            searchPlaceholderText="Search by animal name or observation"
          />
        </div>
      )}
    </>
  );
};

export default Show;
