import type { FC } from 'react';
import Button from '@/components/UI/Button';
import { Animal, AnimalApiId } from '@/model/Animal.model';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { checkStudyForDuplicates } from '../AssignIdentifiers/AssignIdentifiers.utils';
import { StudyApiId } from '@/model/Study.model';
import { DateUtils } from '@/utils/Date.utils';
import { Datepicker, Radio } from '@/components/UI/FormFields';
import { useSelector } from 'react-redux';
import { State } from '@/model/State.model';
import { useRequest } from '@/support/Hooks/request';
import { ISODate } from '@/model/Common.model';
import { _isEmpty, _isNil } from '@/littledash';
import { startCase } from 'lodash';

interface EditSubjectProps {
  animal: Animal;
  studyApiId: StudyApiId;
  handleCallback: (message: string) => void;
  closeModal: () => void;
}

interface AnimalUploadFormData {
  name: string;
  dob: ISODate;
  sex: 'm' | 'f';
  tail: string;
  tag: string;
  donor: string;
  ear: string;
}

interface AnimalUpdatePayload {
  name: string;
  dob: ISODate;
  sex: 'm' | 'f';
  alt_ids: {
    tail: string;
    tag: string;
    donor: string;
    ear: string;
  };
}

const idFields = [
  {
    label: 'Name',
    name: 'name',
    required: true,
  },
  {
    label: 'Tail',
    name: 'tail',
  },
  {
    label: 'Ear',
    name: 'ear',
  },
  {
    label: 'Tag',
    name: 'tag',
  },
  {
    label: 'Donor',
    name: 'donor',
  },
];

const EditSubject: FC<EditSubjectProps> = ({ animal, studyApiId, handleCallback, closeModal }) => {
  const formMethods = useForm({
    defaultValues: {
      name: animal.name,
      dob: animal.dob ?? DateUtils.dateNow(),
      sex: animal.sex,
      ...animal.alt_ids,
    },
  });
  const { handleSubmit } = formMethods;
  const { sendRequest: updateAnimal, requestSending: saving } = useRequest({
    params: { id: animal.id },
    route: 'animal',
    method: 'patch',
  });

  const handlePayload = (data: AnimalUploadFormData): AnimalUpdatePayload => {
    return {
      name: data.name,
      dob: data.dob,
      sex: data.sex,
      alt_ids: {
        tail: data.tail,
        tag: data.tag,
        donor: data.donor,
        ear: data.ear,
      },
    };
  };

  const onSubmit = async (data: AnimalUploadFormData) => {
    await updateAnimal(handlePayload(data));
    handleCallback('Successfully updated animal');
    closeModal();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`center mv3 pa4 bg-white br2 shadow-4`}
      style={{ maxWidth: 420 }}
    >
      <div className="tc pb3">
        <h3 className="normal f4 lh-title">Edit {animal.name}</h3>
      </div>
      <FormProvider {...formMethods}>
        {idFields.map(({ name, label, required }) => (
          <EditSubjectNameField
            key={`${name}-field`}
            name={name}
            label={label}
            studyApiId={studyApiId}
            animalId={animal?.api_id}
            required={required}
          />
        ))}
        <div className="mb3">
          <Datepicker value={animal.dob} name="dob" label="Date of birth" required />
        </div>
        <div className="mb3">
          <Radio
            name="sex"
            label="Sex"
            required
            options={[
              {
                label: 'Male',
                value: 'm',
              },
              {
                label: 'Female',
                value: 'f',
              },
            ]}
          />
        </div>
      </FormProvider>
      <Button submit className="mr3" disabled={saving} testId="edit-subject__save">
        Save
      </Button>
      <Button plain onClick={closeModal}>
        Cancel
      </Button>
    </form>
  );
};

interface EditSubjectNameFieldProps {
  name: string;
  label: string;
  studyApiId: StudyApiId;
  animalId?: AnimalApiId;
  required?: boolean;
}

const EditSubjectNameField: FC<EditSubjectNameFieldProps> = ({
  name,
  label,
  studyApiId,
  animalId,
  required = false,
}) => {
  const { register, errors } = useFormContext();
  const state = useSelector((state: State) => state?.team);
  const rules: { validate?: (value: string) => Promise<boolean | string> } = {};

  if (_isNil(animalId) || _isNil(studyApiId)) {
    return null;
  }

  if (name === 'tag' && state?.features?.assign_identifiers_tag_unique === true) {
    rules.validate = async (value) => {
      try {
        const result = await checkStudyForDuplicates(value, 'tag', studyApiId, animalId);
        return result ? true : 'This identifier is already in use';
      } catch (error) {
        return 'An error occurred';
      }
    };
  } else {
    rules.validate = async (value: string) =>
      required && _isEmpty(value) ? `${startCase(name)} cannot be empty` : true;
  }

  return (
    <div className="mb3">
      <label htmlFor={name}>{`${label}${required ? ' (required)' : ''}`}</label>
      <input
        data-testid={`edit-subject-${name}-input`}
        type="text"
        className={`${errors[name] ? 'input__error' : ''}`}
        name={name}
        ref={register(rules)}
      />
      <ErrorMessage errors={errors} name={name} render={({ message }) => <p className="f6 red lh-copy">{message}</p>} />
    </div>
  );
};

export default EditSubject;
