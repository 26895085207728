import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button/Button';
import Header from '@/components/UI/Header';
import APITable from '@/components/UI/Table/Reusable/APITable/APITable';
import type { UseAPITableProps, UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import { updateColumns, updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { _isNotEmpty } from '@/littledash';
import { State } from '@/model/State.model.ts';
import type { StudyCode, StudyCodeApiId } from '@/model/StudyCode.model';
import { ApiService } from '@/support/ApiService';
import { web as webRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { generateStudyCodeColumns } from './StudyCode.utils';

const StudyCodeList = () => {
  const [columns, setColumns] = useState<Array<Column<StudyCode>>>([]);
  const dispatch = useDispatch();

  const settings = useSelector((state: State) => state.ui.settings);

  const updatedSettings = useMemo(() => {
    if (_isNotEmpty(columns)) {
      return updateSettings('studycodes', columns, settings);
    }
    return settings;
  }, [columns]);

  const handleStudyCodeDelete = async (studyCodeApiIds: Array<StudyCodeApiId>) => {
    try {
      await ApiService.call({
        endpoint: 'DELETE /api/v1/team/study-codes',
        body: { study_code_api_ids: studyCodeApiIds },
      });
      toast.success('Successfully deleted!');
    } catch (error) {
      toast.error('There was a problem deleting study codes, please try again later.');
    }
  };

  useEffect(() => {
    if (_isNotEmpty(columns)) {
      const updatedColumns = updateColumns('studycodes', columns, settings);
      setColumns(updatedColumns);
    }
  }, [settings?.tables]);

  useEffect(() => {
    setColumns(generateStudyCodeColumns());
  }, []);

  const bulkActions = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { setUpdating, fetchTableData },
    },
  }: UseTableProps<StudyCode>) => {
    return [
      {
        name: 'Delete',
        key: 'delete_study_codes',
        className: 'red',
        action: () => {
          const studyCodeApiIds = selectedFlatRows.map(({ original: { api_id } }) => api_id);
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'CONFIRM_DELETE_STUDY_CODES',
            props: {
              onClick: () => {
                setUpdating(true);
                handleStudyCodeDelete(studyCodeApiIds)
                  .then(() => fetchTableData())
                  .catch(() => setUpdating(false));
              },
              length: studyCodeApiIds.length,
            },
          });
        },
      },
    ];
  };

  const NoDataComponent: React.FC<UseAPITableProps<StudyCode>> = () => (
    <NoDataCard
      title="Study codes will appear here"
      text="Study codes listed here can be added by team admins"
      NoDataComponent={
        <Button className="mt3" url={webRoute('team.studies.study_codes.create')}>
          Create a study code
        </Button>
      }
    />
  );

  const AsideComponent: React.FC<UseTableProps<StudyCode>> = () => (
    <Button className="ml2" url={webRoute('team.studies.study_codes.create')}>
      Create a study code
    </Button>
  );

  return (
    <>
      <div>
        <Header mainHeaderText="Study codes" />
        <p className="f6 pb2">
          Set up study codes templates for use in your research.
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="https://help.benchling.com/hc/en-us/articles/20162173756813-Study-Codes"
          >
            Read more
          </a>
        </p>
      </div>
      <div className="mv3">
        <div className={`ui-card treatmentsGlossary`}>
          <div>
            <APITable
              NoDataComponent={NoDataComponent}
              AsideComponent={AsideComponent}
              bulkActions={bulkActions}
              apiInfo={{
                type: 'internalApi',
                route: RouteService.api({
                  endpoint: 'GET /api/v1/team/study-codes',
                  path: undefined,
                }).url,
              }}
              settings={updatedSettings}
              reduxTableName="studycodes"
              searchPlaceholderText="Search by study code title"
              columns={columns.filter((col) => col.Header)}
              defaultSortBy={{ id: 'title', desc: false }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyCodeList;
