import { _isNil, _notNil } from '@/littledash';
import { Order } from '@/model/Common.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import type {
  BlockAllocationServiceProps,
  BlockRandomizationAnimal,
  BlockRandomizationResult,
  IBlockAllocationService,
} from './BlockAllocationService.model';

class BlockAllocationService implements IBlockAllocationService {
  readonly #animals: Readonly<BlockAllocationServiceProps['animals']>;
  readonly #treatmentGroups: Readonly<BlockAllocationServiceProps['treatmentGroups']>;
  readonly #groupSizes: Readonly<BlockAllocationServiceProps['groupSizes']>;
  readonly #metric: Readonly<BlockAllocationServiceProps['metric']>;
  constructor({ animals, treatmentGroups, groupSizes, metric }: BlockAllocationServiceProps) {
    this.#animals = animals;
    this.#treatmentGroups = treatmentGroups;
    this.#groupSizes = groupSizes;
    this.#metric = metric;
  }
  public filterFullGroups(): Readonly<Array<TreatmentGroup>> {
    return Object.freeze([
      ...(this.#treatmentGroups ?? []).filter((group: TreatmentGroup) => this.#groupSizes?.[group?.api_id ?? ''] > 0),
    ]);
  }
  get availableGroups(): Readonly<Array<TreatmentGroup>> {
    return Object.freeze(this.filterFullGroups());
  }
  public sortAnimals(order: Order = Order.asc): Readonly<BlockAllocationServiceProps['animals']> {
    return Object.freeze(
      [...this.#animals].sort(
        (a: BlockRandomizationAnimal, b: BlockRandomizationAnimal) =>
          (order === Order.asc ? a : b)?.measurement_info?.[this.#metric] -
          (order === Order.asc ? b : a)?.measurement_info?.[this.#metric]
      )
    );
  }
  public orderGroups(reversed: boolean = false): Readonly<BlockAllocationServiceProps['treatmentGroups']> {
    return Object.freeze([...(reversed ? Array(...this.availableGroups).reverse() : this.availableGroups)]);
  }
  public spacesLeftInGroup(maxAllocation: number, allocationCount: number): number {
    return Object.freeze(Math.max(maxAllocation - allocationCount, 0));
  }
  public blockAllocation(): BlockRandomizationResult {
    let isBlockReversed = false;
    let groupAllocations: BlockRandomizationResult['groupAllocations'] = {};
    let groupIndexCount = 0;
    const allocatedAnimals: BlockRandomizationResult['allocatedAnimals'] = this.sortAnimals().map(
      (animal, animalIndex) => {
        // Where the remainder is 0 we are at the start of a block, reverse the group order
        const startOfChunk = animalIndex % this.availableGroups.length;
        if (startOfChunk === 0 && animalIndex !== 0) {
          isBlockReversed = !isBlockReversed;
          groupIndexCount = 0;
        }
        let allocatedGroup: TreatmentGroup | null = this.orderGroups(isBlockReversed)?.[groupIndexCount] ?? null;
        if (_isNil(groupAllocations?.[allocatedGroup?.api_id ?? 'grp_'])) {
          groupAllocations = {
            ...groupAllocations,
            [allocatedGroup?.api_id ?? 'grp_']: [],
          };
        }
        // If there is no space left in the allocated group, find another with space
        if (
          this.spacesLeftInGroup(
            this.#groupSizes?.[allocatedGroup?.api_id ?? 'grp_'] ?? 0,
            groupAllocations[allocatedGroup?.api_id ?? 'grp_']?.length ?? 0
          ) <= 0
        ) {
          allocatedGroup =
            this.orderGroups(isBlockReversed)?.find(
              (group: TreatmentGroup) =>
                this.spacesLeftInGroup(
                  this.#groupSizes?.[group?.api_id ?? 'grp_'] ?? 0,
                  groupAllocations[group?.api_id ?? 'grp_']?.length ?? 0
                ) > 0
            ) ?? null;
          // set the groupIndexCount to the allocated group so that we don't allocate to the same group twice on the next animal
        }
        if (_notNil(allocatedGroup)) {
          // keep track of the group allocations for future
          groupAllocations = {
            ...groupAllocations,
            [allocatedGroup?.api_id ?? 'grp_']: [
              ...(groupAllocations?.[allocatedGroup?.api_id ?? 'grp_'] ?? {}),
              animal,
            ],
          };
        }
        // increment the index count for the next group to be chosen
        groupIndexCount++;
        return {
          ...(animal ?? {}),
          study_group_id: allocatedGroup?.api_id ?? 'grp_',
        };
      }
    );
    return Object.freeze({ allocatedAnimals, groupAllocations });
  }
}

export default BlockAllocationService;
