import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import type { StudyDraft } from '@/model/Draft.model';
import { ApiService } from '@/support/ApiService';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute, api as apiRoutes, web as webRoutes } from '@/support/route';
import type { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface DraftProps {
  page: string;
  history: History;
}

interface ComponentProps extends DraftProps {
  draft?: StudyDraft;
}

const withDraft = (Component: React.FC<ComponentProps>) => (props: DraftProps) => {
  const [loadingDraft, setLoadingDraft] = useState<boolean>(true);
  const [apiError, setApiError] = useState<AxiosResponse<StudyDraft> | undefined | false>(false);
  const [draft, setDraft] = useState<StudyDraft | undefined>(undefined);
  const [draftError, setDraftError] = useState<AxiosError | false>(false);
  const location = useLocation();
  const history = useHistory();
  const draftId = new URLSearchParams(location.search).get('draftId');
  const isMounted = useMountedState();

  const fetchDraft = async () => {
    try {
      const {
        data: { data: draft },
      }: {
        data: {
          data: StudyDraft;
        };
      } = await Http.get(apiRoute('drafts.studies.get', { id: draftId }));
      if (draft?.form_data?.project_id) {
        const {
          data: { data: project },
        } = await Http.get(apiRoute('projects.get', { projectId: draft.form_data.project_id }));
        if (project) {
          draft.selectedProjectName = project.name;
        }
      }
      if (draft?.form_data?.study_code_id) {
        const studyCodeApiId = draft.form_data.study_code_id;
        try {
          const code = await ApiService.call({
            endpoint: 'GET /api/v1/team/study-codes/{studyCodeApiId}',
            path: { studyCodeApiId },
          });

          if (code.type === 'success') {
            draft.selectedStudyCode = code.body;
          }
        } catch (ex) {
          if (isMounted()) {
            setApiError(ex as AxiosResponse);
          }
        }
      }

      const { data: fileData } = await Http.get(apiRoute('drafts.files', { id: draftId }));

      draft.form_data.files = fileData;

      setDraft(draft);
    } catch (error) {
      if ((error as AxiosError).response?.status === 409) {
        setDraftError(error as AxiosError);
      } else {
        setApiError((error as AxiosError<StudyDraft>).response);
      }
    } finally {
      setLoadingDraft(false);
    }
  };

  const handleDraftsDelete = async () => {
    try {
      await Http.delete(apiRoutes('drafts.studies.delete', { id: draftId }));
    } catch (error) {
      setApiError((error as AxiosError<StudyDraft>).response);
    } finally {
      history.push(webRoutes('dashboard'));
    }
  };

  useEffect(() => {
    if (draftId) {
      fetchDraft();
    } else {
      setLoadingDraft(false);
    }
  }, []);

  return (
    <>
      {draftError && (
        <ApiErrorBanner
          className="ml3"
          title="This draft is invalid"
          // @ts-expect-error: mapped from ts-ignore
          text={draftError.response?.data.message ?? 'Please restart study creation process'}
          altButtonFunc={() => handleDraftsDelete()}
          altButtonText="Delete draft"
        />
      )}
      {apiError && <ApiErrorBanner error={apiError} />}
      {draftId && loadingDraft && (
        <div className="w-100 h-100">
          <Loading txt="Fetching draft..." />
        </div>
      )}
      {!loadingDraft && !draftError && <Component draft={draft} {...props} />}
    </>
  );
};

export default withDraft;
