// @ts-nocheck: converted from JS

import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { Fragment } from 'react';
import ApprovedTable from './ApprovedTable';
import PendingTable from './PendingTable';

const Approvals = () => {
  return (
    <Fragment>
      <div className="ui-title-bar-container flex-l justify-between-l flex-wrap-l pa3 pa4-l">
        <div>
          <h1 className="f3 normal">Reviews</h1>
        </div>
      </div>
      <Tabs>
        <div className="bb b--moon-gray mh3 mh4-l">
          <Tab className="f5 f4-l">Pending</Tab>
          <Tab className="f5 f4-l">Complete</Tab>
        </div>
        <Panel>
          <PendingTable />
        </Panel>
        <Panel>
          <ApprovedTable />
        </Panel>
      </Tabs>
    </Fragment>
  );
};

export default Approvals;
