// @ts-nocheck: converted from JS

import FormBuilder from '@/components/FormBuilder';
import {
  mapApprovalDataToFormBuilderData,
  mapFormBuilderDataToApprovalData,
} from '@/components/Glossary/Sections/Approvals/TemplateForm/ApprovalForm.utils';

const ApprovalForm = ({ ActionButtons, onSubmit, approval }) => {
  const handleSubmit = (data) => {
    onSubmit({
      form: mapFormBuilderDataToApprovalData(data),
    });
  };

  return (
    <div data-test-component="ApprovalForm" data-test-element="container">
      <FormBuilder
        ActionButtons={ActionButtons}
        onSubmit={handleSubmit}
        defaultValue={approval?.form ? mapApprovalDataToFormBuilderData(approval.form) : undefined}
      />
    </div>
  );
};

export default ApprovalForm;
