import { useEffect, useMemo } from 'react';
import Banner from '@/components/UI/Banner';
import Radio from '@/components/UI/FormElements/Radio';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { ISODate } from '@/model/Common.model';
import { RDOptions, RandomizationDateProps, ValidateAnimals } from './RandomizeByDate.model';
import { _isNotBlank, _isNotEmpty, _notNil } from '@/littledash';
import { getEnabledCalculations, isNotValidResult, validateAnimals } from './RandomizeByDate.utils';
import Link from '@/components/UI/Link';
import { web as webRoute } from '@/support/route';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { AnimalApiId } from '@/model/Animal.model';
import { useCallback } from 'react';
import _debounce from 'lodash/debounce';
import { useState } from 'react';

const DATE_DEBOUNCE_WAIT = 500;

const RandomizeByDate = ({ state, dispatch, props }: RandomizationDateProps) => {
  const {
    originalSelectedSubjects: animals,
    randomizeByDate: {
      rdSelection,
      randomizeDate,
      validStudyGroups,
      validAnimals: { validationMessages, excludedAnimals, includedAnimals },
    },
  } = state;
  const [dateInput, setDateInput] = useState<string>(randomizeDate ?? '');
  const initialValidate: ValidateAnimals = useMemo(() => validateAnimals(animals), [animals]);
  const selectedAnimalApiids = useMemo(() => {
    const apiIds: AnimalApiId[] = [];
    for (const animal of animals) {
      if (_notNil(animal.api_id)) {
        apiIds.push(animal.api_id);
      }
    }
    return apiIds;
  }, [animals]);

  const { loading, invoke: getMeasurementsOnDate } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyId}/measurements-on-date',
    path: { studyId: props.study.api_id },
    invokeOnInit: false,
  });

  useEffect(() => {
    if (_notNil(dispatch)) {
      dispatch({ type: 'stepReady' });
    }
  }, [dispatch]);

  const handleSelectionChange = (rdSelection: RDOptions): void => {
    let updatedRandomizeByDate = {
      ...state.randomizeByDate,
      rdSelection,
    };
    if (rdSelection === RDOptions.LatestMeasurements) {
      updatedRandomizeByDate = {
        ...updatedRandomizeByDate,
        randomizeDate: null,
        validAnimals: initialValidate,
        measurementsOnDate: null,
        enabledCalculations: getEnabledCalculations(initialValidate.includedAnimals, props.study.settings.calculations),
      };
    }
    dispatch({
      type: 'update',
      id: 'randomizeByDate',
      data: updatedRandomizeByDate,
    });
  };

  const onDateInput = async (date: ISODate): Promise<void> => {
    const response = await getMeasurementsOnDate({
      path: { studyId: props.study.api_id },
      body: { date, animal_api_ids: selectedAnimalApiids },
    });
    if (_notNil(response?.body)) {
      const reValidateAnimals = validateAnimals(animals, response.body);
      const enabledCalculations = getEnabledCalculations(
        reValidateAnimals.includedAnimals,
        props.study.settings.calculations,
        response.body
      );
      dispatch({
        type: 'update',
        id: 'randomizeByDate',
        data: {
          ...state.randomizeByDate,
          measurementsOnDate: response?.body,
          validAnimals: reValidateAnimals,
          randomizeDate: date,
          enabledCalculations,
        },
      });
    }
  };

  const debounceDateInput = useCallback(_debounce(onDateInput, DATE_DEBOUNCE_WAIT), [state.randomizeByDate]);

  const handleDateInput = (date: ISODate): void => {
    if (_isNotBlank(date) && !loading) {
      setDateInput(date);
      debounceDateInput(date);
    } else {
      dispatch({
        type: 'update',
        id: 'randomizeByDate',
        data: {
          ...state.randomizeByDate,
          measurementsOnDate: null,
          validAnimals: initialValidate,
          randomizeDate: null,
        },
      });
    }
  };

  if (isNotValidResult(validStudyGroups)) {
    return (
      <div className="ui-card pa3 center mw7 mv4">
        <div className="pa5 br3 bg-near-white tc" data-testid="groups-invalid-message">
          <h3 className="lh-title f4 basier-reg normal pv3">{validStudyGroups}</h3>
          <Link className="dib blue mb3 link f4" to={webRoute('studies.studyGroups', { id: props.study.id })}>
            Add study groups
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="ui-card pa4 mw7" data-testid="randomize-by-date">
      <h2 className="lh-solid f4 pb3">Select date for randomization</h2>
      {_isNotEmpty(validationMessages) && _isNotEmpty(excludedAnimals) && (
        <Banner info dismiss={false} className="mt3" testId="randomize-date-banner">
          <h3 className="f6 lh-copy fw5 pb3">
            {excludedAnimals.length} animal(s) {excludedAnimals.length !== 1 ? 'have' : 'has'} been excluded for the
            following reason(s):
          </h3>
          <ul className="o-list ml3 f6 lh-copy near-black">
            {validationMessages.map((message: string) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </Banner>
      )}
      <div className="flex pt4">
        <div className={`${loading ? 'ui__disabled' : ''} w-50 pr5`}>
          <Radio
            name="rd-measurement-type"
            label="Use latest measurements"
            sublabel="May be across different dates."
            id={RDOptions.LatestMeasurements}
            value={RDOptions.LatestMeasurements}
            className="mb3"
            checked={rdSelection === RDOptions.LatestMeasurements}
            onChange={() => handleSelectionChange(RDOptions.LatestMeasurements)}
            data-testid="latest-measurement-radio-option"
          />
          <Radio
            name="rd-measurement-type"
            label="Specify a date"
            sublabel="Any animals without measurement data on the selected date will be excluded."
            id={RDOptions.SpecifyDate}
            value={RDOptions.SpecifyDate}
            className="mb3"
            checked={rdSelection === RDOptions.SpecifyDate}
            onChange={() => handleSelectionChange(RDOptions.SpecifyDate)}
            data-testid="specify-date-radio-option"
          />
          {rdSelection === RDOptions.SpecifyDate && (
            <div className="ml4">
              <label htmlFor="randomize-date">Date</label>
              <DatePickerNative
                name="randomize-date"
                value={dateInput}
                onChange={handleDateInput}
                autoFocus
                disabled={loading}
                testId="randomize-date-picker"
              />
            </div>
          )}
        </div>
        <div className="w-50">
          <div>
            <div className="pb3" data-testid="metric-selected-animals">
              <p className="f6 lh-copy pb2">Animals selected</p>
              <span className="f3 lh-title near-black">{animals.length}</span>
            </div>
            <div className="pb3" data-testid="metric-included-animals">
              <p className="f6 lh-copy pb2">To be excluded</p>
              <span className="f3 lh-title near-black">{excludedAnimals.length}</span>
            </div>
            <div className="pb3" data-testid="metric-excluded-animals">
              <p className="f6 lh-copy pb2">To be included</p>
              <span className="f3 lh-title near-black">{includedAnimals.length}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomizeByDate;
