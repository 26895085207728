import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal.tsx';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form@latest';

export const WeightTitleEditModal: FC<{
  name: string;
  onUpdate: (data: { name: string }) => void;
  onCancel: () => void;
}> = ({ name, onUpdate, onCancel }) => {
  const formMethods = useForm({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues: { name } });

  return (
    <span data-test-component="WeightTitleEditModal">
      <ModalContainer size="xnarrow">
        <ModalHeader title="Edit measurment" closeModal={onCancel} className="pa3 bb b--moon-gray bg-white" />
        <div className="ph3 pv2 mt2">
          <label>Name</label>
          <Controller
            control={formMethods.control}
            name="name"
            rules={{
              required: 'Name is required',
              maxLength: { value: 32, message: 'Name must be less than 32 characters' },
            }}
            render={({ field, fieldState, formState }) => (
              <>
                <input
                  type="text"
                  {...field}
                  maxLength={32}
                  autoComplete="off"
                  className={cn({ ui__error: fieldState.invalid })}
                  data-test-element="name-input"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="name"
                  render={({ message }) => (
                    <p className="f6 red db" data-test-element="name-error">
                      {message}
                    </p>
                  )}
                />
              </>
            )}
          />
        </div>
        <ModalActions
          className="pa3 mt3 bt b--moon-gray"
          onSubmit={formMethods.handleSubmit(onUpdate)}
          onCancel={onCancel}
          submitButtonProps={{
            disabled:
              !formMethods.formState.isValid || !formMethods.formState.isDirty || formMethods.formState.isSubmitting,
          }}
        />
      </ModalContainer>
    </span>
  );
};
