// @ts-nocheck: converted from JS

import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import MetadataButton from '@/components/UI/MetadataButton/MetadataButton';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import UserAvatar from '@/components/UI/UserAvatar';
import { capitalise, returnUserFromStudy } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import * as Auth from '@/support/auth';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { useDispatch } from 'react-redux';
import { modalAction } from '@/utils/modal';

export const SamplesList = ({ study, samples, handleCallback }) => {
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  return (
    <div>
      {_isNotEmpty(samples) &&
        samples.map((sample, i) => {
          const dateToDisplay = _isNotEmpty(sample.updated_by) ? sample.updated_by : sample.created_by;
          const user = returnUserFromStudy(study, dateToDisplay?.id);

          const confirmDelete = (reasonForDelete?: string) => {
            return Http.delete(apiRoutes('samples.destroy', { id: sample.id }), {
              data: {
                ...(_notNil(reasonForDelete) ? { reason_for_change: reasonForDelete } : {}),
              },
            }).then(() => {
              if (handleCallback) {
                handleCallback('Successfully deleted sample');
                closeModal();
              } else {
                closeModal();
              }
            });
          };

          return (
            <div key={sample.id} className={`${i !== 0 ? 'bt b--moon-gray' : ''} flex pa3`}>
              {user && <UserAvatar user={user} tooltip={user.name ?? user.email} />}
              <div className="w-100 pl3">
                <div className="pb3 justify-between flex">
                  <div>
                    <span className="f7 lh-copy db">
                      <DateTimeRenderer value={sample.date} />
                    </span>
                    {_notNil(sample.updated_by) && (
                      <span className="f7 lh-copy db">
                        Edited <DateTimeRenderer value={sample.updated_by.datetime} /> by{' '}
                        {sample.updated_by.name ?? sample.updated_by.email}
                      </span>
                    )}
                    <h3 className="f5 normal basier-reg lh-copy w-80 mv2">{sample.sample_id}</h3>
                    <p className="lh-title">{sample.type}</p>
                  </div>
                  <MetadataButton entity={sample} entityName="sampleMeta" tooltip="Sample metadata" />
                </div>
                <div className="f6 line-copy">
                  {_isNotEmpty(sample.details) &&
                    sample.details.map(({ key, value, unit }, i) => (
                      <div key={i} className="pb2">
                        <span className="dib near-black">{capitalise(key)}:</span>
                        <span className="dib ml2">
                          {value} {unit}
                        </span>
                      </div>
                    ))}
                  {sample.comments && (
                    <p className="pa3 mv2 br3 br--top--left bg-near-white mw6 dib">{sample.comments}</p>
                  )}
                  {Auth.isWriteUserForStudy(study) && (
                    <div>
                      <SelectDropDown title="Actions" className="f6 basier-reg pl0 stateless blue" alignMenu="left">
                        <ActionList
                          actions={[
                            {
                              action: () =>
                                openModal('EDIT_SAMPLE', {
                                  sample,
                                  closeModal,
                                  handleAfterClose: handleCallback,
                                }),
                              name: 'Edit',
                            },
                            {
                              action: () =>
                                openModal('CONFIRM_DELETE_SAMPLE', {
                                  onClick: confirmDelete,
                                  closeModal,
                                }),
                              name: 'Delete',
                            },
                          ]}
                        />
                      </SelectDropDown>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SamplesList;
