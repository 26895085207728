// @ts-nocheck: converted from JS

import Label from '@/components/UI/Label';

const ObservationScore = ({ type = '', score = '' }) => {
  const typeMap = {
    Mild: 'success',
    Moderate: 'warning',
    Severe: 'critical',
  };
  return <>{type === 'numeric' ? <Label type="info" text={score} /> : <Label type={typeMap[score]} text={score} />}</>;
};

export default ObservationScore;
