// @ts-nocheck: converted from JS

import { DateRenderer, DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { calculateAge, fetchMetadataColumns } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model';
import { ID } from '@/model/Common.model';
import { web as webRoute } from '@/support/route';
import { isClosed } from '@/support/study';
import { generateMetadataFilterOptions } from '../Cages/Cages.utils';
import { filterOptionsFromApi } from '../Colony.utils';

export interface AnimalFiltersEntity {
  fields: Array<{
    name: string;
    value: string;
    options: Array<Record<string, number>>;
  }>;
  metadata: Array<AnimalMetadataFilter>;
}

export interface AnimalMetadataFilter {
  id: ID;
  name: string;
  slug: string;
  field_type: MetadataFieldTypes;
}

export const generateColumns = ({ metadata }, columns) => {
  const cols = [
    {
      id: 'id', // to become `number`,
      accessor: 'id', // to become `number`,
      isVisible: false,
      lockVisibility: true,
    },
    {
      id: 'catalog',
      Header: 'Animal No.',
      accessor: 'catalog', // to become `number`,
      sortBy: 'catalog',
      isVisible: columns.catalog ?? false,
    },
    {
      id: 'name',
      Header: 'Animal Name',
      sortBy: 'name',
      accessor: 'name',
      isVisible: columns.name ?? false,
    },
    {
      id: 'cage',
      Header: 'Cage No.',
      sortBy: 'cage',
      accessor: 'collection.data.catalog',
      isVisible: columns.cage ?? false,
      Cell: ({ row }) => (_isNil(row?.original?.terminated_at) ? row?.original?.collection?.data?.catalog : 'Deceased'),
    },
    {
      id: 'collection',
      Header: 'Cage Name',
      sortBy: 'collection',
      accessor: 'collection.data.name',
      isVisible: columns.collection ?? false,
      Cell: ({ row }) => (_isNil(row?.original?.terminated_at) ? row?.original?.collection?.data?.name : 'Deceased'),
    },
    {
      id: 'deceased',
      Header: 'Deceased date',
      sortBy: 'deceased',
      isVisible: columns['deceased'] ?? false,
      accessor: 'terminated_at',
      Cell: ({
        row: {
          original: { terminated_at },
        },
      }) => <DateRenderer value={terminated_at} defaultResponse="" />,
    },
    {
      id: 'deceased_reason',
      Header: 'Deceased reason',
      sortDisabled: true,
      accessor: 'terminated_at_data.termination_title',
      isVisible: columns['deceased_reason'] ?? false,
    },
    {
      id: 'deceased_comment',
      Header: 'Deceased comment',
      sortDisabled: true,
      accessor: 'terminated_at_data.termination_value',
      isVisible: columns['deceased_comment'] ?? false,
      Cell: ({
        row: {
          original: { terminated_at_data },
        },
      }) => (
        <p className="truncate">
          {_notNil(terminated_at_data?.termination_value) ? terminated_at_data.termination_value : ''}
        </p>
      ),
    },
    {
      id: 'tailId',
      Header: 'Tail ID',
      accessor: 'alt_ids.tail',
      sortDisabled: true,
      isVisible: columns.tailId ?? false,
    },
    {
      id: 'donorId',
      Header: 'Donor ID',
      accessor: 'alt_ids.donor',
      sortDisabled: true,
      isVisible: columns.donorId ?? false,
    },
    {
      id: 'earId',
      Header: 'Ear ID',
      accessor: 'alt_ids.ear',
      sortDisabled: true,
      isVisible: columns.earId ?? false,
    },
    {
      id: 'tagId',
      Header: 'Tag ID',
      accessor: 'alt_ids.tag',
      sortDisabled: true,
      isVisible: columns.tagId ?? false,
    },
    {
      id: 'sex',
      Header: 'Sex',
      sortBy: 'sex',
      accessor: 'sex',
      isVisible: columns.sex ?? false,
      Cell: ({
        row: {
          original: { sex },
        },
      }) => {
        if (sex) {
          return sex === 'f' ? 'Female' : 'Male';
        }
        return '';
      },
    },
    {
      id: 'dob',
      Header: 'D.O.B',
      sortBy: 'dob',
      accessor: 'dob',
      isVisible: columns.dob ?? false,
      Cell: ({ row: { original } }) => <DateRenderer value={original.dob} />,
    },
    {
      id: 'time',
      Header: 'Age',
      sortBy: 'time',
      accessor: 'dob',
      isVisible: columns.time ?? false,
      Cell: ({
        row: {
          original: { dob, terminated_at: terminatedAt },
        },
      }) => calculateAge(dob, terminatedAt),
    },
    {
      id: 'species',
      Header: 'Species',
      sortBy: 'species',
      accessor: 'species_name',
      isVisible: columns.species ?? false,
    },
    {
      id: 'strain',
      Header: 'Strain',
      sortBy: 'strain',
      accessor: 'strain_name',
      isVisible: columns.strain ?? false,
    },
    {
      id: 'date',
      Header: 'Created',
      accessor: 'created_at',
      sortBy: 'date',
      isVisible: columns.date ?? false,
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
    },
    {
      id: 'study',
      Header: 'Study',
      accessor: 'study_id',
      sortBy: 'study',
      isVisible: columns.study ?? false,
      Cell: ({ row: { original } }) => {
        const studyData = original['study_link']?.data;
        const studyName = studyData?.name ?? '';
        return _notNil(studyData?.access) ? (
          <Link className="link blue" to={webRoute('studies.show', { id: studyData?.id })}>
            {studyName}
          </Link>
        ) : (
          studyName
        );
      },
    },
    ...fetchMetadataColumns({ metadata, columns }),
  ];

  return cols;
};
const getFilterOperation = (name) => Object.keys(filterType).find((key) => filterType[key] === name);

export const generateFilterParams = (filters, filterMatch) =>
  filters.reduce(
    (acc, filter) => {
      if (!filter.category.value.includes('metadata.') && filter.option.value !== '') {
        acc = `${acc}&filters[]=${filter.category.value}|${getFilterOperation(filter.operation.name)}|${
          filter.option.value
        }`;
      }
      return acc;
    },
    `filterType=${filterMatch ? 'and' : 'or'}`
  );

export const getParamsFromState = ({
  searchQuery,
  filters,
  filterMatch,
  order,
  sort,
  per_page: perPage,
  current_page: currentPage,
}) => {
  let result = '';
  if (searchQuery) {
    result = `${result}&query=${searchQuery}`;
  }
  if (!_isEmpty(filters)) {
    result = `${result}&${generateFilterParams(filters, filterMatch)}`;
  }
  if (sort) {
    result = `${result}&sort=${sort}`;
  }
  result = `${result}&order=${order}&page=${currentPage}&perPage=${perPage}`;

  return result;
};

export const validateManageAnimalsSelection = (
  validation: {
    hasWritePermission: boolean | true;
    isNotDeceased: boolean | true;
    hasActiveStudy: boolean | false;
    hasIdenticalStudy: boolean | false;
  },
  selectedAnimals: Array<Animal> | undefined = [],
  limit: number = 300
): { disabled: boolean; tooltip: string } => {
  const defaultResult = {
    disabled: false,
    tooltip: '',
  };

  if (_isEmpty(selectedAnimals)) {
    return defaultResult;
  }

  if (selectedAnimals.length > limit) {
    return {
      disabled: true,
      tooltip: `You can only manage ${limit} animals at a time`,
    };
  }

  if (validation.hasWritePermission) {
    const hasPermissions = selectedAnimals.every(
      (a) => a['study_link']?.data.access === 'write' || _isNil(a['study_id'])
    );

    if (_isNotEmpty(selectedAnimals) && !hasPermissions) {
      return {
        disabled: true,
        tooltip: 'You must have at least write access to the study to manage animals',
      };
    }
  }

  if (validation.isNotDeceased) {
    const hasDeceased = selectedAnimals.some(
      (animal) =>
        _notNil(animal.terminated_at) || _isNil(animal.cage_id) || animal.collection?.data?.name === 'Deceased'
    );
    if (hasDeceased) {
      return {
        disabled: true,
        tooltip: 'This action cannot be performed on deceased animals',
      };
    }
  }

  if (validation.hasIdenticalStudy && !animalsInSameStudy(selectedAnimals)) {
    return {
      disabled: true,
      tooltip: 'All animals must be assigned to the same study',
    };
  }

  if (validation.hasActiveStudy) {
    const hasClosedStudy = selectedAnimals.some((animal) => isClosed(animal['study_link']?.data));

    if (hasClosedStudy) {
      return {
        disabled: true,
        tooltip: 'Animals must not be assigned to archived or completed studies',
      };
    }
  }

  return defaultResult;
};

const animalsInSameStudy = (animals: Array<Animal>) => {
  const studyId = animals.find(Boolean)?.study_id;

  return !animals.some((animal) => animal.study_id !== studyId);
};

export const selectedAnimalsStudyIds = (selectedAnimals) => [
  ...new Set(
    selectedAnimals.reduce((studyIds, animal) => {
      if (animal?.study_id) {
        studyIds.push(animal.study_id);
      }
      return studyIds;
    }, [])
  ),
];

export const animalsHaveStudies = (selectedAnimals) =>
  selectedAnimalsStudyIds(selectedAnimals).some((studyId) => _notNil(studyId));

export const generateFilterOptions = (filters: AnimalFiltersEntity) => {
  const result = _isNotEmpty(filters.fields)
    ? filters.fields
        .filter((field: AnimalFilter) => field.name !== 'Cage')
        .map(({ name: animalFilterName, cage, value, options }) => {
          const fetchFromApi = filterOptionsFromApi(animalFilterName);
          if (fetchFromApi) {
            return {
              value,
              name: animalFilterName || cage,
              operations: [
                {
                  name: 'is equal to',
                  value: filterType.eq,
                  options: [],
                },
                {
                  name: 'is not equal to',
                  value: filterType.ne,
                  options: [],
                },
              ],
              fetchFromApi: {
                placeholder: 'Search studies',
                url: 'studies.base',
              },
            };
          } else {
            return {
              value,
              name: animalFilterName || cage,
              operations: [
                {
                  name: 'is equal to',
                  value: filterType.eq,
                  options: options,
                },
                {
                  name: 'is not equal to',
                  value: filterType.ne,
                  options: options,
                },
              ],
            };
          }
        })
    : [];

  const metadataFilterOptions = generateMetadataFilterOptions(filters.metadata);
  if (_isNotEmpty(metadataFilterOptions)) {
    result.push(...metadataFilterOptions);
  }
  return result;
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.data,
      };
    case 'SET_UPDATING':
      return {
        ...state,
        updating: action.data,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.data,
      };
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.data,
        current_page: 1,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selectedRows: action.data,
      };
    case 'SET_PAGING':
      return {
        ...state,
        current_page: state.per_page === action.data.per_page ? action.data.current_page : 1,
        per_page: action.data.per_page,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.data.filters,
        filterMatch: action.data.filterMatch,
        current_page: 1,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: action.data['sort'],
        order: action.data.order,
      };
    case 'SET_FILTER_OPTIONS':
      return {
        ...state,
        filterOptions: action.data,
      };
    case 'SET_COLUMNS':
      return {
        ...state,
        columns: action.data,
      };
    default: {
      return { ...state };
    }
  }
};
