// @ts-nocheck: converted from JS

import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import TableFilterMenu from '@/components/UI/SelectDropDown/Menus/TableFilterMenu';
import UseTableShowMenu from '@/components/UI/SelectDropDown/Menus/UseTableShowMenu';
import Spinner from '@/components/UI/Spinner';
import { useTableContext } from '@/components/UI/Table/TableContext';
import { _isEmpty, _isNotEmpty } from '@/littledash';

const ActionBar = ({
  bulkActions,
  searchQuery,
  setSearchQuery,
  filterOptions,
  filters,
  setFilters,
  filterMatch,
  setFilterMatch,
  searchPlaceholderText,
  updating,
  selectAllActive,
  handleSelectAllClick,
  searchQueryBy,
  setSearchQueryBy,
  searchQueryByOptions,
  settings,
  tableName,
  showMetricChangeFrom = false,
  hideSearch = false,
  hideFilter = false,
  hasSelectorColumn = false,
}) => {
  const {
    selectedFlatRows,
    isAllRowsSelected,
    totalItemCount,
    pageSizes,
    rows,
    state: { pageSize },
  } = useTableContext();
  const searchFilterBarProps = {
    searchPlaceholderText,
    searchBy: searchQueryBy,
    searchByOptions: searchQueryByOptions,
    setSearchBy: setSearchQueryBy,
    handleSearchInput: setSearchQuery,
    searchQuery,
    hideSearch,
    hideFilter,
    ShowMenu: () => (
      <UseTableShowMenu
        settings={settings}
        showMetricChangeFrom={showMetricChangeFrom}
        tableName={tableName}
        hasSelectorColumn={hasSelectorColumn}
      />
    ),
    FilterMenu: false,
  };

  if (_isNotEmpty(filterOptions)) {
    searchFilterBarProps.FilterMenu = () => (
      <TableFilterMenu
        filterOptions={filterOptions}
        filters={filters}
        filterMatch={filterMatch}
        onUpdateFilters={(filters, filterMatch) => {
          setFilters(filters);
          setFilterMatch(filterMatch);
        }}
      />
    );
  }

  const renderSelectAllLinkText = () => {
    if (selectAllActive) {
      if (updating) {
        return (
          <div className="flex items-center">
            <Spinner size="small" />{' '}
            <span className="ml2 lh-title">Fetching all table rows, this may take a moment...</span>
          </div>
        );
      }
      return 'Clear selection';
    }
    return `Select all ${selectedFlatRows.length}+ items in this table`;
  };

  const canSelectAll = (pageSizes.includes(1000) && pageSize === rows.length) || selectAllActive;

  const hideSearchAndFilter = hideSearch && hideFilter;
  return hideSearchAndFilter && _isEmpty(bulkActions) ? (
    <></>
  ) : (
    <div className="w-100">
      {!hideSearchAndFilter && <SearchFilterBar {...searchFilterBarProps} />}
      {_isNotEmpty(bulkActions) && (
        <div className={`w-100 flex items-center ${!hideSearch ? 'mt3' : ''}`}>
          {_isNotEmpty(selectedFlatRows) && (
            <div
              className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
              style={{
                whiteSpace: 'nowrap',
                lineHeight: '2.4rem',
              }}
            >
              {selectedFlatRows.length} selected
            </div>
          )}
          <SelectDropDown
            title="Bulk actions"
            className={`plain f6 ${_isNotEmpty(selectedFlatRows) ? 'br--right' : ''}`}
            alignMenu="right"
            disabled={_isEmpty(selectedFlatRows)}
            testId="animals__bulk-action--button"
          >
            <ActionList actions={bulkActions} testPrefix="animals__" />
          </SelectDropDown>
          {canSelectAll && isAllRowsSelected && (totalItemCount ?? 1000) > selectedFlatRows.length && (
            <a className={`blue link ml3 f6 ${updating ? 'pointer-events-none' : ''}`} onClick={handleSelectAllClick}>
              {renderSelectAllLinkText()}
            </a>
          )}
        </div>
      )}
    </div>
  );
};
export default ActionBar;
