// @ts-nocheck: converted from JS

import { useSelector } from 'react-redux';

const Assignees = ({ assignees }) => {
  const { team } = useSelector(({ team: { team } }) => ({
    team,
  }));
  const { users } = team;

  const getAssigneeName = (id) => users.find((user) => user.id === id).name;

  return (
    <div>
      {assignees.map((assigneeId) => (
        <div key={assigneeId}>{getAssigneeName(assigneeId)}</div>
      ))}
    </div>
  );
};

export default Assignees;
