import logo from '@/assets/images/logo.svg';
import Link from '@/components/UI/Link';
import { web as webRoute } from '@/support/route';
import './NoAuthHeader.scss';

const NoAuthHeader = () => {
  return (
    <header className="no-auth-header center w-100 mv4 mt6-l">
      <Link to={webRoute('login')}>
        <img className="dib" src={logo} alt="Benchling In Vivo" style={{ width: 150, height: 60 }} />
      </Link>
    </header>
  );
};

export default NoAuthHeader;
