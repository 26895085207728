// @ts-nocheck: converted from JS

import { _isNotEmpty } from '@/littledash';
import { ID } from '@/model/Common.model.ts';
import { State } from '@/model/State.model.ts';

export const updateColumns = (tableName, columns, settings) => {
  const columnSettings = settings?.tables[tableName]?.columns;
  const updatedColumns = columns.map((column) => {
    if (column.id in columnSettings) {
      column.isVisible = columnSettings[column.id] ?? false;
    }
    return column;
  });
  return updatedColumns;
};

export const updateSettings = (
  tableName: keyof State['ui']['settings']['tables'],
  columns: Array<{ id: ID }>,
  settings: State['ui']['settings']
) => {
  let columnSettings = settings?.tables[tableName]?.columns;
  if (_isNotEmpty(columns) && _isNotEmpty(columnSettings)) {
    columns.forEach(({ id }) => {
      if (!(id in columnSettings)) {
        columnSettings = { ...columnSettings, [id]: false };
      }
    });
  }

  return {
    ...settings,
    tables: {
      ...settings.tables,
      [tableName]: {
        ...settings.tables[tableName],
        columns: { ...columnSettings },
      },
    },
  };
};
