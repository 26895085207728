// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { web as webRoute } from '@/support/route';
import { useSelector } from 'react-redux';
import Animals from './Animals';
import Cages from './Cages';
import './Colony.scss';

const ColonyTable = () => {
  const { settings, features } = useSelector(({ ui: { settings }, team: { features } }) => ({
    settings,
    features,
  }));
  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  return (
    <div>
      <SubHeader linkToText="Dashboard" link={webRoute('dashboard')} />
      <div className="ph4 pb3">
        <Header
          mainHeaderText="Colony"
          asideComponent={() => (
            <>{!atunesIntegrationEnabled && <Button url={webRoute('colony.add')}>Add New</Button>}</>
          )}
        />
      </div>
      <Tabs>
        <div className="bb b--moon-gray mh3 mh4-l">
          <Tab className="f5 f4-l">Cages</Tab>
          <Tab className="f5 f4-l">Animals</Tab>
        </div>
        <Panel>
          <Cages settings={settings} />
        </Panel>
        <Panel>
          <Animals settings={settings} />
        </Panel>
      </Tabs>
    </div>
  );
};

export default ColonyTable;
