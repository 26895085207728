// @ts-nocheck: converted from JS

import { ApprovalSection } from '@/components/Studies/Show';
import Button from '@/components/UI/Button';
import FormRender from '@/components/UI/FormRender';
import { useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

const ApprovalForm = ({
  onSubmit,
  disabled = false,
  fields,
  groups,
  isSubmitting = false,
  defaultValue,
  isSubmitted,
}) => {
  const formMethods = useForm({
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    // once a form has been submitted and we render the read only format of the form, we need to reset the form default
    // values from the form submission response, so that when the read only components mount, they register with the values
    // from the form, not the original (blank) default values.
    if (defaultValue) {
      reset(defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormProvider {...formMethods}>
      <FormRender
        groupContainer={ApprovalSection}
        fields={fields}
        groups={groups}
        disabled={disabled}
        submitted={isSubmitted}
      />
      {!isSubmitted && (
        <Button
          className="mt3"
          type="submit"
          testId="approval-form-submit"
          onClick={handleSubmit(onSubmit)}
          disabled={disabled || isSubmitting}
        >
          {isSubmitting ? 'Submitting' : 'Submit'}
        </Button>
      )}
    </FormProvider>
  );
};

export default ApprovalForm;
