import { TimeUnit } from '@/components/UI/DataTable/DataTable.model';
import { Number as NumberField, Radio, Select, Text } from '@/components/UI/FormFields';
import Header from '@/components/UI/Header';
import List, { asListItem } from '@/components/UI/List';
import { _isNil } from '@/littledash';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFieldArray, useFormContext } from 'react-hook-form';
import { convertTimeInSecondsToUnit, getTimeInSeconds, timeUnitOptions } from '../Steps/Information.utils';

interface ManualTimeFieldItemProps {
  id: string;
  index: number;
  timepoint: number;
  unit: string;
}

export const Information = () => {
  const { watch, clearErrors, setValue, setError } = useFormContext();
  const watchType = watch('type');
  const timeType = watch('timeType');
  const timeEvery = watch('timeEvery');
  const timeStart = watch('timeStart');
  const timeDuration = watch('timeDuration');
  const timeEveryUnit = watch('timeEveryUnit');
  const timeStartUnit = watch('timeStartUnit');
  const timeDurationUnit = watch('timeDurationUnit');

  const getMinDuration = () => {
    const timeEverySeconds = getTimeInSeconds({ time: timeEvery, unit: timeEveryUnit });
    const timeInLocalUnit = convertTimeInSecondsToUnit({ seconds: timeEverySeconds, unit: timeDurationUnit });
    return timeInLocalUnit;
  };

  useEffect(() => {
    clearErrors();
    setValue('animalsLoaded', false);
  }, [watchType, timeType]);

  useEffect(() => {
    const timeStartSeconds = getTimeInSeconds({ time: timeStart, unit: timeStartUnit });
    const timeEverySeconds = getTimeInSeconds({ time: timeEvery, unit: timeEveryUnit });
    const timeDurationSeconds = getTimeInSeconds({ time: timeDuration, unit: timeDurationUnit });
    clearErrors('timeDuration');
    if (timeEverySeconds > timeDurationSeconds || timeStartSeconds > timeDurationSeconds) {
      const minimumEveryInLocalUnit = convertTimeInSecondsToUnit({ seconds: timeEverySeconds, unit: timeDurationUnit });
      const minimumStartInLocalUnit = convertTimeInSecondsToUnit({ seconds: timeStartSeconds, unit: timeDurationUnit });
      const minimumInLocalUnit =
        minimumStartInLocalUnit > minimumEveryInLocalUnit ? minimumStartInLocalUnit : minimumEveryInLocalUnit;
      setError('timeDuration', { type: 'custom', message: `Minimum of ${minimumInLocalUnit}` });
    }
  }, [timeEvery, timeEveryUnit, timeStart, timeStartUnit, timeDuration, timeDurationUnit]);

  return (
    <>
      <div className="ph4 pv3">
        <Header mainHeaderText="Create a data table" />
      </div>
      <div
        className="ui-card pa2 pb3 mw7 mv3 mh4"
        data-test-component="Information"
        data-test-element="info-form-container"
      >
        <div className="pa3" data-test-component="Information" data-test-element="name-input-container">
          <Text name="name" label="Table name" required maxLength={255} />
        </div>
        <div className="pa3" data-test-component="Information" data-test-element="type-select-container">
          <Select
            required
            name="type"
            label="Type"
            options={[
              {
                label: 'Custom',
                value: 'custom',
              },
              {
                label: 'Numeric',
                value: 'numeric',
              },
              {
                label: 'Timeseries',
                value: 'time',
              },
            ]}
          />
        </div>
        {watchType === 'numeric' && (
          <div className="pa3" data-test-component="Information" data-test-element="unit-input-container">
            <Text name="unit" label="Unit" required={true} />
          </div>
        )}
        {watchType === 'time' && (
          <>
            <div className="ph3 pt3" data-test-component="Information" data-test-element="timeseries-type-container">
              <Radio
                required
                name="timeType"
                label="Timepoint"
                options={[
                  {
                    label: 'Automatic',
                    value: 'automatic',
                  },
                  {
                    label: 'Manual',
                    value: 'manual',
                  },
                ]}
              />
            </div>
            {timeType === 'automatic' ? (
              <>
                <div
                  className="flex justify-apart pa2"
                  data-test-component="Information"
                  data-test-element="start-container"
                >
                  <div className="pa2">
                    <NumberField name="timeStart" label="Start" />
                  </div>
                  <div className="pa2">
                    <Select required name="timeStartUnit" label="Unit" options={timeUnitOptions} />
                  </div>
                </div>
                <div
                  className="flex justify-apart pa2"
                  data-test-component="Information"
                  data-test-element="every-container"
                >
                  <div className="pa2">
                    <NumberField name="timeEvery" label="Every" required min={1} />
                  </div>
                  <div className="pa2">
                    <Select required name="timeEveryUnit" label="Unit" options={timeUnitOptions} />
                  </div>
                </div>
                <div
                  className="flex justify-apart pa2"
                  data-test-component="Information"
                  data-test-element="end-container"
                >
                  <div className="pa2">
                    <NumberField name="timeDuration" label="End" required min={getMinDuration()} />
                  </div>
                  <div className="pa2">
                    <Select required name="timeDurationUnit" label="Unit" options={timeUnitOptions} />
                  </div>
                </div>
              </>
            ) : (
              <ManualTimeFields />
            )}
          </>
        )}
      </div>
    </>
  );
};

const ManualTimeFieldItem = ({ index, timepoint, unit }: ManualTimeFieldItemProps) => (
  <div
    className="flex justify-apart"
    data-test-component="ManualTimeFieldItem"
    data-test-element="container"
    data-test-key={`${index}`}
  >
    <div className="pa2" data-test-element="value-container">
      <NumberField
        value={timepoint}
        name={`manualTimepoints.${index}.timepoint`}
        testId="time-field-value"
        label="Timepoint"
        required
      />
    </div>
    <div className="pa2" data-test-element="unit-container">
      <Select value={unit} required name={`manualTimepoints.${index}.unit`} label="Unit" options={timeUnitOptions} />
    </div>
  </div>
);

const ManualTimeFields = () => {
  const { control, clearErrors, setError, errors } = useFormContext();
  const {
    fields: timePoints,
    append: appendTimePoint,
    remove: removeTimePoint,
  } = useFieldArray({
    control,
    name: 'manualTimepoints',
  });

  const handleRemoveTimepoint = (index: number): void => {
    clearErrors('manualTimepoints');
    removeTimePoint(index);
  };
  const handleAddTimepoint = (): void => {
    clearErrors('noEmptyManualTimepoints');
    appendTimePoint({
      timepoint: null,
      unit: TimeUnit.seconds,
    });
  };

  useEffect(() => {
    if (timePoints.length < 1 && _isNil(errors?.noEmptyManualTimepoints)) {
      setError('noEmptyManualTimepoints', { type: 'custom', message: 'You must add at least one timepoint' });
    }
  }, [timePoints, errors]);

  return (
    <div className="pa2" data-test-component="ManualTimeFields" data-test-element="container">
      <List
        canAmend
        canAdd
        canRemove
        data={timePoints}
        renderItem={asListItem(ManualTimeFieldItem, {})}
        onAddClick={handleAddTimepoint}
        onRemoveClick={handleRemoveTimepoint}
        addButtonText="Add timepoint"
        isRemoveButtonFluid
        removeButtonClasses="self-center"
      />
      <ErrorMessage
        errors={errors}
        name="noEmptyManualTimepoints"
        render={({ message }) => <small className="red db pt2">{message}</small>}
      />
    </div>
  );
};
