// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import GroupLabel from '@/components/UI/GroupLabel';
import * as Form from '@/support/form';
import { Dispatch, type FC, useEffect, useState } from 'react';
import type { ModalProps } from '../Show';
import { areTreatmentGroupsUneven, countGroupDistribution, defaultGroupDistribution } from '../Show.utils';
import type { RandomizeState } from './Randomize/Randomize.model';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';

interface StudyGroupsProps {
  state: RandomizeState;
  dispatch: Dispatch<StepFormAction<RandomizeState>>;
  props: ModalProps;
}

const StudyGroups: FC<StudyGroupsProps> = ({ state, dispatch, props }) => {
  const {
    treatmentGroups,
    randomizeByDate: {
      validAnimals: { includedAnimals },
    },
  } = state;
  const { totalSpace, groupDistribution } = treatmentGroups;
  const { study, groups } = props;

  const [error, setError] = useState(false);

  useEffect(() => {
    setGroupDistribution(defaultGroupDistribution(groups, includedAnimals));
    dispatch({ type: 'stepReady' });
  }, [dispatch]);

  const setGroupDistribution = (
    updatedGroupDistribution: RandomizeState['treatmentGroups']['groupDistribution']
  ): void => {
    const totalSpace = Object.values(updatedGroupDistribution).reduce((total, groupSize) => total + groupSize, 0);
    const remainingAnimals = countGroupDistribution(updatedGroupDistribution, includedAnimals.length);
    const unevenTreatmentGroups = areTreatmentGroupsUneven(updatedGroupDistribution);

    dispatch({
      type: 'update',
      id: 'treatmentGroups',
      data: {
        totalSpace,
        remainingAnimals,
        groupDistribution: updatedGroupDistribution,
        unevenTreatmentGroups,
      },
    });
    setError(remainingAnimals < 0);
  };

  const clampCapacity = (value, min, max) => parseInt(Math.max(min, Math.min(value, max)));

  const handleGroupInput = (e, group) => {
    const updatedGroupDistribution = { ...groupDistribution };
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }
    const cappedValue = clampCapacity(value, 0, group.max_subjects - group.animals_count);
    updatedGroupDistribution[e.target.name] = cappedValue;
    setGroupDistribution(updatedGroupDistribution);
  };

  return (
    <div className="mw7 mt3 pa4 ui-card">
      {!groups.length ? (
        <Banner info dismiss={false} className="mw6 mr4 mb4">
          <h3 className="normal lh-title f5 pb1">You haven&apos;t added any study groups yet</h3>
          <p className="f6 lh-copy pb3">
            Study groups are required to randomise animals. You can add up to 30 study groups from the study settings
            page.
          </p>
          <Button url={study.links.settings} outline info>
            Create study groups
          </Button>
        </Banner>
      ) : (
        <>
          {error && (
            <Banner critical dismiss={false} className="mw6 mb3">
              <h3 className="normal lh-title f5 pb1">You have allocated more spaces than animals</h3>
            </Banner>
          )}
          <Form.FieldError errors={props.errors} name="subjectsRemaining" />
          <div className="pb3">
            <h2 className="f4 normal lh-title">Set study group size</h2>
          </div>
          <div className="flex">
            <div className="w-50">
              {groups.map((group, i) => (
                <div key={i} className="pb2">
                  <div className="flex flex-wrap justify-between items-center pb2" style={{ maxWidth: '31.25rem' }}>
                    <GroupLabel group={group} className="w-auto" />
                    <div className="dib f7 pa2 br-pill lh-solid blue bg-lightest-blue ml3">
                      {`${groupDistribution[group.id] + group.animals_count}
                    / ${group.max_subjects} Capacity`}
                    </div>
                  </div>
                  <input
                    type="tel"
                    name={group.id}
                    autoComplete={'off'}
                    min={0}
                    value={groupDistribution[group.id]}
                    onChange={(e) => handleGroupInput(e, group)}
                  />
                </div>
              ))}
            </div>
            <div className="ph5" style={{ marginTop: '30px' }}>
              <div className="pb3">
                <p className="f6 lh-copy pb2">Animals selected</p>
                <span className="f3 lh-title near-black">{includedAnimals.length}</span>
              </div>
              <div className="pb3">
                <p className="f6 lh-copy pb2 ">To be assigned</p>
                <span className="f3 lh-title near-black">{totalSpace}</span>
              </div>
              <div className="pb3">
                <p className="f6 lh-copy pb2">To be excluded</p>
                <span className="f3 lh-title near-black">{Math.max(0, includedAnimals.length - totalSpace)}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StudyGroups;
