import { _isEmpty, _isNil } from '@/littledash';
import type { StudyGroupV1, TreatmentGroup } from '@/model/TreatmentGroup.model';
import type { CSSProperties, FC } from 'react';
import GroupContextMenu from './GroupContextMenu';
import './GroupLabel.scss';
import { RiForbid2Line } from 'react-icons/ri';

interface GroupLabelProps {
  className?: string;
  group?: StudyGroupV1 | TreatmentGroup;
  noMenu?: boolean;
  style?: CSSProperties;
  groupContextClassName?: string;
}

const GroupLabel: FC<GroupLabelProps> = ({ group, style, className = '', noMenu, groupContextClassName = '' }) => {
  if (_isNil(group)) {
    return '-';
  }
  const metadata = group?.metadata || [];

  return (
    <div className={`f6 ui__group-label relative ${className} w-100 pr3`} style={style}>
      <div
        className="ui__group-swatch absolute z-2 left-0"
        style={{
          top: '2px',
          backgroundColor: group.color ? group.color : '#333',
        }}
      />
      <div className="lh-title" style={{ paddingLeft: 24 }}>
        {group.name}
      </div>
      {!_isEmpty(metadata) && !noMenu && (
        <div
          className="h-100 absolute right-0"
          style={{
            top: '2px',
          }}
        >
          <GroupContextMenu group={group} className={groupContextClassName} />
        </div>
      )}
    </div>
  );
};

export const UnassignedGroupLabel: FC<GroupLabelProps> = ({ group, style, className = '' }) => {
  if (_isNil(group)) {
    return '-';
  }

  return (
    <div className={`f6 ui__group-label relative ${className} w-100 pr3`} style={style}>
      <div
        className=" absolute z-2 left-0"
        style={{
          top: '2px',
          backgroundColor: '#333',
        }}
      />

      <div className="lh-title o-50 flex items-center" style={{ paddingLeft: 24 }}>
        <div className="flex pr1">
          <RiForbid2Line />
        </div>
        <div className="flex">{group.name}</div>
      </div>
    </div>
  );
};

export default GroupLabel;
