import { FeatureFlagType } from '@/model/FeatureFlag.model';
import { MainUser } from '@/model/User.model';
import { State } from '../model/State.model';

export const featuresSelector = (state: State): Partial<Record<FeatureFlagType, boolean>> =>
  state?.team?.features ?? {};
export const adminConsoleUserSelector = (state: State): boolean =>
  state?.team?.team?.pivot?.admin_console_user ?? false;
export const teamOwnerSelector = (state: State): boolean => state?.team?.team?.pivot?.owner ?? false;
export const currentUserSelector = (state: State): MainUser | undefined => state?.user?.currentUser;
export const timeZonesSelector = (state: State): Array<string> => state?.user?.timezones ?? [];
