// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { hasOwnProperty } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import { Animal } from '@/model/Animal.model';
import { Measurement } from '@/model/Measurement.model';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { isClosed } from '@/support/study';
import { sortDescByDateStrProperty } from '@/utils/array.ts';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import Graph from './Graph';
import MeasurementList from './MeasurementList/MeasurementList';

interface MeasurementsProps {
  fromWorkflow: boolean;
  measurements: Array<Measurement>;
  onMeasurementSubmit: (response: any, editing: boolean) => void;
  readOnly?: boolean;
  study: Study;
  subject: Animal;
}

const Measurements: React.FC<MeasurementsProps> = ({
  study,
  subject,
  onMeasurementSubmit,
  measurements = [],
  fromWorkflow,
  readOnly,
}) => {
  const calculations = study?.settings?.calculations || [];
  const dispatch = useDispatch();
  const [activeMeasurement, setActiveMeasurement] = useState(calculations[0]);

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const openModal = (props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_MEASUREMENT',
      props,
    });
  };

  const orderedMeasurements = measurements.sort(sortDescByDateStrProperty('measured_at'));

  const filteredMeasurements = orderedMeasurements.filter((m) =>
    hasOwnProperty(m.variables ?? {}, activeMeasurement.id ?? null)
  );

  const canAdd = !fromWorkflow && !readOnly && study && Auth.isWriteUserForStudy(study);
  const canEdit = Auth.isWriteUserForStudy(study);

  const NoMeasurements = ({ title, canAdd }) => (
    <div className="pa4 br3 bg-near-white">
      <div className="tc">
        <h3 className="lh-title normal f5 pb3">{title}</h3>
        {canAdd && (
          <div className={`${subject.terminated_at && 'ui__disabled'}`}>
            {Auth.isWriteUserForStudy(study) && (
              <>
                <Select
                  className="ui__select w5 f6 w-100"
                  classNamePrefix="ui__select"
                  onChange={(result) => setActiveMeasurement(result.value)}
                  value={{
                    value: activeMeasurement,
                    label: activeMeasurement.name,
                  }}
                  options={calculations.map((calculation) => ({
                    value: calculation,
                    label: calculation.name,
                  }))}
                />
                <Button
                  onClick={() => {
                    openModal({
                      studyApiId: study.api_id,
                      subject,
                      preset: study.settings,
                      settings: {
                        measuring: [activeMeasurement.id],
                      },
                      closeModal,
                      onMeasurementSubmit,
                    });
                  }}
                  className="f6 basier-reg pl0 stateless blue mt3"
                >
                  Add new
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );

  if (_isEmpty(calculations)) {
    return (
      <div className="ma3">
        <NoMeasurements title="No preset has been selected." canAdd={false} />
      </div>
    );
  }

  return (
    <div>
      {_isNotEmpty(measurements) ? (
        <div>
          <div className="flex flex-wrap justify-between items-center pa3">
            <Select
              className="ui__select w5"
              classNamePrefix="ui__select"
              onChange={(result) => setActiveMeasurement(result.value)}
              value={{
                value: activeMeasurement,
                label: activeMeasurement.name,
              }}
              options={calculations.map((calculation) => ({
                value: calculation,
                label: calculation.name,
              }))}
            />
            {canAdd && !isClosed(study) && (
              <Button
                onClick={() => {
                  openModal({
                    studyApiId: study.api_id,
                    subject,
                    preset: study.settings,
                    settings: {
                      measuring: [activeMeasurement.id],
                    },
                    closeModal,
                    onMeasurementSubmit,
                  });
                }}
                className={`f6 basier-reg pl0 stateless blue ${subject.terminated_at ? 'ui__disabled' : ''}`}
              >
                Add new
              </Button>
            )}
          </div>
          {_isNotEmpty(filteredMeasurements) ? (
            <React.Fragment>
              <div className="mb4 ph3">
                <Graph
                  measurements={orderedMeasurements}
                  activeMeasurement={activeMeasurement.id}
                  unit={activeMeasurement.unit}
                />
              </div>
              <div className="bt bb b--moon-gray" data-test-key="measurements-list">
                <MeasurementList
                  preset={study.settings}
                  canAdd={canAdd}
                  canEdit={canEdit}
                  studyApiId={study.api_id}
                  subject={subject}
                  measurements={measurements}
                  activeMeasurement={activeMeasurement}
                  handleCallback={onMeasurementSubmit}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="mh3">
              <NoMeasurements title="No measurements of this type have been added yet." canAdd={false} />
            </div>
          )}
        </div>
      ) : (
        <div className="ma4" data-testid="animal-no-measurements">
          <NoMeasurements
            subject={subject}
            study={study}
            title="No measurements have been added yet."
            canAdd={canAdd}
            calculations={calculations}
          />
        </div>
      )}
    </div>
  );
};

export default Measurements;
