import { TimeUnit } from '@/components/UI/DataTable/DataTable.model';
import { hoursToSeconds, minutesToSeconds, secondsToHours, secondsToMinutes } from 'date-fns';

export const timeUnitOptions = [
  {
    label: 'Seconds',
    value: TimeUnit.seconds,
  },
  {
    label: 'Minutes',
    value: TimeUnit.minutes,
  },
  {
    label: 'Hours',
    value: TimeUnit.hours,
  },
];
export const getTimeInSeconds = ({ time, unit }: { time: number; unit: TimeUnit }) => {
  let timeInSeconds = time;
  switch (unit) {
    case TimeUnit.minutes:
      timeInSeconds = minutesToSeconds(timeInSeconds);
      break;
    case TimeUnit.hours:
      timeInSeconds = hoursToSeconds(time);
      break;
  }

  return Number(timeInSeconds);
};
export const convertTimeInSecondsToUnit = ({ seconds, unit }: { seconds: number; unit: TimeUnit }) => {
  let timeInUnit = seconds;
  switch (unit) {
    case TimeUnit.minutes:
      timeInUnit = secondsToMinutes(timeInUnit);
      break;
    case TimeUnit.hours:
      timeInUnit = secondsToHours(timeInUnit);
      break;
  }
  return Number(timeInUnit);
};
