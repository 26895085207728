import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import type { Animal } from '@/model/Animal.model';
import type { Measurement, MeasurementApiId } from '@/model/Measurement.model';
import { Preset } from '@/model/Preset.model.ts';
import type { PresetCalculationMeasurement } from '@/model/PresetCalculationMeasurement.model';
import type { StudyApiId } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import { modalAction } from '@/utils/modal';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

interface ActionsProps {
  studyApiId: StudyApiId;
  subject: Animal;
  preset: Preset;
  measurement: Measurement;
  activeMeasurement: PresetCalculationMeasurement;
  handleCallback: (message: string, success?: boolean) => Promise<void>;
}

const Actions: FC<ActionsProps> = ({ studyApiId, subject, preset, measurement, activeMeasurement, handleCallback }) => {
  const { openModal, closeModal } = modalAction(useDispatch());

  const confirmDeleteMetrics = (
    measurementId: MeasurementApiId,
    metrics: [PresetCalculationMeasurement['id']],
    handleCallback: ActionsProps['handleCallback'],
    reasonForDelete?: string
  ) => {
    if (!Array.isArray(metrics)) {
      metrics = [metrics];
    }

    const promisesToMake = metrics.map((metric) =>
      ApiService.call({
        endpoint: 'DELETE /api/v1/studies/{studyId}/animals/{animalId}/measurements/{measurementId}/metrics/{metric}',
        path: {
          studyId: studyApiId,
          animalId: subject.api_id as string,
          measurementId,
          metric,
        },
        body: reasonForDelete ? { reason_for_change: reasonForDelete } : {},
      })
    );

    return Promise.all(promisesToMake)
      .then(() => handleCallback('Successfully deleted measurement', true))
      .catch(() => handleCallback('There was a problem deleting the measurement'));
  };

  return (
    <SelectDropDown
      title="Actions"
      className="f6 basier-reg pl0 stateless blue"
      alignMenu="left"
      testId="measurement-actions"
    >
      <ActionList
        actions={[
          {
            name: 'Edit',
            action: () =>
              openModal('ADD_MEASUREMENT', {
                subject,
                studyApiId,
                preset,
                onMeasurementSubmit: handleCallback,
                closeModal,
                editing: true,
                settings: {
                  measured_at: measurement.measured_at,
                  measuring: [activeMeasurement.id],
                },
              }),
          },
          {
            name: 'Delete',
            className: 'red',
            action: () =>
              openModal('CONFIRM_DELETE_METRIC', {
                closeModal,
                onClick: (reasonForDelete?: string) =>
                  confirmDeleteMetrics(measurement.api_id, [activeMeasurement.id], handleCallback, reasonForDelete),
              }),
          },
        ]}
      />
    </SelectDropDown>
  );
};
export default Actions;
