import { _isEmptyString, _notNil } from '@/littledash.ts';
import { DateUtils } from '@/utils/Date.utils.ts';
import cn from 'classnames';
import Fuse from 'fuse.js';
import { FC, FocusEventHandler, useCallback, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import './TimezoneSelect.scss';

interface TimezoneSelectProps {
  value?: string;
  timezones: Array<string>;
  disabled?: boolean;
  invalid?: boolean;
  onChange: (value?: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
}

interface Timezone {
  id: string;
  name: string;
  abbreviation: string;
  offset: number;
}

export const TimezoneSelect: FC<TimezoneSelectProps> = ({
  value,
  timezones,
  disabled = false,
  invalid = false,
  onChange,
  onBlur,
  className,
}) => {
  const { timezoneFuse, timezoneOptions } = useMemo(() => {
    const timezoneData: Array<Timezone> = timezones
      .map((timezone) => ({
        id: timezone,
        name: DateUtils.timezoneName(null, timezone, 'long') ?? '',
        abbreviation: DateUtils.timezoneAbbreviation(null, timezone),
        offset: DateUtils.timezoneOffsetMilliseconds(null, timezone),
      }))
      .sort((a, b) => a.offset - b.offset);
    const timezoneFuse = new Fuse(timezoneData, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [{ name: 'id' }, { name: 'name' }, { name: 'abbreviation' }],
    });
    const timezoneOptions = timezoneData.map((tz) => ({
      value: tz.id,
      label: <TimezoneOption timezone={tz} />,
    }));
    return { timezoneData, timezoneFuse, timezoneOptions };
  }, [timezones]);
  const defaultValue = useMemo(() => {
    if (_notNil(value)) {
      return {
        value,
        label: (
          <TimezoneOption
            timezone={{
              id: value,
              name: DateUtils.timezoneName(null, value, 'long') ?? '',
              abbreviation: DateUtils.timezoneAbbreviation(null, value),
              offset: DateUtils.timezoneOffsetMilliseconds(null, value),
            }}
          />
        ),
      };
    }
  }, [value]);

  const loadOptions = useCallback(
    async (input: string) => {
      if (_isEmptyString(input)) {
        return timezoneOptions;
      }
      return timezoneFuse.search(input).map(({ item }) => ({
        value: item.id,
        disabled: true,
        label: <TimezoneOption timezone={item} />,
      }));
    },
    [timezoneOptions, timezoneFuse]
  );

  return (
    <span
      data-test-component="TimezoneSelect"
      data-test-element="container"
      className={cn({ timezone_select_invalid: invalid })}
    >
      <AsyncSelect
        className={cn('ui__select f6', className)}
        classNamePrefix="ui__select"
        defaultValue={defaultValue}
        isDisabled={disabled}
        defaultOptions={timezoneOptions}
        isOptionDisabled={(option) => option.value === 'UTC'}
        loadOptions={loadOptions}
        onChange={(option) => onChange(option?.value)}
        onBlur={onBlur}
      />
    </span>
  );
};

const TimezoneOption: FC<{ timezone: Timezone }> = ({ timezone }) => (
  <span
    className="timezone_option flex flex-row mr2"
    data-test-component="TimezoneOption"
    data-test-element={'container'}
    data-test-key={timezone.id}
  >
    <span className="timezone_id">{timezone.id}</span>
    <span className="timezone_name">{timezone.name}</span>
    <span className="timezone_abbreviation">{timezone.abbreviation}</span>
  </span>
);
