// @ts-nocheck: converted from JS

const INITIAL_STATE = {
  currentStudy: null,
};

const applySetCurrentStudy = (state, action) => ({
  ...state,
  currentStudy: action.study,
});

const applyResetCurrentStudy = (state, action) => ({
  ...state,
  currentStudy: null,
});

function studiesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CURRENT_STUDY': {
      return applySetCurrentStudy(state, action);
    }
    case 'RESET_CURRENT_STUDY': {
      return applyResetCurrentStudy(state, action);
    }
    default:
      return state;
  }
}

export default studiesReducer;
