// @ts-nocheck: converted from JS

import SubHeader from '@/components/UI/SubHeader';
import { web as webRoute } from '@/support/route';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { useParams } from 'react-router-dom';
import { Approval } from '@/components/Studies/Approval';
import Loading from '@/components/Loading';
import useFetchEntity from '@/support/Hooks/fetch/useFetchEntity';

const Show = () => {
  const { approvalRequestId } = useParams();

  const {
    entity: approval,
    entityLoading: approvalLoading,
    entityError: approvalError,
    fetchEntity: reloadApproval,
  } = useFetchEntity({
    entityType: 'approval',
    params: { approvalRequestId },
    includes: 'study',
  });
  const hasErrors = approvalError !== false;

  if (approvalLoading && !hasErrors) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <SubHeader
        linkToText={approval?.study?.name ?? '-'}
        link={webRoute('studies.show', { id: approval?.study?.id })}
      />
      <div className="pa4">
        {hasErrors ? (
          <ApiErrorBanner
            className="mb4"
            title={'There was a problem fetching data'}
            text={
              'An error has occurred when fetching data from the server, please try again later. If this keeps occurring please contact support.'
            }
            error={hasErrors}
          />
        ) : (
          <Approval approval={approval} onSubmit={() => reloadApproval()} />
        )}
      </div>
    </div>
  );
};

export default Show;
