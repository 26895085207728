// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { NoDataMessage } from '@/components/NoDataCard';
import { sampleColumns } from '@/components/Studies/Requests/Request.utils';
import Button from '@/components/UI/Button';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { useRequest } from '@/support/Hooks/request';
import { api as apiRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const Samples = ({ readOnly = false }) => {
  const [samples, setSamples] = useState([]);
  const { id: studyId, requestId } = useParams();
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const { sendRequest: removeSamples, requestError: removeError } = useRequest({
    route: 'requests.request.samples.delete.bulk',
    method: 'delete',
    params: { requestId },
  });

  const { sendRequest: addSamples, requestError: addError } = useRequest({
    route: 'requests.request.samples.add',
    method: 'post',
    params: { requestId },
  });

  const samplesBulkActions = (selectedFlatRows, resetTableState) => {
    const selectedSamples = selectedFlatRows.map((row) => row.original);
    return [
      {
        name: 'Remove sample(s)',
        key: 'remove_sample',
        action: async () => {
          const sampleIds = selectedSamples.map(({ id }) => id);
          await removeSamples({
            samples: sampleIds,
          });
          resetTableState();
        },
      },
    ];
  };

  const bulkActions = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { resetTableState },
    },
  }) => !readOnly && samplesBulkActions(selectedFlatRows, resetTableState);

  const openAttachSampleModal = (setUpdating, fetchTableData) => {
    openModal('REQUEST_ATTACH_SAMPLE', {
      studyId,
      currentSamples: samples?.map((sample) => sample.id) || [],
      closeModal,
      onAddSample: async (addedSamples) => {
        setUpdating(true);
        try {
          await addSamples({
            samples: addedSamples.map((sample) => sample.id),
          });
          setUpdating(false);
          return fetchTableData();
        } catch (e) {
          setUpdating(false);
        }
      },
    });
  };

  const AsideComponent = readOnly
    ? null
    : ({
        useTableProps: {
          apiTable: { setUpdating, fetchTableData },
        },
      }) => (
        <Button
          className="ml2"
          onClick={() => {
            openAttachSampleModal(setUpdating, fetchTableData);
          }}
        >
          Attach Samples
        </Button>
      );

  return (
    <div className="w-100 mb3 br2 flex-column">
      {(removeError || addError) && (
        <div className="mb3">
          <ApiErrorBanner
            error={removeError || addError}
            title={`There was a problem ${removeError ? 'removing' : 'adding'} selected samples`}
            text={`An error has occurred while ${
              removeError ? 'removing' : 'adding'
            } selected samples, please try again later. If this keeps occurring please contact support.`}
          />
        </div>
      )}
      <APITable
        apiInfo={{ type: 'legacyInternalApi', route: apiRoute('requests.request.samples', { requestId }) }}
        includeParam="study_group"
        bulkActions={!readOnly ? bulkActions : false}
        columns={sampleColumns(dispatch)}
        onFetchTableData={setSamples}
        defaultSortBy={{ id: 'sample_id', desc: false }}
        hideSearch={true}
        hideFilter={true}
        AsideComponent={AsideComponent}
        NoDataComponent={({ apiTable: { setUpdating, fetchTableData } }) => (
          <NoDataMessage
            title="No samples have been added to this request."
            text={readOnly ? undefined : 'When you add samples they will appear here.'}
            onLinkClick={readOnly ? undefined : () => openAttachSampleModal(setUpdating, fetchTableData)}
            btnTxt={readOnly ? undefined : 'Add samples'}
            openModal
            dark
          />
        )}
      />
    </div>
  );
};

export default Samples;
