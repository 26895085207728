import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import { FeatureFlagFormField, FeatureFlagList, FeatureFlagUpdate } from './FeatureFlagConfig.utils';
import { Controller, FieldValues, useForm } from 'react-hook-form@latest';
import { _isEmpty } from '@/littledash';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@/model/State.model';
import { NoDataMessage } from '@/components/NoDataCard';
import Switch from '@/components/UI/FormElements/Switch';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { errorToast, successToast } from '@/helpers';

const TH_CLASSES = 'f7 f6-l ph2 pv2 ph3-l pv3-l near-black basier-med normal';
const TD_CLASSES = 'f7 f6-l ph2 pv1 ph3-l pv2-l bt mw1';

export const FeatureFlagConfig = () => {
  const { response: featureFlagsResponse, loading: loadingFeatureFlags } = useApiHook({
    endpoint: 'GET /api/v1/team/feature-flags',
    invokeOnInit: true,
  });

  if (_isEmpty(featureFlagsResponse?.body) || loadingFeatureFlags) return null;

  return <FeatureFlagConfigForm data={featureFlagsResponse?.body as FeatureFlagList} />;
};

interface FeatureFlagConfigFormProps {
  data: FeatureFlagList;
}

export const FeatureFlagConfigForm = ({ data }: FeatureFlagConfigFormProps) => {
  const { currentUser } = useSelector((state: State) => ({
    currentUser: state.user?.currentUser,
  }));
  const dispatch = useDispatch();
  const formMethods = useForm();
  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields, isValid, isSubmitting },
  } = formMethods;

  const { invoke: patchFeatureFlags } = useApiHook({
    endpoint: 'PATCH /api/v1/team/feature-flags',
    invokeOnInit: false,
  });

  const onSubmit = async (data: FieldValues, dirtyFields: Partial<Readonly<FeatureFlagFormField>>) => {
    try {
      const formValuesArr = Object.keys(dirtyFields).map((key) => ({
        name: key,
        value: data[key],
      })) as FeatureFlagUpdate;
      const { body } = await patchFeatureFlags({ body: formValuesArr });
      const features = body?.reduce<Record<string, boolean>>((acc, { name, value }) => {
        acc[name] = value;
        return acc;
      }, {});
      dispatch({ type: 'TEAM_SET_FEATURES', features });
      reset(data);
      successToast('Feature flags updated successfully.');
    } catch (e) {
      errorToast('Error updating feature flags, please try again.');
    }
  };

  if (_isEmpty(data)) {
    return (
      <div className="pa4" data-testid="feature-flag-no-data">
        <NoDataMessage
          title="Feature Flag Configuration Empty"
          text="Please contact support to have Feature Flags configured."
        />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, dirtyFields))} data-testid="feature-flag-config-form">
      <div className="pa3">
        <>
          <Header
            mainHeaderText="Feature Flag Configuration"
            asideComponent={() => (
              <Button
                disabled={!isValid || isSubmitting || _isEmpty(dirtyFields)}
                onClick={handleSubmit((data) => onSubmit(data, dirtyFields))}
                tooltip={_isEmpty(dirtyFields) ? 'No changes to save' : ''}
                testId="feature-flag-save-changes-button"
              >
                Save changes
              </Button>
            )}
          />
          <p className="f5 dark-gray lh-title basier-reg normal pb1">{currentUser.name}</p>
        </>
        <div className="ui__table bg-white w-100 b-moon--gray ba mt4">
          <table className="w-100 tl bt b--moon-gray" style={{ borderSpacing: 0, fontSize: 11 }}>
            <thead className="bg-near-white">
              <tr>
                <th className={`${TH_CLASSES} br`}>Flag</th>
                <th className={`${TH_CLASSES} br`}>Description</th>
                <th className={`${TH_CLASSES} br`}>Value</th>
              </tr>
            </thead>
            <tbody className="black">
              {data.map((flag) => (
                <tr key={flag.name}>
                  <td className={`${TD_CLASSES} br`}>{flag.name}</td>
                  <td className={`${TD_CLASSES} br`}>{flag.description}</td>
                  <td className={TD_CLASSES}>
                    {
                      <Controller
                        name={flag.name}
                        control={control}
                        defaultValue={flag.value}
                        render={({ field: { onChange, value } }) => (
                          <div className="flex">
                            <Switch value={value} onChange={onChange} testId={`feature-flag-switch-${flag.name}`} />
                            <p className="ml3">{value ? 'True' : 'False'}</p>
                          </div>
                        )}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};
