// @ts-nocheck: converted from JS

import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React from 'react';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    ExceptionHandler.captureException(
      new InVivoError(`Unhandled exception: ${error?.message ?? '???'}`, {
        cause: error,
        slug: 'error-boundary',
        level: 'fatal',
      })
    );
    return {
      error: true,
    };
  }

  resetError = () => this.setState({ error: false });

  render() {
    if (this.state.error) {
      return <ErrorPage resetError={this.resetError} />;
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
