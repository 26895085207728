import { _isNil, _notNil } from '@/littledash';
import { AnalysisConfig, AnalysisState, AnalysisType, AverageType, TrackingFrom } from '@/model/Analysis.model.ts';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import type { FieldErrors, Resolver } from 'react-hook-form';

interface AnalysisDay {
  value: number;
  from: TrackingFrom | null;
}
export interface AnalysisFormData {
  type: AnalysisType;
  measurement: string | null;
  day: AnalysisDay;
  averageType: AverageType | null;
  targetValue: number | null;
}

type ErrorFields = keyof AnalysisFormData | `${keyof Pick<AnalysisFormData, 'day'>}.${keyof AnalysisDay}`;

export interface AnalysisSetupState {
  form: Partial<AnalysisFormData>;
  formState: { dirty: boolean; valid: boolean };
  formErrors: Map<ErrorFields, Array<string>>;
}

export const analysisSetupFormResolver =
  (config: AnalysisState['config']): Resolver<AnalysisFormData> =>
  async (values: AnalysisFormData) => {
    let errors: FieldErrors<AnalysisFormData> = {};
    if (_isNil(values.type)) {
      return { values: {}, errors: { type: { type: 'required' } } };
    }
    const typeConfig = config?.get(values.type);
    if (_notNil(typeConfig)) {
      if (typeConfig.measurements.size > 0) {
        if (!typeConfig.measurements.has(values.measurement as string)) {
          values.measurement = typeConfig.measurements.values().next()?.value as string;
        }
      } else {
        values.measurement = null;
      }
      if (typeConfig.average_type.size > 0) {
        if (!typeConfig.average_type.has(values.averageType as AverageType)) {
          values.averageType = typeConfig.average_type.values().next()?.value as AverageType;
        }
      } else {
        values.averageType = null;
      }
      if (_isNil(values.day.value)) {
        errors = { ...errors, day: { value: { type: 'required' } } };
      } else if (!Number.isInteger(values.day.value)) {
        errors = { ...errors, day: { value: { type: 'invalid', message: 'must be a whole number' } } };
      } else if (values.day.value <= 0) {
        errors = { ...errors, day: { value: { type: 'invalid', message: 'must be greater than 0' } } };
      }

      if (typeConfig.tracking_from.size > 0) {
        if (!typeConfig.tracking_from.has(values?.day?.from as TrackingFrom)) {
          values.day = {
            ...(values?.day ?? {}),
            from: typeConfig.tracking_from.values().next()?.value as AnalysisConfig['tracking_from'][0],
          };
        }
      } else {
        values.day = { ...(values?.day ?? {}), from: null };
      }
      if (values.type === 'EfficacyProphylactic' || values.type === 'Oncology') {
        if (_isNil(values.targetValue)) {
          errors = { ...errors, targetValue: { type: 'required' } };
        } else if (values.targetValue <= 0) {
          errors = { ...errors, targetValue: { type: 'invalid', message: 'must be greater than 0' } };
        }
      }
    } else {
      errors = { ...errors, type: { type: 'invalid', message: 'selected option is invalid' } };
    }
    return { values: Object.values(errors).length === 0 ? values : {}, errors };
  };
