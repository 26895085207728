// @ts-nocheck: converted from JS

import Create from '@/components/Glossary/Sections/Requests/Create';
import Edit from '@/components/Glossary/Sections/Requests/Edit';
import { Route } from 'react-router-dom';
import Show from './Show';

const Requests = () => {
  const subRoutes = [
    {
      name: 'Show',
      route: '/team/studies/requests',
      exact: true,
      isValid: () => true,
      Component: () => <Show />,
    },
    {
      name: 'Create',
      route: '/team/studies/requests/create',
      exact: true,
      isValid: () => true,
      Component: () => <Create />,
    },
    {
      name: 'Edit',
      route: '/team/studies/requests/:requestTemplate/edit',
      exact: true,
      isValid: () => true,
      Component: () => <Edit />,
    },
  ];
  return (
    <>
      <div className="ph4">
        {subRoutes.map((subRoute) => (
          <Route
            key={subRoute.route}
            exact={subRoute.exact}
            path={subRoute.route}
            render={() => <subRoute.Component />}
          />
        ))}
      </div>
    </>
  );
};

export default Requests;
