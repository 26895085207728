// @ts-nocheck: converted from JS

import Sortable from '@/components/Draggable';
import AddButton from '@/components/UI/AddButton';
import RemoveButton from '@/components/UI/RemoveButton';
import React, { useMemo } from 'react';

interface ListProps<T> {
  data: Array<T>;
  removeButtonClasses?: string;
  canAmend?: boolean;
  canAdd?: boolean;
  canRemove?: boolean;
  renderItem?: (value: T, index: number, array: T[]) => React.JSX.Element;
  onAddClick?: any;
  onRemoveClick?: any;
  addButtonText?: any;
  addButtonProps?: any;
  isRemoveButtonFluid?: any;
  itemProps?: any;
  itemsContainerProps?: any;
  ItemsContainerType?: any;
  containerProps?: any;
}

const List = <T extends Record<string, any>>({
  data = [],
  renderItem,
  canAmend,
  canAdd,
  canRemove,
  onAddClick = () => {},
  onRemoveClick,
  addButtonText = '',
  addButtonProps = {},
  isRemoveButtonFluid = true,
  itemProps = {},
  itemsContainerProps = {},
  ItemsContainerType = 'span',
  containerProps = {},
  removeButtonClasses = '',
}: ListProps<T>) => {
  const items = useMemo(() => data.map(renderItem), [data, renderItem]);
  const { className: itemsContainerClassName } = itemsContainerProps;

  const childrenList = React.Children.map(items, (child, index) => {
    const { className: itemClassName, ...restItemProps } = itemProps;
    return (
      <div
        key={data[index].id}
        className={`flex flex-row items-start ${isRemoveButtonFluid ? 'justify-between' : ''} mb3 ${itemClassName}`}
        {...restItemProps}
      >
        {child}
        {canAmend && (
          <RemoveButton
            className={`ml2 ${removeButtonClasses}`}
            style={{ visibility: canRemove ? 'visible' : 'hidden' }}
            onClick={() => onRemoveClick(index)}
          />
        )}
      </div>
    );
  });

  const ItemsContainer = ItemsContainerType || 'span';
  return (
    <div {...containerProps} data-test-component="List" data-test-element="container">
      <ItemsContainer className={itemsContainerClassName}>{childrenList}</ItemsContainer>
      {canAdd && canAmend && (
        <AddButton onClick={onAddClick} {...addButtonProps}>
          {addButtonText || 'Add item'}
        </AddButton>
      )}
    </div>
  );
};

export default List;

export const asListItem = (Component, props) => (item, index) => <Component {...props} {...item} index={index} />;

export const asSortableListItem = (sortableProps) => (Component, props?: unknown) => (item, index) => {
  const component = <Component {...props} {...item} index={index} />;
  return sortableProps.canSort ? (
    <Sortable
      key={item.id}
      id={item.id}
      index={index}
      moveCallback={sortableProps.moveCallback}
      draggableType={sortableProps.sortableType}
      showDraggableIcon
      className="flex flex-row justify-between items-center w-100 hide-child"
      orderNumber={index + 1}
    >
      <div className="flex flex-row">{component}</div>
    </Sortable>
  ) : (
    component
  );
};
