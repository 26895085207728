// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import ApprovalComments from '@/components/Approvals/ApprovalComments';
import ApprovalRequestDetails from '@/components/Approvals/ApprovalRequestDetails';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import { approvalStatusToChecklistStatusMap } from '@/components/Studies/Approval/Approval.utils';
import ReviewTable from '@/components/Studies/Create/Steps/Groups/ReviewTable';
import { ApprovalForm, ApprovalStatus } from '@/components/Studies/Show';
import Project from '@/components/Studies/Show/Project';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { DisplayedGroupMetadata } from '@/model/Metadata.model';
import type { MainUser } from '@/model/User.model';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Status } from '@/referenceData/study/status';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import Attachments from '../Attachments/Attachments';

const Approval = ({ approval, onSubmit }) => {
  const { tab } = useParams();

  const { study, team } = useSelector(
    ({ team: { team }, studies: { currentStudy: study = {} }, user: { currentUser } }) => ({
      team,
      study,
      currentUser,
    })
  );

  const tabState = useMemo(() => {
    if (_notNil(tab)) {
      const tabs = ['detail', ...(_isNil(study?.project) ? [] : ['project']), 'groups', 'attachments', 'comments'];
      const selectedTab = tabs.indexOf(tab);
      if (selectedTab >= 0) {
        return selectedTab;
      }
    }
    return 0;
  }, [tab, study?.project]);

  const {
    collection: studyGroups,
    collectionLoading: studyGroupsLoading,
    collectionError: studyGroupsError,
  } = useFetchCollection({
    collectionType: 'studyGroups',
    params: { id: study?.id },
    includes: 'metadata,treatments[metadata]',
    queryParams: { perPage: -1 },
  });
  const { collection: groupMetadata, collectionLoading: groupMetadataLoading } =
    useFetchCollection<DisplayedGroupMetadata>({
      collectionType: 'teamStudiesGroups',
    });

  const {
    entity: comments,
    entityLoading: commentsLoading,
    fetchEntity: fetchComments,
    entityError: commentsError,
  } = useFetchEntity({
    entityType: 'approvalComments',
    params: { approvalId: approval.id },
    includes: 'study[users],project',
    queryParams: { perPage: -1 },
  });

  const {
    sendRequest: submitApproval,
    requestSending: submittingApproval,
    requestError: submitionError,
  } = useRequest({
    route: 'approvals.requests.update',
    method: 'put',
    params: {
      approvalRequestId: approval.id,
    },
  });

  const handleFormSubmit = async (formData) => {
    const { data } = await submitApproval({
      include: 'study_groups',
      form_data: formData,
    });
    if (!submitionError && data?.data) {
      const updatedApproval = data.data;
      onSubmit(updatedApproval);
    }
  };

  const getApproversByIds = (approverIds) =>
    approverIds
      .map((approverId) => team.users.find((user) => user.id === approverId))
      .filter((approverId) => !!approverId);

  const getApproverStatus = (approver, actionedApprover) => {
    if (approver?.pivot?.status !== 'inactive') {
      return approvalStatusToChecklistStatusMap[actionedApprover?.response] || 'idle';
    } else if (actionedApprover?.response === 'approved') {
      return 'inactiveSuccess';
    } else {
      return 'inactive';
    }
  };

  const approvers = getApproversByIds(approval?.approval?.approvers?.ids ?? []).map((approver: MainUser) => {
    const actionedApprover = (approval?.approvers ?? []).find(
      (assignedApprover) => assignedApprover?.id === approver?.id
    );
    return {
      name: approver?.name,
      email: approver?.email,
      status: getApproverStatus(approver, actionedApprover),
    };
  });

  const getAdditionalApprovers = () => {
    const getApproversByIds = (approverIds) =>
      approverIds.map((approverId) => team.users.find((user) => user.id === approverId));
    return approval.approval.approvers.required_ids
      ? getApproversByIds(approval.approval.approvers.required_ids).map((approver) => {
          const actionedApprover = approval.required_approvers.find(
            (assignedApprover) => assignedApprover.id === approver.id
          );
          return {
            name: approver.name,
            email: approver.email,
            status: getApproverStatus(approver, actionedApprover),
          };
        })
      : [];
  };

  const additionalApprovers = getAdditionalApprovers();
  const ordered = approval.approval.approvers.type === 'ordered';
  const isApprovalFormSubmitted = Boolean(approval.form_data);

  const buildDefaultValue = (approval) => {
    return approval.approval.form.groups.reduce((acc, group) => {
      acc.push(
        ...group.fields.map((field) => ({
          [field.name]: '',
        }))
      );
      return acc;
    }, []);
  };

  if (commentsLoading || studyGroupsLoading || groupMetadataLoading) {
    return <Loading txt={`Fetching ${studyGroupsLoading || groupMetadataLoading ? 'groups' : 'comments'}`} />;
  }

  return (
    <div>
      {submitionError && <ApiErrorBanner className="mb4" error={submitionError} />}
      {(commentsError || studyGroupsError) && (
        <ApiErrorBanner
          className="mb4"
          title={`There was an error adding your ${studyGroupsError ? 'groups' : 'comments'}`}
          text="Please try again later. If this keeps occurring please contact support."
          error={commentsError || studyGroupsError}
        />
      )}
      <h1 className="f3 mb4">{approval.approval.name}</h1>
      <Tabs state={tabState}>
        <div className="mv4 bb b--moon-gray flex">
          <Tab>Review details</Tab>
          {_notNil(study.project) && <Tab>Project</Tab>}
          <Tab counter={`${studyGroups.length}`}>Groups</Tab>
          <Tab>Attachments</Tab>
          <Tab counter={`${comments.length}`}>Comments</Tab>
        </div>
        <div className="pb4">
          <Panel unmountOnInactive={false}>
            <div className="flex justify-apart">
              <div className="mw7 w-two-thirds">
                {_notNil(study) && <ApprovalRequestDetails study={study} />}
                <ApprovalForm
                  disabled={approval.status !== Status.not_submitted}
                  title={approval.approval.name}
                  defaultValue={approval.form_data || buildDefaultValue(approval)}
                  groups={approval.approval.form.groups}
                  onSubmit={(data) => handleFormSubmit(data)}
                  isSubmitted={approval.status !== Status.not_submitted}
                  isSubmitting={submittingApproval}
                />
              </div>
              <div className="pl4 w-33">
                {isApprovalFormSubmitted && (
                  <ApprovalStatus
                    approvers={approvers}
                    additionalApprovers={additionalApprovers}
                    ordered={ordered}
                    status={approval.status}
                  />
                )}
              </div>
            </div>
          </Panel>
          {_isNotEmpty(study.project) && (
            <Panel>
              <Project projectId={study.project.id} />
            </Panel>
          )}
          <Panel>
            {_isEmpty(studyGroups) ? (
              <NoDataCard title="No groups have been defined for this study" />
            ) : (
              <div className="ui-card">
                <h2 className="lh-title normal f4 pa3 bb b--moon-gray">Groups</h2>
                <ReviewTable groups={studyGroups} groupMetadata={groupMetadata} />
              </div>
            )}
          </Panel>
          <Panel>
            <Attachments studyId={study.id} />
          </Panel>
          <Panel>
            <ApprovalComments comments={comments} fetchComments={fetchComments} approvalId={approval.id} />
          </Panel>
        </div>
      </Tabs>
    </div>
  );
};

export default Approval;
