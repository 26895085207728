// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import { _noop } from '@/littledash.ts';
import { CSSProperties, JSX, useState } from 'react';
import './Banner.scss';

interface BannerProps {
  critical?: boolean;
  warning?: boolean;
  info?: boolean;
  success?: boolean;
  children?: JSX.Element;
  className?: string;
  style?: CSSProperties;
  dismiss?: boolean;
  noIcon?: boolean;
  center?: boolean;
  onDismiss?: () => void;
  testId?: string;
}

const Banner: FC<BannerProps> = ({
  critical = false,
  warning = false,
  info = false,
  success = false,
  children,
  className,
  style,
  dismiss = true,
  noIcon = false,
  center = false,
  onDismiss = _noop,
  testId,
}) => {
  const [open, setOpen] = useState(true);

  let type = 'info';

  if (critical) {
    type = 'critical';
  }

  if (warning) {
    type = 'warning';
  }

  if (success) {
    type = 'success';
  }

  const classes = {
    critical,
    warning,
    info,
    success,
  };

  const accent = {
    critical: 'red',
    warning: 'yellow',
    info: 'blue',
    success: 'green',
  };

  const classNames = (classes) => {
    const classNamesToShow = Object.keys(classes)
      .filter((k) => classes[k] && k)
      .join(' ');
    return classNamesToShow + (className ? ` ${className}` : '');
  };

  const iconRef = {
    critical: 'critical',
    warning: 'warning',
    info: 'info',
    success: 'success',
  };

  const iconToShow = (
    <Icon icon={iconRef[type]} width="22" height="22" viewBox="0 0 20 20" className={`${accent[type]} percentCenter`} />
  );

  const dismissBanner = () => {
    setOpen(!open);
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
  };

  return (
    <div
      className={`ui__banner ${classNames(classes)} ${className} ${!open ? 'dn' : 'db'}`}
      style={style}
      data-test-component="Banner"
      data-test-element="container"
      data-testid={testId}
    >
      <div className={`bt bw2 b--${accent[type]} pa3 flex ${center ? 'items-center' : 'items-start'}`}>
        {!noIcon && (
          <div>
            <div className="ui__banner__icon w2 h2 relative br-100" data-test-element="icon-container">
              {iconToShow}
            </div>
          </div>
        )}
        <div className={`w-100 ${!noIcon ? 'ph3' : ''}`} data-test-element="body-container">
          {children}
        </div>
        {dismiss && (
          <div data-test-element="dismiss-container">
            <a className="pointer hover-near-black" onClick={dismissBanner}>
              <Icon icon="close" width="12" height="12" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
