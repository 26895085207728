// @ts-nocheck: converted from JS

import { hasOwnProperty } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty } from '@/littledash';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table7';
import { FixedSizeList } from 'react-window';
import TableHeader from './Cells/TableHeader';
import Expander from './Columns/Expander';
import Selector from './Columns/Selector';
import Pagination from './PaginationNew';
import './Table.scss';
import { Columns } from './TableComponent.model';

export interface TableProps<T> {
  className?: string;
  columns: Columns<T>;
  data: Array<T>;
  selectedRows?: Record<number, boolean>;
  setSelectedRows?: (selectedRows: Record<number, boolean>) => void;
  pagination?: unknown;
  expandable?: unknown;
  manualSort?: unknown;
  setManualSort?: (value: string) => void;
  sortBy?: unknown;
  noDataMessage?: unknown;
  noDataComponent?: unknown;
  hasContextMenu?: unknown;
  searchQuery?: unknown;
  initPageSize?: unknown;
  disableSelectAll?: unknown;
}

const Table = <T extends object>({
  columns,
  data,
  pagination = false,
  expandable = false,
  selectedRows,
  setSelectedRows,
  manualSort,
  setManualSort,
  sortBy = [],
  noDataMessage = 'No rows to display',
  noDataComponent,
  hasContextMenu,
  searchQuery,
  className = '',
  initPageSize,
  disableSelectAll = false,
}: TableProps<T>): ReactElement<any, any> | null => {
  const theadRef = useRef();
  const tbodyRef = useRef();

  const memorizedColumns = useMemo(() => {
    if (setSelectedRows) {
      return [Selector(disableSelectAll), ...columns];
    }
    if (expandable) {
      return [Expander, ...columns];
    }
    return [...columns];
  }, [columns]);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    toggleAllRowsSelected,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { selectedRowIds, pageIndex, pageSize, expanded },
  } = useTable(
    {
      columns: memorizedColumns,
      data,
      manualSortBy: !!manualSort,
      initialState: {
        sortBy,
        pageSize: initPageSize ?? 10,
        selectedRowIds: selectedRows ?? {},
      },
      sortBy,
    },
    useSortBy,
    useExpanded,
    useResizeColumns,
    useFlexLayout,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (_isNil(initPageSize) && page.length !== data?.length) {
      setPageSize(data.length);
    }
    if (_isNotEmpty(selectedRowIds)) {
      toggleAllRowsSelected(false);
    }
  }, [data]);

  useEffect(() => {
    if (_isNotEmpty(expanded)) {
      let newPageSize = pageSize;
      Object.keys(expanded).forEach((row) => {
        newPageSize = newPageSize + (data?.[row]?.subRows?.length ?? 0);
      });
      setPageSize(newPageSize);
    }
  }, [expanded]);

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedRowIds);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    setHiddenColumns(
      columns
        .filter((column) => hasOwnProperty(column, 'isVisible') && column.isVisible === false)
        .map((column) => column.id)
    );
  }, [setHiddenColumns, columns]);

  // # This is used to make the header play well with
  // # virtualised scrolling
  // # <FixedList> doesn't support both horizontal/vertical scrolling
  // # <FixedGrid/VariableGrid> require static sized columns, ours can be changed
  const handleHorizontalScroll = () => {
    theadRef?.current?.scrollTo({ left: tbodyRef?.current?.scrollLeft ?? 0 });
    tbodyRef?.current?.scrollTo({ left: theadRef?.current?.scrollLeft ?? 0 });
  };
  useEffect(() => {
    const abortController = new AbortController();
    tbodyRef?.current?.addEventListener('scroll', handleHorizontalScroll);
    theadRef?.current?.addEventListener('scroll', handleHorizontalScroll);
    return () => {
      abortController.abort('new instance');
    };
  }, [theadRef, tbodyRef]);

  const rowHeight = 50;
  const maxTBodyHeight = 615;
  const maxRowCountBeforeVirtualising = 11;
  const scrollBarHeightAllowance = 15;
  const calculateTBodyHeight = (rowCount) =>
    rowCount <= maxRowCountBeforeVirtualising ? rowCount * rowHeight + scrollBarHeightAllowance : maxTBodyHeight;

  const RenderRow = ({ index, style }) => {
    const row = page[index];
    prepareRow(row);
    const isLast = page.length === index + 1;
    const isNotVirtualized = page.length <= maxRowCountBeforeVirtualising;
    return (
      <div
        {...row.getRowProps({
          style,
        })}
        className="tr"
        data-test-component="RenderRow"
        data-test-element="row-container"
        data-test-key={row.id}
      >
        {row.cells.map((cell, cellIndex) => (
          <div
            className={`td ${cell.column.className} ${isLast && isNotVirtualized ? 'bb b--moon-gray' : ''}
              `}
            key={`row_${row.id}`}
            {...cell.getCellProps(cellProps)}
            style={{ ...cell.getCellProps(cellProps).style, ...cell.column.style }}
            data-test-element="cell-container"
            data-test-key={`(${index},${cellIndex})`}
          >
            {cell.render('Cell')}
          </div>
        ))}
      </div>
    );
  };

  const getStyles = (props, align = 'left', verticalAlign = 'center', show = true) => [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: verticalAlign,
        display: 'flex',
      },
    },
  ];
  const headerProps = (props, { column }) => getStyles(props, column.align);

  const cellProps = (props, { cell }) => {
    const { align, verticalAlign } = cell.column;
    return getStyles(props, align, verticalAlign);
  };

  const noResultsMessage = 'Your search did not return any results';

  return (
    <div className={`ui__table ${className}`} data-test-component="Table" data-test-element="container">
      <div className="table">
        <div className="thead w-100 overflow-scroll" ref={theadRef} data-test-element="header-container">
          {headerGroups.map((headerGroup, index) => (
            <div className="tr" key={`headerGroup_${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <TableHeader
                  {...column}
                  key={`header_${index}`}
                  headerProps={headerProps}
                  manualSort={manualSort}
                  setManualSort={setManualSort}
                />
              ))}
            </div>
          ))}
        </div>
        <div
          className={`${hasContextMenu ? 'context-menu' : ''} tbody`}
          {...getTableBodyProps()}
          data-test-element="body-container"
        >
          {_isEmpty(data) ? (
            _isNil(noDataComponent) ? (
              <div className="flex flex-column white min-h5 justify-center items-center tc pa3 ma3 bg-light-gray">
                <h2 className="tc pa3 ma3">{searchQuery ? noResultsMessage : noDataMessage}</h2>
              </div>
            ) : (
              noDataComponent
            )
          ) : (
            <FixedSizeList
              height={calculateTBodyHeight(page.length)}
              itemCount={page.length}
              itemSize={rowHeight}
              selectedRowIds={selectedRowIds}
              outerRef={tbodyRef}
              className={page.length > maxRowCountBeforeVirtualising ? 'bb b--moon-gray' : ''}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </div>
      </div>
      {pagination && _isNotEmpty(data) && (
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};

export default Table;
