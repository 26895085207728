import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import CopyToClipboard from '@/components/UI/CopyToClipboard';
import Icon from '@/components/UI/Icon';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Tooltip from '@/components/UI/Tooltip';
import { trunc } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil, uuid } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataFieldTypes } from '@/model/Metadata.model';
import { ChangeEvent, FC, Fragment } from 'react';
import { RiInboxArchiveLine, RiInboxUnarchiveFill } from 'react-icons/ri';
import type { Errors, ExtendedMetadataItem } from './Repeater.utils';
import SubComponent from './SubComponent';
import { Title } from './Title';
import './Repeater.scss';

const TH_CLASSES = 'f7 f6-l ph2 pv2 ph3-l pv3-l b--silver near-black basier-med normal';
const TD_CLASSES = 'f7 f6-l ph2 pv1 ph3-l pv2-l bt';

interface ArchiveButtonProps {
  row: ExtendedMetadataItem;
  handleArchive: (id?: ID) => void;
}

export const ToggleArchiveButton: FC<ArchiveButtonProps> = ({ row, handleArchive }) => (
  <Button soft onClick={() => handleArchive(row.id)} className="ml3" style={{ width: '40px', padding: '0' }}>
    {row.active ? (
      <Tooltip render="Archive">
        <RiInboxArchiveLine size={22} className="dark-gray" />
      </Tooltip>
    ) : (
      <Tooltip render="Unarchive">
        <RiInboxUnarchiveFill size={22} className="dark-gray" />
      </Tooltip>
    )}
  </Button>
);

interface DeleteButtonProps {
  rowId?: ID;
  handleDelete: (id?: ID) => void;
}

export const DeleteButton: FC<DeleteButtonProps> = ({ rowId, handleDelete }) => (
  <Button soft onClick={() => handleDelete(rowId)} className="ml3" style={{ width: '40px', padding: '0' }}>
    <Icon icon="close" width="10" height="10" className="dark-gray" />
  </Button>
);

interface RepeaterProps {
  type: string;
  title: string;
  data: Array<ExtendedMetadataItem>;
  setData: (data: Array<ExtendedMetadataItem>) => void;
  errors: Array<Errors>;
  loading: boolean;
  initialDataLookup: Record<ID, ExtendedMetadataItem>;
}
const Repeater: FC<RepeaterProps> = ({ data, setData, errors, type, loading, title, initialDataLookup }) => {
  const selectFields = ['select', 'multi_select'];
  const initialItem = {
    index: (_isNotEmpty(data) ? data[data.length - 1]?.index : -1) + 1,
    title: '',
    type,
    field_type: 'select',
    options: [''],
    active: true,
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const metadataId = event.target.getAttribute('data-id');

    const updatedData = data.map((item) => {
      if (String(item.id) === String(metadataId)) {
        return { ...item, [event.target.name]: event.target.value };
      }
      return item;
    });
    return setData(updatedData);
  };

  const toggleExpanded = (expanded: boolean, id?: ID) => {
    const updatedData = data.map((item) => {
      if (String(item.id) === String(id)) {
        return { ...item, expanded: !expanded };
      }
      return item;
    });
    return setData(updatedData);
  };

  const handleAdd = (field_type: MetadataFieldTypes) => {
    setData([
      ...data,
      {
        ...initialItem,
        field_type,
        expanded: selectFields.includes(field_type),
        id: uuid(),
        new: true,
        read_only: false,
      },
    ]);
  };

  const handleArchive = (id?: ID) => {
    if (_notNil(id)) {
      const originalItem = initialDataLookup[id];
      const updatedData = data.map((item) => {
        if (String(item.id) === String(id)) {
          return { ...originalItem, active: !item.active };
        }
        return item;
      });
      return setData(updatedData);
    }
  };

  const handleDelete = (id?: ID) => {
    const updatedData = data.filter((item) => {
      if (String(item.id) === String(id)) {
        return false;
      }
      return true;
    });
    return setData(updatedData);
  };

  const filteredData = data.filter((d) => d.type === type);
  return (
    <div>
      {_isNotEmpty(data) ? (
        <>
          <div className="ui-card overflow-hidden ui__table ui__repeater bb b--moon-gray mt4">
            <table
              className="w-100 tl bt b--moon-gray"
              style={{ borderSpacing: 0, fontSize: 11, whiteSpace: 'nowrap', borderCollapse: 'collapse' }}
            >
              <thead className="bg-near-white">
                <tr>
                  <th className={TH_CLASSES}></th>
                  <th className={TH_CLASSES}>Category</th>
                  <th className={TH_CLASSES}>Field type</th>
                  <th className={TH_CLASSES}>Slug</th>
                  <th className={TH_CLASSES}></th>
                </tr>
              </thead>
              <tbody className="black">
                {_isEmpty(filteredData) && (
                  <tr className="bg-light-gray" style={{ boxShadow: 'inset 0 0 0 16px white' }}>
                    <td className={TD_CLASSES}></td>
                    <td className={TD_CLASSES}>
                      <div className="flex flex-column white min-h5 justify-center items-center tc pa3 ma3">
                        <h2 className="tc pa3 ma3">No metadata has been added for {title}.</h2>
                      </div>
                    </td>
                    <td className={TD_CLASSES}></td>
                    <td className={TD_CLASSES}></td>
                    <td className={TD_CLASSES}></td>
                  </tr>
                )}
                {filteredData.map((row) => (
                  <Fragment key={row.id}>
                    <tr>
                      <td className={TD_CLASSES}>
                        {_notNil(row.field_type) && selectFields.includes(row.field_type) && (
                          <Icon
                            icon={row.expanded ? 'chev_up' : 'chev_down'}
                            className="dib mid-gray pointer"
                            width="14"
                            height="14"
                            onClick={() => toggleExpanded(row.expanded, row.id)}
                          />
                        )}
                      </td>

                      <td className={TD_CLASSES}>
                        <Title
                          title={row.title}
                          errors={errors}
                          id={row.id}
                          handleChange={handleChange}
                          disabled={!row.active}
                        />
                      </td>
                      <td className={TD_CLASSES}>{row.field_type}</td>
                      <td className={TD_CLASSES}>
                        {_notNil(row.slug) && (
                          <CopyToClipboard textToCopy={row.slug} toastText="Slug copied to clipboard">
                            {trunc(row.slug, 20)}
                          </CopyToClipboard>
                        )}
                      </td>
                      <td className={TD_CLASSES}>
                        {row.new ? (
                          <DeleteButton rowId={row.id} handleDelete={handleDelete} />
                        ) : (
                          <ToggleArchiveButton row={row} handleArchive={handleArchive} />
                        )}
                      </td>
                    </tr>

                    {_notNil(row.field_type) && selectFields.includes(row.field_type) && row.expanded === true && (
                      <tr>
                        <td></td>
                        <td colSpan={4}>
                          <SubComponent row={row} data={data} setData={setData} errors={errors} loading={loading} />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt3">
            <AddCategory handleAdd={handleAdd} />
          </div>
        </>
      ) : (
        <div className="mv4">
          <Banner info className="mw6" dismiss={false}>
            <h3 className="f5 normal basier-med lh-title">No metadata has been added</h3>
            <p className="f6 pt2 lh-copy">
              To get started, add metadata relevant to your studies. All team members will have access to the added
              metadata.
            </p>
            <AddCategory handleAdd={handleAdd} />
          </Banner>
        </div>
      )}
    </div>
  );
};

interface AddCategoryProps {
  handleAdd: (field_type: MetadataFieldTypes) => void;
}
const AddCategory: FC<AddCategoryProps> = ({ handleAdd }) => {
  type selectableFieldsOptions = {
    [key: string]: string;
  };
  const selectableFields: selectableFieldsOptions = {
    select: 'Select',
    multi_select: 'Multi-select',
    date: 'Date',
    text: 'Text',
    numeric: 'Number',
  };

  return (
    <SelectDropDown
      title="Add category"
      className="br-pill"
      btnProps={{
        icon: 'add_new',
        outline: true,
        paleBlue: true,
      }}
      alignMenu="left"
    >
      <ActionList
        actions={Object.entries(selectableFields).map(([key, value]) => ({
          name: value,
          action: () => handleAdd(key as MetadataFieldTypes),
        }))}
      />
    </SelectDropDown>
  );
};

export default Repeater;
