// @ts-nocheck: converted from JS

import { tempEntityArrayToMap } from '@/components/Studies/Create/Steps/GroupsAndTreatments/GroupsAndTreatment.util';
import { _isEmpty } from '@/littledash';
import { useMemo } from 'react';
import StudyGroupSetup from '../../../Settings/Steps/Groups/StudyGroupSetup';
import { GroupConfiguration } from './GroupConfiguration';

/**
 *
 * @param props {groups: Array<Group>, treatments: Array<Treatment>, groupTreatments: Array<GroupTreatment>}
 * @returns {Array}
 * @constructor
 */
export const groupTreatmentEntityMap = (groups = [], treatments = [], groups_treatments = []) => {
  const treatmentsMap = tempEntityArrayToMap(treatments);
  const groupTreatmentsMap = new Map(
    groups_treatments.map(({ group, treatments }) => [
      group,
      treatments.map((treatmentId) => treatmentsMap.get(treatmentId)),
    ])
  );
  return groups.map((group) => ({
    ...group,
    treatments: groupTreatmentsMap.get(group.temp_id) ?? [],
  }));
};

/**
 *
 * @param props {{study: {groups: Array<Group>, dispatch: React.Dispatch}}}
 * @returns {JSX.Element}
 * @constructor
 */

export const GroupCreation = ({ study, dispatch }) => {
  const groups = useMemo(
    () => groupTreatmentEntityMap(study?.groups, study?.treatments, study?.groups_treatments),
    [study]
  );

  const handleGroupConfigurationChange = (changedConfiguration) => {
    dispatch({
      type: 'updateStudy',
      data: { ...changedConfiguration },
    });
  };
  const handleGroupChange = (groups) => {
    const updatedGroups = tempEntityArrayToMap(groups);
    const updatedGroupTempIdList = Array.from(updatedGroups.keys());
    const updatedGroupTreatments = study?.groups_treatments ?? [];

    const filteredGroupsTreatments = updatedGroupTreatments.filter((groupTreatment) => {
      const groupKey = groupTreatment.group;
      return updatedGroupTempIdList.includes(groupKey);
    });

    dispatch({
      type: 'updateStudy',
      data: {
        groups: [...updatedGroups.values()],
        groups_treatments: filteredGroupsTreatments,
      },
    });
  };
  return (
    <>
      <div className={`ui-card mv4 ${_isEmpty(study.groups) ? 'pa4' : ''}`} style={{ minHeight: 190 }}>
        <StudyGroupSetup groups={groups} onUpdateGroupData={handleGroupChange} addTempId={true} />
      </div>
      <div className="ui-card mv4">
        <GroupConfiguration study={study} onChange={handleGroupConfigurationChange} />
      </div>
    </>
  );
};
