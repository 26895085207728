import { _isNil, _notNil } from '@/littledash';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { DateUtils } from '@/utils/Date.utils';
import { isAfter } from 'date-fns';
import type { ChangeOption } from '../Graph.model';
import type { GraphDataType } from './GraphTitle';

export const graphOptions = ({
  withErrors,
  average,
  features,
}: {
  withErrors: boolean;
  average?: GraphDataType;
  features: Record<string, boolean>;
}) => [
  {
    key: 'yaxis',
    title: 'Y-axis scale',
    options: [
      {
        label: 'Linear',
        val: 'linear',
      },
      {
        label: 'Logarithmic',
        val: 'log',
      },
    ],
  },
  ...(withErrors
    ? [
        {
          key: 'average',
          title: 'Daily Point Plotting',
          options: [
            {
              label: 'Mean',
              val: 'mean',
            },
            {
              label: 'Median',
              val: 'median',
            },
            ...(features.data_analysis
              ? [
                  {
                    label: '% dT/dC',
                    val: 'dtdc',
                  },
                  {
                    label: '% Inhibition',
                    val: 'tgi',
                  },
                ]
              : []),
          ],
        },
        {
          key: 'error',
          title: 'Variation display',
          options: [
            {
              label: 'Standard deviation',
              val: 'sd',
              disabled: _isNil(average) || average !== 'mean',
            },
            {
              label: 'Standard error of the mean',
              val: 'sem',
              disabled: _isNil(average) || average !== 'mean',
            },
          ],
        },
      ]
    : []),
];

const averageLabels = {
  dtdc: '% dT/dC',
  tgi: '% Inhibition',
} as const;

export const getAxisLabel = (
  activeMetric: Partial<PresetCalculation>,
  changeOption: ChangeOption,
  average?: keyof typeof averageLabels
) => {
  if (_notNil(average) && average in averageLabels) {
    return averageLabels[average];
  }

  const { name, unit } = activeMetric;
  if (changeOption !== 'absolute' && changeOption !== 'cumulative') {
    return `${name} (%)`;
  }
  if (_notNil(unit)) {
    return `${name} (${unit})`;
  }
  return `${name}`;
};

interface ConfigProps {
  hasLegend?: boolean;
  xTitle?: string;
  yTitle: string;
  yType?: Plotly.AxisType;
  isSurvival?: boolean;
}

export const graphConfig = ({
  hasLegend,
  yTitle,
  yType,
  xTitle,
  isSurvival = false,
}: ConfigProps): { config: Partial<Plotly.Config>; layout: Partial<Plotly.Layout> } => ({
  config: {
    responsive: true,
    displayModeBar: false,
  },
  layout: {
    title: {
      xref: 'paper',
      x: 0.05,
    },
    font: {
      family: 'Inter, Arial',
      size: 12,
      color: '#6b6b76',
    },
    hoverlabel: {
      font: {
        family: 'Inter, Arial',
        size: 12,
      },
    },
    margin: {
      l: 64,
      r: 24,
      b: 24,
      t: 24,
    },
    ...(hasLegend
      ? {
          legend: {
            font: {
              size: 12,
              family: 'Inter, Arial',
              color: '#333',
            },
            orientation: 'h',
            traceorder: 'normal',
            y: -0.15,
          },
        }
      : {}),
    yaxis: {
      title: yTitle,
      type: yType,
      nticks: 6,
      zeroline: false,
      ticks: 'inside',
      rangemode: 'tozero',
      linecolor: '#DFE3E8',
      linewidth: 3,
      gridcolor: '#DFE3E8',
      gridwidth: 1,
      tickcolor: '#DFE3E8',
      showexponent: 'all',
      exponentformat: 'e',
      hoverformat: '.2~f',
      ...(isSurvival
        ? {
            dtick: 20,
            tick0: 0,
            nticks: 6,
            automargin: true,
          }
        : {}),
    },
    xaxis: {
      title: xTitle,
      zeroline: false,
      ticks: 'inside',
      tickmode: 'auto',
      linecolor: '#DFE3E8',
      linewidth: 3,
      gridcolor: '#DFE3E8',
      gridwidth: 1,
      tickcolor: '#DFE3E8',
      ...(isSurvival
        ? {
            tick0: 0,
            nticks: 10,
            rangemode: 'tozero',
            automargin: true,
            showgrid: true,
          }
        : { showgrid: false }),
    },
  },
});

export const getLatestMeasurementFromMetrics = (
  metrics: Array<TreatmentGroup>,
  trackingDate?: string
): string | undefined => {
  return metrics.reduce((acc, group) => {
    if (DateUtils.isValidDate(group.latest_measurement_by_type)) {
      if (DateUtils.isValidDate(acc)) {
        acc = isAfter(new Date(group.latest_measurement_by_type), new Date(acc))
          ? group.latest_measurement_by_type
          : acc;
      } else {
        acc = group.latest_measurement_by_type;
      }
    }
    return acc;
  }, trackingDate);
};

export const renderDateRange = (first: string, last: string): string => {
  if (DateUtils.isValidDate(first) && DateUtils.isValidDate(last)) {
    if (first === last) {
      return DateUtils.renderDate(first);
    }
    return `${DateUtils.renderDate(first)} - ${DateUtils.renderDate(last)}`;
  }
  return 'Invalid date';
};
