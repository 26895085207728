import Icon from '@/components/UI/Icon';
import { Dispatch, useEffect, useReducer } from 'react';
import Layout from './Layout';
import { reducer } from './StepForm.reducer';
import { Step, StepFormAction, StepFormReducer, StepFormState } from './StepForm.model';

interface StepFormProps<T extends StepFormState<T>, PropType extends Record<string, any>> {
  title: string;
  steps: Array<Step<T, PropType>>;
  initialState: T;
  props: PropType;
  finalStepAction: (state: T, dispatch: Dispatch<StepFormAction<T>>) => void;
  horizontal: boolean;
  finalButtonText?: string;
  finalButtonClass?: string;
  cancelFunction?: () => void;
  extraButtonClick?: (state: T, dispatch: Dispatch<StepFormAction<T>>) => void;
  titlePadding?: string;
  disableNav?: boolean;
  errorBanner?: unknown;
  outerStep?: number;
  testPrefix?: string;
}

const StepForm = <T extends StepFormState<T>, PropType extends Record<string, any>>({
  title,
  titlePadding,
  steps,
  initialState,
  props,
  finalStepAction,
  finalButtonText,
  finalButtonClass,
  cancelFunction,
  extraButtonClick,
  disableNav,
  horizontal,
  errorBanner,
  outerStep,
  testPrefix = 'stepform',
}: StepFormProps<T, PropType>): JSX.Element => {
  const [state, dispatch] = useReducer<StepFormReducer<T>>(reducer, initialState);
  const nextStep = () => dispatch({ type: 'next' });
  const previousStep = () => dispatch({ type: 'previous' });
  const jumpToStep = (currentStep: number) => dispatch({ type: 'jumpToStep', currentStep });
  const { currentStep, stepReady, finalStepReady = true } = state;
  const step = steps[currentStep];
  const errorMessage = step.isValid(state);
  const isValid = errorMessage === true;

  useEffect(() => {
    if (outerStep) {
      jumpToStep(outerStep);
    }
  }, [outerStep]);

  return (
    <div className="h-100 flex flex-column justify-between">
      <Layout
        title={title}
        titlePadding={titlePadding}
        steps={steps}
        currentStep={currentStep}
        jumpToStep={jumpToStep}
        horizontal={horizontal}
        errorBanner={errorBanner}
        content={step.renderStep(state, dispatch, props)}
        state={state}
        dispatch={dispatch}
        testPrefix={testPrefix}
      />

      {!disableNav && (
        <div className="flex justify-between mt3">
          <div className={`flex justify-start ${state.currentStep === steps.length - 1 ? finalButtonClass : ''}`}>
            <button disabled={state.currentStep === 0} className="plain mr2" onClick={previousStep}>
              Back
            </button>

            {state.currentStep !== steps.length - 1 ? (
              <div
                data-tooltip-id="global-tooltip-id"
                data-tooltip-content={typeof errorMessage === 'string' ? errorMessage : undefined}
                data-tooltip-hidden={isValid}
              >
                <button className="mr2" disabled={!isValid || !stepReady} onClick={nextStep}>
                  Next
                </button>
              </div>
            ) : (
              <button
                className="mr2"
                disabled={!isValid || !finalStepReady}
                onClick={() => finalStepAction(state, dispatch)}
              >
                {finalButtonText || 'Finish'}
              </button>
            )}
          </div>
          <div className="flex">
            {step.extraButton && (
              <button onClick={() => extraButtonClick?.(state, dispatch)} className="plain relative mr2">
                <Icon
                  icon={step.extraButton.icon}
                  className="absolute"
                  style={{ left: '6px', top: '10px' }}
                  width="27"
                  height="20"
                />
                <span className="pl3">{step.extraButton.title}</span>
              </button>
            )}
            {cancelFunction && (
              <button className="plain" onClick={cancelFunction}>
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StepForm;
