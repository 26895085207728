import { PercentChangeOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.model';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import List from '@/components/UI/List';
import { asSortableListItem } from '@/components/UI/List/List';
import type { WorkflowModel, WorkflowModelChild } from '@/components/Workflow/Show/Workflow.model';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFieldArray, useFormContext } from 'react-hook-form';
import { assembleToggleOptions, MeasurementChild } from '../SetupWorkflow.utils';
import Select from '@/components/UI/Select';
import { assignIdentifierOptions } from '@/components/Modals/AddTask/Steps/AddTaskExecutionForm.tsx';

// measurements are always index 0 in workflow,
// think about refactor into a simple object/key later
const measurementsPathInFormState = 'workflowSetup.workflow[0]';

export type MeasurementOption = {
  label: string;
  value: string;
};

const WorkflowMeasurements = () => {
  const { control, watch, setValue, register } = useFormContext<WorkflowModel>();
  const { fields, swap: swapFormItems } = useFieldArray<WorkflowModelChild, 'fieldId'>({
    control,
    keyName: 'fieldId',
    name: `${measurementsPathInFormState}.children`,
  });

  const selectedMeasurements = watch(`${measurementsPathInFormState}.children`)?.reduce(
    (acc: Array<MeasurementOption>, field: MeasurementChild) => {
      if (field.value) {
        acc.push({ value: field.id, label: field.displayName });
      }
      return acc;
    },
    []
  ) as Array<MeasurementOption>;

  // There should be a better way to handle how we generate the after saving options,
  // the toggled state on `toggleOptions` is no longer required or to be stored in state
  // left as is for now for the sake of unit tests and other views working
  const workflowWatch = watch('workflowSetup.workflow');
  const toggleOptions = assembleToggleOptions(workflowWatch, 'measurements');

  const toggleAllMeasurements = (selected = true) => {
    fields.forEach((_: any, index: any) => {
      setValue(`${measurementsPathInFormState}.children[${index}].value`, selected);
    });
  };

  return (
    <>
      <div className="overflow-auto pa3" style={{ height: 375 }}>
        <div className="pb3">
          <a className="link blue f7 dib" onClick={() => toggleAllMeasurements()}>
            Select all
          </a>
          <a className="link blue f7 dib ml3" onClick={() => toggleAllMeasurements(false)}>
            Clear selection
          </a>
        </div>
        <List
          canAmend={false}
          canAdd={false}
          canRemove={false}
          data={fields}
          renderItem={asSortableListItem({
            canSort: true,
            sortableType: 'workflow-setup-measurement',
            moveCallback: swapFormItems,
          })(FormItem)}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">Change default cursor position</label>
        <Controller
          name={`${measurementsPathInFormState}.cursorPosition`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Select
              options={selectedMeasurements}
              defaultValue={selectedMeasurements?.find((option) => option?.value === value)}
              placeholder="None"
              onChange={(option) => onChange(option?.value ?? null)}
              data-testid="assign-default-cursor-select"
              disabled={selectedMeasurements.length === 0}
              isClearable
              isSearchable
              isMulti={false}
            />
          )}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">Weight percentage change from</label>
        <select className="mw5 mb0" name={`${measurementsPathInFormState}.percentChange.weight`} ref={register()}>
          <option
            value={PercentChangeOptions.fromFirstMeasurement}
            data-testid="weight-percentage-change-first-measurement"
          >
            First measurement
          </option>
          <option
            value={PercentChangeOptions.fromLatestMeasurement}
            data-testid="weight-percentage-change-latest-measurement"
          >
            Latest measurement
          </option>
          <option value={PercentChangeOptions.trackingDate} data-testid="weight-percentage-change-tracking-date">
            Tracking date
          </option>
        </select>
      </div>
      <div
        style={{ height: '12%' }}
        className="pa3 bt b--moon-gray flex justify-between items-center"
        data-testid="assign-identifiers-select"
      >
        <label className="mb0 f6">Assign identifiers</label>
        <Controller
          control={control}
          name={`${measurementsPathInFormState}.altIdToAssign`}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Select
              options={assignIdentifierOptions}
              defaultValue={assignIdentifierOptions?.find((option) => option?.value === value)}
              placeholder="None"
              onChange={(option) => onChange(option?.value ?? null)}
              isClearable
              isSearchable
              isMulti={false}
            />
          )}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name={`${measurementsPathInFormState}.onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search" data-testid="after-saving-option-focus-search-bar">
                Focus search bar
              </option>
              {Object.keys(toggleOptions).map((k) => (
                <option
                  key={k}
                  value={toggleOptions[k].value}
                  data-testid={`after-saving-option-${toggleOptions[k].value}`}
                >
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject" data-testid="after-saving-option-move-to-next-animal">
                Move to next animal
              </option>
            </select>
          )}
        />
      </div>
    </>
  );
};

interface FormItemProps {
  fieldId: string;
  id: string;
  displayName: string;
  value: unknown;
  index: number;
}

const FormItem = ({ fieldId, id, displayName, value, index }: FormItemProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      key={fieldId}
      defaultValue={value}
      name={`${measurementsPathInFormState}.children[${index}].value`}
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div className="flex justify-between items-center">
          <Checkbox
            className={`pointer ${index !== 0 ? 'pv1' : ''}`}
            label={displayName}
            name={id}
            checked={value}
            onChange={() => onChange(!value)}
          />
        </div>
      )}
    />
  );
};

export default WorkflowMeasurements;
