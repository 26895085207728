// @ts-nocheck: converted from JS

import { useCallback } from 'react';
import update from 'immutability-helper';

/**
 *
 * @param {[{id: string, title: string, items: [{id: string, title: string}]}]} groups
 * @param setGroups
 * @returns {{addItemCallback: (function(*=, *=): void), groups, moveCallback: (function(*=, *): void)}}
 */
const useGroupedDraggable = (groups, setGroups) => {
  /**
   * Move an item, given it's dragIndex, to a group (hoverGroupIndex) and place at position (hoverItemIndex)
   * @param dragIndex
   * @param hoverGroupIndex
   * @param hoverItemIndex
   */
  const moveItem = (dragIndex, hoverGroupIndex, hoverItemIndex = 0) => {
    const [dragGroupIndex, dragItemIndex] = dragIndex.split('-');
    const dragItemColumn = groups[dragGroupIndex];
    const dragItem = dragItemColumn.subjects.data[dragItemIndex];
    const groupsWithDragItemRemoved = update(groups, {
      [dragGroupIndex]: {
        subjects: {
          data: {
            $splice: [[dragItemIndex, 1]],
          },
        },
      },
    });
    const groupsWithDragItemAdded = update(groupsWithDragItemRemoved, {
      [hoverGroupIndex]: {
        subjects: {
          data: {
            $splice: [[hoverItemIndex, 0, dragItem]],
          },
        },
      },
    });
    setGroups(groupsWithDragItemAdded);
  };

  const moveItemById = (itemId, hoverGroupIndex, hoverItemIndex = 0) => {
    const itemToRemoveGroupIndex = groups.findIndex((group) =>
      group.subjects.data.some((subject) => subject.id === itemId)
    );
    const itemToRemoveIndex = groups[itemToRemoveGroupIndex]?.subjects.data.findIndex(
      (subject) => subject.id === itemId
    );
    const dragItem = groups[itemToRemoveGroupIndex]?.subjects.data[itemToRemoveIndex];
    const groupsWithDragItemRemoved = update(groups, {
      [itemToRemoveGroupIndex]: {
        subjects: {
          data: {
            $splice: [[itemToRemoveIndex, 1]],
          },
        },
      },
    });
    const groupsWithDragItemAdded = update(groupsWithDragItemRemoved, {
      [hoverGroupIndex]: {
        subjects: {
          data: {
            $splice: [[hoverItemIndex, 0, dragItem]],
          },
        },
      },
    });
    setGroups(groupsWithDragItemAdded);
  };

  /**
   * Move an item from one position to another position in a group or across different groups
   * dragIndex - the index of the item being dragged
   * overIndex - the index of the item that the dragged item is hovering over.
   * @type {function(*=, *): void}
   */
  const moveCallback = useCallback(
    (dragIndex, hoverIndex) => {
      const [hoverGroupIndex, hoverItemIndex] = hoverIndex.split('-');
      moveItem(dragIndex, hoverGroupIndex, hoverItemIndex);
    },
    [groups]
  );

  /**
   * Move an item from one position to another position in a group or across different groups, by its id
   * dragIndex - the index of the item being dragged
   * overIndex - the index of the item that the dragged item is hovering over.
   * @type {function(*=, *): void}
   */
  const moveByIdCallback = useCallback(
    (itemId, hoverIndex) => {
      const [hoverGroupIndex, hoverItemIndex] = hoverIndex.split('-');
      moveItemById(itemId, hoverGroupIndex, hoverItemIndex);
    },
    [groups]
  );

  /**
   * Add an item to an empty group
   * dragIndex - the index of the item being dragged
   * groupIndex - the index of the group that the item is being moved to, defaults to position 0
   * @type {function(*=, *=): void}
   */
  const addItemCallback = useCallback(
    (dragIndex, groupIndex) => {
      moveItem(dragIndex, groupIndex);
    },
    [groups]
  );

  return {
    moveCallback,
    moveByIdCallback,
    addItemCallback,
    groups,
  };
};

export default useGroupedDraggable;
