import { useState, useEffect } from 'react';
import { _get, _isNotBlank, _isNotEmpty } from '@/littledash';
import { findStringMatch } from '@/helpers';

type SearchItem = Record<any, any>;

interface UseSearchProps {
  items: SearchItem[];
  accessors: string[];
}

interface UseSearchMethods {
  search: (query: string) => void;
  searchResults: SearchItem[];
  resetSearch: () => void;
  hasResults: boolean;
}

/** A very simple client side search hook used to filter through items by a set of object accessors eg. `name.first` of your chosing.
 *  Can be used with `<SearchMenu onSearch={handleSearch} ..etc />`
 *  Note this only works with strings and is not a subsitute for `_get` */

const useSearch = ({ items, accessors }: UseSearchProps): UseSearchMethods => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<UseSearchProps['items']>(items);

  useEffect(() => {
    if (_isNotBlank(searchQuery) && _isNotEmpty(items) && _isNotEmpty(accessors)) {
      const results = items.filter((item) => {
        for (const accessor of accessors) {
          if (findStringMatch(_get(item, accessor, ''), searchQuery)) {
            return true;
          }
        }
        return false;
      });
      setSearchResults(results);
    }
  }, [searchQuery]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleResetSearch = () => {
    setSearchResults(items);
  };

  return {
    search: handleSearch,
    searchResults: searchResults,
    resetSearch: handleResetSearch,
    hasResults: _isNotEmpty(searchResults),
  };
};

export default useSearch;
