import { _notNil } from '@/littledash';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './ZendeskMessenger.scss';
import { useSelector } from 'react-redux';
import { ApiService } from '@/support/ApiService';

export function initializeZendesk(): void {
  const zendeskMessengerNode = document.getElementsByClassName('zendeskMessenger-mount')[0];
  // If the mount isn't on the page (e.g. outside the app), don't load the chat.
  if (!zendeskMessengerNode) {
    return;
  }
  ReactDOM.createRoot(zendeskMessengerNode).render(<ZendeskMessenger />);
}

const TARGET_ORIGIN = 'https://public.bnchcdn.com';

enum EventTypes {
  METADATA = 'zendesk-metadata',
  RENEW_JWT_RESPONSE = 'zendesk-renew-jwt-response',
  RENEW_JWT_REQUEST = 'zendesk-renew-jwt-request',
  OPEN_MESSENGER = 'open-zendesk-messenger',
  CLOSE_MESSENGER = 'close-zendesk-messenger',
  REFERRAL_URL_REQUEST = 'referral-url-request',
  REFERRAL_URL_RESPONSE = 'referral-url-response',
}

function ZendeskMessenger(): JSX.Element {
  const iframeNodeRef = useRef<HTMLIFrameElement | null>(null);
  const [isOpen, updateIsOpen] = useState<boolean>(false);

  const zendeskEnabled = useSelector(
    ({ team }: { team: { features: { zendesk: boolean } } }) => _notNil(team) && (team?.features?.zendesk ?? true)
  );

  async function authenticateMessenger(isRenew: boolean = false): Promise<void> {
    if (!iframeNodeRef?.current?.contentWindow) {
      return;
    }
    const response = await ApiService.call({
      endpoint: 'GET /api/v1/zendesk-sunco-jwt-sso',
      options: { onError: { throw: false, toast: false, capture: false } },
    });
    if (response.type === 'success') {
      const message = {
        type: isRenew ? EventTypes.RENEW_JWT_RESPONSE : EventTypes.METADATA,
        data: {
          ...response.body,
          customHelpOption: {
            url: 'https://docs.benchling.com',
            label: 'Special API Docs',
          },
        },
      };
      iframeNodeRef?.current?.contentWindow?.postMessage(message, TARGET_ORIGIN);
    }
  }

  function addZendeskEventListener(): void {
    window.addEventListener(
      'message',
      (e) => {
        if (e.origin !== TARGET_ORIGIN) {
          return;
        }
        if (e.data.type === EventTypes.RENEW_JWT_REQUEST) {
          authenticateMessenger(true);
        } else if (e.data.type === EventTypes.OPEN_MESSENGER) {
          updateIsOpen(true);
        } else if (e.data.type === EventTypes.CLOSE_MESSENGER) {
          updateIsOpen(false);
        } else if (e.data.type === EventTypes.REFERRAL_URL_REQUEST) {
          if (!iframeNodeRef || !iframeNodeRef.current || !iframeNodeRef.current.contentWindow) {
            return;
          }
          const message = { type: EventTypes.REFERRAL_URL_RESPONSE, referralUrl: window.location.pathname };
          iframeNodeRef?.current?.contentWindow?.postMessage(message, TARGET_ORIGIN);
        }
      },
      false
    );
  }

  function onLoad(): void {
    addZendeskEventListener();
    authenticateMessenger();
  }

  return zendeskEnabled ? (
    <iframe
      ref={iframeNodeRef}
      id="ze-iframe"
      title="zendesk"
      className={`messengerWindow ${isOpen ? 'open' : 'closed'}`}
      onLoad={onLoad}
      src={`${TARGET_ORIGIN}/zendesk/index-1.1.0.html`}
      sandbox="allow-same-origin allow-scripts allow-popups"
    />
  ) : (
    <></>
  );
}

export default ZendeskMessenger;
