import Button from '@/components/UI/Button';
import DebounceInput from '@/components/UI/DebounceInput';
import Icon from '@/components/UI/Icon';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { FC } from 'react';
import './Repeater.scss';
import { Errors, ExtendedMetadataItem, findMetaIndexById } from './Repeater.utils';

interface SubComponentProps {
  row: ExtendedMetadataItem;
  data: ExtendedMetadataItem[];
  setData: (data: ExtendedMetadataItem[]) => void;
  errors: Errors[];
  loading: boolean;
}

const SubComponent: FC<SubComponentProps> = ({ row, data, setData, errors, loading }) => {
  // TODO update these functions to be more performant

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = event.target.getAttribute('data-id');
    const value = event.target.value;
    const tempData: ExtendedMetadataItem[] = [...data];
    const metadataItem = findMetaIndexById(data, row.id);
    const tempDataItem = tempData[metadataItem]['options'];
    if (index && _notNil(tempDataItem)) {
      tempDataItem[Number(index)] = value;
    }
    return setData(tempData);
  };

  const handleAdd = () => {
    const tempData = [...data];
    const options = tempData[findMetaIndexById(data, row.id)]['options'];
    if (Array.isArray(options)) {
      const metadataItem = findMetaIndexById(data, row.id);
      if (_notNil(metadataItem)) {
        tempData[metadataItem]['options']?.push('');
      }
    } else {
      tempData[findMetaIndexById(data, row.id)]['options'] = [''];
    }
    return setData(tempData);
  };

  const handleRemove = (index: number) => {
    const tempData: ExtendedMetadataItem[] = [...data];
    const options = tempData[findMetaIndexById(data, row.id)]['options'];
    options?.splice(index, 1);
    tempData[findMetaIndexById(data, row.id)]['options'] = options;
    return setData(tempData);
  };

  const getFieldError = (id: ID, index: number) => errors.find(({ id: fieldId }) => String(fieldId) === String(id));
  const getFieldErrorMessage = (id: ID, index: number) => {
    const fieldError = getFieldError(id, index);

    if (
      fieldError &&
      _notNil(fieldError.errors.options) &&
      Array.isArray(fieldError.errors.options[0]) &&
      fieldError.errors.options[0][index]
    ) {
      return fieldError.errors.options[0][index];
    }
    return '';
  };

  const hasFieldError = (id: ID, index: number) => {
    const fieldError = getFieldError(id, index);
    if (
      fieldError &&
      _notNil(fieldError.errors.options) &&
      Array.isArray(fieldError.errors.options[0]) &&
      fieldError.errors.options[0][index]
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="ml5">
      <div className="ui__repeater__children mb2">
        <label className="pl4">Options</label>
        {row.options &&
          row.options.map((o, index) => (
            <div key={index} className="ui__repeater__child flex items-center pv2">
              <DebounceInput
                handleChange={handleChange}
                inputProps={{
                  className: `z-5 relative ${hasFieldError(row.id as ID, index) && 'input__error'}`,
                  'data-tooltip-content': getFieldErrorMessage(row.id as ID, index),
                  'data-tooltip-id': 'global-tooltip-id',
                  value: o,
                  'data-id': index,
                  style: { width: '268px', marginBottom: 0 },
                  type: 'text',
                }}
              />
              <Button
                soft
                onClick={() => handleRemove(index)}
                disabled={row.options?.length === 1}
                className="ml3"
                style={{ width: '40px', padding: '0' }}
              >
                <Icon icon="close" width="10" height="10" className="dark-gray" />
              </Button>
            </div>
          ))}
      </div>
      <Button disabled={loading} onClick={() => handleAdd()} className="ml4 mb3 br-pill f6" icon="add_new" paleBlue>
        <span>Add option</span>
      </Button>
    </div>
  );
};

export default SubComponent;
