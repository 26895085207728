// @ts-nocheck: converted from JS

import { useEffect } from 'react';

const Summary = ({ state, dispatch }) => {
  const {
    cages: { data: cageData },
    animals: { data: animalData },
  } = state;

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  return (
    <div className="flex items-center w-100 h-100">
      <div className="ui-card ma4 pa4 tc mw6 center w-100">
        <h2 className="f3 near-black pb3">Summary</h2>
        <div className="flex justify-between items-start bb b--moon-gray pv3">
          <div>Cages to be created</div>
          <div>{cageData.filter((cage) => cage.name).length}</div>
        </div>
        <div className="flex justify-between items-start pv3">
          <div>Animals to be created</div>
          <div>{animalData.length}</div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
