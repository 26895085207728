import type { FC } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form@latest';
import { ErrorMessage } from '@hookform/error-message';
import { useRequest } from '@/support/Hooks/request';
import { successToast, errorToast } from '@/helpers';
import { api as apiRoute } from '@/support/route';
import { validatePasswordStrength } from '@/components/PasswordReset/PasswordReset.utils';
import Button from '@/components/UI/Button';

export interface FormValues {
  current_password: string;
  password: string;
  password_confirmation: string;
}

interface UpdatePasswordFormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  checkPasswordStrength: (value: string) => Promise<string | boolean>;
  isDisabled: boolean;
}

interface EvaluationData {
  score: number;
  feedback: { suggestions: Array<string> };
}

const UpdatePassword = (): JSX.Element => {
  const formMethods = useForm();
  const { reset } = formMethods;
  const { sendRequest: updatePassword, requestSending: updatingPassword } = useRequest<FormValues>({
    route: apiRoute('user.update'),
    method: 'put',
  });
  const { sendRequest: evaluatePassword, requestSending: evaluatingPassword } = useRequest<
    Record<string, string>,
    EvaluationData
  >({
    route: apiRoute('password_resets.evaluate'),
    method: 'post',
  });

  const onSubmit = async (data: FormValues): Promise<void> => {
    await updatePassword(data);
    reset();
    successToast('Successfully updated password');
  };

  const checkPasswordStrength = async (password: string): Promise<string | boolean> => {
    try {
      const {
        data: {
          feedback: { suggestions },
          score,
        },
      } = await evaluatePassword({ password });
      return validatePasswordStrength(suggestions, score);
    } catch (error) {
      errorToast('There was a problem communicating with the server');
    }
    return 'Invalid password';
  };

  return (
    <div className="pa4">
      <FormProvider {...formMethods}>
        <UpdatePasswordForm
          onSubmit={onSubmit}
          checkPasswordStrength={checkPasswordStrength}
          isDisabled={evaluatingPassword || updatingPassword}
        />
      </FormProvider>
    </div>
  );
};

export const UpdatePasswordForm: FC<UpdatePasswordFormProps> = ({ onSubmit, checkPasswordStrength, isDisabled }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();
  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="update-password-form">
      <div className="mb3">
        <label htmlFor="current_password">Current Password</label>
        <input
          type="password"
          style={{ marginBottom: 0 }}
          className={`${errors.current_password ? 'input__error' : ''}`}
          {...register('current_password', {
            required: 'Enter your current password',
          })}
          data-testid="current-password-field"
        />
        <ErrorMessage
          errors={errors}
          name="current_password"
          render={({ message }) => (
            <p data-testid="current-password-field-error-message" className="f6 red db pt2">
              {message}
            </p>
          )}
        />
      </div>
      <div className="mb3">
        <label htmlFor="password">New Password</label>
        <input
          type="password"
          style={{ marginBottom: 0 }}
          className={`${errors.password ? 'input__error' : ''}`}
          {...register('password', {
            required: 'Enter your new password',
            validate: {
              isStrongPassword: checkPasswordStrength,
              isNotEqualToCurrentPassword: (password: string): string | boolean =>
                password !== watch('current_password') || "Can't be the same as your current password",
            },
          })}
          data-testid="password-field"
        />
        <ErrorMessage
          errors={errors}
          name="password"
          render={({ message }) => (
            <p data-testid="password-field-error-message" className="f6 red db pt2">
              {message}
            </p>
          )}
        />
      </div>
      <div className="mb3">
        <label htmlFor="password_confirmation">Confirm new password</label>
        <input
          type="password"
          style={{ marginBottom: 0 }}
          className={`${errors.password_confirmation ? 'input__error' : ''}`}
          {...register('password_confirmation', {
            required: 'Enter your new password again',
            validate: (value: string): string | boolean =>
              value === watch('password') || 'Must be the same as your new password.',
          })}
          data-testid="password-confirmation-field"
        />
        <ErrorMessage
          errors={errors}
          name="password_confirmation"
          render={({ message }) => (
            <p data-testid="password-confirmation-field-error-message" className="f6 red db pt2">
              {message}
            </p>
          )}
        />
      </div>
      <div className="pt3 mt3">
        <Button submit disabled={isDisabled} testId="update-password-submit-button">
          Save
        </Button>
      </div>
    </form>
  );
};

export default UpdatePassword;
