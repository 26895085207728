// @ts-nocheck: converted from JS
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import SearchSelect from '@/components/UI/SearchSelect';
import { sortArrAlphabetically } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import { ID } from '@/model/Common.model';
import { SampleType } from '@/model/Sample.model';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { RouteService } from '@/support/RouteService';
import { DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import { isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const SelectSamplesModal = ({ animalId, handleCallback, closeModal }) => {
  const [selected, setSelected] = useState<Array<ID>>([]);
  const [date, setDate] = useState(DateUtils.dateTimeNow());
  const [samples, setTeamSamples] = useState<Array<SampleType>>([]);
  const [generateSampleIds, setGenerateSampleIds] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { openModal } = modalAction(dispatch);
  const { newAbortController } = useAbortController();

  useEffect(() => {
    setLoading(true);
    const { url } = RouteService.legacyApi({ apiRoute: 'team-glossary.list', query: { group: 'samples' } });
    Http.get(url.href, {
      signal: newAbortController().signal,
    })
      .then((result) => {
        const response = result.data.data;
        setTeamSamples(response);
        setLoading(false);
      })
      .catch((error) => {
        if (notAborted(error)) {
          setError(error);
          setLoading(false);
        }
      });
  }, []);

  const handleModalToggle = () => {
    const selectedSampleTypes = selected.map((selectedSampleId) =>
      samples.find((o) => Number(selectedSampleId) === Number(o.id))
    );

    openModal('ADD_SAMPLE', {
      animalId,
      handleAfterClose: handleCallback,
      sampleTypes: selectedSampleTypes,
      collectedAt: date,
      allowNaming: !generateSampleIds,
    });
  };

  const dateInvalid = !date || !isValid(new Date(date));

  return (
    <div
      className="center mv3 bg-white br2 shadow-4"
      style={{ maxWidth: '500px' }}
      data-test-component="SelectSamplesModal"
      data-test-element="container"
    >
      {loading ? (
        <div className="pv3">
          <Loading txt="Loading samples" />
        </div>
      ) : (
        <>
          <div className="ph4 pv3 bb b--moon-gray">
            <h3 className="normal lh-title near-black basier-med f4">Collect new samples</h3>
            {error && (
              <Banner critical dismiss={false} className="mw6 mv3">
                <h3 className="f5 normal lh-title pb2">An error has occurred</h3>
                <p className="f6 pb3 lh-copy">
                  We are having an issue fetching the team glossary right now, please try again later. If this keeps
                  occurring please contact support.
                </p>
                <Button outline critical url={'mailto:support@benchling.com'}>
                  Contact support
                </Button>
              </Banner>
            )}
          </div>
          <div className="ph4 pv3 bg-near-white">
            <div>
              <div className="pb3">
                <label>Date collected</label>
                <div className="w5" data-test-element="data-time-picker-container">
                  <DateTimePickerNative onChange={setDate} value={date} />
                </div>
              </div>
            </div>
            <div className="mb4" data-test-element="auto-id-checkbox-container">
              <Checkbox
                name={'generateIds'}
                label={'Auto-generate sample IDs'}
                checked={generateSampleIds}
                className="f5"
                onChange={() => setGenerateSampleIds(!generateSampleIds)}
              />
            </div>
            <SelectSamples selected={selected} setSelected={setSelected} samples={sortArrAlphabetically(samples)} />
          </div>
          <div className="ph4 pv3 bt b--moon-gray" data-test-element="actions-container">
            <Button
              tooltip={
                _isEmpty(selected)
                  ? 'Select a sample to continue'
                  : dateInvalid
                    ? 'Select a valid date & time'
                    : undefined
              }
              disabled={_isEmpty(selected) || error || loading || dateInvalid}
              testId="collect-button"
              onClick={handleModalToggle}
            >
              Collect sample{selected.length > 1 && 's'}
            </Button>
            <Button className="ml3" plain onClick={() => closeModal()} testId="cancel-button">
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const SelectSamples = ({ selected = [], setSelected, samples = [] }) => {
  return (
    <div data-test-component="SelectSamples" data-test-element="container">
      {_isNotEmpty(samples) ? (
        <div className="ui-card">
          <SearchSelect
            selected={selected}
            setSelected={setSelected}
            sections={[
              {
                title: 'Sample types',
                items: samples.map((o) => ({
                  id: o.id,
                  title: o.title,
                })),
              },
            ]}
          />
        </div>
      ) : (
        <Banner info className="mw6" dismiss={false}>
          <h3 className="f5 normal basier-med lh-title">No team glossary items have been added</h3>
          <p className="f6 pt2 lh-copy">
            To get started, click on Glossary in the left hand menu and add sample type glossary items.
          </p>
        </Banner>
      )}
    </div>
  );
};

export default SelectSamples;
