export enum FilterTypes {
  eq_date = 'is equal to date',
  not_eq_date = 'is not equal to date',
  eq = 'is equal to',
  ne = 'is not equal to',
  contains = 'contains',
  not_contains = 'does not contain',
  gt = 'greater than',
  lt = 'less than',
  in = 'is',
  not_in = 'is not',
  between = 'is between',
  not_between = 'is not between',
  EMPTY = 'empty',
  NOT_EMPTY = 'not empty',
  IS_NULL = 'is null',
  IS_NOT_NULL = 'is not null',
}
