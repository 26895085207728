// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative';
import SearchSelect from '@/components/UI/SearchSelect';
import { _isEmpty } from '@/littledash';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import { isValid } from 'date-fns';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const SelectObservationsModal = ({ animalId, closeModal, handleCallback }) => {
  const [selected, setSelected] = useState([]);
  const [date, setDate] = useState(DateUtils.dateTimeNow());
  const dispatch = useDispatch();
  const { openModal } = modalAction(dispatch);

  const {
    collection: observations,
    collectionLoading: loading,
    collectionError: error,
  } = useFetchCollection({
    queryParams: {
      group: 'observations',
    },
    collectionType: 'teamGlossary',
  });

  const handleModalToggle = () => {
    const selectedObservations = selected.map((s) => observations.find((o) => Number(s) === Number(o.id)));

    return openModal('ADD_OBSERVATION', {
      selectedObservations,
      animalId,
      observed_at: date,
      handleCallback,
    });
  };

  const sortObservations = (observations) => [...observations].sort((a, b) => a?.title?.localeCompare(b?.title) ?? 1);

  const dateInvalid = !date || !isValid(new Date(date));

  return (
    <div className="center mv3 bg-white br2 shadow-4" style={{ maxWidth: '500px' }}>
      {loading ? (
        <div className="pv3">
          <Loading txt="Fetching observations" />
        </div>
      ) : (
        <>
          <div className="ph4 pv3 bb b-moon-gray">
            <h3 className="normal lh-title near-black basier-med f4">Record observations</h3>
            {error && (
              <Banner critical dismiss={false} className="mw6 mv3">
                <h3 className="f5 normal lh-title pb2">An error has occurred</h3>
                <p className="f6 pb3 lh-copy">
                  We are having an issue fetching the team glossary right now, please try again later. If this keeps
                  occurring please contact support.
                </p>
                <Button outline critical url={'mailto:support@benchling.com'}>
                  Contact support
                </Button>
              </Banner>
            )}
          </div>
          <div className="ph4 pv3 bg-near-white">
            <div>
              <div className="pb3">
                <label>Observed date</label>
                <DateTimePickerNative onChange={setDate} value={date} />
              </div>
            </div>
            <SelectObservations
              selected={selected}
              setSelected={setSelected}
              observations={sortObservations(observations)}
            />
          </div>
          <div className="ph4 pv3 bt b-moon-gray">
            <Button
              tooltip={
                _isEmpty(selected)
                  ? 'Select an observation to continue'
                  : dateInvalid
                    ? 'Select a valid date & time'
                    : undefined
              }
              disabled={_isEmpty(selected) || error || loading || dateInvalid}
              onClick={handleModalToggle}
            >
              Record observation{selected.length > 1 && 's'}
            </Button>
            <Button className="ml3" plain onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const SelectObservations = ({ selected = [], setSelected, observations = [] }) => {
  return (
    <div>
      {!_isEmpty(observations) ? (
        <div className="ui-card">
          <SearchSelect
            selected={selected}
            setSelected={setSelected}
            sections={[
              {
                title: 'Observations',
                items: observations.map((o) => ({
                  id: o.id,
                  title: o.title,
                })),
              },
            ]}
          />
        </div>
      ) : (
        <Banner info className="mw6" dismiss={false}>
          <h3 className="f5 normal basier-med lh-title">No team glossary items have been added</h3>
          <p className="f6 pt2 lh-copy">
            To get started, click on Glossary in the left hand menu and add observation glossary items.
          </p>
        </Banner>
      )}
    </div>
  );
};

export default SelectObservations;
