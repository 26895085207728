import { _notNil } from '@/littledash';
// @ts-expect-error: untyped lib
import FuzzySet from 'fuzzyset.js';
import type { IDField, ImportRowMatch } from '../../Importer.utils';

export const initialMatches = (names: Array<string>, fields: Array<IDField>): Record<string, ImportRowMatch> => {
  let matches = {};

  if (Array.isArray(names) && Array.isArray(fields)) {
    const fieldNames = fields.map((f) => f.name);
    const a = FuzzySet(fieldNames);

    matches = names.reduce<Record<string, ImportRowMatch>>((acc, v) => {
      const search = a.get(v);
      if (search) {
        const result = search[0];
        const fieldResult = fields.find((f) => result[1] === f.name);

        if (_notNil(fieldResult)) {
          acc[v] = {
            name: fieldResult.key,
            displayName: fieldResult.name,
            score: result[0],
          };
        }
      } else {
        acc[v] = {};
      }

      return acc;
    }, {});
  }

  return matches;
};
