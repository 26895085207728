// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import { _isEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { useEffect, useState } from 'react';
import TemplateTable from './TemplateTable';

const Templates = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchErrors, setFetchErrors] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const {
          data: { data },
        } = await Http.get(apiRoute('tasks.templateGroups.show'));
        setGroups(data);
      } catch (e) {
        setFetchErrors(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="pb5-l">
      <SubHeader linkToText="Tasks" link={webRoute('tasks')} />
      <div className="ph3 ph4-l pb3">
        <Header
          mainHeaderText="Templates"
          asideComponent={() => <Button url={webRoute('tasks.templates.new')}>Add new template</Button>}
        />
        <div className="pv3 pv4-l">
          {fetchErrors ? (
            <ApiErrorBanner className="mb4" error={fetchErrors} errorType={ApiErrorType.FETCH} />
          ) : !_isEmpty(groups) ? (
            <div className="ui-card">
              <TemplateTable templates={groups} />
            </div>
          ) : (
            <NoData
              title="There are no templates yet"
              text="Add a new template for your team so that it can be re-used across any study."
              bannerTxt="You can add tasks even after you've created a template"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Templates;
