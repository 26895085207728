// @ts-nocheck: converted from JS

import Animals from '@/components/Modals/PrintCageCards/4x2/Animals';
import Metadata from '@/components/Modals/PrintCageCards/4x2/Metadata';
import Header from '@/components/Modals/PrintCageCards/Header';
import { _notNil } from '@/littledash';

const CageCardTemplate = ({ study, cage, selectedMetadata, maxFields }) => {
  const bleed = '3';
  const width = '388';
  const height = '197';
  const fullWidth = `${width}px`;
  const fullHeight = `${height}px`;
  const bleedPx = `${bleed}px`;

  const { subjects } = cage;

  const animals = subjects?.data?.filter((animal) => _notNil(animal));

  return (
    <div
      className="flex flex-column overflow-hidden"
      style={{
        width: fullWidth,
        height: fullHeight,
        padding: bleedPx,
      }}
    >
      <Header cageName={cage.name} studyName={study.name} barcodeValue={cage.catalog} />
      <div className="flex flex-row h-100 w-100 ba bw1 b--black" style={{ marginTop: -5 }}>
        <div className="w-50 br bw1 b--black">
          <Animals animals={animals} />
        </div>
        <div className="w-50">
          <Metadata metadata={selectedMetadata} />
        </div>
      </div>
    </div>
  );
};

export default CageCardTemplate;
