// @ts-nocheck: converted from JS

import './SuccessTick.scss';
import Icon from '@/components/UI/Icon';

const SuccessTick = ({ className }) => {
  return (
    <div className={`success-tick ${className}`}>
      <Icon icon="tick" width="14" height="14" viewBox="0 0 20 20" className="white" />
    </div>
  );
};

export default SuccessTick;
