// @ts-nocheck: converted from JS

const Pagination = ({
  previousPage,
  nextPage,
  pageCount,
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageSize,
  pageSizes = [10, 25, 50, 1000],
  setPageSize,
  testPrefix = '',
}) => {
  const baseClasses = 'dib pointer hover-near-black noselect';

  return (
    <div
      className="flex justify-between flex-wrap items-center pa3 bt b--moon-gray"
      data-testid={`${testPrefix}__footer`}
    >
      <div>
        <a className={`${baseClasses} pr2`} onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
          «
        </a>
        <a className={`${baseClasses} ph2`} onClick={() => previousPage()} disabled={!canPreviousPage}>
          ←
        </a>
        <a className={`${baseClasses} ph2`} onClick={() => nextPage()} disabled={!canNextPage}>
          →
        </a>
        <a className={`${baseClasses} ph2`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          »
        </a>
      </div>
      <div className="f6 tc noselect" data-testid="table__per-page">
        View
        <select
          className="f6 mh2 w3 mv0"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          data-testid="table__per-page-options"
        >
          {pageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        per page
      </div>
      <div>
        <span className="f6 near-black lh-title">
          Showing page {pageIndex + 1} of {pageCount}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
