// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import CageCards from '@/components/Team/CageCards/CageCards';
import { useDispatch, useSelector } from 'react-redux';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import { successToast } from '@/helpers';

const Show = () => {
  const [hasFetchErrors, setHasFetchErrors] = useState(false);
  const [hasSubmissionErrors, setHasSubmissionErrors] = useState(false);
  const [studyMetadata, setStudyMetadata] = useState(undefined);
  const [studyMetadataLoading, setStudyMetadataLoading] = useState(false);
  const [cageMetadata, setCageMetadata] = useState(undefined);
  const [cageMetadataLoading, setCageMetadataLoading] = useState(false);

  const dispatch = useDispatch();

  const { team } = useSelector(({ team: { team } }) => ({
    team,
  }));

  const clearAllErrors = () => {
    setHasSubmissionErrors(false);
    setHasFetchErrors(false);
  };

  useEffect(() => {
    (async () => {
      try {
        clearAllErrors();
        setStudyMetadataLoading(true);
        const metadataResponse = await Http.get(apiRoute('meta-glossary.show'), {
          params: {
            filter_type: 'study_meta',
          },
        });
        if (metadataResponse?.data?.data) {
          setStudyMetadata(
            metadataResponse.data.data.map(({ id, title }) => ({
              id,
              label: title,
              value: title,
            }))
          );
        }
        setStudyMetadataLoading(false);
      } catch (e) {
        setHasFetchErrors(true);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        clearAllErrors();
        setCageMetadataLoading(true);
        const metadataResponse = await Http.get(apiRoute('meta-glossary.show'), {
          params: {
            filter_type: 'collection_meta',
          },
        });
        if (metadataResponse?.data?.data) {
          setCageMetadata(
            metadataResponse.data.data.map(({ id, title }) => ({
              id,
              label: title,
              value: title,
            }))
          );
        }
        setCageMetadataLoading(false);
      } catch (e) {
        setHasFetchErrors(true);
      }
    })();
  }, []);

  const loading = studyMetadataLoading || cageMetadataLoading || !studyMetadata || !team;

  if (loading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  const handleSubmit = async (data) => {
    clearAllErrors();
    const request = {
      cage_card_settings: {
        size: data.size,
        metadata: data.selectedMetadata,
      },
    };
    try {
      await Http.put(apiRoute('teams.update'), request);
      dispatch({ type: 'TEAM_UPDATE_TEAM', data: request });
      successToast('Successfully updated cage card settings');
    } catch (e) {
      setHasSubmissionErrors(true);
    }
  };

  return (
    <div>
      <div>
        {hasFetchErrors ? (
          <ApiErrorBanner className="mb4" error={hasFetchErrors} errorType={ApiErrorType.FETCH} />
        ) : (
          <>
            {hasSubmissionErrors && (
              <ApiErrorBanner className="mb4 mt0" error={hasSubmissionErrors} errorType={ApiErrorType.SUBMIT} />
            )}
            <CageCards
              studyMetadata={studyMetadata}
              cageMetadata={cageMetadata}
              value={team.cage_card_settings}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Show;
