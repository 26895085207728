import { type FC, ReactNode, useState } from 'react';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';

interface CalendarFiltersProps {
  children: ReactNode;
}

const CalendarSidebar: FC<CalendarFiltersProps> = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <div
      className="flex flex-column bg-light-gray justify-between h-100 br b--moon-gray"
      style={{ width: sidebarCollapsed ? '50px' : '400px' }}
      data-test-component="CalendarSidebar"
      data-test-element="container"
    >
      {sidebarCollapsed ? <div></div> : <div>{children}</div>}
      <div className={`menu ${sidebarCollapsed ? '--is-collapsed' : ''}`}>
        <div
          data-tooltip-content={sidebarCollapsed ? 'Expand' : ''}
          data-tooltip-id="global-tooltip-id"
          className="menu-item"
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        >
          <div className="menu-icon">{sidebarCollapsed ? <RiArrowRightLine /> : <RiArrowLeftLine />}</div>
          <div className="no-select menu-title">Collapse</div>
        </div>
      </div>
    </div>
  );
};

export default CalendarSidebar;
