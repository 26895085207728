// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import SuccessTick from '@/components/UI/SuccessTick';

const ApiKeyCreated = ({ closeModal, clientId, secretKey }) => {
  return (
    <div className="center mw6 ui-card">
      <div className="pa3 bg-white">
        <h2 className="normal lh-title f3 mb4 flex flex-row">
          <SuccessTick className="mr2" />
          <span>API key created</span>
        </h2>
        <p className="lh-copy f6 mb4">
          Your API key has been created successfully. Store it securely{' '}
          <b>as this is the only time it will be made available to you</b> and cannot be recovered by Benchling staff.
        </p>
        <div className={'mb3'}>
          <label htmlFor="clientId">Client ID</label>
          <input type="text" name="clientId" value={clientId} disabled />
        </div>
        <div className={'mb3'}>
          <label htmlFor="secretKey">Secret key</label>
          <input type="text" name="secretKey" value={secretKey} disabled />
        </div>
      </div>
      <div className="pa3 mt3 bt b--moon-gray bg-white">
        <Button onClick={closeModal}>Done</Button>
      </div>
    </div>
  );
};

export default ApiKeyCreated;
