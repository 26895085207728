import type { ApprovalStep } from '@/components/Studies/Show/ApprovalStatus';
import { CheckListItem, Line } from '@/components/UI/CheckList';
import { CheckListStatus } from '@/components/UI/CheckList/CheckList.utils';
import './Checklist.styles.scss';

interface CheckListProps {
  steps: Array<ApprovalStep>;
}

const CheckList = ({ steps = [] }: CheckListProps) => {
  return (
    <>
      {steps.map((step, i) => (
        <div key={step.title.toString()}>
          {i !== 0 && (
            <Line dashed={step.status === CheckListStatus.idle || steps[i - 1]?.status === CheckListStatus.idle} />
          )}
          <CheckListItem status={step.status}>
            <div className={`${['inactive', 'inactiveSuccess'].includes(step.status) ? 'inactive' : ''} flex`}>
              {step.title}{' '}
              {['inactive', 'inactiveSuccess'].includes(step.status) ? <div className="ml1">(inactive)</div> : ''}
            </div>
          </CheckListItem>
        </div>
      ))}
    </>
  );
};

export default CheckList;
