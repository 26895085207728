// @ts-nocheck: converted from JS

import { approvalStatusToChecklistStatusMap } from '@/components/Studies/Approval/Approval.utils';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import UserStatusAvatar from '@/components/UI/UserStatusAvatar';
import { fetchMetadataColumns, fetchStudiesMetadataColumns } from '@/helpers';
import { _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import { statusToTextMap } from '@/referenceData/study/status';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import { web as webRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';

export const getApprovers = (approval, team) => {
  const getApproversByIds = (approverIds) =>
    approverIds.map((approverId) => team.users.find((user) => user.id === approverId));
  return getApproversByIds(approval?.approval?.approvers?.ids ?? []).reduce((acc, approver) => {
    if (_notNil(approver)) {
      const actionedApprover = approval.approvers.find((assignedApprover) => assignedApprover.id === approver.id);
      acc.push({
        name: approver.name,
        email: approver.email,
        status: getApproverStatus(approver, actionedApprover),
      });
    }
    return acc;
  }, []);
};

const getAdditionalApprovers = (approval, team) => {
  const getApproversByIds = (approverIds) =>
    approverIds.map((approverId) => team.users.find((user) => user.id === approverId));
  return _notNil(approval.approval.approvers.required_ids) && _notNil(approval.required_approvers)
    ? getApproversByIds(approval.approval.approvers.required_ids).map((approver) => {
        const actionedApprover = approval.required_approvers?.find(
          (assignedApprover) => assignedApprover.id === approver.id
        );
        return {
          name: approver.name,
          email: approver.email,
          status: getApproverStatus(approver, actionedApprover),
        };
      })
    : [];
};

const getApproverStatus = (approver, actionedApprover) => {
  if (approver.pivot?.status !== 'inactive') {
    return approvalStatusToChecklistStatusMap[actionedApprover?.response] || 'idle';
  } else if (actionedApprover?.response === 'approved') {
    return 'inactiveSuccess';
  } else {
    return 'inactive';
  }
};

/**
 *
 * @param props {{study: Study,studyLink?: StudyLink}}
 * @returns {JSX.Element|string}
 * @constructor
 */
const StudyNameCell = ({ study, studyLink }) => {
  if (_notNil(studyLink?.access)) {
    return (
      <Link
        to={webRoute('studies.show', {
          id: study.id,
        })}
        openTab
        className="link blue"
      >
        {study.name}
      </Link>
    );
  }
  return study?.name ?? '';
};

export const generateColumns = async (team, signal: AbortSignal) => {
  let metadata = [];
  try {
    metadata = await fetchStudiesMetadataColumns(signal);
  } catch (error) {
    if (notAborted(error)) {
      ExceptionHandler.captureException(
        new InVivoError('Could not fetch studies metadata columns', {
          cause: error,
          slug: 'approval-study-metadata',
        })
      );
    }
  }

  return [
    {
      id: 'name',
      Header: 'Review name',
      accessor: 'approval.name',
      isVisible: true,
      sortBy: 'name',
      Cell: ({ row: { original } }) => (
        <Link
          to={webRoute('approvals.details', {
            approvalRequestId: original.id,
          })}
          className="link blue"
        >
          {original.approval.name}
        </Link>
      ),
    },
    {
      id: 'studyName',
      Header: 'Study Name',
      accessor: 'study.name',
      isVisible: true,
      sortDisabled: true,
      Cell: ({ row }) => <StudyNameCell study={row?.original?.study} studyLink={row?.original?.study_link} />,
    },
    {
      id: 'studyCreator',
      Header: 'Study Creator',
      accessor: 'study.author.name',
      sortDisabled: true,
      isVisible: true,
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      sortDisabled: true,
      isVisible: true,
      Cell: ({ row: { original } }) => statusToTextMap[original.status] || '',
    },
    {
      id: 'reviewers',
      Header: 'Reviewers',
      isVisible: true,
      sortDisabled: true,
      Cell: ({ row: { original } }) => {
        const approvers = [...getApprovers(original, team), ...getAdditionalApprovers(original, team)];
        return (
          <div className="flex flex-row">
            {approvers.map((approver) => (
              <UserStatusAvatar className="mr1" key={approver.email} user={approver} />
            ))}
          </div>
        );
      },
    },
    {
      id: 'time',
      Header: 'Submitted At',
      isVisible: true,
      accessor: 'submitted_at',
      sortBy: 'time',
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.submitted_at} defaultResponse="-" />,
    },
    ...fetchMetadataColumns({ metadata, nestedStudyLocation: 'study' }),
  ];
};

export const searchByText = 'Search by review name';
