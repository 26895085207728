// @ts-nocheck: converted from JS

import { mapWithKeys } from '@/helpers';
import { _isEmpty } from '@/littledash';
import store from '@/store';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import { add, sub } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

export const getAllStudyIdsFromTasks = (tasks) => {
  const studyIds = [];
  if (!_isEmpty(tasks) && Array.isArray(tasks)) {
    tasks.map((task) => {
      return !studyIds.includes(task.study.id) && studyIds.push(task.study.id);
    });
  }

  return studyIds;
};

export const groupTasksByStudyMap = (tasks) => {
  let groupedTasks = {};
  if (!_isEmpty(tasks) && Array.isArray(tasks)) {
    const studyIds = getAllStudyIdsFromTasks(tasks);
    if (!_isEmpty(studyIds) && Array.isArray(studyIds)) {
      groupedTasks = mapWithKeys(studyIds, (id) => {
        const tasksAssosiated = tasks.filter((t) => t.study.id === id);
        return { [id]: [...tasksAssosiated] };
      });
    }
  }

  return groupedTasks;
};

export const filterStudy = (studies, id) => {
  let study = {};

  if (!_isEmpty(studies) && Array.isArray(studies) && id) {
    study = studies.find((s) => s.id === id);
  }

  return study;
};

export const hasPendingTasks = (tasks) => {
  const dateNow = DateUtils.dateNow();
  return tasks.some((task) => task.date === dateNow && task.status !== 'complete');
};

export const togglePendingTasksNotification = (pendingTasksForToday) => {
  store.dispatch({
    type: 'PENDING_TASKS_FOR_TODAY',
    pendingTasksForToday,
  });
};

export const nextDate = (currentDate: string): string => {
  if (DateUtils.isValidDate(currentDate)) {
    const nextDate = add(fromZonedTime(currentDate, 'UTC'), { days: 1 });
    return formatInTimeZone(nextDate, 'UTC', DateRenderFormat.ISODate);
  }
  return 'Invalid date';
};

export const prevDate = (currentDate: string): string => {
  if (DateUtils.isValidDate(currentDate)) {
    const prevDate = sub(fromZonedTime(currentDate, 'UTC'), { days: 1 });
    return formatInTimeZone(prevDate, 'UTC', DateRenderFormat.ISODate);
  }
  return 'Invalid date';
};
