import { ReleaseInfo as ReleaseInfoData } from '@/generated/ReleaseInfo';
import { _isNil, _noop, _notNil } from '@/littledash';
import { formatDistanceToNow } from 'date-fns';
import { debounceTime, fromEvent, merge, Subscription } from 'rxjs';
import { DateUtils } from './Date.utils';

export interface ReleaseCheckerNoUpdate {
  type: 'no-update';
}

export interface ReleaseCheckerUpdateAvailable {
  type: 'update-available';
  time: number;
  commit: string;
  version: string;
}

export type ReleaseCheckerCheckResponse = ReleaseCheckerNoUpdate | ReleaseCheckerUpdateAvailable;

export interface ReleaseInfo {
  releaseVersion: string;
  commitId: string;
  commitTime: string;
}

interface ReleaseCheckerProps {
  onUpdateAvailable: (release: Omit<ReleaseCheckerUpdateAvailable, 'type'>) => void;
}

export class ReleaseChecker {
  static #defaultTimeout = 1000 * 60 * 15; // 15 mins
  static #timeoutAfterInitialise = 1000 * 30; // 30 seconds
  static #eventEmitter = new EventTarget();
  #timeoutID: number | NodeJS.Timeout | null = null;
  #eventSub$?: Subscription;

  static async fetchLatestReleaseInfo(): Promise<ReleaseInfo> {
    try {
      return fetch(`/release-info.json?v=${Date.now()}`)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          return Promise.reject(new Error('Could not fetch latest release info', { cause: response.statusText }));
        })
        .then((text) => {
          const detail = JSON.parse(text);
          ReleaseChecker.#eventEmitter.dispatchEvent(new CustomEvent<ReleaseInfo>('release-check:result', { detail }));
          return detail;
        });
    } catch (e) {
      return Promise.reject(new Error('Could not fetch latest release info', { cause: e }));
    }
  }

  static subscribe(listener: (event: CustomEvent<ReleaseInfo>) => void, signal: AbortSignal) {
    ReleaseChecker.#eventEmitter.addEventListener('release-check:result', listener as EventListener, {
      passive: true,
      signal,
    });
  }

  constructor(private props: ReleaseCheckerProps) {}

  initialise(): ReleaseChecker {
    window['InVivo'] = {
      debug: () => this.log(),
      enableFeatures: (...flags: Array<string>) =>
        this.updateFeatureFlags(
          flags.map((name) => ({
            name,
            value: true,
          }))
        ),
      disableFeatures: (...flags: Array<string>) =>
        this.updateFeatureFlags(
          flags.map((name) => ({
            name,
            value: false,
          }))
        ),
    };
    this.#eventSub$ = merge(
      fromEvent(document, 'visibilitychange', { passive: true }),
      fromEvent(window, 'online', { passive: true }),
      fromEvent(window, 'offline', { passive: true })
    )
      .pipe(debounceTime(1000))
      .subscribe(() => {
        if (navigator.onLine && document.visibilityState === 'visible') {
          const nextCheckTime = this.#nextCheckTime;
          this.#scheduleCheck({
            timeout: nextCheckTime <= Date.now() ? ReleaseChecker.#timeoutAfterInitialise : nextCheckTime - Date.now(),
          });
        } else {
          this.#clearSchedule();
        }
      });
    const nextCheckTime = this.#nextCheckTime;
    this.#scheduleCheck({
      timeout: nextCheckTime <= Date.now() ? ReleaseChecker.#timeoutAfterInitialise : nextCheckTime - Date.now(),
    });
    return this;
  }

  destroy() {
    this.#eventSub$?.unsubscribe();
    delete window['InVivo'];
  }

  get #nextCheckTime(): number {
    const value = Number(localStorage.getItem('release-check.next') ?? '0');
    return Number.isFinite(value) ? value : 0;
  }

  set #nextCheckTime(value: number) {
    localStorage.setItem('release-check.next', `${value}`);
  }

  #clearSchedule() {
    if (_notNil(this.#timeoutID)) {
      clearTimeout(this.#timeoutID);
      this.#timeoutID = null;
    }
  }

  #scheduleCheck(props: { force?: boolean; timeout?: number; reschedule?: boolean } = {}) {
    if (navigator.onLine && document.visibilityState === 'visible') {
      const force = props?.force ?? false;
      const reschedule = props?.reschedule ?? true;
      const timeout = props?.timeout ?? ReleaseChecker.#defaultTimeout;
      if (_isNil(this.#timeoutID) || force) {
        this.#clearSchedule();
        this.#nextCheckTime = Date.now() + timeout;
        this.#timeoutID = setTimeout(() => {
          this.#timeoutID = null;
          this.check()
            .then((result) => {
              if (result.type === 'update-available') {
                this.props.onUpdateAvailable({ commit: result.commit, version: result.version, time: result.time });
              }
            })
            .catch(_noop)
            .finally(() => {
              if (reschedule) {
                this.#scheduleCheck();
              }
            });
        }, timeout);
      }
    }
  }

  async check(): Promise<ReleaseCheckerCheckResponse> {
    try {
      const {
        releaseVersion: latestReleaseVersion,
        commitTime: latestReleaseCommitTime,
        commitId: latestReleaseCommitId,
      } = await ReleaseChecker.fetchLatestReleaseInfo();
      if (_notNil(latestReleaseCommitId) && ReleaseInfoData.commitId !== latestReleaseCommitId) {
        const time = Date.parse(latestReleaseCommitTime);
        return { type: 'update-available', commit: latestReleaseCommitId, version: latestReleaseVersion, time };
      }
      return { type: 'no-update' };
    } catch (e) {
      return Promise.reject(new Error('Release Check Failed', { cause: e }));
    }
  }

  async log() {
    const checkResult = await this.check();
    /* eslint-disable no-console */
    switch (checkResult.type) {
      case 'no-update': {
        console.log(
          '%c 🟢 Latest version 🟢 ',
          'background-color: green; color: white; font-style: bold; border: 5px solid limegreen; font-size: 5em;'
        );
        const releaseData = new Date(ReleaseInfoData.commitTime);
        console.table({
          Version: ReleaseInfoData.releaseVersion,
          Commit: ReleaseInfoData.commitId,
          'Released On':
            DateUtils.renderDateTime(ReleaseInfoData.commitTime) +
            ' (' +
            formatDistanceToNow(releaseData, { includeSeconds: true, addSuffix: true }) +
            ')',
        });
        break;
      }
      case 'update-available': {
        console.log(
          '%c 🚫 Update available 🚫 ',
          'background-color: yellow; color: red; font-style: bold; border: 5px solid red; font-size: 5em;'
        );
        const releaseDate = new Date(checkResult.time);
        console.table({
          Version: checkResult.version,
          Commit: checkResult.commit,
          'Released On':
            DateUtils.renderDateTime(releaseDate.toISOString()) +
            ' (' +
            formatDistanceToNow(releaseDate, { includeSeconds: true, addSuffix: true }) +
            ')',
        });
        break;
      }
    }
    /* eslint-enable no-console */
  }

  private async updateFeatureFlags(flags: Array<{ name: string; value: boolean }>) {
    const respoone = await fetch(new URL('api/v1/team/feature-flags', window.AppConfig.internalApiUrl), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
        'Team-Id': localStorage.getItem('team_id') ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(flags),
    });
    /* eslint-disable no-console */
    if (respoone.ok) {
      console.log(
        `%c Updated ${flags.length} flag(s) `,
        'font-style: bold; font-size: 2em; background-color: green; color: white; padding: 5px;'
      );
    } else {
      console.log(
        `%c Update failed for ${flags.length} flag(s) `,
        'background-color: red; color: yellow; font-style: bold; font-size: 2em; padding: 5px;'
      );
    }
    /* eslint-enable no-console */
  }
}
