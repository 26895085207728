// @ts-nocheck: converted from JS

import { returnSubmitPromiseArr } from '@/components/Glossary/Show.utils';
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import SearchSelect from '@/components/UI/SearchSelect';
import { sortArrAlphabetically, successToast } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import Http from '@/support/http';
import { RouteService } from '@/support/RouteService';
import { useEffect, useState } from 'react';

const TerminationReasons = ({ glossary = [] }) => {
  const [selected, setSelected] = useState([]);
  const [initialReasons, setInitialReasons] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState(false);

  const setInitialState = () => {
    setLoading(true);
    const { url } = RouteService.legacyApi({ apiRoute: 'team-glossary.list', query: { group: 'euthanize_reasons' } });
    return Http.get(url)
      .then((result) => {
        setInitialReasons(JSON.parse(JSON.stringify(result.data.data)));
        setSelected(result.data.data.map((r) => r.id));
        setReasons(result.data.data);
        setLoading(false);
      })
      .catch((error) => setResponseError(error));
  };

  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    if (!loading) {
      const tempObservations = [...reasons].filter((o) => selected.includes(o.id));
      selected.map((s) => {
        if (!tempObservations.map((o) => o.id).includes(s)) {
          const fromGlossary = [...glossary].find((g) => Number(g.id) === Number(s));
          const defaultProps = {
            type: 'none',
            options: {},
            description: null,
          };
          tempObservations.push({
            ...fromGlossary,
            ...defaultProps,
          });
        }
      });

      return setReasons(tempObservations);
    }
  }, [selected]);

  const handlePayload = (reasons) => {
    return reasons.reduce((acc, o, i) => {
      acc[i] = reasons[i];
      if (o.type !== 'numeric') {
        acc[i]['options'] = null;
      }
      return acc;
    }, []);
  };

  const handleSubmit = () => {
    setLoading(true);
    return returnSubmitPromiseArr(initialReasons, handlePayload(reasons))
      .then(() => {
        successToast('Successfully saved deceased reasons glossary!');
        setInitialState();
      })
      .catch((error) => setResponseError(error));
  };

  return (
    <div>
      <div>
        <Header mainHeaderText="Deceased" />
        <p className="f6 pb2">
          Can&apos;t find the deceased reason you’re looking for?
          <a
            target="_blank"
            without
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="mailto:support@benchling.com"
          >
            Let us know.
          </a>
        </p>
        {responseError && (
          <Banner critical dismiss={false} className="mw6 mv3">
            <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
            <p className="f6 pb3 lh-copy">
              An error has occurred when submitting the form, please try again later. If this keeps occurring please
              contact support.
            </p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
      </div>
      <div className={`${loading ? 'ui__disabled' : ''} flex flex-wrap justify-between items-top mv3`}>
        <div className="w-40">
          <div className="ui-card">
            <SearchSelect
              sections={[
                {
                  title: '',
                  items: [...glossary],
                },
              ]}
              selected={selected}
              setSelected={setSelected}
              sort
            />
          </div>
        </div>
        <div className="w-60">
          {loading ? (
            <Loading txt="Fetching Observations..." />
          ) : (
            <div className="pl4">
              {_isNotEmpty(reasons) ? (
                <div className="ui-card pa3">
                  <div className="bb b--moon-gray pb3 flex flex-wrap justify-between items-center">
                    <h3 className="f6 dark-gray normal basier-reg lh-title">Selected observations</h3>
                  </div>
                  <ul className="ui__overflow__scroll_y" style={{ height: '440px' }}>
                    {sortArrAlphabetically(reasons).map((o, i) => (
                      <li key={i} className="pv3 bb b--moon-gray lh-title f6 near-black">
                        {o.title}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <Banner info className="mw6" dismiss={false}>
                  <h3 className="f5 normal basier-med lh-title">No deceased reasons have been selected</h3>
                  <p className="f6 pt2 lh-copy">
                    To get started, search and select the deceased reasons relevant to your studies. All team members
                    will have access to use the selected deceased reasons.
                  </p>
                </Banner>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pv3 mt4 bt b--moon-gray">
        <Button disabled={loading} className="mr3" onClick={() => handleSubmit()}>
          Save
        </Button>
        <Button plain onClick={() => setInitialState()}>
          Undo changes
        </Button>
      </div>
    </div>
  );
};

export default TerminationReasons;
