// @ts-nocheck: converted from JS

const INITIAL_STATE = {
  pendingTasksForToday: false,
};

const applySetPendingTasksForToday = (state, action) => ({
  ...state,
  pendingTasksForToday: action.pendingTasksForToday,
});

function tasksReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'PENDING_TASKS_FOR_TODAY': {
      return applySetPendingTasksForToday(state, action);
    }
    default:
      return state;
  }
}

export default tasksReducer;
