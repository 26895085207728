import type React from 'react';
import { useDispatch } from 'react-redux';
import { modalAction } from '@/utils/modal';
import { RiInformationLine } from 'react-icons/ri';

type MetaEntityName = 'treatmentMeta' | 'sampleMeta' | 'animalMeta';

interface MetadataButtonProps {
  entity: unknown;
  entityName: MetaEntityName;
  tooltip?: string;
}

const onClick = (
  entity: unknown,
  entityName: MetaEntityName,
  openModal: (modal: string, props: unknown) => void,
  closeModal: () => void
) => {
  switch (entityName) {
    case 'treatmentMeta': {
      openModal('TREATMENT_META', {
        treatment: entity,
        closeModal,
      });
      break;
    }
    case 'sampleMeta': {
      openModal('VIEW_SAMPLE', {
        sample: entity,
        closeModal,
      });
      break;
    }
    case 'animalMeta': {
      openModal('ANIMAL_META', {
        animal: entity,
        closeModal,
      });
      break;
    }
  }
};

const MetadataButton: React.FC<MetadataButtonProps> = ({ entity, entityName, tooltip }) => {
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  return (
    <a
      onClick={() => onClick(entity, entityName, openModal, closeModal)}
      data-tooltip-id="global-tooltip-id"
      data-tooltip-content={tooltip}
      className="dib w1 h1 br1 relative pointer"
    >
      <RiInformationLine className="mid-gray hover-blue" size="1.15em" />
    </a>
  );
};

export default MetadataButton;
