import style from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.module.scss';
import { allInputsRequiredFormDisabled } from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { PresetMeasurementCreateV1 } from '@/model/Preset.model.ts';
import cn from 'classnames';
import { FC, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';

export const AllInputsRequiredForm: FC<{ disabled: boolean }> = ({ disabled }) => {
  const formMethods = useFormContext<PresetMeasurementCreateV1>();
  const formula = useWatch({ control: formMethods.control, name: 'formula' });
  const inputDisabled = useMemo(() => allInputsRequiredFormDisabled(formula), [formula]);

  return (
    <Controller
      control={formMethods.control}
      name="config.all_inputs_required"
      render={({ field: { value, onChange }, fieldState }) => (
        <Checkbox
          label="All inputs required"
          checked={disabled || inputDisabled ? true : (value ?? true)}
          disabled={disabled || inputDisabled}
          className={cn({ [style.invalid]: fieldState.invalid })}
          onChange={(event) => onChange(event.target.checked)}
        />
      )}
    />
  );
};
