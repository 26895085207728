// @ts-nocheck: converted from JS

import { Sidebar } from '@/components/Sidebar';
import ModalContainer from '@/components/UI/ModalContainer';
import { _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import { type ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface LayoutProps {
  route: unknown;
  children: ReactNode;
}

const Layout = ({ route, children }: LayoutProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { currentUser, currentStudyDetails } = useSelector(
    ({ user: { currentUser }, ui: { currentStudyDetails } }: State) => ({
      currentUser,
      currentStudyDetails,
    })
  );

  useEffect(() => {
    if (_notNil(currentStudyDetails) && currentStudyDetails?.id !== id) {
      dispatch({
        type: 'SET_STUDY_DETAILS',
        currentStudyDetails: {},
      });
    }
  }, []);

  return (
    <div className="flex h-100">
      <div className="h-100 flex">
        <Sidebar route={route} />
        {route?.navigation && <route.navigation route={route} />}
      </div>
      <main className="w-100 h-100 overflow-auto" data-testid="main-content-window">
        {currentUser && children}
        <ModalContainer />
      </main>
      <div data-testid="react-tooltip">
        <ReactTooltip id="global-tooltip-id" float={false} className="reacttooltip" />
      </div>
    </div>
  );
};

export default Layout;
