export type ISODate = string;
export type ISODateTime = string;
export type ID = string | number;
export type ApiID<EntityPredix extends string = string> = `${EntityPredix}_${string}`;
export type Nullable<T> = T | null | undefined;
export type RequiredNonNil<T> = {
  [P in keyof T]-?: Exclude<T[P], null | undefined>;
};
export type PickRequired<T, K extends keyof T> = T & RequiredNonNil<Pick<T, K>>;
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface Entity {
  id: ID;
  created_at: ISODateTime;
  updated_at?: ISODateTime;
}

export interface HasApiId<API extends string> {
  api_id: ApiID<API>;
}

export interface DataWrapper<Data> {
  data: Data;
}

export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export interface JwtPayload {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  jti: string;
  sub: string;
  prv: string;
  type: string;
}
