// @ts-nocheck: converted from JS

import { ApprovalSection } from '@/components/Studies/Show';
import FormRender from '@/components/UI/FormRender';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

const ApprovalFormSummary = ({ approval }) => {
  const formMethods = useForm({
    defaultValues: approval.form_data,
  });
  return (
    <FormProvider {...formMethods}>
      <FormRender groups={approval.approval.form.groups} groupContainer={ApprovalSection} submitted />
    </FormProvider>
  );
};

export default ApprovalFormSummary;
