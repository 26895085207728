import type React from 'react';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Loading from '@/components/Loading';
import BulkSamplesForm from './BulkCreateSamplesForm';
import type { MetadataItem } from '@/model/Metadata.model';
import type { BulkSamplesFormData, SampleType } from '@/model/Sample.model';

interface BulkCreateSamplesProps {
  closeModal: () => void;
  handleCallback: (formData: BulkSamplesFormData, metadata?: Array<MetadataItem>) => void;
}

const BulkCreateSamples: React.FC<BulkCreateSamplesProps> = ({ closeModal, handleCallback }) => {
  const { collection: sampleTypeOptions, collectionLoading: sampleTypesLoading } = useFetchCollection<SampleType>({
    queryParams: {
      group: 'samples',
    },
    collectionType: 'teamGlossary',
  });

  const { collection: sampleMetadata, collectionLoading: metadataLoading } = useFetchCollection<MetadataItem>({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: 'sample_meta',
    },
  });

  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Collect Samples" closeModal={closeModal} className="pa3 bb b--moon-gray w-100" />
      {sampleTypesLoading && metadataLoading ? (
        <div className="w-100 h-100">
          <Loading />
        </div>
      ) : (
        <BulkSamplesForm
          sampleMetadata={sampleMetadata}
          sampleTypeOptions={sampleTypeOptions}
          handleCallback={handleCallback}
          closeModal={closeModal}
        />
      )}
    </ModalContainer>
  );
};

export default BulkCreateSamples;
