// @ts-nocheck: converted from JS

export const selectTeam = (state: State) => state.team.team;
export const selectFeatures = (state: State) => state.team.features;

const INITIAL_STATE = {
  team: null,
  features: {},
};

const applySetTeam = (state, action) => ({
  ...state,
  team: action.team,
});

const applyUpdateTeam = (state, action) => ({
  ...state,
  team: {
    ...state.team,
    ...action.data,
  },
});

const applySetFeatures = (state, action) => ({
  ...state,
  features: action.features,
});

function teamReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'TEAM_SET_TEAM': {
      return applySetTeam(state, action);
    }
    case 'TEAM_UPDATE_TEAM': {
      return applyUpdateTeam(state, action);
    }
    case 'TEAM_SET_FEATURES': {
      return applySetFeatures(state, action);
    }
    default:
      return state;
  }
}

export default teamReducer;
