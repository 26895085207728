// @ts-nocheck: converted from JS

import React from 'react';
import MenuContainer from './MenuContainer';
import SearchSelect from '@/components/UI/SearchSelect';
import Button from '@/components/UI/Button';

interface TableShowMenuProps {
  columns: unknown;
  handleChange: (unknown) => void;
  closeMenu?: () => void;
}

const TableShowMenu: React.FC<TableShowMenuProps> = ({ columns, handleChange, closeMenu }) => {
  const selected = columns.reduce((acc, column) => {
    if (column.isVisible && !column.lockVisibility) {
      acc.push(column.id);
    }
    return acc;
  }, []);

  return (
    <MenuContainer width={300}>
      <SearchSelect
        sections={[
          {
            title: 'All fields',
            items: columns
              .filter((column) => column.Header)
              .map(({ id, Header }) => ({
                id,
                title: Header,
              })),
          },
        ]}
        overflowHeight={300}
        selected={selected}
        setSelected={(selected) =>
          handleChange(
            columns.map((column, i) => ({
              ...column,
              isVisible: column.lockVisibility ? column.isVisible : selected.includes(column.id),
            }))
          )
        }
      />
      <div className="bt b--moon-gray pa3">
        <Button onClick={closeMenu} plain className="f6 w-100">
          Close
        </Button>
      </div>
    </MenuContainer>
  );
};

export default TableShowMenu;
