import ApiErrorBanner from '@/components/ApiErrorBanner';
import type { AnimalFiltersEntity } from '@/components/Colony/Animals/Animals.utils';
import Loading from '@/components/Loading';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import { updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import type { Column, FilterOption } from '@/components/UI/Table/TableComponent.model';
import { _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useFetchContext, useFetchEntity } from '@/support/Hooks/fetch';
import type { UseFetchEntityOutput } from '@/support/Hooks/fetch/useFetchEntity';
import { RouteService } from '@/support/RouteService';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animalBulkActions, animalFilterOptions, animalTableColumns } from './AnimalsTable.utils';

const searchByOptions = [
  { value: 'name', display: 'Name' },
  { value: 'tail', display: 'Tail' },
  { value: 'ear', display: 'Ear' },
  { value: 'tag', display: 'Tag' },
  { value: 'donor', display: 'Donor' },
  { value: 'animal_number', display: 'Animal No.' },
  { value: 'cage_name', display: 'Cage' },
  { value: 'cage_number', display: 'Cage No.' },
];

interface ContextProps {
  entity: Study;
}

interface AnimalsTableProps {
  enableBulkActions?: boolean;
  onSelectRow?: () => void;
  onFetchTableData?: () => void;
  queryParams?: Record<string, string>;
}

const AnimalsTable: FC = ({
  enableBulkActions = true,
  onSelectRow,
  onFetchTableData,
  queryParams,
}: AnimalsTableProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<Array<FilterOption>>([]);
  const [columns, setColumns] = useState<Array<Column<any>>>([]);
  const { entity: study } = useFetchContext<ContextProps>();
  const calculations = study?.settings?.calculations;
  const { study_groups: studyGroups, id: studyId } = study;

  const { entity: observationOptions }: UseFetchEntityOutput<Array<string>> = useFetchEntity({
    entityType: 'observationFilterOptions',
    params: { id: studyId },
  });

  const { entity: animalFilters, entityError: animalFiltersError }: UseFetchEntityOutput<AnimalFiltersEntity> =
    useFetchEntity({
      entityType: 'animalFilters',
    });

  const settings = useSelector((state: State) => state.ui.settings);

  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  const buildColumns = async () => {
    if (_notNil(animalFilters)) {
      const columns = animalTableColumns(
        studyId,
        calculations ?? [],
        settings.tables.animals.columns,
        settings.changePercentage,
        animalFilters.metadata,
        features
      );
      setColumns(columns);
      setLoading(false);
    }
  };

  useEffect(() => {
    buildColumns();
  }, [animalFilters, calculations, settings]);

  const updatedSettings = useMemo(() => {
    // Ensure any derived columns (e.g: from measurements) are listed in the settings.
    return updateSettings('animals', columns, settings);
  }, [columns, settings]);

  const dispatch = useDispatch();
  const history = useHistory();

  const hasAccess = Auth.isWriteUserForStudy(study);
  const bulkActions: (props: UseTableProps<Animal>) => Array<unknown> = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { resetTableState },
    },
  }) =>
    hasAccess && enableBulkActions
      ? animalBulkActions(selectedFlatRows, resetTableState, study, dispatch, history, features)
      : [];

  useEffect(() => {
    if (
      _notNil(calculations) &&
      _notNil(studyGroups) &&
      _notNil(studyId) &&
      _notNil(observationOptions) &&
      _notNil(animalFilters)
    ) {
      setFilterOptions(animalFilterOptions(calculations, studyGroups, studyId, observationOptions, animalFilters));
    }
  }, [calculations, studyGroups, studyId, observationOptions, animalFilters]);

  return (
    <div className="pb4" data-test-component="AnimalsTable" data-test-element="animals-table-container">
      {loading ? (
        <div style={{ height: 650 }}>
          <Loading txt="Loading animals..." />
        </div>
      ) : (
        <>
          {animalFiltersError && (
            <ApiErrorBanner
              title="There was an error fetching table data"
              text="Please try again later. If this keeps occurring please contact support."
              error={animalFiltersError}
              className="mb4"
            />
          )}
          <APITable
            apiInfo={{
              type: 'internalApi',
              route: RouteService.api({
                endpoint: 'GET /api/v1/studies/{studyId}/animals',
                path: { studyId: study?.api_id },
              }).url,
            }}
            queryParams={queryParams}
            includeParam="latestMeasurement,study_group,alerts,cage,metadata"
            columns={columns}
            bulkActions={bulkActions}
            filterOptions={filterOptions}
            defaultSortBy={{ id: 'number', desc: false }}
            searchPlaceholderText="Search"
            searchQueryByOptions={searchByOptions}
            settings={updatedSettings}
            reduxTableName={'animals'}
            showMetricChangeFrom={true}
            onSelectRow={onSelectRow}
            pageSizes={[50, 100, 200, 500, 1000]}
            defaultPageSize={1000}
            onFetchTableData={onFetchTableData}
            testIdPrefix="animals"
          />
        </>
      )}
    </div>
  );
};

export default AnimalsTable;
