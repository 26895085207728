// @ts-nocheck: converted from JS

import { successToast } from '@/helpers';
import { useMemo, useState } from 'react';
import Table from '@/components/UI/Table';
import Button from '@/components/UI/Button';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { _isEmpty } from '@/littledash';
import { useDispatch } from 'react-redux';
import useCredentials from './useCredentials';
import useScopes from './useScopes';
import Header from '@/components/UI/Header';
import Loading from '@/components/Loading';

const ApiKeys = () => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const { credentials, refetch, revokeApiKey, loading: credentialsLoading } = useCredentials();
  const { scopes } = useScopes();

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const clearErrors = () => setApiError(false);
  const setErrors = () => setApiError(true);
  const setNotLoading = () => setLoading(false);

  const handleDeleteApiKeysClick = (clientIds) => {
    openModal(clientIds.length > 1 ? 'CONFIRM_DELETE_API_KEYS' : 'CONFIRM_DELETE_API_KEY', {
      onClick: () => {
        setLoading(true);
        Promise.all(clientIds.map(revokeApiKey))
          .then(() => successToast(`Successfully deleted ${clientIds.length > 1 ? 'API keys' : 'API key'}.`))
          .then(clearErrors)
          .then(refetch)
          .catch(setErrors)
          .finally(setNotLoading);
      },
      closeModal,
    });
  };

  const handleAddApiKeyClick = () => {
    openModal('ADD_API_KEY', {
      closeModal,
      handleCallback: (clientId, secretKey) => {
        clearErrors();
        closeModal();
        refetch();
        openModal('API_KEY_CREATED', {
          closeModal,
          clientId,
          secretKey,
        });
      },
      scopes,
    });
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'client_name',
        Header: 'Name',
      },
      {
        accessor: 'scope',
        Header: 'Scopes',
      },
      {
        accessor: 'client_id',
        Header: 'Client ID',
      },
      {
        accessor: 'api_version',
        Header: 'API Version',
      },
    ],
    []
  );

  const actions = useMemo(
    () => [
      {
        name: Object.keys(selectedRows)?.length > 1 ? 'Delete API keys' : 'Delete API key',
        action: () => {
          const getClientId = (rowIndex) => credentials[rowIndex].client_id;
          handleDeleteApiKeysClick(Object.keys(selectedRows).map(getClientId));
        },
      },
    ],
    [selectedRows, credentials]
  );

  const tableData = useMemo(
    () =>
      credentials.map((credential) => ({
        ...credential,
        scope: credential.scope.join(', '),
      })),
    [credentials]
  );

  const isLoading = loading || credentialsLoading;

  if (loading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="mb3">
        <Header mainHeaderText="API keys" />
      </div>
      <div className={`ui-card ${isLoading ? 'ui__disabled' : ''}`}>
        {apiError && <ApiErrorBanner error={apiError} />}
        {credentials?.length > 0 ? (
          <>
            <div className="flex pa3 bb b--moon-gray">
              <div className="flex w-100">
                <div className="flex mr2">
                  {!_isEmpty(selectedRows) && (
                    <div
                      className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                      style={{
                        whiteSpace: 'nowrap',
                        lineHeight: '2.4rem',
                      }}
                    >
                      {Object.keys(selectedRows).length} selected
                    </div>
                  )}
                  <SelectDropDown
                    title="Actions"
                    className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
                    alignMenu="right"
                    disabled={_isEmpty(selectedRows)}
                  >
                    <ActionList actions={actions} />
                  </SelectDropDown>
                </div>
              </div>
              <Button onClick={handleAddApiKeyClick}>Create API key</Button>
            </div>
            <Table
              data={tableData}
              columns={columns}
              selectable
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </>
        ) : (
          <div className="w-100 flex flex-column justify-center items-center" style={{ height: '400px' }}>
            <h3 className="normal lh-title f4 near-black pb4">Your API keys will appear here.</h3>
            <Button onClick={handleAddApiKeyClick}>Add an API key</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApiKeys;
