// @ts-nocheck: converted from JS

const InfoLink = ({ to, linkText, infoText, className }) => (
  <div className={className}>
    <p>
      <a className="link db" onClick={to}>
        {linkText}
      </a>
    </p>
    <p>{infoText}</p>
  </div>
);

export default InfoLink;
