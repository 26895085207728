import React, { useEffect, useState } from 'react';
import MenuContainer from './MenuContainer';
import Radio from '@/components/UI/FormElements/Radio';
import { DateUtils } from '@/utils/Date.utils';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative';
import { add, sub } from 'date-fns';
import { toDate } from 'date-fns-tz';
import type { Task } from '@/model/Task.model';

export const minTime = (dateTime?: string): string => {
  const currentDate = dateTime?.substring(0, 10) ?? DateUtils.dateNow();
  if (DateUtils.isValidDate(currentDate)) {
    return toDate(currentDate, { timeZone: DateUtils.timezone() }).toISOString();
  }
  return 'Invalid date';
};

export const maxTime = (dateTime?: string): string => {
  const currentDate = dateTime?.substring(0, 10) ?? DateUtils.dateNow();
  if (DateUtils.isValidDate(currentDate)) {
    return sub(add(toDate(currentDate, { timeZone: DateUtils.timezone() }), { days: 1 }), { seconds: 1 }).toISOString();
  }
  return 'Invalid date';
};

interface SetTaskProps {
  updateTask: (payload: Task | Record<string, unknown>) => void;
  task: Task;
}

const SetTask: React.FC<SetTaskProps> = ({ task, updateTask }) => {
  const [status, setStatus] = useState<string>(task.status);
  const [dateTime, setDateTime] = useState<string>(DateUtils.dateTimeNow());

  const statusOptions = ['incomplete', 'in_progress', 'complete'];
  const statusNames = ['Incomplete', 'In progress', 'Complete'];

  const taskPayload = {
    description: task.description,
    status: status,
    last_updated_at: dateTime,
  };

  useEffect(() => {
    updateTask(taskPayload);
  }, [status, dateTime]);

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    updateTask(taskPayload);
  };

  return (
    <MenuContainer width={300}>
      <div className="pa3">
        <form onSubmit={(e: unknown) => handleSubmit(e as React.FormEvent<HTMLInputElement>)}>
          <div className="pb2">
            <label htmlFor="status">Set task as</label>
            {statusOptions.map((o, i) => (
              <Radio
                index={i}
                key={o}
                checked={status === o}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStatus(e.target.value)}
                id={o}
                name={'status'}
                label={statusNames[i]}
                className="mb2"
                value={o}
              />
            ))}
          </div>
          <div className="f6">
            <label htmlFor="time">Set time</label>
            <DateTimePickerNative
              value={dateTime}
              max={maxTime()}
              min={minTime()}
              onChange={(value) => setDateTime(value)}
            />
          </div>
        </form>
      </div>
    </MenuContainer>
  );
};

export default SetTask;
