import { DropdownMenuDivider } from '@/components/UI/Dropdown';
import Link from '@/components/UI/Link';
import { Menu, MenuItem, SubMenu } from '@/components/UI/Menu';
import Truncate from '@/components/UI/Truncate/Truncate';
import { _notNil } from '@/littledash';
import { web as webRoutes } from '@/support/route';
import './Navigation.scss';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

interface NavigationItem {
  name: string;
  webRoute: string;
  icon: ReactElement;
  isDisabled?: boolean;
  children?: Array<{ name: string; webRoute: string; icon?: ReactElement }>;
}

export interface RouteProps {
  route: {
    canCollapseNav: boolean;
    component?: ReactNode;
    exact: boolean;
    navigation: Array<NavigationItem>;
    path: string;
    private: boolean;
  };
  studyNames?: { name: string; code: string };
}

const Navigation: React.FC<RouteProps> = ({ route, studyNames }) => {
  const match: {
    path: string;
    url: string;
    isExact: boolean;
    params: unknown;
  } | null = useRouteMatch(route.path);
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: {
      ui: {
        settings: {
          sidebar: {
            collapsed: boolean;
          };
        };
      };
    }) => state.ui.settings
  );

  const {
    sidebar: { collapsed },
  } = settings;

  const defaultOpen = route.navigation.reduce<Array<string>>((acc, v, index) => {
    if (v?.children) {
      v.children.forEach((child) => {
        const isWebRouteActive = match?.url === webRoutes(child.webRoute, { id: params?.id });
        if (isWebRouteActive) {
          acc.push(`subMenu${index}`);
        }
      });
    }

    return acc;
  }, []);

  const handleCollapse = () => {
    // Event for re-rendering sticky elements
    window.dispatchEvent(new Event('scroll'));
    // Event for re-rendering of plotly graphs
    window.dispatchEvent(new Event('resize'));
    dispatch({
      type: 'TOGGLE_OUTER_SIDEBAR',
    });
  };
  const isCollapsed = route?.canCollapseNav && collapsed;

  return (
    <div
      className={`navigation ${
        isCollapsed ? '--is-collapsed' : '--is-expanded'
      } br b--moon-gray bg-light-gray flex flex-column justify-between`}
    >
      <Menu defaultSelected={[match?.url]} defaultOpen={defaultOpen} isCollapsed={route?.canCollapseNav && collapsed}>
        {route.navigation.map((item, index) => (
          <Fragment key={index}>
            {item.children ? (
              <SubMenu
                title={item.name}
                key={index}
                icon={item.icon}
                index={`subMenu${index}`}
                disabled={item.isDisabled}
                url={match?.url ?? ''}
              >
                {item.children.map((child, childIndex) => (
                  <MenuItemWithLink
                    key={`${index}${childIndex}`}
                    index={child?.webRoute}
                    item={child}
                    disabled={item.isDisabled}
                  />
                ))}
              </SubMenu>
            ) : (
              <MenuItemWithLink key={index} index={item.webRoute} item={item} disabled={item.isDisabled} />
            )}
          </Fragment>
        ))}
        {_notNil(studyNames) && !collapsed ? (
          <>
            <DropdownMenuDivider />
            <div className="flex flex-column pa3">
              <Truncate tooltip={studyNames.name} textClass="f6 near-black fw5">
                {studyNames.name}
              </Truncate>
              <Truncate tooltip={studyNames.code} textClass="f6 dark-gray fw4">
                {studyNames.code}
              </Truncate>
            </div>
          </>
        ) : null}
      </Menu>
      <div className={`menu ${collapsed ? '--is-collapsed' : ''}`}>
        {route?.canCollapseNav && (
          <a
            data-tooltip-content={collapsed ? 'Expand' : ''}
            data-tooltip-id="global-tooltip-id"
            className="menu-item"
            onClick={handleCollapse}
          >
            <div className="menu-icon">{collapsed ? <RiArrowRightLine /> : <RiArrowLeftLine />}</div>
            <div className="no-select menu-title">Collapse</div>
          </a>
        )}
      </div>
    </div>
  );
};

interface MenuItemWithLinkProps {
  item: {
    icon?: ReactElement;
    name: string;
    webRoute: string;
  };
  index: string;
  disabled?: boolean;
}

const MenuItemWithLink: React.FC<MenuItemWithLinkProps> = ({ item, index, disabled }) => {
  const params: { id: string } = useParams();
  const link = webRoutes(item.webRoute, { id: params?.id });

  const menuItem = (
    <MenuItem icon={item.icon} index={link} key={index} disabled={disabled}>
      {item.name}
    </MenuItem>
  );

  return disabled ? (
    menuItem
  ) : (
    <Link to={link} data-testid={`${item.name.toLowerCase()}__nav-link`}>
      {menuItem}
    </Link>
  );
};

export default Navigation;
