import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import Loading from '@/components/Loading';
import { SentryUtils } from '@/utils/SentryUtils';
import { DatadogUtils } from '@/utils/Datadog.utils';
import { setGlobalData } from '@/actions/global-data';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import type { Team } from '@/model/Team.model';

interface AuthenticatedProps {
  globalData?: boolean;
  children: ReactNode;
}

const Authenticated: React.FC<AuthenticatedProps> = ({ children, globalData = true }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [_, setError] = useState<Error | false>(false);
  const { newAbortController: newSessionDetailAbortController } = useAbortController();
  const { newAbortController: newGlobalDataAbortController } = useAbortController();

  const dispatch = useDispatch();
  const { team } = useSelector<{ team: { team: Team } }, { team: Team }>(({ team: { team } }) => ({ team }));

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const { data } = await Http.get(apiRoute('sessions.get-details'), {
        signal: newSessionDetailAbortController().signal,
      });
      dispatch({
        type: 'USER_SET_CURRENT_USER',
        currentUser: data.user,
        teams: data.teams,
      });
      setLoading(false);
    } catch (error) {
      if (notAborted(error)) {
        setError(error as Error);
        setLoading(false);
      }
    }
  };

  const fetchGlobalData = async () => {
    try {
      const { data } = await Http.get(apiRoute('global-data'), {
        signal: newGlobalDataAbortController().signal,
      });
      setGlobalData(data, dispatch);
      SentryUtils.setUser(data.currentUser.id, data.team.id, data.currentUser.email);
      DatadogUtils.setUser(data.currentUser.id, data.team.id, data.currentUser.email);
    } catch (error) {
      if (notAborted(error)) {
        setError(error as Error);
      }
    }
  };

  useEffect(() => {
    if (globalData) {
      if (team === null) {
        fetchGlobalData();
      }
    } else {
      fetchUserData();
    }
  }, [globalData, team]);

  return <div className="h-100 flex-grow-1 w-100">{!loading ? children : <Loading />}</div>;
};

export default Authenticated;
