import Button from '@/components/UI/Button';
import { ErrorMessage } from '@hookform/error-message';
import { add, formatDuration, formatISODuration, intervalToDuration, sub } from 'date-fns';
import { FC, FocusEventHandler, useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, InputState, useForm } from 'react-hook-form';
import { parse as parseDuration } from 'tinyduration';
import Select from '../../../../Select';
import type { DataTableTimeUnit } from '../../../DataTable.model';
import type { TimeTableColumnAddProps } from './AddColumn.model';

interface TimeColumForm {
  data: {
    amount: string;
    unit: { label: string; value: DataTableTimeUnit };
  };
}

const timeUnitOptions: Array<TimeColumForm['data']['unit']> = [
  {
    label: 'Seconds',
    value: 'seconds',
  },
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
];
const unSupportedDurationSegments = /\d+Y\d+M/;
const toColumnName = (amount: number, unit: DataTableTimeUnit): string =>
  (amount < 0 ? '-' : '') + formatISODuration({ [unit]: Math.abs(amount) }).replace(unSupportedDurationSegments, '');

export const TimeTableColumnAdd: FC<Omit<TimeTableColumnAddProps, 'tableType'>> = ({
  toggleDropdownMenu,
  handleAddColumn,
  columns,
}) => {
  const timePoints = useMemo(
    () =>
      new Set(
        columns.map(({ name }) => {
          const duration = parseDuration(name);
          if (duration.negative) {
            return sub(0, duration).getTime();
          }
          return add(0, duration).getTime();
        })
      ),
    [columns]
  );
  const defaultValues: Partial<TimeColumForm> = { data: { amount: '', unit: timeUnitOptions[2] } };
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<TimeColumForm>({
    mode: 'onChange',
    defaultValues,
  });
  const onSubmit = async ({ data: { amount, unit } }: TimeColumForm) => {
    toggleDropdownMenu?.(false);
    reset(defaultValues);
    return handleAddColumn({ type: 'number', name: toColumnName(Number(amount), unit.value) });
  };
  return (
    <div className="pa3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div>
          <Controller
            name="data"
            control={control}
            rules={{
              validate: {
                required: (value: TimeColumForm['data']) =>
                  (value?.amount ?? '').length > 0 && (value?.unit?.value ?? '').length > 0 ? true : 'Field required',
                amountIsNumeric: (value: TimeColumForm['data']) =>
                  Number.isFinite(Number(value.amount)) ? true : 'Field must be numeric',
                isUnique: (value: TimeColumForm['data']) => {
                  const amount = Number(value.amount);
                  const timePoint = add(0, { [value.unit.value]: amount }).getTime();
                  return timePoints.has(timePoint) ? 'Field must be unique' : true;
                },
                isGreaterThanLast: (value: TimeColumForm['data']) => {
                  const amount = Number(value.amount);
                  const timePoint = add(0, { [value.unit.value]: amount }).getTime();
                  const lastTimePoint = Math.max(...timePoints);
                  if (timePoint < lastTimePoint) {
                    const lastTimePointString = formatDuration(
                      intervalToDuration({
                        start: 0,
                        end: lastTimePoint,
                      }),
                      { zero: false }
                    );
                    const negative = lastTimePoint < 0;
                    return `Must be greater than ${negative ? '-' : ''}${lastTimePointString}`;
                  }
                  return true;
                },
              },
            }}
            render={({ value, onChange, onBlur }: ControllerRenderProps, { invalid }: InputState) => (
              <DurationInput value={value} onChange={onChange} onBlur={onBlur} invalid={invalid} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="data"
            render={({ message }) => <small className="red db pt2">{message}</small>}
          />
        </div>
        <div className="mt3">
          <Button stateless className="link db pl0" submit disabled={isSubmitting || !isValid}>
            Add column
          </Button>
          <Button
            stateless
            className="near-black db pl0 mt2"
            onClick={() => {
              toggleDropdownMenu?.(false);
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

interface DurationInputProps {
  value: TimeColumForm['data'];
  onChange: (data: DurationInputProps['value']) => void;
  onBlur: FocusEventHandler;
  invalid: boolean;
}

const DurationInput: FC<DurationInputProps> = ({ value, onChange, onBlur, invalid }) => {
  const [durationValue, setDurationValue] = useState(value);
  useEffect(() => {
    onChange(durationValue);
  }, [onChange, durationValue]);

  return (
    <>
      <input
        type="text"
        placeholder="Name"
        autoComplete="off"
        className={invalid ? 'input__error' : ''}
        value={durationValue?.amount}
        onChange={(event) => setDurationValue((prev) => ({ ...prev, amount: event.target.value }))}
        onBlur={onBlur}
        style={{ marginBottom: 0 }}
        autoFocus
      />
      <Select
        value={value.unit}
        className={`${invalid ? 'input__error' : ''} mt2`}
        options={timeUnitOptions}
        onBlur={onBlur}
        onChange={(unit) => setDurationValue((prev) => ({ ...prev, unit: unit as TimeColumForm['data']['unit'] }))}
        isMulti={false}
      />
    </>
  );
};
