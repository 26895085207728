// @ts-nocheck: converted from JS

import { operationOptions } from '@/components/Glossary/Sections/Approvals/TemplateForm/TemplateForm.utils';
import { renderMetadataValue } from '@/helpers';

const Condition = ({ condition, metadata }) => {
  const metadataItem = metadata.find((metadataItem) => metadataItem.id === condition.glossary_id);
  if (!metadataItem) {
    return null;
  }

  const getOperationLabel = () => operationOptions.find((operation) => operation.value === condition.operation).label;

  return (
    <div>
      {metadataItem.title} {getOperationLabel()}{' '}
      {renderMetadataValue({ value: condition.value, field_type: metadataItem.field_type })}
    </div>
  );
};

export default Condition;
