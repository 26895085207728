import axios from 'axios';
import { useEffect, useRef } from 'react';

export interface NewAbortControllerFunction {
  (reason?: string): AbortController;
}

export interface AbortFunction {
  (reason?: string): void;
}

export const notAborted = (value?: any) => !axios.isCancel(value);
export const useAbortController = (): { newAbortController: NewAbortControllerFunction; abort: AbortFunction } => {
  const abortControllerRef = useRef<AbortController>();
  const abort: AbortFunction = (reason?: string) => {
    if (!(abortControllerRef?.current?.signal?.aborted ?? true)) {
      abortControllerRef.current?.abort?.(reason);
    }
  };

  const newAbortController: NewAbortControllerFunction = (reason?: string) => {
    abort(reason);
    const controller = new AbortController();
    abortControllerRef.current = controller;
    return controller;
  };

  useEffect(() => () => abort('component unmounted'), []);

  return { newAbortController, abort };
};
