import {
  analysisDayFromOptions,
  type AnalysisDispatch,
  analysisTypeOptions,
} from '@/components/Studies/Groups/Analysis/Analysis.util';
import { AnalysisIssueCounter } from '@/components/Studies/Groups/Analysis/AnalysisIssueCounter';
import { AnalysisSetup } from '@/components/Studies/Groups/Analysis/AnalysisSetup';
import Button from '@/components/UI/Button';
import { Dropdown } from '@/components/UI/Dropdown';
import Icon from '@/components/UI/Icon';
import { errorToast, successToast } from '@/helpers';
import { _isNil, _notNil } from '@/littledash';
import { AnalysisIssue, AnalysisState, TrackingFrom } from '@/model/Analysis.model.ts';
import type { Preset } from '@/model/Preset.model';
import type { StudyApiId } from '@/model/Study.model';
import { type FC, useMemo, useState } from 'react';
import { useModalAction } from '@/utils/modal';
import Http from '@/support/http';
import { RawAxiosResponseHeaders } from 'axios';
import { RouteService } from '@/support/RouteService';
import useMountedState from '@/support/Hooks/fetch/useMountedState';

interface AnalysisToolbarProps {
  studyApiId: StudyApiId;
  preset: Preset;
  state: AnalysisState;
  dispatch: AnalysisDispatch;
}

interface TableTitleProps {
  options: AnalysisState['options'];
  measurements: Record<string, { value: string; label: string }>;
}

const TableTitle: FC<TableTitleProps> = ({ options, measurements }) => {
  return (
    <div>
      <div className="f5 near-black">Summary Table</div>
      {_notNil(options) && _notNil(options.day) && (
        <div className="f6 mt1">
          <span>{analysisTypeOptions[options.type].label}</span>
          {Object.prototype.hasOwnProperty.call(measurements, options.measurement as string) && (
            <>
              <span className="pl1 pr1">/</span>
              <span>{measurements[options.measurement as string].label}</span>
            </>
          )}
          {options.type !== 'Survival' && options.type !== 'Tolerance' && (
            <>
              <span className="pl1 pr1">/</span>
              <span>Day {options.day}</span>
            </>
          )}
          {Object.prototype.hasOwnProperty.call(analysisDayFromOptions, options.day_from as TrackingFrom) && (
            <span className="pl1 pr1">( {analysisDayFromOptions[options.day_from as TrackingFrom].label} )</span>
          )}
        </div>
      )}
    </div>
  );
};

export const AnalysisToolbar: FC<AnalysisToolbarProps> = ({ studyApiId, preset, state, dispatch }) => {
  const { openModal } = useModalAction();
  const [exporting, setExporting] = useState<boolean>(false);
  const isMounted = useMountedState();

  const handleDownloadFile = (data: BlobPart, headers: Partial<RawAxiosResponseHeaders>) => {
    const fileName: string = headers['file-name'] as string;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = async () => {
    setExporting(true);
    try {
      if (_notNil(state.exportSnapshotApiId)) {
        const { data, headers } = await Http({
          method: 'get',
          url: RouteService.legacyApi({
            apiRoute: 'snapshot.export',
            path: { studyApiId, exportSnapshotApiId: state.exportSnapshotApiId },
          }).url.href,
          responseType: 'blob',
        });
        if (isMounted()) {
          handleDownloadFile(data, headers);
          successToast(`Successfully exported analysis.`);
          setExporting(false);
        }
      }
    } catch (error) {
      errorToast('Could not export analysis. Please try again later');
      setExporting(false);
    }
  };

  const measurements = useMemo(
    () =>
      (preset?.calculations ?? []).reduce(
        (acc, calculation) => ({
          ...acc,
          [calculation.id]: { value: calculation.id, label: calculation.name },
        }),
        {}
      ),
    [preset]
  );
  const { noConfig, optionsNotSet, optionsButtonDisabled } = useMemo(() => {
    const errorIssues = (state.issues ?? []).some((i) => i.level === 'Error');
    const noConfig = _isNil(state.config);
    const optionsNotSet = _isNil(state.options?.day);
    return { noConfig, optionsNotSet, optionsButtonDisabled: errorIssues || noConfig };
  }, [state]);
  return (
    <div
      className="ph4 pv3 bg-white bb b--moon-gray flex justify-between items-center"
      data-test-component="AnalysisToolbar"
      data-test-element="container"
    >
      <div>
        <TableTitle options={state.options} measurements={measurements} />
      </div>
      <div className="flex items-center justify-end">
        <AnalysisIssueCounter
          issues={state.issues ?? []}
          onClick={() =>
            openModal('ANALYSIS_ISSUES', {
              studyApiId: studyApiId,
              onAnalysisIssuesUpdate: (issues: Array<AnalysisIssue>) =>
                dispatch({
                  type: 'update-analysis-issues',
                  data: issues,
                }),
            })
          }
        />
        <div className="pr3">
          <Dropdown
            open={_notNil(state.config) && !Number.isInteger(state.options?.day)}
            disabled={optionsButtonDisabled}
            renderMenu={({ toggleDropdownMenu }) => (
              <AnalysisSetup
                config={state.config}
                options={state.options}
                measurements={measurements}
                onChange={(data) => {
                  toggleDropdownMenu(false);
                  dispatch({ type: 'update-analysis-options', data });
                }}
              />
            )}
          >
            <Button plain disabled={optionsButtonDisabled} loading={noConfig} testId="analysis-options-button">
              <span className="flex justify-between">
                <span>Options</span>
                <span className="pl2">
                  <Icon icon="down_triangle" width="8" height="8" className="right-1" />
                </span>
              </span>
            </Button>
          </Dropdown>
        </div>
        <div>
          <Button disabled={optionsButtonDisabled || optionsNotSet} loading={exporting} onClick={handleExport}>
            Export
          </Button>
        </div>
      </div>
    </div>
  );
};
