// @ts-nocheck: converted from JS

import { FieldTypes, groupTitleValue } from '@/components/FormBuilder/Item.utils';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import { web as webRoute } from '@/support/route';

export const isSelectField = (fieldType, fieldOptions = []) =>
  (fieldType?.toLowerCase() === FieldTypes.select && fieldOptions.length > 3) ||
  fieldType?.toLowerCase() === FieldTypes.multi_select;
export const isRadioField = (fieldType, fieldOptions = []) =>
  (fieldType?.toLowerCase() === FieldTypes.select && fieldOptions.length <= 3) ||
  fieldType?.toLowerCase() === FieldTypes.radio;

const isGroupTitle = (formItem) => formItem.type === groupTitleValue;

export const generateColumns = () => [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    width: 50,
    isVisible: true,
    Cell: ({ row: { original } }) => {
      return (
        <Link
          className="link blue"
          to={webRoute('team.studies.requests.edit', {
            requestTemplate: original?.id,
          })}
        >
          {original.name}
        </Link>
      );
    },
  },
  {
    id: 'created_at',
    accessor: 'created_at',
    Header: 'Created',
    width: 50,
    isVisible: true,
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
  },
];

/**
 * Convert the FormBuilder submit data into the format of an approval object
 * @param formData
 */
export const mapFormBuilderDataToRequestData = (formData) => {
  const buildNewGroup = (label) => {
    const group = {
      fields: [],
    };
    if (label) {
      group.label = label;
    }
    return group;
  };
  const buildRequestField = (formItem, isRadio, isSelect) => {
    const requestField = {
      type: isRadio ? FieldTypes.radio : formItem.type,
      label: formItem.label,
      required: formItem.required ?? true,
    };
    if (isSelect || isRadio) {
      requestField.options = formItem.options;
    }
    if (formItem.hint) {
      requestField.hint = formItem.hint;
    }
    return requestField;
  };
  const groupFormItemsReducer = (groups, formItem) => {
    if (isGroupTitle(formItem)) {
      groups.push(buildNewGroup(formItem.label));
      return groups;
    }
    const isSelect = isSelectField(formItem.type, formItem.options);
    const isRadio = isRadioField(formItem.type, formItem.options);

    const requestField = buildRequestField(formItem, isRadio, isSelect);
    const group = groups[groups.length - 1];
    if (!group) {
      groups.push(buildNewGroup());
    }

    groups[groups.length - 1].fields.push(requestField);
    return groups;
  };
  const hasFields = (group) => Boolean(group.fields?.length);
  const groups = formData.reduce(groupFormItemsReducer, []).filter(hasFields);
  return {
    groups,
  };
};

/**
 * Convert an request object into the structure to populate a FormBuilder value
 * @param requestForm
 */
export const mapRequestDataToFormBuilderData = (requestForm) => {
  const buildGroup = (label) => ({
    label: label || '',
    type: groupTitleValue,
  });
  const buildFormItem = (requestField) => {
    const isSelect = isSelectField(requestField.type, requestField.options);
    const isRadio = isRadioField(requestField.type, requestField.options);
    const mappedFormItem = {
      type: isRadio ? FieldTypes.radio : requestField.type,
      label: requestField.label,
      required: requestField.required ?? true,
    };
    if (requestField.hint) {
      mappedFormItem.hint = requestField.hint;
    }
    if (isSelect || isRadio) {
      mappedFormItem.options = requestField.options;
    }
    return mappedFormItem;
  };
  const groupsToFormItemsReducer = (formItems, group) => {
    if (group.label) {
      formItems.push(buildGroup(group.label));
    }
    const fields = group.fields.map(buildFormItem);
    formItems.push(...fields);
    return formItems;
  };

  return requestForm.groups.reduce(groupsToFormItemsReducer, []);
};
