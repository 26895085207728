// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import { ApiService } from '@/support/ApiService';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';

const useCredentials = () => {
  const [loading, setLoading] = useState(true);
  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    fetchCredentials();
  }, []);

  const setNotLoading = () => setLoading(false);

  const fetchCredentials = () => {
    setLoading(true);
    return Http.get(apiRoutes('v1.credentials.get'), { baseURL: AppConfig.internalApiUrl })
      .then(({ data }) => {
        if (Array.isArray(data.data)) {
          setCredentials(data.data);
        }
      })
      .finally(setNotLoading);
  };

  const addApiKey = ({ name, scopes }) => {
    setLoading(true);
    return ApiService.call({
      endpoint: 'POST /api/v1/credentials',
      body: { name, scope: scopes },
    }).finally(setNotLoading);
  };

  const revokeApiKey = (clientId) => {
    setLoading(true);
    return ApiService.call({
      endpoint: 'DELETE /api/v1/credentials/{clientId}',
      path: { clientId },
    }).finally(setNotLoading);
  };

  return {
    credentials,
    loading,
    refetch: fetchCredentials,
    revokeApiKey,
    addApiKey,
  };
};

export default useCredentials;
