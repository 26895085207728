import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { successToast } from '@/helpers';
import type { RegisteredDevice } from '@/model/Device.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { RouteService } from '@/support/RouteService';
import { DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const RegisterDevices: FC = () => {
  const dispatch = useDispatch();
  const { openModal } = modalAction(dispatch);
  const settings = useSelector<{ ui: { settings: Record<string, any> } }>((state) => state.ui.settings);
  const deviceTableColumns: Columns<RegisteredDevice> = useMemo(
    () => [
      {
        id: 'title',
        Header: 'Title',
        accessor: 'title',
        sortDisabled: true,
        isVisible: true,
        Cell: ({ row: { original: device }, apiTable: { fetchTableData } }) => (
          <span
            onClick={() => openModal('EDIT_DEVICE', { onEditDevice: fetchTableData, device })}
            className="link blue"
          >
            {device?.title ?? ''}
          </span>
        ),
      },
      {
        id: 'created_at',
        Header: 'Created at',
        accessor: 'created_at',
        sortDisabled: true,
        isVisible: true,
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => <>{DateUtils.renderDateTime(created_at, { defaultResponse: '-' })}</>,
      },
      {
        id: 'updated_at',
        Header: 'Updated at',
        accessor: 'updated_at',
        sortDisabled: true,
        isVisible: true,
        Cell: ({
          row: {
            original: { updated_at },
          },
        }) => <>{DateUtils.renderDateTime(updated_at, { defaultResponse: '-' })}</>,
      },
      {
        id: 'device_type_title',
        Header: 'Device type',
        accessor: 'device_type_title',
        sortDisabled: true,
        isVisible: true,
      },
      {
        id: 'usb_product_id',
        Header: 'USB Product ID',
        accessor: 'usb_product_id',
        sortDisabled: true,
        isVisible: true,
      },
      {
        id: 'product_name',
        Header: 'Product name',
        accessor: 'product_name',
        sortDisabled: true,
        isVisible: true,
      },
      {
        id: 'usb_vendor_id',
        Header: 'USB Vendor ID',
        accessor: 'usb_vendor_id',
        sortDisabled: true,
        isVisible: true,
      },
      {
        id: 'vendor_name',
        Header: 'Vendor name',
        accessor: 'vendor_name',
        sortDisabled: true,
        isVisible: true,
      },
    ],
    []
  );

  const { invoke: deleteDevices } = useApiHook({
    endpoint: 'DELETE /api/v1/devices',
    invokeOnInit: false,
  });

  const handleDeleteDevices = async (deviceApiIds: Array<RegisteredDevice['id']>, fetchTableData: () => void) => {
    try {
      await deleteDevices({ body: { device_api_ids: deviceApiIds } });
      await fetchTableData();
      successToast(`Deleted ${deviceApiIds.length} device${deviceApiIds.length > 1 ? 's' : ''}`);
    } catch (error) {
      // handled by `deleteDevices`
    }
  };

  const bulkActions: (props: UseTableProps<RegisteredDevice>) => Array<unknown> = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { fetchTableData },
    },
  }) => {
    const deviceApiIds = selectedFlatRows.map(({ original: { id } }) => id);
    return [
      {
        name: `Delete device${selectedFlatRows?.length > 1 ? 's' : ''}`,
        className: 'red',
        action: () =>
          openModal('DELETE_DEVICES', {
            onClick: () => handleDeleteDevices(deviceApiIds, fetchTableData),
          }),
      },
    ];
  };

  return (
    <>
      <Header mainHeaderText="Devices" />
      <p className="f6 pb2">
        Register devices for use in your research.
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="dib ml1 link blue"
          href="https://help.benchling.com/hc/en-us/articles/22038958660749"
        >
          Read more
        </a>
      </p>
      <div className="mv3">
        <APITable
          apiInfo={{
            type: 'internalApi',
            route: RouteService.api({
              endpoint: 'GET /api/v1/devices',
              path: undefined,
            }).url,
          }}
          bulkActions={bulkActions}
          columns={deviceTableColumns}
          defaultSortBy={{ id: 'created_at', desc: true }}
          searchPlaceholderText="Search devices"
          settings={settings}
          reduxTableName="registerDevices"
          hideSearch
          hideFilter
          AsideComponent={({ useTableProps }) => (
            <Button onClick={() => openModal('ADD_DEVICE', { onAddDevice: useTableProps.apiTable.fetchTableData })}>
              Add device
            </Button>
          )}
        />
      </div>
    </>
  );
};

export default RegisterDevices;
