// @ts-nocheck: converted from JS

import FormRender from '@/components/UI/FormRender';
import { renderMetadataValue } from '@/helpers';
import { _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';

const isMulti = (fieldType) => fieldType === 'Multiselect';
const isSelect = (fieldType) => fieldType === 'Select';

const getValue = (metaItem, linkMetadata) => {
  const metadata = linkMetadata.find((linkMetaItem) => linkMetaItem.glossary_id === metaItem.id);
  if (metadata && (isMulti(metaItem.field_type) || isSelect(metaItem.field_type))) {
    return metadata.map((metaValueItem) => ({
      label: safelyDecodeURIComponent(metaValueItem.value),
      value: safelyDecodeURIComponent(metaValueItem.value),
    }));
  } else if (metadata) {
    return metadata.value;
  }
  return '';
};

export const getMetadataFields = (metadata, link) => {
  const fields = metadata.map((metaItem) => {
    const { field_type: fieldType, slug, title, options } = metaItem;
    const metaValue = () => {
      if (_notNil(link.metadata)) {
        return getValue(metaItem, link.metadata);
      }
      if (fieldType === 'date') {
        return metaItem.value;
      }
      return renderMetadataValue(metaItem);
    };

    return {
      value: metaValue(),
      type: fieldType,
      name: `metadata.${slug || title}`,
      label: title,
      options:
        _isNotEmpty(options) &&
        options.map((option) => ({
          label: safelyDecodeURIComponent(option),
          value: option,
        })),
      isMulti: isMulti(fieldType),
    };
  });
  return <FormRender fields={fields} />;
};
