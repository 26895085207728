// @ts-nocheck: converted from JS

import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { _get, _isEmpty, _size } from '@/littledash';
import {
  DEFAULT_TABLE_WITH_CONTEXT_MAX_TABLE_HEIGHT,
  DEFAULT_TABLE_WITH_CONTEXT_ROW_HEIGHT,
} from '@/helpers.constants';

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
  },
];

export const headerProps = (props, { column }) => getStyles(props, column.align);
export const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

export const passes = (filterOptions, row, filter) => {
  try {
    const categoryName = filterOptions[filter.category.index].value;
    const categoryType = filterOptions[filter.category.index].type;
    const operationType = filterOptions[filter.category.index].operations[filter.operation.index].value;
    let category = _get(row, categoryName);
    const filterValue = String(filter.option.value);

    if (categoryType === 'measurements' || categoryType === 'latestMeasurements') {
      category = row[categoryType][categoryName];

      if (category) {
        category = category.value;
      }
    }
    switch (operationType) {
      case filterType.eq_date:
      case filterType.eq:
        return !_isEmpty(filterValue) && String(category) === String(filterValue);
      case filterType.ne:
        return String(category) !== String(filterValue);
      case filterType.contains:
        return !_isEmpty(filterValue) && category.includes(filterValue);
      case filterType.not_contains:
      case filterType.not_eq_date:
        return !category.includes(filterValue);
      case filterType.gt:
        return !_isEmpty(filterValue) && !_isEmpty(category) && Number(category) > Number(filterValue);
      case filterType.lt:
        return !_isEmpty(filterValue) && !_isEmpty(category) && Number(category) < Number(filterValue);
      case filterType.in:
        return category.includes(filterValue);
      case filterType.not_in:
        return !category.includes(filterValue);
      case filterType.EMPTY:
        return category.length <= 0;
      case filterType.NOT_EMPTY:
        return Array.isArray(category) && category.length > 0;
      case filterType.IS_NULL:
        return !category;
      case filterType.IS_NOT_NULL:
        return !!category;
    }
  } catch (e) {
    // deleteSettingsFromCookies(); DISPATCH THIS
    return true;
  }
};

export const filterDataBySearch = (data, searchQuery, field = false) => {
  let filterData = [];

  if (Array.isArray(data) && !_isEmpty(data)) {
    if (Array.isArray(searchQuery)) {
      /** Coming soon */
    } else {
      filterData = data.filter((row) => {
        if (field) {
          const getField = _get(row, field);
          if (getField) {
            return _get(row, field).toLowerCase().search(searchQuery.toLowerCase()) !== -1;
          }
          return false;
        } else {
          return Object.values(row).some((val) => String(val).toLowerCase().search(searchQuery.toLowerCase()) !== -1);
        }
      });
    }
  }

  return filterData;
};

export const filterDataByFilters = (data, filters, filterMatch, filterOptions) => {
  if (_size(filters)) {
    if (filterMatch) {
      filters.forEach((filter) => {
        data = data.filter((row) => passes(filterOptions, row, filter));
      });
    } else {
      const result = [];
      data.forEach((row) => {
        filters.some((filter) => {
          if (passes(filterOptions, row, filter)) {
            result.push(row);
            return true;
          }
        });
      });
      data = result;
    }
  }

  return data;
};

export function getTableWithContextConfig(maxHeight?: number) {
  return {
    rowHeight: DEFAULT_TABLE_WITH_CONTEXT_ROW_HEIGHT,
    maxTableHeight: maxHeight ?? DEFAULT_TABLE_WITH_CONTEXT_MAX_TABLE_HEIGHT,
  };
}

export const calculateWindowHeight = (rowCount: number, rowHeight: number, maxTableHeight?: number) => {
  const calculatedHeight = rowCount * rowHeight;
  return calculatedHeight <= maxTableHeight ? calculatedHeight : maxTableHeight;
};
