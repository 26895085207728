import { updateColumns } from '@/components/Dashboard/Dashboard.utils';
import MoveToStudyHeader from '@/components/Modals/MoveToStudy/MoveToStudyHeader';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Radio from '@/components/UI/FormElements/Radio/index';
import KeywordSearch from '@/components/UI/KeywordSearch';
import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { _isNil, _isNotEmpty } from '@/littledash';
import type { CageApiId } from '@/model/Cage.model';
import type { Study } from '@/model/Study.model';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import * as Auth from '@/support/auth';
import { useDebounce } from '@/support/Hooks';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ModalActions, ModalContainer, useModalAction } from '@/utils/modal';

interface MoveToStudyTableProps {
  cageApiIds: Array<CageApiId>;
  currentStudy: Study;
  animalCount: number;
  handleCallback: (currentStudy: Study, selectedStudy: Study | undefined, animalCount: number) => void;
}

interface PaginationProps {
  total_pages: number;
  current_page: number;
  per_page: number;
}

interface SortProps {
  order: string;
  sort: string;
}

const MoveToStudyList: FC<MoveToStudyTableProps> = ({ cageApiIds, currentStudy, animalCount, handleCallback }) => {
  const { closeModal } = useModalAction();
  const isMounted = useMountedState();
  const [selectedStudy, setSelectedStudy] = useState<Study>();
  const [studies, setStudies] = useState<Array<Study>>([]);
  const [query, setQuery] = useState('');
  const { newAbortController } = useAbortController();
  const [pagingProps, setPagingProps] = useState<PaginationProps>({ total_pages: 0, current_page: 1, per_page: 10 });
  const [sortProps, setSortProps] = useState<SortProps>({ sort: 'created_at', order: 'desc' });

  const { sort, order } = sortProps;

  const debouncedSearchQuery = useDebounce(query, 500);
  useEffect(() => {
    setPagingProps((previous) => ({
      total_pages: previous?.total_pages ?? pagingProps.total_pages,
      current_page: 1,
      per_page: previous?.per_page ?? pagingProps.per_page,
    }));

    fetchStudies(pagingProps.current_page, pagingProps.per_page, sortProps.sort, sortProps.order);
  }, [debouncedSearchQuery]);

  const handlePagination = (data: Pick<PaginationProps, 'per_page' | 'current_page'>) => {
    setPagingProps({
      per_page: data.per_page,
      current_page: data.current_page,
      total_pages: pagingProps.total_pages,
    });

    fetchStudies(data.current_page, data.per_page, sortProps.sort, sortProps.order);
  };

  const handleSort = (value: any) => {
    setSortProps({ sort: value.sort, order: value.order });

    fetchStudies(pagingProps.current_page, pagingProps.per_page, value.sort, value.order);
  };

  const fetchStudies = async (currentPage: number, perPage: number, sort: string, order: string) => {
    try {
      const response = await Http.get<Record<string, any>>(
        apiRoute(
          'studies.active',
          null,
          {
            ...(_isNotEmpty(debouncedSearchQuery) ? { query: debouncedSearchQuery } : {}),
            perPage: perPage,
            page: currentPage,
            sort,
            order,
            filterType: 'and',
          },
          `filters[]=type|eq|${
            encodeURIComponent(currentStudy.settings?.title) ?? 'No preset'
          }&filters[]=access|eq|write`
        ),
        { signal: newAbortController().signal }
      );

      if (isMounted()) {
        setPagingProps((previous) => ({
          total_pages: response.data.meta.pagination.total_pages,
          current_page: previous?.current_page ?? pagingProps.current_page,
          per_page: previous?.per_page ?? pagingProps.per_page,
        }));

        setStudies(response.data.data);
      }
    } catch (error: any) {
      // intentionally empty
    }
  };

  const onSelectStudy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const study = studies.find((s) => s.id === Number(e.target.value));
    setSelectedStudy(study);
  };

  const isRowDisabled = (study: Study) => {
    if (
      study.id === currentStudy.id ||
      study?.settings?.id !== currentStudy.settings?.id ||
      !Auth.isWriteUserForStudy(study)
    ) {
      return true;
    }
  };

  const columns: Array<Column<Study>> = [
    {
      id: 'name',
      Header: 'Study name',
      accessor: 'name',
      sortBy: 'name',
      Cell: ({
        row: {
          original,
          original: { id, name, settings },
          index,
        },
      }) => (
        <div className="flex flex-wrap items-center">
          <Radio
            id={id as string}
            name="move-to-study-select"
            onChange={onSelectStudy}
            checked={selectedStudy?.id === Number(id)}
            value={id as string}
            label={name}
            disabled={isRowDisabled(original)}
            className="mb2"
            index={index}
            key={id as string}
          />
        </div>
      ),
      isVisible: true,
    },
    {
      id: 'code',
      Header: 'Study code',
      accessor: 'code',
      sortBy: 'code',
      isVisible: false,
      Cell: ({ row: { original } }) => (
        <span className={isRowDisabled(original) ? 'dark-gray' : ''}>{original.code}</span>
      ),
    },
    {
      id: 'preset',
      Header: 'Preset',
      accessor: 'preset',
      sortBy: 'type',
      Cell: ({ row: { original } }) => (
        <span className={isRowDisabled(original) ? 'dark-gray' : ''}>{original.settings.title}</span>
      ),
      isVisible: true,
    },
    {
      id: 'created_at',
      Header: 'Created',
      accessor: 'created_at',
      sortBy: 'created_at',
      Cell: ({ row: { original } }) => (
        <span className={isRowDisabled(original) ? 'dark-gray' : ''}>
          <DateTimeRenderer value={original.created_at} />
        </span>
      ),
      isVisible: true,
    },
  ];

  const updatedColumns = updateColumns(studies, columns);

  const handleFormSubmit = async () => {
    await onSubmit();
    handleCallback(currentStudy, selectedStudy, animalCount);
  };

  const { invoke: moveAnimalsToStudy } = useApiHook({
    endpoint: 'POST /api/v1/operations/study-cage-move',
    body: { old_study: '', new_study: '', cage_ids: [] },
    invokeOnInit: false,
  });

  const onSubmit = async () => {
    await moveAnimalsToStudy({
      body: { old_study: currentStudy?.api_id, new_study: selectedStudy?.api_id, cage_ids: cageApiIds },
    });
  };

  return (
    <ModalContainer size="medium" className="bg-white h-100">
      <MoveToStudyHeader cageCount={cageApiIds.length} />
      <div className="pa3 bb b--moon-gray">
        <KeywordSearch
          style={{ width: '22rem' }}
          searchQuery={query}
          searchPlaceholderText="Search by study name or code"
          setSearchQuery={setQuery}
          autoFocus={true}
        />
      </div>
      <Table
        noDataMessage="No active studies"
        data={studies ?? []}
        columns={updatedColumns}
        manualSort={{ sort, order }}
        setManualSort={handleSort}
      />
      <Pagination
        current_page={pagingProps.current_page}
        total_pages={pagingProps.total_pages}
        per_page={pagingProps.per_page}
        handleChange={handlePagination}
      />
      <ModalActions
        submitBtnText="Next"
        cancelBtnText="Cancel"
        submitButtonProps={{
          className: 'ml3',
          disabled: _isNil(selectedStudy),
          loading: false,
        }}
        onCancel={closeModal}
        onSubmit={handleFormSubmit}
        className="pa3 flex self-end bt b--moon-gray w-100"
      />
    </ModalContainer>
  );
};

export default MoveToStudyList;
