// @ts-nocheck: converted from JS

import Navigation from '@/components/Navigation/index';
import { RiArchiveLine, RiCalendarLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

const TasksNav = ({ route }) => {
  const { tasksEnabled } = useSelector(({ team }) => ({
    tasksEnabled: !(team?.features?.hide_tasks ?? false),
  }));

  const navigation = [
    {
      name: 'Tasks',
      webRoute: 'tasks',
      icon: <RiCalendarLine />,
    },
    {
      name: 'Templates',
      webRoute: 'tasks.templates',
      icon: <RiArchiveLine />,
    },
  ];

  return tasksEnabled ? null : <Navigation route={{ ...route, navigation }} />;
};

export default TasksNav;
