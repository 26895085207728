import { datatableFormulaParser as dtlParser } from '@/generated/dsl/DatatableFormula.parser';
import { presetFormulaParser as pstParser } from '@/generated/dsl/PresetFormula.parser';
import { _noop, _notNil } from '@/littledash';
import type { Completion, CompletionContext, CompletionResult } from '@codemirror/autocomplete';
import { LanguageSupport, LRLanguage, syntaxTree } from '@codemirror/language';
import type { Diagnostic } from '@codemirror/lint';
import type { EditorView } from '@codemirror/view';
import { styleTags, tags } from '@lezer/highlight';

export const presetFormulaParser = pstParser.configure({
  props: [
    styleTags({
      'Keyword!': tags.operator,
      Number: tags.number,
      Variable: tags.variableName,
      MultiplicationOperator: tags.operator,
      DivisionOperator: tags.operator,
      AdditionOperator: tags.operator,
      SubtractionOperator: tags.operator,
      OpeningBracket: tags.paren,
      ClosingBracket: tags.paren,
    }),
  ],
});
export const datatableFormulaParser = dtlParser.configure({
  props: [
    styleTags({
      Number: tags.number,
      Variable: tags.variableName,
      MultiplicationOperator: tags.operator,
      DivisionOperator: tags.operator,
      AdditionOperator: tags.operator,
      SubtractionOperator: tags.operator,
      OpeningBracket: tags.paren,
      ClosingBracket: tags.paren,
    }),
  ],
});

const presetLanguage = LRLanguage.define({
  name: 'PresetFormula',
  parser: presetFormulaParser,
  languageData: {},
});
const datatableLanguage = LRLanguage.define({
  name: 'DatatableFormula',
  parser: datatableFormulaParser,
  languageData: {},
});

const formulaDslAutocomplete =
  (options: Array<Completion>) =>
  (context: CompletionContext): CompletionResult | null => {
    // const inner = syntaxTree(context.state).resolveInner(context.pos, -1);
    const word = context.matchBefore(/[$\w]*/);
    if (_notNil(word) && word.from === word.to && !context.explicit) {
      return { from: context.pos, options: [] };
    }
    return { from: word?.from ?? context.pos, options };
  };

export type Variable = `$${string}`;

interface DslConfig {
  type: 'preset' | 'datatable';
  variables: Record<Variable, string>;
}

export const FormulaDslLint = (
  variables: Set<Variable>,
  diagnosticCallback?: (diagnostics: Array<Diagnostic>) => Promise<void>
) => {
  return (view: EditorView): Readonly<Array<Diagnostic>> => {
    const diagnostics: Array<Diagnostic> = [];
    if (view.state.doc.length > 0) {
      syntaxTree(view.state).iterate({
        enter: ({ type, from, to }) => {
          if (type.isError) {
            const slice = view.state.sliceDoc(from < 5 ? 0 : from - 5, to + 5);

            diagnostics.push({
              from,
              to,
              severity: 'error',
              message: `Invalid syntax around ${from}-${to} "${slice}"`,
            });
          } else if (type.name === 'Variable') {
            const variable = view.state.sliceDoc(from, to) as Variable;
            if (!variables.has(variable)) {
              diagnostics.push({
                from,
                to,
                severity: 'error',
                message: `variable [${variable}] is not recognised`,
              });
            }
          }
        },
      });
    }
    diagnosticCallback?.(diagnostics)?.catch(_noop);
    return diagnostics;
  };
};

export const FormulaDsl = (config: DslConfig) => {
  switch (config.type) {
    case 'datatable':
      return new LanguageSupport(datatableLanguage, [
        datatableLanguage.data.of({
          activateOnTyping: true,
          selectOnOpen: true,
          closeOnBlur: true,
          icons: true,
          autocomplete: formulaDslAutocomplete([
            ...Object.entries(config.variables ?? {}).map(([label, detail]) => ({ type: 'variable', label, detail })),
          ]),
        }),
      ]);
    case 'preset':
      return new LanguageSupport(presetLanguage, [
        presetLanguage.data.of({
          activateOnTyping: true,
          selectOnOpen: true,
          closeOnBlur: true,
          icons: true,
          autocomplete: formulaDslAutocomplete([
            ...Object.entries(config.variables ?? {}).map(([label, detail]) => ({ type: 'variable', label, detail })),
            { type: 'function', label: 'PI()', detail: 'π' },
            { type: 'function', label: 'AVERAGE()', detail: 'Average' },
          ]),
        }),
      ]);
    default:
      throw Error(`Unknown DSL [${config.type}]`);
  }
};
