import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import { filterDataBySearch } from '@/components/UI/Table/Table.utils';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { errorToast, successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import type { Team } from '@/model/Team.model';
import type { MainUser, UserApiId } from '@/model/User.model';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type TeamUserRole = 'administrator' | 'member';

interface SelectionState {
  isEmpty: boolean;
  hasCurrentUser: boolean;
  hasAdministrator: boolean;
  hasActive: boolean;
  hasInactive: boolean;
  hasPending: boolean;
  hasMember: boolean;
}

interface ManageProps {
  team: Team;
  currentUser: MainUser;
}

const Manage: React.FC<ManageProps> = ({ team, currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [APIError, setAPIerror] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const users = useMemo<Array<MainUser>>(() => {
    const query = (searchQuery ?? '').trim();
    const teamUsers = team?.users ?? [];
    if (_isEmpty(query)) {
      return teamUsers;
    }
    return filterDataBySearch(teamUsers, query);
  }, [team, searchQuery]);
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const selectedUsers = useMemo<Array<MainUser>>(() => {
    const rowIds = Object.keys(selectedRows ?? {});
    return rowIds.reduce<Array<MainUser>>((acc, rowIndex) => {
      const user = users?.[Number(rowIndex)];
      if (_notNil(user)) {
        acc.push(user);
      }
      return acc;
    }, []);
  }, [users, selectedRows]);

  const selectionState = useMemo(
    () =>
      selectedUsers.reduce<SelectionState>(
        (acc, selectedUser) => {
          acc.hasCurrentUser = !acc.hasCurrentUser && selectedUser.id === currentUser?.id ? true : acc.hasCurrentUser;
          acc.hasAdministrator = !acc.hasAdministrator && selectedUser.pivot.owner ? true : acc.hasAdministrator;
          acc.hasMember = !acc.hasMember && !selectedUser.pivot.owner ? true : acc.hasMember;
          acc.hasActive = !acc.hasActive && selectedUser.pivot.status === 'active' ? true : acc.hasActive;
          acc.hasPending = !acc.hasPending && selectedUser.pivot.status === 'active' ? true : acc.hasPending;
          acc.hasInactive = !acc.hasInactive && selectedUser.pivot.status === 'inactive' ? true : acc.hasInactive;
          return acc;
        },
        {
          isEmpty: _isEmpty(selectedUsers),
          hasCurrentUser: false,
          hasAdministrator: false,
          hasActive: false,
          hasInactive: false,
          hasPending: false,
          hasMember: false,
        }
      ),
    [selectedUsers, currentUser]
  );

  const dispatch = useDispatch();
  const ssoEnabled = useSelector((state: State) => state?.team?.features?.sso ?? false);
  const isMounted = useMountedState();

  const handleSuspend = () => {
    return openModal('MANAGE_ACCOUNT', {
      users: selectedUsers,
      handleCallback,
      closeModal,
    });
  };

  const handleActivate = () => {
    return openModal('MANAGE_ACCOUNT', {
      users: selectedUsers,
      activate: true,
      handleCallback,
      closeModal,
    });
  };

  const handleResendInvite = () => {
    setLoading(true);
    const promises = selectedUsers.map((s) => {
      return Http.post(apiRoute('users.invitation', { id: s.id }));
    });

    return Promise.all(promises)
      .then(() => {
        setLoading(false);
        successToast(`Successfully resent invitation${selectedUsers.length > 1 ? 's' : ''}`);
      })
      .catch((error) => {
        setLoading(false);
        setAPIerror(error);
      });
  };

  const handleRevokeInvite = () => {
    return openModal('REVOKE_ACCOUNT', {
      users: selectedUsers,
      handleCallback,
      closeModal,
    });
  };

  const updateTeamUserRoles = async (updates: Record<UserApiId, TeamUserRole>) => {
    setLoading(true);
    const updated = await Http.patch(
      RouteService.api({ endpoint: 'PATCH /api/v1/team/update-role', path: undefined }).url.href,
      {
        updates,
      }
    )
      .then(({ data }) => data?.success ?? false)
      .catch(() => false);
    if (isMounted()) {
      if (updated) {
        dispatch({
          type: 'TEAM_UPDATE_TEAM',
          data: {
            ...team,
            users: [
              ...team.users.map((user) => ({
                ...user,
                pivot: {
                  ...user.pivot,
                  owner: !Object.prototype.hasOwnProperty.call(updates, user.api_id)
                    ? user.pivot.owner
                    : updates?.[user.api_id] === 'administrator'
                      ? 1
                      : 0,
                },
              })),
            ],
          },
        });
        setSelectedRows({});
        successToast(`Updated ${Object.keys(updates).length} team users`);
      } else {
        errorToast(`Could not update ${Object.keys(updates).length} team users`);
      }
      setLoading(false);
    }
  };
  const handleUserRoleChange = (currentRoleAdministrator: boolean, selectedTeamUsers: Array<MainUser>) => {
    if (currentRoleAdministrator) {
      updateTeamUserRoles(
        selectedTeamUsers.reduce<Record<UserApiId, TeamUserRole>>(
          (acc, { api_id }) => ({ ...acc, [api_id]: 'member' }),
          {}
        )
      );
    } else {
      updateTeamUserRoles(
        selectedTeamUsers.reduce<Record<UserApiId, TeamUserRole>>(
          (acc, { api_id }) => ({ ...acc, [api_id]: 'administrator' }),
          {}
        )
      );
    }
  };

  const handleCallback = (txt: string) => {
    setLoading(true);
    return Http.get(apiRoute('global-data'))
      .then(({ data }) => {
        dispatch({ type: 'TEAM_SET_TEAM', team: data.team });
        successToast(txt);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAPIerror(error);
      });
  };

  const openModal = (modal: unknown, props: unknown) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const status = {
    inactive: 'Suspended',
    pending: 'Invitation sent',
    active: 'Active',
  };

  const columns: Columns<MainUser> = [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
    },
    {
      accessor: 'pivot.owner',
      id: 'role',
      Header: 'Role',
      width: 80,
      Cell: ({ row: { original } }) => <>{original.pivot.owner ? 'Administrator' : 'Member'}</>,
      sortType: 'basic',
    },
    {
      accessor: 'email',
      id: 'email',
      Header: 'Email',
    },
    {
      accessor: 'pivot.status',
      id: 'status',
      Header: 'Status',
      width: 80,
      Cell: ({ row: { original } }) => {
        return <Fragment>{status[original.pivot.status]}</Fragment>;
      },
    },
  ];

  const actions = [
    {
      name: 'Activate',
      action: () => handleActivate(),
      disabled:
        selectionState.hasCurrentUser ||
        selectionState.hasAdministrator ||
        selectionState.hasActive ||
        selectionState.hasPending,
    },
    {
      name: 'Suspend',
      action: () => handleSuspend(),
      disabled: selectionState.hasCurrentUser || selectionState.hasAdministrator || selectionState.hasInactive,
    },
    {
      name: 'Resend invitation',
      action: () => handleResendInvite(),
      disabled:
        selectionState.hasCurrentUser ||
        selectionState.hasAdministrator ||
        selectionState.hasActive ||
        selectionState.hasInactive,
    },
    {
      name: 'Revoke invitation',
      action: () => handleRevokeInvite(),
      disabled:
        selectionState.hasCurrentUser ||
        selectionState.hasAdministrator ||
        selectionState.hasActive ||
        selectionState.hasInactive,
    },
    ...(selectionState.hasAdministrator && selectionState.hasMember
      ? []
      : [
          {
            name: selectionState.hasAdministrator ? 'Change role to member' : 'Change role to administrator',
            action: () =>
              selectionState.hasCurrentUser || handleUserRoleChange(selectionState.hasAdministrator, selectedUsers),
            disabled: selectionState.hasCurrentUser || selectionState.isEmpty,
          },
        ]),
  ];

  return (
    <div className={`ui-card ${loading ? 'ui__disabled' : ''}`}>
      {APIError && (
        <Banner critical dismiss={false} className="mw6 ma3">
          <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
          <p className="f6 pb3 lh-copy">
            An error has occurred when submitting the form, please try again later. If this keeps occurring please
            contact support.
          </p>
          <Button outline critical url={'mailto:support@benchling.com'}>
            Contact support
          </Button>
        </Banner>
      )}
      <div className="flex pa3 bb b--moon-gray">
        <div className="flex w-100">
          <div className="flex mr2">
            {_isNotEmpty(selectedRows) && (
              <div
                className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                style={{
                  whiteSpace: 'nowrap',
                  lineHeight: '2.4rem',
                }}
              >
                {Object.keys(selectedRows).length} selected
              </div>
            )}
            <SelectDropDown
              title="Actions"
              className={`plain f6 ${_isNotEmpty(selectedRows) ? 'br--right' : ''}`}
              alignMenu="right"
              disabled={_isEmpty(selectedRows) || !team.pivot.owner}
            >
              <ActionList actions={actions} />
            </SelectDropDown>
          </div>
          <div className="w-100">
            <SearchFilterBar
              searchPlaceholderText="Search by name or email"
              handleSearchInput={setSearchQuery}
              searchQuery={searchQuery}
            />
          </div>
        </div>
        <Button
          disabled={!team.pivot.owner || ssoEnabled}
          testId="button__invite_users"
          tooltip={ssoEnabled ? 'This team has SSO enabled' : undefined}
          onClick={() =>
            openModal('INVITE_USERS', {
              closeModal,
              handleCallback,
            })
          }
        >
          Invite users
        </Button>
      </div>
      <Table data={users} columns={columns} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
    </div>
  );
};

export default Manage;
