// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { StudyHeader } from '@/components/Studies/Show';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { FetchProvider, useFetchEntity } from '@/support/Hooks/fetch';
import { useParams } from 'react-router-dom';
import AnimalsTable from './AnimalsTable/AnimalsTable';
import Averages from './Averages';

const Animals = () => {
  const { id: studyId } = useParams();
  const entityProps = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,study_groups',
  });
  const { entity: study, entityLoading: studyLoading, entityError: studyFetchError } = entityProps;

  if (studyLoading) {
    return null;
  }

  if (studyFetchError) {
    return (
      <ApiErrorBanner
        title="There was an error fetching study data"
        text="An error has occurred when fetching study data, please try again. If this keeps occurring please contact support."
        error={studyFetchError}
      />
    );
  }

  return (
    <FetchProvider {...entityProps}>
      <StudyHeader study={study} />
      <div className="ph4 pt3">
        <Tabs>
          <div className="mb4 bb b--moon-gray">
            <Tab className="f4">Animals</Tab>
            <Tab className="f4">Averages</Tab>
          </div>
          <div>
            <Panel>
              <AnimalsTable />
            </Panel>
            <Panel>
              <Averages />
            </Panel>
          </div>
        </Tabs>
      </div>
    </FetchProvider>
  );
};

export default Animals;
