import { Context, createContext, PropsWithChildren, useContext } from 'react';

const FetchContext = createContext<any>(null);

type FetchProviderType<ContextType = unknown> = React.FC<PropsWithChildren<ContextType>>;
export const FetchProvider: FetchProviderType = (props) => {
  const { children } = props;
  return <FetchContext.Provider value={props}>{children}</FetchContext.Provider>;
};

export const useFetchContext = <ContextType = null,>(): ContextType => {
  const context = useContext(FetchContext as Context<ContextType>);

  if (!context) {
    throw new Error('useFetchContext must be used inside FetchProvider');
  }

  return context;
};
