// @ts-nocheck: converted from JS

export class IntercomUtils {
  static init() {
    if (AppConfig.intercomEnabled) {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function () {
          i.c(arguments); // eslint-disable-line  prefer-rest-params
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + AppConfig.intercomAppId;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    }
  }

  static trackEvent(event: string, context?: Record<string, string | number | boolean | null | undefined>) {
    if (AppConfig.intercomEnabled && typeof window.Intercom === 'function') {
      window.Intercom('trackEvent', event, context);
    }
  }
}
