//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';
import type { RequiredMetadataFieldWithValue } from '../Fields';

interface TextProps {
  field: RequiredMetadataFieldWithValue;
  testId?: string;
}

export const Text = ({ field, testId = 'metadata-text-field' }: TextProps) => {
  const { register } = useFormContext();
  return (
    <input
      type="text"
      style={{ marginBottom: 0 }}
      name={`metadata[${field.slug}].value`}
      defaultValue={field.value ?? undefined}
      ref={register({
        required: field.required && 'This field is required',
        maxLength: 250,
      })}
      data-testid={testId}
    />
  );
};
