import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { fetchMetadataColumns, fetchStudiesMetadataColumns } from '@/helpers';
import InVivoError from '@/model/InVivoError.ts';
import type { MetadataItem } from '@/model/Metadata.model';
import type { Request } from '@/model/Request.model';
import { REQUEST_STATUS } from '@/model/Request.model';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import { web as webRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';

export const filterOptions = [
  {
    name: 'Study',
    value: 'study',
    operations: [
      {
        name: 'is equal to',
        value: filterType.eq,
        options: [],
      },
      {
        name: 'is not equal to',
        value: filterType.ne,
        options: [],
      },
    ],
    fetchFromApi: {
      placeholder: 'Search studies',
      url: 'studies.base',
    },
  },
];

export const generateColumns = async (signal: AbortSignal): Promise<Columns<Request>> => {
  let metadata: Array<MetadataItem> = [];
  try {
    metadata = await fetchStudiesMetadataColumns(signal);
  } catch (error) {
    if (notAborted(error)) {
      ExceptionHandler.captureException(
        new InVivoError('Could not fetch studies metadata', {
          cause: error,
          slug: 'request-load-metadata',
        })
      );
    }
  }

  // Sort was disabled on all columns in the original table, have kept that consistent for now.
  return [
    {
      id: 'requestName',
      Header: 'Request name',
      accessor: 'request_template.name',
      sortDisabled: true,
      isVisible: true,
      Cell: ({
        row: {
          original: { id, request_template },
        },
      }) => (
        <Link
          to={webRoute('requests.details', {
            requestId: id,
          })}
          className="link blue"
        >
          {request_template.name}
        </Link>
      ),
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      sortDisabled: true,
      isVisible: true,
      Cell: ({
        row: {
          original: { status },
        },
      }) => {
        return REQUEST_STATUS[status as keyof typeof REQUEST_STATUS];
      },
    },
    {
      id: 'assignee',
      Header: 'Assignee',
      accessor: 'assigned_user.name',
      sortDisabled: true,
      isVisible: true,
      Cell: ({ row: { original } }) => original.assigned_user?.name ?? 'Unassigned',
    },
    {
      id: 'study',
      Header: 'Study',
      accessor: 'study',
      sortDisabled: true,
      isVisible: true,
      Cell: ({
        row: {
          original: { study },
        },
      }) => study?.name ?? '-',
    },
    {
      id: 'createdAt',
      Header: 'Created',
      accessor: 'created_at',
      sortDisabled: true,
      isVisible: true,
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
    },
    {
      id: 'dueDate',
      Header: 'Due date',
      accessor: 'due_date',
      sortDisabled: true,
      isVisible: true,
      Cell: ({ row: { original } }) => (
        <DateTimeRenderer value={original.due_date ?? original.form_data.due_date} defaultResponse="-" />
      ),
    },
    ...fetchMetadataColumns({ metadata, nestedStudyLocation: 'study' }),
  ];
};
