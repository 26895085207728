import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SpreadSheet from '@/components/UI/SpreadSheet';
import type { CustomGridSettings } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import SubHeader from '@/components/UI/SubHeader';
import { clearSessionStorage } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { MetadataField } from '@/model/Metadata.model';
import type { Sample, SampleMeasurement, SampleType } from '@/model/Sample.model';
import { ApiService } from '@/support/ApiService';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import useMountedState from '@/support/Hooks/fetch/useMountedState'; // Facilitates HoT's inability to utilise arrays
import { returnInitialMetaValues } from '@/support/hot';
import { web as webRoute } from '@/support/route';
import type HotTable from '@handsontable//react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseSettings, ColumnWrapper, generateColumnData, generateSampleBulkUpdateList } from './BulkSampleEdit.utils'; // Facilitates HoT's inability to utilise arrays
import { modalAction } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors';

// Facilitates HoT's inability to utilise arrays
export interface CustomSample extends Omit<Sample, 'details'> {
  details: Record<string, SampleMeasurement>;
}

const featureSelector = createSelector([featuresSelector], (features) => ({
  reasonForChange: features?.reason_for_change ?? false,
}));

const BulkSampleEdit = () => {
  const history = useHistory();
  const ref = useRef<HotTable>();
  const [data, setData] = useState<Array<CustomSample>>([]);
  const [apiError, setApiError] = useState<Error | false>(false);
  const [metadata, setMetadata] = useState<Array<MetadataField>>([]);
  const [sampleTypeLookup, setSampleTypeLookup] = useState<Record<string, SampleType>>();
  const [loading, setLoading] = useState(true);
  const [isInvalid, setIsInvalid] = useState<boolean>();
  const [columnData, updateColumnData] = useState<ColumnWrapper>({
    colHeaders: [],
    columns: [],
  });
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);
  const isMounted = useMountedState();

  const { reasonForChange } = useSelector(featureSelector);

  const { id: studyId } = useParams<{ id: string }>();
  const { selectedSamples } = useSelector(
    ({ samples: selectedSamples }: { samples: { selectedSamples: Array<Sample> } }) => selectedSamples
  );

  const { entity: sampleMetadata, entityLoading: sampleMetadataLoading } = useFetchEntity<Array<MetadataField>>({
    entityType: 'glossaryMeta',
    queryParams: {
      filter_type: 'sample_meta',
    },
  });

  const { collection: sampleTypeOptions, collectionLoading: sampleTypesLoading } = useFetchCollection<SampleType>({
    queryParams: {
      group: 'samples',
    },
    collectionType: 'teamGlossary',
  });

  useEffect(() => {
    // Sample types are required for the details column in HoT as a lookup
    if (!sampleTypesLoading) {
      setSampleTypeLookup(
        sampleTypeOptions?.reduce<Record<string, SampleType>>((acc, type) => {
          acc[type.title] = type;
          return acc;
        }, {})
      );
    }
  }, [sampleTypeOptions]);

  const studyApiId = useMemo<string>(() => sessionStorage.getItem(`selectedStudyApiId${studyId}`) ?? '', [studyId]);

  const samples = useMemo<Array<CustomSample>>(() => {
    // The samples are forwarded from the API Table, and are saved in session in the event of
    // refreshing the page
    const sessionSamples = JSON.parse(
      sessionStorage.getItem(`selectedStudy${studyId}Samples`) ?? '[]'
    ) as Array<Sample>;
    return (_isEmpty(sessionSamples) ? selectedSamples : sessionSamples).map((sample) => ({
      ...sample,
      details: sample.details.reduce<Record<string, SampleMeasurement>>((acc, detail) => {
        if (_notNil(detail.key)) {
          acc[detail.key] = detail;
        }
        return acc;
      }, {}),
      meta: returnInitialMetaValues(sample.metadata),
    }));
  }, [studyId]);

  useEffect(() => {
    // Remove the API Table samples in the event the page navigates
    return () => {
      clearSessionStorage(history, `selectedStudy${studyId}Samples`, `/studies/${studyId}/samples/edit`);
      clearSessionStorage(history, `selectedStudyApiId${studyId}`, `/studies/${studyId}/samples/edit`);
    };
  }, [history]);

  useEffect(() => {
    const metadataMap = new Map();

    // Renders the available metadata to add to the table down to only the columns
    // not currently used by the samples
    if (_isEmpty(metadata) && _notNil(sampleMetadata) && _notNil(samples) && _notNil(sampleTypeLookup)) {
      samples.reduce((acc, sample) => {
        for (const meta of sample?.metadata ?? []) {
          const id = meta?.glossary_id ?? meta?.id;
          if (_notNil(id) && !acc.has(id)) {
            acc.set(id, {
              id,
              glossary_id: id,
              field_type: meta.field_type,
              options: meta.options,
              read_only: meta.read_only,
              title: meta.title,
              created_at: meta.created_at,
              updated_at: meta.updated_at,
              slug: meta.slug,
              active: true,
            });
          }
        }
        return acc;
      }, metadataMap);

      setMetadata(sampleMetadata.filter(({ id }) => !metadataMap.has(id)));

      updateColumnData(generateColumnData([...(metadataMap?.values() ?? [])], sampleTypeLookup?.[data[0].type]));
    }
  }, [sampleMetadata, sampleTypeLookup]);

  const settings = useMemo<CustomGridSettings>(
    () => ({
      ...baseSettings,
      ...columnData,
    }),
    [columnData]
  );

  useEffect(() => {
    // Preserves the initial data for later comparison when building the PATCH body
    setData(structuredClone(samples));
    setLoading(false);
  }, [samples]);

  const saveSamples = async (reasonForChange?: string) => {
    setLoading(true);
    const sampleUpdateList = generateSampleBulkUpdateList(samples, data, settings.columns);
    try {
      await ApiService.call({
        endpoint: 'PATCH /api/v1/studies/{studyId}/samples/bulk',
        path: { studyId: studyApiId },
        body: { samples: sampleUpdateList, reason_for_change: reasonForChange },
      });
      history.push(webRoute('studies.samples', { id: studyId }));
      toast.success(`Successfully added ${samples.length > 1 ? 'samples' : 'sample'}`);
    } catch (error) {
      if (isMounted()) {
        setApiError(error as Error);
      }
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  const saveAction = async () => {
    if (reasonForChange) {
      openModal('BULK_REASON_FOR_CHANGE', {
        title: 'Sample information has changed',
        onClick: saveSamples,
        closeModal,
      });
    } else {
      await saveSamples();
    }
  };

  const spreadSheet = (
    <>
      <div className="ow-spreadsheet-styles">
        <SpreadSheet
          data={data}
          settings={settings}
          className="pl4 pr4"
          innerRef={ref}
          metadata={metadata}
          setMetadata={setMetadata}
          setInvalid={setIsInvalid}
          maxRows={data.length}
          greedyValidateRow
        />
      </div>
      <div className="pa4">
        <Button
          onClick={saveAction}
          disabled={loading || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
        >
          Save
        </Button>
        <Button plain className="ml3" onClick={() => history.push(webRoute('studies.samples', { id: studyId }))}>
          Cancel
        </Button>
      </div>
    </>
  );

  return (
    <div className="h-100">
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={'There was an error with your request'}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
      {loading || sampleMetadataLoading || sampleTypesLoading || _isEmpty(settings) ? (
        <div style={{ height: 650 }}>
          <Loading txt={`${loading || sampleMetadataLoading ? 'Updating sample...' : 'Loading...'}`} />
        </div>
      ) : (
        <>
          <SubHeader linkToText="Samples" link={webRoute('studies.samples', { id: studyId })} />
          <div className="ph4">
            <Header mainHeaderText="Edit Samples" />
          </div>
          {_isNotEmpty(settings) && _isNotEmpty(data) ? (
            spreadSheet
          ) : (
            <div className="ma4">
              <NoDataCard
                title="You have not selected any samples"
                text={'Please select samples from your study to proceed'}
                NoDataComponent={
                  <Link to={webRoute('studies.samples', { id: studyId })} className="link blue">
                    Go to Samples
                  </Link>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BulkSampleEdit;
