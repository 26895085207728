import { copyText } from '@/helpers';
import type React from 'react';
import { RiFileCopyLine } from 'react-icons/ri';

interface CopyToClipboardProps {
  textToCopy: string;
  toastText?: string;
  children: React.ReactNode;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy, toastText, children }) => (
  <div className="flex flex-row">
    <a className="mr2 pointer" onClick={() => copyText(textToCopy, toastText)}>
      <RiFileCopyLine />
    </a>
    {children}
  </div>
);

export default CopyToClipboard;
