import type { FC, ReactNode } from 'react';

interface FormFieldProps {
  children: ReactNode;
  label: string;
  enableBorder?: boolean;
}

export const FormField: FC<FormFieldProps> = ({ children, label, enableBorder = true }) => (
  <div
    className={`pa3 bt ${
      enableBorder ? 'b--moon-gray' : 'b--transparent'
    } flex justify-between flex-wrap items-center align-center`}
  >
    <label className="ma0">{label}</label>
    {children}
  </div>
);

export default FormField;
