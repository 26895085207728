import { useEffect, useMemo, useReducer } from 'react';

interface State {
  step: number;
  totalSteps: number;
}

type Action =
  | { type: 'next' }
  | { type: 'back' }
  | { type: 'updateStep'; stepTo: number }
  | { type: 'update-total-steps'; totalSteps: number };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'next':
      return {
        ...state,
        step: state.step < state.totalSteps - 1 ? state.step + 1 : state.step,
      };
    case 'back':
      return {
        ...state,
        step: state.step > 0 ? state.step - 1 : 0,
      };
    case 'updateStep':
      return {
        ...state,
        step: action.stepTo >= 0 && action.stepTo <= state.totalSteps ? action.stepTo : state.step,
      };
    case 'update-total-steps': {
      if (Number.isFinite(action.totalSteps) && state.totalSteps !== action.totalSteps) {
        return {
          ...state,
          totalSteps: action.totalSteps,
        };
      }
    }
  }
  return state;
};

const useStepForm = ({ totalSteps = 0 }) => {
  const initialState = useMemo(
    () => ({
      step: 0,
      totalSteps,
    }),
    []
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: 'update-total-steps', totalSteps });
  }, [dispatch, totalSteps]);

  const { step } = state;
  const nextStep = () =>
    dispatch({
      type: 'next',
    });
  const backStep = () =>
    dispatch({
      type: 'back',
    });
  const updateStep = (stepTo: number): void =>
    dispatch({
      type: 'updateStep',
      stepTo,
    });

  return {
    step,
    updateStep,
    nextStep,
    backStep,
    hasNext: state.totalSteps > step + 1,
    hasBack: step > 0,
  };
};

export default useStepForm;
