import Sortable from '@/components/Draggable';
import SearchSelect from '@/components/UI/SearchSelect';
import { capitalise } from '@/helpers';
import { _isEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { CSSProperties, FC } from 'react';
import { useEffect, useId, useMemo, useState } from 'react';

const selectionEqual = (left: Array<ID>, right: Array<ID>): boolean =>
  Array.isArray(left) &&
  Array.isArray(right) &&
  left.length === right.length &&
  left.every((val, index) => val === right[index]);

interface Option {
  id: ID;
  title: string;
}

interface SelectionReorderProps<VAL extends Option = Option> {
  options: Array<VAL>;
  selectionTitle: string;
  selection?: Array<Option['id']>;
  selectionChange: (selection: Array<Option['id']>) => void;
  style?: CSSProperties;
}

interface ReorderProps<VAL extends Option = Option> extends Pick<SelectionReorderProps<VAL>, 'options'> {
  orderChange: (idOrder: Array<Option['id']>) => void;
  title: string;
}

const SELECT_CONTAINER_OVERFLOW_HEIGHT = 350;
const REORDER_CONTAINER_OVERFLOW_HEIGHT = 458;

const Reorder: FC<ReorderProps> = ({ title, options, orderChange }) => {
  const listId = useId();
  const [sortableOptions, setSortableOptions] = useState([...(options ?? [])]);
  useEffect(() => {
    setSortableOptions(options ?? []);
  }, [options]);

  const handleMove = (sourceIndex: number, destinationIndex: number) => {
    setSortableOptions((prev) => {
      const reorderedOptions = [...(prev ?? [])];
      const movedOption = reorderedOptions.splice(sourceIndex, 1)[0];
      reorderedOptions.splice(destinationIndex, 0, movedOption);
      return reorderedOptions;
    });
  };
  const handleDrop = () => orderChange?.(sortableOptions.map((o) => o.id));
  const handleRevert = () => setSortableOptions([...(options ?? [])]);

  return (
    <div
      className="relative overflow-auto"
      data-test-component="Reorder"
      data-test-element="container"
      data-testid="selection-reorder-container"
      style={{ height: REORDER_CONTAINER_OVERFLOW_HEIGHT }}
    >
      {_isEmpty(sortableOptions) ? (
        <div className="pa2 ma3 bg-near-white br2">
          <h3 className="f7 lh-title near-black pb2 fw4">{capitalise(title)}s will appear here</h3>
          <p className="f7 lh-copy dark-gray">
            Select {title} types from the list and confirm the order these will be collected for each animal
          </p>
        </div>
      ) : (
        <ul className="list ma0 ph3 pv2">
          {sortableOptions.map((option, index) => (
            <li className="justify-between flex pb2" key={option.id}>
              <Sortable
                key={option.id}
                id={option.id}
                index={index}
                moveCallback={handleMove}
                dropCallback={handleDrop}
                revertCallback={handleRevert}
                draggableType={`reorder-option-${listId}`}
                showDraggableIcon
                className="flex flex-row justify-between items-center w-100 hide-child"
              >
                <div
                  className="flex flex-row ma1 near-black f6 lh-title"
                  data-test-element="sortable-item"
                  data-test-key={`${option.id}`}
                >
                  {option.title}
                </div>
              </Sortable>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const SelectionReorder: FC<SelectionReorderProps> = ({
  selectionTitle,
  options,
  selection,
  selectionChange,
}) => {
  const optionMap = useMemo(() => new Map(options.map((option) => [option.id, option])), [options]);
  const [selectedIds, setSelectedIds] = useState<Array<ID>>(selection ?? []);
  const selectedOptions = useMemo(() => {
    const opts = selectedIds.reduce<Array<Option>>((acc, selectedId) => {
      if (optionMap.has(selectedId)) {
        acc.push(optionMap.get(selectedId) as Option);
      }
      return acc;
    }, []);
    if (!selectionEqual(selectedIds, selection ?? [])) {
      selectionChange([...selectedIds]);
    }
    return opts;
  }, [selectedIds, optionMap, selectionChange]);

  useEffect(() => {
    setSelectedIds(selection ?? []);
  }, [selection, setSelectedIds]);

  return (
    <div className="flex">
      <div className="w-50 h-100 br b--moon-gray">
        <SearchSelect
          selected={selectedIds}
          setSelected={setSelectedIds}
          sections={[{ items: options }]}
          overflowHeight={SELECT_CONTAINER_OVERFLOW_HEIGHT}
        />
      </div>
      <div className="w-50 h-100">
        <Reorder title={selectionTitle} options={selectedOptions} orderChange={setSelectedIds} />
      </div>
    </div>
  );
};
