import type { Entity, ID, ISODateTime } from './Common.model';
import type { Project } from './Project.model';
import type { Study } from './Study.model';
import type { BaseUser, StudyUser } from './User.model';

export enum REQUEST_STATUS {
  in_progress = 'In Progress',
  complete = 'Complete',
  pending = 'Pending',
}

export interface RequestTemplateGroups {
  fields: Array<{
    id: ID;
    label: string;
    name: string;
  }>;
}

export interface RequestTemplate extends Entity {
  name: string;
  team_id: ID;
  deleted_at?: ISODateTime;
  form: {
    groups: Array<RequestTemplateGroups>;
  };
}
export interface Request extends Entity {
  request_template: RequestTemplate;
  status: keyof typeof REQUEST_STATUS;
  assigned_user?: StudyUser;
  study?: Study;
  study_id?: ID;
  user: StudyUser;
  due_date?: string;
  form_data: {
    due_date?: string;
  };
  samples: Record<number, number>;
  users: Array<BaseUser>;
  project: Project;
}

export interface RequestComment extends Entity {
  author: BaseUser;
  commentable_id: ID;
  commentable_type: string;
  body: string;
  deleted_at?: ISODateTime;
}
