import DatePickerNative from '@/components/UI/DatePickerNative';
import FormRender from '@/components/UI/FormRender';
import type { Request, RequestTemplate } from '@/model/Request.model';
import { DateUtils } from '@/utils/Date.utils';
import React, { PropsWithChildren } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form';

interface RequestDetailsProps {
  ActionButtons: React.ReactElement;
  onSubmit: (request: Request) => Promise<void>;
  requestTemplate: RequestTemplate;
  request: Request;
  setRequest: React.Dispatch<React.SetStateAction<Request>>;
}

const RequestDetails: React.FC<RequestDetailsProps> = ({
  ActionButtons,
  onSubmit,
  requestTemplate,
  request,
  setRequest,
}) => {
  const buildDefaultFormData = (template: RequestTemplate) =>
    template.form.groups.reduce((acc: Array<Record<string, string>>, group) => {
      acc.push(
        ...group.fields.map((field) => ({
          [field.name]: '',
        }))
      );
      return acc;
    }, []);

  const formMethods = useForm({
    defaultValues: {
      form_data: request?.form_data ?? buildDefaultFormData(requestTemplate),
      due_date: DateUtils.isValidDate(request?.due_date) ? request.due_date : '',
    },
  });

  const handleSubmit = (submitData: Request) => {
    const { due_date: dueDate, form_data: formData } = submitData;
    const updateRequestCallback = (prevRequest: Request) => {
      const updatedRequest = {
        ...prevRequest,
        form_data: formData,
      };
      if (dueDate) {
        updatedRequest.due_date = dueDate;
      }
      return updatedRequest;
    };
    setRequest(updateRequestCallback);
    onSubmit(updateRequestCallback(request));
  };

  const groupsWithNames = requestTemplate.form.groups.map((group) => ({
    ...group,
    fields: Array.isArray(group.fields)
      ? group.fields.map((field, i) => ({
          ...field,
          name: `form_data[${field.name}]`,
        }))
      : [],
  }));

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        className="ui-card pa3"
        data-test-component="RequestDetails"
        data-test-element="container"
      >
        <FormRender groupContainer={GroupContainer} groups={groupsWithNames} />
        <GroupContainer testId="due-date-container">
          <label className="mb2">Due Date (Optional)</label>
          <Controller
            name="due_date"
            control={formMethods.control}
            render={({ onChange, value }: ControllerRenderProps) => (
              <DatePickerNative value={value} style={{ marginBottom: 0 }} onChange={onChange} />
            )}
          />
        </GroupContainer>
        {React.cloneElement(ActionButtons, {
          onOk: formMethods.handleSubmit(handleSubmit),
        })}
      </form>
    </FormProvider>
  );
};

export default RequestDetails;

const GroupContainer: React.FC<PropsWithChildren<{ testId?: string }>> = ({ children, testId }) => (
  <div className="mb4 mw7" data-test-component="GroupContainer" data-test-element="container" data-testid={testId}>
    {children}
  </div>
);
