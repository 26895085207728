// @ts-nocheck: converted from JS

const INITIAL_STATE = {
  currentUser: null,
  teams: [],
  timezones: [],
};

const applySetCurrentUser = (state, action) => ({
  ...state,
  currentUser: action.currentUser,
  teams: action.teams,
});

const applySetTimezones = (state, action) => ({
  ...state,
  timezones: action.timezones.filter((timezone) => timezone !== 'UTC'),
});

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USER_SET_CURRENT_USER': {
      return applySetCurrentUser(state, action);
    }
    case 'USER_SET_TIMEZONES': {
      return applySetTimezones(state, action);
    }
    default:
      return state;
  }
}

export default userReducer;
