// @ts-nocheck: converted from JS

import Checkbox from '@/components/UI/FormElements/Checkbox';
import Radio from '@/components/UI/FormElements/Radio';
import KeywordSearch from '@/components/UI/KeywordSearch';
import { classNames } from '@/helpers';
import { _isEmpty, _isNotEmpty, _noop, _notNil } from '@/littledash';
import { FC, useMemo, useState } from 'react';
import './SearchSelect.scss';

interface SearchSelectProps {
  sections?: any;
  selected?: any;
  prevSelected?: any;
  setSelected?: any;
  sort?: any;
  className?: any;
  disabled?: any;
  style?: any;
  sortAccessor?: any;
  search?: any;
  maxSelections?: any;
  onSelect?: any;
  onSelectAll?: any;
  onClearSelection?: any;
  enableSelectAll?: any;
  enableClearSelection?: any;
  enableSelectedCount?: any;
  overflowHeight?: any;
  customSearchPlaceholderText?: any;
  testPrefix?: any;
}

const SearchSelect: FC<SearchSelectProps> = ({
  sections,
  selected,
  prevSelected,
  setSelected = _noop,
  sort = false,
  className,
  disabled = false,
  style,
  sortAccessor = 'title',
  search = true,
  maxSelections = 9999,
  onSelect = _noop,
  onSelectAll = _noop,
  onClearSelection = _noop,
  enableSelectAll = true,
  enableClearSelection = true,
  enableSelectedCount = true,
  overflowHeight = 400,
  customSearchPlaceholderText,
  testPrefix = 'search-select',
}) => {
  const [query, setQuery] = useState('');

  const filteredSections = useMemo(() => {
    if (query !== '') {
      return sections.map((section) => ({
        ...section,
        items: section.items.filter((item) => item[sortAccessor]?.toLowerCase().includes(query.toLowerCase())),
      }));
    }
    return sections;
  }, [query, sections]);

  let classes = 'relative';
  if (className) {
    classes = classes + ` ${className}`;
  }

  const countTotalItems = () => sections.reduce((acc, curr) => acc + curr.items.length, 0);

  const toggleSelect = (val) => {
    if (!disabled) {
      if (val) {
        setSelected(
          sections.reduce((acc, v, i) => {
            if (!Array.isArray(acc)) {
              acc = [];
            }
            sections[i]['items'].map((item) => acc.push(item.id));

            return acc;
          }, [])
        );
        onSelectAll();
      } else {
        setSelected([]);
        onClearSelection();
      }
    }
  };

  return (
    <div className={classes} style={style} data-test-component="SearchSelect" data-test-element="container">
      {search && (
        <div className="pa3 bb b--moon-gray">
          <div className={`${enableSelectAll || enableClearSelection || enableSelectedCount ? 'pb3' : ''}`}>
            <KeywordSearch
              style={{ marginBottom: 0 }}
              searchQuery={query}
              setSearchQuery={setQuery}
              searchPlaceholderText={customSearchPlaceholderText ?? 'Search'}
              autoFocus={false}
            />
          </div>
          <div className="flex justify-between flex-wrap items-center">
            {_notNil(maxSelections) && (
              <div>
                <div className={`${disabled ? 'ui__disabled' : ''}`}>
                  {enableSelectAll && maxSelections > countTotalItems() && (
                    <a className="dib link blue f7 mr3" onClick={() => toggleSelect(true)}>
                      Select all
                    </a>
                  )}
                  {enableClearSelection && (
                    <a
                      className={classNames('dib f7', {
                        'link blue': _isNotEmpty(selected),
                        'pointer-events-none': _isEmpty(selected),
                      })}
                      onClick={() => toggleSelect(false)}
                    >
                      Clear selection
                    </a>
                  )}
                </div>
              </div>
            )}
            {enableSelectedCount && (
              <div>
                {selected.length > 0 && maxSelections !== 1 && (
                  <p className="f7 lh-title ma0 near-black">{selected.length} Selected</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className="overflow-auto"
        style={{ height: overflowHeight }}
        data-test-component="SearchSelect"
        data-test-element="list-container"
      >
        {filteredSections.map((s, i) => (
          <div
            key={s.title ?? s}
            className="relative"
            data-test-component="SearchSelect"
            data-test-element="section-container"
            data-test-key={s.title ?? `section-${i}`}
          >
            {s.title && (
              <h3
                className="f6 normal basier-med lh-title ph3 pv2 bg-near-white"
                data-test-component="SearchSelect"
                data-test-element="section-title"
                data-test-key={s.title ?? `section-${i}`}
              >
                {s.title}
              </h3>
            )}
            <SelectList
              items={s.items}
              index={i}
              selected={selected}
              setSelected={setSelected}
              sort={sort}
              disabled={disabled || s.disabled}
              sortAccessor={sortAccessor}
              maxSelections={maxSelections}
              prevSelected={prevSelected}
              onSelect={onSelect}
              testPrefix={testPrefix}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const SelectList = ({
  items,
  selected,
  setSelected,
  sort,
  disabled,
  sortAccessor,
  maxSelections,
  prevSelected,
  onSelect,
  testPrefix,
}) => {
  const useRadio = maxSelections === 1;

  let sortedItems = [...items];
  if (sort) {
    sortedItems = items.sort((a, b) => {
      const textA = a?.[sortAccessor]?.toUpperCase?.() ?? '';
      const textB = b?.[sortAccessor]?.toUpperCase?.() ?? '';
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  const handleChange = (e) => {
    e.stopPropagation();
    const tempSelected = [...selected];
    const value = !isNaN(e.target.value) ? Number(e.target.value) : e.target.value;

    if (tempSelected.includes(value)) {
      tempSelected.splice(tempSelected.indexOf(value), 1);
      onSelect({ type: 'REMOVED', value });
    } else {
      tempSelected.push(value);
      onSelect({ type: 'ADDED', value });
    }

    if (prevSelected) {
      return setSelected([...new Set(tempSelected)], [...selected]);
    }
    return setSelected([...new Set(tempSelected)]);
  };

  const handleRadioChange = (e) => {
    if (e.target.checked) {
      setSelected([e.target.value]);
    } else {
      setSelected([]);
    }
  };

  return (
    <ul
      className={`list ma0 ph3 ${useRadio ? 'pv3' : 'pv2'}`}
      data-test-component="SelectList"
      data-test-element="unordered-list"
      data-testid={`${testPrefix}__item-list`}
    >
      {sortedItems.map((key, i) => (
        <li
          key={key.id}
          className="justify-between flex"
          data-test-component="SelectList"
          data-test-element="list-item"
          data-test-key={key.id}
          data-testid={`select-item-${key.id}`}
        >
          {useRadio ? (
            <Radio
              index={i}
              checked={selected.includes(key.id.toString())}
              onChange={handleRadioChange}
              id={key.id}
              label={key[sortAccessor]}
              value={key.id}
              className="mb2"
            />
          ) : (
            <Checkbox
              className="pointer pv2"
              id={`${testPrefix}-${key.id}`}
              name={key.id}
              label={key[sortAccessor]}
              sublabel={key?.sublabel}
              value={key.id}
              checked={selected.includes(key.id)}
              onClick={(e) => !disabled && handleChange(e)}
              disabled={
                (maxSelections && selected.length >= maxSelections && !selected.includes(key.id)) || key.disabled
              }
              testPrefix={`${testPrefix}__checkbox`}
            />
          )}
          {key?.meta && <div className="mt3 w1">{key.meta}</div>}
        </li>
      ))}
    </ul>
  );
};

export default SearchSelect;
