import UserAvatar from '@/components/UI/UserAvatar';
import { TruncatedAvatar } from '@/components/UI/UserAvatar/UserAvatar.tsx';
import type { TaskV1 } from '@/model/Task.model.ts';
import { FC } from 'react';

export const TaskAssigneeAvatars: FC<{
  assignees: TaskV1['assignees'];
  max?: number;
  className?: string | undefined;
}> = ({ assignees = [], max = 3, className }) => {
  const truncatedAssignees = assignees.slice(0, assignees.length > max ? max - 1 : max);
  const additionalCount = assignees.length - max;
  return (
    <span className={className}>
      {truncatedAssignees?.map(({ id, name }) => <UserAvatar key={id} user={{ name }} />)}
      {additionalCount >= 1 ? <TruncatedAvatar className="ml1" remainder={additionalCount} /> : null}
    </span>
  );
};
