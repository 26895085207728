// @ts-nocheck: converted from JS

import NoDataCard from '@/components/NoDataCard';
import { sampleColumns } from '@/components/Studies/Requests/Request.utils';
import Button from '@/components/UI/Button';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import { _isEmpty } from '@/littledash';
import { modalAction } from '@/utils/modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const Samples = ({ ActionButtons, onSubmit, request, setRequest }) => {
  const [samples, setSamples] = useState(request?.samples || []);
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const selectedSamples = Object.keys(selectedRows).map(Number);

  const { id: studyId } = useParams();

  const openAttachSampleModal = () => {
    openModal('REQUEST_ATTACH_SAMPLE', {
      studyId,
      currentSamples: samples?.map((sample) => sample.id) || [],
      closeModal,
      onAddSample: (addedSamples) => {
        setSamples((prevSamples) => prevSamples.concat(addedSamples));
      },
    });
  };

  const bulkActions = [
    {
      name: 'Remove sample(s)',
      key: 'remove_sample',
      action: () => {
        const updatedSamples = [...samples];
        // Note, reverse loop
        for (let i = selectedSamples.length - 1; i >= 0; i--) {
          updatedSamples.splice(selectedSamples[i], 1);
        }
        setSamples(updatedSamples);
      },
    },
  ];

  const handleSubmit = () => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      samples,
    }));
    onSubmit();
  };

  return (
    <div data-test-component="Samples" data-test-element="container">
      <div className="w-100 mb3 br2 flex-column ui-card">
        {_isEmpty(samples) ? (
          <NoDataCard
            title="No samples have been added to this request."
            text="When you add samples they will appear here."
            dark
            onLinkClick={() => openAttachSampleModal()}
            openModal
            btnTxt="Add samples"
          />
        ) : (
          <>
            <div className="pb3 bb b-moon-gray pa3" data-test-element="samples-container">
              <div className="flex flex-row justify-between">
                <div className="flex mr2">
                  {!_isEmpty(selectedRows) && (
                    <div
                      className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                      style={{
                        whiteSpace: 'nowrap',
                        lineHeight: '2.4rem',
                      }}
                    >
                      {Object.keys(selectedRows).length} selected
                    </div>
                  )}
                  <SelectDropDown
                    title="Bulk actions"
                    className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
                    alignMenu="right"
                    disabled={_isEmpty(selectedRows)}
                  >
                    <ActionList actions={bulkActions} />
                  </SelectDropDown>
                </div>
                <Button onClick={() => openAttachSampleModal()} openModal>
                  Attach samples
                </Button>
              </div>
            </div>
            <div className="relative center w-100 ba b--moon-gray items-center justify-center">
              <Table
                columns={sampleColumns(dispatch)}
                data={samples}
                selectedRows={selectedRows}
                setSelectedRows={(data) => setSelectedRows(data)}
              />
            </div>
          </>
        )}
      </div>
      {React.cloneElement(ActionButtons, { onOk: handleSubmit })}
    </div>
  );
};

export default Samples;
