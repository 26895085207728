// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import { _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import type { StudyApiId } from '@/model/Study.model';
import type { GroupApiId } from '@/model/TreatmentGroup.model';
import { ApiService } from '@/support/ApiService';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { isCancel } from 'axios';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Configuration from './Configuration';
import GroupsTable from './GroupsTable';

const Groups: FC<{ canEdit: boolean; studyApiId?: StudyApiId }> = ({ canEdit, studyApiId }) => {
  const { id: studyId } = useParams();

  const {
    collection: groups,
    collectionLoading: groupsLoading,
    fetchCollection: reloadGroups,
  } = useFetchCollection({
    collectionType: 'studyGroups',
    params: { id: studyId },
    queryParams: { perPage: '-1', sort: 'no', order: 'asc' },
    includes: 'metadata,treatments',
  });

  if (groupsLoading) {
    return (
      <div className="mv4 ui-card pa4">
        <Loading />
      </div>
    );
  }

  const handleControlGroupChange = async (id?: GroupApiId | string) => {
    if (_notNil(studyApiId)) {
      try {
        await ApiService.call({
          endpoint: 'PATCH /api/v1/studies/{studyApiId}/update-control-group',
          path: { studyApiId },
          body: { api_id: id },
        });
        await reloadGroups();
      } catch (e) {
        if (!isCancel(e)) {
          ExceptionHandler.captureException(
            new InVivoError('Could not update control group', { cause: e, slug: 'control-group-update' })
          );
        }
      }
    }
  };

  return (
    <>
      <div className="ui-card mv4">
        <GroupsTable groups={groups} reloadGroups={reloadGroups} canEdit={canEdit} />
      </div>
      {canEdit && (
        <div className="ui-card mb4">
          <Configuration
            groups={groups}
            changeControlGroup={handleControlGroupChange}
            canEdit={canEdit}
            testId="study-groups-configuration-panel"
          />
        </div>
      )}
    </>
  );
};

export default Groups;
