// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { closeModal, openModal } from '@/components/Shared/methods';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { defaultPromiseErrorHandler, successToast } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _noop } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Study } from '@/model/Study.model';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from './Header';
import './Importer.scss';
import { getExistingMeasurements, getValidationState, ImportCageMatch, initalSteps, readFile } from './Importer.utils';
import Complete from './Steps/Complete';
import Match from './Steps/Match';
import Review from './Steps/Review';
import { ReviewData } from './Steps/Review/Review';
import Upload from './Steps/Upload';

interface ImporterProps {
  study: Study;
  subjects: Array<Animal>;
  collections: Array<ImportCageMatch>;
}

const Importer: React.FC<ImporterProps> = ({ study, subjects, collections }) => {
  const dispatch = useDispatch();
  const [steps, setSteps] = useState({ ...initalSteps });
  const [activeStep, setActiveStep] = useState(Object.keys(steps)[0]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [existingMeasurements, setExistingMeasurements] = useState(false);

  const [date, setDate] = useState(DateUtils.dateNow());
  const [upload, setUpload] = useState([]);

  const [fileData, setFileData] = useState([]);
  const [matchData, setMatchData] = useState({});
  const [reviewData, setReviewData] = useState<Array<ReviewData>>([]);
  const [animalIdentifiers, setAnimalIdentifiers] = useState([]);

  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();

  useEffect(() => {
    if (_isNotEmpty(subjects) && _isEmpty(animalIdentifiers)) {
      const animalIdentifierMap = subjects.reduce((acc, subject) => {
        acc.push({ ...subject.alt_ids, name: subject.name, animalId: subject.id });
        return acc;
      }, []);
      setAnimalIdentifiers(animalIdentifierMap);
    }
  }, [subjects]);

  useEffect(() => {
    if (_isNotEmpty(date)) {
      setLoading(true);
      getExistingMeasurements(study.id, date, newAbortController().signal)
        .then((result) => {
          if (isMounted()) {
            setLoading(false);
            setExistingMeasurements(result.data);
          }
        })
        .catch((error) => {
          if (isMounted()) {
            setLoading(false);
            if (notAborted(error)) {
              setErrors(error);
            }
          }
        });
    }
  }, [date]);

  useEffect(() => {
    if (!_isEmpty(upload)) {
      setLoading(true);
      readFile(upload[0])
        .then((result) => {
          if (isMounted()) {
            setLoading(false);
            setFileData(result);
          }
        })
        .catch((error) => {
          if (isMounted()) {
            setLoading(false);
            setErrors(error);
          }
        });
    }
  }, [upload]);

  useEffect(() => {
    setMatchData({});
  }, [fileData]);

  const nextStep = (nextStep) => {
    const newSteps = { ...steps };
    newSteps[activeStep]['complete'] = true;
    setSteps(newSteps);
    setActiveStep(nextStep);
  };

  const confirmUnarchive = (study, dispatch) => {
    Http.post(apiRoute('studies.unarchive', { id: study.id }))
      .then(({ data }) => {
        closeModal(dispatch);
        successToast(`Successfully ${study.archived_at ? 'resumed' : 'completed'} ${study.name}`);
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
      })
      .catch(defaultPromiseErrorHandler);
  };

  const confirmUncancel = (study, dispatch) => {
    Http.post(apiRoute('studies.uncancel', { id: study.id }))
      .then(({ data }) => {
        closeModal(dispatch);
        successToast(`Successfully ${study.canceled_at ? 'resumed' : 'cancelled'} ${study.name}`);
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
      })
      .catch(_noop);
  };

  const backStep = (backStep) => setActiveStep(backStep);

  const calculations = study.settings.calculations;

  const validationState = getValidationState(matchData, animalIdentifiers);

  const stageSteps = {
    upload: (
      <Upload
        date={date}
        setDate={setDate}
        upload={upload}
        setUpload={setUpload}
        nextStep={nextStep}
        existingMeasurements={existingMeasurements}
        loading={loading}
      />
    ),
    match: (
      <Match
        fileData={fileData}
        nextStep={nextStep}
        backStep={backStep}
        calculations={calculations}
        matchData={matchData}
        setMatchData={setMatchData}
        existingMeasurements={existingMeasurements}
        animalIdentifiers={animalIdentifiers}
        validationState={validationState}
      />
    ),
    review: (
      <Review
        study={study}
        collections={collections}
        fileData={fileData}
        matchData={matchData}
        reviewData={reviewData}
        setReviewData={setReviewData}
        nextStep={nextStep}
        backStep={backStep}
        existingMeasurements={existingMeasurements}
        validationState={validationState}
        subjects={subjects}
      />
    ),
    complete: (
      <Complete
        study={study}
        date={date}
        reviewData={reviewData}
        backStep={backStep}
        existingMeasurements={existingMeasurements}
      />
    ),
  };

  if (_isEmpty(calculations)) {
    return (
      <div className="flex items-center justify-center h-100">
        <div className="pa5 ui-card">
          <div className="tc">
            <h3 className="lh-title normal f4">No preset has been selected for this study.</h3>
          </div>
        </div>
      </div>
    );
  }

  if (_isNil(study)) {
    return (
      <ApiErrorBanner
        title="There was an error fetching study data"
        text="An error has occurred when fetching study data, please try again. If this keeps occurring please contact support."
      />
    );
  }

  return (
    <>
      <Header steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} date={date} />
      <div className="pv3 ph4">
        {errors && (
          <div className="w-100 h-100 flex items-center justify-center">
            <ApiErrorBanner
              className="mb4"
              title="There was an error with your request"
              text="An error has occurred with your request, please try again. If this keeps occurring please contact support."
              error={errors}
            />
          </div>
        )}
        {study.archived_at ? (
          <Banner info className="mw6 mr4 mb4" dismiss={false}>
            <h3 className="normal lh-title f5 pb1">
              {`This study was completed on ${DateUtils.renderDateTime(study.archived_at)}`}
            </h3>
            <p className="f6 lh-copy pb3">
              You can&apost;t import data into a study that has been completed.
              <br />
              To resume this study please select below.
            </p>
            <Button
              className="plain"
              onClick={() =>
                openModal('ARCHIVE_STUDY', dispatch, {
                  study,
                  onClick: () => confirmUnarchive(study, dispatch),
                  closeModal: () => closeModal(dispatch),
                })
              }
            >
              Resume Study
            </Button>
          </Banner>
        ) : study.canceled_at ? (
          <Banner info className="mw6 mr4 mb4" dismiss={false}>
            <h3 className="normal lh-title f5 pb1">
              {`This study was cancelled on ${DateUtils.renderDateTime(study.canceled_at)}`}
            </h3>
            <p className="f6 lh-copy pb3">
              You can&apost;t import data into a study that has been cancelled.
              <br />
              To resume this study please select below.
            </p>
            <Button
              className="plain"
              onClick={() =>
                openModal('CANCEL_STUDY', dispatch, {
                  study,
                  onClick: () => confirmUncancel(study, dispatch),
                  closeModal: () => closeModal(dispatch),
                })
              }
            >
              Resume Study
            </Button>
          </Banner>
        ) : (
          stageSteps[activeStep]
        )}
      </div>
    </>
  );
};

export default Importer;
