import { _notNil } from '@/littledash';
import { FC } from 'react';

interface PresetHeaderProps {
  action?: 'create' | 'edit' | 'clone';
  measurementName?: string;
}

const HeaderText: FC<Pick<PresetHeaderProps, 'action'> & { entity: string; measurementName?: string }> = ({
  action,
  entity,
  measurementName,
}) => {
  const style = 'near-black';

  if (_notNil(measurementName)) {
    return <h2 className={style}>Edit {measurementName}</h2>;
  }

  switch (action) {
    case 'clone':
      return <h2 className={style}>Clone {entity}</h2>;
    case 'create':
      return <h2 className={style}>Create {entity}</h2>;
    case 'edit':
      return <h2 className={style}>Update {entity}</h2>;
    default:
      return null;
  }
};

export const PresetHeader: FC<PresetHeaderProps> = ({ action, measurementName }) => (
  <div className="flex items-center mv3">
    <HeaderText action={action} entity="preset" measurementName={measurementName} />
  </div>
);
