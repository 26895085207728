// @ts-nocheck: converted from JS

import { NoDataMessage } from '@/components/NoDataCard';
import Table from '@/components/UI/Table';
import { renderMetadataValue } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { MetadataFieldWithValue } from '@/model/Metadata.model';
import React from 'react';

interface MetadataProps {
  metadata?: Array<MetadataFieldWithValue>;
}

const Metadata: React.FC<MetadataProps> = ({ metadata = [] }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'title',
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({
        row: {
          original: { field_type: fieldType, value },
        },
      }) => renderMetadataValue({ value, field_type: fieldType }),
    },
  ];

  if (_isEmpty(metadata)) {
    return <NoDataMessage title="No metadata has been entered for this study" dark />;
  } else {
    return (
      <>
        <Table data={metadata} columns={columns} />
      </>
    );
  }
};

export default Metadata;
