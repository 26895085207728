// @ts-nocheck: converted from JS

import { studyTypeOptions } from '@/components/Glossary/Sections/Approvals/TemplateForm/TemplateForm.utils';
import { closeModal, openModal } from '@/components/Shared/methods';
import Button from '@/components/UI/Button';
import Filters from '@/components/UI/Filters';
import Link from '@/components/UI/Link';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import TableFilterMenu from '@/components/UI/SelectDropDown/Menus/TableFilterMenu';
import Table from '@/components/UI/Table';
import { filterDataByFilters, filterDataBySearch } from '@/components/UI/Table/Table.utils';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { defaultPromiseErrorHandler, successToast } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { sortDescByProperty } from '@/utils/array';
import { canDelete } from '@/utils/includes/operations';

const ApprovalsTable = ({ approvals = [], refetch, onCreateNewClick }) => {
  const [settings, setSettings] = useState({
    rows: 25,
    page: 0,
  });
  const [searchQuery, setQuery] = useState('');
  const [filterMatch, setFilterMatch] = useState(true);
  const [filters, setFilters] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const dispatch = useDispatch();

  const columns = [
    {
      accessor: 'name',
      Header: 'Review Name',
      Cell: ({ row: { original } }) => (
        <Link
          to={webRoute('team.studies.approvals.edit', {
            approvalTemplate: original.id,
          })}
          className="link blue"
        >
          {original.name}
        </Link>
      ),
    },
    {
      accessor: 'study_type',
      Header: 'Applies To',
      Cell: (props) =>
        studyTypeOptions.find((studyTypeOption) => studyTypeOption.value === props.value)?.name || props.value,
    },
  ];

  const deleteApprovalTemplate = (id) => {
    return Http.delete(
      apiRoute('approvals.templates.delete', {
        approvalTemplate: id,
      })
    );
  };

  const handleDeleteClick = (approvalIds) => {
    openModal(
      approvalIds.length > 1 ? 'CONFIRM_DELETE_APPROVAL_TEMPLATES' : 'CONFIRM_DELETE_APPROVAL_TEMPLATE',
      dispatch,
      {
        onClick: () => {
          Promise.all(approvalIds.map(deleteApprovalTemplate))
            .then(() =>
              successToast(`Successfully deleted ${approvalIds.length > 1 ? 'review templates' : 'review template'}.`)
            )
            .then(refetch)
            .catch(defaultPromiseErrorHandler);
        },
        closeModal,
      }
    );
  };

  const tableData = useMemo(() => approvals, [approvals]);

  const onUpdateFilters = (filters, filterMatch) => {
    setFilters(filters);
    setFilterMatch(filterMatch);
    if (!_isEmpty(filters) && settings.page !== 0) {
      const currentSettings = { ...settings };
      currentSettings.page = 0;
      setSettings(currentSettings);
    }
  };

  const filterOptions = [
    {
      value: 'study_type',
      name: columns.find((column) => column.accessor === 'study_type').Header,
      operations: [
        {
          name: filterType.eq,
          value: filterType.eq,
          options: studyTypeOptions,
        },
        {
          name: filterType.ne,
          value: filterType.ne,
          options: studyTypeOptions,
        },
      ],
    },
  ];

  const Filter = () => (
    <TableFilterMenu
      filterOptions={filterOptions}
      filters={filters}
      onUpdateFilters={onUpdateFilters}
      filterMatch={filterMatch}
    />
  );

  const filterApprovals = (approvals) => {
    const approvalsFilteredByFilters = _isEmpty(filters)
      ? approvals
      : filterDataByFilters(approvals.sort(sortDescByProperty('id')), filters, filterMatch, filterOptions);
    return searchQuery === ''
      ? approvalsFilteredByFilters
      : filterDataBySearch(approvalsFilteredByFilters, searchQuery);
  };

  const getSelectedApprovals = (approvals) => {
    const getApproval = (rowIndex) => approvals[rowIndex];
    return approvals?.length
      ? Object.keys(selectedRows)
          .map(getApproval)
          .filter((v) => !!v)
      : [];
  };

  const filteredData = useMemo(() => filterApprovals(tableData), [tableData, filters, searchQuery]);
  const selectedApprovals = getSelectedApprovals(filteredData);
  const canDeleteAllSelected = selectedApprovals.every((approvalTemplate) => canDelete(approvalTemplate.operations));

  const actions = [];
  if (canDeleteAllSelected) {
    actions.push({
      name: 'Delete',
      action: () => {
        const getApprovalId = (rowIndex) => filteredData[rowIndex].id;
        handleDeleteClick(Object.keys(selectedRows).map(getApprovalId));
      },
    });
  }

  const getActionDisabledReason = () => {
    if (!canDeleteAllSelected) {
      const itemsThatCantDelete = selectedApprovals.filter(
        (approvalTemplate) => !canDelete(approvalTemplate.operations)
      );
      if (selectedApprovals.length > 1) {
        return 'One or more selected items cannot be deleted';
      }
      return itemsThatCantDelete?.[0]?.operations?.find((operation) => operation['delete'])?.delete?.reason ?? '';
    }
  };

  const isActionsDisabled = _isEmpty(selectedRows) || actions.length < 1;

  return (
    <div className="ui-card mv3">
      <div className="flex flex-row items-start justify-between pa3 bb b-moon-gray">
        <div className="flex-row w-100">
          <div className="flex w-100">
            <div className="flex mr2">
              {!_isEmpty(selectedRows) && (
                <div
                  className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                  style={{
                    whiteSpace: 'nowrap',
                    lineHeight: '2.4rem',
                  }}
                >
                  {Object.keys(selectedRows).length} selected
                </div>
              )}
              <SelectDropDown
                title="Actions"
                className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
                alignMenu="right"
                disabled={isActionsDisabled}
                tooltip={isActionsDisabled ? getActionDisabledReason() : undefined}
              >
                <ActionList actions={actions} />
              </SelectDropDown>
            </div>
            <SearchFilterBar
              FilterMenu={Filter}
              searchPlaceholderText="Search by review"
              handleSearchInput={setQuery}
              searchQuery={searchQuery}
            />
          </div>
          {!_isEmpty(filters) && (
            <Filters filters={filters} updateFilters={onUpdateFilters} filterMatch={filterMatch} />
          )}
        </div>
        <Button onClick={onCreateNewClick}>Create new review</Button>
      </div>
      <Table
        data={filteredData}
        columns={columns}
        pagination
        selectable
        setSelectedRows={setSelectedRows}
        initPageSize={10}
      />
    </div>
  );
};

export default ApprovalsTable;
