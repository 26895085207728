// @ts-nocheck: converted from JS

import CreateTemplateForm from '@/components/Tasks/Templates/Create/CreateTemplateForm';

const AddTask = (props) => (
  <div className="mw8 center mv3 mv0-l">
    <div className="ui-card pa4 pb0">
      <CreateTemplateForm {...props} modal />
    </div>
  </div>
);

export default AddTask;
