// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import RemoveButton from '@/components/UI/RemoveButton';
import { successToast } from '@/helpers';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import { useRequest } from '@/support/Hooks/request';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const AddTreatments = ({ onAddTreatments }) => {
  const LIMIT_ENTRIES = 20;
  const [loading, setLoading] = useState(false); // toggle when searching for treatment names
  const [apiError, setApiError] = useState(false);
  const { register, control, errors, handleSubmit, getValues } = useForm({
    defaultValues: { treatments: [{ name: '' }] },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'treatments',
  });
  const dispatch = useDispatch();

  const uniqueInSubmission = (value, index) => {
    const { treatments } = getValues();
    const duplicates = treatments?.find(({ name }, i) => name === value && index !== i);
    if (duplicates) {
      return 'Treatment names must be unique';
    }

    return true;
  };

  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });

  const {
    sendRequest: addTreatmentTypes,
    requestSending: addingTreatmentTypes,
    requestError: addTreatmentTypesError,
  } = useRequest({
    route: 'teams.treatmentTypes.post',
    method: 'post',
  });

  const onSubmit = async ({ treatments }) => {
    await addTreatmentTypes({ treatment_types: treatments });
    if (!addTreatmentTypesError) {
      onAddTreatments();
      closeModal();
      successToast('Created new treatment');
    } else {
      setApiError(addTreatmentTypesError);
    }
  };

  const validateUniqueTreatmentName = async (value) => {
    try {
      setLoading(true);
      const { data } = await Http.get(
        apiRoute('teams.treatmentTypes.validate', {
          treatmentName: value,
        })
      );
      setLoading(false);
      return data.valid || 'Treatment names must be unique';
    } catch (error) {
      if (notAborted(error)) {
        setApiError(error);
      }
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`ui-card center mw6 ${addingTreatmentTypes ? 'ui__disabled' : ''}`}
    >
      <div className="pa3">
        <h3 className="f4 lh-title normal">Add treatments</h3>
        <p>Up to a maximum of {LIMIT_ENTRIES}</p>
      </div>
      {apiError && (
        <ApiErrorBanner
          title="There was an error with your request"
          text="An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support."
          error={apiError}
        />
      )}
      <>
        {fields.map((field, index) => (
          <div key={field.id} className={`pa3 ${index > 0 ? 'bt b--moon-gray' : ''}`}>
            <label htmlFor={`treatments[${index}].name`}>Treatment name</label>
            <div className="flex justify-between">
              <input
                type="text"
                name={`treatments[${index}].name`}
                autoFocus={index === 0}
                ref={register({
                  required: 'This field is required',
                  validate: {
                    uniqueInSubmission: (value) => uniqueInSubmission(value, index),
                    uniqueFromAPI: validateUniqueTreatmentName,
                  },
                })}
                defaultValue={field.name}
                className={`mr3 w-100
                  ${errors?.treatments?.[index]?.name ? 'input__error' : ''}
                `}
                style={{ marginBottom: 0 }}
              />
              <RemoveButton onClick={() => remove(index)} isDisabled={fields.length === 1} />
            </div>
            <ErrorMessage
              errors={errors}
              name={`treatments[${index}].name`}
              render={({ message }) => <p className="f6 red db pt2">{message}</p>}
            />
          </div>
        ))}
      </>
      <div className="ph3">
        <Button
          paleBlue
          pill
          icon="add_new"
          disabled={fields.length >= LIMIT_ENTRIES}
          onClick={() =>
            append({
              name: '',
            })
          }
        >
          Add new
        </Button>
      </div>
      <div className={`pa3 mt3 bt b--moon-gray ${loading ? 'ui__disabled' : ''}`}>
        <Button submit>Save</Button>
        <Button plain className="ml3" onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default AddTreatments;
