// @ts-nocheck: converted from JS

const Line = ({ dashed }) => {
  return (
    <div
      style={{
        marginLeft: 9, // half width of circles
        borderLeft: `2px ${dashed ? 'dashed #C4CDD5' : 'solid #00A35E'}`,
        height: 30,
      }}
    />
  );
};

export default Line;
