import { SummaryItem } from '@/components/Studies/Show';
import Banner from '@/components/UI/Banner';
import { DateRenderer, DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import type { Approval } from '@/model/Approval.model';
import InVivoError from '@/model/InVivoError.ts';
import type { Request } from '@/model/Request.model';
import type { Study, StudyMetrics } from '@/model/Study.model';
import { Status } from '@/referenceData/study/status';
import { typeToTextMap } from '@/referenceData/study/type';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React, { useEffect, useState } from 'react';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import Approvals from './Approvals';
import Metadata from './Metadata';
import Project from './Project';
import Reports from './Reports';
import Requests from './Requests';
import { ID } from '@/model/Common.model';
import { StudyFormMetaDetails, StudyFormSection } from '@/model/StudyForm.model';
import { MetadataFieldWithValue } from '@/model/Metadata.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';

interface OverviewProps {
  study: Study;
  approvals?: Array<Approval>;
  requests?: Array<Request>;
}

const Overview: React.FC<OverviewProps> = ({ study, approvals, requests }) => {
  const [metadataSections, setMetadataSections] = useState<
    Array<{ name: string; metadata: Array<MetadataFieldWithValue> }>
  >([]);
  const hasApprovals = (approvals?.length ?? 0) > 0;
  const isAwaitingApproval = study.status === Status.awaiting_approval;
  const author = study.author;
  const owner = study.owner;

  const { entity: metrics, entityError: metricsError } = useFetchEntity<StudyMetrics>({
    entityType: 'studyMetrics',
    params: { id: study.id },
    includes: 'counts',
  });

  if (metricsError) {
    ExceptionHandler.captureException(
      new InVivoError('Could not fetch study metrics', {
        cause: metricsError,
        slug: 'study-metrics-load',
      })
    );
  }

  useEffect(() => {
    const fetchFormTemplate = async () => {
      try {
        const metadata = study.metadata;
        const formId = study.study_creation_template_id;
        if (_isNotEmpty(formId)) {
          const response = await Http.get(
            apiRoute('team.studies.forms.get', {
              formId,
            })
          );

          const formMetaResponse: StudyFormMetaDetails = response.data.data;

          const metadataSections: Array<StudyFormSection> = formMetaResponse.sections.reduce(
            (acc: Array<StudyFormSection>, { id, created_at, type, name, active, metadata }) => {
              if (type === 'custom') {
                acc.push({ name, metadata, active, default_section_number: 0, type, id, created_at });
              }
              return acc;
            },
            []
          );

          const metadataSectionsList: Array<{ name: string; metadata: Array<MetadataFieldWithValue> }> = [];
          const metadataInSectionsIdList: Array<ID> = [];
          metadataSections.forEach((section: StudyFormSection) => {
            const idList: Array<ID> = [];
            section.metadata.forEach((meta) => {
              idList.push(meta.info.id);
            });
            metadataInSectionsIdList.push(...idList);

            const filteredMetadata: Array<MetadataFieldWithValue> = metadata.filter((meta) => {
              return idList.includes(meta.glossary_id);
            });
            if (_isNotEmpty(filteredMetadata)) {
              metadataSectionsList.push({ name: section.name, metadata: filteredMetadata });
            }
          });

          const additionalMetadata: Array<MetadataFieldWithValue> = metadata.filter((meta) => {
            return !metadataInSectionsIdList.includes(meta.glossary_id);
          });

          if (!_isEmpty(additionalMetadata)) {
            metadataSectionsList.push({ name: 'Metadata', metadata: additionalMetadata });
          }
          setMetadataSections(metadataSectionsList);
        }
      } catch (error) {
        if (notAborted(error)) {
          ExceptionHandler.captureException(
            new InVivoError('Could not fetch study creation template.', {
              cause: error,
              slug: 'study-creation-template-load',
            })
          );
        }
      }
    };
    fetchFormTemplate();
  }, []);

  return (
    <div data-test-component="Overview" data-test-element="container">
      {hasApprovals && isAwaitingApproval && (
        <Banner warning className="mw6 mb4" dismiss={false} center>
          <h3 className="lh-title f4 mb2">Action required</h3>
          <h3 className="f6 normal lh-copy">
            This study has outstanding reviews that need to be completed before it can begin.
          </h3>
        </Banner>
      )}
      <Tabs>
        <div className="mb4 bb b--moon-gray">
          <Tab className="f4">Overview</Tab>
          <Tab className="f4" counter={study.metadata.length}>
            Metadata
          </Tab>
          {study.project_id && <Tab className="f4">Project</Tab>}
          <Tab className="f4">Randomizations</Tab>
        </div>
        <div className="mb4">
          <Panel>
            <div className="ui-card pa3">
              <div className="flex flex-row mb3">
                <div className="w-50">
                  {author && <SummaryItem label="Author">{author.name}</SummaryItem>}
                  {owner && <SummaryItem label="Owner">{owner.name}</SummaryItem>}
                  <SummaryItem label="Date created">
                    <DateTimeRenderer value={study.created_at} defaultResponse="-" />
                  </SummaryItem>
                  <SummaryItem label="Preset">{study.settings.title}</SummaryItem>
                  <SummaryItem label="Type">{typeToTextMap[study.type]}</SummaryItem>
                </div>
                <div className="w-50">
                  <SummaryItem label="Animals">{metrics?.counts.active_animals ?? '-'}</SummaryItem>
                  <SummaryItem label="Cages">{metrics?.counts.cages ?? '-'}</SummaryItem>
                  <SummaryItem label="Groups">{metrics?.counts.study_groups ?? '-'}</SummaryItem>
                  <SummaryItem label="Start date">
                    <DateRenderer value={study.started_on} defaultResponse="-" />
                  </SummaryItem>
                </div>
              </div>
              {_isNotEmpty(study?.notes) && (
                <div className="flex flex-row mb3">
                  <div className="w-100">
                    <div className="lh-copy">
                      <div className="flex flex-row ph3 pv2 f6">
                        <div style={{ width: 150, minWidth: 150 }}>Description</div>
                        <p className="near-black f6">{study.notes}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Panel>
          {_isNotEmpty(metadataSections) ? (
            <Panel>
              {metadataSections.map((section, index) => (
                <div key={index} className={`ui-card ${index > 0 ? 'mt4' : ''}`}>
                  <h3 className="f4 lh-title pa3">{section.name}</h3>
                  <div className="bt b--moon-gray">
                    <Metadata metadata={section.metadata} />
                  </div>
                </div>
              ))}
            </Panel>
          ) : (
            <Panel>
              <div className="ui-card mt4">
                <div className="bt b--moon-gray">
                  <Metadata metadata={study.metadata}></Metadata>
                </div>
              </div>
            </Panel>
          )}
          {study.project_id && (
            <Panel>
              <Project projectId={study.project?.id} />
            </Panel>
          )}
          <Panel>
            <Reports studyId={study.id} studyApiId={study.api_id} />
          </Panel>
        </div>
      </Tabs>

      <Tabs>
        <div className="mb4 bb b--moon-gray" data-test-element="tab-container">
          <Tab className="f4" counter={approvals?.length}>
            Reviews
          </Tab>
          <Tab className="f4" counter={requests?.length}>
            Requests
          </Tab>
        </div>
        <div className="mb4">
          <Panel>
            <Approvals approvals={approvals} study={study} />
          </Panel>
          <Panel>
            <Requests requests={requests} study={study} />
          </Panel>
        </div>
      </Tabs>
    </div>
  );
};

export default Overview;
