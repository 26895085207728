import { _notNil } from '@/littledash.ts';
import { historyEventBus, HistoryPushEvent } from '@/utils/history.ts';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const BrowserHistoryEventBridge: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    if (_notNil(history)) {
      const abortController = new AbortController();
      historyEventBus.addEventListener(
        'history:push',
        (event) => {
          history.push((event as HistoryPushEvent)?.detail?.path);
        },
        { capture: true, signal: abortController.signal }
      );
      return () => {
        abortController.abort('destroy');
      };
    }
  }, [history]);
  return children;
};
