// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import { Redirect, Route, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Http from '@/support/http';
import { api as apiRoute, web as webRoutes } from '@/support/route';
import Loading from '@/components/Loading';
import * as Auth from '@/support/auth';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';

interface withStudyV2Props {
  includes?: string;
  restrictedAccessFns?: Array<(study: any) => boolean>;
}

export const withStudyV2 =
  (Component: () => JSX.Element, { includes = '', restrictedAccessFns = [] }: withStudyV2Props) =>
  (props: any) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { newAbortController } = useAbortController();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const fetchStudy = async () => {
      setLoading(true);
      try {
        const apiParams = {
          id: params.id,
        };
        let queryParams = {};
        if (includes !== '') {
          queryParams = { include: includes };
        }
        const {
          data: { data },
        } = await Http.get(apiRoute('studies.show.p', apiParams, queryParams), { signal: newAbortController().signal });
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data,
        });
        if (restrictedAccessFns?.some((restrictedAccessFn) => restrictedAccessFn(data))) {
          history.replace(webRoutes('studies.show', { id: params.id }));
        }
        setLoading(false);
      } catch (error) {
        if (notAborted(error)) {
          setError(error);
          setLoading(false);
        }
      }
    };

    useEffect(() => {
      fetchStudy();
    }, []);

    if (loading) {
      return <Loading />;
    }
    if (error) {
      return <div>error</div>;
    }
    return <Component {...props} />;
  };

export const withStudy = (Component, restrictedAccessFns?: any) => (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { newAbortController } = useAbortController();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const fetchStudy = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('studies.show', { id: params.id, include: 'project' }), {
        signal: newAbortController().signal,
      });
      dispatch({
        type: 'SET_CURRENT_STUDY',
        study: data,
      });
      if (restrictedAccessFns?.some((restrictedAccessFn) => restrictedAccessFn(data))) {
        history.replace(webRoutes('studies.show', { id: params.id }));
      }
      setLoading(false);
    } catch (error) {
      if (notAborted(error)) {
        setError(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchStudy();
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <div>error</div>;
  }
  return <Component {...props} />;
};

export const withGlossary =
  (Component, group = 'observations') =>
  ({ ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [glossary, setGlossary] = useState([]);
    const { newAbortController } = useAbortController();

    const fetchGlossary = async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await Http.get(apiRoute('glossary.show'), { signal: newAbortController().signal });
        setGlossary(data);
        setLoading(false);
      } catch (error) {
        if (notAborted(error)) {
          setError(error);
          setLoading(false);
        }
      }
    };

    useEffect(() => {
      fetchGlossary();
    }, []);

    if (loading) {
      return <Loading txt="Loading Glossary..." />;
    }
    if (error) {
      return <div>error</div>;
    }
    return <Component {...rest} glossary={glossary.filter((item) => item.group === group)} />;
  };

export const withSpacing = (Component) => (props) => (
  <div className="ph4 pb5 w-100 h-100">
    <Component {...props} />
  </div>
);

export const onlyTeamOwners = (Component) => (props) => {
  return (
    <Route
      {...props}
      render={({ location }) =>
        Auth.isCurrentTeamOwner() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/403',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const onlySupportUsers = (Component) => (props) => {
  return (
    <Route
      {...props}
      render={({ location }) =>
        Auth.isCurrentTeamAdminConsoleUser() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/403',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const resetReduxSettings = (Component) => (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'RESET_SETTINGS' });
  }, []);
  return <Component {...props} />;
};
