import { getCalculationsAndMeasurements } from '@/helpers';
import { _isEmpty } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { Alert, AlertUpdateEventHandler } from '@/utils/alerts/Alert';
import React, { useMemo, useState } from 'react';
import type { AlertDispatch } from './Show.utils';

interface AnimalAlertsOverviewProps {
  study: Study;
  alerts: Array<AnimalAlertV1>;
  dispatch: AlertDispatch;
}

/**
 * @param study {Record<string,any>}
 * @param alerts {Array<Record<string,any>>}
 * @param dispatch {AlertDispatch}
 * @returns {JSX.Element|null}
 * @constructor
 */
export const AnimalAlertsOverview: React.FC<AnimalAlertsOverviewProps> = ({
  study,
  alerts: incomingAlerts,
  dispatch,
}) => {
  const [alerts, updateAlerts] = useState(incomingAlerts);
  const calculationMap = useMemo(() => getCalculationsAndMeasurements(study.settings.calculations), [study]);
  const isWriteUser = Auth.isWriteUserForStudy(study);

  const alertSettings = {
    displayHeader: false,
    displayDeferControl: false,
    displayResolveControl: isWriteUser,
    displayAlertState: false,
    displayCreatedAt: false,
  };

  const updateHandler: AlertUpdateEventHandler = async (event) => {
    if (event.type === 'resolved') {
      const updatedAlerts = alerts.map((alert) => (alert.id === event.data.id ? event.data : alert));
      updateAlerts(updatedAlerts);
      dispatch({ type: 'SET_ALERTS', data: updatedAlerts });
    }
  };

  if (_isEmpty(alerts)) {
    return null;
  }

  return (
    <div className="ui-card mb3 pa3 g3 flex flex-column">
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          alert={alert}
          calculation={calculationMap.get(alert.calculation)}
          studyId={study.id}
          settings={alertSettings}
          onUpdate={updateHandler}
        />
      ))}
    </div>
  );
};
