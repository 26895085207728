import { PercentChangeOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.model';
import { components } from '@/generated/internal-api/openapi-types';
import { AnimalIdentifier } from '@/model/Animal.model';
import { ISODate } from '@/model/Common.model.ts';

export type WorkflowTemplate = components['schemas']['WorkflowTemplateV1.schema'];

export const workflowTemplateDefaults = (workflowTemplate: WorkflowTemplate, measured_at: ISODate | undefined) => ({
  idToSearch: workflowTemplate.id_search_by as AnimalIdentifier,
  idToDisplay: workflowTemplate.id_display as AnimalIdentifier,
  showTreatmentGroups: workflowTemplate.show_treatment_groups,
  startOn: workflowTemplate.start_on,
  playSound: workflowTemplate.play_sound,
  measurements: workflowTemplate.measurement,
  samples: workflowTemplate.sample,
  observations: workflowTemplate.observation,
  dosing: workflowTemplate.dosing,
  measured_at,
});

export interface WorkflowShowProps {
  templateDefaults?: {
    idToSearch: AnimalIdentifier;
    idToDisplay: AnimalIdentifier;
    showTreatmentGroups: boolean;
    playSound?: boolean;
    startOn: 'measurements' | 'samples' | 'observations' | 'dosing';
    measurements: WorkflowTemplate['Measurement'];
    samples: WorkflowTemplate['Sample'];
    observations: WorkflowTemplate['Observation'];
    dosing: WorkflowTemplate['Dosing'];
    measured_at: ISODate | undefined;
  };
}

export const getWeightPercentageChangeValue = (weightPercentageChange?: string) => {
  switch (weightPercentageChange) {
    case 'first_measurement':
      return PercentChangeOptions.fromFirstMeasurement;
    case 'latest_measurement':
      return PercentChangeOptions.fromLatestMeasurement;
    case 'tracking_date':
      return PercentChangeOptions.trackingDate;
    default:
      return PercentChangeOptions.fromFirstMeasurement;
  }
};

export const getAfterSaveAction = (afterSaveAction?: string) => {
  switch (afterSaveAction) {
    case 'focus_search_bar':
      return 'focus-search';
    case 'measurements':
    case 'samples':
    case 'observations':
    case 'dosing':
      return `toggle-${afterSaveAction}`;
    case 'next_animal':
      return 'next-subject';
    default:
      return 'focus-search';
  }
};
