// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { DragHintBanner } from '@/components/UI/Banner/Reusable';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import List from '@/components/UI/List';
import { asSortableListItem } from '@/components/UI/List/List';
import SearchSelect from '@/components/UI/SearchSelect';
import { errorToast, successToast } from '@/helpers';
import { _isEmpty, _noop } from '@/littledash';
import { standardSections } from '@/referenceData/study/type';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';

const Show = ({ formId, sectionId, onSaveSection, saveTemplate, customSectionNames }) => {
  const [section, setSection] = useState(undefined);
  const [studyMetadata, setStudyMetadata] = useState(undefined);
  const [assignedStudyMetadataIds, setAssignedStudyMetadataIds] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);

  const fetchSectionAndMetadata = async (formId, sectionId) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'study_meta',
        },
      });
      setStudyMetadata(data);
      const {
        data: { metadata_ids },
      } = await Http.get(apiRoute('team.studies.forms.metadata', { id: formId }));
      setAssignedStudyMetadataIds(metadata_ids);
      if (sectionId) {
        const section = await Http.get(apiRoute('team.studies.forms.section.show', { formId, sectionId }));
        setSection(section.data.data);
      }
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSectionAndMetadata(formId, sectionId);
  }, [formId, sectionId]);

  return (
    <div className="pa2 bg-light-gray ba b--moon-gray br2 shadow-5 mw7 center">
      {apiError && <ApiErrorBanner error={apiError} />}
      {loading ? (
        <Loading />
      ) : (
        <SectionForm
          formId={formId}
          section={section}
          studyMetadata={studyMetadata}
          assignedStudyMetadataIds={assignedStudyMetadataIds}
          onSaveSection={onSaveSection}
          saveTemplate={saveTemplate}
          customSectionNames={customSectionNames}
        />
      )}
    </div>
  );
};

const SectionForm = ({
  formId,
  section = {},
  studyMetadata,
  assignedStudyMetadataIds = [],
  onSaveSection = _noop,
  saveTemplate,
  customSectionNames = [],
}) => {
  const isEditing = !_isEmpty(section);
  const defaultSection = {
    name: section.name || '',
    metadata: section.metadata
      ? section.metadata.map((m) => {
          const meta = { ...m.info, ...m };
          delete meta.info;
          return meta;
        })
      : [],
  };

  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [selected, setSelected] = useState(defaultSection.metadata.map((s) => s.id));
  const [_, setSwapped] = useState();

  const formMethods = useForm({
    defaultValues: {
      name: defaultSection.name,
      metadata: defaultSection.metadata.map((meta) => ({ value: { ...meta } })) || [],
    },
  });
  const { register, errors, handleSubmit, control, setError, clearErrors, formState } = formMethods;
  const { isSubmitting, isSubmitted } = formState;
  const dispatch = useDispatch();
  const { fields, swap, append, remove } = useFieldArray({
    control,
    name: 'metadata',
  });

  // React hook form field swap can only be called once per render (see https://react-hook-form.com/api/usefieldarray/#rules)
  // https://overwatchresearch.atlassian.net/browse/OR-2278 - Duplicates created in DnD component without this
  const handleSwap = (from, to) => {
    setSwapped(() => {
      swap(from, to);
      return { from, to };
    });
  };

  const handleSelect = ({ type, value }) => {
    if (type === 'ADDED') {
      const metadata = studyMetadata.find((meta) => Number(meta.id) === Number(value));
      append({
        value: {
          ...metadata,
          required: false,
        },
      });
    } else {
      const index = fields.findIndex((field) => Number(field.value.id) === Number(value));
      remove(index);
    }
  };

  const selectableMetadata = studyMetadata.filter((meta) => {
    if (isEditing) {
      const sectionMetadataIds = section.metadata.map((m) => m.info.id);
      if (sectionMetadataIds.includes(meta.id)) {
        return true;
      }
    }
    if (!assignedStudyMetadataIds.includes(meta.id)) {
      return true;
    }
  });

  const handleSelectAll = () => {
    remove(fields.map((_, i) => i));
    append(
      selectableMetadata.map((meta) => ({
        value: { ...meta, required: false },
      }))
    );
  };

  const handleClearSelection = () => {
    remove(fields.map((_, i) => i));
  };

  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });

  useEffect(() => {
    if (isSubmitting || isSubmitted) {
      clearErrors('fieldArray.metadata');
      if (!fields.length) {
        setError('fieldArray.metadata', {
          type: 'manual',
          message: 'Sections must contain at least one item.',
        });
      }
    }
  }, [isSubmitting, isSubmitted, setError, selected]);

  const onSubmit = async (data) => {
    if (data.metadata) {
      setSubmitting(true);
      try {
        await saveTemplate();
        const method = isEditing ? 'put' : 'post';
        const url = isEditing
          ? apiRoute('team.studies.forms.section.update', {
              formId,
              sectionId: section.id,
            })
          : apiRoute('team.studies.forms.section.create', { formId });
        await Http[method](url, {
          ...data,
          metadata: data.metadata.map((meta) => ({ ...meta.value })),
        });
        closeModal();
        successToast('Section saved.');
        onSaveSection();
      } catch (error) {
        setApiError(error);
        errorToast('There was a problem with your submission.');
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      {apiError && <ApiErrorBanner error={apiError} />}
      <h3 className="f4 lh-title normal pa3">{isEditing ? `Editing ${section.name}` : 'Add section'}</h3>
      <div className="ph3 pb3">
        <DragHintBanner />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-50 pa3">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            autoFocus={!isEditing}
            className={`${errors?.name ? 'input__error' : ''}`}
            ref={register({
              required: 'This field is required',
              minLength: {
                value: 3,
                message: 'Minimum length of 3 characters',
              },
              maxLength: {
                value: 50,
                message: 'Maximum length of 50 characters',
              },
              validate: {
                notReserved: (val) =>
                  !Object.values(standardSections).includes(val) ||
                  'This name is reserved for Benchling In Vivo sections',
                doesntExistAlready: (val) =>
                  !customSectionNames.filter((name) => name !== section?.name).includes(val) ||
                  'Section names must be unique',
              },
            })}
            style={{ marginBottom: 0 }}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>
        <div className="flex flex-wrap justify-between">
          <div className="w-50 pa3">
            <SearchSelect
              className="ui-card"
              sections={[
                {
                  title: 'Study metadata',
                  items: selectableMetadata,
                },
              ]}
              selected={selected}
              setSelected={setSelected}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onClearSelection={handleClearSelection}
            />
            <ErrorMessage
              errors={errors}
              name="fieldArray.metadata"
              render={({ message }) => <p className="f6 red db pt2">{message}</p>}
            />
          </div>
          <div className="w-50 pa3">
            <div className="ui-card pa3 overflow-auto" style={{ height: 409 }}>
              {!_isEmpty(fields) ? (
                <FormProvider {...formMethods}>
                  <List
                    canAmend={false}
                    canAdd
                    canRemove={false}
                    data={fields}
                    renderItem={asSortableListItem({
                      canSort: fields.length > 1,
                      sortableType: 'study-section-metadata-item',
                      moveCallback: handleSwap,
                    })(MetadataFormItem)}
                    itemProps={{
                      className: 'bb b--moon-gray pb3',
                    }}
                  />
                </FormProvider>
              ) : (
                <div className="bg-light-silver pa3 br2 w-100 h-100 items-center flex">
                  <h3 className="lh-title f5 w-100 tc">Select metadata for this section</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pa3">
          <Button submit className="mr3" disabled={submitting}>
            Save
          </Button>
          <Button plain onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

const MetadataFormItem = ({ value, index }) => {
  const { control } = useFormContext();

  return (
    <Controller
      key={index}
      defaultValue={value}
      name={`metadata[${index}].value`}
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div>
          <h3 className="f6 normal lh-title pb2">{value.title}</h3>
          <Checkbox
            className="pointer"
            type="checkbox"
            label="Required"
            name={value.id}
            checked={value.required}
            onChange={() =>
              onChange({
                ...value,
                required: !value.required,
              })
            }
          />
        </div>
      )}
    />
  );
};

export default Show;
