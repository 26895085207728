import type { FC } from 'react';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ReleaseInfo } from '@/generated/ReleaseInfo';
import { _notNil } from '@/littledash';
import { IntercomUtils } from '@/utils/Intercom.utils';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ReleaseChecker } from '@/utils/ReleaseChecker';

export const UpdateChecker: FC = () => {
  const [updateVersion, setUpdateVersion] = useState<string | null>(null);

  const handleDefer = () => {
    setUpdateVersion(null);
    try {
      IntercomUtils.trackEvent('user-deferred-update', {
        fromVersion: ReleaseInfo.releaseVersion,
        toVersion: updateVersion,
      });
    } catch (e) {
      // Intentionally empty
    }
  };
  const handleUpdate = () => {
    window.location.reload();
  };

  useEffect(() => {
    const releaseChecker = new ReleaseChecker({
      onUpdateAvailable: ({ version }) => setUpdateVersion(version),
    }).initialise();
    return () => {
      releaseChecker.destroy();
    };
  }, [setUpdateVersion]);
  if (_notNil(updateVersion)) {
    return (
      <ReactModal
        isOpen={true}
        className="ui-modal"
        style={{
          overlay: { backgroundColor: 'rgba(00,00,00,.5)' },
          content: { maxHeight: '100vh' },
        }}
        ariaHideApp={false}
      >
        <ModalContainer size="narrow" className="pa3">
          <ModalHeader title="An update to Benchling In Vivo has been released" subText="Simply refresh to apply" />
          <ModalActions
            className="pa3 mt3"
            submitBtnText="Refresh Now"
            cancelBtnText="Later"
            onSubmit={handleUpdate}
            onCancel={handleDefer}
          />
        </ModalContainer>
      </ReactModal>
    );
  }
  return null;
};
