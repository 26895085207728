import type { Dispatch } from 'react';
import SelectDropDown from '@/components/UI/SelectDropDown';
import Select from '@/components/UI/Select';
import MenuContainer from '@/components/UI/SelectDropDown/Menus/MenuContainer';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { ChangeOption, GraphAction, GraphsState } from './Graph.model';
import type { SelectOption } from '@/components/UI/Select/Select';
import { useSelector } from 'react-redux';
import type { State } from '@/model/State.model.ts';
import { createSelector } from '@reduxjs/toolkit';

interface GraphChangeOption {
  label: string;
  value: string;
}

interface ChangeOptionsLookup {
  absolute: GraphChangeOption;
  relative: GraphChangeOption;
  change: GraphChangeOption;
  cumulative?: GraphChangeOption;
}

const featureFlagSelector = createSelector(
  [(state: State) => state.team.features.cumulative_graph ?? false],
  (cumulativeGraphingEnabled) => cumulativeGraphingEnabled
);

const getChangeOptionsLookup = (cumulativeGraphingEnabled: boolean): ChangeOptionsLookup => {
  const options: ChangeOptionsLookup = {
    absolute: {
      label: 'Absolute',
      value: 'absolute',
    },
    relative: {
      label: 'Relative',
      value: 'relative',
    },
    change: {
      label: '% Change',
      value: 'change',
    },
  };

  if (cumulativeGraphingEnabled) {
    options.cumulative = {
      label: 'Cumulative',
      value: 'cumulative',
    };
  }

  return options;
};

interface ToolbarProps {
  calculations: Array<PresetCalculation>;
  state: GraphsState;
  dispatch: Dispatch<GraphAction>;
  disabled?: { metrics: boolean; relative: boolean };
}

const Toolbar: React.FC<ToolbarProps> = ({ calculations, state, dispatch, disabled }) => {
  const { activeMetric, groups, changeOption } = state;

  const cumulativeGraphingEnabled = useSelector(featureFlagSelector);

  const changeOptionsLookup = getChangeOptionsLookup(cumulativeGraphingEnabled);
  const relativeOptions = Object.values(changeOptionsLookup);

  return (
    <div className="ph4 pv3 bg-white bb b--moon-gray flex items-center justify-between flex-wrap z-999">
      <div className="flex">
        <div data-testid="graphs__metric-select">
          <Select
            className="mr3"
            disabled={disabled?.metrics}
            defaultValue={{
              label: activeMetric?.name,
              value: activeMetric,
            }}
            options={calculations.map((c) => ({
              value: c,
              label: c.name,
            }))}
            isMulti={false}
            onChange={(value) =>
              dispatch({ type: 'setActiveMetric', data: (value as SelectOption<PresetCalculation>).value })
            }
          />
        </div>
        <SelectDropDown title="Groups" className="plain" alignMenu="left" testId="graphs__group-select">
          <MenuContainer style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <div className="ph3 pv2" data-testid="graphs__group-select--options">
              {groups.map(({ id, checked, name }, index) => (
                <Checkbox
                  key={id}
                  checked={checked}
                  onChange={() => {
                    const updatedGroups = [...groups];
                    updatedGroups[index].checked = !checked;
                    dispatch({
                      type: 'setGroups',
                      data: updatedGroups,
                    });
                  }}
                  id={id.toString()}
                  name={name}
                  label={name}
                  className="mv3"
                />
              ))}
            </div>
          </MenuContainer>
        </SelectDropDown>
      </div>
      <div>
        <div className="flex justify-between flex-wrap items-center" data-testid="graphs__change-select">
          <Select
            defaultValue={changeOptionsLookup[changeOption]}
            options={relativeOptions}
            onChange={(value) =>
              dispatch({ type: 'setChangeOption', data: (value as SelectOption<ChangeOption>).value })
            }
            isMulti={false}
            disabled={disabled?.relative}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
