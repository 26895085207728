// @ts-nocheck: converted from JS

import Files from '@/components/Files';
import { CreateLinks } from '@/components/Studies/Create/Steps/Attachments/Links';
import { deleteFile, downloadFile, loadFiles, uploadFile } from '@/components/Studies/Create/Steps/Files/file.utils';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { useEffect, useState } from 'react';

const Attachments = ({ state, dispatch, formMeta, formId }) => {
  const [selectedLinks, setSelectedLinks] = useState({});

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  return (
    <>
      <div className="mb3" data-testid="attachments__container">
        <h3 className="fw5 f5 pb2">Attachments</h3>
      </div>
      <Tabs>
        <div className="pt3 pr4 bb b--moon-gray">
          <Tab className="f5">Files</Tab>
          <Tab className="f5">Links</Tab>
        </div>
        <div className={'mv3 ui-card mw7'}>
          <Panel>
            <Files
              context={{ state, dispatch, formMeta, formId }}
              loadFiles={loadFiles}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              downloadFile={downloadFile}
            />
          </Panel>
          <Panel>
            <CreateLinks
              state={state}
              dispatch={dispatch}
              selectedRows={selectedLinks}
              setSelectedRows={setSelectedLinks}
            />
          </Panel>
        </div>
      </Tabs>
    </>
  );
};

export default Attachments;
