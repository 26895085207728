import { useModalAction } from '@/utils/modal';
import type { FC } from 'react';

interface InfoProps {
  title: string;
  text: string;
  btnTxt: string;
}

export const Info: FC<InfoProps> = ({ title, text, btnTxt }) => {
  const { closeModal } = useModalAction();

  return (
    <div className="ui__narrow-modal">
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">{title}</h3>
        <p className="f6 pb2">{text}</p>
      </div>
      <div>
        <a className="button db w-100 mt2" onClick={closeModal}>
          {btnTxt}
        </a>
      </div>
    </div>
  );
};
