import Icon from '@/components/UI/Icon/index';
import type { Study } from '@/model/Study.model';
import { web as webRoute } from '@/support/route';
import { ModalContainer, useModalAction } from '@/utils/modal';
import type React from 'react';
import { useHistory } from 'react-router-dom';

interface MoveToStudySuccessProps {
  currentStudy: Study;
  newStudy: Study;
  animalCount: number;
  handleCallback: (text: string) => void;
}

const MoveToStudySuccess: React.FC<MoveToStudySuccessProps> = ({
  currentStudy,
  newStudy,
  animalCount,
  handleCallback,
}) => {
  const { closeModal } = useModalAction();
  const history = useHistory();

  const onSubmitNewStudy = () => {
    closeModal();
    // TODO: Develop a more robust way to rehydrating the view when the same route is hit (with a different param)
    history.push(webRoute('studies.animals', { id: newStudy.id }));
    history.go(0);
  };

  const onSubmitCurrentStudy = () => {
    handleCallback('Animals moved successfully');
    closeModal();
  };

  return (
    <ModalContainer size="narrow" className="bg-white h-100">
      <div className="pa4 mt2 mb2 flex flex-column items-center">
        <Icon icon="tick" width="40" height="40" className="green" />
        <h3 className="mb2">Animals moved successfully</h3>
        <div className="mb4">
          {animalCount} animal{animalCount > 1 ? 's were' : ' was'} moved to {newStudy.name}
        </div>
        <div className="link blue mb2" onClick={onSubmitCurrentStudy}>
          Return to {currentStudy.name}
        </div>
        <div className="link blue" onClick={onSubmitNewStudy}>
          Go to {newStudy.name}
        </div>
      </div>
    </ModalContainer>
  );
};

export default MoveToStudySuccess;
