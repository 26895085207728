import { AlertBulkActions } from '@/components/Studies/Create/Steps/Alerts/Alerts';
import { AlertsTable } from '@/components/Studies/Create/Steps/Alerts/AlertsTable';
import Button from '@/components/UI/Button';
import { errorToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalAlertSpecCreateOrUpdate, AnimalAlertV1 } from '@/model/Alert.model';
import type { Study } from '@/model/Study.model';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from '@/support/Hooks/request';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';

interface AlertsSettingsProps {
  study: Study;
  alerts: Array<AnimalAlertV1>;
  reloadAlerts: () => void;
}

export const AlertsSettings: React.FC<AlertsSettingsProps> = ({ alerts, study, reloadAlerts }) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const [selectedRows, onSelectedRowsChange] = useState<Record<number, boolean>>({});
  const calculations = study?.settings?.calculations ?? [];
  const disabled = _isEmpty(calculations) || _notNil(study?.archived_at) || _notNil(study.canceled_at);

  const { sendRequest: addAlert } = useRequest({
    route: 'studies.alerts.settings.create',
    method: 'post',
    params: { studyId: study.id },
  });
  const { sendRequest: bulkDelete } = useRequest({
    route: 'studies.alerts.settings.bulk-delete',
    method: 'delete',
    params: { studyId: study.id },
  });

  const handleAddAlert = (alert: AnimalAlertSpecCreateOrUpdate) => {
    return (
      _isNotEmpty(alert.id)
        ? Http.put(apiRoute('studies.alerts.settings.update', { studyId: study.id, alertId: alert.id }), { ...alert })
        : addAlert(alert)
    )
      .then(() => {
        closeModal();
        return reloadAlerts();
      })
      .catch(() => errorToast('Could not create alert'));
  };
  const handleDeleteAlert = () => {
    const alertIdsToDelete = alerts.filter((alert, index) => selectedRows?.[index] ?? false).map(({ id }) => id);

    bulkDelete({ study_alert_ids: alertIdsToDelete })
      .then(() => {
        return reloadAlerts();
      })
      .catch(() => errorToast(`Could not delete alert${alertIdsToDelete.length > 1 ? 's' : ''}`));
  };

  const addOrUpdateAlert = (alert?: AnimalAlertV1) => {
    openModal('ADD_ALERT_FORM', {
      calculations,
      alert,
      onSubmit: handleAddAlert,
      onCancel: () => closeModal(),
    });
  };

  return (
    <>
      <div className="mb4">
        <h3 className="fw5 f5 pb2">Alerts</h3>
        <p className="f6 lh-copy ma0">
          Alerts are rules that can be set to alert users to a status change in an animal.{' '}
          <a
            href="https://help.benchling.com/hc/en-us/articles/20040160668173-Study-Alerts"
            target="_blank"
            rel="noopener noreferrer"
            className="dib link blue"
          >
            Read more.
          </a>
        </p>
      </div>
      <div className="ui-card">
        <div className="w-100 flex justify-between mt3">
          <AlertBulkActions selectedRows={selectedRows} handleDelete={handleDeleteAlert} />
          <Button
            className="relative mr3 br-pill"
            icon="add_new"
            paleBlue
            onClick={() => addOrUpdateAlert()}
            disabled={disabled}
          >
            <span>Add Alert</span>
          </Button>
        </div>
        <div>
          <AlertsTable
            alerts={alerts}
            disabled={disabled}
            calculations={calculations}
            onAlertClick={(alert) => addOrUpdateAlert(alert)}
            onAddAlertClick={() => addOrUpdateAlert()}
            selectedRows={selectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        </div>
      </div>
    </>
  );
};
