import type { ID } from '@/model/Common.model';
import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface CustomInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'data-tooltip-content'> {
  'data-tooltip-content': string | string[];
  'data-id'?: ID;
}

interface DebounceInputProps {
  inputProps: CustomInputProps;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const DebounceInput: FC<DebounceInputProps> = ({ inputProps, handleChange }) => {
  const [val, setVal] = useState<InputHTMLAttributes<HTMLInputElement>['value']>(inputProps.value || '');
  const [event, setEvent] = useState<ChangeEvent>();
  const debouncedFunction = useDebouncedCallback(
    (event) => {
      handleChange(event);
    },
    500,
    { maxWait: 2000 }
  );

  useEffect(() => {
    setVal(inputProps.value);
  }, [inputProps.value]);

  useEffect(() => {
    if (event) {
      debouncedFunction(event);
    }
  }, [val]);

  return (
    // We're incorrectly passing an array type somewhere
    // @ts-expect-error: mapped from ts-ignore
    <input
      {...inputProps}
      data-test-component="DebounceInput"
      data-test-element="input"
      type="text"
      value={val}
      onChange={(e) => {
        e.persist();
        setEvent(e);
        setVal(e.target.value);
      }}
    />
  );
};

export default DebounceInput;
