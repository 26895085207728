// @ts-nocheck: converted from JS

import { HotColumn, HotTable } from '@handsontable/react';
import { afterChangeIncludeSources } from '@/components/Colony/Colony.utils';
import AddMetaColumn from '@/components/Modals/AddMetaColumn/AddMetaColumn';
import Button from '@/components/UI/Button';
import type Handsontable from 'handsontable';
import type { CellChange, ChangeSource } from 'handsontable/common';
import 'handsontable/dist/handsontable.full.css';
import type { CellMeta } from 'handsontable/settings';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import { LegacyRef, ReactNode, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { defaultMetadataToColumnSettingMapper, registerHOTModules } from '@/support/hot';
import type { CustomValidation, SpreadSheetComponent, SpreadSheetError } from './SpreadSheet.model';
import './Spreadsheet.scss';
import { buildCustomValidators, createValidationError } from './SpreadSheet.utils';

registerHOTModules();

const SpreadSheet: React.FC<SpreadSheetComponent> = ({
  afterCreateRow,
  afterChange,
  afterRemoveRow,
  afterAddRow,
  beforeChange,
  afterValidation,
  data,
  settings,
  className,
  addCol = true,
  addRow,
  metadata,
  setMetadata,
  metadataToColumnSettingMapper,
  defaultNewRowParams,
  patchMetadataAfterSettingsUpdated,
  overlay,
  buttonClasses,
  maxRows = Infinity,
  autoAddRow = false,
  afterContextMenuHide = () => {},
  setInvalid,
  innerRef,
  greedyValidateRow,
  testPrefix = 'spreadsheet',
}) => {
  const { columns } = settings;
  const [open, setOpen] = useState<boolean>(false);

  const [cellErrors, setCellErrors] = useState<Record<string, SpreadSheetError>>({});

  const customValidators = useMemo<Record<string, CustomValidation>>(() => {
    return buildCustomValidators(settings.columns);
  }, [settings]);

  useEffect(() => {
    const hot = innerRef?.current?.hotInstance;

    validateAllCells(hot);

    if (innerRef && patchMetadataAfterSettingsUpdated && innerRef.current) {
      patchMetadataAfterSettingsUpdated();
    }
  }, [columns, data]);

  useEffect(() => {
    setInvalid?.(_isNotEmpty(cellErrors));
  }, [cellErrors]);

  const validateAllCells = (hot?: Handsontable | null) => {
    if (_notNil(settings.minSpareRows) && settings.minSpareRows > 0) {
      hot?.validateRows([...Array(data.length - settings.minSpareRows).keys()]);
    } else {
      hot?.validateCells();
    }
  };

  const addNewColumns = (metadataIdsToAdd: Array<string> = []) => {
    const updatedSettings = metadata
      ?.filter(({ id }) => metadataIdsToAdd.includes(id))
      .reduce(
        ({ colHeaders, columns }, mdToAdd) => {
          const { columnHeader, columnSettings } = (
            metadataToColumnSettingMapper ?? defaultMetadataToColumnSettingMapper
          )(mdToAdd);
          return {
            colHeaders: [...colHeaders, columnHeader],
            columns: [...columns, columnSettings],
          };
        },
        { colHeaders: [], columns: [] }
      );

    settings.columns = [...settings.columns, ...updatedSettings.columns];
    settings.colHeaders = [...settings.colHeaders, ...updatedSettings.colHeaders];

    const unusedMetadata = metadata.filter(
      ({ id: metadataId }) => !settings.columns.find(({ metaID: columnId }) => columnId === metadataId)
    );

    setMetadata?.(unusedMetadata);
    if (patchMetadataAfterSettingsUpdated) {
      patchMetadataAfterSettingsUpdated();
    }
  };

  const _afterCreateRow = (index: number, amount: number, source?: ChangeSource) => {
    const hot = innerRef?.current?.hotInstance;
    // if new row is auto added, scroll row into view
    if (source === 'auto' && hot && autoAddRow) {
      hot.selectCell(index, 0);
    }
    afterCreateRow?.call(null, index, amount, source);
  };

  const addNewRow = () => {
    const hot = innerRef?.current?.hotInstance;
    if (hot) {
      if (_isNotEmpty(defaultNewRowParams)) {
        const row = hot.countRows();
        hot.setDataAtCell(
          defaultNewRowParams.map((p) => [row, p.col, p.value]),
          undefined,
          undefined,
          'insert_row'
        );
      } else {
        hot.alter('insert_row', hot.countRows(), 1);
      }
    }
  };

  const _afterChange = (changes: CellChange[] | null, source: ChangeSource | 'insert_row'): void => {
    const hot = innerRef?.current?.hotInstance;

    if (afterChangeIncludeSources.includes(source) && changes) {
      patchMetadataAfterSettingsUpdated?.();
    }

    if (source === 'insert_row') {
      afterAddRow?.();
      hot?.validateRows(changes?.map((x) => x[0]));
    }

    changes?.forEach((change) => {
      if (greedyValidateRow) {
        hot?.validateRows([change[0]]);
      }

      hot?.validateColumns(
        customValidators?.[hot.colToProp(change[1])]?.linkedValidationColumns?.map((linkedColumn) =>
          hot.propToCol(linkedColumn)
        ) ?? []
      );
    });

    afterChange?.(changes, source);
  };

  const _afterRemoveRow = (index: number, amount: number, physicalRows: number[], source: ChangeSource | undefined) => {
    const hot = innerRef.current.hotInstance;
    setCellErrors(() => {
      if (Array.isArray(columns)) {
        physicalRows.forEach((row) => {
          columns.forEach((column) => {
            const currentColIndex = hot.propToCol(column.data);
            const cell = hot.getCell(row, currentColIndex);
            cell?.removeAttribute('data-tooltip-content');
            cell?.removeAttribute('data-tooltip-id');
            cell?.removeAttribute('data-type');
          });
        });
      }
      validateAllCells(hot);
      return {};
    });
    afterRemoveRow?.(source);
  };

  const _afterValidate = (
    isValid: boolean,
    value: ReactNode,
    row: number,
    prop: string | number,
    source: ChangeSource
  ) => {
    const hot = innerRef.current.hotInstance;
    if (_notNil(hot)) {
      const currentColIndex = hot.propToCol(prop);
      const cell = hot.getCell(row, currentColIndex);
      const errorKey = `${currentColIndex},${row + 1}`;
      if (!isValid) {
        const error = { value, rowNumber: row, column: prop.toString().replace('meta.', '') };
        const cellMeta: CellMeta = hot.getCellMeta(row, currentColIndex);
        cell?.setAttribute('data-tooltip-id', 'global-tooltip-id');
        cell?.setAttribute(
          'data-tooltip-content',
          createValidationError(error, cellMeta.type, customValidators[prop]?.validationErrorMessage)
        );
        cell?.setAttribute('data-type', 'error');
        setCellErrors((original: Record<string, SpreadSheetError>) => ({ ...original, [errorKey]: error }));
      } else {
        cell?.removeAttribute('data-tooltip-id');
        cell?.removeAttribute('data-tooltip-content');
        cell?.removeAttribute('data-type');
        setCellErrors((original: Record<string, SpreadSheetError>) => {
          delete original[errorKey];
          return { ...original };
        });
      }

      afterValidation?.(isValid, value, row, prop, source);
    }
  };

  const MemoizedHotTable = useMemo(
    () => (
      <HotTable
        settings={settings}
        data={data}
        licenseKey={AppConfig.handsOnTableLicence}
        fillHandle={{ autoInsertRow: false }}
        ref={innerRef as LegacyRef<HotTable>}
        beforeChange={beforeChange}
        afterChange={_afterChange}
        afterValidate={_afterValidate}
        afterCreateRow={_afterCreateRow}
        afterRemoveRow={_afterRemoveRow}
        maxRows={maxRows}
        afterContextMenuHide={afterContextMenuHide}
      >
        {Array.isArray(settings.columns) &&
          settings.columns.map((settings, index) => {
            const { customEditor: CustomEditor } = settings;

            return (
              <HotColumn data={settings.data} settings={settings} key={index}>
                {CustomEditor && <CustomEditor hot-editor hot-renderer />}
              </HotColumn>
            );
          })}
      </HotTable>
    ),
    [data, columns, maxRows]
  );

  return (
    <div
      className={`hotSpreadSheet ${className}`}
      data-testid={`${testPrefix}__hot`}
      data-test-component="Spreadsheet"
      data-test-element="container"
    >
      <span className={`${buttonClasses || ''}`} style={{ flexDirection: 'row' }}>
        <span className="ml-auto">
          {addCol && (
            <Button
              onClick={() => setOpen(!open)}
              className="mb3 self-end br-pill"
              icon="add_new"
              paleBlue
              disabled={_isNotEmpty(overlay) || _isEmpty(metadata)}
              tooltip={_isEmpty(metadata) ? 'No metadata available' : undefined}
              testId={`${testPrefix}__add-metadata`}
            >
              Add metadata
            </Button>
          )}
        </span>
      </span>
      {open && (
        <div className="flex justify-center pb2">
          <ReactModal
            isOpen={open}
            className="ui-modal"
            style={{
              overlay: { backgroundColor: 'rgba(00,00,00,.5)' },
              content: { maxHeight: '100vh' },
            }}
            ariaHideApp={false}
          >
            <AddMetaColumn
              metadata={metadata}
              callback={(columns) => {
                if (columns) {
                  addNewColumns(columns);
                }
                setOpen(false);
              }}
            />
          </ReactModal>
        </div>
      )}
      <div
        className="ui-card"
        style={{ display: overlay ? 'none' : 'block' }}
        data-testid={`${testPrefix}__hot-table`}
        data-test-component="Spreadsheet"
        data-test-element="hot-container"
      >
        {MemoizedHotTable}
        {_isNotEmpty(addRow) && (
          <div className="pa3 bt b--moon-gray">
            <Button
              onClick={addNewRow}
              className="br-pill"
              icon="add_new"
              paleBlue
              disabled={addRow.tooltip || overlay}
              tooltip={addRow.tooltip}
            >
              <span>{addRow.title}</span>
            </Button>
          </div>
        )}
      </div>
      {overlay && (
        <div className={'tableHolder'}>
          <div className="overlay">{overlay}</div>
        </div>
      )}
    </div>
  );
};

export default SpreadSheet;
