// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { _isEmpty } from '@/littledash';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import './SelectStudyRequests.scss';

const SelectStudyRequests = ({ handleCallback }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);

  const {
    collection: requests,
    collectionLoading: requestsLoading,
    collectionError: apiError,
  } = useFetchCollection({
    collectionType: 'requestTemplates',
    queryParams: { perPage: -1 },
  });

  const sections = useMemo(() => [{ items: requests || [] }], [requests]);

  if (requestsLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <div className="center mw6 ui-card" data-test-component="SelectStudyRequests" data-test-element="container">
      <div className="pa3 bb b--moon-gray">
        <h3 className="normal lh-title f4 mb0">Create a new request</h3>
      </div>
      {apiError && <ApiErrorBanner error={apiError} />}
      {_isEmpty(requests) ? (
        <Banner dismiss={false} info className="mw6 mb3">
          <h3 className="lh-title normal f5 mb2">No request templates have been created</h3>
          <p className="lh-copy f6">Request templates can be created by Admins in the Team section.</p>
        </Banner>
      ) : (
        <SearchSelect
          sections={sections}
          selected={selected}
          setSelected={setSelected}
          sort
          sortAccessor={'name'}
          maxSelections={1}
        />
      )}
      <div className="pa3 bt b--moon-gray" data-test-element="action-container">
        <Button
          onClick={() => handleCallback(selected[0])}
          testId="Next-Button"
          disabled={apiError || _isEmpty(selected)}
          className="mr3"
        >
          Next
        </Button>
        <Button plain onClick={() => dispatch({ type: 'CLOSE_MODAL' })} testId="Cancel-Button">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SelectStudyRequests;
