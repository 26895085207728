// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import SpreadSheet from '@/components/UI/SpreadSheet';
import { preventNumberScroll } from '@/helpers';
import { _get, _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import type HotTable from '@handsontable/react';
import { ErrorMessage } from '@hookform/error-message';
import type { ChangeSource } from 'handsontable/common';
import { Fragment, useEffect, useRef, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import { getMetaData } from '../Colony.utils';

interface CagesProps {
  state: State;
  dispatch: () => void;
  study: Study;
}

const Cages: React.FC<CagesProps> = ({ state, dispatch, study }) => {
  const {
    cages: { settings, data: cageData, metadata, cellErrors, overlay, cageCount, maxRows },
  } = state;

  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const MAX_CAGES = 100;
  const ref = useRef<HotTable>();
  const { register, handleSubmit, errors, setError } = useForm({});

  const newOverlay = (
    <>
      <h3 className="mb2">Cages and metadata will appear here</h3>
      <p style={{ maxWidth: '520px', alignSelf: 'center' }}>
        Enter the number of cages you will be adding in the text box above. You can continue to add additional cages the
        same way afterwards.
      </p>
    </>
  );

  useEffect(() => {
    getMetaData('collection_meta')
      .then(({ data: { data } }) => {
        updateGlossaries(data);
        if (settings?.columns) {
          const metadataColumnIds = settings.columns.filter((c) => c.metaID).map((c) => c.metaID);
          const updatedMetadata = data.filter((d) => !metadataColumnIds.includes(d.id));
          dispatch({ type: 'stepReady' });
          return updateMetadata(updatedMetadata);
        }
        dispatch({ type: 'stepReady' });
        return updateMetadata(data);
      })
      .catch((error) => updateApiError(error));
  }, []);

  useEffect(() => {
    if (_isEmpty(cageData)) {
      updateOverlay(newOverlay);
    }
  }, [cageData]);

  const updateMetadata = (metadata) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: { metadata },
    });
  };
  const updateGlossaries = (metaGlossaries) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: { metaGlossaries },
    });
  };
  const updateOverlay = (overlay) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: { overlay },
    });
  };
  const updateApiError = (apiError) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: apiError,
    });
  };
  const updateMaxRows = (maxRows) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: { maxRows },
    });
  };
  const updateCellErrors = (cellErrors) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: { cellErrors },
    });
  };
  const updateCageCount = (cageCount) => {
    dispatch({
      type: 'update',
      id: 'cages',
      data: { cageCount },
    });
  };

  useEffect(() => {
    if (maxRows > 0 && maxRows > cageData.length) {
      delete cellErrors['no-cage'];
      updateCellErrors({ ...cellErrors });
      updateOverlay('');
      const hot = ref.current.hotInstance;
      const col = cageData.length + 1;
      if (isInvalid) {
        applyInvalidError();
      }

      let cageNameNumber = cageData.length ? cageCount : 1;

      if (_isEmpty(cageData) && _notNil(study?.counts?.cages_with_trashed)) {
        cageNameNumber += study.counts.cages_with_trashed;
      }

      const rowAmountToAdd = maxRows - cageData.length;

      // TODO: Would be good to get loadData() or updateSettings(data: []) working here.
      hot?.alter('insert_row', col, rowAmountToAdd, 'insertRows');
      let newCageID = cageData.length - Number(rowAmountToAdd) + 1;
      for (let i = 0; i < Number(rowAmountToAdd); i++) {
        hot?.setDataAtCell(
          [
            [newCageID - 1, 0, cageNameNumber],
            [newCageID - 1, 1, `Cage ${cageNameNumber}`],
          ],
          `${newCageID === cageData.length ? 'setLastCageID' : 'setCageID'}`
        );
        newCageID++;
        cageNameNumber++;
      }
      updateCageCount(cageNameNumber);
    }
  }, [maxRows]);

  const onSubmit = (rowAmountToAdd) => {
    if (_get(rowAmountToAdd, 'cage_amount') && cageData.length + Number(rowAmountToAdd.cage_amount) > MAX_CAGES) {
      setError('cage_amount', {
        type: 'manual',
        message: `Cannot add more than ${MAX_CAGES} cages`,
      });
      return false;
    }
    updateMaxRows(Number(maxRows) + Number(rowAmountToAdd.cage_amount));
  };

  const afterContextMenuHide = () => {
    if (maxRows >= cageData.length) {
      updateMaxRows(cageData.length);
    }
  };

  useEffect(() => {
    if (isInvalid) {
      applyInvalidError();
    } else {
      delete cellErrors['auto-validation'];
      updateCellErrors({ ...cellErrors });
    }
  }, [isInvalid, maxRows]);

  const applyInvalidError = () => updateCellErrors({ ...cellErrors, 'auto-validation': 'There are invalid cells' });

  const afterRemoveRow = (source: ChangeSource | undefined) => {
    if (_isEmpty(cageData)) {
      updateOverlay(newOverlay);
    }
    const hot = ref.current?.hotInstance;
    const sourceData = hot?.getSourceData();

    if (_isEmpty(sourceData)) {
      const updatedErrors = {};
      updatedErrors['no-cage'] = 'You must supply at least 1 cage';
      updateCellErrors({ ...updatedErrors });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-row items-start flex flex-wrap mb4">
          <p className="lh-copy set-width">
            <span>Enter the amount of cages, animals per cage and any metadata to continue. </span>
            <a
              href="https://help.benchling.com/hc/en-us/articles/21952582878093-Registering-Animals-in-the-Vivarium"
              target="_blank"
              rel="noopener noreferrer"
              className="dib link blue"
            >
              Read more.
            </a>
          </p>
        </div>
        <span className="near-black">How many more cages would you like to add?</span>
        <div className="flex db mt2 w2">
          <input
            type="number"
            onWheel={preventNumberScroll}
            name="cage_amount"
            placeholder="5"
            defaultValue="5"
            ref={register({
              required: 'You must supply an numrical amount of cages to create.',
              pattern: {
                value: '/[0-9]*/i',
                message: 'Please only input numerical values.',
              },
              max: {
                value: `${MAX_CAGES}`,
                message: `You have exceeded the maximum cage limit of ${MAX_CAGES}.`,
              },
            })}
          />
          <Button submit className="ml3" disabled={_isNotEmpty(errors)} plain>
            Add Cages
          </Button>
        </div>
        <ErrorMessage
          errors={errors}
          name="cage_amount"
          render={({ message }) => <p className="f6 red db">{message}</p>}
        />
      </form>
      {_isNotEmpty(settings) && (
        <div className="ow-spreadsheet-styles">
          <SpreadSheet
            data={cageData}
            settings={settings}
            innerRef={ref}
            className="cageCreation"
            metadata={metadata}
            setMetadata={updateMetadata}
            buttonClasses={'absolute-sheet-buttons'}
            overlay={overlay}
            afterRemoveRow={afterRemoveRow}
            maxRows={maxRows}
            afterContextMenuHide={afterContextMenuHide}
            setInvalid={setIsInvalid}
          />
        </div>
      )}
    </Fragment>
  );
};
export default Cages;
