// This file was generated by lezer-generator. You probably shouldn't edit it.
export const Formula = 1,
  Number = 2,
  Variable = 3,
  NumericExpression = 4,
  NumericOperator = 5,
  MultiplicationOperator = 6,
  DivisionOperator = 7,
  AdditionOperator = 8,
  SubtractionOperator = 9,
  OpeningBracket = 10,
  ClosingBracket = 11,
  PiFunction = 12,
  Name = 14,
  PI = 15,
  AverageFunction = 16,
  AVERAGE = 18,
  AverageFunctionInput = 19,
  Atom = 20,
  Comma = 21;
