import { _isNil } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils.ts';
import type { DataTableColumnType, DataTableValidatorFunction, DataTableValidatorResponse } from '../DataTable.model';

const numericValidator: DataTableValidatorFunction = (value) => {
  if (_isNil(value)) {
    return { valid: true };
  }
  const valueAsNumber = Number(value);
  if (isNaN(valueAsNumber)) {
    return { valid: false, error: `[${value}] is not a valid number` };
  }
  if (!Number.isFinite(valueAsNumber)) {
    return { valid: false, error: `[${value}] is not finite` };
  }
  return { valid: true };
};

export class DataTableValidator {
  static readonly #validators: Record<DataTableColumnType, DataTableValidatorFunction> = {
    number: numericValidator,
    measurement: numericValidator,
    formula: (value) => ({ valid: true }),
    observation: (value) => ({ valid: true }),
    text: (value) => {
      if ((value?.length ?? 0) > 255) {
        return { valid: false, error: 'Max length is 255 characters' };
      }
      return { valid: true };
    },
    timestamp: (value) => {
      if (_isNil(value)) {
        return { valid: true };
      }
      if (!DateUtils.isValidDateTime(value)) {
        return { valid: false, error: `[${value}] is not a valid timestamp` };
      }
      return { valid: true };
    },
    timestampBaseline: (value) => {
      if (_isNil(value)) {
        return { valid: true };
      }
      if (!DateUtils.isValidDateTime(value)) {
        return { valid: false, error: `[${value}] is not a valid timestamp` };
      }
      return { valid: true };
    },
    timestampBaselineRelative: (value) => {
      return { valid: true };
    },
  };

  public static validate(type: DataTableColumnType | undefined, value: string | null): DataTableValidatorResponse {
    if (_isNil(type)) {
      return { valid: false, error: 'invalid column type' };
    }
    return (
      DataTableValidator.#validators?.[type]?.(value) ?? {
        valid: false,
        error: `[${type}] is not a recognised column type`,
      }
    );
  }
}
