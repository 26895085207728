import { FormulaError } from '@/components/UI/FormulaInput/FormulaError.tsx';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash.ts';
import { Nullable } from '@/model/Common.model.ts';
import { FormulaDsl, FormulaDslLint, type Variable } from '@/utils/FormulaDsl.ts';
import { autocompletion, closeBrackets, closeBracketsKeymap, completionKeymap } from '@codemirror/autocomplete';
import { defaultKeymap, history, historyKeymap } from '@codemirror/commands';
import {
  bracketMatching,
  foldGutter,
  foldKeymap,
  HighlightStyle,
  indentOnInput,
  syntaxHighlighting,
} from '@codemirror/language';
import { type Diagnostic, linter, lintKeymap } from '@codemirror/lint';
import { highlightSelectionMatches } from '@codemirror/search';
import { Compartment, EditorState } from '@codemirror/state';
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLine,
  highlightSpecialChars,
  keymap,
  placeholder,
  rectangularSelection,
  type ViewUpdate,
} from '@codemirror/view';
import { tags } from '@lezer/highlight';
import cn from 'classnames';
import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import './FormulaInput.scss';

const baseSetup = () => [
  highlightSpecialChars(),
  history(),
  foldGutter(),
  drawSelection(),
  dropCursor(),
  indentOnInput(),
  bracketMatching(),
  closeBrackets(),
  autocompletion({ aboveCursor: true }),
  rectangularSelection(),
  crosshairCursor(),
  highlightActiveLine(),
  highlightSelectionMatches(),
  placeholder('Formula'),
  syntaxHighlighting(
    HighlightStyle.define([
      { tag: tags.operator, color: '#97A6B6' },
      { tag: tags.number, color: '#FF6300' },
      { tag: tags.variableName, color: '#5E2CA5' },
    ])
  ),
  keymap.of([
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...historyKeymap,
    ...foldKeymap,
    ...completionKeymap,
    ...lintKeymap,
  ]),
];

interface FormulaInputProps {
  value: Nullable<string>;
  invalid: boolean;
  variables: Record<`${string}`, string>;
  onChange: (value: string) => void;
  onBlur?: () => void;
  caretPositionRef: MutableRefObject<number>;
}

export const FormulaInput: FC<FormulaInputProps> = ({
  value,
  variables,
  onChange,
  onBlur,
  caretPositionRef,
  invalid,
}) => {
  const formulaEditorRef = useRef<HTMLDivElement>(null);
  const [formulaInputState, setFormulaInputState] = useState<{
    view: EditorView;
    state: EditorState;
    language: Compartment;
    linter: Compartment;
  } | null>(null);
  const [formulaErrorMessages, updateFormulaErrorMessages] = useState<Array<string>>([]);

  const diagnosticCallback = useCallback(
    async (diagnostics: Array<Diagnostic>) => {
      updateFormulaErrorMessages((prevState) => {
        const updatedMessages = diagnostics.map((diagnostic) => diagnostic.message);
        if (_isEmpty(prevState) && _isEmpty(updatedMessages)) {
          return prevState;
        }
        return updatedMessages;
      });
    },
    [updateFormulaErrorMessages]
  );

  useEffect(() => {
    if (_notNil(formulaEditorRef.current)) {
      const updateListener = (update: ViewUpdate) => {
        caretPositionRef.current = update.state.selection.main.head;
        if (update.docChanged) {
          const docUpdate = update.state.doc.toString();
          onChange(docUpdate);
        }
      };
      const language = new Compartment();
      const lint = new Compartment();
      const state = EditorState.create({
        doc: value ?? '',
        extensions: [
          EditorState.transactionFilter.of((tr) => (tr.newDoc.lines > 1 ? [] : tr)),
          EditorView.updateListener.of(updateListener),
          EditorView.domEventHandlers({
            blur: () => onBlur?.(),
          }),
          baseSetup(),
          language.of(FormulaDsl({ type: 'preset', variables })),
          lint.of(
            linter(
              FormulaDslLint(new Set<Variable>(Object.keys(variables ?? {}) as Array<Variable>), diagnosticCallback)
            )
          ),
        ],
      });
      const view = new EditorView({
        parent: formulaEditorRef.current,
        state,
      });
      setFormulaInputState({ view, state, language, linter: lint });
      view.focus();

      return () => {
        setFormulaInputState(null);
        view.destroy();
      };
    }
  }, [formulaEditorRef]);

  useEffect(() => {
    if (_notNil(formulaInputState) && _notNil(variables)) {
      formulaInputState.view.dispatch({
        effects: [
          formulaInputState.language.reconfigure(FormulaDsl({ type: 'preset', variables })),
          formulaInputState.linter.reconfigure(
            linter(
              FormulaDslLint(new Set<Variable>(Object.keys(variables ?? {}) as Array<Variable>), diagnosticCallback)
            )
          ),
        ],
      });
    }
  }, [variables, formulaInputState]);

  useEffect(() => {
    if (_notNil(formulaInputState) && _notNil(value)) {
      formulaInputState.view.focus();
      const formula = formulaInputState.view.state.doc.toString();
      formulaInputState.view.focus();
      const caretPosition = formulaInputState.view.state.selection.main.head + (value.length - formula.length);

      formulaInputState.view.dispatch({
        changes: { from: 0, to: formula.length, insert: value },
        selection: { anchor: caretPosition, head: caretPosition },
      });
    }
  }, [value, formulaInputState]);

  return (
    <div
      className="flex flex-column formula_input_wrapper"
      data-test-component="FormulaInput"
      data-test-element="container"
    >
      <div
        data-test-element="formula-input-container"
        className={cn('formula_input_container', { formula_input_invalid: invalid })}
        ref={formulaEditorRef}
      />
      {_isNotEmpty(formulaErrorMessages) && <FormulaError messages={formulaErrorMessages} />}
    </div>
  );
};
