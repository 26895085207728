import type { Sample } from '@/model/Sample.model';

interface SamplesState {
  selectedSamples: Array<Sample>;
}

type SamplesAction = SamplesState & { type: string };

const INITIAL_STATE: SamplesState = {
  selectedSamples: [],
};

const applySelectedSamples = (state: SamplesState, action: SamplesAction) => ({
  ...state,
  selectedSamples: action.selectedSamples,
});

function samplesReducer(state = INITIAL_STATE, action: SamplesAction) {
  switch (action.type) {
    case 'SET_SELECTED_SAMPLES': {
      return applySelectedSamples(state, action);
    }
    default:
      return state;
  }
}

export default samplesReducer;
