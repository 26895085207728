export class ApplicationEvents {
  static readonly #eventEmitter = new EventTarget();

  static authenticated() {
    this.#eventEmitter.dispatchEvent(new CustomEvent('authenticated'));
  }

  static onAuthenticated(listener: (event: Event) => void, signal: AbortSignal) {
    this.#eventEmitter.addEventListener('authenticated', listener, { signal, passive: true });
  }
}
