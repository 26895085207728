// @ts-nocheck: converted from JS

import { _isNil, _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import './MultiSelectEditor.scss';
import InVivoError from '@/model/InVivoError.ts';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { api } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import Handsontable from 'handsontable';
import { StrictMode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import SyncSelect from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

const MultiSelect = ({ options, defaultValue, optionsLookupId, td, isMultiSelect, onChange }) => {
  const tdRect = td.getBoundingClientRect();
  const { newAbortController } = useAbortController();
  const selectRef = useRef();

  useEffect(() => {
    selectRef?.current?.focus();
  }, [selectRef]);

  const loadOptions = async (query, loadedOptions, { page, perPage }) => {
    try {
      const url = api('metadata.options.show', { id: optionsLookupId });
      const queryParams = new URLSearchParams({ page, perPage });

      const trimmedQuery = (query ?? '').trim();
      if (_isNotEmpty(trimmedQuery)) {
        queryParams.set('query', trimmedQuery);
      }

      const {
        data: {
          data,
          links: { next },
        },
      } = await Http.get(`${url}?${queryParams.toString()}`, {
        signal: newAbortController().signal,
      });

      const hasMore = _notNil(next);
      const options = data.map(({ value }) => ({
        value: isMultiSelect ? encodeURIComponent(value) : value,
        label: value,
      }));
      let nextPage = page;
      let nextPerPage = perPage;
      if (hasMore) {
        const nextUrl = new URL(next);
        nextPage = nextUrl.searchParams.get('page');
        nextPerPage = nextUrl.searchParams.get('perPage');
      }

      return {
        options,
        hasMore,
        additional: { page: nextPage, perPage: nextPerPage },
      };
    } catch (error) {
      if (notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError('Could not load metadata options', { cause: error, slug: 'multi-select-options-load' })
        );
      }
    }
    return { options: [], hasMore: false, additional: { page, perPage } };
  };

  return (
    <div
      style={{ width: `${tdRect.width}px`, height: `${tdRect.height}px` }}
      data-ismulti={isMultiSelect}
      data-test-component="HotMultiselect"
      data-test-element="container"
    >
      {_notNil(optionsLookupId) ? (
        <AsyncPaginate
          selectRef={selectRef}
          classNamePrefix="HoT-Multiselect"
          value={defaultValue}
          additional={{ page: 1, perPage: 10 }}
          loadOptions={loadOptions}
          isMulti={isMultiSelect}
          closeMenuOnSelect={false}
          autoFocus={true}
          defaultOptions
          menuIsOpen={true}
          cacheOptions={false}
          onChange={onChange}
          debounceTimeout={300}
        />
      ) : (
        <SyncSelect
          ref={selectRef}
          classNamePrefix="HoT-Multiselect"
          defaultValue={defaultValue}
          options={options}
          isMulti={isMultiSelect}
          menuIsOpen={true}
          autoFocus={true}
          closeMenuOnSelect={false}
          onChange={(args) => onChange(args)}
        />
      )}
    </div>
  );
};

const valueToChip = (value) => {
  const chip = document.createElement('span');
  chip.classList.add('multi-select-render-selected-option-chip');
  chip.innerText = safelyDecodeURIComponent(value);
  return chip;
};

/**
 * This is some HandsonTable madness to add single click to the cell dropdown arrow
 *
 * Based on HandsonTable DatePicker see <a href="https://github.com/handsontable/handsontable/blob/master/handsontable/src/renderers/autocompleteRenderer/autocompleteRenderer.js#L42-L58">here</a>
 *
 * @private
 * @param hotInstance
 * @param td
 * @param row
 * @param column
 */
const addDropdownIconMousedownHandler = (hotInstance, td, row, column) => {
  if (_isNil(hotInstance.multiSelectDropdownArrowListener)) {
    const eventManager = new Handsontable.EventManager(hotInstance);
    hotInstance.multiSelectDropdownArrowListener = (event) => {
      if (Handsontable.dom.hasClass(event.target, 'multiSelectDropdownArrow')) {
        // This is an internal HoT thing ... If single click breaks look here first
        hotInstance.view?._wt?.getSetting(
          'onCellDblClick',
          null,
          // TODO new CellCoords(row, column),
          { row, column },
          td
        );
      }
    };
    eventManager.addEventListener(hotInstance.rootElement, 'mousedown', hotInstance.multiSelectDropdownArrowListener);
    hotInstance.addHookOnce('afterDestroy', () => eventManager.destroy());
  }
};

const multiSelectRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.call(null, hotInstance, td, row, column, prop, value, cellProperties);
  Handsontable.dom.empty(td);
  const isMultiSelect = cellProperties?.isMultiSelect ?? true;
  const dropdownIconContainer = document.createElement('div');
  dropdownIconContainer.classList.add('multiSelectDropdownArrow', 'customEditorArrow');
  dropdownIconContainer.innerText = '▼';

  if (_isNotEmpty(value)) {
    const msContainer = document.createElement('div');
    msContainer.classList.add('multiSelectContainer');
    const msChipsContainer = document.createElement('div');
    msChipsContainer.classList.add('multiSelectChips');
    msContainer.appendChild(msChipsContainer);
    if (isMultiSelect) {
      value.split(',').forEach((v) => {
        const chipValue = v.trim();
        if (_isNotEmpty(chipValue)) {
          msChipsContainer.appendChild(valueToChip(chipValue));
        }
      });
    } else {
      msChipsContainer.appendChild(valueToChip(value));
    }
    msContainer.appendChild(dropdownIconContainer);
    td.appendChild(msContainer);
  } else {
    td.appendChild(dropdownIconContainer);
  }
  addDropdownIconMousedownHandler(hotInstance, td, row, column);
};

export class MultiSelectEditor extends Handsontable.editors.TextEditor {
  static type = 'multi-select';

  static register(type = MultiSelectEditor.type) {
    Handsontable.cellTypes.registerCellType(type, {
      editor: MultiSelectEditor,
      renderer: multiSelectRenderer,
      validator: (value, callback) => callback(true),
    });
  }

  static validatorFactory(options, isMultiSelect, min = 0, max = Infinity) {
    return (value, callback) => {
      const selectedOptions = isMultiSelect
        ? String(value ?? '')
            .split(',')
            .filter(_isNotEmpty)
        : [String(value ?? '')].filter(_isNotEmpty);
      if (
        selectedOptions.length >= min &&
        selectedOptions.length <= max &&
        !selectedOptions.some((option) => !options.includes(option))
      ) {
        callback(true);
      } else {
        callback(false);
      }
    };
  }

  static lookupValidatorFactory(isMultiSelect, min = 0, max = Infinity) {
    return (value, callback) => {
      const selectedOptions = isMultiSelect
        ? String(value ?? '')
            .split(',')
            .filter(_isNotEmpty)
        : [String(value ?? '')].filter(_isNotEmpty);
      if (selectedOptions.length >= min && selectedOptions.length <= max) {
        callback(true);
      } else {
        callback(false);
      }
    };
  }

  displayed = false;

  init() {
    super.init();
    this.instance.addHook('afterDestroy', () => this.destroyElements());
  }

  createElements() {
    super.createElements();
    this.TEXTAREA.style.fontSize = '1px';
    this.TEXTAREA.style.opacity = '0';

    this.multiSelectContainer = this.hot.rootDocument.createElement('div');
    this.multiSelectContainer.classList.add('multi-select-container');
    this.multiSelectContainer.setAttribute('data-testid', 'hot-multi-select-container');

    this.multiSelectContainer.style.display = 'none';

    this.multiSelectOverlay = this.hot.rootDocument.createElement('div');
    this.multiSelectOverlay.classList.add('multi-select-overlay');
    this.multiSelectOverlay.setAttribute('data-testid', 'hot-multi-select-overlay');

    this.multiSelectContainer.appendChild(this.multiSelectOverlay);

    this.hot.rootDocument.body.appendChild(this.multiSelectContainer);

    const handleMouseDown = (event: MouseEvent) => {
      if (_notNil(this.multiSelectContainer) && event.target === this.multiSelectContainer) {
        this.finishEditing(false, false, () => {
          const { row, col } = this.cellProperties;
          this.hot.selectCell(row, col);
        });
      } else {
        event.stopImmediatePropagation();
      }
    };

    const handleClick = (_) => {
      const isMultiSelect = this.multiSelectOverlay?.firstChild?.getAttribute('data-ismulti') === 'true';
      if (!isMultiSelect) {
        const { row, col } = this.cellProperties;
        this.hot.selectCell(row, col);
      }
    };

    const handleKeyDown = (e) => {
      const isMultiSelect = this.multiSelectOverlay?.firstChild?.getAttribute('data-ismulti') === 'true';
      const { row, col } = this.cellProperties;
      if (isMultiSelect) {
        if (e.code === 'Escape') {
          this.hot.selectCell(row, col);
        }
      } else {
        switch (e.code) {
          case 'Enter':
          case 'Escape':
            this.hot.selectCell(row, col);
            break;
        }
      }
      e.stopPropagation();
    };

    this.multiSelectContainer.addEventListener('mousedown', handleMouseDown, {
      passive: false,
    });
    this.multiSelectContainer.addEventListener('click', handleClick, {
      passive: true,
    });
    this.multiSelectContainer.addEventListener('keydown', handleKeyDown);

    this.hot.addHookOnce('afterDestroy', () => {
      this.multiSelectContainer.removeEventListener('mousedown', handleMouseDown);
      this.multiSelectContainer.removeEventListener('click', handleClick);
      this.multiSelectContainer.removeEventListener('keydown', handleKeyDown);
    });
  }

  render() {
    if (
      this.isOpened() &&
      _notNil(this.multiSelectOverlay) &&
      _notNil(this.cellProperties) &&
      this.multiSelectContainer?.style?.display === 'block'
    ) {
      const isMultiSelect = this.cellProperties?.isMultiSelect ?? true;
      const options =
        this.cellProperties?.options?.filter(_isNotEmpty)?.map((v) => ({
          value: v,
          label: isMultiSelect ? safelyDecodeURIComponent(v) : v,
        })) ?? [];
      const rawValue = this.getValue();
      const defaultValue = isMultiSelect
        ? rawValue
            .split(',')
            .filter(_isNotEmpty)
            .map((v) => ({ value: v, label: safelyDecodeURIComponent(v) }))
        : [{ value: rawValue, label: rawValue }];

      const optionsLookupId = this.cellProperties?.optionsLookupId ?? null;
      const handleOnChange = (value) => {
        if (isMultiSelect) {
          this.setValue(
            value
              .map(({ value }) => value)
              .filter(_isNotEmpty)
              .join(',')
          );
        } else {
          this.setValue(value?.value ?? null);
          this.finishEditing();
        }
      };
      if (_isNil(this.root)) {
        this.root = ReactDOM.createRoot(this.multiSelectOverlay);
      }
      this.root.render(
        <StrictMode>
          <MultiSelect
            options={options}
            defaultValue={defaultValue}
            optionsLookupId={optionsLookupId}
            td={this.TD}
            isMultiSelect={isMultiSelect}
            onChange={handleOnChange}
          />
        </StrictMode>
      );
    }
  }

  destroyElements() {
    Handsontable.dom.empty(this.multiSelectContainer);
    this.multiSelectContainer.remove();
  }

  finishEditing(restoreOriginalValue = false, ctrlDown = false, callback = null) {
    if (restoreOriginalValue && _notNil(this.originalValue)) {
      this.setValue(this.originalValue);
    }
    super.finishEditing(restoreOriginalValue, ctrlDown, callback);
  }

  setValue(newValue) {
    super.setValue(newValue);
    this.render();
  }

  getValue() {
    return super.getValue() ?? '';
  }

  open(event = null) {
    super.open();
    this.display();
  }

  close() {
    this.display(false);
    super.close();
  }

  isOpened() {
    return this.displayed;
  }

  display(display = true) {
    this.displayed = display;
    if (display) {
      const offset = this.TD.getBoundingClientRect();
      this.multiSelectOverlay.style.top = `${this.hot.rootWindow.scrollY + offset.top}px`;
      this.multiSelectOverlay.style.left = `${this.hot.rootWindow.scrollX + offset.left}px`;
      this.TEXTAREA.style.width = `${offset.width}px`;
      this.TEXTAREA.style.height = `${offset.height}px`;
      this.multiSelectContainer.style.display = 'block';
      this.render();
    } else {
      this.multiSelectContainer.style.display = 'none';
      window.requestAnimationFrame(() => {
        this.root?.unmount();
        this.root = null;
        Handsontable.dom.empty(this.multiSelectOverlay);
      });
    }
  }

  focus() {}
}
