import { ErrorMessage } from '@hookform/error-message';
import type { FC, ReactNode } from 'react';
import type { ValidationRule } from 'react-hook-form@latest';
import { useFormContext } from 'react-hook-form@latest';

interface FormFieldProps {
  name: string;
  label: string;
  hint?: string;
  children: ReactNode | Array<ReactNode>;
  required?: ValidationRule<boolean>;
  testPrefix?: string;
  testId?: string;
}

const FormField: FC<FormFieldProps> = ({
  children,
  name,
  label,
  hint,
  required,
  testPrefix = 'form-field',
  testId,
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <div data-test-component="FormField" data-test-element="container" data-test-key={name} data-testid={testId}>
      {label && (
        <label htmlFor={name} data-testid={`${testPrefix}--label`} data-test-element="label-container">
          {label}
          {required && ' (required)'}
        </label>
      )}
      {hint && (
        <div className="dark-gray mb2 f6 lh-copy" data-test-element="hint-container">
          {hint}
        </div>
      )}
      {children}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <small className="red db pt2">{message}</small>}
      />
    </div>
  );
};

export default FormField;
