// @ts-nocheck: converted from JS

import GroupLabel from '@/components/UI/GroupLabel';
import Link from '@/components/UI/Link';
import UserAvatar from '@/components/UI/UserAvatar';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { isDead } from '@/constants/utils';
import { web as webRoutes } from '@/support/route';
import { observationFiltersToTitle } from '@/referenceData/study/observations';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';

const unFilteredCols = ['created_by', 'text'];

export const columns = (studyId) => [
  {
    id: 'animal',
    Header: 'Animal',
    accessor: 'animal.name',
    sortBy: 'animal',
    isVisible: true,
    Cell: ({ row: { original } }) => (
      <Link
        className="link"
        to={webRoutes('animals.show', {
          id: studyId,
          subject_id: original.animal.id,
        })}
      >
        {original.animal.name}
      </Link>
    ),
  },
  {
    id: 'cage',
    Header: 'Cage',
    accessor: 'cage.name',
    sortBy: 'cage',
    isVisible: true,
    Cell: ({ row: { original } }) => isDead(original),
  },
  {
    id: 'study_group',
    Header: 'Study Group',
    isVisible: true,
    sortBy: 'study_group',
    accessor: 'study_group.name',
    Cell: ({
      row: {
        original: { study_group: group },
      },
    }) => <GroupLabel group={group} />,
  },
  {
    id: 'observation',
    Header: 'Observation',
    isVisible: true,
    sortBy: 'observation',
    accessor: 'title',
  },
  {
    id: 'score',
    Header: 'Score',
    accessor: 'value',
    isVisible: true,
    sortBy: 'score',
  },
  {
    id: 'comment',
    Header: 'Comment',
    accessor: 'text',
    isVisible: true,
    sortBy: 'comments',
    Cell: ({
      row: {
        original: { text },
      },
    }) => text || '-',
  },
  {
    id: 'recorded',
    Header: 'Recorded',
    accessor: 'observed_at',
    sortBy: 'recorded',
    isVisible: true,
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.observed_at} />,
  },
  {
    id: 'recorded_by',
    Header: 'Recorded by',
    isVisible: true,
    sortBy: 'recorded_by',
    accessor: 'created_by',
    Cell: ({
      row: {
        original: { created_by: createdBy },
      },
    }) => <UserAvatar user={createdBy} className="ml1 dib" tooltip={createdBy.name} />,
  },
];

export const formatFilterOptions = (filters) =>
  filters
    .filter(({ value }) => !unFilteredCols.includes(value))
    .map(({ value, options }) => {
      return {
        value,
        name: observationFiltersToTitle[value],
        operations:
          value === 'observed_at'
            ? [
                {
                  name: filterType.between,
                  value: filterType.between,
                },
              ]
            : [
                {
                  name: filterType.eq,
                  value: filterType.eq,
                  options: options,
                },
                {
                  name: filterType.ne,
                  value: filterType.ne,
                  options: options,
                },
              ],
      };
    });
