import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { _notNil, _toRecord } from '@/littledash';
import type { MetadataField } from '@/model/Metadata.model';
import { ModalContainer } from '@/utils/modal';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface AddStudyMetadata {
  currentMetadata: Record<number, MetadataField>;
  onSubmit: (fields: unknown) => void;
  selectedMetadata: MetadataField[];
  metadataGlossary: MetadataField[];
}

export const AddStudyMetadata: FC<AddStudyMetadata> = ({
  onSubmit,
  metadataGlossary,
  currentMetadata,
  selectedMetadata,
}) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState<number[]>(selectedMetadata.map(({ glossary_id }) => Number(glossary_id)));
  const [metadataLookup, setMetadataLookup] = useState<Record<number, MetadataField>>({});

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  useEffect(() => {
    if (_notNil(metadataGlossary)) {
      setMetadataLookup(_toRecord(metadataGlossary, 'id'));
    }
  }, [metadataGlossary, currentMetadata]);

  return (
    <ModalContainer size="small">
      <div className="pa3 bb bg-white">
        <h3 className="basier-med normal f4 lh-title near-black">Add Metadata</h3>
      </div>
      <div className="ph3 flex flex-wrap justify-between items-top mv3">
        <div className="w-100 ui-card">
          <SearchSelect
            sections={[{ items: metadataGlossary.filter(({ id }) => !(id in currentMetadata)) }]}
            selected={fields}
            setSelected={setFields}
            testPrefix="metadata-fields"
            sort
          />
        </div>
      </div>
      <div className="pa3 mt3 bt bg-white b--moon-gray">
        <Button
          className="mr3"
          onClick={() => {
            onSubmit(
              fields.map((currentId: number) => {
                const { id, type, ...currentField } = metadataLookup[currentId];
                return { ...currentField, glossary_id: id };
              })
            );
            closeModal();
          }}
          testId="metadata-fields__continue"
        >
          Continue
        </Button>
        <Button plain onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </ModalContainer>
  );
};
