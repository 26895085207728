// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Icon from '@/components/UI/Icon';
import './ExpandButton.scss';

const ExpandButton = ({ onClick, style, isExpanded, className }) => {
  return (
    <Button
      soft
      onClick={onClick}
      testId="ExpandButton"
      disabled={false}
      style={{ width: '40px', padding: '0', ...style }}
      className={className}
    >
      <Icon
        icon="chev_right"
        width="12"
        height="12"
        className={`expander ${isExpanded ? 'expanded' : ''} dark-gray`}
        viewBox="0 0 20 32"
        aria-expanded={Boolean(isExpanded)}
      />
    </Button>
  );
};

export default ExpandButton;
