import { generateTreatmentColumnsStep } from '@/components/Studies/Treatments/Treatment.utils';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { MetadataField } from '@/model/Metadata.model.ts';
import { State } from '@/model/State.model';
import { StudyApiId } from '@/model/Study.model';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { api as apiRoute } from '@/support/route';
import { Dispatch, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { CreateBulkDosingState } from '../CreateDosing.utils';

const onFetchTableData = () => {};
interface SelectTreatmentProps {
  dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>;
}
export const SelectTreatment: React.FC<SelectTreatmentProps> = ({ dispatch }) => {
  const [columns, setColumns] = useState([]);
  const settings = useSelector((state: State) => state.ui.settings);

  const { response: treatmentMetadataResponse, loading: treatmentMetadataLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'meta-glossary.show',
    query: { filter_type: 'treatment_meta' },
    options: { onError: { toast: false, slug: 'treatment-metadata-load' } },
  });

  useEffect(() => {
    dispatch({
      type: 'updateArray',
      id: 'selectedTreatments',
      data: [],
    });
  }, []);

  useEffect(() => {
    if (!treatmentMetadataLoading) {
      const treatmentMetadata = (treatmentMetadataResponse?.body as { data: Array<MetadataField> })?.data ?? [];
      const updatedColumns: any = generateTreatmentColumnsStep(
        settings.tables.treatments.columns,
        treatmentMetadata ?? []
      );

      setColumns(updatedColumns);
      dispatch({ type: 'stepReady' });
    }
  }, [treatmentMetadataLoading]);

  const { pathname } = useLocation();
  const path = matchPath(pathname, { path: '/studies/:studyId/dosing/' });
  const params = path?.params as { studyId: string };
  const studyId = params?.studyId as StudyApiId;

  const onSelectRow = (rows: Array<any>) => {
    const mapped = (rows ?? []).map(({ original }: any) => original);
    dispatch({
      type: 'updateArray',
      id: 'selectedTreatments',
      data: mapped,
    });
  };

  return (
    <div className="pt4">
      <APITable
        apiInfo={{
          type: 'legacyInternalApi',
          route: apiRoute('studies.treatments', {
            studyId,
          }),
        }}
        columns={columns}
        includeParam="metadata,study_groups"
        defaultSortBy={{ id: 'treatment_name', desc: false }}
        searchPlaceholderText="Search"
        reduxTableName={'treatments'}
        showMetricChangeFrom={true}
        onSelectRow={onSelectRow}
        pageSizes={[50, 100, 200, 500, 1000]}
        defaultPageSize={1000}
        onFetchTableData={onFetchTableData}
        testIdPrefix="treatments"
        loadingClassName="pv3"
        maxHeight={450}
      />
    </div>
  );
};
