// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { useState } from 'react';

const AddMetaColumns = ({ metadata, callback }) => {
  const [columns, setColumns] = useState([]);

  return (
    <div className="br2 overflow-hidden bg-near-white w-100 mw6 pa0 center mv3 shadow-4" style={{ paddingBottom: '0' }}>
      <div className="pa3 bb bg-white">
        <h3 className="basier-med normal f4 lh-title near-black">Add Columns</h3>
      </div>
      <div className="ph3 flex flex-wrap justify-between items-top mv3">
        <div className="w-100 ui-card">
          <SearchSelect
            sections={[{ items: metadata }]}
            selected={columns}
            setSelected={setColumns}
            testPrefix="metadata-columns"
            sort
          />
        </div>
      </div>
      <div className="pa3 mt3 bt bg-white b--moon-gray">
        <Button
          className="mr3"
          onClick={() => {
            callback(columns);
          }}
          testId="metadata-columns__continue"
        >
          Continue
        </Button>
        <Button plain onClick={() => callback(null)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddMetaColumns;
