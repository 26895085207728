import { getCalculationsAndMeasurements } from '@/helpers';
import { _isFunction } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { Study } from '@/model/Study.model';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, AlertUpdateEventHandler } from '@/utils/alerts/Alert';
import { modalAction, ModalActions, ModalContainer } from '@/utils/modal';

interface AnimalAlertsModalProps {
  calculations: Array<PresetCalculation>;
  alerts: Array<AnimalAlertV1>;
  studyId: Study['id'];
  animalName: string;
  isWriteUser: boolean;
  cageName: string;
  onModalClose: () => void;
}

const AnimalAlertsModal: React.FC<AnimalAlertsModalProps> = ({
  calculations = [],
  alerts: incomingAlerts = [],
  studyId,
  animalName,
  isWriteUser,
  cageName,
  onModalClose,
}) => {
  const { closeModal } = modalAction(useDispatch());
  const [alerts, setAlerts] = useState(incomingAlerts);

  const calculationMap = useMemo(() => getCalculationsAndMeasurements(calculations), [calculations]);

  const alertSettings = {
    displayHeader: false,
    displayResolveControl: isWriteUser,
    displayDeferControl: false,
    displayTriggeredBy: true,
    displayResolveConfirmation: false,
    displayAlertState: false,
    displayCreatedAt: false,
  };

  const handleModalClose = () => {
    if (_isFunction(onModalClose)) {
      onModalClose();
    }
    closeModal();
  };
  const handleUpdate: AlertUpdateEventHandler = async (action) => {
    switch (action.type) {
      case 'deferred':
      case 'resolved': {
        setAlerts(alerts.map((alert) => (alert.id === action.data.id ? action.data : alert)));
        break;
      }
    }
  };

  return (
    <ModalContainer size="narrow" className="bg-white f6 near-black pa3">
      <div className="flex fw5 mb3">
        {animalName ?? '-'} &middot; {cageName ?? '-'}
      </div>
      <div className="flex flex-column g3">
        {[...alerts.values()].map((alert) => (
          <Alert
            key={alert.id}
            alert={alert}
            studyId={studyId}
            animalName={animalName}
            calculation={calculationMap.get(alert.calculation)}
            settings={alertSettings}
            onUpdate={handleUpdate}
          />
        ))}
      </div>
      <ModalActions className="mt3" onCancel={handleModalClose} cancelButtonStyle="primary" cancelBtnText="Close" />
    </ModalContainer>
  );
};
export default AnimalAlertsModal;
