// @ts-nocheck: converted from JS

import CageCardTemplate from '@/components/Modals/PrintCageCards/4x3/CageCardTemplate';
import { trunc } from '@/helpers';
import { safelyDecodeURIComponent } from '@/littledash';
import React from 'react';

interface CageCardsTemplateProps {
  cages: any;
  study: any;
  selectedMetadata: any;
  species: any;
}

class CageCardsTemplate extends React.Component<CageCardsTemplateProps> {
  renderMetadataValue = function (value) {
    if (value) {
      if (Array.isArray(value)) {
        return trunc(value.map(safelyDecodeURIComponent).join(', '), 70);
      }
      return trunc(safelyDecodeURIComponent(value), 70);
    }
    return '';
  };

  render() {
    const { selectedMetadata, cages, study, maxFields, species = [] } = this.props;
    const cage = Array.isArray(cages) ? cages[0] : undefined;
    return (
      <div
        className="black"
        style={{
          paddingTop: '5px',
        }}
      >
        {cage && (
          <CageCardTemplate
            study={study}
            cage={cage}
            selectedMetadata={selectedMetadata}
            maxFields={maxFields}
            species={species}
          />
        )}
      </div>
    );
  }
}

export default CageCardsTemplate;
