import { matchPath, useLocation } from 'react-router-dom';
import { StudyApiId } from '@/model/Study.model';
import { web as webRoute } from '@/support/route';
import { Dispatch, useEffect, useState } from 'react';
import SubHeader from '@/components/UI/SubHeader';
import Header from '@/components/UI/Header';
import StepForm from '@/components/UI/StepForm';
import Loading from '@/components/Loading';
import { SelectTreatment } from './steps/SelectTreatments';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { SelectAnimals } from './steps/SelectAnimals';
import { CreateBulkDosingState, setupInitialState } from './CreateDosing.utils';
import { Summary } from './steps/Summary';
import { ApiService } from '@/support/ApiService';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import { _isNil } from '@/littledash';
import { toast } from 'react-toastify';
import { history } from '@/utils/history';
import { errorToast } from '@/helpers.tsx';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import InVivoError from '@/model/InVivoError.ts';

export const CreateDosing = () => {
  const steps = [
    {
      name: 'Select treatments',
      isValid: (state: CreateBulkDosingState) => state.selectedTreatments.length > 0,
      renderStep: (state: CreateBulkDosingState, dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>) => (
        <SelectTreatment dispatch={dispatch} />
      ),
    },
    {
      name: 'Select animals',
      isValid: (state: CreateBulkDosingState) => state.selectedAnimals.length > 0,
      renderStep: (state: CreateBulkDosingState, dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>) => (
        <SelectAnimals state={state} dispatch={dispatch} />
      ),
    },
    {
      name: 'Dosing',
      isValid: (state: CreateBulkDosingState) => state.dosages.length > 0,
      renderStep: (state: CreateBulkDosingState, dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>) => (
        <Summary state={state} dispatch={dispatch} />
      ),
    },
  ];

  const [initialState, setInitialState] = useState<CreateBulkDosingState>();
  const [loading, setLoading] = useState(true);

  const { pathname } = useLocation();
  const path = matchPath(pathname, { path: '/studies/:studyId/dosing/' });
  const params = path?.params as { studyId: string };
  const studyId = params?.studyId as StudyApiId;

  useEffect(() => {
    setupInitialState(studyId, steps)
      .then((state) => {
        setInitialState(state);
        setLoading(false);
      })
      .catch((error) => {
        errorToast('Failed to load dosages form');
        ExceptionHandler.captureException(
          new InVivoError('Could not initialize Bulk Dosing form', {
            cause: error,
            slug: 'initialize-bulk-dosing-form',
          })
        );
      });
  }, []);

  const submitDosing = (state: CreateBulkDosingState) => {
    const { dosages, study } = state;

    const dosagesData: any[] = Object.values(dosages).map(({ animal_id, dosed_at, comment, treatment_id }) => ({
      animal_id,
      comment,
      dosed_at,
      treatment_id,
    }));

    ApiService.call({
      endpoint: 'POST /api/v1/studies/{studyId}/dosages',
      path: { studyId: study.api_id },
      body: { dosages: dosagesData },
    })
      .then(() => {
        toast.success(`Successfully recorded doses`);
        history.push(webRoute('studies.dosing.list', { id: study.id }));
      })
      .catch((error) => {
        errorToast('Failed to create dosages');
        ExceptionHandler.captureException(
          new InVivoError('Could not create dosage(s)', {
            cause: error,
            slug: 'submit-bulk-doses',
          })
        );
      });
  };

  const errorBanner = ({ state: { apiError } }: any) => (
    <>
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={apiError}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
    </>
  );

  return (
    <div className=" pb3">
      <SubHeader linkToText="Dosing" link={webRoute('studies.dosing.list', { id: studyId })} />

      <div className="pb4 ph4">
        <div>
          <Header mainHeaderText="Record dose" />
          <p className="f6">
            Administer treatment doses in bulk.
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="dib ml1 link blue"
              href="https://help.benchling.com/hc/en-us/articles/30129796227725-Dosing-multiple-animals-at-one-time"
            >
              Read more
            </a>
          </p>
        </div>
        {loading || _isNil(initialState) ? (
          <div style={{ height: 650 }}>
            <Loading txt="Loading..." />
          </div>
        ) : (
          <StepForm
            title=""
            titlePadding="pb2"
            initialState={initialState}
            props={{}}
            steps={initialState.steps}
            finalStepAction={submitDosing}
            finalButtonText="Create"
            finalButtonClass="w-47 mw6"
            horizontal
            errorBanner={errorBanner}
          />
        )}
      </div>
    </div>
  );
};
