import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList, { ActionProps } from '@/components/UI/SelectDropDown/Menus/ActionList';
import { successToast } from '@/helpers';
import { _isNil, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { StudyApiId } from '@/model/Study.model';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRequest } from '@/support/Hooks/request';
import { web as webRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';

interface AnimalActionProps {
  userHasWriteAccess: boolean;
  animal: Animal;
  fetchAnimal: () => void;
  studyApiId: StudyApiId;
  studyHasGroups: boolean;
  features: Record<string, boolean>;
}

const AnimalActions: FC<AnimalActionProps> = ({
  userHasWriteAccess = false,
  animal,
  fetchAnimal,
  studyApiId,
  studyHasGroups = false,
  features,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: studyId } = useParams<{ id: string }>();
  const { openModal, closeModal } = modalAction(dispatch);
  const { sendRequest: deleteAnimal } = useRequest({
    route: 'animal',
    method: 'delete',
    params: { id: animal.id },
  });

  const handleCallback = async (text: string) => {
    closeModal();
    await fetchAnimal();
    successToast(text);
  };

  const { study_group_id: studyGroupID, terminated_at: terminatedAt } = animal;
  const atunesIntegrationEnabled = features?.atunes_colony_management_integration === true;
  const dataAnalysisEnabled = features?.data_analysis ?? false;

  let actions: Array<ActionProps> = [
    {
      name: 'Edit',
      action: () =>
        openModal('EDIT_SUBJECT', {
          animal,
          studyApiId,
          handleCallback: () => handleCallback('Updated animal.'),
        }),
    },
  ];

  if (_isNil(studyGroupID)) {
    const addToGroupTooltipText = () => {
      if (_notNil(terminatedAt)) {
        return "This animal has been terminated and can't be added to a group";
      } else if (!studyHasGroups) {
        return 'No groups have been created for this study';
      }
      return '';
    };
    actions.push({
      name: 'Add to group',
      disabled: _notNil(terminatedAt) || !studyHasGroups,
      action: () =>
        openModal('ASSIGN_SUBJECT_TO_GROUP', {
          subject: [animal],
          handleCallback: () => handleCallback('Assigned to group.'),
        }),
      tooltip: addToGroupTooltipText(),
    });
  } else {
    actions.push({
      name: 'Remove from group',
      action: () =>
        openModal('REMOVE_SUBJECT_FROM_GROUP', {
          subject: [animal],
          handleCallback: () => handleCallback('Removed from group.'),
        }),
    });
  }

  actions = [
    ...actions,
    {
      name: 'Set tracking date',
      action: () =>
        openModal('SET_TRACKING_DATE', {
          subject: [animal],
          handleCallback,
        }),
    },
    ...(dataAnalysisEnabled
      ? [
          {
            name: 'Set disease induction date',
            action: () => {
              openModal('UPDATE_DISEASE_INDUCTION_DATE', {
                studyApiId,
                animalApiIds: [animal.api_id],
                handleCallback,
              });
            },
          },
        ]
      : []),
    {
      name: 'Move cage',
      disabled: _notNil(terminatedAt),
      action: () =>
        openModal('MOVE_SUBJECT', {
          animal,
          handleCallback: () => handleCallback('Moved cage.'),
        }),
      tooltip: _notNil(terminatedAt) ? "This animal has been terminated and can't move cage" : '',
    },
  ];

  if (!atunesIntegrationEnabled) {
    actions.push(
      {
        name: _notNil(terminatedAt) ? 'Undo termination' : 'Mark deceased',
        action: () => {
          if (_notNil(terminatedAt)) {
            openModal('UNDO_EUTHANISE_SUBJECT', {
              subjects: [animal],
              handleCallback: () => handleCallback('Living status updated.'),
            });
          } else {
            openModal('EUTHANISE_SUBJECT', {
              subjects: [animal],
              handleCallback: () => handleCallback('Marked as deceased.'),
            });
          }
        },
      },
      {
        action: () =>
          openModal('CONFIRM_DELETE_SUBJECT', {
            onClick: async (reasonForDelete?: string) => {
              await deleteAnimal(_notNil(reasonForDelete) ? { reason_for_change: reasonForDelete } : undefined);
              history.push(
                webRoute('studies.show', {
                  id: studyId,
                })
              );
              toast.success('Deleted animal.');
            },
          }),
        className: 'red',
        name: 'Delete',
      }
    );
  }

  return (
    <SelectDropDown className="plain" title="Actions" alignMenu="right" disabled={!userHasWriteAccess}>
      <ActionList actions={actions} />
    </SelectDropDown>
  );
};
export default AnimalActions;
