import Radio from '@/components/UI/FormElements/Radio/Radio';
import { numericStringPattern } from '@/helpers';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { FC, useState } from 'react';

interface StudyCodeAddSequenceProps {
  onClick: (baseCount: string, resetOnYear: boolean) => void;
  closeModal: () => void;
}

const numericStringRegex = /^[0-9\b]+$/;

const StudyCodeAddSequence: FC<StudyCodeAddSequenceProps> = ({ onClick, closeModal }) => {
  const [baseSequence, setBaseSequence] = useState<`${number}`>('1');
  const [resetOnYear, setResetOnYear] = useState<boolean>(false);

  const handleSubmit = () => {
    onClick(baseSequence, resetOnYear);
    closeModal();
  };

  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Add sequence" closeModal={closeModal} className="pa3 b--moon-gray bg-white" />
      <div className="pb3 ph3">
        <div className="w-50 mb3">
          <label>Begin count from:</label>
          <input
            type="text"
            inputMode="numeric"
            pattern={String(numericStringPattern)}
            id="beginSequence"
            value={baseSequence}
            onChange={({ target: { value } }) => {
              if (value === '' || numericStringRegex.test(value)) {
                setBaseSequence(value as `${number}`);
              }
            }}
          />
        </div>
        <Radio
          id="continuous"
          value="continuous"
          name="sequenceReset"
          onChange={() => setResetOnYear(false)}
          checked={!resetOnYear}
          label="Continuous"
          sublabel="Automatically count upwards"
          className="mb2"
        />
        <Radio
          id="reset"
          value="reset"
          name="sequenceReset"
          label="Start a new count each year"
          sublabel="Reset the counter at the end of each year"
          onChange={() => setResetOnYear(true)}
          checked={resetOnYear}
        />
      </div>
      <ModalActions onSubmit={handleSubmit} className="pa3 bt" />
    </ModalContainer>
  );
};

export default StudyCodeAddSequence;
