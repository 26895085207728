import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { Schedule } from '@/components/Studies/Tasks/Schedule';
import { _isNil } from '@/littledash';
import type { Study } from '@/model/Study.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

export const StudySchedule: FC = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const {
    entity: study,
    entityLoading: studyLoading,
    entityError: studyError,
  } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'study_groups,users',
  });
  if (studyLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }
  if (studyError || _isNil(study)) {
    return (
      <ApiErrorBanner
        className="mb4"
        title={'There was an error retrieving the study'}
        text={
          'An error has occurred when fetching the study, please try again later. If this keeps occurring please contact support.'
        }
        errorType={ApiErrorType.FETCH}
        error={studyError}
      />
    );
  }

  return <Schedule study={study} />;
};
