import { formatDosingColumnValue, handleIconClick } from '@/components/Studies/Treatments/Treatment.utils';
import Link from '@/components/UI/Link';
import Table from '@/components/UI/Table';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { formatNumber } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type {
  DisplayedGroupMetadata,
  MetadataField,
  MetadataFieldTypes,
  MetadataFieldValueTypes,
  MetadataFieldWithValue,
} from '@/model/Metadata.model';
import type { Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import Icon from '@/components/UI/Icon';
import style from './ReviewTable.module.scss';

const renderTreatment = (treatment: Treatment): string => {
  if (_notNil(treatment.display_name)) {
    return treatment.display_name;
  }

  const doseColumnValue = formatDosingColumnValue(treatment.fields[0]);
  return `${treatment.treatment_type.name} ${doseColumnValue !== '-' ? `(${doseColumnValue})` : ''}`;
};

interface TreatmentCellProps {
  treatments?: Array<Treatment>;
  onClick: (treatment: Treatment) => void;
}

const TreatmentCell: React.FC<TreatmentCellProps> = ({ treatments, onClick }) => {
  const populatedTreatments = treatments?.filter((treatment) => _notNil(treatment));
  if (_isEmpty(populatedTreatments)) {
    return <>-</>;
  }
  return (
    <>
      {populatedTreatments?.map((treatment, id) => (
        <div className={`flex items-top pointer ${style.treatmentLinkContainer}`}>
          {_notNil(treatment.treatment_type?.benchling_link) && (
            <Icon
              className="blue dib mr1 pt1"
              tooltip="Click to view in new tab"
              onClick={() => handleIconClick(treatment)}
              icon="jeffy_p_circle"
              width="24"
              height="24"
              viewBox="0 0 20 20"
            />
          )}
          <Link
            key={`${id}-${treatment.id}`}
            openModal
            onClick={() => onClick(treatment)}
            className={`link w-100 ${style.treatmentLink}`}
          >
            {renderTreatment(treatment)}
          </Link>
        </div>
      ))}
    </>
  );
};

const getGroupMetadata = (group: TreatmentGroup): Array<MetadataFieldWithValue> => {
  if (group.meta) {
    if (Array.isArray(group.meta)) {
      return group.meta;
    } else if (_notNil(group.meta?.data)) {
      return group.meta.data;
    }
  }
  if (group.metadata) {
    return group.metadata;
  }
  return [];
};

const renderMetadataValue = (value: MetadataFieldValueTypes, type: MetadataFieldTypes): string | number => {
  if (_notNil(value)) {
    if (Array.isArray(value)) {
      return value.map((value) => safelyDecodeURIComponent(value)).join(', ');
    }
    if (type === 'numeric') {
      const numericValue = formatNumber(value?.toString());
      if (typeof numericValue === 'number') {
        return isNaN(numericValue) ? value.toString() : numericValue.toString();
      }
      return numericValue;
    }
    if (type === 'date' && typeof value === 'string') {
      return DateUtils.renderDate(value, { defaultResponse: '-' });
    }
    return safelyDecodeURIComponent(value?.toString());
  }
  return '';
};

interface MetadataCellProps {
  group: TreatmentGroup;
  metadataId: MetadataField['id'];
}

const MetadataCell: React.FC<MetadataCellProps> = ({ group, metadataId }) => {
  const metadata = useMemo(
    () => getGroupMetadata(group).find((meta) => meta.glossary_id === metadataId),
    [group, metadataId]
  );
  const metadataValue = metadata?.value;
  const metadataFieldType = metadata?.field_type;
  if (_isNil(metadataValue) || _isNil(metadataFieldType)) {
    return null;
  }
  return <>{renderMetadataValue(metadataValue, metadataFieldType)}</>;
};

interface ReviewTableProps {
  groups: Array<TreatmentGroup>;
  groupMetadata?: Array<DisplayedGroupMetadata>;
}

const ReviewTable: React.FC<ReviewTableProps> = ({ groups, groupMetadata }) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const columns = useMemo<Columns<TreatmentGroup>>(() => {
    const metadataColumnsToDisplay = new Set(
      (groups ?? []).flatMap((group) => getGroupMetadata(group).map(({ glossary_id }) => glossary_id))
    );
    const hasTreatments = (groups ?? []).some((group) => _isNotEmpty(group.treatments));

    const defaultColumns: Columns<TreatmentGroup> = [
      {
        id: 'number',
        accessor: 'no',
        width: 80,
        Header: 'No.',
        headerClassName: 'no-sort',
        Cell: ({ row: { original, index } }) => (
          <div data-test-component="ReviewTable" data-test-element="no-cell" data-test-key={original.api_id}>
            <span>{original.no || index + 1}</span>
            {original.control === true && (
              <span data-test-element="control-chip" className={style.chip}>
                C
              </span>
            )}
          </div>
        ),
      },
      {
        id: 'color',
        accessor: 'color',
        Header: 'Colour',
        width: 110,
        headerClassName: 'no-sort',
        Cell: ({ row: { original } }) => <Swatch hex={original.color} />,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        headerClassName: 'no-sort',
      },
      {
        id: 'maxSubjects',
        accessor: 'max_subjects',
        Header: 'No. of animals',
        headerClassName: 'no-sort',
      },
    ];
    if (hasTreatments) {
      defaultColumns.push({
        id: 'treatments',
        accessor: 'treatments',
        Header: 'Treatments',
        headerClassName: 'no-sort',
        className: 'flex-wrap',
        verticalAlign: 'start',
        Cell: ({
          row: {
            original: { treatments },
          },
        }) => {
          return (
            <TreatmentCell
              treatments={treatments}
              onClick={(treatment) =>
                openModal('TREATMENT_META', {
                  treatment,
                  closeModal,
                })
              }
            />
          );
        },
      });
    }
    const metadataColumns: Columns<TreatmentGroup> = (groupMetadata ?? []).reduce<Columns<TreatmentGroup>>(
      (acc, { schema: { title, id } }) => {
        if (metadataColumnsToDisplay.has(id)) {
          acc.push({
            id: `glossary_id_${id}`,
            Header: title,
            headerClassName: 'no-sort',
            Cell: ({ row: { original } }) => <MetadataCell group={original} metadataId={id} />,
          });
        }
        return acc;
      },
      []
    );
    const { extraMetadataColumns } = (groups ?? []).reduce<{
      extraMetadataColumns: Columns<TreatmentGroup>;
      displayedMetadataIds: Set<ID>;
    }>(
      (groupAcc, group) => {
        groupAcc.extraMetadataColumns.push(
          ...getGroupMetadata(group).reduce<Columns<TreatmentGroup>>((metadataAcc, { glossary_id, title }) => {
            if (!groupAcc.displayedMetadataIds.has(glossary_id)) {
              groupAcc.displayedMetadataIds.add(glossary_id);
              metadataAcc.push({
                id: `glossary_id_${glossary_id}`,
                Header: title,
                headerClassName: 'no-sort',
                Cell: ({ row: { original } }) => <MetadataCell group={original} metadataId={glossary_id} />,
              });
            }
            return metadataAcc;
          }, [])
        );

        return groupAcc;
      },
      {
        extraMetadataColumns: [],
        displayedMetadataIds: new Set((groupMetadata ?? []).map(({ id }) => id)),
      }
    );

    return [...defaultColumns, ...metadataColumns, ...extraMetadataColumns];
  }, [groups, groupMetadata, openModal, closeModal]);

  type GroupWithMetadata = Record<string, any> & TreatmentGroup;

  const data = groups.map((group) => {
    const tempGroup: GroupWithMetadata = { ...group };
    getGroupMetadata(group).forEach(({ glossary_id, value }) => {
      tempGroup[`glossary_id_${glossary_id}`] = value;
    });
    return tempGroup;
  });

  return (
    <div className="ui__table bg-white w-100" data-test-component="ReviewTable" data-test-element="container">
      <Table columns={columns} data={data} />
    </div>
  );
};

const Swatch: React.FC<{ hex: string }> = ({ hex }) => {
  return <div className="dib w2 h1 br1" style={{ background: hex }} />;
};

export default ReviewTable;
