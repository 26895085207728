import type { EndpointParameters } from '@/generated/internal-api/LegacyApiRoutes.ts';
import { errorToast } from '@/helpers';
import type { PickRequired } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import http from '@/support/http';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import type { AxiosResponse, Method } from 'axios';
import { notAborted } from './Hooks/fetch/useAbortController';
import { RouteService } from './RouteService';

export enum ResponseType {
  Success = 'success',
  Error = 'error',
  NotFound = 'not_found',
}

export interface LegacyApiServiceProps<ApiRoute extends keyof EndpointParameters> {
  method: Method;
  apiRoute: ApiRoute;
  path?: EndpointParameters[ApiRoute];
  query?: Record<string, string | number | boolean | Array<string | number | boolean>>;
  headers?: Record<string, string | number | boolean | Array<string>>;
  body?: any;
  options?: { onError?: { toast?: boolean; throw?: boolean; capture?: boolean; slug?: string } };
}

interface CommonLegacyApiResponse<R = unknown> {
  body?: R;
  error?: Error & { response?: { data: { errors: Record<string, Array<string>> }; status: number } };
}

export interface LegacyApiSuccessResponse<R = unknown>
  extends PickRequired<CommonLegacyApiResponse<R>, 'body'>,
    Pick<AxiosResponse, 'headers' | 'status' | 'statusText'> {
  type: ResponseType.Success;
}

export interface LegacyApiErrorResponse<R = unknown> extends PickRequired<CommonLegacyApiResponse<R>, 'error'> {
  type: ResponseType.Error;
}

export type LegacyApiResponse<R = unknown> = LegacyApiSuccessResponse<R> | LegacyApiErrorResponse<R>;

export class LegacyApiService {
  static async call<ApiRoute extends keyof EndpointParameters>({
    method,
    apiRoute,
    path,
    query,
    headers: requestHeaders,
    body,
    signal,
    options,
  }: LegacyApiServiceProps<ApiRoute> & {
    signal?: AbortSignal;
  }): Promise<LegacyApiResponse<unknown>> {
    try {
      const currentUrl = RouteService.legacyApi({ apiRoute, path, query }).url.href;

      const { data, headers, status, statusText } = await http.request<Response>({
        baseURL: currentUrl,
        method,
        headers: { ...(requestHeaders ?? {}) },
        data: body,
        signal,
      });
      return {
        type: ResponseType.Success,
        body: data,
        headers,
        status,
        statusText,
      } as LegacyApiResponse<Response>;
    } catch (error) {
      const errorResponse: LegacyApiErrorResponse<Response> = {
        type: ResponseType.Error,
        error: error as LegacyApiErrorResponse<Response>['error'],
      };
      if (options?.onError?.capture ?? true) {
        ExceptionHandler.captureException(
          new InVivoError(`Api Error: ${apiRoute}`, {
            cause: error,
            slug: options?.onError?.slug ?? 'legacy-api-service',
          })
        );
      }

      if ((options?.onError?.toast ?? true) && notAborted(error)) {
        errorToast('There was a problem communicating with the Benchling In Vivo server');
      }
      if (options?.onError?.throw ?? true) {
        throw new InVivoError(errorResponse.type, { cause: error, slug: options?.onError?.slug ?? 'api-service' });
      } else {
        return errorResponse;
      }
    }
  }
}
