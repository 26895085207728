// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { CreateStudyAction, CreateStudyState } from '@/components/Studies/Create/Create.model';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { _isEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { Dispatch, FC, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

interface TaskV1Props {
  state: CreateStudyState;
  dispatch: Dispatch<CreateStudyAction>;
}

const TasksV1: FC<TaskV1Props> = ({ state, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('tasks.templateGroups.show'));
      dispatch({ type: 'updateForm', data: { templates: data } });
    } catch (error) {
      setApiError(error);
    }
    setLoading(false);
  };

  const globalDispatch = useDispatch();

  let templateToView = false;
  if (state.study.task_template_group_id !== '') {
    templateToView = state.form.templates.find((t) => Number(t.id) === Number(state.study.task_template_group_id));
  }

  const closeModal = () => {
    globalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const openModal = (modal, props) => {
    globalDispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const callBackFn = async () => {
    await fetchTemplates();
    closeModal();
    toast.success('Successfully created a new template!');
  };

  return (
    <div>
      <div className="mb4">
        <h3 className="fw5 f5 pb2">Tasks</h3>
      </div>
      <div className="ui-card mw8">
        <div className="pa4 bb b--moon-gray">
          {apiError && (
            <ApiErrorBanner
              className="mb4"
              title={'There was a problem fetching data'}
              text={
                'An error has occurred when fetching data from the server, please try again later. If this keeps occurring please contact support.'
              }
              error={apiError}
            />
          )}
          {loading ? (
            <Loading txt="Fetching templates..." />
          ) : (
            <Fragment>
              {!_isEmpty(state.form.templates) ? (
                <div>
                  <label htmlFor="task_template_group_id">Select a template</label>
                  <p className="f6 pb3">
                    Templates allow you to quickly assign tasks that are frequently conducted in your studies
                  </p>
                  <div className="flex items-center mb3">
                    <select
                      name="task_template_group_id"
                      value={state.study.task_template_group_id || ''}
                      onChange={(e) =>
                        dispatch({
                          type: 'updateStudy',
                          data: { task_template_group_id: e.target.value },
                        })
                      }
                      className="w5 mb0"
                    >
                      <option value="">Choose a template</option>
                      {state.form.templates.map((t, i) => (
                        <option key={i} value={t.id}>
                          {t.name}
                        </option>
                      ))}
                    </select>
                    {state.study.task_template_group_id && (
                      <Button
                        onClick={() => {
                          dispatch({
                            type: 'updateStudy',
                            data: { task_template_group_id: '' },
                          });
                        }}
                        stateless
                        className="link blue ph0 f6 ml3"
                      >
                        Remove template
                      </Button>
                    )}
                  </div>
                  {templateToView && (
                    <div className="mb4 mt2">
                      <TasksBreakdown tasks={templateToView.taskTemplates} />
                    </div>
                  )}
                </div>
              ) : (
                <Banner info dismiss={false} className="mw6 mb3">
                  <p className="lh-copy f6 near-black">
                    No templates have been created yet. Templates allow you to quickly assign tasks that are frequently
                    conducted in your studies.
                  </p>
                </Banner>
              )}
            </Fragment>
          )}
          <Button
            icon="add_new"
            pill
            paleBlue
            onClick={() =>
              openModal('ADD_TASK_TEMPLATE', {
                callBackFn,
                closeModal,
              })
            }
          >
            Add a new template
          </Button>
        </div>
      </div>
    </div>
  );
};

export const TasksBreakdown = ({ tasks }) => {
  const formatDays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };

  const terminology = (task) => {
    let content = '';

    if (task.task_type == 'conditional') {
      if (task.conditional_settings.type === 'study_day') {
        content = `On study day ${task.conditional_settings.value}`;
      } else if (task.conditional_settings.type === 'age') {
        content = `When animal is ${task.conditional_settings.value} days old`;
      } else if (task.conditional_settings.type === 'tracking_day') {
        content = `When animal tracking day is equal to ${task.conditional_settings.value}`;
      }
      return content;
    }

    if (task.period === 'weekly') {
      const frequency_settings = task.frequency_settings
        .map((d, i) => `${formatDays[d]}${i !== task.frequency_settings.length - 1 ? ', ' : ''}`)
        .join('');
      content = `Every ${task.frequency} week(s) on: ${frequency_settings}`;
    }
    if (task.period === 'daily') {
      content = `Every ${task.frequency} day(s)`;
    }
    return content;
  };

  return (
    <div className="pa3 pb0 bg-near-white br2 mw6">
      {!_isEmpty(tasks) &&
        tasks.map((t, i) => (
          <div key={i} className="pb3">
            <h4 className="normal basier-med f5 near-black ma0 lh-copy">{t.description}</h4>
            <p className="lh-copy ma0">{terminology(t)}</p>
          </div>
        ))}
    </div>
  );
};

export default TasksV1;
