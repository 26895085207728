import { ID } from './Common.model';
import type { MainUser } from './User.model';

export type TeamApiId = `ten_${string}`;
export const enum CageCardSize {
  FourByThree = '4x3',
  FourByTwo = '4x2',
}

export interface CageCardSettings {
  size?: CageCardSize;
  metadata?: Array<unknown>;
}

interface TeamPivot {
  owner: boolean;
  admin_console_user: boolean;
  status: string;
  team_id: ID;
  user_id: ID;
}

export interface Team {
  users: Array<MainUser>;
  pivot: TeamPivot;
  cage_card_settings?: CageCardSettings;
  colony_exit_url?: string;
  sso_enabled: boolean;
}
