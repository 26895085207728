import UserAvatar from '@/components/UI/UserAvatar';
import { TruncatedAvatar } from '@/components/UI/UserAvatar/UserAvatar';
import type { ColorDetail } from '@/constants/colors';
import type { TaskV1 } from '@/model/Task.model';
import { DateUtils } from '@/utils/Date.utils.ts';
import { TZDate } from '@date-fns/tz';
import { useMemo } from 'react';
import styles from './DayList.module.scss';
import Icon from '@/components/UI/Icon';
import cn from 'classnames';

interface TaskDayCardProps {
  task: TaskV1;
  colours?: ColorDetail;
  onClick?: () => void;
  maxUserBeforeTruncate?: number;
  narrow?: boolean;
}

const taskStatusLookup = (key: string): string => {
  const statusDict: { [key: string]: { text: string } } = {
    done: { text: 'Complete' },
    in_progress: { text: 'In progress' },
  };
  return statusDict[key]?.text ?? '';
};

const DayCard = ({ task, colours, onClick, maxUserBeforeTruncate = 4, narrow }: TaskDayCardProps) => {
  const timezoneData: string | undefined = useMemo(() => {
    const userZonedTaskStart = new TZDate(task.duration.start, DateUtils.timezone());
    const taskZonedTaskStart = new TZDate(task.duration.start, task.timezone);
    if (userZonedTaskStart.getTimezoneOffset() !== taskZonedTaskStart.getTimezoneOffset()) {
      return `${DateUtils.timezoneName(task.duration.start, task.timezone, 'long')} • ${DateUtils.timezoneAbbreviation(task.duration.start, task.timezone)}`;
    }
    return undefined;
  }, [task]);

  return (
    <div
      className={`${styles['task-day-item']} ${colours ? colours?.background?.class : 'bg-near-white'} ${
        onClick ? 'pointer' : ''
      } ${narrow ? 'flex-wrap pl3' : 'pl4 items-center '} w-100 h-100 pa2 flex justify-between relative`}
      onClick={onClick}
      data-test-component="DayCard"
      data-test-element="container"
      data-testid="day-card"
      data-tooltip-id="global-tooltip-id"
      data-tooltip-content={timezoneData}
    >
      <div
        className={`${styles['task-day-aside-border']} br1 bg-red absolute z-1`}
        style={{ backgroundColor: colours?.color ?? 'white' }}
      />
      <div className="w-100" data-test-component="DayCard" data-test-element="task-title">
        <div className="flex items-center">
          {task.status == 'done' && narrow ? (
            <Icon
              icon="tick"
              width="14"
              height="14"
              viewBox="0 0 20 20"
              className="near-black mr1"
              testId="task-completed-icon-tick"
            />
          ) : (
            (task.status === 'done' || task.status === 'in_progress') &&
            !narrow && (
              <div
                className={cn('fw6 f6 pr1', styles.dayCardTaskTitle, {
                  [styles.done]: task.status === 'done',
                  [styles.inProgress]: task.status === 'in_progress',
                })}
              >
                ({taskStatusLookup(task.status)})
              </div>
            )
          )}
          <h3 className="fw5 f6 lh-title near-black truncate">{task.title}</h3>
        </div>
        <p className="lh-copy f6 near-black w-100 truncate">{task.study.name}</p>
      </div>
      <div className={`flex ${narrow ? 'w-100 ui__overflow__scroll_y' : ''}`}>
        {(task.assignees ?? []).slice(0, maxUserBeforeTruncate).map((user, index) => (
          <UserAvatar
            key={user.id}
            tooltip={user.name}
            user={{ name: user.name }}
            className={`${narrow ? 'mr1' : 'ml1'}`}
            dataTestId={`user-avatar-${index}`}
          />
        ))}
        {(task.assignees?.length ?? 0) > maxUserBeforeTruncate && (
          <TruncatedAvatar
            remainder={(task.assignees?.length ?? 0) - maxUserBeforeTruncate}
            className={`${narrow ? 'mr1' : 'ml1'}`}
            dataTestId="truncated-avatar"
          />
        )}
      </div>
    </div>
  );
};

export default DayCard;
