import Sortable from '@/components/Draggable';
import useSortable from '@/components/Draggable/useSortable';
import {
  ApprovalTypes,
  approvalTypeToTextMap,
  ApproversFormData,
  isActive,
  isPositiveInteger,
  lessThanOrEqual,
  mapApproversToFormData,
  mapFormDataToApprovers,
} from '@/components/Glossary/Sections/Approvals/TemplateForm/Approvers.utils';
import Chip from '@/components/UI/Chip/Chip';
import Radio from '@/components/UI/FormElements/Radio';
import SearchSelect from '@/components/UI/SearchSelect';
import UserCard from '@/components/UserCard';
import { missingNameUseEmail } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { Approval } from '@/model/Approval.model';
import type { ID } from '@/model/Common.model';
import type { Team } from '@/model/Team.model';
import type { MainUser } from '@/model/User.model';
import { ErrorMessage } from '@hookform/error-message';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface ApproversProps {
  ActionButtons: JSX.Element;
  onSubmit: (assignees: unknown) => void;
  approval: Approval;
}

const Approvers = ({ ActionButtons, onSubmit, approval }: ApproversProps) => {
  const [approvalUsers, setApprovalUsers] = useState<Array<MainUser>>([]);

  const defaultValues = mapApproversToFormData(approval.approvers);
  const formMethods = useForm({
    defaultValues,
  });
  const { handleSubmit, errors, control, register, getValues, setValue, unregister } = formMethods;
  const { team } = useSelector(({ team: { team } }: { team: { team: Team } }) => ({
    team,
  }));
  const approvers: Array<ID> = useWatch({
    control,
    name: 'approvers',
  });
  const approvalType = useWatch({
    control,
    name: 'type',
  });

  const users =
    team?.users
      ?.filter((user) => isActive(user) || (approval.approvers?.ids.includes(user.id) && approvers?.includes(user.id)))
      .map(missingNameUseEmail) ?? [];

  const sections = useMemo(
    () => [
      {
        items: users,
      },
    ],
    [team, users]
  );

  const usersLookup = users?.reduce<Record<ID, MainUser>>((acc, user) => {
    acc[Number(user.id)] = user;
    return acc;
  }, {});

  const { moveCallback } = useSortable(approvalUsers, setApprovalUsers);

  useEffect(() => {
    if (team?.users) {
      const users =
        approvers?.reduce<Array<MainUser>>((acc, approverId) => {
          const user = team.users.find(({ id }) => id === approverId);
          if (_notNil(user)) {
            acc.push(user);
          }
          return acc;
        }, []) ?? [];
      setApprovalUsers(users);
    }
  }, [team.users, approvers]);

  useEffect(() => {
    if (approvalType !== ApprovalTypes.min_required) {
      const minNumberApprovers = getValues('minNumberApprovers');
      if (minNumberApprovers !== undefined) {
        setValue('minNumberApprovers', undefined);
      }
    }
  }, [approvalType]);

  useEffect(() => {
    // manually register the form control when we hide the controller for 'type' field
    if (_notNil(approvers) && approvers.length > 1) {
      unregister('type');
    } else {
      register('type');
      setValue('type', ApprovalTypes.unordered);
    }
  }, [approvers]);

  const handleOrderingOnSubmit = (formData: ApproversFormData) => {
    onSubmit(mapFormDataToApprovers(formData, approvalUsers));
  };

  const ordered = approvalType === ApprovalTypes.ordered;

  const canSubmit =
    _notNil(approvers) && approvers.length > 0 && approvers.every((userId: any) => isActive(usersLookup[userId]));

  return (
    <FormProvider {...formMethods}>
      <form data-test-component="Approvers" data-test-element="form">
        <div className="flex flex-row  items-start">
          <div className="mb3 mr4" style={{ width: 350 }} data-test-element="approvers-search-select-container">
            <Controller
              name="approvers"
              control={control}
              rules={{
                required: true,
                validate: (data: any) => {
                  if (!Array.isArray(data) || !data.length) {
                    return 'You must select at least one reviewer';
                  }
                },
              }}
              render={({ value, onChange }: ControllerRenderProps) => (
                <div className="ui-card">
                  <SearchSelect
                    className={`${errors.approvers ? 'search_select__error' : ''}`}
                    sections={sections}
                    selected={value}
                    setSelected={onChange}
                    sort
                    sortAccessor={'name'}
                  />
                </div>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="approvers"
              render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
            />
          </div>

          <div className="flex flex-column" style={{ width: 450 }} data-test-element="approvers-detail-container">
            {_notNil(approvers) && approvers.length > 1 && (
              <>
                <h2 className="f5 near-black mb1">Customization</h2>
                <div className="f6 dark-gray mb3">How would you like to order this review?</div>
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ value, onChange }: ControllerRenderProps) => (
                    <>
                      <div className="mb3" data-test-element="approval-type-container">
                        {Object.values(ApprovalTypes).map((approvalType, i) => (
                          <Radio
                            key={approvalType}
                            index={i}
                            checked={value === approvalType}
                            onChange={() => onChange(approvalType)}
                            id={`approval-type-${approvalType}`}
                            name={`approval-type-${approvalType}`}
                            label={approvalTypeToTextMap[approvalType]}
                            value={approvalType}
                            className="mb2"
                          />
                        ))}
                        {value === ApprovalTypes.min_required && (
                          <div style={{ marginLeft: 30 }}>
                            <input
                              type="text"
                              data-test-element="min-approvers-input"
                              name="minNumberApprovers"
                              className={`${errors.minNumberApprovers ? 'input__error' : ''}`}
                              ref={register({
                                required: 'A minimum number is required',
                                validate: {
                                  isPositiveInteger,
                                  lessThanEqualNumberApprovers: lessThanOrEqual(
                                    approvers,
                                    'Enter a number lower than the amount of reviewers'
                                  ),
                                },
                              })}
                              style={{ marginBottom: 0, width: 50 }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="minNumberApprovers"
                              render={({ message }) => <p className="f6 red db pt2">{message}</p>}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                />
              </>
            )}

            {_isNotEmpty(approvalUsers) && (
              <div className="mb3">
                <UserCardContainer>
                  {approvalUsers.map((user, index) => (
                    <div key={user.id}>
                      {index !== 0 && <div className="bt b--moon-gray"></div>}
                      {ordered && approvalUsers.length > 1 ? (
                        <div className="flex flex-row items-center">
                          <Chip value={index + 1} />
                          <Sortable
                            key={user.id}
                            id={user.id}
                            index={index}
                            moveCallback={moveCallback}
                            draggableType={'user-card'}
                            showDraggableIcon
                            className="flex flex-row justify-between items-center w-100"
                          >
                            <div className="flex flex-row">
                              <UserCard
                                name={user.name}
                                email={user.email}
                                inactive={user.pivot.status === 'inactive'}
                              />
                            </div>
                          </Sortable>
                        </div>
                      ) : (
                        <UserCard name={user.name} email={user.email} inactive={user.pivot.status === 'inactive'} />
                      )}
                    </div>
                  ))}
                </UserCardContainer>
              </div>
            )}
          </div>
        </div>

        {React.cloneElement(ActionButtons, {
          onOk: handleSubmit(handleOrderingOnSubmit),
          tooltip: canSubmit ? '' : 'There are inactive users selected',
          canOk: canSubmit,
        })}
      </form>
    </FormProvider>
  );
};

export default Approvers;

const UserCardContainer = ({ children }: { children: ReactNode }) => {
  return <div className="ui-card user-card-container ph3">{children}</div>;
};
