import { formatNumberComingIn } from '@/helpers';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MeasurementPayload } from './Complete';

export const handlePayload = (
  metrics: Record<string, string>,
  date: string | null = null,
  measurementId: ID | null = null,
  subjectId: ID | null = null
) => {
  const keys = Object.keys(metrics).filter((m) => !!metrics[m]);

  const payload: MeasurementPayload = {
    variables: keys.map((k) => ({
      name: k,
      value: formatNumberComingIn(metrics[k]).toString(),
    })),
  };

  if (_notNil(date)) {
    payload['measured_at'] = date;
  }
  if (measurementId) {
    payload['id'] = Number(measurementId);
  }

  if (subjectId) {
    payload['subject_id'] = Number(subjectId);
  }

  return payload;
};
