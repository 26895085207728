// @ts-nocheck: converted from JS

import Approvers from '@/components/Glossary/Sections/Approvals/TemplateForm/Summary/Approvers';
import Conditions from '@/components/Glossary/Sections/Approvals/TemplateForm/Summary/Conditions';
import { studyTypeOptions } from '@/components/Glossary/Sections/Approvals/TemplateForm/TemplateForm.utils';
import { ApprovalSection } from '@/components/Studies/Show';
import Banner from '@/components/UI/Banner';
import FormRender from '@/components/UI/FormRender';
import Summary, { SummaryItem } from '@/components/UI/Summary';
import { _isEmpty } from '@/littledash';
import React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

const ApprovalSummary = ({
  ActionButtons,
  onSubmit,
  approval,
  studyMetadataOptions = [],
  projectMetadataOptions = [],
  reviewTemplates = [],
}) => {
  const metadataOptions = [...studyMetadataOptions, ...projectMetadataOptions];

  // form will not actually get submitted, but FormRender children rely on a form context
  const formMethods = useForm({
    defaultValues: {
      fields: approval.form.groups[0].fields,
    },
  });

  // a field's "name" either comes from the API if already set, or else it needs assigned by the frontend, here we are setting it.
  // it becomes the property on the form's POST payload.
  const groupsWithNames = approval.form.groups.map((group) => ({
    ...group,
    fields: Array.isArray(group.fields)
      ? group.fields.map((field, i) => ({
          ...field,
          name: `fields[${i}].value`,
        }))
      : [],
  }));
  const EMPTY = '-';

  const getStudyType = () => studyTypeOptions.find((option) => option.value === approval.study_type)?.name;
  const getTemplateName = () =>
    reviewTemplates.find((template) => Number(approval.dependencies) === Number(template.id))?.name || 'No dependency';

  return (
    <div data-test-component="ApprovalSummary" data-test-element="container">
      <div className="mb4" style={{ maxWidth: 900 }}>
        <Summary title="Summary">
          <SummaryItem label="Review name">{approval.name}</SummaryItem>
          <SummaryItem label="Applies to">{getStudyType() || EMPTY}</SummaryItem>
          <SummaryItem label="Conditions">
            {_isEmpty(approval.conditions) ? (
              EMPTY
            ) : (
              <Conditions conditions={approval.conditions} metadata={metadataOptions} />
            )}
          </SummaryItem>
          <SummaryItem label="Reviewers">
            <Approvers approvers={approval.approvers} />
          </SummaryItem>
          <SummaryItem label="Review dependency">{getTemplateName()}</SummaryItem>
        </Summary>
      </div>
      <div className="ui-card pa3 dib">
        <Banner info className="w-100 mb4" dismiss={false} center>
          <h3 className="f6 normal lh-title">This is a preview of your form - it can&apos;t be submitted.</h3>
        </Banner>
        <FormProvider {...formMethods}>
          <FormRender groups={groupsWithNames} groupContainer={ApprovalSection} />
        </FormProvider>
      </div>

      {React.cloneElement(ActionButtons, { onOk: () => onSubmit() })}
    </div>
  );
};

export default ApprovalSummary;
