// @ts-nocheck: converted from JS

import { _isNotEmpty } from '@/littledash';

/**
 *
 * @param {*} calculations
 * @param {*} variables
 *
 * In tumour studies the tumour volume calculation requires that
 * the Length is larger than the Width to correctly calculate.
 * Tumours are always measured by length first then width
 */

export const autoSwap = (calculations, variables) => {
  const result = { ...variables };
  calculations.forEach((c) => {
    if (c?.autoSwap) {
      const big = c.measurements.find((f) => f.swap_type === 'length');
      const small = c.measurements.find((f) => f.swap_type === 'width');
      const tempBigVal = result[big.id];
      const tempSmallVal = result[small.id];
      if (Number(result[small.id]) >= Number(result[big.id])) {
        result[big.id] = tempSmallVal;
        result[small.id] = tempBigVal;
      }
    }
  });

  return result;
};

export const getDefaultValues = (measurement) =>
  measurement.reduce((acc, v) => {
    acc = {
      ...acc,
      ...v.variables,
    };
    return acc;
  }, {});

export const checkMeasurementFieldsAreValid = (formValues) => {
  //@ts-expect-error unknown type
  return _isNotEmpty(formValues) && Object.values(formValues).every((f) => f !== '' && !isNaN(f));
};

export const isFormDisabled = ({ submitting, editing, altIdFieldFilled, allMeasurementFieldsFilled, assignAltId }) => {
  let disabled = false;

  if (submitting) {
    disabled = true;
  } else if (assignAltId) {
    if (allMeasurementFieldsFilled && altIdFieldFilled) {
      disabled = true;
    }
  } else if (!editing && allMeasurementFieldsFilled) {
    disabled = true;
  }
  return disabled;
};
