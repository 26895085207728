import { _notNil } from '@/littledash';
import type { AnimalAlertFrom, AnimalAlertV1 } from '@/model/Alert.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';

export const operators: Array<{ label: string; value: AnimalAlertV1['operator'] }> = [
  {
    label: 'is greater than',
    value: 'GREATER_THAN',
  },
  {
    label: 'is less than',
    value: 'LESS_THAN',
  },
  {
    label: 'has increased by',
    value: 'INCREASES_BY',
  },
  {
    label: 'has decreased by',
    value: 'DECREASES_BY',
  },
];

export const types = (unit: string): Array<{ label: string; value: AnimalAlertV1['type'] }> => [
  {
    label: unit,
    value: 'FIXED',
  },
  {
    label: '%',
    value: 'PERCENT',
  },
];

export const compare: Array<{ label: string; value: AnimalAlertFrom }> = [
  {
    label: 'first',
    value: 'FIRST',
  },
  {
    label: 'last',
    value: 'PREVIOUS',
  },
];

export const generateAlertText = (calculations: Array<PresetCalculation>, alert: AnimalAlertV1) => {
  const { operator, value, calculation, type, from } = alert;
  const calc = calculations.find((c) => calculation === c.id);

  const typeNames = types(calc?.unit ?? '').reduce<Record<string, string>>((acc, v) => {
    acc[v.value] = v.label;
    return acc;
  }, {});
  const operatorNames = operators.reduce<Record<string, string>>((acc, v) => {
    acc[v.value] = v.label;
    return acc;
  }, {});
  const fromNames = compare.reduce<Record<string, string>>((acc, v) => {
    acc[v.value] = v.label;
    return acc;
  }, {});

  if (from) {
    return `${calc?.name} ${operatorNames[operator]} ${value} ${typeNames[type]} compared to the ${fromNames[from]} measurement`;
  }

  return `${calc?.name} ${operatorNames[operator]} ${value} ${typeNames[type]}`;
};

const comparedTo: Record<AnimalAlertFrom, string> = {
  FIRST: ' compared to the first measurement',
  PREVIOUS: ' compared to the last measurement',
};

interface GenerateConditionTextProps {
  alert?: AnimalAlertV1;
  calculation?: PresetCalculation;
  displayCalculationName?: boolean;
}

export const generateConditionText = ({
  alert,
  calculation,
  displayCalculationName,
}: GenerateConditionTextProps): JSX.Element | '-' => {
  if (_notNil(calculation) && _notNil(alert)) {
    const operatorLabel = operators?.find(({ value }) => value === alert?.operator)?.label;
    if (_notNil(operatorLabel)) {
      const valueWithUnit =
        alert?.type === 'PERCENT' ? `${alert.value}%` : `${alert.value} ${calculation.unit ?? ''}`.trim();
      const alertComparedTo = _notNil(alert?.from) ? (comparedTo[alert.from] ?? '') : '';
      return (
        <div>
          {(displayCalculationName ?? true) && <span className="fw5">{calculation.name} </span>}
          {operatorLabel} {valueWithUnit} {alertComparedTo}
        </div>
      );
    }
  }
  return '-';
};
