// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import FileUpload from '@/components/UI/FormElements/FileUpload';
import { successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const allowedExtensions = ['.xlsx', '.xls', '.csv'];

const Upload = ({ date, setDate, upload, setUpload, nextStep, loading, existingMeasurements }) => {
  const [errors, setErrors] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();
  const { id: studyId } = useParams<{ id: number }>();

  const handleDownloadFile = (data, headers) => {
    const fileName = headers['file-name'];
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadMeasurementsFile = async () => {
    setDownloadLoading(true);
    try {
      const { data, headers } = await Http.get(apiRoute('studies.measurements.import-template', { id: studyId }), {
        signal: newAbortController().signal,
        responseType: 'blob',
      });
      if (isMounted()) {
        handleDownloadFile(data, headers);
        successToast(`Successfully downloaded measurements template for study`);
      }
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setApiError(error);
      }
    } finally {
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    setErrors(false);
    if (!_isEmpty(upload)) {
      const { size, name } = upload[0];

      const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
      const fileExtensionCheck = name.match(fileExtensionPattern)[0];

      const file = Math.round(size / 1024);
      const errorList = [];

      if (file > 5120) {
        errorList.push('Please select a file less than 5MB');
      }

      if (!allowedExtensions.includes(fileExtensionCheck)) {
        errorList.push('File type invalid, select a CSV, XLS or XLSX.');
      }

      if (!_isEmpty(errorList)) {
        setErrors(errorList);
      }
    }
  }, [upload]);

  if (downloadLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <div className="center" style={{ maxWidth: '560px' }}>
      <div className="ui-card pa3 pa4-l mb3">
        <h3 className="lh-title basier-reg normal f5 f4-l">Upload measurement data</h3>
        {apiError && <ApiErrorBanner error={apiError} />}
        <p className="lh-copy mb0 pt2">
          <a
            href="https://help.benchling.com/hc/en-us/articles/20111362176269-How-to-import-data-into-Benchling-In-Vivo"
            target="_blank"
            rel="noopener noreferrer"
            className="link blue"
          >
            How does this work?
          </a>
        </p>
        <div className="flex mt4">
          <div className="mr2">
            <label>Recorded date</label>
            <DatePickerNative type="date" value={date} onChange={setDate} style={{ marginBottom: 0 }} />
          </div>
          <div className="w-100">
            <label>File upload</label>
            <FileUpload
              limit={1}
              files={upload}
              setFiles={setUpload}
              disabled={loading}
              allowedExtensions={allowedExtensions}
            />
          </div>
        </div>
        {errors && (
          <ul className="list">
            {errors.map((e, i) => (
              <li className="f6 red lh-copy pt2" key={i}>
                {e}
              </li>
            ))}
          </ul>
        )}
        {_isNotEmpty(existingMeasurements) && (
          <Banner dismiss={false} info className="mw6 mt4">
            <h2 className="normal lh-copy f5 pb1">We&apos;ve found existing measurements on this date</h2>
            <p className="f6 lh-copy">
              Values that have previously been recorded on this date will be overwritten by imported values.
            </p>
          </Banner>
        )}
        <p className="pt4 pb3 f6 ma0">Formats supported: CSV, XLS, XLSX. File size cannot exceed 5MB.</p>
        <p className="inline-flex items-center">
          <Button
            disabled={_isEmpty(upload) || date === '' || loading || !_isEmpty(errors)}
            onClick={() => nextStep('match')}
          >
            Continue
          </Button>
          <label className="link blue pl3" onClick={() => downloadMeasurementsFile()}>
            Download import template
          </label>
        </p>
      </div>
    </div>
  );
};

export default Upload;
