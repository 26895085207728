import { useFormContext } from 'react-hook-form@latest';
import { FormField } from '@/components/UI/FormFields/Latest';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils';
import type { FC, CSSProperties } from 'react';

interface TextareaProps {
  value?: string;
  name: string;
  label: string;
  hint?: string;
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
  testId?: string;
  style?: CSSProperties;
  className?: string;
}

const Textarea: FC<TextareaProps> = ({
  value,
  name,
  label,
  disabled = false,
  hint,
  required,
  maxLength = 5000,
  testId = 'textarea',
  style,
  className = '',
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <FormField label={label} name={name} hint={hint} required={required} testPrefix={testId}>
      <textarea
        defaultValue={value}
        {...register(name, getRegisterConfig({ required, maxLength }))}
        className={`${errors?.[name] ? 'input__error' : ''} ${className}`}
        disabled={disabled}
        style={{ marginBottom: 0, ...(style ?? {}) }}
        data-testid={testId}
      />
    </FormField>
  );
};

export default Textarea;
