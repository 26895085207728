// @ts-nocheck: converted from JS

import Radio from '@/components/UI/FormElements/Radio';
import { useDispatch } from 'react-redux';

const MetricChangeFrom = ({ settings }) => {
  const dispatch = useDispatch();

  const updateSettings = (setting) => {
    dispatch({ type: 'SAVE_SETTING', setting });
  };

  return (
    <div className="pa3 bb b--moon-gray">
      <PercentageChange settings={settings} updateSettings={updateSettings} />
    </div>
  );
};

export const PercentageChange = ({ settings, updateSettings }) => {
  const options = {
    value_change_from_first: 'First taken',
    value_change: 'Last taken',
  };

  const handleClick = (event, settings) => {
    updateSettings({ ...settings, changePercentage: event.target.value });
  };

  return (
    <div className="flex items-center">
      <div className="f6 near-black lh-title mr3">% Metric change from</div>
      <div className="flex flex-wrap">
        {Object.keys(options).map((key) => (
          <div key={key} className="dib mr3">
            <Radio
              id={key}
              name="options"
              checked={settings?.changePercentage === key}
              label={options[key]}
              onChange={(e) => handleClick(e, settings)}
              value={key}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MetricChangeFrom;
