import type React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

interface SelectProps {
  name: string;
  isMulti: boolean;
}
const Select: React.FC<SelectProps> = ({ name, isMulti }) => {
  const { control } = useFormContext();

  const formatValue = (value: string | Array<string>) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    } else if (value) {
      if (isMulti) {
        return JSON.parse(value).join(', ');
      }
      return value;
    } else {
      return '-';
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ value }: ControllerRenderProps) => <span>{formatValue(value)}</span>}
    />
  );
};

export default Select;
