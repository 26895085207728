// @ts-nocheck: converted from JS

import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import _map from 'lodash/map';
import React from 'react';

class RemoveFromGroup extends React.Component {
  /**
   * Lifecycle
   */
  constructor(props) {
    super(props);
    this.state = { error: null, isLoading: false };
  }

  /**
   * Rendering
   */

  render() {
    const { error, isLoading } = this.state;

    let what, where;
    if (Array.isArray(this.props.subject) && this.props.subject.length > 1) {
      what = 'these animals';
      where = 'their groups';
    } else {
      what = 'this animal';
      where = 'its group';
    }

    return (
      <form className={`ui__narrow-modal ${isLoading && 'disabled-form'}`}>
        <div className="pb3 tc">
          <h3 className="normal f4 pb2">
            Are you sure you want to remove {what} from {where}?
          </h3>
        </div>
        {error && <p className="red">{error}</p>}
        <div>
          <button type="submit" onClick={this.removeFromGroup} className="button w-100">
            Remove from group
          </button>
          <a className="button plain db w-100 mt2" onClick={this.props.closeModal}>
            Cancel
          </a>
        </div>
      </form>
    );
  }

  /**
   * Methods
   */

  removeFromGroup = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    this.bulkRemove(Array.isArray(this.props.subject) ? this.props.subject : [this.props.subject])
      .then(() => {
        this.props.handleCallback('Successfully removed from group');
      })
      .catch((e) => this.setState({ error: e.response?.data?.message, isLoading: false }));
  };

  bulkRemove = (subjects) => {
    const data = _map(subjects, (subject) => {
      return {
        id: subject.id,
        study_group_id: null,
      };
    });

    return Http.patch(apiRoute('animals.update.bulk'), { animals: data });
  };
}

export default RemoveFromGroup;
