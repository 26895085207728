import type {
  DataTableColumn,
  DataTableColumnUpdateRequest,
  DataTableType,
} from '@/components/UI/DataTable/DataTable.model';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

interface EditDatatableMeasObsColumnProps {
  tableType: DataTableType;
  column: DataTableColumn;
  columns: Array<DataTableColumn>;
  formData: EditDataTableMeasObsColumnForm;
  closeModal: () => void;
  onSave: (columnUpdate: DataTableColumnUpdateRequest) => Promise<void>;
}

interface EditDataTableMeasObsColumnForm {
  referenceDate: string;
}

export const EditDatatableMeasObsColumn: FC<EditDatatableMeasObsColumnProps> = ({
  column,
  formData,
  onSave,
  closeModal,
}) => {
  const onSubmit = async (update: EditDataTableMeasObsColumnForm) => {
    closeModal();
    await onSave({
      id: column.id,
      measurement: { measured_at: update.referenceDate },
    });
  };

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    control,
  } = useForm<EditDataTableMeasObsColumnForm>({
    mode: 'onChange',
    defaultValues: { ...formData },
  });

  return (
    <ModalContainer size="xnarrow">
      <ModalHeader title="Edit column" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="ph3 pv2 mt2">
          <label>Date</label>
          <Controller
            control={control}
            name="referenceDate"
            render={({ value, onChange }: ControllerRenderProps) => {
              return <DatePickerNative value={value} step="any" max={DateUtils.dateNow()} onChange={onChange} />;
            }}
          />
        </div>
      </form>
      <ModalActions
        className="pa3 mt3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitButtonProps={{ disabled: !isValid || !isDirty || isSubmitting }}
      />
    </ModalContainer>
  );
};
