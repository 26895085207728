// @ts-nocheck: converted from JS

import ApprovalForm from '@/components/Glossary/Sections/Approvals/TemplateForm/ApprovalForm';
import Approvers from '@/components/Glossary/Sections/Approvals/TemplateForm/Approvers';
import General from '@/components/Glossary/Sections/Approvals/TemplateForm/General';
import ApprovalSummary from '@/components/Glossary/Sections/Approvals/TemplateForm/Summary/Summary';
import Http from '@/support/http';
import { api as apiRoute, web as webRoutes } from '@/support/route';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplateForm from './TemplateForm';

const initialSteps = [
  {
    title: 'General',
    Component: General,
    complete: false,
  },
  {
    title: 'Reviewers',
    Component: Approvers,
    complete: false,
  },
  {
    title: 'Review form',
    Component: ApprovalForm,
    complete: false,
  },
  {
    title: 'Summary',
    Component: ApprovalSummary,
    complete: false,
  },
];

const Create = () => {
  const [approval, setApproval] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmissionErrors, setHasSubmissionErrors] = useState(false);
  const history = useHistory();

  const submitApproval = async () => {
    // manually falling back to empty conditions array until react-hook-form v7 (https://github.com/react-hook-form/react-hook-form/issues/3231)
    const approvalData = {
      ...approval,
      conditions: approval.conditions || [],
      dependencies: approval.dependencies ? [approval.dependencies] : [],
    };
    setIsSubmitting(true);
    setHasSubmissionErrors(false);
    try {
      await Http.post(apiRoute('approvals.templates.create'), approvalData);
      history.push(webRoutes('team.studies.approvals'));
    } catch (e) {
      setHasSubmissionErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <TemplateForm
      submitApproval={submitApproval}
      hasSubmissionErrors={hasSubmissionErrors}
      initialSteps={initialSteps}
      isSubmitting={isSubmitting}
      approval={approval}
      setApproval={setApproval}
      formTitle="Create a new review"
      submitButtonText="Save and create"
    />
  );
};

export default Create;
