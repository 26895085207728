// @ts-expect-error: untyped lib
import * as jstat from 'jstat';
import { sum } from 'mathjs';
import { mean } from 'simple-statistics';
import type { AnovaMetric, AnovaTable, OneWayAnovaResults } from './Statistics.model';
import { _isNotEmpty } from '@/littledash.ts';

export const calculatePValueAndAnova = (anovaMetric: AnovaMetric): OneWayAnovaResults => {
  let result: OneWayAnovaResults = {
    anovaTable: {
      treatment: { sumOfSquares: 0, degreesOfFreedom: 0 },
      error: { sumOfSquares: 0, degreesOfFreedom: 0 },
      total: { sumOfSquares: 0, degreesOfFreedom: 0 },
    },
    pvalue: 0,
  };

  if (_isNotEmpty(anovaMetric)) {
    const anovaTable = calculateOneWayAnova(anovaMetric);
    result = { anovaTable: anovaTable, pvalue: calculatePValue(anovaTable) };
  }

  return result;
};

export const calculatePValue = (anovaResult: AnovaTable): number => {
  const pvalue = jstat.ftest(
    anovaResult.treatment.fdist,
    anovaResult.treatment.degreesOfFreedom,
    anovaResult.error.degreesOfFreedom
  );
  return !isNaN(pvalue) ? pvalue : 0;
};

// Compute the F-test for one way ANOVA
export const calculateOneWayAnova = (samples: AnovaMetric): AnovaTable => {
  const ms = meanSquare(samples, true);
  return {
    treatment: {
      sumOfSquares: ms.ss.treatment,
      degreesOfFreedom: ms.df.treatment,
      meanSquare: ms.treatment,
      fdist: ms.treatment / ms.error,
    },
    error: {
      sumOfSquares: ms.ss.error,
      degreesOfFreedom: ms.df.error,
      meanSquare: ms.error,
    },
    total: {
      sumOfSquares: ms.ss.total,
      degreesOfFreedom: ms.df.total,
    },
  };
};

// Comptute the Mean Square
const meanSquare = (samples: Array<Array<number>>, verbose?: boolean) => {
  const ss = sumOfSquares(samples);
  const df = degreesOfFreedom(samples);

  const treatment = ss.treatment / df.treatment;
  const error = ss.error / df.error;

  const results = {
    treatment: !isNaN(treatment) ? treatment : 0,
    error: !isNaN(error) ? error : 0,
    ss,
    df,
  };

  return results;
};

// Compute the Sum of Squares
const sumOfSquares = (samples: Array<Array<number>>) => {
  let treatment = 0;
  let error = 0;
  const fullMean = calculateFullMean(samples);

  samples.forEach((sample) => {
    const mu = mean(sample);
    sample.forEach(function (val) {
      error += (val - mu) * (val - mu);
    });
    treatment += (mu - fullMean) * (mu - fullMean) * sample.length;
  });

  return {
    total: treatment + error,
    treatment,
    error,
  };
};

const calculateFullMean = (samples: Array<Array<number>>): number => {
  let total = 0,
    count = 0;
  samples.forEach((sample) => {
    total += sum(sample);
    count += sample.length;
  });
  return total / count;
};

// Compute the Degrees of Freedom
export const degreesOfFreedom = (samples: Array<Array<number>>) => {
  const n = samples.reduce((acc, sample) => (acc += sample.length), 0);

  const treatment = samples.length - 1; // Numerator
  const error = n - samples.length;

  return {
    total: treatment + error,
    treatment,
    error,
  };
};

export const sumPValues = (anovaResults: Record<string, OneWayAnovaResults>): number => {
  return parseFloat(
    Object.values(anovaResults)
      .reduce((sum, result) => sum + result.pvalue, 0)
      .toFixed(5)
  );
};
