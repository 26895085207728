import Indicator from '@/components/UI/Indicator';
import { _isNil } from '@/littledash';
import type React from 'react';

interface WorkflowAlertsProps {
  filters: Array<Record<string, any>>;
  updateFilters: (filters: WorkflowAlertsProps['filters'], filterMatch: boolean) => void;
  alertCount: number;
}

const WorkflowAlerts: React.FC<WorkflowAlertsProps> = ({ filters, updateFilters, alertCount }) => {
  const handleClick = () => {
    if (
      _isNil(
        filters?.find(
          (filter) => filter['category']?.['value'] === 'alerts' && filter['option']?.['value'] === 'alert_unresolved'
        )
      )
    ) {
      updateFilters(
        [
          ...filters,
          {
            category: {
              name: 'Alert',
              value: 'alerts',
              index: '4',
            },
            operation: {
              name: 'is',
              index: 0,
            },
            option: {
              name: 'Unresolved',
              value: 'alert_unresolved',
            },
          },
        ],
        true
      );
    }
  };

  return <Indicator indicatorStyle={{ minWidth: 24 }} onClick={handleClick} counter={alertCount} />;
};

export default WorkflowAlerts;
