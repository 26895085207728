// @ts-nocheck: converted from JS

import Assignees from '@/components/Glossary/Sections/Requests/Create/Assignees';
import Name from '@/components/Glossary/Sections/Requests/Create/Name';
import RequestForm from '@/components/Glossary/Sections/Requests/Create/RequestForm';
import RequestSummary from '@/components/Glossary/Sections/Requests/Create/Summary/Summary';
import { successToast } from '@/helpers';
import Http from '@/support/http';
import { api as apiRoute, web as webRoutes } from '@/support/route';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplateForm from './TemplateForm/TemplateForm';

const initialSteps = [
  {
    title: 'Name',
    Component: Name,
    complete: false,
  },
  {
    title: 'Assignees',
    Component: Assignees,
    complete: false,
  },
  {
    title: 'Request form',
    Component: RequestForm,
    complete: false,
  },
  {
    title: 'Summary',
    Component: RequestSummary,
    complete: false,
  },
];

const Create = () => {
  const [request, setRequest] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmissionErrors, setHasSubmissionErrors] = useState(false);
  const history = useHistory();

  const submitRequest = async () => {
    setIsSubmitting(true);
    setHasSubmissionErrors(false);
    try {
      await Http.post(apiRoute('requests.templates.index'), request);
      history.push(webRoutes('team.studies.requests'));
      successToast('Successfully created request template');
    } catch (e) {
      setHasSubmissionErrors(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <TemplateForm
      submitRequest={submitRequest}
      hasSubmissionErrors={hasSubmissionErrors}
      initialSteps={initialSteps}
      isSubmitting={isSubmitting}
      request={request}
      setRequest={setRequest}
      formTitle={'Create a new request'}
      submitButtonText="Save and create"
    />
  );
};

export default Create;
