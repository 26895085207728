import UserAvatar from '@/components/UI/UserAvatar';
import UserAvatarPlus from '@/components/UI/UserAvatarPlus';
import type { Study } from '@/model/Study.model';
import type { CSSProperties, FC } from 'react';

interface StudyUsersProps {
  study: Study;
  customStyle?: CSSProperties;
}

const StudyUsers: FC<StudyUsersProps> = ({ study, customStyle }) => {
  const { author, owner, users } = study;
  const baseClassNames = 'ml1 dib';

  return (
    <div style={customStyle}>
      {author && (
        <UserAvatar
          user={author}
          className={baseClassNames}
          bgColorClass="bg-near-black"
          tooltip={`${author.name} (Author)`}
        />
      )}
      {owner && (
        <UserAvatar
          user={owner}
          className={baseClassNames}
          bgColorClass="bg-near-black"
          tooltip={`${owner.name} (Owner)`}
        />
      )}
      {users && <UserAvatarPlus users={Array.isArray(users) ? users : users.data} author={author} owner={owner} />}
    </div>
  );
};

export default StudyUsers;
