import { uuid } from '@/littledash';
import type { ID } from '@/model/Common.model';

interface TempID {
  temp_id?: ID;
}

export const tempEntityArrayToMap = <T extends TempID>(entities: Array<T>): Map<ID, T> =>
  new Map(
    (entities ?? []).map((entity) => {
      const tempId = entity?.temp_id ?? uuid();
      return [tempId, { ...entity, temp_id: tempId }];
    })
  );
