// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import ActionButtons from '@/components/Glossary/Sections/Approvals/ActionButtons';
import Loading from '@/components/Loading';
import { useSteps } from '@/components/Steps';
import Samples from '@/components/Studies/Requests/Create/Samples';
import RequestDetails from '@/components/Studies/Requests/RequestDetails';
import Summary from '@/components/Studies/Requests/Summary';
import StepLinkList from '@/components/UI/StepLinkList';
import SubHeader from '@/components/UI/SubHeader';
import { _isNil, _notNil } from '@/littledash';
import { useFetchEntity } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute, web as webRoutes, web as webRoute } from '@/support/route';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { parseQueryString } from '@/helpers';

const initialSteps = [
  {
    title: 'Request details',
    Component: RequestDetails,
    complete: false,
  },
  {
    title: 'Samples',
    Component: Samples,
    complete: false,
  },
  {
    title: 'Summary',
    Component: Summary,
    complete: false,
  },
];

const Create = () => {
  const [postErrors, setPostErrors] = useState(false);
  const [request, setRequest] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const params = parseQueryString(window.location.search);

  const { steps, activeStep, nextStep, backStep, reset } = useSteps(initialSteps);

  const ActivePanel = steps[activeStep - 1].Component;
  const isFirstStep = activeStep === 1;
  const isLastStep = activeStep === steps.length;
  const requestTemplateId = params.requestTemplate;

  const {
    entity: requestTemplate,
    entityLoading: requestTemplateLoading,
    entityError: requestTemplateError,
  } = useFetchEntity({ entityType: 'requestTemplate', params: { requestId: requestTemplateId } });

  // No requestTemplate in query string, redirect to study
  if (_isNil(requestTemplateId) && _notNil(requestTemplateError)) {
    history.push(webRoute('studies.show', { id }));
  }

  const {
    entity: study,
    entityLoading: studyLoading,
    entityError: studyError,
  } = useFetchEntity({ entityType: 'study', params: { id } });

  const isLoading = studyLoading || requestTemplateLoading || _isNil(requestTemplate) || _isNil(study);
  const fetchErrors = studyError || requestTemplateError;
  const hasErrors = fetchErrors || postErrors;

  if (isLoading && !hasErrors) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  const handlePanelSubmission = async () => {
    if (isLastStep) {
      await submitRequest(request);
    } else {
      nextStep();
    }
  };

  const handleBackClick = () => {
    if (isFirstStep) {
      reset();
      history.push(webRoutes('studies.show', { id }));
    } else {
      backStep();
    }
  };

  const submitRequest = async (request) => {
    setIsSubmitting(true);
    const payload = {
      form_data: request.form_data,
    };
    if (_notNil(requestTemplateId)) {
      payload.template_id = params.requestTemplate;
    }
    if (_notNil(request.due_date)) {
      payload.due_date = request.due_date;
    }
    if (_notNil(request.samples)) {
      payload.samples = request.samples.map((sample) => sample.id);
    }
    try {
      await Http.post(
        apiRoute('studies.requests.create', {
          study: id,
        }),
        payload
      );
      setIsSubmitting(false);
      history.push(webRoute('studies.show', { id }));
    } catch (errors) {
      setPostErrors(errors);
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <SubHeader linkToText={study?.name} link={webRoute('studies.show', { id })} />
      {_isNil(requestTemplate) && _notNil(requestTemplateError) ? (
        <ApiErrorBanner error={requestTemplateError} errorType={ApiErrorType.SUBMIT} />
      ) : (
        <div className="ph4">
          <div className={'flex flex-row justify-between items-center mb4 pv3'}>
            <h1 className="f3 normal pb2">Create a request</h1>
            <StepLinkList steps={steps} activeStep={activeStep} />
          </div>
          {postErrors && <ApiErrorBanner error={postErrors} errorType={ApiErrorType.SUBMIT} />}
          {fetchErrors && <ApiErrorBanner error={fetchErrors} errorType={ApiErrorType.FETCH} />}
          <ActivePanel
            request={request}
            setRequest={setRequest}
            requestTemplate={requestTemplate}
            onSubmit={handlePanelSubmission}
            ActionButtons={
              <ActionButtons
                canOk={!isSubmitting}
                onCancel={handleBackClick}
                cancelText={'Back'}
                okText={isLastStep ? 'Finish and create' : 'Continue'}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default Create;
