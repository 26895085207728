import style from '@/constants/colors.module.scss';

export interface ColorDetail {
  color: string;
  background?: { color: string; class: string };
}

interface Colors {
  color_01: ColorDetail;
  color_02: ColorDetail;
  color_03: ColorDetail;
  color_04: ColorDetail;
  color_05: ColorDetail;
  color_06: ColorDetail;
  color_07: ColorDetail;
  color_08: ColorDetail;
  color_09: ColorDetail;
  color_10: ColorDetail;
  color_11: ColorDetail;
  color_12: ColorDetail;
  color_13: ColorDetail;
  color_14: ColorDetail;
  color_15: ColorDetail;
  color_16: ColorDetail;
  color_17: ColorDetail;
  color_18: ColorDetail;
  color_19: ColorDetail;
  color_20: ColorDetail;
  color_21: ColorDetail;
  color_22: ColorDetail;
  color_23: ColorDetail;
  color_24: ColorDetail;
}

export type ColorKey = keyof Colors;
export const colors: Colors = {
  color_01: { color: style.color_01, background: { color: style.color_01_bg, class: style.bg_color_01 } },
  color_02: { color: style.color_02, background: { color: style.color_02_bg, class: style.bg_color_02 } },
  color_03: { color: style.color_03, background: { color: style.color_03_bg, class: style.bg_color_03 } },
  color_04: { color: style.color_04, background: { color: style.color_04_bg, class: style.bg_color_04 } },
  color_05: { color: style.color_05, background: { color: style.color_05_bg, class: style.bg_color_05 } },
  color_06: { color: style.color_06, background: { color: style.color_06_bg, class: style.bg_color_06 } },
  color_07: { color: style.color_07, background: { color: style.color_07_bg, class: style.bg_color_07 } },
  color_08: { color: style.color_08, background: { color: style.color_08_bg, class: style.bg_color_08 } },
  color_09: { color: style.color_09, background: { color: style.color_09_bg, class: style.bg_color_09 } },
  color_10: { color: style.color_10, background: { color: style.color_10_bg, class: style.bg_color_10 } },
  color_11: { color: style.color_11, background: { color: style.color_11_bg, class: style.bg_color_11 } },
  color_12: { color: style.color_12, background: { color: style.color_12_bg, class: style.bg_color_12 } },
  color_13: { color: style.color_13, background: { color: style.color_13_bg, class: style.bg_color_13 } },
  color_14: { color: style.color_14, background: { color: style.color_14_bg, class: style.bg_color_14 } },
  color_15: { color: style.color_15, background: { color: style.color_15_bg, class: style.bg_color_15 } },
  color_16: { color: style.color_16, background: { color: style.color_16_bg, class: style.bg_color_16 } },
  color_17: { color: style.color_17, background: { color: style.color_17_bg, class: style.bg_color_17 } },
  color_18: { color: style.color_18, background: { color: style.color_18_bg, class: style.bg_color_18 } },
  color_19: { color: style.color_19, background: { color: style.color_19_bg, class: style.bg_color_19 } },
  color_20: { color: style.color_20, background: { color: style.color_20_bg, class: style.bg_color_20 } },
  color_21: { color: style.color_21, background: { color: style.color_21_bg, class: style.bg_color_21 } },
  color_22: { color: style.color_22, background: { color: style.color_22_bg, class: style.bg_color_22 } },
  color_23: { color: style.color_23, background: { color: style.color_23_bg, class: style.bg_color_23 } },
  color_24: { color: style.color_24, background: { color: style.color_24_bg, class: style.bg_color_24 } },
};
export const defaultColor: ColorDetail = { color: style.default_blue };
export const colorKeys = Object.keys(colors) as Array<ColorKey>;
export const colorSwatch = Object.values(colors).map((details: ColorDetail) => details.color) as Array<string>;

export const getColorDetail = (key: ColorKey): ColorDetail => colors[key];
