// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Table from '@/components/UI/Table';
import { _isEmpty, _isNotEmpty, safelyDecodeURIComponent } from '@/littledash';
import { ID } from '@/model/Common.model';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import React, { useEffect, useState } from 'react';

interface ProjectProps {
  projectId: ID;
}

const Project: React.FC<ProjectProps> = ({ projectId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});

  const metaColumns = [
    {
      Header: 'Name',
      accessor: 'title',
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({ row: { original } }) => {
        if (original.field_type === 'date') {
          return <DateRenderer value={original.value} defaultResponse="-" />;
        }
        if (Array.isArray(original.value)) {
          return original.value.map(safelyDecodeURIComponent).join(', ');
        }
        return original.value;
      },
    },
  ];

  const rolesColumns = [
    {
      Header: 'Name',
      accessor: 'user.name',
    },
    {
      Header: 'Role',
      accessor: 'role.name',
    },
  ];

  const fetchProject = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('projects.get', { projectId }));
      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  if (loading || _isEmpty(data)) {
    return <Loading />;
  }

  return (
    <>
      {error && (
        <ApiErrorBanner
          className="mb4 mw6"
          title="There was an error retrieving project details"
          text="An error has occurred retrieving project information, please try again later. If this keeps occurring please contact support."
          error={error}
        />
      )}
      <h2 className="pb3">{data.name}</h2>
      {_isNotEmpty(data.metadata) && (
        <>
          <div className="ui-card">
            <Table data={data.metadata} columns={metaColumns} />
          </div>
        </>
      )}
      {_isNotEmpty(data.roles) && (
        <>
          <h3 className="pb3 pt4 f5 lh-title">Project roles</h3>
          <div className="ui-card">
            <Table data={data.roles} columns={rolesColumns} />
          </div>
        </>
      )}
    </>
  );
};

export default Project;
