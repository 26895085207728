import NoDataCard from '@/components/NoDataCard';
import { _isEmpty } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Dose } from '@/model/Dose.model';
import type { Study } from '@/model/Study.model';
import type React from 'react';
import Dosage from './Dosage';

interface DoseListProps {
  study: Study;
  subject: Animal;
  handleCallback: () => void;
  dosages: Array<Dose>;
}

const DoseList: React.FC<DoseListProps> = ({ study, subject, handleCallback, dosages }) => {
  if (_isEmpty(dosages)) {
    return (
      <div className="ma4">
        <NoDataCard className="tc" title="Dosages you record for this animal will appear here" dark />
      </div>
    );
  }

  return (
    <>
      {dosages.map((dosage, i) => (
        <Dosage
          key={dosage.id}
          study={study}
          subject={subject}
          handleCallback={handleCallback}
          dosage={dosage}
          index={i}
        />
      ))}
    </>
  );
};

export default DoseList;
