import Tooltip from '@/components/UI/Tooltip';
import { classNames, formatNumber, isNumericString } from '@/helpers';
import { _notNil } from '@/littledash';
import type { Nullable } from '@/model/Common.model';
import type { FC, FocusEventHandler, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import type {
  DataTableCellAlertUpdateEvent,
  DataTableCellComponentProps,
  DataTableCellSelectionChangeEvent,
  DataTableCellState,
  DataTableCellStateChangeEvent,
  DataTableCellUpdateEvent,
} from '../DataTable.model';
import { DataTableCellSelectionState, DataTableEvent } from '../DataTable.model';
import styles from '../DataTable.module.scss';
import { toCellRef } from '../DataTable.util';

const formatCellValue = (value: string, active: boolean): string =>
  !active && isNumericString(value) ? `${formatNumber(value)}` : value;
const updateCellValue = (element: HTMLDivElement, value: Nullable<string>) => {
  element.innerText = formatCellValue(value ?? '', element.getAttribute('data-active') === 'true');
};

export const DataTableDefaultCell: FC<DataTableCellComponentProps> = ({
  coordinate,
  dataTableService,
  positionOffset,
}) => {
  const { column, row } = coordinate;
  const idCoordinate = useMemo(
    () =>
      dataTableService.fromIndexCoordinate({
        row,
        column,
      }),
    [row, column, dataTableService]
  );
  const [active, setActive] = useState(false);
  const [hasAlert, setHasAlert] = useState(false);
  const [cellState, setCellState] = useState<DataTableCellState>(dataTableService.cellState(idCoordinate));

  const [tooltip, setTooltip] = useState<string | undefined>(
    (dataTableService.cellStatus(idCoordinate) as { error: string })?.error
  );
  const [selected, setCellSelected] = useState<DataTableCellSelectionState>(DataTableCellSelectionState.notSelected);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (_notNil(inputRef.current) && _notNil(idCoordinate)) {
      updateCellValue(inputRef.current, dataTableService.cellData(idCoordinate)?.value);
      setHasAlert((dataTableService.cellAlerts(idCoordinate)?.alerts?.size ?? 0) > 0);
      setCellState(dataTableService.cellState(idCoordinate));
    }
  }, [inputRef, idCoordinate]);

  useEffect(() => {
    if (_notNil(idCoordinate)) {
      const ref = toCellRef(idCoordinate);

      if (dataTableService.selection?.contains(coordinate) ?? false) {
        setCellSelected(
          dataTableService.selection?.from.column === coordinate.column &&
            dataTableService.selection?.from.row === coordinate.row
            ? DataTableCellSelectionState.originSelected
            : DataTableCellSelectionState.highlighted
        );
      } else {
        setCellSelected(DataTableCellSelectionState.notSelected);
      }

      const cellUpdateListener = (event: DataTableCellUpdateEvent) => {
        if (Object.prototype.hasOwnProperty.call(event.detail, ref) && _notNil(inputRef.current)) {
          updateCellValue(inputRef.current, event.detail[ref].value);
          setCellState(dataTableService.cellState(idCoordinate));
        }
      };
      const cellAlertUpdateListener = (event: DataTableCellAlertUpdateEvent) => {
        if (Object.prototype.hasOwnProperty.call(event.detail.cellAlerts, ref)) {
          setHasAlert((event.detail.cellAlerts?.[ref]?.alerts?.size ?? 0) > 0);
        }
      };
      const cellSelectionChangeListener = (event: DataTableCellSelectionChangeEvent) => {
        const fromCoordinate = event.detail.selection.from;
        if (event.detail.selection?.contains(coordinate)) {
          setCellSelected(
            fromCoordinate.column === coordinate.column && fromCoordinate.row === coordinate.row
              ? DataTableCellSelectionState.originSelected
              : DataTableCellSelectionState.highlighted
          );
        } else {
          setCellSelected(DataTableCellSelectionState.notSelected);
        }
        if (
          event.detail.activate &&
          inputRef.current != null &&
          fromCoordinate.column === coordinate.column &&
          fromCoordinate.row === coordinate.row
        ) {
          inputRef.current.setAttribute('data-active', 'true');
          inputRef.current.contentEditable = 'true';
          updateCellValue(
            inputRef.current,
            (event.detail.content?.length ?? 0) > 0
              ? event.detail.content
              : dataTableService.cellData(idCoordinate)?.value
          );
          const selection = window.getSelection();
          const range = document.createRange();
          selection?.removeAllRanges();
          range.selectNodeContents(inputRef.current);
          range.collapse(false);
          selection?.addRange(range);
          inputRef.current.focus();
          setActive(true);
        }
      };

      const cellStateChangeListener = (event: DataTableCellStateChangeEvent) => {
        if (_notNil(idCoordinate)) {
          setCellState((previousCellState) => {
            if (event.detail.invalidCells.has(ref) || previousCellState !== 'valid') {
              const updatedCellState = dataTableService.cellState(idCoordinate);
              if (updatedCellState === 'invalid' || updatedCellState === 'warning') {
                setTooltip((dataTableService.cellStatus(idCoordinate) as { error: string })?.error);
              } else {
                setTooltip(undefined);
              }
              return updatedCellState;
            }
            return previousCellState;
          });
        }
      };
      dataTableService.subscribe(DataTableEvent.CellUpdate, cellUpdateListener);
      dataTableService.subscribe(DataTableEvent.CellAlertUpdate, cellAlertUpdateListener);
      dataTableService.subscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
      dataTableService.subscribe(DataTableEvent.CellStateChange, cellStateChangeListener);

      return () => {
        dataTableService.unsubscribe(DataTableEvent.CellUpdate, cellUpdateListener);
        dataTableService.unsubscribe(DataTableEvent.CellAlertUpdate, cellAlertUpdateListener);
        dataTableService.unsubscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
        dataTableService.unsubscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
      };
    }
  }, [dataTableService, idCoordinate]);

  useEffect(() => {
    if (active) {
      window.addEventListener('mousewheel', handleScroll, { passive: true });
    }
    return () => {
      window.removeEventListener('mousewheel', handleScroll);
    };
  }, [active]);

  const handleScroll: EventListener = (event) => {
    if (active && !dataTableService.readonly) {
      event.stopPropagation();
      event.preventDefault();
      if (_notNil(inputRef.current)) {
        inputRef.current?.blur();
      }
    }
  };
  const handleMouseDown: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: event.shiftKey });
    }
  };
  const handleMouseEnter: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: true });
    }
  };
  const handleMouseUp: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: true });
    }
  };
  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!active && !dataTableService.readonly) {
      setActive(true);
      if (inputRef.current != null) {
        inputRef.current.contentEditable = 'true';
        const selection = window.getSelection();
        const range = document.createRange();
        selection?.removeAllRanges();
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
        selection?.addRange(range);
        inputRef.current.focus();
      }
    }
  };
  const handleBlur: FocusEventHandler<HTMLDivElement> = (event) => {
    event.target.contentEditable = 'false';
    event.target.setAttribute('data-active', 'false');
    const value = event.target.innerText.trim();
    if (_notNil(idCoordinate)) {
      dataTableService.updateCells([
        {
          column_id: idCoordinate.column,
          row_id: idCoordinate.row,
          value: value.length === 0 ? null : value,
        },
      ]);
    }
    updateCellValue(event.target, value);
    setActive(false);
  };
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.key) {
      case 'Escape': {
        event.stopPropagation();
        event.preventDefault();
        if (_notNil(inputRef.current)) {
          inputRef.current.innerText = dataTableService.cellData(idCoordinate)?.value ?? '';
          inputRef.current.blur();
        }
        break;
      }
      case 'Enter': {
        if (!event.ctrlKey) {
          event.stopPropagation();
          event.preventDefault();
          inputRef.current?.blur();
          const workflowStepExecuted = dataTableService.workflowService.workflowActionExit(idCoordinate?.column);
          if (!workflowStepExecuted) {
            dataTableService.moveSelection(event.shiftKey ? 'UP' : 'DOWN');
          }
        }
        break;
      }
      case 'Tab': {
        event.stopPropagation();
        event.preventDefault();
        inputRef.current?.blur();
        const workflowStepExecuted = dataTableService.workflowService.workflowActionExit(idCoordinate?.column);
        if (!workflowStepExecuted) {
          dataTableService.moveSelection(event.shiftKey ? 'LEFT' : 'RIGHT');
        }
        break;
      }
    }
  };

  const { cellHeight, cellWidth } = dataTableService.dimensions;
  const cellType = dataTableService.columnByIndex(coordinate?.column)?.type;

  return (
    <div
      className={classNames(styles['data-table-cell-container'], { [styles['data-table-cell-alert']]: true })}
      data-test-component="DataTableDefaultCell"
      data-test-element="container"
      data-test-key={`(${column},${row})`}
      style={{
        position: 'absolute',
        top: positionOffset.y,
        left: positionOffset.x,
        zIndex: active ? 1 : 0,
        overflow: active ? 'visible' : 'hidden',
        width: `${cellWidth}px`,
        height: `${cellHeight}px`,
      }}
    >
      <Tooltip render={tooltip}>
        <div
          ref={inputRef}
          className={`${styles['data-table-cell']} ${styles[selected]} ${active ? styles['active'] : ''} ${
            styles[cellState]
          }`}
          data-celltype={cellType}
          data-state={cellState}
          data-active={active ? 'true' : 'false'}
          data-test-component="DataTableDefaultCell"
          data-test-element="content"
          style={{ height: active ? 'auto' : '100%' }}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseUp={handleMouseUp}
          onDoubleClick={handleDoubleClick}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          contentEditable={active}
        >
          {formatCellValue(dataTableService.cellData(idCoordinate)?.value ?? '', active)}
        </div>
      </Tooltip>
      {hasAlert && <div className={styles['data-table-cell-alert']} data-test-element="alert-chip" />}
    </div>
  );
};
