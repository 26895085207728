// @ts-nocheck: converted from JS

import { returnSubmitPromiseArr } from '@/components/Glossary/Show.utils';
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import SearchSelect from '@/components/UI/SearchSelect';
import { sortArrAlphabetically, successToast } from '@/helpers';
import Http from '@/support/http';
import { RouteService } from '@/support/RouteService';
import { useEffect, useState } from 'react';
import SelectedList from './SelectedList';

const SampleTypes = ({ glossary = [] }) => {
  const [selected, setSelected] = useState([]);
  const [initialsamples, setInitialsamples] = useState([]);
  const [samples, setSamples] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState(false);

  const setInitialState = () => {
    setLoading(true);

    const { url } = RouteService.legacyApi({ apiRoute: 'team-glossary.list', query: { group: 'samples' } });
    return Http.get(url)
      .then((result) => {
        setInitialsamples(JSON.parse(JSON.stringify(result.data.data)));
        setSelected(result.data.data.map((r) => r.id));
        setSamples(result.data.data);
        setLoading(false);
      })
      .catch((error) => setResponseError(error));
  };

  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    if (!loading) {
      const tempsamples = [...samples].filter((o) => selected.includes(o.id));
      selected.map((s) => {
        if (!tempsamples.map((o) => o.id).includes(s)) {
          const fromGlossary = [...glossary].find((g) => Number(g.id) === Number(s));
          const defaultProps = {
            type: 'none',
            options: {
              details: [],
            },
          };
          tempsamples.push({
            ...fromGlossary,
            ...defaultProps,
          });
        }
      });

      return setSamples(tempsamples);
    }
  }, [selected]);

  const handleInput = (index, key, val) => {
    const tempSamples = [...samples];
    tempSamples[index][key] = val === '' ? null : val;
    return setSamples(tempSamples);
  };

  const handleSubmit = () => {
    setLoading(true);
    return returnSubmitPromiseArr(initialsamples, samples)
      .then(() => {
        successToast('Successfully saved sample types');
        setInitialState();
      })
      .catch((error) => setResponseError(error));
  };

  return (
    <div>
      <div>
        <Header mainHeaderText="Samples" />
        <p className="f6 pb2">
          Can&apos;t find a sample type that you are looking for?
          <a
            target="_blank"
            without
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="mailto:support@benchling.com"
          >
            Let us know.
          </a>
        </p>
        {responseError && (
          <Banner critical dismiss={false} className="mw6 mv3">
            <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
            <p className="f6 pb3 lh-copy">
              An error has occurred when submitting the form, please try again later. If this keeps occurring please
              contact support.
            </p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
      </div>
      <div className={`${loading ? 'ui__disabled' : ''} flex flex-wrap justify-between items-top mv3`}>
        <div className="w-40">
          <div className="ui-card">
            <SearchSelect
              sections={[
                {
                  title: 'All samples',
                  items: [...glossary],
                },
              ]}
              selected={selected}
              setSelected={setSelected}
              sort
            />
          </div>
        </div>
        <div className="w-60">
          {loading ? (
            <Loading txt="Fetching Observations..." />
          ) : (
            <SelectedList handleInput={handleInput} samples={sortArrAlphabetically(samples)} />
          )}
        </div>
      </div>
      <div className="pv3 mt4 bt b--moon-gray">
        <Button disabled={loading} className="mr3" onClick={() => handleSubmit()}>
          Save
        </Button>
        <Button plain onClick={() => setInitialState()}>
          Undo changes
        </Button>
      </div>
    </div>
  );
};

export default SampleTypes;
