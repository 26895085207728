import Button from '@/components/UI/Button';
import type {
  DataTableColumn,
  DataTableColumnId,
  DataTableFeatureFlags,
  DataTableWorkflow,
  DataTableWorkflowAction,
} from '@/components/UI/DataTable/DataTable.model';
import Radio from '@/components/UI/FormElements/Radio';
import Icon from '@/components/UI/Icon';
import Select from '@/components/UI/Select';
import type { SelectOption } from '@/components/UI/Select/Select';
import { classNames, isIsoDurationString } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { formatDuration } from 'date-fns';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Control, Controller, ControllerRenderProps, InputState, useFieldArray, useForm } from 'react-hook-form';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest
import type { Validate } from 'react-hook-form/dist/types/validator';
import { parse as parseDuration } from 'tinyduration';
import styles from './DataTableWorkflowConfigure.module.scss';

interface DataTableWorkflowConfigureProps {
  closeModal: () => void;
  columns: Array<DataTableColumn>;
  flags?: DataTableFeatureFlags;
  workflow: DataTableWorkflow;
  onWorkflowSave: (workflow: DataTableWorkflow) => void;
  onWorkflowClear: () => void;
}

interface SelectColumnWorkflow {
  start: 'select_column';
  startColumn: DataTableColumnId;
}

interface ScanIdWorkflow {
  start: 'scan_id';
  firstAction: DataTableColumnId;
}

interface CommonWorkflow {
  actions: Array<DataTableColumnId>;
}

type WorkflowForm = (ScanIdWorkflow | SelectColumnWorkflow) & CommonWorkflow;
const dataTableColumnIdPattern = /^dtc_[a-zA-Z0-9]{10,22}$/;
const isDataTableColumnId: Validate = (value: string) =>
  dataTableColumnIdPattern.test(value ?? '') || 'Must be a valid column';

const fromWorkflow = (workflow: DataTableWorkflow): Partial<WorkflowForm> => {
  if (_isEmpty(workflow)) {
    return { start: 'scan_id' };
  }
  const [rootActions, ...otherActions] = workflow;
  switch (rootActions.type) {
    case 'OpenSearch': {
      const firstAction = rootActions.destination;
      const actions = otherActions.slice(1).reduce<WorkflowForm['actions']>((acc, action) => {
        switch (action.type) {
          case 'SelectColumn':
          case 'End':
            acc.push(action.source);
            break;
        }
        return acc;
      }, []);

      return { start: 'scan_id', firstAction, actions };
    }
    case 'NextAnimal': {
      const actions = otherActions.slice(1).reduce<WorkflowForm['actions']>((acc, action) => {
        switch (action.type) {
          case 'SelectColumn':
          case 'End':
            acc.push(action.source);
            break;
        }
        return acc;
      }, []);
      return { start: 'select_column', startColumn: rootActions.destination, actions };
    }
    default: {
      return { start: 'scan_id' };
    }
  }
};

export const DataTableWorkflowConfigure: FC<DataTableWorkflowConfigureProps> = ({
  columns,
  flags,
  closeModal,
  workflow,
  onWorkflowSave,
  onWorkflowClear,
}) => {
  const { watch, formState, control, reset, handleSubmit } = useForm<WorkflowForm>({
    mode: 'onChange',
    defaultValues: fromWorkflow(workflow),
  });
  const {
    fields: actionFields,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({ control, name: 'actions' });

  const formData = watch();

  const selectableColumns = useMemo(
    () =>
      columns.filter(
        (column) =>
          column.read_only !== true &&
          (column.type === 'number' ||
            column.type === 'text' ||
            column.type === 'timestamp' ||
            column.type === 'timestampBaseline' ||
            column.type === 'measurement')
      ),
    [columns]
  );

  const usedColumns = useMemo(() => {
    const result = new Set<DataTableColumnId>();
    if (formData.start === 'select_column' && _isNotEmpty(formData.startColumn)) {
      result.add(formData.startColumn);
    }
    if (formData.start === 'scan_id' && _isNotEmpty(formData.firstAction)) {
      result.add(formData.firstAction);
    }
    (formData.actions ?? []).reduce((acc: Set<DataTableColumnId>, action: DataTableColumnId) => {
      if (_isNotEmpty(action)) {
        acc.add(action);
      }
      return acc;
    }, result);

    return result;
  }, [formData]);

  const handleClear = () => {
    reset({ start: 'scan_id' });
    onWorkflowClear();
  };
  const onSubmit = (data: WorkflowForm) => {
    const rootAction: DataTableWorkflowAction =
      data.start === 'scan_id'
        ? {
            type: 'OpenSearch',
            source: 'search',
            destination: data.firstAction,
          }
        : {
            type: 'NextAnimal',
            source: 'next-animal',
            destination: data.startColumn,
          };
    const result = (data.actions ?? []).reduce<DataTableWorkflow>(
      (acc, destination) => {
        const source = acc?.[acc.length - 1]?.destination;
        if (_notNil(source) && source !== 'end') {
          acc.push({ type: 'SelectColumn', source, destination });
        }
        return acc;
      },
      [rootAction]
    );
    const endSource = result?.[result.length - 1]?.destination;
    if (_notNil(endSource) && endSource !== 'end') {
      result.push({ type: 'End', source: endSource, destination: 'end' });
    }
    onWorkflowSave(result);
  };

  return (
    <ModalContainer size="narrow">
      <ModalHeader
        className="bb0 pa3"
        title="Navigation setup"
        subText="Define how to navigate through this DataTable."
        readMoreLink="https://help.benchling.com/hc/en-us/articles/22038901161613"
      />
      <form onSubmit={handleSubmit(onSubmit)} className="ma3">
        <div>
          <div className="f6 near-black">Start by:</div>
          <div className="mv3">
            <Controller
              name="start"
              defaultValue="scan_id"
              control={control}
              rules={{
                required: 'You must select an option to trigger start',
              }}
              render={({ onChange, value }: ControllerRenderProps) => (
                <>
                  <Radio
                    id="scan_id"
                    name="start"
                    onChange={onChange}
                    value="scan_id"
                    checked={value === 'scan_id'}
                    key="scan_id"
                    label="Scanning an ID"
                    className="mb2"
                    index={0}
                  />
                  <Radio
                    id="select_column"
                    name="start"
                    checked={value === 'select_column'}
                    onChange={onChange}
                    value="select_column"
                    key="select_column"
                    label="Choosing a specific column"
                    className="mb2"
                    index={1}
                  />
                </>
              )}
            />
            {formData.start === 'select_column' && (
              <div className="mh4">
                <ColumnSelect
                  control={control}
                  name="startColumn"
                  columns={selectableColumns}
                  usedColumns={usedColumns}
                  defaultValue={formData.startColumn ?? ''}
                />
              </div>
            )}
          </div>
        </div>
        {(formData.start === 'scan_id' || (formData.start === 'select_column' && actionFields.length > 0)) && (
          <div>
            <div className="f6 near-black mb3">Pressing return will move to:</div>
            {formData.start === 'scan_id' && (
              <div className={'flex ' + styles.actionOptions}>
                <ColumnSelect
                  control={control}
                  name="firstAction"
                  columns={selectableColumns}
                  usedColumns={usedColumns}
                  defaultValue={formData.firstAction ?? ''}
                />
              </div>
            )}
            {actionFields.map((field: any, fieldIndex: any) => (
              <div key={field.id} className={'flex ' + styles.actionOptions}>
                <ColumnSelect
                  control={control}
                  name={`actions.${fieldIndex}`}
                  columns={selectableColumns}
                  usedColumns={usedColumns}
                  defaultValue={formData.actions?.[fieldIndex] ?? ''}
                  removeAction={() => removeAction(fieldIndex)}
                />
              </div>
            ))}
          </div>
        )}
        <Button onClick={() => appendAction({})} className="br-pill" icon="add_new" paleBlue>
          <span>Add step</span>
        </Button>
      </form>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Save"
        cancelBtnText="Cancel"
        submitButtonProps={{
          disabled: !formState.isValid,
        }}
        asideComponent={
          <span style={{ margin: '0 auto', marginRight: 0 }}>
            <Button plain onClick={handleClear}>
              Clear setup
            </Button>
          </span>
        }
      />
    </ModalContainer>
  );
};

interface ColumnSelectProps {
  control: Control<WorkflowForm>;
  name: string;
  columns: Array<DataTableColumn>;
  usedColumns: Set<DataTableColumnId>;
  defaultValue: DataTableColumnId;
  removeAction?: () => void;
}

const toColumnLabel = (column: DataTableColumn): string => {
  if (column.type === 'number' && isIsoDurationString(column.name)) {
    try {
      const duration = parseDuration(column.name);
      if (Object.values(duration).every((v) => v === 0)) {
        return '0';
      }
      return (duration.negative === true ? '- ' : '') + formatDuration(duration, { zero: false });
    } catch (e) {
      // intentionally left blank
    }
  } else if (column.type === 'measurement') {
    return `${column.name} (${DateUtils.renderDate(column.reference_date, { defaultResponse: '' })})`;
  }
  return `${column.name}`;
};

const ColumnSelect: FC<ColumnSelectProps> = ({ control, name, columns, usedColumns, defaultValue, removeAction }) => {
  const options = useMemo(
    () =>
      columns.reduce<Record<DataTableColumnId, SelectOption<DataTableColumnId>>>(
        (acc, column) => ({
          ...acc,
          [column.id]: { value: column.id, label: toColumnLabel(column) },
        }),
        {}
      ),
    [columns]
  );
  return (
    <>
      <div className={styles.nestedInput} />
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={{ validate: { isDataTableColumnId } }}
        render={(field: ControllerRenderProps, state: InputState) => (
          <Select
            onChange={(option) => field.onChange((option as SelectOption<DataTableColumnId>)?.value)}
            onBlur={field.onBlur}
            value={options?.[field.value]}
            isOptionDisabled={(option) => usedColumns.has(option.value)}
            options={Object.values(options)}
            className={classNames('w-100 mb3 flex-grow-1', { mr4: _isNil(removeAction), input__error: state.invalid })}
            isMulti={false}
          />
        )}
      />
      {_notNil(removeAction) && (
        <span
          className="ma0 pointer flex justify-center items-center"
          style={{ width: '40px', height: '40px' }}
          onClick={removeAction}
        >
          <Icon icon="close" width="12" height="12" className="mid-gray hover-dark-gray" />
        </span>
      )}
    </>
  );
};
