// @ts-nocheck: converted from JS

import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { _isNotEmpty, _notNil } from '@/littledash';
import { api as apiRoute } from '@/support/route';

const getFilterOperation = (name) => Object.keys(filterType).find((key) => filterType[key] === name);

const filterDetails = (catagory, option, value) => `${catagory}|${option}|${value}`;

export const generateFilterParams = (filters, params, customFilter = null) => {
  params.delete('filters[]');

  filters.forEach((filter) => {
    const filterOption = getFilterOperation(filter.operation.name);

    if (!filter.category.value.includes('metadata.')) {
      if (filter.option.value !== '') {
        if (filterOption === 'between') {
          const optionValue = `${filter.option.from},${filter.option.to}`;
          params.append('filters[]', filterDetails(filter.category.value, filterOption, optionValue));
        } else {
          params.append('filters[]', filterDetails(filter.category.value, filterOption, filter.option.value));
        }
      } else if (filter.operation.name === filterType.IS_NULL || filter.operation.name === filterType.EMPTY) {
        params.append('filters[]', filterDetails(filter.category.value, 'eq', 'null'));
      } else if (filter.operation.name === filterType.IS_NOT_NULL) {
        params.append('filters[]', filterDetails(filter.category.value, 'ne', 'null'));
      }
    }
  });
  if (_notNil(customFilter)) {
    const { type, op, filter, value } = customFilter;
    params.set('filterType', type);
    params.append('filters[]', `${filter}|${op}|${value}`);
  }
};

export const getParams = ({
  searchQuery,
  searchQueryBy,
  filters,
  filterMatch,
  sortBy,
  pageSize = 10,
  pageIndex = 1,
  columns,
  includeParam,
  excludeParam,
  resetPage = false,
  customFilter,
  queryParams,
  urlSearchParams = new URLSearchParams(),
}) => {
  if (searchQuery) {
    if (searchQueryBy) {
      urlSearchParams.set('query_by[]', searchQueryBy);
    }
    urlSearchParams.set('query', searchQuery);
  } else {
    urlSearchParams.delete('query');
    urlSearchParams.delete('query_by[]');
  }

  if (_isNotEmpty(filters) || _notNil(customFilter)) {
    urlSearchParams.set('filterType', filterMatch ? 'and' : 'or');
    generateFilterParams(filters, urlSearchParams, customFilter);
  } else {
    urlSearchParams.delete('filters[]');
    urlSearchParams.delete('filterType');
  }
  if (_isNotEmpty(sortBy)) {
    const { id: columnId, desc } = sortBy[0]; // we only filter by 1 sort/order param from our apis
    urlSearchParams.set('order', desc ? 'desc' : 'asc');
    urlSearchParams.set('sort', columnId);
  }
  if (columns) {
    urlSearchParams.set('columns[]', columns);
  }

  if (includeParam) {
    urlSearchParams.set('include', includeParam);
  }

  if (_isNotEmpty(excludeParam)) {
    excludeParam.forEach((exclude) => {
      urlSearchParams.set('excluded_ids[]', exclude);
    });
  }

  if (!resetPage) {
    urlSearchParams.set('page', pageIndex + 1);
  }

  if (_notNil(queryParams)) {
    (Object.keys(queryParams) ?? []).forEach((key) => {
      urlSearchParams.set(key, queryParams[key]);
    });
  }

  urlSearchParams.set('perPage', pageSize);

  return `?${urlSearchParams.toString()}`;
};

export const getExportUrl = (route, queryString) => {
  const url = route.params ? apiRoute(route.url, route.params) : apiRoute(route.url);

  if (route.noFilters) {
    return url;
  }
  return `${url}${queryString}`;
};

export const downloadFile = ({ data, headers }) => {
  const fileName = headers['file-name'];
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

/**
 *
 * @param dispatch {Dispatch<any>}
 * @param settings {Record<string,any>}
 * @param tableName {string}
 * @param attribute {{ key:string, value?: any }}
 */
export const saveTableSettings = (settings) => {
  saveToLocalStorage(settings);
};

export const saveToLocalStorage = (settings) => {
  try {
    const serializedSettings = JSON.stringify(settings);
    localStorage.setItem('settings', serializedSettings);
  } catch {
    // ignore write errors
  }
};

export const loadSettingsFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('settings');
    if (serializedState === null) {
      return defaultSettings;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return defaultSettings;
  }
};
