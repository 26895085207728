import { preventNumberScroll } from '@/helpers';
import type React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';
import type { RequiredMetadataFieldWithValue } from '../Fields';

export interface NumberFieldProps {
  field: RequiredMetadataFieldWithValue;
  testId?: string;
}

export const NumberField: React.FC<NumberFieldProps> = ({ field, testId = 'number-metadata-field' }) => {
  const { register } = useFormContext();
  return (
    <input
      type="number"
      onWheel={preventNumberScroll}
      style={{ marginBottom: 0 }}
      name={`metadata[${field.slug}].value`}
      step="any"
      defaultValue={Number(field?.value)}
      ref={register({ required: field.required && 'This field is required' })}
      data-testid={testId}
    />
  );
};
