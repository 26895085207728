import type { DataTableColumn } from '@/components/UI/DataTable/DataTable.model';
import type { SelectOption } from '@/components/UI/Select/Select';
import { sortArrAlphabetically } from '@/helpers';
import { _isNil, _notNil } from '@/littledash';
import type { MetadataField } from '@/model/Metadata.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest
import type { Validate } from 'react-hook-form/dist/types/validator';

export const valueIsNumeric: Validate = (value: any) => {
  if (_isNil(value) || (value ?? '').trim().length === 0) {
    return 'This field must be numeric';
  }
  return Number.isFinite(Number(value)) ? true : 'This field must be numeric & finite';
};
export const uniqueNameValidatorFactory =
  (names: Set<string>): Validate =>
  (value?: string) =>
    names.has((value ?? '').trim()) ? 'Field must be unique' : true;

export const convertMetricsToOptions = (
  columns: Readonly<Array<DataTableColumn>>,
  refDate: string,
  metrics?: Array<PresetCalculation>
): Array<SelectOption<string>> =>
  (metrics ?? []).reduce(
    (acc, { id: value, name, unit }) => {
      const alreadyDisplayed = columns?.find(
        (col) => col.type === 'measurement' && col.measurement.metric === value && col['reference_date'] === refDate
      );
      if (_isNil(alreadyDisplayed)) {
        acc.push({
          value,
          label: name + (_isNil(unit) ? '' : ` (${unit})`),
        });
      }
      return acc;
    },
    [] as Array<SelectOption<string>>
  );

export const convertObservationsToOptions = (observations?: Array<MetadataField>): Array<SelectOption<string>> => {
  const alphabeticalObservations = _notNil(observations) ? sortArrAlphabetically(observations) : [];
  return (
    (alphabeticalObservations as Array<MetadataField>)?.reduce<Array<SelectOption<string>>>((acc, observations) => {
      if (_notNil(observations?.api_id)) {
        acc.push({
          label: observations?.title,
          value: observations?.api_id,
        });
      }
      return acc;
    }, []) ?? []
  );
};
