// @ts-nocheck: converted from JS

import { _get, _isNil, _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model';
import { RandomizeState } from '../Randomize/Randomize.model';

export const getCriteriaValue = (
  animal: Animal,
  criteria: { value: string },
  measurementsOnDate: RandomizeState['randomizeByDate']['measurementsOnDate'] = null
): number => {
  if (_isNil(criteria)) {
    return NaN;
  }

  if (_notNil(measurementsOnDate)) {
    const value = measurementsOnDate[animal.api_id]?.[criteria.value]?.value;
    if (_notNil(value)) {
      return Number(value);
    }
    return NaN;
  }

  const value = _get(animal, `latestMeasurement.${criteria.value}.value`);
  if (value) {
    return Number(value);
  }

  return NaN;
};

export const median = (
  sortedArray: Animal[],
  criteria: { value: string },
  movingMedian: boolean,
  measurementsOnDate: RandomizeState['randomizeByDate']['measurementsOnDate'] = null
): number => {
  if (movingMedian) {
    sortedArray = sortedArray.reduce((accumulator, subject) => {
      const value = getCriteriaValue(subject, criteria, measurementsOnDate);
      if (!isNaN(value) && subject.selected !== false) {
        accumulator.push(subject);
      }
      return accumulator;
    }, []);
  }

  const mid = Math.floor(sortedArray.length / 2);

  const even = getCriteriaValue(sortedArray[mid], criteria, measurementsOnDate);
  const odd = getCriteriaValue(sortedArray[mid - 1], criteria, measurementsOnDate);
  return sortedArray.length % 2 !== 0 ? even : (odd + even) / 2;
};

export const mean = (
  sortedArray: Animal[],
  criteria: { value: string },
  measurementsOnDate: RandomizeState['randomizeByDate']['measurementsOnDate'] = null
) => {
  let selectedSubjects = 0;
  const total = sortedArray.reduce((accumulator, subject) => {
    const value = getCriteriaValue(subject, criteria, measurementsOnDate);
    if (!isNaN(value) && subject.selected !== false) {
      accumulator += getCriteriaValue(subject, criteria, measurementsOnDate);
      selectedSubjects++;
    }
    return accumulator;
  }, 0);

  return total / selectedSubjects;
};

export const average = (
  animals: Animal[],
  criteria: { value: string },
  measurementsOnDate: RandomizeState['randomizeByDate']['measurementsOnDate'] = null
) => {
  let length = 0;
  const total = animals.reduce((total, subject) => {
    const value = getCriteriaValue(subject, criteria, measurementsOnDate);
    if (!isNaN(value)) {
      length++;
      total += value;
    }
    return total;
  }, 0);
  return total !== 0 && length !== 0 ? total / length : 0;
};
