// @ts-nocheck: converted from JS

import { ColorEditor } from '@/components/UI/SpreadSheet/editors/ColorEditor';
import { DateEditorWrapper } from '@/components/UI/SpreadSheet/editors/DateEditorWrapper';
import { ExponentialEditor } from '@/components/UI/SpreadSheet/editors/ExponentialEditor';
import { MultiSelectEditor } from '@/components/UI/SpreadSheet/editors/MultiSelectEditor';
import { _isEmpty, _isNil, _notNil, _set } from '@/littledash';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import type { TreatmentGroupKeyedMetadata } from './CreateTreatmentGroups';

const notBlankValidator = (value, callback) => callback((value?.trim()?.length ?? 0) > 0);
const subjectValidator = (value, callback) => callback(typeof value === 'number' && value >= 0 && value <= 100);

const dateValidator = (required) => (value, callback) =>
  callback((_isEmpty(value) && !required) || DateUtils.isValidDate(value));

export const initialData: Array<TreatmentGroupKeyedMetadata> = [
  {
    color: ColorEditor.colorForIndex(0),
    name: 'Group 1',
    max_subjects: 0,
  },
];
const requiredColumn = (title) => `${title} <span class="required">(req.)</span>`;
const inactiveColumn = (title) => `${title} <span class="required">(archived)</span>`;

const baseColumnData = {
  colHeaders: [requiredColumn('Color'), requiredColumn('Name'), requiredColumn('No. of animals')],
  columns: [
    {
      data: 'color',
      value: 'Color',
      type: ColorEditor.type,
      width: 110,
    },
    {
      data: 'name',
      value: 'Name',
      type: 'text',
      width: 200,
      validator: notBlankValidator,
    },
    {
      data: 'max_subjects',
      value: 'Max Subjects',
      type: 'numeric',
      width: 165,
      validator: subjectValidator,
      validationErrorMessage: (value) => 'Max Subjects is required and must be less than 100',
    },
  ],
};

export const settings = {
  ...baseColumnData,
  columnSorting: false,
  className: 'htMiddle',
  minSpareRows: 1,
  manualColumnResize: true,
  rowHeights: 50,
  height: 500,
  rowHeaders: true,
  outsideClickDeselects: false,
};

const columnDataGetterSetterFactory = (metadata) => (row, value) => {
  if (_isNil(row?.metadata?.[metadata.id])) {
    _set(row, ['metadata', metadata.id], {
      ...metadata,
      glossary_id: metadata.id,
      value,
    });
  }
  if (typeof value === 'undefined') {
    return row?.metadata?.[metadata.id]?.value ?? '';
  }
  _set(row, ['metadata', metadata.id], {
    ...metadata,
    glossary_id: metadata.id,
    value,
  });
  _set(row, ['updated_at'], new Date().toISOString()); // Mark group updated
};
const selectColumnDataGetterSetterFactory = (metadata) => (row, value) => {
  if (_isNil(row?.metadata?.[metadata.id])) {
    _set(row, ['metadata', metadata.id], {
      ...metadata,
      glossary_id: metadata.id,
      value: value?.trim() ?? null,
    });
  }
  if (typeof value === 'undefined') {
    return row?.metadata?.[metadata.id]?.value ?? null;
  }
  _set(row, ['metadata', metadata.id], {
    ...metadata,
    glossary_id: metadata.id,
    value: value,
  });
  _set(row, ['updated_at'], new Date().toISOString()); // Mark group updated
};

export const multiSelectColumnDataGetterSetterFactory = (metadata) => (row, value) => {
  if (_isNil(row?.metadata?.[metadata.id])) {
    _set(row, ['metadata', metadata.id], {
      ...metadata,
      glossary_id: metadata.id,
      value: value?.split(',')?.map((v) => v.trim()) ?? [],
    });
  }
  if (typeof value === 'undefined') {
    const metadataValue = row?.metadata?.[metadata.id]?.value ?? [];
    return Array.isArray(metadataValue) ? metadataValue.join(',') : '';
  }
  _set(row, ['metadata', metadata.id], {
    ...metadata,
    glossary_id: metadata.id,
    value: typeof value === 'string' ? (value.split(',') ?? []) : [],
  });
  _set(row, ['updated_at'], new Date().toISOString()); // Mark group updated
};

export const metadataToColumnSettingMapper = (metadata) => {
  const metaID = metadata.id;
  switch (metadata.field_type) {
    case 'select': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: selectColumnDataGetterSetterFactory(metadata),
          metaID,
          width: 200,
          type: MultiSelectEditor.type,
          options: metadata.options,
          isMultiSelect: false,
          validator: MultiSelectEditor.validatorFactory(metadata.options, false, metadata.required ? 1 : 0, 1),
        },
      };
    }
    case 'lookup_select': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: selectColumnDataGetterSetterFactory(metadata),
          metaID,
          width: 200,
          type: MultiSelectEditor.type,
          optionsLookupId: metadata.id,
          isMultiSelect: false,
          validator: MultiSelectEditor.lookupValidatorFactory(false, metadata.required ? 1 : 0, 1),
        },
      };
    }
    case 'multi_select': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: multiSelectColumnDataGetterSetterFactory(metadata),
          metaID,
          width: 200,
          type: MultiSelectEditor.type,
          options: metadata.options,
          validator: MultiSelectEditor.validatorFactory(metadata.options, true, metadata.required ? 1 : 0),
        },
      };
    }
    case 'lookup_multi_select': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: multiSelectColumnDataGetterSetterFactory(metadata),
          metaID,
          width: 200,
          type: MultiSelectEditor.type,
          optionsLookupId: metadata.id,
          validator: MultiSelectEditor.lookupValidatorFactory(true, metadata.required ? 1 : 0),
        },
      };
    }
    case 'text': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: columnDataGetterSetterFactory(metadata),
          metaID,
          width: 200,
          type: 'text',
          metadataType: 'text',
          validator: textMetadataValidator(metadata.required),
        },
      };
    }
    case 'date': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: columnDataGetterSetterFactory(metadata),
          metaID,
          width: 150,
          correctFormat: true,
          type: DateEditorWrapper.type,
          dateFormat: DateInputUtils.hotDatePattern,
          metadataType: 'date',
          validator: dateValidator(metadata.required),
        },
      };
    }
    case 'numeric': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          data: columnDataGetterSetterFactory(metadata),
          metaID,
          width: 150,
          type: ExponentialEditor.type,
          validator: ExponentialEditor.numericValidatorFactory(metadata.required),
        },
      };
    }
    default: {
      throw new Error(`Unrecognised metadata field_type [${metadata.field_type}]`);
    }
  }
};

export const textMetadataValidator = (required = false, max = 250) => {
  return (value: string, callback) => {
    let valid = true;
    if (required) {
      valid = valid && (value?.trim()?.length ?? 0) > 0;
    }
    if (_notNil(value)) {
      valid = valid && value.length <= max;
    }
    callback(valid);
  };
};

export const generateColumnData = (displayedGroupMetadata) =>
  displayedGroupMetadata.reduce(({ colHeaders, columns }, metadata) => {
    if (metadata.field_type) {
      const { columnHeader, columnSettings } = metadataToColumnSettingMapper(metadata);
      let header = columnHeader;
      if (!metadata.active) {
        header = inactiveColumn(columnHeader);
      } else if (metadata.required) {
        header = requiredColumn(columnHeader);
      }

      return {
        colHeaders: [...colHeaders, header],
        columns: [...columns, columnSettings],
      };
    }
    return {
      colHeaders,
      columns,
    };
  }, baseColumnData);
