// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import ActionButtons from '@/components/Glossary/Sections/Approvals/ActionButtons';
import useMetadata from '@/components/Glossary/Sections/Approvals/TemplateForm/useMetadata';
import useReviewTemplates from '@/components/Glossary/Sections/Approvals/TemplateForm/useReviewTemplates';
import { useSteps } from '@/components/Steps';
import StepLinkList from '@/components/UI/StepLinkList';
import { web as webRoutes } from '@/support/route';
import { useHistory } from 'react-router-dom';

const TemplateForm = ({
  submitApproval,
  hasSubmissionErrors,
  initialSteps,
  isSubmitting,
  hasFetchErrors,
  approval,
  setApproval,
  formTitle,
  submitButtonText,
}) => {
  const { studyMetadataOptions, projectMetadataOptions } = useMetadata();
  const { reviewTemplates } = useReviewTemplates();

  const history = useHistory();
  const { steps, activeStep, nextStep, backStep, reset } = useSteps(initialSteps);

  const ActivePanel = steps[activeStep - 1].Component;
  const isFirstStep = activeStep === 1;
  const isLastStep = activeStep === steps.length;

  const updateApproval = (newData) => {
    if (newData) {
      setApproval((prevApproval) => ({
        ...prevApproval,
        ...newData,
      }));
    }
  };

  const handlePanelSubmission = (newData) => {
    if (isLastStep) {
      submitApproval(approval);
    } else {
      updateApproval(newData);
      nextStep();
    }
  };

  const handleBackClick = () => {
    if (isFirstStep) {
      reset();
      history.push(webRoutes('team.studies.approvals'));
    } else {
      backStep();
    }
  };

  return (
    <div className="pb5" data-test-component="TemplateForm" data-test-element="container">
      <div className={'flex flex-row justify-between items-center mb4 pv3'}>
        <h1 className="f3 normal pb2">{formTitle}</h1>
        <StepLinkList steps={steps} activeStep={activeStep} />
      </div>
      {hasSubmissionErrors && <ApiErrorBanner errorType={ApiErrorType.SUBMIT} error={hasSubmissionErrors} />}
      {hasFetchErrors && (
        <ApiErrorBanner
          title="There was an error fetching approval data"
          text="An error has occurred when fetching the form data, please try again
          later. If this keeps occurring please contact support."
          error={hasSubmissionErrors}
        />
      )}
      <ActivePanel
        reviewTemplates={reviewTemplates}
        studyMetadataOptions={studyMetadataOptions}
        projectMetadataOptions={projectMetadataOptions}
        approval={approval}
        onSubmit={handlePanelSubmission}
        ActionButtons={
          <ActionButtons
            canOk={!isSubmitting}
            onCancel={handleBackClick}
            cancelText={'Back'}
            okText={isLastStep ? submitButtonText : 'Continue'}
          />
        }
      />
    </div>
  );
};

export default TemplateForm;
