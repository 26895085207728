import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { _isEmpty, _isNil } from '@/littledash';
import type { StudyForm } from '@/model/StudyForm.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

interface SelectStudyCreationForm {
  onSubmit: (id: StudyForm['id']) => void;
}

const SelectStudyCreationForm: React.FC<SelectStudyCreationForm> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);

  const {
    collection: studyForms,
    collectionLoading: studyFormsLoading,
    collectionError: studyFormsError,
  } = useFetchCollection<StudyForm>({
    collectionType: 'teamStudiesForms',
    queryParams: { perPage: -1 },
  });
  useEffect(() => {
    if (studyForms?.length === 1) {
      onSubmit(studyForms[0].id);
      dispatch({ type: 'CLOSE_MODAL' });
    }
  }, [studyForms]);

  const sections = useMemo(
    () =>
      (studyForms ?? []).reduce<Array<{ title: string; items: Array<StudyForm> }>>(
        ([internal, external], form) => {
          switch (form.type) {
            case 'internal': {
              return [{ ...internal, items: [...internal.items, form] }, external];
            }
            case 'external': {
              return [internal, { ...external, items: [...external.items, form] }];
            }
            default: {
              return [internal, external];
            }
          }
        },
        [
          { title: 'Internal', items: [] },
          { title: 'External', items: [] },
        ]
      ),
    [studyForms]
  );

  const handleSubmit = () => {
    onSubmit(selected[0]);
    dispatch({ type: 'CLOSE_MODAL' });
  };

  if (studyFormsLoading || _isNil(studyForms)) {
    return <Loading />;
  }

  return (
    <div className="center mw6 ui-card">
      <div className="pa3 bb b--moon-gray">
        <h3 className="normal lh-title f4 mb0">Select a form template</h3>
      </div>
      {studyFormsError && <ApiErrorBanner error={studyFormsError} />}
      {_isEmpty(studyForms) ? (
        <Banner dismiss={false} info className="mw6 ma3">
          <h3 className="lh-title normal f5 mb2">No form templates have been created</h3>
          <p className="lh-copy f6">Form templates can be created by Admins in the Team section.</p>
        </Banner>
      ) : (
        <SearchSelect
          sections={sections}
          selected={selected}
          setSelected={setSelected}
          sort
          sortAccessor={'name'}
          maxSelections={1}
        />
      )}
      <div className="pa3 bt b--moon-gray">
        <Button
          onClick={handleSubmit}
          disabled={studyFormsLoading || studyFormsError !== false || _isEmpty(selected)}
          className="mr3"
        >
          Next
        </Button>
        <Button plain onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default SelectStudyCreationForm;
