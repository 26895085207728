// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import { errorToast, formatBytes, trunc } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

const FileUpload = ({ limit, files, setFiles, disabled = false, allowedExtensions = [] }) => {
  const handleFileDrop = useCallback((item, monitor) => {
    if (monitor) {
      let files = monitor.getItem().files;
      if (limit) {
        files = files.slice(0, limit);
      }

      setFiles(files);
    }
  }, []);

  return (
    <>
      {_isEmpty(files) ? (
        <Target
          onDrop={handleFileDrop}
          files={files}
          setFiles={setFiles}
          disabled={disabled}
          allowedExtensions={allowedExtensions}
        />
      ) : (
        <FilledField setFiles={setFiles} files={files} />
      )}
    </>
  );
};

/**
 *
 * @param fileName {string}
 * @param allowedExtensions {Array<string>|undefined}
 * @returns {boolean}
 */
const hasValidExtension = (fileName, allowedExtensions) => {
  if (_isEmpty(allowedExtensions)) {
    return true;
  }
  return allowedExtensions.includes(`.${fileName.split('.').pop().trim()}`);
};

const Target = (props) => {
  const { onDrop, setFiles, disabled, allowedExtensions } = props;
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        const fileName = item?.files?.[0]?.name ?? '';
        if (hasValidExtension(fileName, allowedExtensions)) {
          onDrop(props, monitor);
        } else {
          errorToast(
            <>
              <p>
                <b>{fileName}</b>
              </p>
              <p>is not a supported file type</p>
            </>
          );
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;

  return (
    <div
      className={`${disabled && 'ui__disabled'} ${
        isActive ? 'b--blue' : 'b--moon-gray'
      } ui__file__upload ba b--dashed f6 tc`}
      ref={drop}
    >
      <p className="ma0">
        Drag and drop or <ChooseFileLink setFiles={setFiles} allowedExtensions={allowedExtensions} />
      </p>
    </div>
  );
};

const ChooseFileLink = ({ setFiles, allowedExtensions }) => {
  const captureFiles = (e) => {
    const files = e.target.files;
    if (files.length && typeof files === 'object') {
      delete files.length;
    }

    setFiles([...files]);
  };

  return (
    <label className="link dib pointer ma0">
      browse your computer.
      <input type="file" style={{ display: 'none' }} onChange={captureFiles} accept={allowedExtensions?.join(',')} />
    </label>
  );
};

const FilledField = ({ files, setFiles }) => {
  const handleRemoveFile = (index) => {
    const tempFiles = files;
    tempFiles.splice(index, 1);
    setFiles([...tempFiles]);
  };

  return (
    <ul className="ui__file__upload__filledField list ma0">
      {!_isEmpty(files) &&
        files.map((f, i) => (
          <li className={`${i !== 0 ? 'mt2' : ''} flex justify-between items-center`} key={f.lastModified}>
            <span className="basier-med bg-light-silver br2 mr2 near-black f6 w-100">
              {trunc(f.name, 20)} ({formatBytes(f.size)})
            </span>
            <a className="pointer hover-near-black" onClick={() => handleRemoveFile(i)}>
              <Icon icon="close" width="10" height="10" className="dark-gray" />
            </a>
          </li>
        ))}
    </ul>
  );
};

export default FileUpload;
