import type { AnalysisDispatch } from '@/components/Studies/Groups/Analysis/Analysis.util';
import { AnalysisIssueList } from '@/components/Studies/Groups/Analysis/AnalysisIssueList';
import { _isNotEmpty } from '@/littledash';
import { AnalysisIssue } from '@/model/Analysis.model.ts';
import type { StudyApiId } from '@/model/Study.model';
import { type FC, useMemo } from 'react';
import { useModalAction } from '@/utils/modal';

interface EmptyStateProps {
  studyApiId: StudyApiId;
  issues: Array<AnalysisIssue>;
  dispatch: AnalysisDispatch;
}

const AdditionalInformationRequired: FC<EmptyStateProps> = ({ studyApiId, issues, dispatch }) => {
  const { openModal } = useModalAction();
  const openIssuesModal = () => {
    openModal('ANALYSIS_ISSUES', {
      studyApiId: studyApiId,
      onAnalysisIssuesUpdate: (issues: Array<AnalysisIssue>) =>
        dispatch({
          type: 'update-analysis-issues',
          data: issues,
        }),
    });
  };
  return (
    <div className="flex justify-center mt5 mb6">
      <div style={{ maxWidth: '40vw' }}>
        <h3 className="near-black fw6 f4 mb1">Additional information required</h3>
        <p className="mb2">In order to perform this analysis, please correct the following issues:</p>
        <div className="pv3">
          <AnalysisIssueList studyApiId={studyApiId} issues={issues} />
        </div>
        <div className="link dark-blue" onClick={openIssuesModal}>
          View all issues
        </div>
      </div>
    </div>
  );
};

export const EmptyState: FC<EmptyStateProps> = ({ studyApiId, issues, dispatch }) => {
  const errorLevelIssues = useMemo(() => issues.filter((i) => i.level === 'Error').slice(0, 3), [issues]);

  if (_isNotEmpty(errorLevelIssues)) {
    return <AdditionalInformationRequired studyApiId={studyApiId} issues={errorLevelIssues} dispatch={dispatch} />;
  }
  return (
    <div className="flex justify-center mv6">
      <div style={{ maxWidth: '30vw' }}>
        <h3 className="near-black mb3 f4">Choose a summary table to get started</h3>
        <p>
          Click the Options button above to select a summary table, choose a measurement type, and specify the tracking
          day for which you would like to display an analysis.
        </p>
      </div>
    </div>
  );
};
