// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import './../Colony.scss';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import SpreadSheet from '@/components/UI/SpreadSheet';
import { spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import SubHeader from '@/components/UI/SubHeader';
import { successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Cage } from '@/model/Cage.model';
import type { MetadataField } from '@/model/Metadata.model';
import { createMeta } from '@/support/hot';
import Http from '@/support/http';
import { api as apiRoutes, web as webRoute } from '@/support/route';
import type HotTable from '@handsontable/react';
import type { GridSettings } from 'handsontable/settings';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  fetchExactCageData,
  getMetaData,
  initAnimalSettings,
  initialAddAnimalData,
  updateStrainSelectDropdown,
} from './../Colony.utils';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';

const AddAnimals = () => {
  const { id: cageID } = useParams<{ id: string }>();
  const history = useHistory();
  const ref = useRef<HotTable>();
  const [data, setData] = useState<Array<Animal>>([]);
  const [settings, setSettings] = useState<GridSettings>();
  const [apiError, setApiError] = useState<Error | false>(false);
  const [metadata, setMetadata] = useState<Array<MetadataField>>([]);
  const [cageData, setCageData] = useState<Cage | undefined>();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [animalCount, setAnimalCount] = useState<number>(0);
  const [species, setSpecies] = useState<Array<SpeciesV1>>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    setSettings(initAnimalSettings(species, 'addAnimal', true).settings);
  }, [species]);

  const patchMetadata = () => {
    if (_notNil(ref.current) && _notNil(ref.current.hotInstance)) {
      const hot = ref.current.hotInstance;
      const col = hot.propToCol('species');
      const colData = hot.getDataAtCol(col);

      colData.forEach((value, row) => {
        updateStrainSelectDropdown(hot, species, row, value);
      });
      hot.render();
    }
  };

  useEffect(() => {
    if (_notNil(cageData)) {
      setData(initialAddAnimalData(cageData));
      checkAnimalLimit();
    }
  }, [cageData]);

  const { response: speciesResponse } = useApiHook({
    endpoint: 'GET /api/v1/species',
    invokeOnInit: true,
    query: { include: ['strains'] },
    options: { onError: { throw: false, capture: true, toast: false } },
  });

  useEffect(() => {
    if (speciesResponse?.type === 'success') {
      setSpecies(speciesResponse?.body?.data);
    }
  }, [speciesResponse]);

  const getInitialDependencies = async () => {
    setLoading(true);
    try {
      const metadata = await getMetaData('subject_meta');
      const cage = await fetchExactCageData(cageID);
      setMetadata(metadata.data.data);
      setCageData(cage.data.data);
    } catch (error) {
      setApiError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialDependencies();
  }, []);

  if (_notNil(ref.current) && _notNil(ref.current.hotInstance)) {
    const hot = ref.current.hotInstance;
    hot.addHook('afterRemoveRow', (_, amount) => {
      checkAnimalLimit(true, amount);
    });
  }

  const afterAddRow = () => {
    setUpdating(true);
  };

  useEffect(() => {
    checkAnimalLimit();
  }, [data, updating]);

  const checkAnimalLimit = (removeRow = false, amountRemoved = 0) => {
    const hot = ref.current?.hotInstance;
    const animalsToAdd = hot ? hot.countRenderedRows() : 1;
    if (_isNotEmpty(cageData)) {
      const animalInCage = cageData.counts.animals;
      if (removeRow) {
        setAnimalCount(animalCount - amountRemoved);
      } else {
        setAnimalCount(animalsToAdd + animalInCage);
      }
    } else {
      setAnimalCount(animalCount + animalsToAdd);
    }
    setUpdating(false);
  };

  const postAnimals = async (data: Animal) => {
    if (data) {
      return Http.post(apiRoutes('animals.createMany'), {
        animals: data,
      });
    }
  };

  const mapAnimalPostData = (data: Array<Animal & { meta: unknown }>) =>
    data.map(({ name, sex, dob, species: animalSpecies, strain, meta }) => {
      const returnData: Animal = {
        cage_id: cageData.id,
        name,
        dob,
      };
      if (sex) {
        returnData.sex = sex === 'Male' ? 'm' : 'f';
      }
      if (animalSpecies) {
        const strains = species.find((s) => s.name === animalSpecies).strains;
        if (strains.length) {
          returnData.strain_id = strains.find((s) => s.name === strain).id;
        }
      }
      if (meta && !_isEmpty(meta)) {
        returnData.meta = createMeta(meta, settings.columns);
      }
      return returnData;
    });

  const saveAnimals = async () => {
    setLoading(true);
    const animals = mapAnimalPostData(data);
    try {
      await postAnimals(animals);
      history.push('/colony');
      successToast(`Successfully added ${animals.length > 1 ? 'animals' : 'animal'}`);
    } catch (error) {
      setApiError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-100">
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={'There was an error with your request'}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
      <SubHeader linkToText="Colony" link={webRoute('colony')} />
      <div className="ph4">
        <Header mainHeaderText="Add new animals" />
        <div className=" flex-row items-start flex flex-wrap mb3">
          <p className="lh-copy set-width">
            <span className="mr2">Add new animals to {cageData?.name}</span>
          </p>
        </div>
      </div>
      {loading ? (
        <div style={{ height: 650 }}>
          <Loading txt="Updating cage..." />
        </div>
      ) : (
        !_isEmpty(settings) && (
          <div className="ow-spreadsheet-styles">
            <SpreadSheet
              data={data}
              settings={settings}
              setSettings={setSettings}
              height="500"
              className="pl4 pr4"
              innerRef={ref}
              metadata={metadata}
              setMetadata={setMetadata}
              addRow={{
                state: true,
                tooltip: animalCount >= 10 && 'Maximum cage population reached.',
                title: 'Animal',
              }}
              afterAddRow={afterAddRow}
              defaultNewRowParams={[
                { col: 2, value: cageData.name },
                { col: 4, value: `Animal ${animalCount + 1}` },
              ]}
              patchMetadataAfterSettingsUpdated={patchMetadata}
              setInvalid={setIsInvalid}
            />
          </div>
        )
      )}
      <div className="pa4">
        <Button
          onClick={saveAnimals}
          disabled={loading || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
        >
          Save
        </Button>
        <Button plain className="ml3" onClick={() => history.push('/colony')}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddAnimals;
