interface UserCardProps {
  name: string;
  email: string;
  inactive?: boolean;
}

const UserCard = ({ name, email, inactive }: UserCardProps) => {
  return (
    <div className="pa3 f6">
      <div className="flex">
        <div className="near-black mb1 trunc">{name || email}</div>
        {inactive ? <h4 className="dark-red lh-title f6 ml1 fw5 trunc">(Inactive)</h4> : null}
      </div>
      {name && <div className="trunc">{email}</div>}
    </div>
  );
};

export default UserCard;
