import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import ReasonForChange from '@/components/Shared/Latest/ReasonForChange';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { Select, Textarea } from '@/components/UI/FormFields/Latest';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { GlossaryItem } from '@/model/Glosary.model';
import type { State } from '@/model/State.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { createSelector } from '@reduxjs/toolkit';
import { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';

interface EuthaniseSubjectEditingModalProps {
  glossary_id: number;
  terminated_at: string;
  disease_survivor: boolean;
  termination_value: string;
}

interface EuthaniseSubjectProps {
  subjects: Array<Animal>;
  handleCallback: (message?: string) => void;
  closeModal: () => void;
  editing?: EuthaniseSubjectEditingModalProps;
  reasons?: Array<{ label: string; value: ID }>;
  fetchError?: boolean | Error;
}

interface EuthaniseSubjectFormProps {
  glossary_id: ID;
  terminated_at: string;
  disease_survivor: boolean;
  termination_value: string;
  reason_for_change?: string;
}

const featureSelector = createSelector(
  (state: State) => state?.team?.features ?? {},
  (features) => ({
    reasonForChange: features?.reason_for_change ?? false,
    diseaseSurvivorFlag: features?.disease_survivor ?? false,
  })
);

const EuthaniseSubjectForm: FC<EuthaniseSubjectProps> = ({
  subjects,
  editing,
  handleCallback,
  closeModal,
  reasons,
  fetchError,
}) => {
  const [submissionError, setSubmissionError] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      glossary_id: editing?.glossary_id ?? reasons?.[0]?.value ?? '',
      terminated_at: editing?.terminated_at ?? DateUtils.dateNow(),
      disease_survivor: editing?.disease_survivor ?? false,
      termination_value: editing?.termination_value ?? '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = formMethods;

  const { reasonForChange, diseaseSurvivorFlag } = useSelector(featureSelector);

  const handlePayload = (form: EuthaniseSubjectFormProps) => {
    const { glossary_id, termination_value, terminated_at, disease_survivor } = form;

    return subjects.map(({ id, collection_id }) => ({
      glossary_id,
      terminated_at,
      disease_survivor,
      termination_value,
      id,
      collection_id,
    }));
  };

  const onSubmit = (form: EuthaniseSubjectFormProps) => {
    const url = _notNil(editing) ? apiRoutes('animals.update-terminate') : apiRoutes('animals.terminate');
    const payload = _notNil(editing)
      ? { animals: handlePayload(form), reason_for_change: form.reason_for_change }
      : handlePayload(form);
    const method = _notNil(editing) ? 'patch' : 'post';

    return Http[method](url, payload)
      .then(() => handleCallback('Successfully marked as deceased'))
      .catch((error) => {
        setSubmissionError(error);
      });
  };

  const showReasonForChange = editing && reasonForChange;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} data-test-component="EuthaniseSubjectForm" data-test-element="container">
        <div className={`${isSubmitting ? 'ui__disabled' : ''} ui__card pa3`} data-testid="euthanise-subject">
          {submissionError && (
            <ApiErrorBanner className="ml3" errorType={ApiErrorType.SUBMIT} error={submissionError} />
          )}
          {fetchError && <ApiErrorBanner className="ml3" errorType={ApiErrorType.FETCH} error={fetchError} />}
          {_isNotEmpty(subjects) && (
            <div
              className={`${isSubmitting ? 'ui__disabled' : ''} center mv3 pa4 bg-white br2 shadow-4`}
              style={{ maxWidth: '420px' }}
            >
              <h3 className="normal f4 lh-title pb3">
                Mark {subjects.length === 1 ? subjects[0].name : `${subjects.length} Animals`} as deceased
              </h3>
              {_isEmpty(reasons) ? (
                <Banner info className="mw6 mb3" dismiss={false}>
                  <h3 className="f5 normal lh-title pb2">
                    There are no deceased reasons selected in your team glossary
                  </h3>
                  <p className="lh-copy f6">Contact your team administrator to add terms to select from.</p>
                </Banner>
              ) : (
                <>
                  <label className="mb2">Deceased date</label>
                  <Controller
                    name="terminated_at"
                    control={control}
                    defaultValue={DateUtils.dateNow()}
                    render={({ field: { onChange, value } }) => (
                      <DatePickerNative
                        value={value}
                        onChange={onChange}
                        className="mb0"
                        testId="terminated_at_date_picker"
                      />
                    )}
                  />
                  <div className="mb3" data-test-element="deceased-reasons-container">
                    <Controller
                      name="glossary_id"
                      control={control}
                      rules={{ required: 'Reason is required' }}
                      render={({ field: { value } }) => (
                        <Select
                          name="glossary_id"
                          required
                          value={value}
                          label="Select a reason"
                          options={reasons}
                          disabled={isSubmitting}
                        />
                      )}
                    />
                  </div>
                  {diseaseSurvivorFlag && (
                    <div className="mb3" data-test-element="deceased-reasons-container">
                      <Controller
                        name="disease_survivor"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Checkbox
                              name="disease_survivor"
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                              label="Survived to end of study"
                              disabled={isSubmitting}
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                  <div
                    className={`${showReasonForChange ? 'mb1' : 'mb3'}`}
                    data-test-element="termination-value-container"
                  >
                    <Controller
                      name="termination_value"
                      control={control}
                      render={({ field: { value } }) => (
                        <Textarea name="termination_value" maxLength={5000} label="Additional comment" value={value} />
                      )}
                    />
                  </div>
                  {showReasonForChange && (
                    <div className="mb3" data-test-element="reason-for-change-container">
                      <ReasonForChange />
                    </div>
                  )}
                </>
              )}
              <div>
                <Button
                  disabled={isSubmitting || _isEmpty(reasons)}
                  className="mr2"
                  onClick={handleSubmit(onSubmit)}
                  testId="euthanise-subject__save"
                >
                  Save
                </Button>
                <Button plain onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export const EuthaniseSubject: FC<EuthaniseSubjectProps> = ({ subjects, editing, handleCallback, closeModal }) => {
  const {
    collection: euthanizeReasons,
    collectionError: euthanizeReasonsError,
    collectionLoading: euthanizeReasonsLoading,
  } = useFetchCollection<GlossaryItem>({
    collectionType: 'teamGlossary',
    queryParams: { group: 'euthanize_reasons' },
  });

  const reasons = (euthanizeReasons ?? []).map(({ id, title }: GlossaryItem) => ({
    label: title,
    value: id,
  }));

  if (euthanizeReasonsLoading) {
    return null;
  }

  return (
    <EuthaniseSubjectForm
      subjects={subjects}
      editing={editing}
      handleCallback={handleCallback}
      closeModal={closeModal}
      reasons={reasons}
      fetchError={euthanizeReasonsError}
    />
  );
};
