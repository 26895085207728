// @ts-nocheck: converted from JS

import Files from '@/components/Files';
import { deleteFile, downloadFile, loadFiles, uploadFile } from '@/components/Studies/Attachments/file.utils';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import * as Auth from '@/support/auth';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Links } from './Links';

/**
 *
 * @param props {{studyId: number, isReview?: boolean}}
 * @returns {JSX.Element}
 * @constructor
 */
const Attachments = ({ studyId, isReview = false }) => {
  const params = useParams();
  const studyIdForFetch = studyId ?? params.id;

  const [readOnly, setReadOnly] = useState(true);
  const { entity: study } = useFetchEntity({
    entityType: 'study',
    params: { id: studyIdForFetch },
    includes: 'users,metadata',
  });

  useEffect(() => {
    if (isReview) {
      setReadOnly(false);
    } else {
      setReadOnly(!Auth.isWriteUserForStudy(study));
    }
  }, [study, isReview]);

  return (
    <div className="mh3" data-testid="study-attachments">
      <Tabs>
        <div className="pt3 pr4 bb b--moon-gray">
          <Tab className="f5">Files</Tab>
          <Tab className="f5">Links</Tab>
        </div>
        <div className={'mv3 ui-card w-100'}>
          <Panel>
            {study && (
              <Files
                readOnly={readOnly}
                context={{ study }}
                loadFiles={loadFiles}
                uploadFile={uploadFile}
                deleteFile={deleteFile}
                downloadFile={downloadFile}
              />
            )}
          </Panel>
          <Panel>
            <Links readOnly={readOnly} study={study} />
          </Panel>
        </div>
      </Tabs>
    </div>
  );
};

export default Attachments;
