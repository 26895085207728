// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import StudyGroups from '@/components/Team/Groups/Groups';
import { useSelector } from 'react-redux';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';

const Show = () => {
  const [hasFetchErrors, setHasFetchErrors] = useState(false);
  const [groupMetadata, setGroupMetadata] = useState(undefined);
  const [savedGroupData, setSavedGroupData] = useState(undefined);
  const [groupMetadataLoading, setGroupMetadataLoading] = useState(false);

  const { team } = useSelector(({ team: { team } }) => ({
    team,
  }));

  useEffect(() => {
    const fetchMetadata = async () => {
      const {
        data: { data },
      } = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'group_meta',
        },
      });
      setGroupMetadata(data);
    };
    const fetchSavedGroupData = async () => {
      try {
        const { data } = await Http.get(apiRoute('team.studies.groups'));
        setSavedGroupData(data.data || []);
      } catch (e) {
        setHasFetchErrors(true);
      }
    };
    try {
      setHasFetchErrors(false);
      setGroupMetadataLoading(true);
      fetchMetadata();
      fetchSavedGroupData();
    } catch (e) {
      setHasFetchErrors(true);
    } finally {
      setGroupMetadataLoading(false);
    }
  }, []);

  const loading = groupMetadataLoading || !groupMetadata || !savedGroupData || !team;

  if (loading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div>
        {hasFetchErrors ? (
          <ApiErrorBanner className="mb4" error={hasFetchErrors} errorType={ApiErrorType.FETCH} />
        ) : (
          <StudyGroups groupMetadata={groupMetadata} savedGroupData={savedGroupData} />
        )}
      </div>
    </div>
  );
};

export default Show;
