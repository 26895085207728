import { Order } from '@/model/Common.model';
import type { MeasurementComparatorProps, RenderFunctions } from './useMeasurementComparator.model';
import {
  findComparatorDateMeasurement,
  findDifference,
  findInstanceOfMeasurement,
  orderMeasurementsByDate,
} from './useMeasurementComparator.utils';

const useMeasurementComparator = ({
  calculations = [],
  measurements = [],
  comparatorDate,
}: MeasurementComparatorProps) => {
  const ascMeasurements = orderMeasurementsByDate(measurements, Order.asc);
  return calculations.reduce((acc, { id: calculationKey }): Record<string, RenderFunctions> => {
    acc = {
      ...acc,
      [calculationKey]: {
        comparatorDate: (value: number | null): number | null => {
          return findDifference(
            value,
            findComparatorDateMeasurement(ascMeasurements, { comparatorDate, measurementKey: calculationKey })
              ?.variables?.[calculationKey] ?? null
          );
        },
        fromFirst: (value: number | null): number | null => {
          return findDifference(
            value,
            findInstanceOfMeasurement(ascMeasurements, { from: 'first', measurementKey: calculationKey })?.variables?.[
              calculationKey
            ] ?? null
          );
        },
        fromLatest: (value: number | null): number | null => {
          return findDifference(
            value,
            findInstanceOfMeasurement(ascMeasurements, { from: 'latest', measurementKey: calculationKey })?.variables?.[
              calculationKey
            ] ?? null
          );
        },
      },
    };
    return acc;
  }, {});
};

export default useMeasurementComparator;
