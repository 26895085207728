import APITable from '@/components/UI/Table/Reusable/APITable';
import { RouteService } from '@/support/RouteService';
import { matchPath, useLocation } from 'react-router-dom';
import { Study, StudyApiId } from '@/model/Study.model';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import UserAvatar from '@/components/UI/UserAvatar';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { modalAction } from '@/utils/modal';
import { useDispatch } from 'react-redux';
import { web as webRoute } from '@/support/route';
import { Dose, DoseApiId } from '@/model/Dose.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { _notNil, uuid } from '@/littledash';
import Link from '@/components/UI/Link';
import { FC, useEffect, useState } from 'react';
import { formatNumber, successToast } from '@/helpers';
import * as Auth from '@/support/auth';
import { LegacyApiService } from '@/support/LegacyApiService.ts';

export type DosageValues = {
  display_value: string;
  display_unit: string;
};

export type RowData = {
  dose_volume: DosageValues;
  volume: DosageValues;
  dose: DosageValues;
  stock: DosageValues;
};

export const columns = (studyId: string) => [
  {
    id: 'animal',
    Header: 'Animal',
    accessor: 'animal.name',
    sortBy: 'animal',
    sortDisabled: false,
    isVisible: true,
    Cell: ({ row: { original } }: any) => (
      <Link
        className="link"
        to={webRoute('animals.show', {
          id: studyId,
          subject_id: original.animal.id,
        })}
      >
        {original.animal.name}
      </Link>
    ),
  },
  {
    id: 'cage',
    Header: 'Cage',
    sortDisabled: false,
    accessor: 'cage.name',
    isVisible: true,
  },
  {
    id: 'name',
    Header: 'Treatment',
    sortDisabled: false,
    accessor: 'name',
    isVisible: true,
  },
  {
    id: 'dose',
    Header: 'Dose',
    Cell: ({ row }: { row: { original: RowData } }) =>
      formatDosingValue(row.original.dose.display_value, row.original.dose.display_unit),
    sortDisabled: true,
    isVisible: true,
  },
  {
    id: 'dose_volume',
    Header: 'Dose Volume',
    Cell: ({ row }: { row: { original: RowData } }) =>
      formatDosingValue(row.original.dose_volume.display_value, row.original.dose_volume.display_unit),
    sortDisabled: true,
    isVisible: true,
  },
  {
    id: 'stock',
    Header: 'Stock conc',
    Cell: ({ row }: { row: { original: RowData } }) =>
      formatDosingValue(row.original.stock.display_value, row.original.stock.display_unit),
    sortDisabled: true,
    isVisible: true,
  },
  {
    id: 'volume',
    Header: 'Volume',
    Cell: ({ row }: { row: { original: RowData } }) =>
      formatDosingValue(row.original.volume.display_value, row.original.volume.display_unit),
    sortDisabled: true,
    isVisible: true,
  },
  {
    id: 'notes',
    Header: 'Comments',
    accessor: 'notes',
    sortDisabled: true,
    isVisible: true,
  },
  {
    id: 'user',
    Header: 'User',
    accessor: 'user',
    Cell: ({ row: { original } }: any) => (
      <UserAvatar user={original?.user} tooltip={original?.user?.name ?? original?.user?.email} />
    ),
    isVisible: true,
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    isVisible: true,
    Cell: ({ row: { original } }: any) => <DateTimeRenderer value={original.created_at} />,
  },
  {
    id: 'dosed_at',
    Header: 'Dosed',
    accessor: 'dosed_at',
    isVisible: true,
    Cell: ({ row: { original } }: any) => <DateTimeRenderer value={original.dosed_at} />,
  },
];

const formatDosingValue = (value: string | number | null, unit: string | null) => {
  if (_notNil(value)) {
    return `${formatNumber(value, true, 3)} ${unit}`;
  } else {
    return '-';
  }
};

export const ListDosing: FC = () => {
  const { pathname } = useLocation();
  const path = matchPath(pathname, { path: '/studies/:studyId/dosing/' });
  const params = path?.params as { studyId: string };
  const studyId = params?.studyId as StudyApiId;
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);
  const [study, setStudy] = useState<Study>();
  const [refetchToken, setRefetchToken] = useState<string>(uuid());

  useEffect(() => {
    LegacyApiService.call({
      method: 'GET',
      apiRoute: 'studies.show.p',
      query: { include: 'study_groups,api_id,users' },
      path: { id: studyId },
    }).then((data) => {
      setStudy((data.body as { data: Study })?.data ?? undefined);
    });
  }, []);

  const isWriteUser = study ? Auth.isWriteUserForStudy(study) : false;

  const { invoke } = useApiHook({
    endpoint: 'DELETE /api/v1/studies/{studyId}/dosages',
    invokeOnInit: false,
  });

  const handleDelete = async (callback: (string: string) => void, doses: Dose[], reasonForDelete?: string) => {
    if (_notNil(study?.api_id)) {
      invoke({
        body: {
          dosages: doses.reduce((acc: Array<DoseApiId>, dose) => {
            if (_notNil(dose?.api_id)) {
              acc.push(dose.api_id);
            }
            return acc;
          }, []),
          reason_for_change: reasonForDelete,
        },
        path: { studyId: study.api_id },
      })
        .then(() => {
          callback('Successfully deleted!');
        })
        .catch((cause) => {
          dispatch({ type: 'SET_ERROR', data: cause });
        });
    }
  };

  const callback = (text: string) => {
    closeModal();
    successToast(text);
    setRefetchToken(uuid());
  };
  const bulkActions = ({ useTableProps: { selectedFlatRows } }: any) => {
    if (!isWriteUser) {
      return [];
    }
    const doses = selectedFlatRows.map((row: any) => row.original);

    return [
      {
        name: 'Delete',
        action: () =>
          openModal('CONFIRM_DELETE_DOSES', {
            subject: [doses],
            closeModal,
            onClick: (reasonForDelete?: string) => handleDelete(callback, doses, reasonForDelete),
          }),
        className: 'red',
      },
    ];
  };
  return (
    <>
      <div className="ph4 pt3">
        <Header mainHeaderText={'Doses'} />
        <div>
          {_notNil(study?.api_id) && (
            <APITable
              AsideComponent={
                <>
                  {isWriteUser && <Button url={webRoute('studies.dosing.create', { id: studyId })}>Record dose</Button>}
                </>
              }
              apiInfo={{
                type: 'internalApi',
                route: RouteService.api({
                  endpoint: 'GET /api/v1/studies/{studyId}/dosages',
                  path: { studyId: study!.api_id },
                }).url,
              }}
              includeParam="users,animal,api_id"
              columns={columns(studyId)}
              bulkActions={bulkActions}
              reduxTableName={'doses'}
              showMetricChangeFrom={true}
              pageSizes={[50, 100, 200, 500, 1000]}
              defaultPageSize={1000}
              testIdPrefix="doses"
              refetch={refetchToken}
              hideSearch={false}
              searchPlaceholderText="Search by Name"
            />
          )}
        </div>
      </div>
    </>
  );
};
