// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import { FC, JSX } from 'react';

interface EmptyWorkflowProps {
  openWorkflowBtn?: () => JSX.Element;
  title: string;
  text?: string;
  icon?: boolean;
}

const EmptyWorkflow: FC<EmptyWorkflowProps> = ({ openWorkflowBtn, title, text, icon = true }) => (
  <div className="bg-near-white flex justify-center items-center h-100">
    <div className="tc ph3 mw6 center">
      {icon && <Icon icon="workflow" viewBox="0 0 46 32" width={32} height={21} />}
      <h2 className="f4 normal near-black basier-reg lh-title pt2">{title}</h2>
      {text && <p className="f5 lh-copy pv2 mb3">{text}</p>}
      {openWorkflowBtn && openWorkflowBtn()}
    </div>
  </div>
);

export default EmptyWorkflow;
