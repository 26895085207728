import { taskTypeOptions } from '@/components/Modals/AddTask/AddTask.utils.ts';
import Button from '@/components/UI/Button';
import { FormField, Text, Textarea } from '@/components/UI/FormFields/Latest';
import { featuresSelector } from '@/support/Selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { StepFormPayloadProps, StepRenderProps } from '../AddTask.model';
import styles from '../AddTask.module.scss';

const selector = createSelector([featuresSelector], (features) => ({
  typedTasksEnabled: features?.typed_tasks ?? false,
}));

const AddTaskOverviewForm: FC<StepRenderProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  disabledFields,
}) => {
  const { handleSubmit: useFormHandleSubmit, control, formState } = useFormContext<StepFormPayloadProps>();
  const { typedTasksEnabled } = useSelector(selector);
  const isSelectDisabled = !typedTasksEnabled || (disabledFields?.has('overview.type') ?? false);

  return (
    <form
      onSubmit={useFormHandleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="AddTaskOverviewForm"
      data-test-element="form"
    >
      <div>
        <div className="flex pv3">
          <div className="w-60 ph3" data-test-component="TaskInformationStep" data-test-element="task-title-container">
            <Text name="overview.title" label="Title" maxLength={50} required />
          </div>
          <div className="w-40 ph3" data-test-component="TaskInformationStep" data-test-element="task-type-container">
            <FormField name="type" label="Task type">
              <Controller
                name="overview.type"
                defaultValue={typedTasksEnabled ? undefined : 'other'}
                control={control}
                rules={{ required: !isSelectDisabled }}
                render={({ field }) => (
                  <Select
                    options={taskTypeOptions}
                    isDisabled={isSelectDisabled}
                    defaultValue={taskTypeOptions.find((option) => option.value === field.value)}
                    onChange={(option) => field.onChange(option?.value)}
                    className="ui__select f6"
                    classNamePrefix="ui__select"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div
          className="ph3 pb3"
          data-test-component="TaskInformationStep"
          data-test-element="task-description-container"
        >
          <Textarea
            name="overview.description"
            label="Description"
            maxLength={255}
            className="w-100"
            style={{ maxWidth: 'none', resize: 'vertical' }}
          />
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit" disabled={!formState.isValid}>
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

export default AddTaskOverviewForm;
