import type { components } from '@/generated/internal-api/openapi-types';
import { AnalysisIssue } from '@/model/Analysis.model.ts';
import { type FC, useMemo } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import styles from './Analysis.module.scss';

type AnalysisIssueLevel = components['schemas']['AnalysisIssueLevel.schema'];

interface AnalysisIssueCounterProps {
  issues: Array<AnalysisIssue>;
  onClick?: () => void;
}

export const AnalysisIssueCounter: FC<AnalysisIssueCounterProps> = ({ issues, onClick }) => {
  const level = useMemo<AnalysisIssueLevel>(
    () => (issues.some((issue) => issue.level === 'Error') ? 'Error' : 'Warning'),
    [issues]
  );

  if (issues.length === 0) {
    return null;
  }

  return (
    <div className="flex h-100 f6 pr4" onClick={onClick}>
      <RiAlertFill className={level === 'Error' ? styles.errorColor : styles.waringColor} width="15" height="15" />
      <span className="ml1 link dark-blue">
        {issues.length} issue{issues.length > 1 ? 's' : ''} exist{issues.length <= 1 ? 's' : ''}
      </span>
    </div>
  );
};
