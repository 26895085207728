// @ts-nocheck: converted from JS

import { useDrop } from 'react-dnd';

/**
 * Called when wanting to move a draggable item when hovered over by another draggable item.
 * @callback moveCallback
 * @param {string} dragIndex - The index of the item being dragged
 * @param {string} hoverIndex - The index of the item being hovered
 */

/**
 *
 * @callback dropCallback
 * @param {DragObject} item
 * @param {DropTargetMonitor} monitor
 */

/**
 * @typedef {Object} Callbacks
 * @property {moveCallback} [moveCallback]
 * @property {dropCallback} [dropCallback]
 */

/**
 *
 * @param {string} itemType
 * @param {object} ref
 * @param {string} index
 * @param {Callbacks} callbacks
 * @returns {[{handlerId: *}, ConnectDropTarget]|{handlerId: Identifier}}
 */
const useSortableDrop = (itemType, ref, index, { moveCallback, dropCallback }) => {
  return useDrop({
    accept: itemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        draggedItem: monitor.getItem(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      const dragNotYetCrossedMiddlePointOfTarget =
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY);

      // when dealing with sorting across multiple columns (<columnIndex>-<itemIndex>)
      if (dragIndex.toString().includes('-') && hoverIndex.toString().includes('-')) {
        const [dragColumnIndex] = dragIndex.split('-');
        const [hoverColumnIndex] = hoverIndex.split('-');

        // only consider the hover and drag positions once the cards have been noted to be in the same column
        if (dragColumnIndex === hoverColumnIndex && dragNotYetCrossedMiddlePointOfTarget) {
          return;
        }
      } else {
        if (dragNotYetCrossedMiddlePointOfTarget) {
          return;
        }
      }

      // Time to actually perform the action
      moveCallback(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      if (dropCallback) {
        dropCallback(item, monitor);
      }
    },
  });
};

export default useSortableDrop;
