import { Route } from 'react-router-dom';
import StudyCodeCreate from './StudyCodeCreate';
import StudyCodeEdit from './StudyCodeEdit';
import StudyCodeList from './StudyCodeList';

const StudyCodes = () => {
  const subRoutes = [
    {
      name: 'Show',
      route: '/team/studies/study-codes',
      exact: true,
      isValid: () => true,
      Component: () => <StudyCodeList />,
    },
    {
      name: 'Create',
      route: '/team/studies/study-codes/create',
      exact: true,
      isValid: () => true,
      Component: () => <StudyCodeCreate />,
    },
    {
      name: 'Edit',
      route: '/team/studies/study-codes/:studyCodeApiId/edit',
      exact: true,
      isValid: () => true,
      Component: () => <StudyCodeEdit />,
    },
  ];
  return (
    <>
      <div className="ph4">
        {subRoutes.map((subRoute) => (
          <Route
            key={subRoute.route}
            exact={subRoute.exact}
            path={subRoute.route}
            render={() => <subRoute.Component />}
          />
        ))}
      </div>
    </>
  );
};

export default StudyCodes;
