// @ts-nocheck: converted from JS

import { returnSubmitPromiseArr } from '@/components/Glossary/Show.utils';
import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import SearchSelect from '@/components/UI/SearchSelect';
import { sortArrAlphabetically, successToast } from '@/helpers';
import Http from '@/support/http';
import { RouteService } from '@/support/RouteService';
import { useEffect, useState } from 'react';
import { validateTeamGlossaryObservations } from './Observation.utils';
import SelectedList from './SelectedList';

const Observations = ({ glossary = [] }) => {
  const [selected, setSelected] = useState([]);
  const [initialObservations, setInitialObservations] = useState([]);
  const [observations, setObservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState(false);
  const [errors, setErrors] = useState([]);

  const setInitialState = () => {
    setLoading(true);

    const { url } = RouteService.legacyApi({
      apiRoute: 'team-glossary.list',
      query: {
        group: 'observations',
      },
    });
    return Http.get(url)
      .then((result) => {
        setInitialObservations(JSON.parse(JSON.stringify(result.data.data)));
        setObservations(result.data.data);
        setErrors(validateTeamGlossaryObservations(result.data.data));
        setLoading(false);
      })
      .catch((error) => setResponseError(error));
  };

  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    setSelected(sortArrAlphabetically(initialObservations).map((r) => r.id));
  }, [initialObservations]);

  useEffect(() => {
    if (!loading) {
      const tempObservations = [...observations].filter((o) => selected.includes(o.id));
      selected.map((s) => {
        if (!tempObservations.map((o) => o.id).includes(s)) {
          const fromGlossary = [...glossary].find((g) => Number(g.id) === Number(s));
          const defaultProps = {
            type: 'none',
            options: {},
            description: null,
          };
          tempObservations.push({
            ...fromGlossary,
            ...defaultProps,
          });
        }
      });

      return setObservations(tempObservations);
    }
  }, [selected]);

  const handleInput = (index, key, val) => {
    const tempObservations = [...observations];
    tempObservations[index][key] = val === '' ? null : val;
    setErrors(validateTeamGlossaryObservations(observations));
    return setObservations(tempObservations);
  };

  const handleSubmit = () => {
    const validate = errors.every((o) => o === false);

    if (validate) {
      setLoading(true);
      return returnSubmitPromiseArr(initialObservations, observations)
        .then(() => {
          successToast('Successfully saved observations');
          setInitialState();
        })
        .catch((error) => setResponseError(error));
    }
  };

  return (
    <div>
      <div>
        <Header mainHeaderText="Observations" />
        <p className="f6 pb2">
          Can&apos;t find an observation that you are looking for?
          <a
            target="_blank"
            without
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="mailto:support@benchling.com"
          >
            Let us know.
          </a>
        </p>
        {responseError && (
          <Banner critical dismiss={false} className="mw6 mv3">
            <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
            <p className="f6 pb3 lh-copy">
              An error has occurred when submitting the form, please try again later. If this keeps occurring please
              contact support.
            </p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
      </div>
      <div className={`${loading ? 'ui__disabled' : ''} flex flex-wrap justify-between items-top mv3`}>
        <div className="w-40">
          <div className="ui-card">
            <SearchSelect
              sections={[
                {
                  title: 'All observations',
                  items: [...glossary],
                },
              ]}
              selected={selected}
              setSelected={setSelected}
              sort
            />
          </div>
        </div>
        <div className="w-60">
          {loading ? (
            <Loading txt="Fetching Observations..."></Loading>
          ) : (
            <SelectedList
              errors={errors}
              handleInput={handleInput}
              observations={sortArrAlphabetically(observations)}
            />
          )}
        </div>
      </div>
      <div className="pv3 mt4 bt b--moon-gray">
        <Button
          tooltip={!errors.every((o) => o === false) ? 'There are errors present in your obseravtions' : undefined}
          disabled={loading || !errors.every((o) => o === false)}
          className="mr3"
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
        <Button plain onClick={() => setInitialState()}>
          Undo changes
        </Button>
      </div>
    </div>
  );
};

export default Observations;
