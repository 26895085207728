// @ts-nocheck: converted from JS

import { _isNotEmpty } from '@/littledash';
import validate from 'validate.js';
import { Species, SpeciesBulkUpdateV1, SpeciesBulkDeleteV1 } from '@/model/Species.model.ts';

export const validateData = (data) => {
  const schema = {
    name: {
      presence: { allowEmpty: false, message: "Species can't be blank" },
    },
  };

  // This is to validate strains
  validate.validators.strainArray = function (value) {
    if (_isNotEmpty(value)) {
      const checkedValues = value.map((strain) => (!strain.name ? "Strain can't be blank" : false));
      return checkedValues.every((val) => !val) ? null : [checkedValues];
    } else {
      return 'Species must have strains';
    }
  };

  schema.strains = {
    strainArray: 'test',
  };

  return validate(data, schema, { fullMessages: false });
};

export const removeSpecies = (data: Array<Species>, initialData: Array<Species>): SpeciesBulkDeleteV1 => {
  return {
    species_api_ids: initialData
      .filter((init) => !data.some((update) => update.api_id === init.api_id))
      .map(({ api_id }) => api_id),
  };
};

export const updateSpecies = (data: Array<Species>): SpeciesBulkUpdateV1 => {
  return {
    species: data.map(({ api_id, name, strains }) => ({
      species_api_id: api_id,
      name: name,
      strains: strains.map(({ api_id, name }) => ({
        strain_api_id: api_id,
        name: name,
      })),
    })),
  };
};
