// @ts-nocheck: converted from JS

import ApprovalForm from '@/components/Glossary/Sections/Approvals/TemplateForm/ApprovalForm';
import Approvers from '@/components/Glossary/Sections/Approvals/TemplateForm/Approvers';
import General from '@/components/Glossary/Sections/Approvals/TemplateForm/General';
import ApprovalSummary from '@/components/Glossary/Sections/Approvals/TemplateForm/Summary/Summary';
import Loading from '@/components/Loading';
import { _isEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute, web as webRoutes } from '@/support/route';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TemplateForm from './TemplateForm';

const initialSteps = [
  {
    title: 'General',
    Component: General,
    complete: true,
  },
  {
    title: 'Reviewers',
    Component: Approvers,
    complete: true,
  },
  {
    title: 'Review form',
    Component: ApprovalForm,
    complete: true,
  },
  {
    title: 'Summary',
    Component: ApprovalSummary,
    complete: true,
  },
];

const Edit = () => {
  const [approval, setApproval] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmissionErrors, setHasSubmissionErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasFetchErrors, setHasFetchErrors] = useState(false);

  const history = useHistory();

  const { approvalTemplate } = useParams();

  const fetchApprovals = async (approvalTemplate) => {
    setLoading(true);
    try {
      const { data } = await Http.get(
        apiRoute('approvals.templates.show', {
          approvalTemplate,
        })
      );
      if (data?.data) {
        setApproval(data.data);
      }
    } catch (error) {
      setHasFetchErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApprovals(approvalTemplate);
  }, []);

  const submitApproval = async (approval) => {
    // manually falling back to empty conditions array until react-hook-form v7 (https://github.com/react-hook-form/react-hook-form/issues/3231)
    const approvalData = {
      ...approval,
      conditions: approval.conditions || [],
      dependencies: approval.dependencies ? [approval.dependencies] : [],
    };
    setIsSubmitting(true);
    setHasSubmissionErrors(false);
    try {
      await Http.put(
        apiRoute('approvals.templates.update', {
          approvalTemplate,
        }),
        approvalData
      );
      history.push(webRoutes('team.studies.approvals'));
    } catch (e) {
      setHasSubmissionErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  if ((loading || _isEmpty(approval)) && !hasFetchErrors) {
    return <Loading />;
  }

  return (
    <TemplateForm
      submitApproval={submitApproval}
      hasSubmissionErrors={hasSubmissionErrors}
      initialSteps={initialSteps}
      isSubmitting={isSubmitting}
      hasFetchErrors={hasFetchErrors}
      loading={loading}
      approval={approval}
      setApproval={setApproval}
      formTitle={`Edit ${approval.name}`}
      submitButtonText="Save and update"
    />
  );
};

export default Edit;
