// @ts-nocheck: converted from JS

import Indicator from '@/components/UI/Indicator';
import * as Auth from '@/support/auth';
import { useFetchContext } from '@/support/Hooks/fetch';
import { useDispatch } from 'react-redux';

const AnimalAlerts = ({ animalName, cageName, alerts = [], reloadAlerts }) => {
  const { entity: study } = useFetchContext();
  const {
    id: studyId,
    settings: { calculations },
  } = study;
  const dispatch = useDispatch();

  const isWriteUser = Auth.isWriteUserForStudy(study);

  const handleClick = () => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'ANIMAL_ALERTS',
      props: {
        animalName,
        cageName,
        calculations,
        alerts,
        studyId,
        isWriteUser,
        onModalClose: () => reloadAlerts?.(),
      },
    });
  };

  return <Indicator onClick={handleClick} counter={alerts.length} />;
};

export default AnimalAlerts;
