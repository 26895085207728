import type { ID } from '@/model/Common.model';
import type { MetadataItem } from '@/model/Metadata.model';

export const findMetaIndexById = (data: Array<ExtendedMetadataItem>, id?: number | string): number =>
  data.findIndex((meta) => String(meta.id) === String(id));

export interface Errors {
  errors: Error;
  index: number;
  id: ID;
  type: string;
}
export interface Error {
  options: string[];
  title: string[];
}
export interface ExtendedMetadataItem extends Partial<MetadataItem> {
  new: boolean;
  expanded: boolean;
  index: number;
  archived?: boolean;
}
