import styles from './IncludeExcludeVariablesButton.module.scss';

interface IncludeExcludeVariablesProps {
  onClick: () => void;
  isExcluded: boolean;
  tooltipAppendText?: string;
  buttonText?: string;
  disabled?: boolean;
}

const IncludeExcludeVariablesButton = ({
  onClick,
  isExcluded = false,
  buttonText = '123',
  disabled = false,
}: IncludeExcludeVariablesProps): JSX.Element => (
  <div
    className={`${styles['include-exclude-variables-button']} ${disabled ? 'pointer-events-none' : 'pointer'}  ${
      isExcluded ? 'strike no-underline-hover' : 'no-underline strike-hover'
    } dib pa1 br2 b--blue ba blue f6 lh-solid`}
    onClick={onClick}
  >
    {buttonText}
  </div>
);

export default IncludeExcludeVariablesButton;
