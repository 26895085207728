// @ts-nocheck: converted from JS

import { useCallback, useEffect, useRef } from 'react';

/**
 * When performing any async actions we should check if the component is still mounted when handling results.
 */
export const useMountedState = (): (() => boolean) => {
  // Maintain the mounted state.
  const mountedRef = useRef(false);

  // We leverage useCallback so that we don't recreate a new function every time useMountedState is called for every re-render of a component.
  const isMounted = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};

export default useMountedState;
