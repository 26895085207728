// @ts-nocheck: converted from JS

import ReactDOM from 'react-dom/client';
import { DatadogUtils } from '@/utils/Datadog.utils';
import { SentryUtils } from '@/utils/SentryUtils';
import { TeamChangeDetector } from '@/utils/TeamChangeDetector';
import App from './App';
import { _notNil } from './littledash';
import * as serviceWorker from './serviceWorker';
import { IntercomUtils } from './utils/Intercom.utils';
import { NodePolyfillUtils } from './utils/NodePolyfill.utils';
import { initializeZendesk } from '@/components/Zendesk/ZendeskMessenger';

IntercomUtils.init();
initializeZendesk();
SentryUtils.init();
DatadogUtils.init();
NodePolyfillUtils.init();
TeamChangeDetector.onTeamChange(() => {
  alert('Current team has been changed by another tab\nwindow will now reload');
  window.location.reload();
});

if (_notNil(AppConfig.config.brandingColor)) {
  document.body.style.setProperty('--branding-color', AppConfig.config.brandingColor);
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/**
 * Prevents the browser from saving the page when CTRL+S or CMD+S is pressed.
 */
document.body.addEventListener('keydown', (event) => {
  if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
    event.preventDefault();
  }
});
