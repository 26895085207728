// @ts-nocheck: converted from JS

const OptionsContainer = ({ children, className }) => (
  <div className={className}>
    <label className="pl4">Options</label>
    {children}
  </div>
);

export default OptionsContainer;
