// @ts-nocheck: converted from JS

/**
 * Study-specific helpers
 */

import { formatNumber } from '@/helpers';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

export const VARIABLES_NO_TOP_DISPLAY = ['notes'];

const _PARSED_VARIABLES = {};

/**
 * Given a study, prepare the top-level variables from the blueprint, being
 * sensitive to whether the study has measurements or not.
 */
export function parseVariables(study) {
  if (!_PARSED_VARIABLES[study.id]) {
    const blueprint = study.typeBlueprint;
    let measurementKey = '';
    let topLevelVariables = [];

    if (study.measurement) {
      measurementKey = blueprint.measurements[study.measurement].name;
      topLevelVariables = [measurementKey, ...Object.keys(blueprint.variables)];
    } else {
      measurementKey = blueprint.activeValueName;
      topLevelVariables = [...Object.keys(blueprint.variables)];
    }

    topLevelVariables = _difference(topLevelVariables, VARIABLES_NO_TOP_DISPLAY);

    _PARSED_VARIABLES[study.id] = {
      blueprint,
      measurementKey,
      topLevelVariables,
    };
  }
  return _PARSED_VARIABLES[study.id];
}

/**
 * Calculate a measurement in real-time
 */
export function calculate(studyType, measurement, variables = {}) {
  let calc;

  // Xenograft: Three Variables
  if ((studyType === 'xenograft' || studyType === 'bioluminescenceSubQ') && measurement === 'threeVariables') {
    calc =
      variables['threeVariables:length'] *
      variables['threeVariables:width'] *
      variables['threeVariables:depth'] *
      0.5236;

    // Xenograft: Two Variables
  } else if ((studyType === 'xenograft' || studyType === 'bioluminescenceSubQ') && measurement === 'twoVariables') {
    calc = (variables['twoVariables:length'] * variables['twoVariables:width'] * variables['twoVariables:width']) / 2;
  }

  // Xenograft: Two Variables TRIPLICATE
  else if (studyType === 'xenograft' && measurement === 'twoTriplicate') {
    const firstMeasurement =
      (Math.pow(Number(variables['twoTriplicate:width1']), 2) * Number(variables['twoTriplicate:length1'])) / 2;
    const secondMeasurement =
      (Math.pow(Number(variables['twoTriplicate:width2']), 2) * Number(variables['twoTriplicate:length2'])) / 2;
    const thirdMeasurement =
      (Math.pow(Number(variables['twoTriplicate:width3']), 2) * Number(variables['twoTriplicate:length3'])) / 2;
    calc = (firstMeasurement + secondMeasurement + thirdMeasurement) / 3;
  }

  // Xenograft: Three Variables TRIPLICATE
  else if (studyType === 'xenograft' && measurement === 'threeTriplicate') {
    const firstMeasurement =
      Number(variables['threeTriplicate:width1']) *
      Number(variables['threeTriplicate:length1']) *
      Number(variables['threeTriplicate:depth1']) *
      0.5236;

    const secondMeasurement =
      Number(variables['threeTriplicate:width2']) *
      Number(variables['threeTriplicate:length2']) *
      Number(variables['threeTriplicate:depth2']) *
      0.5236;

    const thirdMeasurement =
      Number(variables['threeTriplicate:width3']) *
      Number(variables['threeTriplicate:length3']) *
      Number(variables['threeTriplicate:depth3']) *
      0.5236;

    calc = (firstMeasurement + secondMeasurement + thirdMeasurement) / 3;
  }

  // Volume only tumour studies
  else if (studyType === 'xenograft' && measurement === 'volume') {
    calc = variables['volume:volume'];
  }

  // Leukaemia: Disease Burden %
  else if (studyType === 'leukaemia' && measurement === 'diseaseBurdenPc') {
    calc =
      (Number(variables['diseaseBurdenPc:hcd45']) /
        (Number(variables['diseaseBurdenPc:hcd45']) + Number(variables['diseaseBurdenPc:mcd45']))) *
      100;
  } else if (studyType === 'leukaemia' && measurement === 'diseaseBurdenPcOnly') {
    calc = variables['diseaseBurdenPcOnly:percent'];
  } else if (studyType === 'rettModel') {
    calc =
      Number(variables['gpcs:mobility']) +
      Number(variables['gpcs:gait']) +
      Number(variables['gpcs:hindlimbClasping']) +
      Number(variables['gpcs:tremor']) +
      Number(variables['gpcs:generalCondition']);
  }

  // No match? Return nothing.
  else {
    return;
  }

  if (Number.isNaN(calc)) {
    calc = 0;
  }

  const rounded = parseFloat(Math.round(calc * 100) / 100).toFixed(2);

  return Number(rounded);
}

export function shouldAverageMetric(metricName) {
  return metricName !== 'notes';
}

export function measurementController(study, measurement, calculationToView) {
  const dataPoint = _get(measurement, calculationToView);
  const { blueprint } = parseVariables(study);

  if (dataPoint) {
    return formatNumber(dataPoint);
  } else if (calculationToView === 'weight') {
    return '';
  } else {
    const varReturn = _intersection(
      Object.keys(measurement),
      _map(blueprint.measurements[study.measurement].variables, (variable) => `${study.measurement}:${variable}`)
    );

    return _isEmpty(varReturn) ? '' : 0;
  }
}

export const isClosed = (study) => Boolean(study?.archived_at) || Boolean(study?.canceled_at);
