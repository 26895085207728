// @ts-nocheck: converted from JS

import validate from 'validate.js';

export const scaleOptions = [
  {
    name: 'None',
    value: 'none',
  },
  {
    name: 'Numeric',
    value: 'numeric',
  },
  {
    name: 'Severity',
    value: 'severity',
  },
];

export const validationSchema = {
  type: {
    presence: { allowEmpty: false },
  },
  description: {
    length: { maximum: 1000, message: 'Maximum of 1000 characters' },
  },
};

export const optionValidationSchema = {
  min: {
    presence: {
      allowEmpty: false,
      message: "Minimum value can't be blank",
    },
    numericality: {
      greaterThanOrEqualTo: 0,
      message: 'Minimum value must be a number',
    },
    equality: {
      attribute: 'max',
      message: 'Minimum value must be a number and less than the maximum value',
      comparator: function (v1, v2) {
        return Number(v1) < Number(v2);
      },
    },
  },
  max: {
    presence: {
      allowEmpty: false,
      message: "Maximum value can't be blank",
    },
    numericality: {
      lessThan: 1000,
      message: 'Maximum value must be a number, larger than the minimum value but less than 1000',
      comparator: function (v1, v2) {
        return Number(v1) > Number(v2);
      },
    },
  },
};

export const validateTeamGlossaryObservations = (observations) => {
  return observations.map((o) => {
    const validateObservation = validate({ ...o }, validationSchema, {
      fullMessages: false,
    });

    if (o.type === 'numeric') {
      let result = false;
      const validateOptions = validate({ ...o.options }, optionValidationSchema, { fullMessages: false });

      if (validateObservation) {
        result = { ...validateObservation };
      }

      if (validateOptions) {
        result = { ...result, options: validateOptions };
      }

      return result;
    } else {
      return validateObservation || false;
    }
  });
};
