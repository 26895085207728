import StudyUsers from '@/components/Studies/Show/Users';
import Summary, { SummaryItem } from '@/components/UI/Summary';
import { getMetadata, renderMetadataValue } from '@/helpers';
import { _notNil } from '@/littledash';
import type { Study } from '@/model/Study.model';
import { typeToTextMap } from '@/referenceData/study/type';

interface ApprovalRequestDetailsProps {
  study: Study;
}

const ApprovalRequestDetails: React.FC<ApprovalRequestDetailsProps> = ({ study }) => (
  <Summary title="Study Details">
    <SummaryItem label="Study type">{typeToTextMap[study.type] || '-'}</SummaryItem>
    {_notNil(study.users) && (
      <SummaryItem
        label="Team"
        rowStyle={{
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        }}
        labelClasses="pt1"
      >
        <StudyUsers study={study} customStyle={{ marginLeft: '-6px' }} />
      </SummaryItem>
    )}
    <SummaryItem label="Study name">{study.name}</SummaryItem>
    <SummaryItem label="Study Code">{study.code}</SummaryItem>
    <SummaryItem label="Notes">{study.notes || '-'}</SummaryItem>
    {getMetadata(study)?.map(({ id, title, value, field_type }) => (
      <SummaryItem key={id} label={title}>
        {renderMetadataValue({ value, field_type })}
      </SummaryItem>
    ))}
  </Summary>
);

export default ApprovalRequestDetails;
