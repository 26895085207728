// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { validateFilter } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';

const ActiveFilters = ({ filters, updateFilters, filterMatch }) => {
  const handleClick = (index) => {
    const currentFilters = [...filters];
    currentFilters.splice(Number(index), 1);
    updateFilters(currentFilters, filterMatch);
  };
  const filterValues = (filter) => {
    const values = { category: '', operation: '', option: '' };
    if (filter.category) {
      values.category = filter.category.name;
    }
    if (filter.operation) {
      values.operation = filter.operation.name;
    }
    if (filter.option) {
      if (values.operation === filterType.between) {
        values.option = filter.option;
      } else {
        values.option = filter.option.name ? filter.option.name : filter.option.value;
      }
    }
    return values;
  };
  return (
    <ul className="ui-tags active-filters mt2">
      {!_isEmpty(filters) && (
        <>
          <li className="dib mr3 f6 v-mid">
            <p className="ui-tag__label near-black">Matching {filterMatch ? 'all conditions' : 'any condition'}</p>
          </li>
          {filters.map((filter, index) => {
            const isValidFilter = validateFilter(filter);
            if (isValidFilter) {
              const values = filterValues(filter);
              return (
                <li key={index} className="ui-tag mr2">
                  <span className="ui-tag__label f6">{`${values.category} `}</span>
                  <span className="ui-tag__label f6 normal">{`${values.operation} `}</span>
                  {values.operation === filterType.between ? (
                    <span className="ui-tag__label f6">{`${DateUtils.renderDate(
                      values.option.from
                    )} to ${DateUtils.renderDate(values.option.to)}`}</span>
                  ) : (
                    <span className="ui-tag__label f6">{`${values.option} `}</span>
                  )}
                  <div className="ui-tag__close dark-gray hover-near-black" onClick={() => handleClick(index)}>
                    <Icon icon="close" width="10" height="22" />
                  </div>
                </li>
              );
            }
          })}
          <li className="dib ml3">
            <a className="f6 basier-med blue link" onClick={() => updateFilters([], true)}>
              Clear all
            </a>
          </li>
        </>
      )}
    </ul>
  );
};

export default ActiveFilters;
