// @ts-nocheck: converted from JS

//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import Lookup from '@/components/UI/Lookup';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { FormField } from '@/components/UI/FormFields/index';
import { safelyDecodeURIComponent } from '@/littledash';
import { useState } from 'react';

const SelectLookup = ({ value, isMulti = false, name, hint, label, required, metadata, testId }) => {
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control } = useFormContext();

  const loadOptions = async ({ inputValue, page }) => {
    setLoading(true);
    let url = apiRoute('metadata.options.show', { id: metadata.id });
    url = `${url}?page=${page}`;

    if (inputValue) {
      url = `${url}&query=${encodeURIComponent(inputValue)}`;
    }
    try {
      const {
        data: { data, links },
      } = await Http.get(url);
      const { next } = links;
      return {
        options: data.map((d) => ({
          label: renderLabel(d.value),
          value: d.value,
        })),
        hasMore: !!next,
      };
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderLabel = (value) => {
    if (value) {
      if (Array.isArray(value)) {
        return value.map(safelyDecodeURIComponent).join(', ');
      }
      return safelyDecodeURIComponent(value);
    }
    return '';
  };

  const defaultValue = (value, isMulti) => {
    if (value) {
      if (isMulti) {
        return value.map((f) => ({
          label: renderLabel(f),
          value: f,
        }));
      } else {
        return {
          label: renderLabel(value),
          value: value,
        };
      }
    }
  };

  const handleChange = (onChange, data, isMulti) => {
    if (data) {
      if (isMulti) {
        onChange(data.map((d) => d.value));
      } else {
        onChange(data.value);
      }
    } else {
      onChange('');
    }
  };

  return (
    <FormField name={name} label={label} hint={hint} required={required} testPrefix={testId}>
      <Controller
        control={control}
        defaultValue={value}
        name={name}
        rules={{
          required: required && 'This field is required',
        }}
        render={({ onChange, value }: ControllerRenderProps) => (
          <div className="w5">
            <Lookup
              disabled={loading}
              isMulti={isMulti}
              defaultValue={defaultValue(value, isMulti)}
              loadOptions={loadOptions}
              handleSelect={(data) => handleChange(onChange, data, isMulti)}
            />
            {apiError && <p className="f7 red">There was a problem fetching options</p>}
          </div>
        )}
      />
    </FormField>
  );
};

export default SelectLookup;
