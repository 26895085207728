// @ts-nocheck: converted from JS

import { ErrorMessage } from '@hookform/error-message';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import Radio from '@/components/UI/FormElements/Radio';
import List from '@/components/UI/List';
import { asSortableListItem } from '@/components/UI/List/List';
import { errorToast, successToast } from '@/helpers';
import { useEffect, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { studyTypes } from '@/referenceData/study/type';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';

const StudyTemplateForm = ({ template, onSaveTemplate, onSaveSection }) => {
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiError] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const defaultValues = {
    name: template.name,
    type: template.type || 'internal',
    sections: template.sections.map((section) => ({
      value: {
        ...section,
      },
    })),
  };
  const formMethods = useForm({
    defaultValues,
  });
  const { register, handleSubmit, errors, control, reset, watch } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [template]);

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      await Http.put(apiRoute('team.studies.forms.update', { id: template.id }), {
        ...data,
        sections: data.sections.map((d) => ({ ...d.value })),
      });
      successToast('Saved form.');
      onSaveTemplate();
    } catch (error) {
      setApiError(error);
      errorToast('There was a problem with your submission.');
    } finally {
      setSubmitting(false);
    }
  };

  const { fields, swap: swapFormItems } = useFieldArray({
    control,
    name: 'sections',
  });

  const openSectionModal = (sectionId = false) => {
    const customSectionNames = fields.filter(({ value }) => value.type === 'custom').map(({ value }) => value.name);
    const props = {
      formId: params.formId,
      onSaveSection,
      saveTemplate: () => handleSubmit(onSubmit)(),
      customSectionNames,
    };

    if (sectionId) {
      props.sectionId = sectionId;
    }

    dispatch({
      type: 'OPEN_MODAL',
      modal: 'CREATE_STUDY_FORM_SECTION',
      props,
    });
  };

  const deleteSection = async (id) => {
    try {
      await Http.delete(apiRoute('team.studies.forms.section.delete', { formId: template.id, sectionId: id }));
      successToast('Deleted section.');
      onSaveSection();
    } catch (error) {
      setApiError(error);
      successToast('There was a problem with your submission.');
    }
  };

  const handleDeleteSection = (id) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'CONFIRM_DELETE_STUDY_FORM_SECTION',
      props: {
        onClick: () => deleteSection(id),
      },
    });
  };

  const handleEditSection = (id) => {
    openSectionModal(id);
  };

  return (
    <>
      {apiError && <ApiErrorBanner error={apiError} />}
      <form onSubmit={handleSubmit(onSubmit)} className="pa4">
        <div className="mb3">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            className={`${errors?.name ? 'input__error' : ''}`}
            ref={register({
              required: 'This field is required',
              minLength: {
                value: 3,
                message: 'Minimum length of 3 characters',
              },
              maxLength: {
                value: 50,
                message: 'Maximum length of 50 characters',
              },
            })}
            style={{ marginBottom: 0 }}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>
        <div className="mb3">
          <label htmlFor="type">Will the study be conducted internally or externally?</label>
          {Object.keys(studyTypes).map((key) => (
            <Controller
              key={key}
              name="type"
              control={control}
              render={(props: ControllerRenderProps) => {
                const type = watch('type');
                return (
                  <Radio
                    value={key}
                    id={`study-type-${key}`}
                    name="type"
                    label={studyTypes[key]}
                    className="mb2"
                    checked={type === key}
                    onChange={(e) => props.onChange(e.target.value)}
                  />
                );
              }}
            />
          ))}
        </div>
        <div className="mb3 mw6">
          <label>Form sections</label>
          <div className="ui-card">
            <div className="pa3 bb b--moon-gray">
              <span className="db lh-copy pb3 f6">Information (required)</span>
              <span className="db lh-copy f6">Team (required)</span>
            </div>
            <div className="pa3 bb b--moon-gray">
              <FormProvider {...formMethods}>
                <List
                  canAmend={false}
                  canAdd
                  canRemove={false}
                  data={fields}
                  renderItem={asSortableListItem({
                    canSort: true,
                    sortableType: 'study-form-section-item',
                    moveCallback: swapFormItems,
                  })(FormItem, {
                    handleEditSection,
                    handleDeleteSection,
                  })}
                />
              </FormProvider>
            </div>
            <div className="pa3">
              <Button pill icon="add_new" paleBlue onClick={() => openSectionModal()}>
                Add section
              </Button>
            </div>
          </div>
        </div>
        <div className="pv3">
          <Button submit disabled={submitting}>
            Save
          </Button>
          <Button plain className="ml3" url={webRoute('team.studies.forms')}>
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

const FormItem = ({ value, index, handleEditSection, handleDeleteSection }) => {
  const { control } = useFormContext();
  const linkClasses = 'pointer f6 child  ml3 dib lh-solid';

  return (
    <Controller
      key={index}
      defaultValue={value}
      name={`sections[${index}].value`}
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div className="flex justify-between items-center">
          <Checkbox
            className={`pointer ${index !== 0 ? 'pv1' : ''}`}
            type="checkbox"
            label={value.name}
            name={value.id}
            checked={value.active}
            onChange={() =>
              onChange({
                ...value,
                active: !value.active,
              })
            }
          />
          {value.type === 'custom' && (
            <>
              <a className={`${linkClasses} blue`} onClick={() => handleEditSection(value.id)}>
                Edit
              </a>
              <a className={`${linkClasses} red`} onClick={() => handleDeleteSection(value.id)}>
                Delete
              </a>
            </>
          )}
        </div>
      )}
    />
  );
};

export default StudyTemplateForm;
