import { useVirtualizer } from '@tanstack/react-virtual';
import { errorToast } from '@/helpers';
import { _isNil, _notNil } from '@/littledash';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useModalAction } from '@/utils/modal';
import { DataTableDefaultCell } from './cells/DataTableDefaultCell';
import { DataTableReadonlyCell } from './cells/DataTableReadonlyCell';
import { DataTableTimestampCell } from './cells/DataTableTimestampCell';
import type {
  DataTableCellComponentProps,
  DataTableCellSelectionChangeEvent,
  DataTableCellStateChangeEvent,
  DataTableCellStateChangeEventDetail,
  DataTableErrors,
  DataTableHighVolumeUpsertEvent,
  DataTableRowHeaderStateChangeEvent,
  DataTableScrollDragScrollBarEvent,
  DataTableScrollService as iDataTableScrollService,
  DataTableService,
} from './DataTable.model';
import { DataTableCellStatusType, DataTableEvent, DataTableScrollEvent } from './DataTable.model';
import styles from './DataTable.module.scss';
import { fromCellRef } from './DataTable.util';
import DataTableHeader from './DataTableHeader';
import { DataTableColumnHeader } from './headers/DataTableColumnHeader';
import {
  DataTableRowColumnHeaders,
  DataTableRowHeader,
  DataTableRowHeaderStickyContainer,
} from './headers/DataTableRowHeader';
import DataTableScrollWrapper from './scrolling/DataTableScrollWrapper';
import { DataTableScrollService } from './service/DataTableScrollService';
import { DataTableRect } from './service/DataTableSelection';

interface DataTableProps {
  dataTableService: DataTableService;
  columnCount: number;
  rowCount: number;
}

const CellTypeSelector: FC<DataTableCellComponentProps> = ({ dataTableService, coordinate, positionOffset }) => {
  const column = dataTableService.columnByIndex(coordinate.column);
  const [readonly, setReadonly] = useState(column?.read_only ?? true);
  useEffect(() => {
    if (_notNil(dataTableService) && _notNil(column)) {
      setReadonly((prevState) => (prevState === column?.read_only ? prevState : (column?.read_only ?? true)));
      const abortController = new AbortController();
      dataTableService.subscribe(
        DataTableEvent.ColumnUpdate,
        ({ detail }) => {
          if (column.id === detail.id) {
            const updatedReadonlyState = detail.read_only ?? false;
            setReadonly((prevState) => (prevState === updatedReadonlyState ? prevState : updatedReadonlyState));
          }
        },
        abortController.signal
      );
      return () => {
        abortController.abort('destroyed');
      };
    }
  }, [dataTableService, column, setReadonly]);

  if (readonly) {
    return (
      <DataTableReadonlyCell
        coordinate={coordinate}
        dataTableService={dataTableService}
        positionOffset={positionOffset}
      />
    );
  }
  switch (column?.type) {
    case 'timestamp':
    case 'timestampBaseline':
      return (
        <DataTableTimestampCell
          coordinate={coordinate}
          dataTableService={dataTableService}
          positionOffset={positionOffset}
        />
      );
    case 'timestampBaselineRelative':
      return (
        <DataTableReadonlyCell
          coordinate={coordinate}
          dataTableService={dataTableService}
          positionOffset={positionOffset}
        />
      );
    case 'text':
    case 'number':
      return (
        <DataTableDefaultCell
          coordinate={coordinate}
          dataTableService={dataTableService}
          positionOffset={positionOffset}
        />
      );
    case 'measurement':
      return !(column.measurement.is_calculated ?? false) ? (
        <DataTableDefaultCell
          coordinate={coordinate}
          dataTableService={dataTableService}
          positionOffset={positionOffset}
        />
      ) : (
        <DataTableReadonlyCell
          coordinate={coordinate}
          dataTableService={dataTableService}
          positionOffset={positionOffset}
        />
      );

    case 'formula':
    case 'observation':
      return (
        <DataTableReadonlyCell
          coordinate={coordinate}
          dataTableService={dataTableService}
          positionOffset={positionOffset}
        />
      );
    default:
      return null;
  }
};

const overviewValid = (overview: DataTableCellStateChangeEventDetail['overview']) =>
  overview[DataTableCellStatusType.ValidationError] === 0 && overview[DataTableCellStatusType.NetworkError] === 0;

export const DataTable: FC<DataTableProps> = ({ dataTableService, columnCount, rowCount }) => {
  const dataTableScrollRef = useRef<HTMLDivElement>(null);
  const { openModal, closeModal } = useModalAction();
  const [tableValid, setTableValid] = useState(overviewValid(dataTableService.cellStatusOverview()));
  const [rowHeaderWidth, setRowHeaderWidth] = useState(dataTableService.rowHeaderWidth());
  const [dataTableScrollService, setDataTableScrollService] = useState<iDataTableScrollService | null>(null);
  const [errors, setErrors] = useState<DataTableErrors>([]);
  const [dataTableDisabled, setDataTableDisabled] = useState(false);
  const { cellHeight, cellWidth, columnHeight } = dataTableService.dimensions;

  const rowVirtualiser = useVirtualizer({
    count: rowCount,
    getScrollElement: () => dataTableScrollRef.current,
    estimateSize: () => cellHeight,
    overscan: 10,
    paddingStart: columnHeight,
    paddingEnd: cellHeight,
    onChange: (instance) => {
      if (instance.isScrolling) {
        updateScrollWindow();
      }
    },
  });
  const columnVirtualiser = useVirtualizer({
    count: columnCount,
    horizontal: true,
    getScrollElement: () => dataTableScrollRef.current,
    estimateSize: () => cellWidth,
    overscan: 5,
    paddingStart: rowHeaderWidth,
    paddingEnd: cellWidth,
    onChange: (instance) => {
      if (instance.isScrolling) {
        updateScrollWindow();
      }
    },
  });

  const updateScrollWindow = useCallback(() => {
    window.requestAnimationFrame(() => {
      const rowItems = rowVirtualiser.getVirtualItems();
      const top = rowItems?.[0]?.index;
      const bottom = rowItems?.[rowItems.length - 1]?.index;
      const columnItems = columnVirtualiser.getVirtualItems();
      const left = columnItems?.[0]?.index;
      const right = columnItems?.[columnItems.length - 1]?.index;
      dataTableService.windowScrollChange(new DataTableRect({ top, bottom, left, right }));
    });
  }, [dataTableService, columnVirtualiser, rowVirtualiser]);

  useEffect(() => {
    const dataTableElement = dataTableScrollRef?.current ?? null;
    if (_notNil(dataTableElement)) {
      setDataTableScrollService(
        new DataTableScrollService({
          dataTableElement,
          dataTableDimensions: dataTableService.dimensions,
          dataTableTotalSize: {
            x: columnVirtualiser.getTotalSize(),
            y: rowVirtualiser.getTotalSize(),
          },
          rowHeaderWidth,
        })
      );
    }
    return () => {
      dataTableScrollService?.destroy();
    };
  }, [dataTableScrollRef, rowCount, columnCount, rowHeaderWidth]);

  useEffect(() => {
    updateScrollWindow();
  }, [updateScrollWindow, columnCount, rowCount]);

  useEffect(() => {
    const cellSelectionChangeListener = (event: DataTableCellSelectionChangeEvent) => {
      if (event.detail.scrollTo) {
        const {
          from: { row, column },
        } = event.detail.selection;
        dataTableScrollService?.moveDataTableWindowToIndex({ row, column }, { behaviour: 'smooth' });
      } else if (!event.detail.activate) {
        dataTableScrollService?.moveDataTableWindowBySelection(event.detail.selection);
      }
    };

    const cellStateChangeListener = (event: DataTableCellStateChangeEvent) => {
      setTableValid(overviewValid(event.detail.overview));
      setErrors((errors) => {
        if (
          errors.length !== event.detail.invalidCells.size ||
          errors.some(({ ref }) => !event.detail.invalidCells.has(ref))
        ) {
          return [...event.detail.invalidCells].map((ref) => {
            const coordinate = fromCellRef(ref);
            return {
              ref,
              coordinate,
              status: dataTableService.cellStatus(coordinate),
            };
          });
        }
        return errors;
      });
    };

    const windowBeforeUnloadListener = (event: BeforeUnloadEvent) => {
      const cellStatusOverview = dataTableService.cellStatusOverview();
      if (
        dataTableService.working ||
        cellStatusOverview[DataTableCellStatusType.ValidationError] > 0 ||
        cellStatusOverview[DataTableCellStatusType.NetworkError] > 0
      ) {
        event.preventDefault();
        event.returnValue = 'Changes that you made may not be saved.';
        return 'Changes that you made may not be saved.';
      }
    };

    const rowHeaderStateChangeListener = ({ detail: { rowHeaderWidth } }: DataTableRowHeaderStateChangeEvent) => {
      setRowHeaderWidth(rowHeaderWidth);
    };

    const pasteLimitExceededListener = () => {
      openModal('INFO', {
        text: 'A maximum of 20000 cells can be pasted.',
        btnTxt: 'OK',
      });
    };

    const highVolumeUpsertListener = (event: DataTableHighVolumeUpsertEvent): void => {
      const {
        detail: { totalChunkedRequests, pending, rejected },
      } = event;
      if (!dataTableService.readonly) {
        if (rejected.length > 0) {
          if (dataTableService.undoEnabled()) {
            dataTableService.undo();
            closeModal();
            errorToast(
              'There was a problem uploading your data. We have reverted the last change made. Please try again.'
            );
          }
        } else {
          if (totalChunkedRequests === pending.length && _notNil(dataTableService)) {
            openModal('DT_HIGH_VOLUME_UPSERT', { dataTableService });
          }
        }
      }
    };

    const handleDragScrollBar = (dragEvent: DataTableScrollDragScrollBarEvent): void => {
      setDataTableDisabled(dragEvent.detail.isDragged);
    };
    const moveColumnListener = () => {
      rowVirtualiser.measure();
    };

    window.addEventListener('beforeunload', windowBeforeUnloadListener, { capture: true });
    dataTableService.subscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
    dataTableService.subscribe(DataTableEvent.MoveColumn, moveColumnListener);
    dataTableService.subscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
    dataTableService.subscribe(DataTableEvent.RowHeaderStateChange, rowHeaderStateChangeListener);
    dataTableService.subscribe(DataTableEvent.PasteLimitExceeded, pasteLimitExceededListener);
    dataTableService.subscribe(DataTableEvent.HighVolumeUpsert, highVolumeUpsertListener);
    dataTableScrollService?.subscribe(DataTableScrollEvent.DragScrollBar, handleDragScrollBar);
    return () => {
      window.removeEventListener('beforeunload', windowBeforeUnloadListener, { capture: true });
      dataTableService.unsubscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
      dataTableService.unsubscribe(DataTableEvent.MoveColumn, moveColumnListener);
      dataTableService.unsubscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
      dataTableService.unsubscribe(DataTableEvent.RowHeaderStateChange, rowHeaderStateChangeListener);
      dataTableService.unsubscribe(DataTableEvent.PasteLimitExceeded, pasteLimitExceededListener);
      dataTableService.unsubscribe(DataTableEvent.HighVolumeUpsert, highVolumeUpsertListener);
      dataTableScrollService?.unsubscribe(DataTableScrollEvent.DragScrollBar, handleDragScrollBar);
    };
  }, [dataTableService, dataTableScrollService]);

  if (_isNil(dataTableService) && _isNil(dataTableScrollService) && _isNil(dataTableScrollRef?.current)) {
    return null;
  }

  return (
    <div className="h-100 bg-white flex flex-column justify-between">
      <DataTableHeader dataTableService={dataTableService} dataTableErrors={errors} />
      <DataTableScrollWrapper ref={dataTableScrollRef} dataTableScrollService={dataTableScrollService}>
        <Prompt message="Changes that you made may not be saved." when={!tableValid || dataTableService.working} />
        <div
          style={{
            height: `${rowVirtualiser.getTotalSize()}px`,
            width: `${columnVirtualiser.getTotalSize()}px`,
            position: 'relative',
            ...(dataTableDisabled ? { pointerEvents: 'none' } : {}),
          }}
        >
          <DataTableRowColumnHeaders dataTableService={dataTableService} />
          <div className={styles['data-table-column-header-sticky-container']}>
            {columnVirtualiser.getVirtualItems().map((virtualColumn) => (
              <DataTableColumnHeader
                key={`column_header_${dataTableService.columnByIndex(virtualColumn.index)?.id}`}
                columnIndex={virtualColumn.index}
                dataTableService={dataTableService}
                positionOffset={{ x: virtualColumn.start, y: 0 }}
              />
            ))}
          </div>
          <DataTableRowHeaderStickyContainer rowHeaderWidth={rowHeaderWidth}>
            <>
              {rowVirtualiser.getVirtualItems().map((virtualRow) => (
                <DataTableRowHeader
                  key={`row_header_${dataTableService.rowByIndex(virtualRow.index)?.id}`}
                  rowIndex={virtualRow.index}
                  rowHeaderWidth={rowHeaderWidth}
                  dataTableService={dataTableService}
                  positionOffset={{ x: 0, y: virtualRow.start }}
                  errors={errors}
                />
              ))}
            </>
          </DataTableRowHeaderStickyContainer>
          {rowVirtualiser.getVirtualItems().map((virtualRow) => (
            <Fragment key={virtualRow.key}>
              {columnVirtualiser.getVirtualItems().map((virtualColumn) => {
                const coordinates = dataTableService.fromIndexCoordinate({
                  column: virtualColumn.index,
                  row: virtualRow.index,
                });
                return (
                  <CellTypeSelector
                    key={
                      _notNil(coordinates)
                        ? `${coordinates?.row}:${coordinates?.column}`
                        : `${virtualRow.key}:${virtualColumn.key}`
                    }
                    coordinate={{ column: virtualColumn.index, row: virtualRow.index }}
                    dataTableService={dataTableService}
                    positionOffset={{
                      x: virtualColumn.start,
                      y: virtualRow.start,
                    }}
                  />
                );
              })}
            </Fragment>
          ))}
        </div>
      </DataTableScrollWrapper>
    </div>
  );
};
