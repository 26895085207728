// @ts-nocheck: converted from JS

import { _isEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import _isEqual from 'lodash/isEqual';

const handlePayload = (item, glossaryId = false) => {
  const payLoad = {
    type: item.type,
    description: item.description,
    options: null,
  };

  if (glossaryId) {
    payLoad['glossary_id'] = item.id;
  } else {
    payLoad.id = item.id;
  }

  if (!_isEmpty(item.options)) {
    payLoad.options = item.options;
  }

  return payLoad;
};

const postGlossaries = (glossaries) => {
  if (!_isEmpty(glossaries)) {
    return Http.post(apiRoutes('team-glossary.create'), {
      items: glossaries.map((g) => handlePayload(g, true)),
    });
  }
};

const putGlossaries = (glossaries) => {
  if (!_isEmpty(glossaries)) {
    return Http.put(apiRoutes('team-glossary.update'), {
      items: glossaries.map((g) => handlePayload(g)),
    });
  }
};

const deleteGlossaries = (glossaries) => {
  if (!_isEmpty(glossaries)) {
    return Http.delete(apiRoutes('team-glossary.destroy'), {
      data: {
        items: glossaries,
      },
    });
  }
};

export const returnSubmitPromiseArr = (intialState, newState) => {
  const oldGlossaryIds = intialState.map((o) => o.id);
  const newGlossaryIds = newState.map((o) => o.id);
  const oldAndNewUniqGlossaryIDs = oldGlossaryIds.concat(
    newGlossaryIds.filter((item) => oldGlossaryIds.indexOf(item) < 0)
  );

  const glossaries = oldAndNewUniqGlossaryIDs.reduce(
    (acc, id) => {
      const stateItem = newState.find((n) => Number(n.id) === Number(id));
      const initialItem = intialState.find((n) => Number(n.id) === Number(id));

      if (!oldGlossaryIds.includes(id)) {
        acc.posts.push(stateItem);
      } else if (oldGlossaryIds.includes(id) && newGlossaryIds.includes(id) && !_isEqual(initialItem, stateItem)) {
        acc.puts.push(stateItem);
      } else if (oldGlossaryIds.includes(id) && !newGlossaryIds.includes(id)) {
        acc.deletes.push(id);
      }

      return acc;
    },
    {
      posts: [],
      puts: [],
      deletes: [],
    }
  );

  const promisesToMake = [
    postGlossaries(glossaries.posts),
    putGlossaries(glossaries.puts),
    deleteGlossaries(glossaries.deletes),
  ];

  return Promise.all(promisesToMake);
};
