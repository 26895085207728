// @ts-nocheck: converted from JS

const ArchiveStudy = ({ closeModal, onClick }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <form onSubmit={handleSubmit} className="ui__narrow-modal">
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">Are you sure you would like to complete this study?</h3>
        <p className="f6 pb2">
          <div>
            <p className="mt2 mb3">If a study is completed it will become read-only and has limited functionality.</p>
            <p>Completed studies can be resumed later in it&apos;s settings page.</p>
          </div>
        </p>
      </div>
      <div>
        <button type="submit" className="button w-100">
          Complete study
        </button>
        <a className="button plain db w-100 mt2" onClick={closeModal}>
          Cancel
        </a>
      </div>
    </form>
  );
};

export default ArchiveStudy;
