import Button from '@/components/UI/Button';
import './Match.scss';
import { hasDuplicates, hasOwnProperty } from '@/helpers';
import { alphabet } from '@/helpers.constants';
import { _isNotEmpty } from '@/littledash';
import type { ExistingMeasurement } from '@/model/Measurement.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getAllColNames, IDField, idFields, ImportRowMatch, MatchData, ValidationState } from '../../Importer.utils';
import { initialMatches } from './Match.utils';
import MatchConfirm from './MatchConfirm';
import MatchSelector from './MatchSelector';

interface MatchProps {
  calculations: Array<PresetCalculation>;
  fileData: Array<Record<string, string | number>>;
  matchData: Record<string, MatchData>;
  setMatchData: React.Dispatch<React.SetStateAction<Record<string, MatchData>>>;
  nextStep: (nextStep: string) => void;
  backStep: (backStep: string) => void;
  existingMeasurements: Array<ExistingMeasurement>;
  animalIdentifiers: Array<Record<string, string | number | undefined>>;
  validationState: ValidationState;
}

const Match = ({
  calculations,
  fileData,
  matchData,
  setMatchData,
  nextStep,
  backStep,
  existingMeasurements,
  animalIdentifiers,
  validationState,
}: MatchProps) => {
  const [matches, setMatches] = useState<Record<string, ImportRowMatch>>({});
  const columns = _isNotEmpty(fileData) ? getAllColNames(fileData) : [];
  const fields = [...idFields];

  calculations.forEach((c) =>
    c.measurements?.forEach((m) => {
      fields.push({ name: `${m.name} (${m.unit})`, key: m.id });
    })
  );

  useEffect(() => {
    setMatches(initialMatches(columns, fields));
  }, [fileData]);

  useEffect(() => {
    if (_isNotEmpty(matches)) {
      const matched = Object.keys(matches).reduce<Record<string, MatchData>>((acc, match) => {
        acc[match] = {
          matched: matches[match]['name'] ?? '',
          displayName: matches[match]['displayName'] ?? '',
          included: true,
          confirmed: false,
        };

        return acc;
      }, {});
      setMatchData(matched);
    }
  }, [matches]);

  return (
    <div>
      <div className="ui-card mb3">
        <h3 className="lh-title basier-reg normal f5 f4-l pa4 pb3">
          Match your data columns to fields in Benchling In Vivo
        </h3>
        <div>
          {_isNotEmpty(columns) &&
            columns.map((col, i) => (
              <MatchRow
                key={i}
                index={i}
                col={col}
                fields={fields}
                fileData={fileData}
                matchData={matchData}
                setMatchData={setMatchData}
                matches={matches}
                existingMeasurements={existingMeasurements}
                validationState={validationState}
                animalIdentifiers={animalIdentifiers}
              />
            ))}
        </div>
        <div className="pa3 ph4 bt b--moon-gray mt3">
          <MatchActions
            matchData={matchData}
            validationState={validationState}
            nextStep={nextStep}
            backStep={backStep}
          />
        </div>
      </div>
    </div>
  );
};

interface MatchRowProps {
  index: number;
  col: string;
  fields: Array<IDField>;
  matches: Record<string, ImportRowMatch>;
  fileData: Array<Record<string, string | number>>;
  matchData: Record<string, MatchData>;
  setMatchData: Dispatch<SetStateAction<Record<string, MatchData>>>;
  existingMeasurements: Array<ExistingMeasurement>;
  validationState: ValidationState;
  animalIdentifiers: Array<Record<string, string | number | undefined>>;
}

const MatchRow = ({
  index,
  col,
  fields,
  matches,
  fileData,
  matchData,
  setMatchData,
  existingMeasurements,
  validationState,
  animalIdentifiers,
}: MatchRowProps) => {
  const [ignored, setIgnored] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const toggleIgnore = (col: string) => {
    const tempMatches = { ...matchData };
    const value = !tempMatches[col]['included'];
    tempMatches[col]['included'] = value;
    if (!value) {
      tempMatches[col]['matched'] = '';
    }

    return setMatchData(tempMatches);
  };

  const toggleConfirm = (col: string) => {
    const tempMatches = { ...matchData };
    tempMatches[col]['confirmed'] = !tempMatches[col]['confirmed'];

    return setMatchData(tempMatches);
  };

  useEffect(() => {
    if (_isNotEmpty(matchData) && hasOwnProperty(matchData, col)) {
      setIgnored(!matchData[col]['included']);
      setConfirmed(matchData[col]['confirmed']);
    }
  }, [matchData]);

  const renderSelector = (
    <>
      <div className="ph3 w-50-l">
        <MatchSelector
          index={index}
          col={col}
          fields={fields}
          fileData={fileData}
          matchData={matchData}
          setMatchData={setMatchData}
        />
      </div>
      <div className="ph3 w-50-l">
        <MatchConfirm
          col={col}
          match={matches[col]}
          fileData={fileData}
          matchData={matchData}
          toggleIgnore={toggleIgnore}
          toggleConfirm={toggleConfirm}
          confirmed={confirmed}
          existingMeasurements={existingMeasurements}
          validationState={validationState}
        />
      </div>
    </>
  );

  const renderIgnored = (
    <>
      <div className="ph3 w-50-l">
        <div className="bg-near-white ba b-moon-gray flex items-center h-100 near-black basier-med lh-title pa3">
          <span className="ui__match__col__id tc dib">{alphabet[index]}</span>
          <span className="pl4 pr3 dib trunc">{col}</span>
        </div>
      </div>
      <div className="ph3 w-50-l self-stretch">
        <div className="ba b--dashed pa3 flex flex-wrap justify-between items-center">
          <p className="basier-med lh-title ma0 red">Ignored</p>
          <Button plain onClick={() => toggleIgnore(col)}>
            Edit
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="pa3 flex flex-wrap w-100">
      {_isNotEmpty(matchData) && (!ignored ? renderSelector : renderIgnored)}
    </div>
  );
};

interface MatchActionProps {
  nextStep: (nextStep: string) => void;
  backStep: (backStep: string) => void;
  matchData: Record<string, MatchData>;
  validationState: ValidationState;
}

const MatchActions = ({ nextStep, backStep, matchData, validationState }: MatchActionProps) => {
  const { cageConfirmed, animalsHaveUniqueId, idFieldConfirmed, activeFieldsNotConfirmed } = validationState;
  const includedFields = Object.values(matchData).filter(({ included }) => included);
  const hasIncompleteFields = includedFields.some(({ included, matched }) => included && !matched);
  const duplicateFieldsExist = hasDuplicates(includedFields.map(({ matched }) => matched));

  const disabledText: Record<string, string> = {
    fieldsIncomplete: 'Columns that are not ignored must be mapped to a field.',
    allActiveFieldsConfirmed: 'Confirm all mappings to continue.',
    duplicateFieldsExist: 'You cannot map a field more than once.',
    uniqueIdOrCageRequired: 'One unique ID or ID and Cage must be confirmed.',
  };

  const disabledStatus: Array<string> = [];
  if ((idFieldConfirmed && !animalsHaveUniqueId && !cageConfirmed) || !idFieldConfirmed) {
    disabledStatus.push('uniqueIdOrCageRequired');
  }
  if (hasIncompleteFields) {
    disabledStatus.push('fieldsIncomplete');
  }
  if (_isNotEmpty(activeFieldsNotConfirmed)) {
    disabledStatus.push('allActiveFieldsConfirmed');
  }
  if (duplicateFieldsExist) {
    disabledStatus.push('duplicateFieldsExist');
  }

  const disabled = _isNotEmpty(disabledStatus);
  const tooltip = disabled ? disabledStatus.map((reason) => disabledText[reason]).join('\n') : undefined;

  return (
    <div className="flex justify-between flex-wrap">
      <Button className="mr2" plain onClick={() => backStep('upload')}>
        Back
      </Button>
      <span>
        <Button tooltip={tooltip} disabled={disabled} onClick={() => nextStep('review')}>
          Review
        </Button>
      </span>
    </div>
  );
};

export default Match;
