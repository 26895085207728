import type { FC } from 'react';
import DatePickerNative from '../DatePickerNative';
import './DateRangePicker.scss';
import type { ISODate } from '@/model/Common.model';

interface DateRangePickerProps {
  range?: { from?: ISODate; to?: ISODate };
  onChange?: (value: DateRangePickerProps['range']) => void;
  classNames?: string;
}

const DateRangePicker: FC<DateRangePickerProps> = ({ range, onChange, classNames = '' }) => (
  <div className={`flex ui__dateRangePicker ${classNames}`}>
    <DatePickerNative value={range?.from ?? ''} onChange={(from) => onChange?.({ ...(range ?? {}), from })} />
    <span className="flex items-center ph2" style={{ minWidth: '30px', marginBottom: '16px' }}>
      to
    </span>
    <span className="ui__dateRangePicker_to">
      <DatePickerNative value={range?.to ?? ''} onChange={(to) => onChange?.({ ...(range ?? {}), to })} />
    </span>
  </div>
);

export default DateRangePicker;
