import Button from '@/components/UI/Button/Button';
import { Select, Text } from '@/components/UI/FormFields';
import { NumberField } from '@/components/UI/FormFields/Number';
import type { components } from '@/generated/internal-api/openapi-types';
import { errorToast, successToast } from '@/helpers';
import { _isNumber } from '@/littledash';
import { TerminationCharacters } from '@/model/Device.model';
import { State } from '@/model/State.model';
import { selectFeatures } from '@/reducers/team';
import {
  flowControlOptions,
  parityOptions,
  readingTypeOptions,
  terminationCharacterOptions,
} from '@/support/Devices/DeviceUtils';
import { Port } from '@/support/Devices/Serial/port';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { useStore } from 'react-redux';

interface AddDeviceProps {
  closeModal: () => void;
  onAddDevice: () => void;
}

export const AddDevice: FC<AddDeviceProps> = ({ onAddDevice, closeModal }) => {
  const formMethods = useForm({
    defaultValues: {
      usb_vendor_id: '',
      usb_product_id: '',
      baud_rate: 9600,
      stop_bits: 1,
      parity: parityOptions[0].value,
      flow_control: flowControlOptions[0].value,
      reading_type: 'text',
      termination_character: TerminationCharacters['Carriage return'],
    },
  });

  const selector = createSelector([selectFeatures], (features) => features);

  const store = useStore();
  const features = selector(store.getState() as State);

  const readingTypeOptionsContext = useMemo(() => {
    return !features.device_trim_channel
      ? readingTypeOptions.filter((option) => option.value !== 'trim_channel')
      : readingTypeOptions;
  }, [features.device_trim_channel]);

  const { handleSubmit, setValue } = formMethods;
  const { response: deviceTypes, loading: fetchDeviceTypesLoading } = useApiHook({
    endpoint: 'GET /api/v1/device-types',
  });
  const deviceTypeOptions = useMemo(() => {
    if (deviceTypes?.type === 'success') {
      return (deviceTypes?.body?.data ?? []).map((type) => ({
        label: type.title,
        value: type.id,
      }));
    }
    return [];
  }, [deviceTypes]);

  const { invoke: createDevice, loading: createDeviceLoading } = useApiHook({
    endpoint: 'POST /api/v1/devices',
    invokeOnInit: false,
  });

  const onSubmit = async (formData: components['schemas']['RegisteredDeviceCreateV1.schema']) => {
    try {
      await createDevice({
        body: formData,
      });
      onAddDevice();
      closeModal();
      successToast('Added new device');
    } catch (error) {
      // error toast generated by `createDevice`
    }
  };

  const requestDevice = async () => {
    const port = new Port();
    try {
      const openPort = await port.requestPort({ filters: [] });
      await port.open();
      const getInfo = openPort.getInfo();
      if (
        getInfo?.usbProductId &&
        getInfo?.usbVendorId &&
        _isNumber(getInfo.usbProductId) &&
        _isNumber(getInfo.usbVendorId)
      ) {
        setValue('usb_vendor_id', getInfo.usbVendorId);
        setValue('usb_product_id', getInfo.usbProductId);
        successToast('Successfully detected USB Vendor and USB Product ID');
      } else {
        errorToast('Unable to get USB Vendor ID and USB Product ID');
      }
    } catch (error: any) {
      errorToast('There was a problem connecting to the device.');
    } finally {
      await port.close();
    }
  };

  return (
    <ModalContainer size="small">
      <ModalHeader
        title="Add device"
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
        subText={'Enter the device configuration.'}
        readMoreLink="https://help.benchling.com/hc/en-us/articles/22038980333325"
      />
      <FormProvider {...formMethods}>
        <div className="pv3 ph2">
          <div className="mb3 ph2">
            <Text name="title" label="Device name" required autoFocus />
          </div>
          <div className="mb3 ph2">
            <Select
              name="device_type_api_id"
              label="Device type"
              options={deviceTypeOptions}
              required={false}
              disabled={fetchDeviceTypesLoading}
            />
          </div>
          <div className="flex mb3">
            <div className="w-40 ph2">
              <NumberField name="usb_vendor_id" label="USB Vendor ID" min={0} required />
            </div>
            <div className="w-60 ph2">
              <Text name="vendor_name" label="Vendor name" />
            </div>
          </div>
          <div className="flex mb3">
            <div className="w-40 ph2">
              <NumberField name="usb_product_id" label="USB Product ID" min={0} required />
            </div>
            <div className="w-60 ph2">
              <Text name="product_name" label="Product name" />
            </div>
          </div>
          <div className="mb3 ph2">
            <NumberField name="baud_rate" label="Baud rate" min={0} value={9600} required />
          </div>
          <div className="flex">
            <div className="mb3 w-50 ph2">
              <NumberField name="data_bits" label="Data bits" min={5} max={8} />
            </div>
            <div className="mb3 w-50 ph2">
              <NumberField name="stop_bits" label="Stop bits" min={1} />
            </div>
          </div>
          <div className="mb3 w-100 ph2">
            <Select name="parity" label="Parity" options={parityOptions} required={true} />
          </div>
          <div className="mb3 w-100 ph2">
            <Select name="flow_control" label="Flow control" options={flowControlOptions} required={true} />
          </div>
          <div className="flex flex-column">
            <div className="mb3 w-50 ph2">
              <Select
                name="termination_character"
                label="Termination character"
                options={terminationCharacterOptions}
                required
              />
            </div>
            <div className="mb3 w-50 ph2">
              <Select name="reading_type" label="Response type" options={readingTypeOptionsContext} required={true} />
            </div>
          </div>
        </div>
      </FormProvider>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Save"
        cancelBtnText="Cancel"
        submitButtonProps={{
          disabled: fetchDeviceTypesLoading || createDeviceLoading,
          loading: fetchDeviceTypesLoading || createDeviceLoading,
        }}
        asideComponent={<Button onClick={requestDevice}>Auto-detect</Button>}
      />
    </ModalContainer>
  );
};
