import { ID } from '@/model/Common.model.ts';
import type { TaskV1 } from '@/model/Task.model.ts';
import { TreatmentApiId } from '@/model/Treatment.model.ts';
import { FC, useMemo } from 'react';

export type TaskTypeData = {
  measurements: Record<string, string>;
  samples: Record<number, string>;
  observations: Record<number, string>;
  treatments: Record<TreatmentApiId, string>;
};
type Items = Array<{ id: ID; title: string }>;
export const TaskTypeDetail: FC<{ task: TaskV1; typeData: TaskTypeData; onClick: () => void }> = ({
  task,
  typeData,
  onClick,
}) => {
  const items: Items = useMemo(() => {
    switch (task.type) {
      case 'measurement':
        return (
          task.execution?.measurement?.measurements?.reduce<Items>(
            (acc, id) =>
              Object.prototype.hasOwnProperty.call(typeData.measurements, id)
                ? [...acc, { id, title: typeData.measurements[id] }]
                : acc,
            []
          ) ?? []
        );
      case 'sample':
        return (
          task.execution?.sample?.samples?.reduce<Items>(
            (acc, id) =>
              Object.prototype.hasOwnProperty.call(typeData.samples, id)
                ? [...acc, { id, title: typeData.samples[id] }]
                : acc,
            []
          ) ?? []
        );
      case 'observation':
        return (
          task.execution?.observation?.observations?.reduce<Items>((acc, id) => {
            return Object.prototype.hasOwnProperty.call(typeData.observations, id)
              ? [...acc, { id, title: typeData.observations[id] }]
              : acc;
          }, []) ?? []
        );
      case 'dosing':
        return (
          task.execution?.dosing?.treatments?.reduce<Items>(
            (acc, id) =>
              Object.prototype.hasOwnProperty.call(typeData.treatments, id)
                ? [...acc, { id, title: typeData.treatments[id] }]
                : acc,
            []
          ) ?? []
        );
    }
    return [];
  }, [task, typeData]);
  if (items.length === 0) {
    return null;
  }
  return (
    <>
      <dl className="f6">
        {items.slice(0, 4).map((item) => (
          <dt key={item.id}>{item.title}</dt>
        ))}
        {items.length > 4 ? (
          <dt>
            <a className="link" onClick={onClick}>
              +{items.length - 4} More
            </a>
          </dt>
        ) : null}
      </dl>
    </>
  );
};
