import { useFormContext } from 'react-hook-form@latest';
import { FormField } from '@/components/UI/FormFields/Latest';
import { getRegisterConfig } from '.././FormField.utils';

interface DateTimePickerProps {
  label: string;
  name: string;
  disabled?: boolean;
  hint?: string;
  required?: boolean;
  includeTime?: boolean;
  value?: string;
  testId?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  name,
  disabled = false,
  hint = '',
  required = false,
  includeTime = false,
  value = '',
  testId = 'date-time-picker',
}) => {
  const { register } = useFormContext();
  return (
    <FormField name={name} label={label} hint={hint} required={required} testPrefix={testId}>
      <div className="w5">
        <input
          type={includeTime ? 'datetime-local' : 'date'}
          step="any"
          defaultValue={value}
          {...register(name, getRegisterConfig({ required }))}
          disabled={disabled}
          style={{ marginBottom: 0 }}
          data-testid={testId}
        />
      </div>
    </FormField>
  );
};

export default DateTimePicker;
