import { expo } from '@/helpers';
import { alphabet } from '@/helpers.constants';
import type { IDField, MatchData } from '../../Importer.utils';

interface MatchSelectorProps {
  index: number;
  col: string;
  fields: Array<IDField>;
  fileData: Array<Record<string, string | number>>;
  className?: string;
  matchData: Record<string, MatchData>;
  setMatchData: (matches: Record<string, MatchData>) => void;
}

const MatchSelector = ({ index, col, fields, fileData, matchData, setMatchData, className }: MatchSelectorProps) => {
  const updateMatch = ({ target: { value } }: { target: { value: string } }) => {
    const matches = { ...matchData };
    matches[col]['matched'] = value;
    matches[col]['confirmed'] = false;

    return setMatchData(matches);
  };

  const selected = matchData[col] || false;

  return (
    <div className={`ui__match ba b--moon-gray bg-white ${className}`}>
      <MatchSelectorHeader
        id={alphabet[index]}
        col={col}
        value={selected?.['matched'] ?? ''}
        options={fields}
        onChange={updateMatch}
      />
      {fileData.slice(0, 3).map((f, i) => (
        <MatchSelectorRow key={i} id={i + 1} value={f[col]} />
      ))}
    </div>
  );
};

interface MatchSelectorHeaderProps {
  id: string;
  col: string;
  value: string;
  options: Array<IDField>;
  onChange: (event: { target: { value: string } }) => void;
}

const MatchSelectorHeader = ({ id, col, value, options, onChange }: MatchSelectorHeaderProps) => (
  <div className="ui__match__col ui__match__header flex items-center">
    <div className="bg-near-white flex items-center h-100 w-50 near-black basier-med lh-title">
      <span className="ui__match__col__id tc dib">{id}</span>
      <span className="pl4 pr3 dib trunc">{col}</span>
    </div>
    <div className="ui__match__select pl4 relative w-50">
      <select onChange={onChange} className="mb0 basier-med w-100" value={value} style={{ maxWidth: 'none' }}>
        <option value="">Select a field</option>
        {options.map((o) => (
          <option key={o.key} value={o.key} selected={value === o.key}>
            {o.name}
          </option>
        ))}
      </select>
    </div>
  </div>
);

interface MatchSelectorRow {
  id: number;
  value: string | number;
}

const MatchSelectorRow = ({ id, value }: MatchSelectorRow) => (
  <div className="ui__match__col ui__match__row bt b--moon-gray overflow-hidden">
    <div className="flex items-center near-black h-100">
      <span className="ui__match__col__id bg-near-white tc dib basier-med br b--moon-gray">{id}</span>
      <span className="pl4 pr3 dib trunc">
        {typeof value === 'number' ? (value > 9999 ? expo(value) : value) : value}
      </span>
    </div>
  </div>
);

export default MatchSelector;
