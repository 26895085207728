import { successToast } from '@/helpers';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { KeyboardEventHandler, type FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import { useDevices } from '@/utils/devices/useDevices';
import { AltId, Animal } from '@/model/Animal.model';
import { useRequest } from '@/support/Hooks/request';
import { _notNil } from '@/littledash';
import { useSelector } from 'react-redux';
import { State } from '@/model/State.model';
import { checkStudyForDuplicates } from '../AssignIdentifiers/AssignIdentifiers.utils';
import { ErrorMessage } from '@hookform/error-message';
import { AxiosResponse } from 'axios';

interface AnimalAltId {
  altId: AltId;
  name: string;
  value: string;
}

export interface EditSubjectIdProps {
  closeModal: () => void;
  onComplete?: (value: string) => void;
  id: AnimalAltId;
  subject: Animal;
}

interface SubmitData {
  id: string;
}

export const EditSubjectId: FC<EditSubjectIdProps> = ({ onComplete, closeModal, id, subject }) => {
  const altId = id.altId;

  const {
    features: { assign_identifiers_tag_unique },
  } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  const formMethods = useForm({});
  const { handleSubmit, register, errors, setValue } = formMethods;

  const { sendRequest: updateAltId } = useRequest({
    route: 'animals.identifiers.bulk.update',
    method: 'patch',
  });

  const onNewReadingFocusSearch = (reading: string) => {
    setValue('id', reading);
    nextReadingFocusSearch(true);
    handleSubmit(onSubmit)();
  };

  const onNewReadingAssignId = (reading: string) => {
    setValue('id', reading);
    nextReadingAssignId(true);
    handleSubmit(onSubmit)();
  };

  const { nextReading: nextReadingFocusSearch } = useDevices({
    targetId: 'focus-search',
    onNewReading: onNewReadingFocusSearch,
  });
  const { nextReading: nextReadingAssignId } = useDevices({
    targetId: 'assign-id',
    onNewReading: onNewReadingAssignId,
  });

  const onSubmit = async (formData: SubmitData) => {
    const newValue = formData.id;
    const existingAltIds = subject.alt_ids;
    const { id: subjectId } = subject;

    const payload = {
      animals: [{ alt_ids: { ...existingAltIds, [altId]: newValue }, id: subjectId, name: subject.name }],
    };
    (await updateAltId(payload)) as AxiosResponse<{
      data: Array<Animal>;
    }>;

    if (_notNil(onComplete)) {
      onComplete(newValue);
    }
    closeModal();

    successToast(`${id.name} updated`);
  };
  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const rules: { required?: string; validate?: (value: string) => Promise<boolean | string> } = {};
  if (altId === 'tag' && assign_identifiers_tag_unique === true) {
    rules.required = 'This field is required';
    rules.validate = async (value) => {
      try {
        if (_notNil(subject.api_id)) {
          const result = await checkStudyForDuplicates(value, 'tag', subject.study_api_id!, subject.api_id);
          return result ? true : 'This identifier is already in use';
        }
        return 'Animal not found';
      } catch (error) {
        return 'An error occurred';
      }
    };
  }

  return (
    <ModalContainer size="small">
      <ModalHeader
        title={`Edit ${id.name} ID`}
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
        readMoreLink="https://help.benchling.com/hc/en-us/articles/22038980333325"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pv3 ph2">
          <div className="mb3 ph2">
            <label>Value</label>
            <input
              autoFocus
              onFocus={({ target }) => target.select()}
              type="text"
              ref={(e) => {
                register(e, rules);
              }}
              name="id"
              placeholder={id.value}
              onKeyDown={handleKeyPress}
              style={{ marginBottom: 0 }}
              className={`${errors?.alt_id ? 'input__error' : ''}`}
            />
            <ErrorMessage
              errors={errors}
              name={'id'}
              render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
            />
          </div>
        </div>
      </form>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Save"
        cancelBtnText="Cancel"
      />
    </ModalContainer>
  );
};
