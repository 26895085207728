import style from '@/components/Studies/Groups/Analysis/Analysis.module.scss';
import Icon from '@/components/UI/Icon';
import { _isNil } from '@/littledash';
import { AnalysisStudyGroupV1 } from '@/model/Analysis.model.ts';
import type { StudyApiId } from '@/model/Study.model';
import type { FC } from 'react';
import { useModalAction } from '@/utils/modal';

export const GroupCell: FC<{ studyApiId: StudyApiId; group?: AnalysisStudyGroupV1 }> = ({ studyApiId, group }) => {
  const { openModal } = useModalAction();
  const handleGroupInformationClick = () => {
    openModal('GROUP_INFORMATION', { studyApiId: studyApiId, groupApiId: group?.api_id });
  };
  if (_isNil(group)) {
    return '-';
  }
  return (
    <div className="w-100 flex items-center ui__overflow__scroll_y">
      <span className={style.groupChip} style={{ backgroundColor: group.color ?? '#333333', minWidth: '20px' }} />
      <span className="mr2">{group.name}</span>
      <span className={`dib w1 h1 br1 relative ${style.hasPointerCursor}`} onClick={handleGroupInformationClick}>
        <Icon className="mid-gray hover-blue" icon="info" width="24" height="24" />
      </span>
    </div>
  );
};
