import Button from '@/components/UI/Button';
import { hasOwnProperty } from '@/helpers';
import { DateRenderer } from '../../../DateRenderers/DateRenderers';
import type { ReviewData } from '../Review/Review';
import { countErrorsAndWarnings } from '../Review/Review.utils';

interface SynopsisProps {
  date: string;
  reviewData: Array<ReviewData>;
  setStated: (stateStatus: boolean) => void;
  backStep: (step: string) => void;
}

const Synopsis = ({ date, reviewData, setStated, backStep }: SynopsisProps) => {
  const results = countErrorsAndWarnings(reviewData);
  const validRows = reviewData.filter((r) => !hasOwnProperty(r, 'errors'));

  return (
    <div className="ph4">
      <h3 className="lh-title basier-reg normal f5 f4-l pb3 pb4-l">Review import</h3>
      <div>
        {date && (
          <div className="flex flex-wrap justify-between lh-solid near-black pb3 bb b--moon-gray mb3">
            <span className="basier-med">Date recorded</span>
            <span>
              <DateRenderer value={date} />
            </span>
          </div>
        )}
        {reviewData && (
          <div className="flex flex-wrap justify-between lh-solid near-black pb3 bb b--moon-gray mb3">
            <span className="basier-med">Rows to be imported</span>
            <span>{validRows.length}</span>
          </div>
        )}
        <div className="flex flex-wrap justify-between lh-solid near-black pb3 bb b--moon-gray mb3">
          <span className="basier-med">Total warnings</span>
          <span>{results.warnings}</span>
        </div>
        <div className="flex flex-wrap justify-between lh-solid near-black pb3 bb b--moon-gray mb3">
          <span className="basier-med">Rows to be ignored</span>
          <span>{reviewData.filter((r) => hasOwnProperty(r, 'errors')).length}</span>
        </div>
      </div>
      <div className="pt3">
        <Button className="mr3" onClick={() => setStated(true)}>
          Import Data
        </Button>
        <Button plain onClick={() => backStep('review')}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default Synopsis;
