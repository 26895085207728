import { generateConditionText } from '@/components/Studies/Create/Steps/Alerts/Alerts.utils';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Icon from '@/components/UI/Icon';
import UserAvatar from '@/components/UI/UserAvatar/UserAvatar';
import { _isFunction, _isNil, _notNil } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { ID } from '@/model/Common.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { BaseUser } from '@/model/User.model';
import React, { useState } from 'react';
import { useRequest } from '@/support/Hooks/request';
import { useVisibilityState } from '@/support/Hooks/visibility-state/useVisibilityState';
import { AlertProgressBar } from '@/utils/alerts/AlertProgressBar';
import { AlertService } from '@/utils/alerts/useAlert';
import type { AxiosResponse } from 'axios';
import styles from './Alert.module.scss';

interface UpdateAlertRequest {
  resolved: boolean;
}

interface UpdateAlertResponse {
  data: AnimalAlertV1;
}

const defaultAlertSettings: Required<AlertSettings> = {
  displayHeader: true,
  displayCreatedAt: true,
  displayTriggeredBy: true,
  displayDeferControl: true,
  displayResolveControl: true,
  displayResolveConfirmation: false,
  displayAlertState: true,
  displayActionsBelow: false,
  displayResolvedTick: true,
};

const TriggeredBy: React.FC<{ alert: AnimalAlertV1 }> = ({ alert }) => {
  const triggeredByUser = alert?.created_by;
  const resolvedByUser = alert?.resolved_by;

  if (_isNil(triggeredByUser)) {
    return null;
  }

  return (
    <div className="flex mb2 self-center" data-testid="alert-triggered-by">
      <UserAvatar className="mr2" user={triggeredByUser} tooltip={triggeredByUser?.name} />
      <div className="flex self-center flex-column">
        <p className="f8">
          <DateTimeRenderer value={alert.created_at} />
        </p>
        {_notNil(resolvedByUser) && _notNil(alert?.resolved_at) && (
          <p className="f7 dark-gray">
            Resolved on <DateTimeRenderer value={alert.resolved_at} /> by {resolvedByUser?.name}
          </p>
        )}
      </div>
    </div>
  );
};

const AlertState: React.FC<{ alert: AnimalAlertV1 }> = ({ alert }) => {
  const resolvedByUser = alert?.resolved_by;
  if (_isNil(alert?.resolved_at)) {
    return null;
  }
  const userName = resolvedByUser?.name;
  return (
    <div className="flex mb2 self-center" data-testid="alert-state">
      <UserAvatar user={resolvedByUser as BaseUser} tooltip={resolvedByUser?.name} className="mr2" />
      <p className="self-center f7">
        Resolved on <DateTimeRenderer value={alert.resolved_at} />
        {_notNil(userName) && ` by ${userName}`}
      </p>
    </div>
  );
};

interface AlertActionsProps {
  alert: AnimalAlertV1;
  alertUpdating: boolean;
  updateAlert: (body: UpdateAlertRequest) => Promise<AxiosResponse<UpdateAlertResponse>>;
  settings: AlertSettings;
  resolved: boolean;
  onUpdate?: AlertUpdateEventHandler;
}

const AlertActions: React.FC<AlertActionsProps> = ({
  alert,
  alertUpdating,
  updateAlert,
  settings,
  resolved,
  onUpdate,
}) => {
  const resolve = (resolved = true) => {
    if (!alertUpdating) {
      const callback: AlertUpdateEventHandler = _isFunction(onUpdate) ? onUpdate : (state) => Promise.resolve();
      callback({ type: 'resolving' })
        .then(() => updateAlert({ resolved }))
        .then(({ data: { data } }) => callback({ type: 'resolved', data }))
        .catch(() => callback({ type: 'error' }));
    }
  };
  const defer = () => {
    if (!alertUpdating) {
      const callback: AlertUpdateEventHandler = _isFunction(onUpdate) ? onUpdate : () => Promise.resolve();
      callback({ type: 'deferred', data: alert }).catch(() => callback({ type: 'error' }));
    }
  };

  const canResolveAndDefer = settings.displayResolveControl && settings.displayDeferControl;

  return (
    <>
      {settings.displayResolveControl && (
        <a
          className={`link dark-blue ${alertUpdating ? 'pointer-events-none cursor-not-allowed' : ''}`}
          onClick={() => resolve(!resolved)}
          data-test-component="AlertActions"
          data-test-element="resolve-link"
          data-testid="alert-resolveControl"
        >
          {resolved ? 'Undo' : 'Resolve'}
        </a>
      )}
      {!resolved && canResolveAndDefer && <> &middot; </>}
      {!resolved && settings.displayDeferControl && (
        <a
          className={`link dark-blue ${alertUpdating ? 'pointer-events-none cursor-not-allowed' : ''}`}
          onClick={() => defer()}
          data-test-component="AlertActions"
          data-test-element="defer-link"
          data-testid="alert-deferControl"
        >
          Close
        </a>
      )}
    </>
  );
};

export type AlertUpdateAction =
  | { type: 'resolving' | 'error' }
  | {
      type: 'resolved' | 'deferred';
      data: AnimalAlertV1;
    };

export type AlertUpdateEventHandler = (updateAction: AlertUpdateAction) => Promise<void>;

export interface AlertSettings {
  displayHeader?: boolean;
  displayCreatedAt?: boolean;
  displayTriggeredBy?: boolean;
  displayDeferControl?: boolean;
  displayResolveControl?: boolean;
  displayResolveConfirmation?: boolean;
  displayAlertState?: boolean;
  displayActionsBelow?: boolean;
  displayResolvedTick?: boolean;
}

export interface AlertProps {
  alert: AnimalAlertV1;
  calculation?: PresetCalculation;
  animalName?: string;
  cageName?: string;
  studyId?: ID;
  onUpdate?: AlertUpdateEventHandler;
  settings?: AlertSettings;
}

export const Alert: React.FC<AlertProps> = ({
  alert,
  calculation,
  animalName,
  cageName,
  studyId,
  onUpdate,
  settings: incomingSettings,
}) => {
  const { sendRequest: updateAlert, requestSending: alertUpdating } = useRequest<
    UpdateAlertRequest,
    UpdateAlertResponse
  >({
    route: 'studies.animal.alert.update',
    method: 'patch',
    params: { studyId, animalId: alert.subject_id, alertId: alert.id },
  });

  if (_isNil(alert) || _isNil(calculation)) {
    return null;
  }
  const controlsEnabled = _notNil(studyId);
  const resolved = _notNil(alert?.resolved_at);
  const isCritical = alert.notification === 'critical';
  const settings: AlertSettings = {
    ...defaultAlertSettings,
    ...(incomingSettings ?? {}),
  };

  return (
    <div
      className={`${settings.displayActionsBelow ? 'pa3' : ''} ${
        settings.displayResolvedTick ? '' : 'mt3'
      } f6 near-black`}
      data-test-component="Alert"
      data-test-element="container"
      data-test-key={`${alert.id}`}
      data-testid="alert-container"
    >
      {settings.displayTriggeredBy && <TriggeredBy alert={alert} />}
      {settings.displayHeader && (
        <div className="flex">
          <Icon
            icon={resolved && settings.displayResolvedTick ? 'tick' : isCritical ? 'critical_alert' : 'warning_alert'}
            className={`${resolved && settings.displayResolvedTick ? 'green' : isCritical ? 'dark-red' : 'gold'} mt1`}
            width="24"
            height="24"
          />
          <div data-testid="alert-header" className="fw5 pr2 pb2 pt1 mb2">
            {cageName ?? '-'} &middot; {animalName ?? '-'}
          </div>
        </div>
      )}
      {settings.displayAlertState && <AlertState alert={alert} />}
      <div className={`bg-near-white ${styles.alertInfoBox} br3 br--top--left`}>
        <div className="flex">
          {!settings.displayHeader && (
            <Icon
              icon={resolved && settings.displayResolvedTick ? 'tick' : isCritical ? 'critical_alert' : 'warning_alert'}
              className={`${resolved && settings.displayResolvedTick ? 'green' : isCritical ? 'dark-red' : 'gold'} mt1`}
              width="24"
              height="24"
            />
          )}
          <div className="flex-grow-1 flex flex-column lh-copy">
            <span data-testid="alert-condition">{generateConditionText({ alert, calculation })}</span>
            {settings.displayCreatedAt && (
              <div className="near-black" data-testid="alert-createdAt">
                <DateTimeRenderer value={alert.created_at} />
              </div>
            )}
            {!settings.displayActionsBelow && controlsEnabled && (
              <div>
                <AlertActions
                  alert={alert}
                  updateAlert={updateAlert}
                  alertUpdating={alertUpdating}
                  settings={settings}
                  resolved={resolved}
                  onUpdate={onUpdate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {settings.displayActionsBelow && controlsEnabled && (
        <div className="mt3">
          <AlertActions
            alert={alert}
            updateAlert={updateAlert}
            alertUpdating={alertUpdating}
            settings={settings}
            resolved={resolved}
            onUpdate={onUpdate}
          />
        </div>
      )}
    </div>
  );
};

export const WarningAlert: React.FC<AlertProps> = ({ alert, animalName, cageName, studyId, calculation }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const { visible } = useVisibilityState();
  const paused = mouseOver || !visible;
  const resolved = _notNil(alert?.resolved_at);

  const handleUpdate = async (action: AlertUpdateAction) => {
    switch (action.type) {
      case 'deferred': {
        AlertService.alertDeferred({ alerts: [action.data], dismiss: true });
        break;
      }
      case 'resolved': {
        AlertService.alertResolved({ alerts: [action.data], dismiss: false });
        break;
      }
    }
  };
  const handleAlertTimeout = async () => {
    if (resolved) {
      AlertService.dismissAlert(alert);
    } else {
      await handleUpdate({ type: 'deferred', data: alert }).catch(() => handleUpdate({ type: 'error' }));
    }
  };

  return (
    <div
      className={`${styles.warningAlert} bg-white w6`}
      data-test-component="WarningAlert"
      data-test-element="container"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <AlertProgressBar
        key="deferProgressBar"
        type={resolved ? 'resolved' : 'defer'}
        color={resolved ? 'dark-green' : 'gold'}
        duration={resolved ? '2.5s' : '5s'}
        paused={paused && !resolved}
        onComplete={handleAlertTimeout}
      />
      <Alert
        alert={alert}
        animalName={animalName}
        cageName={cageName}
        studyId={studyId}
        calculation={calculation}
        settings={{
          displayCreatedAt: false,
          displayResolveConfirmation: true,
          displayTriggeredBy: false,
          displayHeader: true,
          displayActionsBelow: true,
        }}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export const CriticalAlert: React.FC<AlertProps> = ({
  alert,
  animalName,
  cageName,
  studyId,
  calculation,
  onUpdate,
}) => {
  return (
    <div className={`pa1 ${styles.criticalAlertContainer}`}>
      <Alert
        alert={alert}
        animalName={animalName}
        cageName={cageName}
        studyId={studyId}
        calculation={calculation}
        settings={{
          displayResolveConfirmation: true,
          displayDeferControl: false,
          displayTriggeredBy: false,
          displayCreatedAt: false,
          displayHeader: false,
        }}
        onUpdate={onUpdate}
      />
    </div>
  );
};
