// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { initializer, reducer } from '@/components/Modals/MoveSubjectSearch/MoveSubjectSearch.util.ts';
import Button from '@/components/UI/Button';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import { _first, _isNotBlank, _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model.ts';
import { Cage } from '@/model/Cage.model.ts';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import './MoveSubjectSearch.scss';
import { RouteService } from '@/support/RouteService';
import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';

interface MoveSubjectSearchProps {
  selectedAnimals: Array<Animal>;
  animalsHaveStudies: boolean;
  handleCallback: (data: string) => void;
}

const MoveSubjectSearch: FC<MoveSubjectSearchProps> = ({ selectedAnimals, animalsHaveStudies, handleCallback }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [searchQuery, setQuery] = useState('');
  const [apiError, setApiError] = useState<Error | false>(false);
  const [fetchSate, fetchStateDispatch] = useReducer(reducer, undefined, initializer);
  const isMounted = useMountedState();
  const maxSubjects = 10;
  const maxSubjectsTooltip = 'Cage population has reached the max limit';

  const cages = useMemo(() => {
    return fetchSate.pages.flat().map(({ id, name, counts, catalog, study_link: study }) => ({
      id,
      name,
      population: counts?.animals,
      catalog,
      study,
    }));
  }, [fetchSate]);

  const fetchCages = async () => {
    if (!fetchSate.hasNextPage) {
      return;
    }
    try {
      setOptionsLoading(true);
      const { url } = RouteService.legacyApi({
        apiRoute: 'cages.index',
        query: {
          include: 'counts,study_link',
          page: fetchSate.nextPage,
          perPage: 30,
          ...(_isNotBlank(searchQuery) ? { query: searchQuery } : {}),
        },
      });
      const { data } = await Http.get(url.toString());
      if (isMounted()) {
        setOptionsLoading(false);
        fetchStateDispatch({ type: 'ingest:page', data });
      }
    } catch (error) {
      if (notAborted(error) && isMounted()) {
        setApiError(error as Error);
        setOptionsLoading(false);
      }
    }
  };

  const isRowDisabled = ({ population, study }) => {
    const { id: studyId, access } = study.data;
    if (_notNil(studyId) && access !== 'write') {
      return {
        disabled: true,
        tooltip: "Cage is within a study you don't have write access to",
      };
    }
    if (selectedAnimals.length > maxSubjects - population) {
      return { disabled: true, tooltip: 'Not enough room in this Cage' };
    }
    if (animalsHaveStudies) {
      const animalStudyId = _first(selectedAnimals).study_id;
      if (!studyId || studyId !== animalStudyId) {
        return {
          disabled: true,
          tooltip: `Cage is not in the same study as the selected animal${selectedAnimals.length > 1 ? 's' : ''}`,
        };
      }
    } else {
      if (studyId) {
        return {
          disabled: true,
          tooltip: 'Can only move to a Cage not assigned to a study',
        };
      }
    }
    return { disabled: false, tooltip: '' };
  };

  useEffect(() => {
    if (_isNotBlank(searchQuery)) {
      fetchStateDispatch({ type: 'reset' });
      fetchCages();
    }
  }, [searchQuery]);

  const onSubmit = async ({ id, name, catalog }: Cage) => {
    setSubmitting(true);
    try {
      const animals = selectedAnimals.map((animal) => ({
        cage_id: id,
        id: animal.id,
        name: animal.name,
      }));
      await Http.patch(apiRoutes('animals.updateMany'), {
        animals,
      });
      const animalsNum = animals.length;
      return handleCallback(`Moved ${animalsNum > 1 ? `${animalsNum} animals` : '1 animal'} to ${name} - ${catalog}`);
    } catch (error) {
      if (notAborted(error) && isMounted()) {
        setApiError(error as Error);
      }
    } finally {
      if (isMounted()) {
        setSubmitting(false);
      }
    }
  };

  return (
    <div
      className={`ui-card center w-25 pa3 ${submitting ? 'disabled-form' : ''}`}
      data-test-component="MoveSubjectSearch"
      data-test-element="container"
    >
      <div className="ba b--moon-gray br1">
        <div className="pa3 bb">
          <div className="w-100 mr3">
            <SearchFilterBar
              searchPlaceholderText="Search Cage Name or Cage No."
              handleSearchInput={setQuery}
              searchQuery={searchQuery}
            />
          </div>
        </div>
        <div className="ph3">
          <div className="tc">
            {apiError && <ApiErrorBanner className="mb4" error={apiError} />}
            {optionsLoading ? (
              <p className="pt3 pb3">Loading...</p>
            ) : searchQuery === '' ? (
              <p className="h3 pt3">Please search for a cage</p>
            ) : (
              <ul className="modal-list-items near-black touch overflow-y-scroll group-list pt-2">
                {cages.length > 0 ? (
                  cages.map((cage, i) => {
                    const rowVal = (cage && isRowDisabled(cage)) || {};
                    const { population, name, catalog } = cage;
                    const rowDisabled = rowVal.disabled || population >= maxSubjects;
                    return (
                      <li
                        key={`${catalog}-${i}`}
                        className={`bt b--moon-gray ${i === 0 ? 'bt-0' : ''}`}
                        style={{ height: '50px' }}
                        data-tooltip-id="global-tooltip-id"
                        data-tooltip-content={
                          rowVal.disabled ? rowVal.tooltip : population >= maxSubjects ? maxSubjectsTooltip : ''
                        }
                        data-test-element="cage-li"
                        data-test-key={cage.id}
                      >
                        {
                          <a
                            disabled={rowDisabled}
                            className="h-100 db pt3"
                            onClick={() => onSubmit(cage)}
                            data-test-element="cage-anchor"
                          >
                            <div className="flex justify-between">
                              <span className={`-pt2 ${rowDisabled ? 'red' : ''}`}>
                                {name} - {catalog}
                              </span>
                              <span className={`${rowDisabled ? 'red' : 'mid-gray'}`}>{population}</span>
                            </div>
                          </a>
                        }
                      </li>
                    );
                  })
                ) : (
                  <p className="pt3 pb3">
                    {cages.length === 0 && !searchQuery ? '0 cages available' : `0 results found for ${searchQuery}`}
                  </p>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="pa3 bt">
          {cages.length > 0 && fetchSate.hasNextPage && (
            <Button primary className={`button plain mb2 w-100 ${submitting && 'disabled-link'}`} onClick={fetchCages}>
              More
            </Button>
          )}
          <Button
            className={`button plain w-100 ${submitting && 'disabled-link'}`}
            onClick={() => dispatch({ type: 'CLOSE_MODAL' })}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MoveSubjectSearch;
