import {
  analysisDayFromOptions,
  analysisTypeOptions,
  averageTypeOptions,
} from '@/components/Studies/Groups/Analysis/Analysis.util.ts';
import {
  type AnalysisFormData,
  analysisSetupFormResolver,
} from '@/components/Studies/Groups/Analysis/AnalysisSetup.utils';
import Button from '@/components/UI/Button';
import InputWithUnits from '@/components/UI/FormElements/InputWithUnits';
import Select from '@/components/UI/Select';
import type { SelectOption } from '@/components/UI/Select/Select';
import { classNames } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import { AnalysisOptions, AnalysisState, AnalysisType, AverageType, TrackingFrom } from '@/model/Analysis.model.ts';
import { type FC, useCallback, useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, InputState, type Resolver, useForm } from 'react-hook-form';
import styles from './Analysis.module.scss';

interface AnalysisSetupProps {
  config?: AnalysisState['config'];
  options?: AnalysisOptions;
  measurements: Record<string, { value: string; label: string }>;
  onChange: (config: AnalysisOptions) => void;
}
export const AnalysisSetup: FC<AnalysisSetupProps> = ({ config, options, measurements, onChange }) => {
  const resolver: Resolver<AnalysisFormData> = useCallback(analysisSetupFormResolver(config), [config]);

  const { handleSubmit, control, formState, watch } = useForm<AnalysisFormData>({
    mode: 'onChange',
    resolver,
    shouldUnregister: true,
    criteriaMode: 'all',
    defaultValues: {
      type: options?.type ?? config?.keys()?.next()?.value,
      measurement: options?.measurement,
      averageType: options?.average_type,
      day: { value: options?.day, from: options?.day_from },
      targetValue: options?.target_value,
    },
  });

  const type = watch('type') as AnalysisType;

  const typeOptions = useMemo(() => {
    return Array.from(config?.keys() ?? []).map((t) => analysisTypeOptions[t]);
  }, [config]);

  const optionData = useMemo(() => {
    const typeConfig = config?.get(type as AnalysisType);
    return {
      measurementOptions: [...(typeConfig?.measurements ?? [])].reduce<Array<SelectOption<string>>>(
        (acc, m) => (Object.prototype.hasOwnProperty.call(measurements, m) ? [...acc, measurements[m]] : acc),
        []
      ),
      averageTypeOptions: [...(typeConfig?.average_type ?? [])].reduce<Array<SelectOption<AverageType>>>(
        (acc, at) =>
          Object.prototype.hasOwnProperty.call(averageTypeOptions, at) ? [...acc, averageTypeOptions[at]] : acc,
        []
      ),
      trackingFromOptions: [...(typeConfig?.tracking_from ?? [])].reduce<Array<SelectOption<TrackingFrom>>>(
        (acc, tf) =>
          Object.prototype.hasOwnProperty.call(analysisDayFromOptions, tf) ? [...acc, analysisDayFromOptions[tf]] : acc,
        []
      ),
    };
  }, [type]);

  const submit = (formData: AnalysisFormData) => {
    onChange({
      type: formData.type,
      measurement: formData.measurement,
      day: formData.day.value,
      day_from: formData.day.from,
      average_type: formData.averageType,
      target_value: formData.targetValue,
    });
  };

  return (
    <form className="pa3" onSubmit={handleSubmit(submit)} data-test-component="AnalysisSetup" data-test-element="form">
      <div className="pb3" data-testid="analysis-type-container" data-test-element="type-container">
        <label>Summary Table</label>
        <Controller
          name="type"
          control={control}
          render={({ value, onChange, onBlur }: ControllerRenderProps, { invalid }: InputState) => (
            <Select
              className={classNames('', { [styles.selectIsInvalid]: invalid })}
              value={analysisTypeOptions[value as AnalysisType]}
              onChange={(change) => onChange(change?.value)}
              onBlur={onBlur}
              isMulti={false}
              options={typeOptions}
            />
          )}
        />
      </div>
      {_isNotEmpty(optionData.measurementOptions) && (
        <div className="pb3" data-test-element="measurement-container" data-testid="analysis-measurement-container">
          <label>Measurement</label>
          <Controller
            name="measurement"
            control={control}
            defaultValue={config?.get(type)?.measurements?.values().next().value}
            render={({ value, onChange, onBlur }: ControllerRenderProps, { invalid }: InputState) => (
              <Select
                className={classNames('', { [styles.selectIsInvalid]: invalid })}
                value={measurements[value]}
                onChange={(change) => onChange(change?.value)}
                onBlur={onBlur}
                isMulti={false}
                options={optionData.measurementOptions}
              />
            )}
          />
        </div>
      )}
      {(type === 'Oncology' || type === 'EfficacyProphylactic') && (
        <div data-test-element="target-value-container" data-testid="analysis-target-value-container">
          <label>Target tumor volume</label>
          <Controller
            name="targetValue"
            control={control}
            render={({ value, onChange, onBlur }: ControllerRenderProps, { invalid }: InputState) => (
              <InputWithUnits
                name="targetValue"
                hasError={invalid}
                value={value}
                unit="mm³"
                valueMin={0}
                valueStep={0.001}
                onChange={(change) => onChange(change.value)}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      )}
      {_isNotEmpty(optionData.averageTypeOptions) && (
        <div className="pb3" data-test-element="average-container" data-testid="analysis-average-container">
          <label>Summary statistic</label>
          <Controller
            name="averageType"
            control={control}
            defaultValue={config?.get(type)?.average_type?.values().next().value}
            render={({ value, onChange, onBlur }: ControllerRenderProps, { invalid }: InputState) => (
              <Select
                className={classNames('', { [styles.selectIsInvalid]: invalid })}
                value={averageTypeOptions[value as AverageType]}
                onChange={(change) => onChange(change?.value)}
                onBlur={onBlur}
                isMulti={false}
                options={optionData.averageTypeOptions}
              />
            )}
          />
        </div>
      )}

      <div className="pb3" data-test-element="analysis-day-container" data-testid="analysis-day-container">
        <label>{type === 'Survival' || type === 'Tolerance' ? 'Reference Date' : 'Day'}</label>
        <Controller
          name="day"
          control={control}
          defaultValue={{ value: undefined, from: config?.get(type)?.tracking_from?.values().next().value }}
          render={({ value: day, onChange, onBlur }: ControllerRenderProps, { invalid }: InputState) =>
            type === 'Survival' || type === 'Tolerance' ? (
              <Select
                className={classNames('', { [styles.selectIsInvalid]: invalid })}
                value={day?.from ? optionData.trackingFromOptions.find((option) => option.value === day.from) : null}
                onChange={(change) => onChange({ from: change?.value, value: 1 })}
                onBlur={onBlur}
                isMulti={false}
                options={optionData.trackingFromOptions}
              />
            ) : (
              <InputWithUnits
                hasError={invalid}
                value={day?.value}
                unit={day?.from}
                valueMin={1}
                valueStep={1}
                onChange={(change) => onChange({ value: change?.value, from: change?.unit })}
                onBlur={onBlur}
                name="day"
                unitOptions={optionData.trackingFromOptions}
              />
            )
          }
        />
      </div>
      <Button submit disabled={!formState.isDirty || !formState.isValid} testId="analysis-submit-button">
        Apply
      </Button>
    </form>
  );
};
