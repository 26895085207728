// @ts-nocheck: converted from JS

import Link from '@/components/UI/Link';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className="message-page">
      <div className="ui-card pa3 pa4-l mv6 tc">
        <h2 className="normal f3 f2-l black pb3">The page you are trying to view doesn’t exist.</h2>
        <p className="f5 f4-l pb3">
          You may have mistyped the address or the page may have moved. We apologise for the inconvenience.
        </p>
        <Link to="/" className="button">
          Return to home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
