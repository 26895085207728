import Loading from '@/components/Loading';
import MoveToStudyHeader from '@/components/Modals/MoveToStudy/MoveToStudyHeader';
import Table from '@/components/UI/Table';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { CageApiId } from '@/model/Cage.model';
import type { Study } from '@/model/Study.model';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiService } from '@/support/ApiService';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { ModalActions, ModalContainer, useModalAction } from '@/utils/modal';

interface MoveToStudyProps {
  subject: Array<Animal>;
  handleCallback: (cageApiIds: Array<string | undefined>, currentStudy: Study, animalCount: number) => void;
}

const columns = [
  { id: 'no', Header: 'No.', accessor: 'number' },
  { id: 'id', Header: 'Name', accessor: 'name' },
  { id: 'cage', Header: 'Cage', accessor: 'cage.name' },
  { id: 'sex', Header: 'Sex', accessor: 'sex' },
];

const MoveToStudy: React.FC<MoveToStudyProps> = ({ subject, handleCallback }) => {
  const [animals, setAnimals] = useState<Animal[]>([]);
  const [animalsLoading, setAnimalsLoading] = useState<boolean>(true);

  const { closeModal } = useModalAction();
  const { id: studyId } = useParams<{ id: string }>();
  const { entity: currentStudy } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
  });

  const { cageApiIds, cageIds } = subject.reduce(
    (acc, item) => {
      if (_notNil(item.cage) && _notNil(item.cage?.api_id)) {
        acc.cageApiIds.add(item.cage.api_id);
      }
      if (_notNil(item.cage) && _notNil(item.cage?.id)) {
        acc.cageIds.add(item.cage.id);
      }
      return acc;
    },
    { cageApiIds: new Set<string>(), cageIds: new Set<string | number>() }
  );

  const handleFormSubmit = () => {
    if (_notNil(currentStudy)) {
      handleCallback(Array.from(cageApiIds), currentStudy, animals?.length ?? 0);
    }
  };

  const fetchSubjects = async () => {
    if (_notNil(currentStudy) && _notNil(currentStudy.api_id)) {
      const encodedCageIdsFilter = encodeURIComponent(`cage|in|${Array.from(cageIds).join(',')}`);
      const encodedNullFilter = encodeURIComponent(`cage|ne|null`);
      const response = await ApiService.call({
        endpoint: 'GET /api/v1/studies/{studyId}/animals',
        path: { studyId: currentStudy.api_id },
        query: {
          perPage: -1,
          ...(_isNotEmpty(cageIds)
            ? {
                include: ['cage'],
                filterType: 'and',
              }
            : {}),
        },
        filters: `filters%5B%5D=${encodedCageIdsFilter}&filters%5B%5D=${encodedNullFilter}`,
      });
      if (response.type === 'success') {
        setAnimals(response.body.data as unknown as Animal[]);
      }
      setAnimalsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, [currentStudy]);

  const cageCount = useMemo(
    () =>
      (animals ?? []).reduce((acc, animal) => {
        const cageId = animal?.cage?.api_id;
        if (_notNil(cageId)) {
          acc.add(cageId);
        }
        return acc;
      }, new Set<CageApiId>()).size,
    [animals]
  );
  if (animalsLoading) {
    return <Loading />;
  }

  return (
    <ModalContainer size="medium" className="bg-white h-100">
      <MoveToStudyHeader cageCount={cageCount} />
      <Table className="pa3" data={animals ?? []} columns={columns} />
      <ModalActions
        submitBtnText="Next"
        cancelBtnText="Cancel"
        submitButtonProps={{
          className: 'ml3',
          disabled: false,
          loading: false,
        }}
        onCancel={closeModal}
        onSubmit={handleFormSubmit}
        className="pa3 flex self-end bt b--moon-gray w-100"
      />
    </ModalContainer>
  );
};

export default MoveToStudy;
