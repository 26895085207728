import { NoDataMessage } from '@/components/NoDataCard';
import { filterOptions, generateColumns } from '@/components/Requests/Requests.utils';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { defaultPromiseErrorHandler } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import type { Request } from '@/model/Request.model';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController';
import { api as apiRoutes } from '@/support/route';
import React, { useEffect, useState } from 'react';

interface RequestTableProps {
  noDataTitle: string;
  noDataText: string;
  statusFilter: {
    type: string;
    op: string;
    value: string;
    filter: string;
  };
}

const RequestsTable: React.FC<RequestTableProps> = ({ statusFilter, noDataTitle, noDataText }) => {
  const [columns, setColumns] = useState<Columns<Request>>([]);
  const { newAbortController } = useAbortController();

  useEffect(() => {
    generateColumns(newAbortController().signal)
      .then((cols) => setColumns(cols))
      .catch(defaultPromiseErrorHandler);
  }, []);

  return (
    <>
      {_isNotEmpty(columns) && (
        <APITable
          defaultSortBy={{ id: 'time', desc: true }}
          columns={columns}
          apiInfo={{ type: 'legacyInternalApi', route: apiRoutes('user.requests.list') }}
          includeParam="study,study_link"
          customFilter={statusFilter}
          hideSearch={true}
          filterOptions={filterOptions}
          NoDataComponent={() => <NoDataMessage title={noDataTitle} text={noDataText} dark />}
        />
      )}
    </>
  );
};

export default RequestsTable;
