import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList, { ActionProps } from '@/components/UI/SelectDropDown/Menus/ActionList';
import { _isEmpty, _notNil } from '@/littledash';
import { web as webRoute } from '@/support/route.ts';
import { Dispatch } from 'redux';
import { FC } from 'react';
import { Cage } from '@/model/Cage.model.ts';
import { ID } from '@/model/Common.model.ts';
import { useHistory } from 'react-router-dom';
import { History } from '@/utils/history.ts';

interface CageActionProps extends ActionProps {
  cage?: string;
}

export interface CageExtended extends Cage {
  study_link: {
    data: {
      access: string;
    };
  };
  operations: {
    move: {
      reason: string;
    };
  };
}

const cageActions = (
  cage: CageExtended,
  history: History,
  dispatch: Dispatch,
  storeDispatch: Dispatch,
  handleRemoveCageFromStudy: (id: ID, name: string) => void
): CageActionProps[] => {
  const canManageCage = _notNil(cage?.study_link?.data?.access) && cage?.study_link.data.access === 'write';
  const canReadCage = _notNil(cage?.study_link?.data?.access) && cage?.study_link.data.access === 'read';
  const cannotMoveCageReason = cage?.operations?.move?.reason;
  const animals = cage?.counts?.animals;
  return [
    {
      name: 'Add animals',
      disabled: !canManageCage || (animals ?? 0) >= 10,
      disabledReasonTooltip: 'You do not have write access for this cage.',
      cage: cage?.catalog,
      action: () => history.push(webRoute('colony.add.animals', { id: cage?.id })),
    },
    {
      name: 'Remove cage from study',
      disabled: !canManageCage || !cage?.study_id || !!cannotMoveCageReason,
      disabledReasonTooltip: cannotMoveCageReason
        ? cannotMoveCageReason
        : 'You do not have write access for this cage.',
      cage: cage?.catalog,
      action: () =>
        storeDispatch({
          type: 'OPEN_MODAL',
          modal: 'REMOVE_CAGE_FROM_STUDY',
          props: {
            onClick: () => handleRemoveCageFromStudy(cage?.id, cage?.name),
          },
        }),
    },
    {
      name: 'Print label',
      disabled: !(canReadCage || canManageCage),
      disabledReasonTooltip: 'You do not have read access for this cage.',
      cage: cage?.catalog,
      action: () => dispatch({ type: 'SET_CAGE_ID_TO_PRINT', data: cage?.id }),
    },
  ];
};

interface CageActionCellProps {
  cage: CageExtended;
  dispatch: Dispatch;
  storeDispatch: Dispatch;
  handleRemoveCageFromStudy: (id: ID, name: string) => void;
}

const CageActionsCell: FC<CageActionCellProps> = ({ cage, dispatch, storeDispatch, handleRemoveCageFromStudy }) => {
  const history = useHistory();
  const cellActions = cageActions(cage, history, dispatch, storeDispatch, handleRemoveCageFromStudy);

  return !_isEmpty(cellActions) ? (
    <span className="cellActions" style={{ textAlign: 'right' }}>
      <SelectDropDown
        title="More options"
        iconOnly
        className="hover-near-black"
        alignMenu="right"
        portalProps={{
          width: 200,
        }}
        testId="cage-action-dropdown"
      >
        <ActionList actions={cellActions} />
      </SelectDropDown>
    </span>
  ) : (
    ''
  );
};

export default CageActionsCell;
