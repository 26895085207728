import Radio from '@/components/UI/FormElements/Radio/Radio';
import type { StudyCodeYearCode } from '@/model/StudyCode.model';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { FC, useState } from 'react';

const yearCodes: Array<StudyCodeYearCode> = ['YY', 'YYYY'];

interface StudyCodeAddYearProps {
  onClick: (yearCode: StudyCodeYearCode) => void;
  closeModal: () => void;
}

const StudyCodeAddYear: FC<StudyCodeAddYearProps> = ({ onClick, closeModal }) => {
  const [yearCode, setYearCode] = useState<StudyCodeYearCode>('YY');

  const handleSubmit = () => {
    onClick(yearCode);
    closeModal();
  };

  return (
    <ModalContainer size="xsmall">
      <ModalHeader title="Add year" closeModal={closeModal} className="pa3 b--moon-gray bg-white" />
      <div className="pb3 ph3">
        {yearCodes.map((code) => (
          <Radio
            id={`${code}Code`}
            key={`${code}Code`}
            name="yearCode"
            value={code}
            label={code}
            onChange={({ target: { value } }) => setYearCode(value as StudyCodeYearCode)}
            checked={yearCode === code}
            className="mb2"
          />
        ))}
      </div>
      <ModalActions onSubmit={handleSubmit} className="pa3 bt" />
    </ModalContainer>
  );
};

export default StudyCodeAddYear;
