// @ts-nocheck: converted from JS

export const openModal = (modal, dispatch, props) => {
  dispatch({
    type: 'OPEN_MODAL',
    modal,
    props,
  });
};

export const closeModal = (dispatch) => {
  dispatch({
    type: 'CLOSE_MODAL',
  });
};
