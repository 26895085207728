import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import WithAside from '@/components/UI/FormElements/WithAside';
import { NumberInput as Number } from '@/components/UI/FormFields/Number';
import Icon from '@/components/UI/Icon';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID, ISODateTime } from '@/model/Common.model';
import type { Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { web as webRoute } from '@/support/route';
import { useDevices } from '@/utils/devices/useDevices';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import AddDose from '../AddDose';
import MetadataButton from '@/components/UI/MetadataButton/MetadataButton';

type Treatments = Array<Treatment>;
type DoseCreateAPIResponse = { data: { data: Treatment } };

interface DoseFormSubmissionsState {
  [key: string]: undefined | DoseCreateAPIResponse;
}

interface RenderWorkflowDosingProps {
  studyId: ID;
  treatments: Treatments;
  animal: Animal;
  bodyweight?: string;
  dosedAt: ISODateTime;
  takeBodyWeight?: boolean;
  onAllDosesSaved: (responses: DoseFormSubmissionsState) => void;
  onEachDoseSave: () => void;
  openSetupWorkflow: () => void;
}

const isEveryDoseSubmitted = (doseFormSubmissions: DoseFormSubmissionsState) =>
  Object.values(doseFormSubmissions).every((value) => _notNil(value));

const groupIdIPresentInTreatments = (treatment: Treatment, treatmentGroupId?: ID) =>
  treatment.study_groups?.some((group: TreatmentGroup) => String(group?.id) === String(treatmentGroupId));

const initialDoseFormSubmissionState = (treatments: Treatments, treatmentGroupId: ID) =>
  treatments.reduce((acc, treatment: Treatment) => {
    if (_notNil(treatment.id) && groupIdIPresentInTreatments(treatment, treatmentGroupId)) {
      acc = {
        ...acc,
        [treatment.id]: undefined,
      };
    }
    return acc;
  }, {});

const OpenWorkflowSetupButton: React.FC<{ openSetupWorkflow: () => void }> = ({ openSetupWorkflow }) => (
  <Button plain onClick={openSetupWorkflow}>
    Workflow Setup
  </Button>
);

const RenderWorkflowDosing: React.FC<RenderWorkflowDosingProps> = ({
  studyId,
  treatments,
  animal,
  bodyweight,
  dosedAt,
  takeBodyWeight = false,
  onEachDoseSave,
  onAllDosesSaved,
  openSetupWorkflow,
}) => {
  const [doseFormSubmissions, setDoseFormSubmissions] = useState<DoseFormSubmissionsState>(
    initialDoseFormSubmissionState(treatments, animal?.study_group_id ?? '')
  );
  const [disabledTreatments, setDisabledTreatments] = useState<Record<string, Treatment>>({});
  const isBodyweightEmpty = _isNil(bodyweight);

  const filteredTreatments = useMemo(
    () => (treatments ?? []).filter((treatment) => groupIdIPresentInTreatments(treatment, animal.study_group_id)),
    [treatments, animal]
  );

  // Methods to handle taking a body weight snapshot at dose time
  const { control, watch, errors, setValue } = useForm<{ weightAtDosing: string }>({
    mode: 'onChange',
    defaultValues: { weightAtDosing: undefined },
  });
  const newBodyWeight = watch('weightAtDosing');

  const onNewReading = (response: string) => {
    setValue('weightAtDosing', response);
    nextReading(true);
  };

  const { nextReading } = useDevices({
    targetId: 'dosing-weight',
    onNewReading,
  });

  const handleSaveDoseForm = (response: DoseCreateAPIResponse): void => {
    if (onEachDoseSave) {
      onEachDoseSave();
    }
    const responseData = response?.data?.data;
    if (responseData) {
      const updatedDoses = { ...doseFormSubmissions, [`${responseData?.treatment_id}`]: response };
      if (isEveryDoseSubmitted(updatedDoses) && treatments?.length > 0) {
        onAllDosesSaved(updatedDoses);
        toast.success(`Dose${treatments?.length !== 1 ? 's' : ''} saved.`);
      }

      setDoseFormSubmissions(updatedDoses);
    }
  };

  useEffect(() => {
    if (!takeBodyWeight && isBodyweightEmpty) {
      setDisabledTreatments(
        filteredTreatments.reduce<Record<string, Treatment>>((acc, treatment) => {
          if (treatment.type !== 'custom') {
            acc[treatment.id] = treatment;
          }
          return acc;
        }, {})
      );
    }
  }, []);

  const noDataBannerClasses = 'ma3 tc';

  if (_isEmpty(treatments)) {
    return (
      <div data-testid="RenderWorkflowDosing-no-treatments-banner">
        <NoDataCard
          dark
          className={noDataBannerClasses}
          title="Select treatments in Workflow Setup under the Dosing section to record a dose against this animal."
          NoDataComponent={<OpenWorkflowSetupButton openSetupWorkflow={openSetupWorkflow} />}
        />
      </div>
    );
  }

  if (_isNil(animal?.study_group_id) && _notNil(studyId)) {
    return (
      <div data-testid="RenderWorkflowDosing-no-treatment-group-banner">
        <NoDataCard
          dark
          className={noDataBannerClasses}
          title="Assign this animal to a treatment group to record a dose. Animals can be assigned to a treatment group in the Animals section."
          btnTxt="Animals section"
          btnInline
          link={webRoute('studies.animals', { id: studyId })}
        />
      </div>
    );
  }

  if (!takeBodyWeight && isBodyweightEmpty && Object.keys(disabledTreatments).length === filteredTreatments.length) {
    return (
      <div data-testid="RenderWorkflowDosing-no-bw-banner">
        <NoDataCard
          dark
          className={noDataBannerClasses}
          title="A bodyweight has not been recorded for this animal. Select Measurements to record a bodyweight."
        />
      </div>
    );
  }

  if (_isEmpty(filteredTreatments)) {
    return (
      <div data-testid="RenderWorkflowDosing-no-match-group-banner">
        <NoDataCard
          dark
          fluidHeight={true}
          className={noDataBannerClasses}
          title={`This animal is not in a group that receives the selected treatment${
            treatments?.length !== 1 ? 's' : ''
          }`}
        />
      </div>
    );
  }

  return (
    <>
      <div className="pa3">
        {<label>Weight at dosing</label>}
        <Controller
          name="weightAtDosing"
          defaultValue={!takeBodyWeight ? bodyweight : null}
          control={control}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
              message: 'Must be a valid number',
            },
            min: { value: 0, message: 'Must be greater than 0' },
          }}
          render={({ onChange, value }: ControllerRenderProps) => (
            <WithAside style={{ width: 160 }} render={<small className="ma2 lh-title near-black bg-white">g</small>}>
              <Number
                className={_notNil(errors.weightAtDosing) ? 'input__error' : ''}
                onChange={onChange}
                value={value}
                step="0.01"
                style={{ marginBottom: 0 }}
                disabled={!takeBodyWeight}
                testId="workflow-weight-at-dosing"
                autoFocus
              />
            </WithAside>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="weightAtDosing"
          render={({ message }) => <small className="db red pt2">{message}</small>}
        />
      </div>
      {filteredTreatments.map((treatment, index) => {
        if (!isBodyweightEmpty || (isBodyweightEmpty && _isNil(disabledTreatments[treatment.id]))) {
          return (
            <div
              className="pv3 bt b--moon-gray"
              key={`enabled-treatments_${treatment.id}`}
              data-testid="RenderWorkflowDosing-dose-form-container"
            >
              {_notNil(treatment.display_name) && (
                <div className="flex ph3" data-testid="RenderWorkflowDosing-dose-form-title-container">
                  <h3 className="lh-title pb2 f5 w-100">{treatment.display_name}</h3>
                  <MetadataButton entity={treatment ?? {}} entityName="treatmentMeta" tooltip="Treatment metadata" />
                </div>
              )}
              <AddDose
                animalId={animal?.id}
                bodyweight={takeBodyWeight ? (newBodyWeight ?? 0) : bodyweight}
                takingWeight={takeBodyWeight}
                disabledSubmit={
                  takeBodyWeight &&
                  treatment.type !== 'custom' &&
                  (_notNil(errors.weightAtDosing) || _isNil(newBodyWeight))
                }
                workflowDate={dosedAt}
                disabled={_notNil(doseFormSubmissions[treatment.id])}
                treatment={treatment}
                onSave={handleSaveDoseForm}
                autoFocus={!takeBodyWeight && index === 0}
              />
              {_notNil(doseFormSubmissions[treatment.id]) && (
                <div className="mt3 ph3">
                  <RiCheckboxCircleLine className="dib v-mid green" size={22} />{' '}
                  <h3 className="green f6 lh-title v-mid dib">Dose saved</h3>
                </div>
              )}
            </div>
          );
        }
      })}
      {_isNotEmpty(disabledTreatments) ? (
        <div className={`flex flex-column g3 bg-near-white ma3 br3 br--top--left`}>
          <div className="pa3 f6 near-black" data-testid="alert-container">
            <div className="flex">
              <Icon icon="warning_alert" className="gold mt1" width="24" height="24" />
              <div data-testid="alert-header" className="fw5 pr2 pt1 mb2">
                {`The following treatment${
                  Object.keys(disabledTreatments).length > 1 ? 's are' : ' is'
                } not available without bodyweight. Select Measurements to record a bodyweight.`}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {Object.values(disabledTreatments).map((treatment, index) => (
        <div
          className={`pv3 ${index > 0 ? 'bt b--moon-gray ' : ''}`}
          key={`disabled-treatments_${treatment.id}`}
          data-testid="RenderWorkflowDosing-dose-form-disabled-container"
        >
          {_notNil(treatment.display_name) && <h3 className="lh-title pb2 f5 ph3">{treatment.display_name}</h3>}
          <AddDose
            animalId={animal?.id}
            bodyweight={bodyweight}
            workflowDate={dosedAt}
            disabled
            treatment={treatment}
            onSave={handleSaveDoseForm}
          />
          {_notNil(doseFormSubmissions[treatment.id]) && (
            <div className="mt3 ph3">
              <RiCheckboxCircleLine className="dib v-mid green" size={22} />{' '}
              <h3 className="green f6 lh-title v-mid dib">Dose saved</h3>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default RenderWorkflowDosing;
