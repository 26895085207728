// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Comments from '@/components/UI/Comments';
import { useRequest } from '@/support/Hooks/request';
import { api as apiRoute } from '@/support/route';
import React from 'react';

interface ApprovalCommentsProps {
  approvalId: string;
  comments: Array<unknown>;
  fetchComments: () => Promise<void>;
}

const ApprovalComments: React.FC<ApprovalCommentsProps> = ({ comments, fetchComments, approvalId }) => {
  const { sendRequest: addComment, requestError: errorAddingComment } = useRequest({
    route: apiRoute('approvals.comments', {
      approvalId,
    }),
    method: 'post',
    config: { useAbortSignal: false },
  });

  const onAddComment = async (body) => {
    await addComment({ body });
    fetchComments();
  };

  return (
    <div className="mw6">
      {errorAddingComment ? (
        <ApiErrorBanner
          className="mb3"
          title="There was an error adding your comments"
          text="Please try again later. If this keeps occurring please contact support."
          error={errorAddingComment}
        />
      ) : (
        <Comments comments={comments} onAddComment={onAddComment} canAddComment />
      )}
    </div>
  );
};

export default ApprovalComments;
