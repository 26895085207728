// @ts-nocheck: converted from JS

import { isFunction, preventNumberScroll, successToast } from '@/helpers';
import { _notNil } from '@/littledash';
import { useRequest } from '@/support/Hooks/request';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';

const AddCages = ({ study, handleCallback, closeModal }) => {
  const MINMUM_CAGES = 1;
  const MAXIMUM_CAGES = 50;
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      cages: 1,
    },
  });
  const {
    sendRequest: createCages,
    requestSending: creatingCages,
    requestError,
  } = useRequest({
    route: 'cages.createMany',
    method: 'post',
  });

  const generateCages = (num = 0) => {
    const result = [];
    for (let index = 0; index < num; index++) {
      result[index] = {
        ...result[index],
        ...(_notNil(study) ? { study_id: study.id } : {}),
      };
    }
    return result;
  };

  const onSubmit = async ({ cages }) => {
    const res = await createCages({ cages: generateCages(cages) });
    if (!requestError) {
      successToast(`Successfully created ${cages} cage${Number(cages) !== 1 ? 's' : ''}`);
      if (isFunction(handleCallback)) {
        handleCallback(res);
      }
    }
  };

  let title = 'Create cages';
  if (study) {
    title = `Create cages in study ${study.name}`;
  }

  return (
    <ModalContainer size="narrow" className="bg-white">
      <ModalHeader title={title} closeModal={closeModal} closeDisabled={false} className="pa3 bb b--moon-gray w-100" />
      <div className="pa3 mv3">
        <label htmlFor="cages">How many cages would you like to create?</label>
        <input
          type="number"
          onWheel={preventNumberScroll}
          autoFocus
          style={{ marginBottom: 0 }}
          name="cages"
          ref={register({
            min: {
              value: MINMUM_CAGES,
              message: `Can't be less than ${MINMUM_CAGES}`,
            },
            max: {
              value: MAXIMUM_CAGES,
              message: `Can't be more than ${MAXIMUM_CAGES}`,
            },
            required: 'This field is required',
          })}
        />
        <ErrorMessage
          errors={errors}
          name="cages"
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Create"
        cancelBtnText="Cancel"
        submitButtonProps={{
          disabled: creatingCages,
          loading: creatingCages,
        }}
        cancelButtonProps={{ disabled: creatingCages }}
      />
    </ModalContainer>
  );
};

export default AddCages;
