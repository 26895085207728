import type { CSSProperties, FC } from 'react';
import './Indicator.scss';

interface IndicatorProps {
  counter?: number;
  smallIndicator?: boolean;
  onClick?: () => void;
  tooltip?: string;
  theme?: 'blue' | 'red';
  indicatorClassName?: string;
  indicatorStyle?: CSSProperties;
}

/**
 *
 * @param {number} counter
 * @param {boolean} smallIndicator
 * @param {function} onClick
 * @param {string} tooltip
 * @param {('blue'|'red')} theme
 * @param {string} indicatorClassName
 * @param {object} indicatorStyle
 * @returns {ReactNode}
 */
const Indicator: FC<IndicatorProps> = ({
  counter,
  smallIndicator = false,
  onClick,
  tooltip,
  theme = 'red',
  indicatorClassName = '',
  indicatorStyle,
}) => {
  const themes = {
    blue: 'bg-blue white',
    red: 'bg-red white',
  };
  let className = `UI-Indicator ${themes[theme]}`;
  if (indicatorClassName) {
    className = `${className} ${indicatorClassName}`;
  }

  if (smallIndicator) {
    className = `${className} --small-indicator`;
  }

  if (!smallIndicator && counter) {
    const counterLength = String(counter).length;
    if (counterLength > 1) {
      className = `${className} --display-pill`;
    } else {
      className = `${className} --display-circle`;
    }
  }

  if (onClick || tooltip) {
    const hoverClass = `bg-dark-${theme}-hover pointer`;
    className = `${className} ${hoverClass}`;
  }

  let props: Record<string, any> = {
    className,
  };

  if (tooltip) {
    props = {
      ...props,
      'data-tooltip-id': 'global-tooltip-id',
      'data-tooltip-content': tooltip,
    };
  }

  if (onClick) {
    props = {
      ...props,
      onClick,
    };
  }

  if (indicatorStyle) {
    props = {
      ...props,
      style: indicatorStyle,
    };
  }

  return <div {...props}>{counter && <span>{counter}</span>}</div>;
};

export default Indicator;
