import SearchSelect from '@/components/UI/SearchSelect';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { PickRequired } from '@/model/Common.model';
import type { TaskV1 } from '@/model/Task.model';
import type { StudyUserSummaryV1, UserApiId } from '@/model/User.model';
import { type FC, useEffect, useMemo, useState } from 'react';
import type { TaskDetailsProps } from '../TaskDetails';

const getTeamUserStatus = (status: string, inStudyUsers = false) => {
  if (!inStudyUsers) {
    return '(Removed from study)';
  }
  switch (status) {
    case 'inactive':
      return '(Inactive user)';
    case 'pending':
      return '(User pending)';
    default:
      return '';
  }
};

export const userAssignedSectionItems = (
  selected: Array<UserApiId>,
  usersMap: Map<UserApiId, { id: UserApiId; title: string; status: string }>,
  assignees: Array<{ id: `usr_${string}`; name: string }>
) =>
  selected.reduce<Array<{ id: UserApiId; title: string }>>((acc, id) => {
    const isStudyUser = usersMap.has(id);
    acc.push({
      id,
      title: `${
        usersMap.get(id)?.title ?? assignees.find((assignee) => assignee.id === id)?.name ?? ''
      } ${getTeamUserStatus(usersMap.get(id)?.status ?? '', isStudyUser)}`,
    });
    if (isStudyUser) {
      usersMap.delete(id);
    }
    return acc;
  }, []);

export const AssigneesPanel: FC<
  Pick<TaskDetailsProps, 'selectionDisabled'> & {
    task: PickRequired<TaskV1, 'animals'>;
    handleAssigneesChange: (selection: UserApiId[]) => Promise<void>;
    users: Array<Pick<StudyUserSummaryV1, 'api_id' | 'name' | 'status'>>;
  }
> = ({ task, handleAssigneesChange, selectionDisabled, users }) => {
  const studyInactive = !task.study.active;
  const [selected, setSelected] = useState(task?.assignees?.map((a) => a.id) ?? []);

  useEffect(() => {
    if (_notNil(handleAssigneesChange) && _notNil(selected) && _notNil(task)) {
      const currentSelection = new Set(task?.assignees?.map((a) => a.id) ?? []);
      const updatedSelection = [...new Set(selected)];
      if (
        !(currentSelection.size === updatedSelection.length && updatedSelection.every((id) => currentSelection.has(id)))
      ) {
        handleAssigneesChange(updatedSelection);
      }
    }
  }, [selected, task]);

  const sections = useMemo(() => {
    const userMap = new Map<UserApiId, { id: UserApiId; title: string; status: string }>(
      users.map(({ api_id, name, status }) => [
        api_id,
        {
          id: api_id,
          title: name,
          status,
        },
      ])
    );
    const assignedSection = {
      title: 'Assigned',
      items: userAssignedSectionItems(selected ?? [], userMap, task?.assignees ?? []),
    };
    const availableSection = {
      title: 'Available',
      items: [...userMap.values()].filter(({ status }) => status === 'active'),
    };
    const result: Array<{ title: string; items: Array<{ id: UserApiId; title: string }> }> = [];
    if (_isNotEmpty(assignedSection.items)) {
      result.push(assignedSection);
    }
    if (_isNotEmpty(availableSection.items)) {
      result.push(availableSection);
    }
    return result;
  }, [users, selected, studyInactive]);

  return (
    <SearchSelect
      selected={selected}
      setSelected={setSelected}
      disabled={studyInactive || selectionDisabled}
      sections={sections}
      overflowHeight="100%"
    />
  );
};
