// @ts-nocheck: converted from JS

import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import GroupLabel from '@/components/UI/GroupLabel';
import { _isNil } from '@/littledash';

export const createPayload = (request, requestTemplateId) => {
  const payload = {
    form_data: request.form_data,
  };
  if (requestTemplateId) {
    payload.template_id = requestTemplateId;
  }
  if (request.due_date) {
    payload.due_date = request.due_date;
  }
  return payload;
};

export const GroupContainer = ({ children }) => {
  return <div className="ui-card pa3 mb4">{children}</div>;
};

const cellWidth = 180;

export const sampleColumns = (dispatch) => [
  {
    id: 'sampleId',
    Header: 'Sample ID',
    accessor: 'sample_id',
    width: cellWidth,
    Cell: ({ row: { original } }) => (
      <a
        className="link blue lh-title db"
        onClick={() =>
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'VIEW_SAMPLE',
            props: {
              sample: original,
              closeModal: () => dispatch({ type: 'CLOSE_MODAL' }),
            },
          })
        }
      >
        {original.sample_id ?? '-'}
      </a>
    ),
    isVisible: true,
  },
  {
    id: 'subject',
    Header: 'Subject',
    accessor: 'subject',
    width: cellWidth,
    Cell: ({
      row: {
        original: { subject },
      },
    }) => {
      if (subject?.data?.name) {
        return subject.data.name;
      }
      if (subject?.name) {
        return subject.name;
      }
      return '-';
    },
    isVisible: true,
  },
  {
    id: 'dateTaken',
    Header: 'Date taken',
    accessor: 'date',
    width: cellWidth,
    Cell: ({
      row: {
        original: { date },
      },
    }) => <DateTimeRenderer value={date} defaultResponse="-" />,
    isVisible: true,
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'type',
    width: cellWidth,
    Cell: ({
      row: {
        original: { type, glossary },
      },
    }) => {
      if (type) {
        return type;
      }
      if (glossary?.title) {
        return glossary.title;
      }
      return '-';
    },
    isVisible: true,
  },
  {
    id: 'study_groups',
    Header: 'Groups',
    accessor: 'study_groups',
    sortDisabled: true,
    width: 275,
    style: { overflow: 'visible' },
    Cell: ({ row: { original } }) => {
      if (_isNil(original.study_group_id)) {
        return '-';
      }
      return <GroupLabel group={original.study_group} />;
    },
    isVisible: true,
  },
];
