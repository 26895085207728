// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import RemoveButton from '@/components/UI/RemoveButton';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import styles from './modal.module.scss';

export const useModalAction = () => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };
  const openModal = (modal: string, props: Record<string, any>) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props: { ...props, closeModal },
    });
  };
  return { openModal, closeModal };
};

export const modalAction = (dispatch) => {
  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };
  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props: { ...props, closeModal },
    });
  };
  return { openModal, closeModal };
};

interface ModalContainerProps {
  size?: 'large' | 'wide' | 'medium' | 'xnarrow' | 'narrow' | 'small' | 'xsmall' | 'smallest';
  className?: string;
  testKey?: string;
  children: ReactNode;
}

export const ModalContainer: FC<ModalContainerProps> = ({ children, size = 'wide', className = '', testKey }) => {
  const modalStyles = 'center br2 shadow-4';
  const styleReferences = {
    wide: 'w-90 ma3 bg-white mv4',
    medium: 'mw7 mv3 bg-white',
    narrow: 'mw6 mv3 bg-white',
    xnarrow: `${styles.xnarrow} mv3 bg-white`,
    small: 'mv4 mw6 bg-white',
    xsmall: 'mv4 mw5 bg-white',
    smallest: `${styles.smallest} bg-white`,
  };

  return (
    <div
      data-test-component="ModalContainer"
      data-test-element="container"
      data-test-key={testKey}
      data-testid="modal-container"
      className={`${modalStyles} ${styleReferences[size]} ${className}`}
    >
      {children}
    </div>
  );
};

interface ModalHeaderProps {
  title?: string;
  subText?: unknown;
  subTextClasses?: string;
  closeModal?: unknown;
  closeDisabled?: boolean;
  className?: string;
  fontClass?: string;
  readMoreLink?: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  title = '',
  subText,
  subTextClasses = 'f6 lh-copy mt2 measure-wide',
  closeModal,
  closeDisabled = false,
  className = 'pa3 bb b--moon-gray',
  fontClass = 'fw5 near-black lh-title f5',
  readMoreLink,
}) => {
  return (
    <div data-testid="modal-header" className={`flex justify-between items-center ${className}`}>
      <div>
        <h3 data-test-component="ModalHeader" data-test-element="header" className={fontClass}>
          {title}
        </h3>
        {subText && (
          <p data-test-component="ModalHeader" data-test-element="sub-header" className={subTextClasses}>
            {subText}
            {readMoreLink && (
              <a href={readMoreLink} target="_blank" rel="noopener noreferrer" className="link blue">
                {' '}
                Read more
              </a>
            )}
          </p>
        )}
      </div>
      {closeModal && <RemoveButton onClick={closeModal} isDisabled={closeDisabled} />}
    </div>
  );
};

interface ModalActionsProps {
  submitBtnText?: string;
  className?: string;
  onSubmit?: () => void;
  cancelBtnText?: string;
  onCancel?: () => void;
  submitButtonProps?: Record<string, any>;
  cancelButtonProps?: Record<string, any>;
  cancelButtonStyle?: string;
  asideComponent?: React.JSX.Element;
}

export const ModalActions: FC<ModalActionsProps> = ({
  submitBtnText = 'Save',
  className = '',
  onSubmit,
  cancelBtnText = 'Cancel',
  onCancel,
  submitButtonProps = {},
  cancelButtonProps = {},
  cancelButtonStyle = 'plain',
  asideComponent,
}) => (
  <div data-testid="modal-actions" className={`flex justify-between ${className}`}>
    <div>
      {onSubmit && (
        <Button onClick={onSubmit} {...submitButtonProps} testKey="ModalActions__submit-button" className="mr3">
          {submitBtnText}
        </Button>
      )}
      {onCancel && (
        <Button
          onClick={onCancel}
          {...{ [cancelButtonStyle]: true }}
          {...cancelButtonProps}
          testKey="ModalActions__cancel-button"
        >
          {cancelBtnText}
        </Button>
      )}
    </div>
    {asideComponent}
  </div>
);
