import type { components } from '@/generated/internal-api/openapi-types';
import type { ID } from './Common.model';

export type DeviceId = components['schemas']['DeviceApiId.schema'];
export type RegisteredDevice = components['schemas']['RegisteredDeviceV1.schema'];
export type ParityTypes = components['schemas']['ParityType.schema'];
export type FlowControlTypes = components['schemas']['FlowControlType.schema'];
export type ReadingTypes = components['schemas']['ReadingType.schema'];

export interface iPort {
  requestPort({ filters }: { filters: Array<SerialPortFilter> }): Promise<SerialPort>;
  getPorts(): Promise<Array<SerialPort>>;
  open(): Promise<void>;
  close(): Promise<void>;
  read(): Promise<string | undefined>;
}

export enum ParityTypeOptions {
  'None' = 'none',
  'Odd' = 'odd',
  'Even' = 'even',
}

export enum FlowControlTypeOptions {
  'None' = 'none',
  'Hardware' = 'hardware',
}

export enum ReadingTypeOptions {
  'Text' = 'text',
  'Number' = 'number',
  'Trim Channel' = 'trim_channel',
}

export const TerminationCharacters = {
  'Carriage return': 13,
  Comma: 44,
  'Horizontal Tab': 9,
  'Vertical Tab': 11,
  'Line Feed': 10,
  Space: 32,
};

export interface TargetField {
  label: string;
  value: string;
}

export interface MappedDevice {
  device_type_id?: DeviceId;
  name?: string;
  target: TargetField[];
  activeTarget?: TargetField;
  usb_vendor_id: number;
  usb_product_id: number;
}

export interface PresetDeviceMapping {
  presetId: ID;
  mappedDevices: MappedDevice[];
}

export interface TargetField {
  name: string;
  id: string;
}

export interface DeviceWorkerMessage {
  action: 'connect' | 'terminate' | 'new-reading' | 'reader-error' | 'reader-error-retry' | 'terminated-new-type';
  type: RegisteredDevice;
  origin: string;
  newReadingValue?: string;
  newType?: RegisteredDevice;
}
