import Button from '@/components/UI/Button';
import { ErrorMessage } from '@hookform/error-message';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';

interface AddCommentFormProps {
  onAddComment: (content: string) => void;
}

const AddCommentForm = ({ onAddComment }: AddCommentFormProps) => {
  const MAX_CHARACTERS = 5000;
  const { handleSubmit, register, errors, watch, reset } = useForm();
  const onSubmit = ({ content }: { content: string }) => {
    onAddComment(content);
    reset();
  };
  const content = watch('content') || '';
  const count = MAX_CHARACTERS - content.length;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb4">
      <div className="mb2">
        <textarea
          style={{ marginBottom: 0, resize: 'vertical' }}
          name="content"
          ref={register({
            required: "Comment can't be blank",
            maxLength: {
              value: MAX_CHARACTERS,
              message: `Character limit exceeded +${Math.abs(count)}`,
            },
          })}
          placeholder="Add a comment..."
          className={errors?.content ? 'input__error' : ''}
        />
        <span className="f7 lh-title fr pt2">
          {count < 0 ? (
            <>
              Character limit exceeded <strong>+{Math.abs(count)}</strong>
            </>
          ) : (
            <>Characters remaining {count}</>
          )}
        </span>
        <ErrorMessage
          errors={errors}
          name="content"
          render={({ message }) => <p className="f6 red db pv1">{message}</p>}
        />
      </div>
      <Button submit>Add comment</Button>
    </form>
  );
};

export default AddCommentForm;
