// @ts-nocheck: converted from JS

import MenuContainer from './MenuContainer';
import SearchSelect from '@/components/UI/SearchSelect';
import Button from '@/components/UI/Button';

const CagesShowMenu = ({ items, handleChange, closeMenu }) => {
  const selected = items.reduce((acc, item, i) => {
    if (item.isVisible) {
      acc[i] = item.id;
    }
    return acc;
  }, []);

  const setSelected = (selected) => {
    items = items.map((item) => {
      item.isVisible = selected.includes(item.id);
      return item;
    });
    handleChange(items);
  };

  return (
    <MenuContainer width={300}>
      <SearchSelect
        sections={[
          {
            items: items.map((item, i) => ({
              id: item.id,
              title: item.name,
            })),
          },
        ]}
        selected={selected}
        setSelected={setSelected}
      />
      <div className="bt b--moon-gray pa3">
        <Button onClick={closeMenu} plain className="f6 w-100">
          Close
        </Button>
      </div>
    </MenuContainer>
  );
};

export default CagesShowMenu;
