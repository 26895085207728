// @ts-nocheck: converted from JS

import Link from '@/components/UI/Link';
import { typeToTextMap } from '@/referenceData/study/type';
import { web as webRoutes } from '@/support/route';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';

export const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    sortBy: 'name',
    Cell: ({ row: { original } }) => (
      <Link className="blue" to={webRoutes('team.studies.forms.edit', { id: original.id })}>
        {original.name}
      </Link>
    ),
  },
  {
    Header: 'Type',
    accessor: 'type',
    sortBy: 'type',
    Cell: ({ row: { original } }) => {
      return <>{typeToTextMap[original?.type]}</>;
    },
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    sortBy: 'created_at',
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
  },
];

export const getParams = (
  query = '',
  sort = ['id'],
  order = 'desc',
  paging = {
    total_pages: 1,
    current_page: 1,
    per_page: 10,
  }
) => {
  let url = '';
  if (query) {
    url = `${url}&search=${query}`;
  }
  url = `${url}&order=${order}&sort=${sort}&page=${paging.current_page}&perPage=${paging.per_page}`;

  return url;
};
