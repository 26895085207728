import { samplesConfigObj } from '@/components/Glossary/Sections/SampleTypes/SamplesConfig';
import { preventNumberScroll } from '@/helpers';
import { _notNil } from '@/littledash';
import { ErrorMessage } from '@hookform/error-message';
import type React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';

interface DetailFieldProps {
  detailKey: string;
  value?: string;
  unit?: string;
  fieldIndex: number;
}

export const DetailField: React.FC<DetailFieldProps> = ({ detailKey, value, unit, fieldIndex }) => {
  const { register, errors } = useFormContext();
  return (
    <div className="w-50 pa2" data-test-component="DetailField" data-test-element="container" data-test-key={detailKey}>
      <label>{samplesConfigObj?.[detailKey]?.title}</label>
      <div className="flex">
        <input type="hidden" value={detailKey} name={`details[${fieldIndex}].key`} ref={register()} />
        <input
          type="number"
          onWheel={preventNumberScroll}
          className="w-100 mr2"
          data-test-element="number-input"
          step="any"
          style={{ marginBottom: 0 }}
          name={`details[${fieldIndex}].value`}
          ref={register({
            valueAsNumber: true,
            pattern: {
              value: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
              message: 'Must be a valid number',
            },
          })}
          defaultValue={value}
        />
        {_notNil(samplesConfigObj?.[detailKey]?.units) && (
          <select
            className="w3"
            name={`details[${fieldIndex}].unit`}
            data-test-element="unit-select"
            ref={register()}
            style={{ marginBottom: 0 }}
            defaultValue={unit}
          >
            {samplesConfigObj[detailKey].units.map((configUnit: string) => (
              <option key={`${detailKey}_${configUnit}`} value={configUnit}>
                {configUnit}
              </option>
            ))}
          </select>
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name={`details[${fieldIndex}].value`}
        render={({ message }) => <p className="f6 red db pt2">{message}</p>}
      />
    </div>
  );
};
