import type { TaskType } from '@/model/Task.model.ts';
import { FC } from 'react';
import { RiBriefcaseLine, RiRulerLine, RiStethoscopeLine, RiSyringeLine, RiTestTubeLine } from 'react-icons/ri';

export const TaskTypeIcon: FC<{ type: TaskType; size?: number; className?: string }> = ({ type, size, className }) => {
  switch (type) {
    case 'measurement':
      return <RiRulerLine size={size ?? 24} className={className ?? 'blue'} />;
    case 'sample':
      return <RiTestTubeLine size={size ?? 24} className={className ?? 'blue'} />;
    case 'observation':
      return <RiStethoscopeLine size={size ?? 24} className={className ?? 'blue'} />;
    case 'dosing':
      return <RiSyringeLine size={size ?? 24} className={className ?? 'blue'} />;
    case 'other':
      return <RiBriefcaseLine size={size ?? 24} className={className ?? 'blue'} />;
    default:
      return null;
  }
};
