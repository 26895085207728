import { GroupCell } from '@/components/Studies/Groups/Analysis/Cells/GroupCell';
import { NumberCell } from '@/components/Studies/Groups/Analysis/Cells/NumberCell';
import {
  AnalysisAnimalCountV1,
  AnalysisRow,
  AnalysisStudyGroupV1,
  AnalysisType,
  StatColumnV1,
} from '@/model/Analysis.model.ts';
import type { StudyApiId } from '@/model/Study.model';
import type { FC } from 'react';
import { FractionCell } from './Cells/FractionCell';

interface CellProps {
  tableType: AnalysisType;
  studyApiId: StudyApiId;
  columnType: StatColumnV1['type'];
  accessor: keyof AnalysisRow;
  data: AnalysisRow;
}

export const Cell: FC<CellProps> = ({ tableType, studyApiId, columnType, accessor, data }) => {
  switch (columnType) {
    case 'text':
      return <>{data?.[accessor] ?? '-'}</>;
    case 'treatment_group':
      return <GroupCell studyApiId={studyApiId} group={data?.[accessor] as AnalysisStudyGroupV1} />;
    case 'fraction': {
      const currentValue = data?.[accessor] as AnalysisAnimalCountV1;
      return <FractionCell value={currentValue.value} total={currentValue.total} />;
    }
    case 'number':
      return <NumberCell value={data?.[accessor] as unknown as number} />;
    case 'percent':
      return <NumberCell value={data?.[accessor] as unknown as number} percentage />;
  }
  return <>{data?.[accessor] ?? '-'}</>;
};
