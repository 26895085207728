import type { SelectOption } from '@/components/UI/Select/Select';
import { _isNotEmpty } from '@/littledash';
import type { Treatment, TreatmentMethod } from '@/model/Treatment.model';
import type { TreatmentFormField } from './TreatmentForm.model';

export const dosingMethods: Record<TreatmentMethod, SelectOption<TreatmentMethod>> = {
  volume: {
    value: 'volume',
    label: 'Standard volume',
  },
  dose_volume: {
    value: 'dose_volume',
    label: 'Dose volume (ml/kg)',
  },
  concentration: {
    value: 'concentration',
    label: 'Standard concentration',
  },
  concentration_mlkg: {
    value: 'concentration_mlkg',
    label: 'Standard concentration (ml/kg)',
  },
  concentration_vgkg: {
    value: 'concentration_vgkg',
    label: 'Standard concentration (vg/kg)',
  },
  custom: {
    value: 'custom',
    label: 'Custom',
  },
};

export const dosingMethodOptions = Object.values(dosingMethods);

export const getDefaults = (
  formName: string,
  formFields: Array<TreatmentFormField>,
  currentTreatmentMethod: TreatmentMethod,
  currentTreatment?: Treatment
): Record<string, Record<string, unknown>> => ({
  [formName]:
    _isNotEmpty(currentTreatment) && currentTreatmentMethod == currentTreatment.type
      ? buildDefaultsFromTreatment(currentTreatment)
      : buildEmptyFormDefaults(formFields),
});

const buildDefaultsFromTreatment = (treatment: Treatment): Record<string, unknown> =>
  treatment.fields.reduce<Record<string, unknown>>((acc, field) => {
    acc[field.name] = {
      ...field,
      default_value: field.default_display_value ?? field.default_value,
      unit: {
        name: field.unit.display_unit ?? field.unit.name,
        id: field.unit.display_unit_id ?? field.unit.id,
        unit_type: field.name,
      },
    };
    return acc;
  }, {});

const buildEmptyFormDefaults = (formFields: Array<TreatmentFormField>): Record<string, unknown> =>
  formFields.reduce<Record<string, unknown>>((acc, field) => {
    acc[field.name] = {
      default_value: field.default_value,
      unit: field.units[0],
      required: field.required,
    };
    return acc;
  }, {});
