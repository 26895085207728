import Spinner from '@/components/UI/Spinner';
import type React from 'react';

interface LoadingProps {
  txt?: string;
  spinnerColor?: string;
  spinnerSize?: string;
  textClassName?: string;
  loader?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({
  txt = 'Loading...',
  spinnerColor = 'blue',
  spinnerSize = 'large',
  textClassName = 'f4 black',
  loader = false,
}) => {
  return (
    <div className="flex justify-center items-center w-100 h-100" data-testid="loading-spinner-component">
      <div className="tc ph3 center">
        <div className="tc">
          <Spinner size={spinnerSize} color={spinnerColor} className="dib" loader={loader} />
          <h3 className={`${textClassName} lh-title f4 basier-reg normal pv3`}>{txt}</h3>
        </div>
      </div>
    </div>
  );
};

export default Loading;
