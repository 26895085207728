import DatePickerNative from '@/components/UI/DatePickerNative';
import Icon from '@/components/UI/Icon';
import { _isEmptyString, _isNil } from '@/littledash.ts';
import { ISODateTime } from '@/model/Common.model.ts';
import { TaskScheduleDataV1 } from '@/model/Task.model.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { startOfDay } from 'date-fns';
import { FC, useEffect, useMemo, useState } from 'react';

interface ScheduleSearchBarProps {
  onChange: (data: { text: string | undefined; start: ISODateTime | undefined }) => void;
  scheduleData?: TaskScheduleDataV1;
}

export const ScheduleSearchBar: FC<ScheduleSearchBarProps> = ({ onChange, scheduleData }) => {
  const [searchText, updateSearchText] = useState('');
  const [fromDate, updateFromDate] = useState<string>('');
  const { minDate, maxDate } = useMemo(
    () =>
      _isNil(scheduleData?.duration?.start) || _isNil(scheduleData?.duration?.end)
        ? { minDate: undefined, maxDate: undefined }
        : {
            minDate: DateUtils.renderDateTime(scheduleData?.duration?.start, {
              defaultResponse: '',
              format: DateRenderFormat.ISODate,
            }),
            maxDate: DateUtils.renderDateTime(scheduleData?.duration?.end, {
              defaultResponse: '',
              format: DateRenderFormat.ISODate,
            }),
          },
    [scheduleData]
  );
  useEffect(() => {
    if (!_isEmptyString(minDate ?? '')) {
      updateFromDate((prev) => {
        if (_isEmptyString(prev)) {
          return minDate as string;
        }
        return prev;
      });
    }
  }, [minDate, updateFromDate]);

  return (
    <div className="flex flex-row justify-start" data-test-component="ScheduleSearchBar" data-test-element="container">
      <div className="relative w-50 pr2">
        <span className="absolute z-5" style={{ top: '13px', left: '10px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          type="text"
          data-test-element="search-text-input"
          className="ui__keyword-search-bar"
          style={{ marginBottom: 0, maxWidth: 'none' }}
          placeholder="Search by task title"
          autoComplete="off"
          value={searchText}
          onChange={(event) => updateSearchText(event?.target?.value ?? '')}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onChange({
                text: _isEmptyString(searchText) ? undefined : searchText.trim(),
                start: _isEmptyString(fromDate ?? '') ? undefined : startOfDay(fromDate).toISOString(),
              });
            }
          }}
        />
        {(searchText ?? '').trim().length > 0 ? (
          <span
            className="absolute z-5 pointer"
            data-test-element="clear-search-text"
            style={{ top: '10px', right: '18px' }}
            onClick={() => {
              updateSearchText('');
              onChange({
                text: undefined,
                start: _isEmptyString(fromDate ?? '') ? undefined : startOfDay(fromDate).toISOString(),
              });
            }}
          >
            <Icon icon="close" width="12" height="12" />
          </span>
        ) : null}
      </div>
      <div className="w-25">
        <DatePickerNative
          style={{ marginBottom: 0 }}
          value={fromDate}
          min={minDate}
          testId="seatch-date"
          max={maxDate}
          onChange={(value) => {
            const updatedValue = _isEmptyString(value ?? '') ? (minDate ?? '') : value;
            if (updatedValue !== fromDate) {
              updateFromDate(updatedValue);
              onChange({
                text: _isEmptyString(searchText) ? undefined : searchText.trim(),
                start: updatedValue === minDate ? undefined : startOfDay(updatedValue).toISOString(),
              });
            }
          }}
        />
      </div>
    </div>
  );
};
