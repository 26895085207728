// @ts-nocheck: converted from JS

import Animals from '@/components/Modals/PrintCageCards/4x3/Animals';
import Metadata from '@/components/Modals/PrintCageCards/4x3/Metadata';
import Header from '@/components/Modals/PrintCageCards/Header';
import { _notNil } from '@/littledash';

const CageCardTemplate = ({ study, cage, selectedMetadata, species }) => {
  const bleed = '3';
  const width = '388';
  const height = '275';
  const fullWidth = `${width}px`;
  const fullHeight = `${height}px`;
  const bleedPx = `${bleed}px`;

  const { subjects } = cage;

  const hasMetadata = Boolean(selectedMetadata?.length);

  const animals = subjects?.data?.filter((animal) => _notNil(animal));

  return (
    <div
      className="flex flex-column overflow-hidden"
      style={{
        width: fullWidth,
        height: fullHeight,
        padding: bleedPx,
      }}
    >
      <Header cageName={cage.name} studyName={study?.name ?? ''} barcodeValue={cage.catalog} />

      {hasMetadata && (
        <div className="w-100 ba bw1 b--black mb1" style={{ marginTop: -5 }}>
          <Metadata metadata={selectedMetadata} />
        </div>
      )}

      <div className="flex flex-row h-100 w-100 ba bw1 b--black" style={{ marginTop: !hasMetadata ? -5 : 0 }}>
        <div className="br bw1 b--black">
          <Animals animals={animals} species={species} />
        </div>
        <div className="w-100 h-100"></div>
      </div>
    </div>
  );
};

export default CageCardTemplate;
