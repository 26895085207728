// @ts-nocheck: converted from JS

import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import { web as webRoute } from '@/support/route';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cages from '../Cages';

const ColonyAddtoStudy = () => {
  const settings = useSelector((state) => state.ui.settings);
  const { studyId } = useParams();
  return (
    <div className="h-100">
      <SubHeader linkToText="Cages" link={webRoute('studies.cages', { id: studyId })} />
      <div className="pl4 pt2">
        <Header mainHeaderText="Add existing cages from Colony" />
      </div>
      <Cages addToStudy settings={settings} />
    </div>
  );
};

export default ColonyAddtoStudy;
