import ApiErrorBanner from '@/components/ApiErrorBanner';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { successToast } from '@/helpers';
import type { AnimalApiId, DiseaseInductedAtUpdateRequestV1 } from '@/model/Animal.model';
import type { StudyApiId } from '@/model/Study.model';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, ModalHeader, useModalAction } from '@/utils/modal';

interface UpdateDiseaseInductionDateProps {
  studyApiId: StudyApiId;
  animalApiIds: Array<AnimalApiId>;
  handleCallback: () => void;
}

type FormData = Pick<DiseaseInductedAtUpdateRequestV1, 'disease_inducted_at'>;

export const UpdateDiseaseInductionDate: FC<UpdateDiseaseInductionDateProps> = ({
  studyApiId,
  animalApiIds,
  handleCallback,
}) => {
  const { closeModal } = useModalAction();
  const {
    response: updateResponse,
    invoke: updateDiseaseInductionDate,
    loading: updating,
  } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyApiId}/animals/disease-inducted-at',
    path: { studyApiId },
    body: { disease_inducted_at: null, animal_api_ids: animalApiIds },
    invokeOnInit: false,
  });

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      disease_inducted_at: DateUtils.dateNow(),
    },
  });

  const onSubmit = async ({ disease_inducted_at }: FormData) => {
    await updateDiseaseInductionDate({
      path: { studyApiId },
      body: { disease_inducted_at, animal_api_ids: animalApiIds },
    });
    successToast(`Updated ${animalApiIds.length} disease induction date${animalApiIds.length !== 1 ? 's' : ''} `);
    handleCallback();
  };

  return (
    <ModalContainer size="narrow" className="bg-white">
      <div className="flex flex-column bb b--moon-gray">
        <ModalHeader title="Set disease induction date" className="pl3 pt3 pb2 w-100" />
        <div className="f6 lh-copy pl3 pb2" />
      </div>
      {updateResponse?.type === 'error' && <ApiErrorBanner error={updateResponse.error} />}
      <div className="pl3" style={{ width: 290, height: 100 }}>
        <Controller
          name="disease_inducted_at"
          control={control}
          render={({ onChange, onBlur, value }: ControllerRenderProps) => (
            <div className="pt3">
              <label>Disease induction date</label>
              <DatePickerNative value={value} onChange={onChange} onBlur={onBlur} />
            </div>
          )}
        />
      </div>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Save"
        cancelBtnText="Cancel"
        submitButtonProps={{ disabled: updating, loading: updating }}
        cancelButtonProps={{ disabled: updating }}
      />
    </ModalContainer>
  );
};
