// @ts-nocheck: converted from JS
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React, { useEffect } from 'react';

export enum ApiErrorType {
  FETCH = 'fetch',
  SUBMIT = 'submit',
}

interface ApiErrorBannerProps {
  errorType?: ApiErrorType;
  error?: unknown;
  title?: string;
  text?: string;
  className?: string;
  altButtonText?: string;
  altButtonFunc?: unknown;
  onDismiss?: unknown;
}

const ApiErrorBanner: React.FC<ApiErrorBannerProps> = ({
  errorType,
  title,
  text,
  className,
  error,
  altButtonText,
  altButtonFunc,
  onDismiss,
}) => {
  const textByErrorType = {
    [ApiErrorType.FETCH]: {
      title: 'There was a problem fetching data',
      text: 'An error has occurred when fetching data from the server, please try again later. If this keeps occurring please contact support.',
    },
    [ApiErrorType.SUBMIT]: {
      title: 'There was an error with your submission',
      text: 'An error has occurred when submitting the form, please try again later. If this keeps occurring please contact support.',
    },
    default: {
      title: 'There was an error with your submission',
      text: 'An error has occurred when submitting the form, please try again later. If this keeps occurring please contact support.',
    },
  };

  useEffect(() => {
    if (error) {
      ExceptionHandler.captureException(
        new InVivoError('Api error banner', {
          cause: error,
          slug: 'api-error-banner',
        })
      );
    }
  }, [error]);

  const getTitle = () => {
    if (title) {
      return title;
    } else if (errorType) {
      return textByErrorType[errorType]?.title || textByErrorType.default.title;
    } else {
      return textByErrorType.default.title;
    }
  };

  const getText = () => {
    if (text) {
      return text;
    } else if (errorType) {
      return textByErrorType[errorType]?.text || textByErrorType.default.text;
    } else {
      return textByErrorType.default.text;
    }
  };

  return (
    <Banner
      critical
      dismiss={onDismiss != null}
      onDismiss={onDismiss}
      className={`mw6 mt3 ${className}`}
      testId="ApiErrorBanner"
    >
      <h3 className="f5 normal lh-title pb2" data-test-element="error-title">
        {getTitle()}
      </h3>
      <p className="f6 pb3 lh-copy" data-test-element="error-text">
        {getText()}
      </p>
      {!altButtonFunc && (
        <Button outline critical url={'mailto:support@benchling.com'}>
          Contact support
        </Button>
      )}
      {altButtonFunc && (
        <Button outline critical onClick={altButtonFunc}>
          {altButtonText}
        </Button>
      )}
    </Banner>
  );
};

export default ApiErrorBanner;
