import type { Endpoints } from '@/generated/internal-api/Endpoints';
import { errorToast } from '@/helpers';
import { _isEmpty, _notNil } from '@/littledash';
import type { PickRequired } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import http from '@/support/http';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import type { AxiosHeaderValue, AxiosResponse } from 'axios';
import { notAborted } from './Hooks/fetch/useAbortController';
import { RouteService } from './RouteService';

export enum ResponseType {
  Success = 'success',
  Error = 'error',
  NotFound = 'not_found',
}

export interface ApiServiceProps<Endpoint extends keyof Endpoints> {
  endpoint: Endpoint;
  path?: Endpoints[Endpoint]['path'];
  query?: Endpoints[Endpoint]['query'] extends undefined
    ? Record<string, string | number | boolean | Array<string | number | boolean>>
    : Endpoints[Endpoint]['query'];
  filters?: string;
  headers?: Record<string, AxiosHeaderValue>;
  body?: Endpoints[Endpoint]['request'];
  options?: { onError?: { toast?: boolean; throw?: boolean; capture?: boolean; slug?: string } };
}

interface CommonApiResponse<Endpoint extends keyof Endpoints> {
  body?: Endpoints[Endpoint]['response'];
  error?: Error & { response?: { data: { errors: Record<string, Array<string>> }; status: number } };
}

export interface ApiSuccessResponse<Endpoint extends keyof Endpoints>
  extends PickRequired<CommonApiResponse<Endpoint>, 'body'>,
    Pick<AxiosResponse, 'headers' | 'status' | 'statusText'> {
  type: ResponseType.Success;
}

export interface ApiErrorResponse<Endpoint extends keyof Endpoints>
  extends PickRequired<CommonApiResponse<Endpoint>, 'error'> {
  type: ResponseType.Error;
}

export type ApiResponse<Endpoint extends keyof Endpoints> = ApiSuccessResponse<Endpoint> | ApiErrorResponse<Endpoint>;

export class ApiService {
  static async call<Endpoint extends keyof Endpoints>({
    endpoint,
    path,
    query,
    filters,
    headers: requestHeaders,
    body,
    signal,
    options,
  }: ApiServiceProps<Endpoint> & {
    signal?: AbortSignal;
  }): Promise<ApiResponse<Endpoint>> {
    try {
      const { method, url } = RouteService.api({ endpoint, path, query });
      let currentUrl = url.href;
      // Work around for filters being introduced through Java
      // TODO: Add filters to api spec to properly type
      if (_notNil(filters)) {
        currentUrl = `${currentUrl}${_isEmpty(query) ? '?' : '&'}${filters}`;
      }
      const { data, headers, status, statusText } = await http.request<ApiSuccessResponse<Endpoint>['body']>({
        baseURL: currentUrl,
        method,
        headers: { ...(requestHeaders ?? {}) },
        data: body,
        signal,
      });
      return {
        type: ResponseType.Success,
        body: data,
        headers,
        status,
        statusText,
      };
    } catch (error) {
      const errorResponse: ApiErrorResponse<Endpoint> = {
        type: ResponseType.Error,
        error: error as ApiErrorResponse<Endpoint>['error'],
      };
      if (options?.onError?.capture ?? true) {
        ExceptionHandler.captureException(
          new InVivoError(`Api Error: ${endpoint}`, {
            cause: error,
            slug: options?.onError?.slug ?? 'api-service',
          })
        );
      }

      if ((options?.onError?.toast ?? true) && notAborted(error)) {
        errorToast('There was a problem communicating with the Benchling In Vivo server');
      }
      if (options?.onError?.throw ?? true) {
        throw new InVivoError(errorResponse.type, { cause: error, slug: options?.onError?.slug ?? 'api-service' });
      } else {
        return errorResponse;
      }
    }
  }
}
