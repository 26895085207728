// @ts-nocheck: converted from JS

import { MetadataCondition } from '@/components/Glossary/Sections/Approvals/TemplateForm/MetadataCondition';
import { studyTypeOptions } from '@/components/Glossary/Sections/Approvals/TemplateForm/TemplateForm.utils';
import { Radio } from '@/components/UI/FormFields';
import List, { asListItem } from '@/components/UI/List';
import { _isEmpty } from '@/littledash';
import { matchType, Type } from '@/referenceData/study/type';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

const General = ({
  ActionButtons,
  onSubmit,
  approval,
  studyMetadataOptions,
  projectMetadataOptions,
  reviewTemplates,
}) => {
  const formMethods = useForm({
    defaultValues: {
      name: approval.name || '',
      study_type: approval.study_type || Type.all,
      match_type: approval.match_type || matchType.any,
      conditions: approval.conditions || [],
      dependencies: approval.dependencies || '',
    },
  });

  const { handleSubmit, register, errors, control } = formMethods;

  const {
    fields: conditions,
    append: appendToConditions,
    remove: removeFromConditions,
  } = useFieldArray({
    control,
    name: 'conditions',
  });

  const handleAddConditionClick = () => {
    appendToConditions({});
  };

  const handleRemoveConditionClick = (index) => {
    removeFromConditions(index);
  };

  const removeExistingTemplate = () =>
    reviewTemplates.filter((template) => Number(approval.id) !== Number(template.id));

  const onSubmitClick = (data) => {
    // / manually falling back to empty conditions array until react-hook-form v7 (https://github.com/react-hook-form/react-hook-form/issues/3231)
    const approvalData = {
      ...data,
      conditions: data.conditions || [],
    };
    onSubmit(approvalData);
  };
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="ui-card pa3"
        data-test-component="General"
        data-test-element="form"
      >
        <div className="mb3">
          <label htmlFor="name">Review name</label>
          <input
            type="text"
            data-test-element="name-input"
            style={{ marginBottom: 0 }}
            className={`${errors.name ? 'input__error' : ''}`}
            name="name"
            ref={register({
              required: 'Review name is required',
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>

        <div className="mb3">
          <label htmlFor="appliesTo" className="dib mb3">
            Review applies to:
          </label>
          {studyTypeOptions.map((t, i) => (
            <div className="flex items-center pb2" key={t.value}>
              <input
                id={`study_type.${t.value}`}
                type="radio"
                data-test-element="type-radio"
                data-test-key={t.value}
                name="study_type"
                ref={register()}
                value={t.value}
                className="pointer ui__form__radio"
                style={{ marginBottom: 0 }}
              />
              <label className="pointer pl2" style={{ marginBottom: 0 }} htmlFor={`study_type.${t.value}`}>
                {t.name}
              </label>
            </div>
          ))}
        </div>
        {!_isEmpty(reviewTemplates) && (
          <div className="mb3">
            <label htmlFor="dependencies">Review dependency</label>
            <p className="lt-copy f6 pb2">
              This review can&apos;t be submitted until the selected review template has been completed
            </p>
            <select name="dependencies" ref={register({ valueAsNumber: true })} style={{ width: 300 }}>
              <option value="">No dependency</option>
              {removeExistingTemplate().map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="pb3">
          <h3 className="lh-title normal f6">Metadata conditions</h3>
          <p className="lh-copy f6 ">
            Trigger reviews based on text and select metadata values entered during study creation.
          </p>
        </div>
        {conditions.length > 1 && (
          <div className="pb3">
            <div className="flex flex-wrap">
              <Radio
                options={[
                  {
                    value: matchType.all,
                    name: 'all',
                    label: 'All conditions',
                  },
                  {
                    value: matchType.any,
                    name: 'any',
                    label: 'Any condition',
                  },
                ]}
                label="Studies must match"
                name="match_type"
              />
            </div>
          </div>
        )}
        <List
          canAmend
          canAdd
          canRemove
          data={conditions}
          renderItem={asListItem(MetadataCondition, { studyMetadataOptions, projectMetadataOptions })}
          onAddClick={handleAddConditionClick}
          onRemoveClick={handleRemoveConditionClick}
          addButtonText={'Add condition'}
          isRemoveButtonFluid={false}
        />

        {React.cloneElement(ActionButtons, { onOk: handleSubmit(onSubmitClick) })}
      </form>
    </FormProvider>
  );
};

export default General;
