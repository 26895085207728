// @ts-nocheck: converted from JS

import Avatar from '@/components/UI/Avatar';
import { Status, statusToTextMap } from '@/referenceData/study/status';

const UserStatusAvatar = ({ user, className = '' }) => {
  let initials = user?.name?.match?.(/\b\w/g) ?? [];
  initials = `${initials.shift() ?? ''}${initials.pop() ?? ''}`.toUpperCase();

  const getColorClassByStatus = (status) => {
    if (status === Status.success) {
      return 'bg-green';
    } else if (status === Status.fail) {
      return 'bg-red';
    } else {
      return 'bg-dark-gray';
    }
  };

  const buildTooltip = (user) => {
    const name = user.name || user.email;
    return `${name} (${statusToTextMap[user.status]})`;
  };

  return (
    <Avatar tooltip={buildTooltip(user)} className={className} bgColorClass={getColorClassByStatus(user.status)}>
      {initials}
    </Avatar>
  );
};

export default UserStatusAvatar;
