import { _isNil, _notNil, uuid } from '@/littledash';
import { datatableFormulaParser } from '@/utils/FormulaDsl';
import { StringUtils } from '@/utils/String.utils';
import { parse as parseDuration, serialize as serializeDuration } from 'tinyduration';
import type {
  DataTableCellIdCoordinate,
  DataTableCellReference,
  DataTableColumn,
  DataTableColumnId,
  DataTableColumnType,
  DataTableFormula,
  DataTableRow,
  DataTableRowId,
  DataTableService,
} from './DataTable.model';

export const toCellRef = (coordinate?: DataTableCellIdCoordinate): DataTableCellReference =>
  _notNil(coordinate) ? `[${coordinate.column},${coordinate.row}]` : ('[,]' as unknown as DataTableCellReference);

export const cellReferencePattern = /^\[(?<column>dtc_[a-zA-Z0-9]+),(?<row>dtr_[a-zA-Z0-9]+)]$/;
export const fromCellRef = (ref?: DataTableCellReference): DataTableCellIdCoordinate | undefined => {
  const { column, row } = cellReferencePattern.exec(ref ?? '')?.groups ?? { column: undefined, row: undefined };
  if (_notNil(column) && _notNil(row)) {
    return { column: column as DataTableColumnId, row: row as DataTableRowId };
  }
};

export const randomDataTableColumnId = (): DataTableColumnId => `dtc_${uuid().replaceAll('-', '')}`;
export const randomDataTableRowId = (): DataTableRowId => `dtr_${uuid().replaceAll('-', '')}`;
export const randomDataTableCellIdCoordinate = (): DataTableCellIdCoordinate => ({
  column: randomDataTableColumnId(),
  row: randomDataTableRowId(),
});
export const dataTableColumnGenerator = (count: number, type: DataTableColumnType = 'number'): Array<DataTableColumn> =>
  Array.from({ length: count }).map((_, index) => ({
    id: randomDataTableColumnId(),
    type,
    name: `column ${index + 1}`,
  })) as Array<DataTableColumn>;
export const dataTableRowGenerator = (count: number, withGroup = false): Array<DataTableRow> =>
  Array.from({ length: count }).map((_, index) => ({
    id: randomDataTableRowId(),
    type: 'animal',
    animal: {
      id: index + 1,
      name: `Animal ${index + 1}`,
      dob: '',
      study_group: withGroup
        ? {
            id: `grp_${uuid().replaceAll('-', '')}`,
            name: `Group ${index + 1}`,
            no: index + 1,
            color: StringUtils.randomHexColor(),
            max_subjects: 10,
            created_at: '2022-01-01T00:00:00.000000Z',
            updated_at: '2022-01-01T00:00:00.000000Z',
          }
        : undefined,
      created_at: '',
      sex: 'm',
      metadata: [],
    },
  })) as unknown as Array<DataTableRow>;

const greedyVariableReplacePattern = /[^a-zA-Z0-9_]+/g;
export const columnNameToFormulaVariable = (name: string, prefix = ''): string => {
  const variable = name
    .toLowerCase()
    .replaceAll(greedyVariableReplacePattern, ' ')
    .trim()
    .replaceAll(greedyVariableReplacePattern, '_');
  return `${prefix}${variable}`;
};

export const generateColumnFormulaName = (column: DataTableColumn) =>
  `${column.name}${_notNil(column.reference_date) ? '_' + column.reference_date : ''}`;

export const substituteFormulaVariables = async <From extends string, To extends string>(
  formula: string,
  variables: Record<From, To>,
  options: { ignoreInvalidVariables?: boolean } = {}
) =>
  new Promise<{ formula: string; placeholders: Record<To, From> }>((resolve, reject) => {
    const cursor = datatableFormulaParser.parse(formula).cursor();
    const formulaComponents: Array<string> = [];
    const formulaPlaceholders = {} as Record<To, From>;
    do {
      if (cursor.node.type.isError) {
        reject(new Error(`invalid syntax @ [${formula.slice(cursor.from, cursor.to)}]`));
        break;
      }
      if (_isNil(cursor.node.firstChild)) {
        const component = formula.slice(cursor.from, cursor.to);
        if (cursor.node.name === 'Variable') {
          const variable = component.substring(1) as From;
          const placeholder = variables[variable];
          if (_notNil(placeholder)) {
            formulaComponents.push(`$${placeholder}`);
            formulaPlaceholders[placeholder] = component as From;
          } else if (options?.ignoreInvalidVariables ?? false) {
            formulaComponents.push('#REF!');
          } else {
            reject(new Error('invalid variable ' + component));
            break;
          }
        } else {
          formulaComponents.push(component);
        }
      }
    } while (cursor.next());
    resolve({
      formula: formulaComponents.join(' '),
      placeholders: formulaPlaceholders,
    });
  });
export const formulaVariablesToColumnIds = (
  formula: string,
  columns: Array<DataTableColumn>
): Promise<DataTableFormula> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<DataTableFormula>(async (resolve, reject) => {
    const variables = columns.reduce<Record<string, DataTableColumnId>>((acc, column) => {
      acc[columnNameToFormulaVariable(generateColumnFormulaName(column))] = column.id;
      return acc;
    }, {});
    const { formula: value, placeholders } = await substituteFormulaVariables(formula, variables);
    resolve({
      value,
      placeholders: (Object.keys(placeholders) as Array<DataTableColumnId>).reduce<DataTableFormula['placeholders']>(
        (acc, columnId) => {
          acc[columnId] = { type: 'column', value: columnId };
          return acc;
        },
        {}
      ),
    });
  });
export const formulaVariablesFromColumnIds = (
  formula: string,
  columns: Readonly<Array<DataTableColumn>>,
  options: { ignoreInvalidVariables?: boolean } = {}
): Promise<string> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<string>(async (resolve, reject) => {
    const variables = columns.reduce<Record<DataTableColumnId, string>>((acc, column) => {
      acc[column.id] = columnNameToFormulaVariable(generateColumnFormulaName(column));
      return acc;
    }, {});
    try {
      const { formula: value } = await substituteFormulaVariables(formula, variables, {
        ignoreInvalidVariables: options?.ignoreInvalidVariables,
      });
      resolve(value);
    } catch (err) {
      reject(new Error('Could note get variables from column ids', { cause: err }));
    }
  });

interface ChunkOptions {
  chunkSize?: number;
  maxChunks?: number;
}

export const chunk = <T>(array: Array<T>, options?: ChunkOptions): Array<Array<T>> => {
  const actualChunkSize = Math.max(
    Math.min(options?.chunkSize ?? 1000, array.length),
    Math.ceil(array.length / (options?.maxChunks ?? array.length))
  );
  return Array.from({ length: Math.ceil(array.length / actualChunkSize) }, (v, idx) =>
    array.slice(idx * actualChunkSize, idx * actualChunkSize + actualChunkSize)
  );
};

export const makeCellData = (dataTableService: DataTableService) => {
  const result: Array<{ row_id: DataTableRowId; column_id: DataTableColumnId; value: string }> = [];
  dataTableService.rows.forEach((row) => {
    dataTableService.columns.forEach((column) => {
      result.push({
        column_id: column.id,
        row_id: row.id,
        value: (Math.random() * 100).toPrecision(3),
      });
    });
  });
  return result;
};

export const getValueAndUnitFromColumn = (column: DataTableColumn): Record<string, string> => {
  try {
    const parsedDuration = parseDuration(column.name);
    const isNegative = parsedDuration.negative ?? false;
    const validUnit = Object.entries(parsedDuration as Record<string, string>).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        if (_notNil(value) && Number(value) !== 0 && typeof key === 'string') {
          acc[key] = `${isNegative ? '-' : ''}${value}`;
        }
        return acc;
      },
      {}
    );
    const unit = Object.keys(validUnit).at(0) ?? '';
    const amount = validUnit[unit] ?? '';
    return { unit, amount };
  } catch (e) {
    return { unit: '', amount: '' };
  }
};

export const serializeTimepointValue = (value: string, unit: string) => {
  const isNegative = Number(value) < 0;
  const number = Math.abs(Number(value));
  const unitKey = unit === 'H' ? 'hours' : unit === 'M' ? 'minutes' : 'seconds';
  return serializeDuration({ [unitKey]: number, negative: isNegative });
};
