import GroupLabel from '@/components/UI/GroupLabel';
import Icon from '@/components/UI/Icon';
import { renderMetadataValue } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';

interface GroupMetaProps {
  group: TreatmentGroup;
  closeModal: () => void;
}

const GroupMeta = ({ group, closeModal }: GroupMetaProps) => {
  let metadata;
  if (_isNotEmpty(group?.meta)) {
    if (Array.isArray(group.meta)) {
      metadata = group.meta;
    } else if (_notNil(group.meta?.data)) {
      metadata = group.meta.data;
    }
  } else {
    metadata = group.metadata;
  }

  return (
    <div className="ui-card center" style={{ width: '400px' }}>
      <div className="pa3 relative">
        <GroupLabel group={group} className="pr4" noMenu />
        <a
          onClick={closeModal}
          className="absolute right-0 top-0 ma3 w2 h2 br-100 bg-silver pointer flex hover-near-black"
        >
          <Icon icon="close" width="12" height="12" className="self-center center" />
        </a>
      </div>
      {metadata?.map(({ title, value, field_type }, index) => (
        <div key={index} className="bb b--moon-gray pa3" style={{ wordWrap: 'break-word' }}>
          <h4 className="basier-med lh-solid normal f5">{title}</h4>
          <p className="basier-reg normal lh-copy near-black">{renderMetadataValue({ value, field_type })}</p>
        </div>
      ))}
    </div>
  );
};

export default GroupMeta;
