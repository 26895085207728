// @ts-nocheck: converted from JS
import Handsontable from 'handsontable';
import type { BaseRenderer } from 'handsontable/renderers/base';
import { _isNotEmpty, uuid } from '@/littledash';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import './DateTimeEditor.scss';

const datetimeRenderer: BaseRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  let val;
  if (_isNotEmpty(value)) {
    const isDateOnly = cellProperties?.isDateOnly ?? false;
    if (isDateOnly) {
      val = DateUtils.renderDate(value ?? '', { defaultResponse: value ?? '' });
    } else {
      val = DateUtils.renderDateTime(value ?? '', { defaultResponse: value ?? '' });
    }
  } else {
    val = '';
  }

  Handsontable.renderers.TextRenderer(hotInstance, td, row, column, prop, val, cellProperties);
};

export class DateTimeEditor extends Handsontable.editors.TextEditor {
  static type = 'datetime';
  static readonly dateTimeEditorId = Object.freeze(uuid());
  private displayed = false;

  static register(type = DateTimeEditor.type) {
    Handsontable.cellTypes.registerCellType(type, {
      editor: DateTimeEditor,
      renderer: datetimeRenderer,
      validator: (value, callback) => callback(DateUtils.isValidDateTime(value)),
    });
  }

  #container(): HTMLDivElement | null {
    return this.hot.rootDocument.getElementById(
      `${DateTimeEditor.dateTimeEditorId}-datetime-container`
    ) as HTMLDivElement | null;
  }

  #overlay(): HTMLDivElement | null {
    return this.hot.rootDocument.getElementById(
      `${DateTimeEditor.dateTimeEditorId}-datetime-overlay`
    ) as HTMLDivElement | null;
  }

  #input(): HTMLInputElement | null {
    return this.hot.rootDocument.getElementById(
      `${DateTimeEditor.dateTimeEditorId}-datetime-input`
    ) as HTMLInputElement | null;
  }

  init() {
    super.init();
    this.instance.addHook('afterDestroy', () => this.destroyElements());
  }

  setValue(newValue: string) {
    const isDateOnly = this.cellProperties?.isDateOnly ?? false;
    const pattern = isDateOnly ? DateUtils.isoDatePattern : DateUtils.isoDateTimePattern;

    super.setValue(pattern.test(newValue) ? newValue : '');
  }

  open(event?: Event) {
    super.open();
    this.display();
  }

  close() {
    this.display(false);
    super.close();
  }

  display(display = true) {
    this.displayed = display;
    const restrictFutureDates = this.cellProperties?.restrictFutureDates ?? false;
    const dateTimeContainer = this.#container();
    const dateTimeOverlay = this.#overlay();
    const dateTimeInput = this.#input();
    if (dateTimeContainer !== null && dateTimeOverlay !== null && dateTimeInput !== null) {
      if (display) {
        const isDateOnly = this.cellProperties?.isDateOnly ?? false;
        const cellBorder = { vertical: 3, horizontal: 2 };
        const offset = this.TD.getBoundingClientRect();
        dateTimeOverlay.style.top = `${this.hot.rootWindow.scrollY + offset.top + cellBorder.vertical}px`;
        dateTimeOverlay.style.left = `${this.hot.rootWindow.scrollX + offset.left + cellBorder.horizontal}px`;
        this.TEXTAREA.style.width = `${offset.width}px`;
        this.TEXTAREA.style.height = `${offset.height - 5}px`;
        dateTimeContainer.style.visibility = 'visible';
        dateTimeInput.value = DateInputUtils.toLocalDateTime(this.getValue(), {
          includeSeconds: this.cellProperties?.displaySeconds ?? false,
        });
        dateTimeInput.type = isDateOnly ? 'date' : 'datetime-local';
        dateTimeInput.max = restrictFutureDates ? DateUtils.dateNow() : '';
        requestAnimationFrame(() =>
          // @ts-expect-error: mapped from ts-ignore
          dateTimeInput?.showPicker?.()
        );
      } else {
        dateTimeContainer.style.visibility = 'hidden';
      }
    }
  }

  createElements() {
    super.createElements();
    this.TEXTAREA.style.fontSize = '1px';
    this.TEXTAREA.style.opacity = '0';

    const datetimeContainer = this.hot.rootDocument.createElement('div');
    const datetimeOverlay = this.hot.rootDocument.createElement('div');
    const datetimeInput = this.hot.rootDocument.createElement('input');

    const handleChangeOrMouseDown = () => {
      this.setValue(DateInputUtils.localDateTimeToISODateTime(datetimeInput.value));
      this.finishEditing(false, false, () => {
        const { row, col } = this.cellProperties;
        this.hot.selectCell(row, col);
      });
    };

    datetimeContainer.id = `${DateTimeEditor.dateTimeEditorId}-datetime-container`;
    datetimeContainer.classList.add('dateTimeContainer');
    datetimeContainer.style.visibility = 'hidden';
    datetimeContainer.addEventListener('mousedown', handleChangeOrMouseDown, { passive: true });

    datetimeOverlay.id = `${DateTimeEditor.dateTimeEditorId}-datetime-overlay`;
    datetimeOverlay.classList.add('dateTimeOverlay');
    datetimeContainer.appendChild(datetimeOverlay);
    datetimeInput.id = `${DateTimeEditor.dateTimeEditorId}-datetime-input`;
    datetimeInput.type = 'datetime-local';
    datetimeInput.classList.add('dateTimeInput');
    datetimeInput.value = DateInputUtils.toLocalDateTime(this.getValue(), {
      includeSeconds: this.cellProperties?.displaySeconds ?? false,
    });
    datetimeInput.addEventListener('change', handleChangeOrMouseDown, { passive: true });
    datetimeOverlay.appendChild(datetimeInput);
    this.hot.rootDocument.body.appendChild(datetimeContainer);
  }

  destroyElements() {
    const datetimeContainer = this.#container();
    if (datetimeContainer !== null) {
      datetimeContainer.remove();
    }
  }
}
