import { _isNotEmpty, _isNumber, _notNil, uuid } from '@/littledash';
import { Order } from '@/model/Common.model';
import type { Measurement } from '@/model/Measurement.model';
import type { Preset } from '@/model/Preset.model';
import { addDays, format } from 'date-fns';
import type { FindInstanceOfMeasurementProps } from './useMeasurementComparator.model';

export const orderMeasurementsByDate = (measurements: Array<Measurement> = [], order: Order = Order.asc) =>
  [...measurements].sort(
    (a, b) =>
      new Date((order === Order.asc ? a : b).measured_at).getTime() -
      new Date((order === Order.asc ? b : a).measured_at).getTime()
  );

export const findComparatorDateMeasurement = (
  measurements: Array<Measurement> = [],
  { comparatorDate, measurementKey }: { comparatorDate?: string; measurementKey: string }
): Measurement | null => {
  if (_notNil(comparatorDate)) {
    return (
      measurements.filter(
        (measurement) =>
          new Date(measurement.measured_at) >= new Date(comparatorDate) &&
          _notNil(measurement.variables?.[measurementKey])
      )?.[0] ?? null
    );
  }
  return null;
};

export const findInstanceOfMeasurement = (
  measurements: Array<Measurement> = [],
  { from, measurementKey }: FindInstanceOfMeasurementProps
): Measurement | null => {
  return (
    (from === 'latest' ? Array(...measurements).reverse() : [...measurements]).find((measurement) =>
      _notNil(measurement.variables?.[measurementKey])
    ) ?? null
  );
};

export const findDifference = (value: number | null, compareValue: number | null): number | null =>
  _notNil(compareValue) && _isNumber(Number(compareValue)) && _notNil(value) && _isNumber(value)
    ? value - compareValue
    : null;

export const presetFactory = (size: number): any => {
  return Array.from({ length: size }).map((_) => ({
    id: uuid(),
    name: uuid(),
    formula: uuid(),
    unit: uuid(),
    measurements: [
      {
        id: uuid(),
        name: uuid(),
        unit: uuid(),
      },
    ],
  }));
};

export const measurementFactory = (size: number, calculations?: Preset['calculations']): Array<Measurement> => {
  return Array.from({ length: size }).map((_, index) => ({
    id: index + 1,
    api_id: `mst_${uuid().replaceAll('-', '')}`,
    measured_at: format(addDays(new Date('1991-01-01'), index), 'yyyy-MM-dd'),
    variables: (calculations ?? [])?.reduce((acc, { id, measurements }): Record<string, string> => {
      acc = {
        ...acc,
        [id]: String(Math.abs(Math.random() * (0 - 150))),
      };
      if (_isNotEmpty(measurements)) {
        acc = {
          ...acc,
          ...measurements.reduce((measurementAcc, { id: measurementId }) => {
            measurementAcc = {
              ...measurementAcc,
              [measurementId]: String(Math.abs(Math.random() * (0 - 9999))),
            };
            return measurementAcc;
          }, {}),
        };
      }
      return acc;
    }, {}),
    created_at: '',
  }));
};
