// @ts-nocheck: converted from JS

import apiRoutes from '@/constants/apiRoutes';
import webRoutes from '@/constants/webRoutes.json';
import queryString from 'query-string';

export const generateRoute = function (routes, uri, params, qs, filters = ''): string {
  if (typeof params !== 'object') {
    return generateRoute(routes, uri, [params], qs);
  }

  if (routes[uri] !== undefined) {
    uri = routes[uri];
  }

  const hasQueryParams = typeof qs === 'object' && qs !== null && Object.keys(qs).length > 0;
  if (hasQueryParams) {
    uri = uri + '?' + queryString.stringify(qs);
  }

  // This filters parameter is a workaround as it's impossible to set a key of filters[] in a javascript object.
  if (filters !== '') {
    uri = uri + (hasQueryParams ? '&' : '?') + filters;
  }

  if (Array.isArray(params)) {
    return uri.replace(/\{(\w+)\}/g, (s, key) => {
      return params.shift() || key;
    });
  }

  return uri.replace(/\{(\w+)\}/g, (s, key) => {
    return params[key] || key;
  });
};

export function web(
  uri: string,
  params?: Record<string, string | number | undefined>,
  qs?: Record<string, string | number | boolean | Array<string | number | boolean>>
): string {
  return generateRoute(webRoutes, uri, params, qs);
}

export function api(uri: string, params?: any, qs?: any, filters: string = ''): string {
  return generateRoute(apiRoutes, uri, params, qs, filters);
}
