// @ts-nocheck: converted from JS

import { mapAttachmentMetadataForSave } from '@/components/Studies/Attachments/file.utils';
import { defaultPromiseErrorHandler, errorToast, successToast } from '@/helpers';
import InVivoError from '@/model/InVivoError.ts';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import axios from 'axios';
import { assemblePayload } from '../../Show.utils';

const putFile = (url: string, file: File): Promise<void> =>
  fetch(url, { method: 'PUT', body: file }).then((result) => {
    if (result.status === 200) {
      return Promise.resolve();
    }
    const error = new Error('File upload error');
    Object.assign(error, { fileName: file?.name, fileType: file?.type });
    return Promise.reject(error);
  });
export const loadFiles = ({ context: { state }, setFiles, setInitialFiles, setError, setLoading }) => {
  const draftId = state?.form?.draftId;
  if (draftId) {
    setLoading('Loading files...');

    Http.get(apiRoute('drafts.files', { id: draftId }))
      .then(({ data }) => {
        setFiles(data);
        setInitialFiles(data);
      })
      .catch(({ response }) => {
        setError(response);
      })
      .finally(() => {
        setLoading(false);
      });
  } else {
    setFiles([]);
    setLoading(false);
  }
};

export const uploadFile = ({
  context: { state, formMeta, formId, dispatch },
  file,
  setFiles,
  setInitialFiles,
  setLoading,
  setError,
  formDispatch,
  name,
  attachmentMetadata,
  metadata,
}) => {
  const {
    study,
    form: { step },
  } = state;
  const payload = {
    name: study.name,
    form_data: assemblePayload(study, formMeta, formId),
    config: {
      last_section_id: step,
      form_id: formId,
    },
  };

  const mappedMetadata = mapAttachmentMetadataForSave(attachmentMetadata, metadata);

  const closeModal = () => {
    formDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  let draftId = state?.form?.draftId;
  setLoading(`Uploading ${name}`);

  if (draftId) {
    Http.post(apiRoute('drafts.files', { id: draftId }), { name, metadata: mappedMetadata })
      .then(({ data }) => {
        closeModal();
        return putFile(data.url, file);
      })
      .then(() => Http.get(apiRoute('drafts.files', { id: draftId })))
      .then(({ data }) => {
        setFiles(data);
        setInitialFiles(data);
        successToast(`Uploaded file ${name}`);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        errorToast(
          `Could not upload ${name}.\nPlease try again later ... if this keeps occurring please contact support.`
        );
        ExceptionHandler.captureException(
          new InVivoError('Could not upload draft file', {
            cause: error,
            slug: 'file-draft-upload',
          }),
          {
            fileName: file?.name,
            fileType: file?.type,
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  } else {
    Http.post(apiRoute('drafts.studies'), payload)
      .then(({ data: { data } }) => {
        closeModal();
        draftId = data.id;
        dispatch({
          type: 'setDraftId',
          data: { draftId, savingDraft: false },
        });
        return Http.post(apiRoute('drafts.files', { id: draftId }), {
          name,
          metadata: mappedMetadata,
        });
      })
      .then(({ data }) => putFile(data.url, file))
      .then(() => Http.get(apiRoute('drafts.files', { id: draftId })))
      .then(({ data }) => {
        setFiles(data);
        setInitialFiles(data);
        successToast(`Uploaded file ${name}`);
        successToast('Automatically saved draft');
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        ExceptionHandler.captureException(
          new InVivoError('Could not upload file & save draft', {
            cause: error,
            slug: 'file-draft-upload',
          }),
          {
            fileName: file?.name,
            fileType: file?.type,
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

export const deleteFile = ({ context: { state }, name, setFiles, setInitialFiles, setLoading, setError }) => {
  const draftId = state?.form?.draftId;
  setLoading(`Removing ${name}...`);
  Http.delete(apiRoute('drafts.files.name', { id: draftId, name }))
    .then(() => Http.get(apiRoute('drafts.files', { id: draftId })))
    .then(({ data }) => {
      setFiles(data);
      setInitialFiles(data);
      successToast(`Removed ${name}`);
    })
    .catch((error) => {
      setError(true);
    })
    .finally(() => {
      setLoading(false);
    });
};

const saveFile = (url, name) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(defaultPromiseErrorHandler);
};

export const downloadFile = ({ context: { state }, name, setLoading }) => {
  const draftId = state?.form?.draftId;
  if (draftId) {
    setLoading(`Downloading ${name}...`);
    return Http.get(apiRoute('drafts.files.name', { id: draftId, name }))
      .then(({ data }) => saveFile(data.url, name))
      .finally(() => setLoading(false));
  }
};
