// @ts-nocheck: converted from JS
import { useMemo, useEffect } from 'react';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import { web as webRoute } from '@/support/route';
import {
  allAnimalVariablesExcluded,
  formatAverage,
  getExcludedIncludedMap,
  getExcludedOrIncludedVariableApiIds,
  getVariableApiIdsFromMetrics,
} from './Groups.utils';
import GroupLabel from '@/components/UI/GroupLabel';
import Link from '@/components/UI/Link';
import { formatNumber, objectDiff, successToast } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import IncludeExcludeVariablesButton from '@/components/UI/IncludeExcludeVariablesButton';
import './GroupsTable.scss';
import type { ID } from '@/model/Common.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import type { Study } from '@/model/Study.model';
import type { VariableApiId } from '@/model/Measurement.model';
import { useSelector } from 'react-redux';
import Button from '@/components/UI/Button/Button';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useWatch, useForm, useFormContext, FormProvider } from 'react-hook-form';
import * as Auth from '@/support/auth';
import classNames from 'classnames';

interface GroupTableProps {
  metrics: GroupMetrics['data'];
  group: unknown;
  study: Study;
  initialValues: Record<VariableApiId, boolean>;
}

interface AnimalCellProps {
  studyId: ID;
  animalId: ID;
  animalName: string;
  animalVariableApiIds: VariableApiId[];
  isWriteUser?: boolean;
}

interface DataCellProps {
  value: string | null;
  variableApiId: VariableApiId;
  isWriteUser?: boolean;
  initialValues: GroupTableProps['initialValues'];
}

interface TableButtonActionsProps {
  onSubmit: Promise<void>;
  onReset: Promise<void>;
  isSubmitting: boolean;
  isResetting: boolean;
  initialValues: Record<VariableApiId, boolean>;
}

const GroupsTables = ({ metrics, study, fetchMetrics }) => {
  const initExcludedMap = useMemo(() => getExcludedIncludedMap(metrics), [metrics]);
  const formMethods = useForm({
    defaultValues: initExcludedMap,
  });
  const { reset } = formMethods;
  const { invoke: includeVariables, loading: includeVariablesUpdating } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyId}/variables-include',
    invokeOnInit: false,
  });
  const { invoke: excludeVariables, loading: excludeVariablesUpdating } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyId}/variables-exclude',
    invokeOnInit: false,
  });
  const { data_analysis: data_analysis_enabled } = useSelector(
    ({
      team: {
        features: { data_analysis },
      },
    }) => ({
      data_analysis,
    })
  );

  useEffect(() => {
    reset(getExcludedIncludedMap(metrics));
  }, [metrics, reset]);

  const onResetVariables = async (): Promise<void> => {
    const includes: VariableApiId[] = [];
    for (const variableApiId in initExcludedMap) {
      if (initExcludedMap?.[variableApiId as VariableApiId] === true) {
        includes.push(variableApiId as VariableApiId);
      }
    }
    if (_isNotEmpty(includes)) {
      await includeVariables({
        path: { studyId: study.api_id },
        body: { variable_api_ids: includes },
      });
      await fetchMetrics();
      successToast('Reset excluded measurements');
    }
  };

  const onSubmitChanges = async (formData: Record<VariableApiId, boolean>): Promise<void> => {
    const formDiffs = objectDiff(initExcludedMap, formData);
    const excludes: VariableApiId[] = [];
    const includes: VariableApiId[] = [];
    for (const variableApiId in formDiffs) {
      const diff = formDiffs?.[variableApiId]?.obj2;
      if (diff === true) {
        excludes.push(variableApiId as VariableApiId);
      }
      if (diff === false) {
        includes.push(variableApiId as VariableApiId);
      }
    }
    if (_isNotEmpty(excludes)) {
      await excludeVariables({
        path: { studyId: study.api_id },
        body: { variable_api_ids: excludes },
      });
      await fetchMetrics();
    }
    if (_isNotEmpty(includes)) {
      await includeVariables({
        path: { studyId: study.api_id },
        body: { variable_api_ids: includes },
      });
      await fetchMetrics();
    }
    successToast('Updated measurements');
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        {Auth.isWriteUserForStudy(study) && (
          <TableButtonActions
            onSubmit={onSubmitChanges}
            onReset={onResetVariables}
            isSubmitting={includeVariablesUpdating}
            isResetting={excludeVariablesUpdating}
            initialValues={initExcludedMap}
          />
        )}
        {metrics.map((group) => (
          <div key={group.api_id} className="bt odd:b--moon-gray" data-testid={`graphs__tables--entry:${group.id}`}>
            <GroupTable
              key={`group-table-${group?.id}`}
              metrics={metrics}
              group={group}
              study={study}
              initialValues={initExcludedMap}
              dataAnalysisEnabled={data_analysis_enabled}
            />
          </div>
        ))}
      </FormProvider>
    </div>
  );
};

const TableButtonActions = ({
  onSubmit,
  onReset,
  isSubmitting,
  isResetting,
  initialValues,
}: TableButtonActionsProps): JSX.Element => {
  const { handleSubmit, control } = useFormContext();
  const currentValues = useWatch({ control });
  const diffs = objectDiff(initialValues, currentValues);
  const notTouched = Object.keys(diffs).length === 0;
  const hasNoInitialExcluded = getExcludedOrIncludedVariableApiIds(initialValues, true).length === 0;

  return (
    <div className="flex justify-end pa3 bt b--moon-gray">
      <Button plain onClick={onReset} disabled={isSubmitting || hasNoInitialExcluded}>
        Reset
      </Button>
      <Button className="ml3" onClick={handleSubmit(onSubmit)} disabled={isResetting || notTouched}>
        Submit changes
      </Button>
    </div>
  );
};

const TABLE_FOOTER_CLASSES = 'db fw3 near-black lh-copy';

const GroupTable = ({ metrics, group, study, initialValues, dataAnalysisEnabled }: GroupTableProps) => {
  const ReactTableFixedColumns = withFixedColumns(ReactTable);
  const isWriteUser = Auth.isWriteUserForStudy(study);

  const averageTypes = ['sd', 'sem', 'mad'];

  const dayColumns = metrics
    .reduce((days, group) => {
      if (group.animals) {
        group.animals.forEach((animal) => {
          if (animal.values) {
            animal.values.forEach(({ study_day: studyDay }) => !days.includes(studyDay) && days.push(studyDay));
          }
        });
      }
      return days;
    }, [])
    .sort((a, b) => Number(a) - Number(b))
    .map((day) => {
      const averages = group.averages.find((average) => average.study_day === day);

      const metricValue = (method, metrics, spaceTop: boolean) => (
        <span key={method} className={`${TABLE_FOOTER_CLASSES} ${method} ${spaceTop ? 'mt2' : ''}`}>
          {averageTypes.includes(method) ? '±' : ''}
          {formatAverage(metrics)}
        </span>
      );

      return {
        id: `id-${day}`,
        Header: `Day ${day}`,
        accessor: (animal) => {
          const variable = animal?.values?.find((a) => a.study_day === day);
          const apiId = variable?.api_id;
          const value = _notNil(variable?.value) ? formatNumber(variable.value) : null;
          if (_notNil(value) && _notNil(apiId)) {
            return (
              <DataCell value={value} variableApiId={apiId} isWriteUser={isWriteUser} initialValues={initialValues} />
            );
          }
          return <>{value}</>;
        },
        ...(averages
          ? {
              Footer: (
                <>
                  {metricValue('mean', averages.mean)}
                  {metricValue('sd', averages.sd)}
                  {metricValue('sem', averages.sem)}
                  {metricValue('median', averages.median, true)}
                  {metricValue('mad', averages.mad)}
                  {dataAnalysisEnabled ? (
                    <>
                      {metricValue('dtdc', averages.dtdc, true)}
                      {metricValue('tgi', averages.tgi)}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ),
            }
          : {}),
      };
    });

  const columns = [
    {
      fixed: 'left',
      accessor: 'group.name',
      Header: <GroupLabel group={group} />,
      Footer: (
        <div>
          <span className={`${TABLE_FOOTER_CLASSES} mean`}>Mean</span>
          <span className={`${TABLE_FOOTER_CLASSES} sd`}>SD</span>
          <span className={`${TABLE_FOOTER_CLASSES} sem`}>SEM</span>
          <span className={`${TABLE_FOOTER_CLASSES} mt2 median`}>Median</span>
          <span className={`${TABLE_FOOTER_CLASSES} mad`}>MAD</span>
          {dataAnalysisEnabled ? (
            <>
              <span className={`${TABLE_FOOTER_CLASSES} mt2 dtdc`}>% dT/dC</span>
              <span className={`${TABLE_FOOTER_CLASSES} tgi`}>% Inhibition</span>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
      width: 175,
    },
    {
      fixed: 'left',
      accessor: 'number',
      Header: 'No.',
      width: 50,
    },
    {
      fixed: 'left',
      accessor: 'name',
      Header: 'ID',
      width: 150,
      Cell: ({ row }) => {
        const animalId = row._original?.id;
        const animalName = row._original?.name;
        const animalVariableApiIds = getVariableApiIdsFromMetrics(animalId, metrics);
        if (_isNil(animalId)) {
          return <div>—</div>;
        }
        return (
          <AnimalCell
            studyId={study.id}
            animalId={animalId}
            animalName={animalName}
            animalVariableApiIds={animalVariableApiIds}
            isWriteUser={isWriteUser}
          />
        );
      },
    },
    ...dayColumns,
  ];

  return (
    <ReactTableFixedColumns
      // -striped comes from the fixed column lib
      className="ui__groups-table -striped"
      key={group.name}
      defaultPageSize={group.animals.length === 0 ? 5 : group.animals.length}
      showPagination={false}
      sortable={false}
      showPageSizeOptions={false}
      filterable={false}
      resizable={false}
      noDataText="No measurements yet"
      data={group.animals}
      columns={columns}
    />
  );
};

const AnimalCell = ({
  studyId,
  animalId,
  animalName,
  animalVariableApiIds,
  isWriteUser = false,
}: AnimalCellProps): JSX.Element => {
  const { setValue } = useFormContext();
  // this will isolate re-rendering at the custom hook level don't swap for `watch`
  const variableState: Record<VariableApiId, boolean> = useWatch({ name: animalVariableApiIds });
  const allVariablesExcluded = allAnimalVariablesExcluded(variableState);

  const handleIncludeExcludeClick = (): void => {
    let toExclude = false;
    if (!allVariablesExcluded) {
      toExclude = true;
    }

    for (const variableKey in variableState) {
      setValue(variableKey, toExclude);
    }
  };

  return (
    <div className="w-100 hide-child">
      <div className="flex justify-between items-center relative">
        <Link
          to={webRoute('animals.show', {
            id: studyId,
            subject_id: animalId,
          })}
          className="link blue"
        >
          {animalName}
        </Link>
        {isWriteUser && (
          <div className="include-exclude-variable-button child absolute right-0 z-2">
            <IncludeExcludeVariablesButton isExcluded={allVariablesExcluded} onClick={handleIncludeExcludeClick} />
          </div>
        )}
      </div>
    </div>
  );
};

/** `<Controller />` with hook-form V6 has an infinite re-render issue with react table v6 cells
 *  Instead we need to register this 'form-field' seperately and isolate the re-render
 */

const DataCell = ({ value, variableApiId, isWriteUser = false, initialValues }: DataCellProps): JSX.Element => {
  const { register, setValue } = useFormContext();

  // this will isolate re-rendering at the custom hook level don't swap for `watch`
  const excluded = useWatch({ name: variableApiId });
  const initialExcluded = initialValues?.[variableApiId] === true;

  useEffect(() => {
    // we need to custom register all variables for the `handleSubmit` and `useWatch` values
    register(variableApiId);
  }, [register]);

  const handleClick = (): void => {
    if (isWriteUser) {
      setValue(variableApiId, !excluded);
    }
  };

  const className = classNames({
    pointer: isWriteUser,
    blue: initialExcluded,
    strike: excluded || (initialExcluded && excluded),
    'strike-hover': !excluded || !initialExcluded,
  });

  return (
    <div onClick={handleClick} className={className}>
      {value}
    </div>
  );
};

export default GroupsTables;
