// @ts-nocheck: converted from JS

import { useDispatch } from 'react-redux';

const RemoveCageFromStudy = ({ onClick }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <div className="ui__narrow-modal">
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">Are you sure you want to remove this cage from the study?</h3>
      </div>
      <div>
        <button onClick={onClick} className="button critical w-100">
          Remove cage
        </button>
        <a className="button plain db w-100 mt2" onClick={closeModal}>
          Cancel
        </a>
      </div>
    </div>
  );
};

export default RemoveCageFromStudy;
