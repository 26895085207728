import { useEffect, useState } from 'react';

/**
 * @returns {{visible: boolean, state: "hidden" | "visible"}}
 */
export const useVisibilityState = (): { visible: boolean; state: DocumentVisibilityState } => {
  const [state, setState] = useState(document.visibilityState);

  useEffect(() => {
    const handler = () => setState(document.visibilityState);
    document.addEventListener('visibilitychange', handler, { passive: true });
    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  });
  return { visible: state === 'visible', state: state };
};
