import { _isNotEmpty, _notNil } from '@/littledash';
import type { ApiID, ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import Http from '@/support/http';
import { RouteService } from '@/support/RouteService';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { notAborted, useAbortController } from './Hooks/fetch/useAbortController';

export const apiIdPattern = /^(?<entityType>[a-z]{3})_(?<id>[a-zA-Z0-9]{10,22})$/;

// For any api id which does not exist in the PHP server
export const ignoredApiEntities = /^(dtl|pst|sdc|srr)$/;

const mapApiIds = async (apiIds: Set<ApiID>, signal: AbortSignal): Promise<Array<{ api_id: ApiID; id: ID }>> => {
  const { url } = RouteService.legacyApi({ apiRoute: 'util.id' });
  apiIds.forEach((id) => url.searchParams.append('api_id[]', id));
  return Http.get<
    Array<{
      api_id: ApiID;
      id: ID;
    }>
  >(url.href, { signal }).then((result) => (Array.isArray(result?.data) ? result.data : []));
};

/**
 * @param children {React.ReactNode}
 * @returns {JSX.Element|null}
 * @constructor
 */
export const ApiIdMapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { newAbortController } = useAbortController();
  const pathApiIds = useMemo(
    () =>
      window.location.pathname.split('/').reduce((acc, segment) => {
        const entityPrefix = apiIdPattern.exec(segment)?.groups?.entityType;
        if (_notNil(entityPrefix) && !ignoredApiEntities.test(entityPrefix)) {
          acc.add(segment as ApiID);
        }
        return acc;
      }, new Set<ApiID>()),

    [window.location.pathname]
  );

  useEffect(() => {
    if (pathApiIds.size > 0) {
      mapApiIds(pathApiIds, newAbortController('aborted').signal)
        .then((idMap) => {
          if (_isNotEmpty(idMap)) {
            window.location.pathname = idMap.reduce(
              (acc, { api_id: apiId, id }) => acc.replace(new RegExp(apiId, 'g'), `${id}`),
              window.location.pathname
            );
          } else {
            window.location.pathname = '/not-found';
          }
        })
        .catch((error) => {
          if (notAborted(error)) {
            ExceptionHandler.captureException(
              new InVivoError('Could not map api ids', {
                cause: error,
                slug: 'map-api-ids',
              })
            );
            window.location.pathname = '/not-found';
          }
        });
    }
  }, [pathApiIds]);
  if (pathApiIds.size > 0) {
    return null;
  }
  return <>{children}</>;
};
