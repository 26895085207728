import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { DraggableTypes } from '@/constants/draggable';
import { calculateAge } from '@/helpers';
import { _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { DragSourceMonitor, useDrag } from 'react-dnd';

interface CageCardProps {
  id?: ID;
  index?: ID;
  subject: Animal;
  studyGroup?: TreatmentGroup;
  inStudyView?: boolean;
  canDrag: boolean;
  moveCallback?: (dragIndex: any, hoverIndex: any) => void;
  dropCallback?: (item: any) => Promise<void>;
}

const CageCard: React.FC<CageCardProps> = ({ id, index, subject, studyGroup, inStudyView, canDrag }) => {
  const { id: animalId, name, dob, sex, catalog, study_id: studyId, number } = subject;

  const [{ isDragging }, drag] = useDrag({
    item: {
      id,
      index,
      animalId,
      type: DraggableTypes.card,
    },
    canDrag: (_: DragSourceMonitor) => canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const style = {
    opacity: isDragging ? 0.4 : 1,
    cursor: 'move',
  };

  return (
    <div className="bg-white br2 pa2" ref={drag} style={style}>
      <div className="flex f7 near-black">
        <div
          className={`groupColour br3 pa1 mr2 ${studyGroup?.color ?? 'b--dashed ba'}`}
          style={{
            backgroundColor: studyGroup?.color ?? '#fff',
          }}
        />

        <div className="flex-grow-1">
          <div className="flex flex-wrap mb1">
            <div className="pa1 mr1 bg-moon-gray br2">{_notNil(studyId) ? number : catalog}</div>
            <div className="pa1 fw5">{name}</div>
            <div className="pa1 flex flex-grow-1 justify-end fw5">{sex?.toUpperCase?.() ?? '-'}</div>
          </div>
          {_notNil(dob) && (
            <div>
              <DateRenderer value={dob} /> ({calculateAge(dob)})
            </div>
          )}
          <div className="mt1">{studyGroup?.name || (inStudyView ? '-' : ' ')}</div>
        </div>
      </div>
    </div>
  );
};

export default CageCard;
