// @ts-nocheck: converted from JS
import Handsontable from 'handsontable';
import type { BaseRenderer } from 'handsontable/renderers/base';
import { DateUtils } from '@/utils/Date.utils';
import { _isEmpty, _isNil } from '@/littledash';

interface DropdownIconMousedownHandlerProps {
  hotInstance: Handsontable.Core & {
    dropdownArrowListener?: (event: Event) => void;
    view?: any;
  };
  td: HTMLTableCellElement;
  row: number;
  column: number;
}

/**
 * This is some HandsonTable madness to add single click to the cell dropdown arrow
 *
 * Based on HandsonTable DatePicker see <a href="https://github.com/handsontable/handsontable/blob/master/handsontable/src/renderers/autocompleteRenderer/autocompleteRenderer.js#L42-L58">here</a>
 */

const addDropdownIconMousedownHandler = ({ hotInstance, td, row, column }: DropdownIconMousedownHandlerProps) => {
  if (_isNil(hotInstance.dropdownArrowListener)) {
    const eventManager = new Handsontable.EventManager(hotInstance);
    hotInstance.dropdownArrowListener = (event) => {
      if (Handsontable.dom.hasClass(event.target as HTMLElement, 'multiSelectDropdownArrow')) {
        // This is an internal HoT thing ... If single click breaks look here first
        hotInstance.view?._wt?.getSetting(
          'onCellDblClick',
          null,
          // TODO new CellCoords(row, column),
          { row, column },
          td
        );
      }
    };
    eventManager.addEventListener(hotInstance.rootElement, 'mousedown', hotInstance.dropdownArrowListener);
    hotInstance.addHookOnce('afterDestroy', () => eventManager.destroy());
  }
};

const datetimeRenderer: BaseRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.call(null, hotInstance, td, row, column, prop, value, cellProperties);
  Handsontable.dom.empty(td);

  const dateContainer = document.createElement('div');
  dateContainer.innerText = DateUtils.renderDate(value, { defaultResponse: value ?? '' });

  const iconContainer = document.createElement('div');
  iconContainer.classList.add('htAutocompleteArrow', 'multiSelectDropdownArrow');
  iconContainer.innerHTML = '&#9660';

  td.appendChild(iconContainer);
  td.appendChild(dateContainer);
  addDropdownIconMousedownHandler({ hotInstance, td, row, column });
};

export class DateEditorWrapper extends Handsontable.editors.DateEditor {
  static type = 'date-wrapper';
  static dateFormatPattern = /^(?<day>[0-3]\d)\/(?<month>[0-1]\d)\/(?<year>\d{4})$/;

  static register(type = DateEditorWrapper.type) {
    Handsontable.cellTypes.registerCellType(type, {
      editor: DateEditorWrapper,
      renderer: datetimeRenderer,
      validator: (value, callback) => callback(_isEmpty(value) || DateUtils.isValidDate(value)),
    });
  }

  setValue(newValue: string) {
    super.setValue(DateUtils.isValidDate(newValue) ? newValue : '');
  }

  getValue(): any {
    return super.getValue();
  }
}
