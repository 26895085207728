import ApiErrorBanner from '@/components/ApiErrorBanner';
import type {
  ExtendedSpeciesV1,
  FormError,
} from '@/components/Glossary/Sections/SpeciesStrains/SpeciesComponent.model';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import { successToast } from '@/helpers';
import type { State } from '@/model/State.model';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Repeater from './Repeater';
import { removeSpecies, updateSpecies, validateData } from './SpeciesStrains.utils';
import { SpeciesV1, SpeciesBulkUpdateV1 } from '@/model/Species.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';

const SpeciesStrains: React.FC = () => {
  const [data, setData] = useState<Array<ExtendedSpeciesV1> | []>([]);
  const [initialData, setInitialData] = useState<Array<ExtendedSpeciesV1> | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<Array<false | FormError>>([]);
  const [submissionError, setSubmissionError] = useState<false | Error>(false);

  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));
  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  const mapIndex = (species: SpeciesV1, index: number): ExtendedSpeciesV1 => ({
    ...species,
    index,
    expanded: false,
  });

  const { response, invoke: loadSpecies } = useApiHook({
    endpoint: 'GET /api/v1/species',
    invokeOnInit: true,
    query: { include: ['strains'] },
    options: { onError: { throw: false, capture: true, toast: false } },
  });

  const { invoke: deleteSpeciesHook } = useApiHook({
    endpoint: 'DELETE /api/v1/species',
    invokeOnInit: false,
    options: { onError: { throw: false, capture: true, toast: false } },
  });

  const { invoke: updateSpeciesHook } = useApiHook({
    endpoint: 'POST /api/v1/species',
    invokeOnInit: false,
    options: { onError: { throw: false, capture: true, toast: false } },
  });

  useEffect(() => {
    if (response && response.type === 'success') {
      const speciesData = response?.body?.data as Array<SpeciesV1>;
      const modifiedData = speciesData.map(mapIndex);
      setData(modifiedData);
      setInitialData(modifiedData);
      setLoading(false);
    }
  }, [response]);

  const validateField = async (d: ExtendedSpeciesV1): Promise<false | FormError> => {
    const validateRow = await validateData(d);
    if (validateRow) {
      return {
        index: d.index,
        errors: {
          ...validateRow,
        },
      };
    }

    return false;
  };

  const validateFields = async () => {
    const results: Array<false | FormError> = [];
    for (let index = 0; index < data.length; index++) {
      results[index] = await validateField(data[index]);
    }

    return setFormErrors(results);
  };

  useEffect(() => {
    validateFields();
  }, [data]);

  const handleUpdateSpecies = (updates: SpeciesBulkUpdateV1) => {
    updateSpeciesHook({ body: updates })
      .then(() => {
        loadSpecies().then(() => {
          setLoading(false);
          successToast('Successfully saved species and strains');
        });
      })
      .catch((error) => {
        setLoading(false);
        setSubmissionError(error);
      });
  };

  const handleSubmit = async () => {
    const validate = formErrors.every((o) => o === false);
    if (validate) {
      setLoading(true);
      const removedSpecies = removeSpecies(data, initialData);
      const updatedSpecies = updateSpecies(data);
      if (removedSpecies.species_api_ids?.length > 0) {
        deleteSpeciesHook({
          body: removedSpecies,
        })
          .then(() => {
            handleUpdateSpecies(updatedSpecies);
          })
          .catch((error) => {
            setLoading(false);
            setSubmissionError(error);
          });
      } else {
        handleUpdateSpecies(updatedSpecies);
      }
    }
  };
  if (loading) {
    return <Loading txt="Fetching Species and Strains..." />;
  }

  return (
    <div>
      <Header mainHeaderText="Species and Strains" />
      <div className="pb3 f6">
        {atunesIntegrationEnabled ? (
          <p className="f6 pb2">Species and Strains are managed through tick@lab</p>
        ) : (
          <p className="f6 pb2">Customise the species and strains your team can select from.</p>
        )}

        {submissionError && <ApiErrorBanner className="mb4" error={submissionError} />}
      </div>
      <div>
        <Repeater data={data} errors={formErrors} setData={setData} readOnly={atunesIntegrationEnabled} />
      </div>
      {!atunesIntegrationEnabled && (
        <div className="pv3 mt3 bt b--moon-gray">
          <Button
            tooltip={!formErrors.every((o) => o === false) ? 'Please fill in all required fields' : undefined}
            disabled={!formErrors.every((o) => o === false)}
            className="mr3"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
          <Button plain onClick={() => loadSpecies()}>
            Undo changes
          </Button>
        </div>
      )}
    </div>
  );
};

// const AtunesEnabled;

export default SpeciesStrains;
