import type { Endpoints } from '@/generated/internal-api/Endpoints';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ApiID, ID } from '@/model/Common.model';
import type { LatestMeasurement } from '@/model/LatestMeasurements.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import type { ModalProps } from './Show';
import {
  RandomAnimal,
  RandomizationMethodKeys,
  RandomizationResultFlattened,
  RandomizationTableData,
  RandomizeState,
} from './Steps/Randomize/Randomize.model';
import { Step } from '@/components/UI/StepForm/StepForm.model';
import { RDOptions } from './Steps/RandomizeByDate/RandomizeByDate.model';
import {
  getEnabledCalculations,
  validateAnimals,
  validateStudyGroups,
} from './Steps/RandomizeByDate/RandomizeByDate.utils';
import { RandomizeAnimal } from './Steps/Randomize/Randomize';

interface Counted {
  added: number;
  space: number;
}

const groupFreeSpace = ({ max_subjects: ms, animals_count: ac = 0 }: TreatmentGroup): number =>
  ac >= ms ? 0 : ms - ac;

const divMod = (x: number, y: number): Array<number> => [Math.floor(x / y), x % y];

interface DistrubutedGroups {
  groups: Record<ID, Counted>;
  remaining: number;
  freeSpace: number;
}

const distribute = (groups: Record<ID, Counted>, subjectCount: number): Record<ID, Counted> => {
  const groupEntries = Object.entries(groups)
    .filter(([, { space }]) => space > 0)
    .sort(([, a], [, b]) => b.space - a.space);
  const [quotient, remainder] = divMod(subjectCount, groupEntries.length);
  const {
    groups: updatedGroups,
    remaining: updatedSubjectCount,
    freeSpace,
  } = groupEntries.reduce<DistrubutedGroups>(
    ({ groups, remaining, freeSpace }, [groupId, { added, space }]) => {
      if (quotient === 0 && remaining === 0) {
        return { groups, remaining, freeSpace };
      }
      const q = quotient === 0 ? 1 : quotient;
      const addCount = Math.min(q, space);
      return {
        groups: {
          ...groups,
          [groupId]: { added: added + addCount, space: space - addCount },
        },
        remaining: quotient === 0 ? remaining - 1 : remaining + (q - addCount),
        freeSpace: freeSpace + (space - addCount),
      };
    },
    { groups: {}, remaining: remainder, freeSpace: 0 }
  );
  return {
    ...updatedGroups,
    ...(updatedSubjectCount === 0 || freeSpace === 0 ? {} : distribute(updatedGroups, updatedSubjectCount)),
  };
};

export const defaultGroupDistribution = (
  groups: Array<TreatmentGroup>,
  subjects: Array<Animal>
): Record<ID, number> => {
  const distribution = distribute(
    groups.reduce<Record<ID, Counted>>((acc, group) => {
      const space = groupFreeSpace(group);
      if (space > 0) {
        return { ...acc, [group.id]: { added: 0, space } };
      }
      return acc;
    }, {}),
    subjects.length
  );
  return groups.reduce<Record<ID, number>>((acc, { id }) => ({ ...acc, [id]: distribution?.[id]?.added ?? 0 }), {});
};

export const countGroupDistribution = (groupDistribution: Record<ID, number>, subjectsToAdd: number): number => {
  const groupKeys = Object.keys(groupDistribution);
  const groupInputs: Array<number> = [];
  groupKeys.map((k, i) => (groupInputs[i] = Number(groupDistribution[k])));
  const groupInputsSum = groupInputs.reduce<number>((a, b) => {
    return a + b;
  }, 0);

  return subjectsToAdd - groupInputsSum;
};

export const areTreatmentGroupsUneven = (groups: Record<ID, TreatmentGroup>) => {
  return !Object.values(groups).every((maxSubjects, i, arr) => {
    return maxSubjects === arr[0];
  });
};

export const initialiseState = (
  steps: Array<Step<RandomizeState, ModalProps>>,
  data: object,
  props: ModalProps,
  features?: Record<string, boolean>
): RandomizeState => {
  const { selectedSubjects, groups } = props;
  const originalSelectedSubjects = selectedSubjects.reduce<Array<Animal & { selected: boolean }>>(
    (accumulator, subject) => {
      accumulator.push({ ...subject, selected: true });
      return accumulator;
    },
    []
  );
  const validAnimals = validateAnimals(originalSelectedSubjects);
  const validStudyGroups = validateStudyGroups(groups);
  const enabledCalculations = getEnabledCalculations(validAnimals.includedAnimals, props.study.settings.calculations);

  const result: RandomizeState = {
    steps: steps as Array<Step<RandomizeState, Record<string, any>>>,
    groups,
    currentStep: 0,
    data,
    originalSelectedSubjects: originalSelectedSubjects,
    complete: false,
    randomizeByDate: {
      rdSelection: RDOptions.LatestMeasurements,
      randomizeDate: null,
      validAnimals,
      measurementsOnDate: null,
      validStudyGroups,
      enabledCalculations,
    },
    treatmentGroups: {
      totalSpace: 0,
      remainingAnimals: 0,
      groupDistribution: {},
    },
    exclusionCriteria: {
      subjects: originalSelectedSubjects,
      includedSubjects: 0,
      averageActiveCriteria: 0,
      averageWeight: 0,
      autoExcluded: {
        excludedByGroup: 0,
        excludedByMeasurement: 0,
        excludedByStatus: 0,
      },
      criteriaIndex: 0,
      criteriaOptions: [],
      changes: false,
      autoMedian: true,
      targetMean: 0,
      subjectsAfterExclusion: [],
    },
    randomize: {
      method: features?.hide_block_allocation ? RandomizationMethodKeys.cluster : RandomizationMethodKeys.block,
      results: [],
      trackingDatesUpdated: false,
      selectedMetrics: [],
      selectedAttribute: { id: '' },
    },
  };

  return result;
};

interface RandomisedAnimals {
  id?: ApiID<'aml'>;
  study_group_id?: ApiID<'grp'>;
  measurement_info?: Record<string, number>;
}

export const assembleAnimalsArray = (
  animalLookup: Record<string, RandomizeAnimal>,
  results: Array<RandomizationResultFlattened>,
  validMeasurements: Set<string>
): Array<RandomisedAnimals> =>
  results.reduce<Array<RandomisedAnimals>>((acc, { api_id: study_group_id, cohort_subject_ids }) => {
    cohort_subject_ids?.forEach((id) => {
      const currentAnimal = animalLookup[id];
      const measurementInfo = simplifyMeasurements(validMeasurements, currentAnimal.significantMeasurement);
      acc.push({ id: currentAnimal.api_id, study_group_id, measurement_info: measurementInfo });
    });
    return acc;
  }, []);

export const assembleExcludedAnimals = (validMeasurements: Set<string>, excludedAnimals?: Array<Animal>) =>
  excludedAnimals?.map(({ api_id, latestMeasurement }) => ({
    id: api_id,
    measurement_info: simplifyMeasurements(validMeasurements, latestMeasurement),
  }));

export const simplifyMeasurements = (
  validMeasurements: Set<string>,
  significantMeasurement?: Record<string, LatestMeasurement>
): Record<string, number> =>
  Object.entries(significantMeasurement ?? []).reduce<Record<string, number>>((acc, [key, value]) => {
    if (validMeasurements.has(key)) {
      acc[key] = Number(value.value);
    }
    return acc;
  }, {});

export const assembleStudyGroupInfo = (
  selectedMetrics: Array<string>,
  data?: Array<RandomizationTableData>,
  selectedAttribute?: string
) =>
  data?.reduce<
    NonNullable<
      NonNullable<
        Endpoints['POST /api/v1/studies/{studyId}/randomizations']['request']['randomization_report']
      >['study_group_info']
    >
  >((acc, { group, sex, size, significantMeasurement, sd, mad, subRows }) => {
    const selectedMetricsLookup = new Set(selectedMetrics);
    if (size > 0) {
      const measurements = Object.entries(significantMeasurement).reduce<Record<string, Record<string, number>>>(
        (innerAcc, [key, value]) => {
          if (selectedMetricsLookup.has(key)) {
            const numberValues = Object.entries(value).reduce<Record<string, number>>(
              (valueAcc, [valueKey, valueValue]) => {
                valueAcc[valueKey] = Number(valueValue);
                if (valueKey === 'mean') {
                  valueAcc.std_dev = Number(sd.significantMeasurement?.[key]?.[valueKey] ?? 0);
                } else {
                  valueAcc.mad = Number(mad.significantMeasurement?.[key]?.[valueKey] ?? 0);
                }
                return valueAcc;
              },
              {}
            );
            innerAcc[key] = {
              ...numberValues,
            };
          }
          return innerAcc;
        },
        {}
      );
      acc.push({
        id: group.api_id,
        measurements,
        subjects: {
          total: size,
          ...getSelectedAttributeRecord(selectedAttribute ?? '', sex, subRows),
        },
      });
    }
    return acc;
  }, []);

const getSelectedAttributeRecord = (
  selectedAttribute: string,
  sex: Record<string, number>,
  subRows: Partial<RandomAnimal>[]
) => {
  switch (selectedAttribute) {
    case 'sex':
      return {
        sex: {
          m: sex?.Male ?? 0,
          f: sex?.Female ?? 0,
        },
      };
    case 'donor_id':
      return {
        donor_id: subRows.reduce<Record<string, number>>((acc, { alt_ids }) => {
          if (_isNotEmpty(alt_ids) && _notNil(alt_ids?.['donor'])) {
            acc[alt_ids['donor']] = (acc[alt_ids['donor']] ?? 0) + 1;
          }
          return acc;
        }, {}),
      };
    case 'dob':
      return {
        dob: subRows.reduce<Record<string, number>>((acc, { dob }) => {
          if (_isNotEmpty(dob)) {
            acc[dob] = (acc[dob] ?? 0) + 1;
          }
          return acc;
        }, {}),
      };
    default:
      return {};
  }
};

export const assembleRandomizationReport = (
  { exclusionCriteria, randomize, randomizeByDate }: RandomizeState,
  selectedMetrics: Array<string>
): Endpoints['POST /api/v1/studies/{studyId}/randomizations']['request']['randomization_report'] => {
  const RandomizationAttribute = randomize.selectedAttribute?.id as string | undefined;
  const response: Endpoints['POST /api/v1/studies/{studyId}/randomizations']['request']['randomization_report'] = {
    animals_selected: randomizeByDate.validAnimals.includedAnimals.length,
    animals_excluded: exclusionCriteria.subjectsExcluded?.length,
    target_mean: Number(exclusionCriteria.targetMean),
    mean_post_exclusion: exclusionCriteria.averageActiveCriteria,
    exclusion_criteria: exclusionCriteria.criteriaOptions[exclusionCriteria.criteriaIndex]?.value,
    criteria: selectedMetrics,
    one_way_anova: randomize.tableData?.oneWayAnovaResults,
    study_group_info: assembleStudyGroupInfo(
      selectedMetrics,
      randomize.tableData?.data,
      randomize.selectedAttribute?.id?.toString()
    ),
    randomization_method: randomize.method,
  };
  if (_notNil(randomizeByDate.measurementsOnDate)) {
    response.measurement_date = randomizeByDate.randomizeDate as string;
  }
  if (_notNil(RandomizationAttribute)) {
    response.randomization_attribute = RandomizationAttribute;
  }
  return response;
};
