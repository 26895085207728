import {
  createExport,
  toSlug,
  triggerDownload,
  verifyImport,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers.tsx';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model.ts';
import { Columns } from '@/components/UI/Table/TableComponent.model.ts';
import UserAvatar from '@/components/UI/UserAvatar';
import { errorToast, successToast } from '@/helpers.tsx';
import { _notNil } from '@/littledash.ts';
import InVivoError from '@/model/InVivoError.ts';
import { PresetApiId, PresetSummaryV1 } from '@/model/Preset.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { web as webRoute } from '@/support/route.ts';
import { RouteService } from '@/support/RouteService.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { useModalAction } from '@/utils/modal.tsx';
import { ChangeEvent, FC, useCallback, useMemo, useRef } from 'react';
import { RiFileCopyLine, RiFileDownloadLine, RiMoreFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

const buildColumns = (
  handleExport: (apiId: PresetApiId) => Promise<void>,
  handleClone: (apiId: PresetApiId) => Promise<void>
): Columns<PresetSummaryV1> => [
  {
    id: 'title',
    accessor: 'title',
    Header: 'Title',
    isVisible: true,
    sortBy: 'title',
    Cell: ({ row: { original } }) =>
      original.published || original.locked ? (
        original.name
      ) : (
        <Link
          to={webRoute('team.glossary.presets.edit', {
            presetApiId: original.api_id,
          })}
          className="link blue"
        >
          {original.name}
        </Link>
      ),
  },
  {
    id: 'published',
    accessor: 'published',
    Header: 'Published',
    isVisible: true,
    sortBy: 'published',
    Cell: ({ row: { original } }) => (original.published ? 'Yes' : 'No'),
  },
  {
    id: 'created_at',
    accessor: 'created_at',
    Header: 'Created',
    isVisible: true,
    sortBy: 'created_at',
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} defaultResponse="-" />,
  },
  {
    id: 'created_by',
    Header: 'Created by',
    isVisible: true,
    Cell: ({ row: { original } }) => <UserAvatar user={original.created_by} />,
  },
  {
    id: 'updated_at',
    accessor: 'updated_at',
    Header: 'Updated',
    isVisible: true,
    sortBy: 'updated_at',
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.updated_at} defaultResponse="-" />,
  },
  {
    id: 'updated_by',
    Header: 'Updated by',
    isVisible: true,
    Cell: ({ row: { original } }) => <UserAvatar user={original.updated_by ?? { name: '-' }} />,
  },
  {
    id: 'preset-actions',
    Header: '',
    width: 50,
    isVisible: true,
    Cell: ({ row: { original } }) => {
      return !original.locked ? (
        <span className="flex flex-row items-center justify-center w-100">
          <Dropdown
            renderMenu={() => (
              <>
                <DropdownMenuItem onClick={() => handleClone(original.api_id)}>
                  <span className="flex flex-row items-center justify-between">
                    <span>Clone</span>
                    <RiFileCopyLine />
                  </span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleExport(original.api_id)}>
                  <span className="flex flex-row items-center justify-between">
                    <span>Export</span>
                    <RiFileDownloadLine />
                  </span>
                </DropdownMenuItem>
              </>
            )}
          >
            <RiMoreFill size={22} />
          </Dropdown>
        </span>
      ) : null;
    },
  },
];

export const Presets: FC = () => {
  const history = useHistory();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { invoke: deletePresets } = useApiHook({
    endpoint: 'DELETE /api/v1/team/presets/delete',
    invokeOnInit: false,
    options: { onError: { throw: true, capture: true, toast: false } },
  });
  const { invoke: publishPresets } = useApiHook({
    endpoint: 'POST /api/v1/team/presets/publish',
    invokeOnInit: false,
    options: { onError: { throw: true, capture: true, toast: false } },
  });
  const { invoke: loadPreset } = useApiHook({
    endpoint: 'GET /api/v1/team/presets/{presetApiId}',
    invokeOnInit: false,
    options: { onError: { throw: false, capture: true, toast: false } },
  });

  const { openModal } = useModalAction();

  const handleExport = useCallback(
    async (presetApiId: PresetApiId) => {
      const result = await loadPreset({ path: { presetApiId } });
      if (result.type === 'success') {
        try {
          const { measurements, name } = result.body;
          triggerDownload(`${toSlug(name)}.json`, await createExport({ name, measurements }));
          successToast(`${name} exported`);
        } catch (cause) {
          ExceptionHandler.captureException(
            new InVivoError('Could not generate preset export', {
              slug: 'preset-export',
              context: { presetApiId },
              level: 'warning',
              cause,
            })
          );
          errorToast('Could load preset to generate export');
        }
      } else {
        errorToast('Could load preset to generate export');
      }
    },
    [loadPreset]
  );
  const handleImport = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.item(0);
      if (_notNil(file)) {
        try {
          const content = await file.text();
          const parsedContent = JSON.parse(content);
          if (await verifyImport(parsedContent)) {
            successToast(`Imported ${file.name}`);
          }
          history.push(webRoute('team.glossary.presets.create'), { preset: parsedContent.data });
          return;
        } catch (error) {
          // intentionally empty
        }
        errorToast(`Could not import ${file.name}`);
      }
    },
    [history]
  );
  const handleCreate = useCallback(
    (from: 'scratch' | 'import') => {
      switch (from) {
        case 'scratch':
          history.push(webRoute('team.glossary.presets.create'));
          break;
        case 'import':
          fileInputRef.current?.click();
          break;
      }
    },
    [history, fileInputRef]
  );
  const handleClone = useCallback(
    async (presetApiId: PresetApiId) => history.push(webRoute('team.glossary.presets.clone', { presetApiId })),
    [history]
  );

  const columns = useMemo(() => buildColumns(handleExport, handleClone), [handleExport, handleClone]);

  const bulkActions = useCallback(
    ({ useTableProps: { selectedFlatRows, apiTable } }: UseTableProps<PresetSummaryV1>) => {
      const unpublishedPresetApiIds = selectedFlatRows.reduce<Array<PresetApiId>>(
        (acc, row) => (row.original.published || row.original.locked ? acc : [...acc, row.original.api_id]),
        []
      );

      return [
        {
          name: 'Publish',
          key: 'publish',
          tooltip: unpublishedPresetApiIds.length === 0 ? 'No unpublished presets selected' : undefined,
          disabled: unpublishedPresetApiIds.length === 0,
          action: async () => {
            apiTable.setUpdating(true);
            try {
              await publishPresets({ body: { data: unpublishedPresetApiIds } });
              successToast(
                selectedFlatRows.length === 1
                  ? 'Preset published'
                  : `Published ${unpublishedPresetApiIds.length} presets`
              );
              await apiTable.fetchTableData();
            } catch (err) {
              apiTable.setUpdating(true);
              errorToast(
                selectedFlatRows.length === 1
                  ? 'Failed to publish preset'
                  : `Failed to published ${selectedFlatRows.length} presets`
              );
            }
          },
        },
        {
          name: 'Delete',
          key: 'delete',
          className: 'red',
          action: () => {
            openModal('DELETE_PRESETS', {
              onClick: async () => {
                apiTable.setUpdating(true);
                try {
                  await deletePresets({ body: { data: selectedFlatRows.map((s) => s.original.api_id) } });
                  successToast(
                    selectedFlatRows.length > 1 ? 'Preset deleted' : `Deleted ${selectedFlatRows.length} presets`
                  );
                  await apiTable.fetchTableData();
                } catch (err) {
                  apiTable.setUpdating(true);
                  errorToast(
                    selectedFlatRows.length > 1
                      ? 'Failed to delete preset'
                      : `Failed to delete ${selectedFlatRows.length} presets`
                  );
                }
              },
            });
          },
        },
      ];
    },
    [publishPresets, deletePresets, openModal]
  );

  return (
    <>
      <input ref={fileInputRef} type="file" hidden accept=".json,application/json" onChange={handleImport} />
      <div className="flex flex-column mb3">
        <Header mainHeaderText="Presets" />
        <p className="f6 pb2">
          Create and manage study presets.
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="https://help.benchling.com/hc/en-us/articles/22038856066317-Presets"
          >
            Read more
          </a>
        </p>
      </div>
      <APITable
        columns={columns}
        bulkActions={bulkActions}
        defaultSortBy={{ id: 'updated_at', desc: true }}
        noDataMessage="Your presets will appear here."
        AsideComponent={<CreatePresetButton handleCreate={handleCreate} />}
        apiInfo={{
          type: 'internalApi',
          route: RouteService.api({ endpoint: 'GET /api/v1/team/presets', path: undefined }).url,
        }}
      />
    </>
  );
};

const CreatePresetButton: FC<{ handleCreate: (from: 'scratch' | 'import') => void }> = ({ handleCreate }) => {
  const actions = [
    {
      name: 'Add new',
      action: () => handleCreate('scratch'),
    },
    {
      name: 'Import',
      action: () => handleCreate('import'),
    },
  ];
  return (
    <SelectDropDown title="Create preset" testId="create-preset-options--button">
      <ActionList actions={actions} testPrefix="presets__" />
    </SelectDropDown>
  );
};
