// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { successToast } from '@/helpers';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import SampleForm from '../Form';
import WithSampleMetadata from '../Form/withSampleMetadata';

/**
 *
 * @param sample {{
 *  id: number|string,
 *  sample_id: string,
 *  details: array,
 *  comment: string,
 *  date: string }}
 * @param handleAfterClose: (response?: Object) => void
 * @param closeModal:  () => void
 * @constructor
 */

const UpdateSample = ({ sample, handleAfterClose = () => {}, closeModal = () => {} }) => {
  const {
    entity: sampleType,
    entityLoading: fetchingGlossaryItem,
    entityError: glossaryRequestError,
  } = useFetchEntity({
    entityType: 'teamGlossary',
    params: {
      glossaryId: sample?.glossary_id,
    },
  });

  const handleOnClose = () => {
    handleAfterClose();
    closeModal();
  };

  const handleAfterSave = (response) => {
    closeModal();
    handleAfterClose(response);
    successToast('Updated sample');
  };

  const formProps = {
    sample,
    sampleType,
    handleAfterSave,
    allowNaming: true, // When editing, name updates are permitted at any time
    includeDatetimeField: true,
  };

  return (
    <ModalContainer size="narrow" className="bg-white">
      <ModalHeader title="Edit sample" closeModal={handleOnClose} />
      {fetchingGlossaryItem && (
        <div className="pa3">
          <Loading textClassName="f5" />
        </div>
      )}
      {glossaryRequestError && <ApiErrorBanner error={glossaryRequestError} />}
      {sample && sampleType && (
        <WithSampleMetadata>
          <SampleForm {...formProps} />
        </WithSampleMetadata>
      )}
    </ModalContainer>
  );
};

export default UpdateSample;
