import { ChangeEvent, FC, KeyboardEvent, useCallback, useMemo, useRef, useState } from 'react';
import { TagsList } from '@/components/FormBuilder/TagBuilder/TagBuilder.tsx';
import styles from './TagBuilder.module.scss';

interface TagInputProps {
  propTags: string[];
  onNewTag?: (tags: string[]) => void;
  onTagChange?: (tags: string[]) => void;
  allowDuplicates?: boolean;
  maxTags?: number;
}

export const TagInput: FC<TagInputProps> = ({
  propTags = [],
  onNewTag,
  onTagChange,
  allowDuplicates = false,
  maxTags,
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLSpanElement>(null);
  const tags = useMemo(() => propTags, [propTags]);

  const handleNewTag = useCallback(
    (newTags: string[]): void => {
      onNewTag?.(newTags);
      onTagChange?.(newTags);
    },
    [onNewTag, onTagChange]
  );

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value === ',' ? '' : event.target.value;
    setInputValue(value);
  }, []);

  const notDuplicate = useCallback(
    (tagList: string[], newTag: string): boolean => {
      return allowDuplicates || !tagList.includes(newTag);
    },
    [allowDuplicates]
  );

  const addTag = useCallback(
    (tag: string): void => {
      if (notDuplicate(tags, tag) && (!maxTags || tags.length < maxTags)) {
        const newTags = [...tags, tag];
        handleNewTag(newTags);
      }
      setInputValue('');

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
        }
      }, 0);
    },
    [notDuplicate, maxTags, handleNewTag, tags]
  );

  const handleTagDelete = useCallback(
    (index: number): void => {
      const newTags = tags.filter((_, i) => i !== index);
      handleNewTag(newTags);
    },
    [handleNewTag, tags]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      const { key, currentTarget } = e;
      if (key === 'Enter' || key === ',' || key === 'Tab') {
        e.preventDefault();
        const value = currentTarget.value.trim();
        if (value) {
          addTag(value);
        }
      } else if (key === 'Backspace' && !currentTarget.value) {
        const newTags = tags.slice(0, -1);
        handleNewTag(newTags);
      }
    },
    [addTag, handleNewTag, tags]
  );

  return (
    <span className={styles.tagInputWrapper} ref={wrapperRef}>
      <TagsList tags={tags} onTagDelete={handleTagDelete} />
      <input
        ref={inputRef}
        name="tagInput"
        className={styles.tagInput}
        placeholder="Separate tags with a comma"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
    </span>
  );
};

export default TagInput;
