import { approvalStatusToChecklistStatusMap } from '@/components/Studies/Approval/Approval.utils';
import AddButton from '@/components/UI/AddButton';
import CheckList from '@/components/UI/CheckList';
import { CheckListStatus } from '@/components/UI/CheckList/CheckList.utils';
import { _isEmpty } from '@/littledash';
import type { MainUser } from '@/model/User.model';
import { Status, statusToTextMap } from '@/referenceData/study/status';
import { getNumberWithOrdinal } from '@/utils/number';

interface ApprovalStatusProps {
  approvers: Array<MainUser>;
  additionalApprovers: Array<MainUser>;
  ordered: boolean;
  status: CheckListStatus;
  onAddReviewersClick?: () => void;
}

export interface ApprovalStep {
  title: string | JSX.Element;
  status: string;
}

const ApprovalStatus = ({
  approvers,
  additionalApprovers,
  ordered,
  status,
  onAddReviewersClick,
}: ApprovalStatusProps) => {
  const getTitle = (name: string, order: number) =>
    ordered ? (
      <span>
        <span className="dark-gray">{getNumberWithOrdinal(order)}. </span> {name}
      </span>
    ) : (
      name
    );

  const approvalSteps = approvers.map<ApprovalStep>((approver, i) => ({
    title: getTitle(approver.name || approver.email, i + 1),
    status: approvalStatusToChecklistStatusMap[approver.status] || 'idle',
  }));

  const additionalApproversSteps = additionalApprovers?.map<ApprovalStep>((approver, i) => ({
    title: approver.name || approver.email,
    status: approver.status || 'idle',
  }));

  const isActioned = (approval: ApprovalStep) =>
    approval.status === CheckListStatus.success || approval.status === CheckListStatus.fail;

  const actionedApprovalsFirst = (a: ApprovalStep, b: ApprovalStep) => {
    if (isActioned(a) && !isActioned(b)) {
      return -1;
    }
    if (!isActioned(a) && isActioned(b)) {
      return 1;
    }
    return 0;
  };

  const reorderedApprovalSteps = !ordered ? approvalSteps.sort(actionedApprovalsFirst) : approvalSteps;

  const getStatusTextColorClass = (status: string) => {
    const colorClassMap = {
      [Status.rejected]: 'red',
      [Status.approved]: 'dark-green',
    };
    return colorClassMap[status] || 'dark-gray';
  };

  const showAddReviewersButton = Boolean(
    onAddReviewersClick && status !== Status.approved && status !== Status.rejected
  );

  const gutter = 'pa3';
  const requiresFurtherAction = status !== Status.approved && status !== Status.rejected;

  return (
    <div className="ui-card w-100 near-black">
      <div className={gutter}>
        <h2 className="f4 normal lh-title mb2">Review status</h2>
        <div className={`mb4 ${getStatusTextColorClass(status)} f5`}>{statusToTextMap[status]}</div>
        <CheckList steps={reorderedApprovalSteps} />
      </div>
      {((onAddReviewersClick && requiresFurtherAction) || !_isEmpty(additionalApprovers)) && (
        <div className={`${gutter} bt`}>
          {!_isEmpty(additionalApprovers) && (
            <div className={showAddReviewersButton ? 'mb4' : ''}>
              <CheckList steps={additionalApproversSteps} />
            </div>
          )}
          {showAddReviewersButton && <AddButton onClick={onAddReviewersClick}>Add other reviewers</AddButton>}
        </div>
      )}
    </div>
  );
};

export default ApprovalStatus;
