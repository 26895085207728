// @ts-nocheck: converted from JS

const Header = (props) => {
  return (
    <div className="flex flex-row pa3 bg-light-gray br--top br1">
      {props.labels.map(({ width, title }) => (
        <div key={title} className="near-black fw5 mr5" style={{ width: width || 'auto' }}>
          {title}
        </div>
      ))}
    </div>
  );
};

export default Header;
