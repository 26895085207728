import { getCalculationsAndMeasurements } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { ID } from '@/model/Common.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import { useEffect, useState } from 'react';

interface CreateAlertAction {
  type: 'CREATE_ALERTS';
  data: Record<ID, AlertNotificationData>;
}

interface DismissAlertAction {
  type: 'DISMISS_ALERT';
  data: Array<ID>;
}

type Action = CreateAlertAction | DismissAlertAction;

export interface AlertNotificationData {
  alert: AnimalAlertV1;
  calculation: PresetCalculation;
  studyId: number;
  animalName: string;
  cageName: string;
}

/**
 *
 * @param state {Readonly<Record<string,any>>}
 * @param action {{type: string} & Record<string,any>}
 * @returns {Record<string,any>}
 */
const reducer = (
  state: Readonly<Record<ID, AlertNotificationData>>,
  action: Action
): Record<number, AlertNotificationData> => {
  switch (action.type) {
    case 'CREATE_ALERTS': {
      return { ...state, ...action.data };
    }
    case 'DISMISS_ALERT': {
      const updatedState = { ...state };
      action.data.forEach((alertId) => {
        delete updatedState[alertId];
      });
      return updatedState;
    }
    default:
      return state;
  }
};

interface CreateAlertsProps {
  alerts: Array<AnimalAlertV1>;
  animalName: string;
  cageName: string;
  calculations: Array<PresetCalculation>;
  studyId?: ID;
}

interface UpsertAlertProps extends Partial<Omit<CreateAlertsProps, 'alerts' | 'calculations'>> {
  alert: AnimalAlertV1;
  calculation?: PresetCalculation;
}

interface CurrentState {
  alerts: Array<AlertNotificationData>;
  counts: {
    warn: { total: number };
    critical: { total: number };
    total: number;
  };
}

export type AlertUpdateEvent = CustomEvent<Array<AnimalAlertV1>>;
export type AlertStateUpdateEvent = CustomEvent<Readonly<CurrentState>>;
interface AlertServiceEvents {
  'alert-state-update': AlertStateUpdateEvent;
  'alert-dismissed': AlertUpdateEvent;
  'alert-resolved': AlertUpdateEvent;
  'alert-deferred': AlertUpdateEvent;
}

export class AlertService {
  static #eventEmitter = new EventTarget();
  static #state: Readonly<Record<ID, AlertNotificationData>> = Object.freeze({});

  static addEventListener<Type extends keyof AlertServiceEvents>(
    type: Type,
    callback: (event: AlertServiceEvents[Type]) => void,
    options: AddEventListenerOptions
  ) {
    AlertService.#eventEmitter.addEventListener(type, callback as EventListener, options);
  }

  static removeEventListener<Type extends keyof AlertServiceEvents>(
    type: Type,
    callback: (event: AlertServiceEvents[Type]) => void
  ) {
    AlertService.#eventEmitter.removeEventListener(type, callback as EventListener);
  }

  static dispatch(action: Action) {
    AlertService.#state = Object.freeze(reducer(AlertService.#state, action));
    AlertService.#eventEmitter.dispatchEvent(
      new CustomEvent('alert-state-update', { detail: Object.freeze(AlertService.currentState) })
    );
  }

  /**
   * @param alerts {Array<Alert>}
   * @param animalName {string}
   * @param cageName {string}
   * @param calculations {Array<Calculation>}
   */
  static createAlerts({ alerts, animalName, cageName, calculations, studyId }: CreateAlertsProps) {
    const calculationsMap = getCalculationsAndMeasurements(calculations);

    const data = alerts.reduce<Record<ID, AlertNotificationData>>((acc, alert) => {
      return {
        ...acc,
        [alert.id]: {
          alert,
          animalName,
          cageName,
          calculation: calculationsMap.get(alert.calculation),
          studyId,
        } as AlertNotificationData,
      };
    }, {});
    AlertService.dispatch({ type: 'CREATE_ALERTS', data });
  }

  static upsertAlert(...alertData: Array<UpsertAlertProps>) {
    const updatedData = alertData.reduce((acc, data) => {
      const id = data?.alert?.id;
      const previousAlert = AlertService.#state?.[id];
      if (_notNil(data?.alert?.id) && _notNil(previousAlert)) {
        return {
          ...acc,
          [id]: {
            alert: { ...previousAlert.alert, ...(data?.alert ?? {}) },
            animalName: data.animalName ?? previousAlert.animalName,
            cageName: data.cageName ?? previousAlert.cageName,
            calculation: { ...previousAlert.calculation, ...(data?.calculation ?? {}) },
            studyId: data.studyId ?? previousAlert.studyId,
          },
        };
      }
      return acc;
    }, {});
    AlertService.dispatch({ type: 'CREATE_ALERTS', data: updatedData });
  }

  static alertResolved({ alerts, dismiss }: { alerts: Array<AnimalAlertV1>; dismiss: boolean }): void {
    AlertService.#eventEmitter.dispatchEvent(
      new CustomEvent<Array<AnimalAlertV1>>('alert-resolved', { detail: alerts })
    );
    AlertService.upsertAlert(...alerts.map((alert) => ({ alert })));
    if (dismiss) {
      AlertService.dismissAlert(...alerts);
    }
  }

  static alertDeferred({ alerts, dismiss }: { alerts: Array<AnimalAlertV1>; dismiss: boolean }): void {
    AlertService.#eventEmitter.dispatchEvent(
      new CustomEvent<Array<AnimalAlertV1>>('alert-deferred', { detail: alerts })
    );
    if (dismiss) {
      AlertService.dismissAlert(...alerts);
    }
  }

  static dismissAlert(...alert: Array<AnimalAlertV1>): void {
    if (_isNotEmpty(alert)) {
      AlertService.dispatch({ type: 'DISMISS_ALERT', data: alert.map(({ id }) => id) });
      AlertService.#eventEmitter.dispatchEvent(
        new CustomEvent<Array<AnimalAlertV1>>('alert-dismissed', { detail: alert })
      );
    }
  }

  static get currentState(): CurrentState {
    const alerts = Object.values(AlertService.#state);
    const counts = alerts.reduce(
      (acc: CurrentState['counts'], alertData: AlertNotificationData) => {
        switch (alertData?.alert?.notification ?? 'warn') {
          case 'warn': {
            return { ...acc, total: acc.total + 1, warn: { total: acc.warn.total + 1 } };
          }
          case 'critical': {
            return {
              ...acc,
              total: acc.total + 1,
              critical: { total: acc.critical.total + 1 },
            };
          }
          default: {
            return acc;
          }
        }
      },
      {
        warn: { total: 0 },
        critical: { total: 0 },
        total: 0,
      } as CurrentState['counts']
    );
    return { alerts, counts };
  }
}

export const useAlert = () => {
  const [state, setState] = useState<CurrentState>(() => AlertService.currentState);
  const updateHandler = ({ detail }: AlertServiceEvents['alert-state-update']) => setState(detail);
  useEffect(() => {
    AlertService.addEventListener('alert-state-update', updateHandler, { passive: true });
    return () => AlertService.removeEventListener('alert-state-update', updateHandler);
  }, []);
  return state;
};
