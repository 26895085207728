// @ts-nocheck: converted from JS

import { createContext, FC, useContext, useEffect, useState } from 'react';
import './Menu.scss';

const OpenContext = createContext();
const SelectedContext = createContext();
const CollapsedContext = createContext();

interface MenuProps {
  defaultOpen?: any;
  defaultSelected?: any;
  isCollapsed?: any;
  children?: any;
}

export const Menu: FC<MenuProps> = ({ defaultOpen = [], defaultSelected = [], isCollapsed = false, children }) => {
  const openState = useState(defaultOpen);
  const [_, setOpen] = useState(openState);
  const selectedState = useState(defaultSelected);
  const [__, setSelected] = selectedState;

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div className={`menu ${isCollapsed ? '--is-collapsed' : ''}`}>
      <CollapsedContext.Provider value={isCollapsed}>
        <SelectedContext.Provider value={selectedState}>
          <OpenContext.Provider value={openState}>{children}</OpenContext.Provider>
        </SelectedContext.Provider>
      </CollapsedContext.Provider>
    </div>
  );
};

interface MenuItemProps {
  index?: any;
  icon?: any;
  onClick?: any;
  children?: any;
  disabled?: any;
}

export const MenuItem: FC<MenuItemProps> = ({ index, icon, onClick, children, disabled }) => {
  const isCollapsed = useContext(CollapsedContext);
  const [selected, setSelected] = useContext(SelectedContext);
  const isActive = selected.includes(index);
  const handleClick = () => {
    setSelected([index]);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      data-tooltip-id="global-tooltip-id"
      data-tooltip-content={isCollapsed ? children : ''}
      onClick={handleClick}
      className={`menu-item ${disabled ? 'ui__disabled' : ''} ${isActive ? '--is-active' : ''} ${selected.includes(
        index
      )}`}
    >
      {icon && <div className="menu-icon">{icon}</div>}
      <div className="menu-title noselect">{children}</div>
    </div>
  );
};

export const SubMenu = ({ index, icon, title, children, disabled, url }) => {
  const [open, setOpen] = useContext(OpenContext);
  const handleClick = () => {
    if (open.includes(index)) {
      setOpen((open) => open.filter((item) => item !== index));
    } else {
      setOpen((open) => [...open, index]);
    }
  };
  const featureFlaggedRoute =
    (title === 'Admin Console' && (url.includes('admin-console') || url.includes('integration'))) ||
    (title === 'Glossary' && url.includes('presets'));
  const isOpenClass = open.includes(index) || featureFlaggedRoute ? '--is-open' : '';

  return (
    <div className={`menu-sub-menu ${disabled ? 'ui__disabled' : ''}`}>
      <div onClick={handleClick} className={`menu-item menu-sub-menu--title  ${isOpenClass}`}>
        <div className="flex justify-between items-center">
          {icon && <div className="menu-icon">{icon}</div>}
          <div className={`menu-title noselect ${icon ? 'ml3' : ''}`}>{title}</div>
        </div>
        <i className="menu-sub-menu--sub-menu-indicator" />
      </div>
      <div className={`menu-sub-menu--menu ${isOpenClass}`}>{children}</div>
    </div>
  );
};
