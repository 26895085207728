// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import Header from '@/components/UI/Header';
import Loading from '@/components/Loading';
import { getParams, tableColumns } from './Forms.utils';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import Pagination from '@/components/UI/Pagination';
import Button from '@/components/UI/Button';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import Link from '@/components/UI/Link';
import { errorToast, successToast } from '@/helpers';
import { useFetchCollection } from '@/support/Hooks/fetch';

const Forms = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [paging, setPaging] = useState({
    total_pages: 1,
    current_page: 1,
    per_page: 10,
  });
  const [sort, setSort] = useState(['id']);
  const [order, setOrder] = useState('desc');
  const [selectedRows, setSelectedRows] = useState({});
  const [templates, setTemplates] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [apiError, setApiError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { collection: baseStudies } = useFetchCollection({
    collectionType: 'baseStudies',
    queryParams: { perPage: -1 },
  });

  const fetchTemplates = async (params = '') => {
    try {
      const url = `${apiRoute('team.studies.forms.showAll')}?${params}`;
      const {
        data: { data, meta },
      } = await Http.get(url);
      setTemplates(data);
      const { last_page, current_page, per_page } = meta;
      setPaging({
        total_pages: last_page,
        current_page,
        per_page,
      });
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (!templates) {
      setLoading(true);
      fetchTemplates(getParams());
    }
  }, []);

  useEffect(() => {
    if (templates) {
      fetchTemplates(getParams(searchQuery, sort, order, paging));
    }
  }, [searchQuery, paging.per_page, paging.current_page, sort, order]);

  const deleteForms = async (template_ids = []) => {
    try {
      await Http.delete(apiRoute('team.studies.forms.bulkDelete'), {
        data: { template_ids },
      });
      await fetchTemplates(getParams(searchQuery, sort, order, paging));
      successToast(`Deleted ${template_ids.length} form${template_ids.length !== 1 ? 's' : ''}.`);
    } catch (error) {
      setApiError(error);
      errorToast('There was a problem with your submission.');
    }
  };

  const handleDeleteForms = () => {
    const indexes = Object.keys(selectedRows);
    const formIds = indexes.map((key) => templates[key]['id']);
    const studyNames = baseStudies?.reduce((acc, study) => {
      if (formIds.includes(study.study_creation_template_id)) {
        acc.push(study.name);
      }
      return acc;
    }, []);

    dispatch({
      type: 'OPEN_MODAL',
      modal: 'CONFIRM_DELETE_STUDY_FORM',
      props: {
        onClick: () => deleteForms(formIds),
        deleteManyForms: _isNotEmpty(studyNames)
          ? () => (
              <div>
                <span>The following studies can no longer be cloned after deleting these forms:</span>
                <ul className="overflow-scroll mt3 ba b--gray" style={{ maxHeight: '150px' }}>
                  {studyNames.map((name, index) => (
                    <li
                      className="bg-light-gray pv2 pl2 tl ba b--moon-gray lh-title f6 near-black"
                      key={index}
                    >{`${name}`}</li>
                  ))}
                </ul>
              </div>
            )
          : null,
      },
    });
  };

  const bulkActions = [
    {
      name: 'Delete',
      action: handleDeleteForms,
      className: 'red',
    },
  ];

  const searchForms = (query = '') => {
    setSearchQuery(query);
    setPaging({ ...paging, current_page: 1 });
  };

  const onAddTemplate = (res) => {
    history.push(webRoute('team.studies.forms.edit', { id: res.id }));
  };

  const openCreateNewModal = () =>
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'CREATE_STUDY_FORM',
      props: {
        closeModal: () => dispatch({ type: 'CLOSE_MODAL' }),
        onAddTemplate: onAddTemplate,
      },
    });

  const handleSort = ({ sort, order }) => {
    setSort(sort);
    setOrder(order);
  };

  return (
    <div>
      <div>
        <Header mainHeaderText="Study creation forms" />
        <p className="lh-copy f6">
          Customise the study creation form for your team.{' '}
          <Link
            openTab
            className="link blue"
            to="https://help.benchling.com/hc/en-us/articles/20110871844877-Custom-study-creation-forms"
          >
            Read more
          </Link>
        </p>
      </div>
      <div className="mv4 ui-card">
        {apiError && <ApiErrorBanner error={apiError} />}
        {loading ? (
          <div className="pv4">
            <Loading txt="Fetching forms..." />
          </div>
        ) : (
          <>
            <div className="pa3 flex justify-between bb b--moon-gray">
              <div className="w-100 flex">
                <div className="flex mr2">
                  {!_isEmpty(selectedRows) && (
                    <div
                      className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                      style={{
                        whiteSpace: 'nowrap',
                        lineHeight: '2.4rem',
                      }}
                    >
                      {Object.keys(selectedRows).length} selected
                    </div>
                  )}
                  <SelectDropDown
                    title="Bulk actions"
                    className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
                    alignMenu="right"
                    disabled={_isEmpty(selectedRows)}
                  >
                    <ActionList actions={bulkActions} />
                  </SelectDropDown>
                </div>
                <div className="w-100">
                  <SearchFilterBar
                    searchPlaceholderText="Search by form name"
                    handleSearchInput={searchForms}
                    searchQuery={searchQuery}
                  />
                </div>
              </div>
              <Button onClick={openCreateNewModal}>Create new</Button>
            </div>
            {templates && Array.isArray(templates) && (
              <div className={`${updating ? 'ui__disabled' : ''}`}>
                <Table
                  columns={tableColumns}
                  data={templates}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  manualSort={{ sort, order }}
                  setManualSort={handleSort}
                />
                <Pagination {...paging} handleChange={(nextPaging) => setPaging({ ...paging, ...nextPaging })} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Forms;
