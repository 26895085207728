import ApiErrorBanner, { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button/Button';
import Chip from '@/components/UI/Chip/Chip';
import Header from '@/components/UI/Header/Header';
import { _isEmpty, _notNil } from '@/littledash';
import type { StudyCodeComposite, StudyCodeYearCode } from '@/model/StudyCode.model';
import { ApiService } from '@/support/ApiService';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { web as webRoutes } from '@/support/route';
import { modalAction } from '@/utils/modal';
import { type FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const StudyCodeCreate: FC = () => {
  const [title, setTitle] = useState<string>('');
  const [studyCodeComposites, setStudyCodeComposites] = useState<Array<StudyCodeComposite>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const modalActions = modalAction(useDispatch());

  const { response: primitives, loading: loadingPrimitives } = useApiHook({
    endpoint: 'GET /api/v1/team/study-codes/primitives',
  });

  const handleSubmit = async () => {
    setLoading(true);
    const response = await ApiService.call({
      endpoint: 'POST /api/v1/team/study-codes',
      body: {
        title,
        composites: studyCodeComposites.sort((comp_a, comp_b) => comp_a.order - comp_b.order),
      },
    });
    if (response.type === 'success') {
      toast.success('Successfully created study code');
      history.push(webRoutes('team.studies.study_codes'));
    } else {
      toast.error('There was an issue creating study code');
    }
  };

  const handleCancel = () => {
    history.push(webRoutes('team.studies.study_codes'));
  };

  // Validation Generators
  const noTitle = _isEmpty(title);
  const { hasNoYearWithReset, hasSequence } = useMemo(() => {
    const { hasYear, hasResetSequence, hasNoSequence } = studyCodeComposites.reduce(
      (acc, code) => {
        if (code.type == 'sequence') {
          acc.hasNoSequence = false;
          if (code.reset_on_year) {
            acc.hasResetSequence = true;
          }
        } else if (code.type === 'year') {
          acc.hasYear = true;
        }
        return acc;
      },
      { hasYear: false, hasResetSequence: false, hasNoSequence: true }
    );
    return { hasNoYearWithReset: !hasYear && hasResetSequence, hasSequence: !hasNoSequence };
  }, [studyCodeComposites]);

  const componentSelected = (id: number, type?: string) => {
    if (_notNil(type)) {
      switch (type) {
        case 'text':
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'STUDY_CODE_ADD_TEXT',
            props: {
              onClick: (text: string) => {
                setStudyCodeComposites((composites) => [
                  ...composites,
                  { order: composites.length + 1, typeId: id, type, value: text, reset_on_year: false },
                ]);
              },
              ...modalActions,
            },
          });
          break;
        case 'year':
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'STUDY_CODE_ADD_YEAR',
            props: {
              onClick: (yearCode: StudyCodeYearCode) => {
                setStudyCodeComposites((composites) => [
                  ...composites,
                  { order: composites.length + 1, typeId: id, type, value: yearCode, reset_on_year: false },
                ]);
              },
              ...modalActions,
            },
          });
          break;
        case 'sequence':
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'STUDY_CODE_ADD_SEQUENCE',
            props: {
              onClick: (baseCount: string, resetOnYear: boolean) => {
                setStudyCodeComposites((composites) => [
                  ...composites,
                  {
                    order: composites.length + 1,
                    typeId: id,
                    type,
                    value: baseCount,
                    reset_on_year: resetOnYear,
                  },
                ]);
              },
              ...modalActions,
            },
          });
          break;
      }
    }
  };

  return (
    <>
      <div className="mb3">
        <Header mainHeaderText="Create study code" />
        <p className="f6 pb2">
          Set up a study code template for use in your research.
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="https://help.benchling.com/hc/en-us/articles/20162173756813-Study-Codes"
          >
            Read more
          </a>
        </p>
      </div>
      {primitives?.type === 'error' ? (
        <ApiErrorBanner
          className="ma3"
          title="There was a problem fetching components"
          errorType={ApiErrorType.FETCH}
          error={primitives.error}
        />
      ) : !loadingPrimitives && !loading && _notNil(primitives) && _notNil(primitives?.body.data) ? (
        <>
          <div className="ui-card mw7 pa3 mb3">
            <div className="mb2 w-50">
              <label htmlFor="title">Title</label>
              <input
                name="title"
                value={title}
                onChange={({ target: { value } }: { target: { value: string } }) => setTitle(value)}
                type="text"
              />
            </div>
            <div className="mb4">
              <label>Add Component</label>
              {primitives.body.data.map(({ id, type }) => (
                <Button
                  key={`componentButton${id}`}
                  pill
                  onClick={(e) => componentSelected(Number(id), type)}
                  disabled={type === 'sequence' && hasSequence}
                  className="mr2"
                >{`${type?.charAt(0).toUpperCase()}${type?.slice(1)}`}</Button>
              ))}
            </div>
            {studyCodeComposites.length > 0 ? (
              <>
                <div className="mb3 flex">
                  {studyCodeComposites.map((code, index) => (
                    <Chip
                      key={`${code.type}${index}`}
                      title={code.value.toString()}
                      chipId={index}
                      className="mr2"
                      labelTextClass="fw5"
                      colour="gray"
                      onRemove={(chipIndex) =>
                        setStudyCodeComposites((studyCodes) => {
                          if (_notNil(chipIndex)) {
                            studyCodes.splice(chipIndex, 1);
                            for (let i = chipIndex; i < studyCodes.length; i++) {
                              studyCodes[i].order--;
                            }
                          }
                          return [...studyCodes];
                        })
                      }
                    />
                  ))}
                </div>
                <div className="bg-near-white w-50 pa2">
                  Preview: {studyCodeComposites.reduce((acc, code) => acc + code.value, '')}
                </div>
              </>
            ) : null}
          </div>
          <Button
            onClick={handleSubmit}
            // Would have been nice to stack these but HTML is now disabled
            tooltip={
              noTitle
                ? 'Title is required'
                : !hasSequence
                  ? 'Sequence is Required'
                  : hasNoYearWithReset
                    ? 'Year is required with resetting sequence'
                    : ''
            }
            className="mr2"
            disabled={!hasSequence || noTitle || hasNoYearWithReset}
          >
            Save
          </Button>
          <Button onClick={handleCancel} plain>
            Cancel
          </Button>
        </>
      ) : (
        <div className="w-100 vh-100">
          <Loading />
        </div>
      )}
    </>
  );
};

export default StudyCodeCreate;
