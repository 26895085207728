// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import StudyTemplateForm from './StudyTemplateForm';
import Loading from '@/components/Loading';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { useParams } from 'react-router-dom';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { _isEmpty } from '@/littledash';
import SubHeader from '@/components/UI/SubHeader';

const FormEdit = () => {
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [template, setTemplate] = useState(undefined);
  const params = useParams();

  const fetchTemplate = async () => {
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('team.studies.forms.show', { id: params.formId }));
      setTemplate(data);
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const onSaveTemplate = () => {
    setUpdating(true);
    fetchTemplate();
  };

  const onSaveSection = () => {
    setUpdating(true);
    fetchTemplate();
  };

  return (
    <>
      {apiError && <ApiErrorBanner error={apiError} />}
      <div className={`mb4 w-100 h-100 ${updating ? 'ui__disabled' : ''}`}>
        <SubHeader linkToText="Forms" link={webRoute('team.studies.forms')} />
        {loading ? (
          <Loading txt="Fetching template..." />
        ) : (
          !_isEmpty(template) && (
            <StudyTemplateForm template={template} onSaveTemplate={onSaveTemplate} onSaveSection={onSaveSection} />
          )
        )}
      </div>
    </>
  );
};

export default FormEdit;
