// @ts-nocheck: converted from JS

import { formatNumberComingIn } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import type { MetadataFieldTypes } from '@/model/Metadata.model';

interface registerConfigProps {
  required?: ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  min?: ValidationRule<number>;
  max?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
}

export const getRegisterConfig = ({
  required,
  pattern,
  min,
  max,
  maxLength,
}: registerConfigProps): Record<string, Validate> => {
  const registerConfig = {};
  if (required === true) {
    registerConfig.validate = {
      required: (value) => {
        // In study creation this comes back as a string
        // Considered parsing and checking but required more validation than it
        // was worth
        const valueString = `${value ?? ''}`.trim();
        if (valueString === '' || valueString === '[]') {
          return 'This field is required';
        }
      },
    };
  }
  if (pattern) {
    registerConfig.pattern = pattern;
  }
  if (String(min)) {
    registerConfig.min = {
      value: Number(min),
      message: `Minimum of ${String(min)}`,
    };
  }
  if (String(max) && !isNaN(max)) {
    registerConfig.max = {
      value: Number(max),
      message: `Maximum of ${String(max)}`,
    };
  }

  if (_notNil(maxLength)) {
    registerConfig.maxLength = { value: Number(maxLength), message: `Maximum of ${maxLength} characters` };
  }

  return registerConfig;
};

const metadataFieldHasValue = (value) => {
  if (_notNil(value)) {
    if (value instanceof Array) {
      return _isNotEmpty(value);
    }
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    return value !== '';
  }
  return false;
};

export const mapMetadataSubmissions = (submittedMetas, glossaryMetas) => {
  const slugGlossaryInfoMap = new Map(
    (glossaryMetas ?? []).map(({ slug, id, title, field_type }) => [slug, { id, title, field_type }])
  );
  return Object.entries(submittedMetas ?? {}).reduce((acc, [key, value]) => {
    const { id: glossary_id, title, field_type } = slugGlossaryInfoMap.get(key) ?? {};
    if (_notNil(glossary_id) && metadataFieldHasValue(value)) {
      if (typeof value === 'number') {
        value = formatNumberComingIn(value).toString();
      } else if (value === '[]' && (field_type === 'multi_select' || field_type === 'lookup_multi_select')) {
        // Select value is empty so skip
        return acc;
      }
      acc.push({ glossary_id, value, title, field_type });
    }
    return acc;
  }, []);
};

export interface MetadataFieldBuilderReturn {
  label: string;
  name: string;
  options?: Array<string>;
  required: boolean;
  type: MetadataFieldTypes;
  value: string | Array<string>;
  slug: string;
}

export const metadataFieldBuilder = (glossaryMeta: MetadataItem, formValues?: unknown): MetadataFieldBuilderReturn => {
  let value = _notNil(formValues)
    ? formValues.metadata?.find((formMeta) => formMeta.glossary_id === glossaryMeta.id)?.value
    : null;

  if (_isEmpty(value)) {
    value = null;
  }

  return {
    name: `meta.${glossaryMeta.slug}`,
    type: glossaryMeta.field_type,
    label: glossaryMeta.title,
    required: glossaryMeta.required ?? false,
    options:
      glossaryMeta.options?.map((value) => ({
        label: safelyDecodeURIComponent(value),
        value,
      })) ?? [],
    value,
    slug: glossaryMeta.slug,
  };
};
