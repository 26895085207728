// @ts-nocheck: converted from JS

import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import GroupLabel from '@/components/UI/GroupLabel';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { renderMetadataValue } from '@/helpers';
import { _isEmpty, _isNil, _notNil } from '@/littledash';
import { sortAscByProperty } from '@/utils/array';

const cellWidth = 180;

export const initialsColumns = [
  {
    id: 'sampleId',
    Header: 'Sample ID',
    width: cellWidth,
    Cell: ({ row: { original } }) => original.sample_id || '-',
    isVisible: true,
  },
  {
    id: 'subject',
    Header: 'Subject',
    width: cellWidth,
    Cell: ({ row: { original } }) => original?.subject?.name ?? '-',
    isVisible: true,
  },
  {
    id: 'dateTaken',
    Header: 'Date taken',
    width: cellWidth,
    Cell: ({
      row: {
        original: { date },
      },
    }) => <DateTimeRenderer value={date} defaultResponse="-" />,
    isVisible: true,
  },
  {
    id: 'type',
    Header: 'Type',
    width: cellWidth,
    Cell: ({ row: { original } }) => original.type || '-',
    isVisible: true,
  },
  {
    id: 'group',
    Header: 'Group',
    width: 275,
    style: { overflow: 'visible' },
    Cell: ({ row: { original } }) => {
      if (_isNil(original.study_group_id)) {
        return '-';
      }
      return <GroupLabel group={original.study_group} />;
    },
    isVisible: true,
  },
];

const formatMetadata = (id, meta = []) => {
  const metaItem = meta.find((metadata) => metadata.glossary_id === id);
  return _notNil(metaItem) ? renderMetadataValue(metaItem) : '-';
};

const formatMetadataCell = (id, title, type, columns) => {
  const cellWidth = 180;
  return {
    id,
    Header: title,
    sortDisabled: true,
    isVisible: columns?.[id] ?? false,
    width: cellWidth,
    Cell: ({ row: { original } }) => {
      const meta = type === 'group_meta' ? original.study_group?.metadata : original.metadata;
      return _isEmpty(meta) ? '-' : formatMetadata(id, meta);
    },
  };
};

export const buildColumns = (sampleMetdata) => {
  const metadataColumns = [];

  sampleMetdata.forEach(({ id, title, type }) => metadataColumns.push(formatMetadataCell(id, title, type)));

  return [...initialsColumns, ...metadataColumns];
};

export const generateFilterOptions = (data) => {
  if (data) {
    const { users, types } = data;
    types.sort(sortAscByProperty('name'));
    users.sort(sortAscByProperty('name'));

    return [
      {
        value: 'type',
        name: 'Type',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: types,
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: types,
          },
        ],
      },
      {
        value: 'user_id',
        name: 'User',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: users,
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: users,
          },
        ],
      },
    ];
  } else {
    return [];
  }
};
