import ApiErrorBanner from '@/components/ApiErrorBanner';
import Lookup from '@/components/UI/Lookup';
import { _isNil, _isNotEmpty } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import { useRequest } from '@/support/Hooks/request';
import Http from '@/support/http';
import { RouteService } from '@/support/RouteService';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface CageOption {
  label: string;
  value: ID;
}

interface MoveSubjectForm {
  cage: CageOption;
}

interface MoveSubjectProps {
  animal: Animal;
  handleCallback: (text?: string) => Promise<void>;
  closeModal: () => void;
}

export const MoveSubject: FC<MoveSubjectProps> = ({ animal, handleCallback, closeModal }) => {
  const { newAbortController } = useAbortController();
  const { id: studyId } = useParams<{ id: string }>();

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    errors,
  } = useForm<MoveSubjectForm>({
    mode: 'onChange',
  });

  const { sendRequest: updateAnimal, requestError } = useRequest({
    route: 'animals.update.bulk',
    method: 'patch',
  });
  const loadOptions = async ({ inputValue, page }: { inputValue: string; page: string }) => {
    try {
      const {
        data: { data, meta },
      } = await Http.get(
        RouteService.legacyApi({
          apiRoute: 'studies.cages.list',
          path: { studyId },
          query: { page, ...(inputValue?.length > 0 ? { query: inputValue } : {}) },
        }).url.href,
        {
          signal: newAbortController().signal,
        }
      );
      const { current_page: currentPage, last_page: lastPage } = meta;
      const hasMore = currentPage < lastPage;
      const options: Array<CageOption> = data.map((item: { name: string; id: ID }) => ({
        label: item.name,
        value: item.id,
      }));
      return {
        options,
        hasMore,
      };
    } catch (error) {
      if (notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError('Could not load study cages', {
            cause: error,
            slug: 'move-subjects-load-cages',
          })
        );
      }
    }
  };

  const onSubmit = async (form: MoveSubjectForm) => {
    await updateAnimal({
      animals: [{ id: animal.id, collection_id: form.cage.value }],
    });
    handleCallback();
  };

  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Move animal to new cage" closeModal={closeModal} className="pa3 bb b--moon-gray" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pa3">
          {requestError && <ApiErrorBanner error={requestError} />}
          <label>Select a cage to move this animal to</label>
          <Controller
            control={control}
            name="cage"
            rules={{ validate: (cage: any) => (_isNil(cage) ? 'Please select a cage' : true) }}
            render={({ onChange }: ControllerRenderProps) => (
              <Lookup
                placeholder="Search or select..."
                loadOptions={loadOptions}
                handleSelect={onChange}
                disabled={isSubmitting}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="cage"
            render={({ message }) => <p className="f6 red db pt3 pl1">{message}</p>}
          />
        </div>
        <div className="pa3 bt b--moon-gray">
          <ModalActions
            onSubmit={handleSubmit(onSubmit)}
            onCancel={closeModal}
            cancelBtnText="Close"
            submitButtonProps={{ disabled: isSubmitting || _isNotEmpty(errors), loading: isSubmitting }}
          />
        </div>
      </form>
    </ModalContainer>
  );
};
