// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Button from '@/components/UI/Button';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { api as apiRoutes } from '@/support/route';
import { useEffect, useState } from 'react';
import { buildColumns, generateFilterOptions, initialsColumns } from './AttachRequestSamples.utils';

const renderAddSampleActions = (selectedFlatRows, onAdd, closeModal) => {
  const disabled = _isEmpty(selectedFlatRows);
  return (
    <div className="pv3 mt3 bt b--moon-gray" data-test-component="AddSampleActions" data-test-element="container">
      <Button onClick={() => onAdd(selectedFlatRows)} className="mr2" disabled={disabled} testId="Add-Samples-Button">
        Add samples
      </Button>
      <Button plain onClick={() => closeModal()} testId="Cancel-Button">
        Cancel
      </Button>
    </div>
  );
};

const AttachRequestSamples = ({ currentSamples, studyId, closeModal, onAddSample }) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [columns, setColumns] = useState(initialsColumns);

  const { collection: sampleMetadata, collectionError: sampleMetadataError } = useFetchCollection({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: 'sample_meta',
    },
  });

  const {
    collection: sampleFilters,
    collectionLoading: sampleFiltersLoading,
    collectionError: sampleFiltersError,
  } = useFetchCollection({
    collectionType: 'sampleFilters',
  });

  useEffect(() => {
    if (_isNotEmpty(sampleMetadata)) {
      setColumns(buildColumns(sampleMetadata));
    }
  }, [sampleMetadata]);

  useEffect(() => {
    if (!sampleFiltersLoading && _isEmpty(filterOptions)) {
      setFilterOptions(generateFilterOptions(sampleFilters));
    }
  }, [sampleFiltersLoading]);

  const onAdd = (selectedRows) => {
    const selectedSamples = Object.values(selectedRows).map(({ original }) => original);
    onAddSample(selectedSamples);
    closeModal();
  };

  const restrictStudy = {
    type: 'and',
    op: 'eq',
    filter: 'study',
    value: studyId,
  };

  return (
    <div className="center mw8 ui-card" data-test-component="AttachRequestSamples" data-test-element="container">
      <div className="pa3 bg-white bb b--moon-gray">
        <h2 className="normal lh-title f4">Choose samples</h2>
      </div>
      <div className="ph3 bg-near-white">
        {(sampleFiltersError || sampleMetadataError) && (
          <ApiErrorBanner
            errorType={ApiErrorType.FETCH}
            error={sampleFiltersError || sampleMetadataError}
            title={`There was a problem fetching ${sampleFiltersError ? 'filter options' : 'metadata'}`}
          />
        )}
        <div className="pt3" data-test-element="api-table-container">
          <APITable
            apiInfo={{ type: 'legacyInternalApi', route: apiRoutes('samples.index') }}
            includeParam="study_group"
            excludeParam={currentSamples}
            customFilter={restrictStudy}
            columns={columns}
            filterOptions={filterOptions}
            defaultSortBy={{ id: 'sample_id', desc: false }}
            searchPlaceholderText="Search by sample ID"
            renderCustomActions={({ selectedFlatRows }) => renderAddSampleActions(selectedFlatRows, onAdd, closeModal)}
          />
        </div>
      </div>
    </div>
  );
};

export default AttachRequestSamples;
