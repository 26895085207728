import Loading from '@/components/Loading';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Link from '@/components/UI/Link';
import Spinner from '@/components/UI/Spinner';
import StepForm from '@/components/UI/StepForm';
import { Step } from '@/components/UI/StepForm/StepForm.model';
import SubHeader from '@/components/UI/SubHeader';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal, AnimalV1 } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoute } from '@/support/route';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  assembleAnimalsArray,
  assembleExcludedAnimals,
  assembleRandomizationReport,
  initialiseState,
} from './Show.utils';
import Exclusion from './Steps/Exclusion';
import Randomize from './Steps/Randomize';
import { RandomizeAnimal } from './Steps/Randomize/Randomize';
import type { RandomizeState } from './Steps/Randomize/Randomize.model';
import { animalsWithSignificantMeasurement } from './Steps/Randomize/Randomize.utils';
import RandomizeByDate from './Steps/RandomizeByDate';
import { isNotValidResult } from './Steps/RandomizeByDate/RandomizeByDate.utils';
import StudyGroups from './Steps/StudyGroups';
import SuccessTick from '@/components/UI/SuccessTick';

const Show = () => {
  const store = useStore();
  const state = store.getState() as State;
  const { id } = useParams<{ id: string }>();
  const [selectedSubjects, setSelectedSubjects] = useState<Array<Animal>>([]);

  useEffect(() => {
    const subjects = state.subjects?.selectedSubjects;

    setSelectedSubjects(subjects);
  }, [state.subjects?.selectedSubjects]);

  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id },
  });
  const { collection: studyGroups, collectionLoading: studyGroupsLoading } = useFetchCollection<TreatmentGroup>({
    collectionType: 'studyGroups',
    params: { id },
    includes: 'animals_count',
    queryParams: { perPage: -1 },
  });

  return (
    <>
      {studyLoading || studyGroupsLoading || _isNil(study) || _isNil(studyGroups) ? (
        <div className="w-100 h-100">
          <Loading />
        </div>
      ) : (
        <RandomizeScreen study={study} studyGroups={studyGroups} selectedSubjects={selectedSubjects} />
      )}
    </>
  );
};

interface RandomizeScreenProps {
  study: Study;
  studyGroups: Array<TreatmentGroup>;
  selectedSubjects: Array<Animal>;
}

export interface ModalProps extends Omit<RandomizeScreenProps, 'studyGroups'> {
  groups: Array<TreatmentGroup>;
  subject?: Animal[];
  handleCallback?: (res: any) => void;
}

const RandomizeScreen = ({ study, studyGroups, selectedSubjects }: RandomizeScreenProps) => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [randomizedAnimals, setRandomizedAnimals] = useState<AnimalV1[]>();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const features = useSelector((state: State) => state.team.features);

  const { invoke } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyId}/randomizations',
    path: { studyId: id },
    body: {},
    invokeOnInit: false,
  });

  const props: ModalProps = {
    groups: studyGroups,
    study,
    selectedSubjects,
  };

  const openModal = (modal: string, props: Partial<ModalProps>) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const steps: Array<Step<RandomizeState, ModalProps>> = [
    {
      name: 'Animal selection',
      isValid: (state) => {
        const {
          randomizeByDate: { validStudyGroups, measurementsOnDate, validAnimals },
        } = state;
        if (isNotValidResult(validStudyGroups)) {
          return validStudyGroups;
        }
        if (_isEmpty(validAnimals.includedAnimals)) {
          return 'You must have at least one animal to continue';
        }
        if (_notNil(measurementsOnDate) && _isEmpty(measurementsOnDate)) {
          return 'No measurements found on this date';
        }
        return true;
      },
      renderStep: (state, dispatch, props) => <RandomizeByDate state={state} dispatch={dispatch} props={props} />,
    },
    {
      name: 'Study groups',
      isValid: (state) => {
        const { treatmentGroups } = state;
        const { remainingAnimals, totalSpace } = treatmentGroups;
        if (study.settings?.id === null) {
          return 'You must have a study preset defined for randomization';
        }
        if (remainingAnimals < 0) {
          return 'You have allocated more spaces than animals';
        }
        if (totalSpace <= 0) {
          return 'You must select at least one subject to continue';
        }
        return true;
      },
      renderStep: (state, dispatch, props) => <StudyGroups state={state} dispatch={dispatch} props={props} />,
    },
    {
      name: 'Exclusion',
      isValid: (state) => {
        if (state.exclusionCriteria.includedSubjects <= 0) {
          return 'You must select at least one subject to continue';
        }
        if (state.treatmentGroups.totalSpace < state.exclusionCriteria.includedSubjects) {
          return 'You have selected more subjects than spaces in your study groups';
        }
        return true;
      },
      renderStep: (state, dispatch) => <Exclusion state={state} dispatch={dispatch} />,
    },
    {
      name: 'Randomize',
      isValid: (state) => _isNotEmpty(state.randomize.results),
      renderStep: (state, dispatch, props) => (
        <Randomize state={state} dispatch={dispatch} props={props} openModal={openModal} closeModal={closeModal} />
      ),
    },
  ];

  const initialState = useMemo(() => initialiseState(steps, {}, props, features), [steps]);

  const handleSubmit = (state: RandomizeState) => {
    const animalLookup = animalsWithSignificantMeasurement(
      selectedSubjects,
      state.randomizeByDate.measurementsOnDate
    ).reduce<Record<ID, RandomizeAnimal>>((acc, animal: RandomizeAnimal) => {
      acc[animal.id] = animal;
      return acc;
    }, {});

    const selectedMetrics = state.randomize.selectedMetrics.reduce<Array<string>>((acc, { accessor }) => {
      if (_notNil(accessor)) {
        const measurementName = accessor.split('.').at(-2);
        if (_notNil(measurementName)) {
          acc.push(measurementName);
        }
      }
      return acc;
    }, []);

    const validMeasurements = new Set<string>(
      selectedMetrics.concat(state.exclusionCriteria.criteriaOptions[state.exclusionCriteria.criteriaIndex]?.value)
    );

    const body = {
      animals: assembleAnimalsArray(animalLookup, state.randomize.results, validMeasurements),
      excluded_animals: assembleExcludedAnimals(validMeasurements, state.exclusionCriteria?.subjectsExcluded),
      randomization_report: assembleRandomizationReport(state, selectedMetrics),
    };

    setSubmitted(true);
    setLoading(true);

    invoke({ path: { studyId: study.api_id }, body })
      .then((res) => {
        setSuccess(true);
        setRandomizedAnimals(res.body?.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  };

  const handleRedirectRecaging = () => {
    dispatch({ type: 'SET_SELECTED_SUBJECTS', selectedSubjects: randomizedAnimals });
    history.push(webRoute('studies.recaging', { studyId: study.id }));
  };

  return (
    <div className="pb5-l" data-testid="randomization-container">
      <SubHeader linkToText="Animals" link={webRoute('studies.animals', { id: study.id })} />
      <div className="pa4">
        {_isNotEmpty(props.selectedSubjects) ? (
          <>
            {!submitted ? (
              <StepForm
                title="Randomization"
                initialState={initialState}
                props={props}
                steps={steps}
                finalStepAction={handleSubmit}
                horizontal
              />
            ) : (
              <div className="ui-card pa3 center mw6 mv4">
                {loading && (
                  <div className="tc bg-near-white br3">
                    <div className="pa3 dib tc">
                      <Spinner size="large" color="blue" className="dib" />
                      <h3 className="lh-title f4 basier-reg normal pv3">Randomizing animals...</h3>
                    </div>
                  </div>
                )}
                {error && (
                  <Banner critical dismiss={false}>
                    <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
                    <p className="f6 pb3 lh-copy">
                      An error has occurred while submitting your request, please try again later. If this keeps
                      occurring contact support.
                    </p>
                    <Button outline critical url={'mailto:support@benchling.com'}>
                      Contact support
                    </Button>
                  </Banner>
                )}
                {success && (
                  <div className="tc mt2 mb2 ml3 mr3 br3">
                    <SuccessTick className="mr2 mt3 mb3 center" />
                    <h3 className="lh-title f4 basier-reg normal pv3 mb3">
                      Animals have been randomized successfully!
                    </h3>
                    <div className="tc flex flex-row mr3 ml items-center">
                      <Button className="mr2 ml3 f5" onClick={handleRedirectRecaging}>
                        Proceed to recaging
                      </Button>
                      <Link className="dib blue link f5 center" to={webRoute('studies.animals', { id: study.id })}>
                        Return to study
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="ui-card pa3 center mw6 mv4">
            <div className="pa5 br3 bg-near-white">
              <div className="tc">
                <h3 className="lh-title f4 basier-reg normal pv3">No animals have been selected</h3>
                <Link className="dib blue mb3 link f4" to={webRoute('studies.animals', { id: study.id })}>
                  Select animals for randomization
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Show;
