import RequestsTable from '@/components/Requests/RequestsTable';

const PendingRequests: React.FC = () => {
  const statusFilter = {
    type: 'and',
    op: 'ne',
    filter: 'status',
    value: 'complete',
  };
  return (
    <RequestsTable
      statusFilter={statusFilter}
      noDataTitle={'You have no pending requests'}
      noDataText={'Any requests requiring your attention will appear here.'}
    />
  );
};

export default PendingRequests;
