import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model';
import { Cage } from '@/model/Cage.model';
import { ID } from '@/model/Common.model';
import { featuresSelector } from '@/support/Selectors';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

interface UndoEuthaniseProps {
  subjects: Array<Animal>;
  handleCallback: (reasonForChange: string) => void;
}

const featureSelector = createSelector([featuresSelector], (features) => ({
  reasonForChange: features?.reason_for_change ?? false,
}));

const UndoEuthanise: FC<UndoEuthaniseProps> = ({ subjects, handleCallback }) => {
  const { reasonForChange } = useSelector(featureSelector);

  const maxSubjects = 10;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const { id } = useParams<{ id: string }>();
  const {
    collection: collections,
    collectionLoading,
    collectionError,
  } = useFetchCollection<Cage>({
    collectionType: 'studyCages',
    params: { studyId: id },
    includes: 'animals_count',
    queryParams: {
      perPage: -1,
    },
  });

  let what, subjectsToMove;
  if (subjects.length > 1) {
    subjectsToMove = subjects.length;
    what = `these ${subjectsToMove} animals`;
  } else {
    what = 'this animal';
    subjectsToMove = 1;
  }

  const bulkUndo = (subjects: Array<Animal>, newCollectionId: ID, reasonForChange?: string) => {
    const data = {
      subjects: subjects.map(({ id }) => ({
        id,
        collection_id: newCollectionId,
      })),
      ...(_notNil(reasonForChange) ? { reason_for_change: reasonForChange } : {}),
    };

    return Http.post(apiRoute('animals.undo-terminate'), data);
  };

  const submitUnmarkAsDeceased = async (newCollectionId: ID, reasonForChange?: string) => {
    setLoading(true);
    return bulkUndo(subjects, newCollectionId, reasonForChange)
      .then(() => {
        handleCallback('Successfully moved and unmarked as deceased');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const onClick = (newCollectionId: ID) => {
    if (reasonForChange) {
      openModal('BULK_REASON_FOR_CHANGE', {
        title: 'Unmark as deceased',
        onClick: (reason_for_change: string) => submitUnmarkAsDeceased(newCollectionId, reason_for_change),
        closeModal,
      });
    } else {
      submitUnmarkAsDeceased(newCollectionId);
    }
  };

  return collectionLoading ? (
    <div className="w-100 h-100">
      <Loading />
    </div>
  ) : (
    <form className={`ui__narrow-modal ${loading && 'disabled-form'}`}>
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">Undo euthanisation for {what}?</h3>
        <p>Please select a cage - each cage can hold up to {maxSubjects} animals</p>
      </div>
      {collectionError && <ApiErrorBanner errorType={ApiErrorType.FETCH} error={collectionError} />}
      {error && <ApiErrorBanner error={error} />}
      <ul className="ui-card-list touch overflow-y-scroll group-list">
        {collections?.map((collection) => {
          const isCurrentCollection = !Array.isArray(subjects);
          return (collection.animals_count as number) + subjectsToMove > maxSubjects ? (
            <FullCollection subjectCount={collection.animals_count ?? 0} key={collection.id} name={collection.name} />
          ) : (
            <AvailableCollection
              collection={collection}
              isCurrentCollection={isCurrentCollection}
              subjectCount={collection.animals_count ?? 0}
              key={collection.id}
              onClick={onClick}
            />
          );
        })}
      </ul>
      <div className="pt2 bt b--silver">
        <a className={`button plain db w-100 mt2 ${loading && 'disabled-link'}`} onClick={() => closeModal()}>
          Cancel
        </a>
      </div>
    </form>
  );
};

const FullCollection: FC<{ name: string; subjectCount: number }> = ({ name, subjectCount }) => (
  <li>
    <a className="disabled-link">
      <div className="flex justify-between">
        <span>{name}</span>
        <span className="red">{subjectCount}</span>
      </div>
    </a>
  </li>
);

const AvailableCollection: FC<{
  collection: Cage;
  isCurrentCollection: boolean;
  subjectCount: number;
  onClick: (newCollectionId: ID) => void;
}> = ({ collection, isCurrentCollection, subjectCount, onClick }) => (
  <li>
    <a onClick={() => onClick(collection.id)} className={`${isCurrentCollection && 'disabled-link active-box'}`}>
      <div className="flex justify-between">
        <span>{collection.name}</span>
        <span className="mid-gray">{subjectCount}</span>
      </div>
    </a>
  </li>
);

export default UndoEuthanise;
