import { State } from '@/model/State.model';
import collectionsReducer from '@/reducers/collections';
import samplesReducer from '@/reducers/samples';
import studiesReducer from '@/reducers/studies';
import subjectsReducer from '@/reducers/subjects';
import tasksReducer from '@/reducers/tasks';
import teamReducer from '@/reducers/team';
import uiReducer from '@/reducers/ui';
import userReducer from '@/reducers/user';
import { configureStore } from '@reduxjs/toolkit';
import { Store } from 'redux';

const store: Store<State> = configureStore({
  reducer: {
    ui: uiReducer,
    team: teamReducer,
    user: userReducer,
    studies: studiesReducer,
    // @ts-expect-error: type mismatch
    samples: samplesReducer,
    subjects: subjectsReducer,
    collections: collectionsReducer,
    tasks: tasksReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false }),
});
export default store;
