export const samplesConfig = [
  {
    name: 'weight',
    title: 'Weight',
    units: ['mg', 'g', 'µg'],
  },
  {
    name: 'volume',
    title: 'Volume',
    units: ['ml', 'µl', 'cm³', 'mm³'],
  },
  {
    name: 'width',
    title: 'Width',
    units: ['mm'],
  },
  {
    name: 'length',
    title: 'Length',
    units: ['mm'],
  },
  {
    name: 'depth',
    title: 'Depth',
    units: ['mm'],
  },
];

interface SampleEntry {
  title: string;
  units: Array<string>;
}

export const samplesConfigObj = samplesConfig.reduce<Record<string, SampleEntry>>((acc, { name, title, units }) => {
  acc[name] = { title, units };
  return acc;
}, {});

export default samplesConfig;
