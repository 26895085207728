import { CSSProperties, FC } from 'react';
import { RiCloseLine } from 'react-icons/ri';

interface RemoveButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
  style?: CSSProperties;
  testId?: string;
}

const RemoveButton: FC<RemoveButtonProps> = ({ onClick, isDisabled = false, className = '', style = {}, testId }) => {
  return (
    <a
      onClick={onClick}
      style={style}
      className={`w2 h2 br-100 bg-silver pointer flex hover-near-black ${className} ${isDisabled ? 'ui__disabled' : ''}`}
      data-testid={testId}
    >
      <div className="flex justify-center items-center w-100 h-100">
        <RiCloseLine size={20} />
      </div>
    </a>
  );
};

export default RemoveButton;
