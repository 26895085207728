import Button from '@/components/UI/Button';
import { ADDITIONAL_ENTRY_KEYS } from '@/components/UI/TagInput/TagInput.tsx';
import { altIds } from '@/constants/utils';
import { preventNumberScroll } from '@/helpers.tsx';
import { Animal } from '@/model/Animal.model';
import { State } from '@/model/State.model';
import { ErrorMessage } from '@hookform/error-message';
import { CellChange } from 'handsontable/common';
import { FC } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';
// @ts-expect-error untyped library
import TagsInput from 'react-tagsinput';
import {
  FormFields,
  GenerateType,
  nameAnimalsInSequence,
  repeatIdentifiersAcrossCages,
} from './BulkGenerateForm.utils';

const BulkGenerateForm = ({
  data,
  setData,
}: {
  data: Array<Animal>;
  setData: (dataChanges: Array<CellChange>) => void;
}) => {
  const methods = useForm<FormFields>({
    defaultValues: {
      name: 'name',
      type: GenerateType.REPEATING,
      repeating_ids: [],
      sequence_prefix: '',
      sequence_starting: '',
    },
  });

  const onSubmitForm: SubmitHandler<FormFields> = (fields) => {
    if (fields.type === GenerateType.REPEATING) {
      setData(repeatIdentifiersAcrossCages(data, fields.name, fields.repeating_ids));
    } else if (fields.type === GenerateType.SEQUENCE) {
      setData(nameAnimalsInSequence(data, fields.name, fields.sequence_prefix, fields.sequence_starting));
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <BulkGenerate altIdOptions={GetAltIdOptions()} onSubmit={onSubmitForm} />
      </FormProvider>
    </>
  );
};

const BulkGenerate: FC<{
  altIdOptions: Array<{ title: string; key: string }>;
  onSubmit: SubmitHandler<FormFields>;
}> = ({ altIdOptions, onSubmit }) => {
  const { handleSubmit, register, control } = useFormContext<FormFields>();

  const watch = useWatch({
    control,
    name: 'type',
  });

  return (
    <form className="br2 ba b--moon-gray flex flex-wrap mb3 overflow-hidden" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-50 br b--moon-gray pa3 flex justify-between items-center">
        <label className="mb0">Bulk assign IDs for:</label>
        <div className="flex">
          <select {...register('name')} className="w4 mr3 mb0" data-testid="id-field-select">
            {altIdOptions.map(({ title, key }) => (
              <option value={key} key={key}>
                {title}
              </option>
            ))}
          </select>
          <select {...register('type')} className="w4 mb0" data-testid="idGeneratorButton">
            <option value="repeating">Repeating</option>
            <option value="sequence">Sequence</option>
          </select>
        </div>
      </div>
      <div className="w-50 bg-near-white pa3 flex justify-between items-center" data-testid="identifier-tag-input">
        {watch === GenerateType.REPEATING ? <RepeatIdentifiers /> : <PrefixAndSequence />}
        <Button submit stateless className="blue pr0" testId="bulkAssignButton">
          Bulk assign
        </Button>
      </div>
    </form>
  );
};

export const RepeatIdentifiers = () => {
  const { control } = useFormContext<FormFields>();
  return (
    <Controller
      control={control}
      name="repeating_ids"
      defaultValue={[]}
      render={({ field }) => (
        <TagsInput
          className="ui-tags w-100 mr3 mb0"
          value={field.value}
          onChange={field.onChange}
          onlyUnique
          addKeys={ADDITIONAL_ENTRY_KEYS}
          maxTags="10"
          inputProps={{
            placeholder: 'Separate tags with a comma',
          }}
          validate={(tag: string) => tag.length > 0}
        />
      )}
    />
  );
};

export const PrefixAndSequence = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormFields>();
  return (
    <div className="flex">
      <input
        type="text"
        {...register('sequence_prefix')}
        placeholder="ABC"
        className="w4 mr3"
        style={{ marginBottom: 0 }}
      />
      <input
        type="number"
        onWheel={preventNumberScroll}
        {...register('sequence_starting', {
          valueAsNumber: false,
          min: { value: 0, message: 'Number must be greater than or equal to 0' },
        })}
        step="1"
        placeholder="123"
        className="w4 mr3"
        style={{ marginBottom: 0 }}
      />
      <ErrorMessage
        errors={errors}
        name="sequence_starting"
        render={({ message }) => (
          <small data-testid="sequence_starting-error-message" className="red db pt2">
            {message}
          </small>
        )}
      />
    </div>
  );
};

const GetAltIdOptions = (): Array<{ title: string; key: string }> => {
  const state = useSelector((state: State) => state?.team);

  let options = altIds;
  if (state?.features?.assign_identifiers_tag_unique === true) {
    options = options.filter(({ key }) => key !== 'alt_ids.tag');
  }
  return options;
};

export default BulkGenerateForm;
