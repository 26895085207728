// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _isNil, _notNil } from '@/littledash';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import { useEffect, useRef, useState } from 'react';
import SampleForm from '../Form/Form';
import WithSample from '../Form/withSample';

/**
 *
 * @param {*} sampleTypes
 * @returns {Array}
 */

const initialSampleTypesToCreate = (sampleTypes = []) => {
  return Array.from({ length: sampleTypes?.length ?? 0 });
};

/**
 *
 * @param animalId {string|number}
 * @param collectedAt {date} the date the sample was collected on, the current time will be appended on create
 * @param sampleTypes {Array<SampleType>} The sample types to collect eg. Aorta, Blood.. etc
 * @param allowNaming {boolean} Either leave naming up to the server, or allow users to pass their own
 * @param handleAfterClose {function} a function run when the user exits or leaves the modal
 * @param closeModal {function} default prop for modals, closes the modal
 * @returns {JSX.Element|null}
 * @constructor
 */

const CreateSamples = ({
  animalId,
  sampleTypes = [],
  collectedAt,
  allowNaming = false,
  handleAfterClose = () => {},
  closeModal,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [sampleIDsCreated, setSampleIDsCreated] = useState(initialSampleTypesToCreate(sampleTypes)); // Sample IDs created in this "session" of the modal window being open
  const formContainerRef = useRef();

  const {
    collection: sampleMetadataGlossary,
    collectionLoading: fetchingSampleMetadataFields,
    collectionError: requestError,
  } = useFetchCollection({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: 'sample_meta',
    },
  });

  useEffect(() => {
    const formContainer = formContainerRef.current;
    if (_notNil(formContainer)) {
      formContainer.scrollIntoView({
        behavior: 'smooth',
        alignToTop: true,
      });
    }
  }, [tabIndex]);

  /**
   * Used to close the modal and run an after close fn
   * @param sampleResponse {{
   *  id: number|string,
   *  sample_id: string,
   *  details: array,
   *  comment: string,
   *  date: string }}
   * @returns {void}
   */

  const handleOnClose = (sampleResponse = {}) => {
    closeModal();
    handleAfterClose(sampleResponse);
  };

  /**
   *
   * @param sampleResponse {{
   *  id: number|string,
   *  sample_id: string,
   *  details: array,
   *  comment: string,
   *  date: string }}
   * @returns {void}
   */

  const handleAfterSave = (sampleResponse) => {
    if (tabIndex + 1 < sampleTypes.length) {
      setTabIndex(tabIndex + 1);
    } else {
      handleOnClose(sampleResponse);
    }
    const updateSampleIdsCreated = [...sampleIDsCreated];
    updateSampleIdsCreated[tabIndex] = sampleResponse?.data?.data?.id;
    setSampleIDsCreated(updateSampleIdsCreated);
  };

  const saveButtonText = `Save and ${tabIndex + 1 < sampleTypes.length ? 'next' : 'close'}`;
  const sampleFormProps = {
    animalId,
    allowNaming,
    collectedAt,
    sampleMetadataGlossary,
    handleAfterSave,
    saveButtonText,
    formContainerRef,
  };

  return (
    <ModalContainer size="medium" className="bg-white h-100">
      <ModalHeader title="Collect samples" closeModal={handleOnClose} />
      <div
        className="relative"
        style={{ height: 600 }}
        data-test-component="CreateSamples"
        data-test-element="modal-body"
      >
        {requestError && <ApiErrorBanner error={requestError} />}
        {fetchingSampleMetadataFields ? (
          <div className="absolute w-100 h-100 percentCenter pa3">
            <Loading textClassName="f5" />
          </div>
        ) : (
          <Tabs outerState={[tabIndex, setTabIndex]}>
            <div className="flex justify-between h-100">
              <div className="w-third pv3 h-100 overflow-auto" data-test-element="sidebar">
                {sampleTypes.map(({ id, title }, index) => (
                  <Tab
                    plain
                    className="lh-title pointer w-100 ph3 pv2"
                    key={`tab_${id}`}
                    testId={`tab_${id}`}
                    disableNavigation={_isNil(sampleIDsCreated[index])}
                  >
                    {title}
                  </Tab>
                ))}
              </div>
              <div className="w-two-thirds h-100 overflow-auto" data-test-element="main-content">
                {sampleTypes.map((sampleType, index) => (
                  <Panel key={`panel_${sampleType.id}`} className="h-100">
                    {_notNil(sampleIDsCreated[index]) ? (
                      <WithSample animalId={animalId} sampleId={sampleIDsCreated[index]}>
                        <SampleForm {...sampleFormProps} sampleType={sampleType} includeDatetimeField />
                      </WithSample>
                    ) : (
                      <SampleForm {...sampleFormProps} sampleType={sampleType} />
                    )}
                  </Panel>
                ))}
              </div>
            </div>
          </Tabs>
        )}
      </div>
    </ModalContainer>
  );
};

export default CreateSamples;
