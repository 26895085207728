import { _isNil } from '@/littledash';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { RiArrowDropDownFill, RiArrowDropLeftFill, RiArrowDropRightFill, RiArrowDropUpFill } from 'react-icons/ri';
import { Direction, getTrackBackground, Range } from 'react-range';
import type { IThumbProps } from 'react-range/lib/types';
import { usePrevious } from '@/support/Hooks';
import type { DataTableScrollService } from '../DataTable.model';
import { Axis } from '../DataTable.model';
import styles from './DataTableScrollBars.module.scss';

interface DataTableScrollBarsProps {
  dataTableScrollService: DataTableScrollService | null;
}

interface ScrollBarProps {
  min?: number;
  max: number;
  step?: number;
  value: number;
  thumbSize: number;
  onDrag: (isDragged: boolean) => void;
  onChange: (value: Array<number>) => void;
}

interface ScrollBarThumbProps {
  thumbProps: IThumbProps;
  isDragged: boolean;
  onDrag: ScrollBarProps['onDrag'];
}

const ARROW_ICON_SIZE = 16;
const MIN_THUMB_SIZE = 32;
const TITLE_AND_MENU = 114;

const DataTableScrollBars: FC<DataTableScrollBarsProps> = ({ dataTableScrollService }) => {
  if (_isNil(dataTableScrollService)) {
    return null;
  }

  return (
    <>
      {dataTableScrollService.xAxisScrollingEnabled && (
        <div className={styles['data-table-scroll-bars-horizontal']}>
          <div className={styles['data-table-scroll-bars-horizontal-container']}>
            <div className={styles['data-table-scroll-bars-track-container']}>
              <HorizontalScrollBar
                min={0}
                max={dataTableScrollService.totalBlocks.x - dataTableScrollService.viewportBlocks.x}
                value={dataTableScrollService.scrollBlocks.x.startIndex}
                onDrag={(isDragged) => dataTableScrollService?.onDragScrollBar(isDragged, Axis.x)}
                onChange={([value]) => {
                  dataTableScrollService?.moveDataTableToBlock(Axis.x, value);
                }}
                thumbSize={Math.max(
                  dataTableScrollService.windowWidth *
                    (dataTableScrollService.viewportBlocks.x / dataTableScrollService.totalBlocks.x),
                  MIN_THUMB_SIZE
                )}
              />
            </div>
            <div className={styles['data-table-scroll-navigation-container']}>
              <div
                className={styles['data-table-scroll-navigation-left']}
                onClick={() => dataTableScrollService?.moveDataTableWindow('LEFT', 1)}
              >
                <RiArrowDropLeftFill size={ARROW_ICON_SIZE} />
              </div>
              <div
                className={styles['data-table-scroll-navigation-right']}
                onClick={() => dataTableScrollService?.moveDataTableWindow('RIGHT', 1)}
              >
                <RiArrowDropRightFill size={ARROW_ICON_SIZE} />
              </div>
            </div>
          </div>
        </div>
      )}
      {dataTableScrollService.yAxisScrollingEnabled && (
        <div className={styles['data-table-scroll-bars-vertical']}>
          <div className={styles['data-table-scroll-bars-vertical-container']}>
            <div className={styles['data-table-scroll-bars-track-container']}>
              <VerticalScrollBar
                min={0}
                max={dataTableScrollService.totalBlocks.y - dataTableScrollService.viewportBlocks.y}
                value={dataTableScrollService.scrollBlocks.y.startIndex}
                onDrag={(isDragged) => dataTableScrollService?.onDragScrollBar(isDragged, Axis.y)}
                onChange={([value]) => {
                  dataTableScrollService?.moveDataTableToBlock(Axis.y, value);
                }}
                thumbSize={Math.max(
                  (dataTableScrollService?.windowHeight - TITLE_AND_MENU) *
                    (dataTableScrollService?.viewportBlocks.y / dataTableScrollService?.totalBlocks.y),
                  MIN_THUMB_SIZE
                )}
              />
            </div>
            <div className={styles['data-table-scroll-navigation-container']}>
              <div
                className={styles['data-table-scroll-navigation-up']}
                onClick={() => dataTableScrollService?.moveDataTableWindow('UP', 1)}
              >
                <RiArrowDropUpFill size={ARROW_ICON_SIZE} />
              </div>
              <div
                className={styles['data-table-scroll-navigation-down']}
                onClick={() => dataTableScrollService?.moveDataTableWindow('DOWN', 1)}
              >
                <RiArrowDropDownFill size={ARROW_ICON_SIZE} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const HorizontalScrollBar: FC<ScrollBarProps> = ({
  min = 0,
  max,
  step = 1,
  value: incomingValue,
  onChange,
  onDrag,
  thumbSize,
}) => {
  const value = useMemo(() => Math.min(max, incomingValue), [max, incomingValue]);
  return (
    <div
      style={{
        height: '100%',
        margin: `0 ${thumbSize / 2}px`,
      }}
    >
      <Range
        direction={Direction.Right}
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '12px',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '12px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: [value],
                  colors: ['#fff', '#fff'],
                  min,
                  max,
                  direction: Direction.Right,
                  rtl: false,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props: thumbProps, isDragged }) => (
          <ScrollBarThumb
            thumbProps={{ ...thumbProps, style: { ...thumbProps.style, width: `${thumbSize}px`, height: '8px' } }}
            isDragged={isDragged}
            onDrag={onDrag}
          />
        )}
      />
    </div>
  );
};

const VerticalScrollBar: FC<ScrollBarProps> = ({
  min = 0,
  max,
  step = 1,
  value: incomingValue,
  onChange,
  onDrag,
  thumbSize,
}) => {
  const value = useMemo(() => Math.min(max, incomingValue), [max, incomingValue]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: `${thumbSize / 2}px 0`,
      }}
    >
      <Range
        direction={Direction.Down}
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '100%',
              width: '12px',
            }}
          >
            <div
              ref={props.ref}
              style={{
                width: '12px',
                height: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: [value],
                  colors: ['#fff', '#fff'],
                  min,
                  max,
                  direction: Direction.Down,
                  rtl: false,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props: thumbProps, isDragged }) => (
          <ScrollBarThumb
            thumbProps={{ ...thumbProps, style: { ...thumbProps.style, height: `${thumbSize}px`, width: '8px' } }}
            isDragged={isDragged}
            onDrag={onDrag}
          />
        )}
      />
    </div>
  );
};

const ScrollBarThumb: FC<ScrollBarThumbProps> = ({ thumbProps, isDragged, onDrag }) => {
  const prevDragState = usePrevious(isDragged);
  useEffect(() => {
    if (isDragged !== prevDragState) {
      onDrag(isDragged);
    }
  }, [isDragged, prevDragState, onDrag]);

  return (
    <div
      {...thumbProps}
      style={{
        ...thumbProps.style,
        borderRadius: '4px',
        backgroundColor: '#637381',
        outline: 'none',
      }}
    >
      <div />
    </div>
  );
};
export default DataTableScrollBars;
