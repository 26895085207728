// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import StepForm from '@/components/UI/StepForm';
import SubHeader from '@/components/UI/SubHeader';
import { successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { createMeta } from '@/support/hot';
import Http from '@/support/http';
import { api as apiRoutes, web as webRoute } from '@/support/route';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseQueryString } from '@/helpers';
import { initAnimalSettings, initCageSettings } from '../Colony.utils';
import Animals from './Animals';
import Cages from './Cages';
import Summary from './Summary';
import './../Colony.scss';

const ColonyCreation = () => {
  const location = useLocation();
  const { study: studyId } = parseQueryString(location.search);
  const history = useHistory();

  const ColonyCreationCages = _notNil(studyId) ? withStudy(Cages, studyId) : Cages;

  const steps = [
    {
      name: 'Cages',
      isValid: ({ cages: { cellErrors } }) => {
        const cellErrorValues = Object.values(cellErrors);
        if (_isNotEmpty(cellErrorValues) || loading) {
          return cellErrorValues[cellErrorValues.length - 1];
        }
        return true;
      },
      renderStep: (state, dispatch) => <ColonyCreationCages state={state} dispatch={dispatch} />,
    },
    {
      name: 'Add animals',
      isValid: ({ animals: { cellErrors } }) => {
        if (_isNotEmpty(Object.values(cellErrors))) {
          return Object.values(cellErrors).join('</br>');
        }
        return true;
      },
      renderStep: (state, dispatch) => <Animals state={state} dispatch={dispatch} />,
    },
    {
      name: 'Summary',
      isValid: (state) => true,
      renderStep: (state, dispatch) => <Summary state={state} dispatch={dispatch} />,
    },
  ];
  const initialState = {
    steps,
    currentStep: 0,
    complete: false,
    stepReady: false,
    cages: {
      initialData: [],
      data: [],
      settings: initCageSettings(false, true).settings,
      metadata: [],
      metaGlossaries: [],
      cellErrors: { 'no-cage': 'You must supply at least 1 cage' },
      overlay: '',
      invalidCells: {},
      cageCount: 1,
      maxRows: 0,
    },
    animals: {
      species: undefined,
      initialData: [],
      data: [],
      settings: initAnimalSettings([], true, false).settings,
      cellErrors: {},
      metadata: [],
      metaGlossaries: [],
      invalidCells: {},
      overlay: '',
    },
    Summary: {},
    apiError: undefined,
  };
  const [loading, setLoading] = useState(false);

  const formatCurrentCageAnimals = (cageCatalog, state) => {
    const {
      animals: { settings: animalSettings, data: animalData, species },
    } = state;
    return animalData.reduce((acc, { cage_catalog: catalog, name, sex, species: animalSpecies, dob, strain, meta }) => {
      if (cageCatalog === catalog) {
        const returnData = {
          name,
        };
        if (sex) {
          returnData.sex = sex === 'Male' ? 'm' : 'f';
        }
        if (dob) {
          returnData.dob = dob;
        }
        if (animalSpecies) {
          const strains = species.find((species) => species.name === animalSpecies).strains;
          if (strains.length) {
            returnData.strain_id = strains.find((s) => s.name === strain).id;
          }
        }
        if (meta && _isNotEmpty(meta)) {
          returnData.meta = createMeta(meta, animalSettings.columns);
        }
        acc.push(returnData);
      }
      return acc;
    }, []);
  };

  const updateApiError = (dispatch, apiError) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: apiError,
    });
  };

  const formatCageData = (state) => {
    const {
      cages: { data: cageData, settings: cageSettings },
    } = state;
    return cageData
      .filter((cage) => cage.name)
      .map(({ catalog, name, population, meta }) => ({
        name,
        animal_count: population,
        study_id: studyId ? Number(studyId) : null,
        meta: _isEmpty(meta) ? [] : createMeta(meta, cageSettings.columns),
        animals: formatCurrentCageAnimals(catalog, state),
      }));
  };

  const saveColony = (state, dispatch) => {
    setLoading(true);
    const formattedData = formatCageData(state);
    Http.post(apiRoutes('cages.createMany'), {
      cages: formattedData,
    })
      .then(({ data }) => {
        history.push('/colony');
        successToast(`Successfully added ${data.length > 1 ? 'colonies' : 'colony'}`);
        if (studyId) {
          history.push(
            webRoute('studies.cages', {
              id: studyId,
            })
          );
        }
      })
      .catch(() => {
        updateApiError(dispatch, 'Failed to create Colony or Animals');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const errorBanner = ({ state: { apiError } }) => (
    <>
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={apiError}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
    </>
  );
  return (
    <div className="h-100">
      <SubHeader linkToText="Colony" link={webRoute('colony')} />

      <div className="pa4">
        {loading ? (
          <div style={{ height: 650 }}>
            <Loading txt="Updating colony..." />
          </div>
        ) : (
          <StepForm
            title="Add new cages and animals"
            titlePadding="pb2"
            initialState={initialState}
            props={{}}
            steps={steps}
            finalStepAction={saveColony}
            finalButtonText="Create"
            finalButtonClass="w-47 mw6 center"
            horizontal
            errorBanner={errorBanner}
          />
        )}
      </div>
    </div>
  );
};

const withStudy = (CageComponent, studyId) => (props) => {
  const { entity: study } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'counts',
  });

  return <CageComponent study={study} {...props} />;
};

export default ColonyCreation;
