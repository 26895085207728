// @ts-nocheck: converted from JS

const Expander = {
  id: 'expander',
  width: 40,
  minWidth: 40,
  maxWidth: 40,
  canExpand: false,
  Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    <div className="ui__expander w-100 h-100 tc" {...getToggleAllRowsExpandedProps()}>
      <span aria-expanded={isAllRowsExpanded} />
    </div>
  ),
  Cell: ({ row, rows, toggleRowExpanded }) =>
    row.canExpand ? (
      <div
        {...row.getToggleRowExpandedProps({
          className: 'ui__expander w-100 h-100 tc',
          onClick: () => {
            const expandedRow = rows.find((row) => row.isExpanded);
            if (expandedRow) {
              const isSubItemOfRow = Boolean(expandedRow && row.id.split('.')[0] === expandedRow.id);

              if (isSubItemOfRow) {
                const expandedSubItem = expandedRow.subRows.find((subRow) => subRow.isExpanded);

                if (expandedSubItem) {
                  const isClickedOnExpandedSubItem = expandedSubItem.id === row.id;
                  if (!isClickedOnExpandedSubItem) {
                    toggleRowExpanded(expandedSubItem.id, false);
                  }
                }
              } else {
                toggleRowExpanded(expandedRow.id, false);
              }
            }
            row.toggleRowExpanded();
          },
        })}
      >
        <span aria-expanded={row.isExpanded} />
      </div>
    ) : null,
};

export default Expander;
