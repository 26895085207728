// @ts-nocheck: converted from JS

import Radio from '@/components/UI/FormElements/Radio';
import SelectDropDown from '@/components/UI/SelectDropDown';
import MenuContainer from '@/components/UI/SelectDropDown/Menus/MenuContainer';
import { _notNil } from '@/littledash';
import type { ErrorSetting } from '@/model/Study.model';
import type React from 'react';
import DownloadGraph from './DownloadGraph';
import { renderDateRange } from './Graph.utils';

interface GraphTitleProps {
  studyName: string;
  dates?: DateRange;
  graphType: unknown;
  graphOptions?: Array<unknown>;
  settings?: GraphSettings;
  setSettings?: (value: GraphSettings) => void;
}

export interface GraphSettings {
  yaxis: Plotly.AxisType;
  error?: ErrorSetting;
  // For some reason the php returns value, not mean
  average?: GraphDataType;
}

export type GraphDataType = 'mean' | 'median' | 'dtdc' | 'tgi';

export interface DateRange {
  first?: string;
  last?: string;
}

const GraphTitle: React.FC<GraphTitleProps> = ({
  studyName,
  dates,
  graphType,
  graphOptions = [],
  settings,
  setSettings,
}) => {
  return (
    <div className="flex-ns justify-between-ns flex-wrap-ns items-center">
      <div>
        {studyName && (
          <h3 className="f5 dark-gray lh-title basier-reg normal pb1" data-testid="graphs__subtitle">
            {studyName}
          </h3>
        )}
        {_notNil(dates) && (
          <h3 className="f4 normal lh-title" data-testid="graphs__title">
            {renderDateRange(dates?.first, dates?.last)}
          </h3>
        )}
      </div>
      <SelectDropDown title="Options" className="plain" alignMenu="right" testId="graphs__modifiers-select">
        <MenuContainer width={'auto'}>
          <div data-testid="graphs__modifiers-select--options">
            {graphOptions.map(({ title, options, key }) => (
              <div key={title} className="bb b--moon-gray pa3">
                <label>{title}</label>
                {options.map(({ val, label, disabled }, index) => (
                  <Radio
                    disabled={disabled}
                    key={key}
                    checked={val === settings[key]}
                    onChange={(e) =>
                      setSettings({
                        ...settings,
                        [key]: e.target.value,
                      })
                    }
                    id={`${index}_${val}`}
                    name={`${index}_${val}`}
                    label={label}
                    className="mt2"
                    value={val}
                  />
                ))}
              </div>
            ))}
            <div className="pa3">
              <DownloadGraph studyName={studyName} graphType={graphType} />
            </div>
          </div>
        </MenuContainer>
      </SelectDropDown>
    </div>
  );
};

export default GraphTitle;
