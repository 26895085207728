import { useScheduleContext } from '@/components/Studies/Tasks/Schedule.context.ts';
import { ScheduleSearchBar } from '@/components/Studies/Tasks/ScheduleSearchBar.tsx';
import Button from '@/components/UI/Button';
import { ISODateTime } from '@/model/Common.model.ts';
import { TaskScheduleDataV1 } from '@/model/Task.model.ts';
import { featuresSelector } from '@/support/Selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { FC } from 'react';
import { useSelector } from 'react-redux';

const typedTaskFeatureSelector = createSelector([featuresSelector], (features) => features?.typed_tasks ?? false);

export const ScheduleHeader: FC<{
  scheduleData?: TaskScheduleDataV1;
  executeTasks: () => void;
  onSearchChange: (data: { text: string | undefined; start: ISODateTime | undefined }) => void;
  isWriteUserForStudy: boolean;
}> = ({ scheduleData, executeTasks, onSearchChange, isWriteUserForStudy }) => {
  const { state } = useScheduleContext();
  const typedTasksEnabled = useSelector(typedTaskFeatureSelector);
  return (
    <div className="ph4 pv2 bb b-moon-gray" data-test-component="ScheduleHeader" data-test-element="container">
      <div className="flex flex-row justify-between items-center">
        <h1 className="f3 normal">Schedule</h1>
        {typedTasksEnabled ? (
          <>
            <span style={{ width: '50vw' }}>
              <ScheduleSearchBar onChange={onSearchChange} scheduleData={scheduleData} />
            </span>
            {isWriteUserForStudy ? (
              <div>
                <Button disabled={state.selectedTasks.size <= 0} testId="execute-task-button" onClick={executeTasks}>
                  {state.selectedTasks.size > 1 ? <>Execute tasks</> : <>Execute task</>}
                </Button>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};
