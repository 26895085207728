import { assembleToggleOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.utils';
import Switch from '@/components/UI/FormElements/Switch';
import type { ID } from '@/model/Common.model';
import type { Treatment } from '@/model/Treatment.model';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SelectionReorder } from '@/components/Modals/SetupWorkflow/SelectionReorder.tsx';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { ErrorMessage } from '@hookform/error-message';
import Loading from '@/components/Loading';

const Dosing = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const { control, watch, errors } = useFormContext();

  const { response: treatmentsResponse, loading: treatmentsLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.treatments',
    path: { studyId },
    query: { perPage: -1 },
    options: { onError: { toast: false, slug: 'treatments-load' } },
  });

  if (treatmentsLoading) {
    return <Loading />;
  }
  const treatments: Array<{ title: string; id: ID }> =
    (
      treatmentsResponse?.body as {
        data: Array<Treatment>;
      }
    )?.data?.map((treatment) => ({ ...treatment, title: treatment.display_name, id: treatment.api_id })) ?? [];

  const toggleOptions = assembleToggleOptions(watch('workflowSetup.workflow'), 'dosing');
  return (
    <div data-testid="workflow-dosing-setup-main">
      <div>
        <div data-test-element="treatment-selection-container">
          <Controller
            control={control}
            name="workflowSetup.workflow[3].children"
            render={({ onChange, value }: ControllerRenderProps) => (
              <>
                <ErrorMessage
                  errors={errors}
                  name="workflowSetup.workflow[3].children"
                  render={({ message }) => (
                    <p className="mt2 ml2 f6 red" data-test-element="email-error">
                      {message}
                    </p>
                  )}
                />
                <SelectionReorder
                  options={treatments}
                  selectionTitle="treatment"
                  selection={value}
                  selectionChange={onChange}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">Skip dosing for animals that do not require any treatments</label>
        <Controller
          defaultValue={false}
          name="workflowSetup.workflow[3].skipDosing"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Switch value={value} onChange={onChange} testId="skip-dosing-not-required-treatments-switch" />
          )}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <div>
          <label className="mb1 f6">Use new body weight</label>
          <small>For dose calculation without amending study data.</small>
        </div>
        <Controller
          defaultValue={false}
          name="workflowSetup.workflow[3].takeWeight"
          control={control}
          render={({ value, onChange, name }: ControllerRenderProps) => (
            <Switch testId={name} value={value} onChange={onChange} />
          )}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name="workflowSetup.workflow[3].onSave"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search">Focus search bar</option>
              {Object.keys(toggleOptions).map((k) => (
                <option key={k} value={toggleOptions[k].value}>
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject" data-testid="after-saving-option-move-to-next-animal">
                Move to next animal
              </option>
            </select>
          )}
        />
      </div>
    </div>
  );
};

export default Dosing;
