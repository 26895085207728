import { mapMetadataSubmissions, metadataFieldBuilder } from '@/components/UI/FormFields/FormField.utils';
import Icon from '@/components/UI/Icon';
import Link from '@/components/UI/Link';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { fetchMetadataColumns, formatNumber } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil, _toNumber } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { Treatment, TreatmentField, TreatmentFieldV1, TreatmentMethod } from '@/model/Treatment.model';
import { structuredCloneUtil } from '@/utils/browser.utils';

export const generateTreatmentColumns = (
  isWriteUserForStudy: boolean,
  duplicates: Array<ID>,
  columns: Record<string, boolean>,
  isMounted: () => boolean,
  handleAddOrUpdateTreatmentsClick: (event: React.MouseEvent, treatment?: Treatment | undefined) => Promise<boolean>,
  metadata: Array<MetadataField>
): Array<Column<Treatment>> => [
  {
    id: 'id',
    accessor: 'id',
    width: 50,
    isVisible: false,
    sortBy: 'id',
  },
  {
    id: 'treatment_name',
    Header: 'Treatment Name',
    Cell: ({
      apiTable: { setUpdating, fetchTableData },
      row: {
        original: { treatment_type: treatmentType, id },
        original,
      },
    }) => (
      <>
        {isWriteUserForStudy ? (
          <div
            className="flex items-center pointer"
            data-test-component="TreatmentNameCell"
            data-test-element="container"
            data-test-key={`${id}`}
          >
            {original.treatment_type?.benchling_link && (
              <Icon
                className="blue dib mr1"
                onClick={() => {
                  handleIconClick(original);
                }}
                style={{ minWidth: '40px', paddingTop: '3px' }}
                icon="jeffy_p_circle"
                tooltip="Click to view in new tab"
                width="24"
                height="24"
                viewBox="0 0 20 20"
              />
            )}
            <Link
              className="link w-100"
              openModal
              onClick={(e) => {
                setUpdating(true);
                handleAddOrUpdateTreatmentsClick(e, original)
                  .then((reload) => {
                    if (reload) {
                      return fetchTableData();
                    }
                  })
                  .finally(() => {
                    if (isMounted()) {
                      setUpdating(false);
                    }
                  })
                  .catch(() => {
                    if (isMounted()) {
                      setUpdating(false);
                    }
                  });
              }}
            >
              {treatmentType.name}
              {duplicates.includes(id) && <span className="ml2 dark-gray">(duplicate)</span>}
            </Link>
          </div>
        ) : (
          <span>
            {treatmentType.name}
            {duplicates.includes(id) && <span className="ml2 dark-gray">(duplicate)</span>}
          </span>
        )}
      </>
    ),
    isVisible: columns?.treatment_name ?? false,
    sortBy: 'treatment_name',
    accessor: 'name',
  },
  {
    id: 'dose_concentration',
    Header: 'Dose',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'dose');
      return formatDosingColumnValue(currentField);
    },
    isVisible: columns?.dose_concentration ?? false,
    sortBy: 'dose_concentration',
  },
  {
    id: 'dose_volume',
    Header: 'Dose volume',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'dose_volume');
      return formatDosingColumnValue(currentField);
    },
    isVisible: columns?.dose_volume ?? false,
    sortBy: 'dose_volume',
  },
  {
    id: 'stock_concentration',
    Header: 'Stock Concentration',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'stock');
      return formatDosingColumnValue(currentField);
    },
    isVisible: columns?.stock_concentration ?? false,
    sortBy: 'stock_concentration',
  },
  {
    id: 'volume',
    Header: 'Volume',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'volume');
      return formatDosingColumnValue(currentField);
    },
    isVisible: columns?.volume ?? false,
    sortBy: 'volume',
  },
  {
    id: 'groups',
    Header: 'Groups',
    Cell: ({ row: { original } }) => original.study_groups?.map?.(({ name }) => name).join(', ') || '-',
    isVisible: columns?.groups ?? false,
    sortDisabled: true,
    accessor: 'study_groups',
  },
  ...fetchMetadataColumns({ metadata, columns }),
];

export const generateTreatmentColumnsStep = (
  columns: Record<string, boolean>,
  metadata: Array<MetadataField>
): Array<Column<Treatment>> => [
  {
    id: 'treatment_name',
    Header: 'Treatment Name',
    isVisible: _notNil(columns?.treatment_name),
    sortBy: 'treatment_type.name',
    accessor: 'treatment_type.name',
  },
  {
    id: 'dose_concentration',
    Header: 'Dose',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'dose');
      return formatDosingColumnValue(currentField);
    },
    isVisible: _notNil(columns?.dose_concentration),
    sortBy: 'dose_concentration',
  },
  {
    id: 'dose_volume',
    Header: 'Dose volume',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'dose_volume');
      return formatDosingColumnValue(currentField);
    },
    isVisible: _notNil(columns?.dose_volume),
    sortBy: 'dose_volume',
  },
  {
    id: 'stock_concentration',
    Header: 'Stock Concentration',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'stock');
      return formatDosingColumnValue(currentField);
    },
    isVisible: _notNil(columns?.stock_concentration),
    sortBy: 'stock_concentration',
  },
  {
    id: 'volume',
    Header: 'Volume',
    Cell: ({ row: { original } }) => {
      const currentField = original.fields.find((x) => x.name === 'volume');
      return formatDosingColumnValue(currentField);
    },
    isVisible: _notNil(columns?.volume),
    sortBy: 'volume',
  },
  {
    id: 'groups',
    Header: 'Groups',
    Cell: ({ row: { original } }) => original.study_groups?.map?.(({ name }) => name).join(', ') || '-',
    isVisible: _notNil(columns?.groups),
    sortDisabled: true,
    accessor: 'study_groups',
  },
  ...fetchMetadataColumns({ metadata, columns }),
];

export const handleIconClick = (treatment: Treatment) => {
  window.open(treatment.treatment_type.benchling_link, '_blank', 'noopener noreferrer');
};

export const formatDosingColumnValue = (treatmentField: TreatmentField | TreatmentFieldV1 | undefined) => {
  if (_notNil(treatmentField)) {
    // @ts-expect-error: unit name may not exist
    const unit = `${treatmentField.unit.display_unit ?? treatmentField.unit.name}`;

    if (_isNotEmpty(treatmentField.default_display_value)) {
      return `${formatNumber(treatmentField.default_display_value, true, 3)} ${unit}`;
    }

    if (_isNotEmpty(treatmentField.default_value)) {
      return `${formatNumber(treatmentField.default_value, true, 3)} ${unit}`;
    }
  }
  return '-';
};

export const updateNumberToString = (fields: Array<TreatmentField>): Array<TreatmentField> =>
  fields.map((field) => ({
    ...field,
    default_value: _toNumber(field.default_value)?.toString(),
  }));

export type TreatmentFromForm = Treatment & {
  meta: unknown;
  dosingMethod: TreatmentMethod;
  treatment_type_id?: ID;
};

export const addAdditionalProperties = (
  formItems: TreatmentFromForm,
  metadata?: Array<MetadataField>,
  treatment?: Treatment
) => {
  const updatedItems = structuredCloneUtil(formItems);

  if (_isNil(formItems.type)) {
    updatedItems.type = formItems.dosingMethod;
  }

  updatedItems.fields = updatedItems.fields.map(mapFormPropertiesToTreatment);

  updatedItems.treatment_type_id = formItems.treatment_type?.id;

  if (_isNotEmpty(treatment?.study_groups)) {
    // @ts-expect-error: mapped from ts-ignore
    updatedItems.study_groups = treatment?.study_groups.map(({ id }) => id);
  } else {
    delete updatedItems.study_groups;
  }
  if (_isNotEmpty(metadata)) {
    updatedItems.metadata = mapMetadataSubmissions(formItems.meta, metadata);
  }
  return updatedItems;
};

interface MapFormPropertiesToTreatmentReturn extends TreatmentField {
  unit_id: ID;
  value?: string;
  name: string;
}

export const mapFormPropertiesToTreatment = (field: TreatmentField): MapFormPropertiesToTreatmentReturn => ({
  ...field,
  unit_id: field.unit?.id ?? '',
  value: field.default_value?.toString(),
  name: field.unit?.unit_type ?? '',
});

export const treatmentMetadataFields = (treatmentMetas: Array<MetadataField>, treatment: Treatment) =>
  (treatmentMetas ?? []).map((meta) => metadataFieldBuilder(meta, treatment));
