import { _notNil } from '@/littledash';
import { useDevices } from '@/utils/devices/useDevices';

export const FollowFocusConsumer = () => {
  const onNewReading = (reading: string) => {
    const activeElement = document.activeElement as HTMLInputElement;

    if (_notNil(activeElement)) {
      activeElement.value = reading;

      setTimeout(() => {
        const event = new KeyboardEvent('keydown', {
          code: 'Enter',
          key: 'Enter',
          charCode: 13,
          keyCode: 13,
          view: window,
          bubbles: true,
        });

        activeElement?.dispatchEvent(event);
      }, 200);
    }
    nextReading(true);
  };

  const { nextReading } = useDevices({
    targetId: 'follow-focus',
    onNewReading,
  });
  return false;
};
