import Select from '@/components/UI/Select/Select';
import { _isNotEmpty } from '@/littledash';
import type { GroupApiId, TreatmentGroup } from '@/model/TreatmentGroup.model';
import { type FC, JSX, useMemo, useState } from 'react';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import style from './ControlGroupSelect.module.scss';
import { FilterOptionOption } from 'react-select/filters';

interface ControlGroupSelectProps {
  groups: Array<TreatmentGroup>;
  onChange: (id?: GroupApiId | TreatmentGroup['temp_id']) => Promise<void>;
  disabled?: boolean;
}

const GroupLabel: FC<Pick<TreatmentGroup, 'name' | 'color'>> = ({ color: backgroundColor, name }) => (
  <span className={style.group_label} data-test-component="GroupLabel">
    <span className={style.group_color} style={{ backgroundColor }} data-test-element="color"></span>
    <span className={style.group_name} data-test-element="name">
      {name}
    </span>
  </span>
);

interface SelectOption {
  label: JSX.Element;
  name: string;
  value?: GroupApiId | string;
}

const filterOptions = (data: FilterOptionOption<SelectOption>, input: string) => data.data.name.includes(input);
export const ControlGroupSelect: FC<ControlGroupSelectProps> = ({ groups, onChange, disabled = false }) => {
  const isMounted = useMountedState();
  const [isLoading, setLoading] = useState(false);
  const { value, options } = useMemo(() => {
    return groups.reduce<{ value?: SelectOption; options: Array<SelectOption> }>(
      (acc, group) => {
        const option = {
          label: <GroupLabel color={group.color} name={group.name} />,
          name: group.name,
          value: _isNotEmpty(group.api_id) ? group.api_id : group.temp_id,
        };
        acc.options.push(option);
        if (group.control) {
          acc.value = option;
        }
        return acc;
      },
      {
        value: undefined,
        options: [],
      }
    );
  }, [groups]);
  const handleChange = (id?: SelectOption['value']) => {
    setLoading(true);
    onChange(id).finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  };

  return (
    <span data-test-component="ControlGroupSelect" data-test-element="container">
      <Select
        value={value}
        options={options}
        filterOption={filterOptions}
        onChange={(option) => handleChange(option?.value)}
        isLoading={isLoading}
        isClearable={true}
        disabled={isLoading || disabled}
        isMulti={false}
      />
    </span>
  );
};
