import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { DateRenderer } from '../../DateRenderers/DateRenderers';

const Datepicker: FC<{ name: string }> = ({ name }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ value }: ControllerRenderProps) => <DateRenderer value={value} />}
    />
  );
};
export default Datepicker;
