// @ts-nocheck: converted from JS

import AnimalsTable from '@/components/Animals/AnimalsTable/AnimalsTable';
import Loading from '@/components/Loading';
import { _isEmpty, _notNil } from '@/littledash';
import type { AnimalApiId } from '@/model/Animal.model';
import type { FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchProvider, useFetchEntity } from '@/support/Hooks/fetch';
import { ModalActions, ModalContainer, ModalHeader, useModalAction } from '@/utils/modal';

interface AddRowsProps {
  handleAddAnimals: (id: Array<AnimalApiId>) => Promise<void>;
}

const AddRows: FC<AddRowsProps> = ({ handleAddAnimals }) => {
  const [selectedAnimals, setSelectedAnimals] = useState<Array<AnimalApiId>>([]);
  const [animalsLoaded, setAnimalsLoaded] = useState<boolean>(false);
  const { closeModal } = useModalAction();
  const { id: studyId, dataTableId } = useParams<{ id: string; dataTableId: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,study_groups',
  });
  const handleSubmit = (): void => {
    closeModal();
    handleAddAnimals(selectedAnimals);
  };
  const handleSelection = (selectedFlatRows: Array<{ original?: { api_id?: AnimalApiId } }>): void => {
    setSelectedAnimals(
      selectedFlatRows.reduce<Array<AnimalApiId>>((acc, row) => {
        const id = row?.original?.api_id;
        if (_notNil(id)) {
          acc.push(id);
        }
        return acc;
      }, [])
    );
  };
  const onFetchTableData = () => setAnimalsLoaded(true);

  return (
    <ModalContainer>
      <ModalHeader title="Choose animals" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      {!studyLoading ? (
        <div className="ph3 pt3">
          <FetchProvider entity={study}>
            <AnimalsTable
              enableBulkActions={false}
              onSelectRow={handleSelection}
              queryParams={{ excludeAnimalsFromDatatable: dataTableId }}
              onFetchTableData={onFetchTableData}
            />
          </FetchProvider>
        </div>
      ) : (
        <div className="pa7">
          <Loading />
        </div>
      )}
      {!studyLoading && animalsLoaded && (
        <ModalActions
          submitButtonProps={{ disabled: _isEmpty(selectedAnimals) }}
          onSubmit={handleSubmit}
          onCancel={closeModal}
          className="pa3 bt b--moon-gray"
        />
      )}
    </ModalContainer>
  );
};

export default AddRows;
