// @ts-nocheck: converted from JS

import Assignees from '@/components/Glossary/Sections/Requests/Create/Assignees';
import Name from '@/components/Glossary/Sections/Requests/Create/Name';
import RequestForm from '@/components/Glossary/Sections/Requests/Create/RequestForm';
import RequestSummary from '@/components/Glossary/Sections/Requests/Create/Summary/Summary';
import Loading from '@/components/Loading';
import Http from '@/support/http';
import { api as apiRoute, web as webRoutes } from '@/support/route';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TemplateForm from './TemplateForm/TemplateForm';

const initialSteps = [
  {
    title: 'Name',
    Component: Name,
    complete: true,
  },
  {
    title: 'Assignees',
    Component: Assignees,
    complete: true,
  },
  {
    title: 'Request form',
    Component: RequestForm,
    complete: true,
  },
  {
    title: 'Summary',
    Component: RequestSummary,
    complete: true,
  },
];

const Edit = () => {
  const [request, setRequest] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFetchErrors, setHasFetchErrors] = useState(false);
  const [hasSubmissionErrors, setHasSubmissionErrors] = useState(false);
  const history = useHistory();

  const { requestTemplate } = useParams();

  const fetchRequest = async (requestTemplate) => {
    setIsLoading(true);
    try {
      const { data } = await Http.get(
        apiRoute('requests.templates.show', {
          requestTemplate,
        })
      );
      if (data?.data) {
        setRequest(data.data);
      }
    } catch (error) {
      setHasFetchErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequest(requestTemplate);
  }, []);

  const submitRequest = async () => {
    setIsSubmitting(true);
    setHasSubmissionErrors(false);
    try {
      await Http.put(
        apiRoute('requests.templates.update', {
          requestTemplate,
        }),
        request
      );
      history.push(webRoutes('team.studies.requests'));
    } catch (e) {
      setHasSubmissionErrors(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  if ((isLoading || !request) && !hasFetchErrors) {
    return <Loading />;
  }

  return (
    <TemplateForm
      submitRequest={submitRequest}
      hasSubmissionErrors={hasSubmissionErrors}
      initialSteps={initialSteps}
      isSubmitting={isSubmitting}
      request={request}
      setRequest={setRequest}
      formTitle={`Edit ${request.name}`}
      submitButtonText="Save and update"
    />
  );
};

export default Edit;
