// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import { successToast } from '@/helpers';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { modalAction } from '@/utils/modal';
import { useDispatch } from 'react-redux';
import Measurements from './Measurements';

const MeasurementsSidebar = ({ study, animal, measurementsUpdated, readOnly = false }) => {
  const dispatch = useDispatch();
  const { closeModal } = modalAction(dispatch);
  const { collection: measurements, collectionLoading: measurementsLoading } = useFetchCollection({
    collectionType: 'measurements',
    params: { id: animal.id },
  });

  const handleMeasurementSubmit = async () => {
    await measurementsUpdated();
    closeModal();
    successToast('Success.');
  };

  return (
    <div
      style={{ width: 400 }}
      className="bl b--moon-gray bg-white absolute right-0 top-0 z-5 h-100 overflow-auto"
      data-testid="animals-measurements-sidebar"
    >
      {measurementsLoading ? (
        <Loading txt="Fetching measurements..." />
      ) : (
        <Measurements
          subject={animal}
          study={study}
          measurements={measurements}
          onMeasurementSubmit={handleMeasurementSubmit}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default MeasurementsSidebar;
