import { AlertsTable } from '@/components/Studies/Create/Steps/Alerts/AlertsTable';
import Button from '@/components/UI/Button';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import { _isEmpty, uuid } from '@/littledash';
import type { AnimalAlertSpecCreateOrUpdate, AnimalAlertV1 } from '@/model/Alert.model';
import React, { useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useController, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { modalAction } from '@/utils/modal';
import type { CreateStudyState } from '../../Create.model';

const alertsToMap = (
  alerts: Array<AnimalAlertSpecCreateOrUpdate>
): Map<AnimalAlertSpecCreateOrUpdate['id'], AnimalAlertSpecCreateOrUpdate> =>
  new Map(
    (alerts ?? []).map((alert) => {
      const id = alert.id ?? (uuid() as unknown as number);
      return [id, { ...alert, id }];
    })
  );

interface AlertBulkActionsProps {
  selectedRows: Record<number, boolean>;
  handleDelete: () => void;
}

export const AlertBulkActions: React.FC<AlertBulkActionsProps> = ({ selectedRows, handleDelete }) => {
  const selectedRowCount = useMemo(() => Object.keys(selectedRows ?? {}).length, [selectedRows]);
  const hasSelectedRows = selectedRowCount > 0;

  const actions = [
    {
      name: 'Delete',
      key: 'delete',
      action: handleDelete,
    },
  ];
  return (
    <div className="flex items-center ml3">
      {hasSelectedRows && (
        <div
          className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
          style={{
            whiteSpace: 'nowrap',
            lineHeight: '2.4rem',
          }}
        >
          {selectedRowCount} selected
        </div>
      )}
      <SelectDropDown
        title="Bulk actions"
        className={`plain f6 ${hasSelectedRows ? 'br--right' : ''}`}
        alignMenu="right"
        disabled={!hasSelectedRows}
      >
        <ActionList actions={actions} />
      </SelectDropDown>
    </div>
  );
};

interface AlertsProps {
  state: CreateStudyState;
  dispatch: Function; // eslint-disable-line @typescript-eslint/no-unsafe-function-type
}

const Alerts: React.FC<AlertsProps> = ({ state, dispatch }) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const { control } = useFormContext<{ alerts: Array<AnimalAlertSpecCreateOrUpdate> }>();
  const {
    field: { onChange: onAlertsChange, value: alerts },
  } = useController({
    name: 'alerts',
    control,
    defaultValue: [],
  });

  const [selectedRows, onSelectedRowsChange] = useState<Record<number, boolean>>({});

  const findPreset = (presetId: number | string) =>
    state.form.presets.find(({ id }) => Number(id) === Number(presetId));

  const calculations = findPreset(state?.form?.selectedPreset?.id ?? state?.study?.preset_id)?.calculations ?? [];

  const handleAddAlert = async (alert: AnimalAlertSpecCreateOrUpdate) => {
    const alertsMap = alertsToMap(alerts ?? []);
    alertsMap.set(alert.id, alert);
    onAlertsChange([...alertsMap.values()]);
    closeModal();
  };
  const handleDeleteAlert = async () => {
    onAlertsChange(alerts.filter((alert: AnimalAlertV1, index: number) => !selectedRows?.[index]));
    onSelectedRowsChange({});
  };

  const addOrUpdateAlert = (alert?: AnimalAlertV1) => {
    openModal('ADD_ALERT_FORM', {
      calculations,
      alert: alert ?? { id: uuid() },
      onSubmit: handleAddAlert,
      onCancel: () => closeModal(),
    });
  };

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  return (
    <>
      <div className="mb4" data-testid="alerts__container">
        <h3 className="fw5 f5 pb2">Alerts</h3>
        <p className="f6 lh-copy ma0">
          Alerts are rules that can be set to alert users to a status change in an animal.{' '}
          <a
            href="https://help.benchling.com/hc/en-us/articles/20040160668173-Study-Alerts"
            target="_blank"
            rel="noopener noreferrer"
            className="dib link blue"
          >
            Read more.
          </a>
        </p>
      </div>
      <div className="ui-card">
        <div className="w-100 flex justify-between mt3">
          <AlertBulkActions selectedRows={selectedRows} handleDelete={handleDeleteAlert} />
          <Button
            className="relative mr3 br-pill"
            icon="add_new"
            paleBlue
            onClick={() => addOrUpdateAlert()}
            disabled={_isEmpty(calculations)}
          >
            <span>Add Alert</span>
          </Button>
        </div>
        <div>
          <AlertsTable
            alerts={alerts}
            calculations={calculations}
            onAlertClick={(alert) => addOrUpdateAlert(alert)}
            onAddAlertClick={() => addOrUpdateAlert()}
            selectedRows={selectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        </div>
      </div>
    </>
  );
};

export default Alerts;
