import type { CellType } from 'handsontable/cellTypes';
import { _isNil, _notNil } from '@/littledash';
import type { ReactNode } from 'react';
import type { CustomColumnSettings, CustomValidation, SpreadSheetError } from './SpreadSheet.model';

export type SpreadSheetErrorMessage = (value: ReactNode) => string;

export const spreadSheetValidationTooltip = 'There are invalid cells';

export const formatValidationErrors = (errors: Record<string, SpreadSheetError>): Array<string> =>
  Object.keys(errors).map((column: string) => createValidationError(errors[column], ''));

export const createValidationError = (
  error: SpreadSheetError,
  type?: string,
  customMessage?: SpreadSheetErrorMessage
): string => {
  if ((_isNil(error.value) || error.value === '') && _isNil(customMessage)) {
    return 'Required Field';
  } else {
    return (
      customMessage?.(error.value) ?? errorCellTypes[type as CellType]?.(error.value) ?? `${error.value} is not valid`
    );
  }
};

export const buildCustomValidators = (columns: Array<CustomColumnSettings>): Record<string, CustomValidation> =>
  columns.reduce<Record<string, CustomValidation>>((acc, { data, validationErrorMessage, linkedValidationColumns }) => {
    if (_notNil(data)) {
      return { ...acc, [data.toString()]: { validationErrorMessage, linkedValidationColumns } };
    } else {
      return acc;
    }
  }, {});

type CustomCellTypes = 'numeric-expo' | 'multi-select' | 'date-wrapper';

const errorCellTypes: Record<CellType | CustomCellTypes, SpreadSheetErrorMessage> = {
  dropdown: (value) => `${value} is not a valid option`,
  date: () => `Must be a valid date`,
  numeric: (value) => `${value ?? 'Value'} must be a number`,
  time: (value) => `Must be a valid time`,
  autocomplete: (value) => `${value} is not valid`,
  handsontable: (value) => `${value} is not valid`,
  password: (value) => `${value} is not valid`,
  text: (value) => `${value} is not valid`,
  checkbox: (value) => `${value} is not valid`,
  'numeric-expo': (value) => `${value ?? 'Value'} must be a number`,
  'multi-select': (value) => `${value} is not a valid option`,
  'date-wrapper': () => `Must be a valid YYYY-MM-DD date`,
};
