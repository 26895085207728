// @ts-nocheck: converted from JS

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import Banner from '@/components/UI/Banner';
import { capitalise, hasOwnProperty } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { useState } from 'react';
import SettingsForm from './SettingsForm';

const SelectedList = ({ observations, handleInput, errors, disabled = false }) => {
  const state = useState([...observations].map(() => false));
  const [openItems, setOpenItems] = state;

  const allExpanded = !_isEmpty(openItems) && openItems.every((o) => o === true);
  const toggleExpand = () => {
    if (allExpanded) {
      return setOpenItems(observations.map(() => false));
    } else {
      return setOpenItems(observations.map(() => true));
    }
  };

  const optionsFormat = (options, type) => {
    if (type === 'numeric' && !_isEmpty(options)) {
      return `(${hasOwnProperty(options, 'min') && options.min}-${hasOwnProperty(options, 'max') && options.max})`;
    }

    return false;
  };

  return (
    <div className="pl4">
      {!_isEmpty(observations) ? (
        <div className="ui-card pa3">
          <div className="bb b--moon-gray pb3 flex flex-wrap justify-between items-center">
            <h3 className="f6 dark-gray normal basier-reg lh-title">Selected observations</h3>
            <a className="lh-title link blue f7" onClick={() => toggleExpand()}>
              {allExpanded ? 'Collapse' : 'Expand'} all
            </a>
          </div>
          <div className="ui__overflow__scroll_y" style={{ height: '440px' }}>
            <Accordion outerState={state}>
              {observations.map((o, i) => (
                <AccordionItem key={o.id}>
                  <AccordionHeader className="flex flex-wrap justify-between items-top">
                    <span
                      className={`${
                        _isEmpty(errors[i] || []) ? 'near-black' : 'red'
                      } basier-med dib f6 near-black lh-title w-70 pr2`}
                    >
                      {o.title}
                    </span>
                    <span className="dib f7 lh-title w-30 tr">
                      {capitalise(o.type)} {optionsFormat(o.options, o.type)}
                    </span>
                  </AccordionHeader>
                  <AccordionPanel>
                    <div className={`${disabled ? 'ui__disabled' : ''}`}>
                      <SettingsForm handleInput={handleInput} index={i} errors={errors[i] || false} observation={o} />
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      ) : (
        <Banner info className="mw6" dismiss={false}>
          <h3 className="f5 normal basier-med lh-title">No observations have been selected</h3>
          <p className="f6 pt2 lh-copy">
            To get started, search and select the obserations relevant to your studies. All team members will have
            access to the selected observations.
          </p>
        </Banner>
      )}
    </div>
  );
};

export default SelectedList;
