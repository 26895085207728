import logo from '@/assets/images/logo.svg';
import Button from '@/components/UI/Button';
import { _noop, _notNil } from '@/littledash.ts';
import { FC, useState } from 'react';

export const UnSupportedClientVersion: FC = () => {
  const [updating, setUpdating] = useState(false);

  const clearCacheAndReload = async () => {
    setUpdating(true);
    if ('caches' in window) {
      await window.caches.keys().then((keys) => Promise.all(keys.map((key) => window.caches.delete(key).catch(_noop))));
    }
    document.cookie.split(';').forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
    if ('cookieStore' in window) {
      // @ts-expect-error: no supported in all browsers
      await window.cookieStore
        .getAll()
        // @ts-expect-error: no supported in all browsers
        .then((cookies) => Promise.all(cookies.map((cookie) => window.cookieStore.delete(cookie.name))));
    }
    if ('indexedDB' in window) {
      await indexedDB.databases().then((dbs) => {
        dbs.forEach((db) => {
          if (_notNil(db.name)) {
            indexedDB.deleteDatabase(db.name);
          }
        });
      });
    }

    window.localStorage.clear();
    window.sessionStorage.clear();
    const reloadURL = new URL(window.location.origin);
    reloadURL.searchParams.set('version', new Date().getTime().toString());
    window.location.href = reloadURL.href;
  };
  return (
    <div
      className="flex flex-row justify-around mt6-l"
      data-test-component="UnSupportedClientVersion"
      data-test-element="container"
    >
      <div className="flex flex-column justify-center ui-card w-30 pa4">
        <div className="flex flex-row justify-around">
          <img src={logo} alt="Benchling In Vivo" style={{ width: 150, height: 60 }} />
        </div>

        <div className="flex flex-row justify-around mt4">
          <h3 className="normal f4">Outdated Client</h3>
        </div>
        <p className="tc mt4">
          You are using an outdated version of Benchling In Vivo. Please update by clicking the button below.
        </p>
        <Button className="w-100 mt4" disabled={updating} testId="update-now-button" onClick={clearCacheAndReload}>
          Update now
        </Button>
      </div>
    </div>
  );
};
