import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { Overview } from '@/components/Studies/Show';
import { _isNil } from '@/littledash';
import type { Approval } from '@/model/Approval.model';
import type { Request } from '@/model/Request.model';
import type { Study } from '@/model/Study.model';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { useParams } from 'react-router-dom';
import StudyHeader from './StudyHeader';

interface ShowParams {
  id: string;
}

const Show = () => {
  const { id: studyId } = useParams<ShowParams>();
  const {
    entity: study,
    entityLoading: studyLoading,
    entityError: studyError,
  } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,project',
  });
  const {
    collection: reviews,
    collectionLoading: reviewsLoading,
    collectionError: approvalsError,
  } = useFetchCollection<Approval>({
    collectionType: 'studyReviews',
    params: { id: studyId },
  });
  const {
    collection: requests,
    collectionLoading: requestsLoading,
    collectionError: requestsError,
  } = useFetchCollection<Request>({
    collectionType: 'studyRequests',
    params: { id: studyId },
  });

  return studyLoading || reviewsLoading || requestsLoading ? (
    <div className="w-100 h-100">
      <Loading />
    </div>
  ) : studyError || approvalsError || requestsError || _isNil(study) || _isNil(study?.id) ? (
    <ApiErrorBanner
      className="mb4"
      title={'There was an error retrieving the study'}
      text={
        'An error has occurred when fetching the study, please try again later. If this keeps occurring please contact support.'
      }
      errorType={ApiErrorType.FETCH}
      error={studyError ?? approvalsError ?? requestsError}
    />
  ) : (
    <>
      <StudyHeader study={study} />
      <div className="pa4">
        <Overview study={study} approvals={reviews} requests={requests} />
      </div>
    </>
  );
};

export default Show;
