// @ts-nocheck: converted from JS

import FormBuilder from '@/components/FormBuilder';
import {
  mapFormBuilderDataToRequestData,
  mapRequestDataToFormBuilderData,
} from '@/components/Glossary/Sections/Requests/Requests.utils';

const RequestForm = ({ ActionButtons, onSubmit, request }) => {
  const handleSubmit = (data) => {
    onSubmit({
      form: mapFormBuilderDataToRequestData(data),
    });
  };

  return (
    <FormBuilder
      ActionButtons={ActionButtons}
      onSubmit={handleSubmit}
      defaultValue={request?.form ? mapRequestDataToFormBuilderData(request.form) : undefined}
    />
  );
};

export default RequestForm;
