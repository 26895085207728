import { calculateAge } from '@/helpers';
import { _isNil } from '@/littledash';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';

export const renderAnimalAge = (dob: string, terminatedAt?: string): string => {
  if (DateUtils.isValidDate(dob)) {
    if (_isNil(terminatedAt)) {
      return calculateAge(dob, DateUtils.dateNow());
    }
    if (DateUtils.isValidDateTime(terminatedAt)) {
      return calculateAge(dob, DateUtils.renderDateTime(terminatedAt, { format: DateRenderFormat.ISODate }));
    }
    if (DateUtils.isValidDate(terminatedAt)) {
      return calculateAge(dob, DateUtils.renderDate(terminatedAt, { format: DateRenderFormat.ISODate }));
    }
  }
  return '—';
};
