// @ts-nocheck: converted from JS

import Checkbox from '@/components/UI/FormElements/Checkbox';
import { preventNumberScroll } from '@/helpers';
import * as Form from '@/support/form';
import React, { useEffect } from 'react';
import validationModule from 'validate.js';

const NewTask = React.forwardRef(({ index, task, updateTask, defaultTask }, ref) => {
  const getFields = () => {
    return task !== defaultTask ? task : defaultTask;
  };

  const [fields, setFields] = React.useState(Form.fieldObject(getFields()));
  const [errors, setErrors] = React.useState(Form.errorObject(getFields()));

  useEffect(() => {
    try {
      updateTask(index, fields);
    } catch (error) {
      // Intentionally empty
    }
  }, [fields]);

  const recurringValidation = {
    frequency: {
      presence: { allowEmpty: false },
      numericality: {
        greaterThan: 0,
      },
    },
    frequency_settings: {
      presence: { allowEmpty: task.period !== 'weekly' },
    },
  };
  const conditionalValidation = {
    'conditional_settings.type': {
      presence: { allowEmpty: false, message: ' must be selected.' },
    },
    'conditional_settings.value': {
      presence: { allowEmpty: false, message: ' is required.' },
      numericality: true,
    },
  };

  const validationSchema = () => ({
    description: {
      presence: { allowEmpty: false },
      length: { minimum: 3, maximum: 50 },
    },
    task_type: {
      presence: { allowEmpty: false },
    },
    ...(fields.task_type === 'conditional' ? conditionalValidation : recurringValidation),
  });

  React.useImperativeHandle(ref, () => ({
    validate() {
      const results = validationModule(fields, validationSchema());

      // clear out all the old errors
      setErrors(Form.errorObject(defaultTask));

      // set the errors from the validator
      if (results) {
        setErrors(results);
      }

      // If we have errors, return false
      return !results;
    },
  }));

  const handleInput = (e) => {
    setErrors(Form.errorObject(defaultTask));
    const name = e.target.name;
    const value = e.target.value;

    fields[name] = value;

    setFields({
      ...fields,
    });

    updateTask(index, fields);
  };

  const taskTypes = {
    daily: {
      name: 'Daily',
      inputComponent: () => <DailyField errors={errors} frequency={fields.frequency} handleInput={handleInput} />,
    },
    weekly: {
      name: 'Weekly',
      inputComponent: () => (
        <WeeklyField
          errors={errors}
          frequency={fields.frequency}
          frequencySettings={fields.frequency_settings}
          handleInput={handleInput}
          handleDaysInput={handleDaysInput}
        />
      ),
    },
  };

  const conditionTypes = {
    age: {
      name: 'Age (Days)',
    },
    study_day: {
      name: 'Study day',
    },
    tracking_day: {
      name: 'Tracking day',
    },
  };

  /** Methods */
  const setActiveFreq = (event) => {
    fields.period = event.target.value;
    setFields(Form.fieldObject(fields));
    setErrors(Form.errorObject(fields));
  };

  const setActiveConditionType = (event) => {
    fields.conditional_settings.type = event.target.value;
    setFields(Form.fieldObject(fields));
    setErrors(Form.errorObject(fields));
  };

  const handleDaysInput = (event) => {
    const frequencySettings = fields.frequency_settings;
    if (frequencySettings.includes(event.target.value)) {
      frequencySettings.splice(frequencySettings.indexOf(event.target.value), 1);
    } else {
      frequencySettings.push(event.target.value);
    }

    const nfields = { ...fields };
    nfields['frequency_settings'] = frequencySettings;
    setFields(nfields);
    updateTask(index, nfields);
  };

  return (
    <div>
      <div className="pb2 pt2">
        <label>Task description</label>
        <input
          type="text"
          name="description"
          value={fields.description}
          onChange={handleInput}
          style={{ maxWidth: '100%' }}
        />
        <Form.FieldError errors={errors} name="description" />
      </div>

      <div className="pb2">
        <label>Task type</label>
        <div className="flex flex-row items-start">
          <div
            className={`w-30 pa3 mb3 mb4-l bg-white ba ${
              fields.task_type == 'recurring' ? 'b--blue' : 'b--moon-gray'
            } border-hover-blue mr3 pointer`}
            onClick={() => {
              setFields({
                ...fields,
                task_type: 'recurring',
                period: 'daily',
                frequency: null,
                frequency_settings: [],
              });
            }}
          >
            <h3 className="f5 lh-title near-black normal pb2">Recurring Task</h3>
            <p className="lh-copy f5 ">Performed in a recurring pattern, for example every Monday and Wednesday</p>
          </div>
          <div
            className={`w-30 pa3 mb3 mb4-l bg-white ba ${
              fields.task_type == 'conditional' ? 'b--blue' : 'b--moon-gray'
            } border-hover-blue mr3 pointer`}
            onClick={() => {
              setFields({
                ...fields,
                task_type: 'conditional',
                conditional_settings: {
                  type: 'age',
                  value: '',
                },
              });
            }}
          >
            <h3 className="f5 lh-title near-black normal pb2">Conditional Task</h3>
            <p className="lh-copy f5 ">
              Generated automatically when a condition is met, for example when age is 35 days.
            </p>
          </div>
        </div>
        <Form.FieldError errors={errors} name="task_type" />
      </div>

      {fields.task_type == 'recurring' && (
        <React.Fragment>
          <div className="pb2">
            <label htmlFor="period">Frequency</label>
            <select className="w4" name="period" onChange={setActiveFreq}>
              {Object.keys(taskTypes).map((o) => (
                <option key={o} value={o} selected={o === fields.period}>
                  {taskTypes[o].name}
                </option>
              ))}
            </select>
          </div>
          <div className="pb2">{taskTypes[fields.period].inputComponent()}</div>
        </React.Fragment>
      )}
      {fields.task_type == 'conditional' && (
        <React.Fragment>
          <div className="pb2">
            <label htmlFor="period">Condition</label>
            <select className="w4" name="type" onChange={setActiveConditionType}>
              {Object.keys(conditionTypes).map((o) => (
                <option key={o} value={o} selected={o === fields.conditional_settings.type}>
                  {conditionTypes[o].name}
                </option>
              ))}
            </select>
          </div>
          <div className="pb2">
            {' '}
            <CustomField
              errors={errors}
              frequency={fields.conditional_settings.value}
              handleInput={(e) => {
                setFields({
                  ...fields,
                  conditional_settings: {
                    ...fields.conditional_settings,
                    value: e.target.value,
                  },
                });
              }}
              label="is"
              name="conditional_settings.value"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
});

const DailyField = ({ errors, frequency, handleInput }) => (
  <div>
    <div>
      <label className="dib">Every</label>
      <input
        type="number"
        onWheel={preventNumberScroll}
        name="frequency"
        value={frequency}
        onChange={handleInput}
        className="mh3"
        style={{ width: '64px' }}
      />
      <label className="dib">{frequency === 1 ? 'day' : 'days'}</label>
    </div>
    <Form.FieldError errors={errors} name="frequency" />
  </div>
);

const WeeklyField = ({ errors, frequency, frequencySettings, handleInput, handleDaysInput }) => {
  const weekdays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };

  return (
    <div>
      <div className="pb3">
        <label className="dib">Every</label>
        <input
          type="number"
          onWheel={preventNumberScroll}
          name="frequency"
          value={frequency}
          onChange={handleInput}
          className="mh3"
          style={{ width: '64px' }}
        />
        <label className="dib">week(s) on:</label>
      </div>
      <Form.FieldError errors={errors} name="frequency" />
      <div className="">
        {Object.keys(weekdays).map((dayWeekInt) => (
          <div key={dayWeekInt} className="dib mb3 mr2 w-50">
            <Checkbox
              type="checkbox"
              name={weekdays[dayWeekInt]}
              id={weekdays[dayWeekInt]}
              onChange={handleDaysInput}
              value={dayWeekInt}
              label={weekdays[dayWeekInt]}
              checked={frequencySettings.includes(dayWeekInt)}
            />
          </div>
        ))}
      </div>
      <Form.FieldError errors={errors} name="frequency_settings" />
    </div>
  );
};

const CustomField = ({ errors, frequency = '', handleInput, label = 'On study day', name = 'frequency' }) => (
  <React.Fragment>
    <div>
      <label className="dib">{label}</label>
      <input
        type="number"
        onWheel={preventNumberScroll}
        name={name}
        value={frequency}
        onChange={handleInput}
        className="mh3"
        style={{ width: '64px' }}
      />
    </div>
    <Form.FieldError errors={errors} name={name} />
  </React.Fragment>
);

export default NewTask;
