// @ts-nocheck: converted from JS

import Handsontable from 'handsontable';
import { _isNotEmpty } from '@/littledash';
import { formatNumber } from '@/helpers';
import { numericValidator } from '@/validators';

const exponentialRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.NumericRenderer.call(null, hotInstance, td, row, column, prop, value, cellProperties);

  Handsontable.dom.empty(td);

  if (_isNotEmpty(value)) {
    const valueAsNumber = formatNumber(value);
    td.innerText = isNaN(valueAsNumber) ? value : valueAsNumber;
  }

  return td;
};

Handsontable.renderers.registerRenderer('numeric-expo', exponentialRenderer);

export class ExponentialEditor extends Handsontable.editors.NumericEditor {
  static type = 'numeric-expo';

  static register(type = ExponentialEditor.type) {
    Handsontable.cellTypes.registerCellType(type, {
      editor: ExponentialEditor,
      renderer: exponentialRenderer,
      validator: (value, callback) => callback(numericValidator(value, callback)),
    });
  }

  static numericValidatorFactory(required) {
    return (value, callback) => callback(numericValidator(value, required));
  }

  init() {
    super.init();
  }
}
