// @ts-nocheck: converted from JS

import { useCallback } from 'react';
import update from 'immutability-helper';

const useSortable = (list, setList) => {
  const moveCallback = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = list[dragIndex];
      setList(
        update(list, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [list]
  );

  return {
    moveCallback,
  };
};

export default useSortable;
