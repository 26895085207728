import {
  CreateOrUpdatePresetContext,
  generateMeasurementDependencyGraph,
  toSlug,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import { FormulaInput } from '@/components/UI/FormulaInput/FormulaInput.tsx';
import { _isNotBlank } from '@/littledash.ts';
import { PresetCreateOrUpdateV1, PresetMeasurementCreateV1 } from '@/model/Preset.model.ts';
import { Variable } from '@/utils/FormulaDsl.ts';
import Fuse from 'fuse.js';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { RiSearch2Line } from 'react-icons/ri';
import styles from './PresetBuilder.module.scss';

const operators = [
  { label: '+', value: '+' },
  { label: '-', value: '-' },
  { label: '/', value: '/' },
  { label: '*', value: '*' },
  { label: 'π', value: 'PI()' },
  { label: 'avg', value: 'AVERAGE()' },
  { label: '(', value: '(' },
  { label: ')', value: ')' },
] as const;
type Operator = (typeof operators)[number]['value'];
type FormulaInputVariable = { name: string; slug: string; unit: string | null; variable: Variable };

export const OutputForm: FC<{ index: number }> = ({ index }) => {
  const formulaInputCaretPositionRef = useRef<number>(0);
  const { state } = useContext(CreateOrUpdatePresetContext);
  const formMethods = useFormContext<PresetMeasurementCreateV1>();
  const [slug, inputs] = useWatch({ control: formMethods.control, name: ['slug', 'inputs'] });

  const [searchString, updateSearchString] = useState<string>('');

  const { formulaInputs, formulaInputVariables, formulaInputsFuse } = useMemo(() => {
    let formulaInputData: {
      formulaInputs: Array<FormulaInputVariable>;
      formulaInputVariables: Record<Variable, string>;
    };
    if (inputs.length === 0) {
      const measurements = [...(state.preset?.measurements ?? [])];
      measurements.splice(index, 1, formMethods.getValues());
      const { measurementDependencyGraph } = generateMeasurementDependencyGraph(state.preset as PresetCreateOrUpdateV1);
      const currentMeasurementChildren = measurementDependencyGraph.get(slug)?.children ?? new Set<string>();
      formulaInputData = measurements.reduce<{
        formulaInputs: Array<FormulaInputVariable>;
        formulaInputVariables: Record<Variable, string>;
      }>(
        (acc, measurement) => {
          if (measurement.slug !== slug && !currentMeasurementChildren.has(measurement.slug)) {
            const variable: Variable = `$${measurement.slug}`;
            acc.formulaInputs.push({
              name: measurement.name,
              slug: measurement.slug,
              unit: measurement.unit,
              variable,
            });
            acc.formulaInputVariables = { ...acc.formulaInputVariables, [variable]: measurement.name };
          }
          return acc;
        },
        { formulaInputs: [], formulaInputVariables: {} }
      );
    } else {
      formulaInputData = inputs.reduce<{
        formulaInputs: Array<FormulaInputVariable>;
        formulaInputVariables: Record<Variable, string>;
      }>(
        (acc, input) => {
          const inputSlug = toSlug(input.name);
          const variable: Variable = `$${inputSlug}`;
          acc.formulaInputs.push({ name: input.name, slug: inputSlug, unit: input.unit, variable });
          acc.formulaInputVariables = { ...acc.formulaInputVariables, [variable]: input.name };
          return acc;
        },
        { formulaInputs: [], formulaInputVariables: {} }
      );
    }
    const formulaInputsFuse = new Fuse(
      formulaInputData.formulaInputs.sort((a, b) => a.name.localeCompare(b.name)),
      {
        includeScore: false,
        ignoreLocation: true,
        keys: [
          { name: 'name', weight: 0.9 },
          { name: 'slug', weight: 0.75 },
          { name: 'unit', weight: 0.05 },
        ],
      }
    );

    return { ...formulaInputData, formulaInputsFuse };
  }, [formMethods, index, slug, inputs, state.preset]);

  const filteredFormulaInputs = useMemo(() => {
    if (_isNotBlank(searchString)) {
      return formulaInputsFuse.search(searchString.trim()).map((r) => r.item);
    }
    return formulaInputs;
  }, [searchString, formulaInputs, formulaInputsFuse]);
  const addVariable = (variable: Variable | Operator) => {
    const currentFormula = formMethods.getValues('formula') ?? '';
    const updatedFormula = [
      currentFormula.substring(0, formulaInputCaretPositionRef.current),
      variable,
      currentFormula.substring(formulaInputCaretPositionRef.current),
    ].join('');
    formMethods.setValue('formula', updatedFormula);
  };

  return (
    <div className="flex flex-row" data-test-component="OutputForm" data-test-element="container">
      <div className="flex flex-column pa3 br">
        <div className={styles.measurementInputSearch}>
          <span className={styles.searchIcon}>
            <RiSearch2Line size="18" />
          </span>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="search"
            onChange={(event) => updateSearchString(event.target.value)}
          />
        </div>
        <div className={styles.measurementInputSearchList}>
          {filteredFormulaInputs.map((input) => (
            <div key={input.slug} className={styles.searchItem} onClick={() => addVariable(input.variable)}>
              <div className={styles.pill}>{input.name}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-column pa3 w-80">
        <div className="flex flex-column mb3">
          <h3 className="near-black">Calculation</h3>
          <p className="f6">
            Click on any items on the left to build your calculation.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.benchling.com/hc/en-us/articles/29260886232589-Creating-and-managing-presets"
              className="link blue"
            >
              Read more
            </a>
          </p>
        </div>
        <div className="flex flex-row">
          {operators.map((operator) => (
            <div className="formula_operator_pill" key={operator.label} onClick={() => addVariable(operator.value)}>
              {operator.label}
            </div>
          ))}
        </div>
        <Controller
          name="formula"
          control={formMethods.control}
          render={({ field, fieldState }) => (
            <>
              <FormulaInput
                value={field.value}
                invalid={fieldState.invalid}
                variables={formulaInputVariables}
                onChange={(value) => {
                  field.onChange(value);
                  formMethods.trigger();
                }}
                onBlur={() => formMethods.trigger()}
                caretPositionRef={formulaInputCaretPositionRef}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};
