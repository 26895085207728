import type { DataTableColumn, DataTableFormula } from '@/components/UI/DataTable/DataTable.model';
import { formulaVariablesFromColumnIds } from '@/components/UI/DataTable/DataTable.util';
import { _notNil } from '@/littledash';
import { FC, useEffect, useState } from 'react';

interface FormulaInputProps {
  formula: DataTableFormula | undefined;
  columns: Readonly<Array<DataTableColumn>>;
}

export const FormulaDisplay: FC<FormulaInputProps> = ({ formula, columns }) => {
  const [displayValue, setDisplayValue] = useState<string>('...');

  useEffect(() => {
    if (_notNil(formula)) {
      formulaVariablesFromColumnIds(formula.value, columns, { ignoreInvalidVariables: true })
        .then((resp) => setDisplayValue(resp))
        .catch(() => setDisplayValue('invalid formula'));
    }
  }, [formula]);

  return <>{displayValue}</>;
};
