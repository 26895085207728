// @ts-nocheck: converted from JS

import Show from './Show';
import { Route } from 'react-router-dom';
import Create from '@/components/Glossary/Sections/Approvals/Create';
import Edit from '@/components/Glossary/Sections/Approvals/Edit';

const Approvals = () => {
  const subRoutes = [
    {
      name: 'Show',
      route: '/team/studies/approvals',
      exact: true,
      isValid: () => true,
      Component: () => <Show />,
    },
    {
      name: 'Create',
      route: '/team/studies/approvals/create',
      exact: true,
      isValid: () => true,
      Component: () => <Create />,
    },
    {
      name: 'Edit',
      route: '/team/studies/approvals/:approvalTemplate/edit',
      exact: true,
      isValid: () => true,
      Component: () => <Edit />,
    },
  ];
  return (
    <>
      {subRoutes.map((subRoute) => (
        <Route
          key={subRoute.route}
          exact={subRoute.exact}
          path={subRoute.route}
          render={() => <subRoute.Component />}
        />
      ))}
    </>
  );
};

export default Approvals;
