// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';

const ErrorPage = ({ resetError }) => {
  return (
    <div className="flex items-center w-100 h-100">
      <div className="ui-card pa3 pa4-l mv6 tc mw6 center">
        <h2 className="normal f3 near-black pb3">An error has occurred.</h2>
        <p className="f4 pb3 lh-copy">
          Something has gone wrong. The error has been reported to our team, if this keeps occurring please contact
          support.
        </p>
        <div className="mt3 tc">
          <Button url={'mailto:support@benchling.com'} className="mr3">
            Contact support
          </Button>
          <Button
            onClick={() => {
              resetError();
              window.location.href = '/';
            }}
            plain
          >
            Back to dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
