import { ErrorMessage } from '@hookform/error-message';
import Button from '@/components/UI/Button';
import type { DataTableColumnAddRequest } from '@/components/UI/DataTable/DataTable.model';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import type { NumericTableColumnAddProps } from './AddColumn.model';
import { uniqueNameValidatorFactory, valueIsNumeric } from './AddColumn.util';

export const NumericTableColumnAdd: FC<Omit<NumericTableColumnAddProps, 'tableType'>> = ({
  tableUnit,
  toggleDropdownMenu,
  handleAddColumn,
  columns,
}) => {
  const defaultValues = { name: '' };
  const uniqueColumnName = useMemo(
    () => uniqueNameValidatorFactory(new Set(columns.map(({ name }) => name))),
    [columns]
  );
  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<Pick<DataTableColumnAddRequest, 'name'>>({
    mode: 'onChange',
    defaultValues,
  });
  const onSubmit = async ({ name }: Pick<DataTableColumnAddRequest, 'name'>) => {
    toggleDropdownMenu?.(false);
    reset(defaultValues);
    return handleAddColumn({ type: 'number', unit: null, name: name?.trim() ?? null });
  };
  return (
    <div className="pa3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div>
          <div className="flex relative">
            <input
              type="text"
              name="name"
              autoComplete="off"
              ref={register({ required: 'This field is required', validate: { valueIsNumeric, uniqueColumnName } })}
              placeholder="Name"
              className={errors?.name ? 'input__error' : ''}
              style={{ marginBottom: 0 }}
              autoFocus
            />
            <span className="ib w2 absolute right-1 tr" style={{ top: '25%' }}>
              <small>{tableUnit}</small>
            </span>
          </div>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <small className="red db pt2">{message}</small>}
          />
        </div>
        <div className="mt3">
          <Button stateless className="link db pl0" submit disabled={isSubmitting || !isValid}>
            Add column
          </Button>
          <Button
            stateless
            className="near-black db pl0 mt2"
            onClick={() => {
              toggleDropdownMenu?.(false);
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
