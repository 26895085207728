import { _notNil } from '@/littledash';
import { DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils';
import { format, isValid } from 'date-fns';
import { toDate } from 'date-fns-tz';

export const toWorkflowDateTime = (value: string): string => {
  if (_notNil(value) && DateUtils.isoDatePattern.test(value)) {
    const timeNow = format(
      toDate(DateUtils.dateTimeNow(), { timeZone: DateUtils.timezone() }),
      DateTimeRenderFormat.TimeWithSeconds
    );
    const date = toDate(`${value}T${timeNow}`, {
      timeZone: DateUtils.timezone(),
    });
    if (isValid(date)) {
      return date.toISOString();
    }
  }
  return '';
};
