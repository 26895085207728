// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Lookup from '@/components/UI/Lookup';
import RemoveButton from '@/components/UI/RemoveButton';
import Table from '@/components/UI/Table';
import { renderMetadataValue } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';

const projectRolesTableColumns = [
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
];

const projectMetadataTableColumns = [
  {
    Header: 'Name',
    accessor: 'title',
  },
  {
    Header: 'Value',
    accessor: 'value',
    Cell: ({ row: { original } }) => {
      return <>{renderMetadataValue(original)}</>;
    },
  },
];

const ProjectMetadataTable = ({ metadata = [] }) => <Table columns={projectMetadataTableColumns} data={metadata} />;

const ProjectRolesTable = ({ roles = [] }) => <Table columns={projectRolesTableColumns} data={roles} />;

const ProjectsField = ({ setProjectNameForSummary }) => {
  const {
    collection: initialProjects,
    collectionLoading: initialProjectsLoading,
    collectionError: initialProjectsError,
  } = useFetchCollection({
    collectionType: 'projects',
  });

  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projectsError, setProjectsError] = useState(false);

  const [selectedProject, setSelectedProject] = useState(undefined);
  const [selectedProjectLoading, setSelectedProjectLoading] = useState(false);
  const [selectedProjectError, setSelectedProjectError] = useState(false);

  const { newAbortController: newProjectGetAbortController } = useAbortController();
  const { newAbortController: newLoadOptionsAbortController } = useAbortController();
  const isMounted = useMountedState();

  const { control } = useFormContext();
  const projectIdWatch = useWatch({
    control,
    name: 'project_id',
  });

  const fetchProject = async (projectId) => {
    try {
      setSelectedProjectLoading(true);

      const {
        data: { data },
      } = await Http.get(apiRoute('projects.get', { projectId }), {
        signal: newProjectGetAbortController().signal,
      });
      if (isMounted()) {
        setSelectedProject(data);
        setSelectedProjectLoading(false);
      }
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setSelectedProjectError(error);
        setSelectedProjectLoading(false);
      }
    }
  };

  const loadOptions = async ({ inputValue, page }) => {
    // This could be called by AsyncPaginate after component has unmounted.
    if (!isMounted()) {
      return;
    }

    try {
      setProjectsLoading(true);
      let url = apiRoute('projects.list');
      url = `${url}?page=${page}`;

      if (inputValue) {
        url = `${url}&query=${inputValue}`;
      }

      const {
        data: { data, meta },
      } = await Http.get(url, { signal: newLoadOptionsAbortController().signal });
      if (isMounted()) {
        const { current_page: currentPage, last_page: lastPage } = meta;
        const hasMore = currentPage < lastPage;
        setProjectsLoading(false);
        return {
          options: data.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          hasMore,
        };
      }
      return {};
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setProjectsError(error);
        setProjectsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (projectIdWatch) {
      fetchProject(projectIdWatch);
    }
  }, [projectIdWatch]);

  useEffect(() => {
    if (selectedProject) {
      setProjectNameForSummary(selectedProject.name);
    } else {
      setProjectNameForSummary('');
    }
  }, [selectedProject]);

  const getDefaultValue = () => {
    if (selectedProject) {
      return {
        label: selectedProject.name,
        value: selectedProject.id,
      };
    }
  };

  const getValue = (projectId) => {
    let value = '';
    if (projectId && selectedProject) {
      value = {
        label: selectedProject.name,
        value: selectedProject.id,
      };
    }
    return value;
  };

  const projectRolesData = useMemo(() => {
    if (_isNotEmpty(selectedProject?.roles)) {
      return selectedProject.roles.map((value) => ({
        name: value.user.name,
        role: value.role.name,
      }));
    }
  }, [selectedProject]);

  if (initialProjectsLoading) {
    return null;
  }

  if (_isEmpty(initialProjects)) {
    return false;
  }

  if (initialProjectsError) {
    return <ApiErrorBanner errorType="fetch" error={initialProjectsError} />;
  }

  return (
    <>
      <Controller
        name="project_id"
        control={control}
        defaultValue={projectIdWatch}
        rules={{
          required: 'Project ID is required',
        }}
        render={({ value, onChange }: ControllerRenderProps) => (
          <>
            {projectsError && <ApiErrorBanner errorType="fetch" error={projectsError} />}
            <div>
              <label>Select a project</label>
            </div>
            <div className="flex justify-between hide-child">
              <div className="w-100">
                <Lookup
                  value={getValue(value)}
                  disabled={projectsLoading}
                  placeholder="Search or select..."
                  defaultValue={getDefaultValue()}
                  loadOptions={loadOptions}
                  handleSelect={(data) => onChange(data.value)}
                  name="project"
                />
                <ErrorMessage name="project_id" render={({ message }) => <p className="f6 red db pt2">{message}</p>} />
              </div>
              {selectedProject && (
                <RemoveButton
                  className="ml2 child"
                  onClick={() => {
                    onChange('');
                    setSelectedProject(undefined);
                  }}
                />
              )}
            </div>
          </>
        )}
      />

      <div className={`${selectedProjectLoading ? 'ui__disabled' : ''}`}>
        {selectedProjectError && <ApiErrorBanner errorType="fetch" error={selectedProjectError} />}
        {selectedProject && (
          <>
            {_isNotEmpty(selectedProject.metadata) && (
              <>
                <h4 className="f6 lh-title mv3">Metadata</h4>
                <ProjectMetadataTable metadata={selectedProject.metadata} />
              </>
            )}
            {_isNotEmpty(selectedProject.roles) && (
              <>
                <h4 className="f6 lh-title mv3">Roles</h4>
                <ProjectRolesTable roles={projectRolesData} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProjectsField;
