import { ReleaseInfo } from '@/generated/ReleaseInfo';
import { getSessionCorrelationId } from '@/littledash';
import type { ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import { datadogRum } from '@datadog/browser-rum';

export class DatadogUtils {
  static init() {
    if (AppConfig.datadogEnabled) {
      datadogRum.init({
        applicationId: AppConfig.datadogRumApplicationId as string,
        clientToken: AppConfig.datadogRumClientToken as string,
        site: 'datadoghq.com',
        service: 'overwatch-js',
        env: `overwatch-${AppConfig.environment}`,
        version: ReleaseInfo.releaseVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [AppConfig.allowedHostPattern],
      });
      datadogRum.setGlobalContextProperty('session_correlation', { id: getSessionCorrelationId() });
      datadogRum.setGlobalContextProperty('Commit', {
        commitId: ReleaseInfo.commitId,
        commitTime: ReleaseInfo.commitTime,
      });
      datadogRum.startSessionReplayRecording();
    }
  }

  static setUser(userId: ID, teamId: number, email: string) {
    if (AppConfig.datadogEnabled) {
      datadogRum.setUser({ id: `${userId}`, email, team: teamId });
    }
  }

  /**
   *
   * @param exception {unknown}
   * @param context {Record<string,any>|undefined}
   */
  static captureException(exception: unknown, context?: Record<string, any>) {
    if (AppConfig.datadogEnabled) {
      const appErrorContext = Object.entries(exception instanceof InVivoError ? exception.context : {}).reduce(
        (acc, [key, value]) => ({ ...acc, [`InVivoError: ${key}`]: value }),
        {}
      );

      datadogRum.addError(exception, {
        'Current URL': window.location.href,
        Online: navigator?.onLine ?? false,
        Focused: document.hasFocus(),
        'Visibility State': document.visibilityState,
        'Round Trip Time': `${navigator?.connection?.rtt ?? '-1'}ms`,
        ...appErrorContext,
        ...(context ?? {}),
      });
    } else {
      console.error('Datadog.captureException()', exception); // eslint-disable-line no-console
    }
  }
}
