// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Link from '@/components/UI/Link';

const NoData = (props) => {
  const { isFullScreen } = props;
  let content;
  if (isFullScreen) {
    content = (
      <PageWrapper>
        <NoDataMessage {...props} />
      </PageWrapper>
    );
  } else {
    content = <NoDataMessage {...props} />;
  }
  return content;
};

const PageWrapper = ({ children }) => <div className="w-100 h-100 flex items-center justify-center-l">{children}</div>;

const NoDataMessage = ({
  title,
  text,
  bannerTxt,
  link,
  btnTxt,
  NoDataComponent,
  actionButtons,
  children,
  onLinkClick,
}) => (
  <div className="ui-card mw6 mt4">
    <div className="pa3 measure">
      <h3 className="ma0 pb2 f5 f4-l normal lh-copy">{title}</h3>
      <p className="ma0 pb3 f5 lh-copy">{text}</p>
      {children}
      {bannerTxt && (
        <Banner info dismiss={false}>
          <p className="lh-copy f6 near-black">{bannerTxt}</p>
        </Banner>
      )}
      {NoDataComponent}
    </div>
    {actionButtons}
    {btnTxt && (
      <div className="pa3 bt b--moon-gray">
        {link ? (
          <Link to={link}>
            <button>{btnTxt}</button>
          </Link>
        ) : onLinkClick ? (
          <button onClick={onLinkClick}>{btnTxt}</button>
        ) : null}
      </div>
    )}
  </div>
);

export default NoData;
