// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { defaultPromiseErrorHandler, successToast } from '@/helpers';
import * as Auth from '@/support/auth';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { isClosed } from '@/support/study';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const DangerZone = ({ study }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };
  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const confirmDelete = () => {
    Http.delete(apiRoute('studies.destroy', { id: study.id }))
      .then(() => {
        history.push(webRoute('dashboard'));
        successToast(`Successfully deleted ${study.name}.`);
      })
      .catch(defaultPromiseErrorHandler);
  };

  const confirmArchive = () => {
    Http.post(apiRoute('studies.archive', { id: study.id }))
      .then(({ data }) => {
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
        closeModal();
        history.push(webRoute('studies.show', { id: study.id }));
        successToast(`Successfully ${study.archived_at ? 'resumed' : 'completed'} ${study.name}`);
      })
      .catch(defaultPromiseErrorHandler);
  };

  const confirmUnarchive = () => {
    Http.post(apiRoute('studies.unarchive', { id: study.id }))
      .then(({ data }) => {
        closeModal();
        history.push(webRoute('studies.show', { id: study.id }));
        successToast(`Successfully resumed ${study.name}`);
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
      })
      .catch(defaultPromiseErrorHandler);
  };

  const confirmCancel = () => {
    Http.post(apiRoute('studies.cancel', { id: study.id }))
      .then(({ data }) => {
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
        closeModal();
        history.push(webRoute('studies.show', { id: study.id }));
        successToast(`Successfully cancelled ${study.name}`);
      })
      .catch(defaultPromiseErrorHandler);
  };

  const confirmUncancel = () => {
    Http.post(apiRoute('studies.uncancel', { id: study.id }))
      .then(({ data }) => {
        closeModal();
        history.push(webRoute('studies.show', { id: study.id }));
        successToast(`Successfully resumed ${study.name}`);
        dispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
      })
      .catch(defaultPromiseErrorHandler);
  };

  const canPerformAction = Boolean(Auth.isAuthor(study) || Auth.isCurrentTeamOwner() || Auth.isOwner(study));

  return (
    <div className="w-100">
      <div className="ui-card overflow-hidden">
        {isClosed(study) ? (
          <div
            className={`bb pa4 b--moon-gray flex-l flex-wrap-l justify-between-l ${
              !canPerformAction && 'ui__disabled'
            }`}
          >
            <div>
              <h4 className="normal lh-title f5">Resume study</h4>
              <p className="lh-copy ma0 f6">
                Resuming this study will restore all of its functionality to the previous state for all invited
                participants.
              </p>
            </div>
            <Button
              plain
              onClick={() =>
                openModal('RESUME_STUDY', {
                  study,
                  onClick: study.archived_at ? confirmUnarchive : study.canceled_at ? confirmUncancel : () => {},
                  closeModal,
                })
              }
              disabled={!canPerformAction}
            >
              Resume study
            </Button>
          </div>
        ) : (
          <>
            <div
              className={`bb pa4 b--moon-gray flex-l flex-wrap-l justify-between-l ${
                (study.canceled_at || !canPerformAction) && 'ui__disabled'
              }`}
            >
              <div>
                <h4 className="normal lh-title f5">Complete study</h4>
                <p className="lh-copy ma0 f6">
                  {study.archived_at
                    ? 'Resuming this study will restore all of its functionality to the previous state for all invited participants.'
                    : 'Mark this study as complete to become read-only for all invited users'}
                </p>
              </div>
              <Button
                plain
                onClick={() =>
                  openModal('ARCHIVE_STUDY', {
                    study,
                    onClick: confirmArchive,
                    closeModal,
                  })
                }
                disabled={!canPerformAction}
              >
                {study.archived_at ? 'Resume study' : 'Complete study'}
              </Button>
            </div>

            <div
              className={`bb pa4 b--moon-gray flex-l flex-wrap-l justify-between-l ${
                (study.archived_at || !canPerformAction) && 'ui__disabled'
              }`}
            >
              <div>
                <h4 className="normal lh-title f5">Cancel study</h4>
                <p className="lh-copy ma0 f6">
                  {study.canceled_at
                    ? 'Resuming this study will restore all of its functionality to the previous state for all invited participants.'
                    : 'Mark this study as cancelled to become read-only for all invited users'}
                </p>
              </div>
              <Button
                plain
                onClick={() =>
                  openModal('CANCEL_STUDY', {
                    study,
                    onClick: confirmCancel,
                    closeModal,
                  })
                }
                disabled={!canPerformAction}
              >
                {study.canceled_at ? 'Resume study' : 'Cancel study'}
              </Button>
            </div>
          </>
        )}

        <div className={`pa4 flex-l flex-wrap-l justify-between-l ${!canPerformAction && 'ui__disabled'}`}>
          <div>
            <h4 className="normal lh-title f5">Delete study</h4>
            <p className="lh-copy ma0 f6">Once you delete a study, there is no going back. Please be certain.</p>
          </div>
          <Button
            onClick={() =>
              openModal('CONFIRM_DELETE_STUDY', {
                study,
                onClick: confirmDelete,
                closeModal,
              })
            }
            critical
            disabled={!canPerformAction}
          >
            Delete study
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DangerZone;
