// @ts-nocheck: converted from JS

import NumericDelta from '@/components/UI/NumericDelta';
import DataTable from '@/components/UI/Table';
import { formatNumber } from '@/helpers';
import { _isNil, _notNil } from '@/littledash';

const Table = ({ study, animal }) => {
  if (_isNil(animal?.latestMeasurement)) {
    return null;
  }

  const {
    settings: { calculations },
  } = study;

  const measurementColumns = [
    {
      Header: 'Measurement',
      accessor: 'name',
    },
    {
      id: 'value',
      Header: 'Value',
      accessor: ({ unit, id }) => {
        const value = animal?.latestMeasurement?.[id]?.value;
        return _notNil(value) ? `${formatNumber(value)} ${_notNil(unit) ? unit : ''}` : '-';
      },
    },
    {
      Header: '% change from first',
      accessor: ({ id }) => {
        const latestMeasurement = animal?.latestMeasurement?.[id];
        if (_isNil(latestMeasurement?.value_change)) {
          return '-';
        }

        const { value, value_change_from_first: valueChangeFromFirst } = latestMeasurement;
        return <NumericDelta value={value} valueChange={valueChangeFromFirst} className="f6" />;
      },
    },
    {
      Header: '% change from last',
      accessor: ({ id }) => {
        const latestMeasurement = animal?.latestMeasurement?.[id];
        if (_isNil(latestMeasurement?.value_change)) {
          return '-';
        }

        const { value, value_change: valueChange } = latestMeasurement;
        return <NumericDelta value={value} valueChange={valueChange} className="f6" />;
      },
    },
  ];
  return (
    <div className="ui-card mb3">
      <div className="overflow-table">
        <DataTable columns={measurementColumns} data={calculations} />
      </div>
    </div>
  );
};

export default Table;
