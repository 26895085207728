// @ts-nocheck: converted from JS

import { defaultPromiseErrorHandler } from '@/helpers';
import { useEffect, useState } from 'react';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';

const useScopes = () => {
  const [loading, setLoading] = useState(false);
  const [scopes, setScopes] = useState([]);

  useEffect(() => {
    setLoading(true);
    Http.get(apiRoute('credentials.scopes.get'))
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setScopes(data);
        }
      })
      .catch(defaultPromiseErrorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    scopes,
    loading,
  };
};

export default useScopes;
