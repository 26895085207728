import ApiErrorBanner from '@/components/ApiErrorBanner/ApiErrorBanner';
import { NoAuthHeader } from '@/components/Header';
import Banner from '@/components/UI/Banner';
import Link from '@/components/UI/Link';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { ErrorMessage } from '@hookform/error-message';
import { type FC, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form@latest';
import './PasswordForget.scss';
import Button from '../UI/Button';

interface FormValues {
  email: string;
}

interface PasswordForgetFormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  isLoading: boolean;
}

const PasswordForget: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResetSuccess, setResetSuccess] = useState(false);
  const [apiError, setApiError] = useState<Error>();
  const isMounted = useMountedState();
  const formMethods = useForm();
  const { reset } = formMethods;

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await Http.post(apiRoute('password_resets.create'), values);
      reset();
      if (isMounted()) {
        setResetSuccess(true);
      }
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setApiError(error as Error);
      }
    } finally {
      if (isMounted()) {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <NoAuthHeader />
      <div className="password-forget center">
        <header className="ui-title-bar-container">
          <h1 className="f3 pb2">Forgot your password?</h1>
          <p className="f5 pb3">Enter your email address and we&#39;ll send you instructions on how to reset it</p>
        </header>

        <div className="ui-card pa3">
          {isResetSuccess && (
            <Banner success className="mw6 mb3 tl" dismiss={false}>
              <h3 className="normal lh-title f5 pb1">We have e-mailed your password reset link</h3>
              <p className="f6 lh-copy">
                Follow the instructions in the email we've sent to your address to reset your password.
              </p>
            </Banner>
          )}
          {apiError && (
            <ApiErrorBanner
              title="There was an error submitting your request"
              text="Please try again later. If this keeps occurring please contact support."
              error={apiError}
              className="mb4"
            />
          )}
          <FormProvider {...formMethods}>
            <PasswordForgetForm onSubmit={onSubmit} isLoading={isLoading} />
          </FormProvider>
          <Link to={webRoute('login')} className="link pv4 db">
            Cancel
          </Link>
        </div>
      </div>
    </>
  );
};

export const PasswordForgetForm: FC<PasswordForgetFormProps> = ({ onSubmit, isLoading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<FormValues>();
  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="password-forget-form">
      <div className={errors.email && 'error'}>
        <input
          type="text"
          placeholder="Email"
          autoComplete="email"
          data-testid="password-forget-email-field"
          {...register('email', {
            required: "Email can't be blank",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Email is not a valid email',
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p data-testid="password-forget-error-message" className="f6 red db pt2">
              {message}
            </p>
          )}
        />
      </div>
      <Button className="w-100" disabled={isLoading} submit testId="password-forget-submit">
        Email instructions
      </Button>
    </form>
  );
};

export default PasswordForget;
