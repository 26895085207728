import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { downloadFile } from '@/components/UI/Table/Reusable/APITable/APITable.utils';
import { successToast } from '@/helpers';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import type { DataTableErrors, DataTableService } from './DataTable.model';
import { DataTableToolbar } from './toolbar/DataTableToolbar';

interface DataTableHeaderProps {
  dataTableService: DataTableService;
  dataTableErrors: DataTableErrors;
}

const DataTableHeader: FC<DataTableHeaderProps> = ({ dataTableService, dataTableErrors }) => {
  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();
  const { id: studyId, dataTableId } = useParams<{ id: string; dataTableId: string }>();

  const handleExportDataTable = async () => {
    try {
      const { data, headers } = await Http.get<Record<string, any>>(
        apiRoute('datatables.export', { studyId, dataTableId }),
        { signal: newAbortController().signal, responseType: 'blob' }
      );

      if (isMounted()) {
        downloadFile({ data, headers });
        successToast(`Downloaded ${headers['file-name']}`);
      }
    } catch (error: any) {
      // intentionally empty
    }
  };

  return (
    <div data-test-component="DataTableHeader" data-test-element="container">
      <div className="flex justify-between items-center ph3 pv2 bb b--moon-gray bg-white ">
        <h3 className="f6 lh-title near-black fw5" data-test-component="DataTableHeader" data-test-element="name">
          {dataTableService.name}
        </h3>
        <Button onClick={handleExportDataTable}>Export</Button>
      </div>
      {dataTableService.readonly && (
        <Banner className="mw6 ma3" info>
          <h3 className="f5 lh-title">You don't have access to edit this Datatable.</h3>
          <p className="f6 lh-title measure mt2">Request 'Write' access from the Study author or owner. </p>
        </Banner>
      )}
      <div className="bg-white">
        <DataTableToolbar dataTableService={dataTableService} errors={dataTableErrors} />
      </div>
    </div>
  );
};

export default DataTableHeader;
