// @ts-nocheck: converted from JS

const TableHeader = ({
  id,
  isSorted,
  isSortedDesc,
  sortDisabled,
  sortBy,
  getHeaderProps,
  getSortByToggleProps,
  manualSort,
  getResizerProps,
  isResizing,
  headerProps,
  render,
  setManualSort,
}) => {
  let isSorting = isSorted;
  let isSortDesc = isSortedDesc;
  let sortProps = getSortByToggleProps ? getSortByToggleProps(headerProps) : {};
  const { key, ...headerPropsSpread } = getHeaderProps();

  if (manualSort) {
    isSorting = manualSort['sort'] === sortBy;
    isSortDesc = manualSort.order === 'desc';
  }
  if (manualSort || sortDisabled) {
    sortProps = {};
  }

  return (
    <div
      className="th"
      key={key}
      {...headerPropsSpread}
      data-test-component="TableHeader"
      data-test-element="container"
      data-test-key={id}
    >
      <div
        onClick={() => {
          if (manualSort && !sortDisabled) {
            setManualSort({
              sort: sortBy,
              order: manualSort.order === 'asc' ? 'desc' : 'asc',
            });
          }
        }}
      >
        <div {...sortProps} className="header-content">
          {render('Header')}
          {!sortDisabled && isSorting && (
            <span className={`ui__sort-indicator ${isSortDesc ? '-sort-desc' : '-sort-asc'}`} />
          )}
        </div>
      </div>
      <div
        {...getResizerProps()}
        className={`ui__resizer ${isResizing ? 'isResizing' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </div>
  );
};

export default TableHeader;
