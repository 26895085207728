import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import type { State } from '@/model/State.model';
import { web as webRoute } from '@/support/route';
import { useSelector } from 'react-redux';
import UpdatePassword from './UpdatePassword';
import UpdateAccount from './UpdateAccount';

const Account = () => {
  const sso_enabled = useSelector(({ team: { team } }: State) => team?.sso_enabled ?? false);

  return (
    <div>
      <SubHeader linkToText="Dashboard" link={webRoute('dashboard')} />
      <div className="ph4 pv3">
        <Header mainHeaderText="Account" />
        <div className="mv3 ui-card mw7">
          <Tabs>
            <div className="pt3 ph4 bb b--moon-gray">
              <Tab className="f5">Profile</Tab>
              {!sso_enabled && <Tab className="f5">Password</Tab>}
            </div>
            <div>
              <Panel>
                <UpdateAccount />
              </Panel>
              {!sso_enabled && (
                <Panel>
                  <UpdatePassword />
                </Panel>
              )}
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Account;
