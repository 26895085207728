// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox/Checkbox';
import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import { errorToast, infoToast, successToast } from '@/helpers';
import { _isEmpty, _isNil, _notNil } from '@/littledash';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';

const Export = () => {
  const [loading, setLoading] = useState(false);
  const [auditLogGenerating, setAuditLogGenerating] = useState(false);
  const [apiError, setApiError] = useState(false);
  const isMounted = useMountedState();

  const { id: studyId } = useParams();
  const {
    entity: study,
    entityLoading: studyLoading,
    entityError: studyLoadError,
  } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,study_groups',
  });

  const exportTypes = [
    {
      name: 'master',
      label: 'Raw study data',
      subLabel: 'All animals and their measurements',
    },
    {
      name: 'summary',
      label: 'Study group report',
      subLabel: 'Includes relative values',
      disabled: _isEmpty(study?.study_groups),
    },
    {
      name: 'prism',
      label: 'Prism report',
      subLabel: 'Including relative values in PZFX format',
      disabled: _isEmpty(study?.study_groups),
    },
    {
      name: 'dosing',
      label: 'Study dosing',
      subLabel: 'Includes study groups and treatments',
    },
  ];

  const { handleSubmit, control, watch } = useForm({
    defaultValues: exportTypes.reduce((acc, v) => {
      acc[v.name] = false;
      return acc;
    }, {}),
  });

  const handleDownloadFile = (data, headers) => {
    const fileName = headers['file-name'];
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handlePayload = (obj) => {
    const types = Object.keys(obj).reduce((acc, key) => {
      if (obj[key]) {
        acc.push(key);
      }
      return acc;
    }, []);
    return { types };
  };

  const onDownloadFile = async (fields) => {
    setLoading(true);
    try {
      const { data, headers } = await Http({
        method: 'post',
        url: apiRoute('studies.export.create.download', {
          id: study.id,
        }),
        data: handlePayload(fields),
        responseType: 'blob',
      });
      handleDownloadFile(data, headers);
      successToast(`Successfully exported ${study.name}.`);
      setLoading(false);
    } catch (error) {
      setApiError(error);
      setLoading(false);
    }
  };

  const handleEmailExport = async (fields) => {
    setLoading(true);
    try {
      await Http({
        method: 'post',
        url: apiRoute('studies.export.create.email', {
          id: study.id,
        }),
        data: handlePayload(fields),
      });
      successToast(`Successfully exported ${study.name}.`);
      setLoading(false);
    } catch (error) {
      setApiError(error);
      setLoading(false);
    }
  };

  const generateAuditLogReport = async () => {
    try {
      setAuditLogGenerating(true);
      await Http({
        method: 'get',
        url: apiRoute('studies.export.audit-log', {
          id: study.id,
        }),
      });
      infoToast(`Generating audit log. You will recieve an email when complete.`);
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setApiError(error);
        errorToast('Could not create audit log report');
      }
    } finally {
      if (isMounted()) {
        setAuditLogGenerating(false);
        setLoading(false);
      }
    }
  };

  const onSubmit = (fields, _, emailFile = false) => {
    if (emailFile) {
      handleEmailExport(fields);
    } else {
      onDownloadFile(fields);
    }
  };

  const fields = watch(exportTypes.map((type) => type.name));
  const disabled = Object.values(fields).every((field) => !field);

  if (studyLoading || loading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }
  if (_isNil(study) && _notNil(studyLoadError)) {
    return <ApiErrorBanner errorType={ApiErrorType.FETCH} error={studyLoadError} />;
  }

  return (
    <div className="pb3">
      <SubHeader linkToText={study?.name} link={webRoute('studies.show', { id: study?.id })} />
      <div className="ph4">
        <Header mainHeaderText={`Export ${study?.name}`} />
        <div className="ui-card mt3 ph3 pb3 mw6">
          {apiError && <ApiErrorBanner error={apiError} />}
          <form>
            <div>
              {exportTypes.map((type) => (
                <div className="mv3 pb3 bb" key={type.name}>
                  <Controller
                    control={control}
                    name={type.name}
                    render={({ onChange, value }: ControllerRenderProps) => {
                      return (
                        <Checkbox
                          label={type.label}
                          sublabel={type.subLabel}
                          id={type.name}
                          disabled={type?.disabled || false}
                          name={type.name}
                          onChange={(event) => {
                            onChange(event.target.checked);
                          }}
                          checked={value}
                        />
                      );
                    }}
                  />
                </div>
              ))}
            </div>
            <Button disabled={disabled || loading} onClick={handleSubmit(onSubmit)}>
              Download
            </Button>
            <Button
              disabled={disabled || loading}
              onClick={handleSubmit((fields) => onSubmit(fields, '_', true))}
              className="ml3 plain"
            >
              Send to your email
            </Button>
          </form>
        </div>

        <div className="ui-card pa3 mt4 mw6" data-testid="export-audit-log-section">
          <h3 className="f5 normal pb2">Generate audit log</h3>
          <p className="pb3 bb f6 ma0 dark-gray">
            A historical log of samples, measurements, observations etc. Added automatically to the{' '}
            <a className="link underline-hover" href={webRoute('studies.attachments', { id: study.id })}>
              Attachments
            </a>{' '}
            section
          </p>
          {apiError && <ApiErrorBanner error={apiError} />}
          <form className="pt3">
            <Button className="plain" disabled={auditLogGenerating || loading} onClick={generateAuditLogReport}>
              Generate audit log
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Export;
