// @ts-nocheck: converted from JS
import ReactSelect from '@/components/UI/Select';
import { _isEmpty, safelyDecodeURIComponent } from '@/littledash';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, useFormContext } from 'react-hook-form';

export const renderLabel = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value.map(safelyDecodeURIComponent).join(', ');
    }
    return safelyDecodeURIComponent(value);
  }
  return '';
};

export const defaultValue = (field, isMulti) => {
  if (field?.value) {
    if (isMulti) {
      return field.value.map((f) => ({
        label: renderLabel(f),
        value: f,
      }));
    } else {
      return {
        label: renderLabel(field.value),
        value: field.value,
      };
    }
  }
};

export const handleChange = (onChange, data, isMulti) => {
  if (data) {
    if (isMulti) {
      onChange(data.map((d) => d.value));
    } else {
      onChange(data.value);
    }
  } else {
    onChange('');
  }
};

export const Select = ({ field, isMulti = false, testId = 'select' }) => {
  const { control } = useFormContext();

  return (
    <div data-testid={testId}>
      <Controller
        defaultValue={field.value}
        name={`metadata[${field.slug}].value`}
        rules={{
          validate: {
            // Explicit function required, short-circuit causes an error overflow
            required: (value) => {
              if (field.required && _isEmpty(value)) {
                return 'This field is required';
              }
            },
          },
        }}
        control={control}
        render={({ onChange }) => (
          <ReactSelect
            defaultValue={defaultValue(field, isMulti)}
            options={field.options.map((value) => ({
              value: value,
              label: renderLabel(value),
            }))}
            isMulti={isMulti}
            onChange={(data) => handleChange(onChange, data, isMulti)}
          />
        )}
      />
    </div>
  );
};
