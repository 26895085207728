// @ts-nocheck: converted from JS

import './CardColumn.scss';
import { useDrop } from 'react-dnd';
import { DraggableTypes } from '@/constants/draggable';
import Checkbox from '@/components/UI/FormElements/Checkbox';

const CardColumn = ({
  children,
  className,
  title,
  footer,
  onDrop,
  placeholderCard,
  isSelectable,
  hasSpace = true,
  onSelect,
  actionMenu,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept: DraggableTypes.card,
      canDrop: () => hasSpace,
      drop: (item, monitor) => onDrop(item, monitor),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [hasSpace]
  );

  const canDropFirstCard = (!children || children.length === 0) && isOver && canDrop;
  const hasContent = canDropFirstCard || Boolean(children.length);
  const placeholder = placeholderCard ? (
    <div style={{ opacity: 0, height: 100 }}>{placeholderCard}</div>
  ) : (
    <div style={{ width: '100%', height: '200px' }} />
  );

  return (
    <div
      ref={drop}
      className={`overflow-hidden ba bg-light-silver br2 ${className} ${
        isOver && canDrop ? 'b--blue' : 'b--moon-gray'
      }`}
    >
      <div className={'bg-white pa2 ph3 flex flex-row items-center justify-between'}>
        <div className={'flex flex-row items-center'}>
          {isSelectable && (
            <Checkbox
              className={'dib mr2'}
              type="checkbox"
              name={`${title}-checkbox`}
              id={`${title}-checkbox`}
              onChange={onSelect}
            />
          )}
          <span className={'f5 lh-title'}>{title}</span>
        </div>
        {actionMenu && <div>{actionMenu}</div>}
      </div>
      <div className={`card-column-content ${hasContent && 'pa2'}`}>
        {canDropFirstCard || !hasContent ? placeholder : children}
      </div>
      <div className={`bg-white pa2 ${!hasSpace ? 'ui__disabled' : ''}`}>{footer}</div>
    </div>
  );
};

export default CardColumn;
