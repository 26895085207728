import CompletedRequests from '@/components/Requests/CompletedRequests';
import PendingRequests from '@/components/Requests/PendingRequests';
import Header from '@/components/UI/Header';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';

const Requests = () => {
  return (
    <div className="ph4">
      <Header mainHeaderText="Requests" />
      <div className="mt4">
        <Tabs>
          <div className="mb4 bb b--moon-gray flex">
            <Tab className="f4">Pending</Tab>
            <Tab className="f4">Completed</Tab>
          </div>
          <div className="pb4">
            <Panel>
              <PendingRequests />
            </Panel>
            <Panel>
              <CompletedRequests />
            </Panel>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default Requests;
