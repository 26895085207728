import NoDataCard from '@/components/NoDataCard';
import UserAvatar from '@/components/UI/UserAvatar';
import { _isEmpty } from '@/littledash';
import type { ISODateTime } from '@/model/Common.model';
import type { RequestComment } from '@/model/Request.model';
import type { BaseUser } from '@/model/User.model';
import { sortDescByProperty } from '@/utils/array';
import { DateUtils } from '@/utils/Date.utils';
import AddCommentForm from './AddCommentForm';

interface CommentsProps {
  comments?: Array<RequestComment>;
  onAddComment: (content: string) => void;
  canAddComment?: boolean;
}

const Comments = ({ comments = [], onAddComment, canAddComment = false }: CommentsProps) => (
  <>
    {canAddComment && <AddCommentForm onAddComment={onAddComment} />}
    {!_isEmpty(comments) ? (
      <>
        {comments.sort(sortDescByProperty('id')).map((comment) => (
          <Comment {...comment} key={comment.id} />
        ))}
      </>
    ) : (
      <NoDataCard title="" text="No comments have been added yet." />
    )}
  </>
);

interface CommentProps {
  body: string;
  created_at: ISODateTime;
  updated_at?: ISODateTime;
  author: BaseUser;
}

const Comment = ({ body, created_at, updated_at, author }: CommentProps) => (
  <div className="flex flex-wrap relative pl4 mb4">
    <div className="absolute top-0 left-0">
      <UserAvatar user={author} />
    </div>
    <div className="pa3 bg-white br2 br--top--left ml2 w-100">
      <div className="f7 lh-title pb2">
        {author.name} &middot; {DateUtils.renderDateTime(updated_at ?? created_at)}
      </div>
      <p className="lh-copy f6 near-black break-word" style={{ whiteSpace: 'pre-line' }}>
        {body}
      </p>
    </div>
  </div>
);

export default Comments;
