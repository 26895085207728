import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { addAdditionalProperties, TreatmentFromForm } from '@/components/Studies/Treatments/Treatment.utils';
import { TreatmentsTable } from '@/components/Studies/Treatments/TreatmentsTable';
import { successToast } from '@/helpers';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { Study } from '@/model/Study.model';
import type { Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

interface TreatmentsProps {
  study: Study;
  metadata?: Array<MetadataField>;
}

export const Treatments: React.FC<TreatmentsProps> = ({ study, metadata }) => {
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);
  const [updatingTreatment, setUpdatingTreatment] = useState<boolean>(false);
  const [apiError, setAPIError] = useState<Error | undefined>();

  const { collection: groups, collectionLoading: groupsLoading } = useFetchCollection<TreatmentGroup>({
    collectionType: 'studyGroups',
    params: { id: study.id },
    queryParams: { perPage: -1 },
  });

  const {
    sendRequest: addTreatments,
    requestSending: addingTreatments,
    requestError: requestAddError,
  } = useRequest<Treatment>({
    route: 'studies.treatments',
    method: 'post',
    params: { studyId: study.id },
  });

  if (groupsLoading) {
    return (
      <div className="mv4 ui-card pa4">
        <Loading />
      </div>
    );
  }

  const saveTreatment = async (newTreatment: Treatment) => {
    await addTreatments({ ...newTreatment });
    if (!requestAddError) {
      successToast('Successfully created treatment');
    } else {
      setAPIError(requestAddError);
    }
    closeModal();
  };

  const updateTreatment = async (treatmentData: Treatment, treatmentId: ID) => {
    try {
      setUpdatingTreatment(true);
      await Http.patch(
        apiRoute('studies.treatment', {
          studyId: study.id,
          treatmentId,
        }),
        {
          ...treatmentData,
        }
      );
      successToast('Successfully updated treatment');
      closeModal();
    } catch (error) {
      setAPIError(error as Error);
      closeModal();
    }
    setUpdatingTreatment(false);
  };

  const onSubmit = async (formItems: TreatmentFromForm, edit: boolean, treatmentId: ID, treatment?: Treatment) => {
    const updatedTreatment = addAdditionalProperties(formItems, metadata, treatment);

    if (edit) {
      await updateTreatment(updatedTreatment, treatmentId);
    } else {
      await saveTreatment(updatedTreatment);
    }
  };

  const handleUpdateTreatments = (_: React.MouseEvent<Element, MouseEvent>, treatment?: Treatment): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      openModal('ADD_TREATMENTS_FORM', {
        treatment: _notNil(treatment) ? treatment : null,
        metadata,
        onSubmit: (formItems: TreatmentFromForm, edit: boolean, treatmentId: ID) =>
          onSubmit(formItems, edit, treatmentId, treatment)
            .then(() => resolve(true))
            .catch(() => resolve(false)),
        onCancel: () => {
          closeModal();
          resolve(false);
        },
      });
    });

  return (
    <>
      {apiError ? (
        <ApiErrorBanner className="mb4" errorType={ApiErrorType.SUBMIT} error={apiError} />
      ) : (
        <div className={`mv4 ${addingTreatments || updatingTreatment ? 'ui__disabled' : ''}`}>
          <TreatmentsTable
            study={study}
            metadata={metadata}
            handleAddOrUpdateTreatmentsClick={handleUpdateTreatments}
            groups={groups}
          />
        </div>
      )}
    </>
  );
};
