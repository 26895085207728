// @ts-nocheck: converted from JS

import { missingNameUseEmail } from '@/helpers';
import { getNumberWithOrdinal } from '@/utils/number';
import { useSelector } from 'react-redux';

const Approvers = ({ approvers }) => {
  const { team } = useSelector(({ team: { team } }) => ({
    team,
  }));
  const { users } = team;

  const getApproverName = (id) => missingNameUseEmail(users.find((user) => user.id === id)).name;
  const ordered = approvers.type === 'ordered';

  return (
    <div>
      {approvers.ids.map((approverId, i) => (
        <div key={approverId}>
          {ordered ? `${getNumberWithOrdinal(i + 1)} ` : ''}
          {getApproverName(approverId)}
        </div>
      ))}
    </div>
  );
};

export default Approvers;
