// @ts-nocheck: converted from JS

import { groupTreatmentEntityMap } from '@/components/Studies/Create/Steps/GroupsAndTreatments/GroupCreation';
import Button from '@/components/UI/Button';
import Link from '@/components/UI/Link';
import { capitalise, hasOwnProperty, renderMetadataValue } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { DisplayedGroupMetadata } from '@/model/Metadata.model';
import { typeToTextMap } from '@/referenceData/study/type';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { DateUtils } from '@/utils/Date.utils';
import { Fragment, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReviewTable from '../Groups/ReviewTable';
import { TasksV2 } from '../Tasks/TasksV2';
import { BENCHLING_INTEGRATION_META_SLUG } from '@/helpers.constants.tsx';

const ConfirmStudy = ({ state, dispatch, formMeta }) => {
  const {
    name,
    users,
    owner,
    collections,
    notes,
    groups,
    groups_treatments,
    treatments,
    alerts,
    average_calculation,
    error_deviation,
    started_on,
    task_template_group_id,
    study_code,
    links,
    files,
  } = state.study;

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);
  const groupsWithTreatments = groupTreatmentEntityMap(groups, treatments, groups_treatments);
  const { collection: groupMetadata } = useFetchCollection<DisplayedGroupMetadata>({
    collectionType: 'teamStudiesGroups',
  });

  const { currentUser, team, features } = useSelector(({ user: { currentUser }, team: { team, features } }) => ({
    currentUser,
    team,
    features,
  }));

  const getPreset = () => {
    if (state.form.selectedPreset) {
      return state.form.selectedPreset.title;
    } else if (state.study.preset_id) {
      const selectedPreset = state.form.presets.find((preset) => preset.id === state.study.preset_id);
      return selectedPreset.title;
    }
    return '-';
  };

  const countAnimals = () => {
    let animals = 0;
    if (_isNotEmpty(collections)) {
      collections.forEach((c) => {
        animals = Number(c.subjects) + animals;
      });
    }
    return animals;
  };

  const templateGroup = state.form.templates.find((t) => Number(t.id) === Number(task_template_group_id));

  const INFORMATION_SECTION = {
    name: 'Information',
    fields: [
      {
        label: 'Study name',
        value: name,
      },
      {
        label: 'Type',
        value: typeToTextMap[formMeta.type],
      },
      {
        label: 'Description',
        value: notes,
      },
      {
        label: 'Study Code',
        value: _notNil(study_code)
          ? `${study_code?.title} (Template: ${study_code.composites
              .sort((comp_a, comp_b) => comp_a.order - comp_b.order)
              .reduce((acc, code) => acc + code.value, '')})`
          : undefined,
      },
      {
        label: 'Project',
        value: state?.form?.selectedProjectName,
      },
      {
        label: 'Start date',
        value: DateUtils.renderDate(state?.study?.started_on, { defaultResponse: '-' }),
      },
    ],
  };

  const TEAM_SECTION = {
    name: 'Team',
    fields: [
      {
        label: 'Members',
        render: () => {
          let ownerEmail = '';
          if (owner) {
            ownerEmail = team.users.find((user) => Number(owner) === Number(user.id))?.email;
          }
          return (
            <div className="pa4" data-test-element="team-members-container">
              <UserAccess name={currentUser.email} access="author" />
              {owner && <UserAccess name={ownerEmail} access="owner" />}
              {users?.map((user) => <UserAccess key={user.name} name={user.email} access={user.access} />)}
            </div>
          );
        },
      },
    ],
  };

  const requiredSections = [INFORMATION_SECTION, TEAM_SECTION];

  const taskFields = !(features?.hide_tasks ?? false)
    ? [{ label: 'Tasks', render: () => <TasksV2 state={state} dispatch={dispatch} isSummary /> }]
    : [
        {
          label: 'Template',
          value: templateGroup && (hasOwnProperty(templateGroup, 'name') ? templateGroup.name : '—'),
        },
        {
          label: 'Study start date',
          value: DateUtils.renderDate(started_on),
        },
      ];

  const standardSections = [
    {
      name: 'Cages and animals',
      default_section_number: 1,
      fields: [
        {
          label: 'Number of cages',
          value: Array.isArray(collections) ? collections.length : '0',
        },
        {
          label: 'Number of animals',
          value: countAnimals(),
        },
      ],
    },
    {
      name: 'Groups',
      default_section_number: 2,
      fields: [
        {
          label: 'Mean Calculation',
          value: average_calculation === 'arithmetic' ? 'Arithmetic mean' : 'Geometric mean',
        },
        {
          label: 'Variation Display',
          value: error_deviation === 'sd' ? 'Standard deviation' : 'Standard error of the mean',
        },
        {
          label: 'Treatment groups',
          render: () =>
            _isNotEmpty(groupsWithTreatments) ? (
              <ReviewTable groups={groupsWithTreatments} groupMetadata={groupMetadata} />
            ) : (
              <div className="pa4 f6 tc bg-near-white near-black">No groups have been added</div>
            ),
        },
      ],
    },
    {
      name: 'Preset',
      default_section_number: 3,
      fields: [
        {
          label: 'Selected',
          value: getPreset(),
        },
      ],
    },
    {
      name: 'Alerts',
      default_section_number: 4,
      fields: [
        {
          label: 'Total alerts',
          value: alerts.length,
        },
      ],
    },
    {
      name: 'Tasks',
      default_section_number: 5,
      fields: taskFields,
    },
    {
      name: 'Attachments',
      default_section_number: 6,
      fields: [
        {
          label: 'Total Links',
          render: () => (
            <div>
              <h4 className="normal f6 lh-title pt4 ph4 pb3">Links</h4>
              {_isNotEmpty(links) ? (
                links.map((link, i) => (
                  <div className={`lh-copy ${i + 1 !== links.length && 'bb b--moon-gray'}`} key={i}>
                    <div className="flex justify-between flex-wrap ph4 pv3">
                      <Link className="link blue" to={link.url} openTab href>
                        {link.name}
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="pa4 f6 tc bg-near-white near-black">No links have been added</div>
              )}
            </div>
          ),
        },
        {
          label: 'Total Files',
          render: () => (
            <div>
              <h4 className="normal f6 lh-title pt4 ph4 pb3">Files</h4>
              {_isNotEmpty(files) ? (
                files.map((file, i) => (
                  <div className={`lh-copy ${i + 1 !== files.length && 'bb b--moon-gray'}`} key={file.name}>
                    <div className="flex justify-between flex-wrap ph4 pv3">{file.name}</div>
                  </div>
                ))
              ) : (
                <div className="pa4 f6 tc bg-near-white near-black">No files have been added</div>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  const buildMetadataFields = (section, state) => {
    const formatValue = (metadataItem) => {
      if (metadataItem?.value) {
        return renderMetadataValue(metadataItem);
      }
      return '-';
    };
    const metadataSections = features?.benchling_folders
      ? Object.values(section.metadata).filter((metaItem) => metaItem.info.slug !== BENCHLING_INTEGRATION_META_SLUG)
      : Object.values(section.metadata);
    return (metadataSections ?? []).map((metadataItem) => {
      return {
        label: metadataItem.info.title,
        value: formatValue(
          state?.study?.metadata?.find((stateMetadataItem) => stateMetadataItem.glossary_id === metadataItem.info.id)
        ),
      };
    });
  };

  const overview = useMemo(() => {
    if (formMeta) {
      const sections = formMeta.sections.filter((section) => section.active);
      const overview = [
        ...requiredSections,
        ...sections.reduce((acc, section) => {
          if (section.type === 'standard') {
            acc.push(
              standardSections.find(
                (standardSection) => standardSection.default_section_number === section.default_section_number
              )
            );
          } else if (section.type === 'custom') {
            const metaFields = buildMetadataFields(section, state) ?? [];
            if (_isNotEmpty(metaFields)) {
              acc.push({
                name: section.name,
                fields: metaFields,
              });
            }
          }
          return acc;
        }, []),
      ];
      if (_isNotEmpty(state.study?.benchling_folder)) {
        const { name } = state.study.benchling_folder.value;
        overview.push({
          name: 'Benchling Integration',
          fields: [
            {
              label: 'Benchling Folder Name',
              value: name,
            },
          ],
        });
      }
      return overview;
    }
    return [];
  }, [formMeta, state?.study?.metadata, state?.study?.benchling_folder]);

  return (
    <div className="mw8">
      <div>
        {overview.map((o, i) => (
          <FieldPanel key={i} index={i} step={o} dispatch={dispatch} />
        ))}
      </div>
    </div>
  );
};

const FieldPanel = ({ index, step, dispatch }) => {
  const dlFieldClassNames = 'ma0 f6';
  return (
    <div
      className="ui-card mb3 overflow-hidden hide-child"
      data-testid="summary-field-panel"
      data-test-component="FieldPanel"
      data-test-element="container"
      data-test-key={step.name}
    >
      <div className="flex justify-between flex-wrap items-start ph4 pt4">
        <h3 className="normal f5 lh-title pb3" data-testid="summary-field-panel-title">
          {step.name}
        </h3>
        <Button plain className="child" onClick={() => dispatch({ type: 'jumpToStep', data: index })}>
          Edit
        </Button>
      </div>
      <div>
        {step.fields.map((item, index) => (
          <div
            className={`lh-copy ${index + 1 !== step.fields.length && 'bb b--moon-gray'}`}
            key={index}
            data-test-element="field-container"
            data-testid={`summary-${step.name.toLowerCase()}__${item.label}`}
          >
            {item.render ? (
              <Fragment>{item.render()}</Fragment>
            ) : (
              <div
                className="flex justify-between flex-wrap ph4 pv3"
                data-test-element="label-and-value-container"
                data-test-key={item.label}
              >
                <div className={`${dlFieldClassNames} w-30`} data-testid="summary-field-label">
                  {item.label}
                </div>
                <div
                  className={`${dlFieldClassNames} w-70 near-black overflow-auto`}
                  data-testid="summary-field-value"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  <FieldSection item={item} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const FieldSection = ({ item }) => {
  const { value } = item;
  let content = '—';
  if (Array.isArray(value) && value.length > 0) {
    content = value.map((v) => <p className="lh-copy">{v}</p>);
  }
  if (value !== '' && (typeof item.value === 'string' || typeof item.value === 'number')) {
    content = value;
  }
  return content;
};

const UserAccess = ({ name, access }) => (
  <p className="f6 lh-copy db w-100" data-test-component="UserAccess" data-test-key={name} data-testid="user-access">
    <span className="near-black pr2 dib w3">{capitalise(access)}:</span> {name}
  </p>
);

export default ConfirmStudy;
