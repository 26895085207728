// @ts-nocheck: converted from JS

import { useState } from 'react';
import Http from '@/support/http';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { api as apiRoute } from '@/support/route';
import Lookup from '@/components/UI/Lookup';
import Button from '@/components/UI/Button';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import { DateUtils } from '@/utils/Date.utils';
import DatePickerNative from '@/components/UI/DatePickerNative';

/** @deprecated */
const AddTask = ({ addNewTaskCallback, closeModal }) => {
  const [fetchError, setFetchError] = useState('');
  const [submissionError, setSubmissionError] = useState('');

  const formMethods = useForm({
    defaultValues: {
      studyId: '',
      description: '',
      date: DateUtils.dateNow(),
      status: 'incomplete',
    },
  });

  const {
    handleSubmit,
    register,
    control,
    errors,
    formState: { isSubmitting },
  } = formMethods;

  const submit = ({ date, studyId, description }) => {
    Http.post(apiRoute('tasks.create', { study: studyId }), {
      date,
      studyId,
      description,
    })
      .then((response) => {
        addNewTaskCallback(response.data.data);
        closeModal();
      })
      .catch((error) => {
        setSubmissionError(error);
      });
  };

  const loadOptions = async ({ inputValue, page }) => {
    let url = apiRoute('studies.active');
    url = `${url}?page=${page}`;

    if (inputValue) {
      url = `${url}&query=${inputValue}`;
    }
    try {
      const {
        data: {
          data,
          meta: { pagination },
        },
      } = await Http.get(url);
      const { current_page: currentPage, total_pages: lastPage } = pagination;
      const hasMore = currentPage < lastPage;
      return {
        options: data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        hasMore,
      };
    } catch (error) {
      setFetchError(error);
    }
  };

  return (
    <form className="ui__narrow-modal pa3" onSubmit={handleSubmit(submit)}>
      <div className="center pb3">
        <h3 className="normal f4 pb2 tc">Add a new task</h3>
        {submissionError && <ApiErrorBanner className="mb4" errorType={ApiErrorType.SUBMIT} error={submissionError} />}
        {fetchError && <ApiErrorBanner className="mb4" errorType={ApiErrorType.FETCH} error={submissionError} />}
      </div>
      <div>
        <div className="pb4">
          <label htmlFor="">Associated active study</label>
          <Controller
            name="studyId"
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange }: ControllerRenderProps) => (
              <Lookup
                loadOptions={loadOptions}
                placeholder="Choose a study"
                handleSelect={(data) => {
                  onChange(data.value);
                }}
              />
            )}
          />
          {errors.studyId && <p className="f6 red db pt2">Required</p>}
        </div>
        <div className="pb2">
          <label>Task description</label>
          <input
            ref={register({
              required: 'Required',
              minLength: {
                value: 3,
                message: 'Please enter more than 3 characters',
              },
              maxLength: {
                value: 50,
                message: 'Please enter less than 50 characters',
              },
            })}
            type="text"
            name="description"
            placeholder="E.g Dose animals"
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => <p className="f6 red db pb2">{message}</p>}
          />
        </div>
        <div className="pb3">
          <label>Date</label>
          <Controller
            name="date"
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange, value }: ControllerRenderProps) => (
              <DatePickerNative
                type="date"
                name="date"
                value={value}
                style={{ marginBottom: 0, width: '100%' }}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
      <div className="pt3 bt b--moon-gray">
        <Button submit className="w-100 mb2" disabled={isSubmitting} loading={isSubmitting}>
          Create new task
        </Button>
        <Button className="w-100" plain onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default AddTask;
