import { Cage } from '@/model/Cage.model.ts';
import { Reducer } from 'react';

type Actions =
  | {
      type: 'ingest:page';
      data: { data: Array<Cage>; meta: { pagination: { current_page: number; total_pages: number } } };
    }
  | { type: 'reset' };

interface State {
  pages: Array<Array<Cage>>;
  hasNextPage: boolean;
  nextPage: number;
}

export const initializer = (): State => {
  return {
    pages: [],
    nextPage: 1,
    hasNextPage: true,
  };
};

export const reducer: Reducer<State, Actions> = (prevState, action) => {
  switch (action.type) {
    case 'ingest:page': {
      const { current_page, total_pages } = action.data.meta.pagination;
      const { pages } = prevState;
      const hasNextPage = current_page < total_pages;
      const nextPage = hasNextPage ? current_page + 1 : -1;
      pages[current_page - 1] = action.data.data;
      return { ...prevState, pages, nextPage, hasNextPage };
    }
    case 'reset':
      return initializer();
    default:
      return prevState;
  }
};
