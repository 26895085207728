// @ts-nocheck: converted from JS

/**
 *
 * @param a
 * @param b
 * @returns {number}
 */
export const sortDesc = (a, b) => b - a;

/**
 *
 * @param a
 * @param b
 * @returns {number}
 */
export const sortAsc = (a, b) => a - b;

/**
 *
 * @param {string} property
 * @returns {function(*, *)}
 */
export const sortDescByProperty = (property) => (a, b) => b[property] - a[property];

/**
 *
 * @param {string} property
 * @returns {function(*, *)}
 */
export const sortAscByProperty = (property) => (a, b) => a[property] - b[property];

/**
 *
 * @param {string} property
 * @returns {function(*, *)}
 */
export const sortDescByDateStrProperty = (property) => (a, b) => new Date(b[property]) - new Date(a[property]);

/**
 *
 * @param {string} property
 * @returns {function(*, *)}
 */
export const sortAscByDateStrProperty = (property) => (a, b) => new Date(a[property]) - new Date(b[property]);

export const getRandomElement = (a: array) => {
  return a[~~(a.length * Math.random())];
};
