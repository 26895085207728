import type { CalendarFilters } from '@/components/Tasks/Calendar/Calendar.model.ts';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { SelectMenu } from '@/components/UI/SelectMenu';
import { _isEmptyString } from '@/littledash.ts';
import { TaskStatus } from '@/model/Task.model.ts';
import Fuse from 'fuse.js';
import { FC, useMemo, useState } from 'react';

interface StatusFilterProps {
  filters: CalendarFilters;
  onFilterChange: (filters: CalendarFilters) => void;
}

const statusItems: Array<{ value: TaskStatus; name: string }> = [
  { value: 'to_do', name: 'To do' },
  { value: 'in_progress', name: 'In progress' },
  { value: 'done', name: 'Done' },
];
const statusItemFuse = new Fuse(statusItems, {
  includeScore: false,
  ignoreLocation: true,
  keys: [{ name: 'to_do' }, { name: 'name' }],
});

export const StatusFilter: FC<StatusFilterProps> = ({ filters, onFilterChange }) => {
  const [searchText, updateSearchText] = useState<string>('');
  const items = useMemo(() => {
    if (_isEmptyString(searchText ?? '')) {
      return statusItems;
    }
    return statusItemFuse.search(searchText.trim()).map((result) => result.item);
  }, [searchText]);

  return (
    <div className="ui-card mb4" data-test-component="StatusFilter" data-test-element="container">
      <SelectMenu
        items={items}
        preSelectedValues={filters.status}
        onSearch={updateSearchText}
        onSelect={(status) => onFilterChange({ ...filters, status: status as Array<TaskStatus> })}
        searchPlaceholderText="Search by status"
        renderItem={({ name, value, isSelected, onChange }) => (
          <Checkbox label={name} checked={isSelected} onChange={onChange} value={value} />
        )}
        enableSelectAll={false}
      />
    </div>
  );
};
