import ApiErrorBanner, { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox/Checkbox';
import Header from '@/components/UI/Header/Header';
import { _isEmpty, _notNil } from '@/littledash';
import type { StudyCodeApiId, StudyCodeSequence } from '@/model/StudyCode.model';
import { ApiService } from '@/support/ApiService';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import { web as webRoutes } from '@/support/route';
import type { AxiosError } from 'axios';
import { type FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const StudyCodeEdit: FC = () => {
  const [title, setTitle] = useState<string>('');
  const [resetOnYear, setResetOnYear] = useState<boolean>(false);
  const [hasYearCode, setHasYearCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const { studyCodeApiId } = useParams<{ studyCodeApiId: StudyCodeApiId }>();
  const history = useHistory();
  const isMounted = useMountedState();

  const setCurrentStudyCode = async () => {
    setLoading(true);
    const currentCode = await ApiService.call({
      endpoint: 'GET /api/v1/team/study-codes/{studyCodeApiId}',
      path: { studyCodeApiId },
    });
    if (currentCode.type === 'success') {
      const castedCode = currentCode.body;
      setTitle(castedCode.title ?? '');
      setHasYearCode(castedCode.composites.some((comp): comp is StudyCodeSequence => comp.type === 'year'));
      setResetOnYear(
        castedCode.composites.find((comp): comp is StudyCodeSequence => comp.type === 'sequence')?.reset_on_year ??
          false
      );
    } else {
      setError(currentCode.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (_notNil(studyCodeApiId)) {
      setCurrentStudyCode();
    } else {
      setLoading(false);
    }
  }, [studyCodeApiId]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await ApiService.call({
        endpoint: 'PATCH /api/v1/team/study-codes/{studyCodeApiId}',
        path: { studyCodeApiId },
        body: {
          title,
          reset_on_year: resetOnYear,
        },
      });
      history.push(webRoutes('team.studies.study_codes'));
    } catch (ex) {
      if (isMounted()) {
        setError(ex as AxiosError);
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    history.push(webRoutes('team.studies.study_codes'));
  };

  const noTitle = _isEmpty(title);

  return (
    <>
      <div className="mb3">
        <Header mainHeaderText="Edit study code" />
        <p className="f6 pb2">Alter a study code template.</p>
      </div>
      {_notNil(error) ? (
        <ApiErrorBanner
          className="ma3"
          title="There was a problem fetching components"
          errorType={ApiErrorType.FETCH}
          error={error}
        />
      ) : !loading ? (
        <>
          <div className="ui-card mw7 pa3 mb3">
            <div className="mb2 w-50">
              <label htmlFor="title">Title</label>
              <input
                name="title"
                value={title}
                onChange={({ target: { value } }: { target: { value: string } }) => setTitle(value)}
                type="text"
              />
            </div>
            {hasYearCode ? (
              <Checkbox
                name="resetOnYear"
                label="Reset Sequence on Year"
                checked={resetOnYear}
                onChange={({ target: { checked } }: { target: { checked: boolean } }) => setResetOnYear(checked)}
              />
            ) : null}
          </div>
          <Button
            onClick={handleSubmit}
            className="mr2"
            tooltip={noTitle ? 'Title is required' : ''}
            disabled={noTitle}
          >
            Save
          </Button>
          <Button onClick={handleCancel} plain>
            Cancel
          </Button>
        </>
      ) : (
        <div className="w-100 vh-100">
          <Loading />
        </div>
      )}
    </>
  );
};

export default StudyCodeEdit;
