import Icon from '@/components/UI/Icon';
import { ChangeEvent, CSSProperties, FC, FocusEventHandler, ReactNode } from 'react';
import './Checkbox.scss';

interface CheckboxProps {
  index?: string | number;
  id?: string;
  name?: string;
  onChange?: (newValue: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  checked?: boolean;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  sublabel?: ReactNode;
  hideSublabel?: boolean;
  label?: string | number;
  value?: string;
  testPrefix?: string;
}

const Checkbox: FC<CheckboxProps> = ({ hideSublabel, testPrefix = 'checkbox', ...rest }) => (
  <div
    className={`${rest.className} ${rest.disabled ? 'ui__disabled' : ''}`}
    data-test-component="Checkbox"
    data-test-element="container"
  >
    <label htmlFor={rest.id} className="ui__form__checkbox flex items-top pointer dib mb0">
      <div data-testid={`${rest.name}-checkbox-container`}>
        <input {...rest} type="checkbox" data-test-component="Checkbox" data-test-element="checkbox-input" />
        <div className="ui__form__checkbox__custom" style={rest?.style ?? {}} data-testid={`${testPrefix}--input`}>
          {rest.checked && <Icon icon="tick" width="14" height="14" viewBox="0 0 20 20" className="near-black" />}
        </div>
      </div>
      {rest.label && (
        <div>
          <div className="lh-copy pl3">
            <span>{rest.label}</span>
            {rest.sublabel && (
              // visibility used to keep DOM space, to remove from DOM completely, use empty sublabel prop instead of hideSublabel
              <p className="f6 ma0 dark-gray" style={{ visibility: hideSublabel ? 'hidden' : 'visible' }}>
                {rest.sublabel}
              </p>
            )}
          </div>
        </div>
      )}
    </label>
  </div>
);

export default Checkbox;
