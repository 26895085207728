import Link from '@/components/UI/Link';
import Tooltip from '@/components/UI/Tooltip';
import { defaultPromiseErrorHandler, successToast } from '@/helpers';
import { _notNil } from '@/littledash';
import { web as webRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RiAlertFill, RiCloseCircleFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import GroupLabel from '../../GroupLabel';
import type {
  DataTableCellSelectionChangeEvent,
  DataTableCellStateChangeEvent,
  DataTableErrors,
  DataTableRow,
  DataTableRowHeaderStateChangeEvent,
  DataTableRowId,
  DataTableService,
} from '../DataTable.model';
import { DataTableEvent, DataTableRowHeaderId } from '../DataTable.model';
import styles from '../DataTable.module.scss';

interface DataTableRowHeaderProps {
  rowIndex: number;
  rowHeaderWidth: number;
  dataTableService: DataTableService;
  positionOffset: { x: number; y: number };
  errors: DataTableErrors;
}

interface DataTableRowHeaderStickyContainerProps {
  rowHeaderWidth: number;
}

interface DataTableRowColumnHeadersProps {
  dataTableService: DataTableService;
}

export const DataTableRowHeaderStickyContainer: FC<PropsWithChildren<DataTableRowHeaderStickyContainerProps>> = ({
  rowHeaderWidth,
  children,
}) => {
  return (
    <div className={styles['data-table-row-header-sticky-container']} style={{ width: rowHeaderWidth }}>
      {children}
    </div>
  );
};

interface RowHeaderComponentProps {
  headerId: DataTableRowHeaderId;
  row: DataTableRow | undefined;
  selected: boolean;
  error: boolean;
  studyId: string;
  handleRowDelete: () => void;
}

const RowHeaderComponent: FC<RowHeaderComponentProps> = ({ headerId, row, error, studyId, handleRowDelete }) => {
  switch (headerId) {
    case DataTableRowHeaderId.animalName:
      return (
        <Link
          to={webRoute('animals.show', {
            subject_id: row?.animal.id,
            id: studyId,
          })}
          className="link"
          openTab
        >
          {row?.animal?.name ?? ''}
        </Link>
      );
    case DataTableRowHeaderId.animalStudyGroup:
      if (_notNil(row?.animal?.study_group)) {
        return <GroupLabel className="f7 ui__group-label-trim" group={row?.animal?.study_group} />;
      }
      break;
    case DataTableRowHeaderId.animalCage:
      return <>{row?.animal?.cage?.name ?? ''}</>;
    case DataTableRowHeaderId.animalTail:
      return <>{row?.animal?.alt_ids?.tail ?? ''}</>;
    case DataTableRowHeaderId.animalEar:
      return <>{row?.animal?.alt_ids?.ear ?? ''}</>;
    case DataTableRowHeaderId.animalTag:
      return <>{row?.animal?.alt_ids?.tag ?? ''}</>;
    case DataTableRowHeaderId.animalDonor:
      return <>{row?.animal?.alt_ids?.donor ?? ''}</>;
  }
  return null;
};

export const DataTableRowHeader: FC<DataTableRowHeaderProps> = ({
  rowIndex,
  rowHeaderWidth,
  dataTableService,
  positionOffset,
  errors,
}) => {
  const { openModal } = modalAction(useDispatch());
  const row = useMemo<DataTableRow | undefined>(
    () => dataTableService.rowByIndex(rowIndex),
    [rowIndex, dataTableService]
  );
  const [rowHeaders, setRowHeaders] = useState(dataTableService.displayedRowHeaderIds());
  const [rowSelected, setRowSelected] = useState(false);
  const [errorInRow, setErrorInRow] = useState(false);

  useEffect(() => {
    if (_notNil(dataTableService) && _notNil(rowIndex) && _notNil(row)) {
      setRowSelected(dataTableService.rowSelected(rowIndex));
      setErrorInRow(dataTableService.rowHasError(row.id));

      const cellSelectionChangeListener = (event: DataTableCellSelectionChangeEvent) => {
        setRowSelected((previous) => {
          const current = event.detail.selection.containsRow(rowIndex);
          return previous === current ? previous : current;
        });
      };
      const cellStateChangeListener = (event: DataTableCellStateChangeEvent) => {
        setErrorInRow((previous) => {
          const current = event.detail.invalidRows.has(row.id);
          return previous === current ? previous : current;
        });
      };
      const rowHeaderStateChangeListener = (event: DataTableRowHeaderStateChangeEvent) => {
        setRowHeaders([...event.detail.displayedRowHeaderIds]);
      };

      dataTableService.subscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
      dataTableService.subscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
      dataTableService.subscribe(DataTableEvent.RowHeaderStateChange, rowHeaderStateChangeListener);

      return () => {
        dataTableService.unsubscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
        dataTableService.unsubscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
        dataTableService.unsubscribe(DataTableEvent.RowHeaderStateChange, rowHeaderStateChangeListener);
      };
    }
  }, [rowIndex, row, dataTableService]);

  const { cellHeight } = dataTableService.dimensions;

  const handleRowDelete = async () => {
    openModal('CONFIRM_DELETE_DATA_TABLE_ROW', {
      onClick: () => {
        const rowId = row?.id;
        if (_notNil(rowId)) {
          dataTableService
            .removeRow(rowId)
            .then(() => successToast('Row removed'))
            .catch(defaultPromiseErrorHandler);
        }
      },
    });
  };

  const scrollToFirstErrorOnRow = (rowId: DataTableRowId) => {
    const errorsOnRow = errors.filter((error) => rowId === error.coordinate?.row);
    const errorIdCoordinate = errorsOnRow?.[0]?.coordinate;
    if (_notNil(errorIdCoordinate)) {
      const indexCoordinate = dataTableService.toIndexCoordinate(errorIdCoordinate);
      if (_notNil(indexCoordinate)) {
        dataTableService.selectCell(indexCoordinate, { scrollTo: true });
      }
    }
  };

  return (
    <div
      className={styles['data-table-row-header-container']}
      onClick={() => dataTableService.selectRow(rowIndex)}
      style={{
        position: 'absolute',
        top: positionOffset.y,
        left: 0,
        width: `${rowHeaderWidth}px`,
        height: `${cellHeight}px`,
      }}
    >
      <div className="flex h-100">
        {rowHeaders.map((headerId, index) => (
          <div key={headerId} className={`${styles['data-table-row-header']} ${rowSelected ? styles['selected'] : ''}`}>
            <div className="flex flex-row justify-between items-center w-100 hide-child relative">
              <RowHeaderComponent
                studyId={dataTableService.studyId}
                key={headerId}
                headerId={headerId}
                row={row}
                selected={rowSelected}
                error={errorInRow}
                handleRowDelete={handleRowDelete}
              />
              {index === 0 && (
                <>
                  {errorInRow && (
                    <div
                      className={`absolute right-2 z-5 pointer`}
                      onClick={() => scrollToFirstErrorOnRow(row?.id ?? 'dtr_empty')}
                    >
                      <Tooltip render="Error in row">
                        <RiAlertFill className="dark-red" size={18} />
                      </Tooltip>
                    </div>
                  )}
                  {!dataTableService.readonly && (
                    <div className="child absolute right-0 z-5 pointer" onClick={handleRowDelete}>
                      <Tooltip render="Delete row">
                        <RiCloseCircleFill size={18} className="dark-gray" />
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getRowHeaderColumnLeftOffset = (index: number, cellWidth: number): number =>
  Math.round(index === 0 ? 0 : index * cellWidth);

export const DataTableRowColumnHeaders = ({ dataTableService }: DataTableRowColumnHeadersProps) => {
  const { cellWidth, columnHeight } = dataTableService.dimensions;
  const [rowHeaderColumnTitles, setRowHeaderColumnTitles] = useState(() => dataTableService.displayedRowHeaderTitles());
  useEffect(() => {
    const listener = (event: DataTableRowHeaderStateChangeEvent) => {
      setRowHeaderColumnTitles(dataTableService.displayedRowHeaderTitles());
    };
    dataTableService.subscribe(DataTableEvent.RowHeaderStateChange, listener);
    return () => {
      dataTableService.unsubscribe(DataTableEvent.RowHeaderStateChange, listener);
    };
  }, [dataTableService]);

  return (
    <div
      className={styles['data-table-row-column-header-sticky-container']}
      style={{
        width: dataTableService.rowHeaderWidth(),
      }}
    >
      {rowHeaderColumnTitles.map((rowHeaderCol: string, rowHeaderColIndex: number) => (
        <div
          key={`rowHeaderColumn-${rowHeaderColIndex}`}
          className={styles['data-table-column-header-container']}
          style={{
            position: 'absolute',
            top: 0,
            left: getRowHeaderColumnLeftOffset(rowHeaderColIndex, cellWidth),
            width: cellWidth,
            height: columnHeight,
          }}
        >
          <div className={styles['data-table-column-header']}>{rowHeaderCol}</div>
        </div>
      ))}
    </div>
  );
};
