// @ts-nocheck: converted from JS

import NoDataCard from '@/components/NoDataCard';
import type { ID } from '@/model/Common.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import React, { useEffect, useMemo, useState } from 'react';
import { Plotly } from '@/support/plotly';
import { web as webRoute } from '@/support/route';
import type { GraphsState } from '../Graph.model';
import { getAxisLabel, getLatestMeasurementFromMetrics, graphConfig, graphOptions } from './Graph.utils';
import GraphTitle, { GraphSettings } from './GraphTitle';

interface SpiderPlotProps {
  id: ID;
  studyName: string;
  state: GraphsState;
}

// The spider plot shows the value of each animal in the study regardless of the group
const SpiderPlot: React.FC<SpiderPlotProps> = ({ id, studyName, state }) => {
  const { changeOption, activeMetric, metrics, groups, survivalDates } = state;
  const dateRanges = useMemo(
    () => ({ first: survivalDates.start, last: getLatestMeasurementFromMetrics(metrics, survivalDates.start) }),
    [state]
  );
  const [settings, setSettings] = useState<GraphSettings>({
    yaxis: 'linear',
  });

  const updateGraphData = (graphData: Plotly.Data) => {
    const graphSettings = graphConfig({
      hasLegend: true,
      xTitle: 'Days',
      yTitle: getAxisLabel(activeMetric, changeOption),
      yType: settings.yaxis,
    });
    Plotly.newPlot('spiderPlot', {
      ...graphSettings,
      data: graphData,
    });
  };

  const dataFormat = ({ name, id, color }: TreatmentGroup): Plotly.Data => ({
    x: [],
    y: [],
    showlegend: false,
    study_group_id: id,
    name,
    legendgroup: name,
    line: {
      color,
      shape: 'linear',
      width: 1.5,
    },
    mode: 'lines',
    type: 'scatter',
    hoverinfo: 'skip',
  });

  const formatGraphData = () => {
    const graphData = metrics
      .reduce<Plotly.Data>((allAnimalData, group) => {
        group.animals?.forEach((animal, index) => {
          allAnimalData.push(
            animal.values?.reduce((animalData, { value, study_day: studyDay, excluded }) => {
              if (index === 0) {
                animalData.showlegend = true;
              }
              if (!excluded) {
                animalData.x.push(studyDay);
                animalData.y.push(value);
              }
              return animalData;
            }, dataFormat(group))
          );
        });
        return allAnimalData;
      }, [])
      .filter((data) => data);
    updateGraphData(graphData);
  };

  useEffect(() => {
    if (groups.length) {
      formatGraphData();
    }
  }, [settings, metrics, survivalDates]);

  if (!groups.length) {
    return (
      <NoDataCard
        className="ma3"
        title="You haven't added any measurements yet"
        text="Create measurements to monitor how your study progresses. You can export all group data to excel as a report and download graphs instantly."
        link={webRoute('studies.settings', { id })}
        btnTxt="Study settings"
      />
    );
  }

  return (
    <div className="ph4 pv3" data-test-component="SpiderPlot" data-test-element="container">
      <GraphTitle
        dates={dateRanges}
        studyName={studyName}
        graphType="SPIDER"
        graphOptions={graphOptions({ withErrors: false })}
        settings={settings}
        setSettings={setSettings}
      />
      <div className="ui__graph">
        <div id="spiderPlot" className="w-100 h-100" style={{ height: '548px' }} />
      </div>
    </div>
  );
};

export default SpiderPlot;
