// @ts-nocheck: converted from JS

import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

const Name = ({ ActionButtons, onSubmit, request }) => {
  const formMethods = useForm({
    defaultValues: {
      name: request.name || '',
    },
  });

  const { handleSubmit, register, errors, watch } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pa3 ui-card mw6"
        data-test-component="Name"
        data-test-element="form"
      >
        <div className="mb3">
          <label htmlFor="name">Request name</label>
          <input
            type="text"
            style={{ marginBottom: 0 }}
            className={`${errors.name ? 'input__error' : ''}`}
            name="name"
            data-test-element="name-input"
            ref={register({
              required: 'Request name is required',
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>

        {React.cloneElement(ActionButtons, {
          onOk: handleSubmit(onSubmit),
          canOk: watch('name') !== '',
        })}
      </form>
    </FormProvider>
  );
};

export default Name;
