// @ts-nocheck: converted from JS

import { FocusEventHandler, ReactNode } from 'react';
import ReactSelect, {
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

interface BaseSelectProps<T> {
  className?: string;
  defaultValue?: T;
  options?: OptionsOrGroups<T, GroupBase<T>>;
  customStyles?: unknown;
  isSearchable?: boolean;
  isClearable?: boolean;
  onBlur?: FocusEventHandler;
  components?: Partial<SelectComponents<T, boolean, GroupBase<T>>>;
  getOptionLabel?: GetOptionLabel<T>;
  getOptionValue?: GetOptionValue<T>;
  isOptionDisabled?: (option: T) => boolean;
  isLoading?: boolean;
  value?: unknown;
  disabled?: boolean;
  filterOption?: (option: FilterOptionOption<T>, inputValue: string) => boolean;
  placeholder?: string;
  name?: string;
}

interface SingleSelectProps<T> extends BaseSelectProps<T> {
  onChange: (newValue: SingleValue<T>) => void;
  isMulti: false;
}

interface MultiSelectProps<T> extends BaseSelectProps<T> {
  onChange: (newValue: MultiValue<T>) => void;
  isMulti: true;
}

type SelectProps<T> = SingleSelectProps<T> | MultiSelectProps<T>;

export interface SelectOption<T> {
  value: T;
  label: string;
}

const Select = <T extends object>({
  className = '',
  defaultValue,
  options,
  onChange,
  isMulti = false,
  customStyles,
  isSearchable,
  isClearable,
  onBlur,
  components,
  getOptionLabel,
  getOptionValue,
  value,
  disabled,
  isOptionDisabled,
  isLoading,
  filterOption,
  placeholder,
  name,
}: SelectProps<T>): JSX.Element => {
  const styles: StylesConfig<ReactNode, boolean, GroupBase<ReactNoe>> = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
    }),
  };

  return (
    <ReactSelect
      className={`w5 ui__select f6 ${className}`}
      classNamePrefix="ui__select"
      defaultValue={defaultValue}
      options={options}
      isMulti={isMulti}
      onChange={onChange}
      styles={{ styles, ...customStyles }}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onBlur={onBlur}
      name={name}
      components={components}
      isOptionDisabled={isOptionDisabled}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      filterOption={filterOption}
      isDisabled={disabled}
      isLoading={isLoading}
      placeholder={placeholder}
    />
  );
};

export default Select;
