import type {
  DataTableColumn,
  DataTableColumnId,
  DataTableColumnMoveRequest,
  DataTableColumnType,
  DataTableType,
} from '@/components/UI/DataTable/DataTable.model';
import { Radio, Select } from '@/components/UI/FormFields';
import { _isNil, _notNil } from '@/littledash';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import { formatDuration } from 'date-fns';
import { FC, useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { parse as parseDuration } from 'tinyduration';

interface MoveDatatableColumnProps {
  tableType: DataTableType;
  column: DataTableColumn;
  columns: Array<DataTableColumn>;
  closeModal: () => void;
  onSave: (columnUpdate: DataTableColumnMoveRequest) => Promise<void>;
}

const renderColumnName = (
  tableType: DataTableType,
  columnName: string | undefined,
  type: DataTableColumnType | null,
  referenceDate: string | undefined | null
): string => {
  if (_isNil(columnName)) {
    return '';
  }
  switch (tableType) {
    case 'time': {
      const duration = parseDuration(columnName);
      if (Object.values(duration).every((v) => v === 0)) {
        return '0';
      }
      return (duration.negative === true ? '- ' : '') + formatDuration(duration, { zero: false });
    }
    case 'custom': {
      if (type === 'measurement' && _notNil(referenceDate)) {
        return columnName + ' (' + referenceDate + ')';
      } else {
        return columnName;
      }
    }
    default:
      return columnName;
  }
};
export const MoveDatatableColumn: FC<MoveDatatableColumnProps> = ({
  tableType,
  column,
  columns,
  onSave,
  closeModal,
}) => {
  const onSubmit = async (update: DataTableColumnMoveRequest) => {
    closeModal();
    await onSave({ column_id: column.id, target_column_id: update.target_column_id, position: update.position });
  };

  const formMethods = useForm<DataTableColumnMoveRequest>({
    mode: 'onChange',
    defaultValues: { position: 'before' },
  });

  const {
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid, isDirty },
  } = formMethods;

  const selectColumns = useMemo(
    () =>
      columns.reduce<Array<{ value: DataTableColumnId; label: string }>>(
        (acc, { id: value, name, type, reference_date }) =>
          value === column.id
            ? acc
            : [...acc, { value, label: renderColumnName(tableType, name, type, reference_date) }],
        []
      ),
    [tableType, columns]
  );

  const name = renderColumnName(tableType, column.name, null, null);
  const unit = _notNil((column as { unit: string }).unit) ? `(${(column as { unit: string }).unit})` : '';
  return (
    <ModalContainer size="narrow">
      <ModalHeader
        title="Move column"
        subText={`${name} ${unit}`}
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="ph3 pv2">
            <Radio
              required={false}
              options={[
                {
                  value: 'before',
                  name: 'before',
                  label: 'Before',
                },
                {
                  value: 'after',
                  name: 'after',
                  label: 'After',
                },
              ]}
              label={`Move column '${name}' to`}
              name="position"
            />
            <ErrorMessage
              errors={errors}
              name="position"
              render={({ message }) => <small className="red db pt2">{message}</small>}
            />
          </div>
          <div className="ph3 pv2">
            <Select label="Column" required name="target_column_id" options={selectColumns} />
          </div>
        </form>
      </FormProvider>
      <ModalActions
        className="pa3 mt3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitButtonProps={{ disabled: !isValid || !isDirty || isSubmitting }}
      />
    </ModalContainer>
  );
};
