import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import Icon from '@/components/UI/Icon';
import { renderMetadataValue } from '@/helpers.tsx';
import { _isEmptyString, _isNotEmpty, _notNil } from '@/littledash.ts';
import { GlossaryItem } from '@/model/Glosary.model.ts';
import { PresetCalculation } from '@/model/PresetCalculation.model.ts';
import { TaskV1 } from '@/model/Task.model.ts';
import { Treatment } from '@/model/Treatment.model.ts';
import cn from 'classnames';
import Fuse from 'fuse.js';
import { FC, useMemo, useState } from 'react';
import style from './Execution.module.scss';

export type TypedTaskData =
  | { type: 'measurement'; data: Map<PresetCalculation['id'], PresetCalculation> }
  | { type: 'sample' | 'observation'; data: Map<GlossaryItem['id'], GlossaryItem> }
  | { type: 'dosing'; data: Map<Treatment['id'], Treatment> };

export const ExecutionPanel: FC<{ task: TaskV1; typedTaskData?: TypedTaskData }> = ({ task, typedTaskData }) => {
  switch (task.type) {
    case 'measurement':
      return <MeasurementExecution task={task} typedTaskData={typedTaskData} />;
    case 'sample':
      return <SampleExecution task={task} typedTaskData={typedTaskData} />;
    case 'observation':
      return <ObservationExecution task={task} typedTaskData={typedTaskData} />;
    case 'dosing':
      return <DosingExecution task={task} typedTaskData={typedTaskData} />;
  }
  return null;
};

const MeasurementExecution: FC<{ task: TaskV1; typedTaskData?: TypedTaskData }> = ({ task, typedTaskData }) => {
  const [search, updateSearch] = useState('');
  const { measurementsFuse, measurementList } = useMemo(() => {
    const measurementData: Map<PresetCalculation['id'], PresetCalculation> =
      typedTaskData?.type === 'measurement' ? typedTaskData.data : new Map();
    const measurementList = (task.execution?.measurement?.measurements ?? []).reduce<Array<PresetCalculation>>(
      (acc, id) => (measurementData.has(id) ? [...acc, measurementData.get(id) as PresetCalculation] : acc),
      []
    );
    const measurementsFuse = new Fuse(measurementList, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [
        { name: ['name'] },
        { name: ['unit'] },
        { name: ['formula'] },
        { name: ['measurements', 'name'] },
        { name: ['measurements', 'unit'] },
        { name: ['measurements', 'formula'] },
      ],
    });
    return { measurementsFuse, measurementList, measurementData };
  }, [typedTaskData, task]);
  const measurements = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return measurementsFuse.search(trimmedSearchString).map((r) => r.item);
    }
    return measurementList;
  }, [search, measurementsFuse, measurementList]);

  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={cn('pa3 relative w-100', style.searchContainer)}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div className="ph3">
        <Accordion>
          {measurements.map((measurement) => (
            <AccordionItem key={measurement.id}>
              <AccordionHeader>
                <div>
                  <span className="near-black">{measurement.name}</span>
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div className={style.measurementDetail}>
                  <div className={cn('flex flex-row mb2', style.chipContainer)}>
                    {measurement.measurements?.map((m) => (
                      <span
                        key={m.id}
                        className="f7 pa2 mr2 bg-near-white near-black"
                        data-tooltip-id="global-tooltip-id"
                        data-tooltip-content={`ID: ${m.id}`}
                      >
                        <span className="fw5">{m.name}</span>
                        {_isEmptyString(m.unit ?? '') ? null : <span className="fw1 ml1">({m.unit})</span>}
                      </span>
                    ))}
                  </div>
                  <div className="flex flex-column f7 mv2">
                    <div className="f7 fw5 mr2 mb1">Formula</div>
                    <code className="pa2 fw1 bg-near-white near-black">{measurement.formula}</code>
                  </div>
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
const SampleExecution: FC<{ task: TaskV1; typedTaskData?: TypedTaskData }> = ({ task, typedTaskData }) => {
  const [search, updateSearch] = useState('');
  const { samplesFuse, sampleList } = useMemo(() => {
    const sampleData: Map<GlossaryItem['id'], GlossaryItem> =
      typedTaskData?.type === 'sample' ? typedTaskData.data : new Map();
    const sampleList = (task.execution?.sample?.samples ?? []).reduce<Array<GlossaryItem>>(
      (acc, id) => (sampleData.has(id) ? [...acc, sampleData.get(id) as GlossaryItem] : acc),
      []
    );
    const samplesFuse = new Fuse(sampleList, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [{ name: ['title'] }, { name: ['description'] }, { name: ['options', 'details'] }],
    });
    return { samplesFuse, sampleList, sampleData };
  }, [typedTaskData, task]);
  const samples = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return samplesFuse.search(trimmedSearchString).map((r) => r.item);
    }
    return sampleList;
  }, [search, samplesFuse, sampleList]);
  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={cn('pa3 relative w-100', style.searchContainer)}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div className="ph3">
        <Accordion>
          {samples.map((sample) => (
            <AccordionItem key={sample.id}>
              <AccordionHeader>
                <div>
                  <span className="near-black">{sample.title}</span>
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div className="flex flex-row mb2">
                  {(sample?.options?.details ?? []).map((detail) => {
                    switch (detail) {
                      case 'weight':
                        return (
                          <span key={detail} className="f7 pa2 mr2 bg-near-white near-black">
                            <span className="fw5">Weight</span>
                            <span className="ml1 fw1">(mg, g, µg)</span>
                          </span>
                        );
                      case 'length':
                        return (
                          <span key={detail} className="f7 pa2 mr2 bg-near-white near-black">
                            <span className="fw5">Length</span>
                            <span className="ml1 fw1">(mm)</span>
                          </span>
                        );
                      case 'width':
                        return (
                          <span key={detail} className="f7 pa2 mr2 bg-near-white near-black">
                            <span className="fw5">Width</span>
                            <span className="ml1 fw1">(mm)</span>
                          </span>
                        );
                      case 'depth':
                        return (
                          <span key={detail} className="f7 pa2 mr2 bg-near-white near-black">
                            <span className="fw5">Width</span>
                            <span className="ml1 fw1">(mm)</span>
                          </span>
                        );
                      case 'volume':
                        return (
                          <span key={detail} className="f7 pa2 mr2 bg-near-white near-black">
                            <span className="fw5">Volume</span>
                            <span className="ml1 fw1">(ml, µl, cm³, mm³)</span>
                          </span>
                        );
                      default:
                        return null;
                    }
                  })}
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
const ObservationExecution: FC<{ task: TaskV1; typedTaskData?: TypedTaskData }> = ({ task, typedTaskData }) => {
  const [search, updateSearch] = useState('');
  const { observationsFuse, observationList } = useMemo(() => {
    const observationData: Map<GlossaryItem['id'], GlossaryItem> =
      typedTaskData?.type === 'observation' ? typedTaskData.data : new Map();
    const observationList = (task.execution?.observation?.observations ?? []).reduce<Array<GlossaryItem>>(
      (acc, id) => (observationData.has(id) ? [...acc, observationData.get(id) as GlossaryItem] : acc),
      []
    );
    const observationsFuse = new Fuse(observationList, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [
        { name: ['title'] },
        { name: ['description'] },
        { name: ['type'] },
        { name: ['options', 'min'] },
        { name: ['options', 'max'] },
      ],
    });
    return { observationsFuse, observationList, observationData };
  }, [typedTaskData, task]);
  const observations = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return observationsFuse.search(trimmedSearchString).map((r) => r.item);
    }
    return observationList;
  }, [search, observationsFuse, observationList]);
  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={cn('pa3 relative w-100', style.searchContainer)}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div className="ph3">
        <Accordion>
          {observations.map((observation) => (
            <AccordionItem key={observation.id}>
              <AccordionHeader>
                <div>
                  <span className="near-black">{observation.title}</span>
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div className="pb3">
                  <div className="bg-light-gray near-black">
                    <div className="pa2">
                      {_notNil(observation.description) ? (
                        <>
                          <div className="w-100 flex pa1 f6">
                            <div className="w-50 fw5">Type</div>
                            <div className="w-50 fw1">{observation.type_name}</div>
                          </div>
                          <div className="w-100 flex pa1 f6">
                            <div className="w-50 fw5">Grading information</div>
                            <div className="w-50 fw1">{observation.description}</div>
                          </div>
                        </>
                      ) : null}
                      {observation.type === 'numeric' ? (
                        <div className="w-100 flex pa1 f6">
                          <div className="w-50 fw5">Scale</div>
                          <div className="w-50 fw1">
                            <span>{(observation.options as { min?: string })?.min}</span>
                            <span className="mh1">-</span>
                            <span>{(observation.options as { max?: string })?.max}</span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
const DosingExecution: FC<{ task: TaskV1; typedTaskData?: TypedTaskData }> = ({ task, typedTaskData }) => {
  const [search, updateSearch] = useState('');
  const { treatmentsFuse, treatmentList } = useMemo(() => {
    const treatmentData: Map<Treatment['id'], Treatment> =
      typedTaskData?.type === 'dosing' ? typedTaskData.data : new Map();
    const treatmentList = (task.execution?.dosing?.treatments ?? []).reduce<Array<Treatment>>(
      (acc, id) => (treatmentData.has(id) ? [...acc, treatmentData.get(id) as Treatment] : acc),
      []
    );
    const treatmentsFuse = new Fuse(treatmentList, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [
        { name: ['display_name'] },
        { name: ['calculates', 'formula'] },
        { name: ['calculates', 'name'] },
        { name: ['calculates', 'unit', 'display_unit'] },
        { name: ['fields', 'label'] },
        { name: ['fields', 'default_display_value'] },
        { name: ['metadata', 'title'] },
        { name: ['metadata', 'value'] },
      ],
    });
    return { treatmentsFuse, treatmentList, treatmentData };
  }, [typedTaskData, task]);

  const treatments = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return treatmentsFuse.search(trimmedSearchString).map((r) => r.item);
    }
    return treatmentList;
  }, [search, treatmentsFuse, treatmentList]);
  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={cn('pa3 relative w-100', style.searchContainer)}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div className="ph3">
        <Accordion>
          {treatments.map((treatment) => (
            <AccordionItem key={treatment.api_id}>
              <AccordionHeader>
                <div>
                  <span className="near-black">{treatment.display_name}</span>
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <div>
                  <div className="flex flex-row mb2">
                    {treatment.fields.map((field) => (
                      <span key={field.name} className="f7 pa2 mr2 bg-near-white near-black">
                        <span className="fw5">{field.name}</span>
                        <span className="ml1 fw1">
                          {field.default_value} {field.unit.display_unit}
                        </span>
                      </span>
                    ))}
                  </div>
                  {_notNil(treatment.calculates) ? (
                    <div className="flex flex-column f7 mv2">
                      <div className="f7 fw5 mr2 mb1">
                        {treatment.calculates.label} ({treatment.calculates.unit.base_unit})
                      </div>
                      <code className="pa2 fw1 bg-near-white near-black">{treatment.calculates.formula}</code>
                    </div>
                  ) : null}
                  {_isNotEmpty(treatment.metadata) ? (
                    <div className="flex flex-column f7 mv2" data-test-element="metadata-container">
                      {treatment.metadata.map((metadata) => (
                        <div key={metadata.slug} className="flex flex-column f7 mv2" data-test-element="metadata-item">
                          <div className="f7 fw5 mr2 mb1" data-test-element="metadata-title">
                            {metadata.title}
                          </div>
                          <span className="pa2 fw1 bg-near-white near-black" data-test-element="metadata-value">
                            {renderMetadataValue(metadata)}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
