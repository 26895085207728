// @ts-nocheck: converted from JS

import CancelledTable from '@/components/Dashboard/CancelledTable';
import Button from '@/components/UI/Button';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import InVivoError from '@/model/InVivoError.ts';
import { useFetchCollection } from '@/support/Hooks/fetch/index';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useSelectStudyCreationFormModal from '@/support/Hooks/study/useSelectStudyCreationFormModal';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { useEffect, useState } from 'react';
import ActiveTable from './ActiveTable';
import ArchiveTable from './ArchiveTable';
import DraftsTable from './DraftsTable';

const Dashboard = () => {
  const [studyTypes, setStudyTypes] = useState([]);
  const { newAbortController } = useAbortController();
  const { openSelectStudyCreationFormModal } = useSelectStudyCreationFormModal();

  const { collection: studyMetadataFilters } = useFetchCollection({
    queryParams: {
      filter_type: 'study_meta',
    },
    collectionType: 'metadataGlossary',
  });

  useEffect(() => {
    getStudyTypes();
  }, []);

  const getStudyTypes = async () => {
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('study.presets'), {
        signal: newAbortController().signal,
      });
      setStudyTypes(data);
    } catch (error) {
      if (notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError('Could not load study presets', { cause: error, slug: 'dashboard-load-presets' })
        );
      }
    }
  };

  return (
    <>
      <div className="flex justify-between flex-wrap pa4">
        <h1 className="f3 normal">Studies</h1>
        <Button onClick={openSelectStudyCreationFormModal} testId="create-new-study-button">
          Create new study
        </Button>
      </div>
      <Tabs>
        <div className="bb b--moon-gray mh3 mh4-l">
          <Tab className="f5 f4-l">Active</Tab>
          <Tab className="f5 f4-l">Drafts</Tab>
          <Tab className="f5 f4-l">Completed</Tab>
          <Tab className="f5 f4-l">Cancelled</Tab>
        </div>
        <Panel>
          <ActiveTable studyTypes={studyTypes} studyMetadataFilters={studyMetadataFilters} />
        </Panel>
        <Panel>
          <DraftsTable studyTypes={studyTypes} studyMetadataFilters={studyMetadataFilters} />
        </Panel>
        <Panel>
          <ArchiveTable studyTypes={studyTypes} studyMetadataFilters={studyMetadataFilters} />
        </Panel>
        <Panel>
          <CancelledTable studyTypes={studyTypes} studyMetadataFilters={studyMetadataFilters} />
        </Panel>
      </Tabs>
    </>
  );
};

export default Dashboard;
