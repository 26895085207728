import type { ReactNode } from 'react';
import './Summary.scss';

interface SummaryProps {
  title: string;
  children: ReactNode;
}

const Summary = ({ title, children }: SummaryProps) => {
  return (
    <div className="ui-card mb3 overflow-hidden hide-child">
      {title && (
        <div className="flex justify-between flex-wrap items-start ph4 pt4">
          <h3 className="normal f4 lh-title pb3">{title}</h3>
        </div>
      )}
      <div className="summary-items">{children}</div>
    </div>
  );
};

export default Summary;
