// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import { useParams } from 'react-router-dom';
import Cages from './Cages';

const Show = () => {
  const { id: studyId } = useParams();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,project,study_groups,counts',
  });
  const {
    collection: cages,
    collectionLoading: cagesLoading,
    fetchCollection: fetchCages,
  } = useFetchCollection({
    collectionType: 'cages',
    includes: 'counts',
    queryParams: {
      sort: 'id',
      order: 'asc',
      filterType: 'and',
      'filters[]=': `study|eq|${studyId}`,
      perPage: '-1',
    },
  });

  if (studyLoading || cagesLoading) {
    return <Loading />;
  }

  return <Cages study={study} cages={cages} fetchCages={fetchCages} inStudyView />;
};

export default Show;
