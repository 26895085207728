import RequestsTable from '@/components/Requests/RequestsTable';

const CompletedRequests: React.FC = () => {
  const statusFilter = {
    type: 'and',
    op: 'eq',
    filter: 'status',
    value: 'complete',
  };
  return (
    <RequestsTable
      statusFilter={statusFilter}
      noDataTitle={'You have no completed requests'}
      noDataText={'Any completed requests will appear here.'}
    />
  );
};

export default CompletedRequests;
