import NoDataCard from '@/components/NoDataCard';
import { GroupTreatmentConfiguration } from '@/components/Studies/StudyGroups/Configuration';
import { _isEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { DraftFormData } from '@/model/Draft.model';
import type { Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import React, { Dispatch, useMemo } from 'react';

interface GroupAndTreatmentConfigurationProps {
  study: DraftFormData;
  dispatch: Dispatch<unknown>;
}

export const GroupAndTreatmentConfiguration: React.FC<GroupAndTreatmentConfigurationProps> = ({ study, dispatch }) => {
  const treatments: Array<Treatment> = useMemo(
    () =>
      (study?.treatments ?? []).map((treatment) => ({
        ...treatment,
        id: treatment?.temp_id ?? '',
      })),
    [study]
  );
  const groups: Array<TreatmentGroup> = useMemo(
    () =>
      (study?.groups ?? []).map((group) => ({
        ...group,
        id: group?.temp_id ?? '',
      })),
    [study]
  );
  const groupsWithTreatments: Record<ID, Array<ID>> = useMemo(
    () =>
      (study?.groups_treatments ?? []).reduce((acc, { group, treatments }) => ({ ...acc, [group]: treatments }), {}),
    [study]
  );

  const handleTreatmentClick = (groupTreatments: Record<ID, Array<ID>>) => {
    dispatch({
      type: 'updateStudy',
      data: {
        groups_treatments: Object.entries(groupTreatments).map(([group, treatments]) => ({ group, treatments })),
      },
    });
  };

  return _isEmpty(groups) ? (
    <NoDataCard className="mv4" title="This study has no groups yet" text="You can create groups in the Groups tab" />
  ) : (
    <div className="mw9 center ph3">
      <GroupTreatmentConfiguration
        treatments={treatments}
        groups={groups}
        groupsWithTreatments={groupsWithTreatments}
        onToggleTreatment={handleTreatmentClick}
      />
    </div>
  );
};
