import Lookup from '@/components/UI/Lookup';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, useFormContext } from 'react-hook-form';
import { defaultValue, handleChange, renderLabel } from './Select';
import { RequiredMetadataFieldWithValue } from '../Fields';
import { FC } from 'react';
import { _isEmpty } from '@/littledash';

interface SelectLookupProps {
  field: RequiredMetadataFieldWithValue;
  isMulti?: boolean;
  testId?: string;
  name: string;
}

export const SelectLookup: FC<SelectLookupProps> = ({ field, isMulti = false, testId = 'select-lookup', name }) => {
  const [apiError, setApiError] = useState<Error | boolean>(false);
  const { control } = useFormContext();

  const loadOptions = async ({ inputValue, page }: { inputValue: string; page: string | number }) => {
    let url = apiRoute('metadata.options.show', { id: field.id });
    url = `${url}?page=${page}`;
    if (inputValue) {
      url = `${url}&query=${encodeURIComponent(inputValue)}`;
    }

    try {
      const {
        data: { data, links },
      } = await Http.get(url);
      const { next } = links;
      const options = data.map(({ value }: { value: string }) => ({
        label: renderLabel(value),
        value,
      }));

      return { options, hasMore: !!next };
    } catch (error) {
      setApiError(error as Error);
    }
  };

  return (
    <Controller
      control={control}
      defaultValue={field.value}
      name={name}
      rules={{
        validate: {
          required: (value: any) => {
            if (field.required && _isEmpty(value)) {
              return 'This field is required';
            }
          },
        },
      }}
      render={({ name, onChange }: { name: string; onChange: (data: string | Array<string>) => void }) => (
        <div className="w5" data-testid={testId}>
          <Lookup
            name={name}
            isMulti={isMulti}
            defaultValue={defaultValue(field, isMulti)}
            loadOptions={loadOptions}
            handleSelect={(data: string | Array<string>) => handleChange(onChange, data, isMulti)}
            cacheOptions={false}
          />
          {apiError && <p className="f7 red">There was a problem fetching metadata</p>}
        </div>
      )}
    />
  );
};
