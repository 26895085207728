import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import RequestComments from '@/components/Requests/RequestComments';
import RequestFormSummary from '@/components/Requests/RequestFormSummary';
import Attachments from '@/components/Studies/Attachments';
import Samples from '@/components/Studies/Requests/Edit/Samples';
import Project from '@/components/Studies/Show/Project';
import Groups from '@/components/Studies/StudyGroups/RequestApprovals/Groups';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _isNil, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { Request, REQUEST_STATUS, RequestComment } from '@/model/Request.model';
import type { Team } from '@/model/Team.model';
import type { MainUser } from '@/model/User.model';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface RequestStatus {
  status: string;
  assigned_user_id: string;
}

const RequestDetails = () => {
  const [hasRequestError, setHasRequestError] = useState<Error | boolean>();
  const [hasCommentError, setHasCommentError] = useState<Error>();
  const [request, setRequest] = useState<Request>();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [commentLoading, setCommentLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<Array<RequestComment>>([]);

  const { team } = useSelector(({ team: { team } }: { team: { team: Team } }) => ({
    team,
  }));

  const inactiveUserLookup = useMemo(
    () =>
      team.users.reduce<Record<ID, MainUser>>((acc, user) => {
        if (user.pivot.status === 'inactive') {
          acc[user.id] = user;
        }
        return acc;
      }, {}),
    [team]
  );

  const params = useParams<{ requestId: string; tab: string }>();
  const { tab } = params;
  const tabState = useMemo(() => {
    if (_notNil(tab)) {
      const tabs = [
        'detail',
        ...(_isNil(request?.project) ? [] : ['project']),
        'groups',
        'samples',
        'attachments',
        'comments',
      ];
      const selectedTab = tabs.indexOf(tab);
      if (selectedTab >= 0) {
        return selectedTab;
      }
    }
    return 0;
  }, [tab, request?.project]);
  useEffect(() => {
    fetchRequest(params.requestId);
    fetchComments(params.requestId);
  }, []);

  const fetchRequest = async (id: string) => {
    setRequestLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(
        apiRoute('requests', {
          requestId: id,
          query: ['study_groups[metadata,treatment],study[users],project'],
        })
      );
      setRequest(data);
      setRequestStatus({
        assigned_user_id: data.assigned_user?.id ?? undefined,
        status: data.status || '',
      });
    } catch (error) {
      setHasRequestError(error as Error);
    } finally {
      setRequestLoading(false);
    }
  };

  const fetchComments = async (id: string) => {
    setCommentLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(
        RouteService.legacyApi({
          apiRoute: 'requests.comments',
          path: { requestId: id },
          query: { perPage: -1 },
        }).url.href
      );
      setComments(data ?? []);
    } catch (error) {
      setHasCommentError(error as Error);
    } finally {
      setCommentLoading(false);
    }
  };

  const updateRequest = async (newRequestData: RequestStatus) => {
    setRequestLoading(true);
    setRequestStatus(newRequestData);
    try {
      await Http.put(
        apiRoute('requests.update', {
          requestId: request?.id,
        }),
        newRequestData
      );
      toast.success('Successfully updated request');
    } catch (e) {
      setHasRequestError(true);
    } finally {
      setRequestLoading(false);
    }
  };

  const loading = commentLoading || requestLoading;

  const hasError = hasRequestError || hasCommentError;

  if (loading || !request) {
    return <Loading />;
  }

  return (
    <div>
      <SubHeader linkToText="Requests" link={webRoute('requests')} />
      <div className="pa4">
        <div className="flex flex-row justify-between items-center mb4">
          <h1 className="f3">{request.request_template.name}</h1>
        </div>
        <div>
          {hasError && (
            <ApiErrorBanner
              className="mb4 mw6"
              title={`There was an internal error ${hasRequestError ? 'with requests' : 'fetching comments'}`}
              text={
                'An error has occurred with the request, please try again later. If this keeps occurring please contact support.'
              }
              error={hasError}
            />
          )}
          {_notNil(request) && _notNil(requestStatus) && (
            <div className="flex flex-row">
              <div className="mw7 w-100">
                <div className="ui-card mb4 hide-child">
                  <div className="flex flex-row justify-between">
                    <div className="pa4">
                      <p>Status</p>
                      <select
                        name="request_status"
                        className="w5"
                        onChange={(e) =>
                          updateRequest({
                            ...requestStatus,
                            status: e.target.value as keyof typeof REQUEST_STATUS,
                          })
                        }
                      >
                        {[
                          { value: 'pending', label: 'Pending' },
                          { value: 'in_progress', label: 'In Progress' },
                          { value: 'complete', label: 'Complete' },
                        ].map((status) => (
                          <option
                            selected={status.value === requestStatus.status}
                            key={status.value}
                            value={status.value}
                          >
                            {status.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="pa4 mr4">
                      <p>Assignee</p>
                      <select
                        name="assignee_select"
                        className="w5"
                        onChange={(e) =>
                          updateRequest({
                            ...requestStatus,
                            assigned_user_id: e.target.value,
                          })
                        }
                      >
                        {!requestStatus.assigned_user_id && (
                          <option selected={!requestStatus.assigned_user_id}>Unassigned</option>
                        )}
                        {request.users
                          .filter(
                            (user) => user.id === requestStatus.assigned_user_id || _isNil(inactiveUserLookup[user.id])
                          )
                          .map((user) => (
                            <option
                              selected={
                                requestStatus.assigned_user_id
                                  ? user.id === Number(requestStatus.assigned_user_id)
                                  : false
                              }
                              key={user.id}
                              value={user.id}
                            >
                              {user.name + (_notNil(inactiveUserLookup[user.id]) ? ' (inactive)' : '')}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>

                <Tabs state={tabState}>
                  <div className="mv4 bb b--moon-gray flex">
                    <Tab>Request details</Tab>
                    {_notNil(request.project) && <Tab>Project</Tab>}
                    <Tab>Groups</Tab>
                    <Tab>Samples</Tab>
                    <Tab>Attachments</Tab>
                    <Tab counter={comments?.length ?? 0}>Comments</Tab>
                  </div>
                  <div className="pb4">
                    <Panel>
                      <RequestFormSummary request={request} />
                    </Panel>
                    {_notNil(request.project) && (
                      <Panel>
                        <Project projectId={request.project.id} />
                      </Panel>
                    )}
                    <Panel>{_notNil(request?.study?.id) && <Groups studyId={request.study?.id} />}</Panel>
                    <Panel>
                      <Samples readOnly />
                    </Panel>
                    <Panel>
                      <Attachments studyId={request?.study?.id} isReview />
                    </Panel>
                    <Panel>
                      <RequestComments comments={comments} setComments={setComments} requestId={params.requestId} />
                    </Panel>
                  </div>
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestDetails;
