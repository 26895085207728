import { formatNumber } from '@/helpers';
import { _isNil, _isNumber, _notNil } from '@/littledash';
import type { TreatmentField, TreatmentMethod } from '@/model/Treatment.model';
import { evaluate } from 'mathjs';
import type { DoseValues, FormValues } from './AddDose';

export const initialDoseFormDefaultState = (treatmentFields: Array<TreatmentField>): FormValues =>
  (treatmentFields ?? []).reduce(
    (acc, field: TreatmentField) => {
      if (field?.name) {
        acc.values = {
          ...acc.values,
          [`${field.name}`]: _isNil(field?.default_display_value)
            ? null
            : String(formatNumber(field.default_display_value, true, 3)),
        };
      }
      return acc;
    },
    { notes: null, values: {} }
  );

const getFormulaValue = (conversionFormulas: Record<string, string>) => (type: string, value?: string | null) =>
  _notNil(conversionFormulas[type]) ? evaluate(conversionFormulas[type], { value }) : value;

const isDivisible = (denominator: number | string) => {
  return _isNumber(Number(denominator)) && Number(denominator) !== 0;
};

export const calculateDoseFormula = (
  bodyweight: string = '',
  doseValues: DoseValues,
  formula: string,
  conversionFormulas: Record<string, string>,
  treatmentType: TreatmentMethod
): string => {
  if (
    (treatmentType === 'volume' && !isDivisible(bodyweight)) ||
    (treatmentType === 'concentration' && doseValues?.stock && !isDivisible(doseValues.stock))
  ) {
    return '0';
  }
  const loadedGetFormulaValue = getFormulaValue(conversionFormulas);
  let result = '';
  const formScope = {
    bodyweight,
    dose: loadedGetFormulaValue('dose', doseValues?.dose) ?? '',
    stock: loadedGetFormulaValue('stock', doseValues?.stock) ?? '',
    volume: loadedGetFormulaValue('volume', doseValues?.volume) ?? '',
    dose_volume: loadedGetFormulaValue('dose_volume', doseValues?.dose_volume) ?? '',
  };
  result = evaluate(formula ?? '', formScope);
  return String(formatNumber(result, true, 3));
};
