// @ts-nocheck: converted from JS

/**
 * Form data handling and validation
 */

import _each from 'lodash/each';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import React from 'react';
import validationModule from 'validate.js';

/**
 * @internal
 */
const objGenerator = function (defaultValue) {
  return function (fields, data = {}) {
    return Object.keys(fields).reduce((obj, field) => {
      obj[field] =
        typeof data[field] !== 'undefined' ? data[field] : defaultValue === 'fromObject' ? fields[field] : defaultValue;
      return obj;
    }, {});
  };
};

// TODO: document
export const fieldObject = objGenerator('fromObject');
export const errorObject = objGenerator(false);

/**
 * Handle input for a given form field, updating fields state
 *
 * Usage (in component class):
 *   handleInput = Form.handleInput.bind(this)
 *
 * Usage (in render):
 *   <input onChange={this.handleInput}
 */
export const handleInput = function (e) {
  const name = e.target.name;
  const value = e.target.value;
  const fields = { ...this.state.fields };

  if (_get(this, ['casts', name], false)) {
    fields[name] = cast(value, this.casts[name]);
  } else {
    fields[name] = value;
  }

  this.setState(
    {
      fields: fields,
    },
    clearValidationForField.call(this, e.target.name)
  );
};

/**
 * Handle direct input – where the value is given directly, rather than an event object,
 * for a given form field, updating fields state. For usage with components like react-tagsinput
 *
 * Usage (in component class):
 *   handleFieldNameInput = Form.handleDirectInput(this, 'field_name')
 *
 * Usage (in render):
 *   <TagsInput onChange={this.handleFieldNameInput}
 */
export const handleDirectInput = function (self, fieldName) {
  return function (value) {
    const fields = { ...this.state.fields };
    fields[fieldName] = value;
    this.setState(
      {
        fields: fields,
      },
      clearValidationForField.call(this, fieldName)
    );
  }.bind(self);
};

/**
 * Handle checkbox input for a given form field, updating fields state
 *
 * Usage (in component class):
 *   handleCheckbox = Form.handleCheckbox.bind(this)
 *
 * Usage (in render):
 *   <input onChange={this.handleCheckbox}
 */
export const handleCheckbox = function (e) {
  const name = e.target.name;
  const fields = { ...this.state.fields };
  fields[name] = e.target.checked;

  this.setState(
    {
      fields: fields,
    },
    clearValidationForField.call(this, e.target.name)
  );
};

/**
 * Reset fields to defaults and clear validation
 *
 * Usage (in component class):
 *   resetFields = Form.resetFields.bind(this)
 *   this.resetFields().then(whatever)
 */
export const resetFields = function (e) {
  const fields = { ...this.state.fields };

  _each(this.defaultFields, (defaultValue, name) => {
    fields[name] = defaultValue;
    clearValidationForField.call(this, name);
  });

  return new Promise((resolve, reject) => {
    this.setState({ fields }, resolve);
  });
};

/**
 * Validate existing input. Returns true if there are no errors.
 *
 * Usage (in component class):
 *   validate = Form.validate.bind(this)
 */
export const validate = function (throws = false) {
  const results = validationModule(this.state.fields, this.validationSchema);

  let errors = this.state.errors;

  // Reset each error
  _each(errors, (_, name) => {
    errors[name] = false;
  });

  // Do we have any custom validations? Call them now. We have to use this sort
  // of setup so we can call the validation synchronously.
  if (typeof this.customValidation === 'function') {
    errors = this.customValidation(errors);
  }

  // If there are any results, loop through them and set the relevant errors
  if (results) {
    _each(results, (errorArray, name) => {
      errors[name] = errorArray;
    });
  }

  // Set the full error state
  this.setState({ errors });

  // If we have errors, return false
  return !results;
};

/**
 * Form.addError. Given a field name and error message, safely add the error and
 * return a promise for when the state is updated.
 */
export function addError(field, message) {
  const errors = { ...this.state.errors };

  if (errors[field]) {
    errors[field].push(message);
  } else {
    errors[field] = [message];
  }

  return new Promise((resolve, reject) => {
    this.setState({ errors }, resolve);
  });
}

/**
 * Form.FieldError: displays an error inline next to a field
 */
export const FieldError = ({ errors, name }) => {
  const errs = _get(errors, name, false);
  if (errs) {
    return <p className="red">{errs.join('\n')}</p>;
  }
  return '';
};

/**
 * Clear out a specific field's validation
 */
export const clearValidationForField = (name) => {
  return function () {
    const errors = this.state.errors;
    errors[name] = false;
    this.setState({ errors });
  };
};

/**
 * Given an error object, are any of the values truthy?
 */
export const isErrorState = (object) => {
  return !!_reduce(object, (last, val) => last || !!val);
};

/**
 * Cast form data as certain data types
 */

const CAST_TYPES = ['int', 'float', 'string', 'bool'];

export const cast = (value, type) => {
  if (!(CAST_TYPES.indexOf(type) === -1)) {
    throw new Error(`Unknown cast type '${type}'. Valid types: ${CAST_TYPES.join(',')}.`);
  }

  let castValue;

  if (type === 'int') {
    castValue = parseInt(value);
  } else if (type === 'float') {
    castValue = parseFloat(value);
  } else if (type === 'string') {
    castValue = String(value);
  } else if (type === 'bool') {
    castValue = !!value;
  } else {
    castValue = value;
  }

  return castValue;
};

/**
 * Render the required hidden inputs for a direct-to-Laravel form
 */
export const laravelHiddenInputs = (method) => {
  const csrf = document.head.querySelector('meta[name~=csrf-token][content]').content;
  return (
    <React.Fragment>
      <input type="hidden" name="_method" value={method} />
      <input type="hidden" name="_token" value={csrf} />
    </React.Fragment>
  );
};

/**
 * For Jamie to laugh at:
 * @SmartMeasurementValidation
 *
 * Handle custom smart validations for studies - return obj if the validation doesn't pass
 * Return a object suggesting a correction of the data
 *
 * Usage (in component class):
 *   smartValidation = Form.smartValidation(this)
 **/

export function smartValidation(study) {
  let result = true;

  /**
   * At the early stage of tumour growth, when calcuating volumes it's hard to eyeball what dimension is which.
   * When calucating the tumour volume the LENGTH can NEVER be shorter than the WIDTH
   */
  if (study.type === 'xenograft' || study.type === 'bioluminescenceSubQ') {
    const fields = { ...this.state.fields };
    _map(fields, (f, i) => {
      if (!_includes(['weight', 'notes', 'measured_at', 'totalFlux', 'avgRad'], i) && f !== '') {
        const mKey = i.substr(i.indexOf(':') + 1);
        /* Support for triplicate measurements*/
        const mKeyNum = mKey.match(/\d+/);

        const keySelector = (dimension) => `${study.measurement}:${dimension}${mKeyNum ? mKeyNum[0] : ''}`;
        const stateCheck = (dimension) => Number(fields[keySelector(dimension)]);

        if (stateCheck('length') < stateCheck('width')) {
          const tmp = fields[keySelector('width')];
          fields[keySelector('width')] = fields[keySelector('length')];
          fields[keySelector('length')] = tmp;
          result = fields;
        }
      }
    });
  }

  return result;
}
