import type { CSSProperties, FC, FocusEventHandler } from 'react';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';

export interface DateTimePickerNativeProps {
  value?: string;
  name?: string;
  step?: string;
  min?: string;
  max?: string;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  displaySeconds?: boolean;
}

export const DateTimePickerNative: FC<DateTimePickerNativeProps> = ({
  value,
  name,
  step,
  min,
  max,
  className,
  style,
  disabled,
  displaySeconds = false,
  onChange,
  onBlur,
}) => (
  <input
    type="datetime-local"
    data-testid="datetime-picker"
    className={className}
    style={{ width: 185, ...style }}
    value={DateInputUtils.toLocalDateTime(value ?? DateUtils.dateTimeNow(), { includeSeconds: displaySeconds })}
    name={name}
    step={step}
    max={DateInputUtils.toLocalDateTime(max, { includeSeconds: displaySeconds })}
    min={DateInputUtils.toLocalDateTime(min, { includeSeconds: displaySeconds })}
    disabled={disabled}
    onChange={(event) => onChange?.(DateInputUtils.localDateTimeToISODateTime(event.target.value))}
    onBlur={onBlur}
  />
);
