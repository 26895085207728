import { errorToast, swallowPromiseResponse } from '@/helpers';
import InVivoError from '@/model/InVivoError.ts';
import { useMountedState } from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import type { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { notAborted, useAbortController } from './useAbortController';

type UseEntityType =
  | 'studyAnimal'
  | 'animal'
  | 'animalObservations'
  | 'observationFilterOptions'
  | 'animalSamples'
  | 'animalSample'
  | 'animalLatestMeasurement'
  | 'study'
  | 'studyForm'
  | 'studyMetrics'
  | 'studyTreatment'
  | 'cage'
  | 'species'
  | 'glossaryMeta'
  | 'teamGlossary'
  | 'requestTemplate'
  | 'studyGroups'
  | 'sample'
  | 'metadataOptions'
  | 'approval'
  | 'studyDraft'
  | 'project'
  | 'treatmentForm'
  | 'requestApproval'
  | 'approvalComments'
  | 'randomizationReport'
  | 'mapDbId'
  | 'animalFilters';

const entityTypes: Record<UseEntityType, string> = {
  studyAnimal: 'studies.animal.show',
  animal: 'animal',
  animalObservations: 'v1.observations.get',
  observationFilterOptions: 'studies.observations.types',
  animalSamples: 'v1.samples.get',
  animalSample: 'animal.sample.get',
  animalLatestMeasurement: 'animal.latestMeasurement.get',
  study: 'studies.show.p',
  studyForm: 'team.studies.forms.get',
  studyMetrics: 'studies.metrics',
  studyTreatment: 'studies.treatment',
  cage: 'cage.index',
  species: 'species.index',
  glossaryMeta: 'meta-glossary.show',
  teamGlossary: 'team-glossary.show',
  requestTemplate: 'requests.request.template.show',
  studyGroups: 'team.studies.groups',
  sample: 'sample.index',
  metadataOptions: 'metadata.options.show',
  approval: 'approvals.request.show',
  studyDraft: 'drafts.studies.get',
  project: 'projects.get',
  treatmentForm: 'treatment.form',
  requestApproval: 'approvals.requests',
  approvalComments: 'approvals.comments',
  randomizationReport: 'studies.randomization.report',
  mapDbId: 'util.db.id',
  animalFilters: 'animals.filterOptions',
};

interface UseFetchEntityProps {
  entityType: UseEntityType;
  params?: Record<string, unknown>;
  queryParams?: Record<string, unknown>;
  includes?: string;
}

export interface UseFetchEntityOutput<T> {
  entity: T | undefined;
  fetchEntity: () => Promise<void>;
  entityLoading: boolean;
  entityUpdating: boolean;
  entityError: Error | false;
}

const useFetchEntity = <T = unknown>({
  entityType,
  params = {},
  queryParams = {},
  includes = '',
}: UseFetchEntityProps): UseFetchEntityOutput<T> => {
  const [entityLoading, setEntityLoading] = useState(true);
  const [entityUpdating, setEntityUpdating] = useState(false);
  const [entityError, setEntityError] = useState<Error | false>(false);
  const [entity, setEntity] = useState<T | undefined>(undefined);
  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();

  const fetchEntity = async () => {
    setEntityUpdating(true);
    try {
      let qs = { ...queryParams };
      if (includes !== '') {
        qs = { ...qs, include: includes };
      }
      const uri = entityTypes[entityType];
      const url = apiRoute(uri, { ...params }, qs);

      let config: AxiosRequestConfig = {
        signal: newAbortController().signal,
      };
      if (uri.startsWith('v1')) {
        config = { ...config, baseURL: AppConfig.internalApiUrl };
      }

      const response: any = await Http.get<{ data: T }>(url, config);
      const data = response?.data?.data ?? response?.data ?? response;

      if (isMounted()) {
        setEntity(data);
        setEntityLoading(false);
        setEntityUpdating(false);
      }
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setEntityError(error as Error);
        errorToast('There was a problem fetching data from the server');
        setEntityLoading(false);
        setEntityUpdating(false);
        ExceptionHandler.captureException(
          new InVivoError(`Api Error (Fetch Entity): ${entityType}`, {
            cause: error,
            slug: 'use-fetch-entity',
          })
        );
      }
    }
  };

  useEffect(() => {
    swallowPromiseResponse(fetchEntity());
  }, []);

  return {
    entity,
    fetchEntity,
    entityLoading,
    entityUpdating,
    entityError,
  };
};

export default useFetchEntity;
