import { ISODateTime } from '@/model/Common.model.ts';
import { DateUtils } from '@/utils/Date.utils.ts';
import { tz, TZDate } from '@date-fns/tz';
import { endOfDay, lastDayOfWeek, startOfDay, startOfWeek } from 'date-fns';
import { Reducer } from 'react';
import { CalendarAction, type CalendarState, DateRange } from './Calendar.model';

const todayState = (): Pick<CalendarState, 'selectedDate' | 'yearAndMonth'> => {
  const timezoneId = DateUtils.timezone();
  const now = TZDate.tz(timezoneId);
  const timeZone = tz(timezoneId);
  return {
    selectedDate: DateUtils.formatISO(startOfDay(now, { in: timeZone }).getTime()),
    yearAndMonth: [now.getFullYear(), now.getMonth()],
  };
};

export const initialState = (): Readonly<CalendarState> => {
  return {
    ...todayState(),
    span: 'Week',
    filters: { study_api_id: [], assignee: [], status: [] },
  };
};

export const reducer: Reducer<CalendarState, CalendarAction> = (state, action) => {
  switch (action.type) {
    case 'SET_CHOSEN_DAY':
      return {
        ...state,
        selectedDate: action.data,
      };
    case 'SET_SPAN':
      return {
        ...state,
        span: action.data,
      };
    case 'SET_YEAR_AND_MONTH':
      return {
        ...state,
        yearAndMonth: action.data,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.data,
      };
    case 'GO_TO_DAY':
      return {
        ...state,
        span: 'Day',
        selectedDate: action.data,
      };
    default: {
      return state;
    }
  }
};

export const weekRange = (datetime: ISODateTime): DateRange => {
  const timezoneId = DateUtils.timezone();
  const timezone = tz(timezoneId);
  const zonedDate = new TZDate(datetime, timezoneId);
  return {
    start: DateUtils.formatISO(
      startOfDay(startOfWeek(zonedDate, { weekStartsOn: 1, in: timezone }), { in: timezone }).getTime()
    ),
    end: DateUtils.formatISO(
      endOfDay(lastDayOfWeek(zonedDate, { weekStartsOn: 1, in: timezone }), { in: timezone }).getTime()
    ),
  };
};

export const dayRange = (datetime: ISODateTime): DateRange => {
  const timezoneId = DateUtils.timezone();
  const timezone = tz(timezoneId);
  const zonedDate = new TZDate(datetime, timezoneId);
  return {
    start: DateUtils.formatISO(startOfDay(zonedDate, { in: timezone }).getTime()),
    end: DateUtils.formatISO(endOfDay(zonedDate, { in: timezone }).getTime()),
  };
};
