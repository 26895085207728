// @ts-nocheck: converted from JS

import './Menu.scss';
import { FC } from 'react';

interface MenuContainerProps {
  children?: any;
  width?: any;
  noWrap?: any;
  style?: any;
}

const MenuContainer: FC<MenuContainerProps> = ({ children, width, noWrap = false, style: incomingStyle = {} }) => {
  const style = { width: width ? `${width}px` : '240px', ...incomingStyle };
  if (!noWrap) {
    style.overflowWrap = 'anywhere';
  }
  return (
    <div className="ui-card ui__select-menu" style={style} data-testid="menu-container">
      {children}
    </div>
  );
};

export default MenuContainer;
