// @ts-nocheck: converted from JS

import InfoLink from '@/components/InfoLink';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { web as webRoute } from '@/support/route';
import NoData from '@/components/NoData';
import * as Auth from '@/support/auth';
import { isClosed } from '@/support/study';
import { _isNil } from '@/littledash';
import { modalAction } from '@/utils/modal';

const AssignCages = ({ study, setCages, fetchCages }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { features } = useSelector(({ team: { features } }) => ({
    features,
  }));
  const { openModal } = modalAction(dispatch);

  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  const hasAccess = Auth.isWriteUserForStudy(study);

  const assignFromColony = () => {
    history.push(
      webRoute('colony.add.cage', {
        studyId: study.id,
      })
    );
  };

  const createWithColony = () => {
    history.push(
      webRoute('colony.add.study', {
        studyId: study.id,
      })
    );
  };
  const onComplete = () => {
    fetchCages();
  };

  const importFromAtunes = () => {
    openModal('ATUNES_IMPORT_ANIMALS', { studyApiId: study.api_id, onComplete });
  };

  const quickCreateCages = () => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_CAGES',
      props: {
        study,
        cages: study.collections?.data || [],
        handleCallback: (res) => {
          const {
            data: { data: newCages },
          } = res;
          const cages = newCages.map((cage) => ({
            ...cage,
            isVisible: true,
            subjects: { data: [] },
          }));
          setCages(cages);
          dispatch({ type: 'CLOSE_MODAL' });
        },
      },
    });
  };

  return hasAccess ? (
    <NoData title="No cages added" text="Select one of the following options to continue." isFullScreen>
      {!isClosed(study) && (
        <div className="pa4">
          {atunesIntegrationEnabled ? (
            <>
              <InfoLink
                to={importFromAtunes}
                linkText="tick@lab"
                infoText="Select from cages you or your team have already created in Colony."
                className="mb3"
              />
              <InfoLink
                to={assignFromColony}
                linkText="Assign cages from Colony"
                infoText="Select from cages you or your team have already created in Colony."
                className="mb3"
              />
            </>
          ) : (
            <>
              <InfoLink
                to={assignFromColony}
                linkText="Assign cages from Colony"
                infoText="Select from cages you or your team have already created in Colony."
                className="mb3"
              />
              <InfoLink
                to={createWithColony}
                linkText="Create cages with animals"
                infoText="Add new cages, animals and any relevant metadata."
                className="mb3"
              />
              <InfoLink
                to={quickCreateCages}
                linkText="Quick create cages"
                infoText="Simply choose the amount of cages to create. You can add other data later."
              />
            </>
          )}
        </div>
      )}
    </NoData>
  ) : (
    <NoData
      title="You aren't authorized"
      text="Only team members with Write access have access to create Cages. The study author can update your permissions to give you access."
      link={_isNil(study) ? '/colony' : `/studies/${study?.id}/animals/`}
      btnTxt="Return to animals"
      isFullScreen
    />
  );
};

export default AssignCages;
