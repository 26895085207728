import { AddTaskExecutionForm } from '@/components/Modals/AddTask/Steps/AddTaskExecutionForm.tsx';
import Link from '@/components/UI/Link';
import { _notNil } from '@/littledash';
import useStepForm from '@/support/Hooks/form/useStepForm';
import { featuresSelector } from '@/support/Selectors.tsx';
import { DateUtils } from '@/utils/Date.utils';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import type { FC } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form@latest';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import {
  AddTaskProps,
  IStep,
  OptionalStepFormPayloadProps,
  StepFormPayloadProps,
  StepRenderProps,
} from './AddTask.model';
import styles from './AddTask.module.scss';
import { handleAddTaskStepFormPayload } from './AddTask.utils';
import AddTaskAssigneesForm from './Steps/AddTaskAssigneesForm';
import AddTaskOverviewForm from './Steps/AddTaskOverviewForm';
import AddTaskScheduleForm from './Steps/AddTaskScheduleForm/AddTaskScheduleForm';
import AddTaskTargetForm from './Steps/AddTaskTargetForm';

export const allSteps: Array<IStep> = [
  {
    name: 'overview',
    title: 'Overview',
    display: () => true,
    render: (props: StepRenderProps) => <AddTaskOverviewForm {...props} />,
  },
  {
    name: 'execution',
    title: 'Execution',
    display: (type) => _notNil(type) && type !== 'other',
    render: (props: StepRenderProps) => <AddTaskExecutionForm {...props} />,
  },
  {
    name: 'schedule',
    title: 'Schedule',
    display: () => true,
    render: (props: StepRenderProps) => <AddTaskScheduleForm {...props} />,
  },
  {
    name: 'target',
    title: 'Target',
    display: () => true,
    render: (props: StepRenderProps) => <AddTaskTargetForm {...props} />,
  },
  {
    name: 'assignees',
    title: 'Assignees',
    display: () => true,
    render: (props: StepRenderProps) => <AddTaskAssigneesForm {...props} />,
  },
];
const selector = createSelector([featuresSelector], (features) => ({
  typedTasksEnabled: features?.typed_tasks ?? false,
}));

const AddTask: FC<AddTaskProps> = ({
  closeModal,
  studyId,
  studyApiId,
  studyStartedOn,
  studyCreationUsers,
  teamUsers,
  studyCreationGroups,
  execution,
  handleSubmit,
}) => {
  const { typedTasksEnabled } = useSelector(selector);
  const initialValues: StepFormPayloadProps = useMemo(
    () => ({
      overview: {
        title: '',
        type: typedTasksEnabled ? undefined : 'other',
        description: '',
      },
      execution: {},
      assignees: [],
      schedule: {
        type: 'one_off',
        start: _notNil(studyStartedOn) && DateUtils.isValidDate(studyStartedOn) ? studyStartedOn : DateUtils.dateNow(),
        end: '',
      },
      target: {
        type: 'animal',
      },
    }),
    [studyStartedOn, typedTasksEnabled]
  );
  const [stepFormState, setStepFormState] = useState<StepFormPayloadProps>(initialValues);
  const formMethods = useForm<StepFormPayloadProps>({ defaultValues: initialValues });
  const { reset: resetForm } = formMethods;
  const taskType = useWatch({ control: formMethods.control, name: 'overview.type' });

  const { steps, stepTitles } = useMemo(() => {
    const steps = allSteps.filter((s) => s.display(taskType) ?? false);
    const stepTitles = steps.map(({ name, title }) => ({ name, title }));
    return { steps, stepTitles };
  }, [taskType]);

  const { step: currentStep, nextStep, backStep, hasBack, hasNext } = useStepForm({ totalSteps: steps.length });
  const handleNextStep = (stepFormData: OptionalStepFormPayloadProps): void => {
    const updatedStepFormPayload: StepFormPayloadProps = { ...stepFormState, ...stepFormData };
    setStepFormState(updatedStepFormPayload);
    if (hasNext) {
      // On each step reset the defaultValues cache with the updated form state
      resetForm(updatedStepFormPayload);
      nextStep();
    } else {
      if (_notNil(handleSubmit)) {
        handleSubmit(handleAddTaskStepFormPayload(updatedStepFormPayload));
        closeModal();
      }
    }
  };

  const handleBackStep = () => {
    if (hasBack) {
      backStep();
    } else {
      closeModal();
    }
  };

  return (
    <ModalContainer size="medium" testKey="add-task-v2-modal">
      <ModalHeader
        title="Create a task"
        subText={
          <>
            Enter the details of the task you want to create.{' '}
            <Link
              to="https://help.benchling.com/hc/en-us/articles/22039063448077-Adding-and-amending-Tasks"
              openTab
              className="dib link blue"
            >
              Read more
            </Link>
          </>
        }
        subTextClasses="f6 lh-copy mt1 measure-wide"
        closeModal={closeModal}
      />
      <AddTaskBreadCrumbs stepTitles={stepTitles} currentStep={currentStep} />
      <FormProvider {...formMethods}>
        <div className={`${styles['step-container']} h-100`}>
          {steps?.[currentStep]?.render({
            onSubmit: handleNextStep,
            onCancel: handleBackStep,
            submitButtonText: hasNext ? 'Next' : 'Submit',
            cancelButtonText: hasBack ? 'Back' : 'Cancel',
            studyId,
            studyApiId,
            studyCreationUsers,
            teamUsers,
            studyCreationGroups,
            execution,
          }) ?? null}
        </div>
      </FormProvider>
    </ModalContainer>
  );
};

export const AddTaskBreadCrumbs: FC<{
  currentStep: number;
  stepTitles: Array<Pick<IStep, 'name' | 'title'>>;
}> = ({ stepTitles, currentStep }) => (
  <div className="flex justify-between items-center bg-near-white br2 pa2 ma3">
    {stepTitles.map((step, index) => (
      <Fragment key={step.name}>
        <h3
          className={`${currentStep === index ? 'near-black fw5' : 'dark-gray'} pa2 f6 lh-title`}
          data-testid="breadcrumb-title"
        >
          {step.title}
        </h3>
        {stepTitles.length > index + 1 && (
          <span data-testid="breadcrumb-next-arr">
            <RiArrowRightSLine size={20} />
          </span>
        )}
      </Fragment>
    ))}
  </div>
);

export default AddTask;
