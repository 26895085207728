import type { ApprovalType, Approvers } from '@/model/Approval.model';
import type { ID } from '@/model/Common.model';
import type { MainUser } from '@/model/User.model';

export const ApprovalTypes: Record<ApprovalType, ApprovalType> = {
  unordered: 'unordered',
  ordered: 'ordered',
  min_required: 'min_required',
};

export const approvalTypeToTextMap = {
  [ApprovalTypes.unordered]: 'All reviews are required, but can be in any order',
  [ApprovalTypes.ordered]: 'All reviews are required and in a specific order',
  [ApprovalTypes.min_required]: 'A minimum number of reviews are required',
};

export interface ApproversFormData {
  type: ApprovalType;
  approvers: Array<ID>;
  minNumberApprovers: number;
}

interface ApproversRequest {
  approvers: Approvers;
}

export const mapFormDataToApprovers = (formData: ApproversFormData, approvalUsers: Array<MainUser>) => {
  const approversRequest: ApproversRequest = {
    approvers: {
      type: formData.type || ApprovalTypes.unordered,
      ids:
        formData.type === ApprovalTypes.ordered
          ? approvalUsers.map((approvalUser) => approvalUser.id)
          : formData.approvers,
    },
  };
  if (formData.type === ApprovalTypes.min_required) {
    approversRequest.approvers.amount_required = formData.minNumberApprovers ?? formData.approvers.length;
  }
  return approversRequest;
};

export const mapApproversToFormData = (approvers: Approvers) => ({
  type: approvers?.type || ApprovalTypes.unordered,
  approvers: approvers?.ids || [],
  minNumberApprovers: approvers?.amount_required ?? undefined,
});

export const lessThanOrEqual = (theArray: Array<unknown>, errorMessage: string) => (value: number) =>
  Number(value) <= theArray.length || errorMessage;

export const isPositiveInteger = (value: number) =>
  (Number.isInteger(Number(value)) && Number(value) >= 0) || 'Enter a valid number of reviewers';

export const isActive = (user: MainUser) => user.pivot.status === 'active';
