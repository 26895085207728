// @ts-nocheck: converted from JS

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { web as webRoute } from '@/support/route';

const useSelectStudyCreationFormModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return {
    openSelectStudyCreationFormModal: () =>
      dispatch({
        type: 'OPEN_MODAL',
        modal: 'SELECT_STUDY_CREATION_FORM',
        props: {
          onSubmit: (formId) => {
            history.push(
              webRoute('studies.new', {
                formId,
              })
            );
          },
        },
      }),
  };
};

export default useSelectStudyCreationFormModal;
