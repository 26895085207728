import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Radio from '@/components/UI/FormElements/Radio';
import { SelectMenu } from '@/components/UI/SelectMenu';
import { trunc } from '@/helpers';
import { _isEmptyString, _isNotEmpty, _notNil } from '@/littledash';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import useSearch from '@/support/Hooks/useSearch';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import type { GroupApiId, TreatmentGroup } from 'model/TreatmentGroup.model';
import { FC, ReactNode, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { StepFormPayloadProps, type StepRenderProps } from '../AddTask.model';
import styles from '../AddTask.module.scss';

const AddTaskTargetForm: FC<StepRenderProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  studyId,
  studyCreationGroups,
  disabledFields,
}) => {
  const { control, watch, handleSubmit: useFormHandleSubmit, formState } = useFormContext<StepFormPayloadProps>();
  const selectedType = watch('target.type');

  return (
    <form
      data-test-component="AddTaskTargetForm"
      data-test-element="form"
      className={styles['step-content']}
      onSubmit={useFormHandleSubmit(onSubmit)}
    >
      <div data-test-element="GroupTargetAnimals">
        <label className="mb2 ph3">This task applies to:</label>
        <div className="mb2 ph3">
          <Controller
            name="target.type"
            control={control}
            defaultValue="animal"
            render={({ field }) => (
              <Radio
                onChange={field.onChange}
                id="animal"
                value="animal"
                label="All animals"
                checked={selectedType === 'animal'}
                disabled={disabledFields?.has('target.type') ?? false}
              />
            )}
          />
        </div>
        <div data-test-element="GroupTargetGroups">
          <div className="mb2 ph3">
            <Controller
              name="target.type"
              control={control}
              defaultValue="animal"
              render={({ field }) => (
                <Radio
                  onChange={field.onChange}
                  id="group"
                  value="group"
                  label="Groups"
                  checked={selectedType === 'group'}
                  disabled={disabledFields?.has('target.type') ?? false}
                />
              )}
            />
          </div>
          {selectedType === 'group' && (
            <div className={cn({ ui__disabled: disabledFields?.has('target.groups') ?? false })}>
              {_notNil(studyId) ? (
                <StudyGroupsProvider studyId={studyId}>
                  {(groups: TreatmentGroup[]) =>
                    (disabledFields?.has('target.groups') ?? false) ? (
                      <ReadOnlyGroupMenu groups={groups} />
                    ) : (
                      <GroupMenu groups={groups} />
                    )
                  }
                </StudyGroupsProvider>
              ) : (
                <GroupMenu groups={studyCreationGroups ?? []} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit" disabled={!formState.isValid}>
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

const StudyGroupsProvider: FC<{ studyId: number; children: (studyGroups: TreatmentGroup[]) => ReactNode }> = ({
  studyId,
  children,
}) => {
  const { response, loading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.groups.list',
    path: { id: studyId },
    query: { perPage: -1 },
    options: { invokeOnInit: true, onError: { slug: 'fetch-study-groups', throw: false } },
  });

  if (loading) {
    return <Loading />;
  }
  return <>{children((response?.body as { data: Array<TreatmentGroup> })?.data ?? [])}</>;
};

const ReadOnlyGroupMenu: FC<{ groups: TreatmentGroup[] }> = ({ groups }) => {
  const { control } = useFormContext<StepFormPayloadProps>();
  const targetGroups = useWatch({ control, name: 'target.groups' });
  const selectedGroups = useMemo(() => {
    const groupIds = new Set([...(targetGroups ?? [])]);
    return (groups ?? []).filter((group) => groupIds.has(group.api_id as GroupApiId));
  }, [groups, targetGroups]);

  return (
    <div
      className="ml3 pv2 flex flex-column overflow-y-auto"
      data-test-component="ReadOnlyGroupMenu"
      data-test-element="container"
    >
      {selectedGroups.map((group) => (
        <div
          key={group.api_id}
          className="flex flex-row justify-start items-center"
          data-test-element="group-entry"
          data-test-key={group.api_id}
        >
          {group.control ? (
            <span className={styles.controlChip} style={{ minWidth: '20px' }}>
              C
            </span>
          ) : (
            <span style={{ minWidth: '20px' }}></span>
          )}
          <span className="ml2">
            <span className="mr1 dib br1" style={{ backgroundColor: group.color, width: 11, height: 11 }} />
          </span>
          <span className="ml2">{group.name}</span>
        </div>
      ))}
    </div>
  );
};

const GroupMenu: FC<{ groups: TreatmentGroup[] }> = ({ groups }) => {
  const { control, formState } = useFormContext<StepFormPayloadProps>();
  const { search, searchResults, resetSearch } = useSearch({
    items: groups,
    accessors: ['name'],
  });

  const handleSearch = (query: string): void => {
    if (_isEmptyString(query)) {
      resetSearch();
    } else {
      search(query);
    }
  };

  const groupResults = searchResults.map((result) => ({
    name: trunc(result.name, 30),
    // Study creation forms create a `uuid` => `temp_id` as part of the payload
    value: result?.temp_id ?? result.api_id,
  }));
  return (
    <>
      <ErrorMessage
        errors={formState.errors}
        name="target.groups"
        render={({ message }) => <p className="f6 red db pv2 ph3">{message}</p>}
      />
      <Controller
        name="target.groups"
        defaultValue={[]}
        control={control}
        rules={{ validate: (value: any) => (_isNotEmpty(value) ? true : 'At least one group is required') }}
        render={({ field }) => (
          <SelectMenu
            preSelectedValues={field.value}
            onSearch={handleSearch}
            onSelect={field.onChange}
            items={groupResults}
            searchPlaceholderText="Search by name"
          />
        )}
      />
    </>
  );
};

export default AddTaskTargetForm;
