// @ts-nocheck: converted from JS

const SummaryItem = ({ label, children }) => {
  return (
    <div className="lh-copy">
      <div className="flex flex-row flex-wrap ph3 pv2 f6">
        <div style={{ width: 150 }}>{label}</div>
        <div className="near-black f6">{children}</div>
      </div>
    </div>
  );
};

export default SummaryItem;
