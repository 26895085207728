import type { DataTableHighVolumeUpsertEvent, DataTableService } from '@/components/UI/DataTable/DataTable.model';
import { DataTableEvent } from '@/components/UI/DataTable/DataTable.model';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
// @ts-expect-error: untyped lib
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

interface HighVolumeUpsertModalProps {
  dataTableService: DataTableService;
  closeModal: () => void;
}

const HighVolumeUpsertModal: FC<HighVolumeUpsertModalProps> = ({ dataTableService, closeModal }) => {
  const [uploadProgress, setUploadProgress] = useState('0');
  const highVolumeUpsertListener = (event: DataTableHighVolumeUpsertEvent): void => {
    const {
      detail: { totalChunkedRequests, resolved },
    } = event;
    setUploadProgress(String(Math.floor((resolved.length / totalChunkedRequests) * 100)));
  };

  useEffect(() => {
    dataTableService.subscribe(DataTableEvent.HighVolumeUpsert, highVolumeUpsertListener);
    return () => {
      dataTableService.unsubscribe(DataTableEvent.HighVolumeUpsert, highVolumeUpsertListener);
    };
  }, [dataTableService]);

  const isComplete = uploadProgress === '100';

  return (
    <ModalContainer size="narrow" className="bg-white">
      <ModalHeader title="High volume upsert" className="pa3 bb b--moon-gray w-100" />
      <div className="pa3 tc">
        <h3 className="lh-title normal f4 pb3">{isComplete ? 'Complete!' : 'Saving...'}</h3>
        <Progress type="circle" percent={uploadProgress} status="active" />
      </div>
      <ModalActions
        className="pa3 mt3 bt b--moon-gray"
        cancelBtnText="Close"
        cancelButtonProps={{
          disabled: !isComplete,
        }}
        onCancel={closeModal}
      />
    </ModalContainer>
  );
};

export default HighVolumeUpsertModal;
