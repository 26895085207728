import { toSlug } from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import Select from '@/components/UI/Select';
import type { SelectOption } from '@/components/UI/Select/Select';
import { components } from '@/generated/internal-api/openapi-types';
import { _isNil, _isNotBlank, _notNil } from '@/littledash.ts';
import { PresetMeasurementCreateV1 } from '@/model/Preset.model.ts';
import cn from 'classnames';
import { FC, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import style from './PresetBuilder.module.scss';

type AutoSwap = components['schemas']['PresetMeasurementAutoSwapV1.schema'];

export const AutoSwapForm: FC<{ disabled: boolean }> = ({ disabled }) => {
  const formMethods = useFormContext<PresetMeasurementCreateV1>();
  const [valueBeforeDisable, setValueBeforeDisable] = useState<Partial<AutoSwap>>(() => ({
    min: undefined,
    max: undefined,
  }));
  const [autoSwap, inputs] = useWatch({
    control: formMethods.control,
    name: ['config.auto_swap', 'inputs'],
  });
  const { selectOptions, selectOptionCount } = useMemo(() => {
    const selectOptions = (inputs ?? []).reduce<Record<string, SelectOption<string>>>((acc, input) => {
      const slug = toSlug(input.name);
      return {
        ...acc,
        [slug]: { value: slug, label: input.name },
      };
    }, {});
    return { selectOptions, selectOptionCount: Object.keys(selectOptions).length };
  }, [inputs]);
  return (
    <div className="flex flex-column w-100" data-test-component="AutoSwapForm" data-test-element="container">
      <div className="flex flex-row" data-test-element="chckbox-row">
        <Controller
          control={formMethods.control}
          name="config.auto_swap"
          render={({ field }) => (
            <Checkbox
              label="Enable input auto swap"
              testPrefix="auto_swap_enable"
              checked={!disabled && selectOptionCount >= 2 && _notNil(field.value)}
              disabled={disabled || selectOptionCount < 2 || field.disabled}
              onChange={(event) => {
                setValueBeforeDisable({ ...autoSwap });
                field.onChange(event.target.checked ? valueBeforeDisable : null);
              }}
            />
          )}
        />
      </div>
      {!disabled && _notNil(autoSwap) && selectOptionCount >= 2 && (
        <div className="flex flex-column w-100 pt4" data-test-element="min-max-options-row">
          <div className="flex flex-row pv2 pl2 items-center" data-test-element="max-row">
            <label className={cn('pr2', style.autoSwapLabel)}>Ensure the largest value is</label>
            <Controller
              control={formMethods.control}
              name="config.auto_swap.max"
              render={({ field, fieldState }) => {
                const maxValue = selectOptions?.[field?.value ?? ''];
                return (
                  <span
                    className={cn({ [style.invalid]: fieldState.invalid || _isNil(maxValue) })}
                    data-tooltip-id="global-tooltip-id"
                    data-tooltip-content={fieldState.invalid || _isNil(maxValue) ? 'Invalid selection' : undefined}
                  >
                    <Select
                      name="auto_swap.max"
                      options={Object.values(selectOptions)}
                      value={maxValue}
                      onChange={(selection) => field.onChange(selection?.value)}
                      onBlur={field.onBlur}
                      isMulti={false}
                      isSearchable
                    />
                  </span>
                );
              }}
            />
          </div>
          <div className="flex flex-row pv2 pl2 items-center" data-test-element="min-row">
            <label className={cn('pr2', style.autoSwapLabel)}>Ensure the smallest value is</label>
            <Controller
              control={formMethods.control}
              name="config.auto_swap.min"
              render={({ field, fieldState }) => {
                const maxValue = formMethods.getValues('config.auto_swap.max');
                const options = Object.values(selectOptions).filter((o) => o.value !== maxValue);
                const minValue = options.length === 1 ? options[0] : selectOptions?.[field?.value ?? ''];
                if (field?.value !== minValue?.value && _isNotBlank(minValue?.value)) {
                  field.onChange(minValue.value);
                }
                return (
                  <span
                    className={cn({ [style.invalid]: fieldState.invalid || _isNil(minValue) })}
                    data-tooltip-id="global-tooltip-id"
                    data-tooltip-content={fieldState.invalid || _isNil(minValue) ? 'Invalid selection' : undefined}
                  >
                    <Select
                      name="auto_swap.min"
                      options={options}
                      value={minValue}
                      disabled={options.length === 1 && !fieldState.invalid}
                      onChange={(selection) => field.onChange(selection?.value)}
                      onBlur={field.onBlur}
                      isMulti={false}
                      isSearchable
                    />
                  </span>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
