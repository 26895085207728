import { formatNumber } from '@/helpers';
import type { GraphsReducer, GraphsState } from './Graph.model';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { ID as AnimalId, ID, ISODate } from '@/model/Common.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import type { Animal } from '@/model/Animal.model';
import type { VariableApiId } from '@/model/Measurement.model';

interface VariableValue {
  api_id: VariableApiId;
  excluded: boolean;
  study_day: number;
  value: number;
}

export type AnimalValues = VariableValue[];

interface GroupMetricAnimal extends Pick<Animal, 'api_id' | 'id' | 'name' | 'number'> {
  values: AnimalValues;
}

interface GroupExtendedWithMetrics extends TreatmentGroup {
  animals: GroupMetricAnimal[];
  survival: unknown;
}

export interface GroupMetrics {
  survival_end: ISODate;
  survival_start: ISODate;
  data: GroupExtendedWithMetrics[];
}

export const initialState = (state?: Partial<GraphsState>): GraphsState => ({
  activePanel: 'grouped',
  loading: true,
  metricsLoading: false,
  error: undefined,
  metrics: [],
  groups: [],
  changeOption: 'absolute',
  activeMetric: {},
  survivalDates: {
    start: undefined,
    end: undefined,
  },
  ...(state ?? {}),
});

export const getParamsFromState = (state: GraphsState): URLSearchParams => {
  const { activeMetric, changeOption, groups, metrics } = state;

  const urlSearchParams = new URLSearchParams({
    measurement_type: activeMetric?.id ?? 'weight',
    changeOption,
    include: 'metadata',
  });

  const groupIds = groups.filter(({ checked }) => checked).map(({ id }) => id);
  if (groupIds.length) {
    urlSearchParams.append('include', 'grouped');
    for (const groupId of groupIds) {
      urlSearchParams.append('study_groups[]', groupId.toString());
    }
  } else if (metrics?.length) {
    urlSearchParams.append('include', 'ungrouped');
    urlSearchParams.set('exclude', 'grouped');
  } else {
    urlSearchParams.append('include', 'grouped');
  }

  return urlSearchParams;
};

export const formatAverage = (average: number) => (average ? formatNumber(average) : '-');

export const reducer: GraphsReducer = (state, action) => {
  switch (action.type) {
    case 'setLoading': {
      return { ...state, loading: action.data };
    }
    case 'setMetricsLoading': {
      return { ...state, metricsLoading: action.data };
    }
    case 'setApiError': {
      return { ...state, error: action.data };
    }
    case 'setMetrics': {
      return { ...state, metrics: action.data };
    }
    case 'setSurvivalDates': {
      return { ...state, survivalDates: action.data };
    }
    case 'setChangeOption': {
      return { ...state, changeOption: action.data };
    }
    case 'setActiveMetric': {
      return { ...state, activeMetric: action.data };
    }
    case 'setGroups': {
      return { ...state, groups: action.data };
    }
    case 'change-panel': {
      return { ...state, activePanel: action.data };
    }
    default: {
      return { ...state };
    }
  }
};

export const metricsToAnimalValuesMap = (metrics: GroupMetrics['data']): Record<AnimalId, AnimalValues> => {
  const result: Record<AnimalId, AnimalValues> = {};
  if (_isNil(metrics)) {
    return result;
  }
  for (const group of metrics) {
    if (_isEmpty(group?.animals)) {
      continue;
    }

    for (const animal of group.animals) {
      if (_isNil(animal.id)) {
        continue;
      }

      if (_isNotEmpty(animal?.values)) {
        result[animal.id] = animal.values;
      } else {
        result[animal.id] = [];
      }
    }
  }

  return result;
};

export const getExcludedIncludedMap = (metrics: GroupMetrics['data']): Record<VariableApiId, boolean> => {
  const result: Record<VariableApiId, boolean> = {};

  for (const group of metrics) {
    if (_isEmpty(group?.animals)) {
      continue;
    }

    for (const animal of group.animals) {
      if (_isNotEmpty(animal?.values)) {
        for (const value of animal.values) {
          if (_notNil(value?.api_id)) {
            result[value.api_id] = value.excluded;
          }
        }
      } else {
        continue;
      }
    }
  }

  return result;
};

export const allAnimalVariablesExcluded = (variableMap: Record<VariableApiId, boolean>): boolean => {
  if (_isNil(variableMap)) {
    return true;
  }
  for (const variableKey in variableMap) {
    if (variableMap[variableKey as VariableApiId] === false) {
      return false;
    }
  }
  return true;
};

export const getExcludedOrIncludedVariableApiIds = (
  variableMap: Record<VariableApiId, boolean>,
  excluded: boolean = true
): VariableApiId[] => {
  const apiIds: VariableApiId[] = [];
  if (_isNil(variableMap)) {
    return apiIds;
  }
  for (const variableKey in variableMap) {
    if (variableMap[variableKey as VariableApiId] === excluded) {
      apiIds.push(variableKey as VariableApiId);
    }
  }
  return apiIds;
};

export const getVariableApiIdsFromMetrics = (animalId: ID, metrics: GroupMetrics['data']): VariableApiId[] => {
  const result: VariableApiId[] = [];

  for (const group of metrics) {
    if (_isEmpty(group?.animals)) {
      continue;
    }

    for (const animal of group.animals) {
      if (_isNotEmpty(animal?.values) && animal.id === animalId) {
        for (const value of animal.values) {
          if (_notNil(value?.api_id)) {
            result.push(value.api_id);
          }
        }
      } else {
        continue;
      }
    }
  }

  return result;
};
