/**
 * Lightweight Axios wrapper
 */

import { _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import store from '@/store';
import { web as webRoute } from '@/support/route.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { history } from '@/utils/history';
import axios from 'axios';

const instance = axios.create({ baseURL: AppConfig.legacyInternalApiUrl });

instance.interceptors.request.use(
  (config) => {
    if ((config.fetchOptions?.addAuth ?? true) === true) {
      const token = window.localStorage.getItem('token');
      const teamId = window.localStorage.getItem('team_id');

      if (_notNil(token) && !config.headers.has('Authorization')) {
        config.headers.set('Authorization', `Bearer ${token}`, true);
      }
      if (_notNil(teamId)) {
        config.headers.set('Team-Id', teamId, true);
      }
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err.response?.status === 401) {
      store.dispatch({ type: 'LOGOUT' });
      history.push('/login');
      return Promise.reject(err);
    }

    if (err.response?.status === 403) {
      if (err.response?.data?.message === 'create_account') {
        history.push('/create-account');
      } else if (err?.config?.redirect?.[403] ?? true) {
        history.push('/error');
      }
      return Promise.reject(err);
    }

    if (err.response?.status === 404) {
      return Promise.reject(err);
    }

    if (err.response?.status === 410) {
      ExceptionHandler.captureException(
        new InVivoError(`Deprecated API (${err?.config?.url})`, {
          cause: err,
          slug: 'deprecated-api',
          context: { api_path: err?.config?.url },
        })
      );
      history.push(webRoute('unsupported.client.version'));
      return Promise.reject(err);
    }

    if (err.response?.status === 503) {
      history.push('/maintenance');
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export default instance;
