import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const usePrevious = <T>(value: T) => {
  // Refs value change doesn't take effect until next render.
  // So we are mutating the ref object, setting it to the value passed in, but not causing a render in the process.
  // Then on the next render the new value is provided, which is a render behind, so the previous value.
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useInitialRender = () => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
  });
  return ref.current;
};
