import DebounceInput from '@/components/UI/DebounceInput';
import { hasOwnProperty } from '@/helpers';
import type { ID } from '@/model/Common.model';
import type { FC } from 'react';
import './Repeater.scss';
import type { Errors } from './Repeater.utils';

export interface TitleProps {
  errors: Errors[];
  title?: string;
  id?: ID;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Title: FC<TitleProps> = ({ title, errors, id, handleChange, disabled }) => {
  const hasErrors = errors.find(({ id: fieldId }) => String(fieldId) === String(id));
  return (
    <DebounceInput
      handleChange={handleChange}
      inputProps={{
        name: 'title',
        value: title,
        'data-id': id,
        style: { marginBottom: 0 },
        type: 'text',
        className: `z-5 relative ${hasErrors && 'input__error'}`,
        'data-tooltip-id': 'global-tooltip-id',
        'data-tooltip-content':
          hasErrors && hasOwnProperty(hasErrors.errors, 'title') ? hasErrors.errors.title.map((e, i) => e) : '',
        disabled,
      }}
    />
  );
};
