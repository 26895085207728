import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { SummaryItem } from '@/components/Studies/Show';
import SubHeader from '@/components/UI/SubHeader';
import Table from '@/components/UI/Table';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import './Report.scss';
import { formatNumber } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DateTimeRenderer } from '../../../UI/DateRenderers/DateRenderers';
import {
  RandomAnimal,
  RandomizationMethodKeys,
  RandomizationMethods,
  RandomTreatmentGroup,
} from '../Steps/Randomize/Randomize.model';
import { mappedAttrs } from '../Steps/Randomize/Randomize.utils';
import type { OneWayAnovaResults } from '../Steps/Randomize/Statistics.model';
import type { CriteriaOptions } from './Report.utils';
import { generateAnimalColumns, resultColumns } from './Report.utils';

const Report: React.FC = () => {
  const [animalColumns, setAnimalColumns] = useState<Array<Column<RandomAnimal>>>([]);
  const { id: studyId, reportApiId } = useParams<{
    id: string;
    reportApiId: string;
  }>();

  const modalActions = modalAction(useDispatch());

  const {
    entity: studyApiId,
    entityLoading: studyLoading,
    entityError: studyError,
  } = useFetchEntity<Array<{ api_id: string }>>({
    entityType: 'mapDbId',
    params: { entity: 'study' },
    queryParams: { 'id[]': studyId },
  });

  const { invoke, response: randomization } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/randomizations/{randomizationId}',
    path: { studyId: '', randomizationId: '' },
    invokeOnInit: false,
  });

  const criteriaOptions = (randomization?.body?.study_calculations || []).reduce<CriteriaOptions>(
    (acc, calculation) => {
      if (_isNotEmpty(calculation)) {
        const { name, id, unit } = calculation;
        if (_notNil(id)) {
          acc[id] = {
            name: `${name} ${unit ? `(${unit})` : ''}`,
            value: id,
          };
        }
      }
      return acc;
    },
    {}
  );

  useEffect(() => {
    if (_notNil(studyApiId)) {
      invoke({
        path: { studyId: studyApiId[0].api_id, randomizationId: reportApiId },
      });
    }
  }, [studyApiId]);

  useEffect(() => {
    if (randomization?.type === 'success' && _notNil(studyApiId)) {
      setAnimalColumns(
        generateAnimalColumns(
          randomization.body.animals as Array<RandomAnimal>,
          randomization.body.groups as Array<RandomTreatmentGroup>,
          studyId,
          studyApiId[0].api_id,
          criteriaOptions
        )
      );
    }
  }, [randomization]);

  if (studyLoading) {
    return <Loading />;
  }

  if (studyError || _isNil(studyApiId)) {
    return <ApiErrorBanner className="ma3" errorType={ApiErrorType.FETCH} error={studyError} />;
  }

  if (randomization?.type === 'success') {
    const {
      study_name,
      study_error_deviation,
      created_at,
      exclusion_criteria,
      animals,
      animals_excluded,
      mean_post_exclusion,
      criteria,
      groups,
      randomization_method,
      user: { name } = {},
    } = randomization.body;

    return (
      <>
        <SubHeader linkToText="Overview" link={webRoute('studies.overview', { id: studyId })} />
        <div className="ph4 pt4 flex justify-between">
          <span>
            <p>{study_name}</p>
            <h3>
              Randomization: <DateTimeRenderer value={created_at} />
            </h3>
          </span>
        </div>
        <div className="ma4 pa3 ui-card">
          <div className="flex flex-row mb3">
            <div>
              <SummaryItem label="Method">
                {RandomizationMethods[randomization_method as RandomizationMethodKeys]}
              </SummaryItem>
              <SummaryItem label="Exclusion Criteria">
                {_notNil(exclusion_criteria) ? criteriaOptions[exclusion_criteria].name : ''}
              </SummaryItem>
              <SummaryItem label="Total Animals">{animals?.length}</SummaryItem>
              <SummaryItem label="Excluded">{animals_excluded}</SummaryItem>
              <SummaryItem label="Post exclusion mean">
                {_notNil(mean_post_exclusion) ? formatNumber(mean_post_exclusion) : '-'}
              </SummaryItem>
            </div>
            <div className="ml6">
              <SummaryItem label="Metrics">
                <span style={{ whiteSpace: 'pre' }}>
                  {_isNotEmpty(criteria)
                    ? criteria?.map((c) => criteriaOptions[c.toString()]?.name).join('\r\n')
                    : 'None'}
                </span>
              </SummaryItem>
              <SummaryItem label="Attribute">
                {mappedAttrs[Object.keys(groups?.[0]?.subjects as Record<string, number>)[0]]?.Header ?? '-'}
              </SummaryItem>
              <SummaryItem label="User">{name}</SummaryItem>
              {_notNil(randomization.body.measurement_date) && (
                <SummaryItem label="Measurement date">{randomization.body.measurement_date}</SummaryItem>
              )}
            </div>
          </div>
        </div>
        <div className="pb4 ph4">
          <h3 className="mb3">Animals</h3>
          <div className="ui-card mv3">
            <Table data={animals as Array<RandomAnimal>} columns={animalColumns} pagination initPageSize={5} />
          </div>
        </div>
        <div className="pb4 ph4">
          <h3 className="mb3">Results</h3>
          <div className="ui-card mv3">
            <Table
              className="reportTable"
              data={groups as Array<RandomTreatmentGroup>}
              columns={resultColumns(
                groups as Array<RandomTreatmentGroup>,
                criteriaOptions,
                study_error_deviation ?? '',
                randomization.body.one_way_anova as Record<string, OneWayAnovaResults>,
                modalActions
              )}
              pagination
              initPageSize={5}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Report;
