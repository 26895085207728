// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import { successToast } from '@/helpers';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';

const CreateStudyForm = ({ closeModal, onAddTemplate }) => {
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = async (formData) => {
    setSubmitting(true);
    try {
      const {
        data: { data },
      } = await Http.post(apiRoute('team.studies.forms.create'), { ...formData });
      closeModal();
      successToast('Created new template');
      onAddTemplate(data);
    } catch (error) {
      setApiError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="ui-card mw6 pa4 center">
      <h2 className="f4 lh-title normal pb3">Create a new form</h2>
      {apiError && <ApiErrorBanner error={apiError} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            autoFocus
            style={{ marginBottom: 0 }}
            className={`${errors?.name ? 'input__error' : ''}`}
            ref={register({
              required: 'This field is required',
              minLength: { value: 3, message: 'Minimum of 3 characters' },
              maxLength: { value: 255, message: 'Maximum of 255 characters' },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>
        <div className="pt4">
          <Button submit className="mr3" disabled={submitting}>
            Save and continue
          </Button>
          <Button plain onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateStudyForm;
