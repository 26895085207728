import { animalFilterOptions } from '@/components/Animals/AnimalsTable/AnimalsTable.utils';
import { AnimalFiltersEntity } from '@/components/Colony/Animals/Animals.utils';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Column, FilterOption } from '@/components/UI/Table/TableComponent.model';
import { _get, _isNil, _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model';
import { State } from '@/model/State.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { UseFetchEntityOutput } from '@/support/Hooks/fetch/useFetchEntity';
import { RouteService } from '@/support/RouteService';
import { Dispatch, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateBulkDosingState, dosingAnimalTableColumns } from '../CreateDosing.utils';

const searchByOptions = [
  { value: 'name', display: 'Name' },
  { value: 'tail', display: 'Tail' },
  { value: 'ear', display: 'Ear' },
  { value: 'tag', display: 'Tag' },
  { value: 'donor', display: 'Donor' },
];

interface SelectAnimalsProps {
  state: CreateBulkDosingState;
  dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>;
}

export const SelectAnimals: React.FC<SelectAnimalsProps> = ({ state, dispatch }) => {
  const [columns, setColumns] = useState<Array<Column<any>>>([]);
  const settings = useSelector((state: State) => state.ui.settings);
  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));
  const { study } = state;
  const { study_groups: studyGroups, id: studyId } = study;
  const calculations = study?.settings?.calculations;
  const [filterOptions, setFilterOptions] = useState<Array<FilterOption>>([]);

  const { entity: animalFilters }: UseFetchEntityOutput<AnimalFiltersEntity> = useFetchEntity({
    entityType: 'animalFilters',
  });

  const { entity: observationOptions }: UseFetchEntityOutput<Array<string>> = useFetchEntity({
    entityType: 'observationFilterOptions',
    params: { id: studyId },
  });

  useEffect(() => {
    dispatch({
      type: 'updateArray',
      id: 'selectedAnimals',
      data: [],
    });
  }, []);

  useEffect(() => {
    const columns = dosingAnimalTableColumns(
      study.id,
      calculations ?? [],
      settings.tables.animals.columns,
      settings.changePercentage,
      animalFilters?.metadata,
      features
    );

    setColumns(columns);
    dispatch({ type: 'stepReady' });
  }, [animalFilters]);

  useEffect(() => {
    if (
      _notNil(calculations) &&
      _notNil(studyGroups) &&
      _notNil(studyId) &&
      _notNil(observationOptions) &&
      Array.isArray(animalFilters?.metadata)
    ) {
      setFilterOptions(
        animalFilterOptions(calculations, studyGroups, studyId, observationOptions, animalFilters?.metadata)
      );
    }
  }, [calculations, studyGroups, studyId, observationOptions, animalFilters?.metadata]);

  const onSelectRow = (rows: Array<any>, state: any) => {
    if ((rows ?? []).length === 0 || _isNil(state)) {
      return;
    }
    const { id = 'id', desc = false } = state.sortBy[0];
    let accessor = id;
    // only specify the accessor if the sort is one of the following nested sorts:
    switch (id) {
      case 'cage':
        accessor = 'cage.id';
        break;
      case 'weight':
        accessor = 'latestMeasurement.weight.value';
        break;
      case 'study_group':
        accessor = 'study_group_id';
        break;
    }

    const sortedRows = (rows ?? [])
      .map(({ original }: any) => original)
      .sort((a: Animal, b: Animal) => {
        const sortA = _get(a, accessor);
        const sortB = _get(b, accessor);
        return desc ? sortB - sortA : sortA - sortB;
      });

    dispatch({
      type: 'updateArray',
      id: 'selectedAnimals',
      data: sortedRows,
    });
  };

  const treatmentIds = state.selectedTreatments.map(({ api_id }) => api_id);

  return (
    <div className="pt4">
      <APITable
        apiInfo={{
          type: 'internalApi',
          route: RouteService.api({
            endpoint: 'GET /api/v1/studies/{studyId}/animals',
            path: { studyId: state.study?.api_id },
            query: { treatment_ids: treatmentIds, perPage: 1000 },
          }).url,
        }}
        columns={columns}
        includeParam="latestMeasurement,study_group,cage,metadata"
        defaultSortBy={{ id: 'number', desc: false }}
        settings={settings}
        searchPlaceholderText="Search"
        searchQueryByOptions={searchByOptions}
        reduxTableName={'treatments'}
        showMetricChangeFrom={true}
        onSelectRow={onSelectRow}
        pageSizes={[50, 100, 200, 500, 1000]}
        defaultPageSize={1000}
        filterOptions={filterOptions}
        testIdPrefix="animals"
        loadingClassName="pv3"
        maxHeight={450}
      />
    </div>
  );
};
