import ReasonForChange from '@/components/Shared/ReasonForChange';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm, FormProvider } from 'react-hook-form';
import type { FC } from 'react';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';

interface BulkReasonForChangeProps {
  onClick: (reasonForChange?: string) => Promise<void>;
  closeModal: () => void;
  title: string;
}

interface FormDataProps {
  reason_for_change: string;
}

export const BulkReasonForChange: FC<BulkReasonForChangeProps> = ({ onClick, title, closeModal }) => {
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const onSubmit = (form: FormDataProps) => {
    onClick(form.reason_for_change);
    closeModal();
  };

  return (
    <ModalContainer size="narrow" className="bg-white">
      <ModalHeader title={`${title || 'Data has changed'}`} className="pa3 bb b--moon-gray w-100" />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="bulk-reason-for-change-modal">
          <div className="pa3">
            <ReasonForChange data-testid="bulk-reason-for-change-field" />
          </div>
          <ModalActions className="pa3 mt3 bt b--moon-gray" onSubmit={handleSubmit(onSubmit)} onCancel={closeModal} />
        </form>
      </FormProvider>
    </ModalContainer>
  );
};
