import { _isNotEmpty, _notNil } from '@/littledash';
import type {
  DataTableColumnType,
  DataTableMeasurementColumn,
  DataTableService,
  DataTableWorkflow,
  DataTableWorkflowAction,
  DataTableWorkflowActionSource,
  DataTableWorkflowService as IDataTableWorkflowService,
  LoadWorkflowOptions,
  WorkflowActionsExitOptions,
} from '../DataTable.model';
import { DataTableEvent } from '../DataTable.model';

export class DataTableWorkflowService implements IDataTableWorkflowService {
  static sessionStorageKey = 'dtl_workflows';
  #workflowActive = false;
  #rootActionSource: DataTableWorkflowActionSource | undefined;
  #actionSourceMap: Map<DataTableWorkflowActionSource, DataTableWorkflowAction> | undefined;
  #disabledColumns = new Set<DataTableColumnType>(['formula', 'observation']);

  constructor(
    private readonly eventEmitter: EventTarget,
    private readonly dataTableService: DataTableService
  ) {}

  initialise() {
    const workflow = this.#loadWorkflowFromStorage();
    if (_isNotEmpty(workflow)) {
      this.loadWorkflow(workflow, { persist: false });
    }
  }

  loadWorkflow(workflow: DataTableWorkflow, options?: LoadWorkflowOptions): void {
    if (this.workflowValid(workflow)) {
      this.#workflowActive = false;
      const [rootAction] = workflow;
      this.#rootActionSource = rootAction.source;
      this.#actionSourceMap = new Map(workflow.map((action) => [action.source, action]));
      if (options?.persist ?? true) {
        this.#saveWorkflowToStorage(workflow);
      }
    }
  }

  #loadWorkflowFromStorage(): DataTableWorkflow {
    try {
      const dataTableId = this.dataTableService.tableId;
      if (_notNil(dataTableId)) {
        return (
          JSON.parse(sessionStorage.getItem(DataTableWorkflowService.sessionStorageKey) ?? '{}')?.[dataTableId] ?? []
        );
      }
    } catch (e) {
      // intentionally empty
    }
    return [];
  }

  #saveWorkflowToStorage(workflow: DataTableWorkflow): void {
    try {
      const dataTableId = this.dataTableService.tableId;
      if (_notNil(dataTableId)) {
        const workflows = JSON.parse(sessionStorage.getItem(DataTableWorkflowService.sessionStorageKey) ?? '{}');
        sessionStorage.setItem(
          DataTableWorkflowService.sessionStorageKey,
          JSON.stringify({ ...workflows, [dataTableId]: workflow })
        );
      }
    } catch (e) {
      // intentionally empty
    }
  }

  workflowValid(workflow: DataTableWorkflow): boolean {
    const visited = new Set<DataTableWorkflowActionSource>();

    for (const action of workflow) {
      switch (action.type) {
        case 'OpenSearch': {
          const destinationColumn = this.dataTableService.columnById(action.destination);
          const valid =
            _notNil(destinationColumn) &&
            !this.#disabledColumns.has(destinationColumn.type) &&
            !((destinationColumn as DataTableMeasurementColumn)?.measurement?.is_calculated ?? false) &&
            !visited.has(action.source) &&
            !visited.has(action.destination);
          if (!valid) {
            return false;
          }
          break;
        }
        case 'NextAnimal': {
          const destinationColumn = this.dataTableService.columnById(action.destination);
          const valid =
            _notNil(destinationColumn) &&
            !this.#disabledColumns.has(destinationColumn.type) &&
            !((destinationColumn as DataTableMeasurementColumn)?.measurement?.is_calculated ?? false) &&
            !visited.has(action.source) &&
            !visited.has(action.destination);
          if (!valid) {
            return false;
          }
          break;
        }
        case 'SelectColumn': {
          const sourceColumn = this.dataTableService.columnById(action.source);
          const destinationColumn = this.dataTableService.columnById(action.destination);
          const valid =
            _notNil(sourceColumn) &&
            _notNil(destinationColumn) &&
            !this.#disabledColumns.has(sourceColumn.type) &&
            !((sourceColumn as DataTableMeasurementColumn)?.measurement?.is_calculated ?? false) &&
            !this.#disabledColumns.has(destinationColumn.type) &&
            !((destinationColumn as DataTableMeasurementColumn)?.measurement?.is_calculated ?? false) &&
            !visited.has(action.source) &&
            !visited.has(action.destination);
          if (!valid) {
            return false;
          }
          break;
        }
        case 'End': {
          const sourceColumn = this.dataTableService.columnById(action.source);
          const valid =
            _notNil(sourceColumn) &&
            !this.#disabledColumns.has(sourceColumn.type) &&
            !((sourceColumn as DataTableMeasurementColumn)?.measurement?.is_calculated ?? false) &&
            !visited.has(action.source);
          if (!valid) {
            return false;
          }
          break;
        }
        default: {
          return false;
        }
      }
      visited.add(action.source);
    }
    return true;
  }

  activateWorkflow(active = true) {
    if (this.#workflowActive !== active) {
      this.#workflowActive = active;
      this.eventEmitter.dispatchEvent(
        new CustomEvent(DataTableEvent.WorkflowActivated, { detail: Object.freeze({ active }) })
      );
    }
  }

  clearWorkflow(): void {
    try {
      const dataTableId = this.dataTableService.tableId;
      if (_notNil(dataTableId)) {
        const workflows = JSON.parse(sessionStorage.getItem(DataTableWorkflowService.sessionStorageKey) ?? '{}');
        delete workflows[dataTableId];
        sessionStorage.setItem(DataTableWorkflowService.sessionStorageKey, JSON.stringify({ ...workflows }));
        this.activateWorkflow(false);
      }
    } catch (e) {
      // intentionally empty
    }
  }

  get workflowActive(): Readonly<boolean> {
    return this.workflowLoaded() && this.#workflowActive;
  }

  get workflow(): Readonly<DataTableWorkflow> {
    return this.#loadWorkflowFromStorage();
  }

  workflowActionEnter(source: DataTableWorkflowActionSource | undefined): boolean {
    if (this.#workflowActive && _notNil(source) && _notNil(this.#actionSourceMap) && this.sourceInWorkflow(source)) {
      const action = this.#actionSourceMap.get(source) as DataTableWorkflowAction;
      switch (action.type) {
        case 'NextAnimal': {
          const row = (this.dataTableService.selection?.topLeft.row ?? -1) + 1;
          const column = this.dataTableService.toIndexColumn(action.destination);
          const nextRowId = this.dataTableService.rowByIndex(row);
          if (_notNil(nextRowId) && _notNil(column)) {
            this.dataTableService.selectCell({ column, row }, { scrollTo: true });
          }
          break;
        }
      }
      this.eventEmitter.dispatchEvent(
        new CustomEvent(DataTableEvent.WorkflowActionEnter, { detail: Object.freeze({ ...action }) })
      );
      return true;
    }
    return false;
  }

  workflowActionExit(source: DataTableWorkflowActionSource | undefined, options?: WorkflowActionsExitOptions): boolean {
    if (this.#workflowActive && _notNil(source) && _notNil(this.#actionSourceMap) && this.sourceInWorkflow(source)) {
      const action = this.#actionSourceMap.get(source) as DataTableWorkflowAction;
      switch (action.type) {
        case 'OpenSearch': {
          const column = this.dataTableService.toIndexColumn(action.destination);
          const row = options?.targetRowIndex ?? 0;
          if (_notNil(this.dataTableService.rowByIndex(row)) && _notNil(column)) {
            this.dataTableService.selectCell({ column, row }, { scrollTo: true });
            this.eventEmitter.dispatchEvent(
              new CustomEvent(DataTableEvent.WorkflowActionExit, { detail: Object.freeze({ ...action }) })
            );
            return true;
          }
          break;
        }
        case 'SelectColumn': {
          const row = this.dataTableService.selection?.topLeft.row;
          const column = this.dataTableService.toIndexColumn(action.destination);
          if (_notNil(row) && _notNil(column)) {
            this.dataTableService.selectCell({ column, row }, { scrollTo: true });
            this.eventEmitter.dispatchEvent(
              new CustomEvent(DataTableEvent.WorkflowActionExit, { detail: Object.freeze({ ...action }) })
            );
            return true;
          }
          break;
        }
        case 'End': {
          this.eventEmitter.dispatchEvent(
            new CustomEvent(DataTableEvent.WorkflowActionExit, { detail: Object.freeze({ ...action }) })
          );
          return this.workflowActionEnter(this.#rootActionSource);
        }
      }
    }
    return false;
  }

  workflowLoaded(): boolean {
    return (this.#actionSourceMap?.size ?? 0) > 0;
  }

  sourceInWorkflow(source: DataTableWorkflowActionSource): boolean {
    return this.#actionSourceMap?.has(source) ?? false;
  }
}
