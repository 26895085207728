import Button from '@/components/UI/Button';
import type { ReactNode } from 'react';

interface AddButtonProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

const AddButton = ({ children, className, ...rest }: AddButtonProps) => {
  return (
    <Button className={`relative br-pill ${className} ph3`} icon="add_new" paleBlue testId="AddButton" {...rest}>
      <span>{children}</span>
    </Button>
  );
};

export default AddButton;
