import { formatNumber } from '@/helpers';
import { _isNil } from '@/littledash';
import type { FC } from 'react';

export const FractionCell: FC<{ value: number | null; total: number }> = ({ value, total }) => {
  const formattedTotal = formatNumber(total);
  if (_isNil(value) || !Number.isFinite(value)) {
    return formattedTotal ?? '-';
  } else {
    const formattedValue = formatNumber(value);
    if (Number.isNaN(formattedValue) || Number.isNaN(formattedTotal)) {
      return '-';
    }
    return (
      <>
        <span>
          {formattedValue} / {formattedTotal}
        </span>
      </>
    );
  }
};
