import { _noop } from '@/littledash.ts';
import { ISODate, ISODateTime } from '@/model/Common.model.ts';
import { TaskApiId, TaskType } from '@/model/Task.model.ts';
import { createContext, Dispatch, Reducer, useContext } from 'react';

export interface ScheduleContextState {
  selectedTasks: Set<TaskApiId>;
  selectedTaskTypes: Set<TaskType>;
  selectedDate: ISODate | null;
  search: { text: string | undefined; start: ISODateTime | undefined };
}

export const initialState = (): ScheduleContextState => ({
  selectedTaskTypes: new Set<TaskType>(),
  selectedTasks: new Set<TaskApiId>(),
  selectedDate: null,
  search: { text: undefined, start: undefined },
});
export type ScheduleContextActions =
  | { type: 'toggle-task'; data: { id: TaskApiId; type: TaskType; date: ISODate } }
  | { type: 'update-search'; data: ScheduleContextState['search'] };
export const scheduleContextReducer: Reducer<ScheduleContextState, ScheduleContextActions> = (prevState, action) => {
  switch (action.type) {
    case 'toggle-task': {
      let { selectedDate } = prevState;
      if (prevState.selectedTasks.has(action.data.id)) {
        prevState.selectedTasks.delete(action.data.id);
        prevState.selectedTaskTypes.delete(action.data.type);
        selectedDate = prevState.selectedTasks.size === 0 ? null : selectedDate;
      } else {
        prevState.selectedTasks.add(action.data.id);
        prevState.selectedTaskTypes.add(action.data.type);
        selectedDate = action.data.date;
      }
      return { ...prevState, selectedDate };
    }
    case 'update-search': {
      if (prevState.search.text !== action.data.text || prevState.search.start !== action.data.start) {
        return { ...prevState, search: { ...action.data } };
      }
      return prevState;
    }
    default:
      return prevState;
  }
};
const ScheduleContext = createContext<{
  state: ScheduleContextState;
  dispatch: Dispatch<ScheduleContextActions>;
}>({ state: initialState(), dispatch: _noop });
export const ScheduleContextProvider = ScheduleContext.Provider;
export const useScheduleContext = () => useContext(ScheduleContext);
