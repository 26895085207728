// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import Table from '@/components/UI/Table';
import { formatNumber } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import { useEffect, useState } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import BoxAndWhiskersGraph from './BoxAndWhiskersGraph';
import { average, getCriteriaValue, mean } from './Exclusion.utils';

const Exclusion = ({ state, dispatch }) => {
  const {
    treatmentGroups,
    exclusionCriteria,
    randomizeByDate: {
      validAnimals: { includedAnimals },
      measurementsOnDate,
      enabledCalculations,
    },
  } = state;
  const {
    subjects,
    includedSubjects,
    autoExcluded,
    criteriaIndex,
    criteriaOptions,
    averageActiveCriteria,
    changes,
    autoMedian,
    targetMean,
  } = exclusionCriteria;

  const { excludedByGroup, excludedByMeasurement, excludedByStatus } = autoExcluded;
  const [error, setError] = useState();
  const { totalSpace } = treatmentGroups;
  const [traceData, setTraceData] = useState([]);
  const [localTargetMean, setLocalTargetMean] = useState(targetMean);
  const [showAnimals, setShowAnimals] = useState(false);

  const weightCriteria = {
    name: 'Weight',
    value: 'weight',
  };

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  useEffect(() => {
    const criteriaOptions = enabledCalculations.map(({ name, id, unit }) => ({
      name,
      value: id,
      unit,
    }));

    dispatch({
      type: 'update',
      id: 'exclusionCriteria',
      data: {
        criteriaOptions,
      },
    });
  }, [enabledCalculations]);

  const update = (update) => {
    dispatch({
      type: 'update',
      id: 'exclusionCriteria',
      data: {
        ...update,
      },
    });
  };
  const toggleAutoMedian = () => {
    dispatch({
      type: 'update',
      id: 'exclusionCriteria',
      data: {
        autoMedian: !autoMedian,
      },
    });
  };
  const onChangeTargetMean = (event) => {
    setLocalTargetMean(event.target.value);
  };
  const onSetTargetMean = (overwrite) => {
    if (overwrite) {
      dispatch({
        type: 'update',
        id: 'exclusionCriteria',
        data: {
          targetMean: '',
          autoMedian: true,
        },
      });
      setLocalTargetMean('');
    } else {
      const updateValue = Number(localTargetMean);
      if (isNaN(updateValue)) {
        return;
      }
      dispatch({
        type: 'update',
        id: 'exclusionCriteria',
        data: {
          targetMean: localTargetMean,
          autoMedian: false,
        },
      });
    }
  };
  const updateSubjects = (updatedSubjects, changes) => {
    const count = updatedSubjects.reduce((total, subject) => {
      return subject.selected ? total + 1 : total;
    }, 0);
    const subjectsExcluded = [];
    const subjectsAfterExclusion = updatedSubjects.reduce((accumulator, subject) => {
      if (subject.selected) {
        accumulator.push(subject);
      } else {
        subjectsExcluded.push(subject);
      }
      return accumulator;
    }, []);

    dispatch({
      type: 'update',
      id: 'exclusionCriteria',
      data: {
        subjects: updatedSubjects,
        subjectsAfterExclusion,
        subjectsExcluded,
        includedSubjects: count,
        changes,
      },
    });
  };

  const toggleSelection = (index) => {
    const updatedSubjects = [...subjects];
    const subject = updatedSubjects[index];
    subject.selected = subject.selected === false;
    updateSubjects(updatedSubjects, true);
  };
  const toggleAll = () => {
    const criteria = criteriaOptions[criteriaIndex];
    const reducedSubjects = subjects.reduce((accumulator, subject) => {
      const value = getCriteriaValue(subject, criteria, measurementsOnDate);
      if (!isNaN(value)) {
        accumulator.push({
          ...subject,
          selected: subjects.length !== includedSubjects,
        });
      }
      return accumulator;
    }, []);
    updateSubjects(reducedSubjects, true);
  };

  const reset = () => {
    applyExclusionCriteria();
  };

  const variableColumns = enabledCalculations.map((c) => {
    return {
      id: c.id,
      accessor: (animal) => getCriteriaValue(animal, { ...c, value: c.id }, measurementsOnDate),
      Header: c.name,
      Cell: ({ row: { original } }) => {
        const value = getCriteriaValue(original, { ...c, value: c.id }, measurementsOnDate);
        if (!isNaN(value)) {
          return formatNumber(value);
        }
        return '—';
      },
    };
  });

  const columns = [
    {
      id: 'select',
      accessor: 'select',
      width: 50,
      Cell: ({ row: { original, index } }) => {
        return (
          <Checkbox
            className="pointer"
            type="checkbox"
            name={`select-${original.id}`}
            checked={original.selected !== false}
            onChange={() => {
              toggleSelection(index);
            }}
          />
        );
      },
      Header: () => (
        <Checkbox
          className="pointer"
          type="checkbox"
          name={'selectAll'}
          checked={subjects.length === includedSubjects}
          onChange={toggleAll}
        />
      ),
      filterable: false,
      sortable: false,
    },
    {
      id: 'number',
      Header: 'No.',
      accessor: 'number',
    },
    {
      id: 'id',
      Header: 'ID',
      accessor: 'name',
      filterMethod: (filter, row) => {
        return row[filter.id].includes(filter.value);
      },
    },
    ...variableColumns,
    {
      id: 'cage',
      Header: 'Cage',
      Cell: ({ row: { original } }) => {
        const isEuthanised = original.terminated_at || false;
        return <>{isEuthanised ? 'Deceased' : (original.cage?.name ?? '')}</>;
      },
    },
    {
      id: 'sex',
      Header: 'Sex',
      accessor: 'sex',
      Cell: ({ row: { original } }) => original?.sex?.toUpperCase?.() ?? '—',
    },
  ];

  const changeCriteria = (event) => {
    reset();
    dispatch({
      type: 'update',
      id: 'exclusionCriteria',
      data: {
        subjects: includedAnimals,
        criteriaIndex: parseInt(event.target.value),
      },
    });
  };

  const applyExclusionCriteria = () => {
    const criteria = criteriaOptions[criteriaIndex];
    let excludedByMeasurement = 0;
    let excludedByGroup = 0;
    let excludedByStatus = 0;

    setError(undefined);

    let reducedSubjects = includedAnimals.reduce((accumulator, subject) => {
      const value = getCriteriaValue(subject, criteria, measurementsOnDate);
      if (!isNaN(value)) {
        accumulator.push({ ...subject, selected: true });
      } else {
        accumulator.push({ ...subject, selected: false });
        excludedByMeasurement++;
      }
      return accumulator;
    }, []);

    if (_isEmpty(reducedSubjects) && _notNil(criteria)) {
      setError('Unable to apply exclusion criteria, no subjects with metrics');
      return;
    }

    // TODO merge these into one reduce
    reducedSubjects = reducedSubjects.reduce((accumulator, subject) => {
      if (_isEmpty(subject.study_group)) {
        accumulator.push({ ...subject });
      } else {
        accumulator.push({ ...subject, selected: false });
        excludedByGroup++;
      }
      return accumulator;
    }, []);

    reducedSubjects = reducedSubjects.reduce((accumulator, subject) => {
      if (subject.terminated_at == null) {
        accumulator.push({ ...subject });
      } else {
        accumulator.push({ ...subject, selected: false });
        excludedByStatus++;
      }
      return accumulator;
    }, []);

    const sortedSubjects = reducedSubjects.sort(
      (a, b) => getCriteriaValue(a, criteria, measurementsOnDate) - getCriteriaValue(b, criteria, measurementsOnDate)
    );

    update({
      autoExcluded: {
        excludedByMeasurement,
        excludedByGroup,
        excludedByStatus,
      },
    });

    let firstIndex = 0;

    let selectedLength = sortedSubjects.length;
    let lastIndex = selectedLength - 1;

    while (selectedLength > totalSpace) {
      let medianValue;
      if (targetMean && !autoMedian) {
        // Number() to allow exponential numbers
        medianValue = Number(targetMean);
      } else {
        medianValue = mean(sortedSubjects, criteria, measurementsOnDate);
      }

      // TODO: should we calculate the median on each update? discarding unchecked subjects
      const first = getCriteriaValue(sortedSubjects[firstIndex], criteria, measurementsOnDate);
      const last = getCriteriaValue(sortedSubjects[lastIndex], criteria, measurementsOnDate);
      const top = Math.abs(medianValue - last) > Math.abs(medianValue - first);
      if (top) {
        sortedSubjects[lastIndex].selected = false;
        lastIndex--;
      } else {
        sortedSubjects[firstIndex].selected = false;
        firstIndex++;
      }
      selectedLength--;
    }
    updateSubjects(sortedSubjects, false);
  };
  useEffect(() => {
    if (!changes && _isNotEmpty(criteriaOptions)) {
      applyExclusionCriteria();
    }
  }, [autoMedian, targetMean]);

  useEffect(() => {
    onSetTargetMean(true);
    if (!changes && _isNotEmpty(criteriaOptions)) {
      applyExclusionCriteria();
    }
  }, [criteriaIndex, criteriaOptions]);

  // draw graphs
  useEffect(() => {
    const criteria = criteriaOptions[criteriaIndex];

    const preExclusionValues = subjects.reduce((accumulator, subject) => {
      const value = getCriteriaValue(subject, criteria, measurementsOnDate);
      if (!isNaN(value)) {
        accumulator.push(getCriteriaValue(subject, criteria, measurementsOnDate));
      }

      return accumulator;
    }, []);

    const postExclusion = subjects.reduce((accumulator, subject) => {
      if (subject.selected !== false) {
        accumulator.push(subject);
      }
      return accumulator;
    }, []);

    const postExclusionValues = postExclusion.reduce((accumulator, subject) => {
      const value = getCriteriaValue(subject, criteria, measurementsOnDate);
      if (!isNaN(value)) {
        accumulator.push(getCriteriaValue(subject, criteria, measurementsOnDate));
      }

      return accumulator;
    }, []);

    const averageActiveCriteria = average(postExclusion, criteria, measurementsOnDate);
    const averageWeight = average(postExclusion, weightCriteria, measurementsOnDate);

    update({
      averageWeight,
      averageActiveCriteria,
    });

    const preExclusionTrace = {
      name: 'Pre exclusion',
      x: preExclusionValues,
      type: 'box',
      boxpoints: 'all',
      jitter: 0.3,
      boxmean: 'sd',
      pointpos: 0,
      hoverlabel: {
        bgcolor: 'gray',
        font: { color: 'white' },
      },
      line: {
        color: 'rgba(169,169,169,1)',
        width: 2,
      },
      fillcolor: 'rgba(169,169,169,0.2)',
    };
    const postExclusionTrace = {
      name: 'Post exclusion',
      x: postExclusionValues,
      type: 'box',
      boxpoints: 'all',
      jitter: 0.3,
      boxmean: 'sd',
      pointpos: 0,
      line: {
        color: 'rgba(0,68,158,1)',
        width: 2,
      },
      fillcolor: 'rgba(196,205,213,0.4)',
    };
    setTraceData([postExclusionTrace, preExclusionTrace]);
  }, [subjects]);

  const showExcludedByMeasurement = excludedByMeasurement > 0;
  const showExcludedByGroup = excludedByGroup > 0;
  const showExcludedByStatus = excludedByStatus > 0;
  const showExclusionNotification = showExcludedByMeasurement || showExcludedByGroup || showExcludedByStatus;

  const averageCriteria = () => {
    if (_isNotEmpty(criteriaOptions)) {
      const { name, unit } = criteriaOptions[criteriaIndex];
      if (_notNil(unit)) {
        return `Avg. ${name} (${unit})`;
      }
      return `Avg. ${name}`;
    }
  };

  return (
    <StickyContainer className="ui-card mt3">
      <div className="ph4 pt3">
        <div className="pv3">
          <h2 className="f4 normal lh-title">Exclusion</h2>
          <p className="lh-copy">
            Select the metric you would like to exclude by.{' '}
            <a
              className="link blue underline-hover"
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.benchling.com/hc/en-us/articles/20129907280013-Randomizing-to-Study-Groups"
            >
              How does this work?
            </a>
          </p>
        </div>
        <div className="pb2">
          {showExclusionNotification && (
            <Banner info className="mw6 mr4 mb4">
              {showExcludedByMeasurement && (
                <h3 className="f5 lh-copy basier-reg">{`${excludedByMeasurement} without measurements automatically excluded.`}</h3>
              )}
              {showExcludedByGroup && (
                <h3 className="f5 lh-copy basier-reg">{`${excludedByGroup} with study groups automatically excluded.`}</h3>
              )}
              {showExcludedByStatus && (
                <h3 className="f5 lh-copy basier-reg">{`${excludedByStatus} deceased automatically excluded.`}</h3>
              )}
            </Banner>
          )}

          {changes && (
            <Banner info className="mw6 mr4 mb4" dismiss={false}>
              <h3 className="f5 lh-copy">You have made changes to the animal selection.</h3>
              <p className="lh-copy pb3">If you want to use automatic mean or target mean, undo your changes</p>
              <Button outline info disabled={!changes} onClick={reset}>
                Undo changes
              </Button>
            </Banner>
          )}

          {error && (
            <Banner warning className="mw6 mr4 mb4" dismiss={false}>
              <h3 className="f5 lh-copy">{error}</h3>
            </Banner>
          )}
        </div>

        <div className="flex flex-wrap pb3">
          <div className="pr4">
            <p className="f6 lh-copy pb2">Exclude by:</p>
            <select
              name="criteria"
              style={{ width: '180px' }}
              value={criteriaIndex}
              onChange={changeCriteria}
              disabled={changes}
            >
              {criteriaOptions.map((criteria, index) => (
                <option key={index} value={index}>
                  {criteria.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="flex items-center">
              <div>
                <p className="f6 lh-copy pb2">Target mean</p>
                <div className="relative">
                  <input
                    disabled={autoMedian || changes}
                    type="text"
                    name="value"
                    value={localTargetMean}
                    onChange={onChangeTargetMean}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        onSetTargetMean();
                      }
                    }}
                  />
                  <span
                    style={{ position: 'absolute', right: '0' }}
                    onClick={() => onSetTargetMean()}
                    className={`pa2 f6 lh-copy ${targetMean !== localTargetMean ? 'blue pointer' : 'moon-gray'}`}
                  >
                    set
                  </span>
                </div>
              </div>
              <div className="ml4">
                <Checkbox
                  className="pointer mt2"
                  type="checkbox"
                  checked={autoMedian}
                  label="Auto mean"
                  disabled={changes}
                  onChange={toggleAutoMedian}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '400px' }} className="pb4 bt bb b--moon-gray">
        <BoxAndWhiskersGraph data={traceData} />
      </div>
      <Sticky relative={true}>
        {({ style }) => (
          <div style={style} className="flex justify-between items-center pa4 z-max bg-white">
            <div className="flex flex-grow-1 flex-wrap pr4">
              <div className="mr4">
                <p className="f6 lh-copy pb2">{averageCriteria()}</p>
                <span className="f3 lh-title near-black">
                  {averageActiveCriteria ? formatNumber(averageActiveCriteria) : '—'}
                </span>
              </div>
              <div className="mr4">
                <p className="f6 lh-copy pb2">Included</p>
                <span className="f3 lh-title near-black">{includedSubjects}</span>
              </div>
              <div className="mr4">
                <p className="f6 lh-copy pb2">Space available</p>
                <span className={`f3 lh-title near-black ${totalSpace - includedSubjects < 0 ? 'red' : ''}`}>
                  {totalSpace - includedSubjects}
                </span>
              </div>
              <div className="mr4">
                <p className="f6 lh-copy pb2">Excluded</p>
                <span className="f3 lh-title near-black">{includedAnimals.length - includedSubjects}</span>
              </div>
            </div>
            <div>
              <Button plain onClick={() => setShowAnimals(!showAnimals)}>
                {showAnimals ? 'Hide' : 'Show'} Animals
              </Button>
            </div>
          </div>
        )}
      </Sticky>

      <div
        className="ui__table ui__select-table"
        style={{
          display: showAnimals ? 'block' : 'none',
        }}
      >
        <Table
          defaultPageSize={subjects.length}
          resizable={false}
          showPagination={false}
          minRows={0}
          data={subjects}
          defaultSorted={[
            {
              id: criteriaOptions[criteriaIndex]?.value,
              desc: true,
            },
          ]}
          getTrProps={(s, r) => {
            const row = typeof r !== 'undefined';
            if (row) {
              return {
                style: {
                  backgroundColor: r.original.selected !== false ? '#F8FCFF' : '',
                },
              };
            }

            return true;
          }}
          columns={columns}
        />
      </div>
    </StickyContainer>
  );
};

export default Exclusion;
