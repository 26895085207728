// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { preventNumberScroll } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import { ErrorMessage } from '@hookform/error-message';
import { Fragment, useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFieldArray, useFormContext } from 'react-hook-form';
import CageRow from './CageRow';
import { validationSchema } from './Cages.utils';
import { useSelector } from 'react-redux';

const Cages = ({ state, dispatch }) => {
  const { register, errors, getValues, setValue, clearErrors, reset, control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'collections',
  });
  const { features } = useSelector(({ team: { features } }) => ({
    features,
  }));

  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  const generateCages = (num) => {
    const result = [];
    for (let index = 0; index < num; index++) {
      result[index] = {
        name: `Cage ${index + 1}`,
        dob: DateUtils.dateNow(),
        sex: 'm',
        subjects: 5,
      };
    }

    return result;
  };

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  const handleGenerate = () => {
    const totalCages = getValues('totalCages');
    const collections = generateCages(totalCages);
    reset({ collections });
    clearErrors('collections');
    setValue('collections', collections);
    dispatch({
      type: 'updateForm',
      data: { totalCages },
    });
    dispatch({
      type: 'updateStudy',
      data: { collections },
    });
  };

  if (atunesIntegrationEnabled) {
    return <ATunesIntegrationEnabled />;
  }
  return (
    <Fragment>
      <div className="mb4">
        <h3 className="fw5 f5 pb2">Cages and animals</h3>
        <p>Skip this step if you are adding cages from Colony.</p>
      </div>
      <div className="mw7 ui-card" data-testid="cages-and-animals__container">
        <div className="pa4 bb b--moon-gray">
          <label>How many cages are there?</label>
          <p className="f6 mb2">Up to a maximum of 100</p>
          <div className="flex">
            <input
              name="totalCages"
              type="number"
              onWheel={preventNumberScroll}
              style={{ marginBottom: 0, width: '150px' }}
              className={`${errors.totalCages ? 'input__error' : ''} mr2`}
              defaultValue={state.form.totalCages}
              data-testid="generate-cage-count-input"
              ref={register({
                ...validationSchema.totalCages,
              })}
            />
            <Button plain disabled={errors.totalCages} onClick={handleGenerate} testId="generate-cages-button">
              Generate
            </Button>
          </div>
          <ErrorMessage
            errors={errors}
            name="totalCages"
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>
        <div>
          <div className="pa4">
            <div className="flex justify-between flex-wrap items-bottom">
              <div>
                <label className={`${errors.cages ? 'red' : ''}`}>Cage contents</label>
                <p className="f6 measure-wide">Each cage can hold up to a maximum of 10 animals</p>
              </div>
            </div>
          </div>
          <div className="bt b--moon-gray">
            {!_isEmpty(state.study.collections) ? (
              <div>
                {fields.map((field, index) => (
                  <CageRow key={index} field={field} index={index} />
                ))}
              </div>
            ) : (
              <div style={{ height: 400 }} className="pa4">
                <div className="flex flex-wrap justify-center items-center br2 bg-near-white h-100 w-100 tc">
                  <div>
                    <h3 className="normal lh-title f4 near-black">Generate cages to get started</h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const ATunesIntegrationEnabled = () => (
  <Fragment>
    <div className="mb4">
      <h3 className="fw5 f5 pb2">Cages and animals</h3>
      <p>Animals are managed through tick@lab</p>
    </div>
    <div className="mw7 ui-card">
      <div className="pa4 bb b--moon-gray">
        <a
          href="https://help.benchling.com/hc/en-us/articles/21952582878093-Registering-Animals-in-the-Vivarium"
          target="_blank"
          rel="noopener noreferrer"
          className="dib link blue"
        >
          Click here
        </a>
        <span className="ml2">to open tick@lab in a new tab.</span>
      </div>
    </div>
  </Fragment>
);

export default Cages;
