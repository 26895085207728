// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Icon from '@/components/UI/Icon';
import { web as webRoute } from '@/support/route';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

const ImportProgress = ({ study, complete, progress }) => {
  return (
    <div>
      {complete ? (
        <CompleteMessage study={study} />
      ) : (
        <React.Fragment>
          <h3 className="lh-title basier-reg normal f5 f4-l pb3 pb4-l">Importing your data...</h3>
          <Progress type="circle" percent={progress.toFixed(0)} status="active" />
        </React.Fragment>
      )}
    </div>
  );
};

const CompleteMessage = ({ study }) => {
  const history = useHistory();
  return (
    <div>
      <Icon icon="upload" viewBox="0 0 51 32" width="40" height="25" className="dark-gray mb4" />
      <h3 className="lh-title basier-reg normal f5 f4-l pb3 pb4-l">Data has been imported successfully!</h3>
      <Button url={webRoute('studies.animals', { id: study.id })} className="mr3">
        View study
      </Button>
      <Button onClick={() => history.go(0)} plain>
        Upload another
      </Button>
    </div>
  );
};

export default ImportProgress;
