import Checkbox from '@/components/UI/FormElements/Checkbox/Checkbox';
import { _isNotBlank, _isNotEmpty } from '@/littledash';
import { useVirtualizer } from '@tanstack/react-virtual';
import _debounce from 'lodash/debounce';
import { type ChangeEvent, CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import type { SelectMenuProps } from './SelectMenu.model';
import styles from './SelectMenu.module.scss';

const SEARCH_DEBOUNCE_WAIT = 500;

export const SelectMenu = ({
  items,
  preSelectedValues,
  onSearch,
  onSelect,
  onScrollBottom,
  renderItem,
  searchPlaceholderText = 'Search',
  disabled = false,
  scrollContainerHeight = 250,
  enableSelectAll = true,
}: SelectMenuProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState('');
  const [selected, setSelected] = useState<string[]>(preSelectedValues ?? []);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemVirtualizer = useVirtualizer({
    count: (items ?? []).length,
    getScrollElement: () => containerRef?.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  useEffect(() => {
    if (_isNotEmpty(preSelectedValues)) {
      const updatedSelected = new Set([...preSelectedValues, ...selected]);
      setSelected(Array.from(updatedSelected));
    }
  }, [preSelectedValues]);

  const debounceSearchInput = useCallback(_debounce(onSearch, SEARCH_DEBOUNCE_WAIT), []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let updateSelected = new Set(selected);
    if (updateSelected.has(event.target.value)) {
      updateSelected.delete(event.target.value);
    } else {
      updateSelected = updateSelected.add(event.target.value);
    }
    setSelected(Array.from(updateSelected));
    onSelect(Array.from(updateSelected));
  };

  const handleSelectAll = (): void => {
    const updateSelected = Array.from(new Set(items.map((item) => item.value)));
    setSelected(updateSelected);
    onSelect(updateSelected);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const query = event.target.value;
    setSearchInput(query);
    debounceSearchInput(query);
  };

  const handleClearSelection = (): void => {
    setSelected([]);
    onSelect([]);
  };

  const handleResetInput = (): void => {
    setSearchInput('');
    onSearch('');
  };

  const handleScrollBottom = (e: React.UIEvent<HTMLDivElement>): void => {
    if (e.target && onScrollBottom) {
      const { target } = e;
      if (!(target instanceof HTMLDivElement)) {
        return;
      }
      const { scrollTop, clientHeight, scrollHeight } = target;
      const atBottom = scrollTop + clientHeight >= scrollHeight;
      if (atBottom) {
        onScrollBottom(items);
      }
    }
  };

  const contentStyles: CSSProperties = useMemo(
    () => ({
      height: `${itemVirtualizer.getTotalSize()}px`,
      width: '100%',
      position: 'relative',
    }),
    [itemVirtualizer]
  );

  const itemStyles = ({ size, start }: { size: number; start: number }): CSSProperties => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: `${size}px`,
    transform: `translateY(${start}px)`,
  });

  return (
    <div
      className={`${styles['search-filter']} ${disabled ? 'ui__disabled' : ''}`}
      data-testid="select-menu"
      data-test-component="SelectMenu"
      data-test-element="container"
    >
      <div className="flex flex-column h-100">
        <div className="pa3 pb2 bb b--moon-gray">
          <div className="relative mb2 w-100">
            <RiSearchLine className={styles['search-icon']} size={16} />
            <input
              type="text"
              className={styles['search-bar']}
              placeholder={searchPlaceholderText}
              onChange={handleSearch}
              value={searchInput}
              data-testid="search-bar"
              data-test-component="SelectMenu"
              data-test-element="search-input"
            />
            {_isNotBlank(searchInput) && (
              <span
                onClick={handleResetInput}
                className={`${styles['reset-search']} pointer blue f6`}
                data-testid="reset-search"
              >
                Reset
              </span>
            )}
          </div>
          <div className="flex justify-between items-center f7 lh-title pv1">
            {enableSelectAll && selected.length < 1 && (
              <span className="blue pointer" onClick={handleSelectAll} data-testid="select-all">
                Select all
              </span>
            )}
            {selected.length > 0 && (
              <span className="blue pointer" onClick={handleClearSelection} data-testid="clear-selection">
                Clear selection
              </span>
            )}
            {selected.length > 0 && <span className="near-black">{selected.length} Selected</span>}
          </div>
        </div>
        <div
          className={`${styles['scroll-container']} w-100 overflow-auto`}
          style={{ height: scrollContainerHeight }}
          ref={containerRef}
          onScroll={handleScrollBottom}
          data-testid="scroll-container"
        >
          <div className="mt3 mark-test" style={contentStyles}>
            {_isNotEmpty(itemVirtualizer.getVirtualItems()) ? (
              itemVirtualizer.getVirtualItems().map(({ index, key, start, size }) => {
                const item = items[index];
                const isSelected = new Set(selected).has(items[index].value);
                return (
                  <div
                    className="ph3"
                    key={key}
                    style={itemStyles({ start, size })}
                    data-test-component="SelectMenu"
                    data-test-element="menu-item"
                    data-test-key={item.value as string}
                  >
                    {renderItem ? (
                      <>
                        {renderItem({
                          ...item,
                          isSelected,
                          onChange: handleChange,
                        })}
                      </>
                    ) : (
                      <Checkbox
                        label={item.name}
                        checked={isSelected}
                        onChange={handleChange}
                        value={item.value}
                        data-testid={`item-${index}`}
                      />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="ma3 br2 bg-near-white pa3 tc" data-testid="no-data-text">
                <h3 className="lh-title f6 fw4">No options to select from</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
