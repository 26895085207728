import criticalSrc from '@/assets/images/critical-modal.svg';
import warningSrc from '@/assets/images/warning-modal.svg';
import { compare, operators } from '@/components/Studies/Create/Steps/Alerts/Alerts.utils';
import { capitalise, preventNumberScroll } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalAlertSpec, AnimalAlertSpecCreateOrUpdate } from '@/model/Alert.model';
import type { ID } from '@/model/Common.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import React, { ChangeEventHandler, FocusEventHandler, useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Control, Controller, ControllerRenderProps, useController, useForm, ValidateResult } from 'react-hook-form';

const alertTypeFromCondition = (condition?: string): string | null => {
  switch (condition) {
    case 'GREATER_THAN':
    case 'LESS_THAN':
      return 'FIXED';
    case 'INCREASES_BY':
    case 'DECREASES_BY':
      return 'PERCENT';
    default:
      return null;
  }
};

const alertTypes = [
  { name: 'warning', type: 'warn', description: 'Temporary banner' },
  {
    name: 'critical',
    type: 'critical',
    description: 'Overlay requiring attention',
  },
];

interface InputWithReadOnlyUnitProps {
  value?: number;
  onChange?: (value: number | null) => void;
  onBlur?: FocusEventHandler<unknown>;
  disabled?: boolean;
  hasError?: boolean;
  step?: number;
  unit?: string;
}

const InputWithReadOnlyUnit: React.FC<InputWithReadOnlyUnitProps> = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  hasError = false,
  step = 0.1,
  unit = 'g',
}) => (
  <div className="flex relative" data-testid="input-with-readonly-unit">
    <input
      type="number"
      onWheel={preventNumberScroll}
      value={value}
      onChange={(event) => onChange?.(_isEmpty(event?.target?.value) ? null : Number(event.target.value))}
      onBlur={onBlur}
      disabled={disabled}
      style={{ paddingRight: '4.5rem' }}
      step={step}
      className={hasError ? 'input__error' : ''}
    />
    <span className="ib w3 absolute right-1 dark-gray tr" style={{ top: '0.7rem' }}>
      <small>{unit}</small>
    </span>
  </div>
);

interface AlertWhenProps {
  control: Control;
}

const AlertWhen: React.FC<AlertWhenProps> = ({ control }) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    control,
    name: 'retrigger',
    rules: { required: false },
    defaultValue: false,
  });
  return (
    <>
      <label>When</label>
      <select onChange={(event) => onChange(event.target.value === 'true')} onBlur={onBlur} value={`${value}`}>
        <option value="false">As soon as</option>
        <option value="true">Every time</option>
      </select>
    </>
  );
};

interface AlertAmountProps {
  control: Control;
  unit: string;
}

const alertAmountValidator = (value?: number): ValidateResult => {
  if (!Number.isInteger(value)) {
    return 'Enter a valid whole number';
  }

  return true;
};

const AlertAmount: React.FC<AlertAmountProps> = ({ control, unit }) => {
  return (
    <>
      <label>Amount</label>
      <Controller
        name="value"
        control={control}
        rules={{ required: true, valueAsNumber: true, validate: alertAmountValidator }}
        as={<InputWithReadOnlyUnit unit={unit} hasError={_notNil(control?.formState?.errors?.value)} />}
      />
    </>
  );
};

const AlertCondition: React.FC<{ control: Control }> = ({ control }) => {
  const {
    field: { onChange: onTypeChange },
  } = useController({
    control,
    name: 'type',
    rules: { required: true },
  });

  return (
    <>
      <label>Condition</label>
      <Controller
        name="operator"
        control={control}
        render={({ onChange, onBlur, value }: ControllerRenderProps) => {
          const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
            onChange(event?.target?.value);
            onTypeChange(alertTypeFromCondition(event?.target?.value));
          };
          return (
            <select onChange={handleChange} onBlur={onBlur} value={value} data-testid="alert-condition-select">
              {operators.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          );
        }}
      />
    </>
  );
};

interface AlertComparedToProps {
  control: Control;
}

const AlertComparedTo: React.FC<AlertComparedToProps> = ({ control }) => {
  const {
    field: { value: fieldValue, onChange, onBlur },
  } = useController({ control, name: 'from', defaultValue: 'FIRST', rules: { required: true } });

  return (
    <div data-testid="alert-compared-to">
      <label className="mb2">Compared to the:</label>
      {compare.map(({ label, value }, i) => (
        <div className="flex alert_radio">
          <input
            id={label}
            type="radio"
            name="from"
            value={value}
            checked={value === fieldValue}
            className="pointer"
            onChange={onChange}
            onBlur={onBlur}
            data-testid={`alert-compared-to-${value}`}
          />
          <label className="alert_radio_label dib relative pl2 lh-copy mb0 pointer" htmlFor={label}>
            {`${label === 'first' ? 'First measurement recorded' : 'Most recent measurement'}`}
          </label>
        </div>
      ))}
    </div>
  );
};

interface AddAlertFormProps {
  calculations: Array<PresetCalculation>;
  alert?: AnimalAlertSpec;
  onSubmit: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
}

export const AddAlertForm: React.FC<AddAlertFormProps> = ({
  calculations,
  alert,
  onSubmit: handleAlertSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm<AnimalAlertSpecCreateOrUpdate>({
    mode: 'onChange',
    defaultValues: {
      id: alert?.id,
      calculation: alert?.calculation ?? calculations?.[0]?.id ?? null,
      operator: alert?.operator ?? operators[0]?.value ?? null,
      value: alert?.value ?? 0,
      type: alert?.type ?? 'FIXED',
      from: _isNotEmpty(alert?.from) ? alert?.from : undefined,
      notification: alert?.notification ?? 'warn',
      retrigger: alert?.retrigger ?? false,
    },
  });
  const isEdit = _notNil(alert);
  const {
    field: { onChange: onNotificationChange, value: notification },
  } = useController({
    name: 'notification',
    defaultValue: 'warn',
    control,
    rules: { required: true },
  });
  const { calculation, type } = watch(['calculation', 'type']);

  const calculationsWithMeasurements = useMemo(
    () =>
      (calculations ?? []).reduce<Record<ID, PresetCalculation>>((acc, calculation) => {
        acc[calculation.id] = calculation;
        (calculation.measurements ?? []).forEach((measurement) => {
          acc[measurement.id] = measurement as PresetCalculation;
        });
        return acc;
      }, {}),
    [calculations]
  );

  const unit = useMemo(() => {
    switch (type) {
      case 'FIXED': {
        return calculationsWithMeasurements?.calculation?.unit ?? '';
      }
      case 'PERCENT': {
        return '%';
      }
      default: {
        return '';
      }
    }
  }, [calculationsWithMeasurements, calculation, type]);

  return (
    <ModalContainer size="medium" className="bg-white">
      <form className="add-alert-form" onSubmit={handleSubmit(handleAlertSubmit)} data-testid="add-alert-form">
        <input type="hidden" name="id" ref={register()} />
        <ModalHeader
          title={`${isEdit ? 'Edit' : 'Add'} alert`}
          className="pa3 bb b--moon-gray w-100"
          fontClass="normal lh-title f4"
        />
        <div className="ph3 pv4 near-black f6">
          <div className="flex g3">
            <div className="flex-grow-1 w-25">
              <AlertWhen control={control} />
            </div>
            <div className="flex-grow-1 w-25">
              <label>Metric</label>
              <select name="calculation" ref={register({ required: true })} data-testid="alert-metric-select">
                {Object.values(calculationsWithMeasurements)?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-grow-1 w-25">
              <AlertCondition control={control} />
            </div>
            <div className="flex-grow-1 w-25">
              <AlertAmount control={control} unit={unit} />
            </div>
          </div>

          {type === 'PERCENT' && (
            <div className="flex flex-column g1 mt1 lh-solid">
              <AlertComparedTo control={control} />
            </div>
          )}
        </div>

        <div className="ph3 pv4 bt b--moon-gray">
          <label>Alert type</label>
          <div className="flex g4 pt3">
            {alertTypes.map(({ name, type, description }) => (
              <div key={name} className="flex flex-column">
                <div style={{ width: 130 }}>
                  <a
                    style={{ width: 'max-content' }}
                    className={`flex justify-start ba b--solid br2 bw1  ${
                      notification === type ? 'b--dark-blue' : 'b--transparent'
                    }`}
                    onClick={() => onNotificationChange(type)}
                    data-testid={`alert-notification-${type}`}
                  >
                    <img alt={`${name} modal preview`} src={name === 'critical' ? criticalSrc : warningSrc} />
                  </a>
                  <p className="f6 near-black pt2">{capitalise(name)}</p>
                </div>
                <span className="f6">{description}</span>
              </div>
            ))}
          </div>
        </div>
        <ModalActions
          className="pa3 bt b--moon-gray"
          onCancel={onCancel}
          onSubmit={handleSubmit(handleAlertSubmit)}
          submitBtnText="Create"
          cancelBtnText="Cancel"
          submitButtonProps={{
            className: 'ml3',
            disabled: !isValid || isSubmitting,
            tooltip: !isValid ? 'Missing required fields' : undefined,
            loading: isSubmitting,
            loadingText: `${isEdit ? 'Editing' : 'Adding'} Alert`,
          }}
          cancelButtonProps={{
            [`data-testid`]: 'alert-cancel',
          }}
        />
      </form>
    </ModalContainer>
  );
};
