import Lookup from '@/components/UI/Lookup/Lookup';
import { _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import type { StudyFormMetadataItem } from '@/model/StudyForm.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';

export type BenchlingFolder = {
  id: string;
  name: string;
  metaGlossaryId?: ID;
};

export interface FolderOptionType {
  label: string;
  value?: BenchlingFolder;
}

interface BenchlingFolderLookupProps {
  updateStudy: (folder: FolderOptionType, metaGlossaryItem: MetadataFieldWithValue) => void;
  metaGlossary: StudyFormMetadataItem;
}

export const BenchlingFolderLookup: React.FC<BenchlingFolderLookupProps> = ({ updateStudy, metaGlossary }) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const { control } = useFormContext();
  const folderWatch: BenchlingFolder | undefined = useWatch({
    control,
    name: 'benchling_folder',
  });

  const { invoke: getFolders, loading: loadingFolders } = useApiHook({
    endpoint: 'GET /api/v1/folders',
    invokeOnInit: false,
  });

  const loadTokenOptions = async ({ inputValue, token }: { inputValue: string; token: string }) => {
    try {
      setLoading(true);
      setComplete(false);

      const query: Record<string, string> = {};

      if (_isNotEmpty(token)) {
        query.nextToken = token;
      }

      const trimmedQuery = (inputValue ?? '').trim();
      if (_isNotEmpty(trimmedQuery)) {
        query.searchBy = trimmedQuery;
      }

      const response = await getFolders({ query });

      if (response.type === 'success') {
        const options: Array<FolderOptionType> = (response.body.folders ?? []).map(
          (folder) =>
            ({
              label: folder.name,
              value: folder,
            }) as FolderOptionType
        );

        setLoading(false);
        setComplete(true);

        return {
          options,
          nextToken: response.body.nextToken,
        };
      }
    } catch (error) {
      setLoading(false);
      setComplete(true);
    }
    return { options: [], nextToken: null };
  };

  return (
    <Controller
      name="benchling_folder"
      control={control}
      defaultValue={folderWatch}
      rules={{ required: metaGlossary.required ? 'Benchling Folder is required' : '' }}
      render={({ value, onChange }: ControllerRenderProps) => (
        <Lookup
          defaultValue={value}
          loadTokenOptions={loadTokenOptions}
          tokenPagination={true}
          defaultPagination={true}
          handleSelect={(folder: FolderOptionType) => {
            onChange(folder);
            updateStudy(folder, metaGlossary.info);
            return setLoading(false);
          }}
          additional={{ page: 1, perPage: 10 }}
          isLoading={loading || loadingFolders}
          placeholder="Search folder name..."
          noOptionsMessage={({ inputValue = '' }) =>
            !complete || loading ? 'Start searching for folders...' : `No folders match ${inputValue}`
          }
          reduceOptions={(_, nextOptions) => nextOptions}
        />
      )}
    />
  );
};
