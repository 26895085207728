import Button from '@/components/UI/Button';
import { Text } from '@/components/UI/FormFields/Latest';
import { _isNotEmpty } from '@/littledash';
import { useForm, FormProvider, useFormContext } from 'react-hook-form@latest';
import { useDispatch } from 'react-redux';
import { getMetadataFields } from './AddEditLinks.utils';
import { isValidLinkAttribute } from '@/helpers';
import { ErrorMessage } from '@hookform/error-message';
import { FC } from 'react';
import { MetadataColumns } from '@/model/Metadata.model';

interface FormValues {
  name: string;
  url: string;
  alt: string;
}
interface AddEditLinkProps {
  handleSave: (data: FormValues) => void;
  link: { name: string; url: string; alt: string };
  metadata: MetadataColumns[];
}

interface AddEditLinkFormProps extends AddEditLinkProps {
  isEdit: boolean;
}

const AddEditLink: FC<AddEditLinkProps> = ({ handleSave, link, metadata }) => {
  const isEdit = Boolean(link);
  const formMethods = useForm();

  return (
    <>
      <FormProvider {...formMethods}>
        <AddEditLinkForm handleSave={handleSave} isEdit={isEdit} link={link} metadata={metadata} />
      </FormProvider>
    </>
  );
};

const AddEditLinkForm: FC<AddEditLinkFormProps> = ({ handleSave, isEdit, link, metadata }) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext<FormValues>();

  const formContext = useFormContext<FormValues>();
  const register = formContext ? formContext.register : () => ({});
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} className="center mw6 ui-card">
      <div className="pa4">
        <h3 className="normal f4">{isEdit ? 'Edit link' : 'Add a link'}</h3>
      </div>
      <div className="overflow-y-auto" style={{ maxHeight: '500px' }}>
        <div className="pb4 ph4">
          <Text testId="link-name-text-field" value={link?.name} name="name" label="Link name" required />
        </div>
        <div className="pb4 ph4">
          <label htmlFor="url">URL (required)</label>
          <input
            type="text"
            data-testid="url-input"
            data-test-component="Text"
            data-test-element="input"
            style={{ marginBottom: 0 }}
            className={errors.url ? 'input__error' : ''}
            defaultValue={link?.url}
            {...register('url', {
              required: 'This field is required',
              validate: (value) => isValidLinkAttribute(value) || 'Invalid URL',
              maxLength: { value: 1000, message: 'Maximum of 1000' },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="url"
            render={({ message }) => (
              <small data-testid="url-error-message" className="red db pt2">
                {message}
              </small>
            )}
          />
        </div>

        <div className="pb4 ph4">
          <Text value={link?.alt} testId="alt-text-field" name="alt" label="Description" maxLength={1000} />
        </div>
        {_isNotEmpty(metadata) && <div className="pb4 ph4">{getMetadataFields(metadata, link)}</div>}
      </div>
      <div className="bt b--moon-gray bg-white pa3">
        <Button submit className="mr2">
          Save
        </Button>
        <Button plain onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default AddEditLink;
