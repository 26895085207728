import type { RandomizeState } from '../Randomize/Randomize.model';
import { type Dispatch } from 'react';
import type { ModalProps } from '../../Show';
import { Animal } from '@/model/Animal.model';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';

export interface RandomizationDateProps {
  state: RandomizeState;
  dispatch: Dispatch<StepFormAction<RandomizeState>>;
  props: ModalProps;
}

export type RDType = 'rd-latest-measurements' | 'rd-specify-date';
export const enum RDOptions {
  LatestMeasurements = 'rd-latest-measurements',
  SpecifyDate = 'rd-specify-date',
}

export interface ValidateAnimals {
  validationMessages: string[];
  includedAnimals: Animal[];
  excludedAnimals: Animal[];
}
