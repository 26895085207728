import NoDataCard from '@/components/NoDataCard';
import { generateConditionText } from '@/components/Studies/Create/Steps/Alerts/Alerts.utils';
import Table from '@/components/UI/Table';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { getCalculationsAndMeasurements } from '@/helpers';
import { _isEmpty } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import React, { Dispatch, useMemo } from 'react';
import { RiRepeatFill } from 'react-icons/ri';

interface ConditionCellProps {
  calculations: Array<PresetCalculation>;
  alert: AnimalAlertV1;
}

const ConditionCell: React.FC<ConditionCellProps> = ({ calculations, alert }) => {
  const calculation = useMemo(() => calculations?.find(({ id }) => id === alert?.calculation), [calculations, alert]);
  return (
    <span data-testid="alert-condition">
      {generateConditionText({ alert, calculation, displayCalculationName: false })}
    </span>
  );
};

const notificationToTypeString = (notification: AnimalAlertV1['notification']): string => {
  switch (notification) {
    case 'warn':
      return 'Warning';
    case 'critical':
      return 'Critical';
    default:
      return '-';
  }
};

const AlertTypeCell: React.FC<{ alert: AnimalAlertV1 & { retrigger?: boolean } }> = ({ alert }) => {
  return (
    <div className="flex justify-between items-center w-100" data-testid="alert-type-container">
      <span data-testid="alert-type">{notificationToTypeString(alert?.notification)}</span>
      {(alert?.retrigger ?? false) && (
        <span
          data-testid="alert-retrigger-icon"
          data-tooltip-id="global-tooltip-id"
          data-tooltip-content="Alerts every time the condition is met"
        >
          <RiRepeatFill />
        </span>
      )}
    </div>
  );
};
type SelectedRows = Record<number, boolean>;

interface AlertsTableProps {
  alerts: Array<AnimalAlertV1>;
  calculations: Array<PresetCalculation>;
  disabled?: boolean;
  onAlertClick: (alert: AnimalAlertV1) => void;
  onAddAlertClick: () => void;
  selectedRows?: SelectedRows;
  onSelectedRowsChange?: (selectedRows: SelectedRows) => void | Dispatch<SelectedRows>;
}

export const AlertsTable: React.FC<AlertsTableProps> = ({
  alerts,
  calculations,
  disabled = false,
  onAlertClick,
  onAddAlertClick,
  selectedRows,
  onSelectedRowsChange,
}) => {
  const calculationsMap = getCalculationsAndMeasurements(calculations);

  const columns: Columns<AnimalAlertV1> = [
    {
      id: 'metric',
      Header: 'Metric',
      width: 50,
      Cell: ({ row: { original } }) => {
        if (disabled) {
          return <span data-testid="alert-metric">{calculationsMap?.get(original?.calculation)?.name ?? '-'}</span>;
        }
        return (
          <a className="link" onClick={() => onAlertClick?.(original)} data-testid="alert-metric">
            {calculationsMap?.get(original?.calculation)?.name ?? '-'}
          </a>
        );
      },
    },
    {
      id: 'condition',
      Header: 'Condition',
      Cell: ({ row: { original } }) => (
        <ConditionCell alert={original} calculations={Array.from(calculationsMap.values())} />
      ),
    },
    {
      id: 'notification',
      Header: 'Alert Type',
      Cell: ({ row: { original } }) => <AlertTypeCell alert={original} />,
    },
  ];

  const cardClasses = 'w-100 pa3';

  if (_isEmpty(calculations)) {
    return (
      <NoDataCard
        title="This study has no preset configured"
        text="Alerts cannot be configured on studies that do not have a preset"
        dark
        className={cardClasses}
      />
    );
  }

  if (_isEmpty(alerts)) {
    return (
      <NoDataCard
        title="This study has no alerts yet"
        text="A preview of your alerts will appear here"
        onLinkClick={disabled ? undefined : () => onAddAlertClick?.()}
        btnTxt={disabled ? undefined : 'Add an alert'}
        openModal={true}
        dark
        className={cardClasses}
      />
    );
  }

  return (
    <Table
      className="w-100 bt mt3"
      columns={columns}
      data={alerts}
      selectedRows={disabled ? undefined : selectedRows}
      setSelectedRows={disabled ? undefined : onSelectedRowsChange}
    />
  );
};
