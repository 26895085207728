import DatePickerNative from '@/components/UI/DatePickerNative';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import InputWithUnits from '@/components/UI/FormElements/InputWithUnits';
import { RepeatValue } from '@/model/Task.model.ts';
import { DateUtils } from '@/utils/Date.utils';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { StepFormPayloadProps, StepRenderProps } from '../../AddTask.model';
import styles from '../../AddTask.module.scss';
import {
  daysOptions,
  recurringDayValidator,
  repeatPeriodOptions,
  repeatValidator,
  startDateValidator,
} from './AddTaskScheduleForm.utils';

type ReoccurringFieldsProps = Pick<StepRenderProps, 'disabledFields'>;
interface DayChecklistProps {
  selectedDays: string[];
  onChange: (event: string[]) => void;
}

const RecurringFields: FC<ReoccurringFieldsProps> = ({ disabledFields }) => {
  const { control, formState, getValues, clearErrors } = useFormContext<StepFormPayloadProps>();
  const [startValue, endValue, repeat] = useWatch({
    control,
    name: ['schedule.start', 'schedule.end', 'schedule.repeat'],
  });

  const handleScheduleTypeChange = (type: string) => {
    clearErrors();
    return type;
  };

  return (
    <div className="pt3 ml4">
      <div className="mb3">
        <div className="flex items-center">
          <label className="w4 mb0 lh-title">Repeat every</label>
          <Controller
            name="schedule.repeat"
            control={control}
            defaultValue={{ unit: repeatPeriodOptions.days.value } as RepeatValue}
            rules={{ validate: repeatValidator }}
            render={({ field }) => (
              <InputWithUnits
                value={field.value?.value}
                unit={field.value?.unit}
                classes={`${styles['add-task-input-field']} ${styles['add-task-recurring-field']}`}
                unitOptions={Object.values(repeatPeriodOptions)}
                onChange={({ value, unit }) => field.onChange({ ...(getValues('schedule.repeat') ?? {}), value, unit })}
                name="repeat"
                disabled={disabledFields?.has('schedule.repeat') ?? false}
              />
            )}
          />
        </div>
        <ErrorMessage
          errors={formState.errors}
          name="schedule.repeat"
          render={({ message }) => <p className="f6 red db pv2">{message}</p>}
        />
      </div>
      <div className="mb3">
        {repeat?.unit === repeatPeriodOptions.weeks.value && (
          <>
            <div
              className={cn('flex items-top', { ui__disabled: disabledFields?.has(`schedule.repeat.days`) ?? false })}
              data-test-element="repeat-on"
            >
              <label className="w4 mb0 lh-title">Repeat on</label>
              <Controller
                name={`schedule.repeat.days`}
                control={control}
                defaultValue={[]}
                rules={{ validate: recurringDayValidator }}
                render={({ field }) => <DayChecklist onChange={field.onChange} selectedDays={field.value ?? []} />}
              />
            </div>
            <ErrorMessage
              errors={formState.errors}
              name="schedule.repeat.days"
              render={({ message }) => <p className="f6 red db pv2">{message}</p>}
            />
          </>
        )}
      </div>
      <div className="flex mb3" data-test-component="DurationForm" data-test-element="container">
        <div data-test-component="DurationForm" data-test-element="begins">
          <label>Begins</label>
          <Controller
            name="schedule.start"
            control={control}
            defaultValue={DateUtils.dateNow()}
            rules={{ validate: (value?: string) => startDateValidator(value, endValue) }}
            render={({ field }) => (
              <DatePickerNative
                value={field.value ?? ''}
                onChange={(value) => field.onChange(handleScheduleTypeChange(value))}
                className={styles['add-task-input-field']}
              />
            )}
          />
        </div>
        <div className="ml4" data-test-component="DurationForm" data-test-element="ends">
          <label>Ends</label>
          <Controller
            name="schedule.end"
            control={control}
            defaultValue=""
            rules={{ validate: (value?: string) => startDateValidator(startValue, value) }}
            render={({ field }) => (
              <DatePickerNative
                value={field.value ?? ''}
                onChange={(value) => field.onChange(handleScheduleTypeChange(value))}
                className={styles['add-task-input-field']}
              />
            )}
          />
        </div>
      </div>
      <div className="w-100">
        <ErrorMessage
          errors={formState.errors}
          name="schedule.start"
          render={({ message }) => <p className="f6 red db pb2">{message}</p>}
        />
      </div>
    </div>
  );
};

export const DayChecklist: FC<DayChecklistProps> = ({ selectedDays, onChange }: DayChecklistProps) => {
  const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>): void => {
    const day = event.target.value;
    const setDays = new Set(selectedDays);
    if (setDays.has(day)) {
      setDays.delete(day);
    } else {
      setDays.add(day);
    }
    onChange(Array.from(setDays));
  };

  return (
    <div className={styles['schedule-daylist-container']} data-test-component="DayPicker" data-test-element="container">
      {daysOptions.map(({ label, value: dayValue }, idx) => {
        return (
          <div
            className={`${styles['schedule-daylist-item']} mb2`}
            data-test-component="DayPicker"
            data-test-element="day"
            data-test-key={dayValue}
            key={`${idx}__${dayValue}`}
          >
            <Checkbox
              key={dayValue}
              label={label}
              value={dayValue}
              checked={selectedDays.includes(dayValue)}
              onChange={handleCheckboxClick}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RecurringFields;
