import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import type { ButtonProps } from '@/components/UI/Button/Button';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Header from '@/components/UI/Header';
import Pagination from '@/components/UI/Pagination';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import { errorToast, successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { Treatment } from '@/model/Treatment.model';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { Reducer, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getParamsFromState, initialState, reducer, TreatmentsState } from './Treatment.utils';

const Treatments = () => {
  const [state, dispatch] = useReducer<Reducer<TreatmentsState, any>>(reducer, initialState);
  const globalDispatch = useDispatch();
  const { loading, updating, apiError, treatments, total_pages, current_page, per_page, sort, order, searchQuery } =
    state;
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const paging = { total_pages, current_page, per_page };
  const isMounted = useMountedState();

  const fetchTreatmentsWithParams = async () => {
    dispatch({ type: 'SET_LOADING', data: true });
    dispatch({ type: 'SET_UPDATING', data: true });
    try {
      const {
        data: { data, meta },
      } = await Http.get(
        apiRoute('teams.treatmentTypes.get', {
          query: getParamsFromState(state),
        })
      );
      if (isMounted()) {
        if (data) {
          dispatch({
            type: 'SET_TREATMENT',
            data: { data, meta },
          });
        }
        dispatch({ type: 'SET_UPDATING', data: false });
        dispatch({ type: 'SET_LOADING', data: false });
      }
    } catch (error) {
      if (isMounted()) {
        dispatch({ type: 'SET_ERROR', data: error });
        dispatch({ type: 'SET_UPDATING', data: false });
        dispatch({ type: 'SET_LOADING', data: false });
      }
    }
  };

  useEffect(() => {
    fetchTreatmentsWithParams();
  }, [searchQuery, sort, order, per_page, current_page]);

  const toggleAddTreatmentsModal = () =>
    globalDispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_TREATMENTS',
      props: {
        onAddTreatments: () => fetchTreatmentsWithParams(),
      },
    });

  const handleTreatmentDelete = async () => {
    const treatmentTypeId = getFirstSelectedTreatment()?.id;
    if (_notNil(treatmentTypeId)) {
      dispatch({ type: 'SET_UPDATING', data: true });
      try {
        const requestConfig = {
          method: 'DELETE',
          url: apiRoute('teams.treatmentTypes.delete', {
            treatmentTypeId,
          }),
          redirect: { 403: false },
        };
        await Http.request(requestConfig);
        setSelectedRows({});
        fetchTreatmentsWithParams();
        successToast('Deleted treatment');
      } catch (error) {
        errorToast('Could not delete treatment');
        dispatch({ type: 'SET_ERROR', data: error });
      } finally {
        dispatch({ type: 'SET_UPDATING', data: false });
      }
    }
  };

  const getFirstSelectedTreatment = (): Treatment | undefined => {
    return treatments?.[Number(Object.keys(selectedRows)?.[0])];
  };

  const editTreatment = () => {
    const selectedTreatment = getFirstSelectedTreatment();
    if (selectedTreatment) {
      globalDispatch({
        type: 'OPEN_MODAL',
        modal: 'EDIT_TREATMENT',
        props: {
          treatment: selectedTreatment,
          onEditTreatment: () => {
            fetchTreatmentsWithParams();
          },
        },
      });
    }
  };

  const checkIfAnySelectedAreInUse = () =>
    Object.keys(selectedRows ?? {}).some((treatmentIdx) => treatments?.[Number(treatmentIdx)]?.in_use ?? false);

  const singleRowActions = [
    {
      name: 'Edit',
      key: 'edit',
      action: editTreatment,
    },
    {
      name: 'Delete',
      className: 'red',
      disabled: checkIfAnySelectedAreInUse(),
      action: () =>
        globalDispatch({
          type: 'OPEN_MODAL',
          modal: 'CONFIRM_DELETE_TREATMENTS',
          props: {
            onClick: () => handleTreatmentDelete(),
          },
        }),
    },
  ];

  return (
    <>
      <div>
        <Header mainHeaderText="Treatments" />
        <p className="f6 pb2">
          Add or remove treatments for your team to select from throughout Benchling In Vivo.
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="https://help.benchling.com/hc/en-us/articles/20128319637133-Treatments"
          >
            Read more
          </a>
        </p>
      </div>
      {apiError && <ApiErrorBanner />}
      {loading ? (
        <Loading txt="Fetching treatments" />
      ) : (
        <div className="mv3">
          <div className={`ui-card treatmentsGlossary ${updating ? 'ui__disabled' : ''}`}>
            <div className="pa3 bb b--moon-gray flex justify-between">
              <div className="flex w-100">
                <SelectDropDown
                  title="Actions"
                  className={`plain f6 ${_isNotEmpty(selectedRows) ? 'br--right' : ''}`}
                  alignMenu="right"
                  disabled={_isEmpty(selectedRows) || Object.keys(selectedRows).length > 1}
                  tooltip={Object.keys(selectedRows).length > 1 ? 'Select one treatment at a time' : undefined}
                >
                  <ActionList actions={singleRowActions} />
                </SelectDropDown>
                <div className="w-100 ml2">
                  <SearchFilterBar
                    searchPlaceholderText="Search by treatment name"
                    handleSearchInput={(data) => dispatch({ type: 'SET_SEARCH_QUERY', data })}
                    searchQuery={searchQuery}
                  />
                </div>
              </div>

              <AddNewTreatmentButton toggleAddTreatmentsModal={toggleAddTreatmentsModal} />
            </div>
            {_isEmpty(treatments) ? (
              <NoDataCard
                title="Treatments will appear here"
                text="Treatments listed here can be added to studies by all members of your team"
                NoDataComponent={<AddNewTreatmentButton toggleAddTreatmentsModal={toggleAddTreatmentsModal} />}
              />
            ) : (
              <div>
                <Table
                  data={treatments as Array<Treatment>}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  manualSort={{ sort, order }}
                  setManualSort={(data) => dispatch({ type: 'SET_SORT', data })}
                  columns={[
                    { id: 'name', Header: 'Treatment name', accessor: 'name' },
                    {
                      id: 'created_at',
                      Header: 'Created',
                      accessor: 'created_at',
                      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
                    },
                  ]}
                  disableSelectAll
                />
                <Pagination {...paging} handleChange={(data) => dispatch({ type: 'SET_PAGING', data })} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const AddNewTreatmentButton = ({ toggleAddTreatmentsModal }: { toggleAddTreatmentsModal: ButtonProps['onClick'] }) => (
  <Button className="mt3" onClick={toggleAddTreatmentsModal}>
    Add treatments
  </Button>
);

export default Treatments;
