import { _isEmpty } from '@/littledash';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { FC, useState } from 'react';

interface StudyCodeAddTextProps {
  onClick: (text: string) => void;
  closeModal: () => void;
}

const StudyCodeAddText: FC<StudyCodeAddTextProps> = ({ onClick, closeModal }) => {
  const [text, setText] = useState<string>('');

  const handleSubmit = () => {
    onClick(text);
    closeModal();
  };

  return (
    <ModalContainer size="small">
      <ModalHeader title="Add text" closeModal={closeModal} className="pa3 b--moon-gray bg-white" />
      <div className="pb3 ph3">
        <label htmlFor="text" className="lh-copy">
          Label
        </label>
        <input type="text" id="text" name="text" onChange={(e) => setText(e.target.value)} />
      </div>
      <ModalActions submitButtonProps={{ disabled: _isEmpty(text) }} onSubmit={handleSubmit} className="bt pa3" />
    </ModalContainer>
  );
};

export default StudyCodeAddText;
