// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import Lookup from '@/components/UI/Lookup';
import { _isEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const SelectTreatment = ({ handleCallback }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [selected, setSelected] = useState('');

  const loadOptions = async ({ inputValue, page }) => {
    setLoading(true);
    let url = apiRoutes('team.glossary.treatments');
    url = `${url}?page=${page}`;
    if (inputValue) {
      url = `${url}&query=${inputValue}`;
    }
    try {
      const {
        data: { data, meta },
      } = await Http.get(url);
      const { current_page: currentPage, last_page: lastPage } = meta;
      const hasMore = currentPage < lastPage;
      return {
        options: data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        hasMore,
      };
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="center mw6 ui-card pa3">
      <h3 className="normal lh-title f4 mb0">Select a treatment</h3>
      <div className="mv4">
        {apiError && <ApiErrorBanner />}
        <label>Treatment name</label>
        <Lookup isMulti={false} loadOptions={loadOptions} handleSelect={(data) => setSelected(data.value)} />
      </div>
      <div>
        <Button
          onClick={() => handleCallback(selected)}
          disabled={apiError || loading || _isEmpty(selected)}
          className="mr3"
        >
          Continue
        </Button>
        <Button plain onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SelectTreatment;
