// @ts-nocheck: converted from JS

import DatePickerNative from '@/components/UI/DatePickerNative';
import { preventNumberScroll } from '@/helpers';
import { DateUtils } from '@/utils/Date.utils';
import { ErrorMessage } from '@hookform/error-message';
import { memo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { validationSchema } from './Cages.utils';

const CageRow = memo(({ field, index }) => {
  const { register, control, errors } = useFormContext();

  return (
    <div
      className={`w-100 flex flex-wrap justify-between items-start ph4 pv3 ${index !== 0 ? 'bt b--moon-gray' : ''}`}
      data-testid="cage-container"
    >
      <div>
        <label htmlFor={`collections[${index}].name`}>Name</label>
        <input
          type="text"
          key={field.id}
          name={`collections[${index}].name`}
          ref={register({ ...validationSchema.collections.name })}
          defaultValue={field.name}
          data-testid={`cage-${index}-name-input`}
          className={errors?.collections?.[index]?.name ? 'input__error' : ''}
          style={{ marginBottom: 0 }}
        />
        <ErrorMessage
          errors={errors}
          name={`collections[${index}].name`}
          render={({ message }) => <p className="f7 red db lh-copy pt1">{message}</p>}
        />
      </div>
      <div>
        <label htmlFor="subjects">Subjects</label>
        <input
          type="number"
          onWheel={preventNumberScroll}
          key={field.id}
          name={`collections[${index}].subjects`}
          data-testid={`cage-${index}-subject-count-input`}
          ref={register({
            ...validationSchema.collections.subjects,
          })}
          defaultValue={field.subjects}
          className={errors?.collections?.[index]?.subjects ? 'input__error' : ''}
          style={{ marginBottom: 0, width: 120 }}
        />
        <ErrorMessage
          errors={errors}
          name={`cages[${index}].subjects`}
          render={({ message }) => <p className="f7 red db lh-copy pt1">{message}</p>}
        />
      </div>
      <div>
        <label>Sex</label>
        {[
          { n: 'Male', v: 'm' },
          { n: 'Female', v: 'f' },
        ].map((sex, i) => (
          <div className="flex">
            <input
              id={`collections[${index}].sex_${sex.v}`}
              type="radio"
              defaultChecked={field.sex === sex.v}
              defaultValue={field.sex}
              name={`collections[${index}].sex`}
              data-testid={`cage-${index}-sex-${sex.v}-input`}
              ref={register({ ...validationSchema.collections.sex })}
              value={sex.v}
              className="mr2 dib"
              style={{ marginBottom: 0 }}
            />
            <label htmlFor={`collections[${index}].sex_${sex.v}`} className="pointer">
              {sex.n}
            </label>
          </div>
        ))}
      </div>
      <div>
        <label>D.O.B</label>
        <Controller
          name={`collections[${index}].dob`}
          control={control}
          defaultValue={field.dob}
          rules={{ ...validationSchema.collections.dob }}
          render={({ onChange, value }: ControllerRenderProps) => (
            <DatePickerNative
              value={value}
              max={DateUtils.dateNow()}
              style={{ marginBottom: 0 }}
              onChange={onChange}
              testId={`cage-${index}-dob-input`}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`collections[${index}].dob`}
          render={({ message }) => <p className="f7 red db lh-copy pt1">{message}</p>}
        />
      </div>
    </div>
  );
});

export default CageRow;
