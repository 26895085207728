// @ts-nocheck: converted from JS

import FormRender from '@/components/UI/FormRender';
import { safelyDecodeURIComponent } from '@/littledash';

const isMulti = (fieldType) => fieldType === 'Multiselect';
const isSelect = (fieldType) => fieldType === 'Select';

const getValue = (metaItem, linkMetadata) => {
  const metadata = linkMetadata.find((linkMetaItem) => linkMetaItem.glossary_id === metaItem.id);
  if (metadata) {
    if (isMulti(metaItem.field_type) || isSelect(metaItem.field_type)) {
      return metadata.map((metaValueItem) => ({
        label: safelyDecodeURIComponent(metaValueItem.value),
        value: safelyDecodeURIComponent(metaValueItem.value),
      }));
    } else {
      return metadata.value;
    }
  }
  return '';
};

export const getMetadataFields = (metadata, link) => {
  const fields = metadata.map((metaItem) => ({
    value: link?.metadata && getValue(metaItem, link.metadata),
    type: metaItem.field_type,
    name: `metadata.${metaItem.title}`,
    label: metaItem.title,
    options:
      metaItem?.options &&
      metaItem.options.map((option) => ({
        label: safelyDecodeURIComponent(option),
        value: option,
      })),
    isMulti: isMulti(metaItem.field_type),
  }));
  return <FormRender hookFormVersion="latest" fields={fields} />;
};
