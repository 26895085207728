// @ts-nocheck: converted from JS

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import Banner from '@/components/UI/Banner';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { _isEmpty } from '@/littledash';
import { useState } from 'react';
import samplesConfig from './SamplesConfig';

const SelectedList = ({ samples, handleInput, disabled = false }) => {
  const state = useState([...samples].map(() => false));
  const [openItems, setOpenItems] = state;

  const allExpanded = !_isEmpty(openItems) && openItems.every((o) => o === true);
  const toggleExpand = () => {
    if (allExpanded) {
      return setOpenItems(samples.map(() => false));
    } else {
      return setOpenItems(samples.map(() => true));
    }
  };

  const handleCheckbox = (index, event) => {
    const options = samples[index].options;
    if (!options.details.includes(event.target.name)) {
      options.details.push(event.target.name);
    } else {
      options.details.splice(options.details.indexOf(event.target.name), 1);
    }

    return handleInput(index, 'options', options);
  };

  return (
    <div className="pl4">
      {!_isEmpty(samples) ? (
        <div className="ui-card pa3">
          <div className="bb b--moon-gray pb3 flex flex-wrap justify-between items-center">
            <h3 className="f6 dark-gray normal basier-reg lh-title">Selected samples</h3>
            <a className="lh-title link blue f7" onClick={() => toggleExpand()}>
              {allExpanded ? 'Collapse' : 'Expand'} all
            </a>
          </div>
          <div className="ui__overflow__scroll_y" style={{ height: '440px' }}>
            <Accordion outerState={state}>
              {samples.map((s, i) => (
                <AccordionItem key={s.id}>
                  <AccordionHeader className="flex flex-wrap justify-between items-top">
                    <span className="dib f6 basier-med near-black lh-title w-60 pr2">{s.title}</span>
                    <span className="dib f7 lh-title w-40 tr">
                      {s.options ? `${s.options.details.map((d) => d).join(', ')}` : 'No details selected'}
                    </span>
                  </AccordionHeader>
                  <AccordionPanel>
                    <div className={`pa3 mb3 br2 bg-near-white ${disabled ? 'ui__disabled' : ''}`}>
                      <label>Details to collect</label>
                      {s.options &&
                        samplesConfig.map((c) => (
                          <Checkbox
                            key={c.name}
                            className="pointer mt3"
                            type="checkbox"
                            label={`${c.title} (${c.units.map((u) => u).join(', ')})`}
                            name={c.name}
                            checked={s.options.details.includes(c.name)}
                            onChange={(e) => handleCheckbox(i, e)}
                          />
                        ))}
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      ) : (
        <Banner info className="mw6" dismiss={false}>
          <h3 className="f5 normal basier-med lh-title">No samples have been selected</h3>
          <p className="f6 pt2 lh-copy">
            To get started, search and select the samples relevant to your studies. All team members will have access to
            the selected samples.
          </p>
        </Banner>
      )}
    </div>
  );
};

export default SelectedList;
