// @ts-nocheck: converted from JS

import { isDateGreaterThanToday } from '@/helpers';

export const validationSchema = {
  totalCages: {
    min: {
      value: 0,
      message: 'You must create atleast one cage',
    },
    max: {
      value: 200,
      message: 'You can create a maximum of 200 cages per study',
    },
  },
  collections: {
    name: {
      minLength: {
        value: 3,
        message: 'Longer than 3 characters',
      },
      maxLength: {
        value: 50,
        message: 'Less than 50 characters',
      },
      required: 'Required',
    },
    subjects: {
      min: {
        value: 0,
        message: 'Create atleast one subject',
      },
      max: {
        value: 10,
        message: 'Maximum of 10',
      },
      required: 'Required',
    },
    sex: {
      required: 'Required',
    },
    dob: {
      required: 'Required',
      validate: (value) => (isDateGreaterThanToday(value) ? 'D.O.B cannot be in the future' : true),
    },
  },
};
