import { _notNil } from '@/littledash';
import type { Entity, ID } from '@/model/Common.model';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { read as xlsxRead, utils as xlsxUtils } from 'xlsx';

export const initalSteps = {
  upload: {
    displayName: 'Upload your data',
    complete: false,
  },
  match: {
    displayName: 'Match columns',
    complete: false,
  },
  review: {
    displayName: 'Review import',
    complete: false,
  },
  complete: {
    displayName: 'Complete',
    complete: false,
  },
};

export interface IDField {
  name: string;
  key: string;
}

export const idFields: Array<IDField> = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Tail',
    key: 'alt_ids.tail',
  },
  {
    name: 'Ear',
    key: 'alt_ids.ear',
  },
  {
    name: 'Tag',
    key: 'alt_ids.tag',
  },
  {
    name: 'Donor',
    key: 'alt_ids.donor',
  },
  {
    name: 'Cage',
    key: 'collection_name',
  },
];

export const readFile = (inputFile: File): Promise<Array<Record<string, unknown>>> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing input file.'));
    };
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      try {
        const workbook = xlsxRead(arrayBuffer, { type: 'array' });
        // Grab the first sheet in the xlsx
        const firstSheet = Object.values(workbook.Sheets)[0];
        resolve(xlsxUtils.sheet_to_json<Record<string, unknown>>(firstSheet));
      } catch (e) {
        // Handle error here so Sentry doesn't catch it
        return reject(new Error('file read error', { cause: e }));
      }
    };

    reader.readAsArrayBuffer(inputFile);
  });
};

export const getAllColNames = (fileData: Array<Record<string, unknown>>): Array<string> => {
  const names = new Set<string>();
  fileData.forEach((f) => Object.keys(f).forEach((k) => names.add(k)));
  return Array.from(names);
};

export const getExistingMeasurements = (id: number, date: string, signal: AbortSignal) => {
  const url = apiRoute('studies.measurements.get', { id });
  const query = `?measured_at=${date}`;
  return Http.get(`${url}${query}`, { signal });
};

export interface ImportCageMatch extends Entity {
  catalog: string;
  name: string;
  study_id: ID;
}

export interface ImportRowMatch {
  name?: string;
  displayName?: string;
  score?: number;
}

export interface MatchData {
  confirmed: boolean;
  displayName: string;
  included: boolean;
  matched: string;
}

export const checkIdIsUnique = (field: MatchData, animalIdentifiers: Array<Record<string, string>>) => {
  const idName = field?.matched?.replace(/^alt_ids./, '');
  const idArr = animalIdentifiers?.map((animal) => animal[`${idName}`]);
  const idArrUnique = [...new Set(idArr)];
  return idArr.every((id) => _notNil(id)) && idArr.length === idArrUnique.length;
};

const idFieldNames = idFields?.reduce((acc: Array<string>, { key }) => {
  if (key !== 'collection_name') {
    acc.push(key);
  }
  return acc;
}, []);

export interface ValidationState {
  cageConfirmed: boolean;
  idFieldConfirmed: boolean;
  chosenIdField?: string;
  animalsHaveUniqueId: boolean;
  hasIncompleteFields: boolean;
  activeFieldsNotConfirmed: Array<MatchData>;
}

export const getValidationState = (
  matchData: Record<string, MatchData>,
  animalIdentifiers: Array<Record<string, string>>
): ValidationState => {
  let cageConfirmed = false;
  let idFieldConfirmed = false;
  let hasIncompleteFields = false;
  let animalsHaveUniqueId = false;
  let chosenIdField: string | undefined;

  const includedFields = Object.values(matchData).reduce((acc: Array<MatchData>, data) => {
    const { included, matched, confirmed } = data;
    if (included) {
      acc.push(data);
      if (!cageConfirmed && matched === 'collection_name' && confirmed) {
        cageConfirmed = true;
      }
      if (!idFieldConfirmed && idFieldNames.includes(matched) && confirmed) {
        idFieldConfirmed = true;
        chosenIdField = matched;
      }
      if (!hasIncompleteFields && !matched) {
        hasIncompleteFields = true;
      }
    }
    return acc;
  }, []);

  if (_notNil(chosenIdField) && !cageConfirmed) {
    const field = includedFields.find(({ matched }) => matched === chosenIdField);
    if (_notNil(field)) {
      animalsHaveUniqueId = checkIdIsUnique(field, animalIdentifiers);
    }
  }

  const activeFieldsNotConfirmed = includedFields.filter(({ confirmed }) => !confirmed);

  return {
    cageConfirmed,
    idFieldConfirmed,
    chosenIdField,
    animalsHaveUniqueId,
    hasIncompleteFields,
    activeFieldsNotConfirmed,
  };
};
