import { taskTypes } from '@/components/Studies/Tasks/Task.tsx';
import Select from '@/components/UI/Select';
import { _notNil } from '@/littledash.ts';
import { TaskApiId, TasksExecutionAfterSave, TaskV1 } from '@/model/Task.model.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal.tsx';
import cn from 'classnames';
import { isToday } from 'date-fns';
import { FC, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { RiCalendarTodoFill, RiDraggable } from 'react-icons/ri';

type TasksExecutionAfterSaveOption = { label: string; value: TasksExecutionAfterSave };
const tasksExecutionAfterSaveOptions: Array<TasksExecutionAfterSaveOption> = [
  { label: 'Focus search bar', value: 'focus_search_bar' },
  { label: 'Move to next animal', value: 'next_animal' },
];
type ExecuteTasksModalProps = {
  tasks: Array<TaskV1>;
  onExecute: (data: { taskApiIds: Array<TaskApiId>; after_save: TasksExecutionAfterSave }) => void;
  onCancel: () => void;
};

const TaskCard: FC<{ task: TaskV1; index: number; onDrop: (source: number, destination: number) => void }> = ({
  task,
  index,
  onDrop,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [{ opacity }, dragRef] = useDrag({
    item: {
      type: 'TaskCard',
      taskApiId: task.id,
      sourceIndex: index,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isOver }, dropRef] = useDrop({
    accept: 'TaskCard',

    // @ts-expect-error: untyped
    canDrop: (item) => item.sourceIndex !== index,
    drop: (item) => {
      // @ts-expect-error: untyped
      if (Number.isFinite(item.sourceIndex) && item.sourceIndex !== index) {
        // @ts-expect-error: untyped
        onDrop(item.sourceIndex, index);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.canDrop() && monitor.isOver(),
    }),
  });
  dragRef(dropRef(cardRef));
  return (
    <div
      data-test-component="TaskCard"
      data-test-element="container"
      data-test-key={task.id}
      ref={cardRef}
      className={cn('flex flex-row justify-between items-center bg-near-white pa3 mt2 ba', {
        'b--dark-gray': isOver,
      })}
      style={{ opacity }}
    >
      <div className="flex flex-column f5 near-black">
        <div className="fw5">{task.title}</div>
        <div className="f6 fw1">{taskTypes[task.type]}</div>
      </div>
      <div>
        <RiDraggable size={25} className="dark-gray" />
      </div>
    </div>
  );
};

export const ExecuteTasksModal: FC<ExecuteTasksModalProps> = ({ tasks, onExecute, onCancel }) => {
  const [taskList, updateTaskList] = useState(() => tasks);
  const [afterSaveAction, setAfterSaveAction] = useState(() => tasksExecutionAfterSaveOptions[0]);

  const taskExecutionDayDay = useMemo(() => {
    const minTaskStart = tasks
      .map((t) => new Date(t.duration.start))
      .sort((a, b) => b.getTime() - a.getTime())
      .pop();
    if (_notNil(minTaskStart) && !isToday(minTaskStart)) {
      return DateUtils.renderDateTime(minTaskStart.toISOString(), { format: DateRenderFormat.DateDefaultWithDay });
    }
    return null;
  }, [tasks]);

  const handleDrop = (source: number, destination: number) => {
    updateTaskList((previous) => {
      const update = [...previous];
      const [movedTask] = update.splice(source, 1);
      update.splice(destination, 0, movedTask);
      return update;
    });
  };
  return (
    <ModalContainer size="small" testKey="execute-tasks-modal">
      <ModalHeader
        title="Execute tasks"
        subText={
          <span>
            Click and drag the <RiDraggable className="dark-gray" /> icon to specify the order tasks will be completed
          </span>
        }
        subTextClasses="f6 lh-copy mt1 measure-wide"
        closeModal={onCancel}
      />
      <div className="flex flex-column ph3 pt2 pb4 overflow-x-auto overflow-y-hidden bb b--moon-gray">
        {_notNil(taskExecutionDayDay) ? (
          <div className="flex flex-row items-center fw5 pv3 ph3 bg-washed-blue ba b--dark-blue near-black">
            <RiCalendarTodoFill />
            <span className="ml2">{taskExecutionDayDay}</span>
          </div>
        ) : null}
        {taskList.map((task, index) => (
          <TaskCard key={`${task.id}___${index}`} task={task} index={index} onDrop={handleDrop} />
        ))}
      </div>
      <div className="pv3 ph3 flex bb justify-between items-center">
        <label className="mb0 f6">After save</label>
        <Select<TasksExecutionAfterSaveOption>
          options={tasksExecutionAfterSaveOptions}
          defaultValue={afterSaveAction}
          isMulti={false}
          onChange={(option) =>
            setAfterSaveAction(
              tasksExecutionAfterSaveOptions.find((e) => e.value === option?.value) ?? tasksExecutionAfterSaveOptions[0]
            )
          }
        />
      </div>

      <ModalActions
        className="pa3 mt3"
        submitBtnText="Continue"
        cancelBtnText="Close"
        onSubmit={() => onExecute({ taskApiIds: taskList.map((t) => t.id), after_save: afterSaveAction.value })}
        onCancel={() => onCancel()}
      />
    </ModalContainer>
  );
};
