// This file was generated by lezer-generator. You probably shouldn't edit it.
import { LRParser } from '@lezer/lr';
const spec_Name = { __proto__: null, PI: 30, AVERAGE: 36 };
export const presetFormulaParser = LRParser.deserialize({
  version: 14,
  states:
    "$tOVQPOOQhQPOOOVQPO'#C`OOQO'#Ci'#CiOvQPO'#ChOOQO'#Cm'#CmO{QPO'#ClOOQO'#Cu'#CuOOQO'#Cb'#CbOOQO'#Cc'#CcOOQO'#Cd'#CdOOQO'#Ce'#CeOOQO'#Ca'#CaOVQPO,58zOhQPO,58zO!QQPO,59SO!VQPO,59WO!bQPO1G.fOVQPO1G.fOOQO1G.n1G.nOOQO'#Cp'#CpO!vQPO'#CoO!{QPO1G.rO#QQPO7+$QO!VQPO,59ZOOQO7+$^7+$^OOQO<<Gl<<GlO#cQPO1G.uOOQO1G.u1G.u",
  stateData:
    '#k~OhOS~OQVORVOYQO_RObTO~OjWOkXOlYOmZO~OY_O~OY`O~OZcO~OQdORdO_RO~OjWOkXOlYOmZOfSiZSi~OehO~OZiO~OZjOjWOkXOlYOmZO~OehOZci~O',
  goto: '#RjPPPPkqyyyyPP!P!ZPPk!cP!i!oPPPP!uXVOQ]bU]PagRb^X[P^agWVOQ]bTd`h]SOQ]`bhXUOQ]bQf`RlhQe`RkhQPOQ^QQa]Rgb',
  nodeNames:
    '⚠ Formula Number Variable NumericExpression NumericOperator MultiplicationOperator DivisionOperator AdditionOperator SubtractionOperator OpeningBracket ClosingBracket PiFunction Keyword Name PI AverageFunction Keyword AVERAGE AverageFunctionInput Atom Comma',
  maxTerm: 29,
  skippedNodes: [0, 14],
  repeatNodeCount: 0,
  tokenData:
    ")f~RgX^!jpq!jtu#_xy$Pyz$Uz{$Z{|$`|}$e}!O$j!O!P$o!P!Q%{!Q!R&Q!R![&r!c!})Z#T#o)Z#y#z!j$f$g!j#BY#BZ!j$IS$I_!j$I|$JO!j$JT$JU!j$KV$KW!j&FU&FV!j~!oYh~X^!jpq!j#y#z!j$f$g!j#BY#BZ!j$IS$I_!j$I|$JO!j$JT$JU!j$KV$KW!j&FU&FV!j~#bS!Q![#n!c!}#n#R#S#n#T#o#n~#sSR~!Q![#n!c!}#n#R#S#n#T#o#n~$UOY~~$ZOZ~~$`Oj~~$eOl~~$jOe~~$oOm~~$rP!Q![$u~$zSQ~!Q![$u!g!h%W#R#S$u#X#Y%W~%ZS{|%g}!O%g!Q![%p#R#S%p~%jQ!Q![%p#R#S%p~%uQQ~!Q![%p#R#S%p~&QOk~~&VXQ~!O!P$u!Q![&r!g!h%W#R#S&r#U#V'`#X#Y%W#b#c'Z#c#d'}#l#m(f~&wUQ~!O!P$u!Q![&r!g!h%W#R#S&r#X#Y%W#b#c'Z~'`OQ~~'cR!Q!R'l!R!S'l#R#S'l~'qSQ~!Q!R'l!R!S'l#R#S'l#b#c'Z~(QQ!Q!Y(W#R#S(W~(]RQ~!Q!Y(W#R#S(W#b#c'Z~(iS!Q![(u!c!i(u#R#S(u#T#Z(u~(zTQ~!Q![(u!c!i(u#R#S(u#T#Z(u#b#c'Z~)`Q^~!c!})Z#T#o)Z",
  tokenizers: [0],
  topRules: { Formula: [0, 1] },
  specialized: [{ term: 14, get: (value: keyof typeof spec_Name) => spec_Name[value] || -1 }],
  tokenPrec: 0,
  termNames: {
    '0': '⚠',
    '1': '@top',
    '2': 'Number',
    '3': 'Variable',
    '4': 'NumericExpression',
    '5': 'NumericOperator',
    '6': 'MultiplicationOperator',
    '7': 'DivisionOperator',
    '8': 'AdditionOperator',
    '9': 'SubtractionOperator',
    '10': 'OpeningBracket',
    '11': 'ClosingBracket',
    '12': 'PiFunction',
    '13': 'Keyword<"PI">',
    '14': 'Name',
    '15': 'Name/"PI"',
    '16': 'AverageFunction',
    '17': 'Keyword<"AVERAGE">',
    '18': 'Name/"AVERAGE"',
    '19': 'AverageFunctionInput',
    '20': 'Atom',
    '21': 'Comma',
    '22': '␄',
    '23': '%mainskip',
    '24': 'space',
    '25': 'expression',
    '26': '"*"',
    '27': '"/"',
    '28': '"+"',
    '29': '"-"',
  },
});
