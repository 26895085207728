// @ts-nocheck: converted from JS

import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import './portal.scss';

const Portal = ({ children, parentRef, offsets = {} }) => {
  const [portal] = useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement('div');
  });

  const { x, y, width } = parentRef?.getBoundingClientRect() || {};

  useEffect(() => {
    portal.classList.add('portal-root');
    portal.classList.add(`portal-align-${offsets?.align || 'left'}`);
    portal.style.left = `${x + width / 2 - offsets?.width + offsets?.left}px`;
    portal.style.top = `${y + window.scrollY + offsets?.top}px`;

    document.body.appendChild(portal);
    return () => {
      portal.remove();
    };
  }, []);

  return createPortal(children, portal);
};

export default Portal;
