import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import { Columns } from '@/components/UI/Table/TableComponent.model.ts';
import UserAvatar from '@/components/UI/UserAvatar';
import { successToast } from '@/helpers';
import { Sample } from '@/model/Sample.model.ts';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import { modalAction } from '@/utils/modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const Samples = ({ hasWriteAccess = false }) => {
  const { subjectId } = useParams<{ subjectId: string }>();
  const {
    entity: samples,
    entityLoading: samplesLoading,
    fetchEntity: fetchSamples,
    entityError: fetchError,
  } = useFetchEntity<Array<Sample>>({
    entityType: 'animalSamples',
    params: { id: subjectId },
  });
  const dispatch = useDispatch();
  const { openModal } = modalAction(dispatch);

  const columns: Columns<Sample> = [
    {
      id: 'sample_id',
      Header: 'ID',
      sortBy: 'sample_id',
      accessor: 'sample_id',
      Cell: ({ row: { original: sample } }) => (
        <a
          className="link blue lh-title db"
          onClick={() =>
            openModal('VIEW_SAMPLE', {
              sample,
            })
          }
        >
          {sample.sample_id}
        </a>
      ),
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'type',
      sortBy: 'type',
    },
    {
      id: 'date_taken',
      Header: 'Recorded',
      accessor: 'date',
      sortBy: 'date',
      Cell: ({
        row: {
          original: { date },
        },
      }) => <DateTimeRenderer value={date} />,
    },
    {
      id: 'recorded_by',
      Header: 'User',
      sortBy: 'created_by.email',
      accessor: 'created_by.email',
      width: 100,
      Cell: ({
        row: {
          original: { created_by: createdBy },
        },
      }) => <UserAvatar user={createdBy} tooltip={createdBy?.name ?? createdBy?.email} />,
    },
    {
      id: 'actions',
      sortDisabled: true,
      accessor: 'actions',
      isVisible: hasWriteAccess,
      width: 75,
      className: 'ui__table--overflow-cell',
      Cell: ({ row: { original: sample } }) => {
        const { sendRequest: deleteSample } = useRequest({
          route: 'samples.destroy',
          method: 'delete',
          params: { id: sample.id },
        });
        return (
          <SelectDropDown
            title="More options"
            iconOnly
            className="hover-near-black"
            alignMenu="right"
            portalProps={{
              width: 120,
            }}
          >
            <ActionList
              actions={[
                {
                  action: () =>
                    openModal('EDIT_SAMPLE', {
                      sample,
                      handleAfterClose: fetchSamples,
                    }),
                  name: 'Edit',
                },
                {
                  action: () =>
                    openModal('CONFIRM_DELETE_SAMPLE', {
                      onClick: async (reasonForDelete?: string) => {
                        await deleteSample({ reason_for_change: reasonForDelete });
                        await fetchSamples();
                        successToast('Deleted.');
                      },
                    }),
                  className: 'red',
                  name: 'Delete',
                },
              ]}
            />
          </SelectDropDown>
        );
      },
    },
  ];

  if (samplesLoading) {
    return <Loading />;
  }

  return (
    <div className="ui-card" data-test-component="Samples" data-test-element="container">
      <div className="pa3 bb b--moon-gray flex justify-between items-center">
        <h3 className="f5 lh-title">Samples</h3>
        <Button
          disabled={!hasWriteAccess}
          testId="add-new-sample"
          onClick={() =>
            openModal('SELECT_SAMPLES', {
              animalId: subjectId,
              handleCallback: fetchSamples,
            })
          }
        >
          Add new
        </Button>
      </div>
      {fetchError && <ApiErrorBanner error={fetchError} />}
      <Table
        columns={columns}
        data={samples as Array<Sample>}
        sortBy={[{ columnId: 'recorded', desc: false }]}
        className="tableOverflowVisible"
      />
    </div>
  );
};

export default Samples;
