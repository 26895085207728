import { timeUnitOptions } from '@/components/Studies/DataTables/Create/Steps/Information.utils';
import type {
  DataTableColumn,
  DataTableColumnUpdateForm,
  DataTableType,
} from '@/components/UI/DataTable/DataTable.model';
import { getValueAndUnitFromColumn, serializeTimepointValue } from '@/components/UI/DataTable/DataTable.util';
import { uniqueNameValidatorFactory } from '@/components/UI/DataTable/toolbar/Menus/AddColumn/AddColumn.util';
import { Number as NumberField, Select } from '@/components/UI/FormFields';
import { _isNil, _notNil } from '@/littledash';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest
import type { Validate } from 'react-hook-form/dist/types/validator';

interface EditDatatableColumnProps {
  tableType: DataTableType;
  column: DataTableColumn;
  columns: Array<DataTableColumn>;
  closeModal: () => void;
  onSave: (columnUpdate: DataTableColumnUpdateForm) => Promise<void>;
}

const nameValidations = (tableType: DataTableType): Record<string, Validate> => {
  switch (tableType) {
    case 'numeric':
      return {
        required: (value: any) =>
          _notNil(value) && Number.isFinite(Number(value)) ? true : 'Name must be supplied & numeric',
      };
    default:
      return {
        required: (value: any) =>
          typeof value === 'string' && value.trim().length > 0 ? true : 'Name must be supplied',
      };
  }
};

const toDefaultValues = (tableType: DataTableType, column: DataTableColumn): DataTableColumnUpdateForm => {
  switch (tableType) {
    case 'time': {
      const { unit: unitValue, amount } = getValueAndUnitFromColumn(column);
      return {
        id: column.id,
        type: column.type,
        name: amount,
        unit: unitValue === 'seconds' ? 'S' : unitValue === 'minutes' ? 'M' : 'H',
      };
    }
    default: {
      return {
        id: column.id,
        type: column.type,
        name: column.name,
        unit: (column as { unit?: string }).unit,
      };
    }
  }
};

export const EditDatatableColumn: FC<EditDatatableColumnProps> = ({
  tableType,
  column,
  columns,
  onSave,
  closeModal,
}) => {
  const onSubmit = async (update: DataTableColumnUpdateForm) => {
    closeModal();
    switch (tableType) {
      case 'time':
        await onSave({
          id: column.id,
          type: column.type,
          name: serializeTimepointValue(update.name ?? '', update.unit ?? ''),
        });
        break;
      default:
        await onSave({
          id: column.id,
          type: column.type,
          name: update.name ?? '',
          unit: update.unit,
        });
    }
  };

  const uniqueColumnName = useMemo(
    () => uniqueNameValidatorFactory(new Set(columns.filter((c) => c.id !== column.id).map(({ name }) => name.trim()))),
    [column, columns]
  );

  const formMethods = useForm<DataTableColumnUpdateForm>({
    mode: 'onChange',
    defaultValues: toDefaultValues(tableType, column),
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid, isDirty },
  } = formMethods;

  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Edit column" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {tableType === 'time' ? (
          <FormProvider {...formMethods}>
            <div className="flex pa2">
              <div className="pa2">
                <NumberField name="name" label="Timepoint" required />
              </div>
              <div className="pa2">
                <Select name="unit" label="Unit" options={timeUnitOptions} required />
              </div>
            </div>
          </FormProvider>
        ) : (
          <>
            <div className="ph3 pv2">
              <label>Name</label>
              <input
                ref={register({ validate: { ...nameValidations(tableType), uniqueColumnName } })}
                name="name"
                style={{ marginBottom: 0 }}
                className={`${errors?.name ? 'input__error' : ''}`}
                disabled={isSubmitting}
                type={tableType === 'numeric' ? 'number' : 'text'}
                autoFocus
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <small className="red db pt2">{message}</small>}
              />
            </div>
            {tableType === 'custom' && column.type === 'number' && (
              <div className="ph3 pv2">
                <label>Unit</label>
                <input
                  ref={register()}
                  name="unit"
                  type="text"
                  style={{ marginBottom: 0 }}
                  className={`${errors?.unit ? 'input__error' : ''}`}
                  disabled={isSubmitting}
                />
                <ErrorMessage
                  errors={errors}
                  name="unit"
                  render={({ message }) => <small className="red db pt2">{message}</small>}
                />
              </div>
            )}
          </>
        )}
        <ModalActions
          className="pa3 mt3 bt b--moon-gray"
          onSubmit={handleSubmit(onSubmit)}
          onCancel={closeModal}
          submitBtnText={_isNil(column) ? 'Add' : 'Update'}
          submitButtonProps={{ disabled: !isValid || !isDirty || isSubmitting, submit: true }}
        />
      </form>
    </ModalContainer>
  );
};
