import Loading from '@/components/Loading';
import { reducer } from '@/components/Studies/Groups/Analysis/Analysis.util';
import { AnalysisTable } from '@/components/Studies/Groups/Analysis/AnalysisTable';
import { AnalysisToolbar } from '@/components/Studies/Groups/Analysis/AnalysisToolbar';
import { EmptyState } from '@/components/Studies/Groups/Analysis/EmptyState';
import StickyItem from '@/components/UI/Sticky';
import { _notNil } from '@/littledash';
import type { Preset } from '@/model/Preset.model';
import type { StudyApiId } from '@/model/Study.model';
import { type FC, useEffect, useReducer } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';

interface AnalysisProps {
  studyApiId: StudyApiId;
  preset: Preset;
}

export const Analysis: FC<AnalysisProps> = ({ studyApiId, preset }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const { invoke: loadAnalysisConfig, loading } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/analysis/config',
    path: { studyApiId },
    invokeOnInit: false,
  });

  useEffect(() => {
    if (_notNil(studyApiId)) {
      loadAnalysisConfig({ path: { studyApiId } }).then((response) => {
        if (response.type === 'success') {
          dispatch({ type: 'update-analysis-config', data: response.body });
        }
      });
    }
  }, [studyApiId]);

  if (loading) {
    return (
      <div className="w-100 h-100">
        <Loading txt="Loading analyisis configuration" />
      </div>
    );
  }

  return (
    <div className="pv4 h-100 ph4 bg-near-white">
      <div className="ui-card" style={{ minHeight: '60vh', maxHeight: '100%' }}>
        <StickyItem>
          <AnalysisToolbar studyApiId={studyApiId} preset={preset} state={state} dispatch={dispatch} />
        </StickyItem>
        <div className="pv3 h-100">
          {_notNil(state.options) && Number.isInteger(state.options?.day) ? (
            <AnalysisTable studyApiId={studyApiId} options={state.options} dispatch={dispatch} />
          ) : (
            <EmptyState studyApiId={studyApiId} issues={state.issues ?? []} dispatch={dispatch} />
          )}
        </div>
      </div>
    </div>
  );
};
