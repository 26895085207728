// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox/Checkbox';
import { defaultPromiseErrorHandler, errorToast, successToast } from '@/helpers';
import store from '@/store';
import * as Form from '@/support/form';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import React from 'react';
import './ExportStudy.scss';

class ExportStudy extends React.Component {
  /**
   * Form State
   */

  defaultFields = {
    master: false,
    summary: false,
    observations: false,
    prism: false,
    design: false,
  };

  exportTypes = ['master', 'summary', 'observations', 'prism', 'design'];

  handleCheckbox = Form.handleCheckbox.bind(this);

  /**
   * Lifecycle
   */

  constructor(props) {
    super(props);

    this.state = {
      fields: Form.fieldObject(this.defaultFields),
      errors: Form.errorObject(this.defaultFields),
      submissionError: false,
      hasObservations: true,
      exportTooLarge: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    Http.get(apiRoute('studies.observations.check', { id: this.props.studyId }))
      .then(({ data }) => {
        this.setState({
          hasObservations: data.has_observations,
        });
      })
      .catch(defaultPromiseErrorHandler);
  }

  /**
   * Rendering
   */
  closeModal = () => {
    store.dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  render() {
    const { hasGroups } = this.props;
    const disabled = this.state.isLoading || !this.validate();

    return (
      <form className=" ui-card pa4 center" style={{ width: '480px' }}>
        <div className="pb3 tc">
          <h3 className="normal f4 pt2 pb2">Export study data</h3>
        </div>
        {this.state.submissionError && (
          <Banner critical dismiss={false} className="mw6 mb3">
            <h3 className="normal lh-title f5 pb1">There was a problem exporting your study</h3>
            <p className="f6 lh-copy pb3">
              An error has occurred when exporting your study the form, please try again later. If this keeps occurring
              please contact support.
            </p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
        {this.state.exportTooLarge ? (
          <Banner success dismiss={false} className="mw6 mb4">
            <h3 className="normal lh-title f5 pb1">Your export request is being created</h3>
            <p className="f6 lh-copy pb3">
              Once it&apos;s finished we&apos;ll send a copy to your email address. It should arrive in the next few
              minutes.
            </p>
            <Button outline success onClick={() => this.closeModal()}>
              Close
            </Button>
          </Banner>
        ) : (
          <React.Fragment>
            <div className="pb3">
              <Checkbox
                label="Raw study data"
                sublabel="An export of all animals and their measurements."
                id={this.exportTypes[0]}
                name={this.exportTypes[0]}
                onChange={this.handleCheckbox}
                checked={this.state.fields.master}
              />
              {hasGroups && (
                <Checkbox
                  label="Study group report"
                  sublabel="A comprehensive export of study groups including relative values."
                  id={this.exportTypes[1]}
                  name={this.exportTypes[1]}
                  onChange={this.handleCheckbox}
                  className="dib v-mid mt3"
                  checked={this.state.fields.summary}
                />
              )}
              {hasGroups && this.state.hasObservations && (
                <Checkbox
                  label="Observation Study Data"
                  sublabel="A comprehensive export of observations by study group."
                  id={this.exportTypes[2]}
                  name={this.exportTypes[2]}
                  onChange={this.handleCheckbox}
                  className="dib v-mid mt3"
                  checked={this.state.fields.observations}
                />
              )}
              {hasGroups && (
                <Checkbox
                  label="Prism report"
                  sublabel="A comprehensive export of study groups including relative values."
                  id={this.exportTypes[3]}
                  name={this.exportTypes[3]}
                  onChange={this.handleCheckbox}
                  className="dib v-mid mt3"
                  checked={this.state.fields.prism}
                />
              )}
              {
                <Checkbox
                  label="Study design"
                  sublabel="An export of study settings including study groups and study metadata."
                  id={this.exportTypes[4]}
                  name={this.exportTypes[4]}
                  onChange={this.handleCheckbox}
                  className="dib v-mid mt3"
                  checked={this.state.fields.design}
                />
              }
            </div>
            <div>
              <button disabled={disabled} onClick={this.downloadExport} className="button w-100 mb2">
                Download
              </button>
              <a className="button plain w-100 mb4" onClick={() => this.closeModal()}>
                Cancel
              </a>
              <button disabled={disabled} onClick={this.emailExport} className="stateless blue w-100 f5 mb3">
                Send to your email address
              </button>
            </div>
          </React.Fragment>
        )}
      </form>
    );
  }

  /**
   * Methods
   */
  validate = () => {
    return (
      this.state.fields.master ||
      this.state.fields.summary ||
      this.state.fields.observations ||
      this.state.fields.prism ||
      this.state.fields.design
    );
  };
  generateTypes = () => {
    const types = {
      type: [],
    };
    if (this.state.fields.master) {
      types.type.push('master');
    }
    if (this.state.fields.summary) {
      types.type.push('summary');
    }
    if (this.state.fields.observations) {
      types.type.push('observations');
    }
    if (this.state.fields.prism) {
      types.type.push('prism');
    }
    if (this.state.fields.design) {
      types.type.push('design');
    }
    return types;
  };
  emailExport = (event) => {
    event.preventDefault();

    if (this.validate()) {
      const types = this.generateTypes();
      this.setState({ isLoading: true });

      Http({
        method: 'post',
        url: apiRoute('studies.export.create.email', {
          id: this.props.studyId,
        }),
        params: types,
        data: this.state.fields,
      })
        .then(({ data }) => {
          successToast(`Successfully exported ${this.props.studyName}.`);
        })
        .catch(() => {
          errorToast('Error exporting! Please try again.');
        })
        .finally(() => this.closeModal());
    }
  };
  downloadExport = (event) => {
    event.preventDefault();

    if (this.validate()) {
      const types = this.generateTypes();
      this.setState({ isLoading: true });

      Http({
        method: 'post',
        url: apiRoute('studies.export.create.download', {
          id: this.props.studyId,
        }),
        params: types,
        data: this.state.fields,
        responseType: 'blob',
      })
        .then(({ data, headers }) => {
          const fileName = headers['file-name'];
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          successToast(`Successfully exported ${this.props.studyName}.`);
          this.closeModal();
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            this.setState({ exportTooLarge: true });
          } else {
            this.setState({ submissionError: true });
          }
        });
    }
  };
}

export default ExportStudy;
