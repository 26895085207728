// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import Banner from '../Banner';

const DragHint = () => (
  <Banner info>
    <div className="f6 pt2 near-black lh-solid">
      <span>Click or drag the</span> <Icon icon="drag" className="dib v-mid" width="20" height="20" />{' '}
      <span>icon to reorder</span>
    </div>
  </Banner>
);

export default DragHint;
