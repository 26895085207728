// @ts-nocheck: converted from JS

import { _chunk } from '@/littledash';
import { ID } from '@/model/Common.model.ts';
import { pad } from '@/helpers.tsx';

export const SEQUENTIAL = 'SEQUENTIAL';
export const REPEATING = 'REPEATING';
export const CAGE_NUMBER = 'CAGE_NUMBER';
export const GROUP_NUMBER = 'GROUP_NUMBER';

export const ID_METHODS = [
  {
    value: SEQUENTIAL,
    label: 'Sequential',
  },
  {
    value: REPEATING,
    label: 'Repeating',
  },
];

export const CAGE_NAMING_METHODS = [
  {
    value: CAGE_NUMBER,
    label: 'Cage number',
  },
  {
    value: GROUP_NUMBER,
    label: 'Group number',
  },
];

export const groupAnimalsByTreatmentGroup = (accumulator, subject) => {
  const foundIndex = accumulator.findIndex(({ group }) => group.id === subject.study_group.id);
  if (foundIndex !== -1) {
    accumulator[foundIndex].animals.push({ ...subject, newAnimalId: '' });
    return accumulator;
  }
  accumulator.push({
    group: subject.study_group,
    animals: [{ ...subject, newAnimalId: '' }],
  });
  return accumulator;
};

export const groupAnimalsWithoutGroups = (accumulator, subject) => {
  const groupId = -1;
  const groupName = 'Not assigned to group';

  const foundIndex = accumulator.findIndex(({ group }) => group.id === groupId);
  if (foundIndex !== -1) {
    accumulator[foundIndex].animals.push({ ...subject, newAnimalId: '' });
  } else {
    accumulator.push({
      group: { id: groupId, name: groupName },
      animals: [{ ...subject, newAnimalId: '' }],
    });
  }

  return accumulator;
};

export const groupAnimalsByCollectionId = (accumulator, subject) => {
  const foundIndex = accumulator.findIndex(({ collection_id }) => collection_id === subject.cage?.id);
  if (foundIndex !== -1) {
    accumulator[foundIndex].animals.push(subject);
    return accumulator;
  }
  accumulator.push({
    collection_id: subject.cage?.id,
    collection: subject.cage?.name,
    animals: [subject],
  });
  return accumulator;
};

export const sortAnimalsByFrequency = (a, b) => b.animals.length - a.animals.length;

export const sortAnimalsByNumber = (a, b) => a.number - b.number;

export const spreadAnimals = (accumulator, group) => {
  accumulator.push(...group.animals);
  return accumulator;
};

export const chunkGroups = (groups, chunkSize) => _chunk(groups, chunkSize);

export const groupAndSortAnimals = (subjects) => {
  const withGroups = subjects
    .filter((subject) => subject.study_group && subject.study_group.id)
    .reduce(groupAnimalsByTreatmentGroup, [])
    .sort((a, b) => a.group.id - b.group.id)
    .map((group) => ({
      ...group,
      animals: group.animals
        .reduce(groupAnimalsByCollectionId, [])
        .sort(sortAnimalsByFrequency)
        .reduce(spreadAnimals, [])
        .sort(sortAnimalsByNumber),
    }));

  const withoutGroups = subjects
    .filter((subject) => !subject.study_group || !subject.study_group.id)
    .reduce(groupAnimalsWithoutGroups, [])
    .map((group) => ({
      ...group,
      animals: group.animals
        .reduce(groupAnimalsByCollectionId, [])
        .sort(sortAnimalsByFrequency)
        .reduce(spreadAnimals, [])
        .sort(sortAnimalsByNumber),
    }));

  return [...withGroups, ...withoutGroups];
};

export const generateRepeatingIds = (proposedCages, ids: Array<string>) => {
  const cages = proposedCages ? [...proposedCages] : [];
  const cageCountRecord: { [key: ID]: number } = {};

  for (let cageIndex = 0; cageIndex < cages.length; cageIndex++) {
    const animals = cages[cageIndex].animals;
    for (let index = 0; index < animals?.length; index++) {
      const cageId = cages[cageIndex].id;

      if (!cageId) {
        continue;
      }

      if (!cageCountRecord[cageId]) {
        cageCountRecord[cageId] = 0;
      }

      if (ids[cageCountRecord[cageId]]) {
        cages[cageIndex].animals[index].newAnimalId = ids[cageCountRecord[cageId]];
      }
      cageCountRecord[cageId]++;
    }
  }
  return cages;
};

export const generateSequentialIds = (proposedCages, sequencePrefix: string, sequenceStarting: string) => {
  let index = 0;
  const cages = proposedCages ? [...proposedCages] : [];

  return cages.map((cage) => {
    return {
      ...cage,
      animals: cage.animals
        ? cage.animals?.map((animal) => ({
            ...animal,
            newAnimalId: `${sequencePrefix}${pad(Number(sequenceStarting) + index++, String(sequenceStarting).length)}`,
          }))
        : [],
    };
  });
};

export const getHighestCageGroupName = (groupNumber, studyCageNames) => {
  const groupNameMatches = studyCageNames.filter(
    (name) =>
      name.match(new RegExp(`^${groupNumber}([A-Z])$`)) || name.match(new RegExp(`^${groupNumber}([A-Z][A-Z])$`))
  );

  if (groupNameMatches.length) {
    groupNameMatches.map((name) => name.replace(/^\d+/, ''));

    // Return the highest group name
    return groupNameMatches
      .sort((a, b) => {
        // Compare by length first (AA > Z)
        if (a.length !== b.length) {
          return a.length - b.length;
        }

        // If lengths are equal, compare alphabetically
        return a.localeCompare(b);
      })
      .reverse()[0]
      .replace(/^\d+/, '');
  }

  return null;
};

export const determineNextCageGroupName = (highestGroupName) => {
  if (highestGroupName === null) {
    return 'A';
  }

  if (highestGroupName.length === 1) {
    if (highestGroupName === 'Z') {
      return 'AA';
    }
    return String.fromCharCode(highestGroupName.charCodeAt(0) + 1);
  }

  if (highestGroupName.length === 2) {
    if (highestGroupName[1] === 'Z') {
      return `${String.fromCharCode(highestGroupName.charCodeAt(0) + 1)}A`;
    }
    return `${highestGroupName[0]}${String.fromCharCode(highestGroupName.charCodeAt(1) + 1)}`;
  }
};
