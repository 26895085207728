// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Pagination from '@/components/UI/Pagination';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import TableShowMenu from '@/components/UI/SelectDropDown/Menus/TableShowMenu';
import Table from '@/components/UI/Table';
import { _isEmpty } from '@/littledash';

const LinksTable = ({
  readOnly = false,
  state,
  dispatch,
  addLink,
  singleRowActions,
  reduxDispatch,
  settings,
  loading,
}) => {
  const { links, searchQuery, total_pages, current_page, per_page, order, sort, columns, selectedRows } = state;
  const pagingProps = { total_pages, current_page, per_page };

  return (
    <>
      <div className="flex pa3 bb b--moon-gray">
        <div className="flex w-100">
          <div className="flex mr2">
            {!_isEmpty(selectedRows) && (
              <div
                className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
                style={{
                  whiteSpace: 'nowrap',
                  lineHeight: '2.4rem',
                }}
              >
                {Object.keys(selectedRows).length} selected
              </div>
            )}
            <SelectDropDown
              title="Actions"
              className={`plain f6 ${!_isEmpty(selectedRows) ? 'br--right' : ''}`}
              alignMenu="right"
              disabled={_isEmpty(selectedRows) || Object.keys(selectedRows).length > 1}
              tooltip={Object.keys(selectedRows).length > 1 ? 'Select one link at a time' : undefined}
            >
              <ActionList actions={singleRowActions} />
            </SelectDropDown>
          </div>
          <SearchFilterBar
            canSearch={false}
            className="flex flex-grow-1"
            searchPlaceholderText="Search by file name or type"
            handleSearchInput={(data) => dispatch({ type: 'SET_SEARCH_QUERY', data })}
            searchQuery={searchQuery}
            ShowMenu={() => (
              <TableShowMenu
                columns={columns}
                handleChange={(data) => {
                  const newColumns = data.reduce((acc, column) => {
                    return { ...acc, [column.id]: column.isVisible };
                  }, {});
                  const newSettings = {
                    ...settings,
                    tables: {
                      ...settings.tables,
                      links: { ...settings.tables.links, columns: newColumns },
                    },
                  };
                  reduxDispatch({ type: 'SAVE_SETTING', setting: newSettings });

                  dispatch({ type: 'SET_COLUMNS', data });
                }}
              />
            )}
          />
          {!readOnly && <Button onClick={addLink}>Add a link</Button>}
        </div>
      </div>
      {loading ? (
        <div className="flex flex-column min-h5 justify-center items-center bg-white">
          <Loading />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            data={links}
            className="overflow-visible"
            manualSort={{ sort, order }}
            noDataMessage="No links have been added"
            setManualSort={(data) => dispatch({ type: 'SET_SORT', data })}
            selectedRows={selectedRows}
            setSelectedRows={(data) => dispatch({ type: 'SET_SELECTED', data })}
          />
          <Pagination {...pagingProps} handleChange={(data) => dispatch({ type: 'SET_PAGING', data })} />
        </>
      )}
    </>
  );
};

export default LinksTable;
