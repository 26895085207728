import Loading from '@/components/Loading';
import { ControlGroupSelect } from '@/components/Studies/StudyGroups/Groups/ControlGroupSelect';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import Radio from '@/components/UI/FormElements/Radio';
import { successToast } from '@/helpers';
import { _isEmpty, _isNil, _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError';
import { GraphSetting } from '@/model/Preset.model';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import type { GroupApiId, TreatmentGroup } from '@/model/TreatmentGroup.model';
import { ApiService } from '@/support/ApiService';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { isCancel } from 'axios';
import { type FC, useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export interface ConfigurationProps {
  groups: Array<TreatmentGroup>;
  changeControlGroup: (id?: GroupApiId | string) => Promise<void>;
  canEdit: boolean;
}

const Configuration: FC<ConfigurationProps> = ({ canEdit = true, groups, changeControlGroup }) => {
  const { id: studyId } = useParams<{ id: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
  });

  if (studyLoading || _isNil(study)) {
    return (
      <div className="mv4 pa4">
        <Loading />
      </div>
    );
  }

  return <ConfigurationForm study={study} groups={groups} changeControlGroup={changeControlGroup} canEdit={canEdit} />;
};

interface ConfigurationFormProps {
  study: Study;
  groups: Array<TreatmentGroup>;
  changeControlGroup: (id?: GroupApiId | string) => Promise<void>;
  canEdit: boolean;
}

type FormData = {
  averageCalculation: Study['average_calculation'];
  errorDeviation: Study['error_deviation'];
};
export const ConfigurationForm: FC<ConfigurationFormProps> = ({
  study: {
    id: studyId,
    api_id: studyApiId,
    average_calculation: averageCalculation,
    error_deviation: errorDeviation,
    settings: { graph_settings: graphSettings, exclude_before_tracking_date: excludeBeforeTrackingDate },
  },
  groups,
  changeControlGroup,
  canEdit = true,
}) => {
  const [updatingGroupConfig, setUpdatingGroupConfig] = useState(false);
  const [defaultValues, updateDefaultValues] = useState({
    averageCalculation,
    errorDeviation,
    graphSettings,
    excludeBeforeTrackingDate,
  });
  const [excludeBeforeTrackingCheckboxDisabled, setExcludeBeforeTrackingCheckboxDisabled] = useState(
    graphSettings === 'tracking_date'
  );
  const dataAnalysisEnabled = useSelector<State>((state) => state?.team?.features?.data_analysis ?? false);
  const { sendRequest: updateStudy } = useRequest({
    route: 'studies.update',
    method: 'patch',
    params: { id: studyId },
  });
  const { control, handleSubmit, watch, setValue, getValues } = useForm<FormData>({ defaultValues });
  const currentFormData = watch();
  const onSubmit = async ({ averageCalculation, errorDeviation }: FormData) => {
    setUpdatingGroupConfig(true);
    await updateStudy({
      average_calculation: averageCalculation,
      error_deviation: errorDeviation,
    });
    updateDefaultValues({ averageCalculation, errorDeviation, graphSettings, excludeBeforeTrackingDate });
    setUpdatingGroupConfig(false);
    successToast('Saved group settings.');
  };

  const handleGraphSettingsChange = async (value?: GraphSetting) => {
    if (_notNil(studyApiId)) {
      try {
        const updatedExcludeBeforeTrackingDateValue =
          value === 'tracking_date' ? false : getValues('excludeBeforeTrackingDate');
        const response = await ApiService.call({
          endpoint: 'PATCH /api/v1/studies/{studyApiId}/settings',
          path: { studyApiId },
          body: { graph_settings: value, exclude_before_tracking_date: updatedExcludeBeforeTrackingDateValue },
        });
        if (response.body?.success === true) {
          updateDefaultValues({
            averageCalculation,
            errorDeviation,
            graphSettings: value,
            excludeBeforeTrackingDate: updatedExcludeBeforeTrackingDateValue,
          });
          setValue('graphSettings', value);
          setValue('excludeBeforeTrackingDate', updatedExcludeBeforeTrackingDateValue);
          setExcludeBeforeTrackingCheckboxDisabled(value === 'tracking_date');
        }
      } catch (e) {
        if (!isCancel(e)) {
          ExceptionHandler.captureException(
            new InVivoError('Could not update study settings', { cause: e, slug: 'graph-settings-update' })
          );
        }
      }
    }
  };

  const handleExcludeBeforeTrackingDateChange = (value?: boolean) => {
    if (value != getValues('excludeBeforeTrackingDate')) {
      setValue('excludeBeforeTrackingDate', value);
      handleGraphSettingsChange(getValues('graphSettings'));
    }
  };

  useEffect(() => {
    if (
      !updatingGroupConfig &&
      (currentFormData.averageCalculation !== defaultValues.averageCalculation ||
        currentFormData.errorDeviation !== defaultValues.errorDeviation)
    ) {
      onSubmit(currentFormData);
    }
  }, [currentFormData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pv4 flex flex-wrap">
      <div className="ph4 w-28 mb4">
        <label className="mb3 db">How would you like to calculate averages?</label>
        <div>
          <Controller
            control={control}
            name="averageCalculation"
            defaultValue={averageCalculation}
            render={({ onChange, value }: ControllerRenderProps) => (
              <>
                <Radio
                  id="arithmetic"
                  name="average_calculation"
                  checked={value === 'arithmetic'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="arithmetic"
                  label="Arithmetic"
                  onChange={onChange}
                  className="mb2"
                />
                <Radio
                  id="geometric"
                  name="average_calculation"
                  checked={value === 'geometric'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="geometric"
                  label="Geometric"
                  onChange={onChange}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="ph4 w-28 mb4">
        <label className="mb3 db">How would you like to display variation?</label>
        <div>
          <Controller
            control={control}
            name="errorDeviation"
            defaultValue={errorDeviation}
            render={({ onChange, value }: ControllerRenderProps) => (
              <>
                <Radio
                  id="sd"
                  name="error_deviation"
                  checked={value === 'sd'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="sd"
                  label="Standard deviation"
                  onChange={onChange}
                  className="mb2"
                />
                <Radio
                  id="sem"
                  name="error_deviation"
                  checked={value === 'sem'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="sem"
                  label="Standard error of the mean"
                  onChange={onChange}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="ph4 w-28 mb4">
        <label className="mb3 db">Days Plotted</label>
        <div data-testid="group-config-days-plotted">
          <Controller
            control={control}
            name="graphSettings"
            defaultValue={graphSettings}
            render={({ value }: ControllerRenderProps) => (
              <>
                <Radio
                  id="td"
                  name="graph_settings"
                  checked={value === 'tracking_date'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="tracking_date"
                  label="Post tracking date"
                  onChange={(e) => handleGraphSettingsChange(e.target.value as GraphSetting)}
                  className="mb2"
                />
                <Radio
                  id="di"
                  name="graph_settings"
                  checked={value === 'disease_induction'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="disease_induction"
                  label="Post disease induction date"
                  onChange={(e) => handleGraphSettingsChange(e.target.value as GraphSetting)}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="excludeBeforeTrackingDate"
            defaultValue={false}
            render={({ value }: ControllerRenderProps) => (
              <Checkbox
                id="ebtd"
                name="exclude_before_tracking_date"
                checked={value}
                disabled={excludeBeforeTrackingCheckboxDisabled}
                label="Exclude data before tracking date"
                onChange={(e) => {
                  handleExcludeBeforeTrackingDateChange(e.target.checked);
                  setExcludeBeforeTrackingCheckboxDisabled(graphSettings === 'tracking_date');
                }}
                className="mt2 ml4"
              />
            )}
          />
        </div>
      </div>
      {dataAnalysisEnabled ? (
        <div className="ph4 w-28">
          <label className="mb3 db">Control group</label>
          <ControlGroupSelect
            disabled={!canEdit || _isEmpty(groups)}
            groups={groups ?? []}
            onChange={changeControlGroup}
          />
        </div>
      ) : null}
    </form>
  );
};

export default Configuration;
