import {
  FlowControlTypeOptions,
  ParityTypeOptions,
  ReadingTypeOptions,
  TerminationCharacters,
} from '@/model/Device.model';

export const parityOptions = (Object.keys(ParityTypeOptions) as Array<keyof typeof ParityTypeOptions>).map((key) => ({
  label: key,
  value: ParityTypeOptions[key],
}));

export const flowControlOptions = (
  Object.keys(FlowControlTypeOptions) as Array<keyof typeof FlowControlTypeOptions>
).map((key) => ({
  label: key,
  value: FlowControlTypeOptions[key],
}));

export const readingTypeOptions = (Object.keys(ReadingTypeOptions) as Array<keyof typeof ReadingTypeOptions>).map(
  (key) => ({
    label: key,
    value: ReadingTypeOptions[key],
  })
);

export const terminationCharacterOptions = (
  Object.keys(TerminationCharacters) as Array<keyof typeof TerminationCharacters>
).map((key) => ({
  label: key,
  value: TerminationCharacters[key],
}));
