// @ts-nocheck: converted from JS

import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import '../CageCardTemplate.scss';
import { useMemo } from 'react';

const Animals = ({ animals }) => {
  const MAX_NUMBER_ANIMALS = 7;
  const cappedAnimals = useMemo(() => animals.slice(0, MAX_NUMBER_ANIMALS), [animals, MAX_NUMBER_ANIMALS]);
  return (
    <table className="w-100 tl" style={{ borderSpacing: 0, fontSize: 11 }}>
      <thead className="bg-black white">
        <tr className="card-row">
          <th>ID</th>
          <th>DOB</th>
          <th>Sex</th>
          <th>Strain</th>
        </tr>
      </thead>
      <tbody className="black">
        {cappedAnimals.map((animal) => (
          <tr className={`card-row max-${MAX_NUMBER_ANIMALS}`} key={animal.id}>
            <td>{animal.id}</td>
            <td>
              <DateRenderer value={animal.dob} />
            </td>
            <td>{animal.sex}</td>
            <td></td>
            {/* <td>{animal.strain}</td> TODO get strain for printing cards*/}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Animals;
