import { uuid } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { AxiosResponse } from 'axios';
import { Reducer, useReducer, useState } from 'react';
import { notAborted, useAbortController } from '../fetch/useAbortController';

interface RequestIdsSendingState {
  requestIds: Record<string, null>;
  requestSending: boolean;
}

type RequestIdsSendingAction = { type: 'new' | 'completed'; requestId: string };
type RequestIdsSendingReducer = Reducer<RequestIdsSendingState, RequestIdsSendingAction>;

const requestIdsSendingReducer: RequestIdsSendingReducer = (state, action) => {
  switch (action.type) {
    case 'new': {
      const requestIds = { ...state.requestIds, [action.requestId]: null };
      const requestSending = Object.keys(requestIds).length > 0;
      return { requestIds, requestSending };
    }
    case 'completed': {
      const { [action.requestId]: _, ...requestIds } = state.requestIds;
      const requestSending = Object.keys(requestIds).length > 0;
      return { requestIds, requestSending };
    }
  }
  return state;
};

interface UseRequestProps {
  route: string;
  method: string;
  params?: Record<string, any>;
  config?: { useAbortSignal?: boolean };
}

interface UseRequestResponse<Req = unknown, Resp = unknown> {
  sendRequest: (body: Req, signal?: AbortSignal) => Promise<AxiosResponse<Resp>>;
  requestSending: boolean;
  requestError: false | Error;
}

const useRequest = <Req = unknown, Resp = unknown>({
  route,
  method = 'post',
  params = {},
  config = {},
}: UseRequestProps): UseRequestResponse<Req, Resp> => {
  const [{ requestSending }, requestIdsSendingDispatch] = useReducer<RequestIdsSendingReducer>(
    requestIdsSendingReducer,
    {
      requestIds: {},
      requestSending: false,
    }
  );
  const [requestError, setRequestError] = useState<false | Error>(false);
  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();

  const newRequest = () => {
    const requestId = uuid();
    requestIdsSendingDispatch({ type: 'new', requestId });
    return requestId;
  };
  const completeRequest = (requestId: string) => requestIdsSendingDispatch({ type: 'completed', requestId });

  /**
   * @param body {any}
   * @returns {Promise<Response>}
   */
  const sendRequest: UseRequestResponse<Req, Resp>['sendRequest'] = async (body, signal) => {
    const requestId = newRequest();

    try {
      const res = await Http.request({
        method,
        url: apiRoute(route, params),
        data: body,
        signal: (signal ?? config?.useAbortSignal ?? false) ? newAbortController().signal : undefined,
      });
      if (isMounted()) {
        completeRequest(requestId);
      }
      return res;
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setRequestError(error as Error);
        ExceptionHandler.captureException(
          new InVivoError(`Api Error (Request): ${route}`, {
            cause: error,
            slug: 'use-request',
          })
        );
        completeRequest(requestId);
      }
      throw new Error('Could not send request', { cause: error });
    }
  };

  return {
    sendRequest,
    requestSending,
    requestError,
  };
};

export default useRequest;
