import styles from '@/components/Studies/Groups/Analysis/Analysis.module.scss';
import type { AnalysisIssue as IAnalysisIssue } from '@/model/Analysis.model';
import Link from '@/components/UI/Link';
import type { components } from '@/generated/internal-api/openapi-types';
import type { StudyApiId } from '@/model/Study.model';
import type { FC } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import { web } from '@/support/route';

type AnalysisIssueType = components['schemas']['AnalysisIssueType.schema'];

interface AnalysisIssueListProps {
  studyApiId: StudyApiId;
  issues: Array<IAnalysisIssue>;
}

const AnalysisIssueBody: FC<{ studyApiId: StudyApiId; type: AnalysisIssueType }> = ({ studyApiId, type }) => {
  switch (type) {
    case 'AnalysisNotConfigured':
      return (
        <div>
          <div className="pb2">
            <span className="near-black fw6 pr1">Analysis</span>
            <span>has not been created</span>
          </div>
          <a className="link dark-blue" href="mailto:support@benchling.com">
            Contact Support
          </a>
        </div>
      );
    case 'ControlGroupNotSet':
      return (
        <div>
          <div className="pb2">
            <span className="near-black fw6 pr1">Control group</span>
            <span>has not been set</span>
          </div>
          <Link to={web('studies.studyGroups', { id: studyApiId })} target="_blank" className="link dark-blue">
            Go to treatment groups
          </Link>
        </div>
      );
    case 'NoGroupsCreated':
      return (
        <div>
          <div className="near-black pb2">Groups has not been created</div>
          <Link to={web('studies.studyGroups', { id: studyApiId })} target="_blank" className="link dark-blue">
            Go to treatment groups
          </Link>
        </div>
      );
    case 'NoAnimalsAddedToGroups':
      return (
        <div>
          <div className="near-black pb2">Animals have not been added to groups</div>
          <Link to={web('studies.animals', { id: studyApiId })} target="_blank" className="link dark-blue">
            Go to animals
          </Link>
        </div>
      );
    case 'MissingTrackingDate':
      return (
        <div>
          <div className="near-black pb2">Animals are missing tracking dates</div>
          <Link to={web('studies.animals', { id: studyApiId })} target="_blank" className="link dark-blue">
            Go to animals
          </Link>
        </div>
      );
    case 'MissingDiseaseInductionDate':
      return (
        <div>
          <div className="near-black">Animals are missing disease induction dates</div>
          <Link to={web('studies.animals', { id: studyApiId })} target="_blank" className="link dark-blue">
            Go to animals
          </Link>
        </div>
      );
  }
};
const AnalysisIssue: FC<{ studyApiId: StudyApiId; issue: IAnalysisIssue }> = ({ studyApiId, issue }) => {
  return (
    <div className="bg-near-white pa2 mb2">
      <div className="flex pv2">
        <div className="pr2 pl1">
          <RiAlertFill
            className={issue.level === 'Error' ? styles.errorColor : styles.waringColor}
            style={{ width: '1.3rem', height: '1.3rem' }}
          />
        </div>
        <AnalysisIssueBody studyApiId={studyApiId} type={issue.type} />
      </div>
    </div>
  );
};

export const AnalysisIssueList: FC<AnalysisIssueListProps> = ({ studyApiId, issues }) => {
  return (
    <div>
      {issues.map((issue) => (
        <AnalysisIssue key={issue.type} studyApiId={studyApiId} issue={issue} />
      ))}
    </div>
  );
};
