// @ts-nocheck: converted from JS

// credit - https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
export function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const round =
  (decimalPlaces: number) =>
  (value): number =>
    value !== '' ? parseFloat((Math.round(value * 100) / 100).toFixed(decimalPlaces)) : '';

export const getRandomNumberAsString = (min: number, max: number, decimalPlaces: number): string => {
  return round(2)(Math.random() * (max - min) + min);
};
