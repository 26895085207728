import Loading from '@/components/Loading';
import { getCalculationsAndMeasurements } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { Animal as AnimalModel } from '@/model/Animal.model';
import type { Study as StudyModel } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { Alert, AlertSettings, AlertUpdateEventHandler } from '@/utils/alerts/Alert';
import React, { useEffect, useMemo } from 'react';
import NoDataCard from '../../../NoDataCard';
import type { AnimalAlertAction } from '../Show.utils';

interface AlertsProps {
  animal: AnimalModel;
  study: StudyModel;
  dispatch: React.Dispatch<AnimalAlertAction>;
}

interface SortedAlerts {
  resolvedAlerts: Array<AnimalAlertV1>;
  unresolvedAlerts: Array<AnimalAlertV1>;
}

export const Alerts: React.FC<AlertsProps> = ({ study, animal, dispatch }) => {
  const isWriteUser = Auth.isWriteUserForStudy(study);

  const alertSettings: AlertSettings = {
    displayDeferControl: false,
    displayCreatedAt: false,
    displayHeader: false,
    displayTriggeredBy: true,
    displayAlertState: false,
    displayResolvedTick: false,
    displayResolveControl: isWriteUser,
  };

  const {
    collection: alerts,
    collectionLoading: alertsLoading,
    fetchCollection: reloadAlerts,
  } = useFetchCollection<AnimalAlertV1>({
    collectionType: 'animalAlerts',
    params: {
      studyId: study.id,
      id: animal.id,
    },
  });

  useEffect(() => {
    if (_isNotEmpty(alerts)) {
      dispatch({ type: 'SET_ALERTS', data: alerts });
    }
  }, [alerts]);

  const updateEventHandler: AlertUpdateEventHandler = async ({ type }) => {
    if (type === 'resolved' || type === 'deferred') {
      reloadAlerts();
    }
  };

  const { resolvedAlerts, unresolvedAlerts } = useMemo<SortedAlerts>(
    () =>
      (alerts ?? []).reduce<SortedAlerts>(
        ({ resolvedAlerts, unresolvedAlerts }, alert) =>
          _notNil(alert.resolved_at)
            ? { unresolvedAlerts, resolvedAlerts: [...resolvedAlerts, alert] }
            : { resolvedAlerts, unresolvedAlerts: [...unresolvedAlerts, alert] },
        { resolvedAlerts: [], unresolvedAlerts: [] }
      ),
    [alerts]
  );
  const calculationMap = useMemo(
    () => getCalculationsAndMeasurements(study?.settings?.calculations),
    [study?.settings?.calculations]
  );

  if (alertsLoading) {
    return <Loading />;
  }
  if (_isEmpty(alerts)) {
    return (
      <div className="ui-card">
        <div className="ma4">
          <NoDataCard className="tc" title="No alerts to display" dark />
        </div>
      </div>
    );
  }
  return (
    <div>
      {_isNotEmpty(unresolvedAlerts) && (
        <div className="ph4 pv3 bg-white br2 ba b--moon-gray mb4">
          <h3 className="mt3 mb1">Unresolved</h3>
          <div className="flex flex-column">
            {unresolvedAlerts.map((alert) => (
              <Alert
                key={alert.id}
                alert={alert}
                studyId={study.id}
                calculation={calculationMap.get(alert.calculation)}
                settings={alertSettings}
                onUpdate={updateEventHandler}
              />
            ))}
          </div>
        </div>
      )}

      {_isNotEmpty(resolvedAlerts) && (
        <div className="ph4 pv3 bg-white br2 ba b--moon-gray mb4">
          <h3 className="mt3 mb1">Resolved</h3>
          {resolvedAlerts.map((alert) => (
            <Alert
              key={alert.id}
              alert={alert}
              studyId={study.id}
              calculation={calculationMap.get(alert.calculation)}
              animalName={animal.name}
              cageName={animal?.cage?.name}
              settings={alertSettings}
              onUpdate={updateEventHandler}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Alerts;
