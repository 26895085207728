import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import type { StudyDraft } from '@/model/Draft.model';
import Http from '@/support/http';
import { api as apiRoutes, web as webRoutes } from '@/support/route';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const columns: Array<Column<StudyDraft>> = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    sortBy: 'name',
    isVisible: true,
    Cell: ({
      row: {
        original: { config, id, name },
      },
    }) => (
      <Link
        className="link"
        to={webRoutes('studies.new.draft', {
          formId: config.form_id || '',
          draftId: id,
        })}
      >
        {name}
      </Link>
    ),
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    sortBy: 'created_at',
    isVisible: true,
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
  },
  {
    id: 'updated_at',
    Header: 'Updated',
    accessor: 'updated_at',
    sortBy: 'updated_at',
    isVisible: true,
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.updated_at} />,
  },
];

const DraftsTable: React.FC = () => {
  const dispatch = useDispatch();

  const handleDraftsDelete = async ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { fetchTableData, setUpdating, setApiError },
    },
  }: UseTableProps<StudyDraft>) => {
    setUpdating(true);
    const draftIDs = selectedFlatRows.map(({ original: { id } }) => id);
    try {
      await Http.delete(apiRoutes('drafts.studies.bulkDelete'), {
        data: { drafts_ids: draftIDs },
      });
      await fetchTableData();
      toast.success(`Deleted ${draftIDs.length} draft${draftIDs.length !== 1 ? 's' : ''}`);
    } catch (error) {
      setApiError(error as Error);
    } finally {
      setUpdating(false);
    }
  };

  const bulkActions = (props: UseTableProps<StudyDraft>) => [
    [
      {
        name: 'Delete',
        className: 'red',
        action: () => {
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'CONFIRM_DELETE_STUDY_FORM_SECTION',
            props: {
              onClick: () => handleDraftsDelete(props),
            },
          });
        },
      },
    ],
  ];

  return (
    <div className="pa4">
      <APITable
        columns={columns}
        apiInfo={{ type: 'legacyInternalApi', route: apiRoutes('drafts.studies') }}
        bulkActions={bulkActions}
        searchPlaceholderText="Search by study name"
      />
    </div>
  );
};

export default DraftsTable;
