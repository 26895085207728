import { Select } from '@/components/UI/FormFields/Latest';
import TagInput from '@/components/UI/TagInput/TagInput';
import { ErrorMessage } from '@hookform/error-message';
import type { TaskScheduleConditionalType } from 'model/Task.model';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { StepFormPayloadProps, StepRenderProps } from '../../AddTask.model';
import {
  conditionalAnimalAgeValidator,
  conditionalStudyDayValidator,
  conditionalTrackingDayValidator,
  conditionalValueValidator,
} from './AddTaskScheduleForm.utils';

type ConditionalFieldsProps = Pick<StepRenderProps, 'disabledFields'>;

export const conditionTypeOptions: Array<{
  label: string;
  value: TaskScheduleConditionalType;
}> = [
  { label: 'Age (days)', value: 'age' },
  { label: 'Study day', value: 'study_day' },
  { label: 'Tracking day', value: 'tracking_day' },
];

const ConditionalFields = ({ disabledFields }: ConditionalFieldsProps): JSX.Element => {
  const { control, formState } = useFormContext<StepFormPayloadProps>();
  const type = useWatch({ control, name: 'schedule.condition.type' });

  return (
    <div className="ml4 mt3">
      <div
        className="flex items-center mb2"
        data-test-component="ConditionalScheduleForm"
        data-test-element="condition-type"
      >
        <Select
          options={conditionTypeOptions}
          name="schedule.condition.type"
          required={true}
          disabled={disabledFields?.has('schedule.condition.type') ?? false}
        />
        <span className="f6 near-black lh-solid dib ml3">is</span>
      </div>
      <div className="mw6 mb2" data-test-component="ConditionalScheduleForm" data-test-element="condition-value">
        <Controller
          control={control}
          name="schedule.condition.value"
          defaultValue={[]}
          rules={{
            validate: {
              isEmpty: conditionalValueValidator,
              typeValueValiadtor: (value: Array<number>) => {
                switch (type) {
                  case 'age':
                    return conditionalAnimalAgeValidator(value);
                  case 'study_day':
                    return conditionalStudyDayValidator(value);
                  case 'tracking_day':
                    return conditionalTrackingDayValidator(value);
                  default:
                    return 'No valid type set';
                }
              },
            },
          }}
          render={({ field }) => (
            <TagInput
              handleSearchInput={(tags) => field.onChange(tags?.map(Number) ?? [])}
              searchQuery={field.value?.map((v) => `${v}`)}
            />
          )}
        />
        <p className="mt2 f7 near-black">Separate values with commas, Tab key or Enter key</p>
        <ErrorMessage
          errors={formState.errors}
          name="schedule.condition.value"
          render={({ message }) => <p className="f7 red db pv2">{message}</p>}
        />
      </div>
    </div>
  );
};

export default ConditionalFields;
