import {
  InvalidGroupsIssue,
  InvalidMeasurementsIssue,
  InvalidTreatmentsIssue,
  InvalidUsersIssue,
  TaskSpecIssueAction,
  TaskSpecV2Issue,
  TaskSpecWrapper,
} from '@/components/Studies/Create/Steps/Tasks/TasksV2.util.ts';
import Select from '@/components/UI/Select';
import { errorToast } from '@/helpers.tsx';
import { _isNotEmpty } from '@/littledash.ts';
import { State } from '@/model/State.model.ts';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import { FC, useEffect } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import styles from './TaskSpecIssuesModal.module.scss';

interface TaskSpecIssuesModalProps {
  issues: Map<TaskSpecWrapper['id'], Array<TaskSpecV2Issue>>;
  taskSpecTitleMap: Map<string, string>;
  onAction: (action: TaskSpecIssueAction) => void;
}

type IssueActionOption = { value: string; label: string };

const groupIssueActions: Array<IssueActionOption> = [
  { value: 'remove-invalid-groups-and-open', label: 'Remove and open task' },
];
const measurementIssueActions: Array<IssueActionOption> = [
  { value: 'remove-invalid-measurements-and-open', label: 'Remove and open task' },
];
const treatmentIssueActions: Array<IssueActionOption> = [
  { value: 'remove-invalid-treatments-and-open', label: 'Remove and open task' },
];
const userIssueActions: Array<IssueActionOption> = [
  { value: 'remove-invalid-users-from-all-tasks', label: 'Remove from all tasks' },
  { value: 'remove-invalid-users-from-task', label: 'Remove from this task' },
  { value: 'add-invalid-users-write', label: 'Add to study with write access' },
  { value: 'add-invalid-users-read', label: 'Add to study with read access' },
  { value: 'open-task-spec', label: 'Open task' },
];

export const TaskSpecIssuesModal: FC<TaskSpecIssuesModalProps> = ({ taskSpecTitleMap, issues, onAction }) => {
  useEffect(() => {
    if (issues.size === 0) {
      onAction({ type: 'close-modal' });
    }
  }, [onAction, issues]);
  return (
    <ModalContainer size="small">
      <ModalHeader
        title={`Resolve task conflicts`}
        subText={'The below tasks can not be created without resolving conflicts'}
        closeModal={() => onAction({ type: 'close-modal' })}
        className="pa3 bb b--moon-gray bg-white"
      />
      <div className={`pa3 w-100 bl b--moon-gray h-100 overflow-y-auto ${styles.issuesContainer}`}>
        {Array.from(issues.entries()).map(([taskSpecId, taskIssues]) => (
          <span key={taskSpecId}>
            <TaskIssuesCard
              taskSpecId={taskSpecId}
              taskSpecTitle={taskSpecTitleMap.get(taskSpecId)}
              issues={taskIssues}
              onAction={onAction}
            />
          </span>
        ))}
      </div>
      <ModalActions
        className="pa3 bt b--moon-gray"
        cancelBtnText="Close"
        onCancel={() => onAction({ type: 'close-modal' })}
      />
    </ModalContainer>
  );
};

const TaskIssuesCard: FC<{
  taskSpecId: string;
  taskSpecTitle?: string;
  issues: Array<TaskSpecV2Issue>;
  onAction: (action: TaskSpecIssueAction) => void;
}> = ({ taskSpecId, taskSpecTitle, issues, onAction }) =>
  Object.values(issues).map((issue) => (
    <IssueCard
      key={issue.type}
      taskSpecId={taskSpecId}
      taskSpecTitle={taskSpecTitle}
      issue={issue}
      onAction={onAction}
    />
  ));

const IssueCard: FC<{
  taskSpecId: string;
  taskSpecTitle?: string;
  issue: TaskSpecV2Issue;
  onAction: (action: TaskSpecIssueAction) => void;
}> = ({ taskSpecId, taskSpecTitle, issue, onAction }) => {
  switch (issue.type) {
    case 'invalid-groups': {
      return (
        <InvalidGroupsCard taskSpecId={taskSpecId} taskSpecTitle={taskSpecTitle} issue={issue} onAction={onAction} />
      );
    }
    case 'invalid-users': {
      return (
        <InvalidUsersCard taskSpecId={taskSpecId} taskSpecTitle={taskSpecTitle} issue={issue} onAction={onAction} />
      );
    }
    case 'invalid-measurements':
      return (
        <InvalidMeasurementsCard
          taskSpecId={taskSpecId}
          taskSpecTitle={taskSpecTitle}
          issue={issue}
          onAction={onAction}
        />
      );
    case 'invalid-treatments':
      return (
        <InvalidTreatmentsCard
          taskSpecId={taskSpecId}
          taskSpecTitle={taskSpecTitle}
          issue={issue}
          onAction={onAction}
        />
      );
    default:
      return null;
  }
};
const InvalidMeasurementsCard: FC<{
  taskSpecId: string;
  taskSpecTitle?: string;
  issue: InvalidMeasurementsIssue;
  onAction: (action: TaskSpecIssueAction) => void;
}> = ({ taskSpecId, taskSpecTitle, issue, onAction }) => {
  const handleActionSelect = (action: SingleValue<IssueActionOption>) => {
    switch (action?.value) {
      case 'remove-invalid-measurements-and-open':
        onAction({ type: 'remove-invalid-measurements-and-open', taskSpecId, measurements: issue.measurements });
        break;
      default:
        errorToast('Something went wrong please try again');
    }
  };
  return (
    <div
      className="bg-near-white pa2 mv2"
      data-test-component="InvalidMeasurementsCard"
      data-test-element="container"
      data-test-key={taskSpecId}
    >
      <div className="flex pv2">
        <div className="pr2 pl1">
          <RiAlertFill className="red" style={{ width: '1.3rem', height: '1.3rem' }} />
        </div>
        <div>
          {_isNotEmpty(taskSpecTitle) && (
            <div className="pb2">
              <h4>{taskSpecTitle}</h4>
            </div>
          )}
          <div className="pb2">
            {issue.measurements.length} Measurement{issue.measurements.length > 1 ? 's' : ''}{' '}
            {issue.measurements.length > 1 ? 'have' : 'has'} been removed from the study
          </div>
          <Select
            options={measurementIssueActions}
            placeholder="Select fix to apply"
            onChange={handleActionSelect}
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};
const InvalidTreatmentsCard: FC<{
  taskSpecId: string;
  taskSpecTitle?: string;
  issue: InvalidTreatmentsIssue;
  onAction: (action: TaskSpecIssueAction) => void;
}> = ({ taskSpecId, taskSpecTitle, issue, onAction }) => {
  const handleActionSelect = (action: SingleValue<IssueActionOption>) => {
    switch (action?.value) {
      case 'remove-invalid-treatments-and-open':
        onAction({ type: 'remove-invalid-treatments-and-open', taskSpecId, treatments: issue.treatments });
        break;
      default:
        errorToast('Something went wrong please try again');
    }
  };
  return (
    <div
      className="bg-near-white pa2 mv2"
      data-test-component="InvalidTreatmentsCard"
      data-test-element="container"
      data-test-key={taskSpecId}
    >
      <div className="flex pv2">
        <div className="pr2 pl1">
          <RiAlertFill className="red" style={{ width: '1.3rem', height: '1.3rem' }} />
        </div>
        <div>
          {_isNotEmpty(taskSpecTitle) && (
            <div className="pb2">
              <h4>{taskSpecTitle}</h4>
            </div>
          )}
          <div className="pb2">
            {issue.treatments.length} Measurement{issue.treatments.length > 1 ? 's' : ''}{' '}
            {issue.treatments.length > 1 ? 'have' : 'has'} been removed from the study due to preset change
          </div>
          <Select
            options={treatmentIssueActions}
            placeholder="Select fix to apply"
            onChange={handleActionSelect}
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};

const InvalidGroupsCard: FC<{
  taskSpecId: string;
  taskSpecTitle?: string;
  issue: InvalidGroupsIssue;
  onAction: (action: TaskSpecIssueAction) => void;
}> = ({ taskSpecId, taskSpecTitle, issue, onAction }) => {
  const handleActionSelect = (action: SingleValue<IssueActionOption>) => {
    switch (action?.value) {
      case 'remove-invalid-groups-and-open':
        onAction({ type: 'remove-invalid-groups-and-open', taskSpecId, groups: issue.groups });
        break;
      default:
        errorToast('Something went wrong please try again');
    }
  };
  return (
    <div
      className="bg-near-white pa2 mv2"
      data-test-component="InvalidGroupsCard"
      data-test-element="container"
      data-test-key={taskSpecId}
    >
      <div className="flex pv2">
        <div className="pr2 pl1">
          <RiAlertFill className="red" style={{ width: '1.3rem', height: '1.3rem' }} />
        </div>
        <div>
          {_isNotEmpty(taskSpecTitle) && (
            <div className="pb2">
              <h4>{taskSpecTitle}</h4>
            </div>
          )}
          <div className="pb2">
            {issue.groups.length} Group{issue.groups.length > 1 ? 's' : ''} {issue.groups.length > 1 ? 'have' : 'has'}{' '}
            been removed from the study
          </div>
          <Select
            options={groupIssueActions}
            placeholder="Select fix to apply"
            onChange={handleActionSelect}
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};
const teamUserMapSelector = createSelector(
  [(state: State) => state.team.team.users],
  (teamUsers) => new Map((teamUsers ?? []).map((u) => [u.api_id, u.name]))
);
const InvalidUsersCard: FC<{
  taskSpecId: string;
  taskSpecTitle?: string;
  issue: InvalidUsersIssue;
  onAction: (action: TaskSpecIssueAction) => void;
}> = ({ taskSpecId, taskSpecTitle, issue, onAction }) => {
  const teamUserMap = useSelector(teamUserMapSelector);
  const handleActionSelect = (action: SingleValue<IssueActionOption>) => {
    if (issue.type === 'invalid-users') {
      switch (action?.value) {
        case 'add-invalid-users-read':
          onAction({ type: 'add-invalid-users', taskSpecId, users: issue.users, access: 'read' });
          break;
        case 'add-invalid-users-write':
          onAction({ type: 'add-invalid-users', taskSpecId, users: issue.users, access: 'write' });
          break;
        case 'remove-invalid-users-from-task':
          onAction({ type: 'remove-invalid-users', taskSpecId, users: issue.users, allTasks: false });
          break;
        case 'remove-invalid-users-from-all-tasks':
          onAction({ type: 'remove-invalid-users', taskSpecId, users: issue.users, allTasks: true });
          break;
        case 'open-task-spec':
          onAction({ type: 'open-task-spec', taskSpecId, tab: 'assignees' });
          break;
        default:
          errorToast('Something went wrong please try again');
      }
    }
  };
  return (
    <div
      className="bg-near-white pa2 mv2"
      data-test-component="InvalidUsersCard"
      data-test-element="container"
      data-test-key={taskSpecId}
    >
      <div className="flex pv2">
        <div className="pr2 pl1">
          <RiAlertFill className="red" style={{ width: '1.3rem', height: '1.3rem' }} />
        </div>
        <div>
          {_isNotEmpty(taskSpecTitle) && (
            <div className="pb2">
              <h4>{taskSpecTitle}</h4>
            </div>
          )}
          <div className="pb2">
            {issue.users.length} Assignee{issue.users.length > 1 ? 's' : ''} {issue.users.length > 1 ? 'have' : 'has'}{' '}
            been removed from the study
          </div>
          <ul className="pb2">
            {issue.users.map((userId) => (
              <li key={userId}>{teamUserMap.get(userId)}</li>
            ))}
          </ul>
          <Select
            options={userIssueActions}
            placeholder="Select fix to apply"
            onChange={handleActionSelect}
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};
