import { pad } from '@/helpers';
import type { Animal } from '@/model/Animal.model';
import { CellChange } from 'handsontable/common';
import { ID } from '@/model/Common.model.ts';

export type FormFields = {
  name: string;
  type: string;
  repeating_ids: Array<string>;
  sequence_prefix: string;
  sequence_starting: string;
};

export enum GenerateType {
  REPEATING = 'repeating',
  SEQUENCE = 'sequence',
}

export const repeatIdentifiersAcrossCages = (
  animals: Array<Animal>,
  accessor: string = 'name',
  ids: Array<string>
): Array<CellChange> => {
  const rows: Array<CellChange> = [];
  const cageCountRecord: { [key: ID]: number } = {};

  for (let index = 0; index < animals.length; index++) {
    const animal = animals[index];
    const cageId = animal.cage?.id;

    if (!cageId) {
      continue;
    }

    if (!cageCountRecord[cageId]) {
      cageCountRecord[cageId] = 0;
    }

    if (ids[cageCountRecord[cageId]]) {
      rows.push([index, accessor, ids[cageCountRecord[cageId]], null]);
    }
    cageCountRecord[cageId]++;
  }
  return rows;
};

export const nameAnimalsInSequence = (
  animals: Array<Animal> = [],
  accessor: string = 'name',
  prefix: string,
  countFrom: string
): Array<CellChange> => {
  const rows: Array<CellChange> = [];

  for (let index = 0; index < animals.length; index++) {
    rows.push([index, accessor, `${prefix}${pad(Number(countFrom) + index, String(countFrom).length)}`, null]);
  }
  return rows;
};
