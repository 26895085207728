// @ts-nocheck: converted from JS

import {
  isDateField,
  isMultiSelectField,
  isSelectField,
  isTextField,
  isLookupSelectField,
} from '@/components/Glossary/Sections/Approvals/TemplateForm/MetadataCondition.utils';
import { operationOptions } from '@/components/Glossary/Sections/Approvals/TemplateForm/TemplateForm.utils';
import { SelectLookup } from '@/components/Metadata/Fieldtypes/SelectLookup';
import DatePickerNative from '@/components/UI/DatePickerNative';
import ReactSelect from '@/components/UI/Select';
import { _notNil, safelyDecodeURIComponent } from '@/littledash';
import { ID } from '@/model/Common.model';
import { MetadataField, MetadataFieldValueTypes } from '@/model/Metadata.model';
import { ErrorMessage } from '@hookform/error-message';
import { useMemo, FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';

interface MetadataConditionProps {
  studyMetadataOptions: Array<MetadataField>;
  projectMetadataOptions: Array<MetadataField>;
  glossary_id?: ID;
  operation?: string;
  value?: MetadataFieldValueTypes;
  index: number;
}

interface OptionSelectItem {
  value: ID;
  label: string;
}

export const MetadataCondition: FC<MetadataConditionProps> = ({
  studyMetadataOptions,
  projectMetadataOptions,
  glossary_id: defaultGlossaryId,
  operation: defaultOperation,
  value: defaultValue,
  index,
}) => {
  const { control, errors, register } = useFormContext();

  const glossaryIdFieldName = `conditions[${index}].glossary_id`;
  const operationFieldName = `conditions[${index}].operation`;
  const valueFieldName = `conditions[${index}].value`;

  const metadata = useMemo(() => {
    if (!studyMetadataOptions || !projectMetadataOptions) {
      return [];
    }
    return [...studyMetadataOptions, ...projectMetadataOptions];
  }, [studyMetadataOptions, projectMetadataOptions]);

  const glossaryId = useWatch({
    control,
    name: glossaryIdFieldName,
    defaultValue: defaultGlossaryId || null,
  });

  const operation = useWatch({
    control,
    name: operationFieldName,
    defaultValue: defaultOperation || '',
  });

  if (!studyMetadataOptions || !projectMetadataOptions) {
    return null;
  }

  const availableMetadataFieldTypes = ['text', 'select', 'lookup_select', 'lookup_multi_select', 'date'];
  const groupedMetadataOptions: Array<{ label: string; options: Array<OptionSelectItem> }> = [];
  const flatMetadataOptions: Array<OptionSelectItem> = [];
  if (studyMetadataOptions?.length) {
    const options = studyMetadataOptions.reduce((acc, { field_type, id, title }) => {
      if (availableMetadataFieldTypes.includes(field_type)) {
        acc.push({
          value: id,
          label: title,
        });
      }
      return acc;
    }, [] as Array<OptionSelectItem>);
    groupedMetadataOptions.push({
      label: 'Study metadata',
      options,
    });
    flatMetadataOptions.push(...options);
  }

  if (projectMetadataOptions?.length) {
    const options = projectMetadataOptions.reduce((acc, option) => {
      if (availableMetadataFieldTypes.includes(option.field_type)) {
        acc.push({
          value: option.id,
          label: option.title,
        });
      }
      return acc;
    }, [] as Array<OptionSelectItem>);
    groupedMetadataOptions.push({
      label: 'Project metadata',
      options,
    });
    flatMetadataOptions.push(...options);
  }

  const defaultSelectValue = {
    label: 'Select...',
    value: '',
  };

  const hasGlossaryIdError = Boolean(errors?.conditions?.[index]?.glossary_id);
  const hasOperationError = Boolean(errors?.conditions?.[index]?.operation);
  const hasValueError = Boolean(errors?.conditions?.[index]?.value);

  const buildSelectStyles = (hasErrors: boolean) => ({
    control: (base: Record<string, string | number | undefined>) => ({
      ...base,
      border: hasErrors ? '2px solid red !important' : base.border,
      height: 40,
      minHeight: 40,
      '&:hover': {
        border: hasErrors ? '2px solid red !important' : base.border,
      },
    }),
  });

  const getSelectValueOptions = (glossaryId: ID) => {
    const toSelectOptions = (value: string) => ({
      label: safelyDecodeURIComponent(value),
      value,
    });
    const metadataItem = metadata.find((metadataItem) => metadataItem.id === glossaryId);
    if (metadataItem?.field_type === 'select' || metadataItem?.field_type === 'multi_select') {
      return (metadataItem.options ?? []).map(toSelectOptions);
    }
    return [];
  };

  const handleSelectChange = (
    onChange: (value: Array<MetadataFieldValueTypes> | MetadataFieldValueTypes) => void,
    data: Array<Record<string, string>> | Record<string, string> | undefined,
    isMulti: boolean
  ) => {
    if (data) {
      if (!Array.isArray(data)) {
        onChange(data.value);
      } else if (isMulti && Array.isArray(data)) {
        // setting to undefined manually due to https://github.com/JedWatson/react-select/pull/4373
        onChange(data?.map((d) => d.value) ?? undefined);
      }
    } else {
      onChange('');
    }
  };

  const getDefaultSelectValue = (value: Array<MetadataFieldValueTypes> | MetadataFieldValueTypes, isMulti: boolean) => {
    if (value) {
      if (isMulti && Array.isArray(value)) {
        return getSelectValueOptions(glossaryId).filter((option) => value.includes(option.value));
      } else {
        return getSelectValueOptions(glossaryId).find((option) => option.value === value);
      }
    } else {
      if (isMulti) {
        return [];
      } else {
        return defaultSelectValue;
      }
    }
  };

  const metadataItem = metadata.find(({ id }) => id === glossaryId);

  return (
    <div
      className="flex flex-row justify-between items-start"
      data-test-component="MetadataCondition"
      data-test-element="container"
      data-test-key={`${index}`}
      data-testid="metadata-condition"
    >
      <div
        className="flex flex-column"
        data-test-element="metadata-field-select"
        data-testid="metadata-glossary-item-field"
      >
        <Controller
          defaultValue={defaultGlossaryId || null}
          name={glossaryIdFieldName}
          rules={{
            required: 'This field is required',
          }}
          control={control}
          render={({ onChange, value }: ControllerRenderProps) => (
            <ReactSelect
              customStyles={buildSelectStyles(hasGlossaryIdError)}
              isMulti={false}
              className="mr3"
              defaultValue={value ? flatMetadataOptions.find((option) => option.value === value) : defaultSelectValue}
              options={groupedMetadataOptions}
              onChange={(value) => onChange(value?.value)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={glossaryIdFieldName}
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>

      <div className="flex flex-column" data-test-element="operation-select">
        <Controller
          defaultValue={defaultOperation || ''}
          name={operationFieldName}
          rules={{
            required: 'This field is required',
          }}
          control={control}
          render={({ onChange, value }: { onChange: (value?: string) => void; value: string }) => (
            <ReactSelect
              customStyles={buildSelectStyles(hasOperationError)}
              isMulti={false}
              className="mr3"
              defaultValue={value ? operationOptions.find((option) => option.value === value) : defaultSelectValue}
              options={operationOptions}
              onChange={(value: Record<string, string> | null) => onChange(value?.value)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={operationFieldName}
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>

      {glossaryId &&
        operation &&
        ((isTextField(glossaryId, metadata) && (
          <div className="flex flex-column w5 mr3" data-test-element="metadata-text-value">
            <input
              defaultValue={defaultValue || ''}
              type="text"
              data-test-element="metadata-text-value-input"
              data-testid={`metadata-text-input-${index}`}
              style={{ marginBottom: 0 }}
              className={`${hasValueError ? 'input__error' : ''}`}
              name={valueFieldName}
              ref={register({
                required: 'This field is required',
              })}
            />
            <ErrorMessage
              errors={errors}
              name={valueFieldName}
              render={({ message }) => <p className="f6 red db pt2">{message}</p>}
            />
          </div>
        )) ||
          ((isSelectField(glossaryId, metadata) || isMultiSelectField(glossaryId, metadata)) && (
            <div className="flex flex-column w5 mr3" data-test-element="metadata-select-value">
              <Controller
                defaultValue={defaultValue || ''}
                name={valueFieldName}
                rules={{
                  required: 'This field is required',
                }}
                control={control}
                render={({
                  onChange,
                  value,
                }: {
                  onChange: (value: Array<MetadataFieldValueTypes> | MetadataFieldValueTypes) => void;
                  value: Array<MetadataFieldValueTypes> | MetadataFieldValueTypes;
                }) => (
                  <ReactSelect
                    className="mr3"
                    defaultValue={getDefaultSelectValue(value, isMultiSelectField(glossaryId, metadata))}
                    options={getSelectValueOptions(glossaryId)}
                    onChange={(data) => {
                      handleSelectChange(onChange, data, isMultiSelectField(glossaryId, metadata));
                    }}
                    isMulti={isMultiSelectField(glossaryId, metadata)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name={valueFieldName}
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
          )) ||
          (isDateField(glossaryId, metadata) && (
            <div className="flex flex-column w5 mr3" data-test-element="metadata-date-value">
              <Controller
                defaultValue={defaultValue || new Date()}
                name={valueFieldName}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ onChange, value }) => (
                  <DatePickerNative
                    name="dateCollected"
                    value={value}
                    onChange={onChange}
                    style={{ marginBottom: 0 }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name={valueFieldName}
                render={({ message }) => <p className="f7 red db lh-copy pt1">{message}</p>}
              />
            </div>
          )) ||
          (isLookupSelectField(glossaryId, metadata) && _notNil(metadataItem) && (
            <div className="flex flex-column w5 mr3" data-test-element="metadata-lookup-select-value">
              <SelectLookup
                field={{ ...metadataItem, value: defaultValue ?? '', required: true }}
                testId={valueFieldName}
                name={valueFieldName}
                isMulti={metadataItem.field_type === 'lookup_multi_select'}
              />
              <ErrorMessage
                errors={errors}
                name={valueFieldName}
                render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
              />
            </div>
          )))}
    </div>
  );
};
