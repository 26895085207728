import { taskTypeOptions } from '@/components/Modals/AddTask/AddTask.utils.ts';
import { _first } from '@/littledash';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import type { TaskSpec, TaskSpecCreate } from 'model/Task.model';
import {
  AddTaskAssgineeData,
  AddTaskExecutionData,
  AddTaskOverviewData,
  AddTaskScheduleData,
  AddTaskTargetData,
  StepFormPayloadProps,
} from './AddTask.model';
import { conditionTypeOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleConditionalFields';
import { repeatPeriodOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleForm.utils';

export const taskSpecToOverviewDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskOverviewData => {
  return {
    title: taskSpec?.title ?? '',
    description: taskSpec?.description ?? '',
    type: taskSpec?.type ?? taskTypeOptions[0].value,
  };
};
export const taskSpecToExecutionDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskExecutionData => {
  switch (taskSpec.type) {
    case 'measurement':
      return taskSpec.execution?.measurement ?? {};
    case 'dosing':
      return taskSpec.execution?.dosing ?? {};
    case 'observation':
      return taskSpec.execution?.observation ?? {};
    case 'sample':
      return taskSpec.execution?.sample ?? {};
    default:
      return {};
  }
};

export const taskSpecToAssigneeDefaultValues = (taskSpec: TaskSpec): AddTaskAssgineeData => {
  return (taskSpec?.assignees ?? []).map((user) => user.id);
};

export const taskSpecToScheduleDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskScheduleData => {
  switch (taskSpec.schedule.type) {
    case 'recurring':
      return {
        type: 'recurring',
        start: DateUtils.renderDateTime(taskSpec.schedule.duration.start, {
          format: DateRenderFormat.ISODate,
          defaultResponse: '',
        }),
        end: DateUtils.renderDateTime(taskSpec.schedule.duration.end, {
          format: DateRenderFormat.ISODate,
          defaultResponse: '',
        }),
        repeat: {
          value: taskSpec?.schedule?.repeat?.value ?? 0,
          unit: taskSpec.schedule.repeat?.unit ?? repeatPeriodOptions.days.value,
          days: taskSpec.schedule.repeat?.on_day ?? [],
        },
      };
    case 'conditional':
      return {
        type: 'conditional',
        condition: {
          type: taskSpec?.schedule?.condition?.type ?? _first(conditionTypeOptions).value,
          value: taskSpec?.schedule?.condition?.value ?? [],
        },
      };
    case 'one_off':
      return {
        type: 'one_off',
        start: DateUtils.renderDateTime(taskSpec.schedule.duration.start, {
          format: DateRenderFormat.ISODate,
          defaultResponse: '',
        }),
        end: DateUtils.renderDateTime(taskSpec.schedule.duration.end, {
          format: DateRenderFormat.ISODate,
          defaultResponse: '',
        }),
      };
    default:
      return {
        type: 'one_off',
      };
  }
};

export const taskSpecToTargetDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskTargetData => {
  switch (taskSpec.target.type) {
    case 'animal':
      return {
        type: 'animal',
      };
    case 'group':
      return {
        type: 'group',
        groups: taskSpec?.target?.groups ?? [],
      };
    default:
      return {
        type: 'animal',
      };
  }
};

export const editTaskSpecDefaultValues = (taskSpec: TaskSpec): StepFormPayloadProps => {
  return {
    overview: taskSpecToOverviewDefaultValues(taskSpec),
    execution: taskSpecToExecutionDefaultValues(taskSpec),
    assignees: taskSpecToAssigneeDefaultValues(taskSpec),
    schedule: taskSpecToScheduleDefaultValues(taskSpec),
    target: taskSpecToTargetDefaultValues(taskSpec),
  };
};

export const editTaskSpecStudyCreateDefaultValues = (studyCreateTaskSpec: TaskSpecCreate): StepFormPayloadProps => {
  return {
    overview: taskSpecToOverviewDefaultValues(studyCreateTaskSpec),
    execution: taskSpecToExecutionDefaultValues(studyCreateTaskSpec),
    assignees: studyCreateTaskSpec.assignees ?? [],
    schedule: taskSpecToScheduleDefaultValues(studyCreateTaskSpec),
    target: taskSpecToTargetDefaultValues(studyCreateTaskSpec),
  };
};
