import { errorToast } from '@/helpers';
import InVivoError from '@/model/InVivoError.ts';
import { useMountedState } from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import type { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { notAborted, useAbortController } from './useAbortController';

type UseCollectionType =
  | 'baseStudies'
  | 'studyReviews'
  | 'studyRequests'
  | 'animalDosages'
  | 'animalAlerts'
  | 'measurements'
  | 'collections'
  | 'cages'
  | 'studyCages'
  | 'studyGroups'
  | 'studyAnimals'
  | 'animals'
  | 'metadataGlossaries'
  | 'projects'
  | 'requestTemplates'
  | 'metadataGlossary'
  | 'studyTreatments'
  | 'sampleFilters'
  | 'teamGlossary'
  | 'studyAlertSettings'
  | 'teamStudiesForms'
  | 'teamStudiesGroups'
  | 'treatments'
  | 'tasks'
  | 'treatmentUnits';

const collectionTypes: Record<UseCollectionType, string> = {
  baseStudies: 'studies.base',
  studyReviews: 'studies.approvals.list',
  studyRequests: 'studies.requests.list',
  animalDosages: 'v1.dosing.get',
  animalAlerts: 'studies.animal.alerts',
  measurements: 'v1.measurements.get',
  collections: 'studies.collections',
  cages: 'cages.show',
  studyCages: 'studies.cages.list',
  studyGroups: 'studies.groups.list',
  studyAnimals: 'v1.studies.animals.get',
  animals: 'animals.show',
  metadataGlossaries: 'meta-glossary.search',
  projects: 'projects.list',
  requestTemplates: 'requests.templates.list',
  metadataGlossary: 'meta-glossary.show',
  studyTreatments: 'studies.treatments',
  sampleFilters: 'samples.sample-filters',
  teamGlossary: 'team-glossary.list',
  studyAlertSettings: 'studies.alerts.settings.list',
  teamStudiesForms: 'team.studies.forms',
  teamStudiesGroups: 'team.studies.groups',
  treatments: 'team.glossary.treatments',
  treatmentUnits: 'treatment-units',
  tasks: 'v1.tasks.get',
};

interface UseFetchCollectionOutput<Resp = unknown> {
  collection: Array<Resp> | undefined;
  fetchCollection: () => Promise<void>;
  collectionLoading: boolean;
  collectionUpdating: boolean;
  collectionError: Error | false;
}

interface UseFetchCollectionProps {
  collectionType: UseCollectionType;
  params?: Record<string, unknown>;
  queryParams?: Record<string, unknown>;
  includes?: string;
  filters?: string;
}

/**
 * @deprecated useFetchCollection is deprecated, use useApiHook instead.
 */
const useFetchCollection = <Resp = unknown>({
  collectionType,
  params = {},
  queryParams = {},
  includes = '',
  filters = '',
}: UseFetchCollectionProps): UseFetchCollectionOutput<Resp> => {
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [collectionUpdating, setCollectionUpdating] = useState(false);
  const [collectionError, setCollectionError] = useState<Error | false>(false);
  const [collection, setCollection] = useState<Array<Resp> | undefined>(undefined);
  const { newAbortController } = useAbortController();
  const isMounted = useMountedState();

  const fetchCollection = async () => {
    setCollectionUpdating(true);
    try {
      let qs = { ...queryParams };
      if (includes !== '') {
        qs = { ...qs, include: includes };
      }
      const uri = collectionTypes[collectionType];
      const url = apiRoute(uri, { ...params }, qs, filters);

      let config: AxiosRequestConfig = {
        signal: newAbortController().signal,
      };

      if (uri.startsWith('v1')) {
        config = { ...config, baseURL: AppConfig.internalApiUrl };
      }

      const {
        data: { data },
      } = await Http.get<{ data: Array<Resp> }>(apiRoute(url), config);

      if (isMounted()) {
        setCollection(data);
        setCollectionLoading(false);
        setCollectionUpdating(false);
      }
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        setCollectionError(error as Error);
        errorToast('There was a problem fetching data from the server');
        setCollectionLoading(false);
        setCollectionUpdating(false);
        ExceptionHandler.captureException(
          new InVivoError(`Api Error (Fetch Collection): ${collectionType}`, {
            cause: error,
            context: { CollectionType: collectionType },
            slug: 'use-fetch-collection',
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchCollection();
  }, []);

  return {
    collection,
    fetchCollection,
    collectionLoading,
    collectionUpdating,
    collectionError,
  };
};

export default useFetchCollection;
