// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';

const ExportButton = ({ routes, handleExportClick, exporting, disabled }) => {
  if (!routes) {
    return '';
  }

  if (routes.length > 1) {
    return (
      <SelectDropDown
        title="Export"
        alignMenu="right"
        loading={exporting}
        loadingText={'Exporting'}
        disabled={disabled}
      >
        <ActionList
          actions={routes.map((route) => ({
            name: route.label,
            action: () => handleExportClick(route),
          }))}
        />
      </SelectDropDown>
    );
  }

  return (
    <Button
      onClick={() => handleExportClick(routes[0])}
      loading={exporting}
      loadingText={'Exporting'}
      tooltip={disabled ? 'No data to export' : undefined}
      disabled={disabled}
    >
      Export
    </Button>
  );
};

export default ExportButton;
