import { FormField } from '@/components/UI/FormFields/Latest';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils';
import ReactSelect from '@/components/UI/Select';
import { _isNotEmpty, safelyDecodeURIComponent } from '@/littledash';
import { useFormContext, Controller } from 'react-hook-form@latest';
import { FC } from 'react';
import { SingleValue } from 'react-select';

interface SelectProps {
  value?: string | number;
  options?: Array<{ label: string; value: string | number }>;
  name?: string;
  label?: string;
  isMulti?: boolean;
  disabled?: boolean;
  hint?: string;
  required?: boolean;
  overrideLabel?: string;
  testId?: string;
}

interface SelectOption {
  label: string;
  value: string | number;
}

const Select: FC<SelectProps> = ({
  value,
  options,
  name,
  label,
  isMulti,
  disabled = false,
  hint,
  required,
  overrideLabel,
  testId = 'select',
}) => {
  const handleChange = (onChange: (value: string | number) => void, data: SingleValue<object>) => {
    if (data) {
      if (isMulti) {
        onChange(JSON.stringify((data as Array<SelectOption>).map((d) => d.value)));
      } else {
        onChange((data as SelectOption).value);
      }
    } else {
      onChange('');
    }
  };

  const getLabelFromOptions = (value: string | number) =>
    options?.find((option) => option.value === value)?.label || undefined;

  const asSelectValue = (value: string | number) => {
    if (_isNotEmpty(value)) {
      if (isMulti) {
        const options = typeof value === 'string' ? JSON.parse(value) : value;
        return options?.map((v: string) => ({
          label: safelyDecodeURIComponent(v),
          value: v,
        }));
      }
      return {
        label: overrideLabel ?? getLabelFromOptions(value),
        value,
      };
    }
    return undefined;
  };

  const { control } = useFormContext();

  return (
    <FormField name={name ?? ''} label={label ?? ''} hint={hint} required={required} testPrefix={testId}>
      <Controller
        control={control}
        name={name ?? ''}
        defaultValue={value}
        rules={getRegisterConfig({ required })}
        render={({ field: { onChange, value } }) => (
          <ReactSelect
            defaultValue={asSelectValue(value)}
            options={options}
            isMulti={isMulti == true}
            onChange={(data: SingleValue<object>) => handleChange(onChange, data)}
            disabled={disabled}
          />
        )}
      />
    </FormField>
  );
};

export default Select;
