import { weekRange } from '@/components/Tasks/Calendar/Calendar.utils.ts';
import Button from '@/components/UI/Button';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import { ISODate } from '@/model/Common.model.ts';
import { DateUtils } from '@/utils/Date.utils.ts';
import { tz, TZDate } from '@date-fns/tz';
import { addDays, addMonths, addWeeks, format, subDays, subMonths, subWeeks } from 'date-fns';
import type { FC } from 'react';
import { RiArrowDownSFill, RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { type CalendarState, CalendarView } from './Calendar.model';

export interface CalendarNavProps {
  selectedDate: CalendarState['selectedDate'];
  yearAndMonth: CalendarState['yearAndMonth'];
  span: CalendarState['span'];
  onSelectDay: (day: ISODate) => void;
  onSelectYearAndMonth: (yearAndMonth: Array<number>) => void;
  onSelectSpan: (span: CalendarView) => void;
}

const CalendarNav: FC<CalendarNavProps> = ({
  selectedDate,
  yearAndMonth: [year, month],
  span,
  onSelectYearAndMonth,
  onSelectSpan,
  onSelectDay,
}) => {
  const dateText = (): string => {
    const timezone = tz(DateUtils.timezone());
    switch (span) {
      case 'Month': {
        const date = new Date(year, month, 1);
        return `${format(date, 'MMMM')} ${year}`;
      }
      case 'Week': {
        const dateRange = weekRange(selectedDate);
        return `${format(dateRange.start, 'do MMMM yyyy', { in: timezone })} - ${format(dateRange.end, 'do MMMM yyyy', { in: timezone })}`;
      }
      case 'Day':
        return format(selectedDate, 'do MMMM yyyy', { in: timezone });
    }
  };
  const handlePreviousClick = (): void => {
    switch (span) {
      case 'Month': {
        const newMonth = subMonths(new Date(year, month, 1), 1);
        onSelectYearAndMonth([newMonth.getFullYear(), newMonth.getMonth()]);
        break;
      }
      case 'Week':
        onSelectDay(subWeeks(selectedDate, 1).toISOString());
        break;
      case 'Day':
        onSelectDay(subDays(selectedDate, 1).toISOString());
        break;
    }
  };
  const handleNextClick = (): void => {
    switch (span) {
      case 'Month': {
        const newMonth = addMonths(new Date(year, month, 1), 1);
        onSelectYearAndMonth([newMonth.getFullYear(), newMonth.getMonth()]);
        break;
      }
      case 'Week':
        onSelectDay(addWeeks(selectedDate, 1).toISOString());
        break;
      case 'Day':
        onSelectDay(addDays(selectedDate, 1).toISOString());
        break;
    }
  };
  const handleTodayClick = (): void => {
    const now = TZDate.tz(DateUtils.timezone());
    switch (span) {
      case 'Month': {
        onSelectYearAndMonth([now.getFullYear(), now.getMonth()]);
        break;
      }
      default:
        onSelectDay(DateUtils.formatISO(now.getTime()));
    }
  };
  const handleSpanSelect = (selectedSpan: CalendarView): void => {
    onSelectSpan(selectedSpan);
  };

  return (
    <div
      className="flex w-100 min-h3 items-center justify-between bb b--moon-gray"
      data-test-component="CalendarNav"
      data-test-element="container"
    >
      <div className="flex items-center">
        <div onClick={handlePreviousClick} className="pl2 mt1" data-testid="previous-date">
          <RiArrowLeftLine size={28} className="dark-gray dim pointer lh-solid" />
        </div>
        <div onClick={handleNextClick} className="pl2 mt1">
          <RiArrowRightLine size={28} className="dark-gray dim pointer lh-solid" data-testid="next-date" />
        </div>
        <Button className="mh3 ph3 no-select" plain onClick={handleTodayClick} testId="today-button">
          Today
        </Button>
        <div>
          <h3 className="lh-title near-black" data-testid="date-text">
            {dateText()}
          </h3>
        </div>
      </div>
      <div className="flex items-center mr4" data-test-component="CalendarNav" data-test-element="span-select">
        <Dropdown
          renderMenu={() => (
            <>
              <DropdownMenuItem testId="month" onClick={() => handleSpanSelect('Month')}>
                Month
              </DropdownMenuItem>
              <DropdownMenuItem testId="week" onClick={() => handleSpanSelect('Week')}>
                Week
              </DropdownMenuItem>
              <DropdownMenuItem testId="day" onClick={() => handleSpanSelect('Day')}>
                Day
              </DropdownMenuItem>
            </>
          )}
        >
          <Button plain className="ph3">
            <div className="flex items-center">
              {span} <RiArrowDownSFill className="ml1" />
            </div>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default CalendarNav;
