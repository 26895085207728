import type { CSSProperties, FocusEventHandler } from 'react';
import { DateUtils } from '@/utils/Date.utils';

interface DatePickerNativeProps {
  value: string;
  name?: string;
  step?: string;
  min?: string;
  max?: string;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  testId?: string;
  ddPrivacy?: string;
  autoFocus?: boolean;
}

const toLocalDate = (value: string): string => {
  if (DateUtils.isoDatePattern.test(value)) {
    return value;
  }
  return '';
};

const DatePickerNative = ({
  value,
  name,
  step,
  min,
  max,
  className,
  style,
  onChange,
  onBlur,
  disabled,
  testId = 'date-picker',
  ddPrivacy = 'mask-user-input',
  autoFocus,
}: DatePickerNativeProps) => (
  <input
    type="date"
    name={name}
    value={toLocalDate(value ?? DateUtils.dateNow())}
    data-test-component="DatePickerNative"
    data-test-element="input"
    step={step}
    min={toLocalDate(min ?? '')}
    max={toLocalDate(max ?? '')}
    className={className}
    style={{ width: 170, ...style }}
    onChange={(event) => onChange?.(DateUtils.isoDatePattern.test(event.target.value) ? event.target.value : '')}
    onBlur={onBlur}
    disabled={disabled}
    data-testid={testId}
    data-dd-privacy={ddPrivacy}
    autoFocus={autoFocus}
  />
);

export default DatePickerNative;
