import { components } from '@/generated/internal-api/openapi-types';
import { ApiID } from '@/model/Common.model';
import type { SelectOption } from 'components/UI/Select/Select';

import type { Dispatch, Reducer } from 'react';

export type StatColumnV1 = components['schemas']['StatColumnV1.schema'];
export type AnalysisStudyGroupV1 = components['schemas']['AnalysisStudyGroupV1.schema'];
export type AnalysisRow =
  | components['schemas']['EfficacyProphylacticRowV1.schema']
  | components['schemas']['EfficacyRowV1.schema']
  | components['schemas']['SurvivalRowV1.schema']
  | components['schemas']['ToleranceRowV1.schema'];

export type AnalysisOptions = components['schemas']['AnalysisTableRequestV1.schema'];
export type AnalysisConfigResponse = components['schemas']['AnalysisConfigResponseV1.schema'];
export type AnalysisConfig = components['schemas']['AnalysisConfigV1.schema'];
export type AnalysisIssue = components['schemas']['AnalysisIssueV1.schema'];

export type AverageType = components['schemas']['AverageType.schema'];

export type TrackingFrom = components['schemas']['TrackingFrom.schema'];
export type AnalysisTableResponseV1 = components['schemas']['AnalysisTableResponseV1.schema'];

export interface ProcessedAnalysisConfig {
  measurements: Set<AnalysisConfig['measurements'][0]>;
  average_type: Set<AnalysisConfig['average_type'][0]>;
  tracking_from: Set<AnalysisConfig['tracking_from'][0]>;
}
export interface AnalysisState {
  options?: AnalysisOptions;
  config?: Map<AnalysisType, ProcessedAnalysisConfig>;
  issues?: Array<AnalysisIssue>;
  exportSnapshotApiId?: ApiID<'esh'>;
}

export type AnalysisType = components['schemas']['AnalysisType.schema'];
export type AnalysisActions =
  | { type: 'update-analysis-options'; data: AnalysisOptions }
  | { type: 'update-analysis-config'; data: AnalysisConfigResponse }
  | { type: 'update-analysis-issues'; data: Array<AnalysisIssue> }
  | { type: 'update-analysis-export-id'; data: ApiID<'esh'> | undefined };
export type AnalysisDispatch = Dispatch<AnalysisActions>;

export const analysisTypeOptions: Record<AnalysisType, SelectOption<AnalysisType>> = {
  Efficacy: { value: 'Efficacy', label: 'Efficacy' },
  EfficacyProphylactic: { value: 'EfficacyProphylactic', label: 'Efficacy Prophylactic' },
  Survival: { value: 'Survival', label: 'Survival' },
  Tolerance: { value: 'Tolerance', label: 'Tolerance' },
  Oncology: { value: 'Oncology', label: 'Oncology Summary' },
};
export const averageTypeOptions: Record<AverageType, SelectOption<AverageType>> = {
  Mean: { value: 'Mean', label: 'Mean' },
  Median: { value: 'Median', label: 'Median' },
};
export const analysisDayFromOptions: Record<TrackingFrom, SelectOption<TrackingFrom>> = {
  TrackingDay: { value: 'TrackingDay', label: 'from tracking date' },
  DiseaseInduction: { value: 'DiseaseInduction', label: 'from disease induction' },
};
export const reducer: Reducer<AnalysisState, AnalysisActions> = (prevState, action) => {
  switch (action.type) {
    case 'update-analysis-options': {
      return { ...prevState, options: action.data };
    }
    case 'update-analysis-config': {
      const config = new Map(
        action.data.config.map(({ type, measurements, average_type, tracking_from }) => [
          type,
          {
            measurements: new Set(measurements),
            average_type: new Set(average_type),
            tracking_from: new Set(tracking_from),
          },
        ])
      );
      return { ...prevState, config, issues: action.data.issues ?? [] };
    }
    case 'update-analysis-issues': {
      return { ...prevState, issues: action.data ?? [] };
    }
    case 'update-analysis-export-id': {
      return { ...prevState, exportSnapshotApiId: action.data };
    }
  }
  return prevState;
};
