import { formatDosingColumnValue } from '@/components/Studies/Treatments/Treatment.utils';
import MetadataButton from '@/components/UI/MetadataButton/MetadataButton';
import { capitalise } from '@/helpers';
import { middleDot } from '@/helpers.constants';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { Treatment, TreatmentField, TreatmentFieldV1, TreatmentV1 } from '@/model/Treatment.model';

export const initialGroupsWithTreatmentsMap = (treatments: Array<Treatment>): Record<ID, Array<ID>> => {
  return treatments.reduce<Record<ID, Array<ID>>>((acc, treatment) => {
    const { id: treatmentId, study_groups: studyGroups } = treatment;
    if (studyGroups) {
      studyGroups.forEach(({ id: groupId }) => {
        if (!acc?.[groupId]) {
          acc[groupId] = [];
        }
        acc[groupId].push(treatmentId);
      });
    }
    return acc;
  }, {});
};

export const formatDosingMethod = (treatment: Treatment | TreatmentV1): string => {
  const methodsWithValue = (treatment.fields as Array<TreatmentField | TreatmentFieldV1>).reduce<Array<string>>(
    (acc, field) => {
      if (_isNotEmpty(field.default_value)) {
        acc.push(`${field.label ?? capitalise(field.name)}: ${formatDosingColumnValue(field)}`);
      }
      return acc;
    },
    []
  );
  return methodsWithValue.length > 1 ? methodsWithValue.join(` ${middleDot} `) : methodsWithValue[0];
};

interface FormattedTreatment {
  id: ID;
  title: string;
  sublabel: string;
  meta?: JSX.Element;
}

const formatTreatmentItem = (treatment: Treatment): FormattedTreatment => {
  const { id, treatment_type } = treatment;
  const formattedTreatment = {
    id,
    title: treatment_type.name,
    sublabel: formatDosingMethod(treatment),
  };
  if (treatment.fields.every(({ default_value }) => _isEmpty(default_value)) && _isEmpty(treatment?.metadata)) {
    return formattedTreatment;
  }
  return {
    ...formattedTreatment,
    meta: <MetadataButton entity={treatment} entityName="treatmentMeta" tooltip="Treatment metadata" />,
  };
};

interface AvailableAndAssignedSections {
  title: string;
  items: Array<FormattedTreatment>;
}

export const generateAvailableAndAssignedSections = (
  groupId: ID,
  groupsWithTreatments: Record<ID, Array<ID>>,
  treatments: Array<Treatment>
): Array<AvailableAndAssignedSections> => {
  const groupTreatments = groupsWithTreatments?.[groupId];
  const sections: Array<AvailableAndAssignedSections> = [];
  const assignedTreatments: Array<FormattedTreatment> = [];
  const availableTreatments: Array<FormattedTreatment> = [];

  treatments.forEach((treatment) => {
    const formattedTreatment = formatTreatmentItem(treatment);
    if (groupTreatments && groupTreatments.includes(treatment.id)) {
      assignedTreatments.push(formattedTreatment);
    } else {
      availableTreatments.push(formattedTreatment);
    }
  });

  if (_isNotEmpty(assignedTreatments)) {
    sections.push({
      title: 'Assigned treatments',
      items: assignedTreatments,
    });
  }

  sections.push({
    title: 'Available treatments',
    items: availableTreatments,
  });
  return sections;
};

export const formatGroupConfigurePayload = (groupsWithTreatments: Record<ID, Array<ID>>) => {
  return Object.keys(groupsWithTreatments).map((groupId) => ({
    group_id: groupId,
    treatment_ids: groupsWithTreatments[groupId],
  }));
};
