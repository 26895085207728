import { _isEmpty, _isEmptyString, _isNil, _isNotEmpty, _notNil, _toNumber } from '@/littledash';
import { NO_SPACES_REGEX, THREE_DECIMAL_PLACES_REGEX, VALID_CHARACTER_REGEX } from '@/validators.constants';

export const withinCharacterLimits = (value: string, min: number, max: number) => {
  return (max >= value.length && value.length >= min) || `Must be between ${min} and ${max} characters long.`;
};

export const containsValidCharacters = (value: string, message?: string) => {
  return (
    _isNotEmpty(value.match(VALID_CHARACTER_REGEX)) ||
    message ||
    "Consist of letters or numbers and contain only the following special characters:  _ - . ! * ' ( )"
  );
};

export const containsNoSpaces = (value: string) => {
  return _isNotEmpty(value.match(NO_SPACES_REGEX)) || 'Must not contain spaces.';
};

export const numericValidator = (value?: string | null, required: boolean = false) => {
  const val = value ?? '';
  if (_isNotEmpty(val)) {
    return !isNaN(val);
  }
  return !required;
};

export const decimalPlacesValidator = (name: string) => (value: number | null | undefined) => {
  if (_isNil(value) || _isEmpty(value) || THREE_DECIMAL_PLACES_REGEX.test(_toNumber(value, '').toString())) {
    return true;
  }
  return `${name} can't be more than three decimal places`;
};

export const valueRequired = (name: string, required: boolean) => (value: number | null | undefined) =>
  _notNil(value) && _isNotEmpty(value) ? true : required ? `${name} is required` : true;

export const nilOrGreaterThan =
  (name: string, greaterThan: number, required: boolean, units: string = '') =>
  (value: number | string | null | undefined) => {
    const valueAsNumber = Number(value);
    if (_isNil(value) || _isEmptyString(value) || isNaN(valueAsNumber)) {
      return required ? `${name} must be greater than ${greaterThan} ${units}` : true;
    }
    return valueAsNumber > greaterThan ? true : `${name} must be greater than ${greaterThan} ${units}`;
  };
