// @ts-nocheck: converted from JS

import DatePickerNative from '@/components/UI/DatePickerNative';
import Radio from '@/components/UI/FormElements/Radio';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import { ID } from '@/model/Common.model';
import { DateUtils } from '@/utils/Date.utils';
import { ErrorMessage } from '@hookform/error-message';
import { FC, useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { SpeciesV1 } from '@/model/Species.model.ts';

interface SpeciesFormProps {
  selectableSpecies: Array<SpeciesV1>;
  defaultStrainId: ID;
  fieldPath: string;
  formMethods: any;
}

const SpeciesForm: FC<SpeciesFormProps> = ({ selectableSpecies, defaultStrainId, fieldPath, formMethods }) => {
  const [strains, setStrains] = useState([]);

  const { control, watch, getValues, setValue, trigger } = formMethods;

  const { strainIdToSpeciesId, speciesIdToStrains } = useMemo(() => {
    const strainIdToSpeciesId = new Map(
      (selectableSpecies ?? []).flatMap(({ id: speciesId, strains }) =>
        (strains ?? []).map(({ id: strainId }) => [strainId, speciesId])
      )
    );
    const speciesIdToStrains = new Map(
      (selectableSpecies ?? []).map(({ id: speciesId, strains }) => [speciesId, strains ?? []])
    );
    return { strainIdToSpeciesId, speciesIdToStrains };
  }, [selectableSpecies]);

  useEffect(() => {
    setValue(`${fieldPath}.strain_id`, '');
    setStrains(speciesIdToStrains.get(Number(event?.target?.value)) ?? []);
  }, [watch(`${fieldPath}.species_id`)]);

  if (speciesIdToStrains.size === 0) {
    return null;
  }

  return (
    <div>
      <div className="flex items-top">
        <Controller
          name={`${fieldPath}.species_id`}
          control={control}
          rules={{
            validate: (value) =>
              !(_isNotEmpty(value) && _isEmpty(getValues(`${fieldPath}.strain_id`))) ||
              'Strain is required when Species is selected',
          }}
          render={({ value, onChange, onBlur }: ControllerRenderProps) => (
            <div className="w-50 ph2">
              <label>Species</label>
              <select
                style={{ marginBottom: 0 }}
                onBlur={onBlur}
                onChange={onChange}
                defaultValue={strainIdToSpeciesId.get(Number(value ?? defaultStrainId))}
              >
                <option value="">Choose a species</option>
                {selectableSpecies.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}
        />
        <Controller
          name={`${fieldPath}.strain_id`}
          control={control}
          rules={{
            validate: () => {
              trigger(`${fieldPath}.species_id`);
            },
          }}
          render={({ value, onChange, onBlur }: ControllerRenderProps) => (
            <div className="w-50 ph2">
              <label>Strain</label>
              <select
                defaultValue={Number(value)}
                style={{ marginBottom: 0 }}
                disabled={_isEmpty(strains)}
                onChange={onChange}
                onBlur={onBlur}
              >
                <option value="">Choose a strain</option>
                {strains.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const AnimalFormFields = ({ index, name, cage_id: cageId, sex, dob, strain, selectableSpecies = [] }) => {
  const formMethods = useFormContext();
  const { register, errors, control } = formMethods;

  const fieldPath = `animals.${index}`;

  return (
    <div className="w-90 bg-light-silver ph2 pv3 br2 mr3 ">
      <input type="hidden" name={`${fieldPath}.cage_id`} value={cageId} ref={register()} />
      <div className="mb3 ph2">
        <label htmlFor={`${fieldPath}.name`}>Name</label>
        <input
          type="text"
          name={`${fieldPath}.name`}
          ref={register({ required: 'Name field is required' })}
          defaultValue={name}
          style={{ marginBottom: 0 }}
        />
        <ErrorMessage
          errors={errors}
          name={`${fieldPath}.name`}
          render={({ message }) => <p className="f6 red db pt2">{message}</p>}
        />
      </div>
      <div className="mb3 flex items-start">
        <div className="w-50 ph2">
          <label>Sex</label>
          <Controller
            name={`${fieldPath}.sex`}
            defaultValue={sex}
            control={control}
            render={({ onChange, value }: ControllerRenderProps) => (
              <div className="flex items-center">
                <Radio
                  id={`${fieldPath}.sex.m`}
                  className="pointer"
                  label="Male"
                  name={`${fieldPath}.sex`}
                  checked={value === 'm'}
                  value="m"
                  onChange={onChange}
                />
                <Radio
                  id={`${fieldPath}.sex.f`}
                  className="pointer ml3"
                  label="Female"
                  name={`${fieldPath}.sex`}
                  checked={value === 'f'}
                  value="f"
                  onChange={onChange}
                />
              </div>
            )}
          />
        </div>
        <div className="w-50 ph2">
          <label htmlFor={`${fieldPath}.dob`}>D.O.B</label>
          <Controller
            name={`${fieldPath}.dob`}
            control={control}
            rules={{ required: 'DOB is required' }}
            defaultValue={dob}
            render={({ onChange, value }: ControllerRenderProps) => (
              <DatePickerNative
                value={value}
                max={DateUtils.dateNow()}
                style={{ marginBottom: 0 }}
                onChange={onChange}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${fieldPath}.dob`}
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>
      </div>
      <SpeciesForm
        selectableSpecies={selectableSpecies}
        defaultStrainId={strain}
        fieldPath={fieldPath}
        formMethods={formMethods}
      />
      <ErrorMessage
        errors={errors}
        name={`${fieldPath}.species_id`}
        render={({ message }) => <p className="f6 red db pt2 ph2">{message}</p>}
      />
    </div>
  );
};

export default AnimalFormFields;
