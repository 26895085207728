// @ts-nocheck: converted from JS

export const hasLabel = (data) => {
  return Boolean(data.label) || 'Field label is required';
};

export const hasOptionValues = (options) => {
  const hasValue = (option) => option.value !== '' && option.value !== null && option.value !== undefined;
  return options?.every(hasValue) || 'All options must have a value';
};
