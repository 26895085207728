import { safelyDecodeURIComponent } from '@/littledash';
import type { MetadataCageCardItem } from '@/model/Metadata.model';
import { useMemo } from 'react';
import '../CageCardTemplate.scss';

interface CageCardMetadataProps {
  metadata: Array<MetadataCageCardItem>;
}

const Metadata = ({ metadata = [] }: CageCardMetadataProps) => {
  const MAX_NUMBER_METADATA = 8;
  const cappedMetadata: Array<MetadataCageCardItem> = useMemo(
    () => metadata.slice(0, MAX_NUMBER_METADATA),
    [metadata, MAX_NUMBER_METADATA]
  );
  return (
    <table className="w-100 tl black" style={{ borderSpacing: 0, fontSize: 11 }}>
      {cappedMetadata.map(({ id, title, value }) => (
        <tr className={`card-row max-${MAX_NUMBER_METADATA}`} key={id}>
          <td>
            <div className="flex flex-row justify-between">
              <div>{title}</div>
              <div>{Array.isArray(value) ? value.map((val) => safelyDecodeURIComponent(val)).join(', ') : value}</div>
            </div>
          </td>
        </tr>
      ))}
    </table>
  );
};

export default Metadata;
