// @ts-nocheck: converted from JS

import Assignees from '@/components/Glossary/Sections/Requests/Create/Summary/Assignees';
import Banner from '@/components/UI/Banner';
import FormRender from '@/components/UI/FormRender';
import Summary, { SummaryItem } from '@/components/UI/Summary';
import React, { ReactElement } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

interface RequestSummaryProps {
  ActionButtons: ReactElement;
  onSubmit: () => void;
  request: { name: string; users: Array<unknown>; form: { groups: Array<{ fields: Array<unknown> }> } };
}

const RequestSummary: React.FC<RequestSummaryProps> = ({ ActionButtons, onSubmit, request }) => {
  const formMethods = useForm({
    defaultValues: {
      fields: request.form,
    },
  });

  // a field's "name" either comes from the API if already set, or else it needs assigned by the frontend, here we are setting it.
  // it becomes the property on the form's POST payload.

  const groupsWithNames = request.form.groups.map((group, groupIdx) => ({
    ...group,
    fields: Array.isArray(group.fields)
      ? group.fields.map((field, fieldIdx) => ({ ...field, name: `groups[${groupIdx}].fields[${fieldIdx}].value` }))
      : [],
  }));

  return (
    <div data-test-component="Summary" data-test-element="container">
      <div className="mb4" style={{ maxWidth: 900 }}>
        <Summary title="Summary">
          <SummaryItem label="Request name">{request.name}</SummaryItem>
          <SummaryItem label="Assignees">
            <Assignees assignees={request.users} />
          </SummaryItem>
        </Summary>
      </div>
      <div className={'pa3 ui-card'} style={{ width: 550 }}>
        <Banner info className="mw6 mb4" dismiss={false} center>
          <h3 className="f6 normal lh-title">This is a preview of your form - it can&apos;t be submitted.</h3>
        </Banner>
        <FormProvider {...formMethods}>
          <FormRender groups={groupsWithNames} />
        </FormProvider>
      </div>

      {React.cloneElement(ActionButtons, { onOk: () => onSubmit() })}
    </div>
  );
};

export default RequestSummary;
