// @ts-nocheck: converted from JS

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import { DateRenderer, DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import UserAvatar from '@/components/UI/UserAvatar';
import { formatNumber, hasOwnProperty } from '@/helpers';
import { _isEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Measurement } from '@/model/Measurement.model';
import { Preset } from '@/model/Preset.model.ts';
import type { PresetCalculationMeasurement } from '@/model/PresetCalculationMeasurement.model';
import type { StudyApiId } from '@/model/Study.model';
import { sortDescByDateStrProperty } from '@/utils/array.ts';
import type { FC } from 'react';
import Actions from './Actions';

interface MeasurementListProps {
  preset: Preset;
  studyApiId: StudyApiId;
  subject: Animal;
  canEdit: boolean;
  measurements: Array<Measurement>;
  activeMeasurement: PresetCalculationMeasurement;
  handleCallback: (message: string, success?: boolean) => Promise<void>;
}

const MeasurementList: FC<MeasurementListProps> = ({
  preset,
  canEdit = false,
  studyApiId,
  subject,
  measurements,
  activeMeasurement,
  handleCallback,
}) => {
  const filteredMeasurements = measurements.filter((m) =>
    hasOwnProperty(m.variables ?? {}, activeMeasurement.id ?? null)
  );
  const orderedMeasurements = filteredMeasurements.sort(sortDescByDateStrProperty('measured_at'));

  return (
    <Accordion>
      {orderedMeasurements.map((m) => {
        const user = m.created_at;
        const editedUser = m.variable_list?.find((variable) => variable.name === activeMeasurement.id)?.updated_by;

        return (
          <AccordionItem key={m.id}>
            <AccordionHeader className="f6 flex flex-wrap justify-between items-center mh3">
              <span
                className="near-black"
                data-test-key={`measurement-list-summary-${m.measured_at}-${activeMeasurement.id}`}
              >
                {formatNumber(m.variables[activeMeasurement.id])} {activeMeasurement.unit}
              </span>
              <DateRenderer value={m.measured_at} />
            </AccordionHeader>
            <AccordionPanel>
              <div className="ph3 pb3 flex">
                {user && <UserAvatar user={user} tooltip={user.name ?? user.email} />}
                <div className="w-100 pl3">
                  {_notNil(editedUser) && (
                    <p className="f7 lh-copy db pb3">
                      Updated <DateTimeRenderer value={editedUser.datetime} /> by {editedUser.name || editedUser.email}
                    </p>
                  )}
                  {!_isEmpty(activeMeasurement.measurements) && (
                    <ul className="pa3 br3 br--top--left bg-near-white ma0">
                      {activeMeasurement.measurements.map((v) => (
                        <li key={v.id} className="f6 lh-copy">
                          {v.name}:{' '}
                          {m.variables?.[v.id] ? (
                            <>
                              {formatNumber(m.variables[v.id])} {v.unit}
                            </>
                          ) : (
                            ' -'
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                  {canEdit && (
                    <div className="mt2">
                      <Actions
                        studyApiId={studyApiId}
                        subject={subject}
                        preset={preset}
                        measurement={m}
                        activeMeasurement={activeMeasurement}
                        handleCallback={handleCallback}
                      />
                    </div>
                  )}
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default MeasurementList;
