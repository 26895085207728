// @ts-nocheck: converted from JS

export const canDelete = (operations) => {
  if (!operations) {
    return true;
  }
  const deleteOperation = operations.find((operation) => operation['delete'])?.delete;
  if (!deleteOperation) {
    return true;
  }
  return Boolean(deleteOperation.available);
};
