// @ts-nocheck: converted from JS

import { FormBuilderItem, Header } from '@/components/FormBuilder';
import { ErrorTypes, nonFormFieldTypes } from '@/components/FormBuilder/FormBuilder.utils';
import List from '@/components/UI/List';
import { asSortableListItem } from '@/components/UI/List/List';
import { swapArrayItems } from '@/helpers';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

const columns = [
  {
    id: 'fieldType',
    title: 'Field Type',
    width: 200,
  },
  {
    id: 'fieldProperties',
    title: 'Field Properties',
    width: 500,
  },
];

const FormBuilder = ({ ActionButtons, onSubmit, defaultValue }) => {
  const [updatedFields, setUpdatedFields] = useState();

  useEffect(() => {
    if (updatedFields) {
      resetFormItems(updatedFields);
    }
  }, [updatedFields]);

  const defaultOption = {
    label: '',
    value: '',
  };

  const formMethods = useForm({
    defaultValues: {
      formItems: defaultValue || [
        {
          label: '',
          type: '',
          options: [defaultOption],
          required: undefined,
        },
      ],
    },
  });

  const { control, handleSubmit, setError, getValues, errors, clearErrors, reset: resetFormItems } = formMethods;

  const {
    fields: formItems,
    append: appendToFormItems,
    remove: removeFromFormItems,
  } = useFieldArray({
    control,
    name: 'formItems',
  });

  const handleRemoveFormItemClick = (id) => {
    clearErrors('formItems');
    removeFromFormItems(id);
  };

  const handleAddFormItemClick = () => {
    clearErrors('formItems');
    appendToFormItems({
      label: '',
      type: '',
      options: [defaultOption],
    });
  };

  const validateFormItems = (formItems) => {
    const isFormField = (formItem) => !nonFormFieldTypes.includes(formItem.type);
    return formItems.some(isFormField);
  };

  const handleFormSubmit = (formInputs) => {
    // validate formItems as a whole, individual form item validation will be handled by themselves
    const valid = validateFormItems(formInputs);
    if (valid) {
      onSubmit(formInputs);
    } else {
      setError('formItems', {
        type: ErrorTypes.formFieldRequired,
        message: 'At least one row must be a form element.',
      });
    }
  };

  const handleFieldTypeChange = () => {
    clearErrors('formItems');
  };

  return (
    <FormProvider {...formMethods}>
      <div className="ba br1" data-test-component="FormBuilder" data-test-element="container">
        <Header labels={columns} />
        <List
          canAmend
          canAdd
          canRemove={formItems.length > 1}
          data={formItems}
          renderItem={asSortableListItem({
            canSort: formItems?.length > 1,
            sortableType: 'form-item',
            moveCallback: (item1, item2) => {
              const currentValues = getValues()?.formItems;
              if (Array.isArray(currentValues)) {
                const reOrderedValues = swapArrayItems(currentValues, item1, item2);

                setUpdatedFields({
                  formItems: reOrderedValues,
                });
              }
            },
          })(FormBuilderItem, {
            columns,
            onFieldTypeChange: handleFieldTypeChange,
          })}
          onAddClick={handleAddFormItemClick}
          onRemoveClick={handleRemoveFormItemClick}
          addButtonText={'Add field'}
          isRemoveButtonFluid={false}
          containerProps={{
            className: 'bg-white br3 br--bottom',
          }}
          itemProps={{
            className: 'pa3 bb items-center',
          }}
          addButtonProps={{
            className: 'ml3 mb3',
          }}
        />
        <ErrorMessage
          errors={errors}
          name="formItems"
          render={({ message }) => <p className="bg-white pa3 f6 red db pt2">{message}</p>}
        />
      </div>
      {React.cloneElement(ActionButtons, {
        onOk: handleSubmit((data) => handleFormSubmit(data.formItems)),
      })}
    </FormProvider>
  );
};

export default FormBuilder;
