import type { BaseUser } from '@/model/User.model';
import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const Intercom: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentUser } = useSelector<
    { user: { currentUser: BaseUser & { user_hash: string } } },
    { currentUser: BaseUser & { user_hash: string } }
  >(({ user: { currentUser } }) => ({ currentUser }));

  useEffect(() => {
    if (AppConfig.intercomEnabled && !window.Intercom) {
      window.intercomSettings = {
        app_id: AppConfig.intercomAppId,
        hide_default_launcher: true,
      };
    }
  }, []);

  useEffect(() => {
    if (AppConfig.intercomEnabled && window.Intercom && currentUser) {
      window.intercomSettings = {
        app_id: AppConfig.intercomAppId,
        name: currentUser.name,
        email: currentUser.email,
        created_at: new Date(currentUser.created_at).getTime() / 1000,
        user_hash: currentUser.user_hash,
        hide_default_launcher: true,
      };

      window.Intercom('boot', window.intercomSettings);
    }

    if (window.Intercom && !currentUser) {
      window.Intercom('shutdown');
    }
  }, [currentUser]);

  return <>{children}</>;
};

export default Intercom;
