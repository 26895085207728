import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import type { Endpoints } from '@/generated/internal-api/Endpoints';
import { hasOwnProperty } from '@/helpers';
import { _chunk, _isNotEmpty } from '@/littledash';
import type { ExistingMeasurement } from '@/model/Measurement.model';
import type { Study } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import { useEffect, useState } from 'react';
import type { ReviewData } from '../Review/Review';
import { handlePayload } from './Complete.utils';
import Progress from './ImportProgress';
import Synopsis from './Synopsis';

interface ImportCompleteProps {
  study: Study;
  date: string;
  reviewData: Array<ReviewData>;
  existingMeasurements: Array<ExistingMeasurement>;
  backStep: (step: string) => void;
}

export type MeasurementPayload =
  Endpoints['POST /api/v1/studies/{studyId}/measurements']['request']['measurements'][number] &
    Endpoints['PATCH /api/v1/studies/{studyId}/measurements']['request']['measurements'][number];

const Complete = ({ study, date, reviewData, existingMeasurements, backStep }: ImportCompleteProps) => {
  const [errors, setErrors] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [complete, setComplete] = useState<boolean>(false);

  const asyncAwaitMeasurements = async (measurements: Array<MeasurementPayload>, chunkSize: number = 25) => {
    const chunkedMeasurements = _chunk(
      measurements.filter((m) => _isNotEmpty(m.variables)),
      chunkSize
    );

    try {
      for (let index = 0; index < chunkedMeasurements.length; index++) {
        const payload = chunkedMeasurements[index];
        const patches = payload.filter((r) => !hasOwnProperty(r, 'subject_id'));
        const posts = payload.filter((r) => hasOwnProperty(r, 'subject_id'));

        if (_isNotEmpty(posts)) {
          const response = await ApiService.call({
            endpoint: 'POST /api/v1/studies/{studyId}/measurements',
            path: { studyId: study.api_id },
            body: { measurements: posts },
          });
          if (response.type === 'error') {
            setErrors(true);
          }
        }
        if (_isNotEmpty(patches)) {
          const response = await ApiService.call({
            endpoint: 'PATCH /api/v1/studies/{studyId}/measurements',
            path: { studyId: study.api_id },
            body: { measurements: patches },
          });
          if (response.type === 'error') {
            setErrors(true);
          }
        }

        setProgress(((index + 1) / chunkedMeasurements.length) * 100);
        if (index + 1 === chunkedMeasurements.length) {
          return setComplete(true);
        }
      }
    } catch {
      return setErrors(true);
    }
  };

  useEffect(() => {
    if (started) {
      if (_isNotEmpty(reviewData)) {
        const reviewDataWithoutErrors = reviewData.filter((r) => !hasOwnProperty(r, 'errors'));

        const allMeasurements = reviewDataWithoutErrors.map((r) => {
          const existingMatch = existingMeasurements.find((m) => Number(m.subject_id) === Number(r.id));
          let payload = handlePayload(r.metrics, date, null, r.id);

          if (existingMatch) {
            payload = handlePayload(r.metrics, null, existingMatch.measurement_id);
          }

          return payload;
        });

        asyncAwaitMeasurements(allMeasurements);
      }
    }
  }, [started]);

  return (
    <div className="center mw6 mv4">
      {errors && (
        <Banner critical dismiss={false} className="mw6 mb3">
          <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
          <p className="f6 pb3 lh-copy">
            An error has occurred while importing your data, please try again later. If this keeps occurring please
            contact support.
          </p>
          <Button outline critical url={'mailto:support@benchling.com'}>
            Contact support
          </Button>
        </Banner>
      )}
      <div className="ui-card pa3 pa4-l mb3 tc">
        {!started ? (
          <Synopsis date={date} setStated={setStarted} reviewData={reviewData} backStep={backStep} />
        ) : (
          <Progress study={study} complete={complete} progress={progress} />
        )}
      </div>
    </div>
  );
};

export default Complete;
