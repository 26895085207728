// @ts-nocheck: converted from JS

import { Azure, Okta } from './Providers';
import { _isNotEmpty } from '@/littledash';

export const ssoProviders = ['azure', 'okta'];
export const providerNameMap = {
  azure: 'Active Directory (Azure)',
  okta: 'Okta',
};
export const providerFormRenderMap = {
  azure: <Azure />,
  okta: <Okta />,
};

export const generateDefaultValues = ({ sso_providers: ssoProviders = [] }) => {
  if (_isNotEmpty(ssoProviders)) {
    return ssoProviders.reduce((acc, { type, enabled, settings }) => {
      acc[type] = {
        enabled,
        ...settings,
      };
      return acc;
    }, {});
  }
  return {};
};
