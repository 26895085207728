import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import WithAside from '@/components/UI/FormElements/WithAside';
import { NumberInput as Number } from '@/components/UI/FormFields/Number';
import { toWorkflowDateTime } from '@/components/Workflow/Show/Workflow.utils';
import { _notNil, _toNumber } from '@/littledash';
import type { ID, ISODateTime } from '@/model/Common.model';
import type { Treatment, TreatmentCalculation, TreatmentField, TreatmentMethod } from '@/model/Treatment.model';
import { useRequest } from '@/support/Hooks/request';
import { decimalPlacesValidator, nilOrGreaterThan, valueRequired } from '@/validators';
import { ErrorMessage } from '@hookform/error-message';
import type React from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { calculateDoseFormula, initialDoseFormDefaultState } from './AddDose.utils';

type Disabled = boolean;

const buildConversionFormulas = (fields: Array<TreatmentField>): Record<string, string> =>
  fields.reduce<Record<string, string>>((acc, field) => {
    if (_notNil(field.unit.base_conversion_formula)) {
      acc[field.name] = field.unit.base_conversion_formula;
    }
    return acc;
  }, {});

interface AnimalDoseProps {
  animalId: ID;
  bodyweight?: string;
  workflowDate: string;
}

interface AddDoseFormProps extends AnimalDoseProps {
  treatment: Treatment;
  disabledSubmit?: boolean;
  disabled?: Disabled;
  onSave: (response: any) => void;
  takingWeight?: boolean;
  autoFocus?: boolean;
}

export interface DoseValues {
  dose?: string | null;
  stock?: string | null;
  volume?: string | null;
  dose_volume?: string | null;
  weight_at_dosing?: string | null;
}

type Notes = string | null;

export interface FormValues {
  values: DoseValues;
  notes: Notes;
}

interface Payload extends DoseValues {
  dosed_at: ISODateTime;
  treatment_id: ID;
  notes: Notes;
}

const AddDose: React.FC<AddDoseFormProps> = ({
  animalId,
  takingWeight,
  bodyweight,
  treatment,
  disabledSubmit = false,
  disabled = false,
  onSave,
  workflowDate,
  autoFocus = false,
}) => {
  const conversionFormulas: Record<string, string> = useMemo(
    () => buildConversionFormulas(treatment.fields),
    [treatment]
  );
  const defaultValues = useMemo(() => initialDoseFormDefaultState(treatment?.fields), [treatment]);
  const formMethods = useForm<FormValues>({ defaultValues });
  const { handleSubmit, errors, register } = formMethods;
  const {
    sendRequest: saveDose,
    requestSending: doseSaving,
    requestError,
  } = useRequest({
    params: { animalId },
    route: 'animals.dose.create',
    method: 'post',
  });

  const handlePayload = ({ notes, values }: FormValues): Payload => {
    let valuesPayload = {};

    if (_notNil(values)) {
      valuesPayload = Object.entries(values).reduce(
        (acc, [field, value]) => ({
          ...acc,
          [field]: _toNumber(value)?.toString() ?? null,
        }),
        {}
      );
    }
    return {
      dosed_at: toWorkflowDateTime(workflowDate),
      treatment_id: treatment?.id ?? '',
      weight_at_dosing: takingWeight ? bodyweight : undefined,
      notes: notes ?? null,
      ...valuesPayload,
    };
  };

  const onSubmit = async (data: FormValues): Promise<void> => {
    const response = await saveDose(handlePayload(data));
    if (_notNil(onSave)) {
      onSave(response);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <>
          {requestError && <ApiErrorBanner error={requestError} />}
          {(treatment?.fields ?? []).map((field: TreatmentField, index) => {
            return (
              <div className="ph3 mb3" key={`${treatment?.id}_${field.name}`}>
                <DoseField
                  treatmentName={treatment.id.toString()}
                  field={field}
                  disabled={disabled}
                  autoFocus={autoFocus && index === 0}
                />
                <ErrorMessage
                  errors={errors}
                  name={`values.${field['name']}`}
                  render={({ message }) => <small className="db red pt2">{message}</small>}
                />
              </div>
            );
          })}
          {_notNil(treatment.calculates) && (
            <div className="ph3">
              <DoseFormula
                treatmentName={treatment.id.toString()}
                bodyweight={bodyweight}
                calculation={treatment.calculates}
                conversionFormulas={conversionFormulas}
                treatmentType={treatment.type}
              />
            </div>
          )}
          <div className="mt3 ph3">
            <label htmlFor="notes">Notes</label>
            <textarea
              name="notes"
              disabled={disabled}
              style={{ marginBottom: 0 }}
              ref={register({
                maxLength: {
                  value: 255,
                  message: 'Maximum of 255 characters',
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="comments"
              render={({ message }) => <small className="db red pt2">{message}</small>}
            />
          </div>
          <Button
            testId={`${treatment.id}-dose-save`}
            className="mh3 mt3 dose-submit"
            submit
            disabled={doseSaving || disabledSubmit || disabled}
            tooltip={disabledSubmit ? 'A body weight is required' : ''}
          >
            Save
          </Button>
        </>
      </form>
    </FormProvider>
  );
};

interface DoseFieldProps {
  treatmentName: string;
  field: TreatmentField;
  disabled: Disabled;
  autoFocus?: boolean;
}

const DoseField: React.FC<DoseFieldProps> = ({ treatmentName, field, disabled, autoFocus = false }) => {
  const { control, errors } = useFormContext();
  const isError = _notNil(errors?.values?.[field?.name]);
  return (
    <>
      {_notNil(field.label) && <label htmlFor={`${treatmentName}.${field?.name}.value`}>{field.label}</label>}
      <Controller
        name={`values.${field?.name}`}
        defaultValue=""
        rules={{
          required: field.required ? `${field.label} is required` : undefined,
          validate: {
            valueRequired: valueRequired(field.label, field.required),
            decimalPlacesValidator: decimalPlacesValidator(field.label),
            greaterThan: nilOrGreaterThan(field.label, 0, field.required),
          },
        }}
        control={control}
        render={({ onChange, value }: ControllerRenderProps) => (
          <WithAside
            style={{ width: 160 }}
            render={<small className="ma2 lh-title near-black bg-white">{field?.unit?.display_unit ?? ''}</small>}
          >
            <Number
              id={`${treatmentName}.${field?.name}.value`}
              className={isError ? 'input__error' : ''}
              onChange={onChange}
              value={value}
              step="0.01"
              style={{ marginBottom: 0 }}
              disabled={disabled}
              autoFocus={autoFocus}
            />
          </WithAside>
        )}
      />
    </>
  );
};

interface DoseFormulaProps {
  treatmentName: string;
  bodyweight?: string;
  calculation: TreatmentCalculation;
  conversionFormulas: Record<string, string>;
  treatmentType: TreatmentMethod;
}

const DoseFormula: React.FC<DoseFormulaProps> = ({
  treatmentName,
  bodyweight,
  calculation,
  conversionFormulas,
  treatmentType,
}) => {
  const { watch } = useFormContext();
  const formula = calculation?.formula ?? '';
  let formulaResult: string | null = '-';

  if (_notNil(calculation?.formula)) {
    formulaResult = calculateDoseFormula(bodyweight, watch('values'), formula, conversionFormulas, treatmentType);
  }

  return (
    <div className="mt3">
      <div className="pt2" data-testid={`${treatmentName}-calculation-result`}>
        {_notNil(calculation?.label) && <label>{calculation?.label}</label>}
        {_notNil(formulaResult) && (
          <div
            className="lh-title near-black dib bg-near-white br2 pa2"
            data-testid={`${treatmentName}-calculation-result-value`}
          >
            <h3 className="f3 normal dib">
              {formulaResult !== 'Infinity' && formulaResult !== 'NaN' ? formulaResult : '-'}
            </h3>
            {_notNil(calculation?.unit?.display_unit) && (
              <small className="f5 lh-title dib pl2">{calculation?.unit.display_unit}</small>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddDose;
