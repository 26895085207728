import { all, create, EvalFunction } from 'mathjs';

const formulaEngine = create(all);
const AVERAGE = (...inputs: Array<number>): number => formulaEngine.mean(inputs);
AVERAGE.transform = (...inputs: Array<number>) => AVERAGE(...inputs.filter(Number.isFinite));
formulaEngine.import({ AVERAGE });
const piFunctionPattern = /pi\(\)/gi;
const compileFormula = (formula: string): EvalFunction =>
  formulaEngine.compile(formula.replaceAll(piFunctionPattern, `${Math.PI}`));

export { compileFormula, formulaEngine };
