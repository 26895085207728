import { _notNil } from '@/littledash';
import _throttle from 'lodash/throttle';
import type { ReactNode } from 'react';
import { forwardRef, useCallback, useLayoutEffect } from 'react';
import type { DataTableScrollService } from '../DataTable.model';
import styles from '../DataTable.module.scss';
import DataTableScrollBars from './DataTableScrollBars';

interface DataTableScrollProps {
  children: ReactNode;
  dataTableScrollService: DataTableScrollService | null;
}

const WHEEL_EVENT_THROTTLE_THRESHOLD = {
  x: 50,
  y: 25,
};
const THROTTLE_OPTIONS = { trailing: true, leading: true };

const DataTableScrollWrapper = forwardRef<HTMLDivElement, DataTableScrollProps>(
  ({ children, dataTableScrollService }, ref: any) => {
    const preventScroll = useCallback((event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }, []);
    const handleWheel = useCallback(
      (event: any) => {
        if (_notNil(dataTableScrollService)) {
          const { deltaX, deltaY } = event;
          if (dataTableScrollService.isScrollingLeft(deltaX) || dataTableScrollService.isScrollingRight(deltaX)) {
            dataTableScrollService.scrollDataTableWindowX(deltaX);
          }
          if (dataTableScrollService.isScrollingUp(deltaY) || dataTableScrollService.isScrollingDown(deltaY)) {
            dataTableScrollService.scrollDataTableWindowY(deltaY);
          }
        }
      },
      [dataTableScrollService]
    );

    useLayoutEffect(() => {
      const dataTableElement = ref?.current ?? null;
      dataTableElement?.addEventListener('wheel', preventScroll);
      dataTableElement?.addEventListener(
        'wheel',
        _throttle(handleWheel, WHEEL_EVENT_THROTTLE_THRESHOLD.x, THROTTLE_OPTIONS),
        {
          passive: true,
          capture: true,
        }
      );
      dataTableElement?.addEventListener(
        'wheel',
        _throttle(handleWheel, WHEEL_EVENT_THROTTLE_THRESHOLD.y, THROTTLE_OPTIONS),
        {
          passive: true,
          capture: true,
        }
      );
      return () => {
        dataTableElement?.removeEventListener('wheel', preventScroll);
      };
    }, [ref, dataTableScrollService]);

    return (
      <div ref={ref} className={styles['data-table-container']}>
        {children}
        <DataTableScrollBars dataTableScrollService={dataTableScrollService} />
      </div>
    );
  }
);

export default DataTableScrollWrapper;
