import { useState, useEffect, useRef } from 'react';
import type { ColorDetail, ColorKey } from '@/constants/colors';
import { getColorDetail, colorKeys, defaultColor } from '@/constants/colors';
import { _isEmpty, _isNil, _notNil } from '@/littledash';

interface UseColorsProps {
  items: string[];
}
export type ColorMap = Record<ColorKey, string | null>;

export const initialColorMap = (): ColorMap =>
  colorKeys.reduce((acc: Record<string, null>, key) => {
    acc[key] = null;
    return acc;
  }, {}) as ColorMap;

export const getNextFreeColorFromMap = (colorMap: ColorMap, items: string[], item: string): ColorKey | null => {
  for (const [key, value] of Object.entries(colorMap)) {
    // is the colour free, not present in `items` or is equal to an `item` that has been previously set
    if (_isNil(value) || !items.includes(value) || item === value) {
      return key as ColorKey;
    }
  }
  return null;
};

/** A custom hook that takes an array of items (eg. an API ID)
 * and assigns a persisted colour, returning a map of item -> color swatch */

const useColors = ({ items }: UseColorsProps): { itemColors: Record<string, ColorDetail> } => {
  const colorMap = useRef<ColorMap>(initialColorMap());
  const [itemColors, setItemColors] = useState<Record<string, ColorDetail>>({});

  useEffect(() => {
    if (_notNil(items)) {
      if (_isEmpty(items)) {
        // if the array is empty, reset the map
        colorMap.current = initialColorMap();
      }

      const updateItemColors = items.reduce((acc: Record<string, ColorDetail>, item: string) => {
        if (_notNil(itemColors[item])) {
          // Already exists persit previous state
          acc[item] = itemColors[item];
        } else {
          // Check if there is a null space or old value
          const nextColorKey = getNextFreeColorFromMap(colorMap.current, items, item);
          if (_notNil(nextColorKey)) {
            acc[item] = getColorDetail(nextColorKey);
            // update the map with the allocated item
            colorMap.current = { ...colorMap.current, [nextColorKey]: item };
          } else {
            // Default if the map is full
            acc[item] = defaultColor;
          }
        }
        return acc;
      }, {});
      setItemColors(updateItemColors);
    }
  }, [items]);

  return { itemColors };
};

export default useColors;
