// @ts-nocheck: converted from JS

import { FormField } from '@/components/UI/FormFields';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils';
import { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';

interface RadioProps {
  options?: any;
  name?: any;
  label?: any;
  disabled?: any;
  hint?: any;
  required?: any;
  testId?: any;
}

const Radio: FC<RadioProps> = ({ options, name, label, disabled = false, hint, required, testId = 'radio' }) => {
  const { register, watch } = useFormContext();
  const value = watch(name);
  return (
    <FormField className="pt2" label={label} name={name} hint={hint} required={required} testPrefix={testId}>
      {options.map((option, i) => (
        <div className="flex items-center pb2" key={option.value}>
          <label htmlFor={`${name}_${i}`}>
            <input
              id={`${name}_${i}`}
              data-test-component="Radio"
              data-test-element="input"
              data-test-key={option.value}
              type="radio"
              defaultChecked={value === option.value}
              name={name}
              ref={register(getRegisterConfig({ required }))}
              value={option.value}
              className="pointer ui__form__radio"
              style={{ marginBottom: 0 }}
              disabled={disabled}
              data-testid={testId}
            />
            <span className="pointer pl3 v-top" style={{ marginBottom: 0 }} htmlFor={option.value}>
              {option.label}
            </span>
          </label>
        </div>
      ))}
    </FormField>
  );
};

export default Radio;
