// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Link from '@/components/UI/Link';
import { _isEmpty } from '@/littledash';
import * as Form from '@/support/form';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import React from 'react';
import { useDispatch } from 'react-redux';
import validationModule from 'validate.js';
import { updateExistingTasksAPI } from './Create.utils';
import NewTask from './NewTask';

const CreateTemplateForm = ({ group, callBackFn, modal }) => {
  const activeTask = React.useRef();
  const dispatch = useDispatch();

  const defaultTask = {
    description: '',
    period: 'daily',
    frequency: null,
    frequency_settings: [],
    task_type: null, // recurring, conditional
    conditional_settings: {
      type: 'age',
      value: null,
    },
  };

  const validationSchema = {
    name: {
      presence: { allowEmpty: false },
      length: { minimum: 3, maximum: 50 },
    },
  };

  const getDefaultFields = () => {
    const template = {
      fields: {
        name: '',
      },
      tasks: [defaultTask],
    };

    if (!_isEmpty(group)) {
      template.fields.name = group.name;
      template.tasks = [...group.taskTemplates];
    }

    return template;
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [tasks, setTasks] = React.useState(getDefaultFields().tasks);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fields, setFields] = React.useState(Form.fieldObject(getDefaultFields().fields));
  const [errors, setErrors] = React.useState(Form.errorObject(getDefaultFields().fields));

  const [submissionError, setSubmissionError] = React.useState(null);

  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const validate = () => {
    const results = validationModule(fields, validationSchema);
    // clear out all the old errors
    setErrors(Form.errorObject(getDefaultFields()));

    // set the errors from the validator
    if (results) {
      setErrors(results);
    }

    // If we have errors, return false
    return !results;
  };

  const handleInput = (e) => {
    setErrors(Form.errorObject(getDefaultFields()));
    const name = e.target.name;
    const value = e.target.value;

    fields[name] = value;

    setFields({
      ...fields,
    });
  };

  const disabled = isLoading || Form.isErrorState(errors);

  const deleteTemplate = () => {
    return Http.delete(apiRoute('tasks.templateGroups.delete', { id: group.id }))
      .then(() => callBackFn())
      .catch((error) => {
        window.scrollTo(0, 0);
        setIsLoading(false);
        setSubmissionError(error.response.data.error);
      });
  };

  const canProgress = () => {
    return validate() && activeTask?.current?.validate();
  };

  const submitForm = (event) => {
    event.preventDefault();

    if (canProgress()) {
      setIsLoading(true);

      const saveUrl = group
        ? apiRoute('tasks.templateGroups.update', {
            id: group.id,
          })
        : apiRoute('tasks.templateGroups.create');

      const method = group ? 'put' : 'post';

      const saveTemplateGroupFn = () =>
        Http[method](saveUrl, templatePayload())
          .then(({ data }) => callBackFn(data))
          .catch((error) => {
            window.scrollTo(0, 0);
            setIsLoading(false);
            setSubmissionError(error.response.data.error);
          });

      if (group) {
        updateExistingTasksAPI(group.taskTemplates, tasks, group.id)
          .then(() => {
            saveTemplateGroupFn();
          })
          .catch((error) => {
            setIsLoading(false);
            setSubmissionError(error.response.data.error);
          });
      } else {
        saveTemplateGroupFn();
      }
    }
  };

  const templatePayload = () => {
    return {
      ...fields,
      taskTemplates: tasks,
    };
  };

  const updateStep = (step) => {
    if (canProgress()) {
      setActiveStep(step);
    }
  };

  const updateTask = (index, value) => {
    const updatedTasks = [...tasks];
    if (value?.task_type === 'recurring' && value.conditional_settings) {
      delete value.conditional_settings;
    } else if (value?.task_type === 'conditional') {
      delete value.frequency;
      delete value.frequency_settings;
    }
    updatedTasks[index] = value;
    setTasks(updatedTasks);
  };

  const addNewTask = () => {
    if (canProgress()) {
      const increment = tasks.length;
      updateTask(increment, defaultTask);
      setActiveStep(increment);
    }
  };

  const deleteTask = (index) => {
    tasks.splice(index, 1);
    const decrement = Number(activeStep) - 1;
    setTasks(tasks);
    setActiveStep(decrement);
  };

  const lockedGroup = group && group.locked;

  return (
    <React.Fragment>
      <form onSubmit={submitForm}>
        <div>
          <h2 className="normal f4 near-black">{group ? `Editing: ${group.name}` : 'Create a new template'}</h2>
          <p>
            Templates are reusable sets of tasks to be completed on a regular basis.{' '}
            <Link
              openTab
              className="link blue"
              to="https://help.benchling.com/hc/en-us/articles/20133802456077-Task-Management"
            >
              Read More
            </Link>
          </p>
        </div>

        {lockedGroup && (
          <div className="bg-red br2 pa3 mt3 light-gray w-50">This template group is locked and cannot be edited.</div>
        )}

        <div className="mv3">
          <label htmlFor="name">Template name</label>
          <input
            type="text"
            name="name"
            value={fields.name}
            onChange={handleInput}
            readOnly={lockedGroup}
            placeholder="New template name"
            autoFocus
          />
          <Form.FieldError errors={errors} name="name" />
        </div>

        {submissionError && submissionError.message === 'task_template_group_locked' && (
          <TemplateLockedError error={submissionError} />
        )}

        <div className="ui-card flex justify-between ">
          <div className="pa3 br b-moon-gray" style={{ width: '280px' }}>
            <h3 className="normal f5 lh-title near-black pb3">Tasks</h3>
            {tasks.map((t, i) => (
              <a
                className={`dib w-100 mb3 f5 lh-copy pa2 br2 near-black ${
                  i === activeStep ? 'blue bg-light-gray link' : ''
                }`}
                key={i}
                onClick={() => updateStep(i)}
              >
                {t.description || `Task ${i + 1}`}
              </a>
            ))}
            <Button pill paleBlue icon="add_new" className="mr3" onClick={addNewTask} disabled={lockedGroup}>
              Add another task
            </Button>
          </div>
          <div className="pv3 ph4 w-100">
            <div>
              {!_isEmpty(tasks) && (
                <TaskRepeater activeStep={activeStep} deleteTask={deleteTask}>
                  {tasks.map((t, i) => (
                    <NewTask
                      ref={activeTask}
                      key={i}
                      index={i}
                      task={t}
                      updateTask={updateTask}
                      defaultTask={defaultTask}
                    />
                  ))}
                </TaskRepeater>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between pv4">
          <div>
            <Button submit className="mr3" disabled={disabled || lockedGroup} loading={isLoading}>
              Save template
            </Button>
            {modal ? (
              <Button plain onClick={closeModal}>
                Cancel
              </Button>
            ) : (
              <Button plain url={webRoute('tasks.templates')}>
                Cancel
              </Button>
            )}
          </div>
          {group && (
            <Button
              critical
              disabled={lockedGroup}
              onClick={() =>
                openModal('CONFIRM_DELETE_TEMPLATE', {
                  onClick: () => deleteTemplate(),
                })
              }
            >
              Delete this template
            </Button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

const TaskRepeater = ({ activeStep, children, deleteTask }) => {
  return (
    <div className="pb3">
      {children[activeStep]}
      <div>
        {children.length > 1 && (
          <Button onClick={() => deleteTask(activeStep)} plain>
            Remove task
          </Button>
        )}
      </div>
    </div>
  );
};

const TemplateLockedError = ({ error }) => (
  <div className="pa3 mb3 bb b--moon-gray">
    <h3 className="f4 normal basier-med lh-title red pb2">This template is locked</h3>
    <p className="lh-copy">
      Templates assigned to active studies (not complete) can not be edited or deleted. To continue, remove this
      template from each study settings page.
    </p>
    <p className="pt2 lh-copy">This template has been assigned to the following studies:</p>
    {!_isEmpty(error.studies) && (
      <ul className="list mv2 ml3">
        {error.studies.map((s) => (
          <li className="near-black lh-copy" key={s.id}>
            - {s.name}
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default CreateTemplateForm;
