// @ts-nocheck: converted from JS

import { _noop } from '@/littledash.ts';
import React from 'react';

interface PaginationProps {
  current_page: number;
  per_page: number;
  total_pages: number;
  handleChange: (data: Pick<PaginationProps, 'per_page' | 'current_page'>) => void;
  pageSizes?: Array<number>;
}

const Pagination: React.FC<PaginationProps> = ({
  current_page = 1,
  per_page = 10,
  total_pages = 1,
  handleChange = _noop,
  pageSizes = [10, 25, 50, 1000],
}) => {
  const baseClasses = 'dib pointer hover-near-black noselect';
  const canNextPage = current_page !== total_pages;
  const canPreviousPage = current_page !== 1;

  return (
    <div className="flex justify-between flex-wrap items-center pa3">
      <div>
        <a
          className={`${baseClasses} pr2`}
          onClick={() => handleChange({ per_page, current_page: 1 })}
          disabled={current_page === 1}
        >
          «
        </a>
        <a
          className={`${baseClasses} ph2`}
          onClick={() => handleChange({ per_page, current_page: current_page - 1 })}
          disabled={!canPreviousPage}
        >
          ←
        </a>
        <a
          className={`${baseClasses} ph2`}
          onClick={() => handleChange({ per_page, current_page: current_page + 1 })}
          disabled={!canNextPage}
        >
          →
        </a>
        <a
          className={`${baseClasses} ph2`}
          onClick={() => handleChange({ per_page, current_page: total_pages })}
          disabled={!canNextPage}
        >
          »
        </a>
      </div>
      <div className="f6 tc noselect" data-testid="table__per-page">
        View
        <select
          className="f6 mh2 w3 mv0"
          value={per_page}
          onChange={(e) =>
            handleChange({
              current_page: 1,
              per_page: parseInt(e.target.value, 10),
            })
          }
          data-testid="table__per-page-options"
        >
          {pageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        per page
      </div>
      <div>
        <span className="f6 near-black lh-title">
          Showing page {current_page} of {total_pages}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
