// @ts-nocheck: converted from JS

import StepLinkList from '@/components/UI/StepLinkList';

const Layout = ({
  title,
  titlePadding,
  steps,
  currentStep,
  jumpToStep,
  horizontal,
  content,
  errorBanner: ErrorBanner,
  state,
  dispatch,
  testPrefix,
}) => {
  const classes = {
    title: horizontal ? 'w-50' : 'w-100',
    sidebar: horizontal ? 'w-50 tr' : 'w-20',
    content: horizontal ? 'w-100' : 'w-80',
  };

  return (
    <div className={'flex flex-wrap w-100'}>
      <div className={`${classes.title} ${titlePadding || 'pb4'}`}>
        {title && <h3 className="normal lh-title f3">{title}</h3>}
      </div>
      <div className={`${classes.sidebar}`}>
        {horizontal ? (
          <CheckList steps={steps} currentStep={currentStep} jumpToStep={jumpToStep} />
        ) : (
          <LinkList steps={steps} currentStep={currentStep} jumpToStep={jumpToStep} testPrefix={testPrefix} />
        )}
      </div>
      {ErrorBanner && <ErrorBanner state={state} dispatch={dispatch} />}
      <div className={`${classes.content}`}>{content}</div>
    </div>
  );
};

const LinkList = ({ steps, currentStep, jumpToStep, testPrefix }) => (
  <ol data-testid={`${testPrefix}__navlink`}>
    {steps.map((step, i) => (
      <li
        className={`pb3 f5 ${step.disabled && 'ui__disabled'}`}
        key={step.name}
        data-testid={`${testPrefix}__navlink--${step.name.toLowerCase()}`}
      >
        <a
          data-test-component="LinkList"
          data-test-element="link"
          data-test-key={step.name}
          className={`pointer ${
            i === currentStep && !step.disabled ? 'near-black basier-med' : 'link blue underline-hover'
          }`}
          onClick={() => jumpToStep(i)}
        >
          {step.name}
        </a>
      </li>
    ))}
  </ol>
);

const CheckList = ({ steps, currentStep, jumpToStep }) => {
  const reducedSteps = steps.reduce((acc, v, i) => {
    acc.push({
      title: v.name,
      complete: currentStep > i,
    });
    return acc;
  }, []);

  return (
    <StepLinkList
      className="ml-auto"
      style={{ width: '370px' }}
      steps={reducedSteps}
      activeStep={currentStep + 1}
      jumpToStep={jumpToStep}
    />
  );
};

export default Layout;
