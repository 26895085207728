import type { ModalProps } from '@/components/Studies/Randomization/Show';
import { sumPValues } from '@/components/Studies/Randomization/Steps/Randomize/Statistics.ts';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import Radio from '@/components/UI/FormElements/Radio';
import Switch from '@/components/UI/FormElements/Switch';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import Table from '@/components/UI/Table';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import { ApiID } from '@/model/Common.model';
import type { State } from '@/model/State.model';
import './Randomize.scss';
import { Dispatch, type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import BlockAllocationService from './BlockAllocation/BlockAllocationService';
import {
  RandomizationMethodKeys,
  RandomizationResult,
  RandomizationResultFlattened,
  RandomizeMetric,
  RandomizeOutput,
  RandomizeState,
  ResultsTable,
  SubjectAttribute,
} from './Randomize.model';
import {
  allAttrVariantsPresent,
  animalsWithSignificantMeasurement,
  consolidateAttributeValues,
  enabledMetricsForRandomize,
  randomiseSubjects,
  resultsTableTransformer,
  subjectAttrs,
  subjectMetrics,
  subjectValueMetrics,
} from './Randomize.utils';

const MAX_METRIC_SIZE = 3;
const K_SIZE = 4;

export interface RandomizeProps {
  state: RandomizeState;
  dispatch: Dispatch<StepFormAction<RandomizeState>>;
  props: ModalProps;
  openModal: (modal: string, props: Partial<ModalProps>) => void;
  closeModal: () => void;
}
interface MethodChoiceOptionProps {
  title: string;
  text: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  hideRadio?: boolean;
}
interface ClusteringFormProps {
  selectedAttribute: RandomizeOutput['selectedAttribute'];
  selectedMetrics: RandomizeOutput['selectedMetrics'];
  onAttributeChange: (value: SubjectAttribute) => void;
  subjects: Array<Animal>;
  metrics: Array<RandomizeMetric>;
  disabledMetrics?: Array<RandomizeMetric>;
  handleMetricChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}
interface BlockAllocationFormProps {
  selectedMetrics: RandomizeOutput['selectedMetrics'];
  metrics: Array<RandomizeMetric>;
  disabledMetrics?: Array<RandomizeMetric>;
  handleMetricChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface RandomizeAnimal extends Animal {
  significantMeasurement: Animal['latestMeasurement'];
}

const Randomize: FC<RandomizeProps> = ({
  state: {
    exclusionCriteria,
    randomize,
    treatmentGroups,
    currentStep,
    randomizeByDate: { measurementsOnDate },
  },
  dispatch,
  props: { study, groups },
  openModal,
  closeModal,
}) => {
  const [averageType, setAverageType] = useState<'mean' | 'median'>('mean');
  const [medianDisabled, setMedianDisabled] = useState<boolean>(true);

  const { selectedAttribute, selectedMetrics, method, results } = randomize;

  const subjects: RandomizeAnimal[] = useMemo(
    () => animalsWithSignificantMeasurement(exclusionCriteria.subjectsAfterExclusion ?? [], measurementsOnDate),
    [exclusionCriteria.subjectsAfterExclusion, measurementsOnDate]
  );

  const enabledMetrics = enabledMetricsForRandomize(subjects, study.settings.calculations);
  const metrics = subjectMetrics(enabledMetrics, averageType);
  const valueMetrics = subjectValueMetrics(enabledMetrics);
  const [isRandomizeButtonDisabled, setIsRandomizeButtonDisabled] = useState<boolean>(false);

  const resetResults = useCallback(() => {
    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        results: [],
      },
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  useEffect(() => {
    if (!medianDisabled) {
      const selectedMetricIds = new Set(selectedMetrics.map((metric) => metric.id));
      const newMetrics = metrics.filter((metric) => selectedMetricIds.has(metric.id));
      const tableData = resultsTableTransformer(
        results,
        subjects,
        selectedAttribute,
        newMetrics.concat(exclusionMetric),
        study.error_deviation,
        openModal,
        closeModal,
        averageType
      );
      dispatch({
        type: 'update',
        id: 'randomize',
        data: {
          results,
          tableData,
          selectedMetrics: newMetrics,
        },
      });
    }
  }, [averageType]);

  const subjectsMissingTrackingDatePresent = useMemo(
    () => !allAttrVariantsPresent({ accessor: 'tracking_started_at' }, subjects),
    [subjects]
  );

  const features = useSelector((state: State) => state.team.features);
  const hideBlockAllocation = features?.hide_block_allocation;

  const handleSingleMetricChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMedianDisabled(true);
    const metric = metrics.find(({ accessor }) => accessor === event.target.name);
    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        selectedMetrics: [metric],
      },
    });
  };

  const handleMetricChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMedianDisabled(true);
    const tempOptions = [...selectedMetrics];
    // Same issue with assuming the metric will always be found
    const optionItem = metrics.find(({ accessor }) => accessor === event.target.name) as RandomizeMetric;
    const index = tempOptions.findIndex(({ accessor }) => event.target.name === accessor);
    if (index !== -1) {
      tempOptions.splice(index, 1);
    } else {
      if (selectedMetrics.length < MAX_METRIC_SIZE) {
        tempOptions.push(optionItem);
      }
    }

    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        selectedMetrics: tempOptions,
      },
    });
  };
  const onAttributeChange = (value: SubjectAttribute) => {
    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        selectedAttribute: value,
      },
    });
  };
  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setMedianDisabled(true);
    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        method: event?.target?.value ?? 'block',
      },
    });
  };
  const sizedGroups = useMemo(
    () =>
      (groups ?? []).reduce<RandomizationResult[]>((acc, group) => {
        if (treatmentGroups?.groupDistribution?.[group.id] > 0) {
          acc.push({ ...group, size: treatmentGroups.groupDistribution[group.id], cohort_subject_ids: [] });
        }
        return acc;
      }, []),
    [groups, treatmentGroups]
  );
  const sizedGroupsMap = useMemo(
    () =>
      sizedGroups.reduce<Record<ApiID<'grp'>, number>>((acc, group) => {
        if (_notNil(group?.api_id)) {
          acc = {
            ...acc,
            [group.api_id]: group?.size ?? 0,
          };
        }
        return acc;
      }, {}),
    [sizedGroups]
  );
  const exclusionMetric = useMemo(() => {
    return {
      ...(metrics.find(
        (metric) =>
          metric.accessor.split('.')[1] === exclusionCriteria.criteriaOptions[exclusionCriteria.criteriaIndex]?.value
        // Seems we always find a metric so can assume, but in theory we actually should change
        // this to only return if one is found
      ) as RandomizeMetric),
      isExclusion: true,
    };
  }, [metrics, exclusionCriteria]);

  const handleBlockAllocation = (): void => {
    resetResults();
    const metric: RandomizeMetric = selectedMetrics?.[0];
    if (metric && _isNotEmpty(subjects) && _isNotEmpty(groups)) {
      const animals = subjects?.map((subject) => ({
        id: subject?.id ?? null,
        api_id: subject?.api_id ?? 'aml_',
        measurement_info:
          _notNil(metric?.id) && _notNil(subject?.significantMeasurement?.[metric.id]?.value)
            ? { [metric.id]: Number(subject.significantMeasurement[metric.id].value) }
            : {},
      }));
      const blockAllocationService = new BlockAllocationService({
        animals,
        treatmentGroups: groups,
        groupSizes: sizedGroupsMap,
        metric: metric?.id ?? '',
      });
      const { groupAllocations } = blockAllocationService.blockAllocation();
      const results = sizedGroups.map((group) => ({
        ...group,
        cohort_subject_ids: groupAllocations[group?.api_id ?? 'grp_']?.map((animal) => (animal?.id ?? null) as number),
      }));
      const tableData = resultsTableTransformer(
        results,
        subjects,
        selectedAttribute,
        selectedMetrics.concat(exclusionMetric),
        study.error_deviation,
        openModal,
        closeModal,
        averageType
      );
      dispatch({
        type: 'update',
        id: 'randomize',
        data: {
          results,
          tableData,
        },
      });

      setMedianDisabled(false);
    }
  };

  const handleCluster = async () => {
    resetResults();
    setIsRandomizeButtonDisabled(true);

    let tableData: ResultsTable = { columns: [], data: [], oneWayAnovaResults: {} };
    let results: RandomizationResultFlattened[] = [];

    const iterations = randomize.selectedMetrics.length > 0 ? 30 : 1;
    let currentPvalue = 0;
    const chunkSize = 5;

    const exclusionMetric = {
      ...(metrics.find(
        (metric) =>
          metric.accessor.split('.')[1] === exclusionCriteria.criteriaOptions[exclusionCriteria.criteriaIndex]?.value
      ) as RandomizeMetric),
      isExclusion: true,
    };

    for (let i = 0; i < iterations; i += chunkSize) {
      const chunkResults = await Promise.all(
        Array.from({ length: Math.min(chunkSize, iterations - i) }, async () => {
          const tempResults = await randomiseSubjects(subjects, {
            attr: selectedAttribute,
            groups: sizedGroups,
            metrics: valueMetrics.filter((x) => selectedMetrics.find((y) => y.id === x.id)),
            kSize: K_SIZE,
          });

          const tempTableData = resultsTableTransformer(
            tempResults,
            subjects,
            selectedAttribute,
            selectedMetrics.concat(exclusionMetric),
            study.error_deviation,
            openModal,
            closeModal,
            averageType
          );

          const pValue = sumPValues(tempTableData.oneWayAnovaResults);

          return { tempResults, tempTableData, pValue };
        })
      );

      for (const { tempResults, tempTableData, pValue } of chunkResults) {
        if (isNaN(pValue)) {
          tableData = tempTableData;
          results = tempResults;
          break;
        } else if (pValue > currentPvalue || pValue == 0) {
          currentPvalue = pValue;
          tableData = tempTableData;
          results = tempResults;
        }
      }
    }

    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        results,
        tableData,
      },
    });

    setMedianDisabled(false);
    setIsRandomizeButtonDisabled(false);
  };

  useEffect(() => {
    resetResults();
  }, [currentStep]);

  useEffect(() => {
    dispatch({
      type: 'update',
      id: 'randomize',
      data: {
        results: [],
        tableData: {},
        selectedAttribute: {},
        selectedMetrics: method === RandomizationMethodKeys.cluster ? [] : [metrics?.[0]],
      },
    });
  }, [method]);

  return (
    <div className="mv3">
      <div className="pb4">
        <p className="lh-copy">
          Select what you would like to randomize by.{' '}
          <a
            className="link blue underline-hover"
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.benchling.com/hc/en-us/articles/20129907280013-Randomizing-to-Study-Groups"
          >
            How does this work?
          </a>
        </p>
      </div>
      {!randomize.trackingDatesUpdated && subjectsMissingTrackingDatePresent && (
        <Banner info dismiss={false} className="mw6 mb4">
          <h3 className="normal lh-title f5 pb1">Selected animals haven&apos;t been assigned a tracking date</h3>
          <p className="f6 lh-copy pb3">
            A tracking date is the day data is graphed from, commonly known as a "Day zero". Set or update the tracking
            date for selected animals.
          </p>
          <Button
            outline
            info
            className="mb2"
            onClick={() =>
              openModal('SET_TRACKING_DATE', {
                subject: subjects,
                handleCallback: () => {
                  closeModal();
                  dispatch({
                    type: 'update',
                    id: 'randomize',
                    data: {
                      trackingDatesUpdated: true,
                    },
                  });
                },
              })
            }
          >
            Set tracking dates
          </Button>
        </Banner>
      )}

      <div className="ui-card pa3 mw6 mb3">
        {!hideBlockAllocation && (
          <MethodChoiceOption
            value={RandomizationMethodKeys.block}
            title="I am randomizing by one metric"
            text="Block allocation is an ideal method when a single metric is used to allocate animals to treatment
              groups."
            onChange={handleMethodChange}
            isChecked={method === RandomizationMethodKeys.block}
          />
        )}
        <MethodChoiceOption
          value={RandomizationMethodKeys.cluster}
          title="I am randomizing by one or more metrics and/or an attribute"
          text="Clustered randomization is a useful method for when you want to take one or more metrics into account
            and/or an attribute"
          onChange={handleMethodChange}
          isChecked={method === RandomizationMethodKeys.cluster}
          hideRadio={hideBlockAllocation}
        />
        {enabledMetrics.length !== study.settings.calculations.length && (
          <Banner info dismiss={false} className="mw6 mv3">
            <h3 className="normal lh-title f5 pb1">Not all metrics types are present</h3>
            <p className="f6 lh-copy">
              You can only randomize by metrics that have been taken for all animals in the selection.
            </p>
          </Banner>
        )}
        {method === RandomizationMethodKeys.cluster ? (
          <ClusteringForm
            selectedAttribute={selectedAttribute}
            selectedMetrics={selectedMetrics}
            onAttributeChange={onAttributeChange}
            subjects={subjects}
            metrics={metrics}
            handleMetricChange={handleMetricChange}
          />
        ) : (
          <BlockAllocationForm
            selectedMetrics={selectedMetrics}
            metrics={metrics}
            handleMetricChange={handleSingleMetricChange}
          />
        )}
      </div>

      <div className="randomize-button-group">
        <Button
          onClick={method === RandomizationMethodKeys.cluster ? handleCluster : handleBlockAllocation}
          disabled={isRandomizeButtonDisabled}
        >
          Randomize
        </Button>
        <div className="median-toggle">
          <Switch
            testId="median-toggle"
            value={averageType === 'median'}
            onChange={(toggled) => setAverageType(toggled ? 'median' : 'mean')}
            disabled={medianDisabled}
          />
          <label className="ml2 mb0">Show median</label>
        </div>
      </div>

      <div className="ui-card mt4">
        {_isNotEmpty(randomize?.tableData?.data) ? (
          <Table
            data={consolidateAttributeValues(randomize)}
            className="resultsTable"
            columns={randomize.tableData.columns}
            expandable
          />
        ) : (
          <div className="pa5 ma3 br3 bg-near-white">
            <div className="tc">
              <h3 className="lh-title normal f4 pv2">Results</h3>
              <p className="lh-copy f5">
                Select an attribute and/or metric to randomize by. The results will appear here.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MethodChoiceOption: FC<MethodChoiceOptionProps> = ({
  title,
  text,
  value,
  onChange,
  isChecked,
  hideRadio = false,
}) => (
  <label
    data-testid={'test-' + value + '-label-id'}
    htmlFor={value}
    className={`flex items-center pa2 br2 pointer ${isChecked ? 'bg-near-white' : ''}`}
  >
    {!hideRadio && <Radio id={value} name="method" value={value} onChange={onChange} checked={isChecked} />}
    <div className="pl3">
      <h4 className="f6 lh-title fw5 near-black pb1">{title}</h4>
      <p className="f6 lh-copy dark-gray">{text}</p>
    </div>
  </label>
);

const ClusteringForm: FC<ClusteringFormProps> = ({
  selectedAttribute,
  selectedMetrics,
  onAttributeChange,
  subjects,
  metrics,
  handleMetricChange,
}) => {
  return (
    <div className="flex flex-wrap pa3">
      <div className="mr4">
        <label className="pb2">Select one attribute:</label>
        <Radio
          id="none"
          checked={_isEmpty(selectedAttribute)}
          name="attr"
          label="None"
          value="none"
          className="mb2"
          // This is strange behaviour
          onChange={() => onAttributeChange({} as SubjectAttribute)}
        />
        {subjectAttrs.map((attr) => (
          <Radio
            key={attr.accessor}
            checked={attr.accessor === selectedAttribute.accessor}
            id={attr.accessor}
            name={'attr'}
            label={attr.Header}
            value={attr.accessor}
            tooltip={
              !allAttrVariantsPresent(attr, subjects)
                ? `Animals without ${attr.Header} are present in this selection`
                : undefined
            }
            disabled={!allAttrVariantsPresent(attr, subjects)}
            className="mb2"
            onChange={() => onAttributeChange({ ...attr })}
          />
        ))}
      </div>
      <div className="mr4">
        <label className="pb2">Select up to {MAX_METRIC_SIZE} metrics:</label>
        {metrics.map((metric, i) => (
          <Checkbox
            key={`${new Date()}_${i}`}
            id={metric.accessor}
            name={metric.accessor}
            label={metric.Header}
            value={metric.accessor}
            className="mb2"
            onChange={handleMetricChange}
            checked={!!selectedMetrics.find((f) => f.accessor === metric.accessor)}
          />
        ))}
      </div>
    </div>
  );
};

const BlockAllocationForm: FC<BlockAllocationFormProps> = ({ metrics, selectedMetrics, handleMetricChange }) => {
  return (
    <div className="pa3">
      <label className="pb2">Select a metric:</label>
      {(metrics ?? []).map((metric, metricIndex) => (
        <Radio
          id={metric.accessor}
          name={metric.accessor}
          label={metric.Header}
          value={metric.accessor}
          key={`metric-${metricIndex}`}
          className="mb2"
          onChange={handleMetricChange}
          checked={!!selectedMetrics.find((f) => f.accessor === metric.accessor)}
        />
      ))}
    </div>
  );
};

export default Randomize;
