import ApiErrorBanner from '@/components/ApiErrorBanner';
import { copyText } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatMetadataForSave, generateColumns, reducer } from '../Attachment.utils';
import LinksTable from './LinksTable';

interface LinksProps {
  readOnly: boolean;
  study: Study;
}

interface Link {
  alt?: string;
  metadata: Array<Record<string, unknown>>;
  name: string;
  url: string;
}

const Links = ({ readOnly = false, study }: LinksProps) => {
  const initialState = useMemo(
    () => ({
      loading: false,
      updating: false,
      apiErrors: false,
      links: [],
      columns: [],
      searchQuery: '',
      total_pages: 1,
      current_page: 1,
      per_page: 10,
      order: 'desc',
      sort: ['id'],
      selectedRows: {},
      metadata: [],
    }),
    []
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const { links, loading, updating, error, searchQuery, current_page, per_page, order, sort, selectedRows, metadata } =
    state;
  const reduxDispatch = useDispatch();
  const settings = useSelector((state: State) => state.ui.settings);

  const closeModal = () => {
    reduxDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const openModal = (modal: string, props: Record<string, unknown>) => {
    reduxDispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const addLink = () => {
    reduxDispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_EDIT_LINK',
      props: {
        metadata,
        handleSave: async (newLink: Link) => {
          if (newLink.alt === '') {
            delete newLink.alt;
          }
          if (_isNotEmpty(newLink?.metadata)) {
            newLink.metadata = formatMetadataForSave(newLink, metadata);
          }
          try {
            await Http.post(
              apiRoute('studies.links', {
                study_id: study.id,
              }),
              newLink
            );
            await fetchLinks();
            closeModal();
          } catch (e) {
            dispatch({ type: 'SET_ERROR', data: error });
            closeModal();
          }
        },
      },
    });
  };

  const getSelectedLink = () => {
    const getLink = (index: string) => links[index];
    return Object.keys(selectedRows).map(getLink)?.[0];
  };

  const editLink = () => {
    const link = getSelectedLink();
    if (link) {
      reduxDispatch({
        type: 'OPEN_MODAL',
        modal: 'ADD_EDIT_LINK',
        props: {
          link,
          metadata,
          handleSave: async (editedLink: Link) => {
            const payload = { ...editedLink };
            if (_isNotEmpty(payload?.metadata)) {
              payload.metadata = formatMetadataForSave(payload, metadata);
            }
            try {
              const { data } = await Http.patch(
                apiRoute('studies.links.update', {
                  study_id: study.id,
                  link_id: link.id,
                  include: 'metadata',
                }),
                payload
              );
              dispatch({
                type: 'UPDATE_LINK',
                data: { id: link.id, updatedLink: data.data },
              });
            } catch (e) {
              dispatch({ type: 'SET_ERROR', data: error });
            } finally {
              closeModal();
            }
          },
        },
      });
    }
  };

  const copyLink = () => {
    const link = getSelectedLink();
    copyText(link.url, 'Link successfully copied.');
  };

  const deleteLink = async () => {
    const link = getSelectedLink();
    if (link) {
      try {
        await Http.delete(
          apiRoute('studies.links.delete', {
            study_id: study.id,
            link_id: link.id,
          })
        );
        await fetchLinks();
      } catch (e) {
        dispatch({ type: 'SET_ERROR', data: error });
      } finally {
        closeModal();
      }
    }
  };

  interface AssembleRowActionsProps {
    readOnly: boolean;
  }

  interface AssembleRowActions {
    name: string;
    key: string;
    action: () => void;
    className?: string;
  }

  const assembleRowActions = ({ readOnly }: AssembleRowActionsProps) => {
    const actions: Array<AssembleRowActions> = [
      {
        name: 'Copy Link',
        key: 'copy_link',
        action: copyLink,
      },
    ];

    if (!readOnly) {
      actions.push(
        ...[
          {
            name: 'Edit Link',
            key: 'edit_link',
            action: editLink,
          },
          {
            name: 'Delete',
            key: 'delete',
            action: () =>
              openModal('CONFIRM_DELETE_LINK', {
                onClick: () => deleteLink(),
                closeModal,
              }),
            className: 'red',
          },
        ]
      );
    }

    return actions;
  };

  const singleRowActions = assembleRowActions({ readOnly });

  interface QueryParams {
    include: string;
    page: number;
    perPage: number;
    query?: string;
    sort?: Array<string>;
    order?: string;
  }

  const fetchLinks = async () => {
    dispatch({ type: 'SET_LOADING', data: true });
    try {
      let queryParams: QueryParams = {
        include: 'metadata',
        page: current_page,
        perPage: per_page,
      };
      if (_isNotEmpty(searchQuery)) {
        queryParams = { ...queryParams, query: searchQuery };
      }
      if (_notNil(sort)) {
        queryParams = { ...queryParams, sort };
      }
      if (_notNil(order)) {
        queryParams = { ...queryParams, order };
      }

      const {
        data: { data, meta },
      } = await Http.get(
        apiRoute(
          'studies.links',
          {
            study_id: study.id,
          },
          queryParams
        )
      );
      const pagination = {
        total_pages: meta.last_page,
        current_page: meta.current_page,
        per_page: meta.per_page,
      };
      dispatch({
        type: 'SET_LINKS',
        data: { data, pagination },
      });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', data: error });
    } finally {
      dispatch({ type: 'SET_LOADING', data: false });
    }
  };

  const fetchAttachmentMetadata = async () => {
    dispatch({ type: 'SET_LOADING', data: true });
    try {
      const {
        data: { data },
      } = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'attachment_meta',
        },
      });
      dispatch({
        type: 'SET_COLUMNS',
        data: generateColumns(data, settings.tables.links?.columns),
      });
      dispatch({
        type: 'SET_METADATA',
        data: data,
      });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', data: error });
    } finally {
      dispatch({ type: 'SET_LOADING', data: false });
    }
  };

  useEffect(() => {
    fetchLinks();
  }, [searchQuery, per_page, current_page, sort, order]);

  useEffect(() => {
    fetchAttachmentMetadata();
  }, []);

  return error ? (
    <ApiErrorBanner
      className="mb3"
      title="There was an error fetching your links"
      text="Please try again later. If this keeps occurring please contact support."
      error={error}
    />
  ) : (
    <LinksTable
      state={state}
      dispatch={dispatch}
      readOnly={readOnly}
      addLink={addLink}
      singleRowActions={singleRowActions}
      reduxDispatch={reduxDispatch}
      settings={settings}
      loading={loading || updating}
    />
  );
};

export default Links;
