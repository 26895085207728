export const enum UserPermissions {
  write = 'Write',
  read = 'Read',
}

export const userRoles: Record<string, string> = {
  read: 'Read',
  write: 'Write',
  author: 'Author',
  owner: 'Owner',
};
