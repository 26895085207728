import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import Link from '@/components/UI/Link';
import SelectDropDown from '@/components/UI/SelectDropDown';
import SetTask from '@/components/UI/SelectDropDown/Menus/SetTask';
import { _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { State } from '@/model/State.model';
import type { Task, TrafficLightKeys } from '@/model/Task.model';
import type { BaseUser } from '@/model/User.model';
import { useRequest } from '@/support/Hooks/request';
import { isClosed } from '@/support/study';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewTaskProps {
  task: Task;
  closeModal: () => void;
  updateTask: (payload: Task | Record<string, unknown>) => void;
  openModal: (modalName: string, params: Record<string, unknown>) => void;
  deleteTask: (id: string) => void;
}

const ViewTask: React.FC<ViewTaskProps> = ({ task, closeModal, updateTask, openModal, deleteTask }) => {
  const taskStudyUsers = Array.isArray(task.study?.users) ? task.study?.users : task.study?.users?.data;

  const currentUser = useSelector((state: State) => state.user.currentUser);
  const [user, setUser] = useState<ID>(task.last_updated_by?.id ?? taskStudyUsers?.[0]?.id ?? task.study.author.id);
  const [payload, setPayload] = useState<Task | Record<string, unknown>>({
    ...task,
    status: task.status ?? 'incomplete',
  });

  const formatDays: Record<number, string> = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };

  const terminology = () => {
    let content = '';
    const taskTemplate = task.task_template;

    if (taskTemplate?.task_type === 'conditional') {
      if (taskTemplate.conditional_settings.type === 'study_day') {
        content = `On study day ${taskTemplate.conditional_settings.value}`;
      } else if (taskTemplate.conditional_settings.type === 'age') {
        content = `When animal is ${taskTemplate.conditional_settings.value} days old`;
      } else if (taskTemplate.conditional_settings.type === 'tracking_day') {
        content = `When animal tracking day is equal to ${taskTemplate.conditional_settings.value}`;
      }
      return content;
    }

    if (taskTemplate?.period === 'weekly') {
      const frequency_settings = taskTemplate.frequency_settings
        .map((day, i) => `${formatDays[day]}${i !== taskTemplate.frequency_settings.length - 1 ? ', ' : ''}`)
        .join('');
      content = `Every ${taskTemplate.frequency} week(s) on: ${frequency_settings}`;
    }
    if (taskTemplate?.period === 'daily') {
      content = `Every ${taskTemplate.frequency} day(s)`;
    }
    return content;
  };

  const {
    sendRequest: saveTask,
    requestSending: savingTask,
    requestError: saveTaskError,
  } = useRequest<unknown, { data: Task }>({
    route: 'tasks.update',
    params: {
      id: task.id,
    },
    method: 'patch',
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const response = await saveTask({
      ...payload,
      ...{ last_updated_by: Number(user) },
    });
    updateTask(response?.data?.data);
    closeModal();
  };

  const { sendRequest: removeTask, requestError: removeTaskError } = useRequest({
    route: 'tasks.destroy',
    method: 'delete',
    params: { id: task.id },
  });

  const trafficLights = {
    incomplete: { class: 'ui__dropdown__red', label: 'Incomplete' },
    in_progress: { class: 'ui__dropdown__yellow', label: 'In progress' },
    complete: { class: 'ui__dropdown__green', label: 'Complete' },
  };

  const defaultSelectedUser = (last_updated_by?: string, id?: ID, currentUser?: ID) => {
    if (last_updated_by && Number(id) === Number(last_updated_by)) {
      return true;
    }

    if (!last_updated_by && Number(currentUser) === Number(id)) {
      return true;
    }

    return false;
  };

  const updateTheTask = (newTask: Task | Record<string, unknown>) => {
    setPayload(newTask);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`center mv3 bg-white br2 shadow-4 ${savingTask ? 'ui__disabled' : ''}`}
      style={{ maxWidth: '600px' }}
    >
      <div className="ph4 pv3 bb b--moon-gray">
        <h3 className="normal lh-title near-black basier-med f4">Task details</h3>
        {(saveTaskError || removeTaskError) && (
          <ApiErrorBanner
            error={saveTaskError || removeTaskError}
            title="An error has occurred"
            text={`We are having an issue ${
              removeTaskError ? 'deleting' : 'fetching'
            } the task right now, please try again
          later. If this keeps occurring please contact support.`}
          />
        )}
      </div>
      <div className="ph4 pv3 bg-near-white">
        <div className="ui-card pa3">
          <h4>{task.description}</h4>
          <p>{task.study.name}</p>

          <div className="flex flex-row justify-between pt3">
            <div className="w-40">
              <select name="user" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setUser(e.target.value)}>
                {_isNotEmpty(taskStudyUsers) &&
                  taskStudyUsers.map(({ id, name }: BaseUser) => (
                    <option
                      key={id}
                      value={id}
                      selected={defaultSelectedUser(task.last_updated_by?.id, id, currentUser.id)}
                    >
                      {name}
                    </option>
                  ))}
                {_isNotEmpty(task.study.author) && (
                  <option
                    key={task.study.author.id}
                    value={task.study.author.id}
                    selected={defaultSelectedUser(task?.last_updated_by?.id, task.study.author.id, currentUser.id)}
                  >
                    {task.study.author.name}
                  </option>
                )}
                {_isNotEmpty(task.study.owner) && (
                  <option
                    key={task.study.owner?.id}
                    value={task.study.owner?.id}
                    selected={defaultSelectedUser(task.last_updated_by?.id, task.study.owner?.id, currentUser.id)}
                  >
                    {task.study.owner?.name}
                  </option>
                )}
              </select>
            </div>

            <SelectDropDown
              disabled={isClosed(task.study)}
              title={trafficLights[payload.status as TrafficLightKeys]?.['label']}
              className={`f6 ${trafficLights[payload.status as TrafficLightKeys]?.['class']}`}
              alignMenu="left"
              style={{ minWidth: '150px' }}
            >
              <SetTask task={task} updateTask={updateTheTask} />
            </SelectDropDown>
          </div>
          {task.task_template && <div className="pa3 br3 bg-light-gray text-black">{terminology()}</div>}
        </div>

        {_isNotEmpty(task.subjects) && (
          <div className="pt3">
            <h3 className="pb2 mt2">Affected animals</h3>
            <div className="ui-card mb2 pa3 h-100" style={{ overflow: 'auto', maxHeight: '300px' }}>
              <div className="flex mb3">
                <div className="w-40 near-black">Cage</div>
                <div className="w-40 near-black">ID</div>
              </div>

              {task.subjects.map((subject) => (
                <div className="flex mb2 f6" key={subject.id}>
                  <div className="w-40">{subject.collection?.name}</div>
                  <div className="w-40">
                    <Link to={`/studies/${subject.study_id}/animals/${subject.id}`} className="link blue">
                      {subject.name}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="ph4 pv3 bt b--moon-gray flex justify-between">
        <div>
          <Button disabled={savingTask} onClick={handleSubmit}>
            Update task
          </Button>
          <Button className="ml3" plain onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
        {!task.task_template && (
          <Button
            className="ml3"
            critical
            onClick={() =>
              openModal('CONFIRM_DELETE_TASK', {
                onClick: async () => {
                  await removeTask({ id: task.id });
                  deleteTask(task.id);
                },
                closeModal,
              })
            }
          >
            Delete Task
          </Button>
        )}
      </div>
    </form>
  );
};

export default ViewTask;
