// @ts-nocheck: converted from JS

import { ControlGroupSelect } from '@/components/Studies/StudyGroups/Groups/ControlGroupSelect';
import Radio from '@/components/UI/FormElements/Radio';
import { _isEmpty, _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import { useSelector } from 'react-redux';

/**
 *
 * @param props {{study:{average_calculation: 'arithmetic'|'geometric', error_deviation: 'sd'|'sem'}, onChange?: (currentValue: {averageCalculation: 'arithmetic'|'geometric', errorDeviation: 'sd'|'sem'}) => void}}
 * @returns {JSX.Element}
 * @constructor
 */
export const GroupConfiguration = ({ study, onChange }) => {
  const dataAnalysisEnabled = useSelector<State>((state) => state?.team?.features?.data_analysis ?? false);
  const handleChange = (event) => {
    if (_notNil(onChange)) {
      const value = event.target.getAttribute('value');
      const name = event.target.getAttribute('name');

      onChange({
        average_calculation: study.average_calculation,
        error_deviation: study.error_deviation,
        [name]: value,
      });
    }
  };
  const handleControlGroupChange = async (id: string) => {
    onChange({
      groups: study.groups.map((group) => ({ ...group, control: group.temp_id === id })),
    });
  };

  return (
    <div className="pv4 flex flex-wrap" data-test-component="GroupConfiguration" data-test-element="container">
      <div className="ph4 w-32" data-test-element="average-calculation-container">
        <label className="mb2 db">How would you like to calculate averages?</label>
        <Radio
          id="group-configuration-arithmetic-option"
          name="average_calculation"
          checked={study.average_calculation === 'arithmetic'}
          value="arithmetic"
          label="Arithmetic"
          onChange={handleChange}
          className="mb2"
        />
        <Radio
          id="group-configuration-geometric-option"
          name="average_calculation"
          checked={study.average_calculation === 'geometric'}
          value="geometric"
          label="Geometric"
          onChange={handleChange}
        />
      </div>
      <div className="ph4  w-32" data-test-element="error-deviation-container">
        <label className="mb2 db">How would you like to display variation?</label>
        <Radio
          id="group-configuration-sd-option"
          name="error_deviation"
          checked={study.error_deviation === 'sd'}
          value="sd"
          label="Standard deviation"
          onChange={handleChange}
          className="mb2"
        />
        <Radio
          id="group-configuration-sem-option"
          name="error_deviation"
          checked={study.error_deviation === 'sem'}
          value="sem"
          label="Standard error of the mean"
          onChange={handleChange}
        />
      </div>
      {dataAnalysisEnabled && (
        <div className="ph4  w-32" data-test-element="control-group-selection-container">
          <label className="mb2 db">Control group</label>
          <ControlGroupSelect
            groups={study.groups ?? []}
            disabled={_isEmpty(study.groups)}
            onChange={handleControlGroupChange}
          />
        </div>
      )}
    </div>
  );
};
