// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import DoseList from '@/components/Workflow/Show/Views/Dosing/DoseList';
import type { Animal } from '@/model/Animal.model';
import type { Study } from '@/model/Study.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import React from 'react';

interface DosingAnimalViewProps {
  study: Study;
  animal: Animal;
}

const Dosing: React.FC<DosingAnimalViewProps> = ({ study, animal }) => {
  const {
    collection: dosages,
    collectionLoading,
    collectionUpdating,
    fetchCollection,
  } = useFetchCollection({
    collectionType: 'animalDosages',
    params: { id: animal.id },
  });

  if (collectionLoading) {
    return <Loading txt="Fetching dosages..." />;
  }

  return (
    <div className={`ui-card mw7 ${collectionUpdating ? 'ui__disabled' : ''}`}>
      <DoseList study={study} subject={animal} dosages={dosages} handleCallback={fetchCollection} />
    </div>
  );
};

export default Dosing;
