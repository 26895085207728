// @ts-nocheck: converted from JS

import { FilterTypes } from '@/constants/FilterTypes';
import { Type } from '@/referenceData/study/type';

export const operationOptions = [
  {
    value: 'eq',
    label: FilterTypes.eq,
  },
  {
    value: 'ne',
    label: FilterTypes.ne,
  },
];

export const studyTypeOptions = [
  { value: Type.all, name: 'All studies' },
  { value: Type.internal, name: 'Internal studies' },
  { value: Type.external, name: 'External studies' },
];
