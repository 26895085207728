// @ts-nocheck: converted from JS

import { useEffect, useRef, useState } from 'react';

interface StickyItemProps {
  children?: any;
  top?: any;
  stickyClass?: any;
}

const StickyItem: FC<StickyItemProps> = ({ children, top = 0, stickyClass = '' }) => {
  const stickyRef = useRef();
  const mainRef = useRef(document.getElementsByTagName('main')[0]);
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.addEventListener('sticky-scroll', handleScroll);
      return () => {
        mainRef.current.removeEventListener('sticky-scroll', handleScroll);
      };
    }
  }, []);

  const handleScroll = () => {
    setStuck(stickyRef.current.getBoundingClientRect().top <= 0);
  };

  return (
    <div ref={stickyRef} style={{ position: 'sticky', top, zIndex: '9999' }} className={`${stuck ? stickyClass : ''}`}>
      {children}
    </div>
  );
};

export default StickyItem;
