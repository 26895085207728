import { type FC, useEffect, useRef, useState } from 'react';

interface TruncateProps {
  children: string;
  textClass?: string;
  tooltip: string;
}

const Truncate: FC<TruncateProps> = ({ children, textClass, tooltip }) => {
  const ref = useRef<HTMLParagraphElement | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  useEffect(() => {
    if (ref.current && ref.current?.parentElement) {
      const context = document.createElement('canvas').getContext('2d');
      if (context) {
        const computedParent = getComputedStyle(ref.current?.parentElement);
        const computedChild = getComputedStyle(ref.current);
        context.font = `${computedChild.getPropertyValue('font-size')} ${computedChild.getPropertyValue(
          'font-family'
        )}`;
        const textWidth = context?.measureText(children);
        const parentWidth =
          ref.current?.parentElement.clientWidth -
          (parseFloat(computedParent.paddingLeft) + parseFloat(computedParent.paddingRight));
        setShowTooltip((textWidth?.width ?? 0) > parentWidth);
      }
    }
    return () => document.getElementsByTagName('canvas')?.[0]?.remove();
  }, [ref, children]);

  return showTooltip ? (
    <p
      ref={ref}
      data-tooltip-id="global-tooltip-id"
      data-tooltip-content={tooltip}
      className={`${textClass ?? ''} truncate`}
    >
      {children}
    </p>
  ) : (
    <p ref={ref} className={`${textClass ?? ''}`}>
      {children}
    </p>
  );
};

export default Truncate;
