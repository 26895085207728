// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import StepForm from '@/components/UI/StepForm';
import { successToast } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { useEffect, useMemo, useState } from 'react';
import { initialObservationState, validateObservation } from './AddObservation.utils';
import ObservationForm from './ObservationForm';

const AddObservations = ({ animalId, selectedObservations, observed_at: observedAt, closeModal, handleCallback }) => {
  const [observations, setObservations] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  useEffect(() => {
    setObservations(
      selectedObservations.map((o) =>
        initialObservationState({
          glossary_id: o.id,
          title: o.title,
          description: o.description,
          type: o.type,
          text: '',
          options: o.options,
          observed_at: observedAt,
        })
      )
    );
  }, []);

  useEffect(() => {
    setErrors(observations.map((o) => validateObservation(o)));
  }, [observations]);

  const handleInput = (index, key, val) => {
    const tempObservations = [...observations];
    tempObservations[index][key] = val === '' ? '' : val;
    setErrors(tempObservations.map((o) => validateObservation(o)));
    return setObservations(tempObservations);
  };

  const handlePayload = (observation) => {
    const { glossary_id: glossaryId, value, text } = observation;
    let payload = {
      glossary_id: glossaryId,
      value,
      text,
    };

    if (DateUtils.isValidDateTime(observedAt)) {
      payload = {
        ...payload,
        observed_at: observedAt,
      };
    }

    return payload;
  };

  const handleSubmit = () => {
    const validate = errors.every((o) => o === false);
    if (validate) {
      setLoading(true);
      const promises = observations.reduce((acc, v, i) => {
        acc[i] = Http.post(apiRoutes('observations.create', { id: animalId }), handlePayload(v));
        return acc;
      }, []);

      return Promise.all(promises)
        .then(() => {
          setLoading(false);
          closeModal();
          handleCallback();
          successToast(`Observation${observations.length > 1 ? 's' : ''} saved`);
        })
        .catch((error) => {
          setLoading(false);
          setSubmissionError(error);
        });
    }

    return false;
  };

  const steps = observations.map((o, i) => ({
    name: o.title,
    isValid: () => !errors[i],
    renderStep: (state, dispatch) => (
      <div className="ui-card pa3">
        <ObservationForm
          key={`${o.glossary_id}-${i}`}
          index={i}
          errors={errors[i]}
          handleInput={handleInput}
          observation={o}
          dispatch={dispatch}
        />
      </div>
    ),
  }));

  const initialiseState = {
    currentStep: 0,
    steps,
  };

  const initialState = useMemo(() => initialiseState, [steps]);

  return (
    <div className={`${loading ? 'ui__disabled' : ''} w-75-l center ma3 bg-light-gray br2 shadow-4 mv4 relative pa4`}>
      {submissionError && (
        <Banner critical dismiss={false} className="mw6 mb3">
          <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
          <p className="f6 pb3 lh-copy">
            An error has occurred when submitting the form, please try again later. If this keeps occurring please
            contact support.
          </p>
          <Button outline critical url={'mailto:support@benchling.com'}>
            Contact support
          </Button>
        </Banner>
      )}
      {_isNotEmpty(observations) && (
        <StepForm
          titlePadding="pb0"
          steps={steps}
          initialState={initialState}
          finalStepAction={handleSubmit}
          cancelFunction={closeModal}
        />
      )}
    </div>
  );
};

export default AddObservations;
