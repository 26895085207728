import Button from '@/components/UI/Button/Button';

interface ActionButtonsProps {
  onOk: () => void;
  onCancel: () => void;
  canOk?: boolean;
  hideOk?: boolean;
  tooltip?: string;
  canCancel?: boolean;
  hideCancel?: boolean;
  cancelText?: string;
  okText?: string;
}

const ActionButtons = ({
  onOk,
  onCancel,
  canOk = true,
  hideOk,
  tooltip,
  canCancel = true,
  hideCancel,
  cancelText,
  okText,
}: ActionButtonsProps) => {
  return (
    <div className="flex justify-between mt4" data-test-component="ActionButtons" data-test-element="container">
      <div className="flex justify-start">
        {!hideOk && (
          <Button className="mr2" disabled={!canOk} tooltip={tooltip} testId="OK-Button" onClick={onOk} submit>
            {okText || 'Continue'}
          </Button>
        )}
        {!hideCancel && (
          <Button disabled={!canCancel} className="plain mr2" testId="Cancel-Button" onClick={onCancel}>
            {cancelText || 'Back'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ActionButtons;
