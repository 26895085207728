// @ts-nocheck: converted from JS

import CageCardsTemplate4x2 from '@/components/Modals/PrintCageCards/4x2/CageCardsTemplate';
import CageCardTemplate4x2 from '@/components/Modals/PrintCageCards/4x2/CageCardTemplate';
import CageCardsTemplate4x3 from '@/components/Modals/PrintCageCards/4x3/CageCardsTemplate';
import CageCardTemplate4x3 from '@/components/Modals/PrintCageCards/4x3/CageCardTemplate';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SearchSelect from '@/components/UI/SearchSelect';
import { CageCardSize } from '@/model/Team.model';
import { cageCardOptions } from '@/referenceData/global-data/team/cageCardSettings';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, FormProvider, useForm } from 'react-hook-form';

const maxFieldsMap = {
  [CageCardSize.FourByThree]: 8,
  [CageCardSize.FourByTwo]: 8,
};

export const cageCardTemplateMap = {
  [CageCardSize.FourByThree]: CageCardTemplate4x3,
  [CageCardSize.FourByTwo]: CageCardTemplate4x2,
} as const;

export const cageCardsTemplateMap = {
  [CageCardSize.FourByThree]: CageCardsTemplate4x3,
  [CageCardSize.FourByTwo]: CageCardsTemplate4x2,
} as const;

const CageCards = ({ studyMetadata, cageMetadata, onSubmit, value }) => {
  const formMethods = useForm({
    defaultValues: {
      size: value?.size || cageCardOptions[0].value,
      selectedMetadata: value?.metadata || [],
    },
  });

  const { register, control, handleSubmit, errors, watch } = formMethods;

  const getMetadataItem = (id) =>
    [...studyMetadata, ...cageMetadata].find((metadataOption) => metadataOption.id === id);

  const selectedMetadataValue = watch('selectedMetadata');
  const sizeValue = watch('size');

  const sections = useMemo(
    () => [
      {
        title: 'Study',
        items: studyMetadata,
      },
      {
        title: 'Cage',
        items: cageMetadata,
      },
    ],
    [studyMetadata, cageMetadata]
  );

  const exampleCageCardData = useMemo(
    () => ({
      cage: {
        id: 1,
        name: 'Cage 1',
        catalog: 'C00001',
        subjects: {
          data: [
            {
              id: 1,
              name: 'Animal 1',
              sex: 'm',
              dob: '2021-01-01',
              strain: 'C57CL/6',
            },
            {
              id: 2,
              name: 'Animal 2',
              sex: 'f',
              dob: '2021-01-01',
              strain: 'C57CL/6',
            },
            {
              id: 3,
              name: 'Animal 3',
              sex: 'm',
              dob: '2021-01-01',
              strain: 'C57CL/6',
            },
          ],
        },
      },
      study: {
        name: 'Study 1',
        users: {
          data: [
            {
              id: 1,
              name: 'Connie Watsica',
            },
          ],
        },
      },
      selectedMetadata: selectedMetadataValue.map((value) => {
        const metadataOption = getMetadataItem(value);
        return {
          id: value,
          title: <i>{metadataOption?.label ?? ''}</i>,
          value: '',
        };
      }),
    }),
    [selectedMetadataValue]
  );

  const { cage, study, selectedMetadata, subjects } = exampleCageCardData;
  const maxFields = maxFieldsMap[sizeValue];
  const CageCardTemplate = cageCardTemplateMap[sizeValue];

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="mb3">
            <Header mainHeaderText="Cage cards" />
            <h3 className="f5 normal lh-title pb2 dark-gray mb3">
              Choose how cage cards are printed and which fields of metadata are included.
              <Link
                className="link blue"
                to="https://help.benchling.com/hc/en-us/articles/19932834550541-Cage-Cards"
                openTab
              >
                Read more
              </Link>
            </h3>
          </div>
          <div className="flex flex-row">
            <div className="mr4" style={{ width: 500 }}>
              <div className="ui-card pa3 mb4">
                <div className="mb4" style={{ display: 'none' }}>
                  <label htmlFor="size" className="mb3">
                    What size of labels will you be printing?
                  </label>
                  {cageCardOptions.map((cardOption) => (
                    <div className="flex items-center pb2" key={cardOption.value}>
                      <input
                        id={cardOption.value}
                        type="radio"
                        defaultChecked={sizeValue === cardOption.value}
                        name={'size'}
                        ref={register()}
                        value={cardOption.value}
                        className="pointer ui__form__radio"
                        style={{ marginBottom: 0 }}
                      />
                      <label className="pointer pl3" style={{ marginBottom: 0 }} htmlFor={cardOption.value}>
                        {cardOption.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="mb4">
                  <label htmlFor="selectedMetadata" className="mb3">
                    Which metadata would you like to include? Choose up to {maxFields} fields
                  </label>
                  <div className="ui-card">
                    <Controller
                      name="selectedMetadata"
                      control={control}
                      render={(props: ControllerRenderProps) => (
                        <SearchSelect
                          className={`${errors.scopes ? 'search_select__error' : ''}`}
                          sections={sections}
                          selected={props.value}
                          setSelected={props.onChange}
                          sort
                          sortAccessor={'label'}
                          maxSelections={maxFields}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="mr2">
                Save
              </button>
            </div>
            <div className="ui-card pa3 self-start">
              <CageCardTemplate
                subjects={subjects}
                cage={cage}
                study={study}
                selectedMetadata={selectedMetadata}
                maxFields={maxFields}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CageCards;
