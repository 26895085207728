import { _isEmpty, _isNil, _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model';
import { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { ValidateAnimals } from './RandomizeByDate.model';
import { RandomizeState } from '../Randomize/Randomize.model';
import { Preset } from '@/model/Preset.model';
import { PresetCalculation } from '@/model/PresetCalculation.model';

export const hasTreatmentGroup = (animal: Animal): boolean => _notNil(animal.study_group?.api_id);
export const isEuthanised = (animal: Animal): boolean => _notNil(animal.terminated_at);
export const hasNoLatestMeasurements = (animal: Animal): boolean => _isNil(animal.latestMeasurement);
export const isNotValidResult = (validationResult: string | boolean): boolean => typeof validationResult === 'string';

export const validationResponses = {
  hasTreatmentGroup: 'Selected animals are already assigned to groups.',
  isEuthanised: 'Some selected animals have been marked as deceased.',
  hasNoLatestMeasurements: 'Selected animals have no measurements recorded.',
  hasNoMeasurementsOnDate: 'No measurements have been recorded for this date',
};

export const validateMeasurements = (
  animal: Animal,
  measurementMap: RandomizeState['randomizeByDate']['measurementsOnDate'] | null = null
): boolean | string => {
  if (_isNil(animal) || (_isNil(measurementMap) && hasNoLatestMeasurements(animal))) {
    return validationResponses.hasNoLatestMeasurements;
  }
  if (_notNil(measurementMap) && _notNil(animal.api_id) && _isNil(measurementMap?.[animal?.api_id ?? ''])) {
    return validationResponses.hasNoMeasurementsOnDate;
  }

  return true;
};

export const validateAnimals = (
  animals: Animal[],
  measurementMap: RandomizeState['randomizeByDate']['measurementsOnDate'] | null = null
): ValidateAnimals => {
  const validationMessages: Set<string> = new Set();
  const includedAnimals: Animal[] = [];
  const excludedAnimals: Animal[] = [];

  animals.forEach((animal) => {
    const measurementsValidResult = validateMeasurements(animal, measurementMap);
    if (hasTreatmentGroup(animal) || isEuthanised(animal) || isNotValidResult(measurementsValidResult)) {
      excludedAnimals.push(animal);
      if (hasTreatmentGroup(animal)) {
        validationMessages.add(validationResponses.hasTreatmentGroup);
      }
      if (isEuthanised(animal)) {
        validationMessages.add(validationResponses.isEuthanised);
      }
      if (isNotValidResult(measurementsValidResult)) {
        validationMessages.add(measurementsValidResult as string);
      }
    } else {
      includedAnimals.push(animal);
    }
  });

  return {
    validationMessages: Array.from(validationMessages),
    includedAnimals,
    excludedAnimals,
  };
};

export const validateStudyGroups = (groups: TreatmentGroup[]): boolean | string => {
  if (_isEmpty(groups)) return 'No treatment groups exist in this study';

  if (
    groups.every((group) => {
      const animalCount = group?.animals_count ?? 0;
      return animalCount >= group.max_subjects;
    })
  ) {
    return 'All treatment groups are full in this study';
  }

  return true;
};

/**
 * Check all of the included animals for the presence of the study's calculations
 * Return an array of the filtered `studyCalculations`
 */
export const getEnabledCalculations = (
  includedAnimals: Animal[],
  studyCalculations: Preset['calculations'],
  measurementMap: RandomizeState['randomizeByDate']['measurementsOnDate'] | null = null
): PresetCalculation[] => {
  const calculationMap: Record<PresetCalculation['id'], PresetCalculation> = studyCalculations.reduce(
    (acc, calculation: PresetCalculation) => {
      if (_notNil(calculation.id)) {
        acc = { ...acc, [calculation.id]: calculation };
      }
      return acc;
    },
    {}
  );
  const presentCalculations: Set<PresetCalculation['id']> = new Set();
  for (const animal of includedAnimals) {
    for (const calculation of studyCalculations) {
      if (_notNil(measurementMap) && _notNil(animal.api_id) && measurementMap?.[animal.api_id]?.[calculation.id]) {
        presentCalculations.add(calculation.id);
      } else if (animal.latestMeasurement?.[calculation.id]) {
        presentCalculations.add(calculation.id);
      } else {
        continue;
      }
    }
    // There are at least one of each present calculation, break the loop
    if (presentCalculations.size === studyCalculations.length) {
      break;
    }
  }
  return Array.from(presentCalculations).map((calculationId: PresetCalculation['id']) => calculationMap[calculationId]);
};
