import Loader from '@/assets/images/loading-bubbles.svg';
import Icon from '@/components/UI/Icon';
import type { IconProps } from '@/components/UI/Icon/Icon';
import Link from '@/components/UI/Link';
import { classNames } from '@/helpers';
import { _notNil } from '@/littledash';
import type { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import './Button.scss';

export interface ButtonProps extends WithLinkProps {
  id?: string;
  url?: string;
  _blank?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  icon?: IconProps['icon'];
  viewBox?: string;
  className?: string;
  style?: CSSProperties;
  primary?: boolean;
  outline?: boolean;
  critical?: boolean;
  warning?: boolean;
  info?: boolean;
  success?: boolean;
  plain?: boolean;
  soft?: boolean;
  stateless?: boolean;
  transparent?: boolean;
  paleBlue?: boolean;
  pill?: boolean;
  submit?: boolean;
  tooltip?: string;
  testId?: string;
  testKey?: string;
}

const Button: FC<ButtonProps> = ({
  id,
  url,
  _blank,
  disabled,
  loading,
  loadingText,
  onClick,
  children,
  icon,
  viewBox,
  className,
  style,
  primary,
  outline,
  critical,
  warning,
  info,
  success,
  plain,
  soft,
  stateless,
  transparent,
  paleBlue,
  pill,
  tooltip,
  submit,
  testId = 'button',
  testKey,
}) => {
  const type = submit ? 'submit' : 'button';
  const isDisabled = disabled || loading;

  const classes = {
    primary: primary ?? false,
    outline: outline ?? false,
    critical: critical ?? false,
    warning: warning ?? false,
    info: info ?? false,
    success: success ?? false,
    plain: plain ?? false,
    soft: soft ?? false,
    stateless: stateless ?? false,
    transparent: transparent ?? false,
    paleBlue: paleBlue ?? false,
    pill: pill ?? false,
  };

  const btnLoadingSpinner = <img className="v-mid dib mr2 mt0" width={32} height={32} src={Loader} alt="loading" />;

  const content = (
    <>
      {loading && btnLoadingSpinner}
      {_notNil(icon) && <ButtonIcon icon={icon} viewBox={viewBox} />}
      {children && (
        <span data-test-component="Button" data-test-element="saving" className={`v-mid dib ${icon && 'ml2'}`}>
          {!loading ? children : loadingText || 'Saving'}
        </span>
      )}
    </>
  );

  return (
    <span data-tooltip-id="global-tooltip-id" data-tooltip-content={tooltip}>
      <WithLink url={url} target={_blank && '_blank'} role={loading ? 'alert' : undefined} disabled={disabled}>
        <button
          id={id}
          type={type}
          onClick={onClick}
          className={classNames(className ?? '', classes)}
          disabled={isDisabled}
          role={loading ? 'alert' : undefined}
          aria-busy={loading ? true : undefined}
          style={style || {}}
          data-testid={testId}
          data-test-component="Button"
          data-test-element="button"
          data-test-key={testKey}
        >
          {content}
        </button>
      </WithLink>
    </span>
  );
};

interface WithLinkProps extends Pick<ButtonProps, 'url' | 'disabled' | 'children'> {
  role?: string;
  target?: string;
}

const WithLink: FC<WithLinkProps> = ({ url, target, role, disabled, children }) =>
  url ? (
    <Link to={url} disabled={disabled} target={target} role={role}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

const ButtonIcon: FC<Pick<IconProps, 'icon' | 'viewBox'>> = ({ icon, viewBox }) => (
  <Icon icon={icon} width="20" height="20" viewBox={viewBox ?? '0 0 20 20'} className="v-mid dib ba0 transparent" />
);

export default Button;
