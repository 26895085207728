// @ts-nocheck: converted from JS

import CageColumn from '@/components/Cages/Show/CageColumn';
import { _chunk } from '@/littledash';
import type { Cage } from '@/model/Cage.model';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getItemIndexFromChunk } from '../../CardColumn/CardColumn.utils';
import { buildCageCards } from './CageColumns.utils';

const Row = ({ children }) => <div className={'cf ph2-ns'}>{children}</div>;
const Col = ({ children }) => <div className={'fl w-25 pa2'}>{children}</div>;

const CageColumns = ({
  study,
  cages = [],
  toggleAddAnimalsModal,
  toggleDeleteCageModal,
  onMove,
  onAddMove,
  updateCageAPI,
  validateCageName,
  onCageNameChange,
  animalIdProperty,
  species,
  inStudyView,
}) => {
  const MAX_COLUMNS_PER_ROW = 4;
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    setGroups(buildCageCards(cages));
  }, [cages]);

  const getCage = useCallback((id) => cages.find((cage) => cage.id === id), [cages]);

  const rows = _chunk(groups, MAX_COLUMNS_PER_ROW);

  const updateCageName = (cage: Cage, value: string) => {
    if (value !== '') {
      cage.name = value;
      updateCageAPI(cage);
      onCageNameChange(cage, value);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="ph3">
        {rows.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {row.map((group, i) => {
              const groupIndex = getItemIndexFromChunk(i, rowIndex, MAX_COLUMNS_PER_ROW);
              const cage = getCage(group.id);

              return (
                <Col key={group.id}>
                  <CageColumn
                    key={group.id}
                    groups={groups}
                    group={group}
                    groupId={group.id}
                    title={group.title}
                    study={study}
                    cage={cage}
                    updateCageName={updateCageName}
                    validateCageName={validateCageName}
                    groupIndex={groupIndex}
                    toggleAddAnimalsModal={toggleAddAnimalsModal}
                    toggleDeleteCageModal={toggleDeleteCageModal}
                    onCardMove={onMove}
                    onCardDropped={onAddMove}
                    animalIdProperty={animalIdProperty}
                    species={species}
                    inStudyView={inStudyView}
                  />
                </Col>
              );
            })}
          </Row>
        ))}
      </div>
    </DndProvider>
  );
};

export default CageColumns;
