// @ts-nocheck: converted from JS

import StudyGroupSetup from '@/components/Studies/Settings/Steps/Groups/StudyGroupSetup';
import { generateGroupBulkUpdateOperations } from '@/components/Studies/StudyGroups/Groups/Groups.utils';
import { errorToast, successToast } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import { useRequest } from '@/support/Hooks/request';
import { api as apiRoute } from '@/support/route';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const GroupsTable = ({ groups = [], reloadGroups, canEdit = true }) => {
  const { id: studyId } = useParams();

  const [groupsState, setGroupsState] = useState([...groups]);

  const { sendRequest: bulkUpdateGroups } = useRequest({
    route: apiRoute('studies.groups.bulk', { studyId }),
    method: 'post',
  });

  useEffect(() => {
    setGroupsState(groups);
  }, [groups]);

  const handleSave = async (updatedGroups) => {
    const operations = generateGroupBulkUpdateOperations(groupsState, updatedGroups);
    if (_isNotEmpty(operations)) {
      const updateSuccessful = await bulkUpdateGroups({ operations });
      if (updateSuccessful) {
        successToast('Groups updated successfully');
        setGroupsState(updatedGroups);
        reloadGroups();
      } else {
        errorToast('Error updating groups');
      }
    }
  };

  return (
    <div className={`${_isNotEmpty(groups) ? '' : 'pa4'}`}>
      <StudyGroupSetup groups={groupsState} onUpdateGroupData={handleSave} canEdit={canEdit} />
    </div>
  );
};

export default GroupsTable;
