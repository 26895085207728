import type { CreateStudyAction, CreateStudyState } from '@/components/Studies/Create/Create.model';
import TasksV1 from '@/components/Studies/Create/Steps/Tasks/TasksV1';
import { TasksV2 } from '@/components/Studies/Create/Steps/Tasks/TasksV2';
import type { State } from '@/model/State.model';
import type { Dispatch, FC } from 'react';
import { useSelector } from 'react-redux';

interface TaskVersionSelectorProps {
  state: CreateStudyState;
  dispatch: Dispatch<CreateStudyAction>;
}

export const TaskVersionSelector: FC<TaskVersionSelectorProps> = ({ state, dispatch }) => {
  const tasksVersion = useSelector(({ team: { features } }: State) => (!(features?.hide_tasks ?? false) ? 'V2' : 'V1'));
  switch (tasksVersion) {
    case 'V1':
      return <TasksV1 state={state} dispatch={dispatch} />;
    case 'V2':
      return <TasksV2 state={state} dispatch={dispatch} />;
  }
};
