import { CSSProperties } from 'react';

interface NotesProps {
  note: string;
  style?: CSSProperties;
  className?: string;
}

export const Notes: React.FC<NotesProps> = ({ note = '', className = '', style }) => {
  return (
    <div style={style} className={`br3 br--top--left bg-near-white mt2 mw6 break-word ${className}`}>
      <p className="f6 ma0 line-copy near-black pa2">{note}</p>
    </div>
  );
};
