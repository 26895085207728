import { ColumnContext } from '@/components/Studies/DataTables/Display/DisplayDatatable';
import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Select from '@/components/UI/Select';
import type { SelectOption } from '@/components/UI/Select/Select';
import { _isEmpty, _notNil } from '@/littledash';
import type { State } from '@/model/State.model';
import { DateUtils } from '@/utils/Date.utils.ts';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import type { DataTableColumnType, DataTableObservation } from '../../../DataTable.model';
import type { CustomTableColumnAddProps } from './AddColumn.model';
import { convertMetricsToOptions, convertObservationsToOptions, uniqueNameValidatorFactory } from './AddColumn.util';

type ColumnTypeOption = SelectOption<DataTableColumnType>;

interface CustomTableAddColumnForm {
  type: ColumnTypeOption;
  unit: string | null;
  name?: string;
  measurementMetric?: SelectOption<string>;
  observationId?: SelectOption<string>;
  observationType?: DataTableObservation['display'];
  referenceDate?: string;
}

export const CustomTableColumnAdd: FC<Omit<CustomTableColumnAddProps, 'tableType'>> = ({
  toggleDropdownMenu,
  handleAddColumn,
  columns,
}) => {
  const [refDate, setRefDate] = useState(DateUtils.dateNow());
  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  const { metrics, observations } = useContext(ColumnContext);

  const metricOptions = useMemo(() => convertMetricsToOptions(columns, refDate, metrics), [metrics, refDate]);
  const observationsOptions = useMemo(() => convertObservationsToOptions(observations), [observations]);

  let columnTypes: Array<ColumnTypeOption> = [
    {
      label: 'Number',
      value: 'number',
    },
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Formula',
      value: 'formula',
    },
  ];

  if (features?.datatables_observation) {
    columnTypes.push({ label: 'Observation', value: 'observation' });
  }
  columnTypes.push({
    label: 'Timestamp',
    value: 'timestamp',
  });

  if (features?.datatables_measurement) {
    columnTypes = [...columnTypes, { label: 'Measurement', value: 'measurement' }];
  }

  columnTypes.push({
    label: 'Timestamp: PK Dose',
    value: 'timestampBaseline',
  });

  if (columns.some((column) => column.type === 'timestampBaseline')) {
    columnTypes.push({
      label: 'Timestamp: PK Bleed',
      value: 'timestampBaselineRelative',
    });
  }

  const uniqueColumnName = useMemo(
    () => uniqueNameValidatorFactory(new Set(columns.map(({ name }) => name))),
    [columns]
  );
  const defaultValues: Partial<CustomTableAddColumnForm> = useMemo(
    () => ({
      type: columnTypes[0],
      name: undefined,
      unit: null,
      measurementMetric: metricOptions?.[0],
      observationId: observationsOptions?.[0],
      observationType: 'score',
      referenceDate: refDate,
    }),
    [refDate]
  );
  const {
    register,
    control,
    errors,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm<CustomTableAddColumnForm>({
    defaultValues,
    mode: 'onChange',
  });

  const columnType = watch(['type']).type.value as DataTableColumnType;
  const measurementMetricInvalid = columnType === 'measurement' && _isEmpty(metricOptions);
  const emptyMeasurementOption = { value: undefined, label: 'No options available' };

  useEffect(() => {
    const currentMetric = watch('measurementMetric');
    if (_isEmpty(metricOptions)) {
      return setValue('measurementMetric', emptyMeasurementOption);
    } else if (_notNil(currentMetric) && !metricOptions.map((x) => x.value).includes(currentMetric.value)) {
      return setValue('measurementMetric', metricOptions?.[0]);
    }
  }, [refDate]);

  const onSubmit = async ({
    type,
    name,
    unit,
    measurementMetric,
    observationId,
    observationType,
    referenceDate,
  }: CustomTableAddColumnForm) => {
    toggleDropdownMenu?.(false);
    reset(defaultValues);
    return handleAddColumn({
      type: type.value,
      name: name?.trim(),
      unit: (unit?.trim() ?? '').length === 0 ? null : unit?.trim(),
      observation:
        _notNil(observationId) && _notNil(observationType) && _notNil(referenceDate)
          ? { display: observationType, glossary_id: observationId.value.toString(), observed_at: referenceDate }
          : undefined,
      measurement:
        _notNil(measurementMetric) && _notNil(referenceDate)
          ? { metric: measurementMetric.value, measured_at: referenceDate }
          : undefined,
    });
  };

  return (
    <div className="pa3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div>
          <Controller
            control={control}
            name="type"
            defaultValue={columnTypes[0]}
            render={({ value, onChange, onBlur }: ControllerRenderProps) => (
              <Select value={value} onChange={onChange} onBlur={onBlur} options={columnTypes} isMulti={false} />
            )}
          />
        </div>
        {columnType !== 'measurement' && columnType !== 'observation' && (
          <div className="mt2">
            <input
              type="text"
              name="name"
              autoComplete="off"
              ref={register({ required: 'This field is required', maxLength: 255, validate: { uniqueColumnName } })}
              placeholder="Name"
              className={errors?.name ? 'input__error' : ''}
              style={{ marginBottom: 0 }}
              autoFocus
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <small className="red db pt2">{message}</small>}
            />
          </div>
        )}
        {(columnType === 'number' || columnType === 'formula') && (
          <div className="mt2">
            <input
              type="text"
              name="unit"
              autoComplete="off"
              ref={register()}
              placeholder="Unit"
              style={{ marginBottom: 0 }}
            />
            <ErrorMessage
              errors={errors}
              name="unit"
              render={({ message }) => <small className="red db pt2">{message}</small>}
            />
          </div>
        )}
        {columnType === 'measurement' && (
          <div className="mt2">
            <Controller
              control={control}
              name="measurementMetric"
              render={({ value, onChange, onBlur }: ControllerRenderProps) => (
                <Select
                  disabled={measurementMetricInvalid}
                  value={value ?? emptyMeasurementOption}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={metricOptions}
                  isMulti={false}
                />
              )}
            />
          </div>
        )}
        {columnType === 'observation' && (
          <>
            <div className="mt2">
              <Controller
                control={control}
                name="observationId"
                render={({ value, onChange, onBlur }: ControllerRenderProps) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={observationsOptions}
                    isMulti={false}
                  />
                )}
              />
            </div>
            <div className="mt2">
              <label>Display</label>
              <Controller
                control={control}
                name="observationType"
                render={({ onChange }: ControllerRenderProps) => (
                  <>
                    {[
                      { value: 'score', label: 'Score', checked: true },
                      { value: 'comments', label: 'Comments' },
                    ].map(({ value, label, checked }) => (
                      <div className="mt2 flex flex-column items-start">
                        <div className="flex flex-row">
                          <input
                            id={`observationType.${value}`}
                            name="observationType"
                            type="radio"
                            value={value}
                            className="pointer ui__form__radio"
                            style={{ marginBottom: 0 }}
                            defaultChecked={checked}
                            onChange={onChange}
                          />
                          <label className="ml2" htmlFor={`observationType.${value}`}>
                            {label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
          </>
        )}
        {['measurement', 'observation'].includes(columnType) && (
          <div className="mt2">
            <Controller
              control={control}
              name="referenceDate"
              render={({ value, onChange }: ControllerRenderProps) => (
                <DatePickerNative
                  value={value}
                  step="any"
                  onChange={(val) => {
                    setRefDate(val);
                    return onChange(val);
                  }}
                />
              )}
            />
          </div>
        )}
        <div className="mt3">
          <Button
            stateless
            className="link db pl0 f6"
            submit
            disabled={isSubmitting || !isValid || measurementMetricInvalid}
          >
            Add column
          </Button>
          <Button
            stateless
            className="near-black db pl0 mt2 f6"
            onClick={() => {
              toggleDropdownMenu?.(false);
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
