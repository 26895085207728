// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import ActionButtons from '@/components/Glossary/Sections/Approvals/ActionButtons';
import { useSteps } from '@/components/Steps';
import StepLinkList from '@/components/UI/StepLinkList';
import { web as webRoutes } from '@/support/route';
import { useHistory } from 'react-router-dom';

const TemplateForm = ({
  submitRequest,
  hasSubmissionErrors,
  initialSteps,
  isSubmitting,
  hasFetchErrors,
  request,
  setRequest,
  formTitle,
  submitButtonText,
}) => {
  const history = useHistory();
  const { steps, activeStep, nextStep, backStep, reset } = useSteps(initialSteps);

  const ActivePanel = steps[activeStep - 1].Component;
  const isFirstStep = activeStep === 1;
  const isLastStep = activeStep === steps.length;

  const updateRequest = (newData) => {
    if (newData) {
      setRequest((prevRequest) => ({
        ...prevRequest,
        ...newData,
      }));
    }
  };

  const handlePanelSubmission = (newData) => {
    if (isLastStep) {
      submitRequest(request);
    } else {
      updateRequest(newData);
      nextStep();
    }
  };

  const handleBackClick = () => {
    if (isFirstStep) {
      reset();
      history.push(webRoutes('team.studies.requests'));
    } else {
      backStep();
    }
  };

  return (
    <div className="pv3" data-test-component="TemplateForm" data-test-element="container">
      <div className={'flex flex-row justify-between items-center mb4'}>
        <h1 className="f3 normal pb2">{formTitle}</h1>
        <StepLinkList steps={steps} activeStep={activeStep} />
      </div>
      {hasSubmissionErrors && <ApiErrorBanner error={hasSubmissionErrors} />}
      {hasFetchErrors && (
        <ApiErrorBanner
          title="There was an error fetching request data"
          text="An error has occurred when fetching the form data, please try again
          later. If this keeps occurring please contact support."
          error={hasFetchErrors}
        />
      )}
      <ActivePanel
        request={request}
        onSubmit={handlePanelSubmission}
        ActionButtons={
          <ActionButtons
            canOk={!isSubmitting}
            onCancel={handleBackClick}
            cancelText={'Back'}
            okText={isLastStep ? submitButtonText : 'Continue'}
          />
        }
      />
    </div>
  );
};

export default TemplateForm;
