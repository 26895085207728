import MenuContainer from './MenuContainer';
import type { FC } from 'react';

export interface ActionProps {
  name: string;
  key?: string;
  className?: string;
  action: () => void;
  tooltip?: string;
  disabled?: boolean;
  disabledReasonTooltip?: string;
}

interface ActionListMenuProps {
  actions: Array<ActionProps>;
  closeMenu?: () => void;
  testPrefix?: string;
}

const ActionListMenu: React.FC<ActionListMenuProps> = ({ actions, closeMenu, testPrefix = 'dropdown' }) => {
  const actionFn = (fn: () => void) => {
    fn();
    closeMenu?.();
  };

  const listTestId = `${testPrefix}action-list`;

  return (
    <MenuContainer width="auto">
      <ul className="mv2" data-testid={listTestId}>
        {actions?.map((action, index) => {
          if (Array.isArray(action)) {
            const bottomBorder = action.length && index + 1 !== action.length;
            return (
              <li>
                <ul className={`${bottomBorder ? 'pv2 bb b--moon-gray' : 'pt2'}`}>
                  {action.map((child, i) => (
                    <Item key={i} index={i} action={child} actionFn={actionFn} testPrefix={listTestId} />
                  ))}
                </ul>
              </li>
            );
          } else {
            return <Item key={index} index={index} action={action} actionFn={actionFn} testPrefix={listTestId} />;
          }
        })}
      </ul>
    </MenuContainer>
  );
};

interface ItemProps {
  action: ActionProps;
  index: number;
  actionFn: (action: () => void) => void;
  testPrefix: string;
}

const Item: FC<ItemProps> = ({ action, index, actionFn, testPrefix }) => {
  return (
    <li
      key={index}
      data-tooltip-id="global-tooltip-id"
      data-tooltip-content={action.disabled ? action.disabledReasonTooltip : action.tooltip}
      data-tooltip-hidden={action.disabled && !action.disabledReasonTooltip}
      data-testid={`${testPrefix}--item`}
    >
      <a
        className={`f6 db ph3 pv2 near-black tl ${action.disabled ? 'ui__disabled' : ''} ${action.className ?? ''}`}
        style={{ whiteSpace: 'nowrap' }}
        onClick={() => actionFn(action.action)}
      >
        {action.name}
      </a>
    </li>
  );
};

export default ActionListMenu;
