import { RenderedField, RequiredMetadataFieldWithValue } from '@/components/Metadata/Fields';
import { BENCHLING_INTEGRATION_META_SLUG } from '@/helpers.constants.tsx';

interface RenderMetadataProps {
  metadata: Array<RequiredMetadataFieldWithValue>;
}

const RenderMetadata = ({ metadata }: RenderMetadataProps) => {
  return (
    <div data-testid="metadata-field-group">
      {metadata.map((meta) => (
        <div
          style={meta.slug === BENCHLING_INTEGRATION_META_SLUG ? { display: 'none' } : {}}
          className="hide-child flex justify-between items-top"
          key={meta.slug}
          data-testid={`metadata-field-group__${meta.slug}`}
        >
          <RenderedField metadata={meta} required={meta.required} />
        </div>
      ))}
    </div>
  );
};

export default RenderMetadata;
