import { CageCardSize } from '@/model/Team.model';

export const cageCardOptions = [
  {
    label: '4" x 3" individual labels',
    value: CageCardSize.FourByThree,
  },
  {
    label: '4" x 2" letter size Avery labels (10 per sheet)',
    value: CageCardSize.FourByTwo,
  },
];
