// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { useFetchCollection } from '@/support/Hooks/fetch';
import React from 'react';

/**
 *
 * @param Component {JSX.Element||null}
 * @returns {JSX.Element||null}
 */

const WithSampleMetadata = ({ children }) => {
  const {
    collection: sampleMetadataGlossary,
    collectionLoading: fetchingSampleMetadataFields,
    collectionError: requestError,
  } = useFetchCollection({
    collectionType: 'metadataGlossary',
    queryParams: {
      filter_type: 'sample_meta',
    },
  });

  if (fetchingSampleMetadataFields) {
    return (
      <div className="pa3">
        <Loading textClassName="f5" />
      </div>
    );
  }

  if (requestError) {
    return <ApiErrorBanner error={requestError} />;
  }

  return <>{React.cloneElement(children, { sampleMetadataGlossary })}</>;
};

export default WithSampleMetadata;
