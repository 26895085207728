import Icon from '@/components/UI/Icon';
import type { StudyGroupV1, TreatmentGroup } from '@/model/TreatmentGroup.model';
import type React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

interface GroupContextMenuProps {
  group: StudyGroupV1 | TreatmentGroup;
  className?: string;
}

const GroupContextMenu: React.FC<GroupContextMenuProps> = ({ group, className }) => {
  const dispatch = useDispatch();

  const openModal = (modal: string, props: unknown) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <a
      onClick={() => openModal('GROUP_META', { group, closeModal })}
      className={cn('ui__group-label-drop dib w1 h1 br1 relative', className)}
    >
      <Icon className="mid-gray hover-blue" icon="info" width="24" height="24" />
    </a>
  );
};

export default GroupContextMenu;
