// @ts-nocheck: converted from JS

import { useState } from 'react';
import Button from '@/components/UI/Button';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { FormProvider, useForm } from 'react-hook-form@latest';
import { useDispatch, useSelector } from 'react-redux';
import Header from '@/components/UI/Header';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { useRequest } from '@/support/Hooks/request';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { generateDefaultValues, providerFormRenderMap, providerNameMap, ssoProviders } from './SingleSignOn.utils';
import { successToast } from '@/helpers';

export interface FormValues {
  azure: {
    enabled: boolean;
    tenant: string;
    client_id: string;
    client_secret: string;
  };
  okta: {
    enabled: boolean;
    domain: string;
    client_id: string;
    client_secret: string;
  };
}

const SingleSignOn = () => {
  const providerState = useState(0);
  const [provider] = providerState;
  const activeProvider = ssoProviders[provider];
  const { team } = useSelector(({ team: { team } }) => ({
    team,
  }));
  const defaultValues = generateDefaultValues(team);
  const useFormMethods = useForm<FormValues>({
    defaultValues,
  });
  const { handleSubmit } = useFormMethods;
  const dispatch = useDispatch();
  const {
    sendRequest: updateSSOProvider,
    requestSending: updatingSSOProvider,
    requestError,
  } = useRequest({
    route: 'team.sso_providers',
    params: { type: activeProvider },
    method: 'put',
  });
  const onSubmit = async (data) => {
    const providerFormData = data[activeProvider];
    const providerName = providerNameMap[activeProvider];
    await updateSSOProvider(providerFormData);
    if (!requestError) {
      const globalData = await Http.get(apiRoute('global-data'));
      dispatch({ type: 'TEAM_SET_TEAM', team: globalData.data.team });
      successToast(`Updated SSO settings for ${providerName}`);
    }
  };

  return (
    <>
      <div>
        <Header mainHeaderText="Setup single sign on (SSO)" />
        <p className="lh-copy f6">
          Enter your selected provider details to enable SSO.{' '}
          <a
            href="https://help.benchling.com/hc/en-us/articles/21942964574477-Single-Sign-On-SSO-"
            target="_blank"
            rel="noopener noreferrer"
            className="link blue"
          >
            How do I setup my team SSO?
          </a>
        </p>
      </div>
      <div className="ui-card mw7 mv4">
        {requestError && <ApiErrorBanner className="mb4" error={requestError} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs outerState={providerState}>
            <div className="ph3 pt3 mb3 bb b--moon-gray">
              {ssoProviders.map((provider, index) => (
                <Tab key={index} className="f5">
                  {providerNameMap[provider]}
                </Tab>
              ))}
            </div>
            {ssoProviders.map((provider, index) => (
              <Panel key={index}>
                <FormProvider {...useFormMethods}>{providerFormRenderMap[provider]}</FormProvider>
              </Panel>
            ))}
          </Tabs>
          <div className="pa3">
            <Button submit disabled={updatingSSOProvider}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SingleSignOn;
