import type { FC } from 'react';
// @ts-expect-error: untyped lib
import TagsInput from 'react-tagsinput';
import './TagInput.scss';

// Tab, Enter and Comma
export const ADDITIONAL_ENTRY_KEYS = [9, 13, 188];
const TAG_LIMIT = 30;

interface TagInputProps {
  handleSearchInput: (...event: any[]) => void;
  searchQuery: Array<string>;
  searchPlaceholderText?: string;
  maxTags?: number;
}

const TagInput: FC<TagInputProps> = ({
  handleSearchInput,
  searchQuery,
  searchPlaceholderText = '',
  maxTags = TAG_LIMIT,
}) => {
  const renderInput = (props: any) => {
    const { onChange, value, addTag, ...other } = props;
    return (
      <input
        type="text"
        onChange={onChange}
        value={value}
        style={{ width: '1px', height: '1px', maxWidth: '1px' }}
        {...other}
      />
    );
  };

  const renderTag = (props: any) => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <div key={key} {...other}>
        <p className="near-black f7">{tag}</p>
        {!disabled && <a className={classNameRemove} onClick={() => onRemove(key)} />}
      </div>
    );
  };

  const renderLayout = (tagElements: any, inputElement: any) => (
    <span>
      {tagElements}
      {inputElement}
    </span>
  );

  return (
    <TagsInput
      className="flex tag-input ba b--moon-gray bg-white shadow-none"
      value={searchQuery}
      onlyUnique={true}
      onChange={handleSearchInput}
      inputProps={{ placeholder: searchPlaceholderText }}
      addKeys={ADDITIONAL_ENTRY_KEYS}
      maxTags={maxTags}
      renderTag={renderTag}
      renderLayout={renderLayout}
      renderinput={renderInput}
    />
  );
};

export default TagInput;
