// @ts-nocheck: converted from JS

import type { Treatment } from '@/model/Treatment.model';

export const getParamsFromState = ({ searchQuery, sort, order, current_page: currentPage, per_page: perPage }) => {
  let result = '';
  if (searchQuery) {
    result = `${result}&query=${searchQuery}`;
  }
  if (sort) {
    result = `${result}&sort=${sort}`;
  }
  result = `${result}&order=${order}&page=${currentPage}&perPage=${perPage}`;

  return result;
};

export interface TreatmentsState {
  loading: boolean;
  updating: boolean;
  apiError: boolean;
  treatments?: Array<Treatment>;
  total_pages: number;
  current_page: number;
  per_page: number;
  sort: Array<string>;
  order: 'asc' | 'desc';
  searchQuery: string;
}

export const initialState: TreatmentsState = {
  loading: true,
  updating: false,
  apiError: false,
  treatments: undefined,
  total_pages: 10,
  current_page: 1,
  per_page: 10,
  sort: ['name'],
  order: 'asc',
  searchQuery: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.data,
      };
    case 'SET_UPDATING':
      return {
        ...state,
        updating: action.data,
      };
    case 'SET_ERROR':
      return {
        ...state,
        apiErrors: action.data,
      };
    case 'SET_TREATMENT':
      return {
        ...state,
        treatments: action.data.data,
        total_pages: action.data.meta.last_page,
      };
    case 'SET_PAGING':
      return {
        ...state,
        current_page: state.per_page === action.data.per_page ? action.data.current_page : 1,
        per_page: action.data.per_page,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: action.data['sort'],
        order: action.data.order,
      };
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.data,
        current_page: 1,
      };
    default: {
      return { ...state };
    }
  }
};
