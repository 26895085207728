// @ts-nocheck: converted from JS

const INITIAL_STATE = {
  selectedSubjects: [],
};

const applySelectedSubjects = (state, action) => ({
  ...state,
  selectedSubjects: action.selectedSubjects,
});

function subjectsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_SELECTED_SUBJECTS': {
      return applySelectedSubjects(state, action);
    }
    default:
      return state;
  }
}

export default subjectsReducer;
