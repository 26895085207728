// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import ObservationsList from '@/components/Subjects/Show/Views/ObservationsList';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { toWorkflowDateTime } from '@/components/Workflow/Show/Workflow.utils';
import { _isEmpty } from '@/littledash';
import { GlossaryItem } from '@/model/Glosary.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WorkflowNoDataCard } from './WorkflowNoDataCard';

const ObservationView = ({
  study,
  subject,
  observations,
  fetchAnimalDataByType,
  observed_at,
  workflow,
  onSave,
  openModal,
  canWrite,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(workflow?.preSelectedObservationTypeIds ?? []);

  const {
    collection: observationTypes,
    collectionLoading: loading,
    collectionError: error,
  } = useFetchCollection<GlossaryItem>({
    collectionType: 'teamGlossary',
    queryParams: { group: 'observations' },
  });

  const observationTypeSections = useMemo(() => {
    const observationTypeMap = new Map(
      (observationTypes ?? []).map((observationType) => [observationType.id, observationType])
    );

    const selectedTypes = (selected ?? []).reduce((acc, observationTypeId) => {
      if (observationTypeMap.has(observationTypeId)) {
        acc.push(observationTypeMap.get(observationTypeId));
        observationTypeMap.delete(observationTypeId);
      }
      return acc;
    }, []);
    return [
      ...(selectedTypes.length > 0 ? [{ title: 'Selected Observation types', items: selectedTypes }] : []),
      { title: 'Observation types', items: Array.from(observationTypeMap.values()) },
    ];
  }, [selected, observationTypes]);

  const handleCallback = () => {
    onSave();
    dispatch({ type: 'CLOSE_MODAL' });
    setSelected([]);
    if (workflow.onSave !== 'next-subject') {
      fetchAnimalDataByType(subject, 'observations');
    }
  };

  const handleModalToggle = () => {
    const observationTypeMap = new Map(
      (observationTypes ?? []).map((observationType) => [observationType.id, observationType])
    );

    const observations = selected.reduce(
      (acc, s) => (observationTypeMap.has(s) ? [...acc, observationTypeMap.get(s)] : acc),
      []
    );

    return openModal('ADD_OBSERVATION', {
      selectedObservations: observations,
      animalId: subject.id,
      closeModal: () => dispatch({ type: 'CLOSE_MODAL' }),
      observed_at: toWorkflowDateTime(observed_at),
      handleCallback,
    });
  };

  const orderedObservations = observations.sort((a, b) => new Date(b.observed_at) - new Date(a.observed_at));

  return (
    <div className={`flex flex-wrap justify-between items-stetch h-100 ${!canWrite || (loading && 'ui__disabled')}`}>
      <div className="w-50 pa4 br b--moon-gray bg-light-gray">
        {error && (
          <Banner critical dismiss={false} className="mw6 mv3">
            <h3 className="f5 normal lh-title pb2">An error has occurred</h3>
            <p className="f6 pb3 lh-copy">
              We are having an issue fetching the team glossary right now, please try again later. If this keeps
              occurring please contact support.
            </p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
        {loading && <Loading />}
        {observationTypes?.length > 0 && (
          <>
            <div className="ui-card">
              <SearchSelect selected={selected} setSelected={setSelected} sections={observationTypeSections} />
            </div>
            <Button
              className="mt3"
              tooltip={_isEmpty(selected) ? 'Select an observation to continue' : undefined}
              disabled={_isEmpty(selected)}
              onClick={() => handleModalToggle()}
            >
              Record observation{selected.length > 1 && 's'}
            </Button>
          </>
        )}
        {observationTypes?.length === 0 && (
          <Banner info className="mw6" dismiss={false}>
            <h3 className="f5 normal basier-med lh-title">No team glossary items have been added</h3>
            <p className="f6 pt2 lh-copy">
              To get started, click on Glossary in the left hand menu and add observation glossary items.
            </p>
          </Banner>
        )}
      </div>
      <div className="w-50 bg-white">
        <div className="overflow-y-scroll h-100" style={{ maxHeight: '42rem' }}>
          {orderedObservations.length ? (
            <ObservationsList
              study={study}
              subject={subject}
              openModal={openModal}
              closeModal={() => dispatch({ type: 'CLOSE_MODAL' })}
              observations={orderedObservations}
              handleCancel={() => dispatch({ type: 'CLOSE_MODAL' })}
              handleCallback={handleCallback}
            />
          ) : (
            WorkflowNoDataCard
          )}
        </div>
      </div>
    </div>
  );
};

export default ObservationView;
