import Loading from '@/components/Loading';
import { formatDosingMethod } from '@/components/Studies/StudyGroups/Configuration/Configuration.utils';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import Icon from '@/components/UI/Icon';
import Table from '@/components/UI/Table';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import Fuse from 'fuse.js';
import type { components } from '@/generated/internal-api/openapi-types';
import { renderMetadataValue } from '@/helpers';
import { _notNil } from '@/littledash';
import type { AnimalV1 } from '@/model/Animal.model';
import type { StudyApiId } from '@/model/Study.model';
import type { GroupApiId } from '@/model/TreatmentGroup.model';
import { type FC, useMemo, useState } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, useModalAction } from '@/utils/modal';
import style from './GroupInformation.module.scss';

type GroupDetails = components['schemas']['StudyGroupDetailsV1.schema'];
type TreatmentV1 = components['schemas']['TreatmentV1.schema'];

const enum GroupInformationTab {
  Group,
  Treatments,
  Animals,
}
interface GroupInformationProps {
  studyApiId: StudyApiId;
  groupApiId: GroupApiId;
  tab?: GroupInformationTab;
}

export const GroupInformation: FC<GroupInformationProps> = ({ studyApiId, groupApiId, tab }) => {
  const { closeModal } = useModalAction();
  const [activeTab, setActiveTab] = useState(tab ?? GroupInformationTab.Group);

  const { response } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/groups/{groupApiId}/details',
    path: { studyApiId, groupApiId },
  });
  if (response?.type === 'error') {
    closeModal();
  }
  if (response?.type !== 'success') {
    return <Loading />;
  }

  const { group, animals, treatments } = response.body;

  return (
    <ModalContainer size="narrow">
      <div>
        <div className="pa3 flex w-100 justify-between center">
          <div className="fw4 f4 near-black flex items-center">
            <span className={style.groupChip} style={{ backgroundColor: group.color ?? '#333333' }}></span>
            <span className="pl1">{group.name}</span>
          </div>
        </div>
        <Tabs outerState={[activeTab, setActiveTab]}>
          <div className="ph3 pt2 bb b--moon-gray">
            <Tab>Group</Tab>
            <Tab disableNavigation={treatments.length === 0}>Treatments</Tab>
            <Tab disableNavigation={animals.length === 0}>Animals</Tab>
          </div>
          <Panel className={style.panel}>
            <GroupPanel group={group} treatments={treatments} animals={animals} />
          </Panel>
          <Panel className={style.panel}>
            <TreatmentsPanel group={group} treatments={treatments} animals={animals} />
          </Panel>
          <Panel className={style.panel}>
            <AnimalsPanel group={group} treatments={treatments} animals={animals} />
          </Panel>
        </Tabs>
      </div>
      <div className={style.actionContainer}>
        <div className="pa3">
          <ModalActions onCancel={closeModal} cancelBtnText="Close" />
        </div>
      </div>
    </ModalContainer>
  );
};

type KeyValue = { key: string; value: string };
const groupColumns: Columns<KeyValue> = [
  {
    id: 'name',
    Header: 'Name',
    Cell: (props) => <>{props.row.original.key}</>,
  },
  {
    id: 'value',
    Header: 'Value',
    Cell: (props) => <>{props.row.original.value}</>,
  },
];
const GroupPanel: FC<GroupDetails> = ({ group, animals, treatments }) => {
  const [search, updateSearch] = useState('');
  const { fuse, data } = useMemo(() => {
    const deceasedCount = animals.filter((a) => _notNil(a.terminated_at)).length;
    const data = [
      { key: 'Capacity', value: `${group.max_subjects} (${animals.length} used)` },
      { key: 'Animals', value: `${animals.length}` + (deceasedCount > 0 ? ` (${deceasedCount} deceased)` : '') },
      ...group.metadata.map(({ title, field_type, value }) => ({
        key: title,
        value: renderMetadataValue({ field_type, value: value }),
      })),
    ];
    const fuse = new Fuse<KeyValue>(data, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [
        { name: ['key'], weight: 0.2 },
        { name: ['value'], weight: 0.2 },
      ],
    });
    return { data, fuse };
  }, [group, animals]);
  const filteredData: Array<KeyValue> | null = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return fuse.search(trimmedSearchString).map((r) => r.item);
    }
    return null;
  }, [search, fuse]);

  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={`pa3 relative w-100 ${style.searchContainer}`}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div>
        <Table columns={groupColumns} data={filteredData ?? data} />
      </div>
    </div>
  );
};

const TreatmentsPanel: FC<GroupDetails> = ({ treatments }) => {
  const [search, updateSearch] = useState('');
  const fuse = useMemo(() => {
    return new Fuse(treatments, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [
        { name: ['treatment_type', 'name'], weight: 0.2 },
        { name: ['type'], weight: 0.2 },
        { name: ['fields', 'name'], weight: 0.2 },
        { name: ['fields', 'label'], weight: 0.2 },
        { name: ['fields', 'unit', 'display_unit'], weight: 0.2 },
        { name: ['fields', 'default_value'], weight: 0.2 },
        { name: ['fields', 'default_display_value'], weight: 0.2 },
        { name: ['metadata', 'value'], weight: 0.2 },
        { name: ['metadata', 'title'], weight: 0.2 },
      ],
    });
  }, [treatments]);
  const filteredTreatments = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return fuse.search(trimmedSearchString).map((r) => r.item);
    }
    return null;
  }, [search, fuse]);

  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={`pa3 relative w-100 ${style.searchContainer}`}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div className="ph3">
        <Accordion>
          {(filteredTreatments ?? treatments).map((treatment) => (
            <AccordionItem key={treatment.api_id}>
              <AccordionHeader>
                <div>
                  <span className="near-black">{treatment.treatment_type?.name}</span>
                  <p className="f7 mid-gray">{formatDosingMethod(treatment)}</p>
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <TreatmentDetail treatment={treatment} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

const TreatmentDetail: FC<{ treatment: TreatmentV1 }> = ({ treatment }) => {
  const data: Array<KeyValue> = useMemo(() => {
    return [
      ...treatment.fields.map((f) => ({
        key: f.label,
        value: `${f.default_display_value} ${f.unit.display_unit}`,
      })),
      ...treatment.metadata.map((m) => ({
        key: m.title,
        value: renderMetadataValue({ value: m.value, field_type: m.field_type }),
      })),
    ];
  }, [treatment]);

  return (
    <div className="pb3">
      <div className="bg-light-gray near-black">
        <div className="pa2">
          {data.map(({ key, value }) => (
            <div key={key} className="w-100 flex pa1 f6">
              <div className="w-50 fw5">{key}</div>
              <div className="w-50 fw1">{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const AnimalsPanel: FC<GroupDetails> = ({ animals }) => {
  const [search, updateSearch] = useState('');
  const fuse = useMemo(() => {
    return new Fuse(animals, {
      includeScore: false,
      threshold: 0.15,
      ignoreLocation: true,
      keys: [
        { name: ['name'], weight: 0.2 },
        { name: ['dob'], weight: 0.2 },
        { name: ['catalog'], weight: 0.2 },
        { name: ['sex'], weight: 0.2 },
        { name: ['species_name'], weight: 0.2 },
        { name: ['strain_name'], weight: 0.2 },
        { name: ['cage', 'name'], weight: 0.2 },
        { name: ['cage', 'catalog'], weight: 0.2 },
        { name: ['cage', 'metadata', 'value'], weight: 0.2 },
        { name: ['cage', 'metadata', 'title'], weight: 0.2 },
        { name: ['metadata', 'value'], weight: 0.2 },
        { name: ['metadata', 'title'], weight: 0.2 },
      ],
    });
  }, [animals]);
  const filteredAnimals = useMemo(() => {
    const trimmedSearchString = (search ?? '').trim();
    if (trimmedSearchString.length > 0) {
      return fuse.search(trimmedSearchString).map((r) => r.item);
    }
    return null;
  }, [search, fuse]);

  return (
    <div className="w-100 h-100 overflow-y-auto">
      <div className={`pa3 relative w-100 ${style.searchContainer}`}>
        <span className="absolute z-5" style={{ top: '29px', left: '29px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <input
          className="ui__keyword-search-bar relative ba b--gray bg-white"
          type="text"
          placeholder="Search"
          onChange={(event) => updateSearch(event.target.value)}
        />
      </div>
      <div className="ph3">
        <Accordion>
          {(filteredAnimals ?? animals).map((animal) => (
            <AccordionItem key={animal.api_id}>
              <AccordionHeader>
                <div>
                  <span className="near-black">{animal.name}</span>
                  {_notNil(animal.cage) && (
                    <p className="f7 mid-gray">
                      <span>{animal.cage.name}</span>
                      {_notNil(animal.alt_ids.ear) && <span> &middot; {animal.alt_ids.ear}</span>}
                      {_notNil(animal.alt_ids.tail) && <span> &middot; {animal.alt_ids.tail}</span>}
                      {_notNil(animal.alt_ids.tag) && <span> &middot; {animal.alt_ids.tag}</span>}
                      {_notNil(animal.alt_ids.donor) && <span> &middot; {animal.alt_ids.donor}</span>}
                    </p>
                  )}
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <AnimalDetail animal={animal} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

const AnimalDetail: FC<{ animal: AnimalV1 }> = ({ animal }) => {
  const data: Array<KeyValue> = useMemo(() => {
    return [
      { key: 'Name', value: animal.name },
      { key: 'Catalog', value: animal.catalog },
      { key: 'Date of birth', value: DateUtils.renderDate(animal.dob, { defaultResponse: '-' }) },
      { key: 'Id ⏵ Ear', value: animal.alt_ids.ear ?? '-' },
      { key: 'Id ⏵ Tag', value: animal.alt_ids.tag ?? '-' },
      { key: 'Id ⏵ Tail', value: animal.alt_ids.tail ?? '-' },
      { key: 'Id ⏵ Donor', value: animal.alt_ids.donor ?? '-' },
      { key: 'Species', value: animal.species_name ?? '-' },
      { key: 'Strain', value: animal.strain_name ?? '-' },
      ...(animal.metadata ?? []).map((m) => ({
        key: m.title,
        value: renderMetadataValue({ value: m.value, field_type: m.field_type }),
      })),
      { key: 'Cage ⏵ Name', value: animal.cage?.name ?? '-' },
      { key: 'Cage ⏵ Catalog', value: animal.cage?.catalog ?? '-' },
      ...(animal.cage?.metadata ?? []).map((m) => ({
        key: `Cage ⏵ ${m.title}`,
        value: renderMetadataValue({ value: m.value, field_type: m.field_type }),
      })),
    ];
  }, [animal]);

  return (
    <div className="pb3">
      <div className="bg-light-gray near-black">
        <div className="pa2">
          {data.map(({ key, value }) => (
            <div className="w-100 flex pa1 f6">
              <div className="w-50 fw5">{key}</div>
              <div className="w-50 fw1">{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
