import Banner from '@/components/UI/Banner';
import Header from '@/components/UI/Header';
import { State } from '@/model/State.model.ts';
import * as Auth from '@/support/auth';
import { createSelector } from '@reduxjs/toolkit';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Manage from './Manage';

const selector = createSelector(
  [(state: State) => state?.team?.team, (state: State) => state?.user?.currentUser],
  (team, currentUser) => ({ team, currentUser })
);

const Team: FC = () => {
  const { team, currentUser } = useSelector(selector);

  return (
    <div className="ph4">
      <Header mainHeaderText="Users" />
      <div className="pt3">
        {!Auth.isCurrentTeamOwner() && (
          <Banner info className="mw6 mb4" dismiss={false}>
            <h3 className="f5 lh-copy">You don&apos;t have access to this section</h3>
            <p>Contact a team admin to make changes to your team&apos;s settings.</p>
          </Banner>
        )}
        <Manage team={team} currentUser={currentUser} />
      </div>
    </div>
  );
};
export default Team;
