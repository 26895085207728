// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Table from '@/components/UI/Table';
import { userRoles } from '@/referenceData/study/user';
import { useDispatch } from 'react-redux';

const ShowHiddenUsers = ({ users, author, owner, title }) => {
  const dispatch = useDispatch();

  if (owner) {
    users = [
      {
        id: owner.id,
        email: owner.email,
        name: owner.name,
        access: 'owner',
      },
      ...users,
    ];
  }

  if (author) {
    users = [
      {
        id: author.id,
        email: author.email,
        name: author.name,
        access: 'author',
      },
      ...users,
    ];
  }

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const columns = [
    {
      id: 'user',
      Header: 'User',
      accessor: ({ name, email }) => name || email,
      isVisible: true,
    },
    {
      id: 'permission',
      Header: 'Permissions',
      accessor: ({ access }) => userRoles[access],
      isVisible: true,
    },
  ];

  return (
    <div className="br2 overflow-hidden bg-near-white w-100 mw6 pa0 center mv3 shadow-4" style={{ paddingBottom: '0' }}>
      <div className="pa3 bb bg-white">
        <h3 className="basier-med normal f4 lh-title near-black">{title}</h3>
      </div>
      <div className="flex flex-wrap justify-between items-top">
        <div className="w-100 bg-white">
          <Table data={users} columns={columns} />
        </div>
      </div>
      <div className="pa3 bt bg-white b--moon-gray">
        <Button plain onClick={closeModal}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default ShowHiddenUsers;
