// @ts-nocheck: converted from JS

import { useEffect, useRef, useState } from 'react';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import Switch from '@/components/UI/FormElements/Switch';
import { filterEmptyAltIds, HoTcolumns } from './AssignIdentifiers.utils';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { _chunk, _isNotEmpty, _set } from '@/littledash';
import Spinner from '@/components/UI/Spinner';
import BulkGenerateForm from './BulkGenerateForm';
import { useRequest } from '@/support/Hooks/request';
import { toast } from 'react-toastify';
import SpreadSheet from '@/components/UI/SpreadSheet';
import './AssignIdentifiers.scss';
import { spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import type HotTable from '@handsontable/react';
import type { CellChange, ChangeSource } from 'handsontable/common';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import { useSelector } from 'react-redux';

const chunkSize = 50;

interface AssignIdentifiersProps {
  animals: Array<Animal>;
  handleCallback: (message?: string) => void;
  closeModal: () => void;
}

const initialAnimalData = (
  animals: Array<Animal>
): Array<{ id: ID; name: string; cage: Cage; alt_ids: Animal['alt_ids'] }> =>
  animals.map(({ id, name, cage, alt_ids: altIds }) => ({
    id,
    name,
    cage,
    alt_ids: { ...altIds },
  }));

const AssignIdentifiers: React.FC<AssignIdentifiersProps> = ({ animals, handleCallback, closeModal }) => {
  const [data, setData] = useState<Array<Animal>>(initialAnimalData(animals));
  const [changes, setChanges] = useState<Record<ID, Animal>>({});
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false);
  const [reloadOnClose, setReloadOnClose] = useState(false);
  const state = useSelector((state: State) => state?.team);

  const {
    sendRequest: updateAnimals,
    requestSending: updatingAnimals,
    requestError,
  } = useRequest({
    route: 'animals.identifiers.bulk.update',
    method: 'patch',
    config: { useAbortSignal: false },
  });

  const switchRef = useRef(false);
  const HoTRef = useRef<HotTable>();

  const handleSubmit = async () => {
    if (!isInvalid) {
      return handleSave();
    }
    return Promise.reject(new Error('Table data invalid'));
  };

  const handleSave = async () => {
    HoTRef.current?.hotInstance?.updateSettings({ readOnly: true });
    try {
      await Promise.all(
        _chunk(handleChangePayload(changes), chunkSize).map((animals) =>
          updateAnimals({ animals: filterEmptyAltIds(animals) })
        )
      );
      if (autoSaveEnabled) {
        HoTRef.current?.hotInstance?.updateSettings({ readOnly: false });
      } else {
        handleCallback('Succssfully assigned identifiers.');
      }
    } catch (err) {
      HoTRef.current?.hotInstance?.updateSettings({ readOnly: false });
    }
  };

  const handleChangePayload = (changes: Record<string, unknown>) => Object.values(changes);

  const handleBeforeChange = (dataChanges: Array<CellChange>) => {
    const { updatedData, updatedChanges } = dataChanges.reduce<{
      updatedData: Array<Animal>;
      updatedChanges: Record<ID, Animal>;
    }>(
      ({ updatedData, updatedChanges }, [row, column, , newValue]) => {
        _set(updatedData, `[${row}].${column}`, newValue);
        const { id } = animals[row];
        const { name, alt_ids: altIds } = updatedChanges?.[id] ?? animals[row];
        const updatedAnimal = { id, name, alt_ids: { ...altIds } };
        _set(updatedAnimal, column, newValue);
        return {
          updatedData,
          updatedChanges: { ...updatedChanges, [id]: updatedAnimal },
        };
      },
      { updatedData: [...data], updatedChanges: { ...changes } }
    );
    setChanges(updatedChanges);
    setData(updatedData);
  };

  const handleAfterChange = (dataChanges: Array<CellChange>, source: ChangeSource) => {
    HoTRef.current?.hotInstance?.validateRows(dataChanges?.map((change) => change[0]) ?? [], () => {
      if (source === 'edit' && !isInvalid && switchRef.current) {
        handleAutoSave(dataChanges);
      }
    });
  };

  const handleAutoSave = (dataChanges: Array<CellChange>) => {
    const updates = dataChanges.map(([row, column, , newValue]) => {
      const { id } = animals[row];
      const { name, alt_ids: altIds } = changes[id] ?? animals[row];
      const updatedAnimal = { id, name, alt_ids: { ...altIds } };
      _set(updatedAnimal, column, newValue);
      return updatedAnimal;
    });

    if (_isNotEmpty(updates)) {
      updateAnimals({ animals: filterEmptyAltIds(updates) });
    }
  };

  const handleBulkAssign = (dataChanges: Array<CellChange>) => {
    setAutoSaveEnabled(false);
    switchRef.current = false;
    setReloadOnClose(false);
    HoTRef.current?.hotInstance?.setDataAtRowProp(dataChanges);
  };

  const handleExitModal = () => {
    if (!updatingAnimals) {
      if (autoSaveEnabled || reloadOnClose) {
        handleCallback();
      }
      closeModal();
    }
  };

  useEffect(() => {
    if (autoSaveEnabled && Object.keys(changes).length > 0) {
      handleSubmit().catch(() => {
        setAutoSaveEnabled(false);
        switchRef.current = false;
        setReloadOnClose(true);
        toast.warning('Disabled autosave as pre existing changes have errors');
      });
    }
  }, [autoSaveEnabled]);

  const handleSwitch = () => {
    switchRef.current = !autoSaveEnabled;
    setAutoSaveEnabled(!autoSaveEnabled);
  };

  let columns = HoTcolumns;
  if (state?.features?.assign_identifiers_tag_unique === true) {
    columns = columns.filter((column) => column.data !== 'alt_ids.tag');
  }

  return (
    <ModalContainer size="wide" className="bg-white flex flex-column justify-between items-start">
      <ModalHeader
        title="Assign Identifiers to animals"
        closeModal={handleExitModal}
        closeDisabled={updatingAnimals}
        className="pa3 bb b--moon-gray w-100"
      />
      <div className="pa3 w-100">
        <>
          {requestError && <ApiErrorBanner error={requestError} className="mb3" />}
          <BulkGenerateForm data={animals} setData={handleBulkAssign} />
          <div className="ow-spreadsheet-styles assign-identifier-spreadsheet">
            <SpreadSheet
              innerRef={HoTRef}
              className={'mt3'}
              data={data}
              settings={{
                columns,
                colWidths: 200,
                width: '100%',
                height: 500,
                rowHeaders: true,
                colHeaders: true,
                allowInsertRow: false,
                allowInsertColumn: false,
              }}
              beforeChange={handleBeforeChange}
              afterChange={handleAfterChange}
              maxRows={data.length}
              addCol={false}
              setInvalid={setIsInvalid}
            />
          </div>
        </>
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center w-100">
        <ModalActions
          onSubmit={handleSubmit}
          onCancel={handleExitModal}
          cancelBtnText="Close"
          submitButtonProps={{
            loading: updatingAnimals,
            disabled: updatingAnimals || autoSaveEnabled || isInvalid,
            tooltip: isInvalid ? spreadSheetValidationTooltip : undefined,
          }}
          cancelButtonProps={{ disabled: updatingAnimals }}
        />
        <div className="flex items-center">
          {autoSaveEnabled && updatingAnimals && (
            <div className="flex items-center mr3">
              <Spinner size="small" className="mr2" />
              <span className="f6 lh-title near-black">Saving</span>
            </div>
          )}
          <Switch value={autoSaveEnabled} onChange={handleSwitch} disabled={false} />
          <label className="ml2 mb0">Auto save</label>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AssignIdentifiers;
