// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { renderMetadataValue } from '@/helpers';

const generateMetadataColumns = (linkMetadata, columns) =>
  linkMetadata?.map((meta) => {
    return {
      id: `metadata.${meta.slug}`,
      accessor: `metadata.${meta.slug}`,
      Header: meta.title,
      headerClassName: 'no-sort',
      isVisible: columns?.[`metadata.${meta.slug}`] ?? false,
      Cell: ({
        row: {
          original: { metadata },
        },
      }) => {
        const metadataItem = { ...meta, value: metadata[`${meta.slug}`] };
        if (metadataItem?.value) {
          return renderMetadataValue(metadataItem);
        }
        return false;
      },
    };
  });

export const initialColumns = (downloadFile, context, setLoading, metadata, columns) => [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row: { original } }) => (
      <Button
        onClick={() => {
          downloadFile({ context, name: original.name, setLoading });
        }}
        stateless
        className="link blue ph0"
      >
        {original.name}
      </Button>
    ),
    isVisible: columns?.['name'] ?? true,
  },
  {
    id: 'created',
    Header: 'Created',
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.metadata.created_at} />,
    isVisible: columns?.['created'] ?? true,
  },
  {
    id: 'updated',
    Header: 'Updated',
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.metadata.last_modified_at} />,
    isVisible: columns?.['updated'] ?? true,
  },
  {
    id: 'created_by',
    Header: 'Created By',
    accessor: 'metadata.created_by',
    isVisible: columns?.['created_by'] ?? true,
  },
  ...generateMetadataColumns(metadata, columns),
];
