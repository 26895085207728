import { MeasurementForm } from '@/components/Glossary/Sections/Presets/Builder/MeasurementForm.tsx';
import { MeasurementsForm } from '@/components/Glossary/Sections/Presets/Builder/MeasurementsForm.tsx';
import {
  CreateOrUpdatePresetContext,
  formDefault,
  generateIntegrity,
  reducer,
  reducerInitialState,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import { PresetHeader } from '@/components/Glossary/Sections/Presets/Builder/PresetHeader.tsx';
import { PresetPreview } from '@/components/Glossary/Sections/Presets/Builder/PresetPreview.tsx';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button/Button';
import { errorToast, successToast } from '@/helpers.tsx';
import { _isNil, _notNil } from '@/littledash.ts';
import InVivoError from '@/model/InVivoError.ts';
import { PresetApiId, PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { web } from '@/support/route.ts';
import { structuredCloneUtil } from '@/utils/browser.utils.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useReducer } from 'react';
import { Controller, useForm } from 'react-hook-form@latest';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import style from './PresetBuilder.module.scss';

export const PresetBuilder: FC = () => {
  const history = useHistory();
  const location = useLocation<{ activeMeasurement?: number; preset?: PresetCreateOrUpdateV1 }>();
  const [state, dispatch] = useReducer(reducer, reducerInitialState());

  const updatePresetState = useCallback(
    (preset: PresetCreateOrUpdateV1, updateInitial?: boolean) => {
      return generateIntegrity(JSON.stringify(preset))
        .then((integrity) =>
          dispatch({
            type: 'update-preset',
            data: preset,
            snapshot: (updateInitial ?? false) ? { initial: integrity, current: integrity } : { current: integrity },
          })
        )
        .catch(() => dispatch({ type: 'update-preset', data: preset }));
    },
    [dispatch]
  );

  useEffect(() => {
    const incoming = location?.state?.activeMeasurement ?? -1;
    if (incoming !== state.activeMeasurement) {
      dispatch({ type: 'set-active-measurement', index: incoming });
    }
  }, [location?.state?.activeMeasurement, state.activeMeasurement]);

  const { presetApiId, presetAction } = useParams<{
    presetApiId: PresetApiId;
    presetAction: 'create' | 'edit' | 'clone';
  }>();
  const { response, invoke: loadPreset } = useApiHook({
    endpoint: 'GET /api/v1/team/presets/{presetApiId}',
    invokeOnInit: false,
  });
  const { invoke: createPreset } = useApiHook({
    endpoint: 'POST /api/v1/team/presets',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });
  const { invoke: updatePreset } = useApiHook({
    endpoint: 'POST /api/v1/team/presets/{presetApiId}',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });
  const formDisabled = useMemo(
    () => (response?.type === 'success' && presetAction === 'edit' ? response.body.published : false),
    [response, presetAction]
  );
  const nameFormMethods = useForm<Pick<PresetCreateOrUpdateV1, 'name'>>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { name: '' },
  });
  const handleMeasurementSelected = (index: number) => {
    dispatch({ type: 'set-active-measurement', index });
    history.push({ state: { activeMeasurement: index } });
  };

  const handleSubmit = async () => {
    if (state.formState.isSubmitting) {
      return Promise.resolve();
    }
    dispatch({ type: 'set-submitting', value: true });
    if (formDisabled) {
      errorToast('Published preset cannot be updated');
      return Promise.resolve().finally(() => dispatch({ type: 'set-submitting', value: false }));
    }

    if (!state.formState.isValid || !nameFormMethods.formState.isValid) {
      errorToast(`Cannot ${presetAction} preset as there are errors`);
      return Promise.resolve().finally(() => dispatch({ type: 'set-submitting', value: false }));
    }
    const preset = { ...(state.preset as PresetCreateOrUpdateV1), ...nameFormMethods.getValues() };
    try {
      if (presetAction === 'edit') {
        await updatePreset({ path: { presetApiId }, body: preset });
      } else {
        await createPreset({ body: preset });
      }
      successToast('Preset saved');
      dispatch({ type: 'set-clean' });
      dispatch({ type: 'set-submitting', value: false });
      requestAnimationFrame(() => {
        history.push(web('team.glossary.presets'));
      });
    } catch (err) {
      const ivError = new InVivoError('Could not create new study preset', { slug: 'preset-builder', cause: err });
      ExceptionHandler.captureException(ivError);
      errorToast('Could not create preset');
      return Promise.reject(ivError).finally(() => dispatch({ type: 'set-submitting', value: false }));
    }
  };

  useEffect(() => {
    if (_notNil(presetApiId)) {
      loadPreset({ path: { presetApiId } }).then((response) => {
        if (response.type === 'success') {
          const { name, measurements } = response.body;
          updatePresetState({ name, measurements }, true);
          nameFormMethods.reset({ name });
        }
      });
    } else if (_notNil(location?.state?.preset)) {
      updatePresetState(location.state.preset);
      nameFormMethods.reset({ name: location.state.preset.name });
    } else {
      updatePresetState(structuredCloneUtil(formDefault));
      nameFormMethods.reset({ name: '' });
    }
  }, [updatePresetState, nameFormMethods, presetApiId]);

  const windowBeforeUnloadListener = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!state.formState.isClean) {
        event.preventDefault();
        return 'Changes you have made may not be saved';
      }
    },
    [state.formState]
  );
  const reactRouterBeforeUnload = useCallback(
    (location: ReturnType<typeof useLocation>) => {
      if (window.location.pathname !== location.pathname && !state.formState.isClean) {
        return 'Changes you have made may not be saved';
      }
      return true;
    },
    [state.formState.isClean]
  );
  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener('beforeunload', windowBeforeUnloadListener, {
      capture: true,
      signal: abortController.signal,
    });
    return () => abortController.abort('unload');
  }, [windowBeforeUnloadListener]);

  if (_isNil(state.preset)) {
    return <Loading txt={_isNil(presetApiId) ? 'Initialising preset' : 'Loading preset'} />;
  }
  const isClean = state.formState.isClean && !nameFormMethods.formState.isDirty;
  const isValid = state.formState.isValid && nameFormMethods.formState.isValid;

  return (
    <CreateOrUpdatePresetContext.Provider value={{ state, dispatch, updatePresetState }}>
      <Prompt message={reactRouterBeforeUnload} />

      {state.activeMeasurement < 0 || state.preset.measurements.length < state.activeMeasurement ? (
        <div className="flex w-100">
          <div className="flex flex-row h-100 flex-grow-1">
            <div className="pt3 pl4 mr2 flex flex-column w-100">
              <PresetHeader action={presetAction} />
              <Controller
                name="name"
                control={nameFormMethods.control}
                disabled={nameFormMethods.formState.disabled}
                rules={{
                  required: 'Name is required',
                  maxLength: { value: 255, message: 'Name must have length less than 255 characters' },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label>Preset name</label>
                    <input
                      {...field}
                      type="text"
                      autoComplete="off"
                      className={cn({ ui__disabled: field.disabled, ui__error: fieldState.invalid })}
                      style={{ minHeight: '41px' }}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={nameFormMethods.formState.errors}
                name="name"
                render={({ message }) => <p className="f6 red db">{message}</p>}
              />
              <div className="flex flex-row justify-between mt3">
                <MeasurementsForm handleMeasurementSelected={handleMeasurementSelected} />
              </div>

              <div className="flex flex-row pt4 pb4">
                <Button
                  className="mr3"
                  onClick={() => handleSubmit()}
                  loading={state.formState.isSubmitting}
                  testId="save-preset-button"
                  disabled={state.formState.isSubmitting || isClean || !isValid}
                >
                  Save preset
                </Button>
                <Button
                  plain
                  disabled={state.formState.isSubmitting}
                  onClick={() => history.push(web('team.glossary.presets'))}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          <div className={`${style.previewSidebar}`}>
            <div
              className={`${style.previewSidebar} fixed right-0 bl h-100 b--moon-gray ui__overflow__scroll_y bg-white`}
            >
              <PresetPreview />
            </div>
          </div>
        </div>
      ) : (
        <div className="pt3 ph4">
          <MeasurementForm index={state.activeMeasurement} handleClose={() => handleMeasurementSelected(-1)} />
        </div>
      )}
    </CreateOrUpdatePresetContext.Provider>
  );
};
