import type { ReactNode, FC } from 'react';
import { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { _notNil } from '@/littledash';

interface ReactPortalProps {
  children: ReactNode;
  wrapperId?: string;
}

const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  wrapperElement.setAttribute('data-testid', 'react-portal-wrapper');
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

const ReactPortal: FC<ReactPortalProps> = ({ children, wrapperId = 'react-portal-wrapper' }) => {
  const [wrapperElement, setWrapperElement] = useState<Element | null>();

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    element = createWrapperAndAppendToBody(wrapperId);
    setWrapperElement(element);
    return () => {
      element?.parentNode?.removeChild(element);
    };
  }, [wrapperId]);

  if (_notNil(wrapperElement)) {
    return createPortal(children, wrapperElement);
  }

  return null;
};

export default ReactPortal;
