// @ts-nocheck: converted from JS

import {
  FieldTypes,
  groupFieldTypeOptions,
  groupTitleValue,
  showSelectField,
} from '@/components/FormBuilder/Item.utils';
import SelectBuilder from '@/components/FormBuilder/SelectBuilder/SelectBuilder';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import ReactSelect from '@/components/UI/Select';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const FormBuilderItem = (props) => {
  const { control, errors, register, unregister } = useFormContext();

  const labelFieldName = `formItems[${props.index}].label`;
  const typeFieldName = `formItems[${props.index}].type`;
  const hintFieldName = `formItems[${props.index}].hint`;
  const optionsFieldName = `formItems[${props.index}].options`;
  const requiredFieldName = `formItems[${props.index}].required`;

  const hasLabelError = Boolean(errors?.formItems?.[props.index]?.label);
  const hasFieldTypeError = Boolean(errors?.formItems?.[props.index]?.type);

  const fieldTypesWithNoHintText = [groupTitleValue];
  const fieldTypesWithNoRequiredCheckbox = [groupTitleValue];

  const fieldType = useWatch({
    control,
    name: typeFieldName,
    defaultValue: props.type,
  });

  const fieldLabel = useWatch({
    control,
    name: labelFieldName,
    defaultValue: props.label,
  });

  const fieldHint = useWatch({
    control,
    name: hintFieldName,
    defaultValue: props.hint,
  });

  const fieldOptions = useWatch({
    control,
    name: optionsFieldName,
    defaultValue: props.options,
  });

  const fieldRequired = useWatch({
    control,
    name: requiredFieldName,
    defaultValue: props.required ?? true,
  });

  useEffect(() => {
    // switching field types or changing order of items can leave invalid fields registers for the current field type,
    // unregister them
    if (fieldType !== FieldTypes.select && fieldType !== FieldTypes.multi_select && fieldType !== FieldTypes.radio) {
      unregister(optionsFieldName);
    }
  }, [unregister, fieldType, optionsFieldName]);

  const defaultSelectValue = {
    label: 'Select...',
    value: '',
  };

  const getColumnById = (id) => props.columns.find((column) => column.id === id);
  const getWidth = (id) => getColumnById(id)?.width;

  const buildSelectStyles = (hasErrors) => ({
    control: (styles) => ({
      ...styles,
      border: hasErrors ? '2px solid red !important' : styles.border,
      '&:hover': {
        border: hasErrors ? '2px solid red !important' : styles.border,
      },
    }),
  });

  const getFieldTypeDefaultValue = (fieldType) =>
    fieldType === FieldTypes.radio
      ? groupFieldTypeOptions.find((option) => option.value === FieldTypes.select)
      : groupFieldTypeOptions.find((option) => option.value === fieldType);

  return (
    <div className="flex flex-row" data-test-component="FormBuilderItem" data-test-element="container">
      <div style={{ width: getWidth('fieldType') }} className="mr5">
        <div className="flex flex-column" data-test-element="field-type-select-container">
          <Controller
            defaultValue={
              fieldType
                ? getFieldTypeDefaultValue(fieldType)?.value || defaultSelectValue.value
                : defaultSelectValue.value
            }
            name={typeFieldName}
            rules={{
              required: 'This field is required',
            }}
            control={control}
            render={({ onChange, value }) => (
              <ReactSelect
                styles={buildSelectStyles(hasFieldTypeError)}
                defaultValue={value ? getFieldTypeDefaultValue(value) : value}
                options={groupFieldTypeOptions}
                onChange={({ value }) => {
                  props.onFieldTypeChange(value);
                  onChange(value);
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={typeFieldName}
            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
          />
        </div>
      </div>
      <div style={{ width: getWidth('fieldProperties') }} className="mr5">
        {fieldType && (
          <div>
            {showSelectField(fieldType) ? (
              <SelectBuilder
                names={{
                  label: labelFieldName,
                  options: optionsFieldName,
                }}
                defaultValue={{
                  label: fieldLabel || '',
                  options: fieldOptions || [{ label: '', value: '' }],
                }}
              />
            ) : (
              <div className="flex flex-column">
                <input
                  defaultValue={fieldLabel}
                  type="text"
                  data-test-element="label-field-input"
                  style={{ marginBottom: 0 }}
                  className={`${hasLabelError ? 'input__error' : ''}`}
                  name={labelFieldName}
                  ref={register({
                    required: 'This field is required',
                  })}
                  placeholder="Label"
                />
                <ErrorMessage
                  errors={errors}
                  name={labelFieldName}
                  render={({ message }) => <p className="f6 red db pt2">{message}</p>}
                />
              </div>
            )}
            {!fieldTypesWithNoHintText.includes(fieldType) && (
              <textarea
                defaultValue={fieldHint}
                name={hintFieldName}
                data-test-element="hint-field-textarea"
                ref={register({ maxLength: { value: 5000, message: 'Maximum of 5000 characters' } })}
                placeholder="Hint text (optional)"
                className="mt3"
                style={{ resize: 'auto' }}
              />
            )}
            {!fieldTypesWithNoRequiredCheckbox.includes(fieldType) && (
              <Controller
                defaultValue={fieldRequired}
                name={requiredFieldName}
                control={control}
                render={({ onChange, value }) => (
                  <Checkbox
                    type="checkbox"
                    onChange={(e) => onChange(e.target.checked)}
                    value={value}
                    label={'Required'}
                    checked={value}
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormBuilderItem;
