// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import { capitalise, formatNumber } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import React from 'react';

interface ModalMetadataItem {
  field_type: string;
  glossary_id: ID;
  title: string;
  value: string | Array<string> | number;
}

interface ModalTreatment extends Treatment {
  meta: Record<string, unknown>;
  metadata: Array<ModalMetadataItem>;
  stock: { value?: string; unit: string };
  temp_id: ID;
  treatment_name: string;
  treatment_type_id: ID;
}

interface TreatmentMetaProps {
  treatment: ModalTreatment;
  closeModal: () => void;
}

interface SectionProps {
  title: string;
  value: string;
  key: string | number;
}

const Section: React.FC<SectionProps> = ({ title, value, key }) => (
  <div key={key} className="bb b--moon-gray pa3 f6 break-word">
    <h4 className="basier-med lh-solid normal">{title}</h4>
    <p className="mt1 basier-reg normal lh-copy dark-gray">{value}</p>
  </div>
);

const TreatmentMeta: React.FC<TreatmentMetaProps> = ({ treatment, closeModal }) => {
  const metadata = treatment?.metadata ?? treatment?.meta ?? [];
  const renderValue = (value, field_type) => {
    if (_notNil(value)) {
      if (Array.isArray(value)) {
        return _isEmpty(value) ? '-' : value.map(safelyDecodeURIComponent).join(', ');
      }
      if (field_type === 'date') {
        return DateUtils.renderDate(value, { defaultResponse: '-' });
      }
      return safelyDecodeURIComponent(value);
    }
    return '-';
  };

  return (
    <div className="ui-card center" style={{ width: '400px' }}>
      <div className="pa3 relative">
        <div className={'f5 ui__group-label relative w-100 pr3 black'}>
          <div className="lh-title">{treatment.treatment_type.name}</div>
        </div>
        <a onClick={closeModal} className="absolute right-0 top-0 ma2 w2 h2 pointer flex hover-near-black">
          <Icon icon="close" width="12" height="12" className="self-center center" />
        </a>
      </div>
      {treatment?.fields?.map(({ label, default_display_value, default_value, name, unit }) => {
        const displayUnit = `${unit.display_unit ?? unit.name}`;
        const displayValue = formatNumber(default_display_value ?? default_value, true, 3) ?? '-';
        return (
          _isNotEmpty(default_value) && (
            <Section key={label} title={label ?? capitalise(name)} value={`${displayValue} ${displayUnit}`} />
          )
        );
      })}
      {metadata.map(({ title, value, field_type }, index) => (
        <Section key={index} title={title} value={renderValue(value, field_type)} />
      ))}
    </div>
  );
};

export default TreatmentMeta;
