import Navigation from '@/components/Navigation/index';
import { isCancelled } from '@/helpers';
import { _isEmpty } from '@/littledash';
import type { State, StudyState } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import React, { useEffect } from 'react';
import {
  RiCalendarTodoFill,
  RiEqualizerLine,
  RiFile3Line,
  RiFileDownloadLine,
  RiFileUploadLine,
  RiLayoutMasonryLine,
  RiLineChartLine,
  RiRulerLine,
  RiServerLine,
  RiSipLine,
  RiStethoscopeLine,
  RiSyringeLine,
  RiTable2,
  RiTableLine,
  RiTestTubeLine,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { _isNil, _notNil } from '@/littledash';
import { useParams } from 'react-router-dom';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors.tsx';

interface StudyNavProps {
  route: {
    path: string;
    private: boolean;
    exact: boolean;
    canCollapseNav: boolean;
  };
}

const getState = (reviewState: StudyState) => {
  switch (reviewState) {
    case 'in_progress':
      return {
        animals: false,
        cages: false,
        graphs: false,
        workflow: false,
        treatmentGroups: false,
        attachments: false,
        samples: false,
        observations: false,
        doses: false,
        import: false,
        export: false,
        settings: false,
        dataTables: false,
      };
    case 'awaiting_approval':
      return {
        overview: false,
        animals: true,
        cages: true,
        graphs: true,
        workflow: true,
        treatmentGroups: false,
        attachments: false,
        observations: true,
        doses: true,
        import: true,
        export: true,
        settings: false,
      };
    case 'rejected':
      return {
        overview: false,
        animals: true,
        cages: true,
        graphs: true,
        workflow: true,
        treatmentGroups: true,
        attachments: false,
        samples: true,
        observations: true,
        doses: true,
        import: true,
        export: true,
        settings: false,
      };
    case 'cancelled':
    default:
      return {
        overview: false,
        animals: true,
        cages: true,
        graphs: true,
        workflow: true,
        treatmentGroups: true,
        attachments: true,
        samples: true,
        observations: true,
        doses: true,
        import: true,
        export: true,
        settings: false,
      };
  }
};

const featureSelector = createSelector([featuresSelector], (features) => ({
  bulkDosingEnabled: features?.bulk_dosing ?? false,
}));

const StudyNav: React.FC<StudyNavProps> = ({ route }) => {
  const dispatch = useDispatch();
  const tasksVersion = useSelector(({ team: { features } }: State) => (!(features?.hide_tasks ?? false) ? 'V2' : 'V1'));
  const { id } = useParams<{ id: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id },
  });
  const { bulkDosingEnabled } = useSelector(featureSelector);

  const { studyState, currentStudyDetails } = useSelector(
    ({
      ui: {
        settings: { studyState },
        currentStudyDetails,
      },
    }: State) => ({
      studyState,
      currentStudyDetails,
    })
  );

  useEffect(() => {
    if (!studyLoading && _notNil(study)) {
      if (_notNil(studyState?.reviewState)) {
        const currentStudyState = isCancelled(study) ? 'cancelled' : study.status;
        if (currentStudyState !== studyState.reviewState) {
          dispatch({
            type: 'SET_CURRENT_STUDY_STATUS',
            studyState: {
              reviewState: currentStudyState,
              pageStates: getState(currentStudyState as StudyState),
            },
          });
        }
      }
      if (_notNil(id) && (_isNil(currentStudyDetails?.name) || currentStudyDetails.name !== study.name)) {
        dispatch({
          type: 'SET_STUDY_DETAILS',
          currentStudyDetails: {
            id: id,
            name: study.name,
            code: study.code,
          },
        });
      }
    }
  }, [studyLoading, studyState?.reviewState, study, dispatch]);

  if (_isEmpty(studyState)) {
    return null;
  }

  const navigation = [
    {
      name: 'Overview',
      webRoute: 'studies.overview',
      icon: <RiLayoutMasonryLine />,
    },
    ...(tasksVersion !== 'V2'
      ? []
      : [
          {
            name: 'Schedule',
            webRoute: 'studies.schedule',
            icon: <RiCalendarTodoFill />,
          },
        ]),
    {
      name: 'Animals',
      webRoute: 'studies.animals',
      icon: <RiTableLine />,
      isDisabled: studyState?.pageStates?.animals ?? true,
    },
    {
      name: 'Cages',
      webRoute: 'studies.cages',
      icon: <RiServerLine />,
      isDisabled: studyState?.pageStates?.cages ?? true,
    },
    {
      name: 'Graphs',
      webRoute: 'studies.graphs',
      icon: <RiLineChartLine />,
      isDisabled: studyState?.pageStates?.graphs ?? true,
    },
    {
      name: 'Workflow',
      webRoute: 'studies.workflow',
      icon: <RiRulerLine />,
      isDisabled: studyState?.pageStates?.workflow ?? true,
    },
    {
      name: 'Treatment groups',
      webRoute: 'studies.studyGroups',
      icon: <RiSyringeLine />,
      isDisabled: studyState?.pageStates?.treatmentGroups ?? true,
    },
    {
      name: 'Attachments',
      webRoute: 'studies.attachments',
      icon: <RiFile3Line />,
      isDisabled: studyState?.pageStates?.attachments ?? true,
    },
    {
      name: 'Samples',
      webRoute: 'studies.samples',
      icon: <RiTestTubeLine />,
      isDisabled: studyState?.pageStates?.samples ?? true,
    },
    {
      name: 'Observations',
      webRoute: 'studies.observations',
      icon: <RiStethoscopeLine />,
      isDisabled: studyState?.pageStates?.observations ?? true,
    },
    ...(bulkDosingEnabled
      ? [
          {
            name: 'Doses',
            webRoute: 'studies.dosing.list',
            icon: <RiSipLine />,
            isDisabled: studyState?.pageStates?.doses ?? true,
          },
        ]
      : []),
    {
      name: 'Data tables',
      webRoute: 'studies.dataTables.list',
      icon: <RiTable2 />,
      isDisabled: studyState?.pageStates?.dataTables ?? true,
    },

    {
      name: 'Import',
      webRoute: 'studies.import',
      icon: <RiFileDownloadLine />,
      isDisabled: studyState?.pageStates?.import ?? true,
    },
    {
      name: 'Export',
      webRoute: 'studies.export',
      icon: <RiFileUploadLine />,
      isDisabled: studyState?.pageStates?.export ?? true,
    },
    {
      name: 'Settings',
      webRoute: 'studies.settings',
      icon: <RiEqualizerLine />,
    },
  ];

  return <Navigation route={{ ...route, navigation }} studyNames={currentStudyDetails} />;
};

export default StudyNav;
