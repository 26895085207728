// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import DataTable from '@/components/UI/Table';
import { formatNumber } from '@/helpers';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useParams } from 'react-router-dom';

const Averages = () => {
  const { id: studyId } = useParams();
  const {
    entity: metrics,
    entityLoading: metricsLoading,
    entityError: metricsError,
  } = useFetchEntity({
    entityType: 'studyMetrics',
    params: { id: studyId },
    includes: 'averages',
  });

  const measurementColumns = [
    {
      Header: 'Measurement',
      accessor: 'label',
    },
    {
      id: 'value',
      Header: 'Value',
      accessor: ({ value, unit }) => (value ? `${formatNumber(value)} ${unit}` : '-'),
    },
  ];
  return (
    <div className="ui-card mb3">
      {metricsLoading ? (
        <Loading txt="Loading averages..." />
      ) : (
        <>
          {metricsError ? (
            <ApiErrorBanner className="ma3" errorType={ApiErrorType.FETCH} error={metricsError} />
          ) : (
            <div className="overflow-table">
              <DataTable columns={measurementColumns} data={metrics.averages} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Averages;
