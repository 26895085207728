// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon';
import type { ID } from '@/model/Common.model';
import useSortableDrop from '@/support/Hooks/draggable/useSortableDrop';
import { FC, PropsWithChildren, useRef } from 'react';
import { useDrag } from 'react-dnd';
import './sortable.scss';

interface SortableProps {
  className: string;
  id: ID;
  index: number;
  moveCallback: (sourceIndex: number, destinationIndex: number) => void;
  dropCallback?: () => void;
  revertCallback?: (id: ID, index: number) => void;
  draggableType: string;
  showDraggableIcon: boolean;
}

const Sortable: FC<PropsWithChildren<SortableProps>> = ({
  children,
  className,
  id,
  index,
  moveCallback,
  dropCallback,
  revertCallback,
  draggableType,
  showDraggableIcon,
}) => {
  const ref = useRef(null);
  const [{ draggedItem, handlerId }, drop] = useSortableDrop(draggableType, ref, index, { moveCallback, dropCallback });
  const [{ isDragging }, drag] = useDrag({
    item: { type: draggableType, id, index, originalIndex: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop && revertCallback) {
        revertCallback(item.id, item.originalIndex);
      }
    },
  });

  const isSourceItem = draggedItem?.originalIndex === index;
  // dragging items into new column creates a new ref, therefore we need to manually compare ids
  const isDraggedItemInNewColumn = draggedItem?.id === id && !isSourceItem;
  const isInvisible = isDragging || isDraggedItemInNewColumn;
  drag(drop(ref));
  return (
    <div className={`${isInvisible && 'opacity-0'} movable ${className}`} ref={ref} data-handler-id={handlerId}>
      <div>{children}</div>
      {showDraggableIcon && <Icon icon="drag" className="dib mid-gray" width="20" height="20" />}
    </div>
  );
};

export default Sortable;
