import AssignGroups from '@/components/AssignGroups';
import CreateTreatmentGroups from '@/components/CreateTreatmentGroups';
import { WeightTitleEditModal } from '@/components/Glossary/Sections/Presets/Builder/WeightTitleEditModal.tsx';
import ImportAnimals from '@/components/Integrations/Atunes/Import/ImportAnimals';
import AddAdditionalReviewers from '@/components/Modals/AddAdditionalReviewers';
import AddApiKey from '@/components/Modals/AddApiKey';
import AddCages from '@/components/Modals/AddCages/AddCages';
import { AddDevice } from '@/components/Modals/AddDevice/AddDevice';
import AddEditLink from '@/components/Modals/AddEditLink/AddEditLink';
import AddFileMeta from '@/components/Modals/AddFileMeta/AddFileMeta';
import AddMeasurement from '@/components/Modals/AddMeasurement';
import AddMetaColumn from '@/components/Modals/AddMetaColumn/AddMetaColumn';
import AddObservations from '@/components/Modals/AddObservations';
import { EditObservation } from '@/components/Modals/AddObservations/EditObservation';
import { SelectObservationsModal as SelectObservations } from '@/components/Modals/AddObservations/SelectObservations';
import ViewObservation from '@/components/Modals/AddObservations/ViewObservation';
import { AddStudyMetadata } from '@/components/Modals/AddStudyMetadata/AddStudyMetadata';
import AddSubjects from '@/components/Modals/AddSubjects';
import AddTask from '@/components/Modals/AddTask';
import EditTask from '@/components/Modals/AddTask/EditTask';
import LegacyAddTask from '@/components/Modals/AddTask/Legacy';
import AddTaskTemplate from '@/components/Modals/AddTaskTemplate';
import AddTreatmentForm from '@/components/Modals/AddTreatmentForm';
import AddTreatments from '@/components/Modals/AddTreatments';
import AnimalAlertsModal from '@/components/Modals/AnimalAlertsModal';
import { AnimalMeta } from '@/components/Modals/AnimalMeta/AnimalMeta';
import ApiKeyCreated from '@/components/Modals/ApiKeyCreated';
import ArchiveStudy from '@/components/Modals/ArchiveStudy';
import AssignIdentifiers from '@/components/Modals/AssignIdentifiers';
import AttachRequestSamples from '@/components/Modals/AttachRequestSamples';
import { BulkReasonForChange } from '@/components/Modals/BulkReasonForChange/BulkReasonForChange';
import CancelStudy from '@/components/Modals/CancelStudy';
import CompleteAccountModal from '@/components/Modals/CompleteAccountModal/CompleteAccountModal';
import ConfirmDelete from '@/components/Modals/ConfirmDelete';
import { TimedDeleteConfirmation } from '@/components/Modals/ConfirmDelete/TimedDeleteConfirmation';
import ConfirmStudyApproval from '@/components/Modals/ConfirmStudyApproval';
import CreateStudyForm from '@/components/Modals/CreateStudyForm';
import { HighVolumeUpsertModal } from '@/components/Modals/DataTables';
import { DataTableWorkflowConfigure } from '@/components/Modals/DataTableWorkflowConfigure/DataTableWorkflowConfigure';
import { DeviceConnections } from '@/components/Modals/DeviceConnections/DeviceConnections';
import EditDatatable from '@/components/Modals/EditDatatable';
import { EditDatatableColumn } from '@/components/Modals/EditDatatableColumn/EditDatatableColumn';
import { EditDataTableFormulaColumn } from '@/components/Modals/EditDataTableFormulaColumn/EditDataTableFormulaColumn';
import { EditDatatableMeasObsColumn } from '@/components/Modals/EditDatatableMeasObsColumn/EditDatatableMeasObsColumn';
import { EditDataTableTimestampBaselineColumn } from '@/components/Modals/EditDataTableTimestampBaselineColumn/EditDataTableTimestampBaselineColumn';
import { EditDataTableTimestampBaselineRelativeColumn } from '@/components/Modals/EditDataTableTimestampBaselineRelativeColumn/EditDataTableTimestampBaselineRelativeColumn';
import { EditDevice } from '@/components/Modals/EditDevice/EditDevice';
import EditSubject from '@/components/Modals/EditSubject';
import { EditSubjectId } from '@/components/Modals/EditSubjectId/EditSubjectId';
import EditTreatment from '@/components/Modals/EditTreatment';
import { EuthaniseSubject } from '@/components/Modals/EuthaniseSubject/EuthaniseSubject';
import UndoEuthanise from '@/components/Modals/EuthaniseSubject/UndoEuthanise';
import ExportStudy from '@/components/Modals/ExportStudy';
import { GroupInformation } from '@/components/Modals/GroupInformation/GroupInformation';
import GroupMeta from '@/components/Modals/GroupMeta';
import { Info } from '@/components/Modals/Info/Info';
import InviteUsers from '@/components/Modals/InviteUsers';
import ManageAccount from '@/components/Modals/ManageAccount';
import { MoveDatatableColumn } from '@/components/Modals/MoveDatatableColumn/MoveDatatableColumn';
import { MoveSubject } from '@/components/Modals/MoveSubject/MoveSubject';
import MoveSubjectSearch from '@/components/Modals/MoveSubjectSearch';
import MoveToStudy from '@/components/Modals/MoveToStudy';
import MoveToStudyList from '@/components/Modals/MoveToStudy/MoveToStudyList';
import MoveToStudySuccess from '@/components/Modals/MoveToStudy/MoveToStudySuccess';
import OneWayAnovaTable from '@/components/Modals/OneWayAnovaTable';
import RemoveCageFromStudy from '@/components/Modals/RemoveCageFromStudy';
import RemoveFromGroup from '@/components/Modals/RemoveFromGroup';
import ResumeStudy from '@/components/Modals/ResumeStudy';
import RevokeInvitation from '@/components/Modals/RevokeInvitiation';
import { SelectSamplesModal as SelectSamples } from '@/components/Modals/Samples/SelectSamples';
import ViewSample from '@/components/Modals/Samples/ViewSample';
import SelectStudyCreationForm from '@/components/Modals/SelectStudyCreationForm';
import SelectStudyRequests from '@/components/Modals/SelectStudyRequests';
import SelectTreatment from '@/components/Modals/SelectTreatment';
import SetTrackingDate from '@/components/Modals/SetTrackingDate';
import SetupWorkflow from '@/components/Modals/SetupWorkflow';
import ShowHiddenUsers from '@/components/Modals/ShowHiddenUsers';
import StudyCodeAddSequence from '@/components/Modals/StudyCodeAddSequence/StudyCodeAddSequence';
import StudyCodeAddText from '@/components/Modals/StudyCodeAddText/StudyCodeAddText';
import StudyCodeAddYear from '@/components/Modals/StudyCodeAddYear/StudyCodeAddYear';
import { TaskDetails } from '@/components/Modals/TaskDetails/TaskDetails';
import { ShiftTaskDates } from '@/components/Modals/TaskSpec/ShiftTaskDates';
import TreatmentMeta from '@/components/Modals/TreatmentMeta';
import { UpdateDiseaseInductionDate } from '@/components/Modals/UpdateDiseaseInductionDate/UpdateDiseaseInductionDate';
import UpdateDOB from '@/components/Modals/UpdateDOB';
import UpdateSex from '@/components/Modals/UpdateSex';
import ViewTask from '@/components/Modals/ViewTask/ViewTask';
import { AddAlertForm } from '@/components/Studies/Create/Steps/Alerts/AddAlertForm';
import { AnalysisIssuesModal } from '@/components/Studies/Groups/Analysis/AnalysisIssuesModal';
import BulkCreateSamples from '@/components/Studies/Samples/BulkCreate/BulkCreateSamples';
import ViewBulkSampleDetails from '@/components/Studies/Samples/BulkCreate/ViewBulkSampleDetails';
import CreateSamples from '@/components/Studies/Samples/Create';
import UpdateSample from '@/components/Studies/Samples/Update';
import { ExecuteTasksModal } from '@/components/Studies/Tasks/ExecuteTasks.modal.tsx';
import CreateStudyFormSection from '@/components/Team/Studies/Forms/SectionForm';
import AddRows from '@/components/UI/DataTable/toolbar/Menus/AddRows';
import ScrollManager from '@/components/UI/ScrollManager';
import React, { LegacyRef, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface ScrollManagerProps {
  connectScrollTarget?: LegacyRef<ReactModal>;
  scrollKey: string;
  scrollStore: {
    get: (key: string) => void;
    set: (key: string, data: unknown) => void;
    _date: Array<unknown>;
  };
}

const ModalContainer: React.FC = () => {
  const { open, type, props } = useSelector(
    (state: {
      ui: {
        modal: {
          open: boolean;
          type?: string;
          props?: any;
        };
      };
    }) => state.ui.modal
  );

  const renderContent = () => {
    switch (type) {
      case 'ADD_TREATMENTS_FORM':
        return <AddTreatmentForm {...props} />;
      case 'ADD_ALERT_FORM':
        return (
          <AddAlertForm
            calculations={props.calculations}
            alert={props.alert}
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}
          />
        );
      case 'ADD_TREATMENTS':
        return <AddTreatments {...props} />;
      case 'EDIT_TREATMENT':
        return <EditTreatment {...props} />;
      case 'SELECT_TREATMENT':
        return <SelectTreatment {...props} />;
      case 'CONFIRM_DELETE_DOSAGE':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this dosage?"
            text="The delete action can’t be undone and all the content of this dosage will be permanently gone."
            btnTxt="Delete dosage"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_TREATMENTS':
        return (
          <ConfirmDelete
            {...props}
            title={`Are you sure you would like to delete ${
              props.length > 1 ? 'these treatments' : 'this treatment'
            } and any associated dosages?`}
            text={`The delete action can't be undone and ${
              props.length > 1 ? 'these treatments' : 'this treatment'
            } and dosages will be permanently gone.`}
            btnTxt={`Delete treatment${props.length > 1 ? 's' : ''}`}
          />
        );
      case 'CONFIRM_DELETE_DATATABLE':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this datatable?"
            text="The delete action can’t be undone and all the content of this datatable will be permanently gone."
            btnTxt="Delete datatable"
          />
        );
      case 'INVITE_USERS':
        return <InviteUsers {...props} />;
      case 'COMPLETE_ACCOUNT':
        return <CompleteAccountModal {...props} />;
      case 'ADD_SUBJECTS':
        return <AddSubjects {...props} />;
      case 'EDIT_SUBJECT':
        return <EditSubject {...props} />;
      case 'EDIT_SUBJECT_ID':
        return (
          <EditSubjectId
            closeModal={props.closeModal}
            onComplete={props.onComplete}
            id={props.id}
            subject={props.subject}
          />
        );
      case 'MOVE_SUBJECT':
        return <MoveSubject {...props} />;
      case 'EUTHANISE_SUBJECT':
        return <EuthaniseSubject {...props} />;
      case 'UNDO_EUTHANISE_SUBJECT':
        return <UndoEuthanise {...props} />;
      case 'BULK_EUTHANISE_SUBJECTS':
        return <EuthaniseSubject {...props} />;
      case 'ASSIGN_SUBJECT_TO_GROUP':
        return <AssignGroups {...props} />;
      case 'REMOVE_SUBJECT_FROM_GROUP':
        return <RemoveFromGroup {...props} />;
      case 'ADD_CAGES':
        return <AddCages {...props} />;
      case 'ADD_EDIT_FILE':
        return <AddFileMeta {...props} />;
      case 'ADD_EDIT_LINK':
        return <AddEditLink {...props} />;
      case 'CONFIRM_DELETE_OBSERVATION':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this observation?"
            text="The delete action can’t be undone and all the content of this observation will be permanently gone."
            btnTxt="Delete observation"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_METRIC':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this metric?"
            text="The delete action can’t be undone and all the content of this metric will be permanently gone."
            btnTxt="Delete metric"
            showReasonForChange
          />
        );
      case 'ARCHIVE_STUDY':
        return <ArchiveStudy {...props} />;
      case 'CANCEL_STUDY':
        return <CancelStudy {...props} />;
      case 'RESUME_STUDY':
        return <ResumeStudy {...props} />;
      case 'CONFIRM_DELETE_SUBJECT':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this animal?"
            text="The delete action can’t be undone and all the content of this animal will be permanently gone including measurements."
            btnTxt="Delete animal"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_COLLECTION':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this Cage?"
            text="The delete action can’t be undone and all the content of this cage will be permanently gone including animals and measurements."
            btnTxt="Delete Cage"
          />
        );
      case 'CONFIRM_DELETE_COLLECTIONS':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these cages?"
            text="The delete action can’t be undone and all the content of these cages will be permanently gone including animals and measurements."
            btnTxt="Delete cages"
          />
        );
      case 'CONFIRM_DELETE_EMPTY_COLLECTIONS':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you want to delete empty cages?"
            text="The delete action can’t be undone and all of the selected cages will be permantely gone."
            btnTxt="Delete cages"
          />
        );
      case 'CONFIRM_DELETE_STUDY':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this study?"
            text="The delete action can’t be undone and all the content of this study will be permanently gone. Cages and Animals must be deleted in advance."
            btnTxt="Delete study"
          />
        );
      case 'CONFIRM_DELETE_GROUP':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this group?"
            text="The delete action can’t be undone and all group data will be permanantly gone."
            btnTxt="Delete group"
          />
        );
      case 'CONFIRM_DELETE_SUBJECTS':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these animals?"
            text="The delete action can’t be undone and all the content of these animals will be permanently gone including measurements."
            btnTxt="Delete animals"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_DOSES':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these doses?"
            text="The delete action can’t be undone and all the content of these doses will be permanently gone."
            btnTxt="Delete doses"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_SAMPLES':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these samples?"
            text="The delete action can’t be undone and all the content of these samples will be permanently gone."
            btnTxt="Delete samples"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_TEMPLATE':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this template?"
            text="The delete action can’t be undone and this template will be permanantly gone. All future tasks assosiated with this template will also be deleted permanantly."
            btnTxt="Delete template"
          />
        );
      case 'CONFIRM_DELETE_TASK':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this task?"
            text="The delete action can’t be undone and this task will be permanantly gone."
            btnTxt="Delete task"
          />
        );
      case 'CONFIRM_DELETE_SAMPLE':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this sample?"
            text="The delete action can’t be undone and this sample will be permanantly gone."
            btnTxt="Delete sample"
            showReasonForChange
          />
        );
      case 'CONFIRM_DELETE_SPECIES':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this species?"
            text="The delete action can't be undone and this species will be permanantly gone, along with any associated strains."
            btnTxt="Delete species"
          />
        );
      case 'CONFIRM_DELETE_API_KEY':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this API key?"
            text="The delete action can’t be undone and the credentials will be permanently gone including the client ID."
            btnTxt="Delete API key"
          />
        );
      case 'CONFIRM_DELETE_API_KEYS':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these API keys?"
            text="The delete action can’t be undone and the credentials will be permanently gone including the client IDs."
            btnTxt="Delete API keys"
          />
        );
      case 'CONFIRM_DELETE_APPROVAL_TEMPLATE':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this review template?"
            text="The delete action can’t be undone and the review template will be permanently gone.  This will not affect any existing reviews."
            btnTxt="Delete review template"
          />
        );
      case 'CONFIRM_DELETE_APPROVAL_TEMPLATES':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these review templates?"
            text="The delete action can’t be undone and the review templates will be permanently gone. This will not affect any existing reviews."
            btnTxt="Delete review templates"
          />
        );
      case 'CONFIRM_DELETE_STUDY_FORM_SECTION':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this section?"
            text="The delete action can’t be undone and the section will be permanently gone."
            btnTxt="Delete section"
          />
        );
      case 'CONFIRM_DELETE_STUDY_FORM':
        return (
          <TimedDeleteConfirmation
            title="Are you sure you would like to delete these forms?"
            render={props.deleteManyForms}
            text="The delete action can’t be undone and the forms will be permanently gone."
            btnTxt="Delete forms"
            onClick={props.onClick}
          />
        );
      case 'CONFIRM_DELETE_STUDY_CODES':
        return (
          <ConfirmDelete
            {...props}
            title={`Are you sure you would like to delete ${
              props.length > 1 ? 'these study codes' : 'this study code'
            }?`}
            text={`The delete action can't be undone and ${
              props.length > 1 ? 'these study codes' : 'this study code'
            } will be permanently gone.`}
            btnTxt={`Delete study code${props.length > 1 ? 's' : ''}`}
          />
        );
      case 'CONFIRM_DELETE_LINK':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this link?"
            text="The delete action can’t be undone and the link will be permanently gone."
            btnTxt="Delete link"
          />
        );
      case 'CONFIRM_DELETE_FILE':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this file?"
            text="The delete action can’t be undone and the file will be permanently gone."
            btnTxt="Delete file"
          />
        );
      case 'CONFIRM_DELETE_DATA_TABLE_COLUMN':
        return (
          <TimedDeleteConfirmation
            title="Are you sure you would like to delete this column?"
            render={props.deleteManyColumns}
            text="The delete action can’t be undone and the column will be permanently gone."
            btnTxt="Delete column"
            onClick={props.onClick}
          />
        );
      case 'CONFIRM_DELETE_DATA_TABLE_ROW':
        return (
          <TimedDeleteConfirmation
            title="Are you sure you would like to delete this row?"
            text="This row will be removed from the Data table, this action can be undone by adding the animal to the table as all data in the row will not be cleared."
            btnTxt="Delete row"
            onClick={props.onClick}
          />
        );
      case 'CONFIRM_DELETE_TASK_SPEC':
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete this Task Spec?"
            text="Deleting this Task Spec will delete all future tasks related to it."
            btnTxt="Delete Task Spec"
          />
        );
      case 'MANAGE_ACCOUNT':
        return <ManageAccount {...props} />;
      case 'REVOKE_ACCOUNT':
        return <RevokeInvitation {...props} />;
      case 'EXPORT_STUDY':
        return <ExportStudy {...props} />;
      case 'SET_TRACKING_DATE':
        return <SetTrackingDate {...props} />;
      case 'UPDATE_DISEASE_INDUCTION_DATE':
        return (
          <UpdateDiseaseInductionDate
            studyApiId={props.studyApiId}
            animalApiIds={props.animalApiIds}
            handleCallback={props.handleCallback}
          />
        );
      case 'UPDATE_SEX':
        return <UpdateSex {...props} />;
      case 'UPDATE_DOB':
        return <UpdateDOB {...props} />;
      case 'MOVE_TO_STUDY':
        return <MoveToStudy {...props} />;
      case 'MOVE_TO_STUDY_SELECT_STUDY':
        return <MoveToStudyList {...props} />;
      case 'MOVE_TO_STUDY_SUCCESS':
        return <MoveToStudySuccess {...props} />;
      case 'ADD_MEASUREMENT':
        return <AddMeasurement {...props} />;
      case 'ADD_OBSERVATION':
        return <AddObservations {...props} />;
      case 'EDIT_OBSERVATION':
        return <EditObservation {...props} />;
      case 'SELECT_OBSERVATIONS':
        return <SelectObservations {...props} />;
      case 'VIEW_OBSERVATION':
        return <ViewObservation {...props} />;
      case 'LEGACY_ADD_TASK':
        return <LegacyAddTask {...props} />;
      case 'ADD_TASK':
        return (
          <AddTask
            closeModal={props.closeModal}
            studyId={props?.studyId}
            studyApiId={props?.studyApiId}
            handleSubmit={props?.handleSubmit}
            studyStartedOn={props.studyStartedOn}
            studyCreationUsers={props?.studyCreationUsers}
            teamUsers={props?.teamUsers}
            execution={props?.execution}
            studyCreationGroups={props?.studyCreationGroups}
          />
        );
      case 'EDIT_TASK':
        return (
          <EditTask
            closeModal={props.closeModal}
            studyId={props?.studyId}
            taskSpec={props?.taskSpec}
            studyCreationTaskSpec={props?.studyCreationTaskSpec}
            studyApiId={props?.studyApiId}
            studyCreationUsers={props?.studyCreationUsers}
            teamUsers={props?.teamUsers}
            studyCreationGroups={props?.studyCreationGroups}
            handleSubmit={props?.handleSubmit}
            disabledSections={props?.disabledSections}
            execution={props?.execution}
            initialTab={props?.initialTab}
          />
        );
      case 'EXECUTE_TASKS':
        return <ExecuteTasksModal tasks={props.tasks} onExecute={props.onExecute} onCancel={props.onCancel} />;
      case 'SHOW_HIDDEN_USERS':
        return <ShowHiddenUsers {...props} />;
      case 'ADD_TASK_TEMPLATE':
        return <AddTaskTemplate {...props} />;
      case 'SETUP_WORKFLOW':
        return <SetupWorkflow {...props} />;
      case 'ADD_SAMPLE':
        return <CreateSamples {...props} />;
      case 'EDIT_SAMPLE':
        return <UpdateSample {...props} />;
      case 'SELECT_SAMPLES':
        return <SelectSamples {...props} />;
      case 'VIEW_SAMPLE':
        return <ViewSample {...props} />;
      case 'COLLECT_SAMPLES':
        return <BulkCreateSamples closeModal={props.closeModal} handleCallback={props.handleCallback} />;
      case 'VIEW_SAMPLE_DETAILS':
        return (
          <ViewBulkSampleDetails
            studyId={props.studyId}
            subjects={props.subjects}
            sampleDetails={props.sampleDetails}
            metadata={props.metadata}
            closeModal={props.closeModal}
            handleCallback={props.handleCallback}
          />
        );
      case 'ASSIGN_IDENTIFIERS':
        return <AssignIdentifiers {...props} />;
      case 'CREATE_TREATMENT_GROUPS':
        return <CreateTreatmentGroups {...props} />;
      case 'GROUP_INFORMATION':
        return <GroupInformation studyApiId={props.studyApiId} groupApiId={props.groupApiId} />;
      case 'GROUP_META':
        return <GroupMeta {...props} />;
      case 'ONEWAYANOVA_TABLE':
        return <OneWayAnovaTable {...props} />;
      case 'ANIMAL_META':
        return <AnimalMeta {...props} />;
      case 'TREATMENT_META':
        return <TreatmentMeta {...props} />;
      case 'VIEW_TASK':
        return <ViewTask {...props} />;
      case 'ADD_META_COLUMN':
        return <AddMetaColumn {...props} />;
      case 'MOVE_SUBJECT_SEARCH':
        return <MoveSubjectSearch {...props} />;
      case 'ADD_API_KEY':
        return <AddApiKey {...props} />;
      case 'API_KEY_CREATED':
        return <ApiKeyCreated {...props} />;
      case 'REMOVE_CAGE_FROM_STUDY':
        return <RemoveCageFromStudy {...props} />;
      case 'CONFIRM_APPROVAL_STATUS':
        return <ConfirmStudyApproval {...props} />;
      case 'SELECT_STUDY_CREATION_FORM':
        return <SelectStudyCreationForm {...props} />;
      case 'CREATE_STUDY_FORM':
        return <CreateStudyForm {...props} />;
      case 'CREATE_STUDY_FORM_SECTION':
        return <CreateStudyFormSection {...props} />;
      case 'SELECT_STUDY_REQUESTS':
        return <SelectStudyRequests {...props} />;
      case 'STUDY_CODE_ADD_TEXT':
        return <StudyCodeAddText {...props} />;
      case 'STUDY_CODE_ADD_YEAR':
        return <StudyCodeAddYear {...props} />;
      case 'STUDY_CODE_ADD_SEQUENCE':
        return <StudyCodeAddSequence {...props} />;
      case 'REQUEST_ATTACH_SAMPLE':
        return <AttachRequestSamples {...props} />;
      case 'ADD_ADDITIONAL_REVIEWERS':
        return <AddAdditionalReviewers {...props} />;
      case 'ADD_STUDY_METADATA':
        return <AddStudyMetadata {...props} />;
      case 'ANIMAL_ALERTS':
        return <AnimalAlertsModal {...props} />;
      case 'ADD_DATATABLE_ROW':
        return <AddRows {...props} />;
      case 'EDIT_DATATABLE':
        return <EditDatatable {...props} />;
      case 'EDIT_DATATABLE_COLUMN':
        return <EditDatatableColumn {...props} />;
      case 'EDIT_DATATABLE_FORMULA_COLUMN':
        return <EditDataTableFormulaColumn {...props} />;
      case 'EDIT_DATATABLE_TIMESTAMP_BASELINE_COLUMN':
        return <EditDataTableTimestampBaselineColumn {...props} />;
      case 'EDIT_DATATABLE_TIMESTAMP_BASELINE_RELATIVE_COLUMN':
        return <EditDataTableTimestampBaselineRelativeColumn {...props} />;
      case 'MOVE_DATATABLE_COLUMN':
        return <MoveDatatableColumn {...props} />;
      case 'EDIT_DATATABLE_MEASUREMENT_COLUMN':
      case 'EDIT_DATATABLE_OBSERVATION_COLUMN':
        return <EditDatatableMeasObsColumn {...props} />;
      case 'INFO':
        return <Info {...props} />;
      case 'DT_HIGH_VOLUME_UPSERT':
        return <HighVolumeUpsertModal {...props} />;
      case 'DTL_WORKFLOW_SETUP': {
        return (
          <DataTableWorkflowConfigure
            columns={props.columns}
            workflow={props.workflow}
            onWorkflowSave={props.onWorkflowSave}
            onWorkflowClear={props.onWorkflowClear}
            closeModal={props.closeModal}
            flags={props.flags}
          />
        );
      }
      case 'TASK_DETAILS': {
        return (
          <TaskDetails
            taskId={props.taskId}
            startingTab={props.startingTab}
            selectionDisabled={props.selectionDisabled}
            closeModal={props.closeModal}
            onTaskUpdate={props.onTaskUpdate}
            onTaskExecute={props.onTaskExecute}
          />
        );
      }
      case 'ANALYSIS_ISSUES': {
        return (
          <AnalysisIssuesModal studyApiId={props.studyApiId} onAnalysisIssuesUpdate={props.onAnalysisIssuesUpdate} />
        );
      }
      case 'SHIFT_TASK_DATES': {
        return <ShiftTaskDates {...props} />;
      }
      case 'DEVICE_CONNECTIONS': {
        return <DeviceConnections {...props} closeModal={props.closeModal} />;
      }
      case 'ADD_DEVICE': {
        return <AddDevice {...props} />;
      }
      case 'EDIT_DEVICE': {
        return <EditDevice {...props} />;
      }
      case 'DELETE_DEVICES': {
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure you would like to delete these devices?"
            text="The delete action can’t be undone and all the content of these devices will be permanently gone."
            btnTxt="Delete devices"
          />
        );
      }
      case 'DELETE_PRESETS': {
        return (
          <ConfirmDelete
            {...props}
            title="Are you sure?"
            text="The delete action can’t be undone. All content will be permanently gone."
            btnTxt="Delete presets"
          />
        );
      }
      case 'BULK_REASON_FOR_CHANGE': {
        return <BulkReasonForChange {...props} />;
      }
      case 'ATUNES_IMPORT_ANIMALS': {
        return <ImportAnimals {...props} />;
      }
      case 'WEIGHT_TITLE_EDIT': {
        return <WeightTitleEditModal name={props.name} onUpdate={props.onUpdate} onCancel={props.onCancel} />;
      }
      case 'CONFIRM_STUDY_EXECUTION_TIMEZONE_CHANGE': {
        return (
          <TimedDeleteConfirmation
            title="Are you sure?"
            text={`Changing the timezone will update ${(props.totalTaskCount ?? 0) > 0 ? props.totalTaskCount : 'all'} tasks in this study which may change the date on which they are displayed`}
            btnTxt="Update timezone"
            btnColor="dark-blue"
            onClick={props.onClick}
            onCancel={props.onCancel}
          />
        );
      }
      default:
        return <div />;
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (history.action === 'POP' || (history.action === 'PUSH' && open)) {
        dispatch({
          type: 'CLOSE_MODAL',
        });
      }
    };
  }, [history]);

  if (!open) {
    return null;
  }

  return (
    <ScrollManager scrollKey="modal-key">
      {({ connectScrollTarget, ...props }: ScrollManagerProps) => {
        return (
          <ReactModal
            ref={connectScrollTarget}
            isOpen={true}
            className="ui-modal"
            style={{
              overlay: { backgroundColor: 'rgba(00,00,00,.5)' },
              content: { maxHeight: '100vh' },
            }}
            ariaHideApp={false}
          >
            {renderContent()}
          </ReactModal>
        );
      }}
    </ScrollManager>
  );
};

export default ModalContainer;
