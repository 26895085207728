// @ts-nocheck: converted from JS

import React from 'react';
import Link from '@/components/UI/Link';

const Maintenance = (props) => {
  return (
    <React.Fragment>
      <div className="flex items-center w-100 h-100">
        <div className="ui-card pa3 pa4-l mv6 tc mw6 center">
          <h2 className="normal f3 f2-l black pb3">Hold tight.</h2>
          <p className="f5 f4-l pb3">We're currently performing some maintenance. Check back here in a few moments.</p>
          <Link onClick={props.history.goBack} className="button">
            Refresh page
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Maintenance;
