import { FC, MouseEvent } from 'react';
import styles from './TagBuilder.module.scss';

interface TagsListProps {
  tags: string[];
  onTagDelete: (index: number, e?: MouseEvent) => void;
}

interface TagProps {
  name: string;
  index: number;
  onDelete: (index: number, e: MouseEvent) => void;
}

export const TagsList: FC<TagsListProps> = ({ tags, onTagDelete }) => {
  const list = tags.map((tag, index) => <Tag key={index} name={tag} onDelete={onTagDelete} index={index} />);
  return <ul className={styles.tagsList}>{list}</ul>;
};

export const Tag: FC<TagProps> = ({ name, index, onDelete }) => {
  return (
    <li>
      {name}
      <a href="#" onClick={(e) => onDelete(index, e)}>
        x
      </a>
    </li>
  );
};
