// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Button from '@/components/UI/Button';
import { Text } from '@/components/UI/FormFields';
import { successToast } from '@/helpers';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const EditTreatment = ({ treatment, onEditTreatment }) => {
  const [loading, setLoading] = useState(false); // toggle when searching for treatment names
  const [apiError, setApiError] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      ...treatment,
    },
  });
  const { handleSubmit } = formMethods;
  const dispatch = useDispatch();

  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await Http.patch(apiRoute('team.glossary.treatment.update', { id: treatment.id }), data);
      onEditTreatment(data);
      closeModal();
      successToast('Treatment updated successfully');
    } catch (error) {
      setApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className={`ui-card center mw6 ${loading ? 'ui__disabled' : ''}`}>
        <div className="pa3">
          <h3 className="f4 lh-title normal">Edit treatment</h3>
        </div>
        {apiError && <ApiErrorBanner />}
        <div className="pa3">
          <Text label={'Name'} name={'name'} required />
        </div>
        <div className="pa3 mt3 bt b--moon-gray">
          <Button submit>Save</Button>
          <Button plain className="ml3" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditTreatment;
