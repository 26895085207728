import { textMetadataValidator } from '@/components/CreateTreatmentGroups/CreateTreatmentGroups.utils';
import { ColorEditor } from '@/components/UI/SpreadSheet/editors/ColorEditor';
import { DateEditorWrapper } from '@/components/UI/SpreadSheet/editors/DateEditorWrapper';
import { ExponentialEditor } from '@/components/UI/SpreadSheet/editors/ExponentialEditor';
import { MultiSelectEditor } from '@/components/UI/SpreadSheet/editors/MultiSelectEditor';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type {
  MetadataField,
  MetadataFieldTypes,
  MetadataFieldValueTypes,
  MetadataFieldWithValue,
} from '@/model/Metadata.model';
import { DateInputUtils } from '@/utils/Date.utils';
import {
  CheckboxCellType,
  DateCellType,
  DropdownCellType,
  NumericCellType,
  registerCellType,
} from 'handsontable/cellTypes';
import {
  AutoColumnSize,
  Autofill,
  ContextMenu,
  CopyPaste,
  DropdownMenu,
  Filters,
  HiddenColumns,
  HiddenRows,
  registerPlugin,
} from 'handsontable/plugins';
import type { ColumnSettings } from 'handsontable/settings';
import { formatNumberComingIn } from '@/helpers';
import { numericValidator } from '@/validators';
import { DateTimeEditor } from '@/components/UI/SpreadSheet/editors/DateTimeEditor';

export const registerHOTModules = () => {
  ColorEditor.register();
  MultiSelectEditor.register();
  ExponentialEditor.register();
  DateTimeEditor.register();
  DateEditorWrapper.register();
  registerCellType(NumericCellType);
  registerCellType(DropdownCellType);
  registerCellType(DateCellType);
  registerCellType(CheckboxCellType);
  registerPlugin(CopyPaste);
  registerPlugin(ContextMenu);
  registerPlugin(AutoColumnSize);
  registerPlugin(Filters);
  registerPlugin(Autofill);
  registerPlugin(DropdownMenu);
  registerPlugin(HiddenRows);
  registerPlugin(HiddenColumns);
};

export interface HoTMeta {
  value: MetadataFieldValueTypes;
  glossary_id: string;
}

export const formatColumnSettingsDataValue = (str: string) =>
  encodeURIComponent(str).replace(/[.]+/g, '%2E').toLowerCase();

export const defaultMetadataToColumnSettingMapper = (metadata: MetadataField) => {
  const data = `meta.${formatColumnSettingsDataValue(metadata.title)}`;
  const metaID = metadata.id;
  switch (metadata.field_type) {
    case 'select': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          metaID,
          data,
          type: MultiSelectEditor.type,
          isMultiSelect: false,
          options: metadata.options?.map((option) => unescape(option)),
          width: 150,
          validator: MultiSelectEditor.validatorFactory(metadata.options, false),
        },
      };
    }
    case 'multi_select': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          metaID,
          data,
          type: MultiSelectEditor.type,
          options: metadata.options,
          width: 150,
          validator: MultiSelectEditor.validatorFactory(metadata.options, true),
        },
      };
    }
    case 'text': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          metaID,
          data,
          type: 'text',
          metadataType: 'text',
          width: 150,
          validator: textMetadataValidator(),
        },
      };
    }
    case 'date': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          metaID,
          data,
          type: DateEditorWrapper.type,
          dateFormat: DateInputUtils.hotDatePattern,
          width: 150,
        },
      };
    }
    // Custom renderer for numeric metadata
    case 'numeric': {
      return {
        columnHeader: metadata.title,
        columnSettings: {
          metaID,
          data,
          type: ExponentialEditor.type,
          width: 150,
          validator: (value: string, callback: (value: boolean) => void) => callback(numericValidator(value)),
        },
      };
    }
    default: {
      throw new Error(`Unrecognised metadata field_type [${metadata.field_type}]`);
    }
  }
};

/**
 *
 * Sorts a metadata array to map of title key and metadata value type value
 *
 * @param metadata Array<Metadata>
 * @returns Record<string, MetadataFieldValueTypes>
 */
export const returnInitialMetaValues = (metadata: Array<MetadataFieldWithValue>) =>
  metadata.reduce<Record<string, MetadataFieldValueTypes>>((acc, meta) => {
    const title = formatColumnSettingsDataValue(meta.title);
    let val;
    if (Array.isArray(meta.value)) {
      val = meta.value.join(',');
    } else {
      val = meta.value;
    }
    acc[title] = val;
    return acc;
  }, {});

/**
 *
 * Sorts a metadata map [[glossaryId]: Metadata] to  Array<Metadata>
 *   @description
 *   <ul>
 *     <li>Additional metadata added will be placed at the end</li>
 *     <li>Metadata values will be sanitized (ie nil / empty values removed)</li>
 *   </ul>
 *
 * @param metadataGlossaryIdOrder Array[number]
 * @param metadataIdsToRetain Set[number]
 * @param metadata Record<string,Metadata>
 * @returns Array<Metadata>
 */
export const metadataMapToSortedMetadataArray = (
  metadataGlossaryIdOrder: Array<number>,
  metadataIdsToRetain: Set<ID>,
  metadata?: Record<string, MetadataFieldWithValue>
): Array<MetadataFieldWithValue> => {
  const md = { ...metadata };
  const metadataArray = metadataGlossaryIdOrder.reduce<Array<MetadataFieldWithValue>>((acc, glossaryId) => {
    if (_notNil(md?.[glossaryId])) {
      const metadataItem = md[glossaryId];
      delete md[glossaryId];
      return [...acc, metadataItem];
    }
    return acc;
  }, []);
  metadataArray.push(...Object.values(md)); // add unsorted metadata to the end
  return metadataArray.reduce<Array<MetadataFieldWithValue>>((acc, metadataItem) => {
    delete metadataItem['options'];
    if (
      metadataIdsToRetain.has(metadataItem.glossary_id) &&
      valueSet(sanitizeMetadataValue(metadataItem.value, metadataItem.field_type))
    ) {
      return [
        ...acc,
        {
          ...metadataItem,
          value: sanitizeMetadataValue(metadataItem.value, metadataItem.field_type),
        },
      ];
    }
    return acc;
  }, []);
};

/**
 *
 * Sorts a metadata map [[glossaryId]: Metadata] to  Array<Metadata>
 *   @description
 *   <ul>
 *     <li>Metadata values will be sanitized (ie nil / empty values removed)</li>
 *   </ul>
 *
 * @param metadata Record<number,Metadata>
 * @returns Array<Metadata>
 */
export const metadataMapToMetadataArray = (
  metadata: Record<string, MetadataFieldWithValue>
): Array<MetadataFieldWithValue> =>
  Object.values(metadata).reduce<Array<MetadataFieldWithValue>>((acc, metadataItem) => {
    if (valueSet(sanitizeMetadataValue(metadataItem.value, metadataItem.field_type))) {
      return [
        ...acc,
        {
          ...metadataItem,
          value: sanitizeMetadataValue(metadataItem.value, metadataItem.field_type),
        },
      ];
    }
    return acc;
  }, []);

export const sanitizeMetadataValue = (
  value: MetadataFieldValueTypes,
  type: MetadataFieldTypes
): MetadataFieldValueTypes => {
  if (Array.isArray(value)) {
    return value.filter(valueSet);
  }
  if (type === 'numeric' && _notNil(value)) {
    return !isNaN(formatNumberComingIn(value)) ? value : '';
  }

  return value;
};
export const valueSet = (value: MetadataFieldValueTypes): boolean => {
  return (
    _notNil(value) &&
    ((typeof value === 'string' && value.trim().length > 0) ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      (Array.isArray(value) && value.length > 0))
  );
};

export const createMeta = (meta: Record<string, MetadataFieldValueTypes>, columns: Array<ColumnSettings>) =>
  Object.entries(meta).reduce<Array<HoTMeta>>((acc, [title, value]) => {
    if (_notNil(title) && _isNotEmpty(value)) {
      let newValue: MetadataFieldValueTypes;
      const cellData = columns.find((c) => c.data === `meta.${title}`);
      if (_notNil(cellData)) {
        const { type, metaID, isMultiSelect } = cellData;
        if (type === 'multi-select' && typeof value === 'string' && isMultiSelect !== false) {
          newValue = (value as string).split(',');
        } else if (type === 'numeric-expo') {
          newValue = formatNumberComingIn(value);
        } else {
          newValue = String(value);
        }
        acc.push({
          value: newValue,
          glossary_id: metaID,
        });
      }
    }
    return acc;
  }, []);

export const createMetaMap = (meta: Record<string, MetadataFieldValueTypes>, columns: Array<ColumnSettings>) =>
  Object.entries(meta).reduce<Record<string, MetadataFieldValueTypes>>((acc, [title, value]) => {
    if (_notNil(title)) {
      let newValue: MetadataFieldValueTypes;
      const cellData = columns.find((c) => c.data === `meta.${title}`);
      if (_notNil(cellData)) {
        const { type, metaID, isMultiSelect } = cellData;
        if (_notNil(value) && type === 'multi-select' && typeof value === 'string' && isMultiSelect !== false) {
          newValue = _isEmpty(value) ? null : value.split(',');
        } else if (type === 'numeric-expo' && _notNil(value)) {
          newValue = formatNumberComingIn(value);
        } else {
          newValue = value;
        }
        acc[metaID] = newValue;
      }
    }
    return acc;
  }, {});
