import { ModalContainer } from '@/utils/modal.tsx';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import styles from './NetworkStatusConnector.module.scss';

export const NetworkStatusDetector: FC = () => {
  const [online, updateOnline] = useState(window?.navigator?.onLine ?? true);

  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener('online', () => updateOnline(() => true), {
      passive: true,
      signal: abortController.signal,
    });
    window.addEventListener('offline', () => updateOnline(() => false), {
      passive: true,
      signal: abortController.signal,
    });
    return () => {
      abortController.abort();
    };
  }, []);
  if (online) {
    return null;
  }
  return (
    <div className={cn('flex flex-row justify-around', styles.background)}>
      <div className="flex flex-column justify-center">
        <ModalContainer size="medium">
          <div className="flex flex-column bg-blue white pa4">
            <div className="flex flex-row justify-around f2">Offline</div>
            <div className="flex flex-row justify-around mv4">
              <span className={styles.loader}></span>
            </div>
            <div className="flex flex-row justify-around white">Please check your network connection</div>
          </div>
        </ModalContainer>
      </div>
    </div>
  );
};
