// @ts-nocheck: converted from JS

import DatePickerNative from '@/components/UI/DatePickerNative';
import { FormField } from '@/components/UI/FormFields';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

interface DatepickerProps {
  value?: any;
  label?: any;
  name?: any;
  disabled?: any;
  hint?: any;
  required?: any;
}

const Datepicker: FC<DatepickerProps> = ({ value, label, name, disabled = false, hint, required }) => {
  const { control } = useFormContext();
  return (
    <FormField name={name} label={label} hint={hint} required={required}>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={getRegisterConfig({ required })}
        render={({ onChange, value }: ControllerRenderProps) => (
          <DatePickerNative type="date" value={value} onChange={onChange} />
        )}
      />
    </FormField>
  );
};

export default Datepicker;
