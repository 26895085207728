import { CSSProperties, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import './WithAside.scss';

interface WithAsideProps {
  children: ReactNode;
  render: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const DEFAULT_HEIGHT = 40;

const WithAside: FC<WithAsideProps> = ({ children, render, style, className = '' }) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className={`WithAside--container ${className}`}
      ref={ref}
      style={{ height: ref?.current?.clientHeight ?? DEFAULT_HEIGHT, ...style }}
    >
      {children}
      <span className="WithAisde--aside-container">{render}</span>
    </div>
  );
};

export default WithAside;
