import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import UserAvatar from '@/components/UI/UserAvatar';
import { _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { State } from '@/model/State.model';
import type { StudyUser } from '@/model/User.model';
import { web as webRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const buildColumns = (studyId: ID): Array<Column<{ created_at: string; user: StudyUser; id: string }>> => [
  {
    id: 'created_at',
    Header: 'Date of Randomization',
    accessor: 'created_at',
    isVisible: true,
    Cell: ({
      row: {
        original: { id, created_at: createdAt },
      },
    }) => (
      <Link
        to={webRoute('randomization.report', {
          id: studyId,
          reportApiId: id,
        })}
        className="link blue"
      >
        <DateTimeRenderer value={createdAt} defaultResponse="-" />
      </Link>
    ),
  },
  {
    id: 'user',
    Header: 'User',
    accessor: 'user',
    Cell: ({ row: { original } }) => (
      <UserAvatar user={original?.user} tooltip={original?.user?.name ?? original?.user?.email} />
    ),
    isVisible: true,
    sortDisabled: true,
  },
  {
    id: 'preexclusion',
    Header: 'Animals Selected',
    accessor: 'animals_selected',
    isVisible: true,
    sortDisabled: true,
  },
  {
    id: 'postexclusion',
    Header: 'Animals Randomized',
    accessor: 'animals_randomized',
    isVisible: true,
    sortDisabled: true,
  },
];

interface ReportsProps {
  studyApiId: ID;
  studyId: ID;
}

const Reports: React.FC<ReportsProps> = ({ studyApiId, studyId }) => {
  const settings = useSelector((state: State) => state.ui.settings);
  const columns = buildColumns(studyId);

  const updatedSettings = useMemo(() => {
    if (_isNotEmpty(columns)) {
      return updateSettings('randomization', columns, settings);
    }
    return settings;
  }, [columns]);

  return (
    <>
      <APITable
        apiInfo={{
          type: 'internalApi',
          route: RouteService.api({
            endpoint: 'GET /api/v1/studies/{studyId}/randomizations',
            path: { studyId: studyApiId.toString() },
          }).url,
        }}
        columns={columns}
        hideSearch={true}
        hideFilter={true}
        settings={updatedSettings}
        testIdPrefix="reports"
        exportRoutes={[
          {
            label: 'Export study randomization',
            url: 'studies.randomization.export',
            params: { studyApiId },
            noFilters: true,
          },
        ]}
        reduxTableName={'randomization'}
        AsideComponent={
          <div className="flex w-100 justify-start mt2">
            <p data-testid="reports-animals-desc">
              Click the Date of Randomization to view report. To randomize animals into groups use Bulk actions in{' '}
              {settings?.studyState?.pageStates?.animals ? (
                'Animals'
              ) : (
                <Link
                  data-testid="reports-animals-link"
                  className="link blue"
                  to={webRoute('studies.animals', { id: studyId })}
                >
                  Animals
                </Link>
              )}
            </p>
          </div>
        }
      />
    </>
  );
};
export default Reports;
