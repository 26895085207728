import Link from '@/components/UI/Link';
import { _notNil, _isNil } from '@/littledash';
import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import type { StudyFormMetadataItem } from '@/model/StudyForm.model';
import { ErrorMessage } from '@hookform/error-message';
import { type FC, useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useFormContext } from 'react-hook-form';
import type { CreateStudyAction, CreateStudyState } from '../../Create.model';
import { BenchlingFolderLookup, FolderOptionType } from './BenchlingFolderLookup';

interface BenchlingIntegrationProps {
  state: CreateStudyState;
  dispatch: (value: CreateStudyAction) => void;
  metaglossary: StudyFormMetadataItem;
  studyID?: `${number}` | null;
}

const BenchlingIntegration: FC<BenchlingIntegrationProps> = ({ state, dispatch, metaglossary, studyID = null }) => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  const metaIndex = state?.study?.metadata?.findIndex(({ glossary_id }) => glossary_id === metaglossary.info.id) ?? -1;
  const initialFolder = getValues(`metadata[${metaIndex}]`);

  const updateStudy = (folder: FolderOptionType, metaGlossaryItem: MetadataFieldWithValue) => {
    const updatedFormMetadata = [...state.study.metadata];
    let newMetaValue;
    if (_notNil(initialFolder?.value)) {
      newMetaValue = { ...initialFolder, value: folder.value?.id ?? '' };
      updatedFormMetadata[metaIndex] = newMetaValue;
      setValue(`metadata[${metaIndex}]`, newMetaValue);
    } else {
      newMetaValue = { ...metaGlossaryItem, value: folder.value?.id ?? '', glossary_id: metaGlossaryItem.id };
      const newIndex = updatedFormMetadata.length;
      updatedFormMetadata[newIndex] = newMetaValue;
      setValue(`metadata[${newIndex}]`, newMetaValue);
    }

    if (_isNil(studyID)) {
      dispatch({
        type: 'updateStudy',
        data: { ...state.study, benchling_folder: folder, metadata: updatedFormMetadata },
      });
    }
  };

  const folderLabel = _notNil(studyID) ? (
    <>
      <label className="mb3" htmlFor="benchling_folder">
        Current Folder ID: {initialFolder?.value}
      </label>
      <label htmlFor="benchling_folder">Update Folder ID</label>
    </>
  ) : (
    <label htmlFor="benchling_folder">Folder ID</label>
  );

  return (
    <>
      <div className="mb4">
        <h3 className="fw5 f5 pb2">Link a Benchling folder</h3>
        <p className="f6 lh-copy ma0">
          Specify the location in Benchling where study data will be contained.{' '}
          <Link
            to="https://help.benchling.com/hc/en-us/articles/20110963758221-Linking-a-Benchling-Folder"
            openTab
            className="blue link"
          >
            Read more
          </Link>
        </p>
      </div>
      <div className="ui-card mw7" style={{ minHeight: '150px' }}>
        <div className="pa4">
          <div>
            <div className="mb3">
              {folderLabel}
              <BenchlingFolderLookup updateStudy={updateStudy} metaGlossary={metaglossary} />
              <ErrorMessage
                errors={errors}
                name="benchling_folder"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BenchlingIntegration;
