// @ts-nocheck: converted from JS

const ResumeStudy = ({ closeModal, onClick }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <form onSubmit={handleSubmit} className="ui__narrow-modal">
      <div className="pb3 tc">
        <h3 className="normal f4 pb2">Resume study</h3>
        <p className="f6 pb2">
          Resume the study from where it was left off. All invited participants will be notified by email.
        </p>
      </div>
      <div>
        <button type="submit" className="button w-100">
          Resume study
        </button>
        <a className="button plain db w-100 mt2" onClick={closeModal}>
          Cancel
        </a>
      </div>
    </form>
  );
};

export default ResumeStudy;
