// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SpreadSheet from '@/components/UI/SpreadSheet';
import { spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import SubHeader from '@/components/UI/SubHeader';
import { clearSessionStorage, successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import type { Cage } from '@/model/Cage.model';
import { createMeta } from '@/support/hot';
import Http from '@/support/http';
import { api as apiRoutes, web as webRoute } from '@/support/route';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getMetaColumns, getMetaData, initCageSettings, initialBulkCageData } from '../Colony.utils';
import './../Colony.scss';

type CageExtension = Omit<Cage, 'created_at'>;

const BulkCageEdit = () => {
  const history = useHistory();
  const editCageRef = useRef();

  const [loading, setLoading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState(false);
  const [metaColumns, setMetaColumns] = useState(false);
  const [data, setData] = useState([]);
  const [settings, setSettings] = useState([]);
  const [apiError, setApiError] = useState<Error | false>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const selectedCages = JSON.parse(sessionStorage.getItem('selectedColonyCages') ?? '[]');

  useEffect(() => {
    return clearSessionStorage(history, 'selectedColonyCages', '/colony/edit/cages');
  }, [history]);

  const fetchMetadata = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await getMetaData('collection_meta');
      setMetadata(data);
    } catch (error) {
      setApiError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
    setData(initialBulkCageData(selectedCages));
  }, []);

  useEffect(() => {
    if (!metaColumns && !_isEmpty(data)) {
      if (!_isEmpty(metadata)) {
        setMetaColumns(getMetaColumns(selectedCages, metadata));
      } else {
        setMetaColumns([]);
      }
    }
  }, [metadata]);

  useEffect(() => {
    if (Array.isArray(metaColumns) && _isEmpty(settings)) {
      const updateSettings = initCageSettings(true, false, metaColumns, metadata);
      if (updateSettings.settings) {
        setSettings(updateSettings.settings);
      }
      if (updateSettings.metadata) {
        setMetadata(updateSettings.metadata);
      }
    }
  }, [metaColumns]);

  const putCages = async (cages: Array<CageExtension>) => {
    if (cages) {
      return Http.put(apiRoutes('cages.updateMany'), {
        cages,
        query: 'meta',
      });
    }
  };

  const bulkCagesSave = async () => {
    const cages: Array<CageExtension> = data.map(({ id, name, population, meta }) => ({
      name,
      id,
      animal_count: population,
      meta: _isEmpty(meta) ? [] : createMeta(meta, settings.columns),
    }));
    try {
      await putCages(cages);
      history.push('/colony');
      successToast(`Successfully edited ${cages.length > 1 ? 'cages' : 'cage'}`);
    } catch (error) {
      setApiError(error as Error);
    }
  };

  const spreadSheet = _isNotEmpty(settings) && (
    <>
      <div className="ow-spreadsheet-styles">
        <SpreadSheet
          data={data}
          settings={settings}
          setSettings={setSettings}
          height="300"
          className="pl4 pr4"
          innerRef={editCageRef}
          metadata={metadata}
          setMetadata={setMetadata}
          maxRows={data.length}
          setInvalid={setIsInvalid}
        />
      </div>
      <div className="pl4 pt4">
        <Button
          onClick={bulkCagesSave}
          disabled={loading || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
        >
          Save
        </Button>
        <Button plain className="ml3" onClick={() => history.push('/colony')}>
          Cancel
        </Button>
      </div>
    </>
  );

  return (
    <div className="h-100">
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={'There was an error with your request'}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          <SubHeader linkToText="Colony" link={webRoute('colony')} />
          <div className="ph4">
            <Header mainHeaderText="Edit cages" />
          </div>
          {_isNotEmpty(selectedCages) ? (
            spreadSheet
          ) : (
            <div className="ma4">
              <NoDataCard
                title="You have not selected any cages"
                text={'Please select cages from inside Colony to proceed'}
                NoDataComponent={
                  <Link to={webRoute('colony')} className="link blue">
                    Go to Colony
                  </Link>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BulkCageEdit;
