import type { GraphPanel } from '@/components/Studies/Groups/Graph.model';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import Icon from '@/components/UI/Icon';
import { StatelessTab } from '@/components/UI/Tabs';
import { classNames } from '@/helpers';
import type { State } from '@/model/State.model';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

interface IGroupsTabs {
  active: GraphPanel;
  onChange: (data: GraphPanel) => void;
  hasAnalysis?: boolean;
}

const dropDownPanels = /^(grouped|daily|spider|survival)$/;

const GraphsMenu: FC<Pick<IGroupsTabs, 'active'>> = ({ active }) => {
  switch (active) {
    case 'grouped':
      return 'Grouped';
    case 'spider':
      return 'Spider';
    case 'daily':
      return 'Daily';
    case 'survival':
      return 'Survival';
    default:
      return 'Graphs';
  }
};

export const GroupsTabs: FC<IGroupsTabs> = ({ active, onChange, hasAnalysis = false }) => {
  const dataAnalysisEnabled = useSelector<State>((state) => state.team.features?.data_analysis ?? false);
  if (dataAnalysisEnabled && hasAnalysis) {
    return (
      <>
        <StatelessTab testId="graphs-dropdown-tab" isActive={dropDownPanels.test(active)}>
          <Dropdown
            renderMenu={() => (
              <>
                <DropdownMenuItem onClick={() => onChange('grouped')}>
                  <div className={classNames('', { 'is-active': active === 'grouped' })}>Grouped</div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onChange('daily')}>
                  <div className={classNames('', { 'is-active': active === 'daily' })}>Daily</div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onChange('spider')}>
                  <div className={classNames('', { 'is-active': active === 'spider' })}>Spider</div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onChange('survival')}>
                  <div className={classNames('', { 'is-active': active === 'survival' })}>Survival Curve</div>
                </DropdownMenuItem>
              </>
            )}
          >
            <span
              data-test-component="GroupsTabs"
              data-test-element="graph-dropdown"
              className="flex justify-between"
              style={{ minWidth: '85px' }}
            >
              <span>
                <GraphsMenu active={active} />
              </span>
              <span className="pl2">
                <Icon icon="down_triangle" width="8" height="8" className="right-1" />
              </span>
            </span>
          </Dropdown>
        </StatelessTab>
        <StatelessTab testId="analysis-tab" isActive={active === 'analysis'} onClick={() => onChange('analysis')}>
          Analysis
        </StatelessTab>
      </>
    );
  }
  return (
    <>
      <StatelessTab isActive={active === 'grouped'} onClick={() => onChange('grouped')}>
        Grouped
      </StatelessTab>
      <StatelessTab isActive={active === 'daily'} onClick={() => onChange('daily')}>
        Daily
      </StatelessTab>
      <StatelessTab isActive={active === 'spider'} onClick={() => onChange('spider')}>
        Spider
      </StatelessTab>
      <StatelessTab isActive={active === 'survival'} onClick={() => onChange('survival')}>
        Survival Curve
      </StatelessTab>
    </>
  );
};
