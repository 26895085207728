// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import MeasurementsSidebar from '@/components/Subjects/Show/Measurements/MeasurementsSidebar';
import LatestMeasurementsTable from '@/components/Subjects/Show/Measurements/Table';
import Overview from '@/components/Subjects/Show/Overview';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { isDead } from '@/constants/utils';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoute } from '@/support/route';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Profile = () => {
  const { subjectId } = useParams();
  const {
    entity: animal,
    entityUpdating: animalUpdating,
    entityLoading: animalLoading,
    fetchEntity: fetchAnimal,
  } = useFetchEntity({
    entityType: 'animal',
    params: { id: subjectId },
    includes: 'cage,metadata,study,animal_selection',
  });

  useEffect(() => {
    if (animal) {
      fetchAnimal();
    }
  }, [subjectId]);

  if (animalLoading || animalUpdating) {
    return <Loading />;
  }

  return (
    <div style={{ paddingRight: 400 }}>
      <SubHeader
        linkToText="Colony"
        link={webRoute('colony')}
        asideComponent={() =>
          animal?.animal_selection && (
            <div className="flex lh-title">
              <Link
                disabled={!animal.animal_selection.previous}
                to={webRoute('colony.animal.profile', {
                  subjectId: animal.animal_selection.previous,
                })}
                className="blue mr3"
              >
                Previous
              </Link>
              <Link
                disabled={!animal.animal_selection.next}
                to={webRoute('colony.animal.profile', {
                  subjectId: animal.animal_selection.next,
                })}
                className="blue"
              >
                Next
              </Link>
            </div>
          )
        }
      />
      <div className="ph4 pv3 bg-white">
        <Header subHeaderText={isDead(animal)} mainHeaderText={animal.name} />
      </div>
      <Tabs>
        <div className="bb b--moon-gray ph4 bg-white">
          <Tab>Overview</Tab>
        </div>
        <Panel className="pa4">
          <Overview animal={animal} readOnly />
          <div className="mt3">
            <LatestMeasurementsTable animal={animal} study={animal.study} />
          </div>
        </Panel>
      </Tabs>
      <MeasurementsSidebar study={animal.study} animal={animal} readOnly />
    </div>
  );
};

export default Profile;
