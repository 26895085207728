import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import StudyUsers from '@/components/Studies/Show/Users';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Column, FilterOption } from '@/components/UI/Table/TableComponent.model';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { Study } from '@/model/Study.model';
import { statusToTextMap } from '@/referenceData/study/status';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import { api as apiRoutes, web as webRoutes } from '@/support/route';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { StudyTableProps } from './Dashboard.model';
import {
  generateColumns,
  generateStudiesMetadataColumns,
  studiesfilterOptions,
  updateColumns,
} from './Dashboard.utils';
import { useFetchCollection } from '@/support/Hooks/fetch';
import type { StudyForm } from 'model/StudyForm.model';
import Truncate from '@/components/UI/Truncate/Truncate';

const initColumns: Array<Column<Study>> = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    sortBy: 'name',
    isVisible: true,
    Cell: ({
      row: {
        original: { name, id },
      },
    }) => (
      <Link
        className="link w-100"
        to={webRoutes('studies.show', {
          id,
        })}
      >
        <Truncate tooltip={name}>{name}</Truncate>
      </Link>
    ),
  },
  {
    id: 'code',
    Header: 'Study Code',
    accessor: 'code',
    sortBy: 'code',
    isVisible: false,
  },
  {
    id: 'type',
    Header: 'Type',
    isVisible: true,
    sortBy: 'type',
    accessor: 'settings.title',
  },
  {
    id: 'team',
    Header: 'Team',
    isVisible: true,
    sortBy: 'users',
    sortDisabled: true,
    accessor: 'users',
    Cell: ({ row: { original } }) => <StudyUsers study={original} />,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    isVisible: true,
    sortBy: 'status',
    Cell: ({ row: { original } }) => statusToTextMap[original.status] || '',
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    sortBy: 'created_at',
    isVisible: true,
    Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
  },
];

const ActiveTable: React.FC<StudyTableProps> = ({ studyTypes, studyMetadataFilters }) => {
  const [filterOptions, setFilterOptions] = useState<Array<FilterOption>>([]);
  const [columns, setColumns] = useState<Array<Column<Study>>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [apiError, setAPIError] = useState<boolean>(false);
  const [studyIDVisible, setStudyIDVisible] = useState<boolean>(false);
  const history = useHistory();

  const { collection: studyForms, collectionLoading: studyFormsFetching } = useFetchCollection<StudyForm>({
    collectionType: 'teamStudiesForms',
    queryParams: { perPage: -1 },
  });

  useEffect(() => {
    if (_isNotEmpty(studyTypes)) {
      setFilterOptions(studiesfilterOptions(studyTypes, studyMetadataFilters));
    }
  }, [studyTypes, studyMetadataFilters]);

  useEffect(() => {
    if (_notNil(studyMetadataFilters) && !studyFormsFetching) {
      generateStudiesMetadataColumns(studyMetadataFilters)
        .then((metadata) => {
          setColumns(
            generateColumns(
              initColumns,
              metadata.map((m) => ({ id: m.id, header: m.header ?? '' })),
              history,
              studyForms
            )
          );
          setLoading(false);
        })
        .catch((error) => {
          if (notAborted(error)) {
            setLoading(false);
            setAPIError(error);
          }
        });
    }
  }, [studyMetadataFilters, studyFormsFetching]);

  if (loading) {
    return null;
  }

  return (
    <>
      {apiError ? (
        <ApiErrorBanner className="mb4" errorType={ApiErrorType.FETCH} error={apiError} />
      ) : (
        <div className="pa4">
          <APITable
            columns={columns}
            apiInfo={{ type: 'legacyInternalApi', route: apiRoutes('studies.active') }}
            filterOptions={filterOptions}
            onFetchTableData={(studies) => {
              if (!studyIDVisible) {
                const updatedColumns = updateColumns(studies, columns);
                if (_isNotEmpty(updatedColumns)) {
                  setStudyIDVisible(true);
                  setColumns(updatedColumns);
                }
              }
            }}
            searchPlaceholderText="Search by study name or study code"
          />
        </div>
      )}
    </>
  );
};

export default ActiveTable;
