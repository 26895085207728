// @ts-nocheck: converted from JS

import Checkbox from '@/components/UI/FormElements/Checkbox';
import Icon from '@/components/UI/Icon';
import { _isEmpty } from '@/littledash';
import update from 'immutability-helper';
import { useCallback, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, useFormContext } from 'react-hook-form';

export const DragDrawerChildContainer = ({ items, checks, setWorkflowState, parent, fieldIndex }) => {
  const [list, setList] = useState(items);
  const moveChild = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = list[dragIndex];
      const updatedList = update(list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      setList(updatedList);
      checks.find((c) => c.name === 'measurements').children = updatedList;
      setWorkflowState(checks);
    },
    [list]
  );

  return (
    <>
      {!_isEmpty(list) && (
        <div className="pb3">
          {list.map((child, index) => (
            <DragDrawerChild
              id={child.id}
              key={child.id}
              fieldIndex={fieldIndex}
              index={index}
              child={child}
              parent={parent}
              moveChild={moveChild}
              canReorder={true}
            />
          ))}
        </div>
      )}
    </>
  );
};

export const DragDrawerChild = ({ id, fieldIndex, index, moveChild, child }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'drawer',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveChild(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'drawer', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const { control } = useFormContext();
  const basePath = `workflowSetup.workflow[${fieldIndex}].children[${index}]`;
  const fieldName = `${basePath}.value`;

  return (
    <div ref={ref} style={{ opacity }} className="flex justify-between flex-wrap items-center pv2 ph3">
      <Controller
        defaultValue={child.value}
        name={fieldName}
        control={control}
        render={({ value, onChange }) => {
          return (
            <Checkbox
              type="checkbox"
              name={fieldName}
              label={child.displayName}
              checked={value}
              className="f5"
              onChange={() => onChange(!value)}
            />
          );
        }}
      />
      <Icon icon="drag" className="dib mid-gray grab" width="20" height="20" />
    </div>
  );
};
