import GroupLabel from '@/components/UI/GroupLabel';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { calculateAge, capitalise, formatNumber } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _isNumber, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { TbInfoCircle } from 'react-icons/tb';
import type { RandomAnimal, RandomTreatmentGroup } from '../Steps/Randomize/Randomize.model';
import type { OneWayAnovaResults } from '../Steps/Randomize/Statistics.model';
import { RandomizationAttributes } from './Report.model';

export type CriteriaOptions = Record<string, { name: string; value: string }>;

export const generateAnimalColumns = (
  animals: Array<RandomAnimal>,
  studyGroups: Array<RandomTreatmentGroup>,
  studyId: ID,
  studyApiId: string,
  criteriaOptions: CriteriaOptions
): Array<Column<RandomAnimal>> => {
  const measurementColumns = Object.keys(animals?.[0].measurement_info ?? {}).map<Column<RandomAnimal>>((key) => ({
    id: key,
    Header: criteriaOptions[key].name,
    accessor: `measurement_info.${key}`,
    isVisible: true,
    Cell: ({ row: { original } }) =>
      _notNil(original.measurement_info[key]) ? formatNumber(original.measurement_info[key]) : '-',
  }));

  const altIdColumns = animals.reduce<Record<string, Column<RandomAnimal>>>((acc, animal, idx, array) => {
    if (
      Object.prototype.hasOwnProperty.call(acc, 'donor') &&
      Object.prototype.hasOwnProperty.call(acc, 'ear') &&
      Object.prototype.hasOwnProperty.call(acc, 'tag') &&
      Object.prototype.hasOwnProperty.call(acc, 'tail')
    ) {
      return acc;
    }
    return (['donor', 'ear', 'tag', 'tail'] as Array<keyof RandomAnimal>).reduce((innerAcc, key) => {
      if (!Object.prototype.hasOwnProperty.call(innerAcc, key) && _isNotEmpty(animal[key])) {
        innerAcc[key] = {
          id: `alt_id.${key}`,
          accessor: key,
          Header: key.charAt(0).toUpperCase() + key.slice(1),
          isVisible: true,
        };
      }
      return innerAcc;
    }, acc);
  }, {});
  return [
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      isVisible: true,
    },
    ...Object.values(altIdColumns),
    {
      id: 'cage',
      Header: 'Cage at randomization',
      accessor: 'cage_name',
      isVisible: true,
    },
    {
      id: 'sex',
      Header: 'Sex',
      accessor: 'sex',
      isVisible: true,
      Cell: ({
        row: {
          original: { sex },
        },
      }) => capitalise(sex),
    },
    {
      id: 'dob',
      Header: 'D.O.B',
      accessor: 'dob',
      isVisible: true,
      Cell: ({
        row: {
          original: { dob },
        },
      }) => (_notNil(dob) ? calculateAge(dob) : '-'),
    },
    {
      id: 'study_group',
      Header: 'Group Assigned',
      accessor: 'study_group_id',
      isVisible: true,
      width: 200,
      Cell: ({
        row: {
          original: { group },
        },
      }) => (_notNil(group) ? <GroupLabel group={group as unknown as TreatmentGroup} /> : 'Excluded'),
    },
    ...measurementColumns,
  ];
};

const measurementTypes: Record<string, string> = {
  mean: 'Mean',
  sem: 'SEM',
  sd: 'STD DEV',
  median: 'Median',
  mad: 'MAD',
};

const attrTypes: Record<RandomizationAttributes, string> = {
  [RandomizationAttributes.sex]: 'Sex',
  [RandomizationAttributes.donor_id]: 'Donor ID',
  [RandomizationAttributes.dob]: 'Date of birth',
};

export const resultColumns = (
  studyGroups: Array<RandomTreatmentGroup>,
  criteriaOptions: CriteriaOptions,
  errorDeviation: string,
  oneWayAnova: Record<string, OneWayAnovaResults>,
  {
    openModal,
    closeModal,
  }: {
    openModal: (modal: any, props: any) => void;
    closeModal: () => void;
  }
): Array<Column<RandomTreatmentGroup>> => {
  const measurementColumns: Array<Column<RandomTreatmentGroup>> = Object.entries(studyGroups[0].measurements).map(
    ([measure, values]) => {
      const columnName = `${studyGroups[0].name.replace(' ', '')}.${measure}`;
      const pValue = oneWayAnova?.[criteriaOptions[measure].value]?.pvalue;
      return {
        id: columnName,
        Header: (
          <div>
            <div>{criteriaOptions[measure].name}</div>
            {_isNotEmpty(oneWayAnova) && (
              <div className="flex flex-row dark-gray normal f6 pointer">
                {_notNil(pValue) && _isNumber(pValue) ? `P = ${pValue?.toFixed(4)}` : 'No P Value'}
                {
                  <a
                    className="flex items-center ml1"
                    onClick={() => {
                      openModal('ONEWAYANOVA_TABLE', {
                        oneWayAnova: oneWayAnova?.[criteriaOptions[measure].value],
                        closeModal: closeModal,
                      });
                    }}
                  >
                    <TbInfoCircle />
                  </a>
                }
              </div>
            )}
          </div>
        ),
        columns: Object.keys(values).map<Column<RandomTreatmentGroup>>((key) => ({
          id: `${columnName}.${key}`,
          Header:
            key === 'mean' || key === 'median'
              ? measurementTypes[key]
              : measurementTypes[key === 'mad' ? 'mad' : errorDeviation],
          accessor: `measurements.${measure}.${key}`,
          isVisible: true,
          Cell: ({ row: { original } }) => formatNumber(original.measurements[measure][key]),
        })),
      };
    }
  );

  const subjectsColumns = (): Array<Column<RandomTreatmentGroup>> => {
    const subjectType: string | null = Object.keys(studyGroups?.[0]?.subjects ?? {})?.[0] ?? null;

    if (studyGroups.every(({ subjects }) => _isEmpty(subjects)) || _isNil(subjectType)) {
      return [];
    } else {
      const attributes = studyGroups.flatMap((group) => {
        return Object.keys(group.subjects?.[subjectType]);
      });
      return [
        {
          id: `subjects.${subjectType}`,
          Header: attrTypes[subjectType as RandomizationAttributes],
          columns: Array.from(new Set(attributes)).map((key) => ({
            id: `subjects.${subjectType}.${key}`,
            Header: String(
              subjectType === RandomizationAttributes.dob
                ? `${key} (${calculateAge(key, new Date().toISOString(), true)})`
                : capitalise(key)
            ),
            accessor: `subjects.${subjectType}.${key}`,
            isVisible: true,
          })),
        },
      ];
    }
  };
  return [
    {
      id: 'study_group',
      Header: 'Group',
      accessor: 'id',
      isVisible: true,
      width: 200,
      Cell: ({ row: { original } }) => <GroupLabel group={original as unknown as TreatmentGroup} />,
    },
    {
      id: 'total_animals',
      Header: 'Total animals',
      accessor: 'total_animals',
      isVisible: true,
    },
    ...subjectsColumns(),
    ...measurementColumns,
  ];
};
