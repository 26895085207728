// @ts-nocheck: converted from JS

import SelectBuilder from '@/components/FormBuilder/SelectBuilder/SelectBuilder';
import { groupTitleValue } from '@/components/FormBuilder/Item.utils';

export const getFieldComponent = (fieldType) => {
  const componentTypeMap = {
    select: SelectBuilder,
  };
  return componentTypeMap[fieldType] || 'div';
};

export const nonFormFieldTypes = [groupTitleValue];

export const ErrorTypes = {
  formFieldRequired: 'formFieldRequired', // at least one form field in a formItems array
};
