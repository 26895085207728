import Label from '@/components/UI/Label';
import type { FC } from 'react';
import type { LabelProps } from '../Label/Label';

interface HeaderProps {
  mainHeaderText?: string;
  subHeaderText?: string;
  asideComponent?: () => JSX.Element;
  label?: LabelProps;
  testId?: string;
}

const Header: FC<HeaderProps> = ({
  mainHeaderText,
  subHeaderText,
  asideComponent,
  label,
  testId = 'header-container',
}) => (
  <div
    className="pt3 flex justify-between flex-wrap"
    data-test-component="Header"
    data-test-element="container"
    data-testid={testId}
  >
    <div>
      {label && <Label text={label.text} tooltip={label.tooltip} type={label.type} className="mb2" />}
      {subHeaderText && (
        <h2 className="f5 dark-gray lh-title basier-reg normal pb1" data-test-element="sub-head">
          {subHeaderText}
        </h2>
      )}
      <h1 className="f3 normal pb2" data-test-element="head">
        {mainHeaderText}
      </h1>
    </div>
    {asideComponent && <div>{asideComponent()}</div>}
  </div>
);

export default Header;
