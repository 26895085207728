// @ts-nocheck: converted from JS

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import Link from '@/components/UI/Link';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _notNil } from '@/littledash';
import { useEffect } from 'react';
import type { CreateStudyState } from '../../Create.model';

interface SelectPresetProps {
  state: CreateStudyState;
  dispatch: () => void;
}

const SelectPreset = ({ state, dispatch }: SelectPresetProps) => {
  if (_notNil(state.study.preset_id)) {
    const presetId = state.study.preset_id;
    const selectedPreset = state.form.presets.find((preset) => preset.id === presetId);

    state.form.selectedPreset = selectedPreset;
  }

  const {
    form: { selectedPreset, presets },
  } = state;
  let initialIndex = 0;

  if (selectedPreset) {
    initialIndex = presets.findIndex(({ id }) => Number(id) === Number(selectedPreset.id)) + 1;
  }

  const handleClick = (selected = null) => {
    // Filter out alerts that don't exist in the new preset
    if (_notNil(selected) && selected.id !== selectedPreset?.id) {
      const calculationAndMeasurementIds = (selected.calculations ?? []).reduce((acc, calculation) => {
        acc.push(calculation.id);
        (calculation.measurements ?? []).forEach((measurement) => {
          acc.push(measurement.id);
        });
        return acc;
      }, []);

      const alerts = (state.study.alerts ?? []).filter((alert) =>
        calculationAndMeasurementIds.includes(alert.calculation)
      );

      state.study.alerts = alerts;
    }

    dispatch({
      type: 'setPreset',
      data: selected,
    });
  };

  const tabClasses = 'flex flex-wrap items-center f6 lh-title pa3 w-100 pointer';
  const activeClasses = 'bg-near-white black br2';

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  return (
    <>
      <div className="mb4" data-testid="select-preset__container">
        <h3 className="fw5 f5 pb2">Select a preset (optional)</h3>
        <p className="f6 lh-copy ma0">
          Presets are collections of measurements that are typically performed no more than once per day.{' '}
          <Link to="https://help.benchling.com/hc/en-us/articles/22038856066317" openTab className="blue link">
            How does this work?
          </Link>
        </p>
      </div>
      <div className="ui-card overflow-hidden">
        <Tabs state={initialIndex}>
          <div className="flex" style={{ height: 500 }}>
            <div className="w-50 pa3 overflow-scroll h-100 br b--moon-gray">
              <Tab plain activeClass={activeClasses}>
                <div className={`db ${tabClasses}`} onClick={() => handleClick(null)}>
                  No preset
                </div>
              </Tab>
              {presets.map((preset) => (
                <Tab key={preset.id} activeClass={activeClasses} plain>
                  <div
                    className={`${tabClasses}`}
                    onClick={() => handleClick(preset)}
                    data-test-key={preset.id}
                    data-testid="preset-item"
                  >
                    {preset.title}
                  </div>
                </Tab>
              ))}
            </div>
            <div className="w-50 overflow-scroll h-100">
              <Panel>
                <div style={{ height: 500 }} className="pa3">
                  <div className="flex flex-wrap justify-center items-center br2 bg-near-white h-100 w-100 tc">
                    <div>
                      <h3 className="normal lh-title f4 near-black">Empty preset</h3>
                      <p className="lh-copy f5 center mv2 measure-narrow">
                        If you aren&apos;t collecting daily measurements, skip this step.
                      </p>
                    </div>
                  </div>
                </div>
              </Panel>
              {presets.map((preset) => (
                <Panel key={preset.id} className="h-100">
                  <div className="pa3">
                    <Accordion allowMultipleExpanded>
                      {preset.calculations.map(({ id, name, unit, measurements, formula }) => (
                        <AccordionItem key={id}>
                          <AccordionHeader>
                            <span className="dib f6 near-black normal basier-med lh-title">
                              {name} {unit && `(${unit})`}
                            </span>
                          </AccordionHeader>
                          <AccordionPanel>
                            <div className="pv3">
                              <div className="pb2 flex flex-wrap">
                                {measurements?.map(({ id, name, unit }) => (
                                  <div className="f7 bg-near-white pa2 mr2 br2 near-black dib" key={id}>
                                    {name} {unit && `(${unit})`}
                                  </div>
                                ))}
                              </div>
                              <div className="ma2 mt0">
                                <span className="dib mr2 mb2 f7 near-black">Formula</span>
                                <div className="f7 bg-near-white pa2 br2 near-black dib">{formula}</div>
                              </div>
                            </div>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </Panel>
              ))}
            </div>
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default SelectPreset;
