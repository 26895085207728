// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { _isNotEmpty } from '@/littledash';
import type { TaskSchedule } from '@/model/Task.model';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';

export const generateInitialState = (study) => {
  const {
    id,
    author,
    settings: { alerts, calculations },
    name,
    notes,
    type,
    metadata,
    users,
    study_groups: studyGroups,
    average_calculation,
    error_deviation,
    task_template_group_id,
    archived_at,
    canceled_at,
    started_on,
    project,
    owner,
    study_creation_template_id,
  } = study;
  const initialState = {
    form: {
      author,
      study_id: id,
      loading: true,
      submitting: false,
      apiErrors: [],
      calculations,
      taskTemplates: [],
      presets: [],
      selectedPreset: study.settings?.id ? study.settings : null,
      hasMeasurements: true,
      currentStep: 0,
      studyGroups: studyGroups,
      archivedAt: archived_at,
      canceledAt: canceled_at,
      updatingBenchlingIntegration: false,
    },
    study: {
      id,
      alerts,
      average_calculation,
      error_deviation,
      name,
      notes,
      type,
      metadata,
      users: users,
      task_template_group_id,
      started_on: started_on || new Date(),
      project_id: project?.id,
      owner: owner?.id || null,
      study_creation_template_id,
    },
  };

  return initialState;
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'initialiseState':
      return action.data;
    case 'updateForm':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case 'updateStudy':
      return {
        ...state,
        study: {
          ...state.study,
          ...action.data,
        },
      };
    case 'setPreset':
      return {
        ...state,
        form: {
          ...state.form,
          selectedPreset: action.data,
        },
      };
    case 'SET_SUBMITTING':
      return {
        ...state,
        form: {
          ...state.form,
          submitting: action.data,
        },
      };
    case 'SET_UPDATING_BENCHLING_INTEGRATION':
      return {
        ...state,
        form: {
          ...state.form,
          updatingBenchlingIntegration: action.data,
        },
      };
    case 'SUBMIT_PRESET':
      return {
        ...state,
        form: {
          ...state.form,
          submitting: true,
        },
        study: {
          ...state.study,
          preset_id: state.form.selectedPreset?.id || null,
        },
      };
    case 'SET_API_ERROR':
      return {
        ...state,
        form: {
          ...state.form,
          error: action.data,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        form: {
          ...state.form,
          loading: action.data,
        },
      };
    case 'SET_SELECTABLE_METADATA': {
      const filteredMetadata = action.data.selectable.filter(
        (meta) => meta.show_in && meta.show_in.includes(state.study.type)
      );
      return {
        ...state,
        form: {
          ...state.form,
          selectableMetadata: action.data.selectable,
        },
        study: {
          ...state.study,
          metadata: filteredMetadata.map((metadata) => {
            return {
              glossary_id: metadata.id,
              value: action.data.metadata.find((m) => m.glossary_id === metadata.id)?.value || '',
            };
          }),
        },
      };
    }
    default:
      return state;
  }
};

const buttonDefaultProps = (state) => {
  const tooltip = state.form.archivedAt
    ? 'This study is complete'
    : state.form.canceledAt
      ? 'This study is cancelled'
      : undefined;
  return {
    tooltip,
    disabled: state.form.submitting || state.form.archivedAt || state.form.canceledAt,
  };
};

export const UseFormProvider = ({ state, dispatch, overrideOnSubmit, children, unregister = false }) => {
  const methods = useForm({
    shouldUnregister: unregister,
    defaultValues: {
      ...state.study,
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    dispatch({ type: 'updateStudy', data });
    dispatch({ type: 'SET_SUBMITTING', data: true });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(overrideOnSubmit || onSubmit)}>
        {children}
        <div className="pt3 pb5" data-testid="settings__actions">
          <Button {...buttonDefaultProps(state)} submit testId="settings__actions--save-button">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const DefaultFormProvider = ({ state, dispatch, children }) => {
  return (
    <div>
      {children}
      <div className="pt3 pb5">
        <Button {...buttonDefaultProps(state)} onClick={() => dispatch({ type: 'SET_SUBMITTING', data: true })}>
          Save
        </Button>
      </div>
    </div>
  );
};

export const PresetFormProvider = ({ state, dispatch, children }) => {
  return (
    <div>
      {children}
      <div className="pt3 pb5">
        <Button {...buttonDefaultProps(state)} onClick={() => dispatch({ type: 'SUBMIT_PRESET' })}>
          Save
        </Button>
      </div>
    </div>
  );
};

const taskConditionalTypes = {
  age: 'Age',
  study_day: 'Study day',
  tracking_day: 'Tracking day',
};

const recurringDurationFrequency = {
  weeks: 'week',
  days: 'day',
};

const recurringDays = {
  monday: 'Mon',
  tuesday: 'Tues',
  wednesday: 'Wed',
  thursday: 'Thurs',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

const recurringDayKeys = Object.keys(recurringDays);

interface TaskScheduleFrequencyCellProps {
  schedule: Omit<TaskSchedule, 'timezone'>;
}

export const TaskScheduleFrequencyCell = ({ schedule }: TaskScheduleFrequencyCellProps) => {
  const renderFrequencyCell = () => {
    switch (schedule.type) {
      case 'conditional': {
        if (schedule.condition) {
          return `${taskConditionalTypes[schedule.condition.type]} ${schedule.condition.value.join(', ')} ${
            schedule.condition.type === 'age' ? 'days' : ''
          }`;
        }
        break;
      }
      case 'recurring': {
        const days = schedule.repeat.on_day
          ?.sort((a, b) => recurringDayKeys.indexOf(a) - recurringDayKeys.indexOf(b))
          ?.map((day) => recurringDays[day]);
        return `Every ${schedule.repeat.value} ${recurringDurationFrequency[schedule.repeat.unit]}${
          schedule.repeat.value > 1 ? 's' : ''
        } ${_isNotEmpty(days) ? `(${days.join(', ')})` : ''}`;
      }
      default:
        return 'One off';
    }
  };

  return <p data-testid="frequency-cell">{renderFrequencyCell()}</p>;
};
