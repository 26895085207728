import type {
  DataTableAxis,
  DataTableCellIndexCoordinate,
  DataTableDimensions,
  DataTableDirection,
  DataTableRange,
  DataTableScrollListener,
  DataTableScrollOptions,
  DataTableScrollService as iDataTableScrollService,
  DataTableSelection,
} from '../DataTable.model';
import { Axis, DataTableScrollEvent } from '../DataTable.model';

interface DataTableScrollServiceProps {
  dataTableElement: HTMLElement | null;
  dataTableDimensions: DataTableDimensions;
  dataTableTotalSize: DataTableAxis;
  rowHeaderWidth: number;
}

export class DataTableScrollService implements iDataTableScrollService {
  #dataTableElement: HTMLElement | null;
  readonly #dataTableDimensions: DataTableDimensions;
  readonly #rowHeaderWidth: number;
  readonly #dataTableTotalSize: Readonly<{ x: number; y: number }>;
  readonly #eventEmitter = new EventTarget();

  constructor({
    dataTableElement,
    dataTableDimensions,
    rowHeaderWidth,
    dataTableTotalSize,
  }: DataTableScrollServiceProps) {
    this.#dataTableElement = dataTableElement;
    this.#dataTableDimensions = Object.freeze(dataTableDimensions);
    this.#rowHeaderWidth = Object.freeze(rowHeaderWidth);
    this.#dataTableTotalSize = Object.freeze(dataTableTotalSize);
  }

  get cellWidth(): Readonly<number> {
    return Object.freeze(this.#dataTableDimensions?.cellWidth ?? 0);
  }

  get cellHeight(): Readonly<number> {
    return Object.freeze(this.#dataTableDimensions?.cellHeight ?? 0);
  }

  get columnHeight(): Readonly<number> {
    return Object.freeze(this.#dataTableDimensions?.columnHeight ?? 0);
  }

  get windowWidth(): Readonly<number> {
    return Object.freeze(this.#dataTableElement?.getBoundingClientRect().width ?? 0);
  }

  get windowHeight(): Readonly<number> {
    return Object.freeze(this.#dataTableElement?.getBoundingClientRect().height ?? 0);
  }

  get windowScrollLeft(): Readonly<number> {
    return Object.freeze(this.#dataTableElement?.scrollLeft ?? 0);
  }

  get windowScrollTop(): Readonly<number> {
    return Object.freeze(this.#dataTableElement?.scrollTop ?? 0);
  }

  get dataTableTotalWidth(): Readonly<number> {
    return Object.freeze(this.#dataTableTotalSize?.x ?? 0);
  }

  get dataTableTotalHeight(): Readonly<number> {
    return Object.freeze(this.#dataTableTotalSize?.y ?? 0);
  }

  // The viewport's total block index in both x/y
  get viewportBlocks(): Readonly<DataTableAxis> {
    return {
      x: Math.abs(this.#rowHeaderWidth - this.windowWidth) / this.cellWidth - 1,
      y: Math.abs(this.columnHeight - this.windowHeight) / this.cellHeight - 1,
    };
  }

  // at any given time, the scrolled index blocks from start to finish in both x/y
  get scrollBlocks(): Readonly<{ x: DataTableRange; y: DataTableRange }> {
    return {
      x: {
        startIndex: this.windowScrollLeft / this.cellWidth,
        endIndex: this.windowScrollLeft / this.cellWidth + this.viewportBlocks.x,
      },
      y: {
        startIndex: this.windowScrollTop / this.cellHeight,
        endIndex: this.windowScrollTop / this.cellHeight + this.viewportBlocks.y,
      },
    };
  }

  // The total x/y blocks of the DataTable
  get totalBlocks(): Readonly<DataTableAxis> {
    return {
      x: Math.max((this.dataTableTotalWidth - this.#rowHeaderWidth) / this.cellWidth - 1, 1),
      y: Math.max((this.dataTableTotalHeight - this.columnHeight) / this.cellHeight - 1, 1),
    };
  }

  get scrollPercent(): Readonly<DataTableAxis> {
    return {
      x:
        this.windowScrollLeft > 0 && this.#dataTableTotalSize.x > 0
          ? (this.windowScrollLeft / this.#dataTableTotalSize.x) * 100
          : 0,
      y:
        this.windowScrollTop > 0 && this.#dataTableTotalSize.y > 0
          ? (this.windowScrollTop / this.#dataTableTotalSize.y) * 100
          : 0,
    };
  }

  get xAxisScrollingEnabled(): Readonly<boolean> {
    return this.dataTableTotalWidth > this.windowWidth;
  }

  get yAxisScrollingEnabled(): Readonly<boolean> {
    return this.dataTableTotalHeight > this.windowHeight;
  }

  public calculateDataTableMove(block = 1 as number, direction: DataTableDirection = 'LEFT'): { x: number; y: number } {
    const blockXDistance = block * this.cellWidth;
    const blockYdistance = block * this.cellHeight;
    switch (direction) {
      case 'LEFT': {
        const xPosition = Math.abs(this.windowScrollLeft - blockXDistance);
        return { x: xPosition > blockXDistance ? xPosition : 0, y: this.windowScrollTop };
      }
      case 'RIGHT':
        return { x: this.windowScrollLeft + blockXDistance, y: this.windowScrollTop };
      case 'UP': {
        const yPosition = Math.abs(this.windowScrollTop - blockYdistance);
        return { x: this.windowScrollLeft, y: yPosition > blockYdistance ? yPosition : 0 };
      }
      case 'DOWN':
        return { x: this.windowScrollLeft, y: this.windowScrollTop + blockYdistance };
      default:
        return { x: 0, y: 0 };
    }
  }

  public moveDataTableWindow(
    direction: DataTableDirection = 'LEFT',
    blocks = 1 as number,
    options?: Partial<DataTableScrollOptions>
  ): void {
    const { x, y } = this.calculateDataTableMove(blocks, direction);
    this.#dataTableElement?.scrollTo({ left: x, top: y, behavior: options?.behaviour });
  }

  public moveDataTableToBlock(axis: Axis, blocks: number): void {
    switch (axis) {
      case Axis.x:
        this.#dataTableElement?.scrollTo({ left: blocks * this.cellWidth, top: this.windowScrollTop });
        break;
      case Axis.y:
        this.#dataTableElement?.scrollTo({ left: this.windowScrollLeft, top: blocks * this.cellHeight });
        break;
      default:
        break;
    }
  }

  public moveDataTableWindowBySelection(
    dataTableSelection: Readonly<DataTableSelection>,
    options?: Partial<DataTableScrollOptions>
  ): void {
    const {
      from: { row, column },
    } = dataTableSelection;
    if (column <= this.scrollBlocks.x.startIndex) {
      this.moveDataTableWindow('LEFT', 1, options);
    }
    if (column >= this.scrollBlocks.x.endIndex) {
      this.moveDataTableWindow('RIGHT', 1, options);
    }
    if (row <= this.scrollBlocks.y.startIndex) {
      this.moveDataTableWindow('UP', 1, options);
    }
    if (row >= this.scrollBlocks.y.endIndex) {
      this.moveDataTableWindow('DOWN', 1, options);
    }
  }

  public isScrollingLeft(deltaX: number): boolean {
    if (deltaX < 0 && this.windowScrollLeft > 0) {
      return true;
    }
    return false;
  }

  public isScrollingRight(deltaX: number): boolean {
    if (deltaX > 0) {
      return true;
    }
    return false;
  }

  public isScrollingUp(deltaY: number): boolean {
    if (deltaY < 0 && this.windowScrollTop > 0) {
      return true;
    }
    return false;
  }

  public isScrollingDown(deltaY: number): boolean {
    if (deltaY > 0) {
      return true;
    }
    return false;
  }

  public scrollDataTableWindowX(deltaX: number): void {
    if (this.isScrollingLeft(deltaX)) {
      this.moveDataTableWindow('LEFT', 0.25);
    }
    if (this.isScrollingRight(deltaX)) {
      this.moveDataTableWindow('RIGHT', 0.25);
    }
  }

  public scrollDataTableWindowY(deltaY: number): void {
    if (this.isScrollingUp(deltaY)) {
      this.moveDataTableWindow('UP', 0.5);
    }
    if (this.isScrollingDown(deltaY)) {
      this.moveDataTableWindow('DOWN', 0.5);
    }
  }

  public moveDataTableWindowToIndexXY(cellIndexCoordinate: DataTableCellIndexCoordinate): { x: number; y: number } {
    return { x: cellIndexCoordinate.column * this.cellWidth, y: cellIndexCoordinate.row * this.cellHeight };
  }

  public moveDataTableWindowToIndex(
    cellIndexCoordinate: DataTableCellIndexCoordinate,
    options?: Partial<DataTableScrollOptions>
  ): void {
    const { x, y } = this.moveDataTableWindowToIndexXY(cellIndexCoordinate);
    this.#dataTableElement?.scrollTo({ left: x, top: y, behavior: options?.behaviour });
  }

  subscribe<Event extends DataTableScrollEvent>(event: Event, listener: DataTableScrollListener[Event]): void {
    this.#eventEmitter.addEventListener(event, listener as EventListener, { passive: true });
  }

  unsubscribe<Event extends DataTableScrollEvent>(event: Event, listener: DataTableScrollListener[Event]): void {
    this.#eventEmitter.removeEventListener(event, listener as EventListener);
  }

  public onDragScrollBar = (isDragged: boolean, axis: Axis): void => {
    this.#eventEmitter.dispatchEvent(
      new CustomEvent(DataTableScrollEvent.DragScrollBar, {
        detail: { isDragged, axis: Axis[axis] },
      })
    );
  };

  public destroy(): void {
    this.#dataTableElement = null;
  }
}
