// @ts-nocheck: converted from JS

import Radio from '@/components/UI/FormElements/Radio';
import { newLineText, preventNumberScroll } from '@/helpers';
import { _notNil } from '@/littledash';
import { useEffect } from 'react';

const ObservationForm = ({ observation, errors, index, handleInput, width, dispatch }) => {
  const contentProps = {
    observation,
    index,
    handleInput,
    errors,
  };
  const content = {
    none: <></>,
    numeric: <NumericScale {...contentProps} />,
    severity: <SeverityOptions {...contentProps} />,
  };

  useEffect(() => {
    if (_notNil(dispatch)) {
      dispatch({ type: 'stepReady' });
    }
  }, []);

  return (
    <div className="ph1">
      {observation.description && (
        <p className="lh-copy f6 mb3 measure-wide near-black mb3">{newLineText(observation.description)}</p>
      )}
      {content[observation.type]}
      <div className="mt3 measure-wide">
        <label className="mb2">Additional comments</label>
        <textarea
          maxLength={5000}
          style={{ width: width ?? '34em' }}
          onChange={(e) => handleInput(index, 'text', e.target.value)}
          name="text"
          cols="30"
          rows="10"
          value={observation.text}
        />
      </div>
    </div>
  );
};

const NumericScale = ({ observation, errors, index, handleInput }) => (
  <div className="mb3">
    <label htmlFor="value">
      Severity rating {observation.options && `(${observation.options.min}-${observation.options.max})`}
    </label>
    <input
      type="number"
      onWheel={preventNumberScroll}
      name="value"
      className={errors && 'input__error'}
      style={{ marginBottom: 0, width: '125px' }}
      min={observation.options && observation.options.min}
      max={observation.options && observation.options.max}
      value={observation.value}
      onChange={(e) => handleInput(index, 'value', e.target.value)}
    />
    {errors && (
      <ul className="f6 red lh-copy mt2">
        {errors.value.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    )}
  </div>
);

export const severityScale = ['Mild', 'Moderate', 'Severe'];

const SeverityOptions = ({ observation, errors, index, handleInput }) => (
  <div className="mb3">
    <label className="mb2">Select a severity</label>
    {severityScale.map((severity) => (
      <Radio
        key={severity}
        checked={observation.value === severity}
        onChange={(e) => handleInput(index, 'value', e.target.value)}
        id={severity}
        name="severity"
        label={severity}
        value={severity}
        className="mb2"
      />
    ))}
    {errors && (
      <ul className="f6 red lh-copy">
        {errors.value.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    )}
  </div>
);

export default ObservationForm;
