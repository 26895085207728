import { StepFormAction, StepFormState } from './StepForm.model';

export const reducer = <T extends StepFormState<T>>(state: T, action: StepFormAction<T>): T => {
  switch (action.type) {
    case 'next':
      return {
        ...state,
        currentStep: Math.min(state.steps.length - 1, state.currentStep + 1),
        stepReady: false,
      };
    case 'previous':
      return { ...state, currentStep: Math.max(0, state.currentStep - 1), stepReady: false };
    case 'stepReady':
      return { ...state, stepReady: action.data ?? true };
    case 'finalStepReady':
      return { ...state, finalStepReady: action.data ?? true };
    case 'jumpToStep': {
      if (state.currentStep === action.currentStep) {
        return state;
      }
      // if the step is backward you can do it
      if (action.currentStep <= state.currentStep) {
        return { ...state, currentStep: action.currentStep, stepReady: false };
      }
      // if the step is forwards then check all the steps in between
      let valid = true;
      for (let index = state.currentStep; index <= Math.max(state.currentStep, action.currentStep - 1); index++) {
        if (state.steps[index] && state.steps[index].isValid(state) !== true) {
          valid = false;
          break;
        }
      }
      if (valid) {
        return { ...state, currentStep: action.currentStep, stepReady: false };
      }
      return state;
    }
    case 'update':
      return {
        ...state,
        [action.id]: { ...state[action.id], ...action.data },
      };
    case 'updateArray':
      return {
        ...state,
        [action.id]: [...action.data],
      };
    default:
      return state;
  }
};
