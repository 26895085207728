import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import AnimalEvents from '@/components/Subjects/Show/AnimalEvents';
import Dosing from '@/components/Subjects/Show/Views/Dosing';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { isDead } from '@/constants/utils';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Study } from '@/model/Study.model';
import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import * as Auth from '@/support/auth';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoute } from '@/support/route';
import AnimalAlerts from './Alerts/Alerts';
import AnimalActions from './AnimalActions';
import MeasurementsSidebar from './Measurements/MeasurementsSidebar';
import LatestMeasurementsTable from './Measurements/Table';
import Overview from './Overview';
import { initialState, reducer } from './Show.utils';
import Observations from './Views/Observations';
import Samples from './Views/Samples';
import { useSelector } from 'react-redux';
import type { State } from '@/model/State.model';

const Show: React.FC = () => {
  const { id: studyId, subjectId } = useParams<{ id: string; subjectId: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    entity: study,
    entityUpdating: studyUpdating,
    entityLoading: studyLoading,
    entityError: studyError,
    fetchEntity: fetchStudy,
  } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,study_groups',
  });
  const {
    entity: animal,
    entityLoading: animalLoading,
    entityUpdating: animalUpdating,
    entityError: animalError,
    fetchEntity: fetchAnimal,
  } = useFetchEntity<Animal>({
    entityType: 'studyAnimal',
    params: { studyId, id: subjectId },
    includes: 'alerts,cage,study_group,animal_selection,terminated_at_data,latestMeasurement',
  });

  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  useEffect(() => {
    if (animal && study) {
      fetchStudy();
      fetchAnimal();
    }
  }, [studyId, subjectId]);

  useEffect(() => {
    if (_notNil(animal)) {
      dispatch({ type: 'SET_CURRENT_ANIMAL_ID', data: animal.id });
      dispatch({ type: 'SET_ALERTS', data: animal.alerts ?? [] });
    }
  }, [animal]);

  const userHasWriteAccess = study && Auth.isWriteUserForStudy(study);

  if (animalLoading || studyLoading || animalUpdating || studyUpdating) {
    return <Loading />;
  }

  const unresolvedAlertsNumber = state.alerts.filter(({ resolved_at }) => _isNil(resolved_at)).length;

  return animalLoading || studyLoading || animalUpdating || studyUpdating ? (
    <Loading />
  ) : studyError || animalError || !study || !animal ? (
    <div style={{ paddingRight: 400 }}>
      <ApiErrorBanner
        className="mb4"
        title={'There was an error retrieving the animal'}
        text={
          'An error has occurred when fetching the animal, please try again later. If this keeps occurring please contact support.'
        }
        errorType={ApiErrorType.FETCH}
        error={studyError ?? animalError}
      />
    </div>
  ) : (
    <div style={{ paddingRight: 400 }}>
      <SubHeader
        asideComponent={() =>
          animal?.animal_selection && (
            <div className="flex lh-title">
              <Link
                className="blue mr3"
                disabled={!animal.animal_selection.previous}
                to={webRoute('subjects.show', {
                  id: studyId,
                  subject_id: animal.animal_selection.previous,
                })}
              >
                Previous
              </Link>
              <Link
                className="blue"
                disabled={!animal.animal_selection.next}
                to={webRoute('subjects.show', {
                  id: studyId,
                  subject_id: animal.animal_selection.next,
                })}
              >
                Next
              </Link>
            </div>
          )
        }
        link={webRoute('studies.animals', {
          id: studyId,
        })}
        linkToText="Animals"
      />
      <div className="ph4 pv3 bg-white">
        <Header
          asideComponent={() => (
            <AnimalActions
              animal={animal}
              fetchAnimal={fetchAnimal}
              studyHasGroups={_isNotEmpty(study?.study_groups)}
              studyApiId={study?.api_id}
              userHasWriteAccess={userHasWriteAccess ?? false}
              features={features}
            />
          )}
          mainHeaderText={animal?.name}
          subHeaderText={isDead(animal)}
        />
        <AnimalEvents animal={animal} fetchAnimal={fetchAnimal} study={study} />
      </div>
      <Tabs>
        <div className="bb b--moon-gray ph4 bg-white">
          {['Overview', 'Alerts', 'Observations', 'Samples', 'Dosing'].map((tab) =>
            tab === 'Alerts' ? (
              <Tab key={`${tab}-tab`} testId={`animals-${tab.toLowerCase()}-tab`}>
                <span className="pr2">{tab}</span>
                {unresolvedAlertsNumber > 0 && (
                  <div
                    className="br-100 tc dib bg-red white pointer bg-dark-red-hover"
                    style={{
                      height: 24,
                      width: 24,
                      flex: '0 0 24px',
                    }}
                  >
                    <div className="flex justify-center items-center w-100 h-100">
                      <span className="lh-solid">{unresolvedAlertsNumber}</span>
                    </div>
                  </div>
                )}
              </Tab>
            ) : (
              <Tab key={`${tab}-tab`} testId={`animals-${tab.toLowerCase()}-tab`}>
                {tab}
              </Tab>
            )
          )}
        </div>
        <Panel className="pa4">
          <Overview study={study} currentAnimalId={state.currentAnimalId} dispatch={dispatch} />
          <div className="mt3">
            <LatestMeasurementsTable animal={animal} study={study} />
          </div>
        </Panel>
        <Panel className="pa4">
          <AnimalAlerts study={study} animal={animal} dispatch={dispatch} />
        </Panel>
        <Panel className="pa4">
          <Observations hasWriteAccess={userHasWriteAccess} />
        </Panel>
        <Panel className="pa4">
          <Samples hasWriteAccess={userHasWriteAccess} />
        </Panel>
        <Panel className="pa4">
          <Dosing study={study} animal={animal} />
        </Panel>
      </Tabs>
      <MeasurementsSidebar study={study} animal={animal} measurementsUpdated={fetchAnimal} />
    </div>
  );
};

export default Show;
